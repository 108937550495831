import { useForm, zodResolver } from "@mantine/form";
import { useState } from "react";
import { z } from "zod";
import readFileAsBase64 from "../../utils/readFileAsBase64.js";
import DocumentTemplatesAPI from "../../api/DocumentTemplates/DocumentTemplates.js";
import {
  Button,
  FileInputField,
  Switch,
  TextAreaInput,
  TextInput,
} from "@monolith-forensics/monolith-ui";
import ButtonMenu from "./components/ButtonMenu.js";
import { usePermissions } from "../../hooks/usePermissions";
import styled from "styled-components";
import Loader from "../Loader.js";
import LoggingAPI from "../../api/logging/index.js";

const schema = z.object({
  name: z
    .string({ message: "Name is required" })
    .min(1, { message: "Name is required" }),
  description: z.string().nullable(),
  file: z.array(z.object({ name: z.string(), size: z.number() })),
});

const EditDocumentTemplateForm = styled(
  ({ className, onSubmit, onCancel, defaultInfo, buttonProps = {} }) => {
    const [submitting, setSubmitting] = useState(false);
    const { currentUser } = usePermissions();

    const form = useForm({
      mode: "uncontrolled",
      initialValues: {
        name: defaultInfo?.name || "test",
        description: defaultInfo?.description || "",
        file: [],
        is_shared: defaultInfo?.is_shared,
      },
      validate: zodResolver(schema),
    });

    const handleSubmit = async () => {
      const validateResult = form.validate();

      if (validateResult.hasErrors) {
        return;
      }

      setSubmitting(true);

      const formData = form.getValues();

      // Read the file
      const file = formData?.file?.[0]
        ? await readFileAsBase64(formData.file[0])
        : null;

      const templateData = {
        uuid: defaultInfo.uuid,
        name: formData.name,
        description: formData.description,
        is_shared: formData.is_shared,
      };

      if (file) {
        templateData.file = file;
        templateData.file_name = formData.file[0].name;
        templateData.ext = formData.file[0].name.split(".").pop();
        templateData.size = formData.file[0].size;
        templateData.mime_type = formData.file[0].type;
      }

      await DocumentTemplatesAPI.update(templateData);

      LoggingAPI.createLog({
        user_id: currentUser.user_id,
        message: `Edited document template: ${templateData.name}`,
        event: "edit_document_template",
        object_type: "document_template",
        object_uuid: templateData.uuid,
      });

      // Do not need base64 data in the templateData when sent in the onSubmit
      delete templateData.file;

      onSubmit?.(templateData);
      setSubmitting(false);
    };

    const handleCancel = () => {
      form.reset();
      onCancel?.();
    };

    return (
      <div className={className}>
        {submitting && <Loader />}
        {!submitting && (
          <>
            <TextInput
              label="Template Name"
              placeholder="Template Name"
              variant="outlined"
              size="sm"
              required
              key={form.key("name")}
              {...form.getInputProps("name")}
            />
            <TextAreaInput
              label="Description"
              placeholder="Description"
              variant="outlined"
              size="sm"
              minRows={6}
              maxRows={6}
              style={{ height: 100 }}
              key={form.key("description")}
              {...form.getInputProps("description")}
            />
            <FileInputField
              label="Template File (.docx)"
              size="sm"
              key={form.key("file")}
              {...form.getInputProps("file")}
              dropZoneOptions={{
                maxFiles: 1,
                maxSize: 10485760, // 10MB
                accept: {
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                    [".docx"],
                },
              }}
            >
              <div>Drop ".docx" here or Click to select file ...</div>
              <div>(10MB Limit)</div>
            </FileInputField>
            <Switch
              size="sm"
              label="Shared"
              description="Share this template with other Monolith users."
              key={form.key("is_shared")}
              {...form.getInputProps("is_shared")}
            />
            <ButtonMenu>
              <Button size="xs" variant="subtle" onClick={handleCancel}>
                {buttonProps?.cancelText || "Cancel"}
              </Button>
              <Button
                size="xs"
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                {buttonProps?.submitText || "Submit"}
              </Button>
            </ButtonMenu>
          </>
        )}
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  gap: 15px;

  width: ${({ width }) =>
    Number.isInteger(width) ? `${width}px` : width || "100%"};
`;

export default EditDocumentTemplateForm;
