import calculateStringHash from "../utils/calculateStringHash.js";

let DB = null;

export const init = async (dbname) => {
  // Dynamically import Dexie
  const { default: Dexie } = await import("dexie");

  const hashedDBName = await calculateStringHash(dbname, "SHA-1");

  DB = new Dexie(hashedDBName);

  DB.version(2).stores({
    CaseNoteTabs: "uuid",
    EvidenceNoteTabs: "uuid",
    UserPreferences: "setting",
    TaskState: "",
  });

  return DB;
};

export const getDB = () => {
  return DB;
};
