import {
  httpGet,
  httpGetURL,
  httpPost,
  httpPostFormData,
} from "../connection.js";

const get = async (params) => {
  return httpGet(`/case-files`, params);
};

const getItemPath = async (params) => {
  return httpGet(`/case-files/item-path`, params);
};

const create = async (values) => {
  return httpPost(`/case-files/create`, values);
};

const createFolder = async (values) => {
  return httpPost(`/case-files/create-folder`, values);
};

const update = async (values) => {
  return httpPost(`/case-files/update`, values);
};

const upload = async (values, options = {}) => {
  return httpPostFormData(`/case-files/upload`, values, {
    onUploadProgress: options.onUploadProgress,
  });
};

const downloadFolder = async (values) => {
  return httpGet(`/case-files/download-folder`, values);
};

const downloadFile = async (fileName, values) => {
  return httpGet(`/case-files/download-file`, values);
};

const getFolderDownloadURL = (values) => {
  return httpGetURL(`/case-files/download-folder`, values);
};

const getFileDownloadURL = (values) => {
  return httpGetURL(`/case-files/download-file`, values);
};

const deleteItem = async (values) => {
  return httpPost(`/case-files/delete`, values);
};

const getStorageVolume = async () => {
  return httpGet(`/case-files/storage-volume`);
};

const shareItems = async (values) => {
  return httpPost(`/case-files/share-items`, values);
};

const unShareItems = async (values) => {
  return httpPost(`/case-files/unshare-items`, values);
};

const getShareDetails = async (values) => {
  return httpGet(`/case-files/share-details`, values);
};

const getLogs = async (values) => {
  return httpGet(`/case-files/logs`, values);
};

const restoreFiles = async (values) => {
  return httpPost(`/case-files/restore-files`, values);
};

const CaseFileAPI = {
  get,
  getItemPath,
  create,
  createFolder,
  update,
  upload,
  downloadFolder,
  downloadFile,
  getFolderDownloadURL,
  getFileDownloadURL,
  deleteItem,
  getStorageVolume,
  shareItems,
  unShareItems,
  getShareDetails,
  getLogs,
  restoreFiles,
};

export default CaseFileAPI;
