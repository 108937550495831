import styled from "styled-components";

const TabsContainer = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.palette.background.default};
  min-width: 50rem;

  flex-grow: 1;
  height: 35px;
  min-height: 35px;
  border-top: 1px solid ${(props) => props.theme.palette.divider};
  border-right: 1px solid ${(props) => props.theme.palette.divider};
  overflow-x: auto;

  &[data-empty="false"] {
    border-top: none;
    border-right: 1px solid transparent;
  }
`;

export default TabsContainer;
