const readFileAsBase64 = async (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      resolve(reader.result.split("base64,")[1]);
    });
    reader.readAsDataURL(file);
  });
};

export default readFileAsBase64;
