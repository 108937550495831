import { useState } from "react";
import { Modal } from "@monolith-forensics/monolith-ui";
import Loader from "../Loader.js";
import { AnalysisAPI } from "../../api/index.js";
import { useMutation } from "@tanstack/react-query";
import LoggingAPI from "../../api/logging/index.js";
import { usePermissions } from "../../hooks/usePermissions";
import { DefaultTraceAttributeForm } from "../Forms";

const EditTraceAttributeModal = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  defaultFormData,
  ...props
}) => {
  const { currentUser } = usePermissions();
  const [submitting, setSubmitting] = useState(false);

  const updateTraceAttribute = useMutation({
    mutationFn: AnalysisAPI.TraceAttributes.update,
    onMutate: () => setSubmitting(true),
    onSuccess: (_, variables) => {
      LoggingAPI.createLog({
        case_id: defaultFormData.case_id,
        user_id: currentUser.user_id,
        message: `Updated trace attribute.`,
        event: "trace_attribute:update",
        object_type: "trace_attribute",
        object_uuid: defaultFormData.uuid,
      });
      onSubmit?.(defaultFormData, variables);
      setSubmitting(false);
    },
  });

  return (
    <Modal
      open={show}
      onClose={onClose}
      style={{ width: 550 }}
      FocusProps={{ initialFocus: -1 }}
    >
      <Modal.Title style={{ marginBottom: 10 }}>
        Edit Trace Attribute
      </Modal.Title>
      {!submitting && (
        <DefaultTraceAttributeForm
          onSubmit={(data, diff) =>
            updateTraceAttribute.mutate({ uuid: data.uuid, ...diff })
          }
          onCancel={onCancel}
          defaultFormData={defaultFormData}
          {...props}
        />
      )}
      {submitting && <Loader />}
    </Modal>
  );
};

export default EditTraceAttributeModal;
