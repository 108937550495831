import { z } from "zod";

const TraceAttributeTypes = [
  {
    label: "MD5",
    value: "md5",
    category: "Hash",
    editor_type: "text",
    schema: z.string().regex(/^[a-f0-9]{32}$/),
  },
  {
    label: "SHA1",
    value: "sha1",
    category: "Hash",
    editor_type: "text",
    schema: z.string().regex(/^[a-f0-9]{40}$/),
  },
  {
    label: "SHA256",
    value: "sha256",
    category: "Hash",
    editor_type: "text",
    schema: z.string().regex(/^[a-f0-9]{64}$/),
  },
  {
    label: "SHA512",
    value: "sha512",
    category: "Hash",
    editor_type: "text",
    schema: z.string().regex(/^[a-f0-9]{128}$/),
  },
  {
    label: "URL",
    value: "url",
    category: "Networking",
    schema: z.string().url(),
    editor_type: "text",
  },
  {
    label: "IP Source",
    value: "ip:source",
    category: "Networking",
    schema: z.string().ip(),
    editor_type: "text",
  },
  {
    label: "IP Dest",
    value: "ip:dest",
    category: "Networking",
    schema: z.string().ip(),
    editor_type: "text",
  },
  {
    label: "Port Source",
    value: "port:source",
    category: "Networking",
    schema: z.string().regex(/^\d+$/),
    editor_type: "number",
    min: 0,
    max: 65535,
  },
  {
    label: "Port Dest",
    value: "port:dest",
    category: "Networking",
    schema: z.string().regex(/^\d+$/),
    editor_type: "number",
    min: 0,
    max: 65535,
  },
  {
    label: "Hostname",
    value: "hostname",
    category: "Networking",
    schema: z.string(),
    editor_type: "text",
  },
  {
    label: "Domain",
    value: "domain",
    category: "Networking",
    schema: z.string().url(),
    editor_type: "text",
  },
  {
    label: "MIME Type",
    value: "mime_type",
    category: "File",
    editor_type: "text",
    schema: z.string().regex(/^[a-z0-9-]+\/[a-z0-9-]+$/),
  },
  {
    label: "Name",
    value: "file:name",
    category: "File",
    schema: z.string(),
    editor_type: "text",
  },
  {
    label: "Path",
    value: "file:path",
    category: "File",
    schema: z.string(),
    editor_type: "text",
  },
  {
    label: "Extension",
    value: "file:extension",
    category: "File",
    schema: z.string(),
    editor_type: "text",
  },
  {
    label: "Size (bytes)",
    value: "file:size",
    category: "File",
    editor_type: "number",
    min: 0,
    schema: z.string().regex(/^\d+$/),
  },
  {
    label: "Account",
    value: "user:account",
    category: "User",
    schema: z.string(),
    editor_type: "text",
  },
  {
    label: "Hive",
    value: "registry:hive",
    category: "Registry",
    schema: z.string(),
    editor_type: "text",
  },
  {
    label: "Key",
    value: "registry:key",
    category: "Registry",
    schema: z.string(),
    editor_type: "text",
  },
  {
    label: "Value",
    value: "registry:value",
    category: "Registry",
    schema: z.string(),
    editor_type: "text",
  },
  {
    label: "Created",
    value: "time:created",
    category: "Time",
    schema: z.string().datetime(),
    editor_type: "datetime",
  },
  {
    label: "Modified",
    value: "time:modified",
    category: "Time",
    schema: z.string().datetime(),
    editor_type: "datetime",
  },
  {
    label: "Accessed",
    value: "time:accessed",
    category: "Time",
    schema: z.string().datetime(),
    editor_type: "datetime",
  },
  {
    label: "Address",
    value: "email:address",
    category: "Email",
    editor_type: "text",
    schema: z.string().email(),
  },
  {
    label: "Subject",
    value: "email:subject",
    category: "Email",
    editor_type: "text",
    schema: z.string(),
  },
  {
    label: "From",
    value: "email:from",
    category: "Email",
    schema: z.string().email(),
    editor_type: "text",
  },
  {
    label: "To",
    value: "email:to",
    category: "Email",
    schema: z.string().email(),
    editor_type: "text",
  },
  {
    label: "CC",
    value: "email:cc",
    category: "Email",
    schema: z.string().email(),
    editor_type: "text",
  },
  {
    label: "BCC",
    value: "email:bcc",
    category: "Email",
    schema: z.string().email(),
    editor_type: "text",
  },
  {
    label: "Sent Date",
    value: "email:sent_date",
    category: "Email",
    editor_type: "datetime",
    schema: z.string().date(),
  },
  {
    label: "Received Date",
    value: "email:received_date",
    category: "Email",
    editor_type: "datetime",
    schema: z.string().date(),
  },
  {
    label: "Message ID",
    value: "email:message_id",
    category: "Email",
    editor_type: "text",
    schema: z.string(),
  },
  {
    label: "Full Name",
    value: "user:full_name",
    category: "User",
    schema: z.string(),
  },
  {
    label: "First Name",
    value: "user:first_name",
    category: "User",
    schema: z.string(),
  },
  {
    label: "Last Name",
    value: "user:last_name",
    category: "User",
    schema: z.string(),
  },
  {
    label: "Middle Name",
    value: "user:middle_name",
    category: "User",
    schema: z.string(),
  },
  {
    label: "URL",
    value: "internet_history:history",
    category: "Internet History",
    schema: z.string(),
  },
  {
    label: "Title",
    value: "internet_history:title",
    category: "Internet History",
    schema: z.string(),
  },
  {
    label: "Browser",
    value: "internet_history:browser",
    category: "Internet History",
    schema: z.string(),
  },
  {
    label: "Visit Time",
    value: "internet_history:visit_time",
    category: "Internet History",
    schema: z.string().datetime(),
    editor_type: "datetime",
  },
  {
    label: "Visit Count",
    value: "internet_history:visit_count",
    category: "Internet History",
    schema: z.string().regex(/^\d+$/),
    editor_type: "number",
    min: 0,
  },
  {
    label: "Target Path",
    value: "lnk:target_path",
    category: "LNK",
    schema: z.string(),
  },
  {
    label: "Text",
    value: "text",
    category: "Other",
    schema: z.string(),
  },
];

export default TraceAttributeTypes;
