import React, { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { create } from "jss";
import { ThemeProvider } from "styled-components/macro";

import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import AdapterMoment from "@mui/lab/AdapterMoment";

import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useSettings from "./hooks/useSettings";

import config from "devextreme/core/config";
import "devextreme/dist/css/dx.common.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import themes from "devextreme/ui/themes";
import { DevExtremeMap } from "./constants";
import AppSearchBox from "./components/AppSearchBox/AppSearchBox";
import { BarcodeScannerProvider } from "./components/BarcodeScanner";
import { SocketProvider } from "./contexts/SocketContext.js";
import FileUploader, { FileUploaderProvider } from "./contexts/FileUploader.js";
import { useSessionExpiration } from "./hooks/useSessionExpiration.js";
import MonolithDeploymentAlerts from "./pages/Alerts/MonolithDeploymentAlerts.js";
import { MonolithUIProvider } from "@monolith-forensics/monolith-ui";

config({
  editorStylingMode: "filled",
});

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

function App() {
  useSessionExpiration();
  const content = useRoutes(routes);

  const { theme } = useSettings();

  useEffect(() => {
    themes.current(DevExtremeMap[theme]);
  }, [theme]);

  useEffect(() => {
    if (process.env.REACT_APP_PLATFORM === "electron") {
      window.addEventListener("keydown", (e) => {
        if (e.ctrlKey && e.key === ".") {
          window.electronAPI.openDevTools();
        }
      });
    }
  }, []);

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | Monolith"
        defaultTitle="Monolith - Digital Forensics Case Management"
      />
      <StylesProvider jss={jss}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={createTheme(theme)}>
              <ThemeProvider theme={createTheme(theme)}>
                <BarcodeScannerProvider>
                  <SocketProvider>
                    <FileUploaderProvider>
                      <FileUploader />
                      <AppSearchBox>
                        <MonolithDeploymentAlerts />
                        <MonolithUIProvider colorScheme={theme}>
                          <>{content}</>
                        </MonolithUIProvider>
                      </AppSearchBox>
                    </FileUploaderProvider>
                  </SocketProvider>
                </BarcodeScannerProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </StylesProvider>
    </HelmetProvider>
  );
}

export default App;
