import { Card, IconButton, Tooltip } from "@mui/material";
import { DataGrid, Column } from "devextreme-react/ui/data-grid";
import { useRef } from "react";

import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import { getDateFormat } from "../../../../utils/date-format";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getTimeEntryReport } from "../../../../api/index.js";
import Loader from "../../../../components/Loader.js";

export const TimeEntries = ({ ref, timeEntry, reportInfo }) => {
  const timeEntriesGrid = useRef(null);
  const queryClient = useQueryClient();

  return (
    <Card
      style={{
        padding: "20px",
        marginBottom: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            fontSize: "larger",
          }}
        >
          Time Entries
        </div>
        <div style={{ marginLeft: "auto" }}>
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              marginRight: 10,
            }}
          >
            <Tooltip arrow title="Export Grid">
              <IconButton
                color="primary"
                size="small"
                disableRipple={false}
                onClick={(e) => {
                  timeEntriesGrid.current.instance.exportToExcel();
                }}
              >
                <FileDownloadOutlinedIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Refresh">
              <IconButton
                color="primary"
                size="small"
                disableRipple={false}
                onClick={(e) => {
                  queryClient.refetchQueries({
                    queryKey: [
                      "reports:time-entries-overview:entries",
                      reportInfo.report_parameters,
                    ],
                  });
                }}
              >
                <SyncOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Column Select">
              <IconButton
                color="primary"
                size="small"
                disableRipple={false}
                onClick={(e) => {
                  timeEntriesGrid.current.instance.showColumnChooser();
                }}
              >
                <ViewColumnOutlinedIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      <Table timeEntriesGrid={timeEntriesGrid} reportInfo={reportInfo} />
    </Card>
  );
};

const Table = ({ timeEntriesGrid, reportInfo }) => {
  const { isLoading, data: timeEntry } = useQuery({
    queryKey: [
      "reports:time-entries-overview:entries",
      reportInfo.report_parameters,
    ],
    queryFn: () => getTimeEntryReport(reportInfo.report_parameters),
  });

  if (isLoading)
    return (
      <div style={{ marginTop: 30 }}>
        <Loader message="Retrieving Data..." />
      </div>
    );

  return (
    <DataGrid
      ref={timeEntriesGrid}
      dataSource={timeEntry}
      showRowLines={true}
      columnWidth={150}
      columnMinWidth={150}
      height={350}
      width="100%"
      columnResizingMode="widget"
      allowColumnReordering={false}
      allowColumnResizing={true}
      grouping={{ contextMenuEnabled: true }}
      toolbar={{ visible: false }}
      columnChooser={{
        enabled: true,
        mode: "select",
      }}
      paging={{ pageSize: 20 }}
      scrolling={{
        mode: "virtual",
        rowRenderingMode: "virtual",
        useNative: false,
      }}
      headerFilter={{
        visible: true,
        allowSearch: true,
      }}
      hoverStateEnabled={true}
      loadPanel={{
        enabled: false,
      }}
    >
      <Column
        caption="Case Number"
        dataField="case_number"
        alignment={"left"}
      />
      <Column caption="Case Name" dataField="case_ref" alignment={"left"} />
      <Column
        caption="Organization"
        dataField="client_organization"
        alignment={"left"}
      />
      <Column
        caption="Client Name"
        dataField="client_name"
        alignment={"left"}
        visible={false}
      />
      <Column caption="Category" dataField="category_name" alignment={"left"} />
      <Column caption="User" dataField="full_name" alignment={"left"} />
      <Column
        caption="Invoiced"
        dataField="invoiced"
        alignment={"left"}
        visible={false}
      />
      <Column
        caption="Invoice Date"
        dataField="invoice_date"
        alignment={"left"}
        dataType={"date"}
        format={{
          type: getDateFormat({
            isMoment: false,
            includeTime: true,
          }),
        }}
      />
      <Column
        caption="Entry Date"
        dataField="entry_date"
        alignment={"left"}
        dataType={"date"}
        format={{
          type: getDateFormat({
            isMoment: false,
            includeTime: true,
          }),
        }}
      />
      <Column
        caption="Duration (hrs)"
        dataField="duration"
        alignment={"left"}
        calculateSortValue="duration"
        calculateCellValue={(data) => (data.duration / (60 * 60)).toFixed(2)}
      />
      <Column
        caption="Start Time"
        dataField="start_time"
        alignment={"left"}
        dataType={"date"}
        format={{
          type: getDateFormat({
            isMoment: false,
            includeTime: true,
          }),
        }}
        visible={false}
      />
      <Column
        caption="End Time"
        dataField="end_time"
        alignment={"left"}
        dataType={"date"}
        format={{
          type: getDateFormat({
            isMoment: false,
            includeTime: true,
          }),
        }}
        visible={false}
      />
      <Column caption="Task Name" dataField="task_name" alignment={"left"} />
      <Column
        caption="Description"
        dataField="description"
        alignment={"left"}
        visible={false}
      />
    </DataGrid>
  );
};
