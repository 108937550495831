import { FC } from "react";
import { Modal } from "@monolith-forensics/monolith-ui";
import { CreateContactForm } from "../Forms";

interface CreateContactModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onCancel: () => void;
  caseId?: number;
}

const CreateContactModal: FC<CreateContactModalProps> = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  caseId,
}) => {
  const handleSubmit = async () => {
    onSubmit?.();
    handleClose();
  };
  const handleClose = () => {
    onClose();
  };
  const handleCancel = () => {
    onCancel?.();
  };

  return (
    <Modal open={show} onClose={onClose}>
      <CreateContactForm
        caseId={caseId}
        width={550}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </Modal>
  );
};

export default CreateContactModal;
