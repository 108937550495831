import { Link } from "react-router-dom";
import styled from "styled-components";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { getDateFormat } from "../../utils/date-format";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { usePermissions } from "../../hooks/usePermissions";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import ToolBarItems from "../ToolBarItems.js";
import { Modal, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import MonolithReportsAPI from "../../api/reports/index.js";
import DropdownMenu from "../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import { TrashIcon } from "lucide-react";

const TableDefs = [
  {
    dataField: "actions",
    caption: "",
    width: 50,
    allowSorting: false,
    allowReorder: false,
    showInColumnChooser: false,
    render: (rowData) => <ActionCell rowData={rowData} />,
  },
  {
    dataField: "name",
    caption: "Report Name",
    render: (rowData) => <ReportNameComponent rowData={rowData} />,
  },
  {
    dataField: "report_id",
    caption: "Report ID",
    visible: false,
  },
  {
    dataField: "category",
    caption: "Category",
  },
  {
    dataField: "created_by",
    caption: "Created By",
  },
  {
    dataField: "created_on",
    caption: "Created On",
    dataType: "date",
    format: {
      type: getDateFormat({ isMoment: true, includeTime: false }),
    },
  },
];

const ActionCell = ({ rowData }) => {
  const { hasPermission } = usePermissions();
  const queryClient = useQueryClient();

  const [showEditModel, setShowEditModel] = useState(false);
  const [showDeleteModel, setShowDeleteModel] = useState(false);

  const handleItemSelect = (item) => {
    switch (item.text) {
      case "Edit":
        setShowEditModel(true);
        break;
      case "Delete":
        setShowDeleteModel(true);
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    queryClient.refetchQueries({
      queryKey: ["metrics-reports:list"],
      type: "active",
    });
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "left" }}>
        <DropdownMenu
          menuItems={[
            {
              label: "Delete",
              icon: TrashIcon,
              iconColor: "orangered",
              onClick: () => setShowDeleteModel(true),
            },
          ]}
          title={"Actions"}
          variant="outlined"
          // arrow
          disabled={!hasPermission()}
        >
          <MoreHorizOutlinedIcon style={{ fontSize: 13 }} />
        </DropdownMenu>
      </div>
      <DeleteModal
        open={showDeleteModel}
        handleClose={() => setShowDeleteModel(false)}
        onSubmit={() => handleSubmit()}
        defaultInfo={rowData}
      />
    </>
  );
};

const DeleteModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    MonolithReportsAPI.deleteMonolithReport({
      report_id: defaultInfo.report_id,
    }).then(() => {
      enqueueSnackbar(`Report Deleted.`, {
        variant: "success",
      });
      onSubmit();
    });
    handleClose();
  };

  const handleCancel = () => handleClose();

  return (
    <div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        style={{ zIndex: 1400 }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 400,
            backgroundColor: theme.palette.background.default,
            position: "absolute",
            left: "50%",
            top: "20%",
            transform: "translate(-35%, -20%)",
            padding: 20,
            outline: "none",
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Delete Metrics Report
          </div>
          <div style={{ margin: "10px 0px" }}>
            Are you sure you want to delete this Monolith Report?
          </div>
          <div
            style={{
              marginTop: 30,
            }}
          >
            <ToolBarItems
              submitText="Delete Report"
              submitColor="error"
              onSubmit={handleSubmit}
              onCancel={handleCancel}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

const ReportNameComponent = styled(({ className, rowData }) => {
  return (
    <Link className={className} to={`${rowData.report_id}`}>
      <div
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div>{rowData.name}</div>
        <LaunchOutlinedIcon
          className="action-button"
          style={{ fontSize: 13, marginLeft: 5 }}
        />
      </div>
    </Link>
  );
})`
  color: ${({ theme }) => theme.palette.primary.main};
  width: fit-content;
  &:hover {
    text-decoration: underline;
  }
`;

export default TableDefs.slice();
