import { Modal, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import ToolBarItems from "../../../components/ToolBarItems.js";
import styled from "styled-components";
import CaseFileAPI from "../../../api/CaseFiles/index.js";

const DeleteModal = styled(
  ({
    className,
    open,
    handleClose = () => {},
    onSubmit = () => {},
    defaultInfo = {},
  }) => {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = () => {
      CaseFileAPI.deleteItem({
        uuid: defaultInfo.map((i) => i.uuid),
        case_id: defaultInfo[0].case_id,
      }).then((res) => {
        enqueueSnackbar("Item deleted successfully", { variant: "success" });
        onSubmit();
      });
      handleClose();
    };

    const handleCancel = () => handleClose();

    return (
      <Modal
        className={className}
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        style={{ zIndex: 1400 }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 400,
            backgroundColor: theme.palette.background.default,
            position: "absolute",
            left: "50%",
            top: "25%",
            transform: "translate(-35%, -25%)",
            padding: 20,
            outline: "none",
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Delete Items
          </div>
          {defaultInfo.length > 1 && (
            <div style={{ margin: "10px 0px" }}>
              Are you sure you want to delete these {defaultInfo.length} items?
            </div>
          )}
          {defaultInfo.length === 1 && (
            <div style={{ margin: "10px 0px" }}>
              Are you sure you want to delete this item?
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 15,
              maxHeight: 200,
              overflowY: "auto",
            }}
          >
            {defaultInfo.map((item) => (
              <div
                key={item.uuid}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 5,
                }}
              >
                <div
                  style={{
                    width: 50,
                    fontWeight: "bold",
                    color: theme.palette.text.secondary,
                  }}
                >
                  {item.type === "Folder" ? "Folder" : "File"}
                </div>
                <div
                  style={{
                    flex: 1,
                    fontWeight: 500,
                    color: theme.palette.text.primary,
                    maxWidth: 300,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {item.file_name}
                </div>
              </div>
            ))}
          </div>

          <div
            style={{
              marginTop: 30,
            }}
          >
            <ToolBarItems
              submitText={"Delete"}
              submitColor="error"
              onSubmit={handleSubmit}
              onCancel={handleCancel}
            />
          </div>
        </div>
      </Modal>
    );
  }
)`
  .detail-container {
    display: flex;
    margin-bottom: 5px;
    font-size: 12px;
  }
  .detail-label {
    width: 50px;
    font-weight: bold;
    color: ${(props) => props.theme.palette.text.secondary};
  }
  .detail-value {
    flex: 1;
    font-weight: 500;
    color: ${(props) => props.theme.palette.text.primary};
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export default DeleteModal;
