import Chart from "react-apexcharts";

import Loader from "../../../../components/Loader";
import { useQuery } from "@tanstack/react-query";
import MetricsAPI from "../../../../api/Metrics/Metrics.js";
import styled, { useTheme } from "styled-components";
import CasesApi from "../../../../api/cases/index.js";
import getRandomColor from "../../../../utils/getRandomColor.js";

const CaseTypesPerUserWidget = styled(({ className }) => {
  const theme = useTheme();
  const { data: metricsData } = useQuery({
    queryKey: ["caseTypesPerUser"],
    queryFn: () => MetricsAPI.getMetrics({ metric: "caseTypesPerUser" }),
  });

  const { data: caseStatuses } = useQuery({
    queryKey: ["caseStatuses"],
    queryFn: () => CasesApi.getCaseStatuses(),
  });

  if (!(metricsData && caseStatuses)) return <Loader />;

  const caseLeadData = metricsData?.data?.caseTypesPerUser || [];

  const statusNames = caseStatuses
    .map((status) => status.status_name)
    .filter((status) => status !== "Closed");

  const seriesData = statusNames.map((status) => ({
    name: status,
    data: caseLeadData.map((d) => d.case_status_totals[status] || 0),
  }));

  const colors = caseStatuses.map((status) =>
    getRandomColor(
      status.status_name,
      status.case_status_id,
      theme.name === "DARK" ? "dark" : "bright"
    )
  );

  const categories = caseLeadData.map((d) => d.full_name);

  return (
    <div className={className}>
      <Chart
        options={{
          colors,
          chart: {
            animations: {
              enabled: false,
            },
            stacked: true,
            background: "unset",
            toolbar: {
              show: false,
            },
            events: {
              dataPointSelection: function (event, chartContext, config) {
                console.log(event, chartContext, config);
              },
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              dataLabels: {
                position: "bottom", // top, center, bottom
              },
            },
          },
          theme: {
            mode: theme.name === "DARK" ? "dark" : "light",
            palette: "palette8",
          },
          fill: {
            colors,
            type: "solid",
            opacity: theme.name === "DARK" ? 0.33 : 1,
          },
          stroke: {
            colors: [theme.palette.primary.main + "90"],
            width: 1,
          },
          dataLabels: {
            enabled: true,
            textAnchor: "start",
            style: {
              fontSize: "12px",
              fontWeight: "bold",
              colors: ["#fff"],
            },
          },
          grid: {
            show: false,
          },
          yaxis: {
            labels: {
              show: true,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          xaxis: {
            categories,
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
            },
          },
          legend: {
            position: "top",
            horizontalAlign: "left",
            offsetX: 40,
          },
        }}
        type="bar"
        height={"100%"}
        series={seriesData}
      />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export default CaseTypesPerUserWidget;
