import InquiryColumnDefs from "../../components/Inquiries/InquiryColumnDefs.js";
import { useState, useRef, useEffect, useMemo } from "react";
import { usePermissions } from "../../hooks/usePermissions";
import { useSnackbar } from "notistack";
import {
  useInfiniteQuery,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { Button, Modal, Typography, useTheme } from "@mui/material";
import Table, { Column, useTable } from "../../Monolith-UI/Table/Table.js";
import InquiryFilterDimensions from "../../components/Inquiries/InquiryFilterDimensions.js";
import InquiriesAPI from "../../api/inquiries/index.js";
import { addCustomFieldsToColumnDefs } from "../../components/Inquiries/CustomFieldComponent.js";
import {
  db_timestamp,
  getDateFormat,
  monolithMoment,
} from "../../utils/date-format";
import { useQueryFilter } from "../../Monolith-UI/QueryFilter/QueryFilter.js";
import { Helmet } from "react-helmet-async";
import ComboButton from "../../Monolith-UI/ComboButton/ComboButton.js";

//Icons
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";
import styled from "styled-components";
import Flyout, { FlyoutHeader } from "../../Monolith-UI/Flyout/Flyout.js";
import TaskButton from "../../Monolith-UI/TaskButton.js";
import Loader from "../../components/Loader.js";
import { nanoid } from "nanoid";
import { Link, useNavigate } from "react-router-dom";
import ToolBarItems from "../../components/ToolBarItems.js";
import { useAuth } from "../../contexts/AuthContext.js";
import ClientsAPI from "../../api/clients/index.js";
import CasesApi from "../../api/cases/index.js";
import {
  Form,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from "devextreme-react/ui/form.js";
import { Template } from "devextreme-react/core/template";
import ClientSelectBoxItem from "../../components/SelectBoxItems/ClientSelectBoxItem.js";
import { DevexEditors } from "../../utils/devex-editors.js";
import DataSource from "devextreme/data/data_source";
import synchronizeColumnState from "../../utils/synchronize-column-state.js";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { ItemTotal } from "../Cases/CaseEvidence/index";
import { SendIcon } from "lucide-react";
import { Input } from "@monolith-forensics/monolith-ui";
import AccessDeniedMessage from "../../components/AccessDeniedMessage.js";
import CustomAttribute from "@/types/CustomAttribute.js";
import { CustomField } from "../Settings/CustomFieldSettings/types.js";
import {
  ColumnProps,
  PageData,
  TableColumn,
} from "@/Monolith-UI/Table/types/Table.js";
import { ColumnResize, Condition } from "../Cases/types/Cases.js";

interface QueryFilterRef {
  clear: () => void;
}

interface InquiryQuery {
  query: {
    conditions?: Condition[];
    page?: number;
    pageSize?: number;
    search?: string | null;
    order?: {
      field: string;
      sort: string;
    } | null;
  };
}

export interface Inquiry {
  case_id: number;
  case_number: string;
  case_ref: string;
  client_id: number;
  custom_attributes: CustomAttribute[];
  entered_by: string;
  evidence: string;
  evidence_count: number;
  inquiry_address: string;
  inquiry_case_num: string;
  inquiry_case_ref: string;
  inquiry_city: string;
  inquiry_client_type: string;
  inquiry_convert_date: string;
  inquiry_date: string;
  inquiry_email: string;
  inquiry_id: number;
  inquiry_mobile: string;
  inquiry_name: string;
  inquiry_notes: string;
  inquiry_org: string;
  inquiry_phone: string;
  inquiry_referred: string;
  inquiry_state: string;
  inquiry_status: string;
  inquiry_type: string;
  inquiry_user: number;
  inquiry_zipcode: string;
  is_client: string;
  item_id: number;
  office_id: number;
  online_form_id: string;
  people: string;
  previous_client: number;
  request_id: string;
  request_name: string;
  uuid: string;
}

const defaultQueryKey = "inquiries:list";

const columnDefs = InquiryColumnDefs;

const FlyoutContent = styled(
  ({ className, defaultInfo, onDelete = () => {}, setIsFlyoutVisible }) => {
    const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
    const theme = useTheme();
    const [showDeleteModel, setShowDeleteModel] = useState(false);

    const { data: itemInfo } = useQuery({
      queryKey: ["inquiry", { inquiry_id: defaultInfo.inquiry_id }],
      queryFn: () =>
        InquiriesAPI.getInquiries({ inquiry_id: defaultInfo.inquiry_id }),
      initialData: [defaultInfo],
      select: (data) => {
        return data[0];
      },
    });

    const handleDelete = () => {
      setIsFlyoutVisible(false);
      onDelete(itemInfo);
    };

    const dataFieldIgnoreList = ["inquiry_notes"];

    const data = {
      ...itemInfo,
      ...(itemInfo?.custom_attributes?.reduce(
        (
          acc: { [key: string]: string | number | string[] },
          attr: CustomAttribute
        ) => {
          acc[`custom_field_${attr.field_id}`] = attr.value;
          return acc;
        },
        {}
      ) || {}),
    };

    const detail = (
      <div className="detail-container">
        {columnDefs
          .filter((c) => !dataFieldIgnoreList.includes(c.dataField))
          .filter((c) =>
            c.dataField.includes("custom_field_") ? !!data[c.dataField] : true
          )
          .map((c) => {
            return (
              <div className="detail-item" key={nanoid()}>
                <div className="detail-label">{c.caption}</div>
                {c.render ? (
                  c.render(data)
                ) : c.dataType === "date" ? (
                  <>
                    {monolithMoment({
                      timestamp: data[c.dataField],
                      includeTime: true,
                    })}
                  </>
                ) : (
                  <div className="detail-value">{data[c.dataField]}</div>
                )}
              </div>
            );
          })}
      </div>
    );

    return (
      <div className={className}>
        <div className="action-menu">
          <Link
            className={className}
            to={`/inquiries/${itemInfo.inquiry_id}`}
            style={{ padding: 0, margin: 0 }}
          >
            <div className="action-menu-item">
              <LaunchOutlinedIcon
                style={{ color: theme.palette.primary.main }}
              />
              <div className="action-menu-label">View Inquiry</div>
            </div>
          </Link>
          {hasPermission(MONOLITH_PERMISSIONS.INQUIRIES_DELETE) && (
            <>
              <div
                className="action-menu-item"
                onClick={() => setShowDeleteModel(true)}
              >
                <DeleteOutlineOutlinedIcon
                  style={{ color: theme.palette.error.main }}
                />
                <div className="action-menu-label">Delete</div>
              </div>
            </>
          )}
        </div>
        {detail}
        <div className="detail-label" style={{ marginTop: 30 }}>
          Description
        </div>
        <div style={{ position: "relative" }}>
          <div className="description">{itemInfo.inquiry_notes}</div>
        </div>
        <DeleteModal
          open={showDeleteModel}
          handleClose={() => setShowDeleteModel(false)}
          onSubmit={() => handleDelete()}
          defaultInfo={itemInfo}
        />
      </div>
    );
  }
)`
  padding: 20px;

  & {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    margin-right: 5px;
  }
  & .detail-container {
  }
  & .action-menu {
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 25px;
    margin-right: 10px;
  }
  & .action-menu-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin-right: 12px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
    & svg {
      font-size: 15px;
      margin-right: 5px;
    }
  }
  & .detail-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 10px 0px;
    font-size: 12px;
  }
  & .detail-label {
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-right: 10px;
    min-width: 150px;
  }
  & .detail-value {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  & .description {
    position: relative;
    resize: none;
    margin-top: 10px;
    font-size: 12px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: transparent;
    white-space: pre-wrap;
    width: 100%;
    height: fit-content;
    max-height: 30%;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 10px;
    transition: border 0.2s ease-in-out;
    outline: none;
    &:hover {
      border: 1px solid ${({ theme }) => theme.palette.divider};
    }
    &:focus {
      border: 1px solid ${({ theme }) => theme.palette.primary.main};
    }
  }
  & .description-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.palette.divider};
    display: flex;
    alignitems: center;
    fontsize: 12px;
    padding: 10px;
  }
`;

const CreateInquiryPopup = ({
  setIsCreating,
  open,
  onSubmit = () => {},
  handleClose = () => {},
}: {
  setIsCreating: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  onSubmit: (result: Inquiry) => void;
  handleClose: () => void;
}) => {
  const theme = useTheme();
  const form = useRef<any>(null);
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const result = useQueries({
    queries: [
      {
        queryKey: ["clients:list", { isContact: 0 }],
        queryFn: () => ClientsAPI.getClients({ isContact: 0 }),
        enabled: open,
      },
      {
        queryKey: ["cases:types"],
        queryFn: () => CasesApi.getCaseTypes(),
        enabled: open,
      },
      {
        queryKey: ["inquiries:custom_fields", { fieldsOnly: true }],
        queryFn: () => InquiriesAPI.getCustomFields({ fieldsOnly: true }),
        enabled: open,
        select: (data: CustomField[]) =>
          data?.filter((c: CustomField) => c.enabled === 1),
      },
    ],
  });

  const handleSubmit = () => {
    if (form.current.instance.validate().isValid) {
      let formData = { ...form.current.instance.option().formData };

      handleClose();
      setIsCreating(true);

      //Get custom attributes from updateData object
      //Compile them into a single object array with related attrubite values
      //remove original form items from form data
      let customAttributes = [];

      for (let key of Object.keys(formData)) {
        if (key.includes("custom_attribute_")) {
          customAttributes.push({
            field_id: parseInt(key.replace("custom_attribute_", "")),
            value: formData[key],
          });

          delete formData[key];
        }
      }

      if (customAttributes.length > 0)
        formData.custom_attributes = customAttributes;

      formData.inquiry_date = db_timestamp();
      formData.inquiry_user = currentUser.user_id;

      InquiriesAPI.createInquiry({
        ...formData,
        client_id: formData.client.client_id,
        inquiry_name: formData.client.name,
        inquiry_email: formData.client.email,
        inquiry_org: formData.client.organization,
      }).then((result) => {
        enqueueSnackbar(`Inquiry Created.`, {
          variant: "success",
        });

        onSubmit(result);
      });
    }
  };

  const isDone = result.every((item) => item.isFetched);

  const fieldData = {
    clients: result[0]?.data || [],
    caseTypes: result[1]?.data || [],
    customFields: result[2]?.data || [],
  };

  return (
    <>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        style={{ zIndex: 1400, overflowY: "auto" }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 800,
            maxHeight: "calc(100vh - 100px)",
            backgroundColor: theme.palette.background.default,
            position: "fixed",
            left: (window.innerWidth - 800) / 2,
            top: 50,
            padding: 20,
            outline: "none",
            overflowY: "auto",
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Create Inquiry
          </div>
          {!isDone && <Loader />}
          {isDone && (
            <>
              <div
                style={{
                  height: "100%",
                  overflowY: "auto",
                }}
              >
                <Form ref={form} colCount={2} style={{ marginRight: 5 }}>
                  <SimpleItem
                    dataField="request_name"
                    label={{ text: "Request Name" }}
                    editorOptions={{
                      placeholder: "Enter a name for this inquiry...",
                    }}
                  />
                  <SimpleItem
                    dataField="client"
                    label={{ text: "Client" }}
                    isRequired={true}
                    editorType="dxSelectBox"
                    editorOptions={{
                      dataSource: new DataSource({
                        store: fieldData.clients,
                        paginate: true,
                        pageSize: 10,
                      }),
                      onInitialized: () => {},
                      displayExpr: "name",
                      searchEnabled: true,
                      searchExpr: "name",
                      searchMode: "startsWith",
                      placeholder: "Select or Create Client...",
                      dropDownOptions: {
                        maxHeight: 250,
                      },
                      hint: "Select a client or type a name to create a new client.",
                      acceptCustomValue: true,
                      onCustomItemCreating: (e: any) => {
                        e.customItem = {
                          name: e.text,
                          client_id: null,
                          email: null,
                          organization: null,
                        };
                      },
                      itemTemplate: "client",
                    }}
                  />
                  <Template
                    name="client"
                    render={(data: any) => <ClientSelectBoxItem data={data} />}
                  />
                  <SimpleItem
                    dataField="inquiry_type"
                    label={{ text: "Inquiry Type" }}
                    isRequired={true}
                    editorType="dxSelectBox"
                    editorOptions={{
                      dataSource: fieldData.caseTypes,
                      displayExpr: "case_type",
                      valueExpr: "case_type",
                      searchEnabled: true,
                      searchExpr: "case_type",
                      searchMode: "startsWith",
                      placeholder: "Select Case Type...",
                      dropDownOptions: {
                        maxHeight: 250,
                      },
                    }}
                  />
                  <SimpleItem
                    dataField="referred_by"
                    label={{ text: "Referred By" }}
                  />
                  <SimpleItem
                    dataField="inquiry_notes"
                    label={{ text: "Description" }}
                    isRequired={true}
                    editorType="dxTextArea"
                    colSpan={2}
                    editorOptions={{
                      height: 200,
                    }}
                  />
                  <GroupItem
                    caption="Custom Fields"
                    colSpan={2}
                    colCount={2}
                    visible={fieldData.customFields.length > 0}
                  >
                    {fieldData.customFields.map((attribute: CustomField) => {
                      return (
                        <SimpleItem
                          key={`custom_attribute_${attribute.field_id}`}
                          dataField={`custom_attribute_${attribute.field_id}`}
                          /* @ts-ignore */ //ignore dx types
                          editorType={DevexEditors[attribute.editor_type]}
                          label={{ text: attribute.field_name }}
                          editorOptions={{
                            placeholder: attribute.description || "",
                            hint: attribute.description || "",
                            items: JSON.parse(
                              (attribute.options as string) || "[]"
                            ),
                            dateSerializationFormat: "yyyy-MM-dd",
                            useMaskBehavior: true,
                            displayFormat: getDateFormat({
                              isMoment: false,
                              includeTime: false,
                            }),
                            showClearButton:
                              attribute.editor_type !== "textBox",
                            pickerType: "calendar",
                            showDropDownButton: true,
                            multiline: false,
                            showSelectionControls:
                              attribute.editor_type === "tagBox" ? true : false,
                          }}
                        >
                          {attribute.is_required === 1 && (
                            <RequiredRule message="" />
                          )}
                        </SimpleItem>
                      );
                    })}
                  </GroupItem>
                </Form>
              </div>
              <ToolBarItems
                style={{ paddingTop: "20px" }}
                submitText="Create Inquiry"
                onCancel={() => {
                  handleClose();
                }}
                onSubmit={handleSubmit}
              />
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

const DeleteModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}: {
  open: boolean;
  handleClose: () => void;
  onSubmit: () => void;
  defaultInfo: Inquiry;
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    InquiriesAPI.deleteInquiry(defaultInfo.inquiry_id).then(() => {
      enqueueSnackbar(`Inquiry Deleted.`, {
        variant: "success",
      });
      onSubmit();
    });
    handleClose();
  };

  const handleCancel = () => handleClose();

  return (
    <div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        style={{ zIndex: 1400 }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 400,
            backgroundColor: theme.palette.background.default,
            position: "absolute",
            left: "50%",
            top: "20%",
            transform: "translate(-35%, -20%)",
            padding: 20,
            outline: "none",
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Delete Inquiry
          </div>
          <div style={{ margin: "10px 0px" }}>
            Are you sure you want to delete this inquiry?
          </div>
          <div
            style={{ margin: "10px 0px", color: theme.palette.text.secondary }}
          >
            "
            {defaultInfo.request_name || "Inquiry ID " + defaultInfo.inquiry_id}
            "
          </div>
          <div
            style={{
              marginTop: 30,
            }}
          >
            <ToolBarItems
              submitText="Delete Inquiry"
              submitColor="error"
              onSubmit={handleSubmit}
              onCancel={handleCancel}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

const InquirySectionWrapper = styled(
  ({ className, stateStoreKey = "inquiries:view" }) => {
    const navigate = useNavigate();
    const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
    const { enqueueSnackbar } = useSnackbar();
    const editEvidencePopup = useRef(null);
    const deleteEvidencePopup = useRef(null);
    const queryClient = useQueryClient();
    const theme = useTheme();
    const queryFilter = useRef<QueryFilterRef>(null);
    const [isCreating, setIsCreating] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [pageSize, setPageSize] = useState(50);
    // using any for now since multi select is off
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const [flyoutData, setFlyoutData] = useState<Inquiry | null>(null);
    const [searchText, setSearchText] = useState(
      localStorage.getItem(`${stateStoreKey}:searchText`)
    );
    const [debouncedSearchText] = useDebounce(searchText, 500);

    const table = useTable();

    const [columnState, setColumnState] = useState(() => {
      return synchronizeColumnState(
        columnDefs,
        JSON.parse(localStorage.getItem(stateStoreKey) || "{}")
      );
    });

    let filterDimensions = InquiryFilterDimensions;

    const currentSort = useMemo(() => {
      let [sort] =
        columnState
          ?.filter((c) => c.sorting?.active)
          ?.map((c) => ({ field: c.dataField, sort: c.sorting?.direction })) ||
        [];

      return sort;
    }, [columnState]);

    const [query, setQuery] = useState<InquiryQuery | null>(null);

    const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } =
      useInfiniteQuery({
        queryKey: [
          defaultQueryKey,
          {
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
            },
          },
        ],
        queryFn: ({ pageParam }) =>
          InquiriesAPI.query({
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
              page: pageParam,
            },
          }),
        getNextPageParam: (lastPage) => {
          return lastPage.nextPage;
        },
        getPreviousPageParam: (firstPage) => {
          if (firstPage.page - 1 === 0) return null;
          return firstPage.page - 1;
        },
        initialPageParam: 1,
        enabled: !!query,
        placeholderData: (data) => data,
      });

    const records = data?.pages?.reduce((acc, page) => {
      return [...acc, ...page.data];
    }, []);

    const totalRecords = data?.pages?.[0]?.total || 0;

    const debouncedFetchNextPage = useDebouncedCallback(() => {
      fetchNextPage();
    }, 50);

    const { data: customFields } = useQuery({
      queryKey: ["inquries:customFields"],
      queryFn: () => InquiriesAPI.getCustomFields({ fieldsOnly: true }),
    });

    // update filter dimensions with custom fields
    filterDimensions = useMemo(() => {
      if (customFields) {
        //update filter dimensions
        return [
          ...InquiryFilterDimensions,
          ...customFields?.map((field: CustomField) => ({
            name: field.field_name,
            field: `custom_field_${field.field_id}`,
            id: `custom_field_${field.field_id}`,
            type:
              field.editor_type === "dateBox"
                ? "date"
                : field.editor_type === "dropDown" ||
                  field.editor_type === "tagBox"
                ? "string"
                : "text",
            mode:
              field.editor_type === "dateBox"
                ? "date"
                : field.editor_type === "dropDown" ||
                  field.editor_type === "tagBox"
                ? "multi-select"
                : "text",
            options: field.options
              ? JSON.parse(field.options as string).map((o: string) => ({
                  option: o,
                }))
              : null,
            selectionDisplayName: field.options ? "option" : null,
            selectionIdField: field.options ? "option" : null,
          })),
        ];
      }
      return InquiryFilterDimensions;
    }, [customFields]);

    const handleFilter = (newFilter: Condition) => {
      setQuery((q) => {
        return { query: { ...q?.query, ...newFilter, page: 1, pageSize } };
      });
    };
    const handleColumnVisibility = (
      column: ColumnProps<Inquiry>,
      visible: boolean
    ) => {
      setColumnState((cs) => {
        const currentColumn = cs.find((c) => c.dataField === column.dataField);
        if (currentColumn) {
          return cs.map((c) => {
            if (c.dataField === column.dataField) {
              return {
                ...c,
                visible,
              };
            }
            return c;
          });
        } else {
          return [
            ...cs,
            {
              ...column,
              visible,
            },
          ];
        }
      });
    };
    const handleExportTable = () => {
      // show snackbar
      enqueueSnackbar("Exporting inquiry table...", {
        variant: "info",
      });

      InquiriesAPI.exporInquiriesList({
        query: {
          ...query?.query,
          order: query?.query?.order || currentSort,
        },
        type: "xlsx",
        columns: columnState
          .filter((c) => c.visible !== false && c.showInColumnChooser !== false)
          .sort((a, b) => a.order - b.order)
          .map((c) => {
            return { dataField: c.dataField, header: c.caption, ...c };
          }),
        date_format: getDateFormat({ isMoment: true, includeTime: true }),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }).then((res) => {
        const { signedUrl, filename } = res;
        const el = document.createElement("a");
        el.href = signedUrl.replace(
          "http://localhost:3000",
          "http://localhost:3001"
        );

        el.download = filename;
        el.click();

        // remove element
        el.remove();

        // remove snackbar
      });
    };
    const handleClearFilters = () => {
      queryFilter.current && queryFilter.current.clear();
    };
    const handleSort = (field: string) => {
      const savedColumn =
        columnState?.find((svc) => field === svc.dataField) || {};
      const order = !!savedColumn ? savedColumn?.sorting?.direction : null;

      let newOrder = null;

      // if sorted on a different column, reset the sort
      if (query?.query?.order?.field !== field) {
        newOrder = {
          field,
          sort: "asc",
        };
      }

      // otherwise rotate the sort options on the current column
      else {
        switch (order) {
          case "asc":
            newOrder = {
              field,
              sort: "desc",
            };
            break;
          case "desc":
            newOrder = null;
            break;
          default:
            newOrder = {
              field,
              sort: "asc",
            };
        }
      }

      let newCols = columnState?.map((c) => {
        if (c.dataField === field) {
          return {
            ...c,
            sorting: {
              active: newOrder ? true : false,
              direction: newOrder?.sort,
            },
          };
        }
        delete c.sorting;
        return c;
      });

      let newQuery = {
        query: {
          ...query?.query,
          order: newOrder,
        },
      };

      setColumnState(newCols);

      setQuery(newQuery);
    };
    const handleColumnReorder = (
      newOrder: { column: string; order: number }[]
    ) => {
      setColumnState((cs) => {
        return newOrder.map((o) => {
          return {
            ...cs.find((c) => c.dataField === o.column),
            order: o.order,
          };
        });
      });
    };
    const handleColumnResize = (e: ColumnResize) => {
      setColumnState((cs) => {
        return cs.map((c) => {
          const col = e.columns.find((col) => col.dataField === c.dataField);
          if (col) {
            return {
              ...c,
              width: parseInt(col.width.replace(/px/g, "")),
            };
          }
          return c;
        });
      });
    };
    const handleActionButtonClick = (rowData: Inquiry) => {
      setIsFlyoutVisible(true);
      setFlyoutData(rowData);
    };
    const handleGetNextItem = (id: number) => {
      const current = records?.findIndex((i: Inquiry) => i.inquiry_id === id);
      const nextItem = records[current + 1] || records[0];

      setFlyoutData(nextItem);
    };
    const handleGetPrevItem = (id: number) => {
      const current = records?.findIndex((i: Inquiry) => i.inquiry_id === id);
      const prevItem = records[current - 1] || records[records.length - 1];

      setFlyoutData(prevItem);
    };
    const handleRowSelection = (rows: any[]) => {
      setSelectedRows(rows);
    };
    const onInquiryDeleted = (deletedItem: Inquiry) => {
      setIsFlyoutVisible(false);
      setFlyoutData(null);
      queryClient.setQueryData(
        [
          defaultQueryKey,
          {
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
            },
          },
        ],
        (data: PageData<Inquiry>) => {
          if (!data) return null;
          const newPages = data?.pages.map((page) => {
            page.data =
              page?.data?.filter((item) => item.uuid !== deletedItem.uuid) ||
              page.data;
            return page;
          });

          return {
            ...data,
            pages: newPages,
          };
        }
      );
    };
    const handleScroll = (e: React.MouseEvent) => {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      const pageLength = data?.pages?.length ? data?.pages?.length : 0;
      if (scrollHeight - scrollTop <= clientHeight + 100 * pageLength) {
        if (hasNextPage && !isFetchingNextPage) {
          debouncedFetchNextPage();
        }
      }
    };

    useEffect(() => {
      const localStorageItem = localStorage.getItem(stateStoreKey);
      let oldState = localStorageItem ? JSON.parse(localStorageItem) : {};
      localStorage.setItem(
        stateStoreKey,
        JSON.stringify({
          ...oldState,
          cols: columnState,
        })
      );
    }, [columnState]);

    useEffect(() => {
      if (!customFields) return;
      // Add custom fields to column defs
      setColumnState((cs) => {
        return addCustomFieldsToColumnDefs(customFields, cs);
      });
    }, [customFields]);

    const { queryButton, conditions } = useQueryFilter({
      dimensions: filterDimensions.sort((a, b) => a.name.localeCompare(b.name)),
      onQuerySet: handleFilter,
      queryFilter: queryFilter,
      stateStoring: {
        enabled: true,
        type: "localStorage",
        storageKey: stateStoreKey,
      },
    });

    useEffect(() => {
      setQuery((q) => ({
        query: {
          ...q?.query,
          search: debouncedSearchText ? debouncedSearchText : null,
          page: 1,
        },
      }));
    }, [debouncedSearchText]);

    return (
      <div className={className}>
        <Helmet title="Inquiries" />
        <Typography variant="h3" gutterBottom display="inline">
          Inquiries
        </Typography>
        <div
          style={{
            display: "flex",
            flex: "initial",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            marginBottom: 10,
            marginTop: 10,
          }}
        >
          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={!hasPermission(MONOLITH_PERMISSIONS.INQUIRIES_CREATE)}
            onClick={(e) => {
              setShowCreateModal(true);
            }}
            style={{
              minWidth: "fit-content",
              fontSize: 11,
              padding: "3px 6px",
            }}
          >
            + New Inquiry
          </Button>
          <div style={{ marginLeft: 10 }}>{queryButton}</div>
          <ItemTotal total={totalRecords || 0} Icon={SendIcon} />
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              minWidth: "fit-content",
            }}
          >
            <ComboButton
              type="multi-select"
              data={columnState.filter((c) => c.showInColumnChooser !== false)}
              displayField="caption"
              idField={"dataField"}
              selectedItems={columnState.filter((c) => c.visible !== false)}
              variant="outlined"
              closeOnSelect={false}
              showSearch={true}
              dropDownTitle={() => {
                return (
                  <div
                    style={{
                      margin: "5px 0px",
                      padding: 3,
                      color: theme.palette.text.secondary,
                      display: "flex",
                      alignItems: "center",
                      minWidth: 200,
                    }}
                  >
                    Select Columns
                  </div>
                );
              }}
              onItemDeSelect={(item) => {
                handleColumnVisibility(item, false);
              }}
              onItemSelect={(item) => {
                handleColumnVisibility(item, true);
              }}
              textColor={theme.palette.text.secondary}
              title={"Select Columns"}
            >
              <ViewColumnOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Export Table"}
              onClick={handleExportTable}
            >
              <FileDownloadOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Clear Filters"}
              onClick={handleClearFilters}
            >
              <FilterListOffIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={table.isCompact ? "Zoom In" : "Zoom Out"}
              onClick={() => table.toggleCompact()}
            >
              {table.isCompact && (
                <ZoomOutMapOutlinedIcon style={{ fontSize: 18 }} />
              )}
              {!table.isCompact && (
                <ZoomInMapOutlinedIcon style={{ fontSize: 18 }} />
              )}
            </ComboButton>
            <ComboButton
              type="button"
              variant={"outlined"}
              textColor={
                table.isStriped
                  ? theme.palette.primary.main
                  : theme.palette.text.secondary
              }
              title={table.isStriped ? "Hide Stripes" : "Show Stripes"}
              onClick={() => table.toggleStripes()}
            >
              <ReorderOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <Input
              placeholder="Search Inquiries"
              variant="outlined"
              height={30}
              defaultValue={searchText}
              onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                const currentValue = e.currentTarget.value.trim();
                setSearchText(currentValue);

                localStorage.setItem(
                  `${stateStoreKey}:searchText`,
                  currentValue
                );
              }}
              style={{
                marginLeft: "10px",
                border: searchText
                  ? `1px solid ${theme.palette.primary.main}`
                  : "",
              }}
            />
          </div>
        </div>
        {data && customFields && (
          <>
            {<div>{conditions}</div>}
            <Table
              data={records || []}
              totalRecords={totalRecords}
              keyValue="inquiry_id"
              tableInstance={table}
              columnProps={{ minWidth: 150, width: 150 }}
              onHeaderClick={(col: TableColumn<Inquiry>) =>
                col?.sorting?.enabled === false
                  ? null
                  : handleSort(col.dataField)
              }
              onScroll={handleScroll}
              onColumnReorder={handleColumnReorder}
              onColumnResize={handleColumnResize}
              onActionButtonClick={handleActionButtonClick}
              onSelectionChanged={handleRowSelection}
              selectedRows={selectedRows}
              showActionColumn={true}
              focusedRow={flyoutData}
            >
              {columnState.map((col) => {
                return <Column key={col.dataField} {...col} />;
              })}
            </Table>
            {isFlyoutVisible && flyoutData && (
              <Flyout
                onClose={() => {
                  setIsFlyoutVisible(false);
                  setFlyoutData(null);
                }}
              >
                <FlyoutHeader>
                  <div style={{ width: "100%" }}>
                    <h4
                      style={{
                        margin: "5px 0px",
                        color: theme.palette.text.primary,
                      }}
                    >
                      {flyoutData?.request_name ||
                        `Inquiry #${flyoutData?.inquiry_id}`}
                    </h4>
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          color: theme.palette.text.secondary,
                          fontSize: 12,
                        }}
                      >
                        Inquiry Details
                      </div>
                      <div
                        style={{
                          marginLeft: "auto",
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TaskButton
                          onClick={() =>
                            handleGetPrevItem(flyoutData?.inquiry_id ?? 0)
                          }
                          style={{
                            fontSize: 10,
                          }}
                          variant="outlined"
                        >
                          <KeyboardArrowLeftOutlinedIcon
                            style={{ fontSize: 17 }}
                          />
                        </TaskButton>
                        <TaskButton
                          onClick={() =>
                            handleGetNextItem(flyoutData?.inquiry_id ?? 0)
                          }
                          style={{
                            fontSize: 10,
                          }}
                          variant="outlined"
                        >
                          <KeyboardArrowRightOutlinedIcon
                            style={{ fontSize: 17 }}
                          />
                        </TaskButton>
                      </div>
                    </div>
                  </div>
                </FlyoutHeader>
                <FlyoutContent
                  defaultInfo={flyoutData}
                  editEvidencePopup={editEvidencePopup}
                  deleteEvidencePopup={deleteEvidencePopup}
                  onDelete={onInquiryDeleted}
                  setIsFlyoutVisible={setIsFlyoutVisible}
                />
              </Flyout>
            )}
          </>
        )}
        {!(data && customFields) && (
          <Loader message={"Retrieving Inquiries..."} />
        )}
        <CreateInquiryPopup
          setIsCreating={setIsCreating}
          open={showCreateModal}
          handleClose={() => setShowCreateModal(false)}
          onSubmit={(result: Inquiry) =>
            navigate(`/inquiries/${result.inquiry_id}`)
          }
        />
      </div>
    );
  }
)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const InquirySection = () => {
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();

  if (!hasPermission(MONOLITH_PERMISSIONS.INQUIRIES_READ_ALL))
    return <AccessDeniedMessage />;

  return <InquirySectionWrapper />;
};

export default InquirySection;
