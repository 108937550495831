import { useEffect, useRef, useState } from "react";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import {
  Button,
  CssBaseline,
  ThemeProvider,
  useTheme as useMuiTheme,
} from "@mui/material";
import logo from "../../monolith_logo_white.png"; // Tell Webpack this JS file uses this image
import GlobalStyle from "../../components/GlobalStyle.js";
import axios from "axios";
import createTheme from "../../theme/index.js";
import useSettings from "../../hooks/useSettings";
import themes from "devextreme/ui/themes";
import { DevExtremeMap } from "../../constants.js";
import config from "devextreme/core/config";
import "devextreme/dist/css/dx.common.css";

const regionDomainMap = {
  "North America": ["us", "ca"],
};

config({
  editorStylingMode: "filled",
});

const SelectApiMode = ({}) => {
  return (
    <ContentWrapper>
      <Content />
    </ContentWrapper>
  );
};

const Content = ({}) => {
  const [mode, setMode] = useState("Cloud");
  const [region, setRegion] = useState("North America");
  const theme = useMuiTheme();
  const form = useRef(null);
  const muiTheme = useMuiTheme();

  const handleCompleteSubmit = async ({ isValid }) => {
    if (!isValid) return;

    let formData = {
      ...form.current.instance.option("formData"),
    };

    mode.toLowerCase();

    window.electronAPI.setSettings("API_MODE", mode.toLowerCase());
    window.localStorage.setItem("API_MODE", mode.toLowerCase());

    window.electronAPI.setSettings("Monolith_Region", region.toLowerCase());
    window.localStorage.setItem("Monolith_Region", region.toLowerCase());

    switch (region) {
      case "North America":
        window.electronAPI.setSettings(
          "monolith-domain",
          "https://monolith-app.monolithforensics.com"
        );
        window.localStorage.setItem(
          "monolith-domain",
          "https://monolith-app.monolithforensics.com"
        );
        window.electronAPI.setSettings(
          "relay-domain",
          "https://relay-app.monolithforensics.com"
        );
        window.localStorage.setItem(
          "relay-domain",
          "https://relay-app.monolithforensics.com"
        );
        break;
      case "United Kingdom":
        window.electronAPI.setSettings(
          "monolith-domain",
          "https://monolith-app.monolithforensics.co.uk"
        );
        window.localStorage.setItem(
          "monolith-domain",
          "https://monolith-app.monolithforensics.co.uk"
        );
        window.electronAPI.setSettings(
          "relay-domain",
          "https://relay-app.monolithforensics.co.uk"
        );
        window.localStorage.setItem(
          "relay-domain",
          "https://relay-app.monolithforensics.co.uk"
        );
        break;
      default:
        break;
    }

    if (mode === "On-Premises") {
      if (await testHostIP(formData.api_host_ip)) {
        window.electronAPI.setSettings("API_HOST", formData.api_host_ip);
        window.localStorage.setItem("API_HOST", formData.api_host_ip);
        window.electronAPI.initSessionSettings();
        window.location.reload();
      } else {
        form.current.instance
          .getEditor("api_host_ip")
          .option("validationStatus", "invalid");
      }
      return;
    } else {
      // Load Auth0 Login
      window.location.reload();
    }
  };

  const handleSubmit = (e) => {
    const res = form.current.instance.validate();
    if (res.status === "pending") {
      res.complete.then((r) => {
        handleCompleteSubmit({ isValid: r.isValid });
      });
    } else {
      handleCompleteSubmit({ isValid: res.isValid });
    }
  };

  return (
    <div
      style={{
        width: 500,
        padding: "20px 60px",
        height: "auto",
        borderRadius: 5,
        backgroundColor: theme.palette.background.default,
        boxShadow: "none !important",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 25,
          }}
        >
          <img src={logo} height="85" alt="Monolith Forensics Logo"></img>
        </div>
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 25,
              color: muiTheme.palette.text.secondary,
              fontSize: "large",
            }}
          >
            Select API Mode
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px 0px",
            }}
          >
            <Form ref={form} width={"100%"}>
              <SimpleItem
                dataField="api_mode"
                label={{ text: "API Mode" }}
                isRequired={true}
                editorType="dxSelectBox"
                editorOptions={{
                  items: ["Cloud", "On-Premises"],
                  value: mode,
                  stylingMode: "filled",
                  onValueChanged: (e) => {
                    setMode(e.value);
                    window.electronAPI.setSettings(
                      "API_MODE",
                      e.value.toLowerCase()
                    );
                    window.localStorage.setItem(
                      "API_MODE",
                      e.value.toLowerCase()
                    );
                  },
                }}
              />
              <SimpleItem
                dataField="region"
                label={{ text: "Region" }}
                visible={mode === "Cloud"}
                isRequired={true}
                editorType="dxSelectBox"
                editorOptions={{
                  items: ["North America", "United Kingdom"],
                  value: region,
                  stylingMode: "filled",
                  onValueChanged: (e) => {
                    setRegion(e.value);
                    window.electronAPI.setSettings(
                      "Monolith_Region",
                      e.value.toLowerCase()
                    );
                    window.localStorage.setItem(
                      "Monolith_Region",
                      e.value.toLowerCase()
                    );
                  },
                }}
              />
              <SimpleItem
                dataField="api_host_ip"
                label={{ text: "Enter API Host Endpoint" }}
                visible={mode === "On-Premises"}
                editorOptions={{
                  stylingMode: "filled",
                  placeholder: "http://monolith_api_server_ip/api",
                }}
                validationRules={[
                  {
                    type: "required",
                  },
                  {
                    type: "async",
                    message: "Cannot reach API host endpoint",
                    validationCallback: (e) => {
                      return testHostIP(e.value);
                    },
                  },
                ]}
              />
            </Form>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px 0px",
            }}
          >
            <Button
              variant="contained"
              size="small"
              fullWidth
              style={{ marginTop: 20 }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </>
      </div>
    </div>
  );
};

const ContentWrapper = ({ children }) => {
  const { theme } = useSettings();

  useEffect(() => {
    themes.current(DevExtremeMap[theme]);
  }, [theme]);

  return (
    <ThemeProvider theme={createTheme(theme)}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "calc(100vh - 200px)",
          alignItems: "center",
        }}
      >
        <CssBaseline />
        <GlobalStyle />
        {children}
      </div>
    </ThemeProvider>
  );
};

export async function testHostIP(host) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(false);
    }, 20000);
    axios
      .get(`${host}/monolith-api-test`)
      .then((response) => {
        if (response.data.tenant_slug) {
          window.electronAPI.setSettings(
            "monolith.workspace",
            response.data.tenant_slug
          );
          resolve(response.status === 200);
        } else {
          resolve(false);
        }
      })
      .catch((error) => {
        console.trace(error);
        resolve(false);
      });
  });
}

export default SelectApiMode;
