import React, { useEffect, useRef, useState } from "react";
import { Card, Divider } from "@mui/material";
import { getAcquisitionReportMetrics } from "../../../../api";
import DropDownSelector from "../../../../components/DropDownSelector";
import DefaultPieChart from "../DefaultPieChart";
import Loader from "../../../../components/Loader";
import DefaultTextChart from "../DefaultTextChart";

const views = [
  {
    text: "Charts",
    value: 0,
  },
  {
    text: "Text",
    value: 1,
  },
];

const ReportComposition = ({ reportInfo }) => {
  const [metrics, setMetrics] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentView, setCurrentView] = useState(0);

  useEffect(() => {
    getAcquisitionReportMetrics({
      ...reportInfo.report_parameters,
      dimension: "composition",
    }).then((result) => {
      setMetrics(result);
      setIsLoading(false);
    });
  }, []);

  return (
    <Card
      style={{
        padding: "20px",
        marginBottom: "20px",
        height: "350px",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "larger",
            }}
          >
            Acquisitions Composition
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "10px" }}>
              <DropDownSelector
                items={views}
                useSelectMode={true}
                selectedItem={currentView}
                displayExpr="text"
                variant="contained"
                onSelect={(item) => {
                  setCurrentView(item.value);
                }}
              />
            </div>
          </div>
        </div>
        <Divider sx={{ my: 2 }} />
        {isLoading && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Loader />
          </div>
        )}
        {!isLoading && currentView === 0 && (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <DefaultPieChart
              data={metrics.type_comp}
              title="Types"
              valueExpr="type_name"
            />
            <DefaultPieChart
              data={metrics.format_comp}
              title="Formats"
              valueExpr="format_name"
            />
            <DefaultPieChart
              data={metrics.software_comp}
              title="Software"
              valueExpr="software_name"
            />
            <DefaultPieChart
              data={metrics.user_comp}
              title="Acquired By"
              valueExpr="acquired_by"
            />
          </div>
        )}
        {!isLoading && currentView === 1 && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              userSelect: "text",
            }}
          >
            <DefaultTextChart
              data={metrics.type_comp}
              title="Types"
              valueExpr="type_name"
            />
            <DefaultTextChart
              data={metrics.format_comp}
              title="Formats"
              valueExpr="format_name"
            />
            <DefaultTextChart
              data={metrics.software_comp}
              title="Software"
              valueExpr="software_name"
            />
            <DefaultTextChart
              data={metrics.user_comp}
              title="Acquired By"
              valueExpr="acquired_by"
            />
          </div>
        )}
      </div>
    </Card>
  );
};

export default ReportComposition;
