import { Button, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { createContact, getContacts, logActivity } from "../../../../api";
import { createAcquisition } from "../../../../api/acquisitions";
import Loader from "../../../../components/Loader";
import { usePermissions } from "../../../../hooks/usePermissions";

const ImportProgress = styled(
  ({
    className,
    caseInfo,
    evidenceInfo,
    csvData,
    columnNames,
    columnMap,
    preparedData,
  }) => {
    const { currentUser } = usePermissions();
    const navigate = useNavigate();
    const theme = useTheme();
    const [progress, setProgress] = useState({
      total: preparedData?.length || 0,
      completed: 0,
    });

    const [contactProgress, setContactProgress] = useState({
      total: 0,
      completed: 0,
    });

    const [importType, setImportType] = useState("");
    const [isImporting, setIsImporting] = useState(true);

    const handleAcquisitionImport = async () => {
      // Group data into sets of 5
      const dataSets = [];
      let set = [];
      preparedData.forEach((data, index) => {
        set.push(data);
        if (index % 5 === 0) {
          dataSets.push(set);
          set = [];
        }
      });

      // Add last set
      if (set.length > 0) dataSets.push(set);

      // loop through each set
      // for each set, create a promise that will add the data to the database
      // add the promise to an array
      // once all promises are created, run them in parallel
      // once all promises are complete, update the progress
      for (const dataSet of dataSets) {
        const promises = [];
        for (const data of dataSet) {
          const promise = createAcquisition(data);
          promises.push(promise);
        }
        await Promise.all(promises).then(() => {
          setProgress((progress) => ({
            ...progress,
            completed: progress.completed + dataSet.length,
          }));
        });
      }
    };

    const handleContactsImport = async () => {
      // Get Current Contacts in the case
      const contacts = await getContacts({
        case_id: caseInfo.case_id,
      });

      // Get linked contacts that were provided in CSV
      // remove any null or empty string values
      // remove any that already exist in the case
      // Group them by name so there are no duplicates
      const newImportedContacts = preparedData
        .map((d) => d.contact)
        .filter(
          (c) =>
            c !== null &&
            c !== undefined &&
            c !== "" &&
            contacts.find((contact) => contact.name === c) === undefined
        )
        .reduce((acc, cur) => {
          if (!acc.includes(cur)) acc.push(cur);
          return acc;
        }, []);

      setContactProgress((prev) => {
        return {
          ...prev,
          total: newImportedContacts.length,
        };
      });

      // Group data into sets of 5
      const dataSets = [];
      let set = [];
      newImportedContacts.forEach((data, index) => {
        set.push(data);
        if (index % 5 === 0) {
          dataSets.push(set);
          set = [];
        }
      });

      // Add last set
      if (set.length > 0) dataSets.push(set);

      // loop through each set
      // for each set, create a promise that will add the data to the database
      // add the promise to an array
      // once all promises are created, run them in parallel
      // once all promises are complete, update the progress
      for (const dataSet of dataSets) {
        const promises = [];
        for (const contact of dataSet) {
          const promise = createContact({
            name: contact,
            case_id: caseInfo.case_id,
          }).then((result) => {
            contacts.push({ name: contact, client_id: result.client_id });
          });
          promises.push(promise);
        }
        await Promise.all(promises).then(() => {
          setContactProgress((progress) => ({
            ...progress,
            completed: progress.completed + dataSet.length,
          }));
        });
      }

      // Link data to contact IDs
      preparedData.forEach((d) => {
        const contact = contacts.find((c) => c.name === d.contact);
        d.contact_id = contact?.client_id || null;
        delete d.contact;
      });
    };

    const runImport = async () => {
      setImportType("contacts");
      await handleContactsImport();

      setImportType("acquisitions");
      await handleAcquisitionImport();

      logActivity(
        caseInfo ? caseInfo.case_id : evidenceInfo.case_id,
        currentUser.user_id,
        `Imported ${progress.total} acquisitions from CSV.`
      );

      setIsImporting(false);
      preparedData = [];
    };

    useEffect(() => {
      if (preparedData) runImport();
      else navigate(`/cases/${caseInfo.case_id}/acquisitions?v=import`);
    }, []);

    if (!preparedData) return null;

    return (
      <div className={className}>
        <div className="progress-overlay"></div>
        <div className="progress-content">
          {isImporting ? (
            <>
              <div style={{ display: "flex" }}>
                <h2>Import Progress</h2>
                <Loader style={{ marginLeft: 20, width: 20, height: 20 }} />
              </div>
              {importType === "acquisitions" && (
                <>
                  <div>
                    Acquisitions are being imported, please do not navigate or
                    refresh the page.
                  </div>
                  <div style={{ marginTop: 20, textAlign: "center" }}>
                    {progress.completed} / {progress.total} Acquisitions
                    Imported
                  </div>
                  <div
                    style={{
                      position: "relative",
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: theme.palette.action.hover,
                        height: 10,
                        width: "100%",
                        borderRadius: 5,
                      }}
                    ></div>
                    <div
                      className="progress-bar"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        backgroundColor: theme.palette.primary.main,
                        height: 10,
                        width: `${
                          (parseInt(progress.completed) /
                            parseInt(progress.total)) *
                          100
                        }%`,
                        transition: "width 0.5s",
                        borderRadius: 5,
                      }}
                    ></div>
                  </div>
                </>
              )}
              {importType === "contacts" && (
                <>
                  <div>
                    Contacts are being imported, please do not navigate or
                    refresh the page.
                  </div>
                  <div style={{ marginTop: 20, textAlign: "center" }}>
                    {contactProgress.completed} / {contactProgress.total}{" "}
                    Contacts Imported
                  </div>
                  <div
                    style={{
                      position: "relative",
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: theme.palette.action.hover,
                        height: 10,
                        width: "100%",
                        borderRadius: 5,
                      }}
                    ></div>
                    <div
                      className="progress-bar"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        backgroundColor: theme.palette.primary.main,
                        height: 10,
                        width: `${
                          (parseInt(contactProgress.completed) /
                            parseInt(contactProgress.total)) *
                          100
                        }%`,
                        transition: "width 0.5s",
                        borderRadius: 5,
                      }}
                    ></div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div style={{ display: "flex" }}>
                <h2>Import Complete!</h2>
              </div>
              <div style={{ marginBottom: 20 }}>
                {contactProgress?.completed > 0 && (
                  <div>{contactProgress.completed} Contacts Imported</div>
                )}
                <div>{progress.completed} Acquisitions Imported</div>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() =>
                    navigate(`/cases/${caseInfo.case_id}/acquisitions`)
                  }
                >
                  View Acquisitions
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 750px;
  .progress-content {
    width: 400px;
    z-index: 2001;
  }
  .progress-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2000;
  }
`;

export default ImportProgress;
