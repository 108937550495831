import LoggingAPI from "@/api/logging";
import MonolithNotesAPI from "@/api/Notes";
import { usePermissions } from "@/hooks/usePermissions";
import { Task } from "@/pages/Tasks/types/Tasks";
import { Note } from "@/types";
import { monolithMoment } from "@/utils/date-format";
import {
  Button,
  Controls,
  Extensions,
  RichTextEditor,
  SlashCommands,
  TextInput,
} from "@monolith-forensics/monolith-ui";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ArrowLeftIcon, ArrowRightIcon } from "lucide-react";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import shortUUID from "short-uuid";
import styled from "styled-components";
import { useDebouncedCallback } from "use-debounce";

enum NoteView {
  NOTES_LIST = "notes-list",
  NOTE_EDITOR = "note-editor",
}

interface TaskNotesProps {
  task: Task;
}

interface NoteListItemProps {
  className?: string;
  note: Note;
  onClick?: (data: Note) => void;
}

interface NoteListProps {
  className?: string;
  task: Task;
  onNoteSelected: (note: Note) => void;
  search: string;
}

const NotesListItem = styled(
  ({ className, note, onClick }: NoteListItemProps) => {
    return (
      <div
        className={className + " notes-list-item"}
        onClick={() => onClick?.(note)}
      >
        <div className="note-title">{note.note_tag}</div>
        <div className="note-subtitle">{note.created_by.full_name}</div>
        <div
          className="note-subtitle timestamp"
          title={monolithMoment({
            timestamp: note.created_on,
            includeTime: true,
            includeZone: true,
          })}
        >
          {moment.utc(note.created_on).fromNow()}
        </div>
        <div className="view-note-alert">
          <ArrowRightIcon size={20} />
        </div>
      </div>
    );
  }
)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.background.default};
  border: 1px solid ${({ theme }) => theme.palette.input.border};

  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};

    .view-note-alert {
      display: flex;
    }
  }

  .timestamp {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .note-title {
    font-weight: 500;
    width: fit-content;
  }

  .note-subtitle {
    font-size: 12px;
    color: ${({ theme }) => theme.palette.text.secondary};
    width: fit-content;
  }

  .view-note-alert {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    display: none;
    align-items: center;
  }
`;

const NotesList = styled(
  ({ className, task, onNoteSelected, search }: NoteListProps) => {
    const [scrollActive, setScrollActive] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const { data } = useQuery({
      queryKey: [
        "task-notes",
        "list",
        {
          object_id: task.uuid,
          include_content: false,
          search,
        },
      ],
      queryFn: ({ queryKey }) => {
        const [, , query] = queryKey;
        return MonolithNotesAPI.getNotes(query);
      },
      placeholderData: (data) => data,
    });

    const notes: Note[] = data?.data || [];

    useEffect(() => {
      if (containerRef.current) {
        setScrollActive(
          containerRef.current.scrollHeight > containerRef.current.clientHeight
        );
      }
    }, []);

    return (
      <div
        ref={containerRef}
        className={className + " timeline-notes-list"}
        data-scroll-active={scrollActive}
      >
        {notes.map((note) => (
          <NotesListItem key={note.uuid} note={note} onClick={onNoteSelected} />
        ))}
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1 1 auto;
  height: 0px;

  overflow-y: auto;

  &[data-scroll-active="true"] {
    padding-right: 8px;
  }
`;

const NoteEditor = styled(({ className, defaultNoteData, onClose }) => {
  const { currentUser, hasPermission } = usePermissions();
  const queryClient = useQueryClient();
  const [saving, setSaving] = useState(false);
  const { data, isLoading } = useQuery({
    queryKey: [
      "task-notes",
      "list",
      { uuid: defaultNoteData.uuid, include_content: true },
    ],
    queryFn: ({ queryKey }) => {
      const [, , query] = queryKey;
      return MonolithNotesAPI.getNotes(query);
    },
  });

  const updateNoteData = useMutation({
    mutationFn: MonolithNotesAPI.updateNote,
    onMutate: async (variables) => {
      setSaving(true);
    },
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(
        [
          "task-notes",
          "list",
          { uuid: defaultNoteData.uuid, include_content: true },
        ],
        (oldData: { data: Note[] }) => {
          const cachedNote = oldData?.data?.[0];
          if (!cachedNote) {
            return oldData;
          }
          const newData = { ...cachedNote };
          if (variables.note_data !== undefined) {
            newData.note_data = variables.note_data;
          }
          if (variables.note_tag !== undefined) {
            newData.note_tag = variables.note_tag;
          }
          return {
            ...oldData,
            data: [
              {
                ...newData,
              },
            ],
          };
        }
      );

      // Add delay to ensure that user can see the saving state
      // sometimes the update happens so fast that the saving state is not visible
      setTimeout(() => {
        setSaving(false);
      }, 300);

      LoggingAPI.createLog({
        case_id: defaultNoteData.case_id,
        user_id: currentUser.user_id,
        message: `Updated task note.`,
        event: "task:update",
        object_type: "task",
        object_uuid: defaultNoteData.object_id,
      });

      LoggingAPI.createLog({
        case_id: defaultNoteData.case_id,
        user_id: currentUser.user_id,
        message: `Updated case note.`,
        event: "case_note:update",
        object_type: "case_note",
        object_uuid: defaultNoteData.uuid,
      });
    },
  });

  const handleUpdateNoteTitle = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let newTitle = e.target.value;
    if (newTitle === "") {
      newTitle = defaultNoteData.note_tag;
    }
    updateNoteData.mutate({
      uuid: defaultNoteData.uuid,
      note_tag: newTitle,
    });
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    updateNoteData.mutate({
      uuid: defaultNoteData.uuid,
      note_data: e,
    });
  };

  const debouncedHandleChange = useDebouncedCallback((e) => {
    handleChange(e);
  }, 400);

  const debouncedUpdateNoteTitle = useDebouncedCallback((e) => {
    handleUpdateNoteTitle(e);
  }, 350);

  if (isLoading) return null;

  const noteData = data?.data?.[0] || defaultNoteData || {};

  return (
    <div className={className}>
      <div className="note-menu">
        <div style={{ width: "100%" }}>
          <TextInput
            size="md"
            defaultValue={noteData?.note_tag}
            placeholder="Enter Note Title"
            style={{ backgroundColor: "transparent" }}
            onChange={debouncedUpdateNoteTitle}
          />
        </div>
        <Button
          size="xxs"
          variant="outlined"
          leftSection={<ArrowLeftIcon size={16} />}
          onClick={() => onClose?.()}
          title="Back to Notes List"
        >
          Back
        </Button>
      </div>
      <div className="inner-content">
        <RichTextEditor
          autoFocus={true}
          saving={saving}
          showToolbar={true}
          defaultValue={noteData?.note_data}
          onChange={debouncedHandleChange}
          readOnly={!hasPermission()}
          extensions={[
            Extensions.Placeholder,
            Extensions.SlashCommand,
            Extensions.BubbleMenu,
          ]}
          slashCommands={Object.values(SlashCommands).filter(
            (c) => c !== SlashCommands.Image
          )}
          toolbarOptions={{
            controls: Object.values(Controls).filter(
              (c) => c !== Controls.FONT
            ),
          }}
        />
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1 1 auto;

  margin-top: 10px;

  .note-menu {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }

  .inner-content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 0px;

    border: 1px solid ${({ theme }) => theme.palette.input.border};
    border-radius: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .ProseMirror {
    padding: 10px 30px !important;
  }
`;

const TaskNotes: React.FC<TaskNotesProps> = ({ task }) => {
  const { currentUser } = usePermissions();
  const [creatingNote, setCreatingNote] = useState<boolean>(false);
  const [selectedNote, setSelectedNote] = useState<Note | null>(null);
  const [currentView, setCurrentView] = useState<string>(NoteView.NOTES_LIST);
  const [search, setSearch] = useState<string>("");

  // Get Task Notes
  const { data } = useQuery({
    queryKey: [
      "task-notes",
      "list",
      {
        object_id: task.uuid,
        include_content: false,
        search,
      },
    ],
    queryFn: ({ queryKey }) => {
      const [, , query] = queryKey;
      return MonolithNotesAPI.getNotes(query);
    },
    placeholderData: (data) => data,
  });

  const createNoteMutation = useMutation({
    mutationFn: MonolithNotesAPI.createNote,
    onMutate: async () => {
      setCreatingNote(true);
    },
    onSuccess: (data, variables, context) => {
      LoggingAPI.createLog({
        case_id: task.case_id,
        user_id: currentUser.user_id,
        message: `Created task note.`,
        event: "task:create_note",
        object_type: "task",
        object_uuid: task.uuid,
      });

      LoggingAPI.createLog({
        case_id: task.case_id,
        user_id: currentUser.user_id,
        message: `Created task note.`,
        event: "create_case_note",
        object_type: "case_note",
        object_uuid: variables.uuid,
      });

      setCreatingNote(false);
      setSelectedNote(variables);
      setCurrentView("note-editor");
    },
  });

  const handleCreateNote = async () => {
    setCreatingNote(true);
    const note_uuid = shortUUID.generate();
    const note_tag = `Task Note_${moment().toISOString()}`;

    const newNoteData = {
      uuid: note_uuid,
      case_id: task.case_id,
      case_uuid: task.case_uuid,
      note_tag,
      path: note_tag,
      created_on: moment().toISOString(),
      created_by_id: currentUser.user_id,
      parent_id: null,
      is_folder: 0,
      object_type: "task",
      object_id: task.uuid,
    };

    createNoteMutation.mutate(newNoteData);
  };

  const handleSelectNote = (note: Note) => {
    setSelectedNote(note);
    setCurrentView(NoteView.NOTE_EDITOR);
  };

  const handleCloseEditor = () => {
    setSelectedNote(null);
    setCurrentView(NoteView.NOTES_LIST);
  };

  const debouncedHandleSearch = useDebouncedCallback((e) => {
    setSearch(e.target.value);
  }, 400);

  return (
    <>
      {currentView === NoteView.NOTES_LIST && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 5,
              alignContent: "center",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 10,
              marginTop: 10,
              width: "100%",
            }}
          >
            <Button
              size="xxs"
              variant="contained"
              color="primary"
              onClick={handleCreateNote}
              loading={creatingNote}
            >
              + New Note
            </Button>
            <TextInput
              stylingMode="outlined"
              placeholder="Search Notes"
              onKeyUp={debouncedHandleSearch}
            />
          </div>
          <NotesList
            task={task}
            onNoteSelected={handleSelectNote}
            search={search}
          />
        </>
      )}
      {currentView === NoteView.NOTE_EDITOR && (
        <NoteEditor
          defaultNoteData={selectedNote}
          onClose={handleCloseEditor}
        />
      )}
    </>
  );
};

export default TaskNotes;
