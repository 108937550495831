const DefaultTextChart = ({ data, title, valueExpr }) => {
  if (data.length === 0) {
    return (
      <div>
        <div style={{ marginBottom: "10px" }}>{title}</div>
        <div>No Data</div>
      </div>
    );
  }

  return (
    <div style={{ flexGrow: 1, flexBasis: "10px", padding: "0px 10px" }}>
      <div style={{ marginBottom: "10px" }}>{title}</div>
      <div
        style={{
          minWidth: "200px",
          overflowY: "auto",
          maxHeight: "225px",
          paddingRight: "10px",
        }}
      >
        {data.map((d, index) => {
          return (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "5px",
              }}
            >
              <div style={{ marginRight: "20px" }}>
                {d[valueExpr] || "N/A"}:{" "}
              </div>
              <div>
                {d.total} ({(d.percentage * 100).toFixed(2)}%)
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DefaultTextChart;
