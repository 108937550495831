import { useMediaQuery, useTheme } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

import { Popup } from "devextreme-react/ui/popup";
import {
  CustomRule,
  Form,
  RequiredRule,
  SimpleItem,
} from "devextreme-react/ui/form";
import { useAuth } from "../../../../../contexts/AuthContext";
import {
  createBulkCocRecords,
  getIpAddress,
  getLocations,
} from "../../../../../api";
import ToolBarItems from "../../../../../components/ToolBarItems";
import SignatureField from "../../../../../components/SignatureField/SignatureField";
import {
  db_timestamp,
  getDateFormat,
  validateTimestamp,
} from "../../../../../utils/date-format";
import CollapseSection from "../../../../../components/CollaspeSection";
import { useQuery } from "@tanstack/react-query";
import { Template } from "devextreme-react/core/template.js";
import LocationSelectBoxItem from "../../../../../components/SelectBoxItems/LocationSelectBoxItem.js";
import LocationsAPI from "../../../../../api/locations/index.js";

const IntakeItemsPopup = ({ intakeItemsPopup, onSubmit = () => {} }) => {
  const { currentUser } = useAuth();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const receivedBySig = useRef(null);
  const receivedFromSig = useRef(null);
  const form = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedItems, setSelectedItems] = useState([]);
  const [allItemsSelected, setAllItemsSelected] = useState(false);
  const [selectionState, setSelectionState] = useState({});

  const { data: locations } = useQuery({
    queryKey: ["evidence:locations", { include_groups: false }],
    queryFn: () => LocationsAPI.getLocations({}, { include_groups: false }),
    initialData: [],
  });

  const handleSubmit = async () => {
    if (form.current.instance.validate().isValid) {
      const formData = { ...form.current.instance.option().formData };

      const ipAddress = await getIpAddress();

      const signature = {};

      signature.custody_to_sig = receivedBySig.current.isEmpty()
        ? null
        : {
            signature: receivedBySig.current.getSignature(),
            signer: formData.custody_to.location_name,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      signature.custody_from_sig = receivedFromSig.current.isEmpty()
        ? null
        : {
            signature: receivedFromSig.current.getSignature(),
            signer: formData.custody_from,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      formData.evidence_ids = selectedItems;
      formData.current_user_id = currentUser.user_id;
      formData.timestamp = db_timestamp(formData.timestamp, true);
      formData.entered_by = currentUser.full_name;
      formData.coc_type = "Intake";

      formData.signature = JSON.stringify(signature);

      intakeItemsPopup.current.instance.hide();

      // Send Intake Record request to API
      createBulkCocRecords({
        formData: {
          custody_to: formData.custody_to.location_name,
          custody_from: formData.custody_from,
          timestamp: formData.timestamp,
          entered_by: formData.entered_by,
          coc_type: formData.coc_type,
          signature: formData.signature,
          reason: formData.reason || "Evidence Intake",
          new_location_id: formData.custody_to.location_id,
        },
        options: { ...selectionState.options },
      }).then((result) => {
        if (result.success) {
          enqueueSnackbar("Intake Records created successfully", {
            variant: "success",
          });
          onSubmit();
        } else {
          enqueueSnackbar("Error creating Intake Records: " + result.message, {
            variant: "error",
          });
        }
      });
    }
  };

  useEffect(() => {
    if (intakeItemsPopup.current) {
      intakeItemsPopup.current.setSelectionState = setSelectionState;
    }
  }, [intakeItemsPopup.current]);

  return (
    <>
      <Popup
        ref={intakeItemsPopup}
        title="Intake Selected Evidence Items"
        showTitle={true}
        showCloseButton={true}
        fullScreen={true}
        resizeEnabled={true}
        position={{
          offset: "100 0",
        }}
        onHidden={(e) => {
          if (form.current) {
            form.current.instance.resetValues();
          }
          if (receivedBySig.current) receivedBySig.current.clear();
          if (receivedFromSig.current) receivedFromSig.current.clear();
        }}
      >
        <div
          style={{
            margin: "auto",
            width: matches ? "90vw" : "70vw",
            height: "100%",
            maxWidth: 1000,
            userSelect: "none",
          }}
        >
          <div>
            <CollapseSection title="Intake Details" allowCollapse={false}>
              <div
                style={{
                  color: theme.palette.text.secondary,
                  marginBottom: 10,
                }}
              >
                Intake records will be created for {selectionState.total}{" "}
                evidence items.
              </div>
              <Form ref={form} colCount={2}>
                <SimpleItem
                  dataField="custody_to"
                  label={{ text: "Received By" }}
                  editorType="dxSelectBox"
                  isRequired={true}
                  editorOptions={{
                    dataSource: locations,
                    hint: "This is the person or location in your org that received the item.",
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                    displayExpr: "location_name",
                    searchEnabled: true,
                    itemTemplate: "custody_to",
                  }}
                />
                <Template
                  name="custody_to"
                  render={(data) => <LocationSelectBoxItem data={data} />}
                />
                <SimpleItem
                  dataField="custody_from"
                  isRequired={true}
                  label={{ text: "Received From" }}
                  editorOptions={{
                    placeholder: "John Smith, FedEx: tracking 12345...",
                    hint: "This is the person or entity the item was received from.",
                  }}
                ></SimpleItem>
                <SimpleItem
                  dataField="custody_to_sig"
                  label={{ text: "Received By Signature" }}
                >
                  <SignatureField
                    sigFieldRef={receivedBySig}
                    title="Received By Signature"
                  />
                </SimpleItem>
                <SimpleItem
                  dataField="custody_from_sig"
                  label={{ text: "Received From Signature" }}
                >
                  <SignatureField
                    sigFieldRef={receivedFromSig}
                    title="Received From Signature"
                  />
                </SimpleItem>
                <SimpleItem
                  dataField="timestamp"
                  label={{ text: "Intake Timestamp" }}
                  editorType="dxDateBox"
                  editorOptions={{
                    hint: "Date and time the item was received.",
                    type: "datetime",
                    useMaskBehavior: true,
                    displayFormat: getDateFormat({
                      isMoment: false,
                      includeTime: true,
                    }),
                    pickerType: "calendar",
                  }}
                >
                  <CustomRule
                    message="Invalid Date"
                    validationCallback={validateTimestamp}
                  />
                  <RequiredRule message="Intake Timestamp is required" />
                </SimpleItem>
                <SimpleItem
                  dataField="location_received"
                  label={{ text: "Location Received" }}
                  editorOptions={{
                    placeholder: "Address, Front Office, etc...",
                  }}
                />
                <SimpleItem
                  dataField="reason"
                  label={{ text: "Notes" }}
                  colSpan={2}
                  editorType="dxTextArea"
                  editorOptions={{
                    height: 100,
                  }}
                ></SimpleItem>
              </Form>
            </CollapseSection>
          </div>
          <ToolBarItems
            style={{
              margin: "30px 0px",
            }}
            submitText="Submit"
            onSubmit={handleSubmit}
            onCancel={() => {
              intakeItemsPopup.current.instance.hide();
            }}
          />
        </div>
      </Popup>
    </>
  );
};

export default IntakeItemsPopup;
