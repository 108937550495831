import styled from "styled-components";

const EvidenceSelectBoxItem = styled(({ className, data }) => {
  return (
    <div className={className}>
      <div className="evidence-number">{data.evidence_number}</div>
      <div className="evidence-info">
        <div>
          {data.manufacturer ? data.manufacturer : ""}
          {data.manufacturer && data.model_name ? " | " : ""}
          {data.model_name ? data.model_name : ""}
        </div>
      </div>
    </div>
  );
})`
  .evidence-number {
    margin-bottom: 5px;
  }
  .evidence-info {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

export default EvidenceSelectBoxItem;
