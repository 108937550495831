import moment from "moment";
import {
  SunriseIcon,
  SunsetIcon,
  SignalIcon,
  SignalZeroIcon,
  SignalLowIcon,
  SignalMediumIcon,
  SignalHighIcon,
  CalendarIcon,
  CalendarClockIcon,
  CalendarRangeIcon,
  CalendarXIcon,
  InfinityIcon,
  Circle,
  CircleIcon,
} from "lucide-react";

const TaskDueDateOptions = [
  {
    label: "No Due Date",
    value: "no_due_date",
    icon: CircleIcon,
    isSelectable: false,
  },
  {
    label: "Overdue",
    value: "overdue",
    icon: CalendarXIcon,
    iconColor: "orangered",
    isSelectable: false,
  },
  {
    label: "Today",
    value: "today",
    icon: SunriseIcon,
    iconColor: "limegreen",
    getLabel: () => moment().format("MMM DD"),
    getValue: () => moment().format("YYYY-MM-DD"),
    getDate: () => moment().toDate(),
    isSelectable: true,
  },
  {
    label: "Tomorrow",
    value: "tomorrow",
    icon: SignalLowIcon,
    getLabel: () => moment().add(1, "days").format("MMM DD"),
    getValue: () => moment().add(1, "days").format("YYYY-MM-DD"),
    getDate: () => moment().add(1, "days").toDate(),
    isSelectable: true,
  },
  {
    label: "End of Week",
    value: "end_of_week",
    icon: SignalMediumIcon,
    getLabel: () => moment().endOf("week").format("MMM DD"),
    getValue: () => moment().endOf("week").format("YYYY-MM-DD"),
    getDate: () => moment().endOf("week").toDate(),
    isSelectable: true,
  },
  {
    label: "Next Week",
    value: "next_week",
    icon: SignalHighIcon,
    getLabel: () => moment().add(7, "days").format("MMM DD"),
    getValue: () => moment().add(7, "days").format("YYYY-MM-DD"),
    getDate: () => moment().add(7, "days").toDate(),
    isSelectable: true,
  },
  {
    label: "2 Weeks",
    value: "two_weeks",
    icon: SignalIcon,
    getLabel: () => moment().add(14, "days").format("MMM DD"),
    getValue: () => moment().add(14, "days").format("YYYY-MM-DD"),
    getDate: () => moment().add(14, "days").toDate(),
    isSelectable: true,
  },
  {
    label: "3 Weeks",
    value: "three_weeks",
    icon: CalendarIcon,
    getLabel: () => moment().add(21, "days").format("MMM DD"),
    getValue: () => moment().add(21, "days").format("YYYY-MM-DD"),
    getDate: () => moment().add(21, "days").toDate(),
    isSelectable: true,
  },
  {
    label: "1 Month",
    value: "this_month",
    icon: CalendarClockIcon,
    getLabel: () => moment().add(1, "month").format("MMM DD"),
    getValue: () => moment().add(1, "month").format("YYYY-MM-DD"),
    getDate: () => moment().add(1, "month").toDate(),
    isSelectable: true,
  },
  {
    label: "End of Quarter",
    value: "end_of_quarter",
    icon: CalendarRangeIcon,
    getLabel: () => moment().endOf("quarter").format("MMM DD"),
    getValue: () => moment().endOf("quarter").format("YYYY-MM-DD"),
    getDate: () => moment().endOf("quarter").toDate(),
    isSelectable: true,
  },
  {
    label: "Due Later",
    value: "due_later",
    icon: InfinityIcon,
    isSelectable: false,
  },
];

export default TaskDueDateOptions;
