import styled from "styled-components";
import { NavLink, Outlet } from "react-router-dom";

const tabDefs = [
  {
    label: "Timeline",
    value: "timeline",
  },
  {
    label: "Accounts",
    value: "accounts",
  },
  {
    label: "Trace Indicators",
    value: "trace-indicators",
  },
  {
    label: "Trace Attributes",
    value: "trace-attributes",
  },
];

const Tabs = styled(({ className }) => {
  return (
    <div className={className}>
      {tabDefs.map((tab) => (
        <NavLink to={`${tab.value}`} key={tab.value}>
          <div className="tab">{tab.label}</div>
        </NavLink>
      ))}
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.text.secondary};

  .tab {
    padding-right: 15px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-left: 3px solid transparent;
  }

  .tab:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }

  a.active .tab {
    border-left-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const Sidebar = styled(({ className }) => {
  return (
    <div className={className}>
      <Tabs />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  width: 150px;
  min-width: 150px;

  border-right: 1px solid ${({ theme }) => theme.palette.action.hover};
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  padding: 0px 10px;
`;

const CaseAnalysis = styled(({ className }) => {
  return (
    <div className={className + " analysis-section"}>
      <div className="analysis-section-container">
        <Sidebar className="analysis-sidebar" />
        <MainContent className="analysis-content">
          <Outlet />
        </MainContent>
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  .analysis-section-container {
    display: flex;
    flex: 1 1 auto;
  }

  .analysis-options {
    display: flex;
    flex-flow: row wrap;
    gap: 24px;
    justify-content: center;
    align-items: center;
  }
`;

export default CaseAnalysis;
