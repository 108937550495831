import styled from "styled-components";
import TaskButton from "../../TaskButton.js";

import {
  FolderPlus,
  Maximize,
  Minimize,
  Plus,
  Search,
  FileEdit,
  ChevronLeft,
} from "lucide-react";
import Keyboard from "../../Keyboard/Keyboard.js";
import { useState } from "react";

const EmptyState = styled(({ className, readOnly, ...props }) => {
  const [showHelp, setShowHelp] = useState(false);
  return (
    <div className={className}>
      {!showHelp && (
        <div style={{ marginTop: 0 }}>
          {!readOnly && (
            <>
              <div className="item">
                <div className="label">
                  <TaskButton
                    variant="outlined"
                    onClick={() => props?.handleCreateNote()}
                    style={{
                      padding: "3px 5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 5,
                      }}
                    >
                      <Plus size={14} />
                      <div>New Note</div>
                    </div>
                  </TaskButton>
                </div>
                <Keyboard plus keys={["Ctrl", "Shift", "N"]} />
              </div>
              <div className="item">
                <div className="label">
                  <TaskButton
                    variant="outlined"
                    onClick={() => props?.handleCreateFolder()}
                    style={{ padding: "3px 5px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 5,
                      }}
                    >
                      <FolderPlus size={14} />
                      <div>New Folder</div>
                    </div>
                  </TaskButton>
                </div>
                <Keyboard plus keys={["Ctrl", "Shift", "M"]} />
              </div>
            </>
          )}
          <div className="item">
            <div className="label">
              <TaskButton
                variant="outlined"
                onClick={() => props?.handleShowSearch()}
                style={{ padding: "3px 5px" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <Search size={14} />
                  <div>Search Notes</div>
                </div>
              </TaskButton>
            </div>
            <Keyboard plus keys={["Ctrl", "Shift", "S"]} />
          </div>
          <div className="item">
            <div className="label">
              <TaskButton
                variant="outlined"
                onClick={() => props?.toggleToolbar?.()}
                style={{ padding: "3px 5px" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <FileEdit size={14} />
                  <div>Toggle Editor Toolbar</div>
                </div>
              </TaskButton>
            </div>
            <Keyboard plus keys={["Ctrl", "Shift", "T"]} />
          </div>
          <div className="item">
            <div className="label">
              <TaskButton
                variant="outlined"
                onClick={() => props?.toggleFullscreen?.()}
                style={{ padding: "3px 5px" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  {props.fullscreen ? (
                    <Minimize size={14} />
                  ) : (
                    <Maximize size={14} />
                  )}
                  <div>Toggle Fullscreen</div>
                </div>
              </TaskButton>
            </div>
            <Keyboard plus keys={["Ctrl", "Shift", "F"]} />
          </div>
          <div className="item help">
            <div className="label">
              <TaskButton
                variant="outlined"
                onClick={() => setShowHelp(!showHelp)}
                style={{ padding: "3px 5px" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <div>View Help</div>
                </div>
              </TaskButton>
            </div>
            <div>Show Note System Instructions</div>
          </div>
        </div>
      )}
      {showHelp && (
        <div>
          <div className="item">
            <div className="label">
              <TaskButton
                variant="outlined"
                onClick={() => setShowHelp(false)}
                style={{ padding: "3px 5px" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <ChevronLeft size={14} />
                  <div>Back</div>
                </div>
              </TaskButton>
            </div>
          </div>
          <div className="instructions-container">
            <h3>Creating Notes & Folders</h3>
            <p className="instruction-text">
              Create a new note or folder to get started.
            </p>
            <h3>Note Views</h3>
            <p className="instruction-text">
              You can view notes as a tree-list or a flat, vertical list. The
              main difference is that you can view all user notes in the
              vertical list, but you can only view a single user's notes in the
              tree list. Use the viewer icon in the note sidebar menu to toggle
              between the two views.
            </p>
            <h3>Organizing Notes & Navigation</h3>
            <p className="instruction-text">
              Notes are grouped into case segments and evidence segments. Case
              level notes will exist in the case segment, and any evidence
              linked notes will be located in thier respective evidence segment.
            </p>
            <h3>Note Tabs</h3>
            <p className="instruction-text">
              You can have multiple notes open at once which will be displayed
              under tabs. You can even have your notes and other user's notes
              open in these tabs.
            </p>
            <p className="instruction-text">
              Click a note to display it in a temporary tab.
            </p>
            <p className="instruction-text">
              Double-click a note to make the tab permanent - you can also
              double-click the tab.
            </p>
            <h3>Evidence Notes</h3>
            <p className="instruction-text">
              If you wish to focus on just evidence notes, navigate to that
              evidence item's details page, and use the "Notes" tab to create
              notes. Evidence linked notes can also be created from the sidebar
              "..." menu. Select the "Create Evidence Note" item, then select
              the evidence item you wish to link the new note to.
            </p>
            <h3>Note Protection</h3>
            <p className="instruction-text">
              Only your notes are visible by default. To view another user's
              notes, use the notes selctor in the upper left corner.
            </p>
            <p className="instruction-text">
              You can only edit or delete your own notes, but you can view and
              export other user's notes.
            </p>
            <h3>Moving Notes</h3>
            <p className="instruction-text">
              Drag notes and folders to organize them how you wish. Items can
              also be moved between segements.
            </p>
            <h3>Note Exports</h3>
            <p className="instruction-text">
              Export your notes to a Word or PDF document from the sidebar menu
              or from the editor toolbar.
            </p>
            <h3>Deleting and Renaming Notes or Folders</h3>
            <p className="instruction-text">
              While hovering a note or folder, use the "..." menu to delete or
              rename the item. You can also rename a note by clicking the title
              of an open note.
            </p>
            <h3>Slash Commands</h3>
            <p className="instruction-text">
              While editing a note, use the slash command menu,{`"\\"`}, to use
              shortcuts like inserting dates or timestamps.
            </p>
            <h3>Templates</h3>
            <p className="instruction-text">
              To create a template, use the "Actions" menu in the editor toolbar
              and select "Save Template".
            </p>
            <p className="instruction-text">
              There is also an insert template option that will insert the
              template data into your note.
            </p>
          </div>
        </div>
      )}
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  height: calc(100% - 100px);

  max-width: 80%;
  margin: 0 auto;
  overflow-y: auto;
  padding-right: 30px;
  padding-left: 30px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  .hotkey-text {
    color: ${(props) => props.theme.palette.text.secondary};
    font-weight: 500;
  }

  .instructions-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    transition: opacity 0.3s ease;

    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 4px;
    padding: 15px;
  }

  .instruction-text {
    color: ${(props) => props.theme.palette.text.secondary};
    font-size: 0.75rem;
    font-weight: 600;
    margin: 0;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    color: ${(props) => props.theme.palette.action.hover};
  }

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${(props) => props.theme.palette.text.secondary};
    margin-bottom: 1rem;
  }

  .item.help {
    margin: 0 auto;
  }

  .label {
    font-size: 0.75rem;
    min-width: 175px;

    .task-button {
      color: ${(props) => props.theme.palette.text.primary};
    }
  }
`;

export default EmptyState;
