import React, { useRef, useState } from "react";
import { Tabs } from "devextreme-react/ui/tabs";
import { Button, Card, Divider } from "@mui/material";
import moment from "moment";
import ReportMetrics from "./ReportMetrics";
import SavedReports from "./SavedReports";
import ReportComposition from "./ReportComposition";
import { EditReportPopup } from "../../EditReportPopup";
import AcquisitionGrid from "./AcquisitionGrid.js";
import { usePermissions } from "../../../../hooks/usePermissions";
import { MONOLITH_PERMISSIONS } from "../../../../constants.js";

const ReportDetails = ({ reportInfo }) => {
  return (
    <Card
      style={{
        padding: "20px",
        textAlign: "left",
        flexGrow: 1,
        flexBasis: "10px",
        marginRight: 15,
      }}
    >
      <div
        style={{
          fontSize: "larger",
        }}
      >
        Report Details
      </div>
      <Divider sx={{ my: 2 }} />
      <div
        style={{
          minWidth: "300px",
          overflowY: "auto",
          maxHeight: "175px",
          paddingRight: "10px",
        }}
      >
        {reportInfo.report_id && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "5px",
            }}
          >
            <div>Report ID: </div>
            <div>{reportInfo.report_id}</div>
          </div>
        )}
        {reportInfo.name && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "5px",
            }}
          >
            <div>Report Name: </div>
            <div>{reportInfo.name}</div>
          </div>
        )}
        {reportInfo.created_at && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "5px",
            }}
          >
            <div>Created On:</div>
            <div>{moment(reportInfo.created_at).format("MMMM DD, YYYY")}</div>
          </div>
        )}
        {reportInfo.created_by && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "5px",
            }}
          >
            <div>Created By: </div>
            <div>{reportInfo.created_by}</div>
          </div>
        )}
        {reportInfo.category && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "5px",
            }}
          >
            <div>Category: </div>
            <div>{reportInfo.category}</div>
          </div>
        )}
      </div>
    </Card>
  );
};

const ReportParameters = ({ reportInfo, editReportPopup }) => {
  const { hasPermission } = usePermissions();

  return (
    <Card
      style={{
        padding: "20px",
        textAlign: "left",
        flexGrow: 1,
        flexBasis: "10px",
        marginRight: 15,
      }}
    >
      <>
        <div
          style={{
            fontSize: "larger",
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
          }}
        >
          <span style={{ display: "flex", alignItems: "center" }}>
            Report Parameters
          </span>
          <Button
            size="small"
            onClick={() => editReportPopup.current.instance.show()}
            disabled={
              !hasPermission(MONOLITH_PERMISSIONS.METRICS_REPORTS_UPDATE)
            }
          >
            Edit
          </Button>
        </div>
        <Divider sx={{ my: 2 }} />
        <div
          style={{
            minWidth: "300px",
            overflowY: "auto",
            maxHeight: "175px",
            paddingRight: "10px",
          }}
        >
          {reportInfo.report_parameters && (
            <>
              <div
                style={{
                  minWidth: "300px",
                }}
              >
                {reportInfo.report_parameters.time_interval && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>Time Interval:</div>
                    <div
                      style={{
                        maxWidth: 150,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      title={reportInfo.report_parameters.time_interval || ""}
                    >
                      {reportInfo.report_parameters.time_interval}
                    </div>
                  </div>
                )}
                {reportInfo.report_parameters.start_date && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>Start Date:</div>
                    <div
                      style={{
                        maxWidth: 150,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      title={
                        moment(reportInfo.report_parameters.start_date).format(
                          "MMMM DD, YYYY"
                        ) || ""
                      }
                    >
                      {moment(reportInfo.report_parameters.start_date).format(
                        "MMMM DD, YYYY"
                      )}
                    </div>
                  </div>
                )}
                {reportInfo.report_parameters.end_date && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>End Date:</div>
                    <div
                      style={{
                        maxWidth: 150,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      title={
                        moment(reportInfo.report_parameters.end_date).format(
                          "MMMM DD, YYYY"
                        ) || ""
                      }
                    >
                      {moment(reportInfo.report_parameters.end_date).format(
                        "MMMM DD, YYYY"
                      )}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {!reportInfo.report_parameters && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "5px",
              }}
            >
              <div>No Parameters Set</div>
            </div>
          )}
        </div>
      </>
    </Card>
  );
};

const TabSection = ({ reportInfo }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div style={{ minHeight: "450px" }}>
      <Tabs
        items={["Saved Reports", "Acquisitions"]}
        selectedIndex={selectedTab}
        focusStateEnabled={false}
        hoverStateEnabled={false}
        activeStateEnabled={false}
        width={250}
        onItemClick={(e) => {
          e.event.preventDefault();
          e.event.stopPropagation();
          setSelectedTab(e.itemIndex);
        }}
      />
      {selectedTab === 0 && <SavedReports reportInfo={reportInfo} />}
      {selectedTab === 1 && <AcquisitionGrid reportInfo={reportInfo} />}
    </div>
  );
};

const AcquisitionsReport = ({ reportInfo, setFormUpdated }) => {
  const editReportPopup = useRef(null);

  return (
    <>
      <div
        style={{
          display: "flex",
          marginBottom: "20px",
          userSelect: "text",
        }}
      >
        <ReportDetails reportInfo={reportInfo} />
        <ReportParameters
          reportInfo={reportInfo}
          editReportPopup={editReportPopup}
        />
        <ReportMetrics reportInfo={reportInfo} />
      </div>
      <ReportComposition reportInfo={reportInfo} />
      <TabSection reportInfo={reportInfo} />
      <EditReportPopup
        editReportPopup={editReportPopup}
        reportInfo={reportInfo}
        setFormUpdated={setFormUpdated}
      />
    </>
  );
};

export default AcquisitionsReport;
