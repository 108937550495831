import { ColumnProps } from "@monolith-forensics/monolith-ui";
import { monolithMoment } from "../../utils/date-format";
import styled from "styled-components";
import { Link } from "react-router-dom";
import PassIndicator from "../PassIndicator";

const AuditItemCell = styled(({ className, rowData }) => {
  const href =
    rowData.object_type === "evidence"
      ? `/evidence/${rowData.object_uuid}/overview`
      : `/storage/${rowData.object_uuid}`;

  return (
    <div className={className}>
      <Link to={href}>
        <div className="label">{rowData?.object_name}</div>
      </Link>
    </div>
  );
})`
  .label {
    font-weight: 500;
    color: ${(props) => props.theme.palette.primary.main};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      color: ${(props) => props.theme.palette.text.primary};
    }
  }
`;

const AuditLogsDefs: ColumnProps[] = [
  {
    dataField: "created_on",
    caption: "Timestamp",
    width: 200,
    render: ({ rowData }) =>
      monolithMoment({
        timestamp: rowData.created_on,
        includeTime: true,
        includeZone: true,
      }),
  },
  {
    dataField: "object_name",
    caption: "Audit Item",
    width: 200,
    render: ({ rowData }) => <AuditItemCell rowData={rowData} />,
  },
  {
    dataField: "created_by",
    caption: "User",
    width: 200,
    render: ({ rowData }) => rowData.created_by.full_name,
  },
  {
    dataField: "passed_audit",
    caption: "Audit Status",
    width: 200,
    render: ({ rowData }) => <PassIndicator passed={rowData.passed_audit} />,
  },
  { dataField: "notes", caption: "Notes" },
];

export default AuditLogsDefs;
