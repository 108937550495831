import { useState } from "react";
import styled from "styled-components";
import LoggingAPI from "../../api/logging/index.js";
import { usePermissions } from "../../hooks/usePermissions";

const EncryptionKeyComponent = styled(({ className, rowData }) => {
  const { currentUser } = usePermissions();

  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);

    LoggingAPI.logActivity(
      rowData.case_id,
      currentUser.user_id,
      `Viewed Encryption Key for Storage Item ${rowData.storage_number}`
    );
  };

  if (!rowData.encryption_key) return rowData.encryption_key;

  return (
    <div className={className}>
      {!show ? (
        <div className="hiddin-key" onClick={handleClick} title="Reveal Key">
          Click To Show
        </div>
      ) : (
        <div>{rowData.encryption_key}</div>
      )}
    </div>
  );
})`
  .hiddin-key {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.primary.main};
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default EncryptionKeyComponent;
