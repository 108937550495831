import { httpGet } from "../connection";

const SearchApi = {
  barcodeSearch: async (params) => {
    return await httpGet(`/search/barcodeSearch`, params);
  },
  globalSearch: async (params) => {
    return await httpGet(`/search/globalSearch`, params);
  },
};

export default SearchApi;
