import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
import CaseReports from "./CaseReports.js";
import CaseArchives from "./CaseArchives.js";

const buttonStyle = {
  minWidth: "150px",
  padding: "2px 5px",
};

const ReportsAndArchives = styled(({ className }) => {
  const [currentView, setCurrentView] = useState(0);
  const [group, setGroup] = useState("case-reports");

  const handleChange = (event: React.MouseEvent, newGroup: string) => {
    if (newGroup !== null) {
      setGroup(newGroup);
      setCurrentView(newGroup === "case-reports" ? 0 : 1);
    }
  };

  const control = {
    value: group,
    onChange: handleChange,
    exclusive: true,
  };

  return (
    <div className={className}>
      <div style={{ marginBottom: 10, display: "flex" }}>
        <ToggleButtonGroup size="small" {...control} style={{ margin: "auto" }}>
          <ToggleButton
            value="case-reports"
            size="small"
            disableRipple={true}
            style={buttonStyle}
          >
            <>Case Reports</>
          </ToggleButton>
          <ToggleButton
            value="case-archives"
            size="small"
            disableRipple={true}
            style={buttonStyle}
          >
            <>Case Archives</>
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        {currentView === 0 && <CaseReports />}
        {currentView === 1 && <CaseArchives />}
      </div>
    </div>
  );
})`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export default ReportsAndArchives;
