import { useQuery } from "@tanstack/react-query";
import DropdownMenu from "../../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import TasksAPI from "../../../api/tasks/tasks.js";

const TaskTemplateSelector = ({
  value,
  onSelect,
  showLabel = true,
  variant = "contained",
}) => {
  const { data } = useQuery({
    queryKey: ["tasks", "templates"],
    queryFn: () => TasksAPI.getTaskTemplates(),
  });

  const templates = data?.data || [];

  const menuItems = [
    {
      radioGroup: true,
      value: value?.template_id,
      items: templates.map((template) => ({
        label: template.template_name,
        value: template.template_id,
        onClick: () => {
          onSelect?.(template);
        },
      })),
    },
  ];

  return (
    <DropdownMenu
      menuItems={menuItems}
      title={"Select Task Template"}
      variant={variant}
      textColor={!!value ? "primary" : "secondary"}
    >
      {value?.icon && (
        <value.icon
          color={value.color}
          size={13}
          style={{ marginRight: showLabel ? 5 : 0 }}
        />
      )}
      {showLabel && <>{value?.template_name || "Template"}</>}
    </DropdownMenu>
  );
};

export default TaskTemplateSelector;
