import { Button, Divider, useTheme } from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { getLicenseInfo, getOrgInfo, userApi } from "../../api";
import DropDownSelector from "../../components/DropDownSelector";
import Loader from "../../components/Loader";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import ToolBarItems from "../../components/ToolBarItems";
import { getBaseURL, getRelayDomain, initBaseURL } from "../../api/connection";
import { useSnackbar } from "notistack";
import { testHostIP } from "../InitialSetup/SelectApiMode";
import { useAuth } from "../../contexts/AuthContext";
import LaunchIcon from "@mui/icons-material/Launch";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import RelayAPI from "../../api/RelayAPI/index.js";
import CaseFileAPI from "../../api/CaseFiles/index.js";
import usePlatform from "../../hooks/usePlatform.js";
import convertBytesToSize from "../../utils/convertBytesToSize.js";

interface License {
  active: number;
  address: string;
  auth0_id: string;
  city: string;
  country: string;
  email: string;
  expire_date: string;
  first_name: string;
  full_name: string;
  hot_storage_limit_gb: number;
  id: number;
  is_cloud: number;
  is_customer: number;
  is_onprem: number;
  is_trial: number;
  last_name: string;
  license_id: number;
  license_key: string;
  license_token: string;
  licenses_used: number;
  monolith_archive_enabled: number;
  monolith_archive_limit_tb: number;
  organization: string;
  owner_id: number;
  phone: string;
  state: string;
  tenant: string;
  tenant_id: number;
  total_active_users: number;
  user_licenses: number;
  zipcode: string;
}

export interface Organization {
  address: string;
  city: string;
  email: string;
  fax: string;
  image: string;
  mobile: string;
  name: string;
  org_id: number;
  phone: string;
  state: string;
  website: string;
  zipcode: string;
}

const OrgProfile = ({ orgInfo }: { orgInfo: Organization }) => {
  return (
    <>
      <div style={{ fontSize: "large", marginBottom: "20px" }}>
        Organization Profile
      </div>
      <div style={{ display: "flex" }}>
        <div
          style={{
            width: 200,
            height: 85,
          }}
        >
          <img
            src={orgInfo.image}
            style={{
              objectFit: "contain",
              width: "100%",
              height: "100%",
            }}
            alt="Organization Logo"
          ></img>
        </div>
        <div style={{ marginLeft: 20 }}>
          <div>{orgInfo.name}</div>
          <div>{orgInfo.address}</div>
          <div>
            {orgInfo.city || "City"}, {orgInfo.state || "State/Province"}{" "}
            {orgInfo.zipcode || "Postal Code"}
          </div>
          <div>
            {orgInfo.phone || "Phone"} | {orgInfo.email || "Email"}
          </div>
          <div>{orgInfo.website || "Website URL"}</div>
        </div>
      </div>
      <Divider style={{ margin: "20px 0px" }} />
    </>
  );
};

const LicenseInfo = ({ licenseInfo }: { licenseInfo: License }) => {
  const { isOnPremises } = usePlatform();

  return (
    <>
      <div style={{ fontSize: "large", marginBottom: "20px" }}>
        Subscription Info
      </div>
      <div>Registered To</div>
      <div style={{ marginLeft: 10, marginTop: 10, marginBottom: 20 }}>
        <div>{licenseInfo.full_name}</div>
        <div>{licenseInfo.organization}</div>
        <div>{licenseInfo.email}</div>
      </div>
      <div>Details</div>
      <div style={{ marginLeft: 10, marginTop: 10, marginBottom: 20 }}>
        <div>Licensed Users: {licenseInfo.user_licenses}</div>
        <div>User Seats Used: {licenseInfo.total_active_users}</div>
        <div>
          Subscription Expires:{" "}
          {moment(licenseInfo.expire_date).format("MMMM DD, YYYY")}
        </div>
      </div>
      {!isOnPremises && (
        <>
          <div>Cloud Storage</div>
          <StorageStatus licenseInfo={licenseInfo} />
        </>
      )}
      <RelayInfo />
      {!isOnPremises && (
        <>
          <div>Workspaces</div>
          {<WorkspacesList />}
        </>
      )}
      <Divider style={{ margin: "20px 0px" }} />
    </>
  );
};

const StorageStatus = styled(({ className, licenseInfo }) => {
  const { data } = useQuery({
    queryKey: ["case-files:storage-volume"],
    queryFn: () => CaseFileAPI.getStorageVolume(),
  });

  const archiveEnabled = licenseInfo.monolith_archive_enabled === 1;

  const hotStorageLimit = convertBytesToSize(
    parseInt(licenseInfo.hot_storage_limit_gb) * (1024 * 1024 * 1024)
  );

  const hotStorageUsed = convertBytesToSize(data?.total_bytes || 0);

  const percentageUsedHot =
    (
      ((data?.total_bytes || 0) /
        (parseInt(licenseInfo.hot_storage_limit_gb) * (1024 * 1024 * 1024))) *
      100
    ).toFixed(2) + "%";

  const coldStorageLimit = convertBytesToSize(
    parseInt(licenseInfo.monolith_archive_limit_tb) *
      (1024 * 1024 * 1024 * 1024)
  );

  const coldStorageUsed = convertBytesToSize(data?.total_bytes_archived || 0);

  const percentageUsedCold =
    (
      ((data?.total_bytes_archived || 0) /
        (parseInt(licenseInfo.monolith_archive_limit_tb) *
          (1024 * 1024 * 1024 * 1024))) *
      100
    ).toFixed(2) + "%";

  if (!data) return null;

  return (
    <div className={className}>
      {/* HOT STORAGE */}
      <div>
        <div className="storage-meter-header">Hot Storage</div>
        <div className="storage-meter-header">{`${hotStorageUsed} of ${hotStorageLimit} Used (${percentageUsedHot})`}</div>
        <div className="storage-meter">
          <div className="outer-container"></div>
          <div
            className="inner-container"
            style={{ width: percentageUsedHot }}
          ></div>
        </div>
      </div>
      {/* Archive Storage */}
      {archiveEnabled && (
        <div style={{ marginTop: 15 }}>
          <div className="storage-meter-header">Archive Storage</div>
          <div className="storage-meter-header">{`${coldStorageUsed} of ${coldStorageLimit} Used (${percentageUsedCold})`}</div>
          <div className="storage-meter">
            <div className="outer-container"></div>
            <div
              className="inner-container"
              style={{ width: percentageUsedCold }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
})`
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 10px;

  & .storage-meter-header {
    font-size: 12px;
    margin-bottom: 5px;
    color: ${({ theme }) => theme.palette.text.secondary};
    font-weight: 500;
  }

  & .storage-meter {
    position: relative;
    height: 8px;
    width: 250px;
  }

  & .outer-container {
    border-radius: 5px;
    border: 1px solid transparent;
    background-color: ${({ theme }) => theme.palette.action.hover};
    width: 100%;
    height: 100%;
  }

  & .inner-container {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.palette.primary.main};
    height: 100%;
    transition: width 0.2s ease;
  }
`;

const DateFormat = ({}) => {
  return (
    <>
      <div style={{ fontSize: "large", marginBottom: "20px" }}>
        Date & Time Format
      </div>
      <div style={{ marginLeft: 10 }}>
        <div>Date Format</div>
        <DropDownSelector
          useSelectMode={true}
          selectedItem={window.localStorage.getItem("dateFormat.date")}
          items={["MM/DD/YYYY", "DD/MM/YYYY", "YYYY-MM-DD"]}
          variant="contained"
          style={{ margin: "10px 0px" }}
          onSelect={(item) => {
            window.localStorage.setItem("dateFormat.date", item);
          }}
          buttonIcon={undefined}
          value={undefined}
        />
        <div>Time Format</div>
        <DropDownSelector
          useSelectMode={true}
          selectedItem={window.localStorage.getItem("dateFormat.time")}
          items={["HH:mm:ss", "h:mm:ss A"]}
          variant="contained"
          style={{ margin: "10px 0px" }}
          onSelect={(item) => {
            window.localStorage.setItem("dateFormat.time", item);
          }}
          buttonIcon={undefined}
          value={undefined}
        />
      </div>
      <Divider style={{ margin: "20px 0px" }} />
    </>
  );
};

const CurrencyFormat = ({}) => {
  return (
    <>
      <div style={{ fontSize: "large", marginBottom: "20px" }}>
        Currency Format
      </div>
      <div style={{ marginLeft: 10 }}>
        <div>Select Currency</div>
        <DropDownSelector
          useSelectMode={true}
          selectedItem={window.localStorage.getItem("currencyFormat")}
          items={["USD", "EUR", "GBP", "CAD", "AUD", "NZD", "JPY", "CNY"]}
          variant="contained"
          style={{ margin: "10px 0px" }}
          onSelect={(item) => {
            window.localStorage.setItem("currencyFormat", item);
          }}
          buttonIcon={undefined}
          value={undefined}
        />
      </div>
      <Divider style={{ margin: "20px 0px" }} />
    </>
  );
};

const MonolithDesktopInstallers = ({}) => {
  const theme = useTheme();
  return (
    <>
      <div style={{ fontSize: "large", marginBottom: "10px" }}>
        Monolith Desktop Installers
      </div>
      <div style={{ marginLeft: 10, color: theme.palette.text.secondary }}>
        Install Monolith as a Desktop client. The client is identical to the web
        app, but its in a dedicated Windows or MacOS application.
      </div>
      <div style={{ marginLeft: 10, marginTop: 20, marginBottom: 20 }}>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: 10 }}>
            <a
              href="https://monolith-cloud.nyc3.digitaloceanspaces.com/updates/monolith-desktop/latest/Monolith%20Desktop%20Setup.exe"
              title="Download"
            >
              <Button color="secondary" variant="contained" size="small">
                <FileDownloadOutlinedIcon
                  fontSize="small"
                  style={{ marginRight: 5 }}
                />
                Windows
              </Button>
            </a>
          </div>
          <div style={{ marginRight: 10 }}>
            <a
              href="https://monolith-cloud.nyc3.digitaloceanspaces.com/updates/monolith-desktop/latest/Monolith%20Desktop%20Setup.dmg"
              title="Download"
            >
              <Button color="secondary" variant="contained" size="small">
                <FileDownloadOutlinedIcon
                  fontSize="small"
                  style={{ marginRight: 5 }}
                />
                MacOS
              </Button>
            </a>
          </div>
        </div>
      </div>
      <Divider style={{ margin: "20px 0px" }} />
    </>
  );
};

const UpdateApiEndpoint = ({}) => {
  const form: any = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleUpdateSubmit = async ({ isValid }: { isValid: boolean }) => {
    if (!isValid) return;
    const formData = { ...form.current.instance.option("formData") };
    window.electronAPI.setSettings("API_HOST", formData.api_host_ip);
    window.localStorage.setItem("API_HOST", formData.api_host_ip);
    initBaseURL();
    enqueueSnackbar("API Endpoint Updated", { variant: "success" });
  };

  const handleUpdate = () => {
    const res = form.current.instance.validate();
    if (res.status === "pending") {
      res.complete.then((r: any) => {
        handleUpdateSubmit({ isValid: r.isValid });
      });
    } else {
      handleUpdateSubmit({ isValid: res.isValid });
    }
  };

  useEffect(() => {
    getBaseURL().then((result) => {
      form.current.instance.option("formData", { api_host_ip: result });
    });
  });

  return (
    <>
      <div style={{ fontSize: "large", marginBottom: "20px" }}>
        Update API Endpoint
      </div>
      <p>Update the endpoint used for your on-premises Monolith deployment.</p>
      <div style={{ marginLeft: 10, maxWidth: 400 }}>
        <Form ref={form}>
          <SimpleItem
            dataField="api_host_ip"
            label={{ text: "API Host Endpoint" }}
            editorOptions={{
              width: 400,
              stylingMode: "filled",
              placeholder: "http://monolith_api_server_ip/api",
            }}
            validationRules={[
              {
                type: "required",
              },
              {
                type: "async",
                message: "Cannot reach API host endpoint",
                validationCallback: (e: any) => {
                  return testHostIP(e.value);
                },
              },
            ]}
          />
        </Form>
        <ToolBarItems
          onSubmit={handleUpdate}
          submitText="Update"
          showCancel={false}
          style={{ marginTop: 20 }}
        />
      </div>
      <Divider style={{ margin: "20px 0px" }} />
    </>
  );
};

const WorkspacesList = () => {
  const { currentUser } = useAuth();
  const theme = useTheme();
  const [workspaceList, setWorkspaceList] = useState<
    | {
        tenant_slug: string;
        workspace: string;
      }[]
    | null
  >(null);

  const handleClick = (workspace: {
    tenant_slug: string;
    workspace: string;
  }) => {
    localStorage.setItem("MONOLITH.WORKSPACE", JSON.stringify(workspace));
    window.location.reload();
  };

  useEffect(() => {
    userApi.getUserWorkspaces(currentUser.email).then(setWorkspaceList);
  }, []);

  if (!workspaceList) return <Loader />;

  return (
    <div style={{ marginLeft: 10, marginTop: 10, marginBottom: 20 }}>
      <div style={{ color: theme.palette.text.secondary }}>
        These are the Monolith databases you have access to. Click on a
        workspace name to switch workspaces.
      </div>
      {workspaceList.map((workspace) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: theme.palette.primary.main,
              cursor: "pointer",
              margin: "10px 0px",
            }}
            onMouseEnter={(event) => {
              event.currentTarget.style.textDecoration = "underline";
            }}
            onMouseLeave={(event) => {
              event.currentTarget.style.textDecoration = "none";
            }}
            onClick={() => {
              handleClick(workspace);
            }}
          >
            {workspace.workspace}
            <LaunchIcon
              style={{
                fontSize: "15px",
                marginLeft: 5,
                borderRadius: 4,
                padding: "1px",
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

const RelayInfo = styled(({ className }) => {
  const { currentUser } = useAuth();

  const tenant = currentUser.licenseInfo.tenant;

  const { isLoading, data } = useQuery({
    queryKey: ["relayInfo"],
    queryFn: () => RelayAPI.getRelayInfo({ cloud_tenant_id: tenant }),
  });

  if (isLoading) return null;

  const [relayInfo] = data || [];

  if (!relayInfo) return null;

  return (
    <div className={className}>
      <div>Relay URL</div>
      <div style={{ marginLeft: 10, marginTop: 10, marginBottom: 20 }}>
        <div className={"relay-description"}>
          <div>
            Relay is a companion web application that allows others outside of
            Monolith to make forensics requests and view the status of those
            requests.
          </div>
        </div>
        <RelayUrl relayInfo={relayInfo} />
      </div>
    </div>
  );
})`
  .relay-link {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.palette.primary.main};
    cursor: pointer;
    margin: 10px 0px;
    &:hover {
      text-decoration: underline;
    }
  }
  .relay-description {
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;

export const RelayUrl = styled(({ className, relayInfo }) => {
  const isElectron = process.env.REACT_APP_PLATFORM === "electron";

  const { data, isLoading } = useQuery({
    queryKey: ["relayDomain"],
    queryFn: () => getRelayDomain(),
  });

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (isElectron) {
      e.preventDefault();
      const target = e.target as HTMLAnchorElement; // Cast to HTMLAnchorElement
      if (target.href) {
        window.electronAPI.openExternalUrl(target.href);
      }
    }
  };

  if (isLoading) return null;

  const relayUrl = data + "/" + relayInfo.tenant_slug;

  return (
    <div className="relay-link">
      <a
        onClick={handleClick}
        href={relayUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {relayUrl}
      </a>
      <LaunchIcon
        style={{
          fontSize: "15px",
          marginLeft: 5,
          borderRadius: 4,
          padding: "1px",
        }}
      />
    </div>
  );
})``;

const SystemSettings = styled(({ className }) => {
  const [orgInfo, setOrgInfo] = useState(null);
  const [licenseInfo, setLicenseInfo] = useState(null);
  const { isElectron, isOnPremises } = usePlatform();

  useEffect(() => {
    getOrgInfo().then((result) => {
      let arrayBufferView = new Uint8Array(result.image.data);
      let blob = new Blob([arrayBufferView]);
      let urlCreator = window.URL || window.webkitURL;
      let imageUrl = urlCreator.createObjectURL(blob);

      setOrgInfo({ ...result, image: imageUrl });
    });

    getLicenseInfo().then((result) => {
      setLicenseInfo(result);
    });
  }, []);

  if (!orgInfo || !licenseInfo) return <Loader />;

  return (
    <div className={className}>
      <div style={{ maxWidth: 750, padding: "0px 30px" }}>
        <OrgProfile orgInfo={orgInfo} />
        <LicenseInfo licenseInfo={licenseInfo} />
        {isElectron && isOnPremises && <UpdateApiEndpoint />}
        <MonolithDesktopInstallers />
        <DateFormat />
        <CurrencyFormat />
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
`;

export default SystemSettings;
