import styled from "styled-components";

const NoteStateIndicator = styled.div`
  user-select: none;
  display: flex;
  position: ${({ position }) => (!position ? "relative" : "absolute")};
  top: ${({ position }) => (position?.includes("top") ? "10px" : "auto")};
  right: ${({ position }) => (position?.includes("right") ? "10px" : "auto")};
  bottom: ${({ position }) => (position?.includes("bottom") ? "10px" : "auto")};
  left: ${({ position }) => (position?.includes("left") ? "10px" : "auto")};
  width: fit-content;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 10px;
  font-weight: 600;
  background-color: ${({ color, theme }) =>
    color === "success"
      ? `${theme.palette.success.main}35`
      : color === "error"
      ? `${theme.palette.error.main}35`
      : color === "warning"
      ? `${theme.palette.warning.main}35`
      : color === "info"
      ? `${theme.palette.info.main}35`
      : color === "primary"
      ? `${theme.palette.primary.main}35`
      : theme.palette.background.secondary};

  color: ${({ color, theme }) =>
    color === "success"
      ? theme.palette.success.main
      : color === "error"
      ? theme.palette.error.main
      : color === "warning"
      ? theme.palette.warning.main
      : color === "info"
      ? theme.palette.info.main
      : color === "primary"
      ? theme.palette.primary.main
      : theme.palette.text.secondary};
`;

export default NoteStateIndicator;
