import { httpDelete, httpGet, httpPatch, httpPost } from "../../connection.js";

const testAPI = async (values = {}) => {
  return httpPost("/integrations/mattermost/test", values);
};

const getSettings = async () => {
  return httpGet("/integrations/mattermost/settings");
};

const updateSettings = async (values = {}) => {
  return httpPatch("/integrations/mattermost/settings", values);
};

const getTeams = async () => {
  return httpGet("/integrations/mattermost/teams");
};

const completeSetup = async (values = {}) => {
  return httpPost("/integrations/mattermost/complete-setup", values);
};

const removeSetup = async () => {
  return httpDelete("/integrations/mattermost/remove-setup");
};

const MattermostAPI = {
  testAPI,
  updateSettings,
  getSettings,
  getTeams,
  completeSetup,
  removeSetup,
};

export default MattermostAPI;
