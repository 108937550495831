import styled from "styled-components/macro";
import { useTheme } from "@mui/material";
import Flyout, { FlyoutHeader } from "../../../Monolith-UI/Flyout/Flyout";
import TaskButton from "../../../Monolith-UI/TaskButton";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import CaseFlyoutContent from "./CaseFlyoutContent";

const CaseFlyout = styled(
  ({
    className,
    setIsFlyoutVisible,
    setFlyoutData,
    flyoutData,
    handleGetNextItem = () => {},
    handleGetPrevItem = () => {},
    reloadFn = () => {},
    columnDefs = [],
  }) => {
    const theme = useTheme();
    return (
      <Flyout
        onClose={() => {
          setIsFlyoutVisible(false);
          setFlyoutData(null);
        }}
      >
        <FlyoutHeader>
          <div style={{ width: "100%" }}>
            <h4
              className="flyout-title"
              style={{
                margin: "5px 0px",
                color: theme.palette.text.primary,
              }}
            >
              {flyoutData.case_number || "..."}
            </h4>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: theme.palette.text.secondary,
                  fontSize: 12,
                }}
              >
                Case Details
              </div>
              <div
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <TaskButton
                  onClick={() => handleGetPrevItem(flyoutData.case_id)}
                  style={{
                    fontSize: 10,
                  }}
                  variant="outlined"
                >
                  <KeyboardArrowLeftOutlinedIcon style={{ fontSize: 17 }} />
                </TaskButton>
                <TaskButton
                  onClick={() => handleGetNextItem(flyoutData.case_id)}
                  style={{
                    fontSize: 10,
                  }}
                  variant="outlined"
                >
                  <KeyboardArrowRightOutlinedIcon style={{ fontSize: 17 }} />
                </TaskButton>
              </div>
            </div>
          </div>
        </FlyoutHeader>
        <CaseFlyoutContent
          defaultInfo={flyoutData}
          reloadFn={reloadFn}
          columnDefs={columnDefs}
        />
      </Flyout>
    );
  }
)``;

export default CaseFlyout;
