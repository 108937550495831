import { useEffect, useRef, useState } from "react";
import { Form, GroupItem, SimpleItem } from "devextreme-react/ui/form";
import { v4 } from "uuid";
import { Button } from "@mui/material";

import { getEvidenceTypes } from "../../../../api";
import { storeFprSettings } from "../../../../api/integrations/fpr";
import { Types } from "./";
import Loader from "../../../../components/Loader";
import { useSnackbar } from "notistack";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

export const EvidenceTypesMapping = ({ fprSettings }) => {
  const createReportForm = useRef(null);
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const { isLoading, data: evidenceTypes } = useQuery({
    queryKey: ["evidenceTypes"],
    queryFn: getEvidenceTypes,
    refetchOnWindowFocus: false,
    initialData: [],
  });

  if (isLoading) return <Loader />;

  const { evidence: evidenceFpr } = fprSettings.settings;

  const handleEvidenceSettings = async () => {
    const formData = {
      ...createReportForm.current.instance.option("formData"),
    };

    storeFprSettings({ evidence: formData }).then((result) => {
      queryClient.invalidateQueries({ queryKey: ["fpr:settings"] });

      enqueueSnackbar(`Evidence Mapping Updated.`, {
        variant: "success",
      });
    });
  };

  return (
    <>
      <p>
        Map Monolith evidence types to the evidence categories in the FPR report
        template.
      </p>
      <EvidenceTypeForm
        createReportForm={createReportForm}
        evidenceFpr={evidenceFpr}
        evidenceTypes={evidenceTypes}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          margin: "20px 0px",
        }}
      >
        <Button
          variant="contained"
          size="small"
          onClick={handleEvidenceSettings}
        >
          Save Settings
        </Button>
      </div>
    </>
  );
};

const EvidenceTypeForm = ({ createReportForm, evidenceFpr, evidenceTypes }) => {
  return (
    <>
      {useMemo(() => {
        return (
          <Form ref={createReportForm} colCount={2} formData={evidenceFpr}>
            <GroupItem colSpan={2} colCount={4}>
              {Object.keys(Types).map((typeKey) => (
                <SimpleItem
                  key={v4()}
                  dataField={Types[typeKey]}
                  label={{ text: Types[typeKey] }}
                  colSpan={4}
                  editorType="dxTagBox"
                  editorOptions={{
                    valueExpr: "evi_type_id",
                    displayExpr: "evi_type",
                    items: evidenceTypes,
                    showClearButton: true,
                    showDropDownButton: true,
                    multiline: false,
                    showSelectionControls: true,
                    selectAllMode: "allPages",
                    dropDownOptions: { maxHeight: 250 },
                  }}
                />
              ))}
            </GroupItem>
          </Form>
        );
      }, [evidenceTypes])}
    </>
  );
};
