import { httpGet } from "../connection.js";

async function getSystemInfo() {
  return await httpGet("/system/info");
}

const SystemAPI = {
  getSystemInfo,
};

export default SystemAPI;
