import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import {
  CalendarIcon,
  SquareStackIcon,
  UsersIcon,
  ShapesIcon,
  ArrowLeftRightIcon,
  SignalIcon,
  X,
} from "lucide-react";
import moment from "moment";
import { Operators } from "../../Monolith-UI/QueryFilter/QueryFilter.js";
import TimeEntriesAPI from "../../api/TimeEntries/time-entries.js";
import UserApi from "../../api/users/users.js";
import DropdownMenu from "../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import getRandomColor from "../../utils/getRandomColor.js";

export const isDateInRange = (date, range) => {
  const parsedDate = !!date ? moment(date) : null;

  switch (range) {
    case "overdue":
      return !!parsedDate && parsedDate.isBefore(moment(), "date");
    case "today":
      return !!parsedDate && parsedDate.isSame(moment(), "date");
    case "tomorrow":
      return (
        !!parsedDate &&
        parsedDate.isAfter(moment().endOf("day")) &&
        parsedDate.isBefore(moment().endOf("day").add(1, "day"))
      );
    case "end_of_week":
      return (
        !!parsedDate &&
        parsedDate.isAfter(moment().endOf("day").add(1, "day")) &&
        parsedDate.add(2, "days").isBefore(moment().endOf("week"))
      );
    case "next_week":
      return (
        !!parsedDate &&
        parsedDate.isAfter(moment().endOf("week")) &&
        parsedDate.isBefore(moment().endOf("week").add(1, "week"))
      );
    case "two_weeks":
      return (
        !!parsedDate &&
        parsedDate.isAfter(moment().endOf("week").add(1, "week")) &&
        parsedDate.isBefore(moment().endOf("week").add(2, "week"))
      );
    case "three_weeks":
      return (
        !!parsedDate &&
        parsedDate.isAfter(moment().endOf("week").add(2, "week")) &&
        parsedDate.isBefore(moment().endOf("week").add(3, "week"))
      );
    case "this_month":
      return (
        !!parsedDate &&
        parsedDate.isAfter(moment().endOf("week").add(3, "week")) &&
        parsedDate.isBefore(moment().endOf("month"))
      );
    case "next_month":
      return (
        !!parsedDate &&
        parsedDate.isAfter(moment().endOf("month")) &&
        parsedDate.isBefore(moment().endOf("month").add(1, "month"))
      );
    case "due_later":
      return (
        !!parsedDate &&
        parsedDate.isAfter(moment().endOf("month").add(1, "month"))
      );
    case "no_due_date":
      return parsedDate === null;
    default:
      return false;
  }
};

const FilterItem = styled(
  ({ className, condition, onUpdateFilter, caseInfo, onRemoveFilter }) => {
    const op = Operators.find((op) => op.operator === condition.operator)?.name;

    const { data: TaskCategories } = useQuery({
      queryKey: ["task:categories"],
      queryFn: () => TimeEntriesAPI.getTimeCategories(),
      initialData: [],
    });

    const { data: users } = useQuery({
      queryKey: [
        "users",
        "list",
        {
          include_inactive: false,
          include_observers: false,
        },
      ],
      queryFn: () =>
        UserApi.getUsers({
          include_inactive: false,
          include_observers: false,
        }),
      placeholderData: (data) => data,
      initialData: [],
    });

    const menuItems = {
      category: [
        {
          checkboxGroup: true,
          value: condition.value || [],
          onSelectionChanged: (data) => {
            onUpdateFilter?.({
              field: "category",
              value: data.map((d) => d.value),
              label: "Category",
              operator: "isAnyOf",
            });
          },
          items:
            TaskCategories?.map((cat) => ({
              label: cat.category_name,
              value: cat.category_id,
              icon: ({ color, style }) => (
                <div
                  style={{
                    borderRadius: "50%",
                    height: 10,
                    width: 10,
                    backgroundColor: color,
                    ...style,
                  }}
                ></div>
              ),
              iconColor: getRandomColor(cat.category_id, cat.category_id),
            })) || [],
        },
      ],
      users: [
        {
          checkboxGroup: true,
          value: condition.value || [],
          onSelectionChanged: (data) => {
            onUpdateFilter({
              field: "user_id",
              value: data.map((d) => d.value),
              label: "Users",
              operator: "isAnyOf",
            });
          },
          items:
            users?.map((cat) => ({
              label: cat.full_name,
              value: cat.user_id,
            })) || [],
        },
      ],
    };

    return (
      <div className={className + " filter-item"}>
        <div className="filter-segment label">{condition.label}</div>
        <div className="filter-segment op">{op}</div>
        {condition.field === "category" && (
          <DropdownMenu
            menuItems={menuItems.category}
            title={"Select Task Category"}
            variant={"contained"}
            textColor={!!condition.value ? "primary" : "secondary"}
          >
            {condition.value.length === 1 ? (
              condition.value?.map((categoryId) => {
                const category = TaskCategories.find(
                  (category) => category.category_id === categoryId
                );
                return <div key={categoryId}>{category?.category_name}</div>;
              })
            ) : (
              <div>{condition.value.length} Categories</div>
            )}
          </DropdownMenu>
        )}
        {condition.field === "user_id" && (
          <DropdownMenu
            menuItems={menuItems.users}
            title={"Select Users"}
            variant={"contained"}
            textColor={!!condition.value ? "primary" : "secondary"}
          >
            {condition.value.length === 1 ? (
              condition.value?.map((userID) => {
                const user = users.find((u) => u.user_id === userID);
                return <div key={userID}>{user?.full_name}</div>;
              })
            ) : (
              <div>{condition.value.length} Users</div>
            )}
          </DropdownMenu>
        )}
        <div
          className="filter-segment close"
          onClick={() => onRemoveFilter?.(condition.field)}
        >
          <X size={14} />
        </div>
      </div>
    );
  }
)`
  user-select: none;
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;

  .filter-segment {
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 12px;

    background-color: ${({ theme }) => theme.palette.background.secondary};

    &.close {
      display: flex;
      align-items: center;
      padding: 2px;
      cursor: pointer;
      background-color: transparent;
      border: ${({ theme }) => `1px solid ${theme.palette.divider}`};
      color: ${({ theme }) => theme.palette.text.secondary};

      transition: all 0.1s ease-in-out;

      &:hover {
        background-color: ${({ theme }) => theme.palette.background.secondary};
        color: ${({ theme }) => theme.palette.text.primary};
      }
    }
  }
`;

export const FilterList = styled(
  ({ className, filter, onUpdateFilter, caseInfo, onRemoveFilter }) => {
    if (!filter) return null;
    if (filter.length === 0) return null;

    return (
      <div className={className}>
        {filter?.map?.((condition, index) => {
          return (
            <FilterItem
              key={index}
              condition={condition}
              onUpdateFilter={onUpdateFilter}
              caseInfo={caseInfo}
              onRemoveFilter={onRemoveFilter}
            />
          );
        })}
      </div>
    );
  }
)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  margin: 5px 0;
`;

export const TimeEntriesFilter = ({
  filter,
  onUpdateFilter,
  caseInfo,
  variant = "outlined",
}) => {
  const { data: TaskCategories } = useQuery({
    queryKey: ["task:categories"],
    queryFn: () => TimeEntriesAPI.getTimeCategories(),
  });

  const { data: users } = useQuery({
    queryKey: [
      "users",
      "list",
      {
        include_inactive: false,
        include_observers: false,
      },
    ],
    queryFn: () =>
      UserApi.getUsers({
        include_inactive: false,
        include_observers: false,
      }),
    placeholderData: (data) => data,
  });

  const handleUpdateFilter = (filterUpdates) => {
    onUpdateFilter?.(filterUpdates);
  };

  const menuItems = [
    {
      label: "Category",
      value: "category",
      icon: ShapesIcon,
      items: [
        {
          checkboxGroup: true,
          value: filter?.find((f) => f.field === "category")?.value || [],
          onSelectionChanged: (data) => {
            handleUpdateFilter({
              field: "category",
              value: data.map((d) => d.value),
              label: "Category",
              operator: "isAnyOf",
            });
          },
          items:
            TaskCategories?.map((cat) => ({
              label: cat.category_name,
              value: cat.category_id,
              icon: ({ color, style }) => (
                <div
                  style={{
                    borderRadius: "50%",
                    height: 10,
                    width: 10,
                    backgroundColor: color,
                    ...style,
                  }}
                ></div>
              ),
              iconColor: getRandomColor(cat.category_id, cat.category_id),
              onClick: handleUpdateFilter,
            })) || [],
        },
      ],
    },
    {
      label: "Users",
      value: "users",
      icon: UsersIcon,
      items: [
        {
          checkboxGroup: true,
          value: filter?.find((f) => f.field === "user_id")?.value || [],
          onSelectionChanged: (data) => {
            handleUpdateFilter({
              field: "user_id",
              value: data.map((d) => d.value),
              label: "Users",
              operator: "isAnyOf",
            });
          },
          items:
            users?.map((cat) => ({
              label: cat.full_name,
              value: cat.user_id,
              onClick: handleUpdateFilter,
            })) || [],
        },
      ],
    },
  ];

  return (
    <DropdownMenu
      menuItems={menuItems}
      title={"Filter Tasks"}
      variant={variant}
      arrow
    >
      Filter
    </DropdownMenu>
  );
};

export default TimeEntriesFilter;
