import { FC } from "react";
import DefaultContactForm, { ContactFormData } from "./DefaultContactForm";
import { FormTitle, FormWrapper } from "./components/MonolithFormComponents";
import ContactsAPI from "@/api/contacts";
import { useSnackbar, VariantType } from "notistack";
import LoggingAPI from "@/api/logging";
import { usePermissions } from "@/hooks/usePermissions";

interface CreateContactFormProps {
  width?: number | undefined;
  onSubmit: () => void; // Refetch query from parent component
  onCancel: () => void;
  caseId?: number;
}

const CreateContactForm: FC<CreateContactFormProps> = ({
  width,
  onSubmit,
  onCancel,
  caseId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = usePermissions();

  const handleSubmit = async (data: ContactFormData) => {
    console.log(caseId);
    try {
      await ContactsAPI.createContact({
        ...data,
        case_id: caseId,
      });
      onSubmit?.();
      enqueueSnackbar(`Contact ${data?.name || ""} created`, {
        variant: "success" as VariantType,
      });

      LoggingAPI.createLog({
        user_id: currentUser.user_id,
        message: `Created Contact: ${data?.name}`,
        event: "contact:create",
        object_type: "contact",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        "An error occurred while creating this item. Please try again.",
        { variant: "error" as VariantType }
      );
    }
  };

  return (
    <FormWrapper width={width}>
      <FormTitle>Create Contact</FormTitle>
      <DefaultContactForm
        onSubmit={handleSubmit}
        onCancel={onCancel}
        buttonProps={{ submitText: "Create Contact" }}
      />
    </FormWrapper>
  );
};

export default CreateContactForm;
