import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import styled, { useTheme } from "styled-components";
import { AnalysisAPI } from "../../../../../api/index.js";
import {
  Button,
  Flyout,
  FlyoutHeader,
  FlyoutTitle,
} from "@monolith-forensics/monolith-ui";
import { EditIcon, TrashIcon } from "lucide-react";
import FlyoutDetailsTab from "./FlyoutDetailsTab.js";
import FlyoutNotesTab from "./FlyoutNotesTab.js";
import {
  DeleteTimelineEventModal,
  EditTimelineEventModal,
} from "../../../../../components/Modals";
import FlyoutTraceIndicatorsTab from "./FlyoutTraceIndicatorsTab.js";
import TimelineActivity from "./TimelineActivity.js";

const tabDefs = [
  {
    label: "Details",
    value: "details",
  },
  {
    label: "Notes",
    value: "notes",
  },
  {
    label: "Indicators",
    value: "trace-indicators",
  },
  {
    label: "Activity",
    value: "activity",
  },
];

const TabPanel = styled(({ className, children, value }) => {
  return <div className={className}>{children}</div>;
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

TabPanel.displayName = "TabPanel";

const Tabs = styled(({ className, children, defaultValue, onChange }) => {
  const [activeTab, setActiveTab] = useState(defaultValue || "details");

  const handleChange = (tab) => {
    setActiveTab(tab);
    onChange?.(tab);
  };

  const tabPanels = children.filter(
    (child) => child?.type?.displayName === "TabPanel"
  );

  const activePanel = tabPanels.find(
    (child) => child.props.value === activeTab
  );

  return (
    <div className={className}>
      <div className="tabs-list">
        {tabDefs.map((tab) => (
          <div
            className={`tab`}
            data-active={activeTab === tab.value}
            onClick={() => handleChange(tab.value)}
            key={tab.value}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className="tab-content">{activePanel}</div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  .tabs-list {
    user-select: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    color: ${({ theme }) => theme.palette.text.secondary};
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  }

  .tab {
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid transparent;
  }

  .tab:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }

  .tab[data-active="true"] {
    border-bottom-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
  }

  .tab-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 0px;
  }
`;

const TimelineEventFlyout = ({
  open = false,
  onClose,
  event_uuid = "",
  defaultEventData,
  onEdit,
  onDelete,
}) => {
  const theme = useTheme();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["timeline-events", "list", { uuid: event_uuid }],
    queryFn: ({ queryKey }) => {
      const [, , query] = queryKey;
      return AnalysisAPI.TimelineEvents.get(query);
    },
    enabled: !!event_uuid,
    initialData: [defaultEventData],
  });

  const eventData = data?.data?.[0] || {};

  return (
    <Flyout open={open} onClose={() => onClose?.()}>
      {isLoading && <div>Loading...</div>}
      {!isLoading && (
        <>
          <FlyoutHeader>
            <FlyoutTitle>{eventData.event_name}</FlyoutTitle>
            <EditTimelineEventModal
              show={showEditModal}
              defaultFormData={{
                uuid: eventData?.uuid,
                event_name: eventData?.event_name,
                timestamp: eventData?.timestamp,
                event_type_id: eventData?.event_type?.event_type_id,
                artifact_id: eventData?.artifact?.artifact_id,
                account_id: eventData?.account?.account_id,
                description: eventData.description,
                case_id: eventData?.case?.case_id,
                case_uuid: eventData?.case?.uuid,
                source_evidence_uuid: eventData?.source_evidence?.uuid,
                target_evidence_uuid: eventData?.target_evidence?.uuid,
              }}
              onClose={() => setShowEditModal(false)}
              onCancel={() => setShowEditModal(false)}
              onSubmit={(data) => {
                refetch();
                onEdit?.(data);
                setShowEditModal(false);
              }}
            />
            <DeleteTimelineEventModal
              show={showDeleteModal}
              defaultFormData={{
                uuid: eventData?.uuid,
                event_name: eventData?.event_name,
                timestamp: eventData?.timestamp,
                event_type_id: eventData?.event_type?.event_type_id,
                artifact_id: eventData?.artifact?.artifact_id,
                account_id: eventData?.account?.account_id,
                description: eventData.description,
                case_id: eventData?.case?.case_id,
                case_uuid: eventData?.case?.uuid,
                evidence_uuid: eventData?.evidence?.uuid,
              }}
              onClose={() => setShowDeleteModal(false)}
              onCancel={() => setShowDeleteModal(false)}
              onSubmit={(data) => {
                onDelete?.(data);
                onClose?.();
                setShowDeleteModal(false);
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                alignItems: "center",
                color: theme.palette.text.secondary,
                fontWeight: 500,
                marginBottom: 5,
              }}
            >
              <div>Timeline Event</div>
              <Button
                size="xxs"
                variant="text"
                leftSection={<EditIcon size={14} />}
                color="primary"
                onClick={() => setShowEditModal(true)}
              >
                Edit
              </Button>
              <Button
                size="xxs"
                variant="text"
                leftSection={<TrashIcon size={14} />}
                color="error"
                onClick={() => setShowDeleteModal(true)}
              >
                Delete
              </Button>
            </div>
          </FlyoutHeader>
          <Tabs>
            <TabPanel value="details">
              <FlyoutDetailsTab defaultEventData={eventData} />
            </TabPanel>
            <TabPanel value="notes">
              <FlyoutNotesTab defaultEventData={eventData} />
            </TabPanel>
            <TabPanel value="trace-indicators">
              <FlyoutTraceIndicatorsTab defaultEventData={eventData} />
            </TabPanel>
            <TabPanel value="activity">
              <TimelineActivity defaultData={eventData} />
            </TabPanel>
          </Tabs>
        </>
      )}
    </Flyout>
  );
};

export default TimelineEventFlyout;
