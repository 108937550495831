import { usePermissions } from "../../../hooks/usePermissions";
import DropdownMenu from "../../../Monolith-UI/DropdownMenu/DropdownMenu.js";

import { MoreHorizontal } from "lucide-react";
import { useTheme } from "styled-components";

const CaseMoreButton = ({
  handleEditCase = () => {},
  handleDeleteCase = () => {},
}) => {
  const theme = useTheme();
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();

  const menuItems = [
    {
      label: "Edit Case Details",
      onClick: () => handleEditCase(),
      permission: MONOLITH_PERMISSIONS.CASES_UPDATE,
    },
    {
      label: "Delete Case",
      onClick: () => handleDeleteCase(),
      permission: MONOLITH_PERMISSIONS.CASES_DELETE,
    },
  ].filter((item) => hasPermission(item.permission));

  if (menuItems.length === 0) {
    return null;
  }

  return (
    <DropdownMenu
      menuItems={menuItems || []}
      title={"More Actions"}
      variant="text"
      buttonProps={{
        style: {
          color: theme.palette.text.secondary,
          fontSize: 12,
          fontWeight: 600,
        },
      }}
    >
      <MoreHorizontal size={18} />
    </DropdownMenu>
  );
};

export default CaseMoreButton;
