import { httpDelete, httpGet, httpPatch, httpPost } from "../connection";

//Get Groups
export async function getCheckListGroups(checkListID) {
  return await httpGet(`/qa/checklists/${checkListID}/groups`);
}

//Create Group
export async function addCheckListGroup(values) {
  return await httpPost(`/qa/checklists/${values.checklist_id}/groups`, values);
}

//Update Group
export async function editCheckListGroup(values) {
  return await httpPatch(
    `/qa/checklists/${values.checklist_id}/groups/${values.group_id}`,
    values
  );
}

//Delete Group
export async function deleteCheckListGroup(values) {
  return await httpDelete(
    `/qa/checklists/${values.checklist_id}/groups/${values.group_id}`
  );
}

const ChecklistGroupsAPI = {
  getCheckListGroups,
  createChecklistGroup: addCheckListGroup,
  editCheckListGroup,
  deleteCheckListGroup,
};

export default ChecklistGroupsAPI;
