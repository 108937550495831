import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Popup } from "devextreme-react/ui/popup";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import { useAuth } from "../../contexts/AuthContext";
import { useSnackbar } from "notistack";

import { createMonolithReport } from "../../api";
import ToolBarItems from "../../components/ToolBarItems";
import { useNavigate } from "react-router-dom";

import { CATEGORY_MAP } from "./CategoryMap";
import StorageParamsForm from "./params/StorageParams";
import TimeEntriesParamsForm from "./params/TimeEntriesParams";

import { getFprSettings } from "../../api/integrations/fpr";
import CollapseSection from "../../components/CollaspeSection";
import AcquisitionParamsForm from "./params/AcquisitionParams";
import CaseParamsForm from "./params/CaseParams";
import EvidenceParamsForm from "./params/EvidenceParams";
import QaEntryParamsForm from "./params/QaEntryParams";
import FprParamsForm from "./params/FprParams";
import { Modal, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

const CreateReportModal = ({
  open,
  handleClose = () => {},
  onSubmit = (res) => {},
}) => {
  const theme = useTheme();
  const [reportCategory, setReportCategory] = useState(null);
  const createReportForm = useRef(null);
  const paramsForm = useRef(null);
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const { data: fprSettings } = useQuery({
    queryKey: ["fprSettings"],
    queryFn: () => getFprSettings(),
  });

  const fprEnabled = fprSettings?.[0]?.settings
    ? JSON.parse(fprSettings?.[0]?.settings)?.enabled
    : false;

  const CATEGORY_ARRAY = [
    {
      category_id: CATEGORY_MAP.CASES,
      category: "Cases - Overview",
    },
    {
      category_id: CATEGORY_MAP.EVIDENCE,
      category: "Evidence - Overview",
    },
    {
      category_id: CATEGORY_MAP.STORAGE,
      category: "Storage - Overview",
    },
    { category_id: CATEGORY_MAP.QA_ENTRIES, category: "QA Entries - Overview" },
    {
      category_id: CATEGORY_MAP.ACQUISITIONS,
      category: "Acquisitions - Overview",
    },
    {
      category_id: CATEGORY_MAP.TIME_ENTRIES,
      category: "Time Entries - Overview",
    },
  ];

  if (fprEnabled) {
    CATEGORY_ARRAY.push({
      category_id: CATEGORY_MAP.FPR,
      category: "Forensic Partner Reporting - FPR",
    });
  }

  const handleResetForm = () => {
    createReportForm.current.instance.resetValues();
  };

  const handleSubmit = () => {
    if (
      createReportForm.current.instance.validate().isValid &&
      paramsForm.current.instance.validate().isValid
    ) {
      let formData = {
        ...createReportForm.current.instance.option().formData,
        ...paramsForm.current.instance.option().formData,
      };

      formData.created_by_id = currentUser.user_id;

      if (formData.report_parameters) {
        if (formData.report_parameters.time_interval) {
          if (formData.report_parameters.time_interval === "Custom") {
            formData.report_parameters.start_date = moment(
              formData.report_parameters.start_date
            ).toISOString();
            formData.report_parameters.end_date = moment(
              formData.report_parameters.end_date
            ).toISOString();
          }
          if (formData.report_parameters.time_interval === "Today") {
            formData.report_parameters.start_date = moment()
              .startOf("day")
              .toISOString();
            formData.report_parameters.end_date = moment()
              .endOf("day")
              .toISOString();
          }
          if (formData.report_parameters.time_interval === "Yesterday") {
            formData.report_parameters.start_date = moment()
              .subtract(24, "hours")
              .startOf("day")
              .toISOString();
            formData.report_parameters.end_date = moment()
              .startOf("day")
              .toISOString();
          }
          if (formData.report_parameters.time_interval === "This Week") {
            formData.report_parameters.start_date = moment()
              .startOf("week")
              .toISOString();
            formData.report_parameters.end_date = moment()
              .endOf("day")
              .toISOString();
          }
          if (formData.report_parameters.time_interval === "Last Week") {
            formData.report_parameters.start_date = moment()
              .subtract(7, "days")
              .startOf("week")
              .toISOString();
            formData.report_parameters.end_date = moment()
              .subtract(7, "days")
              .endOf("week")
              .toISOString();
          }
          if (formData.report_parameters.time_interval === "This Month") {
            formData.report_parameters.start_date = moment()
              .startOf("month")
              .toISOString();
            formData.report_parameters.end_date = moment()
              .endOf("month")
              .toISOString();
          }
          if (formData.report_parameters.time_interval === "Last Month") {
            formData.report_parameters.start_date = moment()
              .subtract(1, "months")
              .startOf("month")
              .toISOString();
            formData.report_parameters.end_date = moment()
              .subtract(1, "months")
              .endOf("month")
              .toISOString();
          }
          if (formData.report_parameters.time_interval === "This Year") {
            formData.report_parameters.start_date = moment()
              .startOf("year")
              .toISOString();
            formData.report_parameters.end_date = moment()
              .endOf("year")
              .toISOString();
          }
          if (formData.report_parameters.time_interval === "Last Year") {
            formData.report_parameters.start_date = moment()
              .subtract(1, "years")
              .startOf("year")
              .toISOString();
            formData.report_parameters.end_date = moment()
              .subtract(1, "years")
              .endOf("year")
              .toISOString();
          }
        }
      } else formData.report_parameters = {};

      handleClose();

      createMonolithReport(formData).then((result) => {
        enqueueSnackbar(`Monolith Report Created.`, {
          variant: "success",
        });
        onSubmit(result);
      });
    }
  };

  const handleCancel = () => handleClose();

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 650,
          backgroundColor: theme.palette.background.default,
          position: "absolute",
          left: "50%",
          top: "20%",
          transform: "translate(-35%, -20%)",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Create Metrics Report
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <CollapseSection allowCollapse={false} title="Report Details">
            <Form ref={createReportForm} colCount={2}>
              <SimpleItem
                colSpan={1}
                dataField="report_name"
                label={{ text: "Report Name" }}
                isRequired={true}
              />
              <SimpleItem
                colSpan={1}
                dataField="category_id"
                label={{ text: "Report Category" }}
                isRequired={true}
                editorType="dxSelectBox"
                editorOptions={{
                  items: CATEGORY_ARRAY,
                  displayExpr: "category",
                  valueExpr: "category_id",
                  onValueChanged: (e) => setReportCategory(e.value),
                }}
              />
            </Form>
          </CollapseSection>
          <div>
            {reportCategory === CATEGORY_MAP.CASES && (
              <CaseParamsForm paramsForm={paramsForm} />
            )}
            {reportCategory === CATEGORY_MAP.EVIDENCE && (
              <EvidenceParamsForm paramsForm={paramsForm} />
            )}
            {reportCategory === CATEGORY_MAP.STORAGE && (
              <StorageParamsForm paramsForm={paramsForm} />
            )}
            {reportCategory === CATEGORY_MAP.TIME_ENTRIES && (
              <TimeEntriesParamsForm paramsForm={paramsForm} />
            )}
            {reportCategory === CATEGORY_MAP.ACQUISITIONS && (
              <AcquisitionParamsForm paramsForm={paramsForm} />
            )}
            {reportCategory === CATEGORY_MAP.QA_ENTRIES && (
              <QaEntryParamsForm paramsForm={paramsForm} />
            )}
            {reportCategory === CATEGORY_MAP.FPR && (
              <FprParamsForm paramsForm={paramsForm} />
            )}
          </div>
          <ToolBarItems
            submitText="Create Report"
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CreateReportModal;
