import styled from "styled-components";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { ClockIcon, KanbanIcon, Rows3Icon, SheetIcon } from "lucide-react";

const ViewSelector = styled(({ className, currentView, setCurrentView }) => {
  return (
    <ToggleGroup.Root
      className={className + " ToggleGroup"}
      type="single"
      defaultValue={currentView}
      onValueChange={(value) => {
        if (!value || value === currentView) return;
        setCurrentView(value);
      }}
    >
      <ToggleGroup.Item
        className="ToggleGroupItem"
        value="board"
        aria-label="Board View"
      >
        <KanbanIcon size={14} />
      </ToggleGroup.Item>
      <ToggleGroup.Item
        className="ToggleGroupItem"
        value="list"
        aria-label="List View"
      >
        <Rows3Icon size={14} />
      </ToggleGroup.Item>
      <ToggleGroup.Item
        className="ToggleGroupItem"
        value="table"
        aria-label="Table View"
      >
        <SheetIcon size={14} />
      </ToggleGroup.Item>
      <ToggleGroup.Item
        className="ToggleGroupItem"
        value="time"
        aria-label="Time View"
      >
        <ClockIcon size={14} />
      </ToggleGroup.Item>
    </ToggleGroup.Root>
  );
})`
  button {
    all: unset;
  }

  &.ToggleGroup {
    display: flex;
    border-radius: 4px;
  }

  .ToggleGroupItem {
    height: 22px;
    width: 25px;
    display: flex;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-right: none;
  }
  .ToggleGroupItem:first-child {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .ToggleGroupItem:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right: 1px solid ${({ theme }) => theme.palette.divider};
  }
  .ToggleGroupItem:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }
  .ToggleGroupItem[data-state="on"] {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  .ToggleGroupItem:focus {
    position: relative;
  }
`;

export default ViewSelector;
