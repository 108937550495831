import { useState } from "react";
import styled from "styled-components";

import { ChevronDown, ChevronRight } from "lucide-react";
import highlightText from "../utilities/highlightText.js";

const SearchResult = styled(({ className, result, ...props }) => {
  const [open, setOpen] = useState(true);

  return (
    <div className={className} data-selected={props?.selected}>
      {open && <div className="level-line"></div>}
      <div className="result-title" onClick={() => setOpen((prev) => !prev)}>
        {open ? (
          <ChevronDown size={16} style={{ marginRight: 5 }} />
        ) : (
          <ChevronRight size={16} style={{ marginRight: 5 }} />
        )}
        <div>{result.note_tag}</div>
      </div>
      {open && (
        <div className="result-snippets" onClick={() => props?.onClick(result)}>
          {result.snippets?.map((snippet) => {
            return (
              <div
                className="snippet"
                key={snippet}
                dangerouslySetInnerHTML={{
                  __html: highlightText(snippet, props?.searchQuery),
                }}
              ></div>
            );
          })}
        </div>
      )}
    </div>
  );
})`
  user-select: none;
  position: relative;
  height: fit-content;
  border: 1px solid transparent;
  transition: border 0.2s ease-in-out;

  &[data-selected="true"] {
    background-color: ${(props) => props.theme.palette.action.hover};
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.palette.divider};
  }

  &:has(.result-snippets:hover) {
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.palette.primary.main};
  }

  .level-line {
    position: absolute;
    left: 7px;
    top: 0;
    width: 1px;
    background-color: ${(props) => props.theme.palette.divider};
    margin-top: 15.5px;
    margin-bottom: 2.5px;
    height: calc(100% - 15px);
  }

  .result-title {
    display: flex;
    align-items: center;

    font-weight: 600;
    font-size: 0.75rem;
    color: ${(props) => props.theme.palette.text.primary};
  }

  .result-snippets {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    padding-left: 35px;
    width: 100%;
  }

  .snippet {
    font-size: 0.75rem;
    color: ${(props) => props.theme.palette.text.primary};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 15px);

    .highlight {
      font-weight: 600;
      background-color: #ea5c0054;
    }
  }
`;

export default SearchResult;
