import { Button, useTheme } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import TaskButton from "../../Monolith-UI/TaskButton.js";
import KeyboardReturnOutlinedIcon from "@mui/icons-material/KeyboardReturnOutlined";
import PhonelinkOutlinedIcon from "@mui/icons-material/PhonelinkOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import ItemChip from "../../components/ItemChip.js";
import styled from "styled-components";
import { useState } from "react";
import { usePermissions } from "../../hooks/usePermissions";
import { useAuth } from "../../contexts/AuthContext.js";
import { useQuery } from "@tanstack/react-query";
import CaseStorageAPI from "../../api/storage/index.js";
import Loader from "../../components/Loader.js";
import { Helmet } from "react-helmet-async";
import StorageOverview from "./StorageOverview.js";
import { DeleteModal } from "./modals/DeleteModal.js";
import { StorageItem } from "./StorageSection.js";

const BackLink = ({
  message,
  storageInfo,
}: {
  message: string;
  storageInfo: StorageItem;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  let linkTo = `/storage`;

  if (window.history?.state?.usr?.from?.includes("case:storage"))
    linkTo = `/cases/${storageInfo.case_id}/case-storage`;

  return (
    <>
      {/*@ts-ignore*/}
      <Link to={-1}>
        <TaskButton
          style={{ border: `1px solid ${theme.palette.divider}` }}
          title={message}
        >
          <KeyboardReturnOutlinedIcon style={{ fontSize: 20 }} />
        </TaskButton>
      </Link>
    </>
  );
};

const StorageMeta = ({ storageInfo }: { storageInfo: StorageItem }) => {
  const theme = useTheme();

  return (
    <>
      <ItemChip
        text={storageInfo.storage_number}
        Icon={PhonelinkOutlinedIcon}
        iconStyle={{ color: theme.palette.primary.main, fontSize: 12 }}
        title="Storage Number"
      />
    </>
  );
};

const StorageDetails = styled(({ className }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const { storage_id, active_tab } = useParams();
  const [currentTab] = useState(active_tab || "overview");

  const { isLoading, data: storageInfo } = useQuery({
    queryKey: [
      "storage:list",
      {
        uuid: Number.isNaN(Number(storage_id)) ? storage_id : null,
        storage_id: Number.isNaN(Number(storage_id)) ? null : storage_id,
      },
    ],
    queryFn: () =>
      CaseStorageAPI.getStorage({
        uuid: Number.isNaN(Number(storage_id)) ? storage_id : null,
        storage_id: Number.isNaN(Number(storage_id)) ? null : storage_id,
      }),
    select: (data) => data[0],
  });

  const onDelete = async () => {
    const hasHistory = window.history.length > 1;
    if (hasHistory) {
      navigate(-1);
    } else {
      navigate(`/storage`);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className={className}>
      {storageInfo && (
        <>
          <Helmet>
            <title>Storage Item Details | {storageInfo.storage_number}</title>
          </Helmet>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              margin: "10px 0px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
            >
              <BackLink
                message={"Back to Storage Items"}
                storageInfo={storageInfo}
              />
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              >
                Storage Item Details
              </div>
            </div>
            <StorageMeta storageInfo={storageInfo} />
            {hasPermission(MONOLITH_PERMISSIONS.STORAGE_DELETE) && (
              <div
                className="action-menu-item"
                style={{ marginLeft: "auto" }}
                onClick={() => setShowDeleteModel(true)}
              >
                <DeleteOutlineOutlinedIcon
                  style={{ color: theme.palette.error.main }}
                />
                <div className="action-menu-label">Delete</div>
              </div>
            )}
          </div>
          {currentTab === "overview" && (
            <StorageOverview storageInfo={storageInfo} />
          )}
          <DeleteModal
            open={showDeleteModel}
            handleClose={() => setShowDeleteModel(false)}
            onSubmit={() => onDelete()}
            defaultInfo={storageInfo}
          />
        </>
      )}
      {!isLoading && !storageInfo && (
        <>
          <Helmet>
            <title>Storage Details | Access Denied</title>
          </Helmet>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 100,
              padding: 15,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 5,
              width: "fit-content",
              margin: "auto",
            }}
          >
            <div style={{ fontSize: 24, fontWeight: "bold" }}>
              You do not have access to this storage item.
            </div>
            <div style={{ color: theme.palette.text.secondary, fontSize: 18 }}>
              Request access from the case lead or Monolith admin.
            </div>
            <Link to="/storage">
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: 20 }}
                size="small"
              >
                Return To Storage List
              </Button>
            </Link>
          </div>
        </>
      )}
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  overflow-y: auto;
  padding-right: 10px;

  & .action-menu-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin-right: 12px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
    & svg {
      font-size: 15px;
      margin-right: 5px;
    }
  }
`;

export default StorageDetails;
