import { Button, Modal, styled, Typography, useTheme } from "@mui/material";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import { useRef } from "react";
import { useParams } from "react-router";
import CaseFileAPI from "../../../api/CaseFiles/index.js";
import { useAuth } from "../../../contexts/AuthContext.js";

const RenameFileModal = styled(
  ({ className, handleClose, open, onSubmit, handleRename, defaultInfo }) => {
    const { currentUser } = useAuth();
    const { folder_id, case_id } = useParams();
    const theme = useTheme();
    const form = useRef(null);

    const verifyFolderName = async (file_name) => {
      let isValid = true;

      // Check for reserved folder names
      if (
        folder_id === "root" &&
        (file_name === "Evidence Photos" || file_name === "Note Images")
      ) {
        form.current.instance
          .getEditor("file_name")
          .option("validationErrors", [
            { message: "This is a reserved folder name." },
          ]);
        isValid = false;
      }

      if (isValid) {
        // Check that folder name is unique
        const checkUnique = await CaseFileAPI.get({
          case_id,
          parent_folder_id: folder_id || "root",
        });

        const folder = checkUnique.find(
          (folder) => folder.file_name.toLowerCase() === file_name.toLowerCase()
        );

        if (folder) {
          form.current.instance
            .getEditor("file_name")
            .option("validationErrors", [
              { message: "File Name already exists." },
            ]);
          isValid = false;
        }
      }

      if (!isValid) {
        // Set form to invalid
        form.current.instance.getEditor("file_name").option("isValid", false);
        form.current.instance.getEditor("file_name").focus();
      }

      return isValid;
    };

    const handleSubmit = async () => {
      //Check if valid
      if (!form.current.instance.validate().isValid) return;

      //Get form data
      const { file_name } = form.current.instance.option("formData");

      if (!(await verifyFolderName(file_name))) return;

      CaseFileAPI.update({
        file_name,
        uuid: defaultInfo.uuid,
        case_id,
      })
        .then((res) => {
          onSubmit({ ...defaultInfo, newName: file_name });
        })
        .catch((err) => {
          console.log(err);
        });

      handleClose();
    };

    return (
      <>
        <Modal open={open} onClose={handleClose} className={className}>
          <div
            style={{
              marginTop: 20,
              marginBottom: 20,
              width: 400,
              backgroundColor: theme.palette.background.default,
              position: "absolute",
              left: "50%",
              top: "20%",
              transform: "translate(-35%, -20%)",
              padding: 20,
            }}
          >
            {open && (
              <>
                <div style={{ marginBottom: 20 }}>
                  <Typography variant="h4" display="inline">
                    Rename File
                  </Typography>
                </div>
                <Form
                  ref={form}
                  colCount={1}
                  onContentReady={(e) => {
                    e.component.getEditor("file_name").focus();
                  }}
                  onEditorEnterKey={handleSubmit}
                >
                  <SimpleItem
                    autoFocus={true}
                    dataField={"file_name"}
                    label={{ text: "File Name", visible: true }}
                    editorOptions={{
                      placeholder: "Enter a file name",
                      value: defaultInfo.file_name,
                    }}
                    validationRules={[
                      { type: "required", message: "File Name is required" },
                      {
                        type: "stringLength",
                        min: 1,
                        max: 255,
                        message:
                          "File name must be between 1 and 255 characters",
                      },
                      {
                        type: "pattern",
                        pattern: "^[a-zA-Z0-9_(){}\\[\\]\\-\\.\\s]+$",

                        message:
                          "File name can only contain letters, numbers, spaces, underscores, and dashes",
                      },
                    ]}
                  />
                </Form>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: 20,
                  }}
                >
                  <Button size="small" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: 10 }}
                    size="small"
                    onClick={handleSubmit}
                  >
                    Rename File
                  </Button>
                </div>
              </>
            )}
          </div>
        </Modal>
      </>
    );
  }
)`
  z-index: 9999;
  .subtitle {
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-bottom: 20px;
  }
`;

export default RenameFileModal;
