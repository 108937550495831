import styled from "styled-components";

const BasicProgressBar = styled(({ className, percentage }) => {
  return (
    <div className={className}>
      <div className="progress-bar">
        <div
          className="progress-bar-fill"
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
    </div>
  );
})`
  border-radius: 5px;
  width: 100%;
  .progress-bar {
    height: 5px;
    width: 100%;
    background-color: ${({ theme }) => theme.palette.divider};
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
  }
  .progress-bar-fill {
    height: 100%;
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    transition: width 0.2s ease;
  }
`;

export default BasicProgressBar;
