import AcquisitionsAPI from "../../api/acquisitions/index.js";
import EvidenceAPI from "../../api/evidence/index.js";
import UserApi from "../../api/users/users.js";

const AcquisitionDimensions = [
  {
    name: "Acquisition Name",
    field: "acq_name",
    id: "acq_name",
    type: "text",
    mode: "text",
  },
  {
    name: "Software Version",
    field: "tool_version",
    id: "tool_version",
    type: "text",
    mode: "text",
  },
  {
    name: "Evidence Number",
    field: "evidence_number",
    id: "evidence_number",
    type: "text",
    mode: "text",
  },
  {
    name: "Evidence UUID",
    field: "evidence_uuid",
    id: "evidence_uuid",
    type: "text",
    mode: "text",
  },
  {
    name: "Evidence Name",
    field: "evidence_item_name",
    id: "evidence_item_name",
    type: "text",
    mode: "text",
  },
  {
    name: "Evidence Model Number",
    field: "evidence_model_number",
    id: "evidence_model_number",
    type: "text",
    mode: "text",
  },
  {
    name: "Evidence Unique ID",
    field: "evidence_unique_id",
    id: "evidence_unique_id",
    type: "text",
    mode: "text",
  },
  {
    name: "Linked Contact",
    field: "linked_contact",
    id: "linked_contact",
    type: "text",
    mode: "text",
  },
  {
    name: "Description",
    field: "description",
    id: "description",
    type: "text",
    mode: "text",
  },
  {
    name: "Storage",
    field: "storage_number",
    id: "storage_number",
    type: "text",
    mode: "text",
  },
  {
    name: "Hash 1",
    field: "hash_1",
    id: "hash_1",
    type: "text",
    mode: "text",
  },
  {
    name: "Hash 2",
    field: "hash_2",
    id: "hash_2",
    type: "text",
    mode: "text",
  },
  {
    name: "Format",
    field: "format",
    id: "format",
    type: "string",
    mode: "multi-select",
    key: ["acquisition:formats:list"],
    selectionDisplayName: "format",
    selectionIdField: "format",
    queryFn: (params) => AcquisitionsAPI.getFormats(),
  },
  {
    name: "Type",
    field: "acq_type",
    id: "acq_type",
    type: "string",
    mode: "multi-select",
    key: ["acquisition:types:list"],
    selectionDisplayName: "type",
    selectionIdField: "type",
    queryFn: (params) => AcquisitionsAPI.getTypes(),
  },
  {
    name: "Status",
    field: "status",
    id: "status",
    type: "string",
    mode: "multi-select",
    key: ["acquisitions:statuses"],
    selectionDisplayName: "status",
    selectionIdField: "status",
    queryFn: (params) => AcquisitionsAPI.getStatuses(),
  },
  {
    name: "Created On",
    field: "created_on",
    id: "created_on",
    type: "date",
    mode: "date",
  },
  {
    name: "Acquired By",
    field: "acquired_by_id",
    id: "acquired_by_id",
    type: "string",
    mode: "multi-select",
    key: ["users:list"],
    selectionDisplayName: "full_name",
    selectionIdField: "user_id",
    queryFn: (params) =>
      UserApi.getUsers({ includeObservers: false, includeInactive: false }),
  },
  {
    name: "Acquire Date",
    field: "acquire_date",
    id: "acquire_date",
    type: "date",
    mode: "date",
  },
  {
    name: "Forensic Software",
    field: "acq_tool",
    id: "acq_tool",
    type: "string",
    mode: "multi-select",
    key: ["acquisition:software:list"],
    selectionDisplayName: "software_name",
    selectionIdField: "software_name",
    queryFn: (params) => AcquisitionsAPI.getSoftwareNames(),
  },
  {
    name: "Hash 1 Algorithm",
    field: "hash_1_type",
    id: "hash_1_type",
    type: "string",
    mode: "multi-select",
    key: ["acquisition:hash_types:list"],
    selectionDisplayName: "hash_type",
    selectionIdField: "hash_type",
    queryFn: (params) => AcquisitionsAPI.getHashTypes(),
  },
  {
    name: "Hash 2 Algorithm",
    field: "hash_2_type",
    id: "hash_2_type",
    type: "string",
    mode: "multi-select",
    key: ["acquisition:hash_types:list"],
    selectionDisplayName: "hash_type",
    selectionIdField: "hash_type",
    queryFn: (params) => AcquisitionsAPI.getHashTypes(),
  },
  {
    name: "Evidence Type",
    field: "evidence_type",
    id: "evidence_type",
    type: "string",
    mode: "multi-select",
    key: ["evidence:types"],
    selectionDisplayName: "evi_type",
    selectionIdField: "evi_type",
    queryFn: (params) => EvidenceAPI.getEvidenceTypes(),
  },
  {
    name: "Evidence Provider",
    field: "evidence_provider",
    id: "evidence_provider",
    type: "string",
    mode: "multi-select",
    key: ["evidence:providers"],
    selectionDisplayName: "manufacturer",
    selectionIdField: "manufacturer",
    queryFn: (params) => EvidenceAPI.getEvidenceBrands(),
  },
  {
    name: "Location Path",
    field: "location_path",
    id: "location_path",
    type: "text",
    mode: "text",
  },
];

export default AcquisitionDimensions;
