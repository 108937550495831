import { useMemo } from "react";
import moment from "moment";
import TasksApi from "../../../../api/tasks/tasks";
import { useAuth } from "../../../../contexts/AuthContext";
import Loader from "../../../../components/Loader";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { nanoid } from "nanoid";

const UpcompingTasks = styled(({ className }) => {
  const { currentUser } = useAuth();

  const dueBy = useMemo(() => {
    return moment().add(30, "days").toISOString();
  }, []);

  const { data } = useQuery({
    queryKey: [
      "tasks:list:upcoming",
      {
        user_id: currentUser.user_id,
        due_by: dueBy,
        is_complete: 0,
        sort: "due_date",
      },
    ],
    queryFn: () =>
      TasksApi.getTasks({
        user_id: currentUser.user_id,
        due_by: dueBy,
        is_complete: 0,
        sort: "due_date",
      }),
  });

  if (!data) return <Loader />;

  if (data.data.length === 0)
    return <div style={{ textAlign: "center" }}>No Tasks Due Soon</div>;

  return (
    <div className={className}>
      {data.data.map((task) => (
        <TaskItem key={nanoid()} task={task} />
      ))}
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  height: calc(100% - 50px);
  padding: 0px 10px;
`;

const TaskItem = styled(({ className, task }) => {
  return (
    <Link to={`/cases/${task.case_id}/tasks/v/${task.uuid}`}>
      <div className={className} title="Go to Task">
        <div className="task-content">
          <div className="task-name" title={task.task_name}>
            {task.task_name}
          </div>
          <div className="case-number">{task.case_number}</div>
        </div>
        <div>
          <div className="due-date">Due {moment(task.due_date).fromNow()}</div>
        </div>
      </div>
    </Link>
  );
})`
  cursor: pointer;
  background-color: transparent;
  border: ${(props) => `1px solid ${props.theme.palette.divider}`};
  border-radius: 5px;
  padding: 5px 10px;
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  &:hover {
    border: ${(props) => `1px solid ${props.theme.palette.primary.main}`};
    cursor: pointer;
  }
  .task-content {
    max-width: 50%;
  }
  .task-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .case-number {
    color: ${(props) => props.theme.palette.text.secondary};
    font-size: 10px;
  }
  .due-date {
    color: ${(props) => props.theme.palette.text.secondary};
    font-size: 10px;
  }
`;

export default UpcompingTasks;
