import { AuditLogsDefs } from "@/components/ColumnDefinitions";
import { useInfiniteQuery } from "@tanstack/react-query";
import { AuditsAPI } from "../../../api";
import { Audit } from "@/types";
import { useDebouncedCallback } from "use-debounce";
import styled from "styled-components";
import { Table } from "@monolith-forensics/monolith-ui";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { getDateFormat } from "../../../utils/date-format";

const stateStoreKey = "audits:logs:list";

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1 1 auto;

  margin-top: 10px;
`;

const AuditLogsTable = ({ auditData }: { auditData: Audit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState<string>("");
  const query = { audit_uuid: auditData?.uuid, page: 1, pageSize: 100, search };

  const {
    data: infiniteData,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    isPending,
  } = useInfiniteQuery({
    queryKey: [stateStoreKey, query],
    queryFn: ({ pageParam }) =>
      AuditsAPI.getAuditLogs({ ...query, page: pageParam }),
    getNextPageParam: (lastPage, pages) => {
      return lastPage.nextPage;
    },
    getPreviousPageParam: (firstPage, pages) => {
      if (firstPage.page - 1 === 0) return null;
      return firstPage.page - 1;
    },
    initialPageParam: 1,
    enabled: !!query && !!query.audit_uuid,
    placeholderData: (data) => data,
  });

  const records =
    infiniteData?.pages?.reduce((acc, page) => {
      return [...acc, ...page.data];
    }, []) || [];

  const totalRecords = infiniteData?.pages?.[0]?.total || 0;

  const debouncedFetchNextPage = useDebouncedCallback(() => {
    if (!isFetchingNextPage) {
      fetchNextPage();
    }
  }, 50);

  const handleSearch = (searchText: string) => {
    setSearch(searchText);
  };

  const debouncedSearch = useDebouncedCallback(handleSearch, 300);

  const handleExportTable = () => {
    // show snackbar
    enqueueSnackbar("Exporting table...", {
      variant: "info",
    });

    AuditsAPI.exportAuditLogsList({
      query: {
        ...query,
      },
      type: "xlsx",
      columns: AuditLogsDefs.filter((c) => c.visible !== false).map((c) => {
        return { header: c.caption, ...c };
      }),
      date_format: getDateFormat({ isMoment: true, includeTime: true }),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      custom_date_format: getDateFormat({
        isMoment: true,
        includeTime: false,
      }),
    }).then((res) => {
      const { signedUrl, filename } = res;
      const el = document.createElement("a");
      el.href = signedUrl.replace(
        "http://localhost:3000",
        "http://localhost:3001"
      );
      el.download = filename;
      el.click();
      // remove snackbar
      el.remove();
    });
  };

  return (
    <StyledContent>
      <Table
        keyField="id"
        virtualized={true}
        loading={isLoading || isPending}
        data={records}
        totalRecords={totalRecords}
        columnProps={AuditLogsDefs}
        onScrollToBottom={debouncedFetchNextPage}
        tableMenuOptions={{
          enabled: true,
          searchOptions: {
            enabled: true,
            onSearch: debouncedSearch,
            placeholder: "Search Logs...",
          },
          exportOptions: {
            enabled: true,
            onExport: handleExportTable,
          },
          tableCountOptions: {
            recordsTotalEnabled: true,
          },
        }}
        manualSearch={true}
      />
    </StyledContent>
  );
};

export default AuditLogsTable;
