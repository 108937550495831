import { useEffect, useRef, useState } from "react";

// DevExtreme Form components
import "devextreme-react/text-area";
import { Form, EmptyItem, SimpleItem } from "devextreme-react/ui/form";

// Custom Modal component
import Modal from "../../../../Monolith-UI/Modal/Modal";
import { CustomField, GetTypes } from "../types";
import CasesApi from "@/api/cases";
import { CUSTOM_FIELD_CATEGORIES } from "../utils/customFieldConstants";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import shortUUID from "short-uuid";
import ErrorComponent from "../../../../Monolith-UI/Error";

interface CreateCustomFieldProps {
  handleOpen: boolean;
  customFields: CustomField[];
  setCustomFields: React.Dispatch<
    React.SetStateAction<null | Partial<CustomField>[]>
  >;
  handleCreateField: (
    values: Partial<CustomField>
  ) => Promise<{ message: string; field_id: number }>;
  getTypes: null | (() => Promise<GetTypes>);
  category: string;
  handleClose: () => void;
}

const CreateCustomField = ({
  handleOpen = false,
  customFields,
  setCustomFields,
  handleCreateField,
  getTypes = null,
  category = "",
  handleClose = () => {},
}: CreateCustomFieldProps) => {
  const form = useRef<any>();
  const [showSelections, setShowSelections] = useState<boolean>(false);
  const [associatedTypes, setAssociatedTypes] = useState<GetTypes | []>([]);
  const [errors, setErrors] = useState<{ uuid: string; error: string }[] | []>(
    []
  );
  const queryClient = useQueryClient();

  const highestSortValue =
    customFields?.slice(-1)[0]?.sort_value ||
    Math.floor(Math.random() * 10000000);

  const { data: caseCustomFields } = useQuery({
    queryKey: ["custom-fields", "case"],
    queryFn: () => CasesApi.getCaseCustomAttributes({ fieldsOnly: true }),
    enabled: category === CUSTOM_FIELD_CATEGORIES.INQUIRIES,
  });

  useEffect(() => {
    if (getTypes) {
      getTypes().then((result) => {
        setAssociatedTypes(result);
      });
    }
  }, []);

  const handleSubmit = async () => {
    if (form.current.instance.validate().isValid) {
      const values = Object.assign(
        {},
        form.current.instance.option("formData")
      );

      try {
        const result = await handleCreateField({
          field_name: values.field_name,
          is_required: values.is_required,
          editor_type: values.editor_type,
          options: values.options ? JSON.stringify(values.options) : null,
          description: values.description,
          type_ids: values.type_ids,
          sort_value: highestSortValue + 1,
          linked_id: values.linked_id,
        });
        setCustomFields((prev) => {
          if (!prev) {
            return null;
          } else {
            return [
              ...prev,
              {
                field_id: result.field_id,
                field_name: values.field_name,
                is_required: values.is_required,
                editor_type: values.editor_type,
                options: values.options,
                description: values.description,
                sort_value: highestSortValue + 1,
                enabled: 1,
                type_ids: values.type_ids,
                linked_id: values.linked_id,
              },
            ];
          }
        });
        queryClient.invalidateQueries({
          queryKey: ["custom-fields", `${category}`],
        });
        setShowSelections(false);
        handleClose();
      } catch (e) {
        setErrors((prevErrors) => [
          ...prevErrors,
          {
            uuid: shortUUID.generate(),
            error: `${e}`,
          },
        ]);
      }
    }
  };

  // Clear dropdown and tagbox selection box onCancel
  const onCancel = () => {
    setShowSelections(false);
    handleClose();
  };

  const handleErrorClose = (uuid: string) => {
    const updatedErrors = errors.filter((error) => error.uuid !== uuid);
    setErrors(updatedErrors);
  };

  return (
    <Modal
      open={handleOpen}
      onCancel={onCancel}
      title={`Create Custom ${category} Field`}
      submitText="Create"
      width={"550px"}
      style={{ marginTop: "150px" }}
      onSubmit={handleSubmit}
    >
      {errors &&
        errors.map((error) => {
          return (
            <ErrorComponent
              key={error.uuid}
              error={error.error}
              onClose={() => handleErrorClose(error.uuid)}
            />
          );
        })}
      <Form ref={form} style={{ marginBottom: "20px" }} colCount={2}>
        <SimpleItem
          dataField="field_name"
          label={{ text: "Field Name" }}
          isRequired={true}
          editorOptions={{
            placeholder: "Name this field...",
          }}
        ></SimpleItem>
        <SimpleItem
          dataField="is_required"
          label={{ text: "Is Required" }}
          isRequired={true}
          editorType="dxSelectBox"
          editorOptions={{
            items: [
              { value: 1, text: "Yes" },
              { value: 0, text: "No" },
            ],
            displayExpr: "text",
            valueExpr: "value",
          }}
        ></SimpleItem>
        <SimpleItem
          dataField="editor_type"
          label={{ text: "Editor Type" }}
          isRequired={true}
          editorType="dxSelectBox"
          editorOptions={{
            items: [
              { value: "textBox", text: "Text Box" },
              { value: "dateBox", text: "Date" },
              { value: "dropDown", text: "Drop Down Menu" },
              { value: "tagBox", text: "Tag Box" },
            ],
            displayExpr: "text",
            valueExpr: "value",
            onValueChanged: (e: any) => {
              if (["tagBox", "dropDown"].includes(e.value)) {
                setShowSelections(true);
              } else if (showSelections) {
                setShowSelections(false);
              }
            },
          }}
        ></SimpleItem>
        {category === CUSTOM_FIELD_CATEGORIES.INQUIRIES ? (
          <SimpleItem
            dataField="linked_id"
            label={{ text: "Case Field Mapping" }}
            isRequired={false}
            editorType="dxSelectBox"
            editorOptions={{
              items: [
                { value: null, text: "None" },
                ...(caseCustomFields
                  ?.filter((field: CustomField) => {
                    if (
                      form.current?.instance?.option("formData").editor_type ===
                        "dropDown" ||
                      form.current?.instance?.option("formData").editor_type ===
                        "textBox"
                    ) {
                      return (
                        field.editor_type === "dropDown" ||
                        field.editor_type === "textBox"
                      );
                    } else {
                      return (
                        field.editor_type ===
                        form.current?.instance?.option("formData").editor_type
                      );
                    }
                  })
                  .map((field: any) => ({
                    value: field.field_id,
                    text: field.field_name,
                  })) || []),
              ],
              displayExpr: "text",
              valueExpr: "value",
            }}
          ></SimpleItem>
        ) : (
          <EmptyItem />
        )}
        {getTypes ? (
          <SimpleItem
            dataField="type_ids"
            label={{ text: "Associated Types" }}
            editorType="dxTagBox"
            editorOptions={{
              items: associatedTypes,
              displayExpr: "evi_type",
              valueExpr: "evi_type_id",
              multiline: false,
              maxDisplayedTags: 2,
              showDropDownButton: true,
              showSelectionControls: true,
            }}
          />
        ) : (
          <EmptyItem />
        )}
        <EmptyItem />
        <SimpleItem
          dataField="options"
          label={{ text: "Selection Options" }}
          colSpan={2}
          isRequired={true}
          visible={showSelections}
          editorType="dxTagBox"
          editorOptions={{
            items: [],
            placeholder: "Enter Menu Items...",
            acceptCustomValue: true,
            dropDownOptions: {
              visible: false,
            },
            onCustomItemCreating: (e: any) => {
              const newValue = e.text;
              const currentItems = e.component.option("items");
              currentItems.unshift(newValue);
              e.component.option("items", currentItems);
              e.customItem = newValue;
            },
            multiline: false,
            showDropDownButton: false,
            openOnFieldClick: false,
          }}
        ></SimpleItem>
        <SimpleItem
          dataField="description"
          label={{ text: "Description" }}
          editorType="dxTextArea"
          colSpan={2}
        ></SimpleItem>
      </Form>
    </Modal>
  );
};

export default CreateCustomField;
