import React, { useEffect, useState } from "react";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import {
  getCaseStatuses,
  getCaseTypes,
  getClientOrgs,
  getEvidenceMeta,
  userApi,
} from "../../../api";
import CollapseSection from "../../../components/CollaspeSection";
import { getDateFormat } from "../../../utils/date-format";
import { useQuery } from "@tanstack/react-query";
import { useTheme } from "@mui/material";
import { Template } from "devextreme-react/core/template";
import UserSelectBoxItem from "../../../components/SelectBoxItems/UserSelectBoxItem.js";

const EvidenceParamsForm = ({ paramsForm, reportInfo }) => {
  const theme = useTheme();
  const [editorVisiblity, setEditorVisbility] = useState({
    startDate:
      reportInfo?.report_parameters?.timestamp_interval === "Custom"
        ? true
        : false,
    endDate:
      reportInfo?.report_parameters?.timestamp_interval === "Custom"
        ? true
        : false,
    dimension: !!reportInfo?.report_parameters?.time_interval ? true : false,
  });

  const {
    data: { brands: evidenceBrands, types: evidenceTypes },
  } = useQuery({
    queryKey: ["evidenceMeta"],
    queryFn: getEvidenceMeta,
    initialData: { brands: [], types: [] },
    refetchOnWindowFocus: false,
  });

  const { data: clientOrgs } = useQuery({
    queryKey: ["clients:orgs"],
    queryFn: () => getClientOrgs(),
    initialData: [],
    refetchOnWindowFocus: false,
  });

  const { data: caseLeads } = useQuery({
    queryKey: ["users", { includeObservers: false }],
    queryFn: () => userApi.getUsers({ includeObservers: false }),
    initialData: [],
    refetchOnWindowFocus: false,
  });

  const { data: caseStatuses } = useQuery({
    queryKey: ["caseStatuses"],
    queryFn: () => getCaseStatuses(),
    initialData: [],
    refetchOnWindowFocus: false,
  });

  const { data: caseTypes } = useQuery({
    queryKey: ["caseTypes"],
    queryFn: () => getCaseTypes(),
    initialData: [],
    refetchOnWindowFocus: false,
  });

  let startDateRef = null;
  let endDateRef = null;

  return (
    <>
      <CollapseSection
        title={"Report Parameters | Evidence - Overview"}
        allowCollapse={false}
      >
        <Form
          ref={paramsForm}
          colCount={2}
          defaultFormData={reportInfo || null}
        >
          <SimpleItem
            dataField="report_parameters.time_interval"
            label={{ text: "Time Interval", location: "top" }}
            colSpan={1}
            editorType="dxSelectBox"
            editorOptions={{
              items: [
                "Custom",
                "Today",
                "Yesterday",
                "This Week",
                "Last Week",
                "This Month",
                "Last Month",
                "This Year",
                "Last Year",
                "All Time",
              ],
              dropDownOptions: {
                maxHeight: 250,
              },
              onValueChanged: (e) => {
                setEditorVisbility({
                  ...editorVisiblity,
                  startDate: e.value === "Custom",
                  endDate: e.value === "Custom",
                  dimension: e.value !== null,
                });
              },
            }}
          />
          <SimpleItem
            dataField="report_parameters.timestamp_dimension"
            label={{ text: "Time Dimension", location: "top" }}
            colSpan={1}
            editorType="dxSelectBox"
            visible={editorVisiblity.dimension}
            editorOptions={{
              items: ["Created On", "Intake Date", "Date Released"],
            }}
          />
          <SimpleItem
            dataField="report_parameters.start_date"
            label={{ text: "Start Date", location: "top" }}
            editorType="dxDateBox"
            colSpan={1}
            isRequired={true}
            visible={editorVisiblity.startDate}
            editorOptions={{
              onInitialized: (e) => {
                startDateRef = e.component;
              },
              onValueChanged: (e) => {
                endDateRef.option("min", e.value);
              },
              type: "date",
              useMaskBehavior: true,
              displayFormat: getDateFormat({
                isMoment: false,
                includeTime: false,
              }),
            }}
          />
          <SimpleItem
            dataField="report_parameters.end_date"
            label={{ text: "End Date", location: "top" }}
            editorType="dxDateBox"
            colSpan={1}
            isRequired={true}
            visible={editorVisiblity.endDate}
            editorOptions={{
              onInitialized: (e) => {
                endDateRef = e.component;
              },
              onValueChanged: (e) => {
                startDateRef.option("max", e.value);
              },
              type: "date",
              useMaskBehavior: true,
              displayFormat: getDateFormat({
                isMoment: false,
                includeTime: false,
              }),
            }}
          />
          <SimpleItem
            dataField="report_parameters.organization"
            label={{ text: "Organization" }}
            colSpan={1}
            editorType="dxTagBox"
            editorOptions={{
              displayExpr: "organization",
              valueExpr: "organization",
              items: clientOrgs,
              showClearButton: false,
              showDropDownButton: true,
              multiline: false,
              showSelectionControls: true,
              selectAllMode: "allPages",
              dropDownOptions: {
                maxHeight: 250,
              },
            }}
          />
          <SimpleItem
            dataField="report_parameters.evidence_types"
            label={{ text: "Evidence Types" }}
            colSpan={1}
            editorType="dxTagBox"
            editorOptions={{
              valueExpr: "type",
              displayExpr: "type",
              items: evidenceTypes,
              showClearButton: false,
              showDropDownButton: true,
              multiline: false,
              showSelectionControls: true,
              selectAllMode: "allPages",
              dropDownOptions: {
                maxHeight: 250,
              },
            }}
          />
          <SimpleItem
            dataField="report_parameters.evidence_brands"
            label={{ text: "Evidence Providers" }}
            colSpan={1}
            editorType="dxTagBox"
            editorOptions={{
              valueExpr: "make",
              displayExpr: "make",
              items: evidenceBrands,
              showClearButton: false,
              showDropDownButton: true,
              multiline: false,
              showSelectionControls: true,
              selectAllMode: "allPages",
              dropDownOptions: {
                maxHeight: 250,
              },
            }}
          />
          <SimpleItem
            dataField="report_parameters.case_lead"
            label={{ text: "Case Lead" }}
            colSpan={1}
            editorType="dxTagBox"
            editorOptions={{
              displayExpr: "full_name",
              items: caseLeads.map((cl) => {
                return {
                  user_id: cl.user_id,
                  email: cl.email,
                  full_name: cl.full_name,
                  title: cl.title,
                };
              }),
              showClearButton: false,
              showDropDownButton: true,
              multiline: false,
              showSelectionControls: true,
              selectAllMode: "allPages",
              dropDownOptions: {
                maxHeight: 250,
              },
              itemTemplate: "report_parameters.case_lead",
            }}
          />
          <Template
            name="report_parameters.case_lead"
            render={(data) => <UserSelectBoxItem data={data} />}
          />
          <SimpleItem
            dataField="report_parameters.case_types"
            label={{ text: "Case Types" }}
            colSpan={1}
            editorType="dxTagBox"
            editorOptions={{
              valueExpr: "case_type",
              displayExpr: "case_type",
              items: caseTypes,
              showClearButton: false,
              showDropDownButton: true,
              multiline: false,
              showSelectionControls: true,
              selectAllMode: "allPages",
              dropDownOptions: {
                maxHeight: 250,
              },
            }}
          />
          <SimpleItem
            dataField="report_parameters.case_statuses"
            label={{ text: "Case Status" }}
            colSpan={1}
            editorType="dxTagBox"
            visible={editorVisiblity.case_status}
            editorOptions={{
              valueExpr: "status_name",
              displayExpr: "status_name",
              items: caseStatuses,
              showClearButton: false,
              showDropDownButton: true,
              multiline: false,
              showSelectionControls: true,
              selectAllMode: "allPages",
              dropDownOptions: {
                maxHeight: 250,
              },
            }}
          />
        </Form>
      </CollapseSection>
    </>
  );
};

export default EvidenceParamsForm;
