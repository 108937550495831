import { useSnackbar } from "notistack";
import { useEffect, useRef } from "react";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import { createContact, getContactTypes } from "../../api";
import ToolBarItems from "../../components/ToolBarItems";
import Flyout, { FlyoutHeader } from "../../Monolith-UI/Flyout/Flyout.js";
import { useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

const CreateContactFlyout = ({
  open = false,
  onSubmit = () => {},
  handleClose = () => {},
  caseInfo = null,
}) => {
  const theme = useTheme();
  const form = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const { data: contactTypes } = useQuery({
    queryKey: ["contacts:types"],
    queryFn: () => getContactTypes(),
    enabled: open,
    initialData: [],
  });

  const handleSubmit = () => {
    const formData = { ...form.current.instance.option().formData };

    if (form.current.instance.validate().isValid) {
      handleClose();

      createContact({
        ...formData,
        case_id: caseInfo ? caseInfo.case_id : null,
      }).then((result) => {
        formData.client_id = result.client_id;
        onSubmit(formData);
        enqueueSnackbar(`Contact Created and Added to Case.`, {
          variant: "success",
        });
      });
    }
  };
  if (!open) return null;

  return (
    <Flyout
      onClose={() => {
        handleClose();
      }}
      zIndex={1500}
    >
      <FlyoutHeader>
        <div style={{ width: "100%" }}>
          <h4
            style={{
              margin: "5px 0px",
              color: theme.palette.text.primary,
            }}
          >
            Create Contact
          </h4>
          <div
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                color: theme.palette.text.secondary,
                fontSize: 12,
              }}
            >
              Create a new linked contact. This contact will be added to the
              case and linked to this item.
            </div>
          </div>
        </div>
      </FlyoutHeader>
      <div style={{ padding: 20 }}>
        <Form ref={form} colCount={2}>
          <SimpleItem
            dataField="name"
            label={{ text: "Name" }}
            isRequired={true}
          />
          <SimpleItem
            dataField="organization"
            label={{ text: "Organization" }}
          />
          <SimpleItem dataField="email" label={{ text: "Email" }} />
          <SimpleItem
            dataField="office_number"
            label={{ text: "Office Number" }}
          />
          <SimpleItem
            dataField="mobile_number"
            label={{ text: "Mobile Number" }}
          />
          <SimpleItem dataField="address" label={{ text: "Address" }} />
          <SimpleItem dataField="city" label={{ text: "City" }} />
          <SimpleItem dataField="state" label={{ text: "State/Province" }} />
          <SimpleItem dataField="zipcode" label={{ text: "Postal Code" }} />
          <SimpleItem
            dataField="contact_type"
            label={{ text: "Contact Type" }}
            editorType="dxSelectBox"
            editorOptions={{
              dataSource: contactTypes,
              valueExpr: "contact_type",
              displayExpr: "contact_type",
              acceptCustomValue: true,
              onCustomItemCreating: (e) => {
                e.customItem = { contact_type: e.text };
                // Adds the entry to the data source
                e.component.getDataSource().store().insert(e.customItem);
                // Reloads the data source
                e.component.getDataSource().reload();
              },
            }}
          />
          <SimpleItem
            colSpan={2}
            dataField=""
            label={{ text: "Notes" }}
            editorType="dxTextArea"
            editorOptions={{ height: 150 }}
          />
        </Form>
        <ToolBarItems
          submitText="Create Contact"
          style={{ marginTop: 20 }}
          onSubmit={handleSubmit}
          onCancel={handleClose}
        />
      </div>
    </Flyout>
  );
};

export default CreateContactFlyout;
