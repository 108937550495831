import styled from "styled-components";
import { z } from "zod";
import { usePermissions } from "../../hooks/usePermissions";
import { useForm, zodResolver } from "@mantine/form";
import shortUUID from "short-uuid";
import diffFormData from "./utils/diffFormData.js";
import ButtonMenu from "./components/ButtonMenu.js";
import {
  Button,
  FieldLabel,
  Switch,
  TextInput,
} from "@monolith-forensics/monolith-ui";
import getTipTapExtensions from "../../Monolith-UI/RichTextEditor/extensions/index.js";
import getTipTapEditorProps from "../../Monolith-UI/RichTextEditor/props.js";
import { useEditor } from "@tiptap/react";
import RTEditor from "../../Monolith-UI/RTEditor/RTEditor.js";

const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  margin: 0px;
`;

const EditorWrapper = styled(
  ({ className, defaultValue, onChange, readOnly }) => {
    const extensions = getTipTapExtensions({
      exclude: ["Image"],
    });

    const editorProps = getTipTapEditorProps({
      exclude: ["Image"],
    });

    const editor = useEditor({
      content: defaultValue,
      editable: !readOnly,
      extensions,
      editorProps,
      onUpdate: ({ editor }) => {
        onChange?.(editor.getHTML());
      },
    });

    return (
      <div className={className}>
        <FieldLabel size="lg">Template Content</FieldLabel>
        <div className="inner-content">
          <RTEditor
            editor={editor}
            showToolbar={!readOnly}
            style={{ height: "100%" }}
          />
        </div>
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;

  .inner-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 0px;

    border: 1px solid ${({ theme }) => theme.palette.input.border};
    border-radius: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const schema = z.object({
  name: z
    .string({ message: "Name is required" })
    .min(1, { message: "Name is required" }),
});

const EditorTemplateForm = styled(
  ({ className, title, defaultInfo = {}, onSubmit, onCancel }) => {
    const { hasPermission } = usePermissions();

    const readOnly = !hasPermission();

    const form = useForm({
      mode: "uncontrolled",
      initialValues: {
        uuid: defaultInfo?.uuid || shortUUID.generate(),
        name: defaultInfo?.name,
        is_shared: defaultInfo?.is_shared,
        content: defaultInfo?.content,
      },
      validate: zodResolver(schema),
    });

    const handleSubmit = (content) => {
      const validateResult = form.validate();

      if (validateResult.hasErrors) {
        return;
      }
      const formData = form.getValues();

      const diff = diffFormData(defaultInfo, formData);

      onSubmit?.(formData, diff);
    };

    return (
      <div className={className}>
        <div>
          <div className="title-menu">
            <Title>{title}</Title>
            <ButtonMenu style={{ width: "fit-content" }}>
              <Button size="xxs" variant="text" color="gray" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                size="xxs"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </ButtonMenu>
          </div>
        </div>
        <div className="shared-switch">
          <Switch
            size="xs"
            key={form.key("is_shared")}
            {...form.getInputProps("is_shared")}
          />
          <FieldLabel size="sm">Share With Others</FieldLabel>
        </div>
        <TextInput
          size="lg"
          label="Template Name"
          placeholder="Enter template name"
          key={form.key("name")}
          {...form.getInputProps("name")}
        />
        <EditorWrapper
          key={form.key("content")}
          {...form.getInputProps("content")}
          readOnly={readOnly}
        />
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 1 1 auto;
  height: 0px;

  max-width: 800px;

  .title-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid ${({ theme }) => theme.palette.action.hover};
    padding-bottom: 10px;
  }

  .shared-switch {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: baseline;
    align-content: center;
  }
`;

export default EditorTemplateForm;
