import { Button, Divider, Grid, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { createOffice, getOffices, userApi } from "../../api";
import Loader from "../../components/Loader";
import { usePermissions } from "../../hooks/usePermissions";
import { Popup } from "devextreme-react/ui/popup";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import ToolBarItems from "../../components/ToolBarItems";
import { useSnackbar } from "notistack";
import { Template } from "devextreme-react/core/template";
import UserSelectBoxItem from "../../components/SelectBoxItems/UserSelectBoxItem.js";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import AccessDeniedMessage from "../../components/AccessDeniedMessage.js";
import { ItemTotal } from "../Cases/CaseEvidence/index";
import { BuildingIcon } from "lucide-react";
import { Office } from "../Locations";

const OfficesSection = ({}) => {
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const createOfficePopup = useRef<any>(null);

  const { data: offices, isFetched } = useQuery({
    queryKey: ["offices:list"],
    queryFn: () => getOffices(),
    enabled: hasPermission(MONOLITH_PERMISSIONS.OFFICES_READ_ALL),
  });

  if (!hasPermission(MONOLITH_PERMISSIONS.OFFICES_READ_ALL))
    return <AccessDeniedMessage />;

  return (
    <>
      <Helmet title="Offices" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3">Offices</Typography>
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />
      {!isFetched && <Loader />}
      {isFetched && (
        <>
          <div style={{ marginBottom: 20, display: "flex" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={!hasPermission(MONOLITH_PERMISSIONS.OFFICES_CREATE)}
              onClick={(event) => {
                createOfficePopup.current.instance.show();
              }}
              style={{
                minWidth: "fit-content",
                fontSize: 11,
                padding: "3px 6px",
              }}
            >
              + New Office
            </Button>
            <CreateOfficePopup createOfficePopup={createOfficePopup} />
            <ItemTotal total={offices.length || 0} Icon={BuildingIcon} />
          </div>
          <OfficesList offices={offices} />
        </>
      )}
    </>
  );
};

const OfficesList = styled(({ className, offices }) => {
  return (
    <div className={className}>
      <div className="offices-list-container">
        {offices.map((office: Office) => {
          return <OfficesItem key={office.office_id} office={office} />;
        })}
      </div>
    </div>
  );
})`
  & {
  }
  .offices-list-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-gap: 20px;
  }
`;

const OfficesItem = styled(({ className, office }) => {
  let addressBlock = "";
  if (office.office_city) addressBlock += office.office_city;
  if (office.office_region) {
    addressBlock += addressBlock !== "" ? ", " : "";
    addressBlock += office.office_region;
  }
  if (!addressBlock) addressBlock = "No Location";

  let manager = office?.manager?.full_name || "No Manager";

  let managerTitle = `Office Manager\n\n${manager}\n${
    office?.manager?.title || "No Title"
  }\n${office?.manager?.email || "No Email"}`;

  return (
    <div className={className}>
      <div className="office-item-container">
        <Link to={`/offices/${office.office_id}`}>
          <div
            className="office-header"
            title={office?.office_name || "Office"}
          >
            {office.office_name}
          </div>
        </Link>
        <div className="office-content">{addressBlock}</div>
        <div className="office-footer">
          <div className="footer-item" title={managerTitle}>
            <PersonOutlineIcon />
            <div>{manager}</div>
          </div>
          <div className="footer-item" title="Total users at this office">
            <GroupsOutlinedIcon />
            <div>{office?.total_users || 0}</div>
          </div>
        </div>
      </div>
    </div>
  );
})`
  & {
    position: relative;
    width: 400px;
    height: 120px;
    padding: 10px 20px;
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: ${({ theme }) => theme.palette.action.hover};
    }
  }
  .office-header {
    font-size: 20px;
    width: fit-content;
    max-width: calc(100% - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${({ theme }) => theme.palette.primary.main};
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .office-content {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 11px;
  }
  .office-footer {
    position: absolute;
    bottom: 10px;
    display: flex;
    align-items: center;
    align-content: center;
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 11px;
    svg {
      font-size: 16px;
      margin-right: 3px;
    }

    .footer-item {
      display: flex;
      align-items: center;
      align-content: center;
      margin-right: 15px;
      cursor: pointer;
      &:hover {
        color: ${({ theme }) => theme.palette.text.primary};
      }
    }
  }
`;

const CreateOfficePopup = ({
  createOfficePopup,
}: {
  createOfficePopup: React.MutableRefObject<any>;
}) => {
  const [users, setUsers] = useState([]);
  const form = useRef<any>(null);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const handleSubmit = () => {
    let formData = { ...form.current.instance.option().formData };
    if (form.current.instance.validate().isValid) {
      createOffice(formData).then((result) => {
        queryClient.refetchQueries({ queryKey: ["offices:list"] });
        enqueueSnackbar(`Office Created.`, {
          variant: "success",
        });
      });
      createOfficePopup?.current?.instance.hide();
    }
  };

  const handleCancel = () => {
    createOfficePopup.current.instance.hide();
  };

  useEffect(() => {
    userApi
      .getUsers({ includeObservers: true, includeInactive: false })
      .then((result) => {
        setUsers(result);
      });
  }, []);

  return (
    <>
      <Popup
        ref={createOfficePopup}
        title="Create Office"
        showCloseButton={true}
        showTitle={true}
        width={750}
        height={425}
        position={{
          offset: "100 0",
        }}
        onHidden={(e) => {
          form.current.instance.resetValues();
        }}
      >
        <Form ref={form} colCount={2}>
          <SimpleItem
            dataField="office_name"
            label={{ text: "Office Name" }}
            isRequired={true}
          />
          <SimpleItem dataField="office_address" label={{ text: "Address" }} />
          <SimpleItem dataField="office_city" label={{ text: "City" }} />
          <SimpleItem
            dataField="office_region"
            label={{ text: "State/Province" }}
          />
          <SimpleItem
            dataField="office_postal_code"
            label={{ text: "Postal Code" }}
          />
          <SimpleItem dataField="office_phone" label={{ text: "Phone" }} />
          <SimpleItem
            dataField="manager_id"
            label={{ text: "Manager" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: users,
              displayExpr: "full_name",
              valueExpr: "user_id",
              itemTemplate: "manager_id",
            }}
          />
          <Template
            name="manager_id"
            render={(data: any) => <UserSelectBoxItem data={data} />}
          />
        </Form>
        <div style={{ marginTop: 20 }}>
          <ToolBarItems
            submitText="Create Office"
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </div>
      </Popup>
    </>
  );
};

export default OfficesSection;
