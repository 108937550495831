import { httpDelete, httpGet, httpPost, httpPatch } from "../connection";

export async function createLabel(options) {
  return await httpPost(`/labels`, options);
}

export async function deleteLabel(labelID) {
  return await httpDelete(`/labels/${labelID}`);
}

export async function updateLabel(labelID, values) {
  return await httpPatch(`/labels/${labelID}`, values);
}

export async function getAllLabelInfo(options = { label_id: null }) {
  if (options.label_id == null) return await httpGet("/labels");
  else return await httpGet(`/labels/${options.label_id}`);
}

export async function getLabels(options = { label_id: null }) {
  if (options.label_id == null) return await httpGet("/labels");
  else return await httpGet(`/labels/${options.label_id}`);
}

const LabelsAPI = {
  createLabel,
  deleteLabel,
  updateLabel,
  getLabels,
};

export default LabelsAPI;
