import { httpDelete, httpGet, httpPatch, httpPost } from "../connection";

export async function getClients(options = {}) {
  return (await httpGet("/clients", options))?.data || [];
}

async function query(options) {
  return await httpPost("/clients/query", options);
}

export async function createClient(values) {
  return await httpPost("/clients", values);
}

export async function updateClient(client_id, values) {
  return await httpPatch(`/clients/${client_id}`, values);
}

export async function deleteClient(client_id) {
  return await httpDelete(`/clients/${client_id}`);
}

export async function getClientOrgs() {
  return await httpGet(`/clients/organizations`);
}

export async function exportClientsList(values) {
  return await httpPost("/clients/export", values);
}

const ClientsAPI = {
  getClients,
  query,
  createClient,
  updateClient,
  deleteClient,
  getOrganizations: getClientOrgs,
  exportClientsList,
};

export default ClientsAPI;
