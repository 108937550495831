import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { usePermissions } from "../../../hooks/usePermissions";
import ProgressBar from "../../../Monolith-UI/ProgressBar/ProgressBar.js";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import CasesApi from "../../../api/cases/index.js";

const CaseProgress = ({ caseInfo }) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { hasPermission } = usePermissions();
  const [progress, setProgress] = useState(caseInfo.progress);

  const { data: caseProgress } = useQuery({
    queryKey: ["cases:progress"],
    queryFn: () => CasesApi.getCaseProgress(),
  });

  const handleProgressUpdate = (item) => {
    CasesApi.updateCase(caseInfo.case_id, {
      progress: item.progress_name,
    }).then((res) => {
      queryClient.setQueryData(
        ["cases", "list", { case_id: parseInt(caseInfo.case_id) }],
        (oldData) => {
          const caseData = oldData[0];
          return [{ ...caseData, progress: item.progress_name }];
        }
      );
    });
    setProgress(item.progress_name);
  };

  useEffect(() => {
    setProgress(caseInfo.progress);
  }, [caseInfo.progress]);

  return (
    <>
      <div
        style={{
          padding: "20px",
          paddingTop: "30px",
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 5,
          overflow: "auto",
        }}
      >
        <ProgressBar
          defaultValue={progress}
          progressItems={caseProgress || []}
          onSelect={handleProgressUpdate}
          enabled={hasPermission()}
        />
      </div>
    </>
  );
};

export default CaseProgress;
