import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import CaseFileAPI from "../../../../api/CaseFiles/index.js";
import ActivityFeed from "../../../../components/ActivityFeed/ActivityFeed.js";

const Activity = styled(({ className, defaultData }) => {
  const { data, isLoading } = useQuery({
    queryKey: [
      "case-files",
      "logs",
      "list",
      {
        uuid: defaultData.uuid,
        object_type: defaultData.is_folder === 1 ? "folder" : "file",
      },
    ],
    queryFn: ({ queryKey }) => {
      return CaseFileAPI.getLogs(queryKey[3]);
    },
    enabled: !!defaultData.uuid,
  });

  const logs = data?.data || [];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={className}>
      <ActivityFeed logs={logs} variant="card" />
    </div>
  );
})`
  margin-top: 15px;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;
`;

export default Activity;
