import { getDB } from "./db.js";

const getCaseNoteTabs = async ({ uuid }) => {
  return getDB().CaseNoteTabs.get(uuid);
};

const putCaseNoteTabs = async (values = {}) => {
  return getDB().CaseNoteTabs.put(values);
};

const getEvidenceNoteTabs = async ({ uuid }) => {
  return getDB().EvidenceNoteTabs.get(uuid);
};

const putEvidenceNoteTabs = async (values = {}) => {
  return getDB().EvidenceNoteTabs.put(values);
};

const NoteTabs = {
  getCaseNoteTabs,
  putCaseNoteTabs,
  getEvidenceNoteTabs,
  putEvidenceNoteTabs,
};

export default NoteTabs;
