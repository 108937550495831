import axios from "axios";
import CryptoJS from "crypto-js";
import UploadsAPI from "../../api/Uploads/Uploads.js";

const AxiosInstance = axios.create({ baseURL: "", headers: {} });

export class Uploader {
  constructor(options) {
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    this.chunkSize = options.chunkSize || 1024 * 1024 * 10; // 10MB
    // number of parallel uploads
    this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15);
    this.file = options.file;
    this.fileName = options.fileName;
    this.filePath = options.filePath;
    this.aborted = false;
    this.uploadedSize = 0;
    this.progressCache = {};
    this.activeConnections = {};
    this.parts = [];
    this.uploadedParts = [];
    this.fileId = null;
    this.fileKey = null;
    this.onProgressFn = options.onUploadProgress || (() => {});
    this.onErrorFn = () => {};
    this.onCompleteFn = options.onComplete || (() => {});
    this.md5Hasher = CryptoJS.algo.MD5.create();
    this.sha1Hasher = CryptoJS.algo.SHA1.create();
    this.sha256Hasher = CryptoJS.algo.SHA256.create();
    this.md5 = null;
    this.sha1 = null;
    this.sha256 = null;
    this.hashingComplete = false;
    this.nextPartToHash = 1;
    this.numberOfparts = 0;
    this.hashedParts = [];
  }

  start() {
    this.initialize();
  }

  async initialize() {
    try {
      // Only use multipart upload for files larger than 100MB
      if (this.file.size > 1024 * 1024 * 100) {
        const initializeReponse = await UploadsAPI.createMultipartUpload({
          filePath: this.filePath,
          fileName: this.fileName,
          uuid: this.file.uuid,
        });

        this.fileId = initializeReponse.UploadId;
        this.fileKey = initializeReponse.Key;

        // retrieving the pre-signed URLs
        const numberOfparts = Math.ceil(this.file.size / this.chunkSize);
        this.numberOfparts = numberOfparts;

        this.parts = Array.from({ length: numberOfparts }, (v, i) => ({
          PartNumber: i + 1,
        }));

        this.sendNext();
      } else {
        await this.singlePartUpload();
      }
    } catch (error) {
      await this.complete(error);
    }
  }

  async singlePartUpload() {
    const signedUrl = await UploadsAPI.getSignedUrl({
      filePath: this.filePath,
      fileName: this.fileName,
      uuid: this.file.uuid,
    });

    const progressListener = this.handleProgress.bind(this, 0);

    const [hashes, uploadResult] = await Promise.all([
      this.calculateFileHashes(this.file),
      AxiosInstance.request({
        url: signedUrl,
        method: "PUT",
        data: this.file,
        maxContentLength: Infinity,
        onUploadProgress: progressListener,
      }),
    ]);

    this.onCompleteFn({
      fileName: this.fileName,
      filePath: this.filePath,
      md5: hashes.md5Hash,
      sha1: hashes.sha1Hash,
      sha256: hashes.sha256Hash,
    });
  }

  sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length;

    if (activeConnections >= this.threadsQuantity) {
      return;
    }

    if (!this.parts.length) {
      if (!activeConnections) {
        this.complete();
      }

      return;
    }

    const part = this.parts.shift();

    if (this.file && part) {
      const sentSize = (part.PartNumber - 1) * this.chunkSize;
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);

      const sendChunkStarted = () => {
        this.sendNext();
      };

      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext();
        })
        .catch((error) => {
          this.parts.push(part);

          this.complete(error);
        });
    }
  }

  async complete(error) {
    if (error && !this.aborted) {
      this.onErrorFn(error);
      return;
    }

    if (error) {
      this.onErrorFn(error);
      return;
    }

    try {
      await this.sendCompleteRequest();
      const interval = setInterval(() => {
        if (this.hashingComplete) {
          clearInterval(interval);

          this.md5 = this.md5Hasher.finalize().toString(CryptoJS.enc.Hex);
          this.sha1 = this.sha1Hasher.finalize().toString(CryptoJS.enc.Hex);
          this.sha256 = this.sha256Hasher.finalize().toString(CryptoJS.enc.Hex);

          this.onCompleteFn({
            fileName: this.fileName,
            uploadId: this.fileId,
            fileKey: this.fileKey,
            md5: this.md5,
            sha1: this.sha1,
            sha256: this.sha256,
          });
        }
      }, 500);
    } catch (error) {
      this.onErrorFn(error);
    }
  }

  async sendCompleteRequest() {
    if (this.fileId && this.fileKey) {
      await UploadsAPI.completeMultipartUpload({
        uploadId: this.fileId,
        filePath: this.filePath,
        parts: this.uploadedParts.sort((a, b) => a.PartNumber - b.PartNumber),
      });
    }
  }

  sendChunk(chunk, part, sendChunkStarted) {
    return new Promise((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then((status) => {
          if (status !== 200) {
            reject(new Error("Failed chunk upload"));
            return;
          }

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  handleProgress(part, event) {
    if (this.file) {
      if (
        event.type === "progress" ||
        event.type === "error" ||
        event.type === "abort"
      ) {
        this.progressCache[part] = event.loaded;
      }

      if (event.type === "uploaded") {
        this.uploadedSize += this.progressCache[part] || 0;
        delete this.progressCache[part];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);

      const total = this.file.size;

      const percentage = Math.round((sent / total) * 100);

      this.onProgressFn({
        progress: {
          loaded: sent,
          total: total,
          percentage: percentage,
        },
      });
    }
  }

  upload(chunk, part, sendChunkStarted) {
    // uploading each part with its pre-signed URL
    return new Promise(async (resolve, reject) => {
      if (this.fileId && this.fileKey) {
        const signedUrl = await UploadsAPI.getSignedUrl({
          uploadId: this.fileId,
          filePath: this.fileKey,
          fileName: this.fileName,
          partNumber: part.PartNumber,
        });

        part.signedUrl = signedUrl;

        this.activeConnections[part.PartNumber - 1] = {
          partNumber: part.PartNumber,
          uploadId: this.fileId,
        };

        sendChunkStarted();

        const progressListener = this.handleProgress.bind(
          this,
          part.PartNumber - 1
        );

        this.calculatePartHashes(chunk, part.PartNumber);

        AxiosInstance.request({
          url: part.signedUrl,
          method: "PUT",
          data: chunk,
          maxContentLength: Infinity,
          onUploadProgress: progressListener,
        }).then((response) => {
          const ETag = response.headers["etag"] || response.headers["Etag"];

          const uploadedPart = {
            PartNumber: part.PartNumber,
          };

          if (ETag) {
            uploadedPart.ETag = ETag.replaceAll('"', "");
          }

          this.uploadedParts.push(uploadedPart);

          resolve(response.status);
          delete this.activeConnections[part.PartNumber - 1];
        });
      }
    });
  }

  onProgress(onProgress) {
    this.onProgressFn = onProgress;
    return this;
  }

  onError(onError) {
    this.onErrorFn = onError;
    return this;
  }

  abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((id) => {
        this.activeConnections[id].abort();
      });

    this.aborted = true;
  }

  // Function to calculate the hash of a file part
  calculatePartHashes(part, partNumber) {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (partNumber === this.nextPartToHash) {
          clearInterval(interval);

          const reader = new FileReader();
          reader.onload = async () => {
            const partData = reader.result;
            this.md5Hasher.update(CryptoJS.lib.WordArray.create(partData));
            this.sha1Hasher.update(CryptoJS.lib.WordArray.create(partData));
            this.sha256Hasher.update(CryptoJS.lib.WordArray.create(partData));

            this.nextPartToHash = partNumber + 1;
            this.hashedParts.push(partNumber);
            if (this.hashedParts.length === this.numberOfparts) {
              this.hashingComplete = true;
            }
            resolve();
          };
          reader.onerror = function (error) {
            reject(error);
          };
          reader.readAsArrayBuffer(part);
        }
      }, 350);
    });
  }

  calculateFileHashes(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async () => {
        const fileData = reader.result;
        this.md5Hasher.update(CryptoJS.lib.WordArray.create(fileData));
        this.sha1Hasher.update(CryptoJS.lib.WordArray.create(fileData));
        this.sha256Hasher.update(CryptoJS.lib.WordArray.create(fileData));

        const md5Hash = this.md5Hasher.finalize().toString(CryptoJS.enc.Hex);
        const sha1Hash = this.sha1Hasher.finalize().toString(CryptoJS.enc.Hex);
        const sha256Hash = this.sha256Hasher
          .finalize()
          .toString(CryptoJS.enc.Hex);

        resolve({ md5Hash, sha1Hash, sha256Hash });
      };
      reader.onerror = function (error) {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  }
}

export default Uploader;
