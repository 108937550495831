import QaChecklistAPI from "../../api/quality_assurance/checklists.js";
import { Modal } from "@monolith-forensics/monolith-ui";
import DefaultQaChecklistItemForm from "../Forms/DefaultQaChecklistItemForm.js";

const CreateQaChecklistItemModal = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  ...props
}) => {
  const handleSubmit = async (data) => {
    await QaChecklistAPI.ChecklistItems.addCheckListItem(data);
    onSubmit?.(data);
  };

  return (
    <Modal open={show} onClose={onClose} style={{ width: 450 }}>
      <Modal.Title>Create QA Checklist Item</Modal.Title>
      <Modal.SubTitle style={{ marginBottom: 15 }}>
        Create a new Quality Assurance checklist item.
      </Modal.SubTitle>
      <DefaultQaChecklistItemForm
        onSubmit={handleSubmit}
        onCancel={onCancel}
        {...props}
      />
    </Modal>
  );
};

export default CreateQaChecklistItemModal;
