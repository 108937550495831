import { useInfiniteQuery } from "@tanstack/react-query";
import { SelectBox, TagBox } from "@monolith-forensics/monolith-ui";
import { useDebouncedCallback } from "use-debounce";
import { AnalysisAPI } from "../../../../api/index.js";
import { useState } from "react";
import styled from "styled-components";

const PAGE_SIZE = 500;

const AttributeItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.2em;

  .attr-label {
    font-weight: 500;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .attr-delimeter {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .attr-value {
  }
`;

const TraceAttributeSelector = ({ query, multiple = false, ...props }) => {
  const [searchValue, setSearchValue] = useState(null);
  const defaultQuery = {
    ...query,
    search: searchValue,
    pageSize: PAGE_SIZE,
  };

  const {
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ["trace-attributes", "list", defaultQuery],
    queryFn: ({ pageParam, queryKey }) => {
      const [, , query] = queryKey;
      return AnalysisAPI.TraceAttributes.get({
        ...query,
        page: pageParam,
      });
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.nextPage;
    },
    getPreviousPageParam: (firstPage, pages) => {
      if (firstPage.page - 1 === 0) return null;
      return firstPage.page - 1;
    },
    initialPageParam: 1,
    placeholderData: (data) => data,
  });

  const debouncedFetchNextPage = useDebouncedCallback((e) => {
    fetchNextPage();
  }, 50);

  // Detect scroll to bottom
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 100 * data?.pages?.length) {
      if (hasNextPage && !isFetchingNextPage) {
        debouncedFetchNextPage();
      }
    }
  };

  const records = data?.pages
    ?.reduce((acc, page) => {
      return [...acc, ...page.data];
    }, [])
    .map((item) => ({
      label: `${item.label} : ${item.value}`,
      value: item.uuid,
      group: item.category,
      data: item,
    }));

  if (multiple) {
    return (
      <TagBox
        {...props}
        loading={isLoading}
        data={records}
        grouped
        onScroll={handleScroll}
        searchFn={setSearchValue}
        renderOption={(data) => {
          const title = `(${data?.data?.category}) ${data?.data?.label}\n${data?.data?.value}`;
          return (
            <AttributeItem title={title}>
              <span className="attr-label">{data?.data?.label}</span>
              <span className="attr-delimiter">→</span>
              <span className="attr-value">{data?.data?.value}</span>
            </AttributeItem>
          );
        }}
      />
    );
  }

  return (
    <SelectBox
      {...props}
      loading={isLoading}
      data={records}
      onScroll={handleScroll}
      searchFn={setSearchValue}
    />
  );
};

export default TraceAttributeSelector;
