import styled, { useTheme } from "styled-components";
import { X } from "lucide-react";
import TaskButton from "../TaskButton";
import ToolBarItems from "../../components/ToolBarItems";

// Outer wrapper for modal
const Overlay = styled.div`
  position: fixed;
  inset: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1400;
`;

// Inner modal styling
const ModalContent = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: ${(props) =>
    props.width
      ? props.width
      : "700px"}; // Add media queries, force into full screen
  height: ${(props) => (props.height ? props.height : "auto")};
  max-height: 90vh;
  background-color: ${(props) =>
    props.bgColor}; // Theme needs to be declared in component
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  overflow-y: auto;
  padding: 20px;
  border-radius: 0px;
  ${(props) => props.style};

  @media (max-width: 715px) {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    transform: none;
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 0;
  }
`;

const Modal = ({
  open,
  onClose = null,
  style,
  children,
  width,
  height = null,
  title = "",
  titleComponent: TitleComponent = null,
  submitText = "",
  submitColor = "primary", // Use "error" for red in Delete buttons
  onSubmit = () => {},
  onCancel = () => {},
  showCloseButton = false,
  closeOnOutsideClick = false,
  showToolbar = true,
}) => {
  const theme = useTheme();

  // Modal closes on click outside of modal
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      if (closeOnOutsideClick) onClose();
    }
  };

  return (
    open && (
      <Overlay onClick={handleOverlayClick}>
        <ModalContent
          style={style}
          bgColor={theme.palette.background.default}
          width={width}
          height={height}
        >
          {showCloseButton && (
            <TaskButton
              onClick={onClose}
              style={{ position: "absolute", top: "10px", right: "10px" }}
            >
              <X size={"16px"} />
            </TaskButton>
          )}
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            {TitleComponent ? (
              <TitleComponent />
            ) : (
              <div className="title">{title}</div>
            )}
          </div>
          {children}
          {showToolbar && (
            <ToolBarItems
              style={{ marginTop: 20, marginBottom: 10 }}
              onSubmit={onSubmit}
              onCancel={onCancel}
              submitText={submitText || "Submit"}
              submitColor={submitColor}
            />
          )}
        </ModalContent>
      </Overlay>
    )
  );
};

export default Modal;
