import styled from "styled-components";

const UserSelectBoxItem = styled(({ className, data }) => {
  return (
    <div className={className}>
      <div className="user-fullname">{data.full_name}</div>
      <div className="user-title">{data.title}</div>
    </div>
  );
})`
  .user-fullname {
    margin-bottom: 5px;
  }
  .user-title {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

export default UserSelectBoxItem;
