import { monolithMoment } from "../../utils/date-format";

const TraceAccountDefs = [
  {
    dataField: "uuid",
    caption: "UUID",
    visible: false,
  },
  {
    dataField: "name",
    caption: "Name",
  },
  {
    dataField: "platform",
    caption: "Platform",
  },
  {
    dataField: "domain",
    caption: "Domain",
  },
  {
    dataField: "identifier",
    caption: "Identifier",
  },
  {
    dataField: "role",
    caption: "Role",
  },
  {
    dataField: "linked_contact",
    caption: "Linked Contact",
    render: (data) => data?.linked_contact?.name,
  },
  {
    dataField: "description",
    caption: "Description",
    visible: false,
  },
  {
    dataField: "created_by",
    caption: "Created By",
    visible: false,
    render: (data) => data?.created_by?.full_name,
  },
  {
    dataField: "created_on",
    caption: "Created On",
    visible: false,
    render: (data) => {
      return monolithMoment({
        timestamp: data.created_on,
        includeTime: true,
        includeZone: true,
      });
    },
  },
  {
    dataField: "updated_on",
    caption: "Updated On",
    visible: false,
    render: (data) => {
      return monolithMoment({
        timestamp: data.updated_on,
        includeTime: true,
        includeZone: true,
      });
    },
  },
];

export default TraceAccountDefs;
