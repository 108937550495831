// React and React-related imports
import { useState, useEffect, useMemo, SetStateAction } from "react";
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

// React Router
import { Link, useNavigate, useParams } from "react-router-dom";

// Styled-components
import styled, { useTheme } from "styled-components";

// Custom Hooks and Utilities
import { usePermissions } from "../../hooks/usePermissions";
import { getDateFormat, monolithMoment } from "../../utils/date-format";
import { nanoid } from "nanoid";
import { useQueryFilter } from "../../Monolith-UI/QueryFilter/QueryFilter.js";

// API and Components
import CaseStorageAPI from "../../api/storage/index.js";
import CaseStorageColumnsDefs from "../../components/CaseStorage/CaseStorageColumnsDefs.js";
import CaseStorageDimensions from "../../components/CaseStorage/CaseStorageDimensions.js";
import Loader from "../../components/Loader.js";
import Flyout, { FlyoutHeader } from "../../Monolith-UI/Flyout/Flyout.js";
import TaskButton from "../../Monolith-UI/TaskButton.js";
import synchronizeColumnState from "../../utils/synchronize-column-state.js";

// UI Components and Libraries
import Table, { Column, useTable } from "../../Monolith-UI/Table/Table.js";
import ComboButton from "../../Monolith-UI/ComboButton/ComboButton.js";
import { addCustomFieldsToColumnDefs } from "../../components/Evidence/CustomFieldCell.js";
import CreateStoragePopup from "./modals/CreateStoragePopup.js";
import { Input } from "@monolith-forensics/monolith-ui";

// Material-UI Icons
import {
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  ViewColumnOutlined as ViewColumnOutlinedIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
  FilterListOff as FilterListOffIcon,
  ZoomOutMapOutlined as ZoomOutMapOutlinedIcon,
  ZoomInMapOutlined as ZoomInMapOutlinedIcon,
  ReorderOutlined as ReorderOutlinedIcon,
} from "@mui/icons-material";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

// Material-UI Components
import { Button, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

// Helmet for managing document head tags
import { Helmet } from "react-helmet-async";
import { ItemTotal, SelectedItems } from "../Cases/CaseEvidence/index";
import EditStoragePopup from "./modals/EditStoragePopup.js";
import { ViewItemModal } from "./modals/ViewItemModal.js";
import { MoveItemsPopup } from "./modals/MoveItemsPopup.js";
import { ReleaseItemsPopup } from "./modals/ReleaseItemsPopup.js";
import { RemoveItemsPopup } from "./modals/RemoveItemsPopup.js";
import AssignStoragePopup from "./modals/AssignStoragePopup.js";
import { DeleteModal } from "./modals/DeleteModal.js";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { HardDriveIcon } from "lucide-react";
import { MONOLITH_PERMISSIONS } from "../../constants.js";
import { CustomAttribute } from "@/types";
import {
  ColumnProps,
  PageData,
  TableColumn,
  TableColumnDef,
} from "@/Monolith-UI/Table/types/Table";
import { CustomField } from "../Settings/CustomFieldSettings/types";
import { ColumnResize, SharedCaseTabListQuery } from "../Cases/types/Cases";

interface CaseStorageFlyoutProps {
  setIsFlyoutVisible: React.Dispatch<SetStateAction<boolean>>;
  setFlyoutData: React.Dispatch<SetStateAction<StorageItem | null>>;
  flyoutData?: StorageItem | null;
  columnState: ColumnProps<StorageItem>[];
  handleGetNextItem: (uuid: string) => void;
  handleGetPrevItem: (uuid: string) => void;
  handleRefresh: () => void;
  onDelete: (deleteInfo?: StorageItem) => void;
}

interface CaseStorageFlyoutContentProps {
  className?: string;
  defaultInfo?: StorageItem | null;
  columnDefs: ColumnProps<StorageItem>[];
  onDelete: (deleteInfo?: StorageItem) => void;
  onEdit: () => void;
  onCopy: () => void;
}

export interface StorageItem {
  capacity?: number;
  capacity_bytes?: number;
  capacity_gb?: number;
  capacity_unit?: string;
  case_assignment_id?: number;
  case_id?: number;
  case_number?: string;
  case_ref?: string;
  client_id?: number;
  client_name?: string;
  client_org?: string;
  created_on?: string;
  custom_attributes?: CustomAttribute[];
  date_added?: string;
  date_assigned?: string;
  encryption_key?: string;
  free_bytes?: number;
  free_space?: number;
  general_storage?: string;
  is_assigned?: string;
  is_general_storage?: number;
  is_locked?: number;
  item_id?: number;
  last_audit_date?: string;
  location_id?: number;
  location_name?: string;
  location_path?: string;
  make?: string;
  model_name?: string;
  model_number?: string;
  notes?: string;
  office_id?: number;
  serial_number?: string;
  storage_id?: number;
  storage_number?: string;
  stored_bytes?: number;
  type?: string;
  uuid?: string;
}

interface ViewItemState {
  message: string;
  showView: boolean;
  storage_id: number;
}

interface Accumulator {
  [key: string]: string;
}

const actions = [
  { value: 0, text: "Assign Storage Item" },
  { value: 1, text: "Move Selected Items" },
  { value: 2, text: "Release Selected Items" },
  { value: 3, text: "Remove Selected Items" },
];

const columnDefs = CaseStorageColumnsDefs;
const defaultQueryKey = "storage:list";

const CaseStorageFlyout = ({
  setIsFlyoutVisible,
  setFlyoutData,
  flyoutData,
  columnState,
  handleGetNextItem,
  handleGetPrevItem,
  handleRefresh = () => {},
  onDelete = () => {},
}: CaseStorageFlyoutProps) => {
  // will fix this any when we come up with the strategy to merge MUI and SC theme types
  const theme: any = useTheme();

  const handleClose = () => {
    setIsFlyoutVisible(false);
    setFlyoutData(null);
  };

  const onCopy = () => {
    handleClose();
    handleRefresh();
  };

  return (
    <Flyout
      onClose={() => {
        setIsFlyoutVisible(false);
        setFlyoutData(null);
      }}
    >
      <FlyoutHeader>
        <div style={{ width: "100%" }}>
          <h4
            className="flyout-title"
            style={{
              margin: "5px 0px",
              color: theme.palette.text.primary,
            }}
          >
            {flyoutData?.storage_number || "..."}
          </h4>
          <div
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                color: theme.palette.text.secondary,
                fontSize: 12,
              }}
            >
              Storage Item Details
            </div>
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <TaskButton
                onClick={() =>
                  handleGetPrevItem(flyoutData?.uuid ? flyoutData.uuid : "")
                }
                style={{
                  fontSize: 10,
                }}
                variant="outlined"
              >
                <KeyboardArrowLeftOutlinedIcon style={{ fontSize: 17 }} />
              </TaskButton>
              <TaskButton
                onClick={() =>
                  handleGetNextItem(flyoutData?.uuid ? flyoutData.uuid : "")
                }
                style={{
                  fontSize: 10,
                }}
                variant="outlined"
              >
                <KeyboardArrowRightOutlinedIcon style={{ fontSize: 17 }} />
              </TaskButton>
            </div>
          </div>
        </div>
      </FlyoutHeader>
      <FlyoutContent
        defaultInfo={flyoutData}
        columnDefs={columnState}
        onDelete={(deleteInfo?: StorageItem) => {
          handleClose();
          onDelete(deleteInfo);
        }}
        onEdit={handleRefresh}
        onCopy={onCopy}
      />
    </Flyout>
  );
};

const FlyoutContent = styled(
  ({
    className,
    defaultInfo,
    columnDefs,
    onDelete = () => {},
    onEdit = () => {},
    onCopy = () => {},
  }: CaseStorageFlyoutContentProps) => {
    const theme: any = useTheme();
    const { case_id } = useParams();

    const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showCopyModal, setShowCopyModal] = useState(false);
    const queryClient = useQueryClient();

    const { data: itemData } = useQuery<
      StorageItem[] | undefined,
      Error,
      StorageItem | undefined
    >({
      queryKey: ["case-storage:item", { uuid: defaultInfo?.uuid }],
      queryFn: () => CaseStorageAPI.getStorage({ uuid: defaultInfo?.uuid }),
      initialData: defaultInfo ? [defaultInfo] : [],
      select: (res) => {
        if (res && res.length > 0) {
          return res[0];
        }
        return undefined;
      },
    });

    const title = document.querySelector(".flyout-title") as HTMLElement;
    if (title)
      title.innerText =
        itemData?.storage_number || defaultInfo?.storage_number || "";

    const handleEdit = () => {
      setShowEditModal(true);
    };
    const handleDelete = () => {
      setShowDeleteModal(true);
    };

    const handleCopy = () => {
      setShowCopyModal(true);
    };

    const _onEdit = () => {
      onEdit();
      queryClient.refetchQueries({
        queryKey: ["case-storage:item", { uuid: defaultInfo?.uuid }],
      });
    };

    const dataFieldIgnoreList = ["notes"];

    const data = {
      ...itemData,
      ...(itemData?.custom_attributes?.reduce<Accumulator>(
        (acc, attr: CustomAttribute) => {
          if (attr.value === null) return acc;
          acc[`custom_field_${attr.field_id}`] = attr.value as string;
          return acc;
        },
        {}
      ) || {}),
    } as Record<string, string | number | undefined>;

    const detail = (
      <div className="detail-container">
        {/* MAIN DATA */}
        {columnDefs
          .filter(
            (c: ColumnProps<StorageItem>) =>
              !dataFieldIgnoreList.includes(c.dataField)
          )
          .filter((c: ColumnProps<StorageItem>) =>
            c.dataField.includes("custom_field_") ? !!data[c.dataField] : true
          )
          .map((c: ColumnProps<StorageItem>) => {
            return (
              <div className="detail-item" key={nanoid()}>
                <div className="detail-label">{c.caption}</div>
                {c.render ? (
                  c.render(data, { onChange: _onEdit })
                ) : c.dataType === "date" ? (
                  !!data[c.dataField] ? (
                    <>
                      {monolithMoment({
                        timestamp: data[c.dataField],
                        includeTime: true,
                      })}
                    </>
                  ) : null
                ) : (
                  <div className="detail-value">{data[c.dataField]}</div>
                )}
              </div>
            );
          })}
      </div>
    );

    return (
      <div className={className}>
        {!!itemData && (
          <>
            <div className="action-menu">
              {hasPermission() && (
                <div className="action-menu-item" onClick={handleCopy}>
                  <ContentCopyOutlinedIcon
                    style={{
                      color: theme.palette.primary.main,
                    }}
                  />
                  <div className="action-menu-label">Copy</div>
                </div>
              )}
              {hasPermission(MONOLITH_PERMISSIONS.STORAGE_DELETE) && (
                <>
                  <div className="action-menu-item" onClick={handleDelete}>
                    <DeleteOutlineOutlinedIcon
                      style={{
                        color: theme.palette.error.main,
                      }}
                    />
                    <div className="action-menu-label">Delete</div>
                  </div>
                </>
              )}
            </div>
            {detail}
            <div className="detail-label" style={{ marginTop: 30 }}>
              Description
            </div>
            <div style={{ position: "relative" }}>
              <div className="item-description">{itemData.notes}</div>
            </div>
          </>
        )}
        {!itemData && <Loader message={"Loading..."} />}
        <DeleteModal
          open={showDeleteModal}
          defaultInfo={defaultInfo}
          handleClose={() => setShowDeleteModal(false)}
          onSubmit={onDelete}
        />
        <EditStoragePopup
          open={showEditModal}
          handleClose={() => setShowEditModal(false)}
          onSubmit={_onEdit}
          defaultInfo={defaultInfo}
        />
        <CreateStoragePopup
          open={showCopyModal}
          defaultInfo={defaultInfo}
          caseInfo={{ case_id: case_id ? parseInt(case_id) : null }}
          handleClose={() => setShowCopyModal(false)}
          onSubmit={onCopy}
        />
      </div>
    );
  }
)`
  padding: 20px;
  & {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    margin-right: 5px;
  }
  & .action-menu {
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 15px;
    margin-right: 10px;
  }
  & .action-menu-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin-right: 12px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
    & svg {
      font-size: 15px;
      margin-right: 5px;
    }
  }
  & .detail-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 10px 0px;
    font-size: 12px;
  }
  & .detail-label {
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-right: 10px;
    min-width: 150px;
  }
  & .detail-value {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  & .item-description {
    position: relative;
    resize: none;
    margin-top: 10px;
    font-size: 12px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: transparent;
    white-space: pre-wrap;
    width: 100%;
    height: fit-content;
    max-height: 30%;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 10px;
    transition: border 0.2s ease-in-out;
    outline: none;
    &:hover {
      border: 1px solid ${({ theme }) => theme.palette.divider};
    }
    &:focus {
      border: 1px solid ${({ theme }) => theme.palette.primary.main};
    }
  }
  & .item-description-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.palette.divider};
    display: flex;
    alignitems: center;
    fontsize: 12px;
    padding: 10px;
  }
`;

const StorageSection = styled(() => {
  const { case_id } = useParams();
  const stateStoreKey =
    case_id === undefined ? "storage:view" : "case:storage:view";

  // will fix this any when we come up with the strategy to merge MUI and SC theme types
  const theme: any = useTheme();
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [showMovePopup, setShowMovePopup] = useState(false);
  const [showReleasePopup, setShowReleasePopup] = useState(false);
  const [showRemovePopup, setShowRemovePopup] = useState(false);
  const [showAssignPopup, setShowAssignPopup] = useState(false);
  const [showViewItemModal, setShowViewItemModal] = useState(false);
  const [viewItem, setViewItem] = useState<ViewItemState | null>(null);
  const [flyoutData, setFlyoutData] = useState<StorageItem | null>(null);
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);

  const [pageSize, setPageSize] = useState(100);
  const [query, setQuery] = useState<SharedCaseTabListQuery | undefined | null>(
    null
  );
  const [searchText, setSearchText] = useState(
    localStorage.getItem(`${stateStoreKey}:searchText`)
  );
  const [debouncedSearchText] = useDebounce(searchText, 500);

  const table = useTable();

  const [columnState, setColumnState] = useState(() => {
    return synchronizeColumnState(
      columnDefs,
      JSON.parse(localStorage.getItem(stateStoreKey) || "{}")
    );
  });

  const currentSort = useMemo(() => {
    let [sort] = columnState
      ?.filter((c) => c.sorting?.active)
      .map((c) => ({ field: c.dataField, sort: c.sorting?.direction }));

    return sort;
  }, [columnState]);

  const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: [
        case_id === undefined ? defaultQueryKey : "cases:storage:list",
        {
          query: {
            ...query?.query,
            order: query?.query?.order || currentSort,
            pageSize,
            case_id: case_id ? parseInt(case_id) : null,
          },
        },
      ],
      queryFn: ({ pageParam }) =>
        CaseStorageAPI.query({
          query: {
            ...query?.query,
            order: query?.query?.order || currentSort,
            pageSize,
            case_id: case_id ? parseInt(case_id) : null,
            page: pageParam,
          },
        }),
      getNextPageParam: (lastPage) => {
        return lastPage.nextPage;
      },
      getPreviousPageParam: (firstPage) => {
        if (firstPage.page - 1 === 0) return null;
        return firstPage.page - 1;
      },
      initialPageParam: 1,
      enabled: !!query,
      placeholderData: (data) => data,
    });

  const records = data?.pages?.reduce((acc, page) => {
    return [...acc, ...page.data];
  }, []);

  const totalRecords = data?.pages?.[0]?.total || 0;

  const debouncedFetchNextPage = useDebouncedCallback(() => {
    fetchNextPage();
  }, 50);

  let filterDimensions = CaseStorageDimensions;

  const { data: customFields } = useQuery({
    queryKey: ["storage:custom-fields:list"],
    queryFn: () =>
      CaseStorageAPI.getStorageCustomAttributes({ fieldsOnly: true }),
  });

  // update filter dimensions with custom fields
  filterDimensions = useMemo(() => {
    if (customFields) {
      //update filter dimensions
      return [
        ...CaseStorageDimensions,
        ...customFields?.map((field: CustomField) => ({
          name: field.field_name,
          field: `custom_field_${field.field_id}`,
          id: `custom_field_${field.field_id}`,
          type:
            field.editor_type === "dateBox"
              ? "date"
              : field.editor_type === "dropDown" ||
                field.editor_type === "tagBox"
              ? "string"
              : "text",
          mode:
            field.editor_type === "dateBox"
              ? "date"
              : field.editor_type === "dropDown" ||
                field.editor_type === "tagBox"
              ? "multi-select"
              : "text",
          options: field.options
            ? JSON.parse(field.options as string).map((o: string) => ({
                option: o,
              }))
            : null,
          selectionDisplayName: field.options ? "option" : null,
          selectionIdField: field.options ? "option" : null,
        })),
      ];
    }
    return CaseStorageDimensions;
  }, [customFields]);

  const { queryButton, queryFilter, conditions } = useQueryFilter({
    queryFilter: undefined,
    dimensions: filterDimensions.sort((a, b) => a.name.localeCompare(b.name)),
    onQuerySet: (newFilter) =>
      setQuery((q) => {
        return {
          query: {
            ...q?.query,
            ...newFilter,
            page: 1,
            case_id,
          },
        };
      }),
    stateStoring: {
      enabled: true,
      type: "localStorage",
      storageKey: stateStoreKey,
    },
  });

  const handleSort = (field: string) => {
    const savedColumn =
      columnState?.find((svc) => field === svc.dataField) || {};
    const order = !!savedColumn ? savedColumn?.sorting?.direction : null;

    let newOrder = null;

    // if sorted on a different column, reset the sort
    if (query?.query?.order?.field !== field) {
      newOrder = {
        field,
        sort: "asc",
      };
    }

    // otherwise rotate the sort options on the current column
    else {
      switch (order) {
        case "asc":
          newOrder = {
            field,
            sort: "desc",
          };
          break;
        case "desc":
          newOrder = null;
          break;
        default:
          newOrder = {
            field,
            sort: "asc",
          };
      }
    }

    let newCols = columnState?.map((c) => {
      if (c.dataField === field) {
        return {
          ...c,
          sorting: {
            active: newOrder ? true : false,
            direction: newOrder?.sort,
          },
        };
      }
      delete c.sorting;
      return c;
    });

    let newQuery = {
      query: {
        ...query?.query,
        order: newOrder,
      },
    };

    setColumnState(newCols);

    setQuery(newQuery);
  };
  const handleReload = () => {
    refetch();
  };
  const handleExportTable = () => {
    // show snackbar
    enqueueSnackbar("Exporting table...", {
      variant: "info",
    });

    CaseStorageAPI.exportStorageList({
      query: {
        ...query?.query,
        order: query?.query?.order || currentSort,
        pageSize,
        case_id: case_id ? parseInt(case_id) : null,
      },
      type: "xlsx",
      columns: columnState
        .filter((c) => c.visible !== false)
        .sort((a, b) => a.order - b.order)
        .map((c) => {
          return { dataField: c.dataField, header: c.caption, ...c };
        }),
      date_format: getDateFormat({ isMoment: true, includeTime: true }),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      custom_date_format: getDateFormat({ isMoment: true, includeTime: false }),
    }).then((res) => {
      const { signedUrl, filename } = res;
      const el = document.createElement("a");
      el.href = signedUrl.replace(
        "http://localhost:3000",
        "http://localhost:3001"
      );
      // el.target = "_blank";
      el.download = filename;
      el.click();
      // remove snackbar
    });
  };
  const handleColumnReorder = (
    newOrder: { column: string; order: number }[]
  ) => {
    setColumnState((cs) => {
      return newOrder.map((o) => {
        return {
          ...cs.find((c) => c.dataField === o.column),
          order: o.order,
        };
      });
    });
  };
  const handleColumnResize = (e: ColumnResize) => {
    setColumnState((cs) => {
      return cs.map((c) => {
        const col = e.columns.find((col) => col.dataField === c.dataField);
        if (col) {
          return {
            ...c,
            width: parseInt(col.width.replace(/px/g, "")),
          };
        }
        return c;
      });
    });
  };
  const handleColumnVisibility = (
    column: ColumnProps<StorageItem>,
    visible: boolean
  ) => {
    setColumnState((cs) => {
      return cs.map((c) => {
        if (c.dataField === column.dataField) {
          return {
            ...c,
            visible,
          };
        }
        return c;
      });
    });
  };
  const handleActionButtonClick = (rowData: StorageItem) => {
    setFlyoutData(rowData);
    setIsFlyoutVisible(true);
  };
  const handleActionSelect = (action: { text: string; value: number }) => {
    const selectionCount = table.getVirtualSelectionTotal();

    switch (action.value) {
      case 0:
        setShowAssignPopup(true);
        break;
      case 1:
        if (selectionCount === 0) {
          enqueueSnackbar("No storage items selected.", {
            variant: "warning",
          });
        } else setShowMovePopup(true);
        break;
      case 2:
        if (selectionCount === 0) {
          enqueueSnackbar("No storage items selected.", {
            variant: "warning",
          });
        } else setShowReleasePopup(true);
        break;
      case 3:
        if (selectionCount === 0) {
          enqueueSnackbar("No storage items selected.", {
            variant: "warning",
          });
        } else setShowRemovePopup(true);
        break;
      default:
        break;
    }
  };
  const handleClearFilters = () => queryFilter.clear();
  const handleModalAction = (data?: ViewItemState) => {
    if (data?.showView) {
      setViewItem(data);
      setShowViewItemModal(true);
    }
    refetch();
  };
  const handleGetNextItem = (uuid: string) => {
    const current = records?.findIndex((i: StorageItem) => i.uuid === uuid);
    const nextItem = records[current + 1] || records[0];

    setFlyoutData(nextItem);
  };
  const handleGetPrevItem = (uuid: string) => {
    const current = records?.findIndex((i: StorageItem) => i.uuid === uuid);
    const prevItem = records[current - 1] || records[records.length - 1];

    setFlyoutData(prevItem);
  };
  // Detect scroll to bottom
  const handleScroll = (e: React.MouseEvent) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    const pagesLength = data?.pages?.length || 0;
    if (scrollHeight - scrollTop <= clientHeight + 100 * pagesLength) {
      if (hasNextPage && !isFetchingNextPage) {
        debouncedFetchNextPage();
      }
    }
  };
  const onDelete = (deletedItem?: StorageItem) => {
    queryClient.setQueryData(
      [
        case_id === undefined ? defaultQueryKey : "cases:storage:list",
        {
          query: {
            ...query?.query,
            order: query?.query?.order || currentSort,
            pageSize,
            case_id: case_id ? parseInt(case_id) : null,
          },
        },
      ],
      (data: PageData<StorageItem>) => {
        if (!data) return null;
        const newPages = data?.pages.map((page) => {
          page.data =
            page?.data?.filter(
              (item: StorageItem) => item.uuid !== deletedItem?.uuid
            ) || page.data;
          return page;
        });

        return {
          ...data,
          pages: newPages,
        };
      }
    );
  };

  // Persist column state to local storage
  useEffect(() => {
    const oldStateLocalStorage = localStorage.getItem(stateStoreKey);
    let oldState = oldStateLocalStorage ? JSON.parse(oldStateLocalStorage) : {};
    localStorage.setItem(
      stateStoreKey,
      JSON.stringify({
        ...oldState,
        cols: columnState,
      })
    );
  }, [columnState, stateStoreKey]);

  useEffect(() => {
    if (!customFields) return;
    // Add custom fields to column defs
    setColumnState((cs) => {
      return addCustomFieldsToColumnDefs(customFields, cs);
    });
  }, [customFields]);

  useEffect(() => {
    setQuery((q) => ({
      query: {
        ...q?.query,
        search: debouncedSearchText ? debouncedSearchText : null,
        page: 1,
      },
    }));
  }, [debouncedSearchText]);

  return (
    <>
      {!case_id && (
        <>
          <Helmet title="Storage Items" />
          <Typography variant="h3" gutterBottom display="inline">
            Storage Items
          </Typography>
        </>
      )}

      <div
        style={{
          display: "flex",
          flex: "initial",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          marginBottom: 5,
          marginTop: 5,
          overflow: "hidden",
        }}
      >
        {/* New and Filter */}
        <Button
          size="small"
          variant="contained"
          color="primary"
          disabled={!hasPermission(MONOLITH_PERMISSIONS.STORAGE_CREATE)}
          onClick={(e) => {
            setShowCreatePopup(true);
          }}
          style={{
            minWidth: "fit-content",
            fontSize: 11,
            padding: "3px 6px",
          }}
        >
          + New Storage
        </Button>
        <div style={{ marginLeft: 10 }}>{queryButton}</div>
        <ItemTotal total={totalRecords || 0} Icon={HardDriveIcon} />
        {!!case_id && (
          <>
            <SelectedItems selected={table?.getVirtualSelectionTotal() || 0} />
          </>
        )}
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            minWidth: "fit-content",
          }}
        >
          {/* TABLE CONTROLS */}
          {hasPermission() && !!case_id && (
            <ComboButton
              type="dropDown"
              data={actions}
              title={"More Actions"}
              showDropdownIcon={true}
              displayField="text"
              variant="outlined"
              useSelectMode={false}
              onItemSelect={(item) => handleActionSelect(item)}
              textColor={theme.palette.text.primary}
            >
              Actions
            </ComboButton>
          )}
          <ComboButton
            type="multi-select"
            data={columnState.filter((c) => c.showInColumnChooser !== false)}
            displayField="caption"
            idField={"dataField"}
            selectedItems={columnState.filter((c) => c.visible !== false)}
            variant="outlined"
            closeOnSelect={false}
            textColor={theme.palette.text.secondary}
            title={"Select Columns"}
            showSearch={true}
            dropDownTitle={() => {
              return (
                <div
                  style={{
                    margin: "5px 0px",
                    padding: 3,
                    color: theme.palette.text.secondary,
                    display: "flex",
                    alignItems: "center",
                    minWidth: 200,
                  }}
                >
                  Select Columns
                </div>
              );
            }}
            onItemDeSelect={(item) => {
              handleColumnVisibility(item, false);
            }}
            onItemSelect={(item) => {
              handleColumnVisibility(item, true);
            }}
          >
            <ViewColumnOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            textColor={theme.palette.text.secondary}
            title={"Export Table"}
            onClick={handleExportTable}
          >
            <FileDownloadOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            textColor={theme.palette.text.secondary}
            title={"Clear Filters"}
            onClick={handleClearFilters}
          >
            <FilterListOffIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            textColor={theme.palette.text.secondary}
            title={table.isCompact ? "Zoom In" : "Zoom Out"}
            onClick={() => table.toggleCompact()}
          >
            {table.isCompact ? (
              <ZoomOutMapOutlinedIcon style={{ fontSize: 18 }} />
            ) : (
              <ZoomInMapOutlinedIcon style={{ fontSize: 18 }} />
            )}
          </ComboButton>
          <ComboButton
            type="button"
            variant={"outlined"}
            textColor={
              table.isStriped
                ? theme.palette.primary.main
                : theme.palette.text.secondary
            }
            title={table.isStriped ? "Hide Stripes" : "Show Stripes"}
            onClick={() => table.toggleStripes()}
          >
            <ReorderOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          {/* SEARCH */}
          <Input
            placeholder="Search Storage"
            variant="outlined"
            height={30}
            defaultValue={searchText}
            onKeyUp={(e: React.KeyboardEvent) => {
              const target = e.target as HTMLInputElement;
              const currentValue = target.value.trim();
              setSearchText(currentValue);

              localStorage.setItem(`${stateStoreKey}:searchText`, currentValue);
            }}
            style={{
              marginLeft: "10px",
              border: searchText
                ? `1px solid ${theme.palette.primary.main}`
                : "",
            }}
          />
        </div>
      </div>
      {data && (
        <>
          {<div>{conditions}</div>}
          <Table
            data={records}
            totalRecords={totalRecords}
            tableInstance={table}
            keyValue="uuid"
            columnProps={{ minWidth: 150, width: 150 }}
            onHeaderClick={(col: TableColumn<StorageItem>) =>
              col?.sorting?.enabled === false ? null : handleSort(col.dataField)
            }
            onScroll={handleScroll}
            onColumnReorder={handleColumnReorder}
            onColumnResize={handleColumnResize}
            onActionButtonClick={handleActionButtonClick}
            showActionColumn={true}
            focusedRow={flyoutData}
            showSelection={case_id === undefined ? false : true}
            selectAll={case_id === undefined ? false : true}
          >
            {columnState.map((col) => {
              return <Column key={col.dataField} {...col} />;
            })}
          </Table>
        </>
      )}
      {!data && <Loader message={"Retrieving Items..."} />}
      {isFlyoutVisible && flyoutData && (
        <CaseStorageFlyout
          flyoutData={flyoutData}
          setFlyoutData={setFlyoutData}
          setIsFlyoutVisible={setIsFlyoutVisible}
          handleGetNextItem={handleGetNextItem}
          handleGetPrevItem={handleGetPrevItem}
          columnState={columnState}
          handleRefresh={handleReload}
          onDelete={onDelete}
        />
      )}
      <CreateStoragePopup
        onSubmit={handleModalAction}
        open={showCreatePopup}
        handleClose={() => setShowCreatePopup(false)}
        caseInfo={{
          case_id: case_id ? parseInt(case_id) : null,
        }}
      />
      <ViewItemModal
        open={showViewItemModal}
        handleClose={() => setShowViewItemModal(false)}
        onSubmit={() => {
          navigate(`/storage/${viewItem?.storage_id}`);
        }}
      />
      {case_id !== undefined && (
        <>
          <MoveItemsPopup
            onSubmit={handleModalAction}
            caseInfo={{ case_id: case_id ? parseInt(case_id) : null }}
            open={showMovePopup}
            handleClose={() => setShowMovePopup(false)}
            table={table}
            query={query}
          />
          <ReleaseItemsPopup
            onSubmit={handleModalAction}
            caseInfo={{ case_id: case_id ? parseInt(case_id) : null }}
            open={showReleasePopup}
            handleClose={() => setShowReleasePopup(false)}
            table={table}
            query={query}
          />
          <RemoveItemsPopup
            onSubmit={handleModalAction}
            caseInfo={{ case_id: case_id ? parseInt(case_id) : null }}
            open={showRemovePopup}
            handleClose={() => setShowRemovePopup(false)}
            table={table}
            query={query}
          />
          <AssignStoragePopup
            onSubmit={handleModalAction}
            caseInfo={{ case_id: case_id ? parseInt(case_id) : null }}
            open={showAssignPopup}
            handleClose={() => setShowAssignPopup(false)}
            table={table}
          />
        </>
      )}
    </>
  );
})`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export default StorageSection;
