import styled from "styled-components";

const CaseSelectBoxItem = styled(({ className, data }) => {
  return (
    <div className={className}>
      <div className="case-number">{data.case_number}</div>
      <div className="case-name">{data.case_ref}</div>
    </div>
  );
})`
  .case-number {
    margin-bottom: 5px;
  }
  .case-name {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

export default CaseSelectBoxItem;
