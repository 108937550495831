import StarterKit from "@tiptap/starter-kit";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import TiptapImage from "@tiptap/extension-image";
import Placeholder from "@tiptap/extension-placeholder";
import TiptapUnderline from "@tiptap/extension-underline";
import UploadImagesPlugin from "../plugins/upload-images.js";
import Focus from "@tiptap/extension-focus";

import { getSlashCommand } from "./slash-command";
import { InputRule, Extension } from "@tiptap/core";
import TextAlign from "@tiptap/extension-text-align";

const CustomImage = TiptapImage.extend({
  // Add data-uuid attribute to image
  addAttributes() {
    return {
      ...this.parent?.(),
      "data-uuid": {
        default: null,
      },
    };
  },
  addProseMirrorPlugins() {
    return [UploadImagesPlugin()];
  },
});

const CustomStorage = Extension.create({
  name: "customStorage",
  addStorage() {
    const data = {};
    return {
      get: (key) => {
        return data[key];
      },
      set: (key, value) => {
        data[key] = value;
      },
      delete: (key) => {
        delete data[key];
      },
    };
  },
});

const getTipTapExtensions = (options = {}) => {
  return [
    StarterKit.configure({
      bulletList: {
        HTMLAttributes: {
          class: "",
        },
      },
      orderedList: {
        HTMLAttributes: {
          class: "",
        },
      },
      listItem: {
        HTMLAttributes: {
          class: "",
        },
      },
      horizontalRule: false,
      dropcursor: {
        color: "#DBEAFE",
        width: 4,
      },
      gapcursor: false,
    }),
    // patch to fix horizontal rule bug: https://github.com/ueberdosis/tiptap/pull/3859#issuecomment-1536799740
    HorizontalRule.extend({
      addInputRules() {
        return [
          new InputRule({
            find: /^(?:---|—-|___\s|\*\*\*\s)$/,
            handler: ({ state, range }) => {
              const attributes = {};

              const { tr } = state;
              const start = range.from;
              let end = range.to;

              tr.insert(start - 1, this.type.create(attributes)).delete(
                tr.mapping.map(start),
                tr.mapping.map(end)
              );
            },
          }),
        ];
      },
    }).configure({
      HTMLAttributes: {
        class: "mt-4 mb-6 border-t border-stone-300",
      },
    }),
    CustomImage.configure({
      allowBase64: true,
      inline: true,
      HTMLAttributes: {
        class: "monolith-image",
      },
    }),
    Placeholder.configure({
      placeholder: ({ node }) => {
        if (node.type.name === "heading") {
          return `Heading ${node.attrs.level}`;
        }
        return "Press '/' for commands...";
      },
      includeChildren: true,
    }),
    getSlashCommand(options),
    TiptapUnderline,
    TextAlign.configure({ types: ["heading", "paragraph"] }),
    Focus.configure({ className: "has-focus", mode: "all" }),
    CustomStorage,
  ];
};

export default getTipTapExtensions;
