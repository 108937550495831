import styled from "styled-components";

const UserRoleSelectBoxItem = styled(({ className, data }) => {
  return (
    <div className={className}>
      <div className="role-name">{data.text}</div>
      <div className="role-description">{data.description}</div>
    </div>
  );
})`
  .role-name {
    margin-bottom: 5px;
  }
  .role-description {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

export default UserRoleSelectBoxItem;
