import React, { useEffect, useRef, useState } from "react";
import { TreeList, Column } from "devextreme-react/ui/tree-list";
import { Popup } from "devextreme-react/ui/popup";
import { LoadIndicator } from "devextreme-react/ui/load-indicator";
import { Helmet } from "react-helmet-async";
import { useSnackbar } from "notistack";
import {
  Grid,
  Divider,
  Typography,
  useTheme,
  IconButton,
  Button,
  Tooltip,
} from "@mui/material";
import { FaSync } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa";
import { FaThLarge } from "react-icons/fa";
import { FaTable } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaLaptop } from "react-icons/fa";
import { FaRegHdd } from "react-icons/fa";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  createLocation,
  deleteGroup,
  getLocations,
  getOffices,
  updateLocation,
} from "../../api";
import { usePermissions } from "../../hooks/usePermissions";
import { Link } from "react-router-dom";
import DropDownSelector from "../../components/DropDownSelector";
import AccessDeniedMessage from "../../components/AccessDeniedMessage.js";
import { User } from "../Users/types/Users";

export interface Location {
  evidence_count: number;
  is_parent: number;
  location_group?: string | null;
  location_id: number;
  location_name: string;
  location_type: string;
  locations: NestedLocation[];
  office_id: number;
  office_name: string;
  parent_id: number | null;
  storage_count: number;
  user_id?: number | null;
  user_name?: string;
  newGroup?: boolean;
  newLocation?: boolean;
  location_count?: number;
}

type NestedLocation = Omit<Location, "locations">;

export interface Office {
  manager: Partial<User>;
  manager_id: number;
  office_address: string;
  office_city: string;
  office_id: number;
  office_name: string;
  office_phone: string;
  office_postal_code: string;
  office_region: string;
  total_users: number;
}

const views = [
  {
    key: 0,
    text: "Tiles",
    icon: FaThLarge,
  },
  {
    key: 1,
    text: "Grid",
    icon: FaTable,
  },
];

const TileView = ({
  currentOffice,
  locations,
  setLocations,
}: {
  currentOffice: Office;
  locations: Location[];
  setLocations: React.Dispatch<React.SetStateAction<Location[] | []>>;
}) => {
  const [loadingLocations, setLoadingLocations] = useState(true);

  useEffect(() => {
    if (currentOffice !== null) {
      setLoadingLocations(true);
      getLocations({ office_id: currentOffice.office_id }).then((result) => {
        let groups = result.filter((r: Location) => r.is_parent === 1);
        let locationData = groups.map((g: Location) => {
          g.locations = result.filter(
            (r: Location) => r.parent_id === g.location_id
          );
          g.evidence_count = g.locations.reduce((sum, current) => {
            return sum + current.evidence_count;
          }, 0);
          g.storage_count = g.locations.reduce((sum, current) => {
            return sum + current.storage_count;
          }, 0);
          return g;
        });
        setLocations(locationData);
        setLoadingLocations(false);
      });
    }
  }, [currentOffice]);

  return (
    <div
      style={{
        height: "calc(100vh - 200px)",
        overflowX: "auto",
        padding: "0px 5px",
        margin: "10px 0px",
      }}
    >
      {loadingLocations && (
        <div style={{ margin: "auto", marginTop: 50 }}>
          <LoadIndicator />
        </div>
      )}
      {locations &&
        !loadingLocations &&
        locations.map((l) => {
          return (
            <LocationGroup
              key={l.location_id}
              locationGroupData={l}
              locations={locations}
              setLocations={setLocations}
            ></LocationGroup>
          );
        })}
    </div>
  );
};

const GridView = ({
  locationsGrid,
  currentOffice,
}: {
  locationsGrid: React.MutableRefObject<null>;
  currentOffice: Office;
}) => {
  const theme = useTheme();
  const [locations, setLocations] = useState<Location[] | null>(null);
  const [loadingLocations, setLoadingLocations] = useState(true);

  useEffect(() => {
    if (currentOffice !== null) {
      setLoadingLocations(true);
      getLocations({ office_id: currentOffice.office_id }).then(
        (locationData) => {
          locationData = locationData.map((l: Location) => {
            if (l.is_parent === 1) {
              let evidenceCount = 0;
              let storageCount = 0;
              let locationCount = 0;

              locationData
                .filter((ld: Location) => ld.parent_id === l.location_id)
                .forEach((ld: Location) => {
                  evidenceCount += ld.evidence_count;
                  storageCount += ld.storage_count;
                  locationCount++;
                });

              l.evidence_count = evidenceCount;
              l.storage_count = storageCount;
              l.location_count = locationCount;
            }
            if (l.location_id === 2) {
              l.office_id = currentOffice.office_id;
              l.office_name = currentOffice.office_name;
            }
            return l;
          });

          setLocations(locationData);
          setLoadingLocations(false);
        }
      );
    }
  }, [currentOffice]);

  return (
    <>
      {loadingLocations && (
        <div style={{ margin: "auto", marginTop: 50 }}>
          <LoadIndicator />
        </div>
      )}
      {!loadingLocations && (
        <div className="dx-card">
          <TreeList
            ref={locationsGrid}
            style={{ marginTop: 10, maxHeight: "calc(100vh - 200px)" }}
            //@ts-ignore  -ignore dx types
            dataSource={locations}
            showBorders={false}
            key="location_id"
            keyExpr="location_id"
            parentIdExpr="parent_id"
            rootValue={null}
            headerFilter={{
              visible: true,
              height: 350,
              allowSearch: true,
            }}
            hoverStateEnabled={true}
            allowColumnResizing={true}
          >
            <Column
              dataField="location_name"
              caption="Location Name"
              allowReordering={false}
              cellRender={(e) => {
                if (e.data.is_parent === 0) {
                  return (
                    <Link
                      to={`/locations/${e.data.location_id}/details?office_id=${e.data.office_id}`}
                    >
                      <div
                        style={{
                          cursor: "pointer",
                          color: theme.palette.primary.main,
                        }}
                        onMouseEnter={(event) => {
                          event.currentTarget.style.textDecoration =
                            "underline";
                        }}
                        onMouseLeave={(event) => {
                          event.currentTarget.style.textDecoration = "none";
                        }}
                      >
                        <div
                          style={{
                            margin: "0px 5px 0px 5px",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FaMapMarkerAlt style={{ marginRight: 5 }} />
                          {e.data.location_name}
                        </div>
                      </div>
                    </Link>
                  );
                } else {
                  return <>{e.data.location_name}</>;
                }
              }}
            />
            <Column dataField="location_type" caption="Type" />
            <Column dataField="office_name" caption="Office" />
            <Column
              dataField="evidence_count"
              caption="Evidence Items"
              alignment="left"
              cellRender={(e) => {
                return <>{e.data.evidence_count || 0}</>;
              }}
            />
            <Column
              dataField="storage_count"
              caption="Storage Items"
              alignment="left"
              cellRender={(e) => {
                return <>{e.data.storage_count || 0}</>;
              }}
            />
            <Column
              dataField="location_count"
              caption="Locations"
              alignment="left"
            />
          </TreeList>
        </div>
      )}
    </>
  );
};

const LocationGroup = ({
  locationGroupData,
  locations,
  setLocations,
}: {
  locationGroupData: Location;
  locations: Location[];
  setLocations: React.Dispatch<React.SetStateAction<Location[] | []>>;
}) => {
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const [visible, setVisible] = useState(false);
  const [locationCount, setLocationCount] = useState(
    locationGroupData.locations.length
  );
  const groupTitleRef = useRef<HTMLDivElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const confirmLocationGroupDeletePopup = useRef<any>(null);

  useEffect(() => {
    if (locationGroupData.newGroup === true) {
      groupTitleRef.current?.focus();
    }
  }, []);

  return (
    <>
      <div className="dx-card" style={{ margin: 0, marginBottom: "20px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px 20px",
            textAlign: "left",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                margin: "0px 10px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <KeyboardArrowDownIcon
                  style={{
                    padding: "5px",
                    cursor: "pointer",
                    fontSize: 35,
                    transform: visible ? "none" : "rotate(180deg)",
                  }}
                  onClick={(event) => {
                    setVisible(!visible);
                  }}
                />
                <div>
                  <div
                    style={{
                      fontSize: 16,
                      padding: "3px",
                      marginBottom: 3,
                      cursor: "text",
                      outline: "none",
                    }}
                    contentEditable={true}
                    suppressContentEditableWarning={true}
                    ref={groupTitleRef}
                    onFocus={(event) => {
                      event.target.style.outline = "1px solid slategray";
                    }}
                    onBlur={(event) => {
                      event.target.style.outline = "none";
                      if (locationGroupData.newGroup === true) {
                        if (event.target.innerText === "") {
                          setLocations(
                            locations.filter(
                              (l: Location) => l.newGroup !== true
                            )
                          );
                        } else {
                          delete locationGroupData.newGroup;
                          createLocation({
                            location_name: event.target.innerText,
                            location_type: "Group",
                            is_parent: 1,
                            parent_id: null,
                            office_id: locationGroupData.office_id,
                          }).then((result) => {
                            setLocations(
                              locations.map((l: Location) => {
                                if (
                                  l.location_id ===
                                  locationGroupData.location_id
                                ) {
                                  l.location_id = result.location_id;
                                  l.location_name = event.target.innerText;
                                }
                                return l;
                              })
                            );
                          });
                          enqueueSnackbar("Location Group Created.", {
                            variant: "success",
                          });
                        }
                      } else if (
                        event.target.innerText !== "" &&
                        event.target.innerText !==
                          locationGroupData.location_name
                      ) {
                        updateLocation(locationGroupData.location_id, {
                          location_name: event.target.innerText,
                        });
                        setLocations(
                          locations.map((l: Location) => {
                            if (l.location_id === locationGroupData.location_id)
                              l.location_name = event.target.innerText;
                            return l;
                          })
                        );
                        enqueueSnackbar("Location Group Name Updated.", {
                          variant: "success",
                        });
                      } else if (event.target.innerText === "") {
                        event.target.innerText =
                          locationGroupData.location_name;
                      }
                    }}
                    onMouseEnter={(event) => {
                      const target = event.target as HTMLElement;
                      target.style.outline = "1px solid slategray";
                    }}
                    onMouseLeave={(event) => {
                      const target = event.target as HTMLElement; // Type assertion
                      target.style.outline = "none";
                    }}
                  >
                    {locationGroupData.location_name}
                  </div>
                  <LocationGroupMeta
                    locationGroupData={locationGroupData}
                    locationCount={locationCount}
                  />
                </div>
              </div>
            </div>
            {hasPermission(MONOLITH_PERMISSIONS.LOCATIONS_DELETE) &&
              locationGroupData.location_type !== "Team" && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    variant="text"
                    size="small"
                    color="error"
                    onClick={(e) => {
                      confirmLocationGroupDeletePopup.current?.instance.show();
                    }}
                  >
                    Delete
                  </Button>
                </div>
              )}
          </div>
        </div>
      </div>
      <LocationsBox
        locationGroupData={locationGroupData}
        visible={visible}
        locationCount={locationCount}
        setLocationCount={setLocationCount}
      />
      <ConfirmLocationGroupDeletePopup
        confirmLocationGroupDeletePopup={confirmLocationGroupDeletePopup}
        locationGroupData={locationGroupData}
        locations={locations}
        setLocations={setLocations}
      />
    </>
  );
};

const LocationGroupMeta = ({
  locationGroupData,
  locationCount,
}: {
  locationGroupData: Location;
  locationCount: number;
}) => {
  return (
    <div style={{ display: "flex", color: "darkgray" }}>
      <Tooltip title="Location Count" arrow>
        <div
          style={{
            margin: "0px 5px 0px 5px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FaMapMarkerAlt style={{ marginRight: 5 }} />
          {locationCount}
        </div>
      </Tooltip>
      <Tooltip title="Evidence Count" arrow>
        <div
          style={{
            margin: "0px 5px 0px 10px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FaLaptop style={{ marginRight: 5 }} />
          {locationGroupData.evidence_count}
        </div>
      </Tooltip>

      <Tooltip title="Storage Count" arrow>
        <div
          style={{
            margin: "0px 5px 0px 10px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FaRegHdd style={{ marginRight: 5 }} />
          {locationGroupData.storage_count}
        </div>
      </Tooltip>
    </div>
  );
};

const ConfirmLocationGroupDeletePopup = ({
  confirmLocationGroupDeletePopup,
  locationGroupData,
  locations,
  setLocations,
}: {
  confirmLocationGroupDeletePopup: React.MutableRefObject<any>;
  locationGroupData: Location;
  locations: Location[];
  setLocations: React.Dispatch<React.SetStateAction<[] | Location[]>>;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Popup
      ref={confirmLocationGroupDeletePopup}
      title="Delete Location Group"
      showTitle={true}
      width={450}
      height={175}
      position={{
        offset: "100 0",
      }}
    >
      {locationGroupData.evidence_count + locationGroupData.storage_count >
        0 && (
        <div>
          To delete this location group, move its evidence and storage items to
          another group.
        </div>
      )}
      {locationGroupData.evidence_count + locationGroupData.storage_count ===
        0 && (
        <>
          <div>
            Are you sure you want to delete location group "
            {locationGroupData.location_name}"?
          </div>
          <div>This will also delete all of the group's locations.</div>
        </>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginRight: 5,
          marginTop: 30,
          color: "white",
        }}
      >
        <Button
          variant="text"
          size="small"
          style={{ marginRight: 10 }}
          onClick={(e) => {
            confirmLocationGroupDeletePopup.current.instance.hide();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          color="error"
          disabled={
            locationGroupData.evidence_count + locationGroupData.storage_count >
            0
          }
          onClick={async (e) => {
            confirmLocationGroupDeletePopup.current.instance.hide();
            deleteGroup(locationGroupData.location_id);
            enqueueSnackbar("Location Group Deleted.", {
              variant: "success",
            });
            setLocations(
              locations.filter(
                (l) => l.location_id !== locationGroupData.location_id
              )
            );
          }}
        >
          Delete Location
        </Button>
      </div>
    </Popup>
  );
};

const LocationsBox = ({
  locationGroupData,
  visible,
  locationCount,
  setLocationCount,
}: {
  locationGroupData: Location;
  visible: boolean;
  locationCount: number;
  setLocationCount: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const theme = useTheme();
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const [locations, setLocations] = useState(locationGroupData.locations);

  const handleRemoveCard = (id: number) => {
    setLocations(locations.filter((l: NestedLocation) => l.location_id !== id));
  };

  const handleSaveCard = (id: number, locationData: NestedLocation) => {
    setLocations(
      locations.map((l: NestedLocation) => {
        if (l.location_id === id) {
          return locationData;
        }
        return l;
      })
    );
  };

  const handleLocationCountUpdate = (value: number) => {
    setLocationCount(locationCount + value);
  };

  return (
    <div
      style={{
        display: visible ? "flex" : "none",
        minHeight: "100px",
        marginBottom: 20,
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      {locations.map((l: NestedLocation) => {
        return (
          <LocationCard
            key={l.location_id}
            handleLocationCountUpdate={handleLocationCountUpdate}
            handleRemoveCard={handleRemoveCard}
            handleSaveCard={handleSaveCard}
            locationData={l}
          />
        );
      })}
      {hasPermission(MONOLITH_PERMISSIONS.LOCATIONS_CREATE) &&
        locationGroupData.location_type !== "Team" && (
          <div
            className="dx-card"
            style={{
              textAlign: "center",
              background: "transparent",
              borderRadius: 7,
              border: "1px dashed",
              cursor: "pointer",
              color: "slategray",
              margin: 15,
              padding: 15,
              width: 200,
              height: 115,
            }}
            onMouseEnter={(event) => {
              event.currentTarget.style.borderColor =
                theme.palette.primary.main;
              event.currentTarget.style.color = theme.palette.primary.main;
            }}
            onMouseLeave={(event) => {
              event.currentTarget.style.border = "1px dashed";
              event.currentTarget.style.color = "slategray";
            }}
            onClick={(event) => {
              setLocations([
                ...locations,
                {
                  newLocation: true,
                  location_id: Date.now(),
                  location_name: "",
                  location_type: "",
                  is_parent: 0,
                  parent_id: locationGroupData.location_id,
                  office_id: locationGroupData.office_id,
                  office_name: locationGroupData.office_name,
                  user_id: null,
                  evidence_count: 0,
                  storage_count: 0,
                  location_group: locationGroupData.location_name,
                },
              ]);
              handleLocationCountUpdate(1);
            }}
          >
            <div style={{ fontSize: 60, height: 70 }}>+</div>
            <div>Create Location</div>
          </div>
        )}
    </div>
  );
};

const LocationCard = ({
  locationData,
  handleLocationCountUpdate,
  handleRemoveCard,
  handleSaveCard,
}: {
  locationData: NestedLocation;
  handleLocationCountUpdate: (a: number) => void;
  handleRemoveCard: (id: number) => void;
  handleSaveCard: (a: number, l: NestedLocation) => void;
}) => {
  const theme = useTheme();
  const locationNameRef = useRef<HTMLDivElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (locationData.newLocation === true) locationNameRef.current?.focus();
  }, []);

  return (
    <div
      className="dx-card"
      style={{
        textAlign: "center",
        margin: 15,
        padding: 15,
        width: 200,
        height: 115,
        borderRadius: 7,
        boxShadow:
          "0 0.9px 4px -1px rgb(0 0 0 / 20%), 0 2.6px 8px -1px rgb(0 0 0 / 15%), 0 5.7px 12px -1px rgb(0 0 0 / 13%), 0 15px 15px -1px rgb(0 0 0 / 10%)",
      }}
    >
      <Link
        to={`/locations/${locationData.location_id}/details?office_id=${locationData.office_id}`}
      >
        <div
          ref={locationNameRef}
          contentEditable={locationData.newLocation === true}
          suppressContentEditableWarning={true}
          style={{
            padding: 5,
            fontSize: 16,
            outline:
              locationData.newLocation === true
                ? "1px solid slategray"
                : "none",
            cursor: locationData.newLocation === true ? "text" : "pointer",
            color: theme.palette.primary.main,
          }}
          onMouseEnter={(event) => {
            event.currentTarget.style.textDecoration = "underline";
          }}
          onMouseLeave={(event) => {
            event.currentTarget.style.textDecoration = "none";
          }}
          onClick={(event) => {
            //Do not navigate to location while still being created
            if (locationData.newLocation) {
              event.preventDefault();
              event.stopPropagation();
            }
          }}
          onBlur={(event) => {
            const locationName = event.target.innerText;

            if (locationName === "") {
              handleRemoveCard(locationData.location_id);
              handleLocationCountUpdate(-1);
            } else {
              event.currentTarget.style.outline = "none";
              event.currentTarget.style.cursor = "pointer";
              event.currentTarget.setAttribute("contentEditable", "false");
              delete locationData.newLocation;
              createLocation({
                ...locationData,
                location_name: event.currentTarget.innerText,
              }).then((result) => {
                handleSaveCard(locationData.location_id, {
                  ...locationData,
                  location_id: result.location_id,
                  location_name: locationName,
                });
              });
              enqueueSnackbar("Location Created.", {
                variant: "success",
              });
            }
          }}
        >
          {locationData.location_name}
        </div>
      </Link>
      <div style={{ padding: 5, fontStyle: "italic" }}>
        {locationData.location_type}
      </div>
      <div
        style={{
          color: "darkgray",
          padding: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Tooltip title="Evidence Count" arrow>
          <div
            style={{
              margin: "0px 5px 0px 10px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FaLaptop style={{ marginRight: 5 }} />
            {locationData.evidence_count || 0}
          </div>
        </Tooltip>

        <Tooltip title="Storage Count" arrow>
          <div
            style={{
              margin: "0px 5px 0px 10px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FaRegHdd style={{ marginRight: 5 }} />
            {locationData.storage_count || 0}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

const LocationsSectionContent = () => {
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const savedView = window.localStorage.getItem("locations-section.savedView");
  const savedOffice = window.localStorage.getItem(
    "locations-section.savedOffice"
  );
  const [currentView, setCurrentView] = useState(
    savedView ? views[JSON.parse(savedView).key] : views[0]
  );
  const [offices, setOffices] = useState<Office[] | null>(null);
  const [currentOffice, setCurrentOffice] = useState<Office | null>(null);
  const [locations, setLocations] = useState<Location[] | []>([]);

  const locationsGrid = useRef(null);

  useEffect(() => {
    getOffices().then((result) => {
      setOffices(result);
      setCurrentOffice(savedOffice ? JSON.parse(savedOffice) : result[0]);
    });
  }, []);

  return (
    <>
      <Helmet title="Item Locations" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Item Locations
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 4 }} />
      <div style={{ boxShadow: "none" }}>
        {!currentOffice && <></>}
        {currentOffice && (
          <>
            <div
              style={{
                display: "flex",
                padding: "10px 5px",
              }}
            >
              {currentView.key === 0 && (
                <Button
                  variant="contained"
                  size="small"
                  disabled={
                    !hasPermission(MONOLITH_PERMISSIONS.LOCATIONS_CREATE)
                  }
                  style={{ marginRight: 10 }}
                  onClick={(e) => {
                    setLocations([
                      ...locations,
                      {
                        newGroup: true,
                        location_id: Date.now(),
                        location_name: "",
                        location_type: "Group",
                        is_parent: 1,
                        parent_id: null,
                        locations: [],
                        evidence_count: 0,
                        storage_count: 0,
                        office_id: currentOffice.office_id,
                        office_name: currentOffice.office_name,
                        location_group: null,
                      },
                    ]);
                  }}
                >
                  + Create Group
                </Button>
              )}
              <DropDownSelector
                selectedItem={currentOffice}
                useSelectMode={true}
                variant="contained"
                buttonIcon={FaBuilding}
                displayExpr="office_name"
                tooltipText="Select Office"
                items={offices}
                onSelect={(itemData) => {
                  window.localStorage.setItem(
                    "locations-section.savedOffice",
                    JSON.stringify(itemData)
                  );
                  setCurrentOffice(itemData);
                }}
                style={{ marginRight: 5 }}
                value={undefined}
              />
              <DropDownSelector
                selectedItem={currentView}
                useSelectMode={true}
                variant="contained"
                buttonIcon={currentView ? currentView.icon : null}
                displayExpr="text"
                tooltipText="Select View"
                items={views}
                onSelect={(itemData) => {
                  window.localStorage.setItem(
                    "locations-section.savedView",
                    JSON.stringify(itemData)
                  );
                  setCurrentView(itemData);
                }}
                style={{ marginRight: 5 }}
                value={undefined}
              />
              <div style={{ marginLeft: "auto", marginRight: 10 }}>
                <Tooltip arrow title="Refresh">
                  <IconButton
                    color="primary"
                    size="small"
                    disableRipple={false}
                    onClick={(e) => {
                      setCurrentOffice({ ...currentOffice });
                    }}
                  >
                    <FaSync />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            {currentView.key === 0 && (
              <TileView
                currentOffice={currentOffice}
                locations={locations}
                setLocations={setLocations}
              />
            )}
            {currentView.key === 1 && (
              <GridView
                locationsGrid={locationsGrid}
                currentOffice={currentOffice}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

const LocationsSection = () => {
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();

  if (!hasPermission(MONOLITH_PERMISSIONS.LOCATIONS_READ_ALL)) {
    return <AccessDeniedMessage />;
  }

  return <LocationsSectionContent />;
};

export default LocationsSection;
