import styled from "styled-components";

const DetailLabel = styled.div`
  font-weight: 500;
  width: 125px;
  min-width: 125px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;
const DetailValue = styled.div`
  white-space: pre-wrap;
`;

const DetailItem = styled(
  ({ className, label, value, labelProps, valueProps, ...props }) => {
    return (
      <div className={className + " detail-item"} {...props}>
        <DetailLabel className={"detail-label"} {...labelProps}>
          {label}
        </DetailLabel>
        <DetailValue className={"detail-value"} {...valueProps}>
          {value}
        </DetailValue>
      </div>
    );
  }
)`
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 5px;
`;

export default DetailItem;
