import { Button, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AuthService } from "../../api/auth/index.js";
import Loader from "../../components/Loader.js";
import { useAuth } from "../../contexts/AuthContext.js";
import LoggingAPI from "../../api/logging/index.js";

const MFA = () => {
  const { currentUser } = useAuth();
  if (currentUser?.mfa_initialized) return <MfaVerify />;
  return <MfaSetup />;
};

const MfaSetup = () => {
  const { currentUser, setSessionData } = useAuth();

  const theme = useTheme();
  const inputRef = useRef(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [verifying, setVerifying] = useState(false);

  const { data } = useQuery({
    queryKey: ["mfa"],
    queryFn: () => AuthService.getMfa(),
    refetchOnWindowFocus: false,
    // do not cache the data
    gcTime: 0,
  });

  const handleSubmit = () => {
    setVerifying(true);
    AuthService.verifyMfa({
      token,
      mfaSecret: data.mfaSecret,
    })
      .catch((err) => {
        console.log(err);
      })
      .then((result) => {
        if (result?.status === 200) {
          LoggingAPI.logCommonActivity({
            user_email: currentUser.email,
            message: "User Setup a MFA Device.",
          });
          setSessionData();
          navigate("/");
        } else {
          LoggingAPI.logCommonActivity({
            user_email: currentUser.email,
            message: "MFA Device Setup Failed.",
          });
          setError("Invalid code");
          setVerifying(false);
          inputRef.current.clearValues();
        }
      });
  };

  const onTokenChange = (newToken) => {
    setToken(newToken);
  };

  useEffect(() => {
    if (token?.length === 6) {
      handleSubmit();
    }
  }, [token]);

  return (
    <div
      style={{ maxWidth: 300, margin: "auto", marginTop: 30, marginBottom: 30 }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 25,
          color: theme.palette.text.secondary,
          fontSize: "large",
        }}
      >
        <div>2-Factor Authentication Setup</div>
      </div>
      <div style={{ textAlign: "center", marginBottom: 10 }}>
        <img src={data?.mfaQRCode} style={{ maxHeight: 200 }} alt="" />
      </div>
      {error && (
        <div
          style={{
            marginBottom: 10,
            padding: 10,
            fontSize: 12,
            textAlign: "center",
          }}
        >
          <div style={{ color: "red" }}>{error}</div>
          <div style={{ color: theme.palette.text.secondary }}>
            Rescan QR code and try again.
          </div>
        </div>
      )}
      {!error && (
        <div
          style={{
            marginBottom: 10,
            padding: 10,
            fontSize: 12,
            textAlign: "center",
          }}
        >
          <div style={{ color: theme.palette.text.secondary }}>
            Scan QR code with your Google Authenticator (or similar) app and
            enter the 6-digit code:
          </div>
        </div>
      )}
      <div>
        {verifying && <Loader />}
        {!verifying && (
          <>
            <MfaCodeInput inputRef={inputRef} onChange={onTokenChange} />
            <Button
              variant="contained"
              size="small"
              fullWidth
              style={{ marginTop: 20 }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

const MfaVerify = () => {
  const { currentUser, setSessionData } = useAuth();
  const theme = useTheme();
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [token, setToken] = useState(null);
  const [verifying, setVerifying] = useState(false);

  const handleSubmit = () => {
    setVerifying(true);
    AuthService.verifyMfa({
      token,
    })
      .catch((err) => {
        console.log(err);
      })
      .then((result) => {
        if (result?.status === 200) {
          LoggingAPI.logCommonActivity({
            user_email: currentUser.email,
            message: "User Verified MFA Device.",
          });
          setSessionData();
          navigate("/");
        } else {
          LoggingAPI.logCommonActivity({
            user_email: currentUser.email,
            message: "MFA Verification Failed.",
          });
          setError("Invalid code");
          setVerifying(false);
          inputRef.current.clearValues();
        }
      });
  };

  const onTokenChange = (newToken) => {
    setToken(newToken);
  };

  useEffect(() => {
    if (token?.length === 6) {
      handleSubmit();
    }
  }, [token]);

  return (
    <div
      style={{ maxWidth: 250, margin: "auto", marginTop: 30, marginBottom: 30 }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 25,
          color: theme.palette.text.secondary,
          fontSize: "large",
        }}
      >
        <div>2-Factor Authentication</div>
      </div>
      <div
        style={{
          marginBottom: 10,
          padding: 10,
          fontSize: 12,
          textAlign: "center",
        }}
      >
        <div style={{ color: theme.palette.text.secondary }}>
          Enter the 6-digit code generated by your Google Authenticator (or
          similar) app:
        </div>
      </div>
      {error && (
        <div
          style={{
            marginBottom: 10,
            padding: 10,
            fontSize: 12,
            textAlign: "center",
          }}
        >
          <div style={{ color: "red" }}>{error}</div>
          <div style={{ color: theme.palette.text.secondary }}>
            Generate a new code and try again.
          </div>
        </div>
      )}
      <div>
        {verifying && <Loader />}
        {!verifying && (
          <>
            <MfaCodeInput inputRef={inputRef} onChange={onTokenChange} />
            <Button
              variant="contained"
              size="small"
              fullWidth
              style={{ marginTop: 20 }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

const MfaCodeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 40px;
  width: 250px;
`;

const MfaCodeDigitInput = styled.input`
  width: 32px;
  height: 40px;
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: 4px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: ${(props) => props.theme.palette.text.primary};
  background-color: ${(props) => props.theme.palette.background.paper};
  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.palette.primary.main};
  }
  &:hover {
    border-color: ${(props) => props.theme.palette.primary.main};
  }
`;

const MfaCodeInput = styled(({ className, onChange = () => {}, inputRef }) => {
  const [code, setCode] = useState(["", "", "", "", "", ""]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (value.length > 1) {
      return;
    }
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
    onChange(newCode.join(""));
    if (index < 5 && value) {
      document.getElementById(`mfa-code-digit-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0) {
      // document.getElementById(`mfa-code-digit-${index - 1}`).focus();
    }
  };

  const clearValues = () => {
    setCode(["", "", "", "", "", ""]);
    onChange(null);
    document.getElementById(`mfa-code-digit-0`).focus();
  };

  useEffect(() => {
    if (inputRef) {
      inputRef.current = {
        clearValues,
      };
    }
  }, []);

  return (
    <div className={className}>
      <MfaCodeContainer>
        <MfaCodeDigitInput
          id={`mfa-code-digit-0`}
          autoFocus
          value={code[0]}
          onChange={(e) => handleChange(e, 0)}
          onKeyDown={(e) => handleKeyDown(e, 0)}
        />
        <MfaCodeDigitInput
          id={`mfa-code-digit-1`}
          value={code[1]}
          onChange={(e) => handleChange(e, 1)}
          onKeyDown={(e) => handleKeyDown(e, 1)}
        />
        <MfaCodeDigitInput
          id={`mfa-code-digit-2`}
          value={code[2]}
          onChange={(e) => handleChange(e, 2)}
          onKeyDown={(e) => handleKeyDown(e, 2)}
        />
        <MfaCodeDigitInput
          id={`mfa-code-digit-3`}
          value={code[3]}
          onChange={(e) => handleChange(e, 3)}
          onKeyDown={(e) => handleKeyDown(e, 3)}
        />
        <MfaCodeDigitInput
          id={`mfa-code-digit-4`}
          value={code[4]}
          onChange={(e) => handleChange(e, 4)}
          onKeyDown={(e) => handleKeyDown(e, 4)}
        />
        <MfaCodeDigitInput
          id={`mfa-code-digit-5`}
          value={code[5]}
          onChange={(e) => handleChange(e, 5)}
          onKeyDown={(e) => handleKeyDown(e, 5)}
        />
      </MfaCodeContainer>
    </div>
  );
})`
  display: flex;
  justify-content: center;
`;

export default MFA;
