import { useEffect, useState } from "react";
import Loader from "../../components/Loader.js";
import styled from "styled-components";

const MSDocViewer = styled(({ className, file }) => {
  const isWord =
    file?.file_name?.endsWith(".docx") || file?.file_name?.endsWith(".doc");
  const isExcel =
    file?.file_name?.endsWith(".xlsx") || file?.file_name?.endsWith(".xls");
  const isPowerpoint =
    file?.file_name?.endsWith(".pptx") || file?.file_name?.endsWith(".ppt");

  return (
    <div
      className={className}
      style={{
        display: "flex",
        justifyContent: "center",
        maxWidth: isExcel ? "85%" : "8.5in",
        margin: "auto",
      }}
    >
      {!file?.url ? (
        <Loader message="Loading Document" />
      ) : (
        <iframe
          id="msdoc-iframe"
          title="msdoc-iframe"
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
            file.url
          )}`}
          style={{
            width: "100%",
            height: "calc(100vh - 50px)",
            border: "none",
          }}
        />
      )}
    </div>
  );
})`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 50px);
  width: 100%;
  overflow: auto;

  #msdoc-iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`;

export default MSDocViewer;
