import styled from "styled-components";
import { createSafeContext } from "../utils/createSafeContext";
import { useUncontrolled } from "../../hooks/useUncontrolled.js";
import { nanoid } from "nanoid";

interface TabsProps {
  className?: string;
  children: React.ReactNode;
  defaultValue?: string;
  onTabChange?: () => void;
  value: string | undefined;
  style?: {};
  showBorder?: boolean;
}

interface TabsProviderProps {
  children?: React.ReactNode;
  defaultValue?: string;
  value?: string;
  onTabChange: (value?: string) => void;
  orientation?: string;
  loop?: boolean;
  id?: string;
  activateTabWithKeyboard?: boolean;
  allowTabDeactivation?: boolean;
  variant?: string;
  color?: string;
  radius?: number;
  inverted?: boolean;
  placement?: string;
  keepMounted?: boolean;
  classNames?: string[];
  styles?: string[];
  unstyled?: boolean;
}

const [TabsContextProvider, useTabsContext] =
  createSafeContext<TabsProviderProps>();

const TabsList = styled(({ className, children }) => {
  return <div className={className}>{children}</div>;
})`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Tab = styled(({ className, children, value, onClick }) => {
  const ctx = useTabsContext();

  const isActive = ctx.value === value;

  const handleTabSelect = (event: React.MouseEvent<HTMLDivElement>) => {
    ctx.onTabChange(value);
    onClick?.(event);
  };

  return (
    <div
      className={className}
      data-active={isActive || undefined}
      onClick={handleTabSelect}
    >
      {children && <span className="tab-label">{children}</span>}
    </div>
  );
})`
  padding: 0.25rem 0.75rem;
  border: none;
  cursor: pointer;
  margin-bottom: -2px;
  border-bottom: 2px solid transparent;
  .tab-label {
    display: block;
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 0.85rem;
    font-weight: 600;
  }
  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
    border-radius: 3px 3px 0 0;
  }
  &[data-active="true"] {
    border-bottom: 2px solid ${({ theme }) => theme.palette.primary.main};
    .tab-label {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

const TabsProvider = ({
  children,
  defaultValue,
  value,
  onTabChange,
  orientation,
  loop,
  id,
  activateTabWithKeyboard,
  allowTabDeactivation,
  variant,
  color,
  radius,
  inverted,
  placement,
  keepMounted = true,
  classNames,
  styles,
  unstyled,
}: TabsProviderProps) => {
  const uid = nanoid();

  const [_value, onChange] = useUncontrolled({
    value,
    defaultValue,
    finalValue: null,
    onChange: onTabChange,
  });

  return (
    <TabsContextProvider
      value={{
        placement,
        value: _value,
        orientation,
        id: uid,
        loop,
        activateTabWithKeyboard,
        onTabChange: onChange,
        allowTabDeactivation,
        variant,
        color,
        radius,
        inverted,
        keepMounted,
        classNames,
        styles,
        unstyled,
      }}
    >
      {children}
    </TabsContextProvider>
  );
};

const StyledTabs = styled(
  ({
    className,
    children,
    defaultValue,
    onTabChange = () => {},
    value,
    style = {},
  }: TabsProps) => {
    return (
      <TabsProvider
        defaultValue={defaultValue}
        onTabChange={onTabChange}
        value={value}
      >
        <div className={className} style={style}>
          {children}
        </div>
      </TabsProvider>
    );
  }
)`
  border-bottom: 2px solid
    ${({ theme, showBorder }) =>
      showBorder !== false ? theme.palette.divider : "transparent"};
`;

const Tabs: typeof StyledTabs & {
  List: typeof TabsList;
  Tab: typeof Tab;
} = StyledTabs as typeof StyledTabs & {
  List: typeof TabsList;
  Tab: typeof Tab;
};

Tabs.List = TabsList;
Tabs.Tab = Tab;

export default Tabs;
