import styled from "styled-components";

const AuditSectionHeader = styled(({ className, totalRecords = 0 }) => {
  return (
    <div className={className}>
      <div className="header-label">Audits</div>
    </div>
  );
})`
  .header-label {
    font-size: 1.5rem;
    font-weight: 500;
  }
`;

export default AuditSectionHeader;
