// Custom Modal component
import Modal from "../../../../Monolith-UI/Modal/Modal";

const DeleteCustomField = ({
  handleOpen = false,
  title = "Delete Custom Field",
  handleSubmit,
  handleClose = () => {},
}) => {
  return (
    <Modal
      open={handleOpen}
      title={title}
      width={"500px"}
      style={{ marginTop: "150px" }}
      submitText="Delete"
      submitColor="error"
      onSubmit={handleSubmit}
      onCancel={handleClose}
    >
      <div style={{ margin: "15px 0px", minHeight: 50 }}>
        Are you sure you want to delete this custom field? This will also delete
        its associated values.
      </div>
    </Modal>
  );
};

export default DeleteCustomField;
