import QualityAssuranceAPI from "../../../../api/quality_assurance/index.js";
import UserApi from "../../../../api/users/users.js";

const QaEntryFilterDimensions = [
  {
    name: "Created On",
    field: "created_on",
    id: "created_on",
    type: "date",
    mode: "date",
  },
  {
    name: "Examiner",
    field: "examiner_id",
    id: "examiner_id",
    type: "string",
    mode: "multi-select",
    key: ["users:list", { includeObservers: false, includeInactive: false }],
    selectionDisplayName: "full_name",
    selectionIdField: "user_id",
    queryFn: () =>
      UserApi.getUsers({ includeObservers: false, includeInactive: false }),
  },
  {
    name: "Reviewer",
    field: "reviewer_id",
    id: "reviewer_id",
    type: "string",
    mode: "multi-select",
    key: ["users:list", { includeObservers: false, includeInactive: false }],
    selectionDisplayName: "full_name",
    selectionIdField: "user_id",
    queryFn: () =>
      UserApi.getUsers({ includeObservers: false, includeInactive: false }),
  },
  {
    name: "Issue Type",
    field: "issue_type",
    id: "issue_type",
    type: "string",
    mode: "multi-select",
    key: ["quality-assurance:qa-entries:issue-types"],
    selectionDisplayName: "issue_type",
    selectionIdField: "issue_type",
    queryFn: () => QualityAssuranceAPI.QaEntries.getIssueTypes(),
  },
  {
    name: "Resolved",
    field: "resolved",
    id: "resolved",
    type: "string",
    mode: "multi-select",
    key: ["quality-assurance:qa-entries:is-resolved"],
    selectionDisplayName: "text",
    selectionIdField: "text",
    queryFn: () =>
      Promise.resolve([
        {
          text: "Yes",
        },
        {
          text: "No",
        },
      ]),
  },
  {
    name: "QA Checklist",
    field: "checklist_id",
    id: "checklist_id",
    type: "string",
    mode: "multi-select",
    key: ["quality-assurance:checklists"],
    selectionDisplayName: "checklist_name",
    selectionIdField: "checklist_id",
    queryFn: () => QualityAssuranceAPI.getQAChecklists(),
  },
];

export default QaEntryFilterDimensions;
