import { getDB } from "./db.js";

const getTaskState = async ({ uuid }) => {
  return getDB().TaskState.get(uuid);
};

const putTaskState = async (uuid, values = {}) => {
  return getDB().TaskState.put(values, uuid);
};

const TaskState = {
  getTaskState,
  putTaskState,
};

export default TaskState;
