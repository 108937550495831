import React, { useEffect, useRef, useState } from "react";
import { DataGrid, Column } from "devextreme-react/ui/data-grid";
import DataSource from "devextreme/data/data_source";
import { getDateFormat } from "../../../../utils/date-format";
import { useAuth } from "../../../../contexts/AuthContext";
import { Button, IconButton, Tooltip, Card } from "@mui/material";
import { usePermissions } from "../../../../hooks/usePermissions";
import Loader from "../../../../components/Loader";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import {
  deleteSavedReport,
  getSavedReports,
  saveCasesReport,
} from "../../../../api";

const savedReportsSource = new DataSource({
  key: "saved_report_id",
  load: (options) => {
    return getSavedReports(options.select);
  },
  remove: (key) => {
    return deleteSavedReport({ saved_report_id: key });
  },
});

export const CaseSavedReports = ({ reportInfo }) => {
  const savedReportsGrid = useRef(null);
  const { currentUser } = useAuth();
  const { hasPermission } = usePermissions();
  savedReportsSource.select({ report_id: reportInfo.report_id });

  useEffect(() => {
    let timer = setInterval(() => {
      let isCreating = false;
      savedReportsSource.items().every((item) => {
        if (item.is_ready === 0) {
          isCreating = true;
          return false;
        }
        return true;
      });

      if (isCreating) savedReportsGrid.current.instance.refresh();
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Card
      style={{
        padding: "20px",
        marginBottom: "20px",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              fontSize: "larger",
            }}
          >
            Saved Reports
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "5px" }}>
              <Tooltip arrow title="Refresh">
                <IconButton
                  color="primary"
                  size="small"
                  disableRipple={false}
                  onClick={(e) => {
                    savedReportsGrid.current.instance.refresh();
                  }}
                >
                  <SyncOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={(e) => {
                saveCasesReport({
                  ...reportInfo,
                  created_by_id: currentUser.user_id,
                  created_by: currentUser.full_name,
                }).then(() => {
                  savedReportsGrid.current.instance.refresh();
                });
              }}
            >
              Save Report
            </Button>
          </div>
        </div>
        <div>
          <DataGrid
            ref={savedReportsGrid}
            dataSource={savedReportsSource}
            showColumnHeaders={false}
            columnAutoWidth={false}
            hoverStateEnabled={true}
            height="350px"
          >
            <Column dataField="name"></Column>
            <Column
              width="200px"
              dataField="created_on"
              dataType="date"
              format={{
                type: getDateFormat({
                  isMoment: false,
                  includeTime: false,
                }),
              }}
            ></Column>
            <Column width="200px" dataField="created_by"></Column>
            <Column
              width={225}
              alignment="center"
              cellRender={(data) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    }}
                  >
                    {data.data.is_ready === 0 && (
                      <Loader style={{ width: 25, height: 25 }} />
                    )}
                    {data.data.is_ready === 1 && (
                      <>
                        {hasPermission() && (
                          <a
                            href={data.data.link}
                            download={data.data.report_name}
                          >
                            <Button
                              variant="text"
                              color="primary"
                              size="small"
                              style={{ height: "20px" }}
                              disabled={!hasPermission()}
                            >
                              Download
                            </Button>
                          </a>
                        )}
                        <Button
                          variant="text"
                          color="error"
                          size="small"
                          style={{ height: "20px" }}
                          onClick={() => {
                            savedReportsGrid.current.instance.deleteRow(
                              data.rowIndex
                            );
                          }}
                          disabled={!hasPermission()}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </div>
                );
              }}
            ></Column>
          </DataGrid>
        </div>
      </div>
    </Card>
  );
};

export default CaseSavedReports;
