import styled from "styled-components";

const LeftMenu = styled.div`
  width: 20rem;
  min-width: 20rem;
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-bottom: none;
  padding: 0.33rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 1.25rem;
    color: ${(props) => props.theme.palette.text.secondary};
    :hover {
      cursor: pointer;
    }
  }
`;

export default LeftMenu;
