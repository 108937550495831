import { useQuery, useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";

import {
  Button,
  Flyout,
  FlyoutHeader,
  FlyoutTitle,
} from "@monolith-forensics/monolith-ui";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EditIcon,
  TrashIcon,
} from "lucide-react";

import {
  DetailItem,
  DetailLabel,
  DetailValue,
  FlyoutContainer,
  HeaderContent,
} from ".";
import ForensicEquipmentColumnDefs from "../ForensicEquipment/ForensicEquipmentColumnDefs";
import { getEquipmentItems } from "@/api";
import EditEquipmentModal from "../Modals/EditEquipmentModal";
import DeleteEquipmentModal from "../Modals/DeleteEquipmentModal";
import { ForensicEquipment } from "@/types";

interface EquipmentFlyoutProps {
  show: boolean;
  onClose?: () => void;
  defaultInfo: any;
  onEdit: () => void;
  onDelete: () => void;
  onNext: () => void;
  onPrevious: () => void;
}

interface MainDetailsProps {
  defaultData: ForensicEquipment;
}

const MainDetails: FC<MainDetailsProps> = ({ defaultData }) => {
  return (
    <FlyoutContainer>
      <div className="event-details">
        {ForensicEquipmentColumnDefs.filter(
          (col) => col.dataField !== "description"
        ).map((col) => {
          const key = col.dataField as keyof ForensicEquipment;
          const label = col.caption;
          const value = col?.render
            ? col.render(defaultData)
            : defaultData[key];

          return (
            <DetailItem key={key}>
              <DetailLabel>{label}</DetailLabel>
              <DetailValue>{value}</DetailValue>
            </DetailItem>
          );
        })}
        <DetailItem>
          <DetailLabel>Description</DetailLabel>
        </DetailItem>
        <DetailValue>{defaultData.description || "None"}</DetailValue>
      </div>
    </FlyoutContainer>
  );
};

const EquipmentFlyout: React.FC<EquipmentFlyoutProps> = ({
  show,
  onClose,
  defaultInfo,
  onEdit,
  onDelete,
  onNext,
  onPrevious,
}) => {
  const queryClient = useQueryClient();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const { data: itemData, isLoading } = useQuery({
    queryKey: ["equipment", "list", { item_id: defaultInfo.item_id }],
    queryFn: () => getEquipmentItems({ item_id: defaultInfo.item_id }),
    initialData: [defaultInfo],
    select: (res) => {
      return res[0];
    },
  });

  return (
    <Flyout open={show} onClose={() => onClose?.()}>
      {isLoading && <div>Loading...</div>}
      {!isLoading && (
        <>
          <FlyoutHeader>
            <FlyoutTitle>{itemData.asset_number}</FlyoutTitle>
            <EditEquipmentModal
              onSubmit={() => {
                queryClient.refetchQueries({
                  queryKey: [
                    "equipment",
                    "list",
                    { item_id: itemData.item_id },
                  ],
                });
                onEdit();
              }}
              show={showEditModal}
              onClose={() => setShowEditModal(false)}
              onCancel={() => setShowEditModal(false)}
              defaultInfo={itemData}
            />
            <DeleteEquipmentModal
              show={showDeleteModal}
              onCancel={() => setShowDeleteModal(false)}
              onClose={() => setShowDeleteModal(false)}
              onSubmit={() => {
                onDelete?.();
                setShowDeleteModal(false);
              }}
              defaultInfo={itemData}
            />
            <HeaderContent>
              <div>Equipment</div>
              <Button
                size="xxs"
                variant="text"
                leftSection={<EditIcon size={14} />}
                color="primary"
                onClick={() => setShowEditModal(true)}
              >
                Edit
              </Button>
              <Button
                size="xxs"
                variant="text"
                leftSection={<TrashIcon size={14} />}
                color="error"
                onClick={() => setShowDeleteModal(true)}
              >
                Delete
              </Button>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  alignItems: "center",
                  marginLeft: "auto",
                }}
              >
                <Button
                  size="xxs"
                  variant="outlined"
                  onClick={() => onPrevious?.()}
                  style={{ padding: "2px 4px" }}
                >
                  <ChevronLeftIcon size={14} />
                </Button>
                <Button
                  size="xxs"
                  variant="outlined"
                  onClick={() => onNext?.()}
                  style={{ padding: "2px 4px" }}
                >
                  <ChevronRightIcon size={14} />
                </Button>
              </div>
            </HeaderContent>
          </FlyoutHeader>
          <MainDetails defaultData={itemData} />
        </>
      )}
    </Flyout>
  );
};

export default EquipmentFlyout;
