//Time Entry Stuff

import { httpDelete, httpGet, httpPatch, httpPost } from "../connection.js";

export async function createTimeEntry(values = {}) {
  return await httpPost(`/time-entries`, values);
}

export async function getTimeEntries(values = {}) {
  return await httpGet(`/time-entries`, values);
}

async function query(options) {
  return await httpPost(`/time-entries/query`, options);
}

async function exportTimeEntries(options) {
  return await httpPost(`/time-entries/export`, options);
}

export async function updateTimeEntry(time_id, values = {}) {
  return await httpPatch(`/time-entries/${time_id}`, values);
}

export async function deleteTimeEntry(time_id) {
  return await httpDelete(`/time-entries/${time_id}`);
}

export async function deleteTimeEntries(timeIDs) {
  return await httpDelete(`/time-entries/multiple`, { timeIDs: timeIDs });
}

export async function getTimeCategories() {
  return await httpGet(`/time-entries/categories`);
}

export async function createTimeCategory(values = {}) {
  return await httpPost(`/time-entries/categories`, values);
}

export async function updateTimeCategory(category_id, values = {}) {
  return await httpPatch(`/time-entries/categories/${category_id}`, values);
}

export async function deleteTimeCategory(category_id, options = {}) {
  return await httpDelete(`/time-entries/categories/${category_id}`, options);
}

async function getTimeEntryUsers(options = {}) {
  return await httpGet(`/time-entries/users`, options);
}

async function invoiceTimeEntries(options = {}) {
  return await httpPost(`/time-entries/invoice`, options);
}

async function unInvoiceTimeEntries(options = {}) {
  return await httpPost(`/time-entries/un-invoice`, options);
}

const TimeEntriesAPI = {
  createTimeEntry,
  getTimeEntries,
  query,
  exportTimeEntries,
  updateTimeEntry,
  deleteTimeEntry,
  deleteTimeEntries,
  getTimeCategories,
  createTimeCategory,
  updateTimeCategory,
  deleteTimeCategory,
  getTimeEntryUsers,
  invoiceTimeEntries,
  unInvoiceTimeEntries,
};

export default TimeEntriesAPI;
