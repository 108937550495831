import { useState } from "react";
import QaChecklistAPI from "../../api/quality_assurance/checklists.js";
import { Button, Modal } from "@monolith-forensics/monolith-ui";
import ButtonMenu from "../Forms/components/ButtonMenu.js";

const DeleteQaChecklistItemModal = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  buttonProps = {},
  defaultInfo = {},
}) => {
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    setSubmitting(true);

    await QaChecklistAPI.ChecklistItems.deleteCheckListItem(defaultInfo);

    onSubmit?.(defaultInfo);
    setSubmitting(false);
    onClose?.();
  };

  const handleCancel = () => {
    onCancel?.();
  };

  return (
    <Modal open={show} onClose={onClose} style={{ maxWidth: 500 }}>
      <Modal.Title>Delete Checklist Item</Modal.Title>
      <div style={{ fontWeight: 600, margin: "20px 0px" }}>
        <div>Are you sure you want to delete this checklist item:</div>
        <div style={{ fontWeight: 600, marginTop: 20 }}>
          "{defaultInfo?.item_name}"
        </div>
      </div>
      <ButtonMenu>
        <Button
          size="xs"
          variant="subtle"
          onClick={handleCancel}
          disabled={submitting}
        >
          {buttonProps?.cancelText || "Cancel"}
        </Button>
        <Button
          size="xs"
          color="error"
          variant="contained"
          onClick={handleSubmit}
          disabled={submitting}
          loading={submitting}
        >
          {buttonProps?.submitText || "Delete Checklist Item"}
        </Button>
      </ButtonMenu>
    </Modal>
  );
};

export default DeleteQaChecklistItemModal;
