function convertToTree(data) {
  const tree = [];
  const treeMap = {};
  const itemMap = {};

  data
    ?.sort((a, b) => {
      return b.is_folder - a.is_folder;
    })
    ?.forEach((note) => {
      treeMap[note.uuid] = {
        ...note,
        label: note.note_tag,
        id: note.uuid,
        children: note.is_folder === 1 ? [] : undefined,
        folder_count: 0,
        note_count: 0,
      };
    });

  data?.forEach((note) => {
    if (note.parent_id) {
      treeMap[note.parent_id]?.children.push(treeMap[note.uuid]);
    } else {
      tree.push(treeMap[note.uuid]);
    }
  });

  // calculate folder and note counts for each folder
  const calculateCounts = (node, prevPaths = []) => {
    if (!node) return;
    let folderCount = 0;
    let noteCount = 0;
    let paths = [...prevPaths];

    if (node.is_folder === 1) {
      paths.push(node.note_tag);
      node.children.forEach((child) => {
        if (child.is_folder === 1) {
          folderCount++;
          calculateCounts(child, paths);
        } else {
          child.pathArray = [...paths, child.note_tag];
          noteCount++;
        }
        folderCount += child.folder_count;
        noteCount += child.note_count;
      });
    }

    node.folder_count = folderCount;
    node.note_count = noteCount;
    node.total_items = folderCount + noteCount;
    node.pathArray = [...paths];
    itemMap[node.uuid] = {
      path: [...paths].join("/"),
      ...node,
    };

    paths.pop();

    return node;
  };

  tree.forEach((node) => {
    calculateCounts(node);
  });

  return { tree, treeMap, itemMap };
}

export default convertToTree;
