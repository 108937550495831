import { MONOLITH_PERMISSIONS } from "../constants.js";
import { useAuth } from "../contexts/AuthContext.js";

export interface UserPermission {
  description: string;
  permission: string;
  uuid: string;
}

export const usePermissions = () => {
  const { currentUser, workspace } = useAuth();

  const hasPermission = (requestedPermission?: string) => {
    // check rules if a requested permission is provided
    if (requestedPermission) {
      // check if requested permission is a read permission
      // allow read permissions if user has the requested permission
      // no need to check license or observer since we allow read operations for expired licenses and observers
      if (requestedPermission.includes(":read")) {
        return !!currentUser.permissions.find(
          (userPermission: UserPermission) =>
            userPermission.permission === requestedPermission
        );
      }
      // allow write permissions if user has the requested permission, license is not expired, and user is not an observer
      else {
        return (
          !currentUser?.licenseInfo?.is_expired &&
          currentUser.observer !== 1 &&
          !!currentUser.permissions.find(
            (userPermission: UserPermission) =>
              userPermission.permission === requestedPermission
          )
        );
      }
    }
    // if no permission is requested, check that user is not an observer and license is not expired
    // hasPermission is only used to check write permissions
    // observers and expired licenses can still read data from Monolith
    else {
      return (
        !currentUser?.licenseInfo?.is_expired && currentUser.observer !== 1
      );
    }
  };
  return {
    hasPermission,
    isAdmin: currentUser.role_id < 3,
    isSuperAdmin: currentUser.role_id === 1,
    isObserver: currentUser.observer === 1,
    currentUser,
    licenseInfo: currentUser.licenseInfo,
    workspace,
    permissions: currentUser.permissions ? currentUser.permissions : [],
    MONOLITH_PERMISSIONS,
  };
};
