import { Card, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getStorageReportMetrics } from "../../../../api";

const StorageReportMetrics = ({ reportInfo }) => {
  const [metrics, setMetrics] = useState(null);

  useEffect(() => {
    getStorageReportMetrics({
      ...reportInfo.report_parameters,
      dimension: "stats",
    }).then((result) => {
      setMetrics(result);
    });
  }, []);

  return (
    <Card
      style={{
        padding: "20px",
        textAlign: "left",
        flexGrow: 1,
      }}
    >
      <div>
        <div
          style={{
            fontSize: "larger",
          }}
        >
          Metrics
        </div>
        <Divider sx={{ my: 2 }} />
        <div
          style={{
            minWidth: "300px",
          }}
        >
          {metrics && (
            <>
              {typeof metrics.new_items !== "undefined" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>New Storage Items:</div>
                    <div>{metrics.new_items.storage_count}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>Data on New Items:</div>
                    <div>
                      {metrics.new_items.stored_data_gb
                        ? metrics.new_items.stored_data_gb.toFixed(2) + " GB"
                        : "0.00 GB"}
                    </div>
                  </div>
                </>
              )}
              {typeof metrics.assigned_items !== "undefined" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>Storage Items Assigned to Cases: </div>
                    <div>{metrics.assigned_items.storage_count}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>Data on Assigned Items:</div>
                    <div>
                      {metrics.assigned_items.stored_data_gb
                        ? metrics.assigned_items.stored_data_gb.toFixed(2) +
                          " GB"
                        : "0.00 GB"}
                    </div>
                  </div>
                </>
              )}
              {typeof metrics.released_items !== "undefined" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>Storage Items Released: </div>
                    <div>{metrics.released_items.storage_count}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>Data on Released Items:</div>
                    <div>
                      {metrics.released_items.stored_data_gb
                        ? metrics.released_items.stored_data_gb.toFixed(2) +
                          " GB"
                        : "0.00 GB"}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default StorageReportMetrics;
