export const sortTasks = (tasks, sortBy) => {
  if (sortBy === "task_name") {
    return tasks.sort((a, b) => {
      return a.task_name.localeCompare(b.task_name);
    });
  } else if (sortBy === "created_on") {
    return tasks.sort((a, b) => {
      return a.created_on - b.created_on;
    });
  } else if (sortBy === "manual") {
    return tasks.sort((a, b) => {
      return a.sort_value - b.sort_value;
    });
  }
};
