import { Link } from "react-router-dom";
import styled from "styled-components";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { useTheme } from "@mui/material";
import { getDateFormat } from "../../utils/date-format";
import CaseNumberCell from "../Evidence/CaseNumberCell.js";

const RelayLink = styled.a`
  color: ${({ color, theme }) => color || theme.palette.primary.main};
  curosr: pointer;
  font-weight: 600;

  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`;

const InquiryColumnDefs = [
  {
    dataField: "request_name",
    caption: "Request Name",
    render: (data) => {
      return <InquiryNameCell rowData={data} />;
    },
  },
  {
    dataField: "relay_url",
    caption: "Relay Link",
    visible: false,
    render: (data) => {
      if (!data.relay_url) return null;
      return (
        <RelayLink href={data.relay_url} target="_blank">
          View in Relay
          <LaunchOutlinedIcon
            className="action-button"
            style={{ fontSize: 13, marginLeft: 5 }}
          />
        </RelayLink>
      );
    },
  },
  {
    dataField: "inquiry_case_num",
    caption: "Client Ref Number",
    visible: false,
  },
  {
    dataField: "inquiry_id",
    caption: "Inquiry ID",
    visible: false,
  },
  {
    dataField: "online_form_id",
    caption: "Relay ID",
    visible: true,
  },
  {
    dataField: "inquiry_status",
    caption: "Status",
  },
  {
    dataField: "inquiry_type",
    caption: "Type",
  },
  {
    dataField: "inquiry_date",
    caption: "Inquiry Date",
    dataType: "date",
    format: {
      type: getDateFormat({ isMoment: true, includeTime: false }),
    },
  },
  {
    dataField: "case_number",
    caption: "Case Number",
    visible: false,
    render: (rowData) => {
      const CustomCell = ({ rowData }) => {
        const theme = useTheme();

        return (
          <CaseNumberCell
            rowData={rowData}
            color={theme.palette.primary.main}
          />
        );
      };

      return <CustomCell rowData={rowData} />;
    },
  },
  {
    dataField: "case_ref",
    caption: "Case Name",
    visible: false,
  },
  {
    dataField: "inquiry_convert_date",
    caption: "Converted Date",
    dataType: "date",
    format: {
      type: getDateFormat({ isMoment: true, includeTime: false }),
    },
  },
  {
    dataField: "entered_by",
    caption: "Entered By",
    visible: false,
  },
  {
    dataField: "inquiry_name",
    caption: "Client Name",
  },
  {
    dataField: "inquiry_org",
    caption: "Organization",
  },
  {
    dataField: "inquiry_email",
    caption: "Email",
    visible: false,
  },
  {
    dataField: "inquiry_notes",
    caption: "Description",
    visible: false,
    titleRender: (data) => data.inquiry_notes,
  },
  {
    dataField: "inquiry_client_type",
    caption: "Client Type",
    visible: false,
  },
  {
    dataField: "inquiry_referred",
    caption: "Referred By",
    visible: false,
  },
  {
    dataField: "evidence_count",
    caption: "Evidence Total",
    visible: false,
  },
];

export default InquiryColumnDefs.slice();

export const InquiryNameCell = styled(({ className, rowData, color }) => {
  return (
    <div className={className}>
      <Link className={"item-link"} to={`/inquiries/${rowData.inquiry_id}`}>
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            {!!rowData.request_name ? rowData.request_name : "View Inquiry"}
          </div>
          <LaunchOutlinedIcon
            className="action-button"
            style={{ fontSize: 13, marginLeft: 5 }}
          />
        </div>
      </Link>
    </div>
  );
})`
  & {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  & .item-link {
    color: ${({ color, theme }) => color || theme.palette.primary.main};
    curosr: pointer;
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  }
`;
