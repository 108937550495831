import { useTheme } from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getContacts } from "../../../../api";
import Loader from "../../../../components/Loader";
import ToolBarItems from "../../../../components/ToolBarItems";
import {
  db_timestamp,
  getDateFormat,
  monolithMoment,
} from "../../../../utils/date-format";

const ValidateImportData = styled(
  ({
    className,
    caseInfo,
    csvData,
    columnNames,
    columnMap,
    onValidated = () => {},
  }) => {
    const [isValidating, setIsValidating] = useState(true);
    const [errors, setErrors] = useState(null);
    const [preparedData, setPreparedData] = useState(null);
    const [newContacts, setNewContacts] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
      (async () => {
        if (csvData && columnNames && columnMap) {
          const compiledData = compileImportData(csvData, columnMap);
          const errorResult = validateImportData(compiledData);
          if (errorResult.length > 0) {
            setErrors(errorResult);
          } else {
            // Convert rows to create acquisition model and add case id
            const data = prepareData(compiledData, caseInfo.case_id);
            const contactInfo = await checkForNewContacts(
              data,
              caseInfo.case_id
            );
            onValidated(data);
            setPreparedData(data);
            setNewContacts(contactInfo);
          }
          setIsValidating(false);
        }
      })();
    }, [csvData, columnNames, columnMap]);

    useEffect(() => {
      if (!csvData || !columnNames || !columnMap)
        navigate(`/cases/${caseInfo.case_id}/acquisitions?v=import`);
    }, []);

    if (!csvData || !columnNames || !columnMap) return null;

    if (isValidating) {
      return (
        <div className={className}>
          <div style={{ width: 600 }}>
            <h2>Validating</h2>
            <Loader />
          </div>
        </div>
      );
    }

    if (errors) {
      return (
        <div className={className}>
          <div style={{ width: 600 }}>
            <h2>Errors</h2>
            <div>
              {errors.map((error, index) => (
                <div key={index}>
                  Row {error.rowNumber} - {error.error} - {error.value}
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={className}>
        <div style={{ width: 600 }}>
          <h2>Ready to Import?</h2>
          <div>{newContacts.length} Contacts will be created.</div>
          <div>{preparedData.length} Acquisitions will be imported.</div>
          <ExampleData data={preparedData[0]} columnMap={columnMap} />
          <ToolBarItems
            submitText="Start Import"
            cancelText="Back"
            onSubmit={() => {
              navigate(
                `/cases/${caseInfo.case_id}/acquisitions?v=import&s=progress`
              );
            }}
            onCancel={() => {
              navigate(
                `/cases/${caseInfo.case_id}/acquisitions?v=import&s=columnSelect`
              );
            }}
            style={{ marginTop: 25 }}
          />
        </div>
      </div>
    );
  }
)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 750px;
`;

const ExampleData = styled(({ className, data, columnMap }) => {
  const theme = useTheme();
  return (
    <div className={className}>
      <h3>Example Data</h3>
      {Object.entries(data).map(([key, value]) => {
        if (
          ![
            "case_id",
            "evidence_id",
            "acquired_by_id",
            "storage_items",
          ].includes(key)
        ) {
          return (
            <div
              key={key}
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "5px 0",
              }}
            >
              <div style={{ color: theme.palette.text.secondary }}>
                {columnMap[key]}
              </div>
              <div>
                {key === "acquire_date"
                  ? monolithMoment({ timestamp: value, includeTime: true })
                  : value}
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
})`
  margin-top: 35px;
  margin-bottom: 50px;
  border-radius: 5px;
`;

// Check for new contacts
const checkForNewContacts = async (data, caseId) => {
  // Get Current Contacts in the case
  const contacts = await getContacts({
    case_id: caseId,
  });

  // Get linked contacts that were provided in CSV
  // remove any null or empty string values
  // remove any that already exist in the case
  // Group them by name so there are no duplicates
  const newImportedContacts = data
    .map((d) => d.contact)
    .filter(
      (c) =>
        c !== null &&
        c !== undefined &&
        c !== "" &&
        contacts.find((contact) => contact.name === c) === undefined
    )
    .reduce((acc, cur) => {
      if (!acc.includes(cur)) acc.push(cur);
      return acc;
    }, []);

  return newImportedContacts;
};

// Create data object that maps the csv data to users column mapping choices
const compileImportData = (csvData, columnMap) => {
  const compiledData = csvData.map((row) => {
    const compiledRow = {};
    Object.keys(columnMap).forEach((key) => {
      const csvColumn = columnMap[key];
      compiledRow[key] = row[csvColumn];
    });
    compiledRow.evidence_id = columnMap["evidence_id"];
    compiledRow.acquired_by_id = columnMap["acquired_by_id"];
    compiledRow.storage_items = columnMap["storage_items"];
    return compiledRow;
  });
  return compiledData;
};

// Model data in preparation for import
const prepareData = (importData, caseId) => {
  const preparedData = importData.map((row) => {
    const preparedRow = {};
    Object.keys(row).forEach((key) => {
      // trim data
      if (typeof row[key] === "string") row[key] = row[key].trim();
      if (key === "acquire_date") {
        preparedRow[key] = convertTimestamp(row[key]);
      } else {
        preparedRow[key] = row[key];
      }
      preparedRow.case_id = caseId;
    });
    return preparedRow;
  });
  return preparedData;
};

// Validate data
const validateImportData = (importData) => {
  const errors = [];
  for (const row of importData) {
    //check that acq name exists
    if (!row.acq_name || row.acq_name === "") {
      errors.push({
        rowNumber: row.rowNumber,
        error: "Acquisition Name is required",
        value: row.acq_name,
      });
    }

    // check for valid timestamps
    if (row.acquire_date && !isValidTimestamp(row.acquire_date)) {
      errors.push({
        row: row,
        rowNumber: importData.indexOf(row),
        value: row.acquire_date,
        error: "Invalid Acquire Date - must be a valid timestamp format",
      });
    }

    //check for valid duration hours
    if (
      row.duration_hours &&
      (isNaN(row.duration_hours) || parseInt(row.duration_hours) < 0)
    ) {
      errors.push({
        row: row,
        rowNumber: importData.indexOf(row),
        value: row.duration_hours,
        error: "Invalid Hours Duration - must be a number.",
      });
    }

    //check for valid duration minutes
    if (
      row.duration_minutes &&
      (isNaN(row.duration_minutes) ||
        parseInt(row.duration_minutes) < 0 ||
        parseInt(row.duration_minutes) > 59)
    ) {
      errors.push({
        row: row,
        rowNumber: importData.indexOf(row),
        value: row.duration_minutes,
        error: "Invalid Minutes Duration - must be a number between 0 and 59.",
      });
    }

    //Check for valid size value
    if (
      row.size &&
      (isNaN(row.size) ||
        parseFloat(row.size) < 0 ||
        parseFloat(row.size) > 1000)
    ) {
      errors.push({
        row: row,
        rowNumber: importData.indexOf(row),
        value: row.size,
        error: "Invalid Size - must be a number between 0 and 999.",
      });
    }

    //check for valid size units
    if (row.size_unit && !["KB", "MB", "GB", "TB"].includes(row.size_unit)) {
      errors.push({
        row: row,
        rowNumber: importData.indexOf(row),
        value: row.size_unit,
        error: "Invalid Size Units - must be KB, MB, GB, or TB.",
      });
    }
  }

  return errors;
};

const ukDateFormats = [
  "DD/MM/YYYY",
  "DD/MM/YYYY HH:mm",
  "DD/MM/YYYY HH:mm:ss",
  "DD-MM-YYYY",
  "DD-MM-YYYY HH:mm",
  "DD-MM-YYYY HH:mm:ss",
  "DD.MM.YYYY",
  "DD.MM.YYYY HH:mm",
  "DD.MM.YYYY HH:mm:ss",
  "YYYY/MM/DD",
  "YYYY/MM/DD HH:mm",
  "YYYY/MM/DD HH:mm:ss",
  "YYYY-MM-DD",
  "YYYY-MM-DD HH:mm",
  "YYYY-MM-DD HH:mm:ss",
  "YYYY.MM.DD",
  "YYYY.MM.DD HH:mm",
  "YYYY.MM.DD HH:mm:ss",
  "DD/MM/YYYY HH:mm Z",
  "DD/MM/YYYY HH:mm:ss Z",
  "DD-MM-YYYY HH:mm Z",
  "DD-MM-YYYY HH:mm:ss Z",
  "DD.MM.YYYY HH:mm Z",
  "DD.MM.YYYY HH:mm:ss Z",
  "YYYY/MM/DD HH:mm Z",
  "YYYY/MM/DD HH:mm:ss Z",
  "YYYY-MM-DD HH:mm Z",
  "YYYY-MM-DD HH:mm:ss Z",
  "YYYY.MM.DD HH:mm Z",
  "YYYY.MM.DD HH:mm:ss Z",
  "DD/MM/YYYY HH:mm ZZ",
  "DD/MM/YYYY HH:mm:ss ZZ",
  "DD-MM-YYYY HH:mm ZZ",
  "DD-MM-YYYY HH:mm:ss ZZ",
  "DD.MM.YYYY HH:mm ZZ",
  "DD.MM.YYYY HH:mm:ss ZZ",
  "YYYY/MM/DD HH:mm ZZ",
  "YYYY/MM/DD HH:mm:ss ZZ",
  "YYYY-MM-DD HH:mm ZZ",
  "YYYY-MM-DD HH:mm:ss ZZ",
  "YYYY.MM.DD HH:mm ZZ",
  "YYYY.MM.DD HH:mm:ss ZZ",
];

const usDateFormats = [
  "MM/DD/YYYY",
  "MM/DD/YYYY HH:mm",
  "MM/DD/YYYY HH:mm:ss",
  "MM-DD-YYYY",
  "MM-DD-YYYY HH:mm",
  "MM-DD-YYYY HH:mm:ss",
  "MM.DD.YYYY",
  "MM.DD.YYYY HH:mm",
  "MM.DD.YYYY HH:mm:ss",
  "YYYY-MM-DD",
  "YYYY-MM-DD HH:mm",
  "YYYY-MM-DD HH:mm:ss",

  "YYYY/MM/DD",
  "YYYY/MM/DD HH:mm",
  "YYYY/MM/DD HH:mm:ss",

  "MM/DD/YYYY HH:mm Z",
  "MM/DD/YYYY HH:mm:ss Z",
  "MM-DD-YYYY HH:mm Z",
  "MM-DD-YYYY HH:mm:ss Z",
  "YYYY-MM-DD HH:mm Z",
  "YYYY-MM-DD HH:mm:ss Z",

  "MM/DD/YYYY HH:mm ZZ",
  "MM/DD/YYYY HH:mm:ss ZZ",
  "MM-DD-YYYY HH:mm ZZ",
  "MM-DD-YYYY HH:mm:ss ZZ",
  "YYYY-MM-DD HH:mm ZZ",
  "YYYY-MM-DD HH:mm:ss ZZ",
];

const isValidTimestamp = (timestamp) => {
  timestamp = timestamp.trim();
  const currentFormat = getDateFormat();
  if (currentFormat.includes("DD/MM/YYYY")) {
    const date = moment(timestamp, ukDateFormats, true).toDate();
    return date instanceof Date && !isNaN(date);
  }
  const date = moment(timestamp, usDateFormats, true).toDate();
  return date instanceof Date && !isNaN(date);
};

const convertTimestamp = (timestamp) => {
  timestamp = timestamp.trim();
  const currentFormat = getDateFormat();
  if (currentFormat.includes("DD/MM/YYYY")) {
    const date = moment(timestamp, ukDateFormats, true).toDate();
    return db_timestamp(date);
  }
  const date = moment(timestamp, usDateFormats, true).toDate();
  return db_timestamp(date);
};

export default ValidateImportData;
