import { httpPost } from "../connection.js";

const updateCustomField = async (values = {}) => {
  return await httpPost(`/custom-fields/update`, values);
};

const CustomFieldsAPI = {
  updateCustomField,
};

export default CustomFieldsAPI;
