import React, { useState } from "react";
import styled from "styled-components";

const ImageViewerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
`;

// Updated Image styled-component to remove max-width and max-height
const Image = styled.img`
  transition: transform 0.25s ease;
  display: block;
  margin: auto;
  max-width: 90vw;
  max-height: 90vh;
  object-fit: contain;
`;

const ImageViewer = ({ file }) => {
  const [originalDimensions, setOriginalDimensions] = useState({
    width: 0,
    height: 0,
  });

  const handleImageLoad = (e) => {
    setOriginalDimensions({
      width: e.target.naturalWidth,
      height: e.target.naturalHeight,
    });
  };

  return (
    <>
      <ImageViewerContainer>
        <Image
          src={file.url ? file.url : file.image_full_path}
          alt={file.file_name ? file.file_name : file.image_path}
          onLoad={handleImageLoad}
        />
      </ImageViewerContainer>
    </>
  );
};

export default ImageViewer;
