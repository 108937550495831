import Chart from "react-apexcharts";
import chartOptions from "./DefaultChartOptions";

const DefaultPieChart = ({ data, title, valueExpr }) => {
  let options = { ...chartOptions };

  options.labels = data.map((d) => {
    return d[valueExpr] !== null ? d[valueExpr] : "N/A";
  });

  if (data.length === 0) {
    return (
      <div>
        {title && (
          <div
            style={{
              marginBottom: "10px",
            }}
          >
            {title}
          </div>
        )}
        <div>No Data</div>
      </div>
    );
  }

  return (
    <div>
      {title && (
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          {title}
        </div>
      )}
      <Chart
        options={options}
        series={data.map((d) => {
          return d.total;
        })}
        type="donut"
        width={275}
      />
    </div>
  );
};

export default DefaultPieChart;
