import React, { useEffect, useState } from "react";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import { getStorageMeta } from "../../../api";
import CollapseSection from "../../../components/CollaspeSection";
import { getDateFormat } from "../../../utils/date-format";
import { useQuery } from "@tanstack/react-query";

const StorageParamsForm = ({ paramsForm, reportInfo }) => {
  const [editorVisiblity, setEditorVisbility] = useState({
    startDate:
      reportInfo?.report_parameters?.timestamp_interval === "Custom"
        ? true
        : false,
    endDate:
      reportInfo?.report_parameters?.timestamp_interval === "Custom"
        ? true
        : false,
    dimension: !!reportInfo?.report_parameters?.time_interval ? true : false,
  });

  let startDateRef = null;
  let endDateRef = null;

  const {
    data: {
      types: storageTypes,
      sizes: storageSizes,
      brands: storageBrands,
      model_names: storageModels,
    },
  } = useQuery({
    queryKey: ["storage:meta"],
    queryFn: () => getStorageMeta(),
    initialData: {
      storage_types: [],
      storage_sizes: [],
      storage_brands: [],
      storage_models: [],
    },
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <CollapseSection
        title={"Report Parameters | Storage - Overview"}
        allowCollapse={false}
      >
        <Form
          ref={paramsForm}
          colCount={2}
          defaultFormData={reportInfo || null}
        >
          <SimpleItem
            dataField="report_parameters.time_interval"
            label={{ text: "Time Interval", location: "top" }}
            colSpan={1}
            editorType="dxSelectBox"
            editorOptions={{
              items: [
                "Custom",
                "Today",
                "Yesterday",
                "This Week",
                "Last Week",
                "This Month",
                "Last Month",
                "This Year",
                "Last Year",
                "All Time",
              ],
              showClearButton: true,
              dropDownOptions: {
                maxHeight: 250,
              },
              onValueChanged: (e) => {
                setEditorVisbility({
                  ...editorVisiblity,
                  startDate: e.value === "Custom",
                  endDate: e.value === "Custom",
                  dimension: e.value !== null,
                });
              },
            }}
          />
          <SimpleItem
            dataField="report_parameters.timestamp_dimension"
            label={{ text: "Time Dimension", location: "top" }}
            colSpan={1}
            editorType="dxSelectBox"
            visible={editorVisiblity.dimension}
            editorOptions={{
              items: ["Date Created", "Date Assigned", "Date Released"],
            }}
          />
          <SimpleItem
            dataField="report_parameters.start_date"
            label={{ text: "Start Date", location: "top" }}
            editorType="dxDateBox"
            colSpan={1}
            isRequired={true}
            visible={editorVisiblity.startDate}
            editorOptions={{
              onInitialized: (e) => {
                startDateRef = e.component;
              },
              onValueChanged: (e) => {
                endDateRef.option("min", e.value);
              },
              type: "date",
              useMaskBehavior: true,
              displayFormat: getDateFormat({
                isMoment: false,
                includeTime: false,
              }),
            }}
          />
          <SimpleItem
            dataField="report_parameters.end_date"
            label={{ text: "End Date", location: "top" }}
            editorType="dxDateBox"
            colSpan={1}
            isRequired={true}
            visible={editorVisiblity.endDate}
            editorOptions={{
              onInitialized: (e) => {
                endDateRef = e.component;
              },
              onValueChanged: (e) => {
                startDateRef.option("max", e.value);
              },
              type: "date",
              useMaskBehavior: true,
              displayFormat: getDateFormat({
                isMoment: false,
                includeTime: false,
              }),
            }}
          />
          <SimpleItem
            dataField="report_parameters.storage_types"
            label={{ text: "Types" }}
            colSpan={1}
            editorType="dxTagBox"
            editorOptions={{
              valueExpr: "type",
              displayExpr: "type",
              items: storageTypes,
              showClearButton: false,
              showDropDownButton: true,
              multiline: false,
              showSelectionControls: true,
              selectAllMode: "allPages",
              dropDownOptions: {
                maxHeight: 250,
              },
            }}
          />
          <SimpleItem
            dataField="report_parameters.storage_sizes"
            label={{ text: "Sizes" }}
            colSpan={1}
            editorType="dxTagBox"
            editorOptions={{
              valueExpr: "storage_size",
              displayExpr: "storage_size",
              items: storageSizes,
              showClearButton: false,
              showDropDownButton: true,
              multiline: false,
              showSelectionControls: true,
              selectAllMode: "allPages",
              dropDownOptions: {
                maxHeight: 250,
              },
            }}
          />
          <SimpleItem
            dataField="report_parameters.storage_brands"
            label={{ text: "Brands" }}
            colSpan={1}
            editorType="dxTagBox"
            editorOptions={{
              valueExpr: "make",
              displayExpr: "make",
              items: storageBrands,
              showClearButton: false,
              showDropDownButton: true,
              multiline: false,
              showSelectionControls: true,
              selectAllMode: "allPages",
              dropDownOptions: {
                maxHeight: 250,
              },
            }}
          />
          <SimpleItem
            dataField="report_parameters.storage_models"
            label={{ text: "Model Names" }}
            colSpan={1}
            editorType="dxTagBox"
            editorOptions={{
              valueExpr: "model_name",
              displayExpr: "model_name",
              items: storageModels,
              showClearButton: false,
              showDropDownButton: true,
              multiline: false,
              showSelectionControls: true,
              selectAllMode: "allPages",
              dropDownOptions: {
                maxHeight: 250,
              },
            }}
          />
        </Form>
      </CollapseSection>
    </>
  );
};

export default StorageParamsForm;
