import { useParams } from "react-router-dom";
import styled from "styled-components";
import { usePermissions } from "../../../../hooks/usePermissions";
import { useReportParams } from "../contexts/ReportParamsContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import CaseReportsAPI from "../../../../api/cases/reports.js";
import RTEditor from "../../../../Monolith-UI/RTEditor/RTEditor.js";
import { useEditor } from "@tiptap/react";
import getTipTapExtensions from "../../../../Monolith-UI/RichTextEditor/extensions/index.js";
import getTipTapEditorProps from "../../../../Monolith-UI/RichTextEditor/props.js";
import { useDebouncedCallback } from "use-debounce";
import { useCallback, useEffect, useRef } from "react";
import MonolithNotesAPI from "../../../../api/Notes/index.js";
import moment from "moment";
import * as Tooltip from "@radix-ui/react-tooltip";

import { CheckIcon, InfoIcon } from "lucide-react";

const EditorWrapper = ({
  content,
  onContentUpdate,
  handleImageUpload,
  readOnly,
  onSave,
}) => {
  const editor = useEditor({
    content: content,
    editable: !readOnly,
    extensions: getTipTapExtensions({
      handleImageUpload,
    }),
    editorProps: getTipTapEditorProps({
      handleImageUpload,
    }),
    onUpdate: ({ editor }) => {
      onContentUpdate(editor.getHTML());
    },
  });

  const handleSave = useCallback(
    (e) => {
      // save on ctrl+s
      if (e.ctrlKey && e.key === "s") {
        e.preventDefault();
        onSave?.(editor.getHTML());
      }
    },
    [editor, onSave]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleSave);

    return () => {
      window.removeEventListener("keydown", handleSave);
    };
  }, [editor, handleSave]);

  return (
    <RTEditor
      editor={editor}
      showToolbar={!readOnly}
      style={{ height: "calc(100vh - 200px)", maxWidth: 1000 }}
    />
  );
};

const CaseReportSummary = styled(({ className }) => {
  const queryClient = useQueryClient();
  const { case_report_id } = useParams();
  const { hasPermission } = usePermissions();
  const { caseInfo } = useReportParams();
  const showSave = useRef(null);

  const readOnly = !hasPermission();
  const queryKey = [
    "cases",
    "reports",
    "narrative",
    "summary",
    { case_report_id, type_id: 1 },
  ];

  const { data } = useQuery({
    queryKey,
    queryFn: () =>
      CaseReportsAPI.getCaseReportNarrative({ case_report_id, type_id: 1 }),
    placeholderData: (data) => data,
  });

  const toggleShowSave = () => {
    if (showSave.current) {
      showSave.current.style.visibility === "visible"
        ? (showSave.current.style.visibility = "hidden")
        : (showSave.current.style.visibility = "visible");
    }
  };

  const handleUpdateContent = (content) => {
    // Do not upload base64 data
    // Image updloads and edit will be handled by the image uploader
    if (content.includes('src="data:image/')) {
      const parseHTML = new DOMParser().parseFromString(content, "text/html");

      const images = parseHTML.querySelectorAll(".monolith-image");

      // clear base64 data
      images.forEach((img) => {
        img.src = "";
        img.classList = "monolith-image";
      });

      // convert to string
      content = parseHTML.body.innerHTML;
    }

    queryClient.setQueryData(queryKey, (oldData) => {
      const prev = oldData?.[0] || {};
      return [
        {
          ...prev,
          html: content,
        },
      ];
    });

    CaseReportsAPI.updateCaseReportNarrative({
      narrative_id: data?.[0].narrative_id,
      html: content,
    }).then(() => {
      toggleShowSave();
      setTimeout(() => {
        toggleShowSave();
      }, 1000);
    });
  };

  const handleImageUpload = async (imageInfo) => {
    return new Promise(async (resolve, reject) => {
      const uploadURL = await MonolithNotesAPI.getUploadUrl({
        filename: imageInfo.name,
        case_id: caseInfo.case_id,
      });

      await MonolithNotesAPI.uploadImage({
        url: uploadURL.uploadUrl,
        file: imageInfo.file,
      });

      let image = new Image();
      image.src = uploadURL.downloadUrl;
      image.onload = () => {
        resolve(uploadURL.downloadUrl);
      };

      // Send API request to add attachment record for note and img
      MonolithNotesAPI.addAttachment({
        note_uuid: data.uuid,
        uuid: imageInfo.id,
        filename: imageInfo.name,
        size: imageInfo.file.size,
        created_on: moment().toISOString(),
        md5: imageInfo.md5,
        sha1: imageInfo.sha1,
        sha256: imageInfo.sha256,
      });
    });
  };

  const debouncedUpdate = useDebouncedCallback(handleUpdateContent, 600);

  return (
    <div className={className}>
      <Tooltip.Provider delayDuration={600}>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <div className="info-icon">
              <InfoIcon size={12} />
            </div>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content
              className={className + " tooltip-content"}
              side="bottom"
              sideOffset={10}
              align="start"
              onClick={(e) => e.stopPropagation()}
            >
              <h2>Report Summary</h2>
              <p>
                Draft a summary that is unique to this report. If included, the
                report summary will display on the case summary page of the
                Monolith Case Report.
              </p>
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
      <div ref={showSave} className="auto-save-indicator">
        <CheckIcon size={12} />
        Auto-saved
      </div>
      {data && (
        <EditorWrapper
          content={data?.[0]?.html || ""}
          onContentUpdate={debouncedUpdate}
          handleImageUpload={handleImageUpload}
          readOnly={readOnly}
          onSave={handleUpdateContent}
        />
      )}
    </div>
  );
})`
  position: relative;

  &.tooltip-content {
    display: flex;
    flex-direction: column;
    pointer-events: none;
    justify-content: center;
    align-items: flex-start;
    z-index: 100000;
    background-color: ${({ theme }) => theme.palette.background.default};
    padding: 15px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.palette.divider};
    font-size: 0.75rem;
    max-width: 300px;
    width: 250px;

    h2 {
      margin: 0;
    }

    p {
      margin: 0;
    }

    .title {
      font-size: 0.85rem;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }

  .info-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    color: ${({ theme }) => theme.palette.text.secondary};

    &:hover {
      color: ${({ theme }) => theme.palette.text.primary};
    }
  }

  .auto-save-indicator {
    position: absolute;
    top: 10px;
    left: 20px;
    display: flex;
    align-items: center;
    font-size: 12px;
    visibility: hidden;
    color: ${({ theme }) => theme.palette.success.main};
    margin-left: 10px;
  }
`;

CaseReportSummary.displayName = "CaseReportSummary";

export default CaseReportSummary;
