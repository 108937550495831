import { getEquipmentItems } from "@/api";
import ForensicSoftwareAPI from "@/api/software";
import { SelectBox } from "@monolith-forensics/monolith-ui";
import { useQuery } from "@tanstack/react-query";
import { FC } from "react";

interface LocationSelectorProps {
  defaultValue: any;
  onChange?: (value: any) => void;
  error?: string;
  queryKey: "software" | "equipment";
}

type Location = {
  location: string;
};

const locationQueryKeyMap = {
  software: ForensicSoftwareAPI.getSoftwareLocations,
  equipment: getEquipmentItems,
};

const LocationSelector: FC<LocationSelectorProps> = ({
  defaultValue,
  onChange,
  error,
  queryKey,
}) => {
  const { data: locations } = useQuery<Location[]>({
    queryKey: [queryKey, "locations"],
    queryFn: () => locationQueryKeyMap[queryKey](),
  });

  const options = locations?.map((location) => ({
    label: location.location,
    value: location.location,
  }));

  return (
    <>
      <SelectBox
        defaultValue={defaultValue}
        onChange={onChange}
        error={error}
        size="sm"
        variant="outlined"
        label="Location"
        placeholder="Select or enter location name"
        data={options}
        clearable={true}
        searchable={true}
        allowCustomValue={true}
        openOnFocus={false}
      />
    </>
  );
};

export default LocationSelector;
