import moment from "moment";
import { Button, Card, Divider } from "@mui/material";
import { usePermissions } from "../../../../hooks/usePermissions";
import { MONOLITH_PERMISSIONS } from "../../../../constants.js";

export const FprParameters = ({ reportInfo, editReportPopup }) => {
  const { hasPermission } = usePermissions();
  return (
    <Card
      style={{
        padding: "20px",
        textAlign: "left",
        flexGrow: 1,
        flexBasis: "10px",
        marginRight: 15,
      }}
    >
      <>
        <div
          style={{
            fontSize: "larger",
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
          }}
        >
          <span style={{ display: "flex", alignItems: "center" }}>
            Report Parameters
          </span>
          <Button
            size="small"
            onClick={() => editReportPopup.current.instance.show()}
            disabled={
              !hasPermission(MONOLITH_PERMISSIONS.METRICS_REPORTS_UPDATE)
            }
          >
            Edit
          </Button>
        </div>
        <Divider sx={{ my: 2 }} />
        <div
          style={{
            minWidth: "300px",
            overflowY: "auto",
            maxHeight: "175px",
            paddingRight: "10px",
          }}
        >
          {reportInfo.report_parameters && (
            <>
              <div
                style={{
                  minWidth: "300px",
                }}
              >
                {reportInfo.report_parameters.time_interval && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>Time Interval:</div>
                    <div
                      style={{
                        maxWidth: 150,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      title={reportInfo.report_parameters.time_interval || ""}
                    >
                      {reportInfo.report_parameters.time_interval}
                    </div>
                  </div>
                )}
                {reportInfo.report_parameters.start_date && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>Start Date:</div>
                    <div
                      style={{
                        maxWidth: 150,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      title={
                        moment(reportInfo.report_parameters.start_date).format(
                          "MMMM DD, YYYY"
                        ) || ""
                      }
                    >
                      {moment(reportInfo.report_parameters.start_date).format(
                        "MMMM DD, YYYY"
                      )}
                    </div>
                  </div>
                )}
                {reportInfo.report_parameters.end_date && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>End Date:</div>
                    <div
                      style={{
                        maxWidth: 150,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      title={
                        moment(reportInfo.report_parameters.end_date).format(
                          "MMMM DD, YYYY"
                        ) || ""
                      }
                    >
                      {moment(reportInfo.report_parameters.end_date).format(
                        "MMMM DD, YYYY"
                      )}
                    </div>
                  </div>
                )}
                {reportInfo.report_parameters.timestamp_dimension && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>Timestamp Dimension:</div>
                    <div
                      style={{
                        maxWidth: 150,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      title={
                        reportInfo.report_parameters.timestamp_dimension || ""
                      }
                    >
                      {reportInfo.report_parameters.timestamp_dimension}
                    </div>
                  </div>
                )}
                {reportInfo.report_parameters.case_lead && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>Case Lead:</div>
                    <div
                      style={{
                        maxWidth: 150,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      title={
                        reportInfo.report_parameters.case_lead
                          .map((lead) => lead.full_name)
                          .join(", ") || ""
                      }
                    >
                      {reportInfo.report_parameters.case_lead
                        .map((lead) => lead.full_name)
                        .join(", ")}
                    </div>
                  </div>
                )}
                {reportInfo.report_parameters.case_statuses && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>Case Status:</div>
                    <div
                      style={{
                        maxWidth: 150,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      title={
                        reportInfo.report_parameters.case_statuses.join(", ") ||
                        ""
                      }
                    >
                      {reportInfo.report_parameters.case_statuses.join(", ")}
                    </div>
                  </div>
                )}
                {reportInfo.report_parameters.fpr_status && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>FPR Status:</div>
                    <div>{reportInfo.report_parameters.fpr_status}</div>
                  </div>
                )}
              </div>
            </>
          )}
          {!reportInfo.report_parameters && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "5px",
              }}
            >
              <div>No Parameters Set</div>
            </div>
          )}
        </div>
      </>
    </Card>
  );
};
