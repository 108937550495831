import * as Popover from "@radix-ui/react-popover";
import styled from "styled-components";

import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";

const StyledButton = styled.button`
  font-size: 0.75rem;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 3px;
  border-radius: 4px;
  width: fit-content;
  min-width: max-content;
  height: max-content;
  padding: 3px 5px;
  color: ${({ theme, textColor }) =>
    textColor === "secondary"
      ? theme.palette.text.secondary
      : theme.palette.text.primary};
  transition: all 0.15s ease-in-out;
  border: 1px solid
    ${(props) => {
      if (props.variant === "outlined") return props.theme.palette.divider;
      if (props.variant === "text") return "transparent";
      if (props.variant === "contained") return "transparent";
      return "transparent";
    }};
  background-color: ${(props) => {
    if (props.variant === "contained")
      return props.theme.palette.background.secondary;
    if (props.variant === "outlined") return "transparent";
    if (props.variant === "text") return "transparent";
    return "transparent";
  }};
  ${(props) =>
    props.enabled === undefined || props.enabled
      ? `&:hover {
    border: 1px solid ${props.theme.palette.primary.main};
  }`
      : ""}
  outline: none;
`;

export const MenuItem = styled.div`
  font-size: 12px;
  line-height: 1;
  color: ${(props) => props.theme.palette.text.primary};
  border-radius: 3px;
  display: flex;
  align-items: center;
  min-height: 25px;
  padding: 0 5px;
  position: relative;
  user-select: none;
  outline: none;

  &[data-disabled] {
    color: ${(props) => props.theme.palette.text.secondary};
    pointer-events: "none";
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.action.hover};
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

const StyledPopoverContent = styled(Popover.Content)`
  min-width: fit-content;
  background-color: ${(props) => props.theme.palette.background.default};
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.palette.divider};
  overflow: hidden;
  padding: 8px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.85),
    0px 10px 20px -15px rgba(22, 23, 24, 0.65);

  z-index: 100000;

  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-side="top"] {
    animation-name: slideDownAndFade;
  }
  &[data-side="right"] {
    animation-name: slideLeftAndFade;
  }
  &[data-side="bottom"] {
    animation-name: slideUpAndFade;
  }
  &[data-side="left"] {
    animation-name: slideRightAndFade;
  }

  @keyframes slideUpAndFade {
    from {
      opacity: 0;
      transform: translateY(2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideRightAndFade {
    from {
      opacity: 0;
      transform: translateX(-2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: translateY(-2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideLeftAndFade {
    from {
      opacity: 0;
      transform: translateX(2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

const DropdownPopover = ({
  children,
  arrow = false,
  variant = "outlined",
  label = "Dropdown Popover",
  buttonProps = {},
  contentProps = {},
}) => {
  return (
    <Popover.Root modal={true}>
      <Popover.Trigger asChild>
        <StyledButton variant={variant} {...buttonProps}>
          {label}
          {arrow && (
            <ArrowDropDownOutlinedIcon
              style={{ fontSize: 16, marginLeft: 5 }}
            />
          )}
        </StyledButton>
      </Popover.Trigger>
      <Popover.Portal>
        <StyledPopoverContent
          sideOffset={5}
          align="start"
          collisionPadding={20}
          {...contentProps}
        >
          {children}
        </StyledPopoverContent>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default DropdownPopover;
