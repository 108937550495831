import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { createContext } from "react";
import { io } from "socket.io-client";
import { getBaseURL } from "../api/connection.js";
import { useAuth } from "./AuthContext.js";

const SocketContext = createContext();

const SocketProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [monolithAgentSocket, setMonolithAgentSocket] = useState(null);
  const [monolithServerSocket, setMonolithServerSocket] = useState(null);

  const monolith_tenant = currentUser?.licenseInfo?.tenant || null;

  // Monolith Agent Socket Initialization
  // useEffect(() => {
  //   const socket = io("http://localhost:31415", {
  //     pingInterval: 10000,
  //   });
  //   setMonolithAgentSocket(socket);
  //   socket.on("connect", () => {
  //     console.log("connected to monolith agent socket");
  //   });
  //   socket.on("disconnect", () => {
  //     console.log("disconnected from monolith agent socket");
  //   });
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  // Monolith Server Socket Initialization
  useEffect(() => {
    const socket = io(
      process.env.NODE_ENV === "development"
        ? "http://localhost:3001"
        : getBaseURL(),
      {
        withCredentials: true,
        path: "/sio/v1",
        extraHeaders: {
          "x-tenant-slug": monolith_tenant,
        },
      }
    );
    setMonolithServerSocket(socket);
    socket.on("connect", () => {
      console.log("connected to monolith server socket");
    });
    socket.on("disconnect", () => {
      console.log("disconnected from monolith server socket");
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider
      value={{
        MonolithAgentSocket: monolithAgentSocket,
        MonolithServerSocket: monolithServerSocket,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

const useSocket = () => {
  const context = useContext(SocketContext);

  if (context === undefined) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return context;
};

export { SocketProvider, useSocket };
