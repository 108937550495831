import { memo, useMemo, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import moment from "moment";
import { useQueryClient } from "@tanstack/react-query";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  ArchiveIcon,
  ArrowLeftRightIcon,
  CalendarIcon,
  ClockIcon,
  MoreHorizontalIcon,
  PlusIcon,
  ShapesIcon,
  SignalIcon,
  Trash2Icon,
  UsersIcon,
} from "lucide-react";
import { Link } from "react-router-dom";

import TaskButton from "../../../../Monolith-UI/TaskButton.js";
import DropdownMenu from "../../../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import { usePermissions } from "../../../../hooks/usePermissions";
import TasksAPI from "../../../../api/tasks/tasks.js";
import { db_timestamp } from "../../../../utils/date-format";
import TimeEntriesAPI from "../../../../api/TimeEntries/time-entries.js";
import TaskDueDateOptions from "../TaskDueDateOptions.js";
import Calendar from "../../../../Monolith-UI/Calendar/Calendar.js";
import TaskStatuses from "../TaskStatuses.js";
import TaskPriorities from "../TaskPriorities.js";
import UserApi from "../../../../api/users/users.js";
import getRandomColor from "../../../../utils/getRandomColor.js";
import TaskDurationOptions from "../TaskDurationOptions.js";
import ContextMenu from "../../../../Monolith-UI/ContextMenu/ContextMenu.js";
import TaskStatusSelector from "../TaskStatusSelector.js";
import TaskProperties from "../TaskProperties.js";
import LinkedObject from "../LinkedObject.js";
import SubTasksIndicator from "../SubTasksIndicator.js";
import TaskPrioritySelector from "../TaskPrioritySelector.js";
import TaskDuedateSelector from "../TaskDueDateSelector.js";
import TaskDurationSelector from "../TaskDurationSelector.js";
import AssigneeSelector from "../AssigneeSelector.js";
import AddCustomTimeModal from "../AddCustomTimeModal.js";
import DeleteTaskModal from "../DeleteTaskModal.js";

export const StickyScrollContainer = styled(OverlayScrollbarsComponent)`
  flex-grow: 1;
  position: relative;
  overflow: auto;
  overscroll-behavior: none;
  transition: all 0.2s ease-in-out;
`;

export const TableWrapper = styled(({ className, onScroll, children }) => {
  const theme = useTheme();
  const scrollContainerRef = useRef(null);

  const handleScroll = (e) => {
    onScroll?.(e);
  };

  return (
    <div className={className}>
      <div className="taskList-table">
        <div className="sticky-column-wrapper">
          <StickyScrollContainer
            ref={scrollContainerRef}
            className="sticky-scroll-container"
            options={{
              paddingAbsolute: false,
              showNativeOverlaidScrollbars: true,
              update: {
                elementEvents: [["img", "load"]],
                debounce: [0, 33],
                attributes: null,
                ignoreMutation: null,
              },
              overflow: {
                x: "scroll",
                y: "scroll",
              },
              scrollbars: {
                theme:
                  theme.name === "DARK" ? "os-theme-dark" : "os-theme-light",
                visibility: "auto",
                autoHide: "scroll",
                autoHideDelay: 500,
                autoHideSuspend: false,
                dragScroll: true,
                clickScroll: false,
                pointers: ["mouse", "touch", "pen"],
              },
            }}
            events={{
              scroll: (instance, event) => {
                handleScroll(event);
              },
            }}
          >
            {children}
          </StickyScrollContainer>
        </div>
      </div>
    </div>
  );
})`
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .taskList-table {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .sticky-column-wrapper {
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .table-wrapper {
    position: relative;
  }

  table {
    position: relative;
    border-collapse: separate;
    border-spacing: 0;
    min-width: 100%;
    width: 3000px;
    table-layout: fixed;
    border-top: none;
    border-radius: 4px;
  }

  thead,
  tr {
    position: relative;
  }

  th {
    padding: 5px;
    text-align: left;
    background-color: ${({ theme }) => theme.palette.background.alt};
    position: sticky;
    top: 0;
    z-index: 1;
    border-top: 1px solid ${({ theme }) => theme.palette.divider};
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};

    &:first-child {
      border-left: 1px solid ${({ theme }) => theme.palette.divider};
      border-top-left-radius: 4px;
    }

    &:last-child {
      border-right: 1px solid ${({ theme }) => theme.palette.divider};
      border-top-right-radius: 4px;
    }
  }

  td {
    background-color: ${({ theme }) => theme.palette.background.default};

    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};

    &:first-child {
      border-left: 1px solid ${({ theme }) => theme.palette.divider};
    }

    &:last-child {
      border-right: 1px solid ${({ theme }) => theme.palette.divider};
    }
  }
`;

const GroupRow = styled(
  ({ className, group, style, onAddItem, onArchiveTasks }) => {
    const theme = useTheme();
    const Icon = group.icon;

    return (
      <div className={className} style={style}>
        {group.icon && <Icon size={14} style={{ color: group.iconColor }} />}
        <div>{group.label}</div>
        <div>{group?.tasks?.length || 0}</div>
        <div className="group-menu">
          <TaskButton onClick={() => onAddItem?.(group)}>
            <PlusIcon size={16} />
          </TaskButton>
          <DropdownMenu
            menuItems={[
              {
                label: "Archive Tasks",
                icon: ArchiveIcon,
                menuItemProps: {
                  style: {
                    color: theme.palette.primary.main,
                  },
                },
                onClick: () => onArchiveTasks?.(group),
              },
            ]}
            title={"Column Actions"}
            variant={"text"}
            textColor={"secondary"}
          >
            <MoreHorizontalIcon size={16} />
          </DropdownMenu>
        </div>
      </div>
    );
  }
)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px;
  padding-left: 12px;
  border-bottom: 1px solid transparent;
  background-color: ${({ theme }) =>
    theme.name === "DARK" ? theme.palette.background.alt : "#f4f5f8"};
  font-size: 0.8rem;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.text.secondary};

  .group-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-left: auto;
  }
`;

const WidgetWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const TaskRow = styled(
  ({
    className,
    task,
    onItemUpdate,
    onItemRemove,
    onItemArchived,
    onAddItem,
    displayPreferences,
    style = {},
  }) => {
    const theme = useTheme();
    const { currentUser } = usePermissions();
    const queryClient = useQueryClient();
    const [contextMenuOpen, setContextMenuOpen] = useState(false);
    const [showCustomTimeModal, setShowCustomTimeModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const dueDate = task?.due_date || null;

    const TaskCategories = queryClient.getQueryData(["task:categories"]);

    const caseUsers = queryClient.getQueryData([
      "users",
      "list",
      { include_observers: false, include_inactive: false },
    ]);

    const handleStatusChange = (status) => {
      onItemUpdate?.({
        status_id: status.status_id,
        uuid: task.uuid,
      });

      TasksAPI.updateTask({
        status_id: status.status_id,
        uuid: task.uuid,
      });
    };

    const handlePriorityChange = (priority) => {
      onItemUpdate?.({
        priority_id: priority.priority_id,
        uuid: task.uuid,
      });

      TasksAPI.updateTask({
        priority_id: priority.priority_id,
        uuid: task.uuid,
      });
    };

    const handleDueDateChange = (value) => {
      const duedate = value?.value || null;

      onItemUpdate?.({
        due_date: duedate,
        uuid: task.uuid,
      });

      TasksAPI.updateTask({
        due_date: duedate,
        uuid: task.uuid,
      });
    };

    const handleDurationChange = (item) => {
      if (item.label === "Custom") {
        setShowCustomTimeModal(true);
        return;
      }

      let newDuration = (task?.duration || 0) + item.duration;

      onItemUpdate?.({
        duration: newDuration,
        uuid: task.uuid,
      });

      const currentTime = db_timestamp();

      //Create Time entry in API
      TimeEntriesAPI.createTimeEntry({
        task_id: task.task_id,
        case_id: task.case_id,
        user_id: currentUser.user_id,
        entry_date: currentTime,
        start_time:
          item.start_time ||
          moment(currentTime)
            .subtract(item.duration, "s")
            .format("YYYY-MM-DD HH:mm:ss"),
        end_time: item.end_time || currentTime,
        duration: item.duration,
        is_bulk: 1,
        category_id: item.time_category_id || task.time_category_id || null,
      });
    };

    const handleCategoryChange = (category) => {
      onItemUpdate?.({
        time_category_id: category.category_id,
        uuid: task.uuid,
      });

      TasksAPI.updateTask({
        time_category_id: category.category_id,
        uuid: task.uuid,
      });
    };

    const handleAssigneeChange = ({ selections }) => {
      const currentAssignees = task?.assignees || [];

      const selectedUsers = selections
        .map((selection) => selection.value)
        .map((user_id) =>
          caseUsers.find((u) => {
            return u.user_id === user_id;
          })
        );

      const newAssignees = selectedUsers.filter(
        (user) => !currentAssignees.map((a) => a.user_id).includes(user.user_id)
      );

      const removedAssignees = currentAssignees.filter(
        (user) => !selectedUsers.map((u) => u.user_id).includes(user.user_id)
      );

      onItemUpdate?.({
        assignees: selectedUsers,
        uuid: task.uuid,
      });

      if (removedAssignees.length > 0) {
        TasksAPI.removeUsersFromTask({
          users: removedAssignees.map((a) => a.user_id),
          task_id: task.task_id,
          task_uuid: task.uuid,
          removed_by_id: currentUser.user_id,
        });
      }

      if (newAssignees.length > 0) {
        TasksAPI.assignUsersToTask({
          users: newAssignees.map((a) => a.user_id),
          task_id: task.task_id,
          task_uuid: task.uuid,
          assigned_by_id: currentUser.user_id,
        });
      }
    };

    const handleArchiveTask = (taskdata) => {
      TasksAPI.archiveTasks({ uuids: [taskdata.uuid] });

      onItemArchived?.({ task: taskdata });
    };

    const handleDeleteTask = (taskData) => {
      TasksAPI.deleteTask({ task_id: taskData.task_id });
      onItemRemove?.({ task: taskData });
    };

    const DueDateContent = useMemo(() => {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 15,
            marginTop: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: 5,
            }}
          >
            {TaskDueDateOptions.filter((item) => item?.getDate).map(
              (item, index) => {
                return (
                  <TaskButton
                    key={index}
                    variant="outlined"
                    onClick={() => {
                      handleDueDateChange?.({
                        label: item.getLabel(),
                        value: item.getValue(),
                      });
                    }}
                    style={{
                      fontSize: "0.65rem",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    {item.label}
                  </TaskButton>
                );
              }
            )}
          </div>
          <div style={{ margin: "auto" }}>
            <Calendar
              key={1}
              defaultValue={
                dueDate
                  ? moment(
                      Array.isArray(dueDate) ? dueDate[0] : dueDate
                    ).toDate()
                  : undefined
              }
              onChange={(date) => {
                handleDueDateChange?.(
                  date
                    ? {
                        label: moment(date).format("MMM DD"),
                        value: moment(date).format("YYYY-MM-DD"),
                      }
                    : null
                );
              }}
              includeTime={false}
            />
          </div>
        </div>
      );
    }, [dueDate]);

    const contextMenuItems = [
      {
        label: "Status",
        value: "status",
        icon: ArrowLeftRightIcon,
        items: [
          {
            radioGroup: true,
            value: task?.status_id,
            onSelectionChanged: (value) => {
              handleStatusChange(
                TaskStatuses.find((s) => s.status_id === value.value)
              );
            },
            items: TaskStatuses.map((status) => ({
              label: status.status_name,
              value: status.status_id,
              icon: status.icon,
              iconColor: status.color,
            })),
          },
        ],
      },
      {
        label: "Priority",
        value: "priority",
        icon: SignalIcon,
        items: [
          {
            radioGroup: true,
            value: task?.priority_id,
            onSelectionChanged: (value) => {
              handlePriorityChange(
                TaskPriorities.find((p) => p.priority_id === value.value)
              );
            },
            items: TaskPriorities.map((priority) => ({
              label: priority.priority_name,
              value: priority.priority_id,
              icon: priority.icon,
              iconColor: priority.color,
            })),
          },
        ],
      },
      {
        label: "Assignees",
        value: "assignees",
        icon: UsersIcon,
        items: [
          {
            checkboxGroup: true,
            value: task?.assignees?.map((a) => a.user_id) || [],
            onSelectionChanged: (value) => {
              handleAssigneeChange({
                selections: value,
              });
            },
            queryKey: [
              "users",
              "list",
              {
                include_observers: false,
                include_inactive: false,
                case_id: task?.case_id,
              },
            ],
            items: async () => {
              const result = await UserApi.getUsers({
                include_observers: false,
                include_inactive: false,
                case_id: task?.case_id,
              });

              return result?.map((user) => ({
                label: user.full_name,
                value: user.user_id,
                icon: ({ color, style }) => (
                  <div
                    style={{
                      borderRadius: "50%",
                      height: 10,
                      width: 10,
                      backgroundColor: color,
                      ...style,
                    }}
                  ></div>
                ),
                iconColor: getRandomColor(user.user_id, user.user_id),
              }));
            },
          },
        ],
      },
      {
        label: "Due Date",
        value: "due_date",
        icon: CalendarIcon,
        content: DueDateContent,
      },
      {
        label: "Category",
        value: "category",
        icon: ShapesIcon,
        items: [
          {
            radioGroup: true,
            value: task?.time_category_id,
            onSelectionChanged: (value) => {
              handleCategoryChange(
                TaskCategories.find((s) => s.category_id === value.value)
              );
            },
            items:
              TaskCategories?.map((cat) => ({
                label: cat.category_name,
                value: cat.category_id,
                icon: ({ color, style }) => (
                  <div
                    style={{
                      borderRadius: "50%",
                      height: 10,
                      width: 10,
                      backgroundColor: color,
                      ...style,
                    }}
                  ></div>
                ),
                iconColor: getRandomColor(cat.category_id, cat.category_id),
              })) || [],
          },
        ],
      },
      {
        label: "Add Time",
        value: "add_time",
        icon: ClockIcon,
        items: TaskDurationOptions.map((option) => ({
          label: option.label,
          value: option.duration,
          icon: option.icon,
          onClick: () => {
            handleDurationChange(option);
          },
        })),
      },
      {
        label: "Archive Task",
        icon: ArchiveIcon,
        value: "archive",
        menuItemProps: { style: { color: theme.palette.primary.main } },
        onClick: () => handleArchiveTask(task),
      },
      {
        separator: true,
      },
      {
        label: "Delete Task",
        icon: Trash2Icon,
        value: "delete",
        menuItemProps: { style: { color: "orangered" } },
        onClick: () => setShowDeleteModal(true),
      },
    ];

    return (
      <>
        <ContextMenu
          menuItems={contextMenuItems}
          contentProps={{
            style: { width: 150 },
          }}
          onOpenChange={(isOpen) => {
            setContextMenuOpen(isOpen);
          }}
        >
          <Link to={`/cases/${task.case_id}/tasks/v/${task.uuid}`}>
            <div
              className={className + (contextMenuOpen ? " selected" : "")}
              style={style}
            >
              {displayPreferences?.showStatus && (
                <TaskStatusSelector
                  value={TaskStatuses.find(
                    (s) => s.status_id === task.status_id
                  )}
                  showLabel={false}
                  variant="outlined"
                  onSelect={handleStatusChange}
                />
              )}
              <div>{task.task_name}</div>
              <TaskProperties className="grid" style={{ marginLeft: "auto" }}>
                {displayPreferences?.showLinkedObject && (
                  <WidgetWrapper
                    style={{
                      minWidth: 150,
                      maxWidth: 150,
                      overflow: "hidden",
                      justifyContent: "flex-start",
                    }}
                  >
                    <LinkedObject
                      object={{
                        type: task.object_type,
                        name: task.object_name,
                        id: task.object_id,
                        object: task,
                      }}
                      variant="highlighted"
                      href={`/cases/${task.case_id}/tasks`}
                    />
                  </WidgetWrapper>
                )}
                {displayPreferences?.showSubTaskCount && (
                  <WidgetWrapper style={{ minWidth: 50 }}>
                    {task?.subtasks?.length > 0 ? (
                      <SubTasksIndicator tasks={task.subtasks} />
                    ) : null}
                  </WidgetWrapper>
                )}
                {displayPreferences?.showPriority && (
                  <WidgetWrapper style={{ minWidth: 50 }}>
                    <TaskPrioritySelector
                      value={TaskPriorities.find(
                        (s) => s.priority_id === task.priority_id
                      )}
                      showLabel={false}
                      variant="outlined"
                      onSelect={handlePriorityChange}
                    />
                  </WidgetWrapper>
                )}
                {displayPreferences?.showDueDate && (
                  <WidgetWrapper style={{ minWidth: 110 }}>
                    <TaskDuedateSelector
                      variant="outlined"
                      value={
                        task?.due_date
                          ? moment(task.due_date).format("YYYY-MM-DD")
                          : null
                      }
                      onSelect={handleDueDateChange}
                      buttonProps={{
                        textColor: "secondary",
                      }}
                    />
                  </WidgetWrapper>
                )}
                {displayPreferences?.showDuration && (
                  <WidgetWrapper style={{ minWidth: 100 }}>
                    <TaskDurationSelector
                      value={task?.duration}
                      showLabel={true}
                      variant="outlined"
                      onSelect={handleDurationChange}
                      buttonProps={{
                        textColor: "secondary",
                      }}
                    />
                  </WidgetWrapper>
                )}
                {displayPreferences?.showAssignees && (
                  <WidgetWrapper style={{ minWidth: 90 }}>
                    <AssigneeSelector
                      value={task?.assignees?.map((a) => a.user_id) || null}
                      task={task}
                      defaultInfo={task?.assignees || []}
                      showLabel={true}
                      variant="outlined"
                      onSelect={handleAssigneeChange}
                      userQuery={{
                        case_id: task?.case_id,
                        include_inactive: false,
                        include_observers: false,
                      }}
                    />
                  </WidgetWrapper>
                )}
              </TaskProperties>
            </div>
          </Link>
        </ContextMenu>
        {showCustomTimeModal && (
          <AddCustomTimeModal
            open={showCustomTimeModal}
            onClose={() => setShowCustomTimeModal(false)}
            onSubmit={(formData) => {
              onItemUpdate?.({
                duration: formData.duration + task.duration,
                uuid: task.uuid,
              });
            }}
            formOptions={{
              case: { disabled: true },
              task: { disabled: true },
            }}
            defaultFormData={{
              task: task,
              task_id: task.task_id,
              task_name: task.task_name,
              case_id: task.case_id,
              case_number: task.case_number,
              user_id: currentUser.user_id,
              duration: 0,
              billed: 0,
              category_id: task.time_category_id || null,
              category: TaskCategories.find(
                (c) => c.category_id === task.time_category_id
              ),
              start_time: moment().toISOString(),
              end_time: moment().add(1, "h").toISOString(),
            }}
          />
        )}
        {showDeleteModal && (
          <DeleteTaskModal
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            task={task}
            onDelete={handleDeleteTask}
          />
        )}
      </>
    );
  }
)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.action.hover};
  font-size: 0.8rem;
  font-weight: 500;

  &.selected {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }
`;

const MemoizedTaskRow = memo(TaskRow, (prevProps, nextProps) => {
  const { task: currentTask } = nextProps;
  const { task: prevTask } = prevProps;

  return (
    prevTask.sort_value === currentTask.sort_value &&
    prevTask.status_id === currentTask.status_id &&
    prevTask.priority_id === currentTask.priority_id &&
    prevTask.time_category_id === currentTask.time_category_id &&
    prevTask.task_name === currentTask.task_name &&
    prevTask.description === currentTask.description &&
    prevTask.object_id === currentTask.object_id &&
    prevTask.object_type === currentTask.object_type &&
    prevTask.due_date === currentTask.due_date &&
    prevTask.duration === currentTask.duration &&
    prevTask.assignees === currentTask.assignees &&
    prevProps?.displayPreferences?.showStatus ===
      nextProps?.displayPreferences?.showStatus &&
    prevProps?.displayPreferences?.showPriority ===
      nextProps?.displayPreferences?.showPriority &&
    prevProps?.displayPreferences?.showDueDate ===
      nextProps?.displayPreferences?.showDueDate &&
    prevProps?.displayPreferences?.showLinkedObject ===
      nextProps?.displayPreferences?.showLinkedObject &&
    prevProps?.displayPreferences?.showAssignees ===
      nextProps?.displayPreferences?.showAssignees &&
    prevProps?.displayPreferences?.showSubTaskCount ===
      nextProps?.displayPreferences?.showSubTaskCount &&
    prevProps?.displayPreferences?.showDuration ===
      nextProps?.displayPreferences?.showDuration
  );
});

const Row = ({ data, index, style }) => {
  const {
    tasks,
    handleItemClick,
    displayPreferences,
    column,
    onItemArchived,
    onItemUpdate,
    onItemRemove,
    onAddItem,
    handleArchiveTasks,
  } = data;

  const task = tasks[index];

  // We are rendering an extra item for the placeholder
  if (!task) {
    return null;
  }

  if (task.type === "group") {
    return (
      <GroupRow
        style={style}
        group={task}
        onAddItem={onAddItem}
        onArchiveTasks={handleArchiveTasks}
      />
    );
  }

  return (
    <MemoizedTaskRow
      style={style}
      key={task.uuid}
      onClick={(e) =>
        handleItemClick?.({
          item: task,
          group: column,
          event: e,
        })
      }
      task={task}
      displayPreferences={displayPreferences}
      onItemArchived={onItemArchived}
      onItemUpdate={onItemUpdate}
      onItemRemove={onItemRemove}
    />
  );
};

const TaskList = styled(
  ({
    className,
    data,
    groupedData,
    displayPreferences,
    onItemUpdate,
    onItemRemove,
    onItemArchived,
    onAddItem,
    handleArchiveTasks,
  }) => {
    const theme = useTheme();

    const rows = [];
    groupedData.map((group) => {
      rows.push(group);
      rows.push(...group.tasks);
    }, []);

    return (
      <div className={className}>
        <StickyScrollContainer
          options={{
            scrollbars: {
              theme: theme.name === "DARK" ? "os-theme-dark" : "os-theme-light",
              visibility: "auto",
              autoHide: "scroll",
              autoHideDelay: 500,
            },
          }}
        >
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                className={"column-inner-container"}
                overscanCount={3}
                height={height} // subtract the height of the column title
                itemCount={rows?.length || 0}
                itemSize={40}
                width={width}
                itemData={{
                  tasks: rows,
                  onItemArchived,
                  onItemUpdate,
                  onItemRemove,
                  onAddItem,
                  handleArchiveTasks,
                  displayPreferences,
                }}
              >
                {Row}
              </FixedSizeList>
            )}
          </AutoSizer>
        </StickyScrollContainer>
      </div>
    );
  }
)`
  position: relative;
  margin-top: 10px;
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export default TaskList;
