import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import SearchApi from "../../api/search";
import Loader from "../Loader";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SubdirectoryArrowRightOutlinedIcon from "@mui/icons-material/SubdirectoryArrowRightOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import { Link } from "react-router-dom";
import { useTheme } from "@mui/material";
import { createContext } from "react";
import { useContext } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import TaskButton from "../../Monolith-UI/TaskButton.js";

const SearchContext = createContext();

export const useAppSearch = () => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error("useSearch must be used within a SearchProvider");
  }
  return context;
};

const AppSearchBox = styled(({ className, children }) => {
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [show, setShow] = useState(false);
  const mainRef = useRef(null);
  const parentRef = useRef(null);

  const { isLoading, isFetching, error, data } = useQuery({
    queryKey: ["globalSearch", searchText],
    queryFn: () => SearchApi.globalSearch({ searchText }),
    enabled: searchEnabled && searchText.length > 2,
    refetchOnWindowFocus: false,
  });

  const onSearchResultSelected = (result) => {
    setShow(false);
    setSearchText("");
    setSearchResults([]);
    setIsSearching(false);
  };

  const setSearch = (value, results) => {
    setShow(true);
    setSearchText(value);
  };

  useEffect(() => {
    if (data) {
      setSearchResults(data);
      setIsSearching(false);
    }
  }, [isLoading, data, isFetching]);

  // Show Search Box Logic
  useEffect(() => {
    // show with ctrl+F
    const handleShowSearch = (event) => {
      if (event.ctrlKey && event.key === "f") {
        event.preventDefault();
        event.stopPropagation();
        setShow(true);
      }
    };
    document.addEventListener("keydown", handleShowSearch);
    return () => {
      document.removeEventListener("keydown", handleShowSearch);
    };
  }, []);

  // Search on keyinput logic with rate limiting
  useEffect(() => {
    let timeout = null;
    setSearchResults([]);

    if (show) {
      if (timeout) {
        clearTimeout(timeout);
        setSearchEnabled(false);
        setIsSearching(false);
      }
      timeout = setTimeout(() => {
        if (searchText.length > 2) {
          setSearchEnabled(true);
          setIsSearching(true);
        }
      }, 300);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
        setSearchEnabled(false);
        setIsSearching(false);
      }
    };
  }, [searchText, show]);

  return (
    <SearchContext.Provider
      value={{
        showSearch: () => {
          setShow(true);
        },
        isSearching,
        searchResults,
        searchText,
        setSearchText,
        setSearch,
      }}
    >
      {show && (
        <div ref={mainRef} className={className + " global-search-box"}>
          <SearchInputContainer>
            <SearchOutlinedIcon style={{ fontSize: 14 }} />
            <SearchInput
              autoFocus={true}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                // Added to disable the barcode scanner
                e.stopPropagation();
              }}
              placeholder="Search Monolith for cases, evidence, notes, etc..."
            ></SearchInput>
            <TaskButton>
              <CloseOutlinedIcon
                onClick={() => setShow(false)}
                style={{ fontSize: 20, cursor: "pointer" }}
              />
            </TaskButton>
          </SearchInputContainer>
          <SearchResults ref={parentRef} className="search-results">
            {isSearching && (
              <SearchLoader>
                <Loader />
              </SearchLoader>
            )}
            {!isSearching &&
              (searchResults.length > 0 ? (
                <VirtualizedSearchResults
                  searchResults={searchResults}
                  parentRef={parentRef}
                  onItemSelected={onSearchResultSelected}
                />
              ) : (
                <NoSearchResults>No Results</NoSearchResults>
              ))}
          </SearchResults>
        </div>
      )}
      {show && <SearchBackDrop show={show} />}
      {children}
    </SearchContext.Provider>
  );
})`
  position: absolute;
  top: 0;
  left: calc(50% - 200px);
  margin-top: 25px;
  margin-bottom: 0;
  width: 500px;
  z-index: 3000;
  background-color: ${(props) => props.theme.palette.background.paper};
  border: 1px solid ${(props) => props.theme.palette.divider};
  color: ${(props) => props.theme.palette.text.primary};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
`;

const VirtualizedSearchResults = ({
  searchResults,
  parentRef,
  onItemSelected = () => {},
}) => {
  const rowVirtualizer = useVirtualizer({
    count: searchResults.length,
    getScrollElement: () => parentRef.current,
    estimateSize: (i) => 95,
    overscan: 10,
  });

  return (
    <div
      style={{
        height: rowVirtualizer.getTotalSize(),
        width: "100%",
        position: "relative",
      }}
    >
      {rowVirtualizer.getVirtualItems().map((virtualRow) => {
        return (
          <SearchResult
            key={virtualRow.index}
            result={searchResults[virtualRow.index]}
            onItemSelected={onItemSelected}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              transform: `translateY(${virtualRow.start}px)`,
            }}
          />
        );
      })}
    </div>
  );
};

const SearchBackDrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2999;
`;

const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  width: 100%;
  padding: 0 10px;
  border-bottom: 1px solid transparent;
  &:focus-within {
    border-bottom: 1px solid ${(props) => props.theme.palette.primary.main};
  }
`;

const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0 10px;
  // font-size: 16px;
  color: ${(props) => props.theme.palette.text.primary};
`;

const SearchResults = styled.div`
  width: 500px;
  min-height: 100px;
  max-height: 500px;
  padding: 10px 10px;
  background-color: ${(props) => props.theme.palette.background.alt};
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-bottom: none;
  border-radius: 0px 0px 5px 5px;
  overflow: auto;
`;

const SearchLoader = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoSearchResults = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.palette.text.secondary};
`;

const SearchResult = styled(
  ({ className, result, onItemSelected = () => {}, style = {} }) => {
    const theme = useTheme();
    let link = null;
    const metadata = JSON.parse(result.metadata);

    switch (result.type) {
      case "Case":
        link = `/cases/${result.item_id}/overview`;
        break;
      case "Inquiry":
        link = `/inquiries/${result.item_id}`;
        break;
      case "Evidence":
        link = `/evidence/${result.item_id}/overview`;
        break;
      case "Storage":
        link = `/storage/${result.item_id}`;
        break;
      case "Note":
        link = `/cases/${metadata.case_id}/notes/${result.item_id}`;
        break;
      case "Acquisition":
        link = `/cases/${metadata.case_id}/acquisitions?acq_id=${result.item_id}`;
        break;
      case "Task":
        link = `/cases/${metadata.case_id}/tasks?task_id=${result.item_id}`;
        break;
      case "Client":
        link = `/clients/${result.item_id}`;
        break;
      case "Contact":
        link = `/contacts/${result.item_id}`;
        break;
      case "Case Document":
        link = `/cases/${metadata.case_id}/documents`;
        break;
      case "Evidence Photo":
        link = `/evidence/${metadata.uuid}/overview`;
        break;
      default:
        link = "/";
    }

    const handleItemClick = () => {
      onItemSelected(result);
    };

    return (
      <div className={className} style={{ ...style }}>
        <div className="result-container">
          <div>
            <div className="result-type">{result.type}</div>
            <div className="result-title" title={result.title}>
              {result.title}
            </div>
            <div className="result-subtitle">{result.subtitle}</div>
          </div>
          <Link to={link} onClick={handleItemClick}>
            <TaskButton
              style={{ outline: `1px solid ${theme.palette.divider}` }}
              title="Go to item"
            >
              <SubdirectoryArrowRightOutlinedIcon style={{ fontSize: 20 }} />
            </TaskButton>
          </Link>
        </div>
      </div>
    );
  }
)`
  padding: 5px;
  .result-container {
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    height: 85px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${(props) => props.theme.palette.divider};
    &:hover {
      background-color: ${(props) => props.theme.palette.action.hover};
    }
  }
  .result-type {
    font-size: 12px;
    color: ${(props) => props.theme.palette.text.secondary};
  }
  .result-title {
    font-size: 14px;
    max-width: 390px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${(props) => props.theme.palette.text.primary};
  }
  .result-subtitle {
    font-size: 12px;
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;

export default AppSearchBox;
