import styled from "styled-components";
import CasesApi from "../../api/cases/index.js";
import DefaultCaseForm from "./DefaultCaseForm.js";

const FormTitle = styled.h2`
  margin: 0;
`;

const EditCaseForm = styled(({ className, onSubmit, defaultFormData }) => {
  const handleSubmit = async (data, diff, rawFormData) => {
    await CasesApi.updateCase(defaultFormData.case_id, diff);
    onSubmit?.(data, diff, rawFormData);
  };
  return (
    <div className={className}>
      <FormTitle>Edit Forensic Case</FormTitle>
      <DefaultCaseForm
        onSubmit={handleSubmit}
        defaultFormData={defaultFormData}
      />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  gap: 15px;

  width: ${({ width }) =>
    Number.isInteger(width) ? `${width}px` : width || "100%"};
`;

export default EditCaseForm;
