import { httpDelete, httpGet, httpPatch, httpPost } from "../../connection.js";

async function get(values = {}) {
  return httpGet("/analysis/trace-attributes", values);
}

async function create(values = {}) {
  return httpPost("/analysis/trace-attributes", values);
}

async function update(values = {}) {
  const { uuid, ...rest } = values;
  return httpPatch(`/analysis/trace-attributes/${uuid}`, rest);
}

async function remove(values = {}) {
  const { uuid } = values;
  return httpDelete(`/analysis/trace-attributes/${uuid}`);
}

async function exportList(values = {}) {
  return httpPost("/analysis/trace-attributes/export", values);
}

const TraceAttributesAPI = {
  get,
  create,
  update,
  remove,
  exportList,
};

export default TraceAttributesAPI;
