import { FC } from "react";
import DefaultSoftwareForm, {
  EditSoftwareFormData,
  SoftwareFormData,
} from "./DefaultSoftwareForm";
import ForensicSoftwareAPI from "@/api/software";
import { FormTitle, FormWrapper } from "./components/MonolithFormComponents";
import { usePermissions } from "@/hooks/usePermissions";
import { useSnackbar, VariantType } from "notistack";
import LoggingAPI from "@/api/logging";
import { ForensicSoftware } from "@/types";

interface EditSoftwareFormProps {
  width?: number | undefined;
  defaultFormData: ForensicSoftware;
  onSubmit: () => void;
  onCancel: () => void;
}

const EditSoftwareForm: FC<EditSoftwareFormProps> = ({
  width,
  defaultFormData,
  onSubmit,
  onCancel,
}) => {
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (
    data: SoftwareFormData,
    diffData?: EditSoftwareFormData
  ) => {
    try {
      if (defaultFormData.license_id) {
        ForensicSoftwareAPI.updateSoftwareItem(
          defaultFormData.license_id,
          diffData
        );
        onSubmit?.();
        enqueueSnackbar(`Software item ${data?.name || ""} Edited`, {
          variant: "success" as VariantType,
        });

        LoggingAPI.createLog({
          user_id: currentUser.user_id,
          message: `Edited Software Item: ${data?.name}`,
          event: "software:edit",
          object_type: "forensic_software",
        });
      } else {
        enqueueSnackbar(
          `An error occurred while editing software ${
            data?.name || ""
          }. Please try again.`,
          { variant: "error" as VariantType }
        );
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        `An error occurred while editing software ${
          data?.name || ""
        }. Please try again.`,
        { variant: "error" as VariantType }
      );
    }
  };

  return (
    <FormWrapper width={width}>
      <FormTitle>Edit Software</FormTitle>
      <DefaultSoftwareForm
        onSubmit={handleSubmit}
        onCancel={onCancel}
        defaultFormData={defaultFormData}
        buttonProps={{ submitText: "Save Changes" }}
      />
    </FormWrapper>
  );
};
export default EditSoftwareForm;
