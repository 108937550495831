import { FC } from "react";
import { Modal } from "@monolith-forensics/monolith-ui";
import CreateClientForm from "../Forms/CreateClientForm";

interface CreateClientModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onCancel: () => void;
}

const CreateClientModal: FC<CreateClientModalProps> = ({
  show,
  onClose,
  onSubmit,
  onCancel,
}) => {
  const handleSubmit = async () => {
    onSubmit?.();
    handleClose();
  };
  const handleClose = () => {
    onClose();
  };
  const handleCancel = () => {
    onCancel?.();
  };

  return (
    <Modal open={show} onClose={onClose}>
      <CreateClientForm
        width={550}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </Modal>
  );
};

export default CreateClientModal;
