import { Button, useTheme } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { MutableRefObject, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import shortUUID from "short-uuid";
import CaseFileAPI from "../../../api/CaseFiles/index.js";
import BreadCrumbs from "../../../components/BreadCrumbs/index.js";
import FileBrowser from "../../../components/FileBrowser/FileBrowser.js";
import { useFileUploader } from "../../../contexts/FileUploader.js";
import UploadButton from "./UploadButton.js";
import CreateFolderModal from "./CreateFolderModal.js";
import { useRef } from "react";
import moment from "moment";
import styled from "styled-components";
import { usePermissions } from "../../../hooks/usePermissions";
import { useSnackbar } from "notistack";
import DeleteModal from "./DeleteItemModal.js";
import RenameFolderModal from "./RenameFolderModal.js";
import RenameFileModal from "./RenameFileModal.js";
import FileViewer from "../../../Monolith-UI/FileViewer/FileViewer.js";
import LoggingAPI from "../../../api/logging/index.js";

import {
  DownloadIcon,
  EyeIcon,
  InfoIcon,
  PencilIcon,
  SendIcon,
  TrashIcon,
} from "lucide-react";
import CasesApi from "../../../api/cases/index.js";
import ItemInfoFlyout from "./ItemInfoFlyout/ItemInfoFlyout.js";
import { ShareItemModal } from "../../../components/Modals";

interface CaseFile extends Partial<File> {
  case_id?: number | null;
  case_number?: string;
  case_ref?: string;
  case_storage_path?: string;
  case_uuid?: string;
  client_modified?: string;
  created_by?: {
    name: string;
    email?: string;
    title?: string;
    user_id: number;
    last_name?: string;
  };
  created_by_id?: number;
  created_on?: string;
  description?: string;
  file_ext?: string;
  file_id?: number;
  file_name?: string;
  file_path?: string;
  file_size?: number;
  is_folder?: number;
  newName?: string;
  newPath?: string;
  md5?: string;
  parent_folder_id?: string;
  sha1?: string;
  sha256?: string;
  status?: string;
  uuid?: string | null;
  monolith_path?: string;
}

interface CaseFolder {
  name?: string;
  case_id: number;
  created_by: { name: string; user_id: number };
  created_by_id: number;
  created_on: string;
  file_name: string;
  file_path: string;
  is_folder: number;
  parent_folder_id: string;
  newName?: string;
  type: string;
  uuid: string;
}

interface FileUploadedEvent extends Event {
  detail: {
    case_id: string;
    parent_folder_id?: string; // Make this optional
    uuid: string;
  };
}

declare global {
  interface DocumentEventMap {
    "file-uploader:file-uploaded": FileUploadedEvent;
  }
}

interface FileBrowserContainerProps {
  fileBrowserRef: MutableRefObject<(() => void) | null>;
  setShowInfoPanel: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedItem: React.Dispatch<React.SetStateAction<any>>;
  setSelectedItems: React.Dispatch<React.SetStateAction<any>>;
  onBrowserDrop: (droppedFiles: CaseFile[]) => void;
  onFolderDrop: (folder: CaseFolder, droppedFiles: any) => void;
  onSelectionChanged: (selectedItems: CaseFile[]) => void;
  handleViewItem: (file: CaseFile | CaseFolder) => void;
  handleDownload: (file: CaseFile | CaseFolder) => void;
  handleDeleteItem: (file: CaseFile | CaseFolder) => void;
  handleRenameFile: (file: CaseFile | CaseFolder) => void;
  toggleShareModal: (type: "relay" | "email") => void;
}

const isElectron = process.env.REACT_APP_PLATFORM === "electron";

const FileBrowserContainer = ({
  fileBrowserRef,
  setShowInfoPanel,
  setSelectedItem,
  setSelectedItems,
  onBrowserDrop = (droppedFiles: CaseFile[]) => {},
  onFolderDrop = (folder: CaseFolder, droppedFiles: any) => {},
  onSelectionChanged = (selectedItems: CaseFile[]) => {},
  handleViewItem = (file: CaseFile | CaseFolder) => {},
  handleDownload = (file: CaseFile | CaseFolder) => {},
  handleDeleteItem = (file: CaseFile | CaseFolder) => {},
  handleRenameFile = () => {},
  toggleShareModal = () => {},
}: FileBrowserContainerProps) => {
  const theme = useTheme();
  const { case_id, folder_id } = useParams();
  const { hasPermission, currentUser } = usePermissions();
  const { data, isLoading } = useQuery({
    queryKey: [
      "case-files",
      {
        case_id: case_id ? parseInt(case_id) : null,
        parent_folder_id: folder_id || "root",
      },
    ],
    queryFn: () =>
      CaseFileAPI.get({
        case_id: case_id ? parseInt(case_id) : null,
        parent_folder_id: folder_id || "root",
      }),
    placeholderData: (data) => data,
  });
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const onFileUploaded = (event: FileUploadedEvent) => {
    const { detail: file } = event; // Destructure detail correctly

    queryClient.refetchQueries({
      queryKey: [
        "case-files",
        {
          case_id: parseInt(file.case_id),
          parent_folder_id: file.parent_folder_id || "root",
        },
      ],
    });

    LoggingAPI.createLog({
      case_id: file.case_id ? parseInt(file.case_id) : null,
      user_id: currentUser.user_id,
      message: `Uploaded File`,
      event: "case_file:upload",
      object_type: "file",
      object_uuid: file.uuid,
    });
  };

  const onFileClick = (file: CaseFile) => {
    setSelectedItem(file);
  };

  const onFileDblClick = (file: CaseFile) => {
    handleViewItem(file);
  };

  const onFolderClick = (folder: CaseFolder) => {
    setSelectedItem(folder);
  };

  const onFolderDblClick = (folder: CaseFolder) => {
    setSelectedItem(null);
    setSelectedItems([]);
    navigate(`/cases/${case_id}/documents/folders/${folder.uuid}`);

    LoggingAPI.createLog({
      case_id: case_id ? parseInt(case_id) : null,
      user_id: currentUser.user_id,
      message: "Accessed folder",
      event: "case_folder:access",
      object_type: "folder",
      object_uuid: folder.uuid,
    });
  };

  const handleItemMove = (item: CaseFile, folder: CaseFolder) => {
    if (item && item.uuid !== folder.uuid) {
      CaseFileAPI.update({
        case_id: case_id ? parseInt(case_id) : null,
        uuid: item.uuid,
        parent_folder_id: folder.uuid,
      }).then((res) => {
        enqueueSnackbar("File moved successfully", { variant: "success" });
        LoggingAPI.createLog({
          case_id: case_id ? parseInt(case_id) : null,
          user_id: currentUser.user_id,
          message: `Moved ${
            item.is_folder === 1 ? "Folder" : "File"
          } - "${item.file_path?.slice(1)}" to "${folder.file_path.slice(1)}"`,
          event: item.is_folder === 1 ? "case_folder:move" : "case_file:move",
          object_type: item.is_folder === 1 ? "folder" : "file",
          object_uuid: item.uuid,
        });
      });

      queryClient.setQueryData(
        [
          "case-files",
          {
            case_id: case_id ? parseInt(case_id) : null,
            parent_folder_id: folder_id || "root",
          },
        ],
        (oldData: CaseFile[]) => {
          return oldData.filter((oldItem) => oldItem.uuid !== item.uuid);
        }
      );
    }
  };

  useEffect(() => {
    document.addEventListener("file-uploader:file-uploaded", onFileUploaded);

    return () => {
      document.removeEventListener(
        "file-uploader:file-uploaded",
        onFileUploaded
      );
    };
  }, []);

  if (isLoading) return null;

  return (
    <>
      <FileBrowser
        fileBrowserRef={fileBrowserRef}
        disabled={!hasPermission()}
        items={data}
        onFolderDblClick={onFolderDblClick}
        onFileDblClick={onFileDblClick}
        fileItemWidth={250}
        folderItemWidth={250}
        fileSectionName="Files"
        emptyFolderMessage="Create a Folder or Upload Files"
        displayName="file_name"
        onBrowserDrop={onBrowserDrop}
        onFolderDrop={onFolderDrop}
        onFileClick={onFileClick}
        onSelectionChanged={onSelectionChanged}
        onFolderClick={onFolderClick}
        onClearSelection={() => setSelectedItem(null)}
        onItemMoved={handleItemMove}
        fileContextMenuProps={
          hasPermission()
            ? {
                menuItems: [
                  {
                    label: "View",
                    icon: EyeIcon,
                    iconColor: theme.palette.primary.main,
                    onClick: (
                      _: React.MouseEvent<HTMLButtonElement>,
                      itemData: CaseFile
                    ) => handleViewItem(itemData),
                  },
                  {
                    label: "View Info",
                    icon: InfoIcon,
                    iconColor: theme.palette.primary.main,
                    onClick: (
                      _: React.MouseEvent<HTMLButtonElement>,
                      itemData: CaseFile
                    ) => setShowInfoPanel(true),
                  },
                  {
                    label: "Download",
                    icon: DownloadIcon,
                    iconColor: theme.palette.primary.main,
                    onClick: (
                      _: React.MouseEvent<HTMLButtonElement>,
                      itemData: CaseFile
                    ) => handleDownload(itemData),
                  },
                  {
                    label: "Rename",
                    icon: PencilIcon,
                    iconColor: theme.palette.primary.main,
                    onClick: (
                      _: React.MouseEvent<HTMLButtonElement>,
                      itemData: CaseFile
                    ) => handleRenameFile(itemData),
                  },
                  {
                    label: "Share via Relay",
                    icon: SendIcon,
                    iconColor: theme.palette.primary.main,
                    onClick: () => toggleShareModal("relay"),
                  },
                  {
                    separator: true,
                  },
                  {
                    label: "Delete",
                    icon: TrashIcon,
                    iconColor: theme.palette.error.main,
                    onClick: (
                      _: React.MouseEvent<HTMLButtonElement>,
                      itemData: CaseFile
                    ) => handleDeleteItem(itemData),
                  },
                ],
              }
            : null
        }
        folderContextMenuProps={
          hasPermission()
            ? {
                menuItems: [
                  {
                    label: "Open",
                    icon: EyeIcon,
                    iconColor: theme.palette.primary.main,
                    onClick: (
                      _: React.MouseEvent<HTMLButtonElement>,
                      itemData: CaseFolder
                    ) => handleViewItem(itemData),
                  },
                  {
                    label: "View Info",
                    icon: InfoIcon,
                    iconColor: theme.palette.primary.main,
                    onClick: (
                      _: React.MouseEvent<HTMLButtonElement>,
                      itemData: CaseFolder
                    ) => setShowInfoPanel(true),
                  },
                  {
                    label: "Download",
                    icon: DownloadIcon,
                    iconColor: theme.palette.primary.main,
                    onClick: (
                      _: React.MouseEvent<HTMLButtonElement>,
                      itemData: CaseFolder
                    ) => handleDownload(itemData),
                  },
                  {
                    label: "Rename",
                    icon: PencilIcon,
                    iconColor: theme.palette.primary.main,
                    onClick: (
                      _: React.MouseEvent<HTMLButtonElement>,
                      itemData: CaseFolder
                    ) => handleRenameFile(itemData),
                  },
                  {
                    label: "Share via Relay",
                    icon: SendIcon,
                    iconColor: theme.palette.primary.main,
                    onClick: () => toggleShareModal("relay"),
                  },
                  {
                    separator: true,
                  },
                  {
                    label: "Delete",
                    icon: TrashIcon,
                    iconColor: theme.palette.error.main,
                    onClick: (
                      _: React.MouseEvent<HTMLButtonElement>,
                      itemData: CaseFolder
                    ) => handleDeleteItem(itemData),
                  },
                ],
              }
            : null
        }
      />
    </>
  );
};

const NavigationMenu = ({
  onPathResovled = (
    pathData: { uuid: string; file_name: string; path_order: number }[]
  ) => {},
}) => {
  const { case_id, folder_id } = useParams();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery({
    queryKey: ["case-files:item-path", { folder_id: folder_id || "root" }],
    queryFn: () => CaseFileAPI.getItemPath({ uuid: folder_id || "root" }),
    placeholderData: (data) => data,
  });

  const handleNavigate = (item: CaseFile) => {
    // Navigate to the selected item
    navigate(`/cases/${case_id}/documents/folders/${item.uuid}`);
  };

  useEffect(() => {
    if (data) onPathResovled(data);
  }, [data, onPathResovled]);

  return (
    <div style={{ marginBottom: 5 }}>
      <BreadCrumbs
        list={data || []}
        onItemSelect={handleNavigate}
        displayName="file_name"
        variant="text"
      />
    </div>
  );
};

const ActionMenu = styled(
  ({
    className,
    caseInfo,
    fileBrowserRef,
    setShowInfoPanel,
    showInfoPanel,
    selectedItem,
    selectedItems,
    currentPath,
    handleDeleteItem = () => {},
    handleRenameFolder = () => {},
    handleRenameFile = () => {},
    handleDownload = () => {},
    onUploadSelect = () => {},
    handleViewItem = () => {},
    handleShareFile = () => {},
  }) => {
    const { case_id, folder_id } = useParams();
    const { hasPermission, currentUser } = usePermissions();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const queryClient = useQueryClient();

    const isMultiSelect = selectedItems?.length > 1;

    const onFolderCreated = (data: CaseFolder) => {
      queryClient.setQueryData<CaseFile[]>(
        [
          "case-files",
          {
            case_id: case_id ? parseInt(case_id) : null,
            parent_folder_id: folder_id || "root",
          },
        ],
        (oldData) => {
          const currentData = oldData || [];
          return [
            ...currentData,
            {
              ...data,
              created_by: {
                name: currentUser.full_name,
                user_id: currentUser.user_id,
              },
              file_path: "/" + currentPath + "/" + data.file_name,
            },
          ];
        }
      );
    };

    const handleView = () => {
      handleViewItem(selectedItem);
    };

    return (
      <div
        className={className}
        style={{
          marginBottom: 10,
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div className="action-menu">
          {hasPermission() && (
            <>
              <Button
                variant="contained"
                size="small"
                style={{
                  minWidth: "fit-content",
                  fontSize: 11,
                  padding: "3px 6px",
                  marginRight: 10,
                }}
                onClick={() => setOpen(true)}
                disabled={!hasPermission()}
              >
                + Create Folder
              </Button>
              <CreateFolderModal
                open={open}
                handleClose={() => setOpen(false)}
                onSubmit={onFolderCreated}
                defaultFormData={{
                  case_uuid: caseInfo.uuid,
                  case_id: caseInfo.case_id,
                }}
              />
            </>
          )}
          {hasPermission() && <UploadButton onSelect={onUploadSelect} />}

          {(selectedItem || selectedItems.length > 0) && (
            <>
              <>
                {!isMultiSelect && (
                  <div
                    className="action-menu-item"
                    onClick={() => handleDownload(selectedItem)}
                  >
                    <DownloadIcon
                      size={14}
                      color={theme.palette.primary.main}
                    />
                    <div className="action-menu-label">Download</div>
                  </div>
                )}
                {hasPermission() && !isMultiSelect && (
                  <div
                    className="action-menu-item"
                    onClick={
                      selectedItem?.type === "Folder"
                        ? handleRenameFolder
                        : handleRenameFile
                    }
                  >
                    <PencilIcon size={14} color={theme.palette.primary.main} />
                    <div className="action-menu-label">Rename</div>
                  </div>
                )}
                {hasPermission() && (
                  <div className="action-menu-item" onClick={handleShareFile}>
                    <SendIcon
                      size={14}
                      style={{ color: theme.palette.primary.main }}
                    />
                    {!isMultiSelect && (
                      <div className="action-menu-label">Share</div>
                    )}
                    {isMultiSelect && (
                      <div className="action-menu-label">
                        Share {selectedItems.length} Items
                      </div>
                    )}
                  </div>
                )}
              </>
              {!isMultiSelect && (
                <div
                  className="action-menu-item"
                  onClick={() => {
                    handleView();
                  }}
                >
                  <EyeIcon size={14} color={theme.palette.primary.main} />
                  <div className="action-menu-label">View</div>
                </div>
              )}
              {!isMultiSelect && (
                <div
                  className="action-menu-item"
                  onClick={() => {
                    setShowInfoPanel(!showInfoPanel);
                  }}
                >
                  <InfoIcon size={14} color={theme.palette.primary.main} />
                  <div className="action-menu-label">
                    {showInfoPanel ? "Hide Info" : "Show Info"}
                  </div>
                </div>
              )}
              {hasPermission() && (
                <div className="action-menu-item" onClick={handleDeleteItem}>
                  <TrashIcon size={14} color={theme.palette.error.main} />
                  {!isMultiSelect && (
                    <div className="action-menu-label">Delete</div>
                  )}
                  {isMultiSelect && (
                    <div className="action-menu-label">
                      Delete {selectedItems.length} Items
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
)`
  & .action-menu {
    display: flex;
    align-items: center;
    align-content: center;
  }
  & .action-menu-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin-right: 12px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    color: ${(props) => props.theme.palette.text.primary};
    &:hover {
      text-decoration: underline;
    }
    & svg {
      font-size: 16px;
      margin-right: 5px;
    }
  }
`;

const CaseDocumentsWrapper = styled(({ className, caseInfo }) => {
  const { case_id, folder_id } = useParams();
  const { licenseInfo, currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const fileBrowserRef = useRef<null | (() => void)>(null);
  const queryClient = useQueryClient();
  const [showInfoPanel, setShowInfoPanel] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareType, setShareType] = useState<"relay" | "email">("relay");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRenameFolderModal, setShowRenameFolderModal] = useState(false);
  const [showRenameFileModal, setShowRenameFileModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<CaseFile | null>(null);
  const [selectedItems, setSelectedItems] = useState<CaseFile[] | []>([]);
  const [currentPath, setCurrentPath] = useState("");
  const [showFileViewer, setShowFileViewer] = useState(false);
  const basePath = `Cases/${caseInfo.storage_path}/Documents${
    !currentPath ? "" : `/${currentPath}`
  }`;

  const { addFiles } = useFileUploader();

  const handlePathResolved = (
    pathData: {
      uuid: string;
      file_name: string;
      path_order: number;
    }[]
  ) => {
    const path = pathData
      .filter((item) => item.uuid !== "root")
      .map((item) => item.file_name)
      .join("/");

    setCurrentPath(path);
  };

  const onDelete = () => {
    queryClient.refetchQueries({
      queryKey: [
        "case-files",
        {
          case_id: case_id ? parseInt(case_id) : null,
          parent_folder_id: folder_id || "root",
        },
      ],
    });

    selectedItems.forEach((item) => {
      LoggingAPI.createLog({
        case_id: case_id ? parseInt(case_id) : null,
        user_id: currentUser.user_id,
        message: item.is_folder === 1 ? `Deleted Folder` : `Deleted File`,
        event: item.is_folder === 1 ? "case_folder:delete" : "case_file:delete",
        object_type: item.is_folder === 1 ? "folder" : "file",
        object_uuid: item.uuid,
      });
    });
    setShowInfoPanel(false);
    setSelectedItem(null);
    setSelectedItems([]);
  };

  const onRename = (data: CaseFolder | CaseFile) => {
    queryClient.refetchQueries({
      queryKey: [
        "case-files",
        {
          case_id: case_id ? parseInt(case_id) : null,
          parent_folder_id: folder_id || "root",
        },
      ],
    });

    LoggingAPI.createLog({
      case_id: case_id ? parseInt(case_id) : null,
      user_id: currentUser.user_id,
      message:
        selectedItem?.is_folder === 1
          ? `Renamed Folder - "${selectedItem?.file_name}" to "${data.newName}"`
          : `Renamed File - "${selectedItem?.file_name}" to "${data.newName}"`,
      event:
        selectedItem?.is_folder === 1
          ? "case_folder:update"
          : "case_file:update",
      object_type: selectedItem?.is_folder === 1 ? "folder" : "file",
      object_uuid: selectedItem?.uuid,
    });
  };

  function convertFilesToTree(
    droppedFiles: CaseFile[],
    parentFolderId = "",
    case_id: number | null
  ) {
    const folders: { [key: string]: any } = {};
    const filesWithIds = [];

    for (const file of droppedFiles) {
      const useNewPath = isElectron && typeof file.newPath !== "undefined";

      const parsablePath = useNewPath
        ? file.newPath
        : file?.path || file?.webkitRelativePath || "";

      const pathSegments = parsablePath?.replace(/^\//, "")?.split("/") || [];

      if (pathSegments.length > 1) {
        // If the file has a parent folder
        let currentFolderId = parentFolderId;
        let currentPathSegment = "";

        for (const segment of pathSegments) {
          currentPathSegment += segment;

          if (segment !== pathSegments[pathSegments.length - 1]) {
            // If the current segment is not the file name
            currentPathSegment += "/";

            if (!folders[currentPathSegment]) {
              // If the folder doesn't exist yet
              const folderId = shortUUID.generate(); // generate a unique ID for the folder
              const folder = {
                uuid: folderId,
                name: segment,
                file_path: `/${currentPath}/${currentPathSegment}`.slice(0, -1),
                parent_folder_id: currentFolderId,
                case_id: case_id,
                children: [],
              };
              folders[currentPathSegment] = folder;
              currentFolderId = folderId;
            } else {
              // If the folder already exists
              currentFolderId = folders[currentPathSegment].uuid;
            }
          } else {
            // If the current segment is the file name
            file.uuid = shortUUID.generate(); // generate a unique ID for the file
            file.parent_folder_id = currentFolderId;
            file.case_id = case_id;
            file.client_modified = moment(file.lastModified).toISOString();
            filesWithIds.push(file);
          }
        }
      } else {
        // If the file is in the root folder
        file.uuid = shortUUID.generate(); // generate a unique ID for the file
        file.parent_folder_id = parentFolderId;
        file.case_id = case_id;
        file.client_modified = moment(file.lastModified).toISOString();

        filesWithIds.push(file);
      }
    }

    const total_size = filesWithIds.reduce(
      (acc, file) => acc + (file.size ?? 0),
      0
    );

    return {
      folders: Object.values(folders),
      files: filesWithIds,
      total_size,
    };
  }

  function getRelativePaths(files: CaseFile[]) {
    if (files.length === 0) {
      return [];
    }

    const commonBaseParts = files.reduce<string[]>(
      (prevBaseParts, { path: filePath }) => {
        const parts = filePath?.replace(/(\\+)/g, "/").split("/") || [];

        if (!prevBaseParts) {
          return parts;
        }

        let i = 0;
        while (i < prevBaseParts?.length && prevBaseParts[i] === parts?.[i]) {
          i++;
        }

        return prevBaseParts.slice(0, i);
      },
      []
    );

    const updatedFiles = files.map((file) => {
      //remove first item from commonBaseParts

      const parts = file.path?.replace(/(\\+)/g, "/").split("/");
      const relativeParts = parts?.slice(commonBaseParts.length - 1);
      file.newPath = relativeParts?.join("/");
      return file;
    });

    return updatedFiles;
  }

  const onBrowserDrop = async (droppedFiles: CaseFile[]) => {
    if (isElectron) droppedFiles = getRelativePaths(Array.from(droppedFiles));

    let { files, folders, total_size } = convertFilesToTree(
      droppedFiles,
      folder_id || "root",
      case_id ? parseInt(case_id) : null
    );

    const { total_bytes: storageVolume } = await CaseFileAPI.getStorageVolume();

    if (storageVolume + total_size > licenseInfo.hot_storage_limit_gb * 1e9) {
      return enqueueSnackbar("Monolith storage limit exceeded.", {
        variant: "error",
      });
    }

    const data = queryClient.getQueryData<CaseFile[]>([
      "case-files",
      {
        case_id: case_id ? parseInt(case_id) : null,
        parent_folder_id: folder_id || "root",
      },
    ]);

    folders.forEach((folder) => {
      if (folder.parent_folder_id === (folder_id || "root")) {
        // Check if root folder already exists
        const rootFolderExists = data?.find(
          (item) => item.file_name === folder.name
        );

        if (rootFolderExists) {
          folder.name = `${folder.name}_${Date.now()}`;
        }
      }
    });

    const newFiles = files.map((file) => {
      if (file.parent_folder_id === (folder_id || "root")) {
        // Check if root folder already exists
        const rootFolderExists = data?.find(
          (item) => item.file_name === file.name
        );

        if (rootFolderExists) {
          let newName = null;
          const fileSegments = file.name?.split(".");
          if (fileSegments && fileSegments.length > 1) {
            // If file has extension
            newName = `${fileSegments[0]}_${Date.now()}.${
              fileSegments[fileSegments.length - 1]
            }`;
          } else {
            newName = `${file.name}_${Date.now()}`;
          }

          const newFile: CaseFile = Object.assign(
            new File([file as BlobPart], newName, {
              type: file.type,
            }),
            {
              case_id: file.case_id,
              uuid: file.uuid,
              parent_folder_id: file.parent_folder_id,
              client_modified: file.client_modified,
              path: newName,
              original_path: file.path,
            }
          );

          return newFile;
        }
      }
      return file;
    });

    handleCreateFolders(folders).then((res) => {
      addFiles({
        uploadKey: "case-files",
        files: newFiles.map((file) => {
          file.monolith_path = `${basePath}/${
            file.newPath || file.path || file.webkitRelativePath
          }`.replace(/(\/+)/g, "/");

          // check if monolith_path ends with a slash
          if (file.monolith_path.endsWith("/")) {
            file.monolith_path = file.monolith_path + file.name;
          }

          file.case_uuid = caseInfo.uuid;
          return file;
        }),
      });
    });
  };

  const onFolderDrop = async (folder: CaseFolder, droppedFiles: CaseFile[]) => {
    if (isElectron) droppedFiles = getRelativePaths(Array.from(droppedFiles));

    const { files, folders } = convertFilesToTree(
      droppedFiles,
      folder.uuid,
      case_id ? parseInt(case_id) : null
    );

    for (const current of folders) {
      if (current.parent_folder_id === folder.uuid) {
        // Check that folder name is unique
        const checkUnique = await CaseFileAPI.get({
          case_id,
          parent_folder_id: folder.uuid,
        });

        const rootFolderExists = checkUnique
          .filter((item: CaseFile) => item.type === "Folder")
          .find(
            (item: CaseFile) =>
              item.file_name?.toLowerCase() === current.name.toLowerCase()
          );

        if (rootFolderExists) {
          current.name = `${current.name}_${Date.now()}`;
        }
      }
    }

    const filePromises = files.map(async (file) => {
      if (file.parent_folder_id === folder.uuid) {
        // Check if root file already exists
        const checkUnique = await CaseFileAPI.get({
          case_id,
          parent_folder_id: folder.uuid,
        });

        const rootFileExists = checkUnique
          .filter((item: CaseFile) => item.type === "File")
          .find(
            (item: CaseFile) =>
              item.file_name?.toLowerCase() === file.name?.toLowerCase()
          );

        if (rootFileExists) {
          let newName = null;
          const fileSegments = file.name?.split(".");
          if (fileSegments && fileSegments.length > 1) {
            // If file has extension
            newName = `${fileSegments[0]}_${Date.now()}.${
              fileSegments[fileSegments.length - 1]
            }`;
          } else {
            newName = `${file.name}_${Date.now()}`;
          }

          const newFile: CaseFile = Object.assign(
            new File([file as BlobPart], newName, {
              type: file.type,
            }),
            {
              case_id: file.case_id,
              uuid: file.uuid,
              parent_folder_id: file.parent_folder_id,
              client_modified: file.client_modified,
              path: newName,
              original_path: file.path,
            }
          );

          return newFile;
        }
      }
      return file;
    });

    const newFiles = await Promise.all(filePromises);

    handleCreateFolders(folders).then((res) => {
      addFiles({
        uploadKey: "case-files",
        files: newFiles.map((file) => {
          file.monolith_path = `${basePath}/${folder.file_path}/${
            file.newPath || file.path || file.webkitRelativePath
          }`.replace(/(\/+)/g, "/");

          file.case_uuid = caseInfo.uuid;
          return file;
        }),
      });
    });
  };

  const handleCreateFolders = async (folders: CaseFolder[]) => {
    const promises = folders.map((folder) => {
      return CaseFileAPI.createFolder({
        file_name: folder.name,
        file_path: folder.file_path,
        uuid: folder.uuid,
        parent_folder_id: folder.parent_folder_id,
        case_id: folder.case_id,
        case_uuid: caseInfo.uuid,
      }).then(() => {
        LoggingAPI.createLog({
          case_id: case_id ? parseInt(case_id) : null,
          user_id: currentUser.user_id,
          message: `Created Folder`,
          event: "case_folder:create",
          object_type: "folder",
          object_uuid: folder.uuid,
        });
      });
    });

    await Promise.all(promises);

    queryClient.refetchQueries({
      queryKey: [
        "case-files",
        {
          case_id: case_id ? parseInt(case_id) : null,
          parent_folder_id: folder_id || "root",
        },
      ],
    });
  };

  const handleViewItem = () => {
    setShowFileViewer(true);

    const fileInfo = {
      uuid: selectedItem?.uuid,
    };

    CaseFileAPI.downloadFile(selectedItem?.file_name, {
      ...fileInfo,
    }).then((res) => {
      if (!res || res.success === false) {
        enqueueSnackbar(res.message || "Error downloading file", {
          variant: "error",
        });
        return;
      }

      LoggingAPI.createLog({
        case_id: case_id ? parseInt(case_id) : null,
        user_id: currentUser.user_id,
        message: `Viewed File`,
        event: "case_file:view",
        object_type: "file",
        object_uuid: selectedItem?.uuid,
      });

      setSelectedItem((oldItem) => {
        return {
          ...oldItem,
          url: res.link,
        };
      });
    });
  };

  const handleSelectionChanged = (selectedItems: CaseFile[]) => {
    setSelectedItems(selectedItems);
  };

  const handleDownload = (item: CaseFile | CaseFolder) => {
    if (!item) return;

    if (item.type === "Folder") {
      enqueueSnackbar("Downloading folder, please wait...", {
        variant: "info",
      });

      CaseFileAPI.downloadFolder({
        uuid: item.uuid,
      }).then((res) => {
        if (!res || res.success === false) {
          enqueueSnackbar(res.message || "Error downloading folder", {
            variant: "error",
          });
          return;
        }
        const el = document.createElement("a");
        el.href = res.link.replace(
          "http://localhost:3000",
          "http://localhost:3001"
        );
        el.download = res.name;
        el.click();

        LoggingAPI.createLog({
          case_id: case_id ? parseInt(case_id) : null,
          user_id: currentUser.user_id,
          message: `Downloaded Folder`,
          event: "case_folder:download",
          object_type: "folder",
          object_uuid: item.uuid,
        });
      });
    }

    if (item.type === "File") {
      const fileInfo = {
        uuid: item.uuid,
      };

      CaseFileAPI.downloadFile(item.file_name, {
        ...fileInfo,
      }).then((res) => {
        if (!res || res.success === false) {
          enqueueSnackbar(res.message || "Error downloading file", {
            variant: "error",
          });
          return;
        }
        const el = document.createElement("a");
        el.href = res.link.replace(
          "http://localhost:3000",
          "http://localhost:3001"
        );
        el.download = res.name;
        el.click();

        LoggingAPI.createLog({
          case_id: case_id ? parseInt(case_id) : null,
          user_id: currentUser.user_id,
          message: `Downloaded File`,
          event: "case_file:download",
          object_type: "file",
          object_uuid: item.uuid,
        });
      });
    }
  };

  const toggleShareModal = (type: "relay" | "email") => {
    setShareType(type);
    setShowShareModal((prev) => !prev);
  };

  return (
    <div className={className}>
      <ActionMenu
        caseInfo={caseInfo}
        fileBrowserRef={fileBrowserRef}
        setShowInfoPanel={setShowInfoPanel}
        showInfoPanel={showInfoPanel}
        selectedItem={selectedItem}
        selectedItems={selectedItems}
        currentPath={currentPath}
        handleDeleteItem={() => setShowDeleteModal(true)}
        handleRenameFolder={() => setShowRenameFolderModal(true)}
        handleRenameFile={() => setShowRenameFileModal(true)}
        handleShareFile={() => setShowShareModal(true)}
        handleDownload={handleDownload}
        onUploadSelect={onBrowserDrop}
        handleViewItem={handleViewItem}
        handleViewInfo={() => setShowInfoPanel(true)}
      />
      <FileViewer
        file={selectedItem}
        open={showFileViewer}
        handleClose={() => setShowFileViewer(false)}
      />
      <NavigationMenu onPathResovled={handlePathResolved} />
      <FileBrowserContainer
        fileBrowserRef={fileBrowserRef}
        setShowInfoPanel={setShowInfoPanel}
        setSelectedItem={setSelectedItem}
        setSelectedItems={setSelectedItems}
        onSelectionChanged={handleSelectionChanged}
        onBrowserDrop={onBrowserDrop}
        onFolderDrop={onFolderDrop}
        handleViewItem={handleViewItem}
        handleDownload={handleDownload}
        handleDeleteItem={() => setShowDeleteModal(true)}
        handleRenameFile={() => setShowRenameFileModal(true)}
        toggleShareModal={toggleShareModal}
      />
      <ItemInfoFlyout
        open={showInfoPanel}
        onClose={() => setShowInfoPanel(false)}
        uuid={selectedItem?.uuid}
        onDelete={() => onDelete()}
        defaultData={{ ...selectedItem, case_uuid: caseInfo.uuid }}
      />
      <DeleteModal
        open={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        onSubmit={() => onDelete()}
        defaultInfo={selectedItems}
      />
      <RenameFolderModal
        open={showRenameFolderModal}
        handleClose={() => setShowRenameFolderModal(false)}
        onSubmit={onRename}
        defaultInfo={selectedItem}
      />
      <RenameFileModal
        open={showRenameFileModal}
        handleClose={() => setShowRenameFileModal(false)}
        onSubmit={onRename}
        defaultInfo={selectedItem}
      />
      <ShareItemModal
        show={showShareModal}
        onClose={() => setShowShareModal(false)}
        onSubmit={(values) => {
          setShowShareModal(false);
        }}
        onCancel={() => setShowShareModal(false)}
        type={shareType}
        defaultValues={{
          selectedItem,
          selectedItems,
          caseInfo: { uuid: caseInfo.uuid, case_id: caseInfo.case_id },
        }}
      />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;
`;

const CaseDocuments = () => {
  const { case_id } = useParams();

  const { data: caseInfo } = useQuery({
    queryKey: [
      "cases",
      "list",
      {
        case_id: case_id ? parseInt(case_id) : null,
      },
    ],
    queryFn: () =>
      CasesApi.getCases({
        case_id: case_id ? parseInt(case_id) : null,
      }),
    enabled: !!case_id,
    select: (data) => data?.[0] || {},
  });

  if (!caseInfo) return <div>Error loading case documents</div>;

  return <CaseDocumentsWrapper caseInfo={caseInfo} />;
};

export default CaseDocuments;
