import { httpDelete, httpGet, httpPatch, httpPost } from "../connection";

export async function createCaseNote(
  values = {
    case_id: "",
    user_id: 0,
    note_tag: "",
    note_data: "",
    timestamp: "",
  }
) {
  return await httpPost(`/cases/${values.case_id}/notes`, values);
}

export async function getCaseNotes(
  values = { case_id: null, case_note_id: null },
  options = { content: false, searchTerm: null }
) {
  if (typeof values.case_note_id == "undefined")
    return await httpGet(`/cases/${values.case_id}/notes`, options);
  else
    return await httpGet(
      `/cases/${values.case_id}/notes/${values.case_note_id}`,
      options
    );
}

export async function updateCaseNote(
  values = {
    case_id: null,
    case_note_id: null,
    note_data: null,
    note_tag: null,
  }
) {
  let caseID = values.case_id;

  let noteID = values.case_note_id;

  delete values.case_note_id;
  delete values.case_id;

  return await httpPatch(`/cases/${caseID}/notes/${noteID}`, values);
}

export async function deleteCaseNote(
  values = { case_id: null, case_note_id: null }
) {
  return await httpDelete(
    `/cases/${values.case_id}/notes/${values.case_note_id}`
  );
}

export async function uploadNoteImage(
  values = { case_id: null, file: { filename: null, data: null } }
) {
  return await httpPost(`/cases/${values.case_id}/notes/images`, {
    file: values.file,
  });
}

const CaseNotes = {
  createCaseNote,
  getCaseNotes,
  updateCaseNote,
  deleteCaseNote,
  uploadNoteImage,
};

export default CaseNotes;
