import { Audit } from "@/types";
import styled from "styled-components";
import AuditLogsTable from "./AuditLogsTable";

interface AuditLogsProps {
  auditData: Audit;
}

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const AuditLogs: React.FC<AuditLogsProps> = ({ auditData }) => {
  return (
    <StyledContent>
      <AuditLogsTable auditData={auditData} />
    </StyledContent>
  );
};

export default AuditLogs;
