import { Helmet } from "react-helmet-async";

import Tabs from "../../Monolith-UI/Tabs/Tabs";
import { Link, useParams } from "react-router-dom";
import AdminView from "./AdminView/AdminView.js";
import { usePermissions } from "../../hooks/usePermissions";
import UserView from "./UserView/UserView.js";

function Overview() {
  const { currentTab } = useParams();
  const { isAdmin } = usePermissions();

  let salutation = "Good Morning";
  const date = new Date();
  if (date.getHours() >= 0) salutation = "Good Evening";
  if (date.getHours() >= 3) salutation = "Good Morning";
  if (date.getHours() >= 12) salutation = "Good Afternoon";
  if (date.getHours() >= 18) salutation = "Good Evening";

  return (
    <>
      <Helmet title="Overview" />
      <div style={{ marginTop: 5 }}>
        <Tabs value={currentTab}>
          <Tabs.List>
            <Link to="/dashboard/overview/user">
              <Tabs.Tab value="user" onClick={() => {}}>
                My Dashboard
              </Tabs.Tab>
            </Link>
            {isAdmin && (
              <Link to="/dashboard/overview/admin">
                <Tabs.Tab value="admin" onClick={() => {}}>
                  Admin Dashboard
                </Tabs.Tab>
              </Link>
            )}
          </Tabs.List>
        </Tabs>
      </div>
      {isAdmin && currentTab === "admin" && <AdminView />}
      {currentTab === "user" && <UserView />}
    </>
  );
}

export default Overview;
