import styled from "styled-components";
import { X } from "lucide-react";

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  outline: none;
  transition: color 0.2s;
  padding-inline-end: 5px;
`;

const Pill = styled(
  ({ className, children, size, showRemoveIcon = true, onRemove }) => {
    const handleRemove = (e) => {
      e.preventDefault();
      e.stopPropagation();
      onRemove?.();
    };
    return (
      <div className={className}>
        <span className="pill-content">{children}</span>
        {showRemoveIcon && (
          <StyledButton onClick={handleRemove}>
            <X />
          </StyledButton>
        )}
      </div>
    );
  }
)`
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: fit-content;
  max-width: 250px;
  gap: 5px;
  flex: 0;

  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};

  padding-inline-end: 0px;

  padding-inline-start: ${({ size }) => {
    switch (size) {
      case "xs":
        return "8px";
      case "sm":
        return "10px";
      case "md":
        return "12px";
      case "lg":
        return "14px";
      case "xl":
        return "16px";
      default:
        return "10px";
    }
  }};

  height: ${({ size }) => {
    switch (size) {
      case "xs":
        return "20px";
      case "sm":
        return "22px";
      case "md":
        return "24px";
      case "lg":
        return "26px";
      case "xl":
        return "28px";
      default:
        return "22px";
    }
  }};

  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 1000px;

  background-color: ${({ theme }) => theme.palette.background.secondary};

  .pill-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: ${({ size }) => {
      switch (size) {
        case "xs":
          return "10px";
        case "sm":
          return "12px";
        case "md":
          return "14px";
        case "lg":
          return "16px";
        case "xl":
          return "18px";
        default:
          return "12px";
      }
    }};
  }

  svg {
    color: ${({ theme }) => theme.palette.text.primary};

    width: ${({ size }) => {
      switch (size) {
        case "xs":
          return "12px";
        case "sm":
          return "14px";
        case "md":
          return "16px";
        case "lg":
          return "18px";
        case "xl":
          return "20px";
        default:
          return "14px";
      }
    }};
  }
`;

export default Pill;
