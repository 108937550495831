import "react-app-polyfill/stable";

import electronLog from "electron-log/renderer";

import { Container, createRoot } from "react-dom/client";
import { BrowserRouter, HashRouter } from "react-router-dom";

import reportWebVitals from "./utils/reportWebVitals";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { UserSettingsProvider } from "./contexts/UserSettingsContext";
import Slide from "@mui/material/Slide";
import { SnackbarProvider } from "notistack";
import { AuthProvider as LocalAuthProvider } from "./contexts/AuthContext";
import SelectApiMode from "./pages/InitialSetup/SelectApiMode";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

declare global {
  interface Window {
    electronAPI: any;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const main = async () => {
  const isElectron = process.env.REACT_APP_PLATFORM === "electron";
  let apiMode = null;
  if (isElectron) {
    electronLog.errorHandler.startCatching({ showDialog: false });
    console.log = electronLog.log;
    apiMode = await window.electronAPI.getSettings("API_MODE");
  }

  const root = createRoot(document.getElementById("root") as Container);

  root.render(
    <UserSettingsProvider>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={2500}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          TransitionComponent={Slide}
        >
          {isElectron && (
            <>
              {!apiMode ? (
                <SelectApiMode />
              ) : (
                <HashRouter>
                  <LocalAuthProvider>
                    <App />
                  </LocalAuthProvider>
                </HashRouter>
              )}
            </>
          )}
          {!isElectron && (
            <BrowserRouter>
              <LocalAuthProvider>
                <App />
              </LocalAuthProvider>
            </BrowserRouter>
          )}
        </SnackbarProvider>
      </QueryClientProvider>
    </UserSettingsProvider>
  );
};

main();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
