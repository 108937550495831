import { FC } from "react";
import { FormTitle, FormWrapper } from "./components/MonolithFormComponents";

import { useSnackbar, VariantType } from "notistack";
import { usePermissions } from "@/hooks/usePermissions";
import LoggingAPI from "@/api/logging";
import DefaultClientsForm, { EditClientFormData } from "./DefaultClientForm";
import { updateClient } from "@/api";
import { Client } from "@/types";

interface EditClientFormProps {
  width?: number | undefined;
  defaultFormData: Client;
  onSubmit: () => void;
  onCancel: () => void;
}

const EditClientForm: FC<EditClientFormProps> = ({
  width,
  defaultFormData,
  onSubmit,
  onCancel,
}) => {
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (
    data: EditClientFormData,
    diffData?: EditClientFormData
  ) => {
    try {
      updateClient(defaultFormData.client_id, diffData);
      onSubmit?.();
      enqueueSnackbar(`Client ${data?.name || ""} Edited`, {
        variant: "success" as VariantType,
      });

      LoggingAPI.createLog({
        user_id: currentUser.user_id,
        message: `Edited Client: ${data?.name}`,
        event: "contact:edit",
        object_type: "client",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        `An error occurred while editing client ${
          data?.name || ""
        }. Please try again.`,
        { variant: "error" as VariantType }
      );
    }
  };

  return (
    <FormWrapper width={width}>
      <FormTitle>Edit Client</FormTitle>
      <DefaultClientsForm
        onSubmit={handleSubmit}
        onCancel={onCancel}
        defaultFormData={defaultFormData}
        buttonProps={{ submitText: "Edit Client" }}
      />
    </FormWrapper>
  );
};
export default EditClientForm;
