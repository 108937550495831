import QaChecklistAPI from "../../api/quality_assurance/checklists.js";
import { Modal } from "@monolith-forensics/monolith-ui";
import DefaultQaChecklistGroupForm from "../Forms/DefaultQaChecklistGroupForm.js";

const CreateQaChecklistGroupModal = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  ...props
}) => {
  const handleSubmit = async (data) => {
    await QaChecklistAPI.ChecklistGroups.createChecklistGroup(data);
    onSubmit?.(data);
  };

  return (
    <Modal open={show} onClose={onClose}>
      <Modal.Title>Create QA Checklist Group</Modal.Title>
      <Modal.SubTitle style={{ marginBottom: 15 }}>
        Create a new Quality Assurance checklist group.
      </Modal.SubTitle>
      <DefaultQaChecklistGroupForm
        onSubmit={handleSubmit}
        onCancel={onCancel}
        {...props}
      />
    </Modal>
  );
};

export default CreateQaChecklistGroupModal;
