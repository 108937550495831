import { useQuery } from "@tanstack/react-query";

import Modal from "../../../../Monolith-UI/Modal/Modal.js";
import styled from "styled-components";
import { useState } from "react";
import CasesApi from "../../../../api/cases/index.js";
import { useDebounce } from "use-debounce";
import Loader from "../../../../components/Loader.js";
import EvidenceAPI from "../../../../api/evidence/index.js";
import { Input } from "@monolith-forensics/monolith-ui";

const CaseResultItem = styled(
  ({ className, item, onSelect, selectedItem = {} }) => {
    return (
      <div
        data-selected={selectedItem?.uuid === item?.uuid}
        className={className + " case-result-item"}
        onClick={() => onSelect?.(item)}
      >
        <div className="case-number">{item.case_number}</div>
        <div className="case-name">{item.case_ref}</div>
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: auto;
  width: 300px;
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: 5px;
  cursor: pointer;

  transition: border 0.1s ease-in-out;

  &:hover {
    border: 1px solid ${(props) => props.theme.palette.primary.main};
  }

  .case-number {
    font-size: 12px;
    font-weight: 600;
    color: ${(props) => props.theme.palette.text.primary};
  }

  .case-name {
    font-size: 10px;
    color: ${(props) => props.theme.palette.text.secondary};
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const CaseResults = styled(({ className, data, onSelect, selectedItem }) => {
  return (
    <div className={className}>
      <div className="title">Select Case for Migration</div>
      <div className="results-container">
        {data?.length === 0 && <div>No Results</div>}
        {data?.map((item) => (
          <CaseResultItem
            item={item}
            onSelect={onSelect}
            selectedItem={selectedItem}
          />
        ))}
      </div>
    </div>
  );
})`
  user-select: none;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .results-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-height: 300px;
    overflow-y: auto;
    padding-right: 5px;

    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .title {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;

const CasesSearch = styled(
  ({ className, onSelect, selectedItem, caseInfo }) => {
    const [search, setSearch] = useState("");
    const [value] = useDebounce(search, 350);

    const { data, isFetching } = useQuery({
      queryKey: ["cases", "search", { search: value }],
      queryFn: () => CasesApi.getCases({ search: value }),
      enabled: value.length > 3,
    });

    return (
      <div className={className}>
        <Input
          placeholder="Search for Case"
          onChange={(e) => setSearch(e.target.value)}
          style={{ marginTop: 10 }}
          autoFocus
        />
        {!data && isFetching && (
          <div>
            <Loader />
          </div>
        )}
        {value.length > 3 && (
          <>
            <CaseResults
              data={data?.filter((item) => item.uuid !== caseInfo?.uuid)}
              onSelect={onSelect}
              selectedItem={selectedItem}
            />
          </>
        )}
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 auto;
  max-width: 300px;
  width: 300px;
`;

const MigrateEvidenceModal = styled(
  ({
    className,
    open,
    onSubmit = () => {},
    handleClose = () => {},
    defaultInfo = {},
  }) => {
    const [selectedCase, setSelectedCase] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(null);

    const handleSubmit = async (event) => {
      if (!selectedCase) {
        setError("Please select a case to migrate evidence to");
        return;
      }
      setSubmitting(true);
      await EvidenceAPI.migrateItems({
        selectionOptions: defaultInfo?.selectionState?.options,
        migrate_to: {
          case_id: selectedCase.case_id,
          case_uuid: selectedCase.uuid,
          case_number: selectedCase.case_number,
        },
      });
      setSelectedCase(null);
      setSubmitting(false);
      setError(null);
      onSubmit?.();
      handleClose();
    };

    const handleCancel = () => {
      setSelectedCase(null);
      handleClose();
    };

    return (
      <Modal
        open={open}
        title="Migrate Evidence"
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        submitText="Migrate"
        showCloseButton={false}
        width={"500px"}
      >
        <div className={className}>
          {submitting ? (
            <Loader message="Migrating Items..." />
          ) : (
            <>
              <div className="subtle">
                Migrate the selected evidence items from this case to another
                case.
              </div>
              {error && <div className="error-message">{error}</div>}
              {!selectedCase && (
                <div className="thick">
                  <span className="item-total">
                    {defaultInfo?.selectionState?.total}
                  </span>{" "}
                  Items will be migrated.
                </div>
              )}
              {!selectedCase && ( // hide search if case is selected
                <CasesSearch
                  onSelect={setSelectedCase}
                  selectedItem={selectedCase}
                  caseInfo={defaultInfo?.caseInfo || {}}
                />
              )}
              {selectedCase && (
                <div className="selection-result">
                  <div>
                    Migrate{" "}
                    <span className="item-total">
                      {defaultInfo?.selectionState?.total}
                    </span>{" "}
                    Evidence Items
                  </div>
                  <div className="migrate-segment">
                    <div className="direction">FROM</div>
                    <CaseResultItem item={defaultInfo.caseInfo} />
                  </div>
                  <div className="migrate-segment">
                    <div className="direction">TO</div>
                    <CaseResultItem item={selectedCase} />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Modal>
    );
  }
)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .selection-result {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    margin: 0 auto;

    .case-result-item:hover {
      border: 1px solid ${(props) => props.theme.palette.divider};
      cursor: default;
    }
  }

  .error-message {
    font-size: 12px;
    font-weight: 600;
    color: ${(props) => props.theme.palette.error.main};
  }

  .migrate-segment {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    width: 100%;

    .direction {
      font-size: 12px;
      font-weight: 600;
      color: ${(props) => props.theme.palette.text.secondary};
      min-width: 50px;
    }
  }

  .thick {
    font-weight: 600;
  }

  .subtle {
    color: ${(props) => props.theme.palette.text.secondary};
  }

  .item-total {
    font-weight: 600;
    color: ${(props) => props.theme.palette.primary.main};
  }

  .case-number {
    font-weight: 600;
    color: ${(props) => props.theme.palette.success.main};
  }
`;

export default MigrateEvidenceModal;
