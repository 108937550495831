import { useState } from "react";
import {
  Button,
  Loader,
  Modal,
  TextAreaInput,
} from "@monolith-forensics/monolith-ui";
import ButtonMenu from "../Forms/components/ButtonMenu.js";
import { AuditsAPI } from "@/api/index.js";
import { Audit, AuditItem, DefaultTheme } from "@/types";
import styled, { useTheme } from "styled-components";

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: 400px;

  .new-status {
    text-transform: capitalize;
  }
`;

type DefaultInfo = AuditItem & {
  newStatus: "passed" | "failed" | "pending";
} & {
  auditData: Audit;
};

interface ModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: (defaultInfo: DefaultInfo) => void;
  onCancel: () => void;
  buttonProps?: {
    cancelText: string;
    submitText: string;
  };
  defaultInfo: DefaultInfo;
}

const AuditStatusChangeModal: React.FC<ModalProps> = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  buttonProps,
  defaultInfo,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const theme = useTheme() as DefaultTheme;
  const [notes, setNotes] = useState("");

  const buttonStyle = {
    fontWeight: 500,
    padding: "1px 3px",
    borderRadius: 4,
    color:
      defaultInfo.newStatus === "passed"
        ? theme.name === "DARK"
          ? "#32CD32"
          : theme.palette.success.main
        : defaultInfo.newStatus === "failed"
        ? theme.palette.error.main
        : theme.palette.text.secondary,
    backgroundColor:
      defaultInfo.newStatus === "passed"
        ? theme.name === "DARK"
          ? "#32CD3230"
          : theme.palette.success.main + "30"
        : defaultInfo.newStatus === "failed"
        ? theme.palette.error.main + "30"
        : theme.name === "DARK"
        ? "#212121"
        : "#dddddd",
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      if (defaultInfo.newStatus !== "pending") {
        await AuditsAPI.createAuditLog({
          audit_uuid: defaultInfo.auditData.uuid,
          object_uuid: defaultInfo.object_uuid,
          passed_audit: defaultInfo.newStatus === "passed",
          object_type: defaultInfo.object_type,
          notes,
        });
      }

      await AuditsAPI.updateAuditItem({
        uuid: defaultInfo.auditData.uuid,
        object_uuid: defaultInfo.object_uuid,
        status: defaultInfo.newStatus,
        method: defaultInfo.newStatus === "pending" ? null : "manual",
      });

      onSubmit?.(defaultInfo);
    } catch (error) {
      console.error("Error changing audit status:", error);
    } finally {
      setSubmitting(false);
      setNotes(""); // Clear notes
      onClose?.();
    }
  };

  const handleCancel = () => {
    setNotes(""); // Clear notes
    onCancel?.();
  };

  return (
    <Modal open={show} onClose={onClose}>
      <StyledContent>
        <Modal.Title> Change Audit Item Status</Modal.Title>
        {submitting ? (
          <Loader />
        ) : (
          <>
            <div style={{ fontWeight: 500 }}>
              Changing status of item{" "}
              <span className="object-number">{defaultInfo.object_number}</span>{" "}
              to{" "}
              <span className="new-status" style={buttonStyle}>
                {defaultInfo.newStatus}
              </span>
            </div>
            <TextAreaInput
              defaultValue={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Enter notes about the audit status of this item."
              minRows={5}
              maxRows={8}
            />
            <ButtonMenu>
              <Button
                size="xs"
                variant="subtle"
                onClick={handleCancel}
                disabled={submitting}
              >
                {buttonProps?.cancelText || "Cancel"}
              </Button>
              <Button
                size="xs"
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                disabled={submitting}
                loading={submitting}
              >
                {buttonProps?.submitText || "Update Status"}
              </Button>
            </ButtonMenu>
          </>
        )}
      </StyledContent>
    </Modal>
  );
};

export default AuditStatusChangeModal;
