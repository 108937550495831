import { httpGet, httpPatch, httpPost } from "../connection";

async function getUsers(options = {}) {
  return (await httpGet("/users", options)).data;
}

async function checkUserValidity(options = {}) {
  return await httpPost("/users/validate", options);
}

async function query(options = {}) {
  return await httpPost("/users/query", options);
}

async function exportUsersList(options = {}) {
  return await httpPost("/users/export", options);
}

async function createUser(values) {
  return await httpPost("/users", values);
}

async function getUserLeadAssignments() {
  let api = "/users/assignments/leads?exclude=Closed";

  let data = await httpGet(api);

  return data;
}

async function updateUser(id, values) {
  return await httpPatch(`/users/${id}`, values);
}

async function getUserWorkspaces(email) {
  return await httpGet(`/workspaces`, { user_email: email });
}

async function getUserByEmail(user_email) {
  return (
    await httpGet(`/users`, {
      email: user_email,
      includeInactive: true,
      includeObservers: true,
    })
  ).data;
}

async function getUserByUsername(username) {
  return await httpGet(`/users`, { username: username });
}

async function resetMFA({ email }) {
  return await httpPost(`/users/reset-mfa`, { email });
}

async function resetPassword({ email }) {
  const response = await fetch(
    `https://monolithforensics.auth0.com/dbconnections/change_password`,
    {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        connection: "monolith-cloud",
        email,
        client_id: "CHXJ0vb313Vhdix6p5KO5m1XFjY7DXJw",
      }),
    }
  );

  return response;
}

async function updateMFA(values) {
  return await httpPost(`/users/mfa-update`, values);
}

export const userApi = {
  getUsers,
  createUser,
  getUserLeadAssignments,
  updateUser,
  getUserWorkspaces,
  getUserByEmail,
  getUserByUsername,
  resetMFA,
  resetPassword,
  query,
  exportUsersList,
  validateUser: checkUserValidity,
  updateMFA,
};

const UserApi = userApi;
const UserAPI = userApi;

export default UserApi;
