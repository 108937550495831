import styled from "styled-components";
import {
  Button,
  DateInput,
  FieldLabel,
  SelectBox,
  TextInput,
} from "@monolith-forensics/monolith-ui";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { XIcon } from "lucide-react";
import { TraceAttributeTypes } from "../../ColumnDefinitions";

const DefaultAttribute = {
  uuid: null,
  type: null,
  value: null,
  trace_uuid: null,
  created_by_id: null,
  metadata: null,
};

const StyledTextInput = styled(TextInput)`
  width: 100%;
`;

const AttributeRow = styled(
  ({ className, defaultValue, onRemove, onChange, error, query }) => {
    const [selectedType, setSelectedType] = useState(defaultValue?.type);

    const handleChangeType = (type, data) => {
      setSelectedType(data);
      onChange?.({ ...defaultValue, type });
    };

    const handleChangeValue = (value, data) => {
      onChange?.({
        ...defaultValue,
        value,
      });
    };

    return (
      <div className={className}>
        {error && <div className="attr-row-error">{error}</div>}
        <div className="attr-row">
          <div className="attr-col">
            <SelectBox
              data={TraceAttributeTypes.map((type) => ({
                ...type,
                group: type.category,
                label: type.label.replace(type.category, "").trim(),
              }))}
              size="sm"
              placeholder="Select Type"
              onChange={handleChangeType}
              grouped
              searchable
              clearable
              focused
              DropDownProps={{ autoHeight: true }}
            />
          </div>
          <div className="attr-col">
            {selectedType?.editor_type === "datetime" ? (
              <DateInput
                size="sm"
                required
                clearable
                enableCalendar
                description="This is the timestamp of the event in UTC time.  This is the time that the event occurred."
                format={"YYYY-MM-DD HH:mm:ss.SSS"}
                utc={true}
                onChange={(value) => handleChangeValue(value)}
              />
            ) : selectedType?.editor_type === "number" ? (
              <StyledTextInput
                type="number"
                min={selectedType?.min}
                max={selectedType?.max}
                size="sm"
                variant="outlined"
                placeholder="Attribute Value"
                onChange={(e) => handleChangeValue(e.target.value)}
              />
            ) : (
              <StyledTextInput
                size="sm"
                placeholder="Attribute Value"
                onChange={(e) => handleChangeValue(e.target.value)}
                disabled={!selectedType}
              />
            )}
          </div>
          <div
            className="attr-col row-delete-icon"
            onClick={() => onRemove(defaultValue)}
          >
            <XIcon size={16} />
          </div>
        </div>
      </div>
    );
  }
)`
  width: 100%;
  min-height: 28px;

  .attr-row-error {
    user-select: none;
    color: ${({ theme }) => theme.palette.error.main};
    font-size: 10px;
  }

  .attr-row {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    height: 100%;
  }

  .attr-col {
    flex: 10;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .row-delete-icon {
    flex: 1;
    min-height: 20px;
    width: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    color: ${({ theme }) => theme.palette.error.main};

    &:hover {
      background-color: ${({ theme }) => theme.palette.action.hover};
    }
  }
`;

const AttributeRows = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  gap: 8px;
`;

const TraceAttributeInput = styled(
  ({
    className,
    defaultValue,
    defaultTraceIndicatorValue,
    onChange,
    error,
    errors,
    label,
    required,
    size,
    description,
  }) => {
    const [attributes, setAttributes] = useState(defaultValue || []);

    const handleAddAttribute = () => {
      setAttributes((prev) => {
        const newItems = [
          ...prev,
          {
            ...DefaultAttribute,
            uuid: uuidv4(),
            boolean: false,
            created_by_id: defaultTraceIndicatorValue.created_by_id,
            trace_uuid: defaultTraceIndicatorValue.uuid,
            ...defaultValue,
          },
        ];

        onChange?.(newItems);

        return newItems;
      });
    };

    const handleRemoveAttribute = (attr) => {
      const uuid = attr.uuid;

      setAttributes((prev) => {
        const newItems = prev.filter((attr) => attr.uuid !== uuid);
        onChange?.(newItems);
        return newItems;
      });
    };

    const handleAttributeChange = (attr) => {
      setAttributes((prev) => {
        const newItems = prev.map((item) => {
          if (item.uuid === attr.uuid) {
            return attr;
          }
          return item;
        });
        onChange?.(newItems);
        return newItems;
      });
    };

    const handleKeyDown = (e) => {
      // shift + enter
      if (e.shiftKey && e.key === "Enter") {
        e.preventDefault();
        handleAddAttribute();
      }
    };

    useEffect(() => {
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, []);

    return (
      <div className={className}>
        {label && (
          <FieldLabel
            error={error}
            asterisk={required}
            size={size}
            description={
              description ||
              'Add the attributes that define this indicator. These attributes can be used to identify and search the indicator and its behavior.\n\nPress "Shift + Enter" to add a new attribute.'
            }
          >
            {label}
          </FieldLabel>
        )}
        <Button
          variant="text"
          color="primary"
          size="xxs"
          onClick={handleAddAttribute}
        >
          + Add Attribute
        </Button>
        <AttributeRows>
          {attributes.map((attribute, index) => (
            <AttributeRow
              key={attribute.uuid}
              defaultValue={attribute}
              query={defaultTraceIndicatorValue}
              error={
                errors?.[
                  Object.keys(errors).find((key) =>
                    key.includes(attribute.uuid)
                  )
                ] || null
              }
              onRemove={handleRemoveAttribute}
              onChange={handleAttributeChange}
            />
          ))}
        </AttributeRows>
      </div>
    );
  }
)`
  height: auto;
`;

export default TraceAttributeInput;
