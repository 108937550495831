import {
  Button,
  Divider,
  Modal,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import styled from "styled-components";
import RelayAPI from "../../../api/RelayAPI/index.js";
import Loader from "../../../components/Loader.js";
import { useState } from "react";
import { useRef } from "react";
import { useMemo } from "react";
import { useAuth } from "../../../contexts/AuthContext.js";
import BreadCrumbs from "../../../components/BreadCrumbs/index.js";
import { useSnackbar } from "notistack";
import { TextBox } from "devextreme-react/ui/text-box.js";
import Table, { Column } from "../../../Monolith-UI/Table/Table.js";
import { ItemTotal } from "../../Cases/CaseEvidence/index";
import { UsersIcon } from "lucide-react";

const buttonStyle = {
  minWidth: "150px",
  padding: "2px 5px",
};

const Tabs = ({ onTabSelect = () => {} }) => {
  const [group, setGroup] = useState("relay-users");

  const handleChange = (event, newGroup) => {
    if (newGroup !== null) {
      setGroup(newGroup);
      if (newGroup !== group) {
        onTabSelect(newGroup);
      }
    }
  };

  const control = {
    value: group,
    onChange: handleChange,
    exclusive: true,
  };

  return (
    <div style={{ margin: "auto", marginBottom: 15, display: "flex" }}>
      <ToggleButtonGroup size="small" {...control} style={{ margin: "auto" }}>
        <ToggleButton
          value="relay-users"
          key="relay-users"
          size="small"
          disableRipple={true}
          style={buttonStyle}
        >
          <>Relay Users</>
        </ToggleButton>
        <ToggleButton
          value="access-requests"
          key="access-requests"
          size="small"
          disableRipple={true}
          style={buttonStyle}
        >
          <>Access Requests</>
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

const AccessRequests = styled(({ className, relayInfo }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [tableState, setTableState] = useState({});
  const { data, isLoading } = useQuery({
    queryKey: ["relay:user-invites", { tenant_uuid: relayInfo.tenant_uuid }],
    queryFn: () =>
      RelayAPI.getUserInvites({ tenant_uuid: relayInfo.tenant_uuid }),
  });

  const handleGrantAccess = (data) => {
    RelayAPI.grantUserAccess({
      invite_uuid: data.invite_uuid,
      tenant_uuid: data.tenant_uuid,
    }).then((res) => {
      handleQueryRefetch();

      enqueueSnackbar("Relay Access Granted", {
        variant: "success",
      });
    });
  };

  const handleDenyAccess = (data) => {
    RelayAPI.denyUserAccess({
      invite_uuid: data.invite_uuid,
      tenant_uuid: data.tenant_uuid,
    }).then((res) => {
      handleQueryRefetch();

      enqueueSnackbar("Relay Access Denied", {
        variant: "success",
      });
    });
  };

  const handleDeleteInvite = (data) => {
    RelayAPI.denyUserAccess({
      invite_uuid: data.invite_uuid,
      tenant_uuid: data.tenant_uuid,
    }).then((res) => {
      handleQueryRefetch();

      enqueueSnackbar("Relay Invite Revoked", {
        variant: "success",
      });
    });
  };

  const handleQueryRefetch = async () => {
    await queryClient.refetchQueries({
      queryKey: ["relay:user-invites", { tenant_uuid: relayInfo.tenant_uuid }],
    });
  };

  if (isLoading) return <Loader />;

  let users = data.map((invite) => {
    return {
      ...invite,
      ...invite.user,
      email: invite?.user?.email || invite.invite_email,
    };
  });

  if (tableState.search) {
    users = users.filter((user) => {
      return (
        user?.first_name
          ?.toLowerCase()
          ?.includes(tableState.search.toLowerCase()) ||
        user?.last_name
          ?.toLowerCase()
          ?.includes(tableState.search.toLowerCase()) ||
        user?.email?.toLowerCase().includes(tableState.search.toLowerCase()) ||
        user?.org?.toLowerCase().includes(tableState.search.toLowerCase())
      );
    });
  }

  return (
    <div className={className}>
      <div style={{ marginBottom: 10, display: "flex", alignItems: "center" }}>
        <ItemTotal total={users.length || 0} Icon={UsersIcon} />
        <div style={{ marginLeft: "auto" }}>
          <TextBox
            stylingMode="filled"
            placeholder="Search Requests"
            labelMode="static"
            height={30}
            style={{ marginLeft: "10px" }}
            onKeyUp={(e) => {
              let searchText = e.event.currentTarget.value;
              if (
                e.event.code === "Enter" ||
                e.event.code === "NumpadEnter" ||
                searchText === ""
              ) {
                setTableState((prev) => ({ ...prev, search: searchText }));
              }
            }}
          />
        </div>
      </div>
      <Table
        data={users ? users : []}
        keyValue="user_id"
        columnProps={{ minWidth: 150, width: 150 }}
        showActionColumn={false}
      >
        <Column dataField="full_name" caption="Name" />
        <Column dataField="email" caption="Email" />
        <Column dataField="org" caption="Organization" />
        <Column
          dataField="status"
          caption="Status"
          render={(data) => {
            if (data.type === "invite") {
              return (
                <>
                  <>Invited</>
                  <Button
                    variant="text"
                    color="error"
                    size="small"
                    style={{ marginLeft: 20, padding: 3 }}
                    onClick={() => handleDeleteInvite(data)}
                  >
                    Revoke
                  </Button>
                </>
              );
            }

            return (
              <div>
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  style={{ marginRight: 10, padding: 3 }}
                  onClick={() => handleGrantAccess(data)}
                >
                  Grant Access
                </Button>
                <Button
                  variant="text"
                  color="error"
                  size="small"
                  style={{ marginRight: 10, padding: 3 }}
                  onClick={() => handleDenyAccess(data)}
                >
                  Deny Access
                </Button>
              </div>
            );
          }}
        />
      </Table>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  .user-name {
    font-weight: 500;
    color: ${(props) => props.theme.palette.primary.main};
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const RelayUsers = styled(({ className, relayInfo }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [tableState, setTableState] = useState({});
  const queryClient = useQueryClient();
  const [currentView, setCurrentView] = useState(null);

  const { isLoading, data } = useQuery({
    queryKey: ["relay:users", { tenant_uuid: relayInfo.tenant_uuid }],
    queryFn: () => RelayAPI.getUsers({ tenant_uuid: relayInfo.tenant_uuid }),
    select: (data) => {
      return data.map((user) => {
        // get tenant data
        const thisTenant = user.tenants.find(
          (tenant) => tenant.tenant_uuid === relayInfo.tenant_uuid
        );

        return {
          ...user,
          is_admin: thisTenant.is_admin,
          is_active: thisTenant.active,
          is_deleted: thisTenant.is_deleted,
        };
      });
    },
  });

  const onInviteUser = (res) => {
    if (res.success && res.user) {
      queryClient.refetchQueries({
        queryKey: ["relay:users", { tenant_uuid: relayInfo.tenant_uuid }],
      });
    }

    enqueueSnackbar("User Invited", {
      variant: "success",
    });
  };

  const handleViewUser = (user) => {
    setCurrentView(user);
  };

  if (isLoading) return <Loader />;

  let users = data ? data : [];
  if (tableState.search) {
    users = users.filter((user) => {
      return (
        user.full_name
          .toLowerCase()
          .includes(tableState.search.toLowerCase()) ||
        user.email.toLowerCase().includes(tableState.search.toLowerCase()) ||
        user.org.toLowerCase().includes(tableState.search.toLowerCase())
      );
    });
  }

  return (
    <div className={className}>
      {currentView && (
        <>
          <UserView
            user={currentView}
            relayInfo={relayInfo}
            setCurrentView={setCurrentView}
          />
        </>
      )}
      {!currentView && (
        <>
          <InviteUserModal
            relayInfo={relayInfo}
            open={open}
            handleClose={() => setOpen(false)}
            onSubmit={onInviteUser}
          />
          <div
            style={{
              marginTop: 0,
              marginBottom: 10,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setOpen(true)}
            >
              Invite User
            </Button>
            <ItemTotal total={users.length || 0} Icon={UsersIcon} />
            <div style={{ marginLeft: "auto" }}>
              <TextBox
                stylingMode="filled"
                placeholder="Search Users"
                labelMode="static"
                height={30}
                style={{ marginLeft: "10px" }}
                onKeyUp={(e) => {
                  let searchText = e.event.currentTarget.value;
                  if (
                    e.event.code === "Enter" ||
                    e.event.code === "NumpadEnter" ||
                    searchText === ""
                  ) {
                    setTableState((prev) => ({ ...prev, search: searchText }));
                  }
                }}
              />
            </div>
          </div>
          <Table
            data={users ? users : []}
            keyValue="user_id"
            columnProps={{ minWidth: 150, width: 150 }}
            showActionColumn={false}
          >
            <Column
              dataField="full_name"
              caption="Name"
              render={(data) => {
                return (
                  <div
                    className="user-name"
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={() => handleViewUser(data)}
                  >
                    <div>{data.full_name}</div>
                  </div>
                );
              }}
            />
            <Column dataField="email" caption="Email" />
            <Column dataField="org" caption="Organization" />
            <Column
              dataField="is_admin"
              caption="Relay Admin"
              alignment={"left"}
              render={(data) => (data.is_admin ? "Yes" : "No")}
            />
          </Table>
        </>
      )}
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  .user-name {
    font-weight: 500;
    color: ${(props) => props.theme.palette.primary.main};
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const InviteUserModal = styled(
  ({ className, relayInfo, handleClose, open, onSubmit }) => {
    const { currentUser } = useAuth();
    const theme = useTheme();
    const form = useRef(null);

    const handleSubmit = () => {
      //Check if valid
      if (!form.current.instance.validate().isValid) return;

      //Get form data
      const { email } = form.current.instance.option("formData");

      RelayAPI.inviteUser({
        email,
        tenant_uuid: relayInfo.tenant_uuid,
        tenant_admin_email: currentUser.email,
        type: "invite",
        created_on: new Date().toISOString(),
      })
        .then((res) => {
          onSubmit(res);
        })
        .catch((err) => {
          console.log(err);
        });

      handleClose();
    };

    return (
      <>
        <Modal open={open} onClose={handleClose}>
          <div
            style={{
              marginTop: 20,
              marginBottom: 20,
              width: 400,
              backgroundColor: theme.palette.background.default,
              position: "absolute",
              left: "50%",
              top: "20%",
              transform: "translate(-35%, -20%)",
              padding: 20,
            }}
          >
            <div style={{ marginBottom: 20 }}>
              <Typography variant="h4" display="inline">
                Invite User
              </Typography>
            </div>
            {useMemo(() => {
              return (
                <Form
                  ref={form}
                  colCount={1}
                  onContentReady={(e) => {
                    e.component.getEditor("email").focus();
                  }}
                >
                  <SimpleItem
                    dataField="email"
                    label={{ text: "User Email", visible: false }}
                    isRequired={true}
                    editorOptions={{
                      placeholder: "Enter the user's email address",
                      mode: "email",
                    }}
                  />
                </Form>
              );
            }, [])}
            <div
              style={{ display: "flex", justifyContent: "end", marginTop: 20 }}
            >
              <Button size="small" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: 10 }}
                size="small"
                onClick={handleSubmit}
              >
                Invite
              </Button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
)``;

const UserView = styled(({ className, user, relayInfo, setCurrentView }) => {
  const theme = useTheme();
  const userForm = useRef(null);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const navItems = [
    {
      name: "Relay Users",
      path: "/settings",
    },
    {
      name: `${user.full_name} (${user.email})`,
      path: `/settings`,
    },
  ];

  const handleSelect = (item) => {
    if (item.name === "Relay Users") {
      setCurrentView(null);
    }
  };

  const handleSubmit = () => {
    if (!userForm.current.instance.validate().isValid) return;

    const userFormData = userForm.current.instance.option("formData");

    RelayAPI.updateUser({
      ...userFormData,
      email: user.email,
    })
      .then((res) => {
        enqueueSnackbar("User updated successfully", { variant: "success" });
        queryClient.refetchQueries({ queryKey: ["relay:users"] });
        setCurrentView((oldData) => {
          return {
            ...oldData,
            ...userFormData,
          };
        });
      })
      .catch((err) => {});
  };

  return (
    <div className={className}>
      <div className="bc-container">
        <BreadCrumbs list={navItems} onItemSelect={handleSelect} />
      </div>

      <div style={{ marginTop: 20 }}>
        <Typography variant="h3" display="inline">
          Edit User Details
        </Typography>
        <div style={{ color: theme.palette.text.secondary, marginTop: 5 }}>
          Edit the details of this user.
        </div>
        <>
          <div style={{ marginTop: 20, textAlign: "left" }}>
            <div
              style={{
                marginTop: 20,
                marginBottom: 20,
                textAlign: "left",
                maxWidth: 500,
              }}
            >
              <Form ref={userForm} colCount={2}>
                <SimpleItem
                  dataField="first_name"
                  isRequired={true}
                  label={{ visible: false }}
                  editorOptions={{
                    placeholder: "First Name",
                    value: user.first_name,
                  }}
                />
                <SimpleItem
                  dataField="last_name"
                  isRequired={true}
                  label={{ visible: false }}
                  editorOptions={{
                    placeholder: "Last Name",
                    value: user.last_name,
                  }}
                />
                <SimpleItem
                  dataField="org"
                  colSpan={2}
                  isRequired={true}
                  label={{ visible: false }}
                  editorOptions={{
                    placeholder: "Organization Name",
                    value: user.org,
                  }}
                />
                <SimpleItem
                  dataField="address"
                  isRequired={true}
                  colSpan={2}
                  label={{ visible: false }}
                  editorOptions={{
                    placeholder: "Address",
                    value: user.address,
                  }}
                />
                <SimpleItem
                  dataField="city"
                  isRequired={true}
                  label={{ visible: false }}
                  editorOptions={{ placeholder: "City", value: user.city }}
                />
                <SimpleItem
                  dataField="state"
                  isRequired={true}
                  label={{ visible: false }}
                  editorOptions={{
                    placeholder: "State/Province",
                    value: user.state,
                  }}
                />
                <SimpleItem
                  dataField="zipcode"
                  isRequired={true}
                  label={{ visible: false }}
                  editorOptions={{
                    placeholder: "Postal Code",
                    value: user.zipcode,
                  }}
                />
                <SimpleItem
                  dataField="phone"
                  isRequired={true}
                  label={{ visible: false }}
                  editorOptions={{
                    placeholder: "Phone Number",
                    value: user.phone,
                  }}
                />
              </Form>
            </div>
          </div>
          <div className="action-btns">
            <div className="view-request-btn">
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleSubmit}
              >
                Update User
              </Button>
            </div>
          </div>
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
        </>
        <RemoveUserSection
          user={user}
          relayInfo={relayInfo}
          setCurrentView={setCurrentView}
        />
        <UpdateUserRoleSection
          user={user}
          relayInfo={relayInfo}
          setCurrentView={setCurrentView}
        />
      </div>
    </div>
  );
})`
  .bc-container {
    margin-bottom: 20px;
  }
`;

const RemoveUserSection = styled(
  ({ className, user, relayInfo, setCurrentView }) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);

    const handleRemove = () => setOpen(true);

    return (
      <>
        <Typography variant="h3" display="inline">
          Remove User
        </Typography>
        <div style={{ color: theme.palette.text.secondary, marginTop: 5 }}>
          This will remove this user's access to your Relay Tenant.
        </div>
        <div className="action-btns" style={{ marginTop: 15 }}>
          <div>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleRemove}
            >
              Remove User
            </Button>
          </div>
        </div>
        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
        <RemoveUserConfirm
          user={user}
          relayInfo={relayInfo}
          open={open}
          handleClose={handleClose}
          setCurrentView={setCurrentView}
        />
      </>
    );
  }
)``;

const RemoveUserConfirm = ({
  user,
  relayInfo,
  open,
  handleClose,
  setCurrentView,
}) => {
  const theme = useTheme();
  const queryClient = useQueryClient();

  const handleSubmit = () => {
    RelayAPI.removeUser({
      tenant_uuid: relayInfo.tenant_uuid,
      user_id: user.user_id,
    })
      .then((res) => {
        queryClient.refetchQueries({ queryKey: ["relay:users"] });
      })
      .catch((err) => {});
    setCurrentView(null);
    handleClose();
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 400,
            backgroundColor: theme.palette.background.default,
            position: "absolute",
            left: "50%",
            top: "20%",
            transform: "translate(-35%, -20%)",
            padding: 20,
          }}
        >
          <div style={{ marginBottom: 20 }}>
            <Typography variant="h4" display="inline">
              Remove User
            </Typography>
          </div>
          <div>
            <div style={{ color: theme.palette.text.secondary, marginTop: 5 }}>
              Are you sure you want to remove this user from your Relay Tenant?
            </div>
          </div>
          <div
            style={{ display: "flex", justifyContent: "end", marginTop: 20 }}
          >
            <Button size="small" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              style={{ marginLeft: 10 }}
              size="small"
              onClick={handleSubmit}
            >
              Remove User
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const UpdateUserRoleSection = styled(
  ({ className, user, relayInfo, setCurrentView }) => {
    const theme = useTheme();
    const queryClient = useQueryClient();
    const [open, setOpen] = useState(false);
    const [isAdmin, setIsAdmin] = useState(user.is_admin === 1);

    const handleClose = () => setOpen(false);

    const handleRemove = () => setOpen(true);

    const onUpdate = (data) => {
      setIsAdmin(data.is_admin === 1);
      queryClient.refetchQueries({ queryKey: ["relay:users"] });
    };

    return (
      <>
        <Typography variant="h3" display="inline">
          Change User Role
        </Typography>
        <div style={{ color: theme.palette.text.secondary, marginTop: 5 }}>
          Add or remove Relay admin status from this user. Admins can grant
          Relay Access to other users.
        </div>
        <div className="action-btns" style={{ marginTop: 15 }}>
          {!isAdmin && (
            <div>
              <Button
                variant="contained"
                color="success"
                size="small"
                onClick={handleRemove}
                style={{
                  color: "white",
                  backgroundColor: "green",
                }}
              >
                Grant Admin Role
              </Button>
              <GrantAdminConfirm
                user={user}
                relayInfo={relayInfo}
                open={open}
                handleClose={handleClose}
                onSubmit={onUpdate}
                setCurrentView={setCurrentView}
              />
            </div>
          )}
          {isAdmin && (
            <div>
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={handleRemove}
              >
                Revoke Admin Role
              </Button>
              <RevokeAdminConfirm
                user={user}
                relayInfo={relayInfo}
                open={open}
                handleClose={handleClose}
                onSubmit={onUpdate}
                setCurrentView={setCurrentView}
              />
            </div>
          )}
        </div>
        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      </>
    );
  }
)``;

const RevokeAdminConfirm = ({
  user,
  relayInfo,
  open,
  handleClose,
  onSubmit = () => {},
}) => {
  const theme = useTheme();

  const handleSubmit = () => {
    RelayAPI.updateUserRole({
      is_admin: 0,
      email: user.email,
      tenant_uuid: relayInfo.tenant_uuid,
    })
      .then((res) => {
        onSubmit({
          is_admin: 0,
          email: user.email,
          tenant_uuid: relayInfo.tenant_uuid,
        });
      })
      .catch((err) => {});
    handleClose();
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 400,
            backgroundColor: theme.palette.background.default,
            position: "absolute",
            left: "50%",
            top: "20%",
            transform: "translate(-35%, -20%)",
            padding: 20,
          }}
        >
          <div style={{ marginBottom: 20 }}>
            <Typography variant="h4" display="inline">
              Revoke Admin Status
            </Typography>
          </div>
          <div>
            <div style={{ color: theme.palette.text.secondary, marginTop: 5 }}>
              Are you sure you want to revoke admin status from this user?
            </div>
          </div>
          <div
            style={{ display: "flex", justifyContent: "end", marginTop: 20 }}
          >
            <Button size="small" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              style={{ marginLeft: 10 }}
              size="small"
              onClick={handleSubmit}
            >
              Revoke Admin
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const GrantAdminConfirm = ({
  user,
  relayInfo,
  open,
  handleClose,
  onSubmit = () => {},
}) => {
  const theme = useTheme();

  const handleSubmit = () => {
    RelayAPI.updateUserRole({
      is_admin: 1,
      email: user.email,
      tenant_uuid: relayInfo.tenant_uuid,
    })
      .then((res) => {
        onSubmit({
          is_admin: 1,
          email: user.email,
          tenant_uuid: relayInfo.tenant_uuid,
        });
      })
      .catch((err) => {});
    handleClose();
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 400,
            backgroundColor: theme.palette.background.default,
            position: "absolute",
            left: "50%",
            top: "20%",
            transform: "translate(-35%, -20%)",
            padding: 20,
          }}
        >
          <div style={{ marginBottom: 20 }}>
            <Typography variant="h4" display="inline">
              Grant Admin Status
            </Typography>
          </div>
          <div>
            <div style={{ color: theme.palette.text.secondary, marginTop: 5 }}>
              Are you sure you want to grant Relay admin status to this user?
            </div>
          </div>
          <div
            style={{ display: "flex", justifyContent: "end", marginTop: 20 }}
          >
            <Button size="small" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              style={{
                marginLeft: 10,
                color: "white",
                backgroundColor: "green",
              }}
              size="small"
              onClick={handleSubmit}
            >
              Grant Admin
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const UserManagement = styled(({ className, relayInfo }) => {
  const [view, setView] = useState("relay-users");

  return (
    <div className={className}>
      <Tabs onTabSelect={setView} />
      {view === "relay-users" && <RelayUsers relayInfo={relayInfo} />}
      {view === "access-requests" && <AccessRequests relayInfo={relayInfo} />}
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;
`;

export default UserManagement;
