import { Card, Divider } from "@mui/material";
import Loader from "../../../../components/Loader";

const TextLine = ({ text, value }) => {
  return (
    <div
      style={{
        minWidth: "300px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "5px",
        }}
      >
        <div>{text}:</div>
        <div>{value || 0}</div>
      </div>
    </div>
  );
};

export const TimeEntryMetrics = ({ timeEntry, metrics }) => {
  return (
    <Card
      style={{
        padding: "20px",
        textAlign: "left",
        flexGrow: 1,
        flexBasis: "10px",
      }}
    >
      <div
        style={{
          fontSize: "larger",
        }}
      >
        Metrics
      </div>
      <Divider sx={{ my: 2 }} />
      <div
        style={{
          minWidth: "300px",
          overflowY: "auto",
          maxHeight: "175px",
          paddingRight: "10px",
        }}
      >
        {!metrics && !timeEntry && <Loader />}
        {metrics && timeEntry && (
          <>
            <TextLine text="Entries" value={timeEntry.length} />
            <TextLine
              text="Total Time (hrs)"
              value={(
                timeEntry.reduce((acc, curr) => (acc += curr.duration), 0) /
                3600
              ).toFixed(2)}
            />
            <TextLine
              text="Total Categories"
              value={metrics?.categories?.length || 0}
            />
            <TextLine text="Total Users" value={metrics?.users?.length || 0} />
            <TextLine
              text="Total Organizations"
              value={metrics?.org_clients?.length || 0}
            />
          </>
        )}
      </div>
    </Card>
  );
};
