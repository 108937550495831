import convertBytesToSize from "../../utils/convertBytesToSize.js";
import { monolithMoment } from "../../utils/date-format";

const CaseFileDefs = [
  {
    dataField: "uuid",
    caption: "UUID",
    visible: false,
  },
  {
    dataField: "file_name",
    caption: "Name",
    visible: true,
  },
  {
    dataField: "status",
    caption: "Status",
    render: (rowData) => (
      <span style={{ textTransform: "capitalize" }}>{rowData.status}</span>
    ),
    visible: true,
  },
  {
    dataField: "file_size",
    caption: "Size",
    visible: true,
    render: (rowData) => convertBytesToSize(rowData.file_size),
  },
  {
    dataField: "file_ext",
    caption: "Type",
    visible: true,
  },
  {
    dataField: "file_path",
    caption: "Path",
    visible: true,
  },
  {
    dataField: "created_on",
    caption: "Created On",
    render: (rowData) =>
      monolithMoment({
        timestamp: rowData.created_on,
        includeTime: true,
        includeZone: true,
      }),
    visible: true,
  },
  {
    dataField: "client_modified",
    caption: "Client Modified",
    render: (rowData) =>
      monolithMoment({
        timestamp: rowData.client_modified,
        includeTime: true,
        includeZone: true,
      }),
    visible: true,
  },
  {
    dataField: "md5",
    caption: "MD5",
    visible: true,
  },
  {
    dataField: "sha1",
    caption: "SHA1",
    visible: true,
  },
  {
    dataField: "sha256",
    caption: "SHA256",
    visible: true,
  },
  {
    dataField: "description",
    caption: "Description",
    visible: false,
  },
];

export default CaseFileDefs;
