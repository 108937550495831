import { useState } from "react";
import QaChecklistAPI from "../../api/quality_assurance/checklists.js";
import { Button, Modal } from "@monolith-forensics/monolith-ui";
import ButtonMenu from "../Forms/components/ButtonMenu.js";

const DeleteQaChecklistGroupModal = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  buttonProps = {},
  defaultInfo = {},
}) => {
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    setSubmitting(true);

    await QaChecklistAPI.ChecklistGroups.deleteCheckListGroup(defaultInfo);

    onSubmit?.(defaultInfo);
    setSubmitting(false);
    onClose?.();
  };

  const handleCancel = () => {
    onCancel?.();
  };

  return (
    <Modal open={show} onClose={onClose}>
      <Modal.Title>Delete Checklist Group</Modal.Title>
      <div style={{ fontWeight: 600, margin: "20px 0px" }}>
        Are you sure you want to delete the Checklist Group{" "}
        <span style={{ fontWeight: 600 }}>"{defaultInfo?.group_name}"</span>?
      </div>
      <ButtonMenu>
        <Button
          size="xs"
          variant="subtle"
          onClick={handleCancel}
          disabled={submitting}
        >
          {buttonProps?.cancelText || "Cancel"}
        </Button>
        <Button
          size="xs"
          color="error"
          variant="contained"
          onClick={handleSubmit}
          disabled={submitting}
          loading={submitting}
        >
          {buttonProps?.submitText || "Delete Checklist Group"}
        </Button>
      </ButtonMenu>
    </Modal>
  );
};

export default DeleteQaChecklistGroupModal;
