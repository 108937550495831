import { useState } from "react";
import { Modal } from "@monolith-forensics/monolith-ui";
import Loader from "../Loader.js";

import DefaultTimelineEventForm from "../Forms/DefaultTimelineEventForm.js";
import { AnalysisAPI } from "../../api/index.js";
import { useMutation } from "@tanstack/react-query";
import LoggingAPI from "../../api/logging/index.js";
import { usePermissions } from "../../hooks/usePermissions";

const CreateTimelineEventModal = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  ...props
}) => {
  const { currentUser } = usePermissions();
  const [submitting, setSubmitting] = useState(false);

  const createTimelineEvent = useMutation({
    mutationFn: AnalysisAPI.TimelineEvents.create,
    onMutate: () => setSubmitting(true),
    onSuccess: (_, variables) => {
      LoggingAPI.createLog({
        case_id: variables.case_id,
        user_id: currentUser.user_id,
        message: `Created timeline event.`,
        event: "create_timeline_event",
        object_type: "timeline_event",
        object_uuid: variables.uuid,
      });
      onSubmit?.(variables);
      setSubmitting(false);
      onClose?.();
    },
  });

  const handleSubmit = async (data) => {
    createTimelineEvent.mutate(data);
  };

  return (
    <Modal open={show} onClose={onClose} style={{ width: 600 }}>
      <Modal.Title>Create Timeline Event</Modal.Title>
      <Modal.SubTitle style={{ marginBottom: 15 }}>
        Create a new timeline event
      </Modal.SubTitle>
      {!submitting && (
        <DefaultTimelineEventForm
          onSubmit={handleSubmit}
          onCancel={onCancel}
          {...props}
        />
      )}
      {submitting && <Loader />}
    </Modal>
  );
};

export default CreateTimelineEventModal;
