import { FC } from "react";
import { Modal } from "@monolith-forensics/monolith-ui";

import { EditContactForm } from "../Forms";
import { Contact } from "@/types";

interface EditContactModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onCancel: () => void;
  defaultInfo: Contact;
}

const EditContactModal: FC<EditContactModalProps> = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  defaultInfo,
}) => {
  const handleSubmit = () => {
    onSubmit?.(); // Refetch queries in Contact section
    handleClose();
  };

  const handleClose = () => {
    onClose?.();
  };
  const handleCancel = () => {
    onCancel?.();
  };

  return (
    <Modal open={show} onClose={onClose}>
      <EditContactForm
        width={550}
        defaultFormData={defaultInfo}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </Modal>
  );
};

export default EditContactModal;
