import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import DropdownMenu from "../../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import { usePermissions } from "../../../hooks/usePermissions";
import CasesApi from "../../../api/cases/index.js";
import { useState } from "react";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { FolderClosedIcon } from "lucide-react";
import styled from "styled-components";

const PageSize = 100;

const MenuItem = styled(({ className, caseRecord }) => {
  return (
    <div className={className}>
      <div className={"case-number"}>{caseRecord.case_number}</div>
      <div className={"case-name"}>{caseRecord.case_ref}</div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  gap: 5px;

  padding: 5px 0px;

  .case-number {
    font-weight: 400;
  }

  .case-name {
    font-weight: 300;
    font-size: 12px;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

const CaseSelector = ({
  value,
  onSelect,
  showLabel = true,
  variant = "contained",
  error = false,
  disabled = false,
}) => {
  const { currentUser } = usePermissions();
  const [selectedCase, setSelectedCase] = useState(value);
  const [search, setSearch] = useState("");
  const [searchValue] = useDebounce(search, 300);

  const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: [
        "cases",
        "inf-list",
        {
          user_id: currentUser.user_id,
          search: searchValue,
          pageSize: PageSize,
        },
      ],
      queryFn: ({ pageParam }) => {
        return CasesApi.query({
          user_id: currentUser.user_id,
          search: searchValue,
          page: pageParam,
          pageSize: PageSize,
        });
      },
      getNextPageParam: (lastPage, pages) => {
        return lastPage.nextPage;
      },
      getPreviousPageParam: (firstPage, pages) => {
        if (firstPage.page - 1 === 0) return null;
        return firstPage.page - 1;
      },
      initialPageParam: 1,
      placeholderData: (data) => data,
    });

  const records = data?.pages?.reduce((acc, page) => {
    return [...acc, ...page.data];
  }, []);

  const totalRecords = data?.pages?.[0]?.total || 0;

  const debouncedFetchNextPage = useDebouncedCallback((e) => {
    if (isFetchingNextPage) return;
    if (!hasNextPage) return;
    fetchNextPage();
  }, 50);

  const menuItems = [
    {
      radioGroup: true,
      value: value?.case_id,
      items:
        records?.map((caseRecord) => {
          return {
            label: caseRecord.case_number,
            value: caseRecord.case_id,
            render: () => <MenuItem caseRecord={caseRecord} />,
            onClick: () => {
              setSelectedCase(caseRecord);
              onSelect?.(caseRecord);
            },
          };
        }) || [],
    },
  ];

  return (
    <DropdownMenu
      menuItems={menuItems}
      title={"Select Task"}
      variant={variant}
      searchEnabled={true}
      onScrollEnd={debouncedFetchNextPage}
      onSearch={setSearch}
      textColor={!!selectedCase?.case_id ? "primary" : "secondary"}
      loading={!data}
      error={error}
      disabled={disabled}
    >
      {showLabel && (
        <div title={selectedCase?.case_number || "Case"}>
          {
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                width: "100%",
              }}
            >
              <FolderClosedIcon size={14} style={{ minWidth: 14 }} />
              <div
                style={{
                  maxWidth: 125,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {selectedCase?.case_number || "Case"}
              </div>
            </div>
          }
        </div>
      )}
    </DropdownMenu>
  );
};

export default CaseSelector;
