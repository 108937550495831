import styled from "styled-components";
import MainGridLayout from "./MainGridLayout.js";
import DropdownMenu from "../../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import { LayoutDashboardIcon } from "lucide-react";
import CaseLeadChart from "../components/widgets/CaseLeadChart.js";
import CaseTypesPerUserWidget from "../components/widgets/CaseTypesPerUserWidget.js";
import CaseDataChart from "../components/widgets/CaseDataChart.js";
import DataCollectedWidget from "../components/widgets/DataCollectedWidget.js";
import EvidenceSummaryWidget from "../components/widgets/EvidenceSummaryWidget.js";
import CasesSummaryWidget from "../components/widgets/CasesSummaryWidget.js";
import TimeTrackedWidget from "../components/widgets/TimeTrackedWidget.js";
import TopClientsWidget from "../components/widgets/TopClientsWidget.js";
import TimeTrackedPerUserWidget from "../components/widgets/TimeTrackedPerUserWidget.js";
import { useState } from "react";

const items = [
  {
    title: "Case Lead Assignments",
    label: "Case Lead Assignments",
    value: "case_lead_chart",
    visible: true,
    layout: {
      w: 4,
      h: 7,
      x: 0,
      y: 5,
      visible: true,
      i: "case_lead_chart",
      moved: false,
      static: false,
    },
    widget: <CaseLeadChart />,
    description:
      "Shows the composition of cases by lead and excludes closed cases.",
  },
  {
    title: "Cases Grouped by User and Status",
    label: "Cases Grouped by User and Status",
    value: "case_types_by_user_chart",
    visible: true,
    layout: {
      w: 8,
      h: 7,
      x: 4,
      y: 5,
      visible: true,
      i: "case_types_by_user_chart",
      moved: false,
      static: false,
    },
    widget: <CaseTypesPerUserWidget />,
    description:
      "Shows the composition of cases by lead and excludes closed cases.",
  },
  {
    title: "Cases & Evidence (last 12 months)",
    label: "Cases & Evidence (last 12 months)",
    value: "case_data_chart",
    visible: true,
    layout: {
      w: 8,
      h: 7,
      x: 0,
      y: 12,
      visible: true,
      i: "case_data_chart",
      moved: false,
      static: false,
    },
    widget: <CaseDataChart />,
    description:
      "Shows the previous 12 months worth of new cases, evidence, and acquisitions.",
  },
  {
    label: "Data Collected (This Month)",
    layout: {
      w: 3,
      h: 5,
      x: 0,
      y: 0,
      visible: true,
      i: "data_collected_chart",
      moved: false,
      static: false,
    },
    value: "data_collected_chart",
    visible: true,
    widget: <DataCollectedWidget />,
    description: "The amount of data collected this month from all cases.",
  },
  {
    label: "Evidence Summary (This Month)",
    value: "evidence_summary",
    visible: true,
    layout: {
      w: 3,
      h: 5,
      x: 9,
      y: 0,
      visible: true,
      i: "evidence_summary",
      moved: false,
      static: false,
    },
    widget: <EvidenceSummaryWidget />,
    description: "The amount of evidence collected this month from all cases.",
  },
  {
    label: "Case Summary (This Month)",
    value: "cases_summary",
    visible: true,
    layout: {
      w: 3,
      h: 5,
      x: 6,
      y: 0,
      visible: true,
      i: "cases_summary",
      moved: false,
      static: false,
    },
    widget: <CasesSummaryWidget />,
    description: "The amount of evidence collected this month from all cases.",
  },
  {
    label: "Time Tracked (This Month)",
    value: "time_tracked",
    visible: true,
    layout: {
      w: 3,
      h: 5,
      x: 3,
      y: 0,
      visible: true,
      i: "time_tracked",
      moved: false,
      static: false,
    },
    widget: <TimeTrackedWidget />,
    description: "The amount of evidence collected this month from all cases.",
  },
  {
    title: "Top Clients (Last 6 Months)",
    label: "Top Clients (Last 6 Months)",
    value: "top_clients",
    visible: true,
    layout: {
      w: 4,
      h: 7,
      x: 8,
      y: 12,
      visible: true,
      i: "top_clients",
      moved: false,
      static: false,
    },
    widget: <TopClientsWidget />,
    description: "The amount of evidence collected this month from all cases.",
  },
  {
    label: "Time Tracked Per User",
    value: "time_tracked_per_user",
    visible: true,
    layout: {
      w: 12,
      h: 12,
      x: 0,
      y: 19,
      visible: true,
      i: "time_tracked_per_user",
      moved: false,
      static: false,
    },
    widget: <TimeTrackedPerUserWidget />,
    description: "The amount of evidence collected this month from all cases.",
  },
];

const AdminView = styled(({ className }) => {
  const [widgetState, setWidgetState] = useState(() => {
    const savedLayout = JSON.parse(
      window.localStorage.getItem("overview:layout:admin")
    );

    return items.map((layoutItem) => {
      const layout = layoutItem.layout;
      const saved =
        savedLayout?.find((l) => l?.layout?.i === layout.i)?.layout || {};

      return {
        ...layoutItem,
        layout: {
          ...layout,
          ...saved,
        },
      };
    });
  });

  const onLayoutChange = (layout) => {
    setWidgetState((prev) => {
      const newState = prev.map((l) => {
        const newLayout = layout.find((nl) => nl.i === l.layout.i);
        return {
          ...l,
          layout: {
            ...l.layout,
            ...newLayout,
          },
        };
      });

      window.localStorage.setItem(
        "overview:layout:admin",
        JSON.stringify(newState)
      );

      return newState;
    });
  };

  const handleSelect = (data) => {
    const selected = data.map((d) => d.value);

    setWidgetState((prev) => {
      return prev.map((l) => {
        return {
          ...l,
          layout: {
            ...l.layout,
            visible: selected.includes(l.layout.i),
          },
        };
      });
    });
  };

  return (
    <div className={className}>
      <div className="menu-bar">
        <DropdownMenu
          menuItems={[
            {
              checkboxGroup: true,
              value: widgetState
                .filter((l) => l?.layout?.visible !== false)
                .map((l) => l.layout.i),
              onSelectionChanged: handleSelect,
              items: items,
            },
          ]}
          arrow
        >
          <LayoutDashboardIcon size={14} style={{ marginRight: 5 }} />
          Customize
        </DropdownMenu>
      </div>
      <MainGridLayout
        layout={widgetState.map((l) => l.layout)}
        widgets={widgetState.filter((l) => l?.layout?.visible !== false)}
        onLayoutChange={onLayoutChange}
      />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;

  .menu-bar {
    display: flex;
    flex-direction: row;
    margin: 10px 13px;
    margin-bottom: 0;
  }

  .react-grid-item.react-grid-placeholder {
    background: none !important;
    border: 3px solid rgb(71, 130, 218);
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
`;

export default AdminView;
