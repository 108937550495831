import { EditorContent } from "@tiptap/react";
import { forwardRef } from "react";
import styled from "styled-components";
import { RichTextEditorLabels } from "../RichTextEditor/labels.js";
import {
  IconBold,
  IconItalic,
  IconUnderline,
  IconStrikethrough,
  IconClearFormatting,
  IconH1,
  IconH2,
  IconH3,
  IconH4,
  IconH5,
  IconH6,
  IconList,
  IconListNumbers,
  IconUnlink,
  IconBlockquote,
  IconAlignLeft,
  IconAlignRight,
  IconAlignCenter,
  IconAlignJustified,
  IconSubscript,
  IconSuperscript,
  IconCode,
  IconHighlight,
  IconLineDashed,
  IconCircleOff,
  IconCornerUpLeft,
  IconCornerUpRight,
  IconDots,
} from "@tabler/icons-react";

const ControlsGroup = styled(
  forwardRef(({ className, children, ...props }, ref) => {
    return (
      <div className={className + " controls-group"} ref={ref}>
        {children}
      </div>
    );
  })
)`
  & {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  & button {
    border-radius: 0;
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-right: none;
  }
  & button:first-child {
    border-radius: 5px 0 0 5px;
  }
  & button:last-child {
    border-radius: 0 5px 5px 0;
    border-right: 1px solid ${({ theme }) => theme.palette.divider};
  }
`;

ControlsGroup.displayName = "ControlsGroup";

const Control = styled(
  ({ className, editor, isActive, operation, label, icon: Icon }) => {
    const _label = RichTextEditorLabels[label];

    const active = isActive?.name
      ? editor?.isActive?.(isActive.name, isActive.attributes)
      : false;

    return (
      <button
        className={className + (active ? " active" : "")}
        aria-label={_label}
        active={active}
        title={_label}
        onClick={() => {
          editor?.chain().focus()[operation.name](operation.attributes).run();
        }}
      >
        <Icon size="16px" />
      </button>
    );
  }
)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0px;
  background-color: transparent;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.text.primary};
  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }
  &.active {
    background-color: ${({ theme }) => theme.palette.action.hover};
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const UndoControl = ({ editor }) => {
  return (
    <Control
      editor={editor}
      label="undoControlLabel"
      operation={{
        name: "undo",
      }}
      icon={IconCornerUpLeft}
    />
  );
};

const RedoControl = ({ editor }) => {
  return (
    <Control
      editor={editor}
      label="redoControlLabel"
      operation={{
        name: "redo",
      }}
      icon={IconCornerUpRight}
    />
  );
};

const BoldControl = ({ editor }) => {
  return (
    <Control
      editor={editor}
      label="boldControlLabel"
      isActive={{
        name: "bold",
      }}
      operation={{
        name: "toggleBold",
      }}
      icon={IconBold}
    />
  );
};

const ItalicControl = ({ editor }) => {
  return (
    <Control
      editor={editor}
      label="italicControlLabel"
      isActive={{
        name: "italic",
      }}
      operation={{
        name: "toggleItalic",
      }}
      icon={IconItalic}
    />
  );
};

const UnderlineControl = ({ editor }) => {
  return (
    <Control
      editor={editor}
      label="underlineControlLabel"
      isActive={{
        name: "underline",
      }}
      operation={{
        name: "toggleUnderline",
      }}
      icon={IconUnderline}
    />
  );
};

const StrikeThroughControl = ({ editor }) => {
  return (
    <Control
      editor={editor}
      label="strikeControlLabel"
      isActive={{
        name: "strike",
      }}
      operation={{
        name: "toggleStrike",
      }}
      icon={IconStrikethrough}
    />
  );
};

const Heading1Control = ({ editor }) => {
  return (
    <Control
      editor={editor}
      label="h1ControlLabel"
      isActive={{
        name: "heading",
        attributes: { level: 1 },
      }}
      operation={{
        name: "toggleHeading",
        attributes: { level: 1 },
      }}
      icon={IconH1}
    />
  );
};

const Heading2Control = ({ editor }) => {
  return (
    <Control
      editor={editor}
      label="h2ControlLabel"
      isActive={{
        name: "heading",
        attributes: { level: 2 },
      }}
      operation={{
        name: "toggleHeading",
        attributes: { level: 2 },
      }}
      icon={IconH2}
    />
  );
};

const Heading3Control = ({ editor }) => {
  return (
    <Control
      editor={editor}
      label="h3ControlLabel"
      isActive={{
        name: "heading",
        attributes: { level: 3 },
      }}
      operation={{
        name: "toggleHeading",
        attributes: { level: 3 },
      }}
      icon={IconH3}
    />
  );
};

const Heading4Control = ({ editor }) => {
  return (
    <Control
      editor={editor}
      label="h4ControlLabel"
      isActive={{
        name: "heading",
        attributes: { level: 4 },
      }}
      operation={{
        name: "toggleHeading",
        attributes: { level: 4 },
      }}
      icon={IconH4}
    />
  );
};

const BulletListControl = ({ editor }) => {
  return (
    <Control
      editor={editor}
      label="bulletListControlLabel"
      isActive={{
        name: "bulletList",
      }}
      operation={{
        name: "toggleBulletList",
      }}
      icon={IconList}
    />
  );
};

const OrderedListControl = ({ editor }) => {
  return (
    <Control
      editor={editor}
      label="orderedListControlLabel"
      isActive={{
        name: "orderedList",
      }}
      operation={{
        name: "toggleOrderedList",
      }}
      icon={IconListNumbers}
    />
  );
};

const AlignLeftControl = ({ editor }) => {
  return (
    <Control
      editor={editor}
      label="alignLeftControlLabel"
      isActive={{
        name: { textAlign: "left" },
      }}
      operation={{
        name: "setTextAlign",
        attributes: "left",
      }}
      icon={IconAlignLeft}
    />
  );
};

const AlignRightControl = ({ editor }) => {
  return (
    <Control
      editor={editor}
      label="alignRightControlLabel"
      isActive={{
        name: { textAlign: "right" },
      }}
      operation={{
        name: "setTextAlign",
        attributes: "right",
      }}
      icon={IconAlignRight}
    />
  );
};

const AlignCenterControl = ({ editor }) => {
  return (
    <Control
      editor={editor}
      label="alignCenterControlLabel"
      isActive={{
        name: { textAlign: "center" },
      }}
      operation={{
        name: "setTextAlign",
        attributes: "center",
      }}
      icon={IconAlignCenter}
    />
  );
};

const AlignJustifiedControl = ({ editor }) => {
  return (
    <Control
      editor={editor}
      label="alignJustifiedControlLabel"
      isActive={{
        name: { textAlign: "justify" },
      }}
      operation={{ name: "setTextAlign", attributes: "justify" }}
      icon={IconAlignJustified}
    />
  );
};

export const Toolbar = styled(({ className, editor }) => {
  return (
    <div className={className}>
      <ControlsGroup>
        <UndoControl editor={editor} />
        <RedoControl editor={editor} />
      </ControlsGroup>
      <ControlsGroup>
        <BoldControl editor={editor} />
        <ItalicControl editor={editor} />
        <UnderlineControl editor={editor} />
        <StrikeThroughControl editor={editor} />
      </ControlsGroup>
      <ControlsGroup>
        <Heading1Control editor={editor} />
        <Heading2Control editor={editor} />
        <Heading3Control editor={editor} />
        <Heading4Control editor={editor} />
      </ControlsGroup>
      <ControlsGroup>
        <BulletListControl editor={editor} />
        <OrderedListControl editor={editor} />
      </ControlsGroup>
      <ControlsGroup>
        <AlignLeftControl editor={editor} />
        <AlignCenterControl editor={editor} />
        <AlignRightControl editor={editor} />
        <AlignJustifiedControl editor={editor} />
      </ControlsGroup>
    </div>
  );
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
  border-radius: 5px 5px 0 0;
  border: 1px solid transparent;
`;

const SavingBadge = styled(({ className }) => {
  return (
    <div className={className}>
      <div className="saving-badge">
        <span>Saving...</span>
      </div>
    </div>
  );
})`
  position: absolute;
  top: 0px;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const RTEditor = styled(
  forwardRef(
    (
      { className, editor, font, showToolbar = true, style, saving = false },
      ref
    ) => {
      return (
        <div className={className}>
          {showToolbar && <Toolbar editor={editor} />}
          {saving && <SavingBadge />}
          <EditorContent
            className="editor-content"
            editor={editor}
            data-font={font || null}
            style={style}
          />
        </div>
      );
    }
  )
)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  justify-content: flex-start;
  align-items: center;

  .editor-content {
    height: ${({ height }) => height || "100%"};
    width: 100%;
    overflow-y: auto;
  }

  .editor-content[data-font="Times New Roman"] {
    font-family: "Times New Roman", Times, serif;
  }

  .editor-content[data-font="Courier New"] {
    font-family: "Courier New", Courier, monospace;
  }

  .editor-content[data-font="Arial"] {
    font-family: Arial, Helvetica, sans-serif;
  }

  .editor-content[data-font="Segoe UI"] {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }

  .editor-content[data-font="Verdana"] {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }

  .editor-content[data-font="Roboto"] {
    font-family: Roboto, sans-serif;
  }

  .ProseMirror {
    font-weight: 400;
    outline: none;
    height: 100%;
    padding: 1rem 4rem;
    border-radius: 8px;
    border: 1px solid transparent;
    white-space: break-spaces;
    word-break: break-word;
    text-rendering: optimizeLegibility;
    h1 {
      font-size: 1.5rem;
      line-height: 2rem;
      margin: 0;
    }
    h2 {
      font-size: 1.25rem;
      line-height: 1.75rem;
      margin: 0;
    }
    h3 {
      font-size: 1.125rem;
      line-height: 1.75rem;
      margin: 0;
    }
    h4 {
      font-size: 1rem;
      line-height: 1.5rem;
      margin: 0;
    }
    p {
      margin: 0;
      font-size: 0.8rem;
      line-height: 1.5rem;
    }
    ul {
      margin: 0;
    }
    ol {
      margin: 0;
    }
    .editor-link {
      color: ${({ theme }) => theme.palette.primary.main};
    }
    .editor-link:hover {
      color: ${({ theme }) => theme.palette.text.primary};
      text-decoration: underline;
      cursor: pointer;
      // Set title attribute
      title: "Click to open link";
    }
    img {
      max-width: 100%;
      max-height: 25rem;
      border: 1px solid ${({ theme }) => theme.palette.divider};
    }
    img.has-focus {
      border: 1px solid ${({ theme }) => theme.palette.primary.main};
    }
  }

  .ProseMirror .is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #888;
    pointer-events: none;
    height: 0;
  }
  .ProseMirror .is-empty::before {
    content: attr(data-placeholder);
    float: left;
    color: #888;
    pointer-events: none;
    height: 0;
  }

  .ProseMirror .monolith-image.uploading {
    opacity: 0.25;
  }

  .ProseMirror .img-placeholder {
    position: relative;
    width: fit-content;

    &:before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      top: calc(50% - 18px);
      left: calc(50% - 18px);
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border: 3px solid ${({ theme }) => theme.palette.primary.main};
      border-top-color: ${({ theme }) => theme.palette.divider};
      animation: spinning 0.6s linear infinite;
    }

    @keyframes spinning {
      to {
        transform: rotate(360deg);
      }
    }
  }

  .floating-menu {
    display: flex;
    background-color: #0d0d0d10;
    padding: 0.2rem;
    border-radius: 0.5rem;

    button {
      border: none;
      outline: none;
      background: none;
      font-size: 0.5rem;
      font-weight: 500;
      padding: 0 0.2rem;
      color: ${({ theme }) => theme.palette.text.primary};

      &:hover,
      &:focus,
      &.is-active {
        opacity: 1;
        color: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }

  ul[data-type="taskList"] {
    list-style: none;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      margin: 0.25rem 0;

      > label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
        user-select: none;
      }

      > div {
        flex: 1 1 auto;
      }
    }

    input[type="checkbox"] {
      cursor: pointer;
      margin: 0;
      -webkit-appearance: none;
      appearance: none;
      border: 2px solid ${({ theme }) => theme.palette.primary.main};
      border-radius: 0.125rem;
      background-color: ${({ theme }) => theme.palette.background.default};
      width: 1.25rem;
      height: 1.25rem;
      display: grid;
      place-content: center;

      &:hover {
        border-color: ${({ theme }) => theme.palette.primary.main};
      }

      &:active {
        background-color: ${({ theme }) => theme.palette.divider};
      }

      &::before {
        content: "";
        width: 0.75em;
        height: 0.75em;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em;
        transform-origin: center;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        color: ${({ theme }) => theme.palette.text.primary};
      }

      &:checked::before {
        transform: scale(1);
      }
    }
  }

  ul[data-type="taskList"] li[data-checked="true"] > div > p {
    color: ${({ theme }) => theme.palette.text.secondary};
    opacity: 0.5;
    text-decoration: line-through;
    text-decoration-thickness: 1px;
  }

  .ProseMirror .note-tag {
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 0.25rem;
    padding: 0.125rem 0.25rem;
    font-size: 0.75rem;
    line-height: 1rem;
    color: ${({ theme }) => theme.palette.text.secondary};
    font-weight: 500;
    margin: 0 0.125rem;
  }
`;

RTEditor.displayName = "RTEditor";

export default RTEditor;
