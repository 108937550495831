import { DateInputFormat, getDateFormat } from "@/utils/date-format";
import { z } from "zod";
import {
  Button,
  DateInput,
  Grid,
  TextAreaInput,
  TextInput,
} from "@monolith-forensics/monolith-ui";
import ButtonMenu from "./components/ButtonMenu";
import { FC } from "react";
import { useForm } from "@mantine/form";
import diffFormData from "./utils/diffFormData";
import validateAsync from "./utils/zod/validateAsync";

import LocationSelector from "./components/Selectors/LocationSelector";
import EquipmentNameSelector from "./components/Selectors/EquipmentNameSelector";
import EquipmentModelSelector from "./components/Selectors/EquipmentModelSelector";
import VendorSelector from "./components/Selectors/VendorSelector";
import { ForensicEquipment } from "@/types";

export interface EquipmentFormData
  extends Omit<ForensicEquipment, "equipment_id" | "asset_number">,
    Partial<Pick<ForensicEquipment, "equipment_id" | "asset_number">> {}

export interface EditEquipmentFormData extends Partial<EquipmentFormData> {}

interface DefaultEquipmentFormProps {
  DefaultFormData?: EquipmentFormData;
  buttonProps?: { cancelText?: string; submitText?: string };
  onSubmit: (data: EquipmentFormData, diffData?: EditEquipmentFormData) => void;
  onCancel: () => void;
}

// FORM VALIDATION
const currencyRegex = /^$|^\d+(\.\d{1,2})?$/;
const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;

const equipmentValidationSchema = z.object({
  asset_number: z.string({
    required_error: "Asset number is required",
  }),
  vendor: z.string().nullable().optional(),
  name: z.string().nullable().optional(),
  model: z.string().nullable().optional(),
  category: z.string().nullable().optional(),
  serial_number: z.string().nullable().optional(),
  purchase_date: z
    .string()
    .regex(
      isoDateRegex,
      `Invalid date format. Please select a date or enter in "YYYY-MM-DD" format.`
    )
    .nullable()
    .optional(),
  cost: z
    .string()
    .regex(currencyRegex, "Invalid - please enter a cost number")
    .nullable()
    .optional(),
  barcode: z
    .string()
    .regex(/^(\d+)?$/, "Barcode must contain only numbers")
    .nullable()
    .optional(),
  location: z.string().nullable().optional(),
  description: z.string().nullable().optional(),
});

const DefaultEquipmentForm: FC<DefaultEquipmentFormProps> = ({
  DefaultFormData,
  buttonProps,
  onSubmit,
  onCancel,
}) => {
  const form = useForm<EquipmentFormData>({
    mode: "uncontrolled",
    initialValues: DefaultFormData,
  });

  const handleSubmit = async () => {
    const formData = form.getValues();

    const validationResult = await validateAsync(equipmentValidationSchema)(
      formData
    );
    if (validationResult?.hasErrors) {
      form.setErrors(validationResult?.errors);
      return;
    }

    const diffData = diffFormData(DefaultFormData, formData);
    onSubmit?.(formData, diffData);
  };

  const handleCancel = () => {
    form.reset();
    onCancel?.();
  };

  return (
    <>
      <Grid col={2}>
        <TextInput
          label="Asset Number"
          placeholder="Enter Asset Number"
          description="Asset number associated with your equipment item"
          required
          key={form.key("asset_number")}
          {...form.getInputProps("asset_number")}
        />
        <VendorSelector
          defaultValue={DefaultFormData?.vendor}
          openOnFocus={true}
          queryKey="equipment"
          key={form.key("vendor")}
          {...form.getInputProps("vendor")}
        />
        <EquipmentNameSelector
          defaultValue={DefaultFormData?.name}
          openOnFocus={true}
          key={form.key("name")}
          {...form.getInputProps("name")}
        />
        <EquipmentModelSelector
          defaultValue={DefaultFormData?.model}
          openOnFocus={true}
          key={form.key("model")}
          {...form.getInputProps("model")}
        />

        <TextInput
          label="Category"
          placeholder="Enter equipment category"
          description="Category of equipment item"
          key={form.key("category")}
          {...form.getInputProps("category")}
        />
        <TextInput
          label="Serial Number"
          placeholder="Enter equipment serial number"
          description="Serial number of equipment item"
          key={form.key("serial_number")}
          {...form.getInputProps("serial_number")}
        />
        <DateInput
          label="Purchase Date"
          variant="outlined"
          description="Enter the date the equipment was purchased"
          clearable={true}
          arrow={true}
          enableCalendar={true}
          format={
            getDateFormat({
              isMoment: true,
              includeTime: false,
            }) as DateInputFormat
          }
          key={form.key("purchase_date")}
          {...form.getInputProps("purchase_date")}
        />
        <TextInput
          label="Cost"
          type="number"
          min="0"
          placeholder="Enter the cost of the equipment"
          description="Cost of the equipment item"
          key={form.key("cost")}
          {...form.getInputProps("cost")}
        />
        <TextInput
          label="Barcode"
          placeholder="Enter equipment barcode number"
          description="Barcode number of equipment item"
          key={form.key("barcode")}
          {...form.getInputProps("barcode")}
        />
        <LocationSelector
          defaultValue={DefaultFormData?.location}
          queryKey="equipment"
          key={form.key("location")}
          {...form.getInputProps("location")}
        />
        <TextAreaInput
          size="sm"
          variant="outlined"
          placeholder="A brief description of the equipment item"
          description="Enter a brief description of the equipment item."
          colSpan={2}
          minRows={6}
          maxRows={24}
          label="Description"
          key={form.key("description")}
          {...form.getInputProps("description")}
        />
      </Grid>
      <ButtonMenu>
        <Button size="xs" variant="subtle" onClick={handleCancel}>
          {buttonProps?.cancelText || "Cancel"}
        </Button>
        <Button
          size="xs"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          {buttonProps?.submitText || "Submit"}
        </Button>
      </ButtonMenu>
    </>
  );
};

export default DefaultEquipmentForm;
