import { createContext, useContext, useEffect, useRef, useState } from "react";
import shortUUID from "short-uuid";
import { db_timestamp, monolithMoment } from "../../../utils/date-format";
import convertToTree from "../utilities/convertToTree.js";
import { NoteView } from "../Enums/NoteView.js";
import useSettings from "../../../hooks/useSettings.js";
import { SETTINGS } from "../../../constants.js";

const createSegments = (notes, options = {}) => {
  //Group notes by object_id
  const grouped =
    notes?.reduce((acc, note) => {
      if (!acc[note.object_id]) acc[note.object_id] = [];
      acc[note.object_id].push(note);
      return acc;
    }, {}) || {};

  // Convert to array
  const segments = Object.keys(grouped).map((key) => {
    const treeResult = convertToTree(grouped[key]);

    return {
      object_id: key,
      object_name: grouped?.[key]?.[0]?.object_name,
      object_type: grouped?.[key]?.[0]?.object_type,
      notes: treeResult?.tree || [],
      noteMap: treeResult?.itemMap || {},
    };
  });

  // sort by object_name, with null as first element
  segments.sort((a, b) => {
    if (!a.object_name) return -1;
    if (!b.object_name) return 1;
    return a.object_name.localeCompare(b.object_name);
  });

  // place root segment at the beginning of the array
  const root = segments.find(
    (segment) => segment.object_id === options.rootSegment?.object_id
  );

  if (root) {
    const index = segments.indexOf(root);
    segments.splice(index, 1);
    segments.unshift(root);
  }

  return segments;
};

const NoteSystemContext = createContext();

const NoteSystemProvider = ({
  children,
  defaultTabs = [],
  notes = [],
  defaultNote = null,
  onTabsChange,
  readOnly = false,
  rootObject = {},
  noteMetadata = {},
  onNoteOrderChange,
  defaultNoteOrder = {
    field: "created_on",
    sort: "asc",
  },
  onNoteViewChange,
  defaultNoteView = NoteView.TREE,
  onNoteCreated,
}) => {
  const [noteOrder, setNoteOrder] = useState(defaultNoteOrder);
  const [noteView, setNoteView] = useState(defaultNoteView);
  const { noteSettings, setNoteSettings } = useSettings();
  const [tabs, setTabs] = useState(defaultTabs);
  const tabsRef = useRef();
  const isInitialRender = useRef(true);
  tabsRef.current = tabs;

  const allNotesRef = useRef();

  const [currentTab, setCurrentTab] = useState(
    defaultTabs.find((tab) => tab.current === true)
  );

  const currentTabRef = useRef();
  currentTabRef.current = currentTab;

  const [currentNote, setCurrentNote] = useState(
    defaultTabs.find((tab) => tab.current === true)?.note
  );
  const currentNoteRef = useRef();
  currentNoteRef.current = currentNote;

  const [fullscreen, setFullscreen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const [showToolbar, setShowToolbar] = useState(
    noteSettings.find((note) => note.setting === SETTINGS.NOTES_TOGGLE_TOOLBAR)
      .value
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteNote, setDeleteNote] = useState(null);

  const noteListRef = useRef();
  const leftMenuRef = useRef();
  const noteListWidthRef = useRef();
  const mouseX = useRef();

  const draggingItemRef = useRef();
  const targetItemRef = useRef();
  const targetSegementRef = useRef();

  const segments = createSegments(notes, { rootSegment: rootObject });

  // if no segments, create a default segment
  if (segments.length === 0) {
    segments.push({
      object_id: rootObject?.object_id || null,
      object_name: rootObject?.object_name || null,
      object_type: rootObject?.object_type || null,
      notes: [],
      noteMap: {},
    });
  }

  // if rendering the cases page notes and there is no cases segment render it
  if (
    rootObject?.object_type === "case" &&
    !segments.find((segment) => segment.object_type === "case")
  ) {
    segments.unshift({
      object_id: rootObject?.object_id || null,
      object_name: rootObject?.object_name || null,
      object_type: rootObject?.object_type || null,
      notes: [],
      noteMap: {},
    });
  }

  const handleCreateNote = (options = {}) => {
    if (readOnly) return; // don't allow creating notes if read-only
    const uuid = shortUUID.generate();
    const createdOn = db_timestamp();
    let parentID;

    if (options?.parent_id !== undefined) parentID = options.parent_id;
    else {
      parentID =
        currentNoteRef?.current?.is_folder === 1
          ? currentNoteRef?.current?.uuid
          : currentNoteRef?.current?.parent_id || null;
    }

    const segment = segments.find(
      (segment) => segment.object_id === options.object_id
    );

    const parentPath = segment?.noteMap?.[parentID]?.pathArray || [];

    const newNoteTitle = `${monolithMoment({
      timestamp: createdOn,
      includeTime: true,
    })}`;

    const newNote = {
      uuid: uuid,
      case_note_id: null,
      parent_id: parentID,
      note_tag: newNoteTitle,
      path: [...parentPath, newNoteTitle].join("\\"),
      created_on: createdOn,
      updated_at: null,
      updated_by: null,
      note_data: "",
      is_folder: 0,
      object_type: options.object_type || rootObject?.object_type || null,
      object_id: options.object_id || rootObject?.object_id || null,
      object_name: options.object_name || rootObject?.object_name || null,
      ...noteMetadata,
      created_by: {
        ...noteMetadata.created_by,
      },
    };

    onNoteCreated?.(newNote);

    const newTab = {
      label: newNote.note_tag,
      note: newNote,
      value: newNote.uuid,
      state: "perm",
      status: "new",
    };

    setCurrentTab(newTab);
    setCurrentNote(newTab.note);
    setTabs((tabs) => {
      return [...tabs, newTab];
    });
  };

  const handleNoteOrderChange = (options = {}) => {
    setNoteOrder(options);
    onNoteOrderChange?.(options);
  };

  const handleNoteViewChange = (newView) => {
    setNoteView(newView);
    onNoteViewChange?.(newView);
  };

  useEffect(() => {
    if (onTabsChange) onTabsChange(tabs);
  }, [tabs]);

  // Update tabs when currentTab changes
  useEffect(() => {
    if (!currentTab) setCurrentTab(tabs[0]);
    setTabs((tabs) => {
      return tabs.map((tab) => {
        return {
          ...tab,
          current: tab.value === currentTab?.value,
        };
      });
    });
  }, [currentTab]);

  // Set Default Note based on props
  useEffect(() => {
    if (defaultNote) {
      let defaultTab = {
        label: defaultNote.note_tag,
        note: defaultNote,
        value: defaultNote.uuid,
        state: "perm",
      };

      if (!tabs.find((tab) => tab.value === defaultNote?.uuid)) {
        setTabs((tabs) => {
          return [defaultTab, ...tabs];
        });
      }
      setCurrentNote(defaultNote);
      setCurrentTab(defaultTab);
    }
  }, [defaultNote]);

  useEffect(() => {
    if (isInitialRender.current) {
      // Skip the effect on initial render
      isInitialRender.current = false;
      return;
    }
    const toggleSetting = noteSettings.find(
      (note) => note.setting === SETTINGS.NOTES_TOGGLE_TOOLBAR
    );
    toggleSetting.value = !toggleSetting.value;
    setNoteSettings(noteSettings);
  }, [showToolbar, setNoteSettings, noteSettings]);

  return (
    <NoteSystemContext.Provider
      value={{
        tabs,
        setTabs,
        setCurrentTab,
        tabsRef,
        allNotesRef,
        segments,
        currentNoteRef,
        setCurrentNote,
        currentTabRef,
        currentNote,
        currentTab,
        fullscreen,
        setFullscreen,
        showSearch,
        setShowSearch,
        showToolbar,
        setShowToolbar,
        showDeleteModal,
        setShowDeleteModal,
        deleteNote,
        setDeleteNote,
        noteListRef,
        leftMenuRef,
        noteListWidthRef,
        mouseX,
        draggingItemRef,
        targetItemRef,
        targetSegementRef,
        handleCreateNote,
        handleNoteOrderChange,
        noteOrder,
        handleNoteViewChange,
        noteView,
      }}
    >
      {children}
    </NoteSystemContext.Provider>
  );
};

const useNoteSystem = () => {
  const context = useContext(NoteSystemContext);
  if (context === undefined) {
    throw new Error("useNoteSystem must be used within a NoteSystemProvider");
  }
  return context;
};

export { NoteSystemProvider, useNoteSystem };
