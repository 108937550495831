import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import TasksAPI from "../../api/tasks/tasks.js";
import { useDebouncedCallback } from "use-debounce";
import Table, { Column } from "../../Monolith-UI/Table/Table.js";
import { useEffect, useState } from "react";

const PageSize = 100;

const TaskTable = ({
  taskQuery = {},
  columnState,
  setColumnState,
  onTasksLoaded,
}) => {
  const queryClient = useQueryClient();
  const [query, setQuery] = useState(taskQuery);

  const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ["tasks", "inf-list", query],
      queryFn: ({ pageParam }) =>
        TasksAPI.getTasks(
          { ...query, pageSize: PageSize, page: pageParam } || {}
        ),
      getNextPageParam: (lastPage, pages) => {
        return lastPage.nextPage;
      },
      getPreviousPageParam: (firstPage, pages) => {
        if (firstPage.page - 1 === 0) return null;
        return firstPage.page - 1;
      },
      initialPageParam: 1,
      enabled: !!query,
      placeholderData: (data) => data,
    });

  const records = data?.pages?.reduce((acc, page) => {
    return [...acc, ...page.data];
  }, []);

  const totalRecords = data?.pages?.[0]?.total || 0;

  const debouncedFetchNextPage = useDebouncedCallback((e) => {
    fetchNextPage();
  }, 50);

  // Detect scroll to bottom
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 100 * data?.pages?.length) {
      if (hasNextPage && !isFetchingNextPage) {
        debouncedFetchNextPage();
      }
    }
  };

  const handleSort = (field) => {
    const savedColumn =
      columnState?.find((svc) => field === svc.dataField) || {};
    const dir = !!savedColumn ? savedColumn?.sorting?.direction : null;

    let newOrder = null;

    // if sorted on a different column, reset the sort
    if (query?.sort?.field !== field) {
      newOrder = {
        field,
        dir: "asc",
      };
    }

    // otherwise rotate the sort options on the current column
    else {
      switch (dir) {
        case "asc":
          newOrder = {
            field,
            dir: "desc",
          };
          break;
        case "desc":
          newOrder = null;
          break;
        default:
          newOrder = {
            field,
            dir: "asc",
          };
      }
    }

    let newCols = columnState?.map((c) => {
      if (c.dataField === field) {
        return {
          ...c,
          sorting: newOrder
            ? {
                active: newOrder ? true : false,
                direction: newOrder?.dir,
              }
            : null,
        };
      }
      delete c.sorting;
      return c;
    });

    let newQuery = {
      ...query,
      sort: newOrder,
    };

    setColumnState(newCols);

    setQuery(newQuery);
  };

  const updateCache = (e) => {
    const { updateData, rowData } = e;

    if (!updateData) return;

    // Update the cache
    queryClient.setQueryData(["tasks", "inf-list", query], (oldData) => {
      let newPages = oldData?.pages?.map((page) => {
        let newPage = { ...page };
        newPage.data = page.data.map((row) => {
          if (row.uuid === rowData.uuid) {
            return {
              ...row,
              ...updateData,
            };
          }
          return row;
        });
        return newPage;
      });
      return {
        ...oldData,
        pages: newPages,
      };
    });
  };

  useEffect(() => {
    if (taskQuery) {
      setQuery(taskQuery);
    }
  }, [taskQuery]);

  useEffect(() => {
    onTasksLoaded?.({
      data: records,
      total: totalRecords,
    });
  }, [totalRecords, records, onTasksLoaded, query]);

  return (
    <Table
      data={records || []}
      totalRecords={totalRecords || 0}
      keyValue="uuid"
      onHeaderClick={(col) =>
        col?.sorting?.enabled === false ? null : handleSort(col.dataField)
      }
      onScroll={handleScroll}
      onRowUpdated={(e) => updateCache(e)}
    >
      {columnState.map((col) => {
        return <Column key={col.dataField} {...col} />;
      })}
    </Table>
  );
};

export default TaskTable;
