import React, { useState } from "react";
import styled from "styled-components";
import { XIcon, ShieldAlert } from "lucide-react";

const ErrorContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.error.main + "15"};
  color: ${({ theme }) => theme.palette.error.light};
  border: 1px solid #d93025;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  align-items: center;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.palette.error.light};
  cursor: pointer;
`;

const ErrorText = styled.span`
  margin-left: 10px;
  flex: 1;
`;

const ErrorComponent = ({
  error,
  onClose,
}: {
  error: string;
  onClose?: () => void;
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
    onClose?.();
  };

  return (
    <>
      {isVisible && (
        <ErrorContainer>
          <ShieldAlert size={19} />
          <ErrorText>{error}</ErrorText>
          <CloseButton onClick={handleClose}>
            <XIcon size={19} />
          </CloseButton>
        </ErrorContainer>
      )}
    </>
  );
};

export default ErrorComponent;
