import styled from "styled-components";
import { Button } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import TimeEntriesAPI from "../../../api/TimeEntries/time-entries.js";
import UserApi from "../../../api/users/users.js";
import TasksAPI from "../../../api/tasks/tasks.js";
import TaskStatuses from "./TaskStatuses.js";
import TaskPriorities from "./TaskPriorities.js";
import Dialog from "../../../Monolith-UI/Dialog/Dialog.js";
import TaskStatusSelector from "./TaskStatusSelector.js";
import TaskPrioritySelector from "./TaskPrioritySelector.js";
import TaskDuedateSelector from "./TaskDueDateSelector.js";
import TaskCategorySelector from "./TaskCategorySelector.js";
import AssigneeSelector from "./AssigneeSelector.js";
import TaskTemplateSelector from "./TaskTemplateSelector.js";
import { ListTodoIcon } from "lucide-react";
import shortUUID from "short-uuid";
import Loader from "../../Loader.js";
import CaseSelector from "./CaseSelector.js";
import { useDebouncedCallback } from "use-debounce";
import { TextAreaInput, TextInput } from "@monolith-forensics/monolith-ui";

const Menu = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.divider};
  margin: 10px 0;
`;

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 200px;
  min-width: 600px;

  // Allow height to increase with content
  height: fit-content;
`;

const StyledButton = styled(Button)`
  padding: 0 10px;
`;

const SubTasksLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${({ theme }) => theme.palette.text.secondary};
  user-select: none;
  cursor: default;

  &:hover {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

const AddTaskModal = ({
  open = false,
  onClose,
  defaultFormData = {},
  onSubmit,
}) => {
  const [formData, setFormData] = useState(defaultFormData);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedCase, setSelectedCase] = useState({
    case_id: defaultFormData?.case_id,
    case_number: defaultFormData?.case_number,
  });
  const taskNameRef = useRef(null);
  const descriptionRef = useRef(null);

  const { data: TaskCategories } = useQuery({
    queryKey: ["task:categories"],
    queryFn: () => TimeEntriesAPI.getTimeCategories(),
  });

  const { data: users, isFetched } = useQuery({
    queryKey: [
      "users",
      "list",
      {
        case_id: formData?.case_id,
        include_inactive: false,
        include_observers: false,
      },
    ],
    queryFn: () =>
      UserApi.getUsers({
        case_id: formData?.case_id,
        include_inactive: false,
        include_observers: false,
      }),
    enabled: open,
  });

  const handleTasknameChange = useDebouncedCallback((e) => {
    setErrors((prev) => ({
      ...prev,
      task_name: null,
    }));
    setFormData((prev) => ({
      ...prev,
      task_name: e.target.value,
    }));
  }, 350);

  const handleDescriptionChange = useDebouncedCallback((e) => {
    setFormData((prev) => ({
      ...prev,
      description: e.target.value,
    }));
  }, 350);

  const handleCaseChange = (caseRecord) => {
    setSelectedCase(caseRecord);
    setFormData((prev) => ({
      ...prev,
      case_id: caseRecord.case_id,
      case_uuid: caseRecord.uuid,
      object_id: caseRecord.uuid,
      object_type: "case",
      object_name: caseRecord.case_number,
    }));
    setErrors((prev) => ({
      ...prev,
      case: null,
    }));
  };

  const handleCategoryChange = (item) => {
    setFormData((prev) => ({
      ...prev,
      time_category_id: item.category_id,
    }));
  };

  const handleStatusChange = (item) => {
    setFormData((prev) => ({
      ...prev,
      status_id: item.status_id,
    }));
  };

  const handlePriorityChange = (item) => {
    setFormData((prev) => ({
      ...prev,
      priority_id: item.priority_id,
    }));
  };

  const handleDueDateChange = (item) => {
    setFormData((prev) => ({
      ...prev,
      due_date: item?.value || null,
    }));
  };

  const handleAssigneeChange = (item) => {
    setFormData((prev) => ({
      ...prev,
      assignees: item.selections.map((selection) => selection.value),
    }));
  };

  const handleTemplateChange = (item) => {
    const newTaskName = item?.task_data?.task_name || item.template_name;
    const newDescription = item?.task_data?.description || null;
    const newPriority = item?.task_data?.priority_id || null;
    const newCategory = item?.task_data?.time_category_id || null;

    taskNameRef.current.value = newTaskName;
    descriptionRef.current.value = newDescription;

    setErrors((prev) => ({
      ...prev,
      task_name: null,
    }));

    setSelectedTemplate(item);
    setFormData((prev) => ({
      ...prev,
      task_name: newTaskName,
      sub_tasks: item?.sub_tasks,
      description: newDescription,
      priority_id: newPriority,
      time_category_id: newCategory,
    }));
  };

  const validateForm = () => {
    const errors = {};
    if (!formData?.case_id) errors.case = "Case is required";
    if (!formData.task_name) {
      errors.task_name = "Task name is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    setSubmitting(true);

    const task_id = Math.random() * (1e13 - 1e12) + 1e12;

    if (formData?.sub_tasks) {
      formData.sub_tasks = formData.sub_tasks.map((subtask, i) => {
        return {
          ...subtask,
          task_id: Math.random() * (1e13 - 1e12) + 1e12,
          uuid: shortUUID.generate(),
          status_id: formData.status_id || 4,
          priority_id: subtask.priority_id || 2,
          sort_value: Math.random() * 10000000,
          parent_id: formData.uuid,
          object_id: formData.object_id,
          object_type: formData.object_type,
          case_id: formData.case_id,
          case_uuid: formData.case_uuid,
        };
      });
    }

    formData.task_id = task_id;
    formData.status_id = formData.status_id || 4;
    formData.priority_id = formData.priority_id || 2;

    if (formData.sub_tasks && formData.sub_tasks.length > 0) {
      await TasksAPI.createTask(formData);
    } else {
      // We don't need to wait on the API call to finish, this is handled optimistically
      TasksAPI.createTask(formData);
    }

    onSubmit?.(formData);

    onClose?.();
    setSubmitting(false);
  };

  const currentStatus = TaskStatuses.find(
    (status) => status.status_id === formData?.status_id
  );

  const currentPriority = TaskPriorities.find(
    (priority) => priority.priority_id === formData?.priority_id
  );

  const currentDueDate = formData?.due_date
    ? moment(formData?.due_date).format("YYYY-MM-DD")
    : null;

  const currentCategory = TaskCategories?.find(
    (category) => category.category_id === formData?.time_category_id
  );

  useEffect(() => {
    setFormData(defaultFormData);
  }, [defaultFormData]);

  useEffect(() => {
    if (!open) {
      setSelectedTemplate(null);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        {submitting && <Loader message="Creating Task..." />}
        {!submitting && (
          <>
            <TextInput
              ref={taskNameRef}
              placeholder={formData?.parent_id ? "Subtask name" : "Task name"}
              autoComplete="off"
              onChange={handleTasknameChange}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  descriptionRef.current.focus();
                }
              }}
              size="lg"
              variant="text"
              style={{
                padding: 8,
                borderBottomColor: errors.task_name ? "red" : "",
              }}
              error={errors.task_name}
            />
            <TextAreaInput
              ref={descriptionRef}
              placeholder={
                formData?.parent_id
                  ? "Enter subtask description..."
                  : "Enter task description..."
              }
              autoComplete="off"
              onChange={handleDescriptionChange}
              minRows={6}
              maxRows={12}
              size="sm"
              variant="text"
            />
            <div style={{ marginTop: "auto" }}>
              <Menu>
                {!defaultFormData.case_id && (
                  <CaseSelector
                    value={selectedCase}
                    error={errors?.case}
                    showLabel={true}
                    variant="contained"
                    onSelect={handleCaseChange}
                    disabled={!!defaultFormData?.case_id}
                  />
                )}
                <TaskStatusSelector
                  value={currentStatus}
                  onSelect={handleStatusChange}
                />
                <TaskPrioritySelector
                  value={currentPriority}
                  onSelect={handlePriorityChange}
                />
                <TaskDuedateSelector
                  value={currentDueDate}
                  onSelect={handleDueDateChange}
                />
                <TaskCategorySelector
                  value={currentCategory}
                  showLabel={true}
                  variant="contained"
                  onSelect={handleCategoryChange}
                />
                <AssigneeSelector
                  value={formData?.assignees || []}
                  showLabel={true}
                  variant="contained"
                  onSelect={handleAssigneeChange}
                  userQuery={{
                    case_id: formData?.case_id,
                    include_inactive: false,
                    include_observers: false,
                  }}
                />
                {/* // Dont show template selector if this is a subtask being created */}
                {!formData.parent_id && (
                  <TaskTemplateSelector
                    value={selectedTemplate}
                    onSelect={handleTemplateChange}
                  />
                )}
                {formData?.sub_tasks && (
                  <SubTasksLabel
                    title={`${formData?.sub_tasks?.length} Sub Tasks`}
                  >
                    <ListTodoIcon size={14} />
                    <span>{formData?.sub_tasks?.length}</span>
                  </SubTasksLabel>
                )}
              </Menu>
              <Divider />
              <Menu topBorder style={{ justifyContent: "flex-end", gap: 10 }}>
                <StyledButton
                  variant="text"
                  color="primary"
                  onClick={() => onClose()}
                >
                  Cancel
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </StyledButton>
              </Menu>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AddTaskModal;
