import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import {
  CalendarIcon,
  SquareStackIcon,
  UsersIcon,
  ShapesIcon,
  ArrowLeftRightIcon,
  SignalIcon,
  X,
} from "lucide-react";
import { Operators } from "../../../Monolith-UI/QueryFilter/QueryFilter.js";
import TimeEntriesAPI from "../../../api/TimeEntries/time-entries.js";
import UserApi from "../../../api/users/users.js";
import TaskStatuses from "./TaskStatuses.js";
import TaskPriorities from "./TaskPriorities.js";
import getRandomColor from "../../../utils/getRandomColor.js";
import { Avatar, UserList } from "./AssigneeSelector.js";
import TaskDueDateOptions from "./TaskDueDateOptions.js";
import DropdownMenu from "../../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import moment from "moment";

export const isDateInRange = (date, range) => {
  const parsedDate = !!date ? moment(date) : null;

  switch (range) {
    case "overdue":
      return !!parsedDate && parsedDate.isBefore(moment(), "date");
    case "today":
      return !!parsedDate && parsedDate.isSame(moment(), "date");
    case "tomorrow":
      return (
        !!parsedDate &&
        parsedDate.isAfter(moment().endOf("day")) &&
        parsedDate.isBefore(moment().endOf("day").add(1, "day"))
      );
    case "end_of_week":
      return (
        !!parsedDate &&
        parsedDate.isAfter(moment().endOf("day").add(1, "day")) &&
        parsedDate.isBefore(moment().endOf("week"))
      );
    case "next_week":
      return (
        !!parsedDate &&
        parsedDate.isAfter(moment().endOf("week")) &&
        parsedDate.isBefore(moment().endOf("week").add(1, "week"))
      );
    case "two_weeks":
      return (
        !!parsedDate &&
        parsedDate.isAfter(moment().endOf("week").add(1, "week")) &&
        parsedDate.isBefore(moment().endOf("week").add(2, "week"))
      );
    case "three_weeks":
      return (
        !!parsedDate &&
        parsedDate.isAfter(moment().endOf("week").add(2, "week")) &&
        parsedDate.isBefore(moment().endOf("week").add(3, "week"))
      );
    case "this_month":
      return (
        !!parsedDate &&
        parsedDate.isAfter(moment().endOf("week").add(3, "week")) &&
        parsedDate.isBefore(moment().endOf("month"))
      );
    case "end_of_quarter":
      return (
        !!parsedDate &&
        parsedDate.isAfter(moment().endOf("month")) &&
        parsedDate.isBefore(moment().endOf("quarter"))
      );
    case "due_later":
      return !!parsedDate && parsedDate.isAfter(moment().endOf("quarter"));
    case "no_due_date":
      return parsedDate === null;
    default:
      return false;
  }
};

const FilterItem = styled(
  ({ className, condition, onUpdateFilter, caseInfo, onRemoveFilter }) => {
    const op = Operators.find((op) => op.operator === condition.operator)?.name;

    const { data: TaskCategories } = useQuery({
      queryKey: ["task:categories"],
      queryFn: () => TimeEntriesAPI.getTimeCategories(),
      initialData: [],
    });

    const { data: caseUsers } = useQuery({
      queryKey: [
        "users",
        "list",
        {
          case_id: caseInfo?.case_id,
          include_inactive: false,
          include_observers: false,
        },
      ],
      queryFn: () =>
        UserApi.getUsers({
          case_id: caseInfo?.case_id,
          include_inactive: false,
          include_observers: false,
        }),
      placeholderData: (data) => data,
      initialData: [],
    });

    const menuItems = {
      status: [
        {
          checkboxGroup: true,
          value: condition.value || [],
          onSelectionChanged: (data) => {
            onUpdateFilter({
              field: "status",
              value: data.map((d) => d.value),
              label: "Status",
              operator: "isAnyOf",
            });
          },
          items: TaskStatuses.map((status) => ({
            label: status.status_name,
            value: status.status_id,
            icon: status.icon,
            iconColor: status.color,
          })),
        },
      ],
      priority: [
        {
          checkboxGroup: true,
          value: condition.value || [],
          onSelectionChanged: (data) => {
            onUpdateFilter({
              field: "priority",
              value: data.map((d) => d.value),
              label: "Priority",
              operator: "isAnyOf",
            });
          },
          items: TaskPriorities.map((priority) => ({
            label: priority.priority_name,
            value: priority.priority_id,
            icon: priority.icon,
            iconColor: priority.color,
          })),
        },
      ],
      category: [
        {
          checkboxGroup: true,
          value: condition.value || [],
          onSelectionChanged: (data) => {
            onUpdateFilter({
              field: "category",
              value: data.map((d) => d.value),
              label: "Category",
              operator: "isAnyOf",
            });
          },
          items:
            TaskCategories?.map((cat) => ({
              label: cat.category_name,
              value: cat.category_id,
              icon: ({ color, style }) => (
                <div
                  style={{
                    borderRadius: "50%",
                    height: 10,
                    width: 10,
                    backgroundColor: color,
                    ...style,
                  }}
                ></div>
              ),
              iconColor: getRandomColor(cat.category_id, cat.category_id),
            })) || [],
        },
      ],
      assignees: [
        {
          checkboxGroup: true,
          value: condition.value || [],
          onSelectionChanged: (data) => {
            onUpdateFilter({
              field: "assignees",
              value: data.map((d) => d.value),
              label: "Assignees",
              operator: "isAnyOf",
            });
          },
          items:
            caseUsers?.map((assignedUser) => ({
              label: assignedUser?.full_name || "User",
              value: assignedUser.user_id,
              icon: () => (
                <Avatar user={assignedUser} style={{ marginRight: 5 }} />
              ),
            })) || [],
        },
      ],
      has_subtasks: [
        {
          radioGroup: true,
          value: condition.value || [],
          onSelectionChanged: (data) => {
            onUpdateFilter({
              field: "has_subtasks",
              value: data?.value,
              label: "Has Subtasks",
              operator: "equals",
            });
          },
          items: [
            {
              label: "True",
              value: "true",
            },
            {
              label: "False",
              value: "false",
            },
          ],
        },
      ],
      due_date: [
        {
          radioGroup: true,
          value: condition.value || [],
          onSelectionChanged: (data) => {
            onUpdateFilter({
              field: "due_date",
              value: data?.value,
              label: "Due Date",
              operator: "equals",
            });
          },
          items: TaskDueDateOptions,
        },
      ],
    };

    return (
      <div className={className + " filter-item"}>
        <div className="filter-segment label">{condition.label}</div>
        <div className="filter-segment op">{op}</div>
        {condition.field === "status" && (
          <DropdownMenu
            menuItems={menuItems.status}
            title={"Select Task Status"}
            variant={"contained"}
            textColor={!!condition.value ? "primary" : "secondary"}
          >
            {condition.value.length === 1 ? (
              condition.value?.map((statusId) => {
                const status = TaskStatuses.find(
                  (status) => status.status_id === statusId
                );
                return <div key={statusId}>{status?.status_name}</div>;
              })
            ) : (
              <div>{condition.value.length} Statuses</div>
            )}
          </DropdownMenu>
        )}
        {condition.field === "priority" && (
          <DropdownMenu
            menuItems={menuItems.priority}
            title={"Select Task Priority"}
            variant={"contained"}
            textColor={!!condition.value ? "primary" : "secondary"}
          >
            {condition.value.length === 1 ? (
              condition.value?.map((priorityId) => {
                const priority = TaskPriorities.find(
                  (priority) => priority.priority_id === priorityId
                );
                return <div key={priorityId}>{priority?.priority_name}</div>;
              })
            ) : (
              <div>{condition.value.length} Priorities</div>
            )}
          </DropdownMenu>
        )}
        {condition.field === "category" && (
          <DropdownMenu
            menuItems={menuItems.category}
            title={"Select Task Category"}
            variant={"contained"}
            textColor={!!condition.value ? "primary" : "secondary"}
          >
            {condition.value.length === 1 ? (
              condition.value?.map((categoryId) => {
                const category = TaskCategories.find(
                  (category) => category.category_id === categoryId
                );
                return <div key={categoryId}>{category?.category_name}</div>;
              })
            ) : (
              <div>{condition.value.length} Categories</div>
            )}
          </DropdownMenu>
        )}
        {condition.field === "assignees" && (
          <DropdownMenu
            menuItems={menuItems.assignees}
            title={"Select Task Assignees"}
            variant={"contained"}
            textColor={!!condition.value ? "primary" : "secondary"}
          >
            <UserList
              users={
                caseUsers?.filter((u) =>
                  condition?.value?.includes(u.user_id)
                ) || []
              }
            />
          </DropdownMenu>
        )}
        {condition.field === "has_subtasks" && (
          <DropdownMenu
            menuItems={menuItems.has_subtasks}
            title={"Select Subtask Status"}
            variant={"contained"}
            textColor={!!condition.value ? "primary" : "secondary"}
          >
            {
              <div style={{ textTransform: "capitalize" }}>
                {condition.value}
              </div>
            }
          </DropdownMenu>
        )}
        {condition.field === "due_date" && (
          <DropdownMenu
            menuItems={menuItems.due_date}
            title={"Select Due Date"}
            variant={"contained"}
            textColor={!!condition.value ? "primary" : "secondary"}
          >
            {
              <div style={{ textTransform: "capitalize" }}>
                {
                  TaskDueDateOptions.find(
                    (opt) => opt.value === condition.value
                  )?.label
                }
              </div>
            }
          </DropdownMenu>
        )}
        <div
          className="filter-segment close"
          onClick={() => onRemoveFilter?.(condition.field)}
        >
          <X size={14} />
        </div>
      </div>
    );
  }
)`
  user-select: none;
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;

  .filter-segment {
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 12px;

    background-color: ${({ theme }) => theme.palette.background.secondary};

    &.close {
      display: flex;
      align-items: center;
      padding: 2px;
      cursor: pointer;
      background-color: transparent;
      border: ${({ theme }) => `1px solid ${theme.palette.divider}`};
      color: ${({ theme }) => theme.palette.text.secondary};

      transition: all 0.1s ease-in-out;

      &:hover {
        background-color: ${({ theme }) => theme.palette.background.secondary};
        color: ${({ theme }) => theme.palette.text.primary};
      }
    }
  }
`;

export const FilterList = styled(
  ({ className, filter, onUpdateFilter, caseInfo, onRemoveFilter }) => {
    if (!filter) return null;
    if (filter.length === 0) return null;

    return (
      <div className={className}>
        {filter?.map?.((condition, index) => {
          return (
            <FilterItem
              key={index}
              condition={condition}
              onUpdateFilter={onUpdateFilter}
              caseInfo={caseInfo}
              onRemoveFilter={onRemoveFilter}
            />
          );
        })}
      </div>
    );
  }
)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  margin: 5px 0;
`;

export const TaskFilter = ({
  filter,
  onUpdateFilter,
  caseInfo,
  variant = "outlined",
}) => {
  const { data: TaskCategories } = useQuery({
    queryKey: ["task:categories"],
    queryFn: () => TimeEntriesAPI.getTimeCategories(),
  });

  const { data: caseUsers } = useQuery({
    queryKey: [
      "users",
      "list",
      {
        case_id: caseInfo?.case_id,
        include_inactive: false,
        include_observers: false,
      },
    ],
    queryFn: () =>
      UserApi.getUsers({
        case_id: caseInfo?.case_id,
        include_inactive: false,
        include_observers: false,
      }),
    placeholderData: (data) => data,
  });

  const handleUpdateFilter = (filterUpdates) => {
    onUpdateFilter?.(filterUpdates);
  };

  const menuItems = [
    {
      label: "Status",
      value: "status",
      icon: ArrowLeftRightIcon,
      items: [
        {
          checkboxGroup: true,
          value: filter?.find((f) => f.field === "status")?.value || [],
          onSelectionChanged: (data) => {
            handleUpdateFilter({
              field: "status",
              value: data.map((d) => d.value),
              label: "Status",
              operator: "isAnyOf",
            });
          },
          items: TaskStatuses.map((status) => ({
            label: status.status_name,
            value: status.status_id,
            icon: status.icon,
            iconColor: status.color,
          })),
        },
      ],
    },
    {
      label: "Priority",
      value: "priority",
      icon: SignalIcon,
      items: [
        {
          checkboxGroup: true,
          value: filter?.find((f) => f.field === "priority")?.value || [],
          onSelectionChanged: (data) => {
            handleUpdateFilter({
              field: "priority",
              value: data.map((d) => d.value),
              label: "Priority",
              operator: "isAnyOf",
            });
          },
          items: TaskPriorities.map((priority) => ({
            label: priority.priority_name,
            value: priority.priority_id,
            icon: priority.icon,
            iconColor: priority.color,
          })),
        },
      ],
    },
    {
      label: "Category",
      value: "category",
      icon: ShapesIcon,
      items: [
        {
          checkboxGroup: true,
          value: filter?.find((f) => f.field === "category")?.value || [],
          onSelectionChanged: (data) => {
            handleUpdateFilter({
              field: "category",
              value: data.map((d) => d.value),
              label: "Category",
              operator: "isAnyOf",
            });
          },
          items:
            TaskCategories?.map((cat) => ({
              label: cat.category_name,
              value: cat.category_id,
              icon: ({ color, style }) => (
                <div
                  style={{
                    borderRadius: "50%",
                    height: 10,
                    width: 10,
                    backgroundColor: color,
                    ...style,
                  }}
                ></div>
              ),
              iconColor: getRandomColor(cat.category_id, cat.category_id),
              onClick: handleUpdateFilter,
            })) || [],
        },
      ],
    },
    {
      label: "Assignees",
      value: "assignees",
      icon: UsersIcon,
      items: [
        {
          checkboxGroup: true,
          value: filter?.find((f) => f.field === "assignees")?.value || [],
          onSelectionChanged: (data) => {
            handleUpdateFilter({
              field: "assignees",
              value: data.map((d) => d.value),
              label: "Assignees",
              operator: "isAnyOf",
            });
          },
          items:
            caseUsers?.map((assignedUser) => ({
              label: assignedUser?.full_name || "User",
              value: assignedUser.user_id,
              icon: () => (
                <Avatar user={assignedUser} style={{ marginRight: 5 }} />
              ),
              onClick: handleUpdateFilter,
            })) || [],
        },
      ],
    },
    {
      label: "Has Subtasks",
      value: "has_subtasks",
      icon: SquareStackIcon,
      items: [
        {
          radioGroup: true,
          value: filter?.find((f) => f.field === "has_subtasks")?.value || [],
          onSelectionChanged: (data) => {
            handleUpdateFilter({
              field: "has_subtasks",
              value: data?.value,
              label: "Has Subtasks",
              operator: "equals",
            });
          },
          items: [
            {
              label: "True",
              value: "true",
            },
            {
              label: "False",
              value: "false",
            },
          ],
        },
      ],
    },
    {
      label: "Due Date",
      value: "due_date",
      icon: CalendarIcon,
      items: [
        {
          radioGroup: true,
          value: filter?.find((f) => f.field === "due_date")?.value || [],
          onSelectionChanged: (data) => {
            handleUpdateFilter({
              field: "due_date",
              value: data?.value,
              label: "Due Date",
              operator: "equals",
            });
          },
          items: TaskDueDateOptions,
        },
      ],
    },
  ];

  return (
    <DropdownMenu
      menuItems={menuItems}
      title={"Filter Tasks"}
      variant={variant}
      arrow
    >
      Filter
    </DropdownMenu>
  );
};

export default TaskFilter;
