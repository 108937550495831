import styled from "styled-components";

const NoteEditorContainer = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.palette.background.default};
  flex-grow: 1;
  min-width: 50rem;
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${(props) => props.theme.palette.divider};
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  border-top: 1px solid transparent;
`;

export default NoteEditorContainer;
