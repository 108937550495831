import { FC } from "react";

import { FormTitle, FormWrapper } from "./components/MonolithFormComponents";
import { useSnackbar, VariantType } from "notistack";
import LoggingAPI from "@/api/logging";
import { usePermissions } from "@/hooks/usePermissions";
import DefaultClientsForm, { ClientFormData } from "./DefaultClientForm";
import { createClient } from "@/api/clients";

interface CreateClientFormProps {
  width?: number | undefined;
  onSubmit: () => void; // Refetch query from parent component
  onCancel: () => void;
}

const CreateClientForm: FC<CreateClientFormProps> = ({
  width,
  onSubmit,
  onCancel,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = usePermissions();

  const handleSubmit = async (data: ClientFormData) => {
    try {
      await createClient(data);
      onSubmit?.();
      enqueueSnackbar(`Client ${data?.name || ""} created`, {
        variant: "success" as VariantType,
      });

      LoggingAPI.createLog({
        user_id: currentUser.user_id,
        message: `Created Client: ${data?.name}`,
        event: "client:create",
        object_type: "client",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        "An error occurred while creating this item. Please try again.",
        { variant: "error" as VariantType }
      );
    }
  };

  return (
    <FormWrapper width={width}>
      <FormTitle>Create Client</FormTitle>
      <DefaultClientsForm
        onSubmit={handleSubmit}
        onCancel={onCancel}
        buttonProps={{ submitText: "Create Client" }}
      />
    </FormWrapper>
  );
};

export default CreateClientForm;
