import { useState } from "react";
import { useSnackbar } from "notistack";
import { Button, Modal } from "@monolith-forensics/monolith-ui";
import ButtonMenu from "../Forms/components/ButtonMenu.js";

import LoggingAPI from "../../api/logging/index.js";
import ContactsAPI from "@/api/contacts/index.js";
import { usePermissions } from "@/hooks/usePermissions";
import { Contact } from "@/types/index.js";

interface UnassignContactProps {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onCancel: () => void;
  buttonProps?: {
    cancelText: string;
    submitText: string;
  };
  defaultInfo: Contact;
  caseId?: number;
}

const UnassignContactModal: React.FC<UnassignContactProps> = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  buttonProps,
  defaultInfo,
  caseId,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      await ContactsAPI.unlinkCaseContact({
        client_id: defaultInfo.client_id,
        case_id: caseId,
      });
      enqueueSnackbar(`Contact Unassigned.`, {
        variant: "success",
      });

      LoggingAPI.createLog({
        user_id: currentUser.user_id,
        message: `Unassigned ${defaultInfo?.name} from case`,
        event: "contact:unassign",
        object_type: "contact",
        object_uuid: defaultInfo?.uuid,
      });
    } catch (error) {
      console.error("Error unassigning contact:", error);
      enqueueSnackbar(`Error unassigning contact.`, {
        variant: "error",
      });
    } finally {
      setSubmitting(false);
      onSubmit?.();
      onClose?.();
    }
  };

  const handleCancel = () => {
    onCancel?.();
  };

  return (
    <Modal open={show} onClose={onClose}>
      <Modal.Title>Unassign Contact</Modal.Title>
      <div style={{ fontWeight: 500, margin: "20px 0px" }}>
        <span style={{ fontWeight: 900 }}>{defaultInfo?.name}</span> will no
        longer be associated with this case.
      </div>
      <ButtonMenu>
        <Button
          size="xs"
          variant="subtle"
          onClick={handleCancel}
          disabled={submitting}
        >
          {buttonProps?.cancelText || "Cancel"}
        </Button>
        <Button
          size="xs"
          color="orange"
          variant="contained"
          onClick={handleSubmit}
          disabled={submitting}
          loading={submitting}
        >
          {buttonProps?.submitText || "Unassign Contact"}
        </Button>
      </ButtonMenu>
    </Modal>
  );
};

export default UnassignContactModal;
