import styled, { useTheme } from "styled-components";
import {
  Form,
  SimpleItem,
  EmptyItem,
  GroupItem,
} from "devextreme-react/ui/form";
import { useRef } from "react";
import ToolBarItems from "../../../../components/ToolBarItems";
import { useQuery } from "@tanstack/react-query";
import { getContacts, getEvidence, getStorage, userApi } from "../../../../api";
import { useNavigate } from "react-router-dom";
import DataSource from "devextreme/data/data_source";
import { useEffect } from "react";
import { Template } from "devextreme-react/core/template";
import EvidenceSelectBoxItem from "../../../../components/SelectBoxItems/EvidenceSelectBoxItem.js";
import StorageSelectBoxItem from "../../../../components/SelectBoxItems/StorageSelectBoxItem.js";
import UserSelectBoxItem from "../../../../components/SelectBoxItems/UserSelectBoxItem.js";

const AcquisitionImportForm = styled(
  ({
    className,
    caseInfo,
    evidenceInfo,
    columnNames = [],
    onSubmit = () => {},
  }) => {
    const form = useRef(null);
    const theme = useTheme();
    const navigate = useNavigate();

    const { data: evidenceItems } = useQuery({
      queryKey: ["evidence:list", { case_id: caseInfo.case_id }],
      queryFn: () => getEvidence({ case_id: caseInfo.case_id }),
      initialData: [],
      refetchOnWindowFocus: false,
    });

    const { data: storageItems } = useQuery({
      queryKey: [
        "storage",
        {
          case_id: caseInfo ? caseInfo.case_id : evidenceInfo.case_id,
          active_only: true,
          include_general_storage: true,
        },
      ],
      queryFn: () =>
        getStorage({
          case_id: caseInfo ? caseInfo.case_id : evidenceInfo.case_id,
          active_only: true,
          include_general_storage: true,
        }),
      initialData: [],
      refetchOnWindowFocus: false,
    });

    const { data: users } = useQuery({
      queryKey: ["users"],
      queryFn: () =>
        userApi.getUsers({ includeObservers: false, includeInactive: false }),
      initialData: [],
    });

    const handleSubmit = () => {
      if (form.current.instance.validate().isValid) {
        const formData = Object.assign(
          {},
          form.current.instance.option("formData")
        );

        // Convert to array
        if (formData.storage_items) {
          formData.storage_items = [formData.storage_items];
        }

        onSubmit(formData);
        navigate(`/cases/${caseInfo.case_id}/acquisitions?v=import&s=validate`);
      }
    };

    const handleCancel = () => {
      navigate(`/cases/${caseInfo.case_id}/acquisitions?v=import`);
    };

    useEffect(() => {
      if (!columnNames)
        navigate(`/cases/${caseInfo.case_id}/acquisitions?v=import`);
    }, []);

    if (!columnNames) return null;

    return (
      <div className={className}>
        <Title style={{ marginBottom: 50 }}>
          Map Columns to Acquisition Fields
        </Title>
        <Form ref={form} colCount={2}>
          <SimpleItem
            dataField="evidence_id"
            label={{ text: "Evidence" }}
            isRequired={true}
            editorType="dxSelectBox"
            editorOptions={{
              dataSource: evidenceItems,
              dropDownOptions: {
                maxHeight: 300,
              },
              hint: "Select an evidence item to acquire.",
              noDataText: "No evidence items are in this case.",
              searchEnabled: true,
              valueExpr: "evidence_id",
              displayExpr: "evidence_number",
              placeholder: "Select...",
              itemTemplate: "evidence_id",
            }}
          />
          <Template
            name="evidence_id"
            render={(data) => <EvidenceSelectBoxItem data={data} />}
          />
          <EmptyItem />
          <SimpleItem
            dataField="acq_name"
            label={{ text: "Acquisition Name" }}
            isRequired={true}
            editorType="dxSelectBox"
            editorOptions={{
              dataSource: columnNames,
              showClearButton: true,
              dropDownOptions: {
                maxHeight: 300,
              },
              value: columnNames.find(
                (c) =>
                  c.toLowerCase() === "name" ||
                  c.toLowerCase() === "acquisition name"
              ),
              placeholder: "Select Column...",
            }}
          />
          <GroupItem colCount={2}>
            <SimpleItem
              dataField="format"
              label={{ text: "Format" }}
              editorType="dxSelectBox"
              editorOptions={{
                items: columnNames,
                showClearButton: true,
                placeholder: "Select Column...",
                dropDownOptions: {
                  maxHeight: 300,
                },
                value: columnNames.find((c) => c.toLowerCase() === "format"),
              }}
            />
            <SimpleItem
              dataField="acq_type"
              label={{ text: "Type" }}
              editorType="dxSelectBox"
              editorOptions={{
                items: columnNames,
                showClearButton: true,
                dropDownOptions: {
                  maxHeight: 300,
                },
                placeholder: "Select Column...",
                value: columnNames.find((c) => c.toLowerCase() === "type"),
              }}
            />
          </GroupItem>
          <SimpleItem
            dataField="acq_tool"
            label={{ text: "Forensic Software" }}
            editorType="dxSelectBox"
            editorOptions={{
              dataSource: columnNames,
              placeholder: "Select Column...",
              showClearButton: true,
              dropDownOptions: {
                maxHeight: 300,
              },
              value: columnNames.find(
                (c) => c.toLowerCase() === "forensic software"
              ),
            }}
          />
          <SimpleItem
            dataField="tool_version"
            label={{ text: "Software Version" }}
            editorType="dxSelectBox"
            editorOptions={{
              dataSource: columnNames,
              placeholder: "Select Column...",
              showClearButton: true,
              dropDownOptions: {
                maxHeight: 300,
              },
              value: columnNames.find(
                (c) => c.toLowerCase() === "software version"
              ),
            }}
          />
          <SimpleItem
            dataField="storage_items"
            label={{ text: "Storage Item" }}
            editorType="dxSelectBox"
            editorOptions={{
              dataSource: new DataSource({
                store: storageItems,
                paginate: true,
                pageSize: 10,
                key: "storage_id",
                group: "is_general_storage",
                sort: [
                  "Position",
                  { selector: "is_general_storage", desc: true },
                ],
              }),
              showClearButton: true,
              dropDownOptions: {
                maxHeight: 300,
              },
              placeholder: "Select Storage Item...",
              hint: "Select storage items that this acquisition is stored on.",
              noDataText:
                "Assign storage to the case to choose a storage item.",
              searchEnabled: true,
              displayExpr: "storage_number",
              valueExpr: "storage_id",
              showDropDownButton: true,
              grouped: true,
              groupTemplate: (data) => {
                return `<div>${
                  data.key === 1 ? "General Storage" : "Assigned Storage"
                }</div>`;
              },
              itemTemplate: "storage_id",
            }}
          />
          <Template
            name="storage_id"
            render={(data) => <StorageSelectBoxItem data={data} />}
          />
          <GroupItem colCount={2}>
            <SimpleItem
              dataField="size"
              label={{ text: "Size" }}
              editorType="dxSelectBox"
              editorOptions={{
                items: columnNames,
                showClearButton: true,
                dropDownOptions: {
                  maxHeight: 300,
                },
                placeholder: "Select Column...",
                value: columnNames.find((c) => c.toLowerCase() === "size"),
              }}
            />
            <SimpleItem
              dataField="size_unit"
              label={{ text: "Unit" }}
              editorType="dxSelectBox"
              editorOptions={{
                items: columnNames,
                showClearButton: true,
                dropDownOptions: {
                  maxHeight: 300,
                },
                placeholder: "Select Column...",
                value: columnNames.find((c) => c.toLowerCase() === "unit"),
              }}
            />
          </GroupItem>
          <SimpleItem
            dataField="hash_1"
            label={{ text: "Hash 1" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: columnNames,
              showClearButton: true,
              dropDownOptions: {
                maxHeight: 300,
              },
              placeholder: "Select Columns...",
              value: columnNames.find((c) => c.toLowerCase() === "hash 1"),
            }}
          />
          <SimpleItem
            dataField="hash_1_type"
            label={{ text: "Algorithm" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: columnNames,
              showClearButton: true,
              dropDownOptions: {
                maxHeight: 300,
              },
              placeholder: "Select Columns...",
              value: columnNames.find(
                (c) => c.toLowerCase() === "hash 1 algorithm"
              ),
            }}
          />
          <SimpleItem
            dataField="hash_2"
            label={{ text: "Hash 2" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: columnNames,
              showClearButton: true,
              dropDownOptions: {
                maxHeight: 300,
              },
              placeholder: "Select Columns...",
              value: columnNames.find((c) => c.toLowerCase() === "hash 2"),
            }}
          />
          <SimpleItem
            dataField="hash_2_type"
            label={{ text: "Algorithm" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: columnNames,
              showClearButton: true,
              dropDownOptions: {
                maxHeight: 300,
              },
              placeholder: "Select Columns...",
              value: columnNames.find(
                (c) => c.toLowerCase() === "hash 2 algorithm"
              ),
            }}
          />
          <SimpleItem
            dataField="acquire_date"
            label={{ text: "Acquire Date" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: columnNames,
              showClearButton: true,
              dropDownOptions: {
                maxHeight: 300,
              },
              placeholder: "Select Columns...",
              value: columnNames.find(
                (c) => c.toLowerCase() === "acquire date"
              ),
            }}
          />
          <GroupItem colCount={2}>
            <SimpleItem
              dataField="duration_hours"
              label={{ text: "Duration Hours" }}
              editorType="dxSelectBox"
              editorOptions={{
                items: columnNames,
                showClearButton: true,
                dropDownOptions: {
                  maxHeight: 300,
                },
                placeholder: "Select Columns...",
                value: columnNames.find(
                  (c) => c.toLowerCase() === "duration hours"
                ),
              }}
            />
            <SimpleItem
              dataField="duration_mins"
              label={{ text: "Duration Mins" }}
              editorType="dxSelectBox"
              editorOptions={{
                items: columnNames,
                showClearButton: true,
                dropDownOptions: {
                  maxHeight: 300,
                },
                placeholder: "Select Columns...",
                value: columnNames.find(
                  (c) => c.toLowerCase() === "duration mins"
                ),
              }}
            />
          </GroupItem>
          <SimpleItem
            dataField="acquired_by_id"
            label={{ text: "Acquired By" }}
            editorType="dxSelectBox"
            editorOptions={{
              dataSource: users,
              dropDownOptions: {
                maxHeight: 250,
              },
              placeholder: "Examimer name",
              displayExpr: "full_name",
              valueExpr: "user_id",
              searchEnabled: true,
              hint: "Select the examiner that acquired this data.",
              showClearButton: true,
              itemTemplate: "acquired_by_id",
            }}
          />
          <Template
            name="acquired_by_id"
            render={(data) => <UserSelectBoxItem data={data} />}
          />
          <SimpleItem
            dataField="contact"
            label={{ text: "Linked Contact" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: columnNames,
              showClearButton: true,
              dropDownOptions: {
                maxHeight: 300,
              },
              placeholder: "Select Columns...",
              value: columnNames.find(
                (c) => c.toLowerCase() === "linked contact"
              ),
            }}
          />
          <SimpleItem
            dataField="acq_notes"
            label={{ text: "Notes" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: columnNames,
              showClearButton: true,
              dropDownOptions: {
                maxHeight: 300,
              },
              placeholder: "Select Columns...",
              value: columnNames.find((c) => c.toLowerCase() === "notes"),
            }}
          />
        </Form>
        <div
          style={{
            marginTop: 30,
          }}
        >
          <ToolBarItems
            submitText="Next"
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </div>
      </div>
    );
  }
)`
  margin: 40px auto;
  max-width: 800px;
`;

const Title = styled.div`
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 1rem;
`;

export default AcquisitionImportForm;
