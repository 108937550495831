export const calculateNewSortValue = (droppableArr, destination) => {
  if (!Array.isArray(droppableArr)) {
    throw new Error('Invalid data type "droppableArr" must be an array');
  }

  // If list is empty, do nothing
  if (droppableArr.length === 0)
    return droppableArr[destination.index].sort_value;

  // was the item inserted at the end of the list
  if (destination.index === droppableArr.length - 1) {
    return droppableArr[destination.index - 1].sort_value + 1;
  }

  // If item inserted at the beginning of the list
  if (destination.index === 0) {
    return droppableArr[destination.index + 1].sort_value - 1;
  }

  // if the item inserted between two items
  if (destination.index > 0 && destination.index < droppableArr.length - 1) {
    const first = droppableArr[destination.index - 1].sort_value;
    const second = droppableArr[destination.index + 1].sort_value;
    return (first + second) / 2;
  }
};
