import { Form, SimpleItem } from "devextreme-react/ui/form";
import { useEffect, useState } from "react";
import { userApi } from "../../../api";
import CollapseSection from "../../../components/CollaspeSection";
import { getDateFormat } from "../../../utils/date-format";
import { Template } from "devextreme-react/core/template";
import UserSelectBoxItem from "../../../components/SelectBoxItems/UserSelectBoxItem.js";
import TimeEntriesAPI from "../../../api/TimeEntries/time-entries.js";

const TimeEntriesParamsForm = ({ paramsForm, reportInfo }) => {
  const [editorVisiblity, setEditorVisbility] = useState({
    startDate:
      reportInfo?.report_parameters?.timestamp_interval === "Custom"
        ? true
        : false,
    endDate:
      reportInfo?.report_parameters?.timestamp_interval === "Custom"
        ? true
        : false,
    dimension: !!reportInfo?.report_parameters?.time_interval ? true : false,
    invoiced: true,
    categories: true,
    user: true,
  });

  let startDateRef = null;
  let endDateRef = null;

  const [categories, setCategories] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    userApi.getUsers({ includeObservers: false }).then(setUsers);

    TimeEntriesAPI.getTimeCategories().then(setCategories);
  }, []);

  return (
    <>
      <CollapseSection
        title={"Report Parameters | Time Entries - Overview"}
        allowCollapse={false}
      >
        <Form
          ref={paramsForm}
          colCount={2}
          defaultFormData={reportInfo || null}
        >
          <SimpleItem
            dataField="report_parameters.time_interval"
            label={{ text: "Time Interval", location: "top" }}
            colSpan={1}
            editorType="dxSelectBox"
            isRequired={true}
            editorOptions={{
              items: [
                "Custom",
                "Today",
                "Yesterday",
                "This Week",
                "Last Week",
                "This Month",
                "Last Month",
                "This Year",
                "Last Year",
                "All Time",
              ],
              dropDownOptions: {
                maxHeight: 250,
              },
              onValueChanged: (e) => {
                setEditorVisbility({
                  ...editorVisiblity,
                  startDate: e.value === "Custom",
                  endDate: e.value === "Custom",
                  dimension: e.value !== null,
                });
              },
            }}
          />
          <SimpleItem
            dataField="report_parameters.timestamp_dimension"
            label={{ text: "Time Dimension", location: "top" }}
            colSpan={1}
            editorType="dxSelectBox"
            visible={editorVisiblity.dimension}
            editorOptions={{
              items: ["Start Date", "End Date", "Invoice Date"],
              onValueChanged: (e) => {
                setEditorVisbility({
                  ...editorVisiblity,
                  invoiced: e.value !== "Invoice Date",
                });
              },
            }}
          />
          <SimpleItem
            dataField="report_parameters.start_date"
            label={{ text: "Start Date", location: "top" }}
            editorType="dxDateBox"
            colSpan={1}
            visible={editorVisiblity.startDate}
            editorOptions={{
              onInitialized: (e) => {
                startDateRef = e.component;
              },
              onValueChanged: (e) => {
                endDateRef.option("min", e.value);
              },
              type: "date",
              useMaskBehavior: true,
              displayFormat: getDateFormat({
                isMoment: false,
                includeTime: false,
              }),
            }}
          />

          <SimpleItem
            dataField="report_parameters.end_date"
            label={{ text: "End Date", location: "top" }}
            editorType="dxDateBox"
            colSpan={1}
            visible={editorVisiblity.endDate}
            editorOptions={{
              onInitialized: (e) => {
                endDateRef = e.component;
              },
              onValueChanged: (e) => {
                startDateRef.option("max", e.value);
              },
              type: "date",
              useMaskBehavior: true,
              displayFormat: getDateFormat({
                isMoment: false,
                includeTime: false,
              }),
            }}
          />

          <SimpleItem
            dataField="report_parameters.invoiced"
            label={{ text: "Invoiced", location: "top" }}
            colSpan={1}
            editorType="dxSelectBox"
            visible={editorVisiblity.invoiced}
            editorOptions={{
              items: ["Yes", "No"],
              onValueChanged: (e) => {
                setEditorVisbility({
                  ...editorVisiblity,
                });
              },
            }}
          />

          <SimpleItem
            dataField="report_parameters.category"
            label={{ text: "Categories" }}
            colSpan={1}
            editorType="dxTagBox"
            editorOptions={{
              valueExpr: "category_id",
              displayExpr: "category_name",
              items: categories,
              showClearButton: false,
              showDropDownButton: true,
              multiline: false,
              showSelectionControls: true,
              selectAllMode: "allPages",
              dropDownOptions: {
                maxHeight: 250,
              },
            }}
          />

          <SimpleItem
            dataField="report_parameters.users"
            label={{ text: "Users" }}
            colSpan={1}
            editorType="dxTagBox"
            editorOptions={{
              displayExpr: "full_name",
              items: users.map((cl) => ({
                user_id: cl.user_id,
                email: cl.email,
                full_name: cl.full_name,
                title: cl.title,
              })),
              showClearButton: false,
              showDropDownButton: true,
              multiline: false,
              showSelectionControls: true,
              selectAllMode: "allPages",
              dropDownOptions: {
                maxHeight: 250,
              },
              itemTemplate: "report_parameters.users",
            }}
          />
          <Template
            name="report_parameters.users"
            render={(data) => <UserSelectBoxItem data={data} />}
          />
        </Form>
      </CollapseSection>
    </>
  );
};

export default TimeEntriesParamsForm;
