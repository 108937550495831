import { FC } from "react";

import { FormTitle, FormWrapper } from "./components/MonolithFormComponents";
import { usePermissions } from "@/hooks/usePermissions";
import { useSnackbar, VariantType } from "notistack";
import LoggingAPI from "@/api/logging";
import DefaultEquipmentForm, {
  EditEquipmentFormData,
  EquipmentFormData,
} from "./DefaultEquipmentForm";
import { ForensicEquipment } from "@/types";
import { updateEquipmentItem } from "@/api";

interface EditEquipmentFormProps {
  width?: number | undefined;
  defaultFormData: ForensicEquipment;
  onSubmit: () => void;
  onCancel: () => void;
}

const EditEquipmentForm: FC<EditEquipmentFormProps> = ({
  width,
  defaultFormData,
  onSubmit,
  onCancel,
}) => {
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (
    data: EquipmentFormData,
    diffData?: EditEquipmentFormData
  ) => {
    try {
      if (defaultFormData.item_id) {
        updateEquipmentItem(defaultFormData.item_id, diffData);
        onSubmit?.();
        enqueueSnackbar(`Equipment item ${data?.name || ""} Edited`, {
          variant: "success" as VariantType,
        });
        LoggingAPI.createLog({
          user_id: currentUser.user_id,
          message: `Edited Equipment Item: ${data?.name}`,
          event: "equipment:edit",
          object_type: "equipment",
        });
      } else {
        enqueueSnackbar(
          `An error occurred while editing equipment ${
            data?.name || ""
          }. Please try again.`,
          { variant: "error" as VariantType }
        );
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        `An error occurred while editing equipment ${
          data?.name || ""
        }. Please try again.`,
        { variant: "error" as VariantType }
      );
    }
  };

  return (
    <FormWrapper width={width}>
      <FormTitle>Edit Equipment</FormTitle>
      <DefaultEquipmentForm
        onSubmit={handleSubmit}
        onCancel={onCancel}
        DefaultFormData={defaultFormData}
        buttonProps={{ submitText: "Save Changes" }}
      />
    </FormWrapper>
  );
};
export default EditEquipmentForm;
