import { Modal, useMediaQuery, useTheme } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import { getIpAddress } from "../../../api";
import { convertInquiry } from "../../../api/inquiries";
import { useAuth } from "../../../contexts/AuthContext";
import {
  db_timestamp,
  getDateFormat,
  validateTimestamp,
} from "../../../utils/date-format";

import {
  EmptyItem,
  Form,
  GroupItem,
  SimpleItem,
} from "devextreme-react/ui/form";
import SignatureField from "../../../components/SignatureField/SignatureField";
import ToolBarItems from "../../../components/ToolBarItems";
import { Template } from "devextreme-react/core/template";
import UserSelectBoxItem from "../../../components/SelectBoxItems/UserSelectBoxItem";
import LocationSelectBoxItem from "../../../components/SelectBoxItems/LocationSelectBoxItem";
import { useQueries } from "@tanstack/react-query";
import UserApi from "../../../api/users/users.js";
import LocationsAPI from "../../../api/locations/index.js";
import Loader from "../../../components/Loader.js";
import CasesApi from "../../../api/cases/index.js";

const ConvertInquiryPopup = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const { currentUser } = useAuth();
  const theme = useTheme();
  const receivedBySig = useRef(null);
  const receivedFromSig = useRef(null);
  const form = useRef(null);
  const [showCocForm, setShowCocForm] = useState(false);
  const [showEvidenceNumbers, setShowEvidenceNumbers] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const continueSubmit = async ({ isValid }) => {
    if (!isValid) return;
    const formData = { ...form.current.instance.option().formData };

    handleClose();

    if (formData.include_coc && formData.import_evidence) {
      const custodyTo = locations.find(
        (l) => l.location_id === formData.custody_to
      );
      const ipAddress = await getIpAddress();

      const signature = {};

      signature.custody_to_sig = receivedBySig.current.isEmpty()
        ? null
        : {
            signature: receivedBySig.current.getSignature(),
            signer: custodyTo.location_name,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      signature.custody_from_sig = receivedFromSig.current.isEmpty()
        ? null
        : {
            signature: receivedFromSig.current.getSignature(),
            signer: formData.custody_from,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      formData.timestamp = db_timestamp(formData.timestamp, true);
      formData.entered_by = currentUser.full_name;
      formData.signature = JSON.stringify(signature);
    } else {
      delete formData.custody_to;
      delete formData.custody_from;
      delete formData.timestamp;
    }

    formData.current_user_id = currentUser.user_id;
    if (formData.case_number) {
      formData.case_number = formData.case_number.trim();
      formData.case_ref = formData.case_ref.trim();
    }

    convertInquiry(defaultInfo.inquiry_id, formData).then((result) => {
      enqueueSnackbar(`Inquiry Converted.`, {
        variant: "success",
      });

      onSubmit(result);
    });
  };

  const handleSubmit = () => {
    const res = form.current.instance.validate();
    if (res.status === "pending") {
      res.complete.then((r) => {
        continueSubmit({ isValid: r.isValid });
      });
    } else {
      continueSubmit({ isValid: res.isValid });
    }
  };

  const result = useQueries({
    queries: [
      {
        queryKey: [
          "users:list",
          { includeObservers: false, includeInactive: false },
        ],
        queryFn: () =>
          UserApi.getUsers({ includeObservers: false, includeInactive: false }),
        enabled: open,
      },
      {
        queryKey: ["locations:list", { include_groups: false }],
        queryFn: () => LocationsAPI.getLocations({}, { include_groups: false }),
        enabled: open,
      },
    ],
  });

  const isDone = result.every((q) => q.isFetched);
  const users = result[0]?.data || [];
  const locations = result[1]?.data || [];

  const defaultFormData = {
    custody_to: currentUser.location_id,
    custody_from: defaultInfo.inquiry_name,
    timestamp: new Date(),
    case_ref: defaultInfo.request_name,
    evidence_number_import: false,
    import_evidence: true,
    import_docs: true,
    import_people: true,
    include_coc: false,
  };

  return (
    <>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        style={{ zIndex: 1400 }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 700,
            maxWidth: "95vw",
            maxHeight: "calc(100vh - 100px)",
            height: "fit-content",
            backgroundColor: theme.palette.background.default,
            position: "fixed",
            left: "calc(50% - 350px)",
            top: 50,
            padding: 20,
            outline: "none",
            overflowY: "auto",
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Create Case From Inquiry
          </div>
          {!isDone ? (
            <Loader />
          ) : (
            <>
              <div>
                <Form
                  ref={form}
                  colCount={2}
                  defaultFormData={{ ...defaultFormData }}
                >
                  <GroupItem caption="Case Details" colSpan={2} colCount={2}>
                    <SimpleItem
                      dataField="case_number"
                      label={{ text: "Case Number" }}
                      editorOptions={{
                        placeholder: "Leave blank to auto-generate",
                      }}
                      validationRules={[
                        {
                          type: "pattern",
                          message: 'Contains invalid characters: <>:"/\\|?*',
                          pattern: /^[^<>:"/\\|?*]+$/,
                        },
                        {
                          type: "async",
                          message: "Case Number already exists",
                          ignoreEmptyValue: true,
                          validationCallback: (e) => {
                            return CasesApi.caseNumberExists({
                              case_number: e.value,
                            }).then((res) => {
                              return !res;
                            });
                          },
                        },
                      ]}
                    />
                    <SimpleItem
                      dataField="case_ref"
                      label={{ text: "Case Name" }}
                      isRequired={true}
                    />
                    <SimpleItem
                      dataField="case_lead_id"
                      label={{ text: "Case Lead" }}
                      editorType="dxSelectBox"
                      editorOptions={{
                        dataSource: users,
                        displayExpr: "full_name",
                        valueExpr: "user_id",
                        searchEnabled: true,
                        searchExpr: "full_name",
                        searchMode: "startsWith",
                        placeholder: "Select Case Lead...",
                        dropDownOptions: {
                          maxHeight: 250,
                        },
                        itemTemplate: "case_lead_id",
                      }}
                    />
                    <Template
                      name="case_lead_id"
                      render={(data) => <UserSelectBoxItem data={data} />}
                    />
                  </GroupItem>
                  <GroupItem caption="Import Options" colSpan={2} colCount={3}>
                    <SimpleItem
                      editorType="dxCheckBox"
                      dataField="import_docs"
                      label={{ text: "Import Documents", location: "left" }}
                    />
                    <SimpleItem
                      editorType="dxCheckBox"
                      dataField="import_people"
                      label={{ text: "Import People", location: "left" }}
                    />
                    <SimpleItem
                      editorType="dxCheckBox"
                      dataField="import_evidence"
                      label={{ text: "Import Evidence", location: "left" }}
                      editorOptions={{
                        onValueChanged: (e) => {
                          setShowEvidenceNumbers(e.value);
                        },
                      }}
                    />
                    <SimpleItem
                      editorType="dxSelectBox"
                      dataField="evidence_number_import"
                      label={{ text: "Evidence Numbers" }}
                      editorOptions={{
                        dataSource: [
                          { text: "Auto-generate", value: false },
                          { text: "Import Evidence Numbers", value: true },
                        ],
                        displayExpr: "text",
                        valueExpr: "value",
                        disabled: !showEvidenceNumbers,
                      }}
                    />
                    <EmptyItem />
                    <EmptyItem />
                    <SimpleItem
                      editorType="dxCheckBox"
                      dataField="include_coc"
                      visible={showEvidenceNumbers}
                      label={{
                        text: "Include Chain of Custody",
                        location: "left",
                      }}
                      editorOptions={{
                        onValueChanged: (e) => {
                          setShowCocForm(e.value);
                        },
                      }}
                    />
                  </GroupItem>
                  <GroupItem
                    caption="Evidence Intake"
                    colSpan={2}
                    colCount={2}
                    visible={showCocForm && showEvidenceNumbers}
                  >
                    <SimpleItem
                      dataField="timestamp"
                      label={{ text: "Intake Timestamp" }}
                      isRequired={true}
                      editorType="dxDateBox"
                      editorOptions={{
                        type: "datetime",
                        useMaskBehavior: true,
                        displayFormat: getDateFormat({
                          isMoment: false,
                          includeTime: true,
                        }),
                      }}
                      validationRules={[
                        {
                          type: "custom",
                          message: "Invalid Date",
                          validationCallback: validateTimestamp,
                        },
                      ]}
                    />
                    <EmptyItem />
                    <SimpleItem
                      dataField="custody_to"
                      label={{ text: "Received By" }}
                      editorType="dxSelectBox"
                      isRequired={true}
                      editorOptions={{
                        dataSource: locations,
                        dropDownOptions: {
                          maxHeight: 250,
                        },
                        displayExpr: "location_name",
                        valueExpr: "location_id",
                        searchEnabled: true,
                        itemTemplate: "custody_to",
                      }}
                    />
                    <Template
                      name="custody_to"
                      render={(data) => <LocationSelectBoxItem data={data} />}
                    />
                    <SimpleItem
                      dataField="custody_from"
                      label={{ text: "Received From" }}
                      isRequired={true}
                    />
                    <SimpleItem
                      dataField="custody_to_sig"
                      label={{ text: "Received By Signature" }}
                    >
                      <SignatureField sigFieldRef={receivedBySig} />
                    </SimpleItem>
                    <SimpleItem
                      dataField="custody_from_sig"
                      label={{ text: "Received From Signature" }}
                    >
                      <SignatureField sigFieldRef={receivedFromSig} />
                    </SimpleItem>
                  </GroupItem>
                </Form>
                <ToolBarItems
                  submitText="Create Case"
                  onSubmit={handleSubmit}
                  onCancel={handleClose}
                />
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ConvertInquiryPopup;
