import { DropDownMenu } from "@monolith-forensics/monolith-ui";
import { useTheme } from "styled-components";
import { useState } from "react";
import { AuditItem, AuditItemStatus, DefaultTheme } from "@/types";
import { AuditStatusChangeModal } from "@/components/Modals";

interface AuditItemStatusSelectorProps {
  readOnly?: boolean;
  defaultValue?: AuditItemStatus;
  auditItem: AuditItem;
  value?: AuditItemStatus;
  onChange?: (status: AuditItemStatus) => void;
  onUpdate?: (newStatus: AuditItemStatus, auditItem: AuditItem) => void;
}

const StatusMenuItems = [
  { label: "Passed", value: "passed" },
  { label: "Failed", value: "failed" },
  { label: "Pending", value: "pending" },
];

const AuditItemStatusSelector: React.FC<AuditItemStatusSelectorProps> = ({
  defaultValue,
  auditItem,
  value,
  readOnly,
  onChange,
  onUpdate,
}) => {
  const [status, setStatus] = useState<AuditItemStatus | undefined>(
    defaultValue
  );
  const [showAuditStatusChangeModal, setShowAuditStatusChangeModal] =
    useState<boolean>(false);
  const theme = useTheme() as DefaultTheme;
  const controlled = value !== undefined;

  const _status = controlled ? value : status;

  const buttonStyle = {
    fontWeight: 500,
    color:
      _status === "passed"
        ? theme.name === "DARK"
          ? "#32CD32"
          : theme.palette.success.main
        : _status === "failed"
        ? theme.palette.error.main
        : "#f0ad4e",
    backgroundColor:
      _status === "passed"
        ? theme.name === "DARK"
          ? "#32CD3230"
          : theme.palette.success.main + "30"
        : _status === "failed"
        ? theme.palette.error.main + "30"
        : "#f0ad4e30",
  };

  const handleStatusChange = (newStatus: AuditItemStatus) => {
    if (!controlled) {
      setStatus(newStatus);
    }
    onChange?.(newStatus);

    setShowAuditStatusChangeModal(true);
  };

  return (
    <>
      <DropDownMenu
        variant="subtle"
        size="xs"
        arrow={!readOnly}
        data={StatusMenuItems}
        onItemSelect={(item) =>
          handleStatusChange(item.value as AuditItemStatus)
        }
        dropDownProps={{
          style: { width: 175, maxWidth: 400 },
        }}
        buttonProps={{
          size: "xxs",
          style: buttonStyle,
          title: "Action",
          disabled: readOnly,
        }}
      >
        <span style={{ textTransform: "capitalize" }}>{_status}</span>
      </DropDownMenu>
      <AuditStatusChangeModal
        show={showAuditStatusChangeModal}
        onClose={() => setShowAuditStatusChangeModal(false)}
        onSubmit={(values) => {
          onUpdate?.(values.newStatus, {
            ...auditItem,
            status: values.newStatus,
          });
          setShowAuditStatusChangeModal(false);
        }}
        onCancel={() => {
          setStatus(auditItem.status);
          setShowAuditStatusChangeModal(false);
        }}
        defaultInfo={{ ...auditItem, newStatus: _status as AuditItemStatus }}
      />
    </>
  );
};

export default AuditItemStatusSelector;
