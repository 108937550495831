import IndexedDBService from "./IndexedDBService.js";

const getUserPreferences = async (values = {}) => {
  return (await IndexedDBService.getDB().UserPreferences.get(values)) || null;
};

const createUserPreferences = async (values = {}) => {
  return IndexedDBService.getDB()
    .UserPreferences.add(values)
    .catch((err) => {
      console.log(err);
      // Update existing record
      return IndexedDBService.getDB().UserPreferences.update(values);
    });
};

const updateUserPreferences = async (values = {}) => {
  return IndexedDBService.getDB().UserPreferences.update(values);
};

const UserPreferences = {
  get: getUserPreferences,
  create: createUserPreferences,
  update: updateUserPreferences,
};

export default UserPreferences;
