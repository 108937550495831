import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useState } from "react";
import styled from "styled-components";
import { usePermissions } from "../../../hooks/usePermissions";
import AuditsAPI from "../../../api/Audits/Audits";
import { getDateFormat, monolithMoment } from "../../../utils/date-format";
import { Audit } from "../../../types";
import { Button, DropDownMenu } from "@monolith-forensics/monolith-ui";
import { CompleteAuditModal } from "@/components/Modals";
import { MoreHorizontalIcon } from "lucide-react";

interface AuditOverviewContentProps {
  auditData: Audit;
}

type Condition = {
  id: number;
  label: string;
  mode: "multi-select" | "text" | "date";
  operator: {
    name: string;
    operator: string;
  };
  displayValue: string[];
};

type ParsedFilter = {
  id: number;
  label: string;
  operator: string;
  value: string[] | string;
};

const FilterList = styled(
  ({ className, filter }: { className?: string; filter: Condition[] }) => {
    const parsedFilter: (ParsedFilter | undefined)[] = filter.map(
      (condition) => {
        if (condition.mode === "multi-select") {
          return {
            id: condition.id,
            label: condition.label,
            operator: condition.operator.name,
            value: [condition.displayValue.join(", ")],
          };
        }

        if (condition.mode === "text") {
          return {
            id: condition.id,
            label: condition.label,
            operator: condition.operator.name,
            value: condition.displayValue.map((v) => `"${v}"`),
          };
        }

        if (condition.mode === "date") {
          let value = condition.displayValue;
          if (condition.operator.operator === "isBetween") {
            value = value.map((v) => v.split("T")[0]);
            value.splice(1, 0, "And");
          } else {
            value = value.map((v) => v.split("T")[0]);
          }

          return {
            id: condition.id,
            label: condition.label,
            operator: condition.operator.name,
            value: value,
          };
        }
      }
    );

    return (
      <div className={className}>
        {parsedFilter.map((condition: ParsedFilter | undefined) => {
          if (!condition) return null;
          return (
            <div key={condition.id} className="filter-condition">
              <div className="filter-segment">{condition.label}</div>
              <div className="filter-segment">{condition.operator}</div>
              {Array.isArray(condition?.value) &&
                condition.value.map((v: string) => (
                  <div className="filter-segment">{v}</div>
                ))}
            </div>
          );
        })}
      </div>
    );
  }
)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 10px;
  user-select: none;

  .filter-condition {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2.5px;
  }

  .filter-segment {
    font-size: 0.75rem;
    font-weight: 300;
    cursor: default;

    padding: 2px 5px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.palette.background.secondary};
    border: 1px solid ${({ theme }) => theme.palette.divider};
    color: ${({ theme }) => theme.palette.text.primary};

    font-weight: 400;
    cursor: default;
  }
`;

const StyledContainer = styled.div`
  .audit-details {
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 4px;
    padding: 18px;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    .action-menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }

    .title {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .details-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 3fr));
    grid-gap: 20px;
    margin-bottom: 20px;
  }

  .detail-item {
  }

  .detail-label {
    font-size: 0.75rem;
    font-weight: 300;
    color: ${({ theme }) => theme.palette.text.secondary};
    cursor: default;
  }

  .detail-value {
    font-size: 0.85rem;
    font-weight: 500;
    color: ${({ theme }) => theme.palette.text.primary};
    cursor: default;

    text-transform: capitalize;

    &.no-cap {
      text-transform: none;
    }
  }
`;

const AuditOverviewContent: React.FC<AuditOverviewContentProps> = ({
  auditData,
}) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();

  const onCompleteAudit = () => {
    queryClient.refetchQueries({
      queryKey: ["audit:list", { uuid: auditData.uuid }],
    });
  };

  const handleExportAuditReport = () => {
    const total_items = auditData?.totals?.reduce((a, b) => a + b.total, 0);

    enqueueSnackbar("Generating audit report, this may take a minute...", {
      variant: "info",
    });
    AuditsAPI.getAuditReport({
      uuid: auditData.uuid,
      date_format: getDateFormat({ isMoment: true, includeTime: true }),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      total_items,
    }).then((res) => {
      const { signedUrl, filename, status } = res;
      if (status === "emailing") {
        enqueueSnackbar("Audit report is being emailed to you", {
          variant: "info",
        });
        return;
      }
      const el = document.createElement("a");
      el.href = signedUrl.replace(
        "http://localhost:3000",
        "http://localhost:3001"
      );
      el.download = filename;
      el.click();
    });
  };

  const handleExportAuditItemsList = () => {
    enqueueSnackbar("Generating audit items list, this may take a minute...", {
      variant: "info",
    });

    AuditsAPI.exportAuditItemsList({
      uuid: auditData.uuid,
      date_format: getDateFormat({ isMoment: true, includeTime: true }),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }).then((res) => {
      const { signedUrl, filename } = res;
      const el = document.createElement("a");
      el.href = signedUrl.replace(
        "http://localhost:3000",
        "http://localhost:3001"
      );
      el.download = filename;
      el.click();
    });
  };

  if (!auditData) return null;

  return (
    <StyledContainer>
      <div className="audit-details">
        <div className="header">
          <div className="title">Audit Details</div>
          <div className="action-menu">
            <DropDownMenu
              variant="subtle"
              data={[
                { label: "Export Audit Report", value: "export:audit_report" },
                { label: "Export Item List", value: "export:item_list" },
              ]}
              onItemSelect={(item: { label: string; value: string }) =>
                item.value === "export:audit_report"
                  ? handleExportAuditReport()
                  : handleExportAuditItemsList()
              }
              dropDownProps={{
                style: { width: 175, maxWidth: 400 },
              }}
              buttonProps={{
                title: "Export List to XLSX",
                size: "xxs",
              }}
            >
              <MoreHorizontalIcon size={16} />
            </DropDownMenu>
            {!auditData.is_complete && (
              <Button
                size="xxs"
                variant="contained"
                color="primary"
                disabled={!hasPermission(MONOLITH_PERMISSIONS.AUDITS_UPDATE)}
                onClick={() => setShowCompleteModal(true)}
                style={{
                  minWidth: "fit-content",
                  fontSize: 11,
                  padding: "3px 6px",
                }}
              >
                Complete Audit
              </Button>
            )}
          </div>
        </div>
        <CompleteAuditModal
          show={showCompleteModal}
          onClose={() => setShowCompleteModal(false)}
          onCancel={() => setShowCompleteModal(false)}
          onSubmit={onCompleteAudit}
          defaultInfo={auditData}
        />
        <div className="details-grid">
          <div className="detail-item">
            <div className="detail-label">Status</div>
            <div className="detail-value">{auditData?.status}</div>
          </div>
          <div className="detail-item">
            <div className="detail-label">Item Type</div>
            <div
              className="detail-value"
              style={{ textTransform: "capitalize" }}
            >
              {auditData?.item_type}
            </div>
          </div>
          <div className="detail-item">
            <div className="detail-label">Total Items</div>
            <div className="detail-value">
              {auditData?.totals?.reduce((a, b) => a + b.total, 0)}
            </div>
          </div>
          <div className="detail-item">
            <div className="detail-label">Pending</div>
            <div className="detail-value">
              {auditData?.totals?.find((t) => t.status === "pending")?.total ||
                0}
            </div>
          </div>
          <div className="detail-item">
            <div className="detail-label">Passed</div>
            <div className="detail-value">
              {auditData?.totals?.find((t) => t.status === "passed")?.total ||
                0}
            </div>
          </div>
          <div className="detail-item">
            <div className="detail-label">Failed</div>
            <div className="detail-value">
              {auditData?.totals?.find((t) => t.status === "failed")?.total ||
                0}
            </div>
          </div>
          <div className="detail-item">
            <div className="detail-label">Assignee</div>
            <div className="detail-value">
              {auditData?.assigned_to?.full_name || "None"}
            </div>
          </div>
          <div className="detail-item">
            <div className="detail-label">Created By</div>
            <div className="detail-value">{auditData.created_by.full_name}</div>
          </div>
          <div className="detail-item">
            <div className="detail-label">Created On</div>
            <div className="detail-value">
              {monolithMoment({ timestamp: auditData.created_on })}
            </div>
          </div>
          <div className="detail-item">
            <div className="detail-label">Start Date</div>
            <div className="detail-value">
              {auditData.start_date
                ? monolithMoment({ timestamp: auditData.start_date })
                : "None"}
            </div>
          </div>
          <div className="detail-item">
            <div className="detail-label">Due Date</div>
            <div className="detail-value">
              {auditData.due_date
                ? monolithMoment({ timestamp: auditData.due_date })
                : "None"}
            </div>
          </div>
          {auditData.is_complete === 1 && (
            <div className="detail-item">
              <div className="detail-label">Completed By</div>
              <div className="detail-value">
                {auditData.completed_on
                  ? auditData.completed_by.full_name
                  : "None"}
              </div>
            </div>
          )}
          {auditData.is_complete === 1 && (
            <div className="detail-item">
              <div className="detail-label">Completed On</div>
              <div className="detail-value">
                {auditData.completed_on
                  ? monolithMoment({ timestamp: auditData.completed_on })
                  : "None"}
              </div>
            </div>
          )}
        </div>
        <div className="detail-item" style={{ marginBottom: 20 }}>
          <div className="detail-label">Filter</div>
          {auditData?.filter?.length > 0 ? (
            <FilterList filter={auditData.filter} />
          ) : (
            <div className="detail-value">No Filter - All Items Included</div>
          )}
        </div>
        <div className="detail-item" style={{ marginBottom: 0 }}>
          <div className="detail-label">Description</div>
          <div className="detail-value no-cap">
            {auditData.description || "No Description"}
          </div>
        </div>
      </div>
    </StyledContainer>
  );
};

export default AuditOverviewContent;
