import { FC } from "react";
import { Modal } from "@monolith-forensics/monolith-ui";
import CreateEquipmentForm from "../Forms/CreateEquipmentForm";

interface CreateEquipmentModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onCancel: () => void;
}

const CreateEquipmentModal: FC<CreateEquipmentModalProps> = ({
  show,
  onClose,
  onSubmit,
  onCancel,
}) => {
  const handleSubmit = () => {
    onSubmit?.(); // Refetches queries in equipment section
    handleClose();
  };

  const handleClose = () => {
    onClose();
  };
  const handleCancel = () => {
    onCancel?.();
  };

  return (
    <Modal open={show} onClose={onClose}>
      <CreateEquipmentForm
        width={550}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </Modal>
  );
};

export default CreateEquipmentModal;
