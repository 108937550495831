import DropdownMenu from "../../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import TaskPriorities from "./TaskPriorities.js";

const TaskPrioritySelector = ({
  value,
  onSelect,
  showLabel = true,
  variant = "contained",
}) => {
  const menuItems = [
    {
      radioGroup: true,
      value: value?.priority_id,
      items: TaskPriorities.map((priority) => ({
        label: priority.priority_name,
        value: priority.priority_id,
        icon: priority.icon,
        iconColor: priority.color,
        onClick: () => {
          onSelect?.(priority);
        },
      })),
    },
  ];

  return (
    <DropdownMenu
      menuItems={menuItems}
      title={"Select Task Priority"}
      variant={variant}
      textColor={!!value ? "primary" : "secondary"}
    >
      {value?.icon && (
        <value.icon
          color={value.color}
          size={13}
          style={{ marginRight: showLabel ? 5 : 0 }}
        />
      )}
      {showLabel && <div>{value?.priority_name || "Priority"}</div>}
    </DropdownMenu>
  );
};

export default TaskPrioritySelector;
