import { FC } from "react";
import { usePermissions } from "@/hooks/usePermissions";
import { useSnackbar, VariantType } from "notistack";
import LoggingAPI from "@/api/logging";
import DefaultEquipmentForm, {
  EquipmentFormData,
} from "./DefaultEquipmentForm";
import { FormTitle, FormWrapper } from "./components/MonolithFormComponents";
import { createEquipmentItem } from "@/api";

interface CreateEquipmentFormProps {
  width?: number | undefined;
  onSubmit: () => void;
  onCancel: () => void;
}

const CreateEquipmentForm: FC<CreateEquipmentFormProps> = ({
  width,
  onSubmit,
  onCancel,
}) => {
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (data: EquipmentFormData) => {
    try {
      await createEquipmentItem(data);
      onSubmit?.();
      enqueueSnackbar(`Software item ${data?.name || ""} created`, {
        variant: "success" as VariantType,
      });

      LoggingAPI.createLog({
        user_id: currentUser.user_id,
        message: `Created Software Item: ${data?.name}`,
        event: "software:create",
        object_type: "forensic_software",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        "An error occurred while creating this item. Please try again.",
        { variant: "error" as VariantType }
      );
    }
  };

  return (
    <FormWrapper width={width}>
      <FormTitle>Create Equipment Item</FormTitle>
      <DefaultEquipmentForm
        onSubmit={handleSubmit}
        onCancel={onCancel}
        DefaultFormData={{
          barcode: String(Math.floor(Math.random() * 1000000000000)),
        }}
        buttonProps={{ submitText: "Add Equipment" }}
      />
    </FormWrapper>
  );
};
export default CreateEquipmentForm;
