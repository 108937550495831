import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import { usePermissions } from "../../hooks/usePermissions";
import AcquisitionsAPI from "../../api/acquisitions/index.js";
import DropdownMenu from "../../Monolith-UI/DropdownMenu/DropdownMenu.js";

const StatusSelector = ({ rowData, queryKey, onChange = () => {} }) => {
  const { hasPermission } = usePermissions();
  const queryClient = useQueryClient();
  const cachedData = queryClient.getQueryData(["acquisitions:status"]);
  const containerRef = useRef(null);

  const { data: statusItems } = useQuery({
    queryKey: ["acquisitions:status"],
    queryFn: () => AcquisitionsAPI.getStatuses(),
    enabled: !cachedData,
    placeholderData: (data) => data || cachedData,
  });

  const currentStatus =
    statusItems?.find((p) => p.status === rowData?.status) || {};

  const handleItemSelect = (item) => {
    containerRef.current.classList.remove("custom-action-button");
    AcquisitionsAPI.updateAcquisition(rowData.acq_id, {
      status: item.status,
    });
    onChange(item);
    if (queryKey) {
      queryClient.setQueryData(queryKey, (oldData) => {
        const { data } = oldData;

        return {
          ...oldData,
          data: data.map((e) => {
            if (e.acq_id === rowData.acq_id) {
              e.status = item.status;
            }
            return e;
          }),
        };
      });
    }
  };

  const menuItems = [
    {
      radioGroup: true,
      value: currentStatus?.status_id,
      onSelectionChanged: (data) => {
        handleItemSelect(statusItems.find((p) => p.status_id === data.value));
      },
      items:
        statusItems?.map?.((p) => ({
          label: p.status,
          value: p.status_id,
        })) || [],
    },
  ];

  return (
    <div
      ref={containerRef}
      className={`${currentStatus ? "" : "custom-action-button"}`}
    >
      <DropdownMenu
        menuItems={menuItems}
        disabled={!hasPermission()}
        title={"Select Status"}
        variant={"contained"}
        textColor={rowData?.status ? "primary" : "secondary"}
        buttonProps={{
          style: {
            fontSize: 12,
            color: currentStatus?.color,
            backgroundColor: currentStatus?.color + "25",
            fontWeight: 500,
          },
        }}
      >
        {rowData?.status ? <div>{rowData.status}</div> : <div>Select...</div>}
      </DropdownMenu>
    </div>
  );
};

export default StatusSelector;
