import styled from "styled-components";

const StorageSelectBoxItem = styled(({ className, data }) => {
  return (
    <div className={className}>
      <div className="storage-number">{data.storage_number}</div>
      <div sclassName="storage-info">
        <div>
          {data.make || "Unknown"} {data.model_name || "Unknown"}
        </div>
        <div>S/N: {data.serial_number || "No Serial #"}</div>
      </div>
    </div>
  );
})`
  .storage-number {
    margin-bottom: 5px;
  }
  .storage-info {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

export default StorageSelectBoxItem;
