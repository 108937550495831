import { Card, Divider, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Popup } from "devextreme-react/ui/popup";

import { Types } from "../../../Settings/Integration/FPR_Integration";
import ToolBarItems from "../../../../components/ToolBarItems";
import Loader from "../../../../components/Loader";
import { markItemsAsReported } from "../../../../api/integrations/fpr";
import { useSnackbar } from "notistack";
import TaskButton from "../../../../Monolith-UI/TaskButton.js";

export const FprMetrics = ({ reportInfo, cases, evidence }) => {
  const [total, setTotal] = useState(null);
  const markReportedPopup = useRef(null);
  const theme = useTheme();

  const handleValueCalc = (data) => {
    if (!data.length || data.length === 0) {
      return "0%";
    }
    return `${data.length} (${((100 * data.length) / total).toFixed(1)}%)`;
  };

  const handleMarkReported = (e) => {
    markReportedPopup.current.instance.show();
  };

  useEffect(() => {
    if (evidence) {
      let count = 0;

      Object.keys(evidence).forEach((e) => {
        count += evidence[e].length;
      });

      setTotal(count);
    }
  }, [evidence]);

  return (
    <>
      <Card
        style={{
          padding: "20px",
          textAlign: "left",
          flexGrow: 1,
          flexBasis: "10px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              fontSize: "larger",
            }}
          >
            Metrics
          </div>
          {cases && evidence && (
            <TaskButton onClick={handleMarkReported}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                  padding: "1px 5px",
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 5,
                  width: "fit-content",
                  fontSize: 11,
                }}
              >
                <div>Mark As Reported</div>
              </div>
            </TaskButton>
          )}
        </div>
        <Divider sx={{ my: 2 }} />
        {(!cases || !evidence) && <Loader />}
        {cases && evidence && (
          <>
            <div
              style={{
                minWidth: "300px",
                overflowY: "auto",
                maxHeight: "175px",
                paddingRight: "10px",
              }}
            >
              {cases && evidence && (
                <>
                  <TextLine
                    text="Cases | Evidence"
                    value={`${cases.length} | ${total}`}
                  />
                  <TextLine
                    text="CPUs"
                    value={handleValueCalc(evidence[Types.CPU])}
                  />
                  <TextLine
                    text="ETech"
                    value={handleValueCalc(evidence[Types.ETECH])}
                  />
                  <TextLine
                    text="Loose Drives"
                    value={handleValueCalc(evidence[Types.LOOSE_DRIVES])}
                  />
                  <TextLine
                    text="Storage Media"
                    value={handleValueCalc(evidence[Types.STORAGE_MEDIA])}
                  />
                  <TextLine
                    text="Cloud"
                    value={handleValueCalc(evidence[Types.CLOUD])}
                  />
                  <TextLine
                    text="Other"
                    value={handleValueCalc(evidence[Types.OTHER])}
                  />
                </>
              )}
            </div>
            <MarkReportedPopup
              markReportedPopup={markReportedPopup}
              evidence={evidence}
            />
          </>
        )}
      </Card>
    </>
  );
};

const TextLine = ({ text, value }) => {
  return (
    <div
      style={{
        minWidth: "300px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "5px",
        }}
      >
        <div>{text}:</div>
        <div>{value || 0}</div>
      </div>
    </div>
  );
};

const MarkReportedPopup = ({ markReportedPopup, evidence }) => {
  const [updating, setUpdating] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (e) => {
    setUpdating(true);

    const evidenceIDs = getReportableItems(evidence);

    if (evidenceIDs.length === 0) {
      enqueueSnackbar("All items already reported.", {
        variant: "warning",
      });

      markReportedPopup.current.instance.hide().then(() => {
        setUpdating(false);
      });
      return;
    }

    markItemsAsReported(evidenceIDs)
      .then(() => {
        markReportedPopup.current.instance.hide();
        enqueueSnackbar(`${evidenceIDs.length} Items Marked as "Reported"`, {
          variant: "success",
        });
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  const handleCancel = (e) => {
    markReportedPopup.current.instance.hide();
  };

  const getReportableItems = (evidence) => {
    const evidenceIDs = [];

    Object.keys(evidence).forEach((key) => {
      evidenceIDs.push(
        ...evidence[key]
          .filter((e) => e.fpr_status !== "Reported")
          .map((e) => e.evidence_id)
      );
    });

    return evidenceIDs;
  };

  return (
    <>
      <Popup
        ref={markReportedPopup}
        title="Mark Evidence Items as Reported"
        showTitle={true}
        showCloseButton={false}
        height={"auto"}
        defaultWidth={400}
      >
        <div>
          {updating && (
            <>
              <div>
                <Loader />
              </div>
              <div style={{ marginTop: 10, textAlign: "center" }}>
                Updating Evidence Items...
              </div>
            </>
          )}
          {!updating && (
            <div>
              <p>Are you sure you want to mark these items as "Reported"?</p>
              <p>
                The "FPR - Status" custom evidence field for{" "}
                <u>
                  <b>{getReportableItems(evidence).length}</b>
                </u>{" "}
                evidence items will be changed to "Reported".
              </p>
              <ToolBarItems
                submitText="Mark as Reported"
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                style={{ marginTop: 50 }}
              />
            </div>
          )}
        </div>
      </Popup>
    </>
  );
};
