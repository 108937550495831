import { getEquipmentVendors } from "@/api";
import ForensicSoftwareAPI from "@/api/software";
import { SelectBox } from "@monolith-forensics/monolith-ui";
import { useQuery } from "@tanstack/react-query";
import { FC } from "react";

interface VendorSelectorProps {
  defaultValue: string;
  onChange?: (value: string) => void;
  error?: string;
  openOnFocus?: boolean;
  queryKey: "software" | "equipment";
}

type Vendor = {
  vendor: string;
};

const vendorQueryKeyMap = {
  software: ForensicSoftwareAPI.getSoftwareVendors,
  equipment: getEquipmentVendors,
};

const VendorSelector: FC<VendorSelectorProps> = ({
  defaultValue,
  onChange,
  error,
  openOnFocus = false,
  queryKey,
}) => {
  const { data: vendors } = useQuery<Vendor[]>({
    queryKey: ["software", "vendors"],
    queryFn: () => vendorQueryKeyMap[queryKey](),
  });

  const options = vendors?.map((vendor) => ({
    label: vendor?.vendor,
    value: vendor?.vendor,
  }));

  return (
    <>
      <SelectBox
        defaultValue={defaultValue}
        onChange={onChange}
        error={error}
        size="sm"
        variant="outlined"
        label="Vendor"
        placeholder="Select or enter vendor name"
        data={options}
        clearable={true}
        searchable={true}
        allowCustomValue={true}
        openOnFocus={openOnFocus}
      />
    </>
  );
};
export default VendorSelector;
