import { getQaAssignmentsByUser } from "../../../../api";
import { useAuth } from "../../../../contexts/AuthContext";
import { Link } from "react-router-dom";
import moment from "moment";
import Loader from "../../../../components/Loader";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { nanoid } from "nanoid";

const MyQaReviews = styled(({ className }) => {
  const { currentUser } = useAuth();

  const { data } = useQuery({
    queryKey: ["qa-reviews:list", currentUser.user_id],
    queryFn: () => getQaAssignmentsByUser(currentUser.user_id),
  });

  if (!data) return <Loader />;

  if (data.length === 0)
    return <div style={{ textAlign: "center" }}>No QA Reviews</div>;

  return (
    <div className={className}>
      {data.map((qaReview) => (
        <QaReviewItem key={nanoid()} qaReview={qaReview} />
      ))}
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  height: calc(100% - 50px);
  padding: 0px 10px;
`;

const QaReviewItem = styled(({ className, qaReview }) => {
  return (
    <Link to={`/cases/${qaReview.case_id}/qa/review/${qaReview.qa_id}`}>
      <div className={className} title="Go to QA Review">
        <div className="task-content">
          <div className="task-name" title={qaReview.name}>
            {qaReview.name}
          </div>
          <div className="case-number">{qaReview.case_number}</div>
        </div>
        <div>
          <div className="due-date">
            {moment(qaReview.created_on).fromNow()}
          </div>
        </div>
      </div>
    </Link>
  );
})`
  cursor: pointer;
  background-color: transparent;
  border: ${(props) => `1px solid ${props.theme.palette.divider}`};
  border-radius: 5px;
  padding: 5px 10px;
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  &:hover {
    border: ${(props) => `1px solid ${props.theme.palette.primary.main}`};
    cursor: pointer;
  }
  .task-content {
    max-width: 50%;
  }
  .task-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .case-number {
    color: ${(props) => props.theme.palette.text.secondary};
    font-size: 10px;
  }
  .due-date {
    color: ${(props) => props.theme.palette.text.secondary};
    font-size: 10px;
  }
`;

export default MyQaReviews;
