// Used for handling non-JSON data in tagboxes
export const safeParseJSON = (stringToParse) => {
  try {
    const parsed = JSON.parse(stringToParse);
    // Ensure parsing results in an object or array
    if (parsed && typeof parsed === "object") {
      return parsed;
    }
  } catch (e) {
    // If not, log error and return original string
    console.error("error parsing JSON: ", e);
  }
  return stringToParse;
};
