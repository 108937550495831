import { useTheme } from "@mui/material";
import moment from "moment";
import Chart from "react-apexcharts";
import Loader from "../../../../components/Loader";
import { useQuery } from "@tanstack/react-query";
import MetricsAPI from "../../../../api/Metrics/Metrics.js";
import styled from "styled-components";

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const CaseDataChart = styled(({ className }) => {
  const theme = useTheme();

  const options = {
    colors: [theme.palette.primary.main, "#00FA9A", "#FFA500"],
    legend: {
      show: false,
    },
    chart: {
      stacked: false,
      background: "unset",
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 250,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
      toolbar: {
        show: false,
      },
    },
    theme: {
      mode: theme.name === "DARK" ? "dark" : "light",
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      custom: undefined,
      fillSeriesColor: false,
      theme: theme.name === "DARK" ? "dark" : "light",
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
    },
    markers: {
      size: 5,
      strokeColor: "undefined",
      strokeWidth: 3,
    },
    stroke: {
      show: true,
      curve: "smooth",
      width: 3,
    },
    yaxis: {
      show: false,
      title: {
        text: "Total",
        style: {
          fontWeight: "normal",
        },
      },
    },
    xaxis: {
      show: true,
      type: "category",
      labels: {
        formatter: function (value, timestamp, opts) {
          return moment(value).format("MMM");
        },
      },
    },
  };

  const metrics = [
    {
      metric: "casesPerMonth",
      args: [
        timezone,
        moment().subtract(1, "years").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
    },
    {
      metric: "evidencePerMonth",
      args: [
        timezone,
        moment().subtract(1, "years").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
    },
    {
      metric: "acquisitionsPerMonth",
      args: [
        timezone,
        moment().subtract(1, "years").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
    },
  ];

  const { data } = useQuery({
    queryKey: ["metrics", { metrics }],
    queryFn: () => MetricsAPI.getMetrics({ metrics }),
  });

  if (!data) return <Loader />;

  const cases = data?.data?.[0]?.casesPerMonth || [];
  const evidence = data?.data?.[1]?.evidencePerMonth || [];
  const acquisitions = data?.data?.[2]?.acquisitionsPerMonth || [];

  return (
    <div className={className}>
      <Chart
        options={{
          ...options,
        }}
        type="line"
        height={"100%"}
        series={[
          {
            name: "Cases",
            data: cases,
          },
          {
            name: "Evidence",
            data: evidence,
          },
          {
            name: "Acquisitions",
            data: acquisitions,
          },
        ]}
      />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export default CaseDataChart;
