import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import MetricsAPI from "../../../../api/Metrics/Metrics.js";
import moment from "moment";
import DropdownMenu from "../../../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import { useState } from "react";
import Loader from "../../../../components/Loader.js";

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const HourProgressComponent = styled(({ className, hours, capacity }) => {
  const percentage = (hours / capacity) * 100;

  const overflowPercentage =
    capacity / hours > 1
      ? 0
      : (hours / capacity - Math.floor(hours / capacity)) * 100;

  return (
    <div className={className}>
      <div className="progress-bar">
        <div className="bar-background" />
        <div
          className="bar-foreground"
          style={{
            width: `${percentage.toLocaleString("en-US", {
              maximumFractionDigits: 2,
            })}%`,
          }}
        />
        <div
          className="bar-overflow"
          style={{
            width: `${overflowPercentage.toLocaleString("en-US", {
              maximumFractionDigits: 2,
            })}%`,
          }}
        />
        <div className="bar-label">{`${percentage.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        })}%`}</div>
      </div>
    </div>
  );
})`
  display: flex;
  flex: 1;

  .progress-bar {
    height: 25px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    border: 1px solid ${(props) => props.theme.palette.primary.main + "70"};
    border-radius: 5px;
  }

  .bar-label {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.palette.text.primary};
    font-size: 0.8rem;
    font-wieght: 500;
  }

  .bar-background {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    background-color: ${(props) => props.theme.palette.primary.main + "25"};
  }

  .bar-foreground {
    position: absolute;
    height: 100%;
    width: 0%;
    max-width: 100%;
    border-radius: 5px;
    background-color: ${(props) => props.theme.palette.primary.main};
  }

  .bar-overflow {
    position: absolute;
    height: 100%;
    width: 0%;
    max-width: 100%;
    border-radius: 5px;
    background-color: orange;
  }
`;

const TimeTrackedPerUserWidget = styled(({ className }) => {
  const handleSelect = (value) => {
    setCurrentMenuItem(value);
  };

  const menuItems = [
    {
      label: "Today",
      value: {
        metric: "timeTrackedPerUser",
        args: [
          timezone,
          moment().startOf("day").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
        capacity: 7,
      },
      onClick: handleSelect,
      getCapacity: (userCapacity = 0) => {
        return userCapacity / 7;
      },
    },
    {
      label: "This Week",
      value: {
        metric: "timeTrackedPerUser",
        args: [
          timezone,
          moment().startOf("week").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
        capacity: 35,
      },
      onClick: handleSelect,
      getCapacity: (userCapacity = 0) => {
        return userCapacity;
      },
    },
    {
      label: "This Month",
      value: {
        metric: "timeTrackedPerUser",
        args: [
          timezone,
          moment().startOf("month").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
        capacity: moment().daysInMonth() * 5,
      },
      onClick: handleSelect,
      getCapacity: (userCapacity = 0) => {
        return moment().daysInMonth() * (userCapacity / 7);
      },
    },
    {
      label: "Last Month",
      value: {
        metric: "timeTrackedPerUser",
        args: [
          timezone,
          moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
          moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
        ],
        capacity: moment().subtract(1, "month").daysInMonth() * 5,
      },
      onClick: handleSelect,
      getCapacity: (userCapacity = 0) => {
        return moment().subtract(1, "month").daysInMonth() * userCapacity;
      },
    },
    {
      label: "This Year",
      value: {
        metric: "timeTrackedPerUser",
        args: [
          timezone,
          moment().startOf("year").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
        capacity: moment().diff(moment().startOf("year"), "weeks") * 35,
      },
      onClick: handleSelect,
      getCapacity: (userCapacity = 0) => {
        return moment().diff(moment().startOf("year"), "weeks") * userCapacity;
      },
    },
    {
      label: "Last Year",
      value: {
        metric: "timeTrackedPerUser",
        args: [
          timezone,
          moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD"),
          moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD"),
        ],
        capacity: 52 * 35,
      },
      onClick: handleSelect,
      getCapacity: (userCapacity = 0) => {
        return 52 * userCapacity;
      },
    },
  ];

  const [currentMenuItem, setCurrentMenuItem] = useState(menuItems[2]);

  const metric = {
    metric: "timeTrackedPerUser",
    args: currentMenuItem.value.args,
  };

  const { data } = useQuery({
    queryKey: ["metrics", metric],
    queryFn: () => MetricsAPI.getMetrics(metric),
  });

  if (!data) return <Loader />;

  const timeTrackedPerUser = data?.data?.timeTrackedPerUser || [];

  const capacity = currentMenuItem.value.capacity;
  const teamCapacity = timeTrackedPerUser.reduce(
    (acc, d) => acc + currentMenuItem.getCapacity(d.capacity || 0),
    0
  );

  const totalTrackedHours =
    timeTrackedPerUser.reduce((acc, d) => acc + (d.duration || 0), 0) / 3600;

  return (
    <div className={className}>
      <div className="widget-header">
        <div className="widget-title">Time Tracked Per User</div>
        <DropdownMenu menuItems={menuItems} arrow>
          {currentMenuItem.label}
        </DropdownMenu>
      </div>
      <div className="info-section">
        <div>
          <div className="info-label">Total Hours</div>
          <div>
            {totalTrackedHours.toLocaleString("en-US", {
              maximumFractionDigits: 2,
            })}
          </div>
        </div>
        <div>
          <div className="info-label">Team Capacity</div>
          <div>
            {teamCapacity.toLocaleString("en-US", { maximumFractionDigits: 2 })}
          </div>
        </div>
        <HourProgressComponent
          hours={totalTrackedHours}
          capacity={teamCapacity}
        />
      </div>

      <div className="tbl-container">
        <div className="tbl">
          <div className="tbl-header">
            <div
              className="tbl-cell"
              style={{ minWidth: 150, width: 150, maxWidth: 150 }}
            >
              User
            </div>
            <div className="tbl-cell">Hours</div>
            <div
              className="tbl-cell"
              style={{ minWidth: 85, width: 85, maxWidth: 85 }}
            >
              Capacity
            </div>
          </div>
          <div className="tbl-body">
            {timeTrackedPerUser.map((d) => {
              const hours = ((d.duration || 0) / 3600).toFixed(2);

              return (
                <div className="tbl-row">
                  <div
                    className="tbl-cell"
                    style={{ minWidth: 150, width: 150, maxWidth: 150 }}
                  >
                    {d.full_name}
                  </div>
                  <div className="tbl-cell" style={{ gap: 5 }}>
                    <div style={{ minWidth: 50 }}>
                      {hours.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })}
                    </div>
                    <HourProgressComponent hours={hours} capacity={capacity} />
                  </div>
                  <div
                    className="tbl-cell"
                    style={{ minWidth: 85, width: 85, maxWidth: 85 }}
                  >
                    {capacity.toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;

  .widget-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }

  .info-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding: 0 15px;
    gap: 25px;
    font-size: 1rem;
    font-weight: 500;

    .info-label {
      font-size: 0.8rem;
      color: ${(props) => props.theme.palette.text.secondary};
    }
  }

  .widget-title {
    font-size: 1rem;
    font-weight: bold;
  }

  .tbl-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-y: auto;
  }

  .tbl {
    width: 100%;
    border: 1px solid ${(props) => props.theme.palette.divider};
  }

  .tbl-header {
    display: flex;
    flex-direction: row;
    height: 35px;
    font-weight: bold;
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }

  .tbl-row {
    display: flex;
    flex-direction: row;
    height: 50px;
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }

  .tbl-cell {
    flex: 1;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0 10px;
  }
`;

export default TimeTrackedPerUserWidget;
