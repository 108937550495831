import { useMemo, useRef, useState } from "react";
import DataSource from "devextreme/data/data_source";
import {
  Form,
  SimpleItem,
  EmptyItem,
  GroupItem,
  RequiredRule,
} from "devextreme-react/ui/form";
import { DevexEditors } from "../../../../utils/devex-editors";

import { useSnackbar } from "notistack";

import { Modal, useTheme } from "@mui/material";

import {
  getContacts,
  getEvidence,
  getSoftwareNames,
  getStorage,
  logActivity,
  userApi,
} from "../../../../api";
import AcquisitionsAPI, {
  createAcquisition,
} from "../../../../api/acquisitions";
import ToolBarItems from "../../../../components/ToolBarItems";
import { useAuth } from "../../../../contexts/AuthContext";
import {
  db_timestamp,
  getDateFormat,
  validateTimestamp,
} from "../../../../utils/date-format";
import CreateContactFlyout from "../../../Contacts/CreateContactFlyout";
import { useQueries } from "@tanstack/react-query";
import { Template } from "devextreme-react/core/template";
import ContactSelectBoxItem from "../../../../components/SelectBoxItems/ContactSelectBoxItem.js";
import EvidenceSelectBoxItem from "../../../../components/SelectBoxItems/EvidenceSelectBoxItem.js";
import StorageSelectBoxItem from "../../../../components/SelectBoxItems/StorageSelectBoxItem.js";
import UserSelectBoxItem from "../../../../components/SelectBoxItems/UserSelectBoxItem.js";
import Loader from "../../../../components/Loader.js";
import CollapseSection from "../../../../components/CollaspeSection";

const CreateAcquisitionPopup = ({
  caseInfo = null,
  evidenceInfo = null,
  showEvidenceField = false,
  onSubmit = () => {},
  handleClose = () => {},
  open = false,
  flyoutData,
}) => {
  const theme = useTheme();
  const selectedEvidence = useRef(null);
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [showCreateContactFlyout, setShowCreateContactFlyout] = useState(false);

  const form = useRef(null);
  const customFieldForm = useRef(null);
  const advancedDetailsForm = useRef(null);

  const handleSubmit = async (event) => {
    if (
      form.current.instance.validate().isValid &&
      (customFieldForm?.current
        ? customFieldForm?.current?.instance?.validate().isValid
        : true) &&
      advancedDetailsForm.current.instance.validate().isValid
    ) {
      let data = {
        ...form.current.instance.option().formData,
        ...(customFieldForm?.current?.instance?.option()?.formData || {}),
        ...advancedDetailsForm.current.instance.option().formData,
      };
      //Set Default Values

      data.evidence_id =
        data?.evidence_id ||
        evidenceInfo?.evidence_id ||
        flyoutData?.evidence_id ||
        null;

      data.case_id =
        caseInfo?.case_id ||
        evidenceInfo?.case_id ||
        flyoutData?.case_id ||
        null;

      data.acquire_date = db_timestamp(data.acquire_date);
      data.contact_id = data?.person_id || null;
      delete data.contact;

      let customAttributes = [];

      // parse custom attribute field_id from form field
      for (let key of Object.keys(data)) {
        if (key.indexOf("custom_attribute_") !== -1) {
          customAttributes.push({
            field_id: parseInt(key.replace("custom_attribute_", "")),
            value: data[key],
          });

          delete data[key];
        }
      }
      if (customAttributes?.length > 0)
        data.custom_attributes = customAttributes;

      createAcquisition(data).then((result) => {
        onSubmit(data);
        enqueueSnackbar(`Acquisition Created.`, {
          variant: "success",
        });

        const evidenceNumber =
          evidenceInfo?.evidence_number ||
          selectedEvidence?.current?.evidence_number ||
          flyoutData?.evidence_number ||
          null;

        logActivity(
          caseInfo?.case_id ||
            evidenceInfo?.case_id ||
            flyoutData?.case_id ||
            null,
          currentUser.user_id,
          evidenceNumber
            ? `Acquisition Created (Evidence: ${evidenceNumber})`
            : `Acquisition Created - ${data.acq_name}`
        );
      });

      handleClose();
    }
  };

  const handleCancel = () => {
    handleClose();
  };

  const onContactCreated = (newContact) => {
    advancedDetailsForm.current.instance.option("formData.contact", newContact);
    advancedDetailsForm.current.instance
      .getEditor("person_id")
      .option("value", newContact.client_id);

    advancedDetailsForm.current.instance
      .getEditor("person_id")
      .option("dataSource", [...fieldData.contacts, newContact]);
  };

  const results = useQueries({
    queries: getQueries({ enabled: open, caseInfo, evidenceInfo }),
  });

  const isDone = results.every((result) => result.isFetched);

  const fieldData = {
    storage: results[0]?.data || [],
    software: results[1]?.data || [],
    contacts: [
      { name: "Create New Contact", client_id: "new" },
      ...(results[2]?.data || []),
    ],
    users: results[3]?.data || [],
    formats: results[4]?.data || [],
    types: results[5]?.data || [],
    hash_types: results[6].data || [],
    customFields: results[7]?.data || [],
  };

  if (!evidenceInfo) fieldData["evidence"] = results[8]?.data || [];

  const MainDetailsFormSection = useMemo(() => {
    const defaultFormData = {
      evidence_id: flyoutData?.evidence_id || null,
      acq_name: flyoutData?.acq_name || null,
      format: flyoutData?.format || null,
      acq_type: flyoutData?.acq_type || null,
      acq_tool: flyoutData?.acq_tool || null,
      tool_version: flyoutData?.tool_version || null,
      size: flyoutData?.size || null,
      size_unit: flyoutData?.size_unit || "GB",
      storage_items: flyoutData?.storage_id ? [flyoutData?.storage_id] : [],
    };

    return (
      <Form ref={form} colCount={2} defaultFormData={defaultFormData}>
        {(!evidenceInfo || showEvidenceField) && (
          <SimpleItem
            dataField="evidence_id"
            label={{ text: "Evidence" }}
            isRequired={false}
            editorType="dxSelectBox"
            editorOptions={{
              dataSource: new DataSource({
                store: fieldData?.evidence || [],
                paginate: true,
                pageSize: 10,
              }),
              dropDownOptions: {
                minHeight: 200,
                maxHeight: 300,
              },
              hint: "Select an evidence item to acquire.",
              noDataText: "No evidence items are in this case.",
              searchEnabled: true,
              valueExpr: "evidence_id",
              displayExpr: "evidence_number",
              placeholder: "Select...",
              onSelectionChanged: (e) => {
                selectedEvidence.current = e.selectedItem;
              },
              itemTemplate: "evidence_id",
            }}
          />
        )}
        <Template
          name="evidence_id"
          render={(data) => <EvidenceSelectBoxItem data={data} />}
        />
        {!evidenceInfo && <EmptyItem />}
        <SimpleItem
          dataField="acq_name"
          label={{ text: "Acquisition Name" }}
          isRequired={true}
        />
        <GroupItem colCount={2}>
          <SimpleItem
            dataField="format"
            label={{ text: "Format" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: fieldData?.formats || [],
              placeholder: "Select...",
              displayExpr: "format",
              valueExpr: "format",
              dropDownOptions: {
                height: "200px",
              },
              acceptCustomValue: true,
              onCustomItemCreating: (e) => {
                if (e.text !== "") {
                  e.customItem = { format: e.text };
                  e.component.getDataSource().store().insert(e.customItem);
                  e.component.getDataSource().reload();
                } else {
                  e.customItem = null;
                }
              },
            }}
          />
          <SimpleItem
            dataField="acq_type"
            label={{ text: "Type" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: fieldData?.types || [],
              placeholder: "Select...",
              displayExpr: "type",
              valueExpr: "type",
              acceptCustomValue: true,
              onCustomItemCreating: (e) => {
                if (e.text !== "") {
                  e.customItem = { type: e.text };
                  e.component.getDataSource().store().insert(e.customItem);
                  e.component.getDataSource().reload();
                } else {
                  e.customItem = null;
                }
              },
            }}
          />
        </GroupItem>
        <SimpleItem
          dataField="acq_tool"
          label={{ text: "Forensic Software" }}
          editorType="dxSelectBox"
          editorOptions={{
            dataSource: new DataSource({
              store: fieldData?.software || [],
              paginate: true,
              pageSize: 10,
            }),
            displayExpr: "software_name",
            valueExpr: "software_name",
            placeholder: "Select...",
            acceptCustomValue: true,
            onCustomItemCreating: (e) => {
              if (e.text != "") {
                e.customItem = { software_name: e.text };
                e.component.getDataSource().store().insert(e.customItem);
                e.component.getDataSource().reload();
              } else {
                e.customItem = null;
              }
            },
          }}
        />
        <SimpleItem
          dataField="tool_version"
          label={{ text: "Software Version" }}
        />
        <GroupItem colCount={2}>
          <SimpleItem
            dataField="size"
            label={{ text: "Size" }}
            editorType="dxNumberBox"
            editorOptions={{
              min: 0,
              max: 999,
              showSpinButtons: true,
            }}
          />
          <SimpleItem
            dataField="size_unit"
            label={{ text: "Unit" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: ["KB", "MB", "GB", "TB"],
              value: flyoutData?.size_unit || "GB",
            }}
          />
        </GroupItem>
        <SimpleItem
          dataField="storage_items"
          label={{ text: "Storage Items" }}
          editorType="dxTagBox"
          editorOptions={{
            dataSource: new DataSource({
              store: fieldData?.storage || [],
              paginate: true,
              pageSize: 10,
              key: "storage_id",
              group: "is_general_storage",
              sort: [
                "Position",
                { selector: "is_general_storage", desc: true },
              ],
            }),
            hint: "Select storage items that this acquisition is stored on.",
            noDataText: "Assign storage to the case to choose a storage item.",
            searchEnabled: true,
            displayExpr: "storage_number",
            valueExpr: "storage_id",
            showSelectionControls: true,
            maxDisplayedTags: 1,
            selectAllMode: "allPages",
            showDropDownButton: true,
            grouped: true,
            dropDownOptions: {
              height: "350px",
            },
            groupTemplate: (data) => {
              return `<div>${
                data.key === 1 ? "General Storage" : "Assigned Storage"
              }</div>`;
            },
            itemTemplate: "storage_items",
          }}
        />
        <Template
          name="storage_items"
          render={(data) => <StorageSelectBoxItem data={data} />}
        />
      </Form>
    );
  }, [open, isDone]);

  const AdvDetailsFormSection = useMemo(() => {
    const defaultFormData = {
      hash_1: flyoutData?.hash_1 || null,
      hash_1_type: flyoutData?.hash_1_type || null,
      hash_2: flyoutData?.hash_2 || null,
      hash_2_type: flyoutData?.hash_2_type || null,
      acquire_date: flyoutData?.acquire_date || new Date(),
      duration_hours: flyoutData?.duration_hours || 0,
      duration_mins: flyoutData?.duration_mins || 0,
      acquired_by_id: flyoutData?.acquired_by_id || currentUser.user_id,
      person_id: flyoutData?.person_id || null,
      acq_notes: flyoutData?.acq_notes || null,
    };

    return (
      <Form
        ref={advancedDetailsForm}
        colCount={2}
        defaultFormData={defaultFormData}
      >
        <SimpleItem dataField="hash_1" label={{ text: "Hash 1" }} />
        <SimpleItem
          dataField="hash_1_type"
          label={{ text: "Algorithm" }}
          editorType="dxSelectBox"
          editorOptions={{
            displayExpr: "hash_type",
            valueExpr: "hash_type",
            items: fieldData?.hash_types || [],
            placeholder: "Select...",
          }}
        />
        <SimpleItem dataField="hash_2" label={{ text: "Hash 2" }} />
        <SimpleItem
          dataField="hash_2_type"
          label={{ text: "Algorithm" }}
          editorType="dxSelectBox"
          editorOptions={{
            items: fieldData?.hash_types || [],
            displayExpr: "hash_type",
            valueExpr: "hash_type",
            placeholder: "Select...",
          }}
        />
        <SimpleItem
          dataField="acquire_date"
          label={{ text: "Acquire Date" }}
          editorType="dxDateBox"
          editorOptions={{
            type: "datetime",
            useMaskBehavior: true,
            displayFormat: getDateFormat({
              isMoment: false,
              includeTime: true,
            }),
          }}
          validationRules={[
            {
              type: "custom",
              message: "Invalid Date",
              validationCallback: validateTimestamp,
            },
          ]}
        />
        <GroupItem colCount={2}>
          <SimpleItem
            dataField="duration_hours"
            label={{ text: "Duration Hours" }}
            editorType="dxNumberBox"
            editorOptions={{
              min: 0,
              showSpinButtons: true,
            }}
          />
          <SimpleItem
            dataField="duration_mins"
            label={{ text: "Duration Mins" }}
            editorType="dxNumberBox"
            editorOptions={{
              max: 59,
              min: 0,
              showSpinButtons: true,
            }}
          />
        </GroupItem>
        <SimpleItem
          dataField="acquired_by_id"
          label={{ text: "Acquired By" }}
          editorType="dxSelectBox"
          editorOptions={{
            dataSource: fieldData?.users || [],
            dropDownOptions: {
              maxHeight: 250,
            },
            placeholder: "Examiner name",
            displayExpr: "full_name",
            valueExpr: "user_id",
            searchEnabled: true,
            hint: "Select the examiner that acquired this data.",
            showClearButton: true,
            itemTemplate: "acquired_by_id",
          }}
        />
        <Template
          name="acquired_by_id"
          render={(data) => <UserSelectBoxItem data={data} />}
        />
        <SimpleItem
          dataField="person_id"
          label={{ text: "Linked Contact" }}
          editorType="dxSelectBox"
          editorOptions={{
            dataSource: fieldData?.contacts || [],
            placeholder: "Select Contact...",
            dropDownOptions: {
              maxHeight: 300,
            },
            displayExpr: "name",
            valueExpr: "client_id",
            searchEnabled: true,
            showClearButton: true,
            openOnFieldClick: false,
            onFocusIn: (e) => {
              e.component.open();
            },
            onValueChanged: (e) => {
              if (e.value && e.value === "new") {
                e.event.preventDefault();
                e.event.stopPropagation();
                e.component.option("value", null);
                setShowCreateContactFlyout(true);
              }
            },
            itemTemplate: "contact",
          }}
        />
        <Template
          name="contact"
          render={(data) => <ContactSelectBoxItem data={data} />}
        />
        <SimpleItem
          dataField="acq_notes"
          label={{ text: "Notes" }}
          editorType="dxTextArea"
          colSpan={2}
          editorOptions={{ height: 125 }}
        />
      </Form>
    );
  }, [open, isDone]);

  const CustomFieldFormSection = useMemo(() => {
    const defaultFormData = {};

    flyoutData?.custom_attributes?.forEach((attr) => {
      defaultFormData[`custom_attribute_${attr.field_id}`] =
        attr.editor_type === "tagBox"
          ? typeof attr.value === "object"
            ? attr.value
            : JSON.parse(attr.value)
          : attr.value;
    });

    return (
      <Form
        ref={customFieldForm}
        colCount={2}
        style={{ padding: 10 }}
        disabled={showCreateContactFlyout}
        defaultFormData={defaultFormData}
      >
        {fieldData?.customFields?.map((attribute) => (
          <SimpleItem
            key={`custom_attribute_${attribute.field_id}`}
            dataField={`custom_attribute_${attribute.field_id}`}
            editorType={DevexEditors[attribute.editor_type]}
            label={{ text: attribute.field_name }}
            editorOptions={{
              placeholder: attribute.description || "",
              hint: attribute.description || "",
              items: JSON.parse(attribute.options || "[]"),
              dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ",
              useMaskBehavior: true,
              displayFormat: getDateFormat({
                isMoment: false,
                includeTime: false,
              }),
              showClearButton: attribute.editor_type !== "textBox",
              pickerType: "calendar",
              showDropDownButton: true,
              multiline: false,
              showSelectionControls:
                attribute.editor_type === "tagBox" ? true : false,
            }}
          >
            {attribute.is_required === 1 && <RequiredRule message="" />}
          </SimpleItem>
        ))}
      </Form>
    );
  }, [open, isDone]);

  return (
    <>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        style={{ zIndex: 1400 }}
        disableEnforceFocus={true}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 700,
            maxHeight: "90vh",
            backgroundColor: theme.palette.background.default,
            position: "fixed",
            left: "calc(50% - 350px)",
            overflowY: "auto",
            padding: 20,
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Create Acquisition
          </div>
          {!isDone && <Loader />}
          {isDone && (
            <>
              <CollapseSection title="Acquisition Details" visible={true}>
                {MainDetailsFormSection}
              </CollapseSection>

              <CollapseSection title="Advanced Details">
                {AdvDetailsFormSection}
              </CollapseSection>
              {fieldData?.customFields?.length === 0 ? (
                <></>
              ) : (
                <CollapseSection title="Custom Fields">
                  {CustomFieldFormSection}
                </CollapseSection>
              )}
              <div
                style={{
                  marginTop: 30,
                }}
              >
                <ToolBarItems
                  submitText="Create Acquisition"
                  onSubmit={handleSubmit}
                  onCancel={handleCancel}
                />
              </div>
            </>
          )}
        </div>
      </Modal>
      <CreateContactFlyout
        open={showCreateContactFlyout}
        onSubmit={onContactCreated}
        handleClose={() => setShowCreateContactFlyout(false)}
        caseInfo={caseInfo ? caseInfo : evidenceInfo}
      />
    </>
  );
};

const getQueries = ({ caseInfo, evidenceInfo, enabled }) => {
  const queries = [];

  queries.push({
    queryKey: [
      "storage:list",
      {
        case_id: caseInfo ? caseInfo.case_id : evidenceInfo.case_id,
        active_only: true,
        include_general_storage: true,
      },
    ],
    queryFn: () =>
      getStorage({
        case_id: caseInfo ? caseInfo.case_id : evidenceInfo.case_id,
        active_only: true,
        include_general_storage: true,
      }),
    enabled,
  });

  queries.push({
    queryKey: ["acquisition:software:list"],
    queryFn: () => getSoftwareNames(),
    enabled,
  });

  queries.push({
    queryKey: [
      "people:contacts",
      { case_id: caseInfo ? caseInfo.case_id : evidenceInfo.case_id },
    ],
    queryFn: () =>
      getContacts({
        case_id: caseInfo ? caseInfo.case_id : evidenceInfo.case_id,
      }),
    enabled,
  });

  queries.push({
    queryKey: ["cases:leads"],
    queryFn: () =>
      userApi.getUsers({ includeInactive: false, includeObservers: false }),
    enabled,
  });

  queries.push({
    queryKey: ["acquisition:formats:list"],
    queryFn: () => AcquisitionsAPI.getFormats(),
    enabled,
  });

  queries.push({
    queryKey: ["acquisition:types:list"],
    queryFn: () => AcquisitionsAPI.getTypes(),
    enabled,
  });

  queries.push({
    queryKey: ["acquisition:hash_types:list"],
    queryFn: () => AcquisitionsAPI.getHashTypes(),
    enabled,
  });

  queries.push({
    queryKey: ["acquisition:custom_fields:list"],
    queryFn: () => AcquisitionsAPI.getCustomAttributes({ fieldsOnly: true }),
    enabled,
    select: (data) => data?.filter((c) => c.enabled === 1),
  });

  if (!evidenceInfo) {
    queries.push({
      queryKey: [
        "evidence:list",
        {
          case_id: caseInfo ? caseInfo.case_id : evidenceInfo.case_id,
        },
      ],
      queryFn: () =>
        getEvidence({
          case_id: caseInfo ? caseInfo.case_id : evidenceInfo.case_id,
        }),
      enabled,
    });
  }

  return queries;
};

export default CreateAcquisitionPopup;
