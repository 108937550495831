import { useTheme } from "@mui/material";
import { getDateFormat } from "../../utils/date-format";
import CaseNumberCell from "../Evidence/CaseNumberCell.js";
import EvidenceNumberCell from "../Evidence/EvidenceNumberCell.js";
import StatusSelector from "./StatusSelector.js";

let AcqColumnDefs = [
  {
    dataField: "acq_name",
    caption: "Acquisition Name",
    visible: true,
  },
  {
    dataField: "case_number",
    caption: "Case Number",
    visible: false,
    render: (rowData) => {
      return <CaseNumberCell rowData={rowData} />;
    },
  },
  {
    dataField: "status",
    caption: "Status",
    render: (rowData, options = {}) => {
      if (options.enabled === false) return rowData.status;
      return (
        <StatusSelector
          rowData={rowData}
          queryKey={options.queryKey}
          onChange={(data) => {
            options?.reload && options?.reload();
            options?.onChange && options?.onChange(data);
          }}
        />
      );
    },
  },
  {
    dataField: "format",
    caption: "Format",
  },
  {
    dataField: "acq_type",
    caption: "Type",
  },
  {
    dataField: "size_bytes",
    caption: "Size",
    visible: false,
    render: (rowData) =>
      rowData.size ? `${rowData.size} ${rowData.size_unit || ""}` : "",
  },
  {
    dataField: "size_gb",
    caption: "Size (GB)",
    visible: false,
    render: (rowData) => {
      if (rowData.size_gb === null || rowData.size_gb === undefined) return "";
      return rowData.size_gb + " GB";
    },
  },
  {
    dataField: "created_on",
    caption: "Created On",
    dataType: "date",
    visible: false,
    format: {
      type: getDateFormat({ isMoment: true, includeTime: false }),
    },
  },
  {
    dataField: "acquire_date",
    caption: "Acquire Date",
    dataType: "date",
    format: {
      type: getDateFormat({ isMoment: true, includeTime: false }),
    },
  },
  {
    dataField: "acquired_by",
    caption: "Acquired By",
    visible: false,
  },
  {
    dataField: "acq_tool",
    caption: "Forensic Software",
    visible: false,
  },
  {
    dataField: "tool_version",
    caption: "Software Version",
    visible: false,
  },
  {
    dataField: "storage_number",
    caption: "Storage",
  },
  {
    dataField: "hash_1",
    caption: "Hash 1",
    visible: false,
  },
  {
    dataField: "hash_1_type",
    caption: "Hash 1 Algorithm",
    visible: false,
  },
  {
    dataField: "hash_2",
    caption: "Hash 2",
    visible: false,
  },
  {
    dataField: "hash_2_type",
    caption: "Hash 2 Algorithm",
    visible: false,
  },
  {
    dataField: "duration",
    caption: "Duration",
    visible: false,
    render: (rowData) => {
      return (
        (
          parseInt(rowData.duration_hours || 0) +
          parseInt(rowData.duration_mins || 0) / 60
        ).toFixed(2) + " hours"
      );
    },
  },
  {
    dataField: "evidence_number",
    caption: "Evidence Number",
    visible: false,
    render: (rowData) => {
      const CustomCell = ({ rowData }) => {
        const theme = useTheme();

        return (
          <EvidenceNumberCell
            rowData={{ ...rowData, uuid: rowData.evidence_uuid }}
            color={theme.palette.primary.main}
          />
        );
      };

      return <CustomCell rowData={rowData} />;
    },
  },
  {
    dataField: "evidence_uuid",
    caption: "Evidence UUID",
    visible: false,
  },
  {
    dataField: "evidence_type",
    caption: "Evidence Type",
    visible: false,
  },
  {
    dataField: "evidence_provider",
    caption: "Evidence Provider",
    visible: false,
  },
  {
    dataField: "evidence_item_name",
    caption: "Evidence Name",
    visible: false,
  },
  {
    dataField: "evidence_model_number",
    caption: "Evidence Model Number",
    visible: false,
  },
  {
    dataField: "evidence_unique_id",
    caption: "Evidence Unique ID",
    visible: false,
  },

  {
    dataField: "evidence_capacity_bytes",
    caption: "Evidence Size",
    visible: false,
    render: (rowData) => {
      // return 0 if capacity is left blank
      if (
        rowData.evidence_capacity_bytes === undefined ||
        rowData.evidence_capacity_bytes === null ||
        rowData.evidence_capacity_bytes === 0
      ) {
        return "0";
      }
      const capacity_unit = rowData.capacity_unit || "GB";
      let formattedValue = 0; // Initialize formatted value

      // Convert evidence_capacity_bytes to the specified unit and format the number
      switch (capacity_unit) {
        case "TB":
          formattedValue = (
            rowData.evidence_capacity_bytes /
            1024 ** 4
          ).toFixed(2);
          break;
        case "GB":
          formattedValue = (
            rowData.evidence_capacity_bytes /
            1024 ** 3
          ).toFixed(2);
          break;
        case "MB":
          formattedValue = (
            rowData.evidence_capacity_bytes /
            1024 ** 2
          ).toFixed(2);
          break;
        case "KB":
          formattedValue = (rowData.evidence_capacity_bytes / 1024).toFixed(2);
          break;
        default:
          // returns GB by default
          formattedValue = (
            rowData.evidence_capacity_bytes /
            1024 ** 3
          ).toFixed(2);
      }

      return `${Number(formattedValue).toLocaleString()} ${capacity_unit}`;
    },
  },
  {
    dataField: "contact",
    caption: "Linked Contact",
    visible: false,
  },
  {
    dataField: "acq_notes",
    caption: "Description",
    titleRender: (rowData) => rowData.acq_notes,
    visible: false,
  },
  {
    dataField: "location_path",
    caption: "Location Path",
    visible: false,
  },
];

// export a copy of the array so that we can modify it without affecting the original
export default AcqColumnDefs.slice();
