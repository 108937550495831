import { Modal, Switch, useTheme } from "@mui/material";
import ToolBarItems from "./ToolBarItems.js";
import { Form, SimpleItem } from "devextreme-react/ui/form.js";
import { useEffect, useRef } from "react";

const SaveTemplateModal = ({
  onSubmit = (name, description, content, is_shared) => {},
  open = false,
  onClose = () => {},
  details = {},
}) => {
  const theme = useTheme();
  const form = useRef(null);
  const sharedSwitchRef = useRef(null);

  // Reset shared switch when modal is closed
  useEffect(() => {
    sharedSwitchRef.current = false;
  }, [open]);

  const handleSubmit = () => {
    const isValid = form.current.instance.validate();
    if (!isValid.isValid) return;
    const formData = { ...form.current.instance.option().formData };

    onSubmit({
      name: formData.name,
      description: formData.description,
      content: details.content,
      is_shared: sharedSwitchRef.current,
    });
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  const defaultFormData = {
    name: details?.note?.note_tag || "",
    description: "",
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleCancel();
      }}
      style={{ zIndex: 1400, outline: "none" }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 500,
          maxHeight: "90vh",
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          top: "25%",
          left: "50%",
          transform: "translate(-50%, -33%)",
          overflowY: "auto",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large", fontWeight: 600 }}>
          Create Template from Note
        </div>
        <Form
          ref={form}
          defaultFormData={defaultFormData}
          onContentReady={(e) => {
            setTimeout(() => {
              e.component.getEditor("name").focus();
            }, 100);
          }}
        >
          <SimpleItem
            autoFocus={true}
            dataField="name"
            label={{ text: "Template Name" }}
            editorOptions={{
              placeholder: "Enter a name for this template",
            }}
            isRequired={true}
          />
        </Form>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 5,
            alignItems: "center",
            fontWeight: 600,
            color: theme.palette.text.secondary,
            width: "fit-content",
            userSelect: "none",
          }}
          title="Shared templates are visible to all users in Monolith."
        >
          <Switch
            size="small"
            defaultChecked={false}
            onChange={(event) => {
              sharedSwitchRef.current = event.target.checked;
            }}
          />
          <div>Shared</div>
        </div>

        <ToolBarItems
          style={{ marginTop: 5 }}
          submitText={"Create Template"}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </div>
    </Modal>
  );
};

export default SaveTemplateModal;
