import CollapseSection from "../../../../components/CollaspeSection";
import { CaseTypesMapping } from "./CaseTypesMapping";
import { EvidenceTypesMapping } from "./EvidenceTypesMapping";

export const FPRSettings = ({ fprSettings }) => {
  return (
    <>
      <h1>FPR Integration Settings</h1>
      <CollapseSection title="Evidence Type Map">
        <EvidenceTypesMapping fprSettings={fprSettings} />
      </CollapseSection>

      <CollapseSection title="Case Type Map">
        <CaseTypesMapping fprSettings={fprSettings} />
      </CollapseSection>
    </>
  );
};
