import * as RadixDialog from "@radix-ui/react-dialog";
import styled from "styled-components";
import { X } from "lucide-react";
import TaskButton from "../TaskButton.js";

const StyledOverlay = styled(RadixDialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  inset: 0;
  z-index: 100000;

  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const StyledContent = styled(RadixDialog.Content)`
  background-color: ${(props) => props.theme.palette.background.default};
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.palette.divider};
  position: fixed;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -15%);
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 16px 70px;

  z-index: 100000;

  &:focus {
    outline: none;
  }

  @keyframes contentShow {
    from {
      opacity: 0;
      transform: translate(-50%, -48%) scale(0.96);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
`;

const StyledClose = styled(RadixDialog.Close)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Dialog = ({
  children,
  open = false,
  onClose,
  style = {},
  showCloseButton = true,
}) => {
  return (
    <RadixDialog.Root
      open={open}
      onOpenChange={(open) => {
        if (!open) onClose?.();
      }}
      modal={true}
    >
      <RadixDialog.Portal>
        <StyledOverlay />
        <StyledContent
          side="bottom"
          style={style}
          onPointerDownOutside={(event) => {
            event.preventDefault();
          }}
        >
          {showCloseButton && (
            <StyledClose asChild>
              <TaskButton>
                <X size={16} />
              </TaskButton>
            </StyledClose>
          )}
          {children}
        </StyledContent>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};

export default Dialog;
