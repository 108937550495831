import { httpDelete, httpGet, httpPatch, httpPost } from "../connection.js";

async function get(query = {}) {
  return await httpGet("/document-templates", query);
}

async function create(values = {}) {
  return await httpPost("/document-templates", values);
}

async function update(values = {}) {
  return await httpPatch("/document-templates", values);
}

async function remove(values = {}) {
  return await httpDelete("/document-templates", values);
}

const DocumentTemplatesAPI = {
  get,
  create,
  update,
  remove,
};

export default DocumentTemplatesAPI;
