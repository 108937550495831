import Chart from "react-apexcharts";

import Loader from "../../../../components/Loader";
import { useQuery } from "@tanstack/react-query";
import MetricsAPI from "../../../../api/Metrics/Metrics.js";
import styled, { useTheme } from "styled-components";

const chartOptions = {
  labels: [],
  chart: {
    background: "unset",
    type: "donut",
    height: 350,
  },
  theme: {
    mode: "dark",
    palette: "palette10",
  },
  stroke: {
    show: false,
  },
  legend: {
    show: false,
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
      },
    },
  ],
};

const DefaultPieChart = ({ data, title, valueExpr }) => {
  let options = { ...chartOptions };

  options.labels = data.map((d) => {
    return d[valueExpr] !== null ? d[valueExpr] : "N/A";
  });

  if (data.length === 0) {
    return (
      <div>
        {title && (
          <div
            style={{
              marginBottom: "10px",
            }}
          >
            {title}
          </div>
        )}
        <div>No Data</div>
      </div>
    );
  }

  return (
    <div>
      {title && (
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          {title}
        </div>
      )}
      <Chart
        options={options}
        series={data.map((d) => {
          return d.total;
        })}
        type="donut"
        width={275}
      />
    </div>
  );
};

const CaseLeadChart = styled(({ className }) => {
  const theme = useTheme();
  const { data } = useQuery({
    queryKey: ["activeCasesPerLead"],
    queryFn: () => MetricsAPI.getMetrics({ metric: "activeCasesPerLead" }),
  });

  if (!data) return <Loader />;

  const caseLeadData = data?.data?.activeCasesPerLead || [];

  const labels = caseLeadData.map((d) => d.full_name);
  const points = caseLeadData.map((d) => d.total);

  return (
    <div className={className}>
      <Chart
        options={{
          chart: {
            animations: {
              enabled: false,
            },
            stacked: false,
            background: "unset",
            sparkline: {
              enabled: true,
            },
            toolbar: {
              show: false,
            },
            events: {
              dataPointSelection: function (event, chartContext, config) {
                console.log(event, chartContext, config);
              },
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              dataLabels: {
                position: "bottom", // top, center, bottom
              },
            },
          },
          theme: {
            mode: theme.name === "DARK" ? "dark" : "light",
          },
          fill: {
            colors: [theme.palette.primary.main],
            type: "solid",
            opacity: 0.33,
          },
          stroke: {
            colors: [theme.palette.primary.main + "90"],
            width: 1,
          },
          dataLabels: {
            enabled: true,
            textAnchor: "start",
            style: {
              colors: [theme.palette.text.primary],
            },
            formatter: function (val, data) {
              return `${labels[data.dataPointIndex]} (${val})`;
            },
          },
          grid: {
            show: false,
          },
          yaxis: {
            labels: {
              show: true,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          xaxis: {
            categories: labels,
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
        }}
        type="bar"
        height={"auto"}
        width={325}
        series={[
          {
            name: "Total",
            data: points,
          },
        ]}
      />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-height: 350px;
  overflow-y: auto;
`;

export default CaseLeadChart;
