import { useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useState } from "react";

import { Typography, useTheme } from "@mui/material";
import { getClients } from "../../api";
import KeyboardReturnOutlinedIcon from "@mui/icons-material/KeyboardReturnOutlined";

import Loader from "../../components/Loader";
import ClientOverview from "./ClientOverview";
import { usePermissions } from "../../hooks/usePermissions";
import TaskButton from "../../Monolith-UI/TaskButton";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import styled from "styled-components";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import DeleteClientModal from "@/components/Modals/DeleteClientModal";

const ClientDetails = styled(({ className }) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const { client_id } = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { data: clientInfo, isLoading } = useQuery({
    queryKey: ["client", "item", client_id],
    queryFn: () => getClients({ client_id }),
    select: (res) => res[0],
  });

  const handleClientUpdate = () => {
    queryClient.refetchQueries({
      queryKey: ["client", "item", client_id],
    });
  };
  if (isLoading) return <Loader />;

  return (
    <div className={className}>
      <Helmet>
        <title>Client Details | {clientInfo.name}</title>
      </Helmet>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          margin: "10px 0px",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            minWidth: "fit-content",
          }}
        >
          <BackLink message={"Back to Clients"} />
          <div
            style={{
              marginLeft: 10,
              marginRight: 10,
              fontWeight: "bold",
              fontSize: 20,
            }}
          >
            {clientInfo.name || "Client Details"}
            <Typography color="textSecondary" variant="subtitle2">
              {clientInfo.organization || ""}
            </Typography>
          </div>
        </div>
        {hasPermission(MONOLITH_PERMISSIONS.CLIENTS_DELETE) && (
          <DeleteButton onClick={() => setShowDeleteModal(true)}>
            <DeleteOutlineOutlinedIcon
              style={{
                color: theme.palette.error.main,
              }}
            />
            <div className="action-menu-label">Delete</div>
          </DeleteButton>
        )}
      </div>
      <ClientOverview
        clientInfo={clientInfo}
        handleClientUpdate={handleClientUpdate}
      />
      <DeleteClientModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onCancel={() => setShowDeleteModal(false)}
        onSubmit={() => {}}
        defaultInfo={clientInfo}
      />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1;

  overflow-y: auto;
  padding-right: 10px;
`;

const BackLink = ({ message }: { message: string }) => {
  const theme = useTheme();

  let linkTo = `/clients`;

  if (window.history?.state?.usr?.from?.includes("case:clients"))
    linkTo = `/cases/${window.history?.state?.usr?.case_id}/overview`;
  return (
    <Link
      style={{
        color: theme.palette.primary.main,
        fontSize: "small",
        marginLeft: "auto",
        marginBottom: 15,
      }}
      onMouseEnter={(event) => {
        event.currentTarget.style.textDecoration = "underline";
      }}
      onMouseLeave={(event) => {
        event.currentTarget.style.textDecoration = "none";
      }}
      to={linkTo}
    >
      <TaskButton
        style={{ outline: `1px solid ${theme.palette.divider}` }}
        title={message}
      >
        <KeyboardReturnOutlinedIcon style={{ fontSize: 15 }} />
      </TaskButton>
    </Link>
  );
};

const DeleteButton = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  margin: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }

  svg {
    font-size: 18px;
    margin-right: 5px;
    padding-bottom: 2px;
  }
`;

export default ClientDetails;
