import { createContext, useContext, useEffect, useState } from "react";
import { AuthService } from "../api/auth/index";
import { useNavigate } from "react-router-dom";
import { getLicenseInfo, userApi } from "../api";
import moment from "moment";
import { setWorkspace as setApiWorkspace } from "../api/connection";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import SplashPage from "../components/SplashPage";
import LoggingAPI from "../api/logging/index.js";
import IndexedDBService from "../IndexedDB/IndexedDBService.js";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [isDone, setIsDone] = useState(false);
  const [workspace, setWorkspace] = useState(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { isLoading, data, error, isFetched } = useQuery({
    queryKey: ["session"],
    queryFn: () => AuthService.checkSession(),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const login = async (email, password) => {
    return AuthService.userLogin({
      email,
      password,
    })
      .then((result) => {
        setUserInfo(result.userInfo);
        return result;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  };

  const logout = async () => {
    LoggingAPI.logActivity(null, userInfo.user_id, "User logged out");
    // Clear react query cache
    queryClient.clear();
    await AuthService.userLogout();
    navigate(`/login`);
    setUserInfo(null);
    setWorkspace(null);
  };

  const setSessionData = async (defaultUserInfo) => {
    if (!defaultUserInfo && !userInfo) throw new Error("No user info provided");

    const userData = defaultUserInfo || userInfo; // if defaultUserInfo is not provided, use the one from state

    let savedWorkspace = localStorage.getItem("MONOLITH.WORKSPACE");
    let hasWorkspaceAccess;
    if (savedWorkspace) {
      hasWorkspaceAccess = userData.monolith_tenants.some(
        (tenant) =>
          tenant.tenant_slug === JSON.parse(savedWorkspace).tenant_slug
      );
    }

    if (!savedWorkspace || !hasWorkspaceAccess) {
      localStorage.setItem(
        "MONOLITH.WORKSPACE",
        JSON.stringify(userData.monolith_tenants[0])
      );
    }

    let currentWorkspace =
      savedWorkspace && hasWorkspaceAccess
        ? JSON.parse(savedWorkspace).tenant_slug
        : userData.monolith_tenants[0].tenant_slug;

    setApiWorkspace(currentWorkspace);
    setWorkspace(
      userData.monolith_tenants.find((t) => t.tenant_slug === currentWorkspace)
    );

    LoggingAPI.logActivity(null, userData.user_id, "User Accessed Monolith");

    await IndexedDBService.init(`${currentWorkspace}:${userData.email}`);

    const [user, licenseInfo] = await Promise.all([
      userApi.getUsers({
        email: userData.email,
      }),
      getLicenseInfo(),
    ]);

    if (!user || user?.length === 0) {
      setIsDone(true);
      logout();
      return;
    }

    licenseInfo.is_expired = moment(licenseInfo.expire_date).isBefore(moment());

    user[0].licenseInfo = licenseInfo;
    user[0].mfa_initialized = userData.mfa_initialized;
    user[0].mfa_enabled = userData.mfa_enabled;
    LoggingAPI.logActivity(null, user[0].user_id, "User Accessed Monolith");
    setUserInfo(user[0]);
    setIsDone(true);
  };

  useEffect(() => {
    if (isLoading) return;
    if (error) {
      setIsDone(true);
      console.log("There was an error fetching the user info", error);
      return;
    }

    if (data) {
      if (data?.userInfo) {
        setSessionData(data.userInfo);
      } else setIsDone(true);
      return;
    }
  }, [data, isLoading, error, isFetched]);

  if (!isDone) return <SplashPage />;

  return (
    <AuthContext.Provider
      value={{
        currentUser: userInfo,
        isLoading: !isDone,
        isAuthenticated: !!userInfo,
        logout,
        login,
        setSessionData,
        workspace,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
