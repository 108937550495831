import { httpDelete, httpGet, httpPatch, httpPost } from "../connection.js";

//Case Reports API
async function getCaseReports(values) {
  return await httpGet(`/cases/case-reports`, values);
}

async function query(query) {
  return await httpPost("/cases/case-reports/query", query);
}

async function createCaseReport(params = {}) {
  return await httpPost(`/cases/case-reports`, params);
}

async function updateCaseReport(params = {}) {
  return await httpPatch(
    `/cases/case-reports/${params.case_report_id}`,
    params
  );
}

async function deleteCaseReport(params = {}) {
  return await httpDelete(`/cases/case-reports/${params.case_report_id}`);
}

//Case Report Exports API
async function getCaseReportExports(params = {}) {
  return await httpGet(`/cases/case-report-exports`, params);
}

async function createCaseReportExport(params = {}) {
  return await httpPost(`/cases/case-report-exports`, params);
}

async function deleteCaseReportExport(params = {}) {
  return await httpDelete(`/cases/case-report-exports/${params.report_id}`);
}

//Case Report Narratives
async function getCaseReportNarrative(params = {}) {
  return await httpGet(`/cases/report-narratives`, params);
}

async function updateCaseReportNarrative(params = {}) {
  return await httpPatch(
    `/cases/report-narratives/${params.narrative_id}`,
    params
  );
}

async function deleteCaseReportNarrative(params = {}) {
  return await httpDelete(`/cases/report-narratives/${params.narrative_id}`);
}

async function exportCaseReportList(query) {
  return await httpPost("/cases/case-reports/export", query);
}

const CaseReportsAPI = {
  query,
  getCaseReports,
  createCaseReport,
  updateCaseReport,
  deleteCaseReport,
  getCaseReportExports,
  createCaseReportExport,
  deleteCaseReportExport,
  getCaseReportNarrative,
  updateCaseReportNarrative,
  deleteCaseReportNarrative,
  exportCaseReportList,
};

export default CaseReportsAPI;
