import { Card, Divider } from "@mui/material";
import { LoadIndicator } from "devextreme-react/ui/load-indicator";
import React, { useEffect, useState } from "react";
import { getCasesReportMetrics } from "../../../../api";

const CaseReportMetrics = ({ reportInfo }) => {
  const [metrics, setMetrics] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getCasesReportMetrics({
      ...reportInfo.report_parameters,
      dimension: "stats",
    }).then((result) => {
      setMetrics(result);
      setIsLoading(false);
    });
  }, []);

  return (
    <Card
      style={{
        padding: "20px",
        textAlign: "left",
        flexGrow: 1,
        flexBasis: "10px",
      }}
    >
      <div>
        <div
          style={{
            fontSize: "larger",
          }}
        >
          Metrics
        </div>
        <Divider sx={{ my: 2 }} />
        {isLoading && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <LoadIndicator />
          </div>
        )}
        {!isLoading && (
          <div
            style={{
              minWidth: "300px",
              overflowY: "auto",
              maxHeight: "175px",
              paddingRight: "10px",
            }}
          >
            {metrics && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>Cases:</div>
                  <div>{metrics.cases_count}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>Evidence Items: </div>
                  <div>{metrics.evidence_count}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>Organizations: </div>
                  <div>{metrics.org_count}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>Acquisition Total: </div>
                  <div>
                    {metrics.acquisition_total
                      ? metrics.acquisition_total.toFixed(2) + " GB"
                      : "0.00 GB"}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>Average Close Rate: </div>
                  <div>
                    {metrics.avg_close_rate
                      ? metrics.avg_close_rate.toFixed(0) + " Days"
                      : "N/A"}{" "}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Card>
  );
};

export default CaseReportMetrics;
