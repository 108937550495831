import { Fragment, useEffect, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import {
  CalendarContainer,
  CalendarHeader,
  CalendarGrid,
  CalendarMonth,
  CalendarDay,
  CalendarDate,
  HighlightedCalendarDate,
  TodayCalendarDate,
  TimeContainer,
  MainContainer,
  TimePickerContainer,
  TimeHourSelect,
  TimeHeader,
  TimeMinuteSelect,
  TimeItem,
  TimeItemActive,
  NoValueButton,
} from "./CalendarStyles";
import calendar, {
  isDate,
  isSameDay,
  isSameMonth,
  getDateISO,
  getNextMonth,
  getPreviousMonth,
  WEEK_DAYS,
  CALENDAR_MONTHS,
  HOURS24,
  HOURS12,
  MINUTES,
} from "./calendarHelpers";
import moment from "moment";

const checkValidRange = (value, min, max) => {
  if (min && moment(value).startOf("day").isBefore(moment(min).startOf("day")))
    return false;
  if (max && moment(value).startOf("day").isAfter(moment(max).startOf("day")))
    return false;
  return true;
};

const Calendar = ({
  defaultValue = new Date(),
  value,
  onChange = () => {},
  hourFormat = "24",
  includeTime = true,
  clearable = true,
  min,
  max,
}) => {
  const [dateState, setDateState] = useState({
    current: defaultValue,
    month: defaultValue.getMonth() + 1,
    year: defaultValue.getFullYear(),
    hours: 0,
    minutes: 0,
  });
  const [today, setToday] = useState(new Date());
  let pressureTimer;
  let pressureTimeout;
  let dayTimeout;

  const addDateToState = (date) => {
    const isDateObject = isDate(date);
    const _date = isDateObject ? date : new Date();
    setDateState({
      current: isDateObject ? date : null,
      month: +_date.getMonth() + 1,
      year: _date.getFullYear(),
      hours: _date.getHours(),
      minutes: _date.getMinutes(),
    });
  };

  const getCalendarDates = () => {
    const { current, month, year } = dateState;
    const calendarMonth = month || +(current?.getMonth() || 0) + 1;
    const calendarYear = year || current?.getFullYear();
    return calendar(calendarMonth, calendarYear);
  };

  //new start

  const renderMonthAndYear = () => {
    const { month, year } = dateState;

    // Resolve the month name from the CALENDAR_MONTHS object map
    const monthname =
      Object.keys(CALENDAR_MONTHS)[Math.max(0, Math.min(month - 1, 11))];
    return (
      <CalendarHeader>
        <ChevronLeftIcon
          onMouseDown={handlePrevious}
          onMouseUp={clearPressureTimer}
          // title="Previous Month"
        />
        <CalendarMonth>
          {monthname} {year}
        </CalendarMonth>
        <ChevronRightIcon
          onMouseDown={handleNext}
          onMouseUp={clearPressureTimer}
          // title="Next Month"
        />
      </CalendarHeader>
    );
  };
  // Render the label for day of the week
  // This method is used as a map callback as seen in render()
  const renderDayLabel = (day, index) => {
    // Resolve the day of the week label from the WEEK_DAYS object map
    const daylabel = WEEK_DAYS[day];
    return (
      <CalendarDay key={daylabel} index={index}>
        {daylabel}
      </CalendarDay>
    );
  };
  // Render a calendar date as returned from the calendar builder function
  // This method is used as a map callback as seen in render()
  const renderCalendarDate = (date, index) => {
    const { current, month, year } = dateState;
    const _date = new Date(date.join("-"));
    // Check if calendar date is same day as today
    const isToday = isSameDay(_date, today);

    // Check if calendar date is same day as currently selected date
    const isCurrent = current && isSameDay(_date, current);

    // Check if calendar date is in the same month as the state month and year
    const inMonth =
      month && year && isSameMonth(_date, new Date([year, month, 1].join("-")));
    // The click handler
    const props = {
      "data-disabled": !checkValidRange(
        getDateISO(_date),
        min || null,
        max || null
      ),
      index,
      key: getDateISO(_date),
      onClick: gotoDate(_date),
      title: _date.toDateString(),
      inMonth,
    };
    // Conditionally render a styled date component

    return isCurrent ? (
      <HighlightedCalendarDate {...props}>
        {_date.getDate()}
      </HighlightedCalendarDate>
    ) : isToday ? (
      <TodayCalendarDate {...props}>{_date.getDate()}</TodayCalendarDate>
    ) : (
      <CalendarDate {...props}>{_date.getDate()}</CalendarDate>
    );
  };

  const renderHours = () => {
    const { hours } = dateState;
    const hoursArray = hourFormat === "24" ? HOURS24 : HOURS12;
    return hoursArray.map((hour, index) => {
      const HourComponent = hour.value === hours ? TimeItemActive : TimeItem;
      return (
        <HourComponent
          key={hour.value}
          // value={hour.value}
          onClick={(e) => {
            const newTime = moment(dateState.current)
              .hours(hour.value)
              .toDate();
            setDateState({
              ...dateState,
              hours: hour.value,
              current: newTime,
            });
            onChange(newTime);
          }}
        >
          {hour.label}
        </HourComponent>
      );
    });
  };

  const renderMinutes = () => {
    const { minutes } = dateState;
    return MINUTES.map((minute) => {
      const MinuteComponent = minute === minutes ? TimeItemActive : TimeItem;
      return (
        <MinuteComponent
          key={minute}
          // value={minute}
          onClick={(e) => {
            const newTime = moment(dateState.current)
              .minutes(Number(minute))
              .toDate();
            setDateState({
              ...dateState,
              minutes: Number(minute),
              current: newTime,
            });
            onChange(newTime);
          }}
        >
          {minute}
        </MinuteComponent>
      );
    });
  };

  // new 2
  const gotoDate = (date) => (evt) => {
    evt && evt.preventDefault();
    const { current } = dateState;

    // Set Hours and Minutes
    const newTime = moment(date)
      .hours(includeTime ? dateState.hours : 0)
      .minutes(includeTime ? dateState.minutes : 0)
      .toDate();

    !(current && isSameDay(newTime, current)) && addDateToState(newTime);
    onChange(newTime);
  };

  const gotoPreviousMonth = () => {
    const { month, year } = dateState;
    const previousMonth = getPreviousMonth(month, year);
    setDateState({
      month: previousMonth.month,
      year: previousMonth.year,
      current: dateState.current,
      hours: dateState.hours,
      minutes: dateState.minutes,
    });
  };

  const gotoNextMonth = () => {
    const { month, year } = dateState;
    const nextMonth = getNextMonth(month, year);
    setDateState({
      month: nextMonth.month,
      year: nextMonth.year,
      current: dateState.current,
      hours: dateState.hours,
      minutes: dateState.minutes,
    });
  };
  const gotoPreviousYear = () => {
    const { year } = dateState;
    setDateState({
      month: dateState.month,
      year: year - 1,
      current: dateState.current,
      hours: dateState.hours,
      minutes: dateState.minutes,
    });
  };
  const gotoNextYear = () => {
    const { year } = dateState;
    setDateState({
      month: dateState.month,
      year: year + 1,
      current: dateState.current,
      hours: dateState.hours,
      minutes: dateState.minutes,
    });
  };
  const clearPressureTimer = () => {
    pressureTimer && clearInterval(pressureTimer);
    pressureTimeout && clearTimeout(pressureTimeout);
  };
  const handlePrevious = (evt) => {
    evt && evt.preventDefault();
    const fn = evt.shiftKey ? gotoPreviousYear : gotoPreviousMonth;
    fn();
  };
  const handleNext = (evt) => {
    evt && evt.preventDefault();
    const fn = evt.shiftKey ? gotoNextYear : gotoNextMonth;
    fn();
  };

  // lifecycle methods
  useEffect(() => {
    const now = new Date().valueOf();
    const tomorrow = new Date().setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000;
    const ms = tomorrow - now;
    dayTimeout = setTimeout(() => {
      setToday(new Date());
      clearDayTimeout();
    }, ms);
    return () => {
      clearPressureTimer();
      clearDayTimeout();
    };
  }, []);

  const clearDayTimeout = () => {
    dayTimeout && clearTimeout(dayTimeout);
  };

  return (
    <MainContainer>
      <CalendarContainer>
        {renderMonthAndYear()}
        <CalendarGrid>
          <Fragment>{Object.keys(WEEK_DAYS).map(renderDayLabel)}</Fragment>
          <Fragment>{getCalendarDates().map(renderCalendarDate)}</Fragment>
        </CalendarGrid>
        {clearable && (
          <div style={{ textAlign: "center", marginTop: 3 }}>
            <NoValueButton onClick={() => onChange(null)}>
              Clear Date
            </NoValueButton>
          </div>
        )}
      </CalendarContainer>
      {includeTime && (
        <TimeContainer>
          <TimeHeader>Select Time</TimeHeader>
          <TimePickerContainer>
            <TimeHourSelect>{renderHours()}</TimeHourSelect>
            <TimeMinuteSelect>{renderMinutes()}</TimeMinuteSelect>
          </TimePickerContainer>
        </TimeContainer>
      )}
    </MainContainer>
  );
};

Calendar.displayName = "Calendar";

export default Calendar;
