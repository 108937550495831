import { useState } from "react";
import { Button, Modal } from "@monolith-forensics/monolith-ui";
import ButtonMenu from "../Forms/components/ButtonMenu.js";
import { usePermissions } from "../../hooks/usePermissions";
import LoggingAPI from "../../api/logging/index.js";
import { deleteEquipmentItem } from "@/api/index.js";
import { EquipmentFormData } from "../Forms/DefaultEquipmentForm.js";
import { ForensicEquipment } from "@/types/index.js";

interface DeleteEquipmentModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: (defaultInfo?: EquipmentFormData) => void;
  onCancel: () => void;
  buttonProps?: {
    cancelText: string;
    submitText: string;
  };
  defaultInfo?: ForensicEquipment;
}

const DeleteEquipmentModal: React.FC<DeleteEquipmentModalProps> = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  buttonProps,
  defaultInfo,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const { currentUser } = usePermissions();

  const handleSubmit = async () => {
    setSubmitting(true);

    await deleteEquipmentItem(defaultInfo?.item_id);

    LoggingAPI.createLog({
      user_id: currentUser.user_id,
      message: `Deleted equipment: ${defaultInfo?.asset_number}`,
      event: "equipment:delete",
      object_type: "equipment",
    });

    onSubmit?.(defaultInfo);
    setSubmitting(false);
    onClose?.();
  };

  const handleCancel = () => {
    onCancel?.();
  };

  return (
    <Modal open={show} onClose={onClose}>
      <Modal.Title>Delete Equipment Item</Modal.Title>
      <div style={{ fontWeight: 600, margin: "20px 0px" }}>
        Are you sure you want to delete this equipment item:{" "}
        <span style={{ fontWeight: 600 }}>"{defaultInfo?.asset_number}"</span>?
      </div>
      <ButtonMenu>
        <Button
          size="xs"
          variant="subtle"
          onClick={handleCancel}
          disabled={submitting}
        >
          {buttonProps?.cancelText || "Cancel"}
        </Button>
        <Button
          size="xs"
          color="error"
          variant="contained"
          onClick={handleSubmit}
          disabled={submitting}
          loading={submitting}
        >
          {buttonProps?.submitText || "Delete Equipment"}
        </Button>
      </ButtonMenu>
    </Modal>
  );
};

export default DeleteEquipmentModal;
