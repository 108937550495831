import { ListTodoIcon } from "lucide-react";
import styled from "styled-components";

const SubTasksIndicator = styled(({ className, tasks }) => {
  const subTaskCount = tasks?.length || 0;
  const taskComplete =
    tasks?.filter((task) => task.status_id === 2)?.length || 0;

  return (
    <div
      className={className}
      title={`${taskComplete} of ${subTaskCount} tasks complete`}
    >
      <div className="task-count">
        <ListTodoIcon size={14} />
        <span>{taskComplete}</span>
        <span>/</span>
        <span>{subTaskCount}</span>
      </div>
    </div>
  );
})`
  min-height: 22px;
  display: block;
  width: fit-content;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 4px;
  padding: 1px 3px;
  color: ${({ theme }) => theme.palette.text.secondary};
  cursor: default;

  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
    color: ${({ theme }) => theme.palette.text.primary};
  }

  .task-count {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.75rem;
    gap: 2px;
  }
`;

export default SubTasksIndicator;
