import { httpDelete, httpGet, httpPatch, httpPost } from "../connection";
import ChecklistGroupsAPI from "./checklist_groups.js";
import ChecklistItemsAPI from "./checklist_items.js";

export async function getQAChecklists(params = {}) {
  return await httpGet(`/qa/checklists`, params);
}

export async function createQAChecklist(values) {
  return await httpPost(`/qa/checklists`, values);
}

export async function updateQAChecklist(values) {
  return await httpPatch(`/qa/checklists`, values);
}

export async function deleteQAChecklist(values = {}) {
  return await httpDelete(`/qa/checklists`, values);
}

const QaChecklistAPI = {
  getQAChecklists,
  createQAChecklist,
  updateQAChecklist,
  deleteQAChecklist,
  ChecklistItems: ChecklistItemsAPI,
  ChecklistGroups: ChecklistGroupsAPI,
};

export default QaChecklistAPI;
