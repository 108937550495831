import styled from "styled-components";
import { useTheme, Button } from "@mui/material";

import convertBytesToSize from "../../utils/convertBytesToSize.js";

const NullViewer = styled(
  ({ className, file = {}, typeCheck, handleDownload = () => {} }) => {
    const theme = useTheme();

    return (
      <div className={className}>
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 350,
            maxHeight: "90vh",
            backgroundColor: theme.palette.background.default,
            position: "fixed",
            left: "calc(50% - 175px)",
            overflowY: "auto",
            padding: 20,
            textAlign: "center",
          }}
        >
          <div style={{ marginBottom: 10, fontSize: "large" }}>
            Preview Not Available
          </div>
          <div
            style={{ margin: "10px 0px", color: theme.palette.text.secondary }}
          >
            {typeCheck
              ? "File size is too large to preview (Max 20MB)"
              : "File type not supported"}
          </div>
          <div
            style={{ marginTop: "20px", display: "flex", textAlign: "left" }}
          >
            <div style={{ margin: "auto" }}>
              <div className="detail-container">
                <div className="detail-label">File</div>
                <div className="detail-value">{file?.file_name || ""}</div>
              </div>
              <div className="detail-container">
                <div className="detail-label">Type</div>
                <div className="detail-value">
                  {file?.file_ext ? file.file_ext.toUpperCase() : "None"}
                </div>
              </div>
              <div className="detail-container">
                <div className="detail-label">Size</div>
                <div className="detail-value">
                  {file?.file_size
                    ? convertBytesToSize(file.file_size)
                    : "0 Bytes"}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: 30,
              display: "flex",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "auto" }}
              size="small"
              onClick={handleDownload}
            >
              Download
            </Button>
          </div>
        </div>
      </div>
    );
  }
)`
  .detail-container {
    display: flex;
    margin-bottom: 5px;
    font-size: 12px;
  }
  .detail-label {
    width: 50px;
    color: ${(props) => props.theme.palette.text.secondary};
  }
  .detail-value {
    flex: 1;
    font-weight: 500;
    color: ${(props) => props.theme.palette.text.primary};
    max-width: 300px;
    overflow: hidden;
    word-break: break-all;
    word-wrap: break-word;
  }
`;

export default NullViewer;
