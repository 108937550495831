const extensionMap = new Map();

const addExtensionsToMap = (extensions, type) => {
  for (const ext of extensions) {
    extensionMap.set(ext, type);
  }
};

const ImageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg"];
const TextExtensions = ["txt", "csv", "tsv", "log"];
const CodeExtensions = [
  "js",
  "jsx",
  "ts",
  "tsx",
  "py",
  "rb",
  "php",
  "java",
  "html",
  "htm",
  "xml",
  "json",
  "css",
  "scss",
  "sass",
  "less",
  "go",
  "c",
  "cpp",
  "h",
  "hpp",
  "cs",
];
const OfficeExtensions = ["doc", "docx", "xls", "xlsx", "ppt", "pptx"];
const VideoExtensions = [
  "mp4",
  "mov",
  "wmv",
  "flv",
  "avi",
  "mkv",
  "mpg",
  "mpeg",
];

const AudioExtensions = ["mp3", "wav", "ogg", "flac", "aac", "m4a", "wma"];

addExtensionsToMap(ImageExtensions, "image");
addExtensionsToMap(TextExtensions, "text");
addExtensionsToMap(CodeExtensions, "code");
addExtensionsToMap(OfficeExtensions, "office");
addExtensionsToMap(VideoExtensions, "video");
addExtensionsToMap(AudioExtensions, "audio");

export default extensionMap;
