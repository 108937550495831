import {
  getDateFormat,
  validateTimestamp,
} from "../../../../utils/date-format";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { useQueries } from "@tanstack/react-query";
import { useState, useRef } from "react";
import { Modal, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import QualityAssuranceAPI from "../../../../api/quality_assurance/index.js";
import ToolBarItems from "../../../../components/ToolBarItems.js";
import UserApi from "../../../../api/users/users.js";
import EvidenceAPI from "../../../../api/evidence/index.js";

import { Template } from "devextreme-react/core/template";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import Loader from "../../../../components/Loader.js";
import UserSelectBoxItem from "../../../../components/SelectBoxItems/UserSelectBoxItem.js";
import EvidenceSelectBoxItem from "../../../../components/SelectBoxItems/EvidenceSelectBoxItem.js";
import { usePermissions } from "../../../../hooks/usePermissions";
import DropdownMenu from "../../../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import { EditIcon, TrashIcon } from "lucide-react";

const QaEntryColumnDefs = [
  {
    dataField: "actions",
    caption: "",
    width: 55,
    allowSorting: false,
    allowReorder: false,
    showInColumnChooser: false,
    render: (rowData, options) => (
      <ActionCell rowData={rowData} options={options} />
    ),
  },
  {
    dataField: "examiner_name",
    caption: "Examiner",
  },
  {
    dataField: "reviewer_name",
    caption: "Reviewer",
  },
  {
    dataField: "created_on",
    caption: "Created On",
    visible: false,
    dataType: "date",
    format: {
      type: getDateFormat({ isMoment: true, includeTime: false }),
    },
  },
  {
    dataField: "evidence_number",
    caption: "Evidence Number",
  },
  {
    dataField: "issue_type",
    caption: "Issue Type",
  },
  {
    dataField: "resolved",
    caption: "Resolved",
  },
  {
    dataField: "item_name",
    caption: "QA Item",
    visible: false,
    titleRender: (rowData) => rowData.item_name,
  },
  {
    dataField: "checklist_name",
    caption: "QA Checklist",
    visible: false,
  },
  {
    dataField: "details",
    caption: "Details",
    visible: false,
    titleRender: (rowData) => rowData.details,
  },
  {
    dataField: "resolution",
    caption: "Resolution",
    visible: false,
    titleRender: (rowData) => rowData.resolution,
  },
  {
    dataField: "notes",
    caption: "Notes",
    visible: false,
    titleRender: (rowData) => rowData.notes,
  },
];

export default QaEntryColumnDefs.slice();

const ActionCell = ({ rowData, options = {} }) => {
  const { hasPermission } = usePermissions();

  const [showEditModel, setShowEditModel] = useState(false);
  const [showDeleteModel, setShowDeleteModel] = useState(false);

  const handleSubmit = () => {
    options?.onRowUpdated?.();
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "left" }}>
        <DropdownMenu
          menuItems={[
            {
              label: "Edit",
              icon: EditIcon,
              onClick: () => setShowEditModel(true),
            },
            {
              label: "Delete",
              icon: TrashIcon,
              iconColor: "orangered",
              onClick: () => setShowDeleteModel(true),
            },
          ]}
          title={"Actions"}
          variant="outlined"
          disabled={!hasPermission()}
        >
          <MoreHorizOutlinedIcon style={{ fontSize: 13 }} />
        </DropdownMenu>
      </div>
      <EditModal
        open={showEditModel}
        handleClose={() => setShowEditModel(false)}
        onSubmit={() => handleSubmit()}
        defaultInfo={rowData}
      />
      <DeleteModal
        open={showDeleteModel}
        handleClose={() => setShowDeleteModel(false)}
        onSubmit={() => handleSubmit()}
        defaultInfo={rowData}
      />
    </>
  );
};

const EditModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const theme = useTheme();
  const form = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const result = useQueries({
    queries: [
      {
        queryKey: ["_users:list", { includeObservers: false }],
        queryFn: () => UserApi.getUsers({ includeObservers: false }),
        enabled: open,
      },
      {
        queryKey: ["_evidence:list", { case_id: defaultInfo.case_id }],
        queryFn: () =>
          EvidenceAPI.getEvidence({ case_id: defaultInfo.case_id }),
        enabled: open,
      },
      {
        queryKey: ["_quality-assurance:qa-entries:issue-types"],
        queryFn: () => QualityAssuranceAPI.QaEntries.getIssueTypes(),
        enabled: open,
      },
      {
        queryKey: [
          "_quality-assurance:checklists:items",
          { checklist_id: defaultInfo.checklist_id },
        ],
        queryFn: () =>
          QualityAssuranceAPI.getCheckListItems(defaultInfo.checklist_id),
        enabled: open,
      },
    ],
  });

  const handleSubmit = () => {
    if (form.current.instance.validate().isValid) {
      let values = Object.assign({}, form.current.instance.option("formData"));

      QualityAssuranceAPI.QaEntries.updateQaEntry({ ...values }).then(
        (result) => {
          enqueueSnackbar(`QA Review Updated.`, {
            variant: "success",
          });
          onSubmit();
        }
      );

      handleClose();
    }
  };

  const handleCancel = () => handleClose();

  const isDone = result.every((x) => x.isFetched);

  const users = result[0]?.data || [];
  const evidence = result[1]?.data || [];
  const issue_types = result[2]?.data || [];
  const qa_items =
    result[3]?.data?.map((itemData) => {
      return {
        ...itemData,
        key: itemData.group_name,
        items: itemData.checklist_items,
      };
    }) || [];

  const formData = {
    entry_id: defaultInfo.entry_id,
    examiner_id: defaultInfo.examiner_id,
    reviewer_id: defaultInfo.reviewer_id,
    date: defaultInfo.date,
    evidence_id: defaultInfo.evidence_id,
    issue_type: defaultInfo.issue_type,
    resolved: defaultInfo.resolved,
    checklist_item_id: defaultInfo.checklist_item_id,
    details: defaultInfo.details,
    resolution: defaultInfo.resolution,
    notes: defaultInfo.notes,
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        disableEnforceFocus={true}
        style={{
          userSelect: "none",
          zIndex: 1400,
        }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 600,
            maxHeight: "90vh",
            backgroundColor: theme.palette.background.default,
            position: "fixed",
            left: "calc(50% - 300px)",
            overflowY: "auto",
            padding: 20,
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Edit QA Entry
          </div>
          {!isDone && <Loader />}
          {isDone && (
            <>
              <Form ref={form} colCount={2} defaultFormData={formData}>
                <SimpleItem
                  dataField="examiner_id"
                  label={{ text: "Examiner" }}
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: users,
                    displayExpr: "full_name",
                    valueExpr: "user_id",
                    searchEnabled: true,
                    searchExpr: "full_name",
                    placeholder: "Select Examiner...",
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                    itemTemplate: "examiner_id",
                  }}
                />
                <Template
                  name="examiner_id"
                  render={(data) => <UserSelectBoxItem data={data} />}
                />
                <SimpleItem
                  dataField="date"
                  label={{ text: "Entry Date" }}
                  editorType="dxDateBox"
                  editorOptions={{
                    useMaskBehavior: true,
                    displayFormat: getDateFormat({
                      isMoment: false,
                      includeTime: false,
                    }),
                  }}
                  validationRules={[
                    {
                      type: "custom",
                      message: "Invalid Date",
                      validationCallback: validateTimestamp,
                    },
                  ]}
                />
                <SimpleItem
                  dataField="evidence_id"
                  label={{ text: "Evidence Item" }}
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: evidence,
                    displayExpr: "evidence_number",
                    valueExpr: "evidence_id",
                    searchEnabled: true,
                    searchExpr: "evidence_number",
                    placeholder: "Select Evidence...",
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                    itemTemplate: "evidence_id",
                  }}
                />
                <Template
                  name="evidence_id"
                  render={(data) => <EvidenceSelectBoxItem data={data} />}
                />
                <SimpleItem
                  dataField="issue_type"
                  label={{ text: "Issue Type" }}
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: issue_types,
                    displayExpr: "issue_type",
                    valueExpr: "issue_type",
                    searchEnabled: true,
                    searchExpr: "issue_type",
                    placeholder: "Select Issue...",
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                  }}
                />
                <SimpleItem
                  dataField="resolved"
                  label={{ text: "Is Resolved" }}
                  editorType="dxSelectBox"
                  editorOptions={{ items: ["Yes", "No"] }}
                />
                <SimpleItem
                  dataField="checklist_item_id"
                  label={{ text: "Associated QA Item" }}
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: qa_items,
                    displayExpr: "item_name",
                    deferRendering: false,
                    grouped: true,
                    valueExpr: "item_id",
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                  }}
                />
                <SimpleItem
                  dataField="details"
                  editorType="dxTextArea"
                  colSpan={2}
                />
                <SimpleItem
                  dataField="resolution"
                  editorType="dxTextArea"
                  colSpan={2}
                />
                <SimpleItem
                  dataField="notes"
                  editorType="dxTextArea"
                  colSpan={2}
                />
              </Form>
              <div
                style={{
                  marginTop: 30,
                }}
              >
                <ToolBarItems
                  submitText="Edit QA Entry"
                  onSubmit={handleSubmit}
                  onCancel={handleCancel}
                />
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

const DeleteModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    QualityAssuranceAPI.QaEntries.deleteQaEntry(defaultInfo.entry_id).then(
      () => {
        enqueueSnackbar(`QA Entry Deleted.`, {
          variant: "success",
        });
        onSubmit();
      }
    );
    handleClose();
  };

  const handleCancel = () => handleClose();

  return (
    <div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        disableEnforceFocus={true}
        style={{
          userSelect: "none",
          zIndex: 1400,
        }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 400,
            maxHeight: "90vh",
            backgroundColor: theme.palette.background.default,
            position: "fixed",
            left: "calc(50% - 200px)",
            overflowY: "auto",
            padding: 20,
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Delete QA Entry
          </div>
          <div style={{ margin: "10px 0px" }}>
            Are you sure you want to delete this QA Entry?
          </div>
          <div
            style={{
              marginTop: 30,
            }}
          >
            <ToolBarItems
              submitText="Delete QA Entry"
              submitColor="error"
              onSubmit={handleSubmit}
              onCancel={handleCancel}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
