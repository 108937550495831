import { useRef, useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { usePermissions } from "../../../hooks/usePermissions";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import UserGroupsAPI from "../../../api/users/groups.js";
import Loader from "../../../components/Loader.js";
import { Button, Modal } from "@mui/material";
import ComboButton from "../../../Monolith-UI/ComboButton/ComboButton.js";
import UserApi from "../../../api/users/users.js";
import { useSnackbar } from "notistack";
import { Form, SimpleItem } from "devextreme-react/ui/form.js";
import ToolBarItems from "../../../components/ToolBarItems.js";
import { nanoid } from "nanoid";
import AutoSizer from "react-virtualized-auto-sizer";

import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TaskButton from "../../../Monolith-UI/TaskButton.js";
import LoggingAPI from "../../../api/logging/index.js";
import { ItemTotal } from "../../Cases/CaseEvidence/index";
import { UsersIcon } from "lucide-react";
import AccessDeniedMessage from "../../../components/AccessDeniedMessage.js";
import { User } from "../types/Users";

interface UserGroupUser extends Partial<User> {
  assigned_on: string;
}

interface UserGroup {
  group_id: number;
  uuid: string;
  created_on: string;
  created_by_id: number;
  name: string;
  description: string;
  user_ids: number[];
  users: UserGroupUser[];
}

const GroupItem = styled(
  ({ className, children, groupInfo, handleRefresh = () => {} }) => {
    const theme: any = useTheme();
    const { hasPermission } = usePermissions();
    const queryClient = useQueryClient();
    const { currentUser } = usePermissions();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const handleAssignUser = (item: Partial<User>) => {
      if (!hasPermission()) return;
      const { user_id } = item;

      queryClient.setQueryData<{ data: UserGroup[] }>(
        ["user-groups:list", {}],
        (oldData) => {
          const newData = oldData?.data?.map((g) => {
            if (g.uuid === groupInfo.uuid) {
              return {
                ...g,
                users: [...g.users, item],
                user_ids: [...g.user_ids, item.user_id],
              };
            }
            return g;
          });
          return {
            ...oldData,
            data: newData,
          } as { data: UserGroup[] };
        }
      );

      UserGroupsAPI.assign({
        user_id,
        group_uuid: groupInfo.uuid,
        created_on: new Date().toISOString(),
        created_by_id: currentUser?.uuid || currentUser?.user_id,
      }).then(() => {
        LoggingAPI.logActivity(
          null,
          currentUser.user_id,
          `User Assigned to Group: ${item.full_name} (${item.email}) added to the ${groupInfo.name} (${groupInfo.uuid}) group`
        );
      });
    };

    const handleUnAssignUser = (item: Partial<User>) => {
      if (!hasPermission()) return;

      const { user_id } = item;

      queryClient.setQueryData<{ data: UserGroup[] }>(
        ["user-groups:list", {}],
        (oldData) => {
          const newData = oldData?.data?.map((g) => {
            if (g.uuid === groupInfo.uuid) {
              return {
                ...g,
                users: g.users.filter((m) => m.user_id !== user_id),
                user_ids: g.user_ids.filter((m) => m !== user_id),
              };
            }
            return g;
          });
          return {
            ...oldData,
            data: newData,
          } as { data: UserGroup[] };
        }
      );

      UserGroupsAPI.unassign({
        user_id,
        group_uuid: groupInfo.uuid,
      }).then(() => {
        LoggingAPI.logActivity(
          null,
          currentUser.user_id,
          `User Removed from Group: ${item.full_name} (${item.email}) removed from the ${groupInfo.name} (${groupInfo.uuid}) group`
        );
      });
    };

    const { data: users } = useQuery({
      queryKey: ["users:list", {}],
      queryFn: () => UserApi.getUsers({}),
      initialData: [],
    });

    return (
      <div className={className}>
        <div className="top-content">
          <div className="group-info-container">
            <div className="group-name">{groupInfo.name}</div>
            <div className="group-id">Group ID {groupInfo.uuid}</div>
            <div className="group-description">{groupInfo.description}</div>
          </div>
          {hasPermission() && (
            <div className="group-options-container">
              <TaskButton
                title="Delete User Group"
                onClick={() => setShowDeleteModal(true)}
              >
                <DeleteOutlineOutlinedIcon style={{ fontSize: "1rem" }} />
              </TaskButton>
              <TaskButton
                title="Edit User Group"
                onClick={() => setShowEditModal(true)}
              >
                <EditOutlinedIcon style={{ fontSize: "1rem" }} />
              </TaskButton>
              <ComboButton
                type="multi-select"
                data={users}
                displayField="full_name"
                idField="user_id"
                selectedItems={groupInfo.users || []}
                variant="outlined"
                closeOnSelect={false}
                showSearch={true}
                textColor={theme.palette.text.primary}
                showDropdownIcon={true}
                onItemSelect={handleAssignUser}
                onItemDeSelect={handleUnAssignUser}
                title={undefined}
              >
                Manage Users
              </ComboButton>
            </div>
          )}
        </div>
        <div className="bottom-content">
          <div className="members-list">
            {groupInfo.users.length === 0 && (
              <div
                style={{ fontSize: 12, color: theme.palette.text.secondary }}
              >
                No Users Assigned
              </div>
            )}
            {groupInfo.users.map((m: Partial<User>) => (
              <UserLabel
                key={m.user_id}
                userInfo={m}
                groupInfo={groupInfo}
                handleUnAssignUser={handleUnAssignUser}
              />
            ))}
          </div>
        </div>
        <DeleteModal
          open={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          onSubmit={handleRefresh}
          groupInfo={groupInfo}
        />
        <EditModal
          open={showEditModal}
          handleClose={() => setShowEditModal(false)}
          onSubmit={handleRefresh}
          groupInfo={groupInfo}
        />
      </div>
    );
  }
)`
  height: auto;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 5px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  .top-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .group-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }
  .group-options-container {
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    justify-content: center;
    align-items: center;
    width: 10rem;
  }
  .group-name {
    font-size: 1.25rem;
    font-weight: 600;
  }
  .group-id {
    font-size: 0.6rem;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
  .group-description {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-top: 1rem;
    font-weight: 500;
  }

  .bottom-content {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    padding-top: 0.5rem;
  }
  .members-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
  .members-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 0.35rem;
    margin-top: 0.5rem;
  }
`;

const UserLabel = styled(
  ({ className, children, userInfo, groupInfo, handleUnAssignUser }) => {
    return (
      <div
        className={className}
        title={`${userInfo.full_name}\n${userInfo.email}\n${userInfo.title}`}
      >
        <div className="user-name">{userInfo.full_name}</div>
        <div
          className="remove-icon"
          onClick={() => handleUnAssignUser(userInfo)}
        >
          <CloseIcon />
        </div>
      </div>
    );
  }
)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.palette.background.secondary};
  margin-bottom: 0.25rem;
  width: fit-content;
  min-width: fit-content;
  .user-name {
    font-size: 0.7rem;
    font-weight: 500;
    white-space: nowrap;
  }
  .remove-icon {
    font-size: 0.25rem;
    font-weight: 500;
    white-space: nowrap;
    margin-left: 0.5rem;
    cursor: pointer;
    color: ${({ theme }) => theme.palette.text.secondary};
    display: flex;
    &:hover {
      background-color: ${({ theme }) => theme.palette.action.hover};
    }
    svg {
      font-size: 0.75rem;
    }
  }
`;

const CreateModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
}: {
  open: boolean;
  handleClose: () => void;
  onSubmit: () => void;
}) => {
  const { currentUser } = usePermissions();
  const theme: any = useTheme();
  const form: any = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    if (!form.current.instance.validate().isValid) return;

    const values = { ...form.current.instance.option("formData") };

    handleClose();

    UserGroupsAPI.create({
      ...values,
      created_on: new Date().toISOString(),
      created_by_id: currentUser?.uuid || currentUser?.user_id,
      uuid: nanoid(),
    }).then((result) => {
      enqueueSnackbar("User Group Created", { variant: "success" });
      onSubmit();
      LoggingAPI.logActivity(
        null,
        currentUser.user_id,
        `User Group Created: ${values.name}`
      );
    });
  };

  useEffect(() => {
    if (form.current && open) {
      form.current.instance.resetValues();
      form.current.instance.getEditor("name").focus();
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 700,
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 350px)",
          top: 20,
          overflowY: "auto",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Create User Group
        </div>
        <Form ref={form} colCount={1}>
          <SimpleItem
            dataField="name"
            label={{ text: "Group Name" }}
            isRequired={true}
          />
          <SimpleItem
            dataField="description"
            label={{ text: "Description" }}
            editorType={"dxTextArea"}
            editorOptions={{
              height: 100,
            }}
          />
        </Form>
        <div style={{ marginTop: 20 }}>
          <ToolBarItems
            submitText="Create Group"
            onSubmit={handleSubmit}
            onCancel={handleClose}
          />
        </div>
      </div>
    </Modal>
  );
};

const EditModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  groupInfo,
}: {
  open: boolean;
  handleClose: () => void;
  onSubmit: () => void;
  groupInfo: UserGroup;
}) => {
  const { currentUser } = usePermissions();
  const theme: any = useTheme();
  const form: any = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    if (!form.current.instance.validate().isValid) return;

    const values = { ...form.current.instance.option("formData") };

    handleClose();

    UserGroupsAPI.update({
      ...values,
      uuid: groupInfo.uuid,
    }).then((result) => {
      enqueueSnackbar("User Group Updated", { variant: "success" });
      onSubmit();

      let logMessage = `User Group Updated: `;

      if (groupInfo.name !== values.name) {
        logMessage += `\nName: ${groupInfo.name} >> ${values.name} `;
      }
      if (groupInfo.description !== values.description) {
        logMessage += `\nDescription: ${groupInfo.description} >> ${values.description} `;
      }

      LoggingAPI.logActivity(null, currentUser.user_id, logMessage);
    });
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 700,
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 350px)",
          top: 20,
          overflowY: "auto",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Edit User Group
        </div>
        <Form
          ref={form}
          colCount={1}
          defaultFormData={{
            name: groupInfo.name,
            description: groupInfo.description,
          }}
        >
          <SimpleItem
            dataField="name"
            label={{ text: "Group Name" }}
            isRequired={true}
          />
          <SimpleItem
            dataField="description"
            label={{ text: "Description" }}
            editorType={"dxTextArea"}
            editorOptions={{
              height: 100,
            }}
          />
        </Form>
        <div style={{ marginTop: 20 }}>
          <ToolBarItems
            submitText="Edit Group"
            onSubmit={handleSubmit}
            onCancel={handleClose}
          />
        </div>
      </div>
    </Modal>
  );
};

const DeleteModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  groupInfo,
}: {
  open: boolean;
  handleClose: () => void;
  onSubmit: () => void;
  groupInfo: UserGroup;
}) => {
  const { currentUser } = usePermissions();
  const theme: any = useTheme();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    handleClose();

    UserGroupsAPI.delete({
      uuid: groupInfo.uuid,
    }).then((result) => {
      enqueueSnackbar("User Group Deleted", { variant: "success" });
      onSubmit();
      LoggingAPI.logActivity(
        null,
        currentUser.user_id,
        `User Group Deleted: ${groupInfo.name}`
      );
    });
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 400,
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 200px)",
          top: 20,
          overflowY: "auto",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Delete User Group
        </div>
        <div style={{ marginBottom: 15, fontSize: "0.75rem" }}>
          Are you sure you want to delete this group?
        </div>
        <div style={{ fontSize: "0.75rem", fontWeight: 500, display: "flex" }}>
          <div
            style={{
              width: 75,
              color: theme.palette.text.secondary,
              fontWeight: 500,
            }}
          >
            Group
          </div>
          <div style={{}}>{groupInfo.name}</div>
        </div>
        <div style={{ fontSize: "0.75rem", fontWeight: 500, display: "flex" }}>
          <div
            style={{
              width: 75,
              color: theme.palette.text.secondary,
              fontWeight: 500,
            }}
          >
            Users
          </div>
          <div style={{}}>{groupInfo.users.length}</div>
        </div>
        <div style={{ marginTop: 20 }}>
          <ToolBarItems
            submitText="Delete Group"
            submitColor="error"
            onSubmit={handleSubmit}
            onCancel={handleClose}
          />
        </div>
      </div>
    </Modal>
  );
};

const GroupsTabContentWrapper = styled(({ className }) => {
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const queryClient = useQueryClient();
  const [showCreateModal, setShowCreateModal] = useState(false);

  const handleRefresh = () => {
    queryClient.refetchQueries({ queryKey: ["user-groups:list", {}] });
  };

  const { data } = useQuery({
    queryKey: ["user-groups:list", {}],
    queryFn: () => UserGroupsAPI.get({}),
    placeholderData: (data) => data,
    enabled: hasPermission(MONOLITH_PERMISSIONS.USER_GROUPS_MANAGE),
  });

  if (!data) {
    return <Loader message={"Retrieving User Groups..."} />;
  }

  const groups = data?.data || [];
  const total = data?.total || 0;

  if (!hasPermission(MONOLITH_PERMISSIONS.USER_GROUPS_MANAGE)) {
    return <AccessDeniedMessage />;
  }

  return (
    <div data-name="group-content" className={className}>
      <AutoSizer>
        {({ height, width }) => {
          return (
            <div style={{ height, width }}>
              <div
                style={{
                  display: "flex",
                  flex: "initial",
                  flexDirection: "row",
                  alignContent: "center",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={
                    !hasPermission(MONOLITH_PERMISSIONS.USER_GROUPS_MANAGE)
                  }
                  onClick={(e) => {
                    setShowCreateModal(true);
                  }}
                  style={{
                    minWidth: "fit-content",
                    fontSize: 11,
                    padding: "3px 6px",
                  }}
                >
                  + New Group
                </Button>
                <ItemTotal total={total || 0} Icon={UsersIcon} />
              </div>
              <div className="group-list">
                {groups.map((g: UserGroup) => (
                  <GroupItem
                    key={g.uuid}
                    groupInfo={g}
                    handleRefresh={handleRefresh}
                  />
                ))}
              </div>
            </div>
          );
        }}
      </AutoSizer>

      <CreateModal
        open={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
        onSubmit={handleRefresh}
      />
    </div>
  );
})`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .group-list {
    height: calc(100% - 2rem);
    overflow-y: auto;
    padding-right: 5px;
  }
`;

const GroupsTabContent = () => {
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();

  if (!hasPermission(MONOLITH_PERMISSIONS.USER_GROUPS_MANAGE))
    return <AccessDeniedMessage />;

  return <GroupsTabContentWrapper />;
};

export default GroupsTabContent;
