import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";

const BreadCrumbsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const BreadCrumbList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const BreadCrumbItem = styled.div`
  cursor: pointer;
  padding: 4px 16px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  border-radius: 6px;
  // margin-bottom: 5px;
  clip-path: polygon(
    0 0,
    calc(100% - 8px) 0,
    100% 50%,
    calc(100% - 8px) 100%,
    0 100%,
    8px 50%
  );
  margin-right: -5px;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: #36a2ef33;

  &.text {
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: transparent;
    clip-path: none;
    // border: 1px solid ${({ theme }) => theme.palette.divider};
    margin-right: 0;
    padding: 4px 8px;
  }

  &:last-child {
    border-radius: 0 6px 6px 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 8px 50%);
    background-color: ${({ theme }) => theme.palette.divider};
    color: ${({ theme }) => theme.palette.text.primary};
    cursor: default;
    &:hover {
      text-decoration: none;
    }
  }

  &.text:last-child {
    background-color: transparent;
    clip-path: none;
    border-radius: 6px;
  }

  &:first-child {
    clip-path: polygon(
      0 0,
      calc(100% - 8px) 0,
      100% 50%,
      calc(100% - 8px) 100%,
      0 100%
    );
  }

  &.text:first-child {
    clip-path: none;
  }

  &:only-child {
    border-radius: 6px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  &.text:only-child {
    clip-path: none;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const BreadCrumbDivider = styled.div`
  margin: 0px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const BreadCrumbs = styled(
  ({ className, list, onItemSelect, displayName, variant = "contained" }) => {
    const handleClick = (item) => {
      if (onItemSelect) {
        onItemSelect(item);
      }
    };

    const lastItemIndex = list.length - 1;

    return (
      <BreadCrumbsContainer className={className} variant={variant}>
        {list.map((item, index) => (
          <BreadCrumbList key={nanoid()}>
            <BreadCrumbItem
              className={"bread-crumb-item " + variant}
              onClick={() => handleClick(item)}
            >
              {displayName ? item[displayName] : item.name}
            </BreadCrumbItem>
            {variant === "text" && index !== lastItemIndex && (
              <BreadCrumbDivider className="bread-crumb-divider">
                /
              </BreadCrumbDivider>
            )}
          </BreadCrumbList>
        ))}
      </BreadCrumbsContainer>
    );
  }
)``;

export default BreadCrumbs;
