import { httpDelete, httpGet, httpPatch, httpPost } from "../connection.js";

/// Auth Services
const getStatus = async () => {
  return await httpGet(`/monolith-api-test`);
};

const getAccessToken = async (values = {}) =>
  await httpPost(`/auth/token`, values);

const userLogin = async (values = {}) => {
  return await httpPost(`/auth/login`, values);
};

const ssoLogin = async (values = {}) => {
  return await httpPost(`/auth/sso/callback`, values);
};

const checkSession = async (values = {}) => {
  return await httpGet(`/check-session`, values);
};

const userLogout = async (values = {}) => {
  return await httpPost(`/auth/logout`, values);
};

const resetPasswordRequest = async (values = {}) => {
  return await httpPost(`/auth/password-reset`, values);
};
const updatePassword = async (values = {}) => {
  return await httpPost(`/auth/update-password`, values);
};
const checkPasswordReset = async (values = {}) => {
  return await httpGet(`/auth/check-password-reset`, values);
};

const getMfa = async (values = {}) => {
  return await httpGet(`/auth/mfa`, values);
};

const updateMfa = async (values = {}) => {
  return await httpPatch(`/auth/mfa`, values);
};

const verifyMfa = async (values = {}) => {
  if (values.mfaSecret) return await httpPost(`/auth/mfa-verify`, values);
  else return await httpPost(`/auth/mfa`, values);
};

export const AuthService = {
  getStatus,
  userLogin,
  ssoLogin,
  userLogout,
  checkSession,
  resetPasswordRequest,
  updatePassword,
  checkPasswordReset,
  getAccessToken,
  getMfa,
  updateMfa,
  verifyMfa,
};
