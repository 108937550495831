import { Link } from "react-router-dom";
import moment from "moment";
import Loader from "../../../../components/Loader";
import InquiriesAPI from "../../../../api/inquiries";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { nanoid } from "nanoid";

const NewInquiries = styled(({ className }) => {
  const { data } = useQuery({
    queryKey: ["inquiries:list", { status: "New" }],
    queryFn: () => InquiriesAPI.getInquiries({ status: "New" }),
  });

  if (!data) return <Loader />;

  if (data.length === 0)
    return <div style={{ textAlign: "center" }}>No New Inquiries</div>;

  return (
    <div className={className}>
      <div className="new-inquries-container">
        <div className="new-inquries-grid">
          {data.map((inquiry) => (
            <InquiryItem key={nanoid()} inquiry={inquiry} />
          ))}
        </div>
      </div>
    </div>
  );
})`
  height: calc(100% - 50px);
  overflow-y: auto;
  display: flex;

  .new-inquries-container {
    width: 100%;
    padding: 0px 10px;
    margin: 0px auto;
  }
  .new-inquries-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
    grid-gap: 15px;
  }
`;

const InquiryItem = styled(({ className, inquiry }) => {
  return (
    <Link to={`/inquiries/${inquiry.inquiry_id}`}>
      <div className={className} title="View Inquiry">
        <div className="request-name">
          {inquiry.request_name || "No Request Name"}
        </div>
        <div className="inquiry-name">
          {inquiry.inquiry_name || "Unknown Requestor"}
        </div>
        <div className="inquiry-name">
          {inquiry.inquiry_org || "No Organization"}
        </div>
        <div className="inquiry-type">
          {inquiry.inquiry_type || "Unknown Type"}
        </div>
        <div className="inquiry-date">
          {moment.utc(inquiry.inquiry_date).fromNow()}
        </div>
      </div>
    </Link>
  );
})`
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.palette.divider};
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  font-size: 10px;
  width: 100%;
  cursor: pointer;
  user-select: none;
  &:hover {
    border: 1px solid ${(props) => props.theme.palette.primary.main};
  }
  .request-name {
    font-size: 14px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .inquiry-name {
    font-size: 10px;
    color: ${(props) => props.theme.palette.text.secondary};
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .inquiry-type {
    font-size: 12px;
    color: ${(props) => props.theme.palette.primary.main};
    margin-top: 15px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .inquiry-date {
    font-size: 10px;
    color: ${(props) => props.theme.palette.text.secondary};
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
`;

export default NewInquiries;
