import { Briefcase, Grid, Activity } from "react-feather";
import ImportantDevicesOutlinedIcon from "@mui/icons-material/ImportantDevicesOutlined";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import GroupIcon from "@mui/icons-material/Group";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import BiotechOutlinedIcon from "@mui/icons-material/BiotechOutlined";
import { RocketIcon } from "lucide-react";
import { MONOLITH_PERMISSIONS } from "../../constants.js";

const pagesSection = [
  {
    icon: Grid,
    title: "Dashboard",
    children: [
      {
        href: "/dashboard/overview/user",
        title: "Overview",
      },
      {
        href: "/dashboard/tasks",
        title: "Tasks",
      },
    ],
  },
  {
    icon: Briefcase,
    title: "Case Management",
    children: [
      {
        href: "/cases",
        title: "Cases",
      },
      {
        href: "/inquiries",
        title: "Inquiries",
        permission: MONOLITH_PERMISSIONS.INQUIRIES_READ_ALL,
      },
    ],
  },
  {
    icon: ImportantDevicesOutlinedIcon,
    title: "Evidence Management",
    children: [
      {
        href: "/evidence",
        title: "Evidence Items",
      },
      {
        href: "/storage",
        title: "Storage Items",
        permission: MONOLITH_PERMISSIONS.STORAGE_READ_ALL,
      },
      {
        href: "/locations",
        title: "Item Locations",
        permission: MONOLITH_PERMISSIONS.LOCATIONS_READ_ALL,
      },
      {
        href: "/audits",
        title: "Audits",
        permission: MONOLITH_PERMISSIONS.AUDITS_READ,
      },
    ],
  },
  {
    icon: GroupIcon,
    title: "People",
    children: [
      {
        href: "/clients",
        title: "Clients",
        permission: MONOLITH_PERMISSIONS.CLIENTS_READ_ALL,
      },
      {
        href: "/contacts",
        title: "Contacts",
        permission: MONOLITH_PERMISSIONS.CONTACTS_READ_ALL,
      },
    ],
  },
  {
    icon: BiotechOutlinedIcon,
    title: "Lab Management",
    children: [
      {
        href: "/software",
        title: "Forensic Software",
        permission: MONOLITH_PERMISSIONS.SOFTWARE_READ_ALL,
      },
      {
        href: "/equipment",
        title: "Equipment",
        permission: MONOLITH_PERMISSIONS.EQUIPMENT_READ_ALL,
      },
    ],
  },
  {
    icon: ManageAccountsIcon,
    title: "Organization",
    children: [
      {
        href: "/team",
        title: "Team Management",
        permission: MONOLITH_PERMISSIONS.USERS_MANAGE,
      },
      {
        href: "/offices",
        title: "Offices",
        permission: MONOLITH_PERMISSIONS.OFFICES_READ_ALL,
      },
    ],
  },
  {
    icon: Activity,
    title: "Analytics",
    children: [
      {
        href: "/reports",
        title: "Reports",
        permission: MONOLITH_PERMISSIONS.METRICS_REPORTS_READ,
      },
    ],
  },
  {
    href: "/settings",
    icon: SettingsOutlinedIcon,
    title: "Settings",
  },
  {
    href: "/settings/release-notes",
    icon: RocketIcon,
    title: "Release Notes",
  },
];

const navItems = [
  {
    pages: pagesSection,
  },
];

export default navItems;
