import { httpDelete, httpGet, httpPatch, httpPost } from "../connection";

export async function createCocRecord(
  values = {
    evidence_id: 0,
    custody_to: "",
    custody_from: "",
    reason: "",
    timestamp: "",
    entered_by: "",
  }
) {
  return await httpPost(`/evidence/items/${values.evidence_id}/coc`, values);
}

export async function createBulkCocRecords(values = {}) {
  return await httpPost(`/evidence/coc/bulk`, values);
}

export async function getCocRecords(options = { evidence_id: null }) {
  return await httpGet(`/evidence/items/${options.evidence_id}/coc`);
}

export async function getCocRecord(options = { coc_id: null }) {
  return await httpGet(`/evidence/coc/${options.coc_id}`);
}

export async function updateCocRecord(id, values) {
  return await httpPatch(`/evidence/coc/${id}`, values);
}

export async function deleteCocRecord(id) {
  return await httpDelete(`/evidence/coc/${id}`);
}

export async function getCocMoveReasons() {
  return await httpGet(`/evidence/coc/move-reasons`);
}
