import { useState } from "react";
import { Modal } from "@monolith-forensics/monolith-ui";
import Loader from "../Loader.js";
import { useMutation } from "@tanstack/react-query";
import LoggingAPI from "../../api/logging/index.js";
import { usePermissions } from "../../hooks/usePermissions";
import { DefaultUserRoleForm } from "../Forms";
import UserRolesAPI from "../../api/UserRoles/UserRoles.js";

const EditUserRoleModal = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  defaultFormData,
  ...props
}) => {
  const { currentUser } = usePermissions();
  const [submitting, setSubmitting] = useState(false);

  const update = useMutation({
    mutationFn: UserRolesAPI.update,
    onMutate: () => setSubmitting(true),
    onSuccess: (_, variables) => {
      LoggingAPI.createLog({
        user_id: currentUser.user_id,
        message: `Updated user role.`,
        event: "user_role:update",
        object_type: "user_role",
        object_uuid: defaultFormData.uuid,
      });
      onSubmit?.(defaultFormData, variables);
      setSubmitting(false);
    },
  });

  return (
    <Modal
      open={show}
      onClose={onClose}
      style={{ width: 550 }}
      FocusProps={{ initialFocus: -1 }}
    >
      <Modal.Title style={{ marginBottom: 10 }}>Edit User Role</Modal.Title>
      {!submitting && (
        <DefaultUserRoleForm
          onSubmit={(data, diff) => update.mutate({ uuid: data.uuid, ...diff })}
          onCancel={onCancel}
          defaultFormData={defaultFormData}
          {...props}
        />
      )}
      {submitting && <Loader />}
    </Modal>
  );
};

export default EditUserRoleModal;
