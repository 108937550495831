import { useQueries } from "@tanstack/react-query";
import { z } from "zod";
import { useForm } from "@mantine/form";

import {
  FormSection,
  Grid,
  SelectBox,
  TextInput,
  Button,
  DateInput,
  FieldLabel,
  TextAreaInput,
} from "@monolith-forensics/monolith-ui";
import ClientListItem from "./components/ClientListItem.js";
import ButtonMenu from "./components/ButtonMenu.js";

import CasesApi from "../../api/cases/index.js";

import resolveCustomFieldValidations from "./utils/resolveCustomFieldValidations.js";
import convertCustomFieldsToArray from "./utils/convertCustomFieldsToArray.js";
import validateAsync from "./utils/zod/validateAsync.js";
import CustomFieldRender from "./components/CustomFieldRender.js";
import diffFormData from "./utils/diffFormData.js";
import { useRef, useState } from "react";
import EvidenceAPI from "../../api/evidence/index.js";
import LocationsAPI from "../../api/locations/index.js";
import ContactsAPI from "../../api/contacts/index.js";
import CaseListItem from "./components/CaseListItem.js";
import LocationListItem from "./components/LocationListItem.js";
import SignatureField from "../SignatureField/SignatureField.js";
import { usePermissions } from "../../hooks/usePermissions";
import moment from "moment";
import { getDateFormat } from "../../utils/date-format";

const DefaultEvidenceForm = ({
  defaultFormData = {},
  buttonProps = {},
  onSubmit,
  onCancel,
  includeCaseField = false,
  includeCoc = false,
  includeCustomFields = true,
  includeBasicFields = true,
}) => {
  const { currentUser } = usePermissions();
  const receivedBySig = useRef(null);
  const receivedFromSig = useRef(null);

  const results = useQueries({
    queries: [
      {
        queryKey: ["evidence", "types", "list"],
        queryFn: () => EvidenceAPI.getEvidenceTypes(),
      },
      {
        queryKey: ["evidence", "providers", "list"],
        queryFn: () => EvidenceAPI.getEvidenceBrands(),
      },
      {
        queryKey: ["evidence", "locations", "list", { include_groups: false }],
        queryFn: () => LocationsAPI.getLocations({}, { include_groups: false }),
      },
      {
        queryKey: ["contacts", "list"],
        queryFn: () => ContactsAPI.getContacts(),
      },
      {
        queryKey: ["cases", "caseNumbers", "list"],
        queryFn: () => CasesApi.getCaseNumbers(),
      },
      {
        queryKey: ["evidence", "custom-fields", "list", { fieldsOnly: true }],
        queryFn: () => EvidenceAPI.getCustomFields({ fieldsOnly: true }),
      },
    ],
  });

  const isDone = results.every((result) => result.isFetched);

  const evidenceTypes = results[0].data || [];
  const evidenceProviders = results[1].data || [];
  const evidenceLocations = results[2].data || [];
  const contacts = results[3]?.data || [];
  const caseNumbers = results[4].data || [];
  const customFields = results?.[5]?.data || []; // filter out disabled fields

  // Validation schema
  const schema = z
    .object({
      case_id: includeCaseField
        ? z.number().int().positive().min(1)
        : z.number().int().positive().optional(), // optional for edit context, or when evidence is being created within a case
      evidence_number: z
        .string()
        .regex(/^[^<>:"/\\|?*]+$/, 'Evidence number cannot contain: <>:"/\\|?*')
        .optional(),
      custody_to: z.number(),
      custody_from: z.string(),
      coc_intake_timestamp: z.string(),
      ...(includeCustomFields
        ? resolveCustomFieldValidations(customFields)
        : {}),
    })
    .refine(
      (data) => {
        // if any of the coc fields are filled, all must be filled
        const { custody_to, custody_from, coc_intake_timestamp } = data;
        const fields = [custody_to, custody_from, coc_intake_timestamp];
        const completedFields = fields.filter(
          (f) => f !== null && f !== "" && f !== undefined
        );
        return (
          completedFields.length === 0 ||
          completedFields.length === fields.length
        );
      },
      {
        message: "All chain of custody fields are required",
        path: ["custody_to", "custody_from", "coc_intake_timestamp"],
      }
    );

  const form = useForm({
    mode: "uncontrolled",
    initialValues: defaultFormData,
  });

  const modelFormData = (formData) => {
    const signature = {};

    if (includeCoc) {
      signature.custody_to_sig = receivedBySig?.current?.isEmpty?.()
        ? null
        : {
            signature: receivedBySig.current.getSignature(),
            signer: evidenceLocations.find(
              (loc) => loc.location_id === formData.custody_to
            )?.location_name,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: null,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      signature.custody_from_sig = receivedFromSig?.current?.isEmpty?.()
        ? null
        : {
            signature: receivedFromSig.current.getSignature(),
            signer: formData.custody_from,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: null,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };
    }

    const modeledFormData = {
      case_id: formData?.case_id || null,
      evidence_number: formData.evidence_number || null,
      item_type: formData?.item_type || null,
      manufacturer: formData?.manufacturer || null,
      model_name: formData?.model_name || null,
      model_number: formData.model_number || null,
      serial_number: formData.serial_number || null,
      capacity: formData.capacity || null,
      capacity_unit: formData.capacity_unit || null,
      contact_id: formData?.contact_id || null,
      description: formData.description || null,
      custody_to: formData.custody_to?.location_id || null,
      custody_from: formData.custody_from || null,
      coc_intake_timestamp: formData.coc_intake_timestamp || null,
      location_received: formData.location_received || null,
      reason: formData.reason || null,
      signature,
      custom_attributes: convertCustomFieldsToArray(customFields, formData),
    };

    return modeledFormData;
  };

  const handleSubmit = async () => {
    const submitData = form.getValues();
    const validationResult = await validateAsync(schema)(submitData);

    if (validationResult.hasErrors) {
      console.error("Validation Errors", validationResult.errors);
      form.setErrors(validationResult.errors);
      return;
    }

    const modeledFormData = modelFormData(submitData);
    const modeledDiffData = diffFormData(defaultFormData, submitData);

    // convert any custom fields in diff to an array
    if (
      Object.keys(modeledDiffData).some((key) =>
        key.startsWith("custom_attribute_")
      )
    ) {
      modeledDiffData.custom_attributes = convertCustomFieldsToArray(
        customFields,
        modeledDiffData
      );

      // remove custom fields from diff
      Object.keys(modeledDiffData).forEach((key) => {
        if (key.startsWith("custom_attribute_")) {
          delete modeledDiffData[key];
        }
      });
    }

    // call onSubmit with modeled data and diff data
    // diff data is used when the form is in edit context
    // diff may be used when we only want to update modified values
    onSubmit?.(modeledFormData, modeledDiffData);
  };

  const handleCancel = () => {
    form.reset();
    onCancel?.();
  };

  return (
    <>
      {includeBasicFields && (
        <FormSection title="Basic Information">
          <Grid col={2}>
            {includeCaseField && (
              <>
                <SelectBox
                  size="sm"
                  variant="outlined"
                  label="Case"
                  description={
                    "This is the case that the evidence is being collected for.  This is the case that the evidence will be associated with and will be used to track the progress of the evidence."
                  }
                  required
                  searchable
                  clearable
                  data={caseNumbers?.map((c) => ({
                    label: c.case_number,
                    value: c.case_id,
                    data: c,
                  }))}
                  renderOption={(option) => {
                    return <CaseListItem caseNumber={option.data} />;
                  }}
                  key={form.key("case_id")}
                  {...form.getInputProps("case_id")}
                />
                <div></div>
              </>
            )}
            <TextInput
              size="sm"
              variant="outlined"
              label="Evidence Number"
              placeholder="Leave blank for auto-generation"
              description={
                "This is the unique identifier for the evidence - repeat evidence numbers may be used.\n\nIf left blank, a unique identifier will be generated automatically."
              }
              key={form.key("evidence_number")}
              {...form.getInputProps("evidence_number")}
            />
            <SelectBox
              size="sm"
              variant="outlined"
              label="Evidence Type"
              description={
                "This is the type of evidence that you are working on.  This is used to categorize your evidence and can be used to filter your evidence list.\n\nThis list can also be fully customized in the Monolith settings menu."
              }
              clearable
              searchable
              data={evidenceTypes?.map((e) => ({
                label: e.evi_type,
                value: e.evi_type,
                data: e,
              }))}
              key={form.key("item_type")}
              {...form.getInputProps("item_type")}
            />
            <SelectBox
              size="sm"
              variant="outlined"
              label="Provider"
              description={
                "This is the manufacturer or provider of the evidence.  This is used to track the source of the evidence and can be used to filter your evidence list.\n\nThis list will populate over time as you add more items."
              }
              allowCustomValue
              clearable
              searchable
              data={evidenceProviders?.map((ep) => ({
                label: ep.manufacturer,
                value: ep.manufacturer,
                data: ep,
              }))}
              key={form.key("manufacturer")}
              {...form.getInputProps("manufacturer")}
            />
            <TextInput
              size="sm"
              variant="outlined"
              label="Item Name"
              placeholder="iPhone 13 Pro, Smith Gmail Account, etc."
              description="This is the name of the evidence item. Consider using the model name of the item or something short and memorable to help easily identify the item."
              key={form.key("model_name")}
              {...form.getInputProps("model_name")}
            />
            <TextInput
              size="sm"
              variant="outlined"
              label="Model Number or Service"
              placeholder="A4521, WD2500, Gmail, etc."
              description="This is the name of the model or service that the evidence item is associated with. This can be a model number, serial number, or service name."
              key={form.key("model_number")}
              {...form.getInputProps("model_number")}
            />
            <TextInput
              size="sm"
              variant="outlined"
              label="Unique Identifier"
              placeholder="Serial number, Account name, etc."
              description="This is the unique identifier for the evidence item. This can be a serial number, account name, or any other unique identifier associated with the item or account."
              key={form.key("serial_number")}
              {...form.getInputProps("serial_number")}
            />
            <Grid col={2}>
              <TextInput
                size="sm"
                variant="outlined"
                label="Size"
                placeholder="1, 2, 50, etc."
                description="This is the size of the evidence item. This can be in bytes, kilobytes, megabytes, gigabytes, or any other size unit."
                key={form.key("capacity")}
                {...form.getInputProps("capacity")}
                type="number"
                min={0}
                max={999}
                step={1}
              />
              <SelectBox
                size="sm"
                variant="outlined"
                label="Size Units"
                description={
                  "This is the unit of measurement for the size of the evidence item. This can be in bytes, kilobytes, megabytes, gigabytes, or any other size unit."
                }
                clearable
                data={["KB", "MB", "GB", "TB", "PB"].map((unit) => ({
                  label: unit,
                  value: unit,
                  data: unit,
                }))}
                key={form.key("capacity_unit")}
                {...form.getInputProps("capacity_unit")}
              />
            </Grid>
            <SelectBox
              size="sm"
              variant="outlined"
              label="Linked Contact"
              description={
                "This is the person that the evidence is associated with. This can be the owner of the evidence, the person that provided the evidence, or any other person associated with the evidence."
              }
              searchable
              clearable
              data={contacts?.map((c) => ({
                label: c.name,
                value: c.client_id,
                data: c,
              }))}
              renderOption={(option) => {
                return <ClientListItem client={option.data} />;
              }}
              key={form.key("contact_id")}
              {...form.getInputProps("contact_id")}
            />
            <TextAreaInput
              size="sm"
              variant="outlined"
              label="Description"
              description="This is a basic description of the case.  This can include information about the case, the subject, evidence, and any other relevant information."
              placeholder="Enter evidence description..."
              colSpan={2}
              minRows={6}
              maxRows={24}
              key={form.key("description")}
              {...form.getInputProps("description")}
            />
          </Grid>
        </FormSection>
      )}
      {includeCustomFields && (
        <FormSection title="Custom Fields" defaultOpen={false}>
          <Grid col={2}>
            {customFields
              ?.filter((c) => c.enabled === 1) // filter out disabled fields
              ?.map((field) => (
                <CustomFieldRender
                  key={form.key(`custom_attribute_${field.field_id}`)}
                  field={field}
                  inputProps={form.getInputProps(
                    `custom_attribute_${field.field_id}`
                  )}
                />
              ))}
          </Grid>
        </FormSection>
      )}
      {includeCoc && (
        <FormSection title="Chain of Custody" defaultOpen={false}>
          <Grid col={2}>
            <SelectBox
              size="sm"
              variant="outlined"
              label="Received By"
              description={
                "This is the person that received the evidence. This is the person that took possession of the evidence and is responsible for the evidence from this point forward.\n\nThis may also be a location if the evidence was received by a location.\n\nThese locations can be managed in the 'Item Locations' section"
              }
              searchable
              clearable
              data={evidenceLocations?.map((c) => ({
                label: c.location_name,
                value: c.location_id,
                data: c,
              }))}
              renderOption={(option) => {
                return <LocationListItem location={option.data} />;
              }}
              key={form.key("custody_to")}
              {...form.getInputProps("custody_to")}
            />
            <TextInput
              size="sm"
              variant="outlined"
              label="Received From"
              placeholder="John Smith, FedEx, etc."
              description={
                "This is the person or organization that the evidence was received from. This is the person or organization that provided the evidence to you.\n\nThis can also be a mail or shipping service if the evidence was shipped to you - it may be prudent to record the tracking number here or in the notes section."
              }
              key={form.key("custody_from")}
              {...form.getInputProps("custody_from")}
            />
            <div>
              <FieldLabel
                size="sm"
                description={
                  "This is the signature of the person that received the evidence. This is used to verify that the evidence was received by the correct person.\n\nThis signature can be captured on a touch screen device or with a connected USB Topaz signature pad."
                }
              >
                Received By Signature
              </FieldLabel>
              <SignatureField
                sigFieldRef={receivedBySig}
                title="Received By Signature"
                style={{ margin: 0 }}
              />
            </div>
            <div>
              <FieldLabel
                size="sm"
                description={
                  "This is the signature of the person that provided the evidence. This is used to verify that the evidence was received by the correct person.\n\nThis signature can be captured on a touch screen device or with a connected USB Topaz signature pad."
                }
              >
                Received From Signature
              </FieldLabel>
              <SignatureField
                sigFieldRef={receivedFromSig}
                title="Received From Signature"
                style={{ margin: 0 }}
              />
            </div>
            <DateInput
              label="Intake Timestamp"
              description="This is the date and time that the evidence was received. This is used to track the time that the evidence was received and can be used to track the chain of custody."
              variant="outlined"
              arrow
              size="sm"
              clearable
              max={new Date()}
              enableCalendar={true}
              defaultValue={new Date()}
              format={getDateFormat({ isMoment: true, includeTime: true })}
              key={form.key("coc_intake_timestamp")}
              {...form.getInputProps("coc_intake_timestamp")}
            />
            <TextInput
              size="sm"
              variant="outlined"
              label="Location Received"
              placeholder="Address, Front Desk, etc."
              description="This is the location that the evidence was received. This is used to track the location that the evidence was received and can be used to track the chain of custody."
              key={form.key("location_received")}
              {...form.getInputProps("location_received")}
            />
            <TextAreaInput
              size="sm"
              variant="outlined"
              label="Notes"
              description="These are any additional notes or information about the chain of custody. This can include information about the evidence transfer, the condition of the evidence, or any other relevant information."
              placeholder="Enter intake notes..."
              colSpan={2}
              minRows={6}
              maxRows={6}
              key={form.key("reason")}
              {...form.getInputProps("reason")}
            />
          </Grid>
        </FormSection>
      )}
      <ButtonMenu>
        <Button size="xs" variant="subtle" onClick={handleCancel}>
          {buttonProps.cancelText || "Cancel"}
        </Button>
        <Button
          size="xs"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          {buttonProps.submitText || "Submit"}
        </Button>
      </ButtonMenu>
    </>
  );
};

export default DefaultEvidenceForm;
