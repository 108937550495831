import styled from "styled-components";
import CasesApi from "../../api/cases/index.js";
import DefaultCaseForm from "./DefaultCaseForm.js";

const FormTitle = styled.h2`
  margin: 0;
`;

const CreateCaseForm = styled(
  ({ className, onSubmit, onCancel, defaultFormData = {} }) => {
    const handleSubmit = async (data) => {
      await CasesApi.createCase(data);
      onSubmit?.(data);
    };
    return (
      <div className={className}>
        <FormTitle>Create Forensic Case</FormTitle>
        <DefaultCaseForm
          onSubmit={handleSubmit}
          onCancel={onCancel}
          defaultFormData={defaultFormData}
        />
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  gap: 15px;

  width: ${({ width }) =>
    Number.isInteger(width) ? `${width}px` : width || "100%"};
`;

export default CreateCaseForm;
