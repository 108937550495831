import { z } from "zod";
import {
  Button,
  Grid,
  TextAreaInput,
  TextInput,
} from "@monolith-forensics/monolith-ui";
import ButtonMenu from "./components/ButtonMenu.js";
import { useForm, zodResolver } from "@mantine/form";
import diffFormData from "./utils/diffFormData.js";
import { v4 as uuidv4 } from "uuid";
import TraceAttributeInput from "./components/TraceAttributeInput.js";
import { useState } from "react";
import {
  EvidenceSelector,
  TimelineEventSelector,
} from "./components/Selectors/index.js";
import TraceAttributeSelector from "./components/Selectors/TraceAttributeSelector.js";
import { TraceAttributeTypes } from "../ColumnDefinitions";

const TraceAttributeSchema = z
  .object({
    uuid: z.string().min(10).max(36),
    type: z.any(),
    value: z.any(),
    trace_uuid: z.string().min(10).max(36),
    created_by_id: z.number().positive().min(1).optional(),
    metadata: z.object().nullable().optional(),
  })
  .refine(
    (data) => {
      if (!data.type || !data.value) return false;
      if (z.string().parse(data.value).length < 1) return false;
      if (z.string().parse(data.type).length < 1) return false;
      return TraceAttributeTypes.find(
        (type) => type.value === data.type
      )?.schema.safeParse(data.value).success;
    },
    (data) => {
      const type = TraceAttributeTypes.find((type) => type.value === data.type);
      return {
        message: `Invalid value${
          type?.label ? ` for ${type?.label} (${type?.category})` : ""
        }`,
        path: [data.uuid],
      };
    }
  );

// Validation schema
const TraceIndicatorSchema = z.object({
  uuid: z.string().min(10).max(36),
  name: z.string(),
  description: z.string().optional(),
  evidence_uuid: z
    .array(z.string().min(10).max(36))
    .or(z.string().min(10).max(36))
    .nullable()
    .optional(),
  timeline_event_uuid: z
    .array(z.string().min(10).max(36))
    .or(z.string().min(10).max(36))
    .nullable()
    .optional(),
  attribute_uuid: z
    .array(
      z.object({
        value: z.string().min(10).max(36),
      })
    )
    .optional(),
  attributes: z.array(TraceAttributeSchema).optional(),
  created_by_id: z.number().positive().min(1).optional(),
  updated_by_id: z.number().positive().min(1).optional(),
});

const DefaultTraceIndicatorForm = ({
  defaultFormData = {},
  buttonProps = {},
  excludedFields = [],
  onSubmit,
  onCancel,
  onChange,
}) => {
  const [formData, setFormData] = useState(defaultFormData);
  const [errors, setErrors] = useState({});

  const form = useForm({
    mode: "uncontrolled",
    initialValues: { uuid: uuidv4(), ...defaultFormData },
    validate: zodResolver(TraceIndicatorSchema),
    onValuesChange: (values) => {
      setFormData(values);
      onChange?.(values);
    },
  });

  const handleSubmit = async () => {
    const validateResult = form.validate();

    if (validateResult.hasErrors) {
      setErrors(validateResult.errors);
      return;
    }
    setErrors({});

    const { attribute_uuid, ...rest } = form.getValues();

    const _formData = {
      ...rest,
      attribute_uuid: attribute_uuid?.map?.((item) => item.value),
    }; // convert attribute_uuid to array of strings

    const diffData = diffFormData(defaultFormData, _formData);

    // call onSubmit with modeled data and diff data
    // diff data is used when the form is in edit context
    // diff may be used when we only want to update modified values
    onSubmit?.(_formData, diffData);
  };

  const handleCancel = () => {
    form.reset();
    onCancel?.();
  };

  const selectedEvidence = formData.evidence_uuid;

  return (
    <>
      <Grid col={1} style={{ marginBottom: 10 }}>
        <TextInput
          size="sm"
          variant="outlined"
          label="Name"
          placeholder="Enter trace indicator name"
          required
          description={
            "Provide a name for this trace indicator so that it can be easily identified."
          }
          key={form.key("name")}
          {...form.getInputProps("name")}
        />
        {!excludedFields.includes("evidence_uuid") && (
          <EvidenceSelector
            size="sm"
            variant="outlined"
            label="Source Evidence"
            placeholder="Select evidence linked to this indicator"
            data={TraceAttributeTypes}
            clearable
            searchable
            // multiple
            query={{
              case_id: defaultFormData.case_id,
              case_uuid: defaultFormData.case_uuid,
            }}
            key={form.key("evidence_uuid")}
            {...form.getInputProps("evidence_uuid")}
          />
        )}
        {!excludedFields.includes("timeline_event_uuid") && (
          <TimelineEventSelector
            size="sm"
            variant="outlined"
            label="Timeline Event"
            placeholder="Select timeline event linked to this indicator"
            clearable
            searchable
            // multiple
            disabled={!selectedEvidence}
            query={{
              case_id: defaultFormData.case_id,
              case_uuid: defaultFormData.case_uuid,
              evidence_uuid: selectedEvidence,
            }}
            key={form.key("timeline_event_uuid")}
            {...form.getInputProps("timeline_event_uuid")}
          />
        )}
        <TraceAttributeSelector
          size="sm"
          variant="outlined"
          label="Attributes"
          placeholder="Select attributes"
          description="Select attributes to link to this trace indicator - this is a list of attributes that already exist within this case."
          clearable
          multiple
          query={{
            case_uuid: defaultFormData.case_uuid,
          }}
          key={form.key("attribute_uuid")}
          {...form.getInputProps("attribute_uuid")}
        />
        <TextAreaInput
          size="sm"
          variant="outlined"
          label="Description"
          placeholder="Enter trace indicator description"
          description={
            "Provide a brief description of the indicator so that it can be easily identified and its behavior understood."
          }
          minRows={6}
          maxRows={24}
          key={form.key("description")}
          {...form.getInputProps("description")}
        />
        {!excludedFields.includes("attributes") && (
          <TraceAttributeInput
            size="sm"
            variant="outlined"
            label="New Attributes"
            placeholder="Enter trace attributes"
            defaultTraceIndicatorValue={{
              created_by_id: form.getValues().created_by_id,
              uuid: form.getValues().uuid,
              case_id: defaultFormData.case_id,
              case_uuid: defaultFormData.case_uuid,
            }}
            errors={errors}
            key={form.key("attributes")}
            {...form.getInputProps("attributes")}
          />
        )}
      </Grid>
      <ButtonMenu>
        <Button size="xs" variant="subtle" onClick={handleCancel}>
          {buttonProps.cancelText || "Cancel"}
        </Button>
        <Button
          size="xs"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          {buttonProps.submitText || "Submit"}
        </Button>
      </ButtonMenu>
    </>
  );
};

export default DefaultTraceIndicatorForm;
