import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import ClearIcon from "@mui/icons-material/Clear";
import TaskButton from "../TaskButton";

const Flyout = styled(({ className, children, onClose = () => {} }) => {
  const flyoutRef = useRef(null);
  const backgroundRef = useRef(null);

  const handleClose = () => {
    flyoutRef?.current?.classList?.remove("open");
    setTimeout(() => {
      onClose();
    }, 100);
  };

  // use settimeout to set the flyout to open after the animation has finished
  useEffect(() => {
    setTimeout(() => {
      flyoutRef?.current?.classList?.add("open");
    }, 0);
  }, []);

  // pass onClose to the FlyoutHeader
  children = React.Children.map(children, (child) => {
    if (child.type.displayName === "FlyoutHeader") {
      return React.cloneElement(child, { handleClose });
    }
    return child;
  });

  return (
    <div className={className} ref={backgroundRef} onClick={handleClose}>
      <div
        ref={flyoutRef}
        className="flyout-content"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>
  );
})`
  position: fixed;
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 1300)};
  inset: 0px;

  display: flex;

  .flyout-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    position: fixed;
    top: 0;
    right: 0;
    width: 0px;
    height: 100%;
    background-color: ${({ theme }) => theme.palette.background.default};
    box-shadow: -2px 0px 4px 0px rgba(0, 0, 0, 0.5);
    transition: all 0.1s ease-in-out;
    &.open {
      width: 50%;
    }
  }
`;

export const FlyoutHeader = styled(
  ({ className, children, handleClose = () => {}, style = {} }) => {
    return (
      <div className={className} style={style}>
        <div className="close-button">
          <TaskButton
            onClick={() => handleClose()}
            style={{
              fontSize: 10,
              padding: "3px 3px",
            }}
            variant="text"
          >
            <ClearIcon style={{ fontSize: 17 }} />
          </TaskButton>
        </div>
        {children}
      </div>
    );
  }
)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider}};
  & .close-button {
    cursor: pointer;
    font-size: 1.5rem;
    color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    padding: 15px;
  }
`;

FlyoutHeader.displayName = "FlyoutHeader";

export default Flyout;
