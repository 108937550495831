import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import styled, { useTheme } from "styled-components";
import { EditIcon, TrashIcon } from "lucide-react";
import FlyoutDetailsTab from "./FlyoutDetailsTab.js";
import UserRolesAPI from "../../../../api/UserRoles/UserRoles.js";
import {
  Button,
  Flyout,
  FlyoutHeader,
  FlyoutTitle,
} from "@monolith-forensics/monolith-ui";
import EditUserRoleModal from "../../../../components/Modals/EditUserRoleModal.js";
import DeleteUserRoleModal from "../../../../components/Modals/DeleteUserRoleModal.js";
import PermissionsTab from "./PermissionsTab";

const tabDefs = [
  {
    label: "Details",
    value: "details",
  },
  {
    label: "Permissions",
    value: "permissions",
  },
];

const TabPanel = styled(({ className, children, value }) => {
  return <div className={className}>{children}</div>;
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

TabPanel.displayName = "TabPanel";

const Tabs = styled(({ className, children, defaultValue, onChange }) => {
  if (!Array.isArray(children)) children = [children]; // Ensure children is an array
  const [activeTab, setActiveTab] = useState(defaultValue || "details");

  const handleChange = (tab) => {
    setActiveTab(tab);
    onChange?.(tab);
  };

  const tabPanels = children.filter(
    (child) => child?.type?.displayName === "TabPanel"
  );

  const activePanel = tabPanels.find(
    (child) => child.props.value === activeTab
  );

  return (
    <div className={className}>
      <div className="tabs-list">
        {tabDefs.map((tab) => (
          <div
            className={`tab`}
            data-active={activeTab === tab.value}
            onClick={() => handleChange(tab.value)}
            key={tab.value}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className="tab-content">{activePanel}</div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  .tabs-list {
    user-select: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    color: ${({ theme }) => theme.palette.text.secondary};
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  }

  .tab {
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid transparent;
  }

  .tab:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }

  .tab[data-active="true"] {
    border-bottom-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
  }

  .tab-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 0px;
  }
`;

const UserRoleFlyout = ({
  open = false,
  onClose,
  uuid = null,
  defaultData = {},
  onEdit,
  onDelete,
}) => {
  const theme = useTheme();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["user-roles", "list", { uuid }],
    queryFn: ({ queryKey }) => {
      const [_, __, query] = queryKey;
      return UserRolesAPI.get(query);
    },
    enabled: !!uuid,
    initialData: [defaultData],
  });

  const itemData = data?.data?.[0] || {};

  return (
    <Flyout open={open} onClose={() => onClose?.()}>
      {isLoading && <div>Loading...</div>}
      {!isLoading && (
        <>
          <FlyoutHeader>
            <FlyoutTitle>{itemData.name}</FlyoutTitle>
            {!itemData.protected && (
              <EditUserRoleModal
                show={showEditModal}
                defaultFormData={{
                  uuid: itemData?.uuid,
                  name: itemData?.name,
                  description: itemData?.description,
                }}
                onClose={() => setShowEditModal(false)}
                onCancel={() => setShowEditModal(false)}
                onSubmit={(data) => {
                  refetch();
                  onEdit?.(data);
                  setShowEditModal(false);
                }}
              />
            )}
            {!itemData.protected && (
              <DeleteUserRoleModal
                show={showDeleteModal}
                defaultFormData={{ ...itemData }}
                onClose={() => setShowDeleteModal(false)}
                onCancel={() => setShowDeleteModal(false)}
                onSubmit={(data) => {
                  onDelete?.(data);
                  setShowDeleteModal(false);
                }}
              />
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                alignItems: "center",
                color: theme.palette.text.secondary,
                fontWeight: 500,
                marginBottom: 5,
              }}
            >
              <div>User Role</div>
              {itemData.protected && (
                <>This is a default user role and cannot be edited.</>
              )}
              {!itemData.protected && (
                <Button
                  size="xxs"
                  variant="text"
                  leftSection={<EditIcon size={14} />}
                  color="primary"
                  onClick={() => setShowEditModal(true)}
                >
                  Edit
                </Button>
              )}
              {!itemData.protected && (
                <Button
                  size="xxs"
                  variant="text"
                  leftSection={<TrashIcon size={14} />}
                  color="error"
                  onClick={() => setShowDeleteModal(true)}
                >
                  Delete
                </Button>
              )}
            </div>
          </FlyoutHeader>
          <Tabs>
            <TabPanel value="details">
              <FlyoutDetailsTab defaultData={itemData} />
            </TabPanel>
            <TabPanel value="permissions">
              <PermissionsTab defaultData={itemData} />
            </TabPanel>
          </Tabs>
        </>
      )}
    </Flyout>
  );
};

export default UserRoleFlyout;
