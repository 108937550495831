import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { AuthService } from "../../api/auth/index.js";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import { Button, CssBaseline, useTheme } from "@mui/material";
import logo from "../../monolith_logo_white.png"; // Tell Webpack this JS file uses this image
import GlobalStyle from "../../components/GlobalStyle.js";

const ResetPassword = () => {
  const { uuid } = useParams();
  const theme = useTheme();
  const form = useRef<any>(null);
  const [expired, setExpired] = useState(null);
  const navigate = useNavigate();

  const handleReset = () => {
    if (form.current.instance.validate().isValid) {
      const { password }: { password: string } = {
        ...form.current.instance.option().formData,
      };
      AuthService.updatePassword({ password, uuid });
      navigate(`/login`);
    }
  };

  useEffect(() => {
    AuthService.checkPasswordReset({ uuid }).then((result) => {
      setExpired(result.expired);
    });
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "calc(100vh - 200px)",
        alignItems: "center",
      }}
    >
      <CssBaseline />
      <GlobalStyle />
      <div
        style={{
          width: 500,
          padding: "20px 60px",
          height: "auto",
          borderRadius: 5,
          backgroundColor: theme.palette.background.default,
          boxShadow: "none !important",
        }}
      >
        <div style={{ maxWidth: 350, margin: "auto" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 25,
            }}
          >
            <img src={logo} height="85" alt="Monolith Forensics Logo"></img>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 25,
              color: theme.palette.text.secondary,
              fontSize: "large",
            }}
          >
            Update Your Password
          </div>
          {!expired && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0px",
                }}
              >
                <Form ref={form} colCount={1} width="100%">
                  <SimpleItem
                    dataField="password"
                    label={{ text: "New Password", visible: false }}
                    editorOptions={{
                      mode: "password",
                      placeholder: "New Password",
                      maxLength: 100,
                    }}
                    validationRules={[
                      {
                        type: "required",
                      },
                      {
                        type: "stringLength",
                        message: "Password must be at least 8 characters",
                        min: 8,
                        max: 100,
                      },
                      {
                        type: "pattern",
                        pattern:
                          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!+_\\-\\.@#$%^&*])",
                        message:
                          "Must contain upper, lower, special, & numeric.",
                      },
                    ]}
                  />
                </Form>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0px",
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  fullWidth
                  style={{ marginTop: 20 }}
                  onClick={handleReset}
                >
                  Update Password
                </Button>
              </div>
            </>
          )}
          {expired && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0px",
                }}
              >
                This password reset request has expired.
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
