import styled, { useTheme } from "styled-components";
import Flyout, { FlyoutHeader } from "./Flyout";
import { useEffect, useMemo, useState } from "react";
import { usePermissions } from "../../hooks/usePermissions";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getEvidence } from "../../api";
import { nanoid } from "nanoid";
import { monolithMoment } from "../../utils/date-format";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EditEvidenceModal from "../../pages/Evidence/components/Popups/EditEvidenceModal";
import TableColumnDefs from "../../components/Evidence/TableColumnDefs";
import { addCustomFieldsToColumnDefs } from "../../components/Evidence/CustomFieldCell";
import EvidenceAPI from "../../api/evidence";
import { Link } from "react-router-dom";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import Loader from "../../components/Loader";

const EvidenceFlyout = styled(
  ({ className, defaultEvidenceInfo, isOpen, setIsOpen }) => {
    const theme = useTheme();

    return (
      <Flyout
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <FlyoutHeader>
          <div style={{ width: "100%" }}>
            <h4
              style={{
                margin: "5px 0px",
                color: theme.palette.text.primary,
              }}
            >
              {defaultEvidenceInfo.object_number}
            </h4>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: theme.palette.text.secondary,
                  fontSize: 12,
                }}
              >
                Evidence Details
              </div>
            </div>
          </div>
        </FlyoutHeader>
        <EvidenceFlyoutContent defaultEvidenceInfo={defaultEvidenceInfo} />
      </Flyout>
    );
  }
)``;

const EvidenceFlyoutContent = styled(({ className, defaultEvidenceInfo }) => {
  const { hasPermission } = usePermissions();
  const [showEditEvidenceModal, setShowEditEvidenceModal] = useState(false);
  const [columnState, setColumnState] = useState(TableColumnDefs);
  const theme = useTheme();
  const queryClient = useQueryClient();

  const { data: evidenceInfo, isFetched } = useQuery({
    queryKey: ["evidence:list", { uuid: defaultEvidenceInfo.object_uuid }],
    queryFn: () => getEvidence({ uuid: defaultEvidenceInfo.object_uuid }),
    initialData: [defaultEvidenceInfo],
    select: (data) => {
      return data[0];
    },
  });

  const handleChange = (data) => {
    queryClient.invalidateQueries([
      "evidence:list",
      { uuid: defaultEvidenceInfo.object_uuid },
    ]);
  };

  const { data: customFields } = useQuery({
    queryKey: ["evidence:customFields"],
    queryFn: () => EvidenceAPI.getCustomFields({ fieldsOnly: true }),
  });

  useEffect(() => {
    if (!customFields) return;
    // Add custom fields to column defs
    setColumnState((cs) => {
      return addCustomFieldsToColumnDefs(customFields, cs);
    });
  }, [customFields]);

  const dataFieldIgnoreList = ["description"];

  const data = {
    ...evidenceInfo,
    ...(evidenceInfo?.custom_attributes?.reduce((acc, attr) => {
      acc[`custom_field_${attr.field_id}`] = attr.value;
      return acc;
    }, {}) || {}),
  };

  const detail = (
    <div className="detail-container">
      {columnState
        .filter((c) => !dataFieldIgnoreList.includes(c.dataField))
        .filter((c) =>
          c.dataField.includes("custom_field_") ? !!data[c.dataField] : true
        )
        .map((c) => {
          return (
            <div className="detail-item" key={nanoid()}>
              <div className="detail-label">{c.caption}</div>
              {c.render ? (
                c.render(data, { onChange: handleChange })
              ) : c.dataType === "date" ? (
                <>
                  {monolithMoment({
                    timestamp: data[c.dataField],
                    includeTime: true,
                  })}
                </>
              ) : (
                <div className="detail-value">{data[c.dataField]}</div>
              )}
            </div>
          );
        })}
    </div>
  );

  if (!evidenceInfo)
    return <div className={className}>Evidence Does Not Exist</div>;

  return (
    <div className={className}>
      <div className="action-menu">
        <Link
          className={className}
          to={`/evidence/${evidenceInfo.uuid}/overview`}
          style={{ padding: 0, margin: 0 }}
        >
          <div className="action-menu-item">
            <LaunchOutlinedIcon style={{ color: theme.palette.primary.main }} />
            <div className="action-menu-label">View Evidence</div>
          </div>
        </Link>
        {hasPermission() && (
          <>
            <div
              className="action-menu-item"
              onClick={() => setShowEditEvidenceModal(true)}
            >
              <EditOutlinedIcon style={{ color: theme.palette.primary.main }} />
              <div className="action-menu-label">Edit Evidence</div>
            </div>
          </>
        )}
      </div>
      {!(isFetched && customFields) && (
        <Loader message={"Retrieving Evidence..."} />
      )}
      {detail}
      <div className="detail-label" style={{ marginTop: 30 }}>
        Description
      </div>
      <div style={{ position: "relative" }}>
        <div className="description">{evidenceInfo.description}</div>
      </div>
      {showEditEvidenceModal && (
        <EditEvidenceModal
          open={showEditEvidenceModal}
          onEditSubmit={handleChange}
          handleClose={() => setShowEditEvidenceModal(false)}
          defaultEvidenceInfo={evidenceInfo}
        />
      )}
    </div>
  );
})`
  padding: 20px;

  & {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    margin-right: 5px;
  }
  & .detail-container {
  }
  & .action-menu {
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 25px;
    margin-right: 10px;
  }
  & .action-menu-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin-right: 12px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
    & svg {
      font-size: 15px;
      margin-right: 5px;
    }
  }
  & .detail-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 10px 0px;
    font-size: 12px;
  }
  & .detail-label {
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-right: 10px;
    min-width: 150px;
  }
  & .detail-value {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  & .description {
    position: relative;
    resize: none;
    margin-top: 10px;
    font-size: 12px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: transparent;
    white-space: pre-wrap;
    width: 100%;
    height: fit-content;
    max-height: 30%;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 10px;
    transition: border 0.2s ease-in-out;
    outline: none;
    &:hover {
      border: 1px solid ${({ theme }) => theme.palette.divider};
    }
    &:focus {
      border: 1px solid ${({ theme }) => theme.palette.primary.main};
    }
  }
  & .description-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.palette.divider};
    display: flex;
    alignitems: center;
    fontsize: 12px;
    padding: 10px;
  }
`;

export default EvidenceFlyout;
