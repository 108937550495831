import { useQuery } from "@tanstack/react-query";
import { Tabs } from "devextreme-react/ui/tabs";
import { useEffect, useRef, useState } from "react";
import { getTimeEntryReport, getTimeEntryReportMetrics } from "../../../../api";
import { EditReportPopup } from "../../EditReportPopup";
import { SavedReports } from "./SavedReports";
import { TimeEntries } from "./TimeEntries";
import { TimeEntryComposition } from "./TimeEntryComposition";
import { TimeEntryDetails } from "./TimeEntryDetails";
import { TimeEntryMetrics } from "./TimeEntryMetrics";
import { TimeEntryParams } from "./TimeEntryParams";

export const TimeEntryReport = ({ reportInfo, setFormUpdated }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const editReportPopup = useRef(null);

  const { isLoading, data: timeEntry } = useQuery({
    queryKey: [
      "reports:time-entries-overview:entries",
      reportInfo.report_parameters,
    ],
    queryFn: () => getTimeEntryReport(reportInfo.report_parameters),
  });

  const { isLoading: metricsLoading, data: metrics } = useQuery({
    queryKey: [
      "reports:time-entries-overview:metrics",
      {
        ...reportInfo.report_parameters,
        dimension: "composition",
      },
    ],
    queryFn: () =>
      getTimeEntryReportMetrics({
        ...reportInfo.report_parameters,
        dimension: "composition",
      }),
  });

  return (
    <div>
      <div
        style={{
          display: "flex",
          marginBottom: "20px",
          userSelect: "text",
        }}
      >
        <TimeEntryDetails reportInfo={reportInfo} />
        <TimeEntryParams
          reportInfo={reportInfo}
          editReportPopup={editReportPopup}
        />
        <TimeEntryMetrics timeEntry={timeEntry} metrics={metrics} />
      </div>

      <TimeEntryComposition metrics={metrics} />

      <div
        style={{
          minHeight: "450px",
          margin: "20px 0px",
        }}
      >
        <Tabs
          items={["Saved Reports", "Time Entries"]}
          selectedIndex={selectedIndex}
          focusStateEnabled={false}
          hoverStateEnabled={false}
          activeStateEnabled={false}
          width={250}
          onItemClick={(e) => {
            e.event.preventDefault();
            e.event.stopPropagation();
            setSelectedIndex(e.itemIndex);
          }}
        />

        {selectedIndex === 0 && <SavedReports reportInfo={reportInfo} />}
        {selectedIndex === 1 && (
          <TimeEntries reportInfo={reportInfo} timeEntry={timeEntry} />
        )}
      </div>

      <EditReportPopup
        editReportPopup={editReportPopup}
        reportInfo={reportInfo}
        setFormUpdated={setFormUpdated}
      />
    </div>
  );
};
