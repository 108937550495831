import React, { createContext, useContext } from "react";

export function createSafeContext<T extends unknown | null>(errorMessage = "") {
  const Context = createContext<T | undefined>(undefined);

  const useSafeContext = (): T => {
    const ctx = useContext(Context);

    if (ctx === undefined) {
      throw new Error(errorMessage);
    }

    return ctx;
  };

  const Provider = ({
    children,
    value,
  }: {
    children: React.ReactNode;
    value: T;
  }) => <Context.Provider value={value}>{children}</Context.Provider>;

  return [Provider, useSafeContext] as const;
}
