import { lazy, Suspense } from "react";
import styled from "styled-components";

const ReactPlayer = lazy(() => import("react-player"));

const MediaViewer = styled(({ className, file }) => {
  return (
    <div className={className}>
      <Suspense fallback={<div>Loading...</div>}>
        <ReactPlayer
          url={file.url}
          height={"90vh"}
          width={"85vw"}
          controls={true}
          playing={true}
        />
      </Suspense>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default MediaViewer;
