import styled from "styled-components";

const MainNoteView = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${(props) => props.theme.palette.background.default};
  transition: all 0.3s ease;

  .notes-section {
    flex: 1 1 auto;
  }

  &[data-fullscreen="true"] {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1400;
    height: 100vh;
    width: 100vw;
    border-radius: 0;

    .notes-section {
      height: calc(100vh - 50px);
    }
  }
`;

export default MainNoteView;
