import { useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";

import { Grid, Chip, Typography, useTheme } from "@mui/material";

import Loader from "../../components/Loader";
import { getAcquisitions } from "../../api/acquisitions";
import AcquisitionOverview from "./AcquisitionOverview";
import { Acquisition } from "@/types";

const AcquisitionDetails = () => {
  const { acq_id } = useParams();
  const [acquisitionInfo, setAcquisitionInfo] = useState<Acquisition | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    setIsLoading(true);
    getAcquisitions({ acq_id: acq_id }).then((result) => {
      setIsLoading(false);
      setAcquisitionInfo(result[0]);
    });
  }, [acq_id]);

  const handleAcquisitionUpdate = (newValues: Partial<Acquisition>) => {
    setAcquisitionInfo({ ...acquisitionInfo, ...newValues } as Acquisition);
  };

  if (isLoading) return <Loader />;

  return (
    <>
      {acquisitionInfo && (
        <>
          <Helmet>
            <title>Acquisition Details | {acquisitionInfo.acq_name}</title>
          </Helmet>
          <Grid justifyContent="space-between" container spacing={20} mb={2}>
            <Grid item width="100%">
              <Typography variant="h3" gutterBottom display="inline">
                {acquisitionInfo.acq_name || ""}{" "}
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "10px 0px",
                }}
              >
                <Chip
                  label="Acquisition"
                  variant="filled"
                  color="primary"
                  size="small"
                  sx={{ cursor: "pointer" }}
                />
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                  sx={{ ml: "10px" }}
                >
                  {acquisitionInfo.evidence_number &&
                    acquisitionInfo.evidence_number}
                </Typography>
                <Link
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: "smaller",
                    marginLeft: "auto",
                  }}
                  onMouseEnter={(event) => {
                    event.currentTarget.style.textDecoration = "underline";
                  }}
                  onMouseLeave={(event) => {
                    event.currentTarget.style.textDecoration = "none";
                  }}
                  to={`/cases/${acquisitionInfo.case_id}/acquisitions`}
                >
                  {"< Back to Case"}
                </Link>
              </div>
            </Grid>
          </Grid>
          <AcquisitionOverview
            acquisitionInfo={acquisitionInfo}
            handleAcquisitionUpdate={handleAcquisitionUpdate}
          />
        </>
      )}
    </>
  );
};

export default AcquisitionDetails;
