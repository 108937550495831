import { z } from "zod";

const resolveCustomFieldValidations = (fields) => {
  return fields.reduce((acc, field) => {
    const isRequired = field.is_required === 1;
    switch (field.editor_type) {
      case "textBox":
        return {
          ...acc,
          [`custom_attribute_${field.field_id}`]: isRequired
            ? z.string().min(1)
            : z.string().optional(),
        };
      case "dropDown":
        return {
          ...acc,
          [`custom_attribute_${field.field_id}`]: isRequired
            ? z.string()
            : z.string().optional(),
        };
      case "dateBox":
        return {
          ...acc,
          [`custom_attribute_${field.field_id}`]: isRequired
            ? z.string().date()
            : z.string().date().optional(),
        };
      case "tagBox":
        return {
          ...acc,
          [`custom_attribute_${field.field_id}`]: isRequired
            ? z.array(z.string())
            : z.array(z.string()).optional(),
        };
      default:
        return acc;
    }
  }, {});
};

export default resolveCustomFieldValidations;
