import { httpDelete, httpGet, httpPatch, httpPost } from "../connection";

//Get Items
export async function getCheckListItems(checkListID, params) {
  return await httpGet(`/qa/checklists/${checkListID}/items`, params);
}

//Create Item
export async function addCheckListItem(values) {
  let checklistID = values.checklist_id;
  delete values.checklist_id;

  return await httpPost(`/qa/checklists/${checklistID}/items`, values);
}

//Edit Item
export async function editCheckListItem(values) {
  return await httpPatch(
    `/qa/checklists/${values.checklist_id}/items/${values.item_id}`,
    values
  );
}

//Delete Item
export async function deleteCheckListItem(values) {
  return await httpDelete(
    `/qa/checklists/${values.checklist_id}/items/${values.item_id}`
  );
}

export async function createCheckListLog(values) {
  return await httpPost(
    `/qa/checklists/${values.checklist_id}/items/log`,
    values
  );
}

export async function deleteCheckListLog(values) {
  return await httpDelete(
    `/qa/checklists/${values.checklist_id}/items/${values.item_id}/log?qa_id=${values.qa_id}`
  );
}

export async function getChecklistLogs(caseID) {
  return await httpGet(`/cases/${caseID}/qa/checklist/logs`);
}

export async function getCheckListSelections(caseID) {
  return await httpGet(`/cases/${caseID}/qa/checklist/selections`);
}

//Update Checklist Selections
export async function updateCheckListSelections(caseID, itemKeys = []) {
  return await httpPatch(`/cases/${caseID}/qa/checklist/selections`, {
    item_keys: itemKeys,
  });
}

const ChecklistItemsAPI = {
  getCheckListItems,
  addCheckListItem,
  editCheckListItem,
  deleteCheckListItem,
  createCheckListLog,
  deleteCheckListLog,
  getChecklistLogs,
  getCheckListSelections,
  updateCheckListSelections,
};

export default ChecklistItemsAPI;
