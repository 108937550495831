import OfficesApi from "../../api/offices/index.js";
import UserRolesAPI from "../../api/UserRoles/UserRoles.js";

const UsersFilterDimensions = [
  {
    name: "Role",
    field: "user_role",
    id: "user_role",
    type: "string",
    mode: "multi-select",
    key: ["users:roles"],
    selectionDisplayName: "text",
    selectionIdField: "value",
    queryFn: async (params) => {
      const response = await UserRolesAPI.get();
      return response.data.map((role) => ({
        text: role.name,
        value: role.name,
      }));
    },
  },
  {
    name: "Status",
    field: "active",
    id: "active",
    type: "string",
    mode: "multi-select",
    key: ["users:statuses"],
    selectionDisplayName: "text",
    selectionIdField: "value",
    queryFn: (params) =>
      Promise.resolve([
        {
          text: "Active",
          value: 1,
        },
        {
          text: "Deactivated",
          value: 0,
        },
      ]),
  },
  {
    name: "Read Only",
    field: "observer",
    id: "observer",
    type: "string",
    mode: "multi-select",
    key: ["users:read_only"],
    selectionDisplayName: "text",
    selectionIdField: "value",
    queryFn: (params) =>
      Promise.resolve([
        {
          text: "No",
          value: 0,
        },
        {
          text: "Yes",
          value: 1,
        },
      ]),
  },
  {
    name: "Office",
    field: "office_id",
    id: "office_id",
    type: "string",
    mode: "multi-select",
    key: ["offices:list"],
    selectionDisplayName: "office_name",
    selectionIdField: "office_id",
    queryFn: (params) => OfficesApi.getOffices(),
  },
];

export default UsersFilterDimensions;
