import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Loader from "../../components/Loader.js";

const CodeViewer = styled(({ className, file }) => {
  const [highlighter, setHighlighter] = useState(null);

  const { data } = useQuery({
    queryKey: ["text-data", file.uuid],
    queryFn: async () => {
      const response = await fetch(file.url);
      const text = await response.text();
      return text;
    },
    enabled: !!file.url && !!highlighter,
  });

  useEffect(() => {
    import("highlight.js").then(async (module) => {
      await import("highlight.js/styles/atom-one-dark.css");
      // Once the module is loaded, you can set it in the state
      setHighlighter(module);
    });
  }, []);

  // Use the highlighter module to highlight code
  const highlightCode = (code) => {
    if (highlighter) {
      // print highlighter module functions
      return highlighter.HighlightJS.highlightAuto(code).value;
    }
    return code;
  };

  if (!data) return <Loader message="Loading Document" />;

  return (
    <div className={className}>
      <div className="text-doc">
        <pre>
          <code
            dangerouslySetInnerHTML={{
              __html: highlightCode(data),
            }}
          />
        </pre>
      </div>
    </div>
  );
})`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: calc(100vh - 25px);
  width: 100%;
  max-width: 8.5in;
  overflow: auto;
  margin: auto;

  & .text-doc {
    width: 100%;
    max-width: 8.5in;
    height: 100%;
    padding: 0.5in;
    box-sizing: border-box;
    background-color: ${(props) => props.theme.palette.background.alt};
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-y: auto;
  }

  & pre {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`;

export default CodeViewer;
