import styled from "styled-components";
import logo from "../monolith_logo_white.png";

const SplashPage = styled(({ className }) => {
  return (
    <div className={className}>
      <div className="screenLoader">
        <div>
          <div>
            <img src={logo} height="150" alt="Monolith Forensics Logo"></img>
          </div>
          <div className="spinner-container">
            <span className="spinner"></span>
          </div>
        </div>
      </div>
    </div>
  );
})`
  .screenLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
  }
  .spinner-container {
    display: flex;
    justify-content: center;
    margin: 20px;
  }
  .screenLoader .spinner {
    /* Spinner size and color */
    width: 1.5rem;
    height: 1.5rem;
    border-top-color: white;
    border-left-color: white;

    /* Additional spinner styles */
    animation: spinner 400ms linear infinite;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
  }

  /* Animation styles */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* Optional — create your own variations! */
  .screenLoader .spinner-large {
    width: 5rem;
    height: 5rem;
    border-width: 6px;
  }

  .screenLoader .spinner-slow {
    animation: spinner 1s linear infinite;
  }

  .screenLoader .spinner-blue {
    border-top-color: #09d;
    border-left-color: #09d;
  }
`;

export default SplashPage;
