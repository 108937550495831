import PDFViewer from "./PDFViewer.js";
import NullViewer from "./NullViewer.js";
import MSDocViewer from "./MSDocViewer.js";
import usePlatform from "../../hooks/usePlatform.js";
import PlainTextViewer from "./PlainTextViewer.js";
import ImageViewer from "./ImageViewer.js";
import CodeViewer from "./CodeViewer.js";
import MediaViewer from "./MediaViewer.js";

import fileExtensionsMap from "../../utils/fileExtensionsMap.js";
import styled from "styled-components";

const ViewSelector = styled(
  ({ className, selectedItem, handleDownload, zoom }) => {
    const { isOnPremises } = usePlatform();

    const fileExtension = selectedItem?.file_name
      ? selectedItem.file_name.split(".").pop().toLowerCase()
      : selectedItem.image_path.split(".").pop().toLowerCase();

    // Overide size restrictions for video and audio files due to streaming capabilities
    if (
      fileExtensionsMap.get(fileExtension) === "video" ||
      fileExtensionsMap.get(fileExtension) === "audio"
    )
      return <MediaViewer file={selectedItem} />;

    return (
      <div className={className}>
        {fileExtensionsMap.get(fileExtension) === "undefined" ||
        selectedItem.file_size > 20000000 ? (
          <NullViewer
            file={selectedItem}
            handleDownload={handleDownload}
            typeCheck={true}
          />
        ) : fileExtension === "pdf" ? (
          <PDFViewer file={selectedItem} zoom={zoom} />
        ) : fileExtensionsMap.get(fileExtension) === "office" ? (
          isOnPremises ? (
            <NullViewer file={selectedItem} handleDownload={handleDownload} />
          ) : (
            <MSDocViewer file={selectedItem} />
          )
        ) : fileExtensionsMap.get(fileExtension) === "text" ? (
          <PlainTextViewer file={selectedItem} />
        ) : fileExtensionsMap.get(fileExtension) === "image" ? (
          <ImageViewer file={selectedItem} />
        ) : fileExtensionsMap.get(fileExtension) === "code" ? (
          <CodeViewer file={selectedItem} />
        ) : (
          <NullViewer file={selectedItem} handleDownload={handleDownload} />
        )}
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export default ViewSelector;
