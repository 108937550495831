import styled from "styled-components";

const KeyboardKey = styled.span`
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;

  user-select: none;

  display: inline-block;

  vertical-align: middle;
  padding: 3px 5px;
  margin: 0 2px;

  border: 1px solid ${({ theme }) => theme.palette.background.default};
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 ${({ theme }) => theme.palette.divider};
  background-color: ${({ theme }) => theme.palette.background.secondary};

  font-size: 0.75rem;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const KeyboardRow = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  line-height: 10px;

  .plus {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-weight: 600;
    font-size: 0.75rem;
  }
`;

const Keyboard = ({ keys = [], plus = false }) => {
  return (
    <div>
      <KeyboardRow>
        {keys.map((key, index) => (
          <>
            <KeyboardKey key={index}>{key}</KeyboardKey>
            {plus && index !== keys.length - 1 && (
              <span className="plus">+</span>
            )}
          </>
        ))}
      </KeyboardRow>
    </div>
  );
};

export default Keyboard;
