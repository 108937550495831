import { Button, Modal, useTheme } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useRef, useState } from "react";
import styled from "styled-components";
import InquiriesAPI from "../../../api/inquiries/index.js";
import { usePermissions } from "../../../hooks/usePermissions";
import { v4 as uuidv4 } from "uuid";
import { Form, SimpleItem } from "devextreme-react/ui/form";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ToolBarItems from "../../../components/ToolBarItems.js";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

const PeopleContent = styled(
  ({ className, defaultInquiryInfo, readOnly = true }) => {
    const { hasPermission } = usePermissions();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const queryClient = useQueryClient();
    const { data } = useQuery({
      queryKey: [
        "inquiries:list",
        { inquiry_id: defaultInquiryInfo.inquiry_id },
      ],
      queryFn: () =>
        InquiriesAPI.getInquiries({
          inquiry_id: defaultInquiryInfo.inquiry_id,
        }),
      select: (data) => data[0],
    });

    const onCreate = () => {
      queryClient.refetchQueries({
        queryKey: [
          "inquiries:list",
          { inquiry_id: defaultInquiryInfo.inquiry_id },
        ],
      });
    };

    const peopleItems = data?.people || [];

    return (
      <div className={className}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => setShowCreateModal(true)}
          style={{
            minWidth: "fit-content",
            fontSize: 11,
            padding: "3px 6px",
          }}
          disabled={!hasPermission() || readOnly}
        >
          + New Contact
        </Button>
        <div className="contacts-container">
          {peopleItems.length === 0 ? (
            <div style={{ marginBottom: 20 }}>No contacts.</div>
          ) : (
            <>
              {peopleItems.map((contact) => {
                return <ContactItem contact={contact} readOnly={readOnly} />;
              })}
            </>
          )}
        </div>
        <CreateModal
          open={showCreateModal}
          handleClose={() => setShowCreateModal(false)}
          onSubmit={() => onCreate()}
        />
      </div>
    );
  }
)`
  & .contacts-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  }
`;

const ContactItem = styled(({ className, contact, readOnly = true }) => {
  const { inquiry_id } = useParams();
  const { hasPermission } = usePermissions();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const queryClient = useQueryClient();
  const theme = useTheme();

  const handleEditEvidence = () => setShowEditModal(true);
  const handleDeleteEvidence = () => setShowDeleteModal(true);
  const onDelete = () => {
    queryClient.refetchQueries({
      queryKey: ["inquiries:list", { inquiry_id: parseInt(inquiry_id) }],
    });
  };

  const onEdit = () => {
    queryClient.refetchQueries({
      queryKey: ["inquiries:list", { inquiry_id: parseInt(inquiry_id) }],
    });
  };

  return (
    <div className={className}>
      <div className="contact-details-container">
        <div className="contact-name">{contact.person_name}</div>
        <div className="contact-email">{contact.unique_id}</div>
        <div className="contact-type">{contact.person_type}</div>
        <div className="action-menu">
          {!readOnly && hasPermission() && (
            <>
              <div className="action-menu-item" onClick={handleEditEvidence}>
                <EditOutlinedIcon
                  style={{ color: theme.palette.primary.main }}
                />
                <div className="action-menu-label">Edit</div>
              </div>
              <div className="action-menu-item" onClick={handleDeleteEvidence}>
                <DeleteOutlineOutlinedIcon
                  style={{ color: theme.palette.error.main }}
                />
                <div className="action-menu-label">Delete</div>
              </div>
            </>
          )}
        </div>
      </div>
      <div>
        <div>
          <span className="contact-email">Email: </span>
          <span className="contact-description">
            {contact.person_email || "No email provided."}
          </span>
        </div>
        <div className="contact-header">Description</div>
        <div className="contact-description">
          {contact?.description || "No description provided."}
        </div>
      </div>
      <EditModal
        open={showEditModal}
        handleClose={() => setShowEditModal(false)}
        onSubmit={() => onEdit()}
        defaultInfo={contact}
      />
      <DeleteModal
        open={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        onSubmit={() => onDelete()}
        defaultInfo={contact}
      />
    </div>
  );
})`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 5px;
  padding: 20px;
  display: flex;

  transition: all 0.2s ease-in-out;

  &:hover {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }

  & .contact-header {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 12px;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  & .contact-details-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    min-width: 150px;
  }

  & .contact-description {
    flex-grow: 1;
    white-space: pre-wrap;
    text-align: justify;
    font-size: 0.75rem;
  }

  & .contact-name {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  & .contact-type {
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  & .contact-email {
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  & .action-menu {
    display: flex;
    align-items: center;
    align-content: center;
    margin-top: auto;
    padding-top: 30px;
  }
  & .action-menu-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin-right: 12px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 10px;
    &:hover {
      text-decoration: underline;
    }
    & svg {
      font-size: 12px;
      margin-right: 5px;
    }
  }
`;

const CreateModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const { inquiry_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const form = useRef(null);

  const handleSubmit = () => {
    if (form.current.instance.validate().isValid) {
      const formData = { ...form.current.instance.option("formData") };
      const uuid = uuidv4();

      handleClose();

      InquiriesAPI.createInquiryPeople(inquiry_id, {
        ...formData,
        item_id: uuid,
      }).then((res) => {
        enqueueSnackbar(`Person Created.`, {
          variant: "success",
        });
        onSubmit();
      });
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        style={{ zIndex: 1400 }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 650,
            maxHeight: "90vh",
            overflowY: "auto",
            backgroundColor: theme.palette.background.default,
            position: "absolute",
            left: "50%",
            top: "20%",
            transform: "translate(-35%, -20%)",
            padding: 20,
            outline: "none",
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Create Contact
          </div>
          <Form ref={form} colCount={2}>
            <SimpleItem
              dataField="person_name"
              label={{ text: "Name" }}
              isRequired={true}
              editorOptions={{
                placeholder: "Enter this person's name",
              }}
            />
            <SimpleItem
              dataField="person_type"
              label={{ text: "Type" }}
              isRequired={true}
              editorType="dxSelectBox"
              editorOptions={{
                items: [
                  "Suspect",
                  "Victim",
                  "Witness",
                  "Employee",
                  "Contractor",
                  "Attorney",
                  "Prosecutor",
                  "Other",
                ],
                dropDownOptions: {
                  height: 250,
                },
              }}
            />
            <SimpleItem
              dataField="unique_id"
              label={{ text: "Unique ID" }}
              editorOptions={{
                placeholder: "Employee ID, DOB, email, etc.",
              }}
            />
            <SimpleItem
              dataField="person_email"
              label={{ text: "Email" }}
              editorOptions={{
                placeholder: "Enter email address",
              }}
            />
            <SimpleItem
              dataField="description"
              editorType="dxTextArea"
              colSpan={2}
              editorOptions={{
                height: 150,
                placeholder:
                  "Provide a brief description of this person and any relevant details.",
              }}
            />
          </Form>
          <ToolBarItems
            submitText="Create Contact"
            onSubmit={handleSubmit}
            onCancel={handleClose}
            style={{ marginTop: 10 }}
          />
        </div>
      </Modal>
    </>
  );
};

const DeleteModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const { inquiry_id } = useParams();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    InquiriesAPI.deleteInquiryPeople(inquiry_id, {
      item_id: defaultInfo.item_id,
    }).then(() => {
      enqueueSnackbar(`Contact Deleted.`, {
        variant: "success",
      });
      onSubmit();
    });
    handleClose();
  };

  const handleCancel = () => handleClose();

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 400,
          backgroundColor: theme.palette.background.default,
          position: "absolute",
          left: "50%",
          top: "20%",
          transform: "translate(-35%, -20%)",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Delete Inquiry Contact
        </div>
        <div style={{ margin: "10px 0px" }}>
          Are you sure you want to delete this Contact?
        </div>
        <div style={{ margin: "10px 0px" }}>"{defaultInfo.person_name}"</div>
        <div
          style={{
            marginTop: 30,
          }}
        >
          <ToolBarItems
            submitText="Delete Contact"
            submitColor="error"
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </div>
      </div>
    </Modal>
  );
};

const EditModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const { inquiry_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const form = useRef(null);

  const defaultFormData = {
    person_name: defaultInfo.person_name,
    person_type: defaultInfo.person_type,
    unique_id: defaultInfo.unique_id,
    description: defaultInfo.description,
    item_id: defaultInfo.item_id,
    person_email: defaultInfo.person_email,
  };

  const handleSubmit = () => {
    if (form.current.instance.validate().isValid) {
      const formData = { ...form.current.instance.option().formData };

      handleClose();

      const modeledData = { item_id: formData.item_id };

      for (const key of Object.keys(defaultFormData)) {
        if (!defaultFormData[key] || defaultFormData[key] !== formData[key]) {
          modeledData[key] = formData[key];
        }
      }

      InquiriesAPI.updateInquiryPeople(inquiry_id, modeledData).then((res) => {
        enqueueSnackbar(`Contact Updated.`, {
          variant: "success",
        });
        onSubmit();
      });
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        style={{ zIndex: 1400 }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 650,
            maxHeight: "90vh",
            overflowY: "auto",
            backgroundColor: theme.palette.background.default,
            position: "absolute",
            left: "50%",
            top: "20%",
            transform: "translate(-35%, -20%)",
            padding: 20,
            outline: "none",
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Edit Contact
          </div>
          <Form
            ref={form}
            colCount={2}
            defaultFormData={{ ...defaultFormData }}
          >
            <SimpleItem
              dataField="person_name"
              label={{ text: "Name" }}
              isRequired={true}
              editorOptions={{
                placeholder: "Enter this person's name",
              }}
            />
            <SimpleItem
              dataField="person_type"
              label={{ text: "Type" }}
              isRequired={true}
              editorType="dxSelectBox"
              editorOptions={{
                items: [
                  "Suspect",
                  "Victim",
                  "Witness",
                  "Employee",
                  "Contractor",
                  "Attorney",
                  "Prosecutor",
                  "Other",
                ],
                dropDownOptions: {
                  height: 250,
                },
              }}
            />
            <SimpleItem
              dataField="unique_id"
              label={{ text: "Unqiue Identifier" }}
              editorOptions={{
                placeholder: "Employee ID, DOB, email, etc.",
              }}
            />
            <SimpleItem
              dataField="person_email"
              label={{ text: "Email" }}
              editorOptions={{
                placeholder: "Enter email address",
              }}
            />
            <SimpleItem
              dataField="description"
              editorType="dxTextArea"
              colSpan={2}
              editorOptions={{
                height: 150,
                placeholder:
                  "Provide a brief description of this person and any relevant details.",
              }}
            />
          </Form>
          <ToolBarItems
            submitText="Edit Contact"
            onSubmit={handleSubmit}
            onCancel={handleClose}
            style={{ marginTop: 10 }}
          />
        </div>
      </Modal>
    </>
  );
};

export default PeopleContent;
