import { init, getDB } from "./db.js";
import UserPreferences from "./UserPreferences.js";
import NoteTabs from "./NoteTabs.js";
import TaskState from "./TaskState.js";

const IndexedDBService = {
  init,
  getDB,
  UserPreferences,
  NoteTabs,
  TaskState,
};

export default IndexedDBService;
