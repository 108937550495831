import LocationsAPI from "../../api/locations/index.js";

const EvidenceDimensions = [
  {
    name: "Evidence Number",
    field: "evidence_number",
    id: "evidence_number",
    type: "text",
    mode: "text",
  },
  {
    name: "UUID",
    field: "uuid",
    id: "uuid",
    type: "text",
    mode: "text",
  },
  {
    name: "Case Number",
    field: "case_number",
    id: "case_number",
    type: "text",
    mode: "text",
  },
  {
    name: "Case Name",
    field: "case_ref",
    id: "case_ref",
    type: "text",
    mode: "text",
  },
  {
    name: "Item Name",
    field: "model_name",
    id: "model_name",
    type: "text",
    mode: "text",
  },
  {
    name: "Location Received",
    field: "location_received",
    id: "location_received",
    type: "text",
    mode: "text",
  },
  {
    name: "Location Path",
    field: "location_path",
    id: "location_path",
    type: "text",
    mode: "text",
  },
  {
    name: "Parent Evidence Number",
    field: "parent_name",
    id: "parent_name",
    type: "text",
    mode: "text",
  },
  {
    name: "Received By",
    field: "received_by",
    id: "received_by",
    type: "text",
    mode: "text",
  },
  {
    name: "Received From",
    field: "received_from",
    id: "received_from",
    type: "text",
    mode: "text",
  },
  {
    name: "Linked Contact",
    field: "linked_contact",
    id: "linked_contact",
    type: "text",
    mode: "text",
  },
  {
    name: "Description",
    field: "description",
    id: "description",
    type: "text",
    mode: "text",
  },
  {
    name: "Created On",
    field: "created_on",
    id: "created_on",
    type: "date",
    mode: "date",
  },
  {
    name: "Last Audit Date",
    field: "last_audit_date",
    id: "last_audit_date",
    type: "date",
    mode: "date",
  },
  {
    name: "Intake Date",
    field: "intake_date",
    id: "intake_date",
    type: "date",
    mode: "date",
  },
  {
    name: "Type",
    field: "item_type",
    id: "item_type",
    type: "string",
    mode: "multi-select",
    key: ["evidence:types"],
    selectionDisplayName: "evi_type",
    selectionIdField: "evi_type",
  },
  {
    name: "Provider",
    field: "manufacturer",
    id: "manufacturer",
    type: "string",
    mode: "multi-select",
    key: ["evidence:providers"],
    selectionDisplayName: "manufacturer",
    selectionIdField: "manufacturer",
  },
  {
    name: "Progress",
    field: "progress",
    id: "progress",
    type: "string",
    mode: "multi-select",
    key: ["evidence:progress"],
    selectionDisplayName: "progress_name",
    selectionIdField: "progress_name",
  },
  {
    name: "Model Number",
    field: "model_number",
    id: "model_number",
    type: "text",
    mode: "text",
  },
  {
    name: "Unique ID",
    field: "serial_number",
    id: "serial_number",
    type: "text",
    mode: "text",
  },
  {
    name: "Current Location",
    field: "location_id",
    id: "location_id",
    type: "string",
    mode: "multi-select",
    key: ["evidence:locations", { include_groups: false }],
    queryFn: () => LocationsAPI.getLocations({}, { include_groups: false }),
    selectionDisplayName: "location_name",
    selectionIdField: "location_id",
    addtionalSelections: [
      { location_id: -1, location_name: "Destroyed" },
      { location_id: 1, location_name: "Released" },
      { location_id: 0, location_name: "At Parent" },
    ],
  },
  {
    name: "Client",
    field: "client_name",
    id: "client_name",
    type: "string",
    mode: "multi-select",
    key: ["clients:list"],
    selectionDisplayName: "name",
    selectionIdField: "client_id",
  },
  {
    name: "Organization",
    field: "client_organization",
    id: "client_organization",
    type: "string",
    mode: "multi-select",
    key: ["clients:organizations"],
    selectionDisplayName: "organization",
    selectionIdField: "organization",
  },
  {
    name: "Office",
    field: "office_name",
    id: "office",
    type: "string",
    mode: "multi-select",
    key: ["offices:list"],
    selectionDisplayName: "office_name",
    selectionIdField: "office_id",
  },
];

export default EvidenceDimensions;
