export const Features = {
  CASE_ANALYSIS: "cases:analysis",
};

const allowedDomains = {
  [Features.CASE_ANALYSIS]: [
    "monolithforensics.com",
    "accenture.com",
    "kyndryl.com",
    "bannerhealth.com",
    "summitforensics.com",
    "mtahq.org",
    "desjardins.com",
    "carle.com",
    "frsecure.com",
    "datto.com",
    "kaseya.com",
    "cyxcel.com",
    "cybervance.com",
    "ornl.gov",
    "mjolnirsecurity.com",
    "tenant_nNstkRaxjFep",
    "monolith_test",
    "tenant_1MrLFaYhsFz6",
    "tenant_qCHT1pb1dDmy",
  ],
};

export const FeatureFlagResolver = (email, tenant, feature) => {
  const domain = email.split("@")[1];
  return (
    allowedDomains[feature].includes(domain) ||
    allowedDomains[feature].includes(tenant)
  );
};
