import moment from "moment";
import { useFloating, flip, offset } from "@floating-ui/react";
import { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { FieldLabel, Calendar } from "@monolith-forensics/monolith-ui";
import { ChevronDownIcon } from "lucide-react";

function parseTimestamp(timestamp, format, utc = false) {
  // Define patterns to identify datetime components and their placeholders
  const patterns = [
    { pattern: "YYYY", placeholder: "YYYY", type: "year", momentType: "year" },
    { pattern: "MM", placeholder: "MM", type: "month", momentType: "month" },
    { pattern: "DD", placeholder: "DD", type: "day", momentType: "date" },
    { pattern: "HH", placeholder: "HH", type: "hour", momentType: "hour" },
    { pattern: "h", placeholder: "h", type: "hour", momentType: "hour" },
    { pattern: "mm", placeholder: "mm", type: "minute", momentType: "minute" },
    { pattern: "ss", placeholder: "ss", type: "second", momentType: "second" },
    {
      pattern: "SSS",
      placeholder: "SSS",
      type: "millisecond",
      moment: "millisecond",
    },
    {
      pattern: "A",
      placeholder: "AM/PM",
      type: "meridiem",
      momentType: "meridiem",
    },
    { pattern: "Z", placeholder: "Z", type: "timezone", moment: "timezone" },
  ];

  // Helper function to split format into datetime components and separators
  function splitFormat(format) {
    return format.split(/(YYYY|MM|DD|HH|h|mm|ss|SSS|Z)/).filter((s) => s);
  }

  // Helper function to match segments to their placeholders and types
  function matchSegment(segment) {
    const pattern = patterns.find((p) => p.pattern === segment);
    return pattern
      ? pattern
      : { pattern: segment, placeholder: segment, type: "separator" };
  }

  const segments = splitFormat(format).map((segment, i) => {
    const { pattern, placeholder, type, momentType } = matchSegment(segment);
    const value = timestamp
      ? utc === true
        ? moment.utc(timestamp).format(pattern)
        : moment(timestamp).format(pattern)
      : placeholder;

    return {
      value,
      text: value,
      placeholder,
      type,
      momentType,
      index: i,
    };
  });

  return segments;
}

const StyledFloatContainer = styled.div`
  z-index: 100000;
  width: fit-content;
  height: auto;
`;

const StyledContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: fit-content;
  background-color: ${(props) => props.theme.palette.background.default};
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.palette.divider};
  overflow: hidden;
  padding: 5px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.85),
    0px 10px 20px -15px rgba(22, 23, 24, 0.65);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
`;

const CalendarContainer = styled(
  ({ className, isOpen = false, setIsOpen = () => {}, containerRef }) => {
    const { refs, floatingStyles } = useFloating({
      open: isOpen,
      onOpenChange: setIsOpen,
      placement: "bottom-start",
      strategy: "absolute",
      // Handle collisions with the viewport
      middleware: [flip(), offset(5)],
    });

    refs.setReference(containerRef);

    return (
      <StyledFloatContainer
        ref={refs.popper}
        className={className}
        style={floatingStyles}
      >
        <StyledContent>Calendar</StyledContent>
      </StyledFloatContainer>
    );
  }
)``;

const DateField = styled(
  ({
    className,
    defaultValue,
    format = "YYYY-MM-DD HH:mm:ss",
    label = null,
    onChange = () => {},
    min = null,
    max = null,
    error,
    showDropDownArrow = false,
    enableCalendar = false,
    utc = false,
  }) => {
    const [value, setValue] = useState(defaultValue);
    const [selectedSegment, setSelectedSegment] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const mainRef = useRef(null);
    const meridanRef = useRef(true);

    const typedKeys = useRef("");

    const { refs, floatingStyles } = useFloating({
      open: isOpen,
      onOpenChange: setIsOpen,
      placement: "bottom-start",
      strategy: "absolute",
      // Handle collisions with the viewport
      middleware: [flip(), offset(5)],
    });

    const segments = useMemo(
      () =>
        parseTimestamp(value ? moment(value).toISOString() : null, format, utc),
      [value, format]
    );

    const checkValidRange = (value) => {
      if (min && moment(value).isBefore(min)) return false;
      if (max && moment(value).isAfter(max)) return false;
      return true;
    };

    const toggleOpen = () => {
      setIsOpen((prev) => {
        return !prev;
      });
    };

    const handleSegmentClick = (e, segment) => {
      e.stopPropagation();
      e.preventDefault();
      if (segment.type === "separator") return;
      mainRef.current.querySelector("div[data-type='input']").focus();
      setSelectedSegment(segment);
    };

    const handleContainerClick = (e) => {
      e.stopPropagation();
      e.preventDefault();
      mainRef.current.querySelector("div[data-type='input']").focus();
      setIsOpen(true);
      setSelectedSegment(segments[0]);
    };

    const nextSegment = () => {
      setSelectedSegment((prev) => {
        const next = segments[prev.index + 2];
        return next || prev;
      });
    };

    // prevent click and drag selection
    const handleMouseDown = (e) => {
      e.preventDefault();
    };

    const handleKeyDown = (e) => {
      if (!selectedSegment) return;
      // tab
      if (e.key === "Tab") {
        setSelectedSegment(null);
        setIsOpen(false);
        return;
      }
      // Right Arrow
      if (e.key === "ArrowRight") {
        e.preventDefault();
        setSelectedSegment((prev) => {
          const next = segments[prev.index + 2];
          return next || prev;
        });
      }
      // Left Arrow
      if (e.key === "ArrowLeft") {
        e.preventDefault();
        setSelectedSegment((prev) => {
          const next = segments[prev.index - 2];
          return next || prev;
        });
      }

      // Up Arrow
      if (e.key === "ArrowUp") {
        e.preventDefault();

        setValue((prev) => {
          const newValue = prev
            ? moment(prev).add(1, selectedSegment.type).toISOString()
            : moment().toISOString();
          if (!checkValidRange(newValue)) return prev;
          onChange?.(newValue);
          return newValue;
        });
      }

      // Down Arrow
      if (e.key === "ArrowDown") {
        e.preventDefault();

        setValue((prev) => {
          const newValue = prev
            ? moment(prev).subtract(1, selectedSegment.type).toISOString()
            : moment().toISOString();
          if (!checkValidRange(newValue)) return prev;

          onChange?.(newValue);
          return newValue;
        });
      }

      // only allow numbers
      if (e.key.match(/[0-9]/)) {
        e.preventDefault();
        const segmentLength = selectedSegment.text.length;

        typedKeys.current += e.key;
        let tempValue = typedKeys.current;

        if (
          ["month", "day"].includes(selectedSegment.type) &&
          Array.from(tempValue).every((c) => c === "0")
        ) {
          tempValue = "01";
        }

        setValue((prev) => {
          const momentObject = utc ? moment.utc(prev) : moment(prev);
          const newValue = momentObject
            .set(
              selectedSegment.momentType,
              parseInt(tempValue, 10) -
                (selectedSegment.type === "month" ? 1 : 0)
            )
            .toISOString();
          if (!checkValidRange(newValue)) return prev;
          onChange?.(newValue);
          return newValue;
        });

        if (typedKeys.current.length === segmentLength) {
          nextSegment();
          typedKeys.current = "";
        }
      }

      e.preventDefault();
    };

    const handleWheelEvent = (e) => {
      if (!selectedSegment) return;
      e.preventDefault();
      e.stopPropagation();
      if (e.deltaY > 0) {
        setValue((prev) => {
          const newValue = prev
            ? moment(prev).subtract(1, selectedSegment.type).toISOString()
            : moment().toISOString();
          if (!checkValidRange(newValue)) return prev;
          onChange?.(newValue);
          return newValue;
        });
      } else {
        setValue((prev) => {
          const newValue = prev
            ? moment(prev).add(1, selectedSegment.type).toISOString()
            : moment().toISOString();
          if (!checkValidRange(newValue)) return prev;
          onChange?.(newValue);
          return newValue;
        });
      }
    };

    const contentWidth =
      refs?.reference?.current?.getClientRects?.()?.[0].width;

    // Close on outside click
    useEffect(() => {
      const handleClick = (e) => {
        if (
          !mainRef.current.contains(e.target) &&
          !refs.floating.current.contains(e.target)
        ) {
          setSelectedSegment(null);
          setIsOpen(false);
        }
      };
      document.addEventListener("mousedown", handleClick);
      return () => {
        document.removeEventListener("mousedown", handleClick);
      };
    }, []);

    // Global Wheel Event when segement selected
    useEffect(() => {
      if (selectedSegment) {
        document.addEventListener("wheel", handleWheelEvent);
      }
      return () => {
        document.removeEventListener("wheel", handleWheelEvent);
      };
    }, [selectedSegment]);

    return (
      <div>
        {label.enabled !== false && (
          <FieldLabel error={error || "Required"}>
            {label.text || "Date"}
          </FieldLabel>
        )}
        <div
          ref={(ref) => {
            mainRef.current = ref;
            refs.setReference(ref);
          }}
          className={className}
          onClick={handleContainerClick}
          onMouseDown={handleMouseDown}
          onKeyDown={handleKeyDown}
          onFocus={(e) => {
            e.preventDefault();
            setSelectedSegment(segments[0]);
          }}
          data-empty={!value}
          role="textbox"
        >
          {segments.map((segment, i) => {
            if (segment.type === "separator") {
              return (
                <div
                  className="separator"
                  key={i}
                  tabIndex={-1}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onFocus={(e) => e.preventDefault()}
                  onPointerDown={(e) => e.preventDefault()}
                  data-type="separator"
                  data-identifier={segment.type}
                  data-has-space={segment.text.includes(" ")}
                  data-placeholder={segment.placeholder}
                  data-value={segment.value}
                >
                  {segment.text}
                </div>
              );
            }
            return (
              <div
                className={"input"}
                contentEditable
                suppressContentEditableWarning
                key={i}
                tabIndex={i === 0 ? 0 : -1}
                onClick={(e) => handleSegmentClick(e, segment)}
                data-type="input"
                data-identifier={segment.type}
                data-has-space={segment.text.includes(" ")}
                data-placeholder={segment.placeholder}
                data-value={segment.value}
                data-selected={selectedSegment?.index === segment.index}
              >
                {value ? segment.text : segment.placeholder}
              </div>
            );
          })}
          {utc && <div style={{ marginLeft: 5 }}>UTC</div>}
          {showDropDownArrow && (
            <ChevronDownIcon size={16} style={{ marginLeft: "auto" }} />
          )}
        </div>
        <StyledFloatContainer ref={refs.setFloating} style={floatingStyles}>
          {enableCalendar && isOpen && (
            <StyledContent style={{ width: contentWidth }}>
              <Calendar
                key={1}
                defaultValue={value ? moment(value).toDate() : undefined}
                min={min}
                max={max}
                onChange={(date) => {
                  setValue((prev) => {
                    // make copy of prev variable
                    const oldValue = moment(prev).toISOString();

                    const result = `${moment(date).format(
                      "YYYY-MM-DD"
                    )}T${moment(prev || undefined).format("HH:mm:ss")}`;

                    const isoResult = moment(result).toISOString();

                    if (!checkValidRange(result)) return oldValue;

                    onChange?.(isoResult);
                    return isoResult;
                  });
                }}
                includeTime={false}
              />
            </StyledContent>
          )}
        </StyledFloatContainer>
      </div>
    );
  }
)`
  caret-color: transparent;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid
    ${({ theme, variant }) => {
      switch (variant) {
        case "contained":
          return "transparent";
        case "outlined":
          return theme.palette.input.border;
        case "text":
          return "transparent";
        default:
          return theme.palette.input.border;
      }
    }};
  border-radius: 5px;
  color: ${(props) => props.theme.palette.text.primary};
  outline: none;
  padding: ${({ size }) =>
    size === "xs"
      ? "0px 8px"
      : size === "sm"
      ? "0px 10px"
      : size === "md"
      ? "0px 12px"
      : size === "lg"
      ? "0px 14px"
      : size === "xl"
      ? "0px 16px"
      : "0px 8px"};
  width: ${({ width }) => {
    if (typeof width === "undefined") return "100%";
    if (width === null) return "100%";
    if (typeof width === "string") return width;
    if (typeof width === "number") return `${width}px`;
  }};
  height: auto;
  background-color: ${({ theme, variant }) => {
    switch (variant) {
      case "contained":
        return theme.palette.background.secondary;
      case "outlined":
        return "transparent";
      case "text":
        return "transparent";
      default:
        return "transparent";
    }
  }};
  transition: border 0.1s ease-in-out;

  &:hover {
    border: 1px solid
      ${({ theme, variant }) => {
        switch (variant) {
          case "contained":
            return theme.palette.primary.main;
          case "outlined":
            return theme.palette.primary.main;
          case "text":
            return theme.palette.primary.main;
          default:
            return theme.palette.primary.main;
        }
      }};
  }
  &:focus {
    border: 1px solid ${(props) => props.theme.palette.primary.main};
  }
  &:focus-within {
    border: 1px solid ${(props) => props.theme.palette.primary.main};
  }

  & div {
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    pointer-events: all;
    outline: none;
    border: none;
    padding: 0;
    font-size: ${({ size }) =>
      size === "xs"
        ? "12px"
        : size === "sm"
        ? "14px"
        : size === "md"
        ? "16px"
        : size === "lg"
        ? "18px"
        : size === "xl"
        ? "20px"
        : "12px"};
    height: ${({ size }) =>
      size === "xs"
        ? "22px"
        : size === "sm"
        ? "26px"
        : size === "md"
        ? "32px"
        : size === "lg"
        ? "38px"
        : size === "xl"
        ? "46px"
        : "22px"};
    background-color: transparent;
    color: ${(props) => props.theme.palette.text.primary};
  }

  & [data-has-space="true"] {
    padding-left: 4px;
  }

  & [data-selected="true"] {
    background-color: ${(props) => props.theme.palette.primary.main}50;
  }

  &[data-empty="true"] {
    color: ${(props) => props.theme.palette.text.secondary};
    div {
      color: ${(props) => props.theme.palette.text.secondary};
    }
  }
`;

export default DateField;
