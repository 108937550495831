import { useTheme } from "styled-components";
import { usePermissions } from "../../../hooks/usePermissions";
import CaseStorageAPI from "../../../api/storage/index.js";
import { Modal } from "@mui/material";
import { useSnackbar } from "notistack";
import ToolBarItems from "../../../components/ToolBarItems.js";

export const RemoveItemsPopup = ({
  caseInfo,
  onSubmit = () => {},
  open,
  handleClose,
  table,
  query,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    const moveInfo = {
      total: table?.getVirtualSelectionTotal() || 0,
      caseInfo: {
        case_id: caseInfo.case_id,
        case_number: caseInfo.case_number,
        case_ref: caseInfo.case_ref,
      },
      options: {
        selectionState: table?.getSelectionState() || null,
        included: table?.getSelectedRows().map((r) => r.uuid) || [],
        excluded: table?.getExcludedRows().map((r) => r.uuid) || [],
        ...query,
      },
    };

    if (moveInfo.total > 0) {
      CaseStorageAPI.removeStorageFromCase({ options: moveInfo.options }).then(
        (result) => {
          enqueueSnackbar(`Storage Items Removed`, {
            variant: "success",
          });
          onSubmit();
        }
      );
    } else {
      enqueueSnackbar("No storage items selected.", {
        variant: "warning",
      });
    }

    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 500,
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 250px)",
          maxHeight: "calc(100vh - 100px)",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Remove Storage Items
        </div>
        <div>
          Are you sure you want to remove these storage items from the case?
        </div>
        <div style={{ marginTop: 20 }}>
          Chain of custody records will be cleared and acquisitions will be
          unlinked from these items.
        </div>
        <ToolBarItems
          submitText="Remove Items"
          submitColor="error"
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          style={{ marginTop: 20 }}
        />
      </div>
    </Modal>
  );
};
