import styled from "styled-components";
import { Button } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import TasksAPI from "../../../api/tasks/tasks.js";
import Dialog from "../../../Monolith-UI/Dialog/Dialog.js";
import Loader from "../../Loader.js";
import { FieldLabel } from "@monolith-forensics/monolith-ui";

const Menu = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const TaskNameInput = styled.input`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 5px;
  width: 100%;
  height: 30px;
  padding: 3px;

  color: ${({ theme }) => theme.palette.text.primary};

  font-size: 12px;
  font-family: inherit;
  font-weight: normal;

  // Set to single line
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  //Remove resize handle
  resize: none;

  &.error {
    border: 1px solid ${({ theme }) => theme.palette.error.main};
  }

  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }
`;

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 200px;
  min-width: 400px;
  max-width: 400px;

  // Allow height to increase with content
  height: fit-content;
`;

const StyledButton = styled(Button)`
  padding: 0 10px;
`;

const SaveTaskTemplateModal = ({
  open = false,
  onClose,
  defaultFormData = {},
  onSubmit,
}) => {
  const [formData, setFormData] = useState(defaultFormData);
  const [submitting, setSubmitting] = useState(false);
  const templateNameRef = useRef(null);

  const handleTemplateNameChange = (e) => {
    templateNameRef.current.classList.remove("error");
    setFormData((prev) => ({
      ...prev,
      template_name: e.target.value,
    }));
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.template_name) {
      templateNameRef.current.classList.add("error");
      errors.template_name = "Template name is required";
    }

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    setSubmitting(true);

    const finalFormData = { ...formData };

    if (finalFormData?.sub_tasks) {
      finalFormData.sub_tasks = finalFormData.sub_tasks.map((subtask) => {
        return {
          priority_id: subtask.priority_id || 2,
          task_notes: subtask.task_notes || "",
          task_name: subtask.task_name,
          time_category_id: subtask.time_category_id || null,
        };
      });
    }

    // Ensure that status id is not saved
    delete finalFormData.task_data.status_id;

    await TasksAPI.createTaskTemplate(finalFormData);

    onSubmit?.(finalFormData);

    onClose?.();
    setSubmitting(false);
  };

  useEffect(() => {
    setFormData(defaultFormData);
  }, [defaultFormData]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        {submitting && <Loader message="Creating Task..." />}
        {!submitting && (
          <>
            <div style={{ fontSize: 16, fontWeight: 600 }}>
              Create Task Template
            </div>
            <div>
              This will create a task template that can be used at anytime.The
              task priority, description, category, and sub-tasks will be saved.
            </div>
            <div style={{}}>
              <FieldLabel>Template Name</FieldLabel>
              <TaskNameInput
                ref={templateNameRef}
                defaultValue={formData.template_name || ""}
                placeholder={"Template name"}
                maxLength={512}
                autoComplete="off"
                onChange={handleTemplateNameChange}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
              />
            </div>

            <div style={{ marginTop: "auto" }}>
              <Menu topBorder style={{ justifyContent: "flex-end", gap: 10 }}>
                <StyledButton
                  variant="text"
                  color="primary"
                  onClick={() => onClose()}
                >
                  Cancel
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                >
                  Save Template
                </StyledButton>
              </Menu>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SaveTaskTemplateModal;
