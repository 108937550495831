import { useSearchParams } from "react-router-dom";
import ContactsImportView from "./ContactsImportView";
import CaseContactsView from "./CaseContactsView";
import { usePermissions } from "../../../hooks/usePermissions";

const CaseContacts = () => {
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  let [searchParams, setSearchParams] = useSearchParams();

  const view = searchParams.get("v") || "list";

  return (
    <>
      {view === "list" && <CaseContactsView />}
      {hasPermission(MONOLITH_PERMISSIONS.CONTACTS_BULK_IMPORT) &&
        view === "import" && <ContactsImportView />}
    </>
  );
};

export default CaseContacts;
