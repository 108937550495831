import { httpDelete, httpGet, httpPatch, httpPost } from "../connection";
import readFileAsBase64 from "../../utils/readFileAsBase64";

export async function getCaseDocs(values = { case_id: "" }) {
  return await httpGet(`/cases/${values.case_id}/documents`);
}

export async function getCaseDoc(values = { case_id: null, file_id: null }) {
  return await httpGet(`/cases/${values.case_id}/documents/${values.file_id}`);
}

export async function uploadCaseDocuments(
  values = { case_id: null, filePaths: [] }
) {
  let requests = [];

  for (const file of values.filePaths) {
    let buffer = await readFileAsBase64(file);
    let filename = file.name;
    let extension = file.name.split(".")[1];
    let size = file.size;

    if (size > 30000000) {
      requests.push({
        filename: filename,
        message: "File size is too large",
        status: 400,
      });
    } else {
      requests.push(
        httpPost(`/cases/${values.case_id}/documents`, {
          case_id: values.case_id,
          user_id: values.user_id,
          created_on: values.upload_date,
          file: {
            filename: filename,
            size: size,
            ext: extension,
            data: buffer,
          },
        })
      );
    }
  }

  return await Promise.all(requests);
}

export async function updateCaseDoc(file_id, values = {}) {
  let caseID = values.case_id;
  delete values.case_id;
  return await httpPatch(`/cases/${caseID}/documents/${file_id}`, values);
}

export async function deleteCaseDoc(values = { case_id: null, file_id: null }) {
  return await httpDelete(
    `/cases/${values.case_id}/documents/${values.file_id}`
  );
}
