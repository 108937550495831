import styled, { useTheme } from "styled-components";
import { usePermissions } from "../../../hooks/usePermissions";
import { useSnackbar } from "notistack";
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import synchronizeColumnState from "../../../utils/synchronize-column-state.js";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import Table, { Column, useTable } from "../../../Monolith-UI/Table/Table.js";
import UserApi from "../../../api/users/users.js";
import { getDateFormat, monolithMoment } from "../../../utils/date-format";
import { useQueryFilter } from "../../../Monolith-UI/QueryFilter/QueryFilter.js";
import UsersFilterDimensions from "../../../components/Users/UsersFilterDimensions.js";
import { Button, Modal } from "@mui/material";
import ComboButton from "../../../Monolith-UI/ComboButton/ComboButton.js";
import Loader from "../../../components/Loader.js";
import Flyout, { FlyoutHeader } from "../../../Monolith-UI/Flyout/Flyout.js";
import TaskButton from "../../../Monolith-UI/TaskButton.js";
import { nanoid } from "nanoid";
import UsersColumnDefs from "../../../components/Users/UsersColumnDefs.js";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";
import { UserIcon } from "lucide-react";

import { ItemTotal } from "../../Cases/CaseEvidence/index";
import OfficesApi from "../../../api/offices/index.js";
import UserRolesAPI from "../../../api/UserRoles/UserRoles.js";
import { isLicenseAvailable, logActivity } from "../../../api/index.js";
import { Form, SimpleItem } from "devextreme-react/ui/form.js";
import { Template } from "devextreme-react/core/template.js";
import UserRoleSelectBoxItem from "../../../components/SelectBoxItems/UserRoleSelectBoxItem.js";
import ToolBarItems from "../../../components/ToolBarItems.js";
import AccessDeniedMessage from "../../../components/AccessDeniedMessage.js";
import { Input } from "@monolith-forensics/monolith-ui";
import { ColumnProps, TableColumn } from "@/Monolith-UI/Table/types/Table";
import { UserRole } from "../types/Users";
import { Condition } from "@/pages/Cases/types/Cases";
import { FullUser } from "./UserDetails";

interface UserTabQuery {
  query: {
    conditions?: Condition[];
    page?: number;
    search?: string | null;
    order?: {
      field: string;
      sort: string;
    } | null;
  };
}

interface QueryFilterRef {
  clear: () => void;
}

const columnDefs = UsersColumnDefs;

const validatePasswordLength = (password: string) => {
  return password.length > 7;
};

const validatePasswordPattern = (password: string) => {
  const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!+_\\-\\.@#$%^&*])/;
  return password.match(pattern);
};

const CreateModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
}: {
  open: boolean;
  handleClose: () => void;
  onSubmit: () => void;
}) => {
  const { currentUser } = usePermissions();
  const theme: any = useTheme();
  const form = useRef<any>(null);

  const { enqueueSnackbar } = useSnackbar();

  const { data: offices } = useQuery({
    queryKey: ["offices:list"],
    queryFn: () => OfficesApi.getOffices(),
    enabled: open,
  });

  const { data, isLoading } = useQuery({
    queryKey: ["user-roles", "list"],
    queryFn: () => UserRolesAPI.get(),
  });

  const userRoles = data?.data || [];

  const handleCompleteSubmit = async ({ isValid }: { isValid: boolean }) => {
    if (!isValid) return;
    let formData = { ...form.current.instance.option("formData") };

    if (!formData.password || formData.password === "") {
      formData.password = null;
    }

    handleClose();

    //Verify User Limit
    if (!(await isLicenseAvailable())) {
      //show toast bar
      enqueueSnackbar(
        "User limit reached - deactivate a user or purchase more licenses.",
        {
          variant: "error",
        }
      );
    } else {
      //Create User

      formData.active = 1;
      formData.username = formData.email.split("@")[0];

      UserApi.createUser(formData).then(() => {
        onSubmit();
        logActivity(
          "Admin",
          currentUser.user_id,
          `New User Created: Role: ${formData.user_role} | ${
            formData.first_name + " " + formData.last_name
          } | ${formData.username} | ${formData.email}`
        );
      });
    }
  };

  const handleSubmit = () => {
    const res = form.current.instance.validate();
    if (res.status === "pending") {
      res.complete.then((r: any) => {
        handleCompleteSubmit({ isValid: r.isValid });
      });
    } else {
      handleCompleteSubmit({ isValid: res.isValid });
    }
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 700,
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 350px)",
          top: 20,
          overflowY: "auto",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>Create User</div>
        <Form ref={form} colCount={2}>
          <SimpleItem
            dataField="first_name"
            label={{ text: "First Name" }}
            isRequired={true}
          />
          <SimpleItem
            dataField="last_name"
            label={{ text: "Last Name" }}
            isRequired={true}
          />
          <SimpleItem
            dataField="email"
            label={{ text: "Email" }}
            validationRules={[
              { type: "email" },
              { type: "required" },
              {
                type: "async",
                message: "Email already exists",
                validationCallback: (e: any) => {
                  return new Promise((resolve, reject) => {
                    UserApi.validateUser({ email: e.value }).then((result) => {
                      resolve(result.is_user_valid);
                    });
                  });
                },
              },
            ]}
          />
          <SimpleItem
            dataField="password"
            label={{ text: "Password" }}
            editorOptions={{
              mode: "password",
              maxLength: 100,
              placeholder: "Leave blank to auto-generate",
              inputAttr: { autoComplete: "new-password" },
            }}
            validationRules={[
              {
                type: "custom",
                validationCallback: (e: any) => {
                  if (!e.value || e.value === "") return true;
                  return validatePasswordLength(e.value);
                },
                message: "Password must be at least 8 characters",
              },
              {
                type: "custom",
                validationCallback: (e: any) => {
                  if (!e.value || e.value === "") return true;
                  return validatePasswordPattern(e.value);
                },
                message: "Must contain upper, lower, special, & numeric.",
              },
            ]}
          />
          <SimpleItem
            dataField="office_id"
            label={{ text: "Office" }}
            isRequired={true}
            editorType="dxSelectBox"
            editorOptions={{
              items: offices,
              valueExpr: "office_id",
              displayExpr: "office_name",
            }}
          />
          <SimpleItem
            dataField="role_id"
            label={{ text: "Role" }}
            isRequired={true}
            editorType="dxSelectBox"
            editorOptions={{
              valueExpr: "role_id",
              displayExpr: "text",
              items: userRoles.map((role: UserRole) => ({
                text: role.name,
                description: role.description,
                role_id: role.id,
              })),
              wrapItemText: true,
              itemTemplate: "user_role",
              dropDownOptions: {
                height: 200,
              },
            }}
          />
          <Template
            name="user_role"
            render={(data: any) => <UserRoleSelectBoxItem data={data} />}
          />
          <SimpleItem
            dataField="observer"
            label={{ text: "Read Only Status" }}
            isRequired={true}
            editorType="dxSelectBox"
            editorOptions={{
              valueExpr: "value",
              displayExpr: "text",
              value: 0,
              items: [
                {
                  text: "Read Only",
                  value: 1,
                },
                {
                  text: "Write Access",
                  value: 0,
                },
              ],
              wrapItemText: true,
            }}
          />
        </Form>
        <div style={{ marginTop: 20 }}>
          <ToolBarItems
            submitText="Create User"
            onSubmit={handleSubmit}
            onCancel={handleClose}
          />
        </div>
      </div>
    </Modal>
  );
};

const FlyoutContent = styled(({ className, defaultInfo, onEdit, onDelete }) => {
  const queryClient = useQueryClient();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { data: itemData } = useQuery({
    queryKey: ["users:list", { user_id: defaultInfo.user_id }],
    queryFn: () =>
      UserApi.getUsers({
        user_id: defaultInfo.user_id,
      }),
    initialData: [defaultInfo],
    select: (res) => {
      return res[0];
    },
  });

  const title = document.querySelector(".flyout-title") as HTMLElement;
  if (title) {
    title.innerText = itemData?.full_name || defaultInfo.full_name || "";
  }

  const dataFieldIgnoreList = [""];

  const detail = (
    <div className="detail-container">
      {columnDefs
        .filter((c) => !dataFieldIgnoreList.includes(c.dataField))
        .map((c) => {
          return (
            <div className="detail-item" key={nanoid()}>
              <div className="detail-label">{c.caption}</div>
              {c.render ? (
                c.render(itemData)
              ) : (
                <div className="detail-value">{itemData[c.dataField]}</div>
              )}
            </div>
          );
        })}
    </div>
  );

  return (
    <div className={className}>
      {!!itemData && (
        <>
          {detail}
          <div className="detail-label" style={{ marginTop: 30 }}>
            Description
          </div>
          <div style={{ position: "relative" }}>
            <div className="item-description">{itemData.description}</div>
          </div>
        </>
      )}
      {!itemData && <Loader message={"Loading..."} />}
    </div>
  );
})`
  padding: 20px;
  & {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    margin-right: 5px;
  }
  & .action-menu {
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 15px;
    margin-right: 10px;
  }
  & .action-menu-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin-right: 12px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
    & svg {
      font-size: 15px;
      margin-right: 5px;
    }
  }
  & .detail-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 10px 0px;
    font-size: 12px;
  }
  & .detail-label {
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-right: 10px;
    min-width: 150px;
  }
  & .detail-value {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  & .item-description {
    position: relative;
    resize: none;
    margin-top: 10px;
    font-size: 12px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: transparent;
    white-space: pre-wrap;
    width: 100%;
    height: fit-content;
    max-height: 30%;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 10px;
    transition: border 0.2s ease-in-out;
    outline: none;
    &:hover {
      border: 1px solid ${({ theme }) => theme.palette.divider};
    }
    &:focus {
      border: 1px solid ${({ theme }) => theme.palette.primary.main};
    }
  }
  & .item-description-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.palette.divider};
    display: flex;
    alignitems: center;
    fontsize: 12px;
    padding: 10px;
  }
`;

const ItemFlyout = styled(
  ({
    setIsFlyoutVisible,
    setFlyoutData,
    flyoutData,
    handleGetNextItem = () => {},
    handleGetPrevItem = () => {},
    onEdit = () => {},
    onDelete = () => {},
  }) => {
    const theme: any = useTheme();
    return (
      <Flyout
        onClose={() => {
          setIsFlyoutVisible(false);
          setFlyoutData(null);
        }}
      >
        <FlyoutHeader>
          <div style={{ width: "100%" }}>
            <h4
              className="flyout-title"
              style={{
                margin: "5px 0px",
                color: theme.palette.text.primary,
              }}
            >
              {flyoutData.full_name || "..."}
            </h4>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: theme.palette.text.secondary,
                  fontSize: 12,
                }}
              >
                User Details
              </div>
              <div
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <TaskButton
                  onClick={() => handleGetPrevItem(flyoutData.user_id)}
                  style={{
                    fontSize: 10,
                  }}
                  variant="outlined"
                >
                  <KeyboardArrowLeftOutlinedIcon style={{ fontSize: 17 }} />
                </TaskButton>
                <TaskButton
                  onClick={() => handleGetNextItem(flyoutData.user_id)}
                  style={{
                    fontSize: 10,
                  }}
                  variant="outlined"
                >
                  <KeyboardArrowRightOutlinedIcon style={{ fontSize: 17 }} />
                </TaskButton>
              </div>
            </div>
          </div>
        </FlyoutHeader>
        <FlyoutContent
          defaultInfo={flyoutData}
          onEdit={onEdit}
          onDelete={() => {
            setIsFlyoutVisible(false);
            onDelete();
          }}
        />
      </Flyout>
    );
  }
)``;

const UsersTabContentWrapper = styled(
  ({
    className,
    stateStoreKey = "users:view",
    defaultQueryKey = "users:list",
  }) => {
    const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
    const { enqueueSnackbar } = useSnackbar();
    const theme: any = useTheme();
    const queryFilter = useRef<QueryFilterRef | null>(null);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [hasInitialized, setHasInitialized] = useState(true);
    const [pageSize, setPageSize] = useState(100);
    const [searchText, setSearchText] = useState(
      localStorage.getItem(`${stateStoreKey}:searchText`)
    );
    const [debouncedSearchText] = useDebounce(searchText, 500);

    const [columnState, setColumnState] = useState(() => {
      return synchronizeColumnState(
        columnDefs,
        JSON.parse(localStorage.getItem(stateStoreKey) || "{}")
      );
    });

    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const [flyoutData, setFlyoutData] = useState<FullUser | null>(null);

    const table = useTable();

    const currentSort = useMemo(() => {
      let [sort] = columnState
        ?.filter((c) => c.sorting?.active)
        .map((c) => ({ field: c.dataField, sort: c.sorting?.direction }));

      return sort;
    }, [columnState]);

    const [query, setQuery] = useState<UserTabQuery | null>(null);

    const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } =
      useInfiniteQuery({
        queryKey: [
          defaultQueryKey,
          {
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
            },
          },
        ],
        queryFn: () =>
          UserApi.query({
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
            },
          }),
        getNextPageParam: (lastPage) => {
          return lastPage.nextPage;
        },
        getPreviousPageParam: (firstPage) => {
          if (firstPage.page - 1 === 0) return null;
          return firstPage.page - 1;
        },
        initialPageParam: 1,
        enabled: !!query,
        placeholderData: (data) => data,
      });

    const records = data?.pages?.reduce((acc, page) => {
      return [...acc, ...page.data];
    }, []);

    const totalRecords = data?.pages?.[0]?.total || 0;

    const debouncedFetchNextPage = useDebouncedCallback(() => {
      fetchNextPage();
    }, 50);

    const handleSort = (field: string) => {
      const savedColumn =
        columnState?.find((svc) => field === svc.dataField) || {};
      const order = !!savedColumn ? savedColumn?.sorting?.direction : null;

      let newOrder = null;

      // if sorted on a different column, reset the sort
      if (query?.query?.order?.field !== field) {
        newOrder = {
          field,
          sort: "asc",
        };
      }

      // otherwise rotate the sort options on the current column
      else {
        switch (order) {
          case "asc":
            newOrder = {
              field,
              sort: "desc",
            };
            break;
          case "desc":
            newOrder = null;
            break;
          default:
            newOrder = {
              field,
              sort: "asc",
            };
        }
      }

      let newCols = columnState?.map((c) => {
        if (c.dataField === field) {
          return {
            ...c,
            sorting: {
              active: newOrder ? true : false,
              direction: newOrder?.sort,
            },
          };
        }
        delete c.sorting;
        return c;
      });

      let newQuery = {
        query: {
          ...query?.query,
          order: newOrder,
        },
      };

      setColumnState(newCols);

      setQuery(newQuery);
    };

    const handleFilter = (newFilter: Condition) => {
      setQuery((q) => {
        return { query: { ...q?.query, ...newFilter, page: 1 } };
      });
    };

    const handleClearFilters = () => {
      queryFilter.current && queryFilter.current.clear();
    };

    const handleExportTable = () => {
      // show snackbar
      enqueueSnackbar("Exporting table...", {
        variant: "info",
      });

      UserApi.exportUsersList({
        query: {
          ...query?.query,
          order: query?.query?.order || currentSort,
          pageSize,
        },
        type: "xlsx",
        columns: columnState
          .filter((c) => c.visible !== false)
          .sort((a, b) => a.order - b.order)
          .map((c) => {
            return { dataField: c.dataField, header: c.caption, ...c };
          }),
        date_format: getDateFormat({
          isMoment: true,
          includeTime: true,
        }),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }).then((res) => {
        const { signedUrl, filename } = res;
        const el = document.createElement("a");
        el.href = signedUrl.replace(
          "http://localhost:3000",
          "http://localhost:3001"
        );

        el.download = filename;
        el.click();
        // remove snackbar
      });
    };

    const handleColumnReorder = (
      newOrder: { column: string; order: number }[]
    ) => {
      setColumnState((cs) => {
        return newOrder.map((o) => {
          return {
            ...cs.find((c) => c.dataField === o.column),
            order: o.order,
          };
        });
      });
    };

    const handleColumnVisibility = (
      column: ColumnProps<FullUser>,
      visible: boolean
    ) => {
      setColumnState((cs) => {
        return cs.map((c) => {
          if (c.dataField === column.dataField) {
            return {
              ...c,
              visible,
            };
          }
          return c;
        });
      });
    };

    const handleActionButtonClick = (rowData: FullUser) => {
      setIsFlyoutVisible(true);
      setFlyoutData(rowData);
    };

    const handleGetNextItem = (id: number) => {
      const current = records?.findIndex((i: FullUser) => i.user_id === id);
      const nextItem = records[current + 1] || records[0];

      setFlyoutData(nextItem);
    };
    const handleGetPrevItem = (id: number) => {
      const current = records?.findIndex((i: FullUser) => i.user_id === id);
      const prevItem = records[current - 1] || records[records.length - 1];

      setFlyoutData(prevItem);
    };
    const handleRefetchQuery = () => {
      refetch();
    };
    // Detect scroll to bottom
    const handleScroll = (e: React.MouseEvent) => {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      const pageLength = data?.pages?.length ? data?.pages?.length : 0;
      if (scrollHeight - scrollTop <= clientHeight + 100 * pageLength) {
        if (hasNextPage && !isFetchingNextPage) {
          debouncedFetchNextPage();
        }
      }
    };
    const onCreate = () => handleRefetchQuery();
    const onEdit = () => handleRefetchQuery();
    const onDelete = () => handleRefetchQuery();

    useEffect(() => {
      const localStorageStateValue = localStorage.getItem(stateStoreKey);
      let oldState = localStorageStateValue
        ? JSON.parse(localStorageStateValue)
        : {};
      localStorage.setItem(
        stateStoreKey,
        JSON.stringify({
          ...oldState,
          cols: columnState,
        })
      );
    }, [columnState]);

    useEffect(() => {
      if (debouncedSearchText !== "") {
        setQuery((q) => ({
          query: {
            ...q?.query,
            search: debouncedSearchText,
            page: 1,
          },
        }));
      } else {
        setQuery((q) => ({
          query: {
            ...q?.query,
            search: null,
            page: 1,
          },
        }));
      }
    }, [debouncedSearchText]);

    const { queryButton, conditions } = useQueryFilter({
      dimensions: UsersFilterDimensions,
      onQuerySet: handleFilter,
      queryFilter: queryFilter,
      stateStoring: {
        enabled: true,
        type: "localStorage",
        storageKey: stateStoreKey,
      },
    });

    return (
      <div data-name="users-content" className={className}>
        <div
          style={{
            display: "flex",
            flex: "initial",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={!hasPermission(MONOLITH_PERMISSIONS.USERS_MANAGE)}
            onClick={(e) => {
              setShowCreateModal(true);
            }}
            style={{
              minWidth: "fit-content",
              fontSize: 11,
              padding: "3px 6px",
            }}
          >
            + New User
          </Button>
          <div style={{ marginLeft: 10 }}>{queryButton}</div>
          <ItemTotal total={totalRecords || 0} Icon={UserIcon} />
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              minWidth: "fit-content",
            }}
          >
            <ComboButton
              type="multi-select"
              data={columnState.filter((c) => c.showInColumnChooser !== false)}
              displayField="caption"
              idField={"dataField"}
              selectedItems={columnState.filter((c) => c.visible !== false)}
              variant="outlined"
              closeOnSelect={false}
              showSearch={true}
              dropDownTitle={() => {
                return (
                  <div
                    style={{
                      margin: "5px 0px",
                      padding: 3,
                      color: theme.palette.text.secondary,
                      display: "flex",
                      alignItems: "center",
                      minWidth: 200,
                    }}
                  >
                    Select Columns
                  </div>
                );
              }}
              onItemDeSelect={(item) => {
                handleColumnVisibility(item, false);
              }}
              onItemSelect={(item) => {
                handleColumnVisibility(item, true);
              }}
              textColor={theme.palette.text.secondary}
              title={"Select Columns"}
            >
              <ViewColumnOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Export Table"}
              onClick={handleExportTable}
            >
              <FileDownloadOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Clear Filters"}
              onClick={handleClearFilters}
            >
              <FilterListOffIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={table.isCompact ? "Zoom In" : "Zoom Out"}
              onClick={() => table.toggleCompact()}
            >
              {table.isCompact && (
                <ZoomOutMapOutlinedIcon style={{ fontSize: 18 }} />
              )}
              {!table.isCompact && (
                <ZoomInMapOutlinedIcon style={{ fontSize: 18 }} />
              )}
            </ComboButton>
            <ComboButton
              type="button"
              variant={"outlined"}
              textColor={
                table.isStriped
                  ? theme.palette.primary.main
                  : theme.palette.text.secondary
              }
              title={table.isStriped ? "Hide Stripes" : "Show Stripes"}
              onClick={() => table.toggleStripes()}
            >
              <ReorderOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <Input
              placeholder="Search Users"
              variant="outlined"
              height={30}
              defaultValue={searchText}
              onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                const currentValue = e.currentTarget.value.trim();
                setSearchText(currentValue);

                localStorage.setItem(
                  `${stateStoreKey}:searchText`,
                  currentValue
                );
              }}
              style={{
                marginLeft: "10px",
                border: searchText
                  ? `1px solid ${theme.palette.primary.main}`
                  : "",
              }}
            />
          </div>
        </div>
        {data && hasInitialized && (
          <>
            {<div>{conditions}</div>}
            <Table
              data={records}
              totalRecords={totalRecords}
              rowHeight={65}
              keyValue="user_id"
              tableInstance={table}
              columnProps={{ minWidth: 150, width: 150 }}
              onHeaderClick={(col: TableColumn<FullUser>) =>
                col?.sorting?.enabled === false
                  ? null
                  : handleSort(col.dataField)
              }
              onScroll={handleScroll}
              onColumnReorder={handleColumnReorder}
              showActionColumn={true}
              onActionButtonClick={handleActionButtonClick}
              focusedRow={flyoutData}
            >
              {columnState.map((col) => {
                return <Column key={col.dataField} {...col} />;
              })}
            </Table>
          </>
        )}
        {(!data || !hasInitialized) && (
          <Loader message={"Retrieving Users..."} />
        )}
        {isFlyoutVisible && flyoutData && (
          <ItemFlyout
            setIsFlyoutVisible={setIsFlyoutVisible}
            setFlyoutData={setFlyoutData}
            flyoutData={flyoutData}
            handleGetNextItem={handleGetNextItem}
            handleGetPrevItem={handleGetPrevItem}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        )}
        <CreateModal
          onSubmit={onCreate}
          open={showCreateModal}
          handleClose={() => setShowCreateModal(false)}
        />
      </div>
    );
  }
)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const UsersTabContent = () => {
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();

  if (!hasPermission(MONOLITH_PERMISSIONS.USERS_MANAGE))
    return <AccessDeniedMessage />;

  return <UsersTabContentWrapper />;
};

export default UsersTabContent;
