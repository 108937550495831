import { httpDelete, httpGet, httpPatch, httpPost } from "../connection";

export async function getSoftwareItems(params = {}) {
  return (await httpGet(`/software/items`, params))?.data || [];
}

async function query(params = {}) {
  return await httpPost(`/software/items/query`, params);
}

async function exportSoftwareList(params = {}) {
  return await httpPost(`/software/items/export`, params);
}

export async function createSoftwareItem(values) {
  return await httpPost(`/software/items`, values);
}

export async function updateSoftwareItem(license_id, values) {
  return await httpPatch(`/software/items/${license_id}`, values);
}

export async function deleteSoftwareItem(license_id) {
  return await httpDelete(`/software/items/${license_id}`);
}

export async function getSoftwareVersions(values = { software_name: "" }) {
  return await httpGet(`/software/versions`);
}

export async function getSoftwareVendors(params = {}) {
  return await httpGet(`/software/vendors`, params);
}

export async function getSoftwareLocations(params = {}) {
  return await httpGet(`/software/locations`, params);
}

export async function getSoftwareNames() {
  return await httpGet(`/software/names`);
}

export async function getSoftwareMeta(params = {}) {
  return await httpGet(`/software/meta`, params);
}

const ForensicSoftwareAPI = {
  getSoftwareItems,
  query,
  exportSoftwareList,
  createSoftwareItem,
  updateSoftwareItem,
  deleteSoftwareItem,
  getSoftwareVersions,
  getSoftwareVendors,
  getSoftwareLocations,
  getSoftwareNames,
  getSoftwareMeta,
};

export default ForensicSoftwareAPI;
