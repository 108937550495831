import styled from "styled-components";

const TaskProperties = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  margin-top: auto;
`;

export default TaskProperties;
