import { useInfiniteQuery } from "@tanstack/react-query";
import { SelectBox, TagBox } from "@monolith-forensics/monolith-ui";
import { useDebouncedCallback } from "use-debounce";
import styled from "styled-components";
import { AnalysisAPI } from "../../../../api/index.js";
import { TimelineEventDefs } from "../../../ColumnDefinitions";
import { useState } from "react";

const PAGE_SIZE = 30;

const DetailItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: top;
`;

const DetailLabel = styled.div`
  font-weight: 500;
  margin-right: 8px;
  min-width: 125px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const DetailValue = styled.div`
  white-space: pre-wrap;
  max-height: 150px;

  // line-clamp
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const TooltipContent = styled(({ className, data }) => {
  return (
    <div className={className}>
      <div className="detail-title">Timeline Event Details</div>
      <div className="event-details">
        {TimelineEventDefs.filter((col) => col.dataField !== "description").map(
          (col) => {
            const key = col.dataField;
            const label = col.caption;
            const value = col?.render ? col.render(data) : data[key];

            return (
              <DetailItem key={key}>
                <DetailLabel>{label}</DetailLabel>
                <DetailValue>{value}</DetailValue>
              </DetailItem>
            );
          }
        )}
        <DetailItem>
          <DetailLabel>Description</DetailLabel>
        </DetailItem>
        <DetailValue>{data.description || "None"}</DetailValue>
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: fit-content;
  max-width: 300px;

  .detail-title {
    font-weight: bold;
    font-size: 15px;
  }

  .event-details {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

const TimelineEventSelector = ({
  query,
  multiple = false,
  disabled = false,
  ...props
}) => {
  const [searchValue, setSearchValue] = useState(null);
  const defaultQuery = {
    ...query,
    search: searchValue,
    pageSize: PAGE_SIZE,
  };

  const {
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery({
    enabled: !disabled,
    queryKey: ["timeline-events", "list", defaultQuery],
    queryFn: ({ pageParam, queryKey }) => {
      const [, , query] = queryKey;
      return AnalysisAPI.TimelineEvents.get({
        ...query,
        page: pageParam,
      });
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.nextPage;
    },
    getPreviousPageParam: (firstPage, pages) => {
      if (firstPage.page - 1 === 0) return null;
      return firstPage.page - 1;
    },
    initialPageParam: 1,
    placeholderData: (data) => data,
  });

  const debouncedFetchNextPage = useDebouncedCallback((e) => {
    fetchNextPage();
  }, 50);

  // Detect scroll to bottom
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 100 * data?.pages?.length) {
      if (hasNextPage && !isFetchingNextPage) {
        debouncedFetchNextPage();
      }
    }
  };

  const records = data?.pages
    ?.reduce((acc, page) => {
      return [...acc, ...page.data];
    }, [])
    .map((item) => ({
      label: item.event_name,
      value: item.uuid,
      data: item,
    }));

  if (multiple) {
    return (
      <TagBox
        {...props}
        disabled={disabled}
        loading={isLoading}
        data={records}
        onScroll={handleScroll}
        OptionTooltip={TooltipContent}
      />
    );
  }

  return (
    <SelectBox
      {...props}
      searchFn={(searchValue) => {
        setSearchValue(searchValue);
      }}
      disabled={disabled}
      loading={isLoading}
      data={records}
      onScroll={handleScroll}
      OptionTooltip={TooltipContent}
    />
  );
};

export default TimelineEventSelector;
