import * as RadixContextMenu from "@radix-ui/react-context-menu";
import styled from "styled-components";

import { ChevronRightIcon, Check } from "lucide-react";
import { useState } from "react";
import Loader from "../../components/Loader.js";
import { useQuery } from "@tanstack/react-query";

const StyledMenuContent = styled(RadixContextMenu.Content)`
  min-width: fit-content;
  max-width: 300px;
  max-height: 400px;
  background-color: ${(props) => props.theme.palette.background.default};
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.palette.divider};
  overflow: hidden;
  padding: 5px;
  cursor: default;

  box-shadow: rgba(0, 0, 0, 0.5) 0px 16px 50px;

  z-index: 100000;

  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-side="top"] {
    animation-name: slideDownAndFade;
  }
  &[data-side="right"] {
    animation-name: slideLeftAndFade;
  }
  &[data-side="bottom"] {
    animation-name: slideUpAndFade;
  }
  &[data-side="left"] {
    animation-name: slideRightAndFade;
  }

  @keyframes slideUpAndFade {
    from {
      opacity: 0;
      transform: translateY(2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideRightAndFade {
    from {
      opacity: 0;
      transform: translateX(-2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: translateY(-2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideLeftAndFade {
    from {
      opacity: 0;
      transform: translateX(2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

const StyledSubMenuContent = styled(RadixContextMenu.SubContent)`
  min-width: 150px;
  max-width: 300px;
  max-height: 400px;
  overflow-y: auto;
  background-color: ${(props) => props.theme.palette.background.default};
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.palette.divider};
  padding: 5px;
  cursor: default;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 16px 50px;

  z-index: 100000;
`;

const StyledMenuItem = styled(RadixContextMenu.Item)`
  font-size: 12px;
  line-height: 1;
  color: ${(props) => props.theme.palette.text.primary};
  border-radius: 3px;
  display: flex;
  align-items: center;
  min-height: 25px;
  padding: 5px;
  position: relative;
  user-select: none;
  outline: none;

  &[data-disabled] {
    color: ${(props) => props.theme.palette.text.secondary};
    pointer-events: "none";
  }

  &[data-highlighted] {
    background-color: ${(props) => props.theme.palette.action.hover};
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

const StyledSubTrigger = styled(RadixContextMenu.SubTrigger)`
  font-size: 12px;
  line-height: 1;
  color: ${(props) => props.theme.palette.text.primary};
  border-radius: 3px;
  display: flex;
  align-items: center;
  min-height: 25px;
  padding: 5px;
  position: relative;
  user-select: none;
  outline: none;

  &[data-disabled] {
    color: ${(props) => props.theme.palette.text.secondary};
    pointer-events: "none";
  }

  &[data-highlighted] {
    background-color: ${(props) => props.theme.palette.action.hover};
    color: ${(props) => props.theme.palette.text.primary};
  }

  .RightSlot {
    margin-left: auto;
    padding-left: 20px;
    color: ${(props) => props.theme.palette.text.primary};
  }
  [data-highlighted] > .RightSlot {
    color: ${(props) => props.theme.palette.text.primary};
  }
  [data-disabled] .RightSlot {
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;

const StyledSeparator = styled(RadixContextMenu.Separator)`
  height: 1px;
  background-color: ${(props) => props.theme.palette.divider};
  margin: 5px 0px;
`;

const StyledLabel = styled(RadixContextMenu.Label)`
  font-size: 12px;
  line-height: 1;
  color: ${(props) => props.theme.palette.text.secondary};
  padding: 5px;
`;

const StyledRadioItem = styled(RadixContextMenu.RadioItem)`
  position: relative;
  font-size: 12px;
  line-height: 1;
  color: ${(props) => props.theme.palette.text.primary};
  border-radius: 3px;
  display: flex;
  align-items: center;
  min-height: 25px;
  padding: 5px;
  padding-right: 25px;
  user-select: none;
  outline: none;

  &[data-disabled] {
    color: ${(props) => props.theme.palette.text.secondary};
    pointer-events: "none";
  }

  &[data-highlighted] {
    background-color: ${(props) => props.theme.palette.action.hover};
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

const StyledCheckboxItem = styled(RadixContextMenu.CheckboxItem)`
  position: relative;
  font-size: 12px;
  line-height: 1;
  color: ${(props) => props.theme.palette.text.primary};
  border-radius: 3px;
  display: flex;
  align-items: center;
  min-height: 25px;
  padding: 5px;
  padding-right: 25px;
  user-select: none;
  outline: none;

  &[data-disabled] {
    color: ${(props) => props.theme.palette.text.secondary};
    pointer-events: "none";
  }

  &[data-highlighted] {
    background-color: ${(props) => props.theme.palette.action.hover};
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

const StyledItemIndicator = styled(RadixContextMenu.ItemIndicator)`
  position: absolute;
  right: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const EmptyPlaceholder = styled.div`
  user-select: none;
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: 0.75rem;
  font-weight: 600;
`;

const Label = ({ item, placeholder }) => {
  if (item.component) {
    const Component = item.component;
    return <Component data={item} />;
  }
  if (item.render) {
    return item.render(item);
  }
  return <>{item?.label || placeholder}</>;
};

const CheckboxGroup = ({ items = [], value, onSelect, queryKey = [] }) => {
  const [selected, setSelected] = useState(value || []);
  let data = [];

  const { data: asyncItems, isFetched } = useQuery({
    queryKey,
    queryFn: items,
    enabled: items instanceof Function,
  });

  // Update local state when selection changes
  const handleSelect = (item) => {
    setSelected((prev) => {
      let newSelected = null;
      if (prev.includes(item.value)) {
        newSelected = prev.filter((v) => v.value !== item.value);
      } else {
        newSelected = [...prev, item.value];
      }

      onSelect?.(data.filter((i) => newSelected.includes(i.value)));
      return newSelected;
    });
  };

  if (items instanceof Function && !isFetched)
    return <Loader iconProps={{ size: 25 }} />;

  data = asyncItems?.length > 0 ? asyncItems : items;

  return (
    <>
      {data.map((checkboxItem) => {
        const Icon = checkboxItem?.icon;
        return (
          <StyledCheckboxItem
            key={checkboxItem?.value}
            checked={selected.includes(checkboxItem?.value)}
            onSelect={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleSelect(checkboxItem);
            }}
          >
            {checkboxItem?.icon && (
              <Icon
                color={checkboxItem?.iconColor}
                size={14}
                style={{ marginRight: 10 }}
              />
            )}
            <Label item={checkboxItem} placeholder={"Menu Item"} />
            <StyledItemIndicator>
              <Check size={14} />
            </StyledItemIndicator>
          </StyledCheckboxItem>
        );
      })}
    </>
  );
};

const RadioGroup = ({ items = [], value, onSelect }) => {
  return (
    <RadixContextMenu.RadioGroup value={value}>
      {items.map((radioItem, idx) => {
        const Icon = radioItem?.icon;
        if (radioItem.separator) {
          return <StyledSeparator key={idx} />;
        }
        return (
          <StyledRadioItem
            key={idx}
            value={radioItem?.value}
            onSelect={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (radioItem?.value === value) onSelect?.(null);
              else onSelect?.(radioItem);
            }}
          >
            {radioItem?.icon && (
              <Icon
                color={radioItem?.iconColor}
                size={14}
                style={{ marginRight: 10 }}
              />
            )}
            <Label item={radioItem} placeholder={"Menu Item"} />
            <StyledItemIndicator>
              <Check size={14} />
            </StyledItemIndicator>
          </StyledRadioItem>
        );
      })}
    </RadixContextMenu.RadioGroup>
  );
};

const RenderItems = ({ items = [], itemData, emptyPlaceholder }) => {
  const handleSelect = (item) => {
    item?.onClick?.(item, itemData);
  };

  return (
    <>
      {items.map((item, idx) => {
        const Icon = item?.icon;

        if (item?.content) {
          return (
            <RadixContextMenu.Sub key={idx}>
              <StyledSubTrigger>
                {item?.icon && (
                  <Icon
                    color={item?.iconColor}
                    size={14}
                    style={{ marginRight: 10 }}
                  />
                )}
                <Label item={item} placeholder={"Menu Item"} />
                <div className="RightSlot">
                  <ChevronRightIcon size={16} />
                </div>
              </StyledSubTrigger>
              <RadixContextMenu.Portal>
                <StyledSubMenuContent sideOffset={2} alignOffset={-5}>
                  {item?.content}
                </StyledSubMenuContent>
              </RadixContextMenu.Portal>
            </RadixContextMenu.Sub>
          );
        }
        if (item.separator) {
          return <StyledSeparator key={idx} />;
        }
        if (item.header) {
          return (
            <StyledLabel key={idx}>
              {item?.label || "Section Header"}
            </StyledLabel>
          );
        }
        if (item.checkboxGroup) {
          return (
            <CheckboxGroup
              key={idx}
              items={item?.items}
              value={item?.value}
              onSelect={(data) => item?.onSelectionChanged?.(data)}
            />
          );
        }
        if (item.radioGroup) {
          return (
            <RadioGroup
              key={idx}
              items={item?.items}
              value={item?.value}
              onSelect={(data) => item?.onSelectionChanged?.(data)}
            />
          );
        }
        if (item.items) {
          return (
            <RadixContextMenu.Sub key={idx}>
              <StyledSubTrigger>
                {item?.icon && (
                  <Icon
                    color={item?.iconColor}
                    size={14}
                    style={{ marginRight: 10 }}
                  />
                )}
                <Label item={item} placeholder={"Menu Item"} />
                <div className="RightSlot">
                  <ChevronRightIcon size={16} />
                </div>
              </StyledSubTrigger>
              <RadixContextMenu.Portal>
                <StyledSubMenuContent sideOffset={2} alignOffset={-5}>
                  {item?.items?.length === 0 ? (
                    <EmptyPlaceholder>{emptyPlaceholder}</EmptyPlaceholder>
                  ) : (
                    <RenderItems
                      items={item?.items}
                      handleSelect={handleSelect}
                      emptyPlaceholder={emptyPlaceholder}
                    />
                  )}
                </StyledSubMenuContent>
              </RadixContextMenu.Portal>
            </RadixContextMenu.Sub>
          );
        }
        return (
          <StyledMenuItem
            key={idx}
            onSelect={(e) => {
              e.stopPropagation();
              handleSelect(item);
            }}
            {...(item?.menuItemProps || {})}
          >
            {item?.icon && (
              <Icon
                color={item?.iconColor}
                size={14}
                style={{ marginRight: 7 }}
              />
            )}
            <Label item={item} placeholder={"Menu Item"} />
          </StyledMenuItem>
        );
      })}
    </>
  );
};

const ContextMenu = ({
  children,
  menuItems,
  itemData,
  arrow = false,
  variant = "outlined",
  emptyPlaceholder = "No Items",
  textColor = "primary",
  style = {},
  contentProps = {},
  dropdownContent,
  onOpenChange,
  disabled = false,
}) => {
  return (
    <RadixContextMenu.Root onOpenChange={onOpenChange}>
      <RadixContextMenu.Trigger disabled={disabled}>
        {children}
      </RadixContextMenu.Trigger>
      <RadixContextMenu.Portal>
        <StyledMenuContent
          sideOffset={2}
          align="start"
          style={style}
          onClick={(e) => e.stopPropagation()}
          collisionPadding={20}
          {...contentProps}
        >
          {dropdownContent}
          {!dropdownContent && menuItems?.length === 0 ? (
            <EmptyPlaceholder>{emptyPlaceholder}</EmptyPlaceholder>
          ) : (
            <RenderItems
              items={menuItems}
              itemData={itemData}
              emptyPlaceholder={emptyPlaceholder}
            />
          )}
        </StyledMenuContent>
      </RadixContextMenu.Portal>
    </RadixContextMenu.Root>
  );
};

export default ContextMenu;
