const hashData = async (buffer, checksum) => {
  if (checksum === "MD5") {
    const { default: SparkMD5 } = await import("spark-md5");
    const spark = new SparkMD5.ArrayBuffer();
    spark.append(buffer);
    return spark.end();
  }

  const crypto = window.crypto || window.msCrypto;
  if (!crypto || !crypto.subtle) {
    throw new Error("Web Crypto API not available");
  }

  const hash = await crypto.subtle.digest(checksum, buffer);
  const hashArray = Array.from(new Uint8Array(hash));

  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
};

const calculateStringHash = async (String, checksum) => {
  //Convert string to buffer
  const encoder = new TextEncoder();
  const buffer = encoder.encode(String);

  switch (checksum) {
    case "MD5":
      return await hashData(buffer, "MD5");
    case "SHA-1":
      return await hashData(buffer, "SHA-1");
    case "SHA-256":
      return await hashData(buffer, "SHA-256");
    default:
      return null;
  }
};

export default calculateStringHash;
