import { useInfiniteQuery } from "@tanstack/react-query";
import { SelectBox, TagBox } from "@monolith-forensics/monolith-ui";
import { useDebouncedCallback } from "use-debounce";
import styled from "styled-components";
import { AnalysisAPI } from "../../../../api/index.js";

const PAGE_SIZE = 30;

const TooltipContent = styled(({ className, data }) => {
  return (
    <div className={className}>
      <div className="detail-title">Trace Indicator Details</div>
      <div className="detail-item">
        <div className="detail-label">Name</div>
        <div className="detail-value">{data.name || "None"}</div>
      </div>
      <div className="detail-item">
        <div className="detail-label">Created On</div>
        <div className="detail-value">{data.created_on || "None"}</div>
      </div>
      <div className="detail-item">
        <div className="detail-label">Description</div>
      </div>
      <div className="detail-value">{data.description || "None"}</div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: fit-content;
  max-width: 300px;

  .detail-title {
    font-weight: bold;
    font-size: 15px;
  }
  .detail-item {
    display: flex;
    flex-direction: column;
  }

  .detail-label {
    font-weight: bold;
    min-width: 150px;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .detail-value {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

const TraceIndicatorSelector = ({
  query,
  multiple = false,
  label = "Trace Indicator",
  placeholder = "Select Trace Indicator",
  description = "Select a trace indicator to link to this item.",
  ...props
}) => {
  const defaultQuery = {
    ...query,
    pageSize: PAGE_SIZE,
  };

  const {
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ["trace-indicators", "list", defaultQuery],
    queryFn: ({ pageParam }) =>
      AnalysisAPI.TraceIndicators.get({
        ...defaultQuery,
        page: pageParam,
      }),
    getNextPageParam: (lastPage, pages) => {
      return lastPage.nextPage;
    },
    getPreviousPageParam: (firstPage, pages) => {
      if (firstPage.page - 1 === 0) return null;
      return firstPage.page - 1;
    },
    initialPageParam: 1,
    placeholderData: (data) => data,
  });

  const debouncedFetchNextPage = useDebouncedCallback((e) => {
    fetchNextPage();
  }, 50);

  // Detect scroll to bottom
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 100 * data?.pages?.length) {
      if (hasNextPage && !isFetchingNextPage) {
        debouncedFetchNextPage();
      }
    }
  };

  const records = data?.pages
    ?.reduce((acc, page) => {
      return [...acc, ...page.data];
    }, [])
    .map((item) => ({
      label: item.name,
      value: item.uuid,
      data: item,
    }));

  if (multiple) {
    return (
      <TagBox
        {...props}
        label={label}
        placeholder={placeholder}
        description={description}
        loading={isLoading}
        data={records}
        onScroll={handleScroll}
        OptionTooltip={TooltipContent}
      />
    );
  }

  return (
    <SelectBox
      {...props}
      label={label}
      placeholder={placeholder}
      description={description}
      loading={isLoading}
      data={records}
      onScroll={handleScroll}
      OptionTooltip={TooltipContent}
    />
  );
};

export default TraceIndicatorSelector;
