import { getEquipmentModels } from "@/api";
import { SelectBox } from "@monolith-forensics/monolith-ui";
import { useQuery } from "@tanstack/react-query";
import { FC } from "react";

interface EquipmentModelSelectorProps {
  defaultValue: string;
  onChange?: (value: string) => void;
  error?: string;
  openOnFocus?: boolean;
}

type EquipmentModel = {
  model: string;
};

const EquipmentModelSelector: FC<EquipmentModelSelectorProps> = ({
  defaultValue,
  onChange,
  error,
  openOnFocus = false,
}) => {
  const { data: equipmentItems } = useQuery<EquipmentModel[]>({
    queryKey: ["equipment", "model"],
    queryFn: () => getEquipmentModels(),
  });

  const options = equipmentItems?.map((equipment) => ({
    label: equipment?.model,
    value: equipment?.model,
  }));

  return (
    <>
      <SelectBox
        defaultValue={defaultValue}
        onChange={onChange}
        error={error}
        size="sm"
        variant="outlined"
        label="Model"
        placeholder="Select or enter model"
        data={options}
        clearable={true}
        searchable={true}
        allowCustomValue={true}
        openOnFocus={openOnFocus}
      />
    </>
  );
};
export default EquipmentModelSelector;
