import { httpDelete, httpGet, httpPatch, httpPost } from "../../connection.js";

async function get(values = {}) {
  const { uuid, ...query } = values;
  if (uuid) {
    return httpGet(`/analysis/trace-indicators/${uuid}`, query);
  }
  return httpGet("/analysis/trace-indicators", query);
}

async function create(values = {}) {
  return httpPost("/analysis/trace-indicators", values);
}

async function exportList(values = {}) {
  return httpPost("/analysis/trace-indicators/export", values);
}

async function update(values = {}) {
  return httpPatch(`/analysis/trace-indicators/${values.uuid}`, values);
}

async function remove(values = {}) {
  const { uuid, ...query } = values;
  if (uuid) {
    return httpDelete(`/analysis/trace-indicators/${uuid}`, query);
  }
  return null;
}

const TraceIndicatorAPI = {
  get,
  create,
  exportList,
  remove,
  update,
};

export default TraceIndicatorAPI;
