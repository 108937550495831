import { useQuery } from "@tanstack/react-query";
import Loader from "../../components/Loader.js";
import styled from "styled-components";

const PlainTextViewer = styled(({ className, file }) => {
  const { data } = useQuery({
    queryKey: ["text-data", file.uuid],
    queryFn: async () => {
      const response = await fetch(file.url);
      const text = await response.text();
      return text;
    },
    enabled: !!file.url,
  });

  if (!data) return <Loader message="Loading Document" />;

  return (
    <div className={className}>
      <div className="text-doc">{data}</div>
    </div>
  );
})`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: calc(100vh - 75px);
  width: 100%;
  max-width: 8.5in;
  overflow: auto;
  margin: 0 auto;

  & .text-doc {
    width: 100%;
    max-width: 8.5in;
    height: 100%;
    padding: 0.5in;
    box-sizing: border-box;
    background-color: ${(props) => props.theme.palette.background.alt};
    white-space: pre-wrap !important;
    word-wrap: break-word !important;
    overflow-y: auto;
  }
`;

export default PlainTextViewer;
