import DropdownMenu from "../../DropdownMenu/DropdownMenu.js";
import TaskButton from "../../TaskButton.js";
import { useNoteSystem } from "../Contexts/NoteSystemContext.js";
import { NoteView } from "../Enums/NoteView.js";
import LeftMenu from "./LeftMenu.js";

import {
  Maximize,
  Minimize,
  MoreHorizontal,
  Search,
  ArrowUpNarrowWide,
  FolderTree,
  List,
} from "lucide-react";

const NoteListMenu = ({
  children,
  readOnly = false,
  searchEnabled = true,
  orderEnabled = true,
  fullscreenEnabled = true,
  actionMenuItems = [],
}) => {
  const noteSystemApi = useNoteSystem();

  const {
    fullscreen,
    setFullscreen,
    showSearch,
    setShowSearch,
    setShowToolbar,
    leftMenuRef,
    tabsRef,
    allNotesRef,
    handleCreateNote,
    handleNoteOrderChange,
    noteOrder,
    handleNoteViewChange,
    noteView,
  } = noteSystemApi;

  const noteActionItems = [
    {
      label: "Toggle Editor Toolbar",
      title:
        "Show a toolbar in the note editor that gives you additional editing options.  The toolbar can also be toggled by pressing Ctrl + T.",
      onClick: () => setShowToolbar((t) => !t),
    },
    ...actionMenuItems.map((item) => ({
      ...item,
      onClick: () => {
        if (item.onSelect) {
          item.onSelect({
            noteSystem: noteSystemApi,
            tabs: tabsRef.current,
            allNotes: allNotesRef.current,
          });
        }
      },
      items: item.items?.map((subItem) => ({
        ...subItem,
        onClick: () => {
          if (subItem.onSelect) {
            subItem.onSelect({
              handleCreateNote,
              tabs: tabsRef.current,
              allNotes: allNotesRef.current,
            });
          }
        },
      })),
    })),
  ];

  const currentOrder = noteOrder.field + "-" + noteOrder.sort;

  const orderMenuItems = [
    {
      radioGroup: true,
      value: currentOrder,
      items: [
        {
          label: "Note Name (A-Z)",
          title: "",
          value: "name-asc",
          onClick: () => handleNoteOrderChange({ field: "name", sort: "asc" }),
        },
        {
          label: "Note Name (Z-A)",
          title: "",
          value: "name-desc",
          onClick: () => handleNoteOrderChange({ field: "name", sort: "desc" }),
        },
        {
          separator: true,
        },
        {
          label: "Updated On (Newest)",
          title: "",
          value: "updated_on-desc",
          onClick: () =>
            handleNoteOrderChange({ field: "updated_on", sort: "desc" }),
        },
        {
          label: "Updated On (Oldest)",
          title: "",
          value: "updated_on-asc",
          onClick: () =>
            handleNoteOrderChange({ field: "updated_on", sort: "asc" }),
        },
        {
          separator: true,
        },
        {
          label: "Created On (Newest)",
          title: "",
          value: "created_on-desc",
          onClick: () =>
            handleNoteOrderChange({ field: "created_on", sort: "desc" }),
        },
        {
          label: "Created On (Oldest)",
          title: "",
          value: "created_on-asc",
          onClick: () =>
            handleNoteOrderChange({ field: "created_on", sort: "asc" }),
        },
      ],
    },
  ];

  if (noteView === NoteView.LIST) {
    orderMenuItems[0].items.push(
      {
        separator: true,
      },
      {
        label: "Created By (A-Z)",
        title: "",
        value: "created_by-asc",
        onClick: () =>
          handleNoteOrderChange({ field: "created_by", sort: "asc" }),
      },
      {
        label: "Created By (Z-A)",
        title: "",
        value: "created_by-desc",
        onClick: () =>
          handleNoteOrderChange({ field: "created_by", sort: "desc" }),
      }
    );
  }

  return (
    <LeftMenu
      ref={leftMenuRef}
      className="left-menu"
      style={{ justifyContent: "space-between" }}
    >
      <div>{children}</div>
      <div style={{ display: "flex" }}>
        {fullscreenEnabled && !fullscreen && (
          <TaskButton
            onClick={(e) => setFullscreen(true)}
            title="Enter FullScreen"
          >
            <Maximize size={18} style={{ margin: 2 }} />
          </TaskButton>
        )}
        {fullscreenEnabled && fullscreen && (
          <TaskButton
            onClick={(e) => setFullscreen(false)}
            title="Exit FullScreen"
          >
            <Minimize size={18} style={{ margin: 2 }} />
          </TaskButton>
        )}
        {noteView === NoteView.TREE ? (
          <TaskButton
            onClick={() => handleNoteViewChange(NoteView.LIST)}
            title="Change Note View"
            variant={"text"}
          >
            <FolderTree size={18} style={{ margin: 2 }} />
          </TaskButton>
        ) : (
          <TaskButton
            onClick={() => handleNoteViewChange(NoteView.TREE)}
            title="Change Note View"
            variant={"text"}
          >
            <List size={18} style={{ margin: 2 }} />
          </TaskButton>
        )}
        {orderEnabled && (
          <DropdownMenu
            menuItems={orderMenuItems}
            title={"Order Actions"}
            variant="text"
          >
            <ArrowUpNarrowWide size={18} style={{ margin: 2 }} />
          </DropdownMenu>
        )}
        {searchEnabled && (
          <TaskButton
            onClick={() => setShowSearch((s) => !s)}
            title="Search Notes"
            variant={showSearch ? "contained" : "text"}
          >
            <Search size={18} style={{ margin: 2 }} />
          </TaskButton>
        )}
        <DropdownMenu
          menuItems={noteActionItems}
          title={"Note Actions"}
          variant="text"
        >
          <MoreHorizontal size={18} style={{ margin: 2 }} />
        </DropdownMenu>
      </div>
    </LeftMenu>
  );
};

export default NoteListMenu;
