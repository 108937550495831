import { Link } from "react-router-dom";
import { useState, useRef } from "react";
import styled from "styled-components";
import { getDateFormat } from "../../utils/date-format";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import QaStatusSelector from "../../pages/Cases/CaseQA/components/QaStatusSelector.js";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { Modal, useTheme } from "@mui/material";
import Loader from "../Loader.js";
import ToolBarItems from "../ToolBarItems.js";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import UserSelectBoxItem from "../SelectBoxItems/UserSelectBoxItem.js";
import { Template } from "devextreme-react/core/template";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import UserApi from "../../api/users/users.js";
import QualityAssuranceAPI from "../../api/quality_assurance/index.js";
import { useSnackbar } from "notistack";
import { usePermissions } from "../../hooks/usePermissions";
import DropdownMenu from "../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import { EditIcon, TrashIcon } from "lucide-react";

const QaReviewColumnDefs = [
  {
    dataField: "actions",
    caption: "",
    width: 55,
    allowSorting: false,
    allowReorder: false,
    showInColumnChooser: false,
    render: (rowData, options) => (
      <ActionCell rowData={rowData} options={options} />
    ),
  },
  {
    dataField: "qa_id",
    caption: "ID",
    visible: false,
  },
  {
    dataField: "name",
    caption: "Name",
    minWidth: 225,
    width: 225,
    render: (data) => {
      return <QaNameCell rowData={data} />;
    },
  },
  {
    dataField: "status",
    caption: "Status",
    render: (rowData, options = {}) => {
      return (
        <QaStatusSelector
          variant={"outlined"}
          rowData={rowData}
          onChange={options?.onRowUpdated}
        />
      );
    },
  },
  {
    dataField: "type",
    caption: "Type",
  },
  {
    dataField: "checklist_name",
    caption: "QA Checklist",
  },
  {
    dataField: "created_on",
    caption: "Created On",
    visible: false,
    dataType: "date",
    format: {
      type: getDateFormat({ isMoment: true, includeTime: false }),
    },
  },
  {
    dataField: "completed_on",
    caption: "Completed On",
    visible: false,
    dataType: "date",
    format: {
      type: getDateFormat({ isMoment: true, includeTime: false }),
    },
  },
  {
    dataField: "is_complete",
    caption: "Complete",
  },
  {
    dataField: "created_by",
    caption: "Created By",
    visible: false,
  },
  {
    dataField: "assigned_to",
    caption: "Assigned To",
    visible: false,
  },
  {
    dataField: "description",
    caption: "Description",
    visible: false,
    titleRender: (data) => data.description,
  },
];

export default QaReviewColumnDefs;

export const QaNameCell = styled(({ className, rowData, color }) => {
  if (!rowData.qa_id) return <></>;

  return (
    <div className={className}>
      <Link className={"qa-link"} to={`review/${rowData.qa_id}`}>
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <div>{!!rowData.name ? rowData.name : "View QA Review"}</div>
          <LaunchOutlinedIcon
            className="action-button"
            style={{ fontSize: 13, marginLeft: 5 }}
          />
        </div>
      </Link>
    </div>
  );
})`
  & {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  & .qa-link {
    color: ${({ color, theme }) => color || theme.palette.primary.main};
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ActionCell = ({ rowData, options }) => {
  const { hasPermission } = usePermissions();

  const [showEditModel, setShowEditModel] = useState(false);
  const [showDeleteModel, setShowDeleteModel] = useState(false);

  const handleSubmit = () => {
    options?.onRowUpdated?.();
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "left" }}>
        <DropdownMenu
          menuItems={[
            {
              label: "Edit",
              icon: EditIcon,
              onClick: () => setShowEditModel(true),
            },
            {
              label: "Delete",
              icon: TrashIcon,
              iconColor: "orangered",
              onClick: () => setShowDeleteModel(true),
            },
          ]}
          title={"Actions"}
          variant="outlined"
          // arrow
          disabled={!hasPermission()}
        >
          <MoreHorizOutlinedIcon style={{ fontSize: 13 }} />
        </DropdownMenu>
      </div>
      <EditModal
        open={showEditModel}
        handleClose={() => setShowEditModel(false)}
        onSubmit={() => handleSubmit()}
        defaultInfo={rowData}
      />
      <DeleteModal
        open={showDeleteModel}
        handleClose={() => setShowDeleteModel(false)}
        onSubmit={() => handleSubmit()}
        defaultInfo={rowData}
      />
    </>
  );
};

const EditModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const theme = useTheme();
  const form = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const result = useQueries({
    queries: [
      {
        queryKey: [
          "users_:list",
          { includeObservers: false, includeInactive: false },
        ],
        queryFn: () =>
          UserApi.getUsers({ includeObservers: false, includeInactive: false }),
        enabled: open,
      },
      {
        queryKey: ["quality-assurance_:checklists"],
        queryFn: () => QualityAssuranceAPI.getQAChecklists(),
        enabled: open,
      },
    ],
  });

  const handleSubmit = () => {
    if (form.current.instance.validate().isValid) {
      let values = Object.assign({}, form.current.instance.option("formData"));

      QualityAssuranceAPI.updateQAReview({ ...values }).then((result) => {
        enqueueSnackbar(`QA Review Updated.`, {
          variant: "success",
        });
        onSubmit();
      });

      handleClose();
    }
  };

  const handleCancel = () => handleClose();

  const isDone = result.every((x) => x.isFetched);

  const users = result[0]?.data || [];
  const qa_checklists = result[1]?.data || [];

  const formData = {
    qa_id: defaultInfo.qa_id,
    name: defaultInfo.name,
    type: defaultInfo.type,
    checklist_id: defaultInfo.checklist_id,
    assigned_to_id: defaultInfo.assigned_to_id,
    description: defaultInfo.description,
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        disableEnforceFocus={true}
        style={{
          userSelect: "none",
          zIndex: 1400,
        }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 600,
            maxHeight: "90vh",
            backgroundColor: theme.palette.background.default,
            position: "fixed",
            left: "calc(50% - 300px)",
            overflowY: "auto",
            padding: 20,
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Edit QA Review
          </div>
          {!isDone && <Loader />}
          {isDone && (
            <>
              <Form ref={form} colCount={2} defaultFormData={formData}>
                <SimpleItem
                  dataField="name"
                  label={{ text: "QA Name" }}
                  isRequired={true}
                />
                <SimpleItem
                  dataField="type"
                  label={{ text: "QA Type" }}
                  isRequired={true}
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: [
                      "General Review",
                      "Technical Review",
                      "Admin Review",
                    ],
                    placeholder: "Select QA Type...",
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                  }}
                />
                <SimpleItem
                  dataField="checklist_id"
                  label={{ text: "Checklist" }}
                  isRequired={true}
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: qa_checklists,
                    displayExpr: "checklist_name",
                    valueExpr: "checklist_id",
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                  }}
                />
                <SimpleItem
                  dataField="assigned_to_id"
                  label={{ text: "Assigned To" }}
                  isRequired={true}
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: users,
                    displayExpr: "full_name",
                    valueExpr: "user_id",
                    searchEnabled: true,
                    searchExpr: "full_name",
                    placeholder: "Select Examiner...",
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                    itemTemplate: "assigned_to_id",
                  }}
                />
                <Template
                  name="assigned_to_id"
                  render={(data) => <UserSelectBoxItem data={data} />}
                />
                <SimpleItem
                  dataField="description"
                  label={{ text: "QA Description" }}
                  editorType="dxTextArea"
                  colSpan={2}
                  editorOptions={{
                    height: 75,
                  }}
                />
              </Form>
              <div
                style={{
                  marginTop: 30,
                }}
              >
                <ToolBarItems
                  submitText="Edit QA Review"
                  onSubmit={handleSubmit}
                  onCancel={handleCancel}
                />
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

const DeleteModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    QualityAssuranceAPI.deleteQAReview(defaultInfo.qa_id).then(() => {
      enqueueSnackbar(`QA Review Deleted.`, {
        variant: "success",
      });
      onSubmit();
    });
    handleClose();
  };

  const handleCancel = () => handleClose();

  return (
    <div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        disableEnforceFocus={true}
        style={{
          userSelect: "none",
          zIndex: 1400,
        }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 400,
            maxHeight: "90vh",
            backgroundColor: theme.palette.background.default,
            position: "fixed",
            left: "calc(50% - 200px)",
            overflowY: "auto",
            padding: 20,
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Delete QA Review
          </div>
          <div style={{ margin: "10px 0px" }}>
            Are you sure you want to delete this QA Review?
          </div>
          <div style={{ margin: "10px 0px" }}>"{defaultInfo.name}"</div>
          <div
            style={{
              marginTop: 30,
            }}
          >
            <ToolBarItems
              submitText="Delete QA Review"
              submitColor="error"
              onSubmit={handleSubmit}
              onCancel={handleCancel}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
