import QaChecklistAPI from "../../api/quality_assurance/checklists.js";
import { Modal } from "@monolith-forensics/monolith-ui";
import DefaultQaChecklistForm from "../Forms/DefaultQaChecklistForm.js";

const CreateQaChecklistModal = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  ...props
}) => {
  const handleSubmit = async (data) => {
    await QaChecklistAPI.createQAChecklist(data);
    onSubmit?.(data);
  };

  return (
    <Modal open={show} onClose={onClose}>
      <Modal.Title>Create QA Checklist</Modal.Title>
      <Modal.SubTitle style={{ marginBottom: 15 }}>
        Create a new Quality Assurance checklist.
      </Modal.SubTitle>
      <DefaultQaChecklistForm
        width={400}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        {...props}
      />
    </Modal>
  );
};

export default CreateQaChecklistModal;
