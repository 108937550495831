import { useState, useMemo } from "react";
import styled from "styled-components";
import releaseNotes from "../../ReleaseNotes/release-notes.json";
import HtmlParser from "../../utils/HtmlParser";
import { SelectBox } from "@monolith-forensics/monolith-ui";

interface ReleaseNote {
  version: string;
  date: string;
  update: UpdateItem[];
}

interface UpdateItem {
  title: string;
  body: string[];
}

const ReleaseNotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  padding-left: 20px;
  max-width: 900px;
  a {
    color: ${({ theme }) => theme.palette.primary.main};
    &:hover {
      color: ${({ theme }) => theme.palette.primary.dark};
      text-decoration: underline;
    }
  }
`;

const Heading = styled.div`
  font-size: x-large;
  font-weight: 800;
`;

const Date = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const Content = styled.div``;

const VersionSelector = styled.div`
  margin-bottom: 20px;
  width: 200px;
`;

const ReleaseNotes = {
  Container: ReleaseNotesContainer,
  Heading,
  Date,
  Content,
  VersionSelector,
};

const ReleaseNotesSection: React.FC = () => {
  const { groupedVersions, versionOptions } = useMemo(() => {
    const grouped: Record<string, ReleaseNote[]> = {};
    const options: { label: string; value: string }[] = [];

    releaseNotes.forEach((note) => {
      const [major, minor] = note.version.substring(1).split(".");
      const key = `Version ${major}.${minor}`;
      if (!grouped[key]) {
        grouped[key] = [];
        options.push({ label: key, value: key });
      }
      grouped[key].push(note);
    });

    // Sort notes within each group
    Object.values(grouped).forEach((notes) => {
      notes.sort((a, b) => {
        const versionA = a.version.split(".").map(Number);
        const versionB = b.version.split(".").map(Number);
        for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
          if (versionA[i] !== versionB[i]) {
            return versionB[i] - versionA[i]; // Descending order
          }
        }
        return 0;
      });
    });

    // Sort version options
    options.sort((a, b) => b.value.localeCompare(a.value));

    return { groupedVersions: grouped, versionOptions: options };
  }, []);

  const [selectedVersion, setSelectedVersion] = useState<string>(
    () => versionOptions[0]?.value || ""
  );

  const handleVersionChange = (value: string) => {
    setSelectedVersion(value);
  };

  return (
    <ReleaseNotes.Container>
      <ReleaseNotes.VersionSelector>
        <SelectBox
          data={versionOptions}
          onChange={handleVersionChange}
          defaultValue={selectedVersion}
          placeholder="Select Version"
          size="sm"
        />
      </ReleaseNotes.VersionSelector>
      {groupedVersions[selectedVersion]?.map((item, index) => (
        <ReleaseNotes.Content key={item.version + index}>
          <ReleaseNotes.Heading>{item.version}</ReleaseNotes.Heading>
          <ReleaseNotes.Date>{item.date}</ReleaseNotes.Date>
          {item.update.map((listItem, listIndex) => (
            <ReleaseNotes.Content key={listItem.title + listIndex}>
              <h4>{listItem.title}</h4>
              <ul>
                {listItem.body?.map((bullet, bulletIndex) => (
                  <li key={bulletIndex}>
                    <HtmlParser htmlContent={bullet} />
                  </li>
                ))}
              </ul>
            </ReleaseNotes.Content>
          ))}
        </ReleaseNotes.Content>
      ))}
    </ReleaseNotes.Container>
  );
};

export default ReleaseNotesSection;
