import React, { useEffect, useRef, useState, useMemo } from "react";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import { useSnackbar } from "notistack";

import {
  createCheckListLog,
  deleteCheckListLog,
  getCheckListItems,
} from "../../../../api";
import {
  db_timestamp,
  getDateFormat,
  monolithMoment,
  validateTimestamp,
} from "../../../../utils/date-format";

import { usePermissions } from "../../../../hooks/usePermissions";
import { useAuth } from "../../../../contexts/AuthContext";
import {
  Button,
  useTheme,
  ToggleButtonGroup,
  ToggleButton,
  Modal,
  Checkbox,
} from "@mui/material";
import ToolBarItems from "../../../../components/ToolBarItems";

//Icons
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";

import { Link, useNavigate, useParams } from "react-router-dom";
import UserSelectBoxItem from "../../../../components/SelectBoxItems/UserSelectBoxItem.js";
import EvidenceSelectBoxItem from "../../../../components/SelectBoxItems/EvidenceSelectBoxItem.js";
import { Template } from "devextreme-react/core/template";
import QualityAssuranceAPI from "../../../../api/quality_assurance/index.js";
import Loader from "../../../../components/Loader.js";
import {
  useInfiniteQuery,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import KeyboardReturnOutlinedIcon from "@mui/icons-material/KeyboardReturnOutlined";
import QaStatusSelector from "./QaStatusSelector.js";
import QaEntryColumnDefs from "./QaEntryColumnDefs.js";
import Table, {
  useTable,
  Column,
} from "../../../../Monolith-UI/Table/Table.js";
import { useQueryFilter } from "../../../../Monolith-UI/QueryFilter/QueryFilter.js";
import { ItemTotal } from "../../CaseEvidence/index";
import BookmarkAddedOutlinedIcon from "@mui/icons-material/BookmarkAddedOutlined";
import ComboButton from "../../../../Monolith-UI/ComboButton/ComboButton.js";
import { TextBox } from "devextreme-react/ui/text-box.js";
import styled from "styled-components";
import QaEntryFilterDimensions from "./QaEntryFilterDimensions.js";
import UserApi from "../../../../api/users/users.js";
import EvidenceAPI from "../../../../api/evidence/index.js";
import synchronizeColumnState from "../../../../utils/synchronize-column-state.js";
import TaskButton from "../../../../Monolith-UI/TaskButton.js";
import { useDebouncedCallback } from "use-debounce";
import { QaReview } from "./QaReviews";
import { User } from "@/pages/Users/types/Users";
import { TableColumn, TableColumnDef } from "@/Monolith-UI/Table/types/Table";

interface QaEntry {
  case_id: number;
  checklist_id: number;
  checklist_item_id: number;
  checklist_name: string;
  created_on: string;
  date: string;
  details: string;
  entry_id: number;
  evidence_id: number;
  evidence_number: string;
  examiner_id: 1;
  examiner_name: string;
  issue_type: string;
  item_name: string;
  notes: string;
  qa_id: number;
  resolution: string;
  resolved: string;
  reviewer_id: number;
  reviewer_name: string;
}

interface QaChecklistItem {
  checklist_id: number;
  checklist_items: {
    case_id: number;
    checklist_id: number;
    complete_date: string;
    group_id: number;
    is_complete: number;
    item_id: number;
    item_name: string;
    user: User;
  }[];
  group_id: number;
  group_name: string;
}

interface QaEntriesCondition {
  field: string;
  label: string;
  mode: string;
  operator: {
    name: string;
    operator: string;
    mode: string[];
    type: string;
  };
  value: string[];
  displayValue: string[];
  key: (
    | string
    | {
        includeObservers: boolean;
        includeInactive: boolean;
      }
  )[];
  id: string;
}

interface QaEntriesQuery {
  query: {
    conditions?: QaEntriesCondition[];
    page?: number;
    qa_id?: string;
    order?: {
      field: string;
      sort: string;
    } | null;
    search?: string;
  };
}

const buttonStyle = {
  minWidth: "150px",
  padding: "2px 5px",
};

const Chip = ({ text }: { text: string }) => {
  const chip = useRef(null);
  const theme = useTheme();

  return (
    <>
      <div
        ref={chip}
        style={{
          borderRadius: "5px",
          padding: "3px 5px",
          border: `1px solid ${theme.palette.primary.main}`,
          color: "white",
          fontSize: "0.75rem",
          fontWeight: 500,
        }}
      >
        <div>{text}</div>
      </div>
    </>
  );
};

const ListItem = styled(({ className, data, qaInfo, is_complete }) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { hasPermission } = usePermissions();
  const { currentUser } = useAuth();

  const queryClient = useQueryClient();

  const values = data;

  const handleCheck = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newState = e.target.checked;
    if (newState === true) {
      await createCheckListLog({
        case_id: values.case_id,
        checklist_id: values.checklist_id,
        item_id: values.item_id,
        user: currentUser.full_name,
        qa_id: values.qa_id,
      });
    } else {
      await deleteCheckListLog({
        qa_id: values.qa_id,
        checklist_id: values.checklist_id,
        item_id: values.item_id,
      });
    }

    queryClient.refetchQueries({
      queryKey: [
        "quality-assurance:checklist:items",
        {
          case_id: qaInfo.case_id,
          qa_id: qaInfo.qa_id,
          checklist_id: data.checklist_id,
        },
      ],
    });
  };

  return (
    <div
      className={className}
      style={{
        display: "flex",
        alignItems: "center",
        margin: "0px 15px",
      }}
    >
      {hasPermission() && qaInfo.assigned_to_id === currentUser.user_id && (
        <div style={{ marginRight: "15px" }}>
          <Checkbox defaultChecked={is_complete} onChange={handleCheck} />
        </div>
      )}
      {qaInfo.assigned_to_id !== currentUser.user_id && (
        <div style={{ marginRight: "15px", minWidth: "13px" }}>
          {is_complete && (
            <i className="fa fa-check" style={{ color: "limegreen" }}></i>
          )}
        </div>
      )}
      <div
        style={{
          display: "inline-block",
          width: "450px",
          whiteSpace: "normal",
          textAlign: "justify",
        }}
      >
        {values.item_name}
      </div>
      {hasPermission() && qaInfo.assigned_to_id === currentUser.user_id && (
        <div className="qa-entry-btn" style={{ margin: "0px 25px" }}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={(e) => {
              setShowCreateModal(true);
            }}
          >
            + Create QA Entry
          </Button>
        </div>
      )}
      {values.complete_date && (
        <div style={{ display: "inline-block", marginLeft: "auto" }}>
          <span style={{ display: "flex", alignItems: "center" }}>
            <Chip text={`${values.user}`} />
            <div style={{ margin: "0px 10px" }}>
              {monolithMoment({
                timestamp: values.complete_date,
                includeTime: true,
              })}
            </div>
          </span>
        </div>
      )}
      <CreateQAEntryPopup
        open={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
        checklist_id={qaInfo.checklist_id}
      />
    </div>
  );
})`
  & {
    padding: 10px;
    border-radius: 5px;
    min-height: 50px;
  }
  & .qa-entry-btn {
    display: none;
  }
  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
    .qa-entry-btn {
      display: block;
    }
  }
`;

const QaChecklist = ({ qaInfo }: { qaInfo: QaReview }) => {
  const { case_id, qa_id, checklist_id } = qaInfo;
  const theme = useTheme();

  const { data } = useQuery({
    queryKey: [
      "quality-assurance:checklist:items",
      { case_id: case_id, qa_id: qa_id, checklist_id },
    ],
    queryFn: () =>
      getCheckListItems(checklist_id, { case_id: case_id, qa_id: qa_id }),
  });

  if (!data) return null;

  return (
    <div
      style={{
        padding: "0px 0px",
        height: "0px",
        overflow: "auto",
        flex: "1 1 auto",
        marginTop: 10,
      }}
    >
      {data.map((group: QaChecklistItem) => {
        return (
          <div style={{ marginBottom: 20 }} key={group.group_id}>
            <div
              style={{
                marginBottom: 5,
                fontSize: "large",
                color: theme.palette.text.primary,
                fontWeight: 500,
              }}
            >
              {group.group_name}
            </div>
            <div style={{ marginTop: 5, marginBottom: 5 }}>
              {group.checklist_items
                .sort((a, b) => a.item_id - b.item_id)
                .map((item) => (
                  <ListItem
                    key={item.item_id}
                    data={{ ...item, case_id: case_id, qa_id: qa_id }}
                    qaInfo={qaInfo}
                    is_complete={item.is_complete ? true : false}
                  />
                )) || <div>No checklist items</div>}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const columnDefs = QaEntryColumnDefs;

const QaEntries = ({
  qaInfo,
  stateStoreKey = "cases:qa-entries:view",
}: {
  qaInfo: QaReview;
  stateStoreKey?: string;
}) => {
  const { review_id } = useParams();
  const theme = useTheme();
  const { hasPermission } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [columnState, setColumnState] = useState(() => {
    return synchronizeColumnState(
      columnDefs,
      JSON.parse(localStorage.getItem(stateStoreKey) || "{}")
    );
  });

  const [pageSize, setPageSize] = useState(100);
  const [query, setQuery] = useState<QaEntriesQuery | null>(null);

  const table = useTable();

  const currentSort = useMemo(() => {
    let [sort] = columnState
      ?.filter((c) => c.sorting?.active)
      .map((c) => ({ field: c.dataField, sort: c.sorting?.direction }));

    return sort;
  }, [columnState]);

  const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: [
        "cases:qa-entries",
        {
          query: {
            ...query?.query,
            order: query?.query?.order || currentSort,
            pageSize,
            qa_id: review_id,
          },
        },
      ],
      queryFn: ({ pageParam }) =>
        QualityAssuranceAPI.QaEntries.query({
          query: {
            ...query?.query,
            order: query?.query?.order || currentSort,
            pageSize,
            page: pageParam,
            qa_id: review_id,
          },
        }),
      getNextPageParam: (lastPage) => {
        return lastPage.nextPage;
      },
      getPreviousPageParam: (firstPage) => {
        if (firstPage.page - 1 === 0) return null;
        return firstPage.page - 1;
      },
      initialPageParam: 1,
      enabled: !!query,
      placeholderData: (data) => data,
    });

  const records = data?.pages?.reduce((acc, page) => {
    return [...acc, ...page.data];
  }, []);

  const totalRecords = data?.pages?.[0]?.total || 0;

  const debouncedFetchNextPage = useDebouncedCallback(() => {
    fetchNextPage();
  }, 50);

  const { queryButton, queryFilter, conditions } = useQueryFilter({
    dimensions: QaEntryFilterDimensions,
    onQuerySet: (newFilter) =>
      setQuery((q) => {
        return {
          query: { ...q?.query, ...newFilter, page: 1, qa_id: review_id },
        };
      }),
    stateStoring: {
      enabled: true,
      type: "localStorage",
      storageKey: stateStoreKey,
    },
    queryFilter: undefined,
  });
  const handleSort = (field: string) => {
    const savedColumn =
      columnState?.find((svc) => field === svc.dataField) || {};
    const order = !!savedColumn ? savedColumn?.sorting?.direction : null;

    let newOrder = null;

    // if sorted on a different column, reset the sort
    if (query?.query?.order?.field !== field) {
      newOrder = {
        field,
        sort: "asc",
      };
    }

    // otherwise rotate the sort options on the current column
    else {
      switch (order) {
        case "asc":
          newOrder = {
            field,
            sort: "desc",
          };
          break;
        case "desc":
          newOrder = null;
          break;
        default:
          newOrder = {
            field,
            sort: "asc",
          };
      }
    }

    let newCols = columnState?.map((c) => {
      if (c.dataField === field) {
        return {
          ...c,
          sorting: {
            active: newOrder ? true : false,
            direction: newOrder?.sort,
          },
        };
      }
      delete c.sorting;
      return c;
    });

    let newQuery = {
      query: {
        ...query?.query,
        order: newOrder,
      },
    };

    setColumnState(newCols);

    setQuery(newQuery);
  };
  const handleQueryRefetch = ({ onRefetched = () => {} }) => {
    queryClient
      .refetchQueries({
        queryKey: [
          "cases:qa-entries",
          {
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
              qa_id: review_id,
            },
          },
        ],
        exact: true,
      })
      .then((res) => onRefetched());
  };
  const handleExportTable = () => {
    // show snackbar
    enqueueSnackbar("Exporting table...", {
      variant: "info",
    });

    QualityAssuranceAPI.QaEntries.exportQaEntriesList({
      query: {
        ...query?.query,
        order: query?.query?.order || currentSort,
        pageSize,
      },
      type: "xlsx",
      columns: columnState
        .filter((c) => c.visible !== false && c.showInColumnChooser !== false)
        .sort((a, b) => a.order - b.order)
        .map((c) => {
          return { dataField: c.dataField, header: c.caption, ...c };
        }),
      date_format: getDateFormat({
        isMoment: true,
        includeTime: true,
      }),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }).then((res) => {
      const { signedUrl, filename } = res;
      const el = document.createElement("a");
      el.href = signedUrl.replace(
        "http://localhost:3000",
        "http://localhost:3001"
      );
      el.download = filename;
      el.click();
      // remove snackbar
    });
  };
  const handleColumnReorder = (
    newOrder: { column: string; order: number }[]
  ) => {
    setColumnState((cs) => {
      return newOrder.map((o) => {
        return {
          ...cs.find((c) => c.dataField === o.column),
          order: o.order,
        };
      });
    });
  };
  const handleColumnVisibility = (
    column: TableColumnDef<any>,
    visible: boolean
  ) => {
    setColumnState((cs) => {
      return cs.map((c) => {
        if (c.dataField === column.dataField) {
          return {
            ...c,
            visible,
          };
        }
        return c;
      });
    });
  };
  const handleClearFilters = () => queryFilter.clear();
  // Detect scroll to bottom
  const handleScroll = (e: React.MouseEvent) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    const pagesLength = data?.pages?.length || 0;
    if (scrollHeight - scrollTop <= clientHeight + 100 * pagesLength) {
      if (hasNextPage && !isFetchingNextPage) {
        debouncedFetchNextPage();
      }
    }
  };

  // Persist column state to local storage
  useEffect(() => {
    const localStorageItem = localStorage.getItem(stateStoreKey);
    let oldState = localStorageItem ? JSON.parse(localStorageItem) : {};
    localStorage.setItem(
      stateStoreKey,
      JSON.stringify({
        ...oldState,
        cols: columnState,
      })
    );
  }, [columnState]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flex: "initial",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Button
          size="small"
          variant="contained"
          color="primary"
          disabled={!hasPermission()}
          onClick={(e) => {
            setShowCreateModal(true);
          }}
          style={{
            minWidth: "fit-content",
            fontSize: 11,
            padding: "3px 6px",
          }}
        >
          + New QA Entry
        </Button>
        <div style={{ marginLeft: 10 }}>{queryButton}</div>
        <ItemTotal total={totalRecords} Icon={BookmarkAddedOutlinedIcon} />
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            minWidth: "fit-content",
          }}
        >
          <ComboButton
            type="multi-select"
            data={columnState.filter((c) => c.showInColumnChooser !== false)}
            displayField="caption"
            idField={"dataField"}
            selectedItems={columnState.filter((c) => c.visible !== false)}
            variant="outlined"
            closeOnSelect={false}
            textColor={theme.palette.text.secondary}
            title={"Select Columns"}
            showSearch={true}
            dropDownTitle={() => {
              return (
                <div
                  style={{
                    margin: "5px 0px",
                    padding: 3,
                    color: theme.palette.text.secondary,
                    display: "flex",
                    alignItems: "center",
                    minWidth: 200,
                  }}
                >
                  Select Columns
                </div>
              );
            }}
            onItemDeSelect={(item) => {
              handleColumnVisibility(item, false);
            }}
            onItemSelect={(item) => {
              handleColumnVisibility(item, true);
            }}
          >
            <ViewColumnOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            textColor={theme.palette.text.secondary}
            title={"Export Table"}
            onClick={handleExportTable}
          >
            <FileDownloadOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            textColor={theme.palette.text.secondary}
            title={"Clear Filters"}
            onClick={handleClearFilters}
          >
            <FilterListOffIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            textColor={theme.palette.text.secondary}
            title={table.isCompact ? "Zoom In" : "Zoom Out"}
            onClick={() => table.toggleCompact()}
          >
            {table.isCompact ? (
              <ZoomOutMapOutlinedIcon style={{ fontSize: 18 }} />
            ) : (
              <ZoomInMapOutlinedIcon style={{ fontSize: 18 }} />
            )}
          </ComboButton>
          <ComboButton
            type="button"
            variant={"outlined"}
            textColor={
              table.isStriped
                ? theme.palette.primary.main
                : theme.palette.text.secondary
            }
            title={table.isStriped ? "Hide Stripes" : "Show Stripes"}
            onClick={() => table.toggleStripes()}
          >
            <ReorderOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <TextBox
            stylingMode="filled"
            placeholder="Search QA Entries"
            labelMode="static"
            height={30}
            style={{ marginLeft: "10px" }}
            onKeyUp={(e: any) => {
              let searchText = e.event.currentTarget.value;
              if (
                e.event.code === "Enter" ||
                e.event.code === "NumpadEnter" ||
                searchText === ""
              ) {
                setQuery((q) => ({
                  query: {
                    ...q?.query,
                    search: searchText === "" ? null : searchText,
                    page: 1,
                  },
                }));
              }
            }}
          />
        </div>
      </div>
      {data && (
        <>
          {<div>{conditions}</div>}
          <Table
            data={records}
            totalRecords={totalRecords}
            tableInstance={table}
            keyValue="entry_id"
            columnProps={{ minWidth: 150, width: 150 }}
            onHeaderClick={(col: TableColumn<QaEntry>) =>
              col?.sorting?.enabled === false ? null : handleSort(col.dataField)
            }
            onScroll={handleScroll}
            onRowUpdated={() => {
              refetch();
            }}
            onColumnReorder={handleColumnReorder}
            showSelection={true}
          >
            {columnState
              .filter((col) =>
                col.dataField === "actions" ? hasPermission() : true
              )
              .map((col) => {
                return <Column key={col.dataField} {...col} />;
              })}
          </Table>
        </>
      )}
      {!data && <Loader message={"Retrieving Items..."} />}
      <CreateQAEntryPopup
        open={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
        onSubmit={() => handleQueryRefetch({})}
        checklist_id={qaInfo.checklist_id}
      />
    </>
  );
};

const CreateQAEntryPopup = ({
  open,
  onSubmit = () => {},
  handleClose = () => {},
  checklist_id,
}: {
  open: boolean;
  onSubmit?: () => void;
  handleClose: () => void;
  checklist_id: number;
}) => {
  const { case_id, review_id } = useParams();
  const theme = useTheme();
  const form = useRef<any>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = usePermissions();

  const result = useQueries({
    queries: [
      {
        queryKey: [
          "users:list",
          { includeObservers: false, includeInactive: false },
        ],
        queryFn: () =>
          UserApi.getUsers({ includeObservers: false, includeInactive: false }),
        enabled: open,
      },
      {
        queryKey: ["evidence:list", { case_id }],
        queryFn: () => EvidenceAPI.getEvidence({ case_id }),
        enabled: open,
      },
      {
        queryKey: ["quality-assurance:qa-entries:issue-types"],
        queryFn: () => QualityAssuranceAPI.QaEntries.getIssueTypes(),
        enabled: open,
      },
      {
        queryKey: ["quality-assurance:checklists:items", { checklist_id }],
        queryFn: () => QualityAssuranceAPI.getCheckListItems(checklist_id),
        enabled: open,
      },
    ],
  });

  const handleSubmit = () => {
    if (form.current.instance.validate().isValid) {
      let values = Object.assign({}, form.current.instance.option("formData"));

      QualityAssuranceAPI.QaEntries.createQaEntry({
        ...values,
        case_id,
        qa_id: review_id,
        checklist_id: checklist_id,
        reviewer_id: currentUser.user_id,
        date: db_timestamp(values.date),
      }).then((result) => {
        enqueueSnackbar(`QA Entry Created.`, {
          variant: "success",
        });
        onSubmit();
      });
      handleClose();
    }
  };

  const handleCancel = () => handleClose();

  const isDone = result.every((x) => x.isFetched);

  const users = result[0]?.data || [];
  const evidence = result[1]?.data || [];
  const issue_types = result[2]?.data || [];
  const qa_items =
    result[3]?.data?.map((itemData: QaChecklistItem) => {
      return {
        ...itemData,
        key: itemData.group_name,
        items: itemData.checklist_items,
      };
    }) || [];

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      disableEnforceFocus={true}
      style={{
        userSelect: "none",
        zIndex: 1400,
      }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 600,
          maxHeight: "90vh",
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 300px)",
          overflowY: "auto",
          padding: 20,
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Create QA Entry
        </div>
        {!isDone && <Loader />}
        {isDone && (
          <>
            <Form ref={form} colCount={2}>
              <SimpleItem
                dataField="examiner_id"
                label={{ text: "Examiner" }}
                editorType="dxSelectBox"
                editorOptions={{
                  dataSource: users,
                  displayExpr: "full_name",
                  valueExpr: "user_id",
                  searchEnabled: true,
                  searchExpr: "full_name",
                  placeholder: "Select Examiner...",
                  dropDownOptions: {
                    maxHeight: 250,
                  },
                  itemTemplate: "examiner_id",
                }}
              />
              <Template
                name="examiner_id"
                render={(data: any) => <UserSelectBoxItem data={data} />}
              />
              <SimpleItem
                dataField="date"
                label={{ text: "Entry Date" }}
                editorType="dxDateBox"
                editorOptions={{
                  useMaskBehavior: true,
                  displayFormat: getDateFormat({
                    isMoment: false,
                    includeTime: true,
                  }),
                }}
                validationRules={[
                  {
                    type: "custom",
                    message: "Invalid Date",
                    validationCallback: validateTimestamp,
                  },
                ]}
              />
              <SimpleItem
                dataField="evidence_id"
                label={{ text: "Evidence Item" }}
                editorType="dxSelectBox"
                editorOptions={{
                  dataSource: evidence,
                  displayExpr: "evidence_number",
                  valueExpr: "evidence_id",
                  searchEnabled: true,
                  searchExpr: "evidence_number",
                  placeholder: "Select Evidence...",
                  dropDownOptions: {
                    maxHeight: 250,
                  },
                  itemTemplate: "evidence_id",
                }}
              />
              <Template
                name="evidence_id"
                render={(data: any) => <EvidenceSelectBoxItem data={data} />}
              />
              <SimpleItem
                dataField="issue_type"
                label={{ text: "Issue Type" }}
                editorType="dxSelectBox"
                editorOptions={{
                  dataSource: issue_types,
                  displayExpr: "issue_type",
                  valueExpr: "issue_type",
                  searchEnabled: true,
                  searchExpr: "issue_type",
                  placeholder: "Select Issue...",
                  dropDownOptions: {
                    maxHeight: 250,
                  },
                }}
              />
              <SimpleItem
                dataField="resolved"
                label={{ text: "Is Resolved" }}
                editorType="dxSelectBox"
                editorOptions={{ items: ["Yes", "No"] }}
              />
              <SimpleItem
                dataField="checklist_item_id"
                label={{ text: "Associated QA Item" }}
                editorType="dxSelectBox"
                editorOptions={{
                  dataSource: qa_items,
                  displayExpr: "item_name",
                  deferRendering: false,
                  grouped: true,
                  valueExpr: "item_id",
                  dropDownOptions: {
                    maxHeight: 250,
                  },
                }}
              />
              <SimpleItem
                dataField="details"
                editorType="dxTextArea"
                colSpan={2}
              />
              <SimpleItem
                dataField="resolution"
                editorType="dxTextArea"
                colSpan={2}
              />
              <SimpleItem
                dataField="notes"
                editorType="dxTextArea"
                colSpan={2}
              />
            </Form>
            <ToolBarItems
              submitText="Create QA Entry"
              onCancel={handleCancel}
              onSubmit={handleSubmit}
              style={{ marginTop: 15 }}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

const QaHeader = styled(({ className, qaInfo }) => {
  const theme = useTheme();
  return (
    <div className={className}>
      {/*@ts-ignore*/}
      <Link to={-1} style={{ marginRight: 10 }}>
        <TaskButton
          style={{ outline: `1px solid ${theme.palette.divider}` }}
          title="Back to QA Reviews"
        >
          <KeyboardReturnOutlinedIcon style={{ fontSize: 20 }} />
        </TaskButton>
      </Link>
      <div style={{ fontSize: "1rem" }}>{qaInfo.name}</div>
    </div>
  );
})`
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
`;

const QaDetails = styled(({ className }) => {
  const { case_id, review_id } = useParams();
  const [currentView, setCurrentView] = useState(0);

  const [group, setGroup] = useState("qa-checklist");

  const handleChange = (event: React.MouseEvent, newGroup: string) => {
    if (newGroup !== null) {
      setGroup(newGroup);
      setCurrentView(newGroup === "qa-checklist" ? 0 : 1);
    }
  };

  const control = {
    value: group,
    onChange: handleChange,
    exclusive: true,
  };

  const { data: qaInfo } = useQuery({
    queryKey: ["quality-assurance:qa-review", { review_id }],
    queryFn: () => QualityAssuranceAPI.getQAReviews({ review_id }),
    select: (data) => data[0],
  });

  if (!qaInfo) return <Loader />;

  return (
    <div className={className}>
      <div style={{ display: "flex", marginBottom: 10 }}>
        <QaHeader qaInfo={qaInfo} />
        <ToggleButtonGroup size="small" {...control} style={{ margin: "auto" }}>
          <ToggleButton
            value="qa-checklist"
            size="small"
            disableRipple={true}
            style={buttonStyle}
          >
            QA Checklist
          </ToggleButton>
          <ToggleButton
            value="qa-entries"
            size="small"
            disableRipple={true}
            style={buttonStyle}
          >
            QA Entries
          </ToggleButton>
        </ToggleButtonGroup>
        <div style={{ position: "absolute", top: 0, right: 0 }}>
          <QaStatusSelector
            rowData={qaInfo}
            showDropdownIcon
            variant={"outlined"}
          />
        </div>
      </div>
      {currentView === 0 && <QaChecklist qaInfo={qaInfo} />}
      {currentView === 1 && <QaEntries qaInfo={qaInfo} />}
    </div>
  );
})`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;
`;

export default QaDetails;
