import Loader from "../../../../components/Loader";
import React, { useEffect, useRef, useState } from "react";
import { getAcquisitionReportMetrics } from "../../../../api";
import { monolithMoment } from "../../../../utils/date-format";
import { Card, Divider } from "@mui/material";

const ReportMetrics = ({ reportInfo }) => {
  const [metrics, setMetrics] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAcquisitionReportMetrics({
      ...reportInfo.report_parameters,
      dimension: "stats",
    }).then((result) => {
      setMetrics(result);
      setIsLoading(false);
    });
  }, []);

  return (
    <Card
      style={{
        padding: "20px",
        textAlign: "left",
        flexGrow: 1,
        flexBasis: "10px",
      }}
    >
      <div>
        <div
          style={{
            fontSize: "larger",
          }}
        >
          Metrics
        </div>
        <Divider sx={{ my: 2 }} />
        {isLoading && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Loader />
          </div>
        )}
        {!isLoading && (
          <div
            style={{
              minWidth: "300px",
              overflowY: "auto",
              maxHeight: "175px",
              paddingRight: "10px",
            }}
          >
            {metrics && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>Acquisitions:</div>
                  <div>{metrics.acquisition_count}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>Acquisitions Per Month:</div>
                  <div>
                    {(
                      (metrics.acquisition_count || 0) /
                      (metrics.total_months || 1)
                    ).toFixed(0)}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>Acquisition Total: </div>
                  <div>
                    {metrics.acquired_data_gb
                      ? metrics.acquired_data_gb.toFixed(2) + " GB"
                      : "0.00 GB"}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>Avg Data per Month: </div>
                  <div>
                    {metrics.avg_data_per_month
                      ? metrics.avg_data_per_month + " GB"
                      : "0.00 GB"}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>Earliest Acquistiion: </div>
                  <div>
                    {metrics.earliest_date
                      ? monolithMoment({
                          timestamp: metrics.earliest_date,
                          includeTime: false,
                        })
                      : "None"}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>Latest Acquistiion: </div>
                  <div>
                    {metrics.earliest_date
                      ? monolithMoment({
                          timestamp: metrics.latest_date,
                          includeTime: false,
                        })
                      : "None"}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Card>
  );
};

export default ReportMetrics;
