import { httpDelete, httpGet, httpPatch, httpPost } from "../../connection.js";

async function query(query = {}) {
  return await httpPost(`/analysis/timeline-events/query`, query);
}

async function get(params = {}) {
  return await httpGet(`/analysis/timeline-events`, params);
}

async function getLogs(params = {}) {
  return await httpGet(`/analysis/timeline-events/logs`, params);
}

async function exportList(params = {}) {
  return await httpPost(`/analysis/timeline-events/export`, params);
}

async function create(values = {}) {
  return await httpPost(`/analysis/timeline-events`, values);
}

async function update(values = {}) {
  return await httpPatch(`/analysis/timeline-events/${values.uuid}`, values);
}

async function remove(values = {}) {
  return await httpDelete(`/analysis/timeline-events/${values.uuid}`);
}

async function getNotes(params = {}) {
  return await httpGet(
    `/analysis/timeline-events/notes/${params.uuid}`,
    params
  );
}

async function getTraceIndicators(params = {}) {
  const { uuid, ...rest } = params;
  return await httpGet(
    `/analysis/timeline-events/${params.uuid}/trace-indicators`,
    rest
  );
}

const TimelineEventsAPI = {
  query,
  exportList,
  get,
  getLogs,
  create,
  update,
  remove,
  getNotes,
  getTraceIndicators,
};

export default TimelineEventsAPI;
