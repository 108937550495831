import CollapseSection from "../../../components/CollaspeSection";
import CustomFieldsTemplate from "./CustomFieldsTemplate";
import customFieldAPIBundler from "./utils/customFieldAPIBundler";
import styled from "styled-components";

const CustomFieldSettings = styled(({ className }) => {
  return (
    <div className={className} style={{ maxWidth: 800, padding: "0px 30px" }}>
      {customFieldAPIBundler.map((api) => {
        return (
          <CollapseSection title={`Custom ${api.id} Fields`}>
            <CustomFieldsTemplate
              category={api.id}
              description={api.description}
              handleCreateField={api.createField}
              handleUpdateField={api.updateField}
              handleDeleteField={api.deleteField}
              getFields={api.getFields}
              getTypes={api.getTypes}
            />
          </CollapseSection>
        );
      })}
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
`;

export default CustomFieldSettings;
