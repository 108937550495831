import CasesApi from "../../api/cases/index.js";
import InquiriesAPI from "../../api/inquiries/index.js";
import UserApi from "../../api/users/users.js";
import { InquiryStatusOptions } from "../../pages/Inquiries/InquiryDetails";

const InquiryFilterDimensions = [
  {
    name: "Request Name",
    field: "request_name",
    id: "request_name",
    type: "text",
    mode: "text",
  },
  {
    name: "Inquiry ID",
    field: "inquiry_id",
    id: "inquiry_id",
    type: "text",
    mode: "text",
  },
  {
    name: "Relay ID",
    field: "relay_id",
    id: "relay_id",
    type: "text",
    mode: "text",
  },
  {
    name: "Case Number",
    field: "case_number",
    id: "case_number",
    type: "text",
    mode: "text",
  },
  {
    name: "Case Name",
    field: "case_ref",
    id: "case_ref",
    type: "text",
    mode: "text",
  },
  {
    name: "Client Name",
    field: "inquiry_name",
    id: "inquiry_name",
    type: "text",
    mode: "text",
  },
  {
    name: "Client Organization",
    field: "inquiry_org",
    id: "inquiry_org",
    type: "text",
    mode: "text",
  },
  {
    name: "Client Email",
    field: "inquiry_email",
    id: "inquiry_email",
    type: "text",
    mode: "text",
  },
  {
    name: "Description",
    field: "inquiry_notes",
    id: "inquiry_notes",
    type: "text",
    mode: "text",
  },
  {
    name: "Status",
    field: "inquiry_status",
    id: "inquiry_status",
    type: "string",
    mode: "multi-select",
    key: ["inquiries:statuses"],
    selectionDisplayName: "status_name",
    selectionIdField: "status_name",
    queryFn: () =>
      Promise.resolve(
        InquiryStatusOptions.map((item) => ({ status_name: item }))
      ),
  },
  {
    name: "Type",
    field: "inquiry_type",
    id: "inquiry_type",
    type: "string",
    mode: "multi-select",
    key: ["cases:types"],
    selectionDisplayName: "case_type",
    selectionIdField: "case_type",
    queryFn: () => CasesApi.getCaseTypes(),
  },
  {
    name: "Entered By",
    field: "entered_by_id",
    id: "entered_by_id",
    type: "string",
    mode: "multi-select",
    key: ["users:list"],
    selectionDisplayName: "full_name",
    selectionIdField: "user_id",
    queryFn: () => UserApi.getUsers({ includeObservers: false }),
  },
  {
    name: "Inquiry Date",
    field: "inquiry_date",
    id: "inquiry_date",
    type: "date",
    mode: "date",
  },
  {
    name: "Organization",
    field: "inquiry_org",
    id: "inquiry_org",
    type: "string",
    mode: "multi-select",
    key: ["inquiries:organizations"],
    selectionDisplayName: "inquiry_org",
    selectionIdField: "inquiry_org",
    queryFn: () => InquiriesAPI.getInquiryOrgs(),
  },
  {
    name: "Convert Date",
    field: "inquiry_convert_date",
    id: "inquiry_convert_date",
    type: "date",
    mode: "date",
  },
];

export default InquiryFilterDimensions;
