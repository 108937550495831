import moment from "moment";
import { formatCurrency } from "../../utils/currency-formatter.js";
import { getDateFormat } from "../../utils/date-format";

const ForensicSoftwareColumnDefs = [
  {
    dataField: "vendor",
    caption: "Vendor",
  },
  {
    dataField: "software_name",
    caption: "Software",
  },
  {
    dataField: "name",
    caption: "License Name",
  },
  {
    dataField: "edition",
    caption: "Edition",
  },
  {
    dataField: "purchase_date",
    caption: "Purchase Date",
    dataType: "date",
    format: {
      type: getDateFormat({ isMoment: true, includeTime: false }),
    },
    render: (data) =>
      data.purchase_date
        ? moment(data.purchase_date).format(
            getDateFormat({ isMoment: true, includeTime: false })
          )
        : null,
  },
  {
    dataField: "expire_date",
    caption: "Expire Date",
    dataType: "date",
    format: {
      type: getDateFormat({ isMoment: true, includeTime: false }),
    },
    render: (data) =>
      data.expire_date
        ? moment(data.expire_date).format(
            getDateFormat({ isMoment: true, includeTime: false })
          )
        : null,
  },
  {
    dataField: "expires",
    caption: "Expires",
    render: (data) => <ExpiresComponent data={data} />,
  },
  {
    dataField: "cost",
    caption: "Cost",
    render: (data) => <CurrencyComponent value={data.cost} />,
  },
  {
    dataField: "sms",
    caption: "SMS",
    render: (data) => <CurrencyComponent value={data.sms} />,
  },
  {
    dataField: "license_key",
    caption: "License Key",
    visible: false,
  },
  {
    dataField: "dongle_sn",
    caption: "Dongle S/N",
    visible: false,
  },
  {
    dataField: "location",
    caption: "Location",
  },
  {
    dataField: "description",
    caption: "Description",
    visible: false,
    titleRender: (data) => data.description,
  },
];

const ExpiresComponent = ({ data }) => {
  const fromNow = data.expire_date ? moment(data.expire_date).fromNow() : null;

  const diffDays = data.expire_date
    ? moment(data.expire_date).diff(moment(), "days")
    : null;

  let color = "limegreen";
  if (diffDays <= 30) color = "red";
  else if (diffDays <= 60) color = "orange";
  else if (diffDays <= 90) color = "yellow";

  return <div style={{ color: color }}>{fromNow}</div>;
};

const CurrencyComponent = ({ value }) => {
  return <div>{formatCurrency(value)}</div>;
};

export default ForensicSoftwareColumnDefs;
