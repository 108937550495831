import axios from "axios";
import { httpDelete, httpGet, httpPatch, httpPost } from "../connection.js";

// Get Notes
async function getNotes(values = {}) {
  return await httpGet(`/notes`, values);
}

// Get Notes with complex query
async function query(query) {
  return await httpPost("/notes/query", query);
}

// Create Note
async function createNote(values) {
  return await httpPost(`/notes`, values);
}

// Update Note
async function updateNote(values) {
  return await httpPatch(`/notes`, values);
}

// Delete Note
async function deleteNote(values = {}) {
  return await httpDelete(`/notes/${values.uuid}`);
}

async function exportNotes(values = {}) {
  return await httpPost(`/notes/export`, values);
}

async function getUploadUrl(values = {}) {
  return await httpPost(`/notes/get-upload-url`, values);
}

async function uploadImage(values = {}) {
  // Check if URL is AWS S3
  if (
    values.url.includes("amazonaws.com") ||
    values.url.includes("backblazeb2.com")
  ) {
    return await axios.put(values.url, values.file, {});
  }

  // Upload to Monolith API
  else {
    // Construct form data object
    const formData = new FormData();
    formData.append("file", values.file);

    // Upload file
    return await axios.put(values.url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

async function addAttachment(values = {}) {
  return await httpPost(`/notes/add-attachment`, values);
}

async function getTemplates(values = {}) {
  return await httpGet(`/notes/templates`, values);
}

async function updateTemplate(values = {}) {
  return await httpPatch(`/notes/templates`, values);
}

async function createTemplate(values = {}) {
  return await httpPost(`/notes/templates`, values);
}

async function deleteTemplate(values = {}) {
  return await httpDelete(`/notes/templates/${values.uuid}`);
}

async function getNoteObjectLinks(values = {}) {
  return await httpGet(`/notes/object-links`, values);
}

async function createNoteObjectLink(values = {}) {
  return await httpPost(`/notes/object-links`, values);
}

async function deleteNoteObjectLink(values = {}) {
  return await httpDelete(`/notes/object-links/${values.uuid}`);
}

async function getNoteUsers(values = {}) {
  return await httpGet(`/notes/note-users`, values);
}

const MonolithNotesAPI = {
  getNotes,
  query,
  createNote,
  updateNote,
  deleteNote,
  exportNotes,
  getUploadUrl,
  uploadImage,
  addAttachment,
  getTemplates,
  updateTemplate,
  deleteTemplate,
  createTemplate,
  getNoteObjectLinks,
  createNoteObjectLink,
  deleteNoteObjectLink,
  getNoteUsers,
};

export default MonolithNotesAPI;
