import { Modal, useTheme } from "@mui/material";
import { Form, SimpleItem } from "devextreme-react/ui/form.js";
import { useRef } from "react";
import CaseReportsAPI from "../../../../api/cases/reports.js";
import ToolBarItems from "../../../../components/ToolBarItems.js";

const CreateCaseReportModal = ({
  caseInfo,
  open = false,
  handleClose = () => {},
  onSubmit = () => {},
}) => {
  const theme = useTheme();
  const form = useRef(null);

  const handleSubmit = (event) => {
    if (form.current.instance.validate().isValid) {
      const formData = form.current.instance.option().formData;

      CaseReportsAPI.createCaseReport({
        case_id: caseInfo.case_id,
        name: formData.name,
        description: formData.description,
      }).then((result) => {
        onSubmit();
      });

      handleClose();
    }
  };

  const handleCancel = (event) => {
    handleClose();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        style={{
          userSelect: "none",
          zIndex: 1400,
        }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 400,
            maxHeight: "90vh",
            backgroundColor: theme.palette.background.default,
            position: "fixed",
            left: "calc(50% - 200px)",
            overflowY: "auto",
            padding: 20,
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Create Case Report
          </div>
          <Form ref={form} colCount={2}>
            <SimpleItem
              dataField="name"
              label={{ text: "Case Report Name" }}
              colSpan={2}
              isRequired={true}
            />
            <SimpleItem
              dataField="description"
              editorType="dxTextArea"
              colSpan={2}
              editorOptions={{
                height: 75,
              }}
            />
          </Form>
          <ToolBarItems
            style={{ marginTop: 20 }}
            submitText="Start Report"
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </div>
      </Modal>
    </>
  );
};

export default CreateCaseReportModal;
