const CaseDimensions = [
  {
    name: "Case Number",
    field: "case_number",
    id: "case_number",
    type: "text",
    mode: "text",
  },
  {
    name: "Case Name",
    field: "case_ref",
    id: "case_ref",
    type: "text",
    mode: "text",
  },
  {
    name: "Description",
    field: "case_synopsis",
    id: "case_synopsis",
    type: "text",
    mode: "text",
  },
  {
    name: "Type",
    field: "case_type",
    id: "case_type",
    type: "string",
    mode: "multi-select",
    key: ["cases:types"],
    selectionDisplayName: "case_type",
    selectionIdField: "case_type",
  },
  {
    name: "Status",
    field: "case_status",
    id: "case_status",
    type: "string",
    mode: "multi-select",
    key: ["cases:statuses"],
    selectionDisplayName: "status_name",
    selectionIdField: "status_name",
  },
  {
    name: "Progress",
    field: "progress",
    id: "progress",
    type: "string",
    mode: "multi-select",
    key: ["cases:progress"],
    selectionDisplayName: "progress_name",
    selectionIdField: "progress_name",
  },
  {
    name: "Office",
    field: "office",
    id: "office",
    type: "string",
    mode: "multi-select",
    key: ["offices:list"],
    selectionDisplayName: "office_name",
    selectionIdField: "office_id",
  },
  {
    name: "Case Lead",
    field: "case_lead",
    id: "case_lead",
    type: "string",
    mode: "multi-select",
    key: ["users:list", { includeObservers: false, includeInactive: false }],
    selectionDisplayName: "full_name",
    selectionIdField: "user_id",
  },
  {
    name: "Assigned Users",
    field: "assigned_users",
    id: "assigned_users",
    type: "string",
    mode: "multi-select",
    key: ["users:list", { includeObservers: false, includeInactive: false }],
    selectionDisplayName: "full_name",
    selectionIdField: "user_id",
    operators: ["isAnyOf", "isNoneOf"],
  },
  {
    name: "Open Date",
    field: "case_open_date",
    id: "case_open_date",
    type: "date",
    mode: "date",
  },
  {
    name: "Close Date",
    field: "case_closed_date",
    id: "case_closed_date",
    type: "date",
    mode: "date",
  },
  {
    name: "Last Activity",
    field: "last_activity_date",
    id: "last_activity_date",
    type: "date",
    mode: "date",
  },
  {
    name: "Client",
    field: "client",
    id: "client",
    type: "string",
    mode: "multi-select",
    key: ["clients:list"],
    selectionDisplayName: "name",
    selectionIdField: "client_id",
  },
  {
    name: "Organization",
    field: "organization",
    id: "organization",
    type: "string",
    mode: "multi-select",
    key: ["clients:organizations"],
    selectionDisplayName: "organization",
    selectionIdField: "organization",
  },
].sort((a, b) => a.name.localeCompare(b.name));

export default CaseDimensions;
