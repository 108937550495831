import { httpDelete, httpGet, httpPatch, httpPost } from "../../connection.js";

const get = async (values = {}) => {
  return httpGet("/analysis/event-types", values);
};

const create = async (values = {}) => {
  return httpPost("/analysis/event-types", values);
};

const update = async (values = {}) => {
  return httpPatch(`/analysis/event-types/${values.uuid}`, values);
};

const remove = async (values = {}) => {
  return httpDelete(`/analysis/event-types/${values.uuid}`);
};

const EventTypesAPI = {
  get,
  create,
  update,
  remove,
};

export default EventTypesAPI;
