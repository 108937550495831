import { Form, SimpleItem } from "devextreme-react/ui/form";
import { useRef } from "react";
import ToolBarItems from "../../components/ToolBarItems";
import { Modal, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../components/Loader.js";
import { customizeLabel } from "../../utils/labels/labelUtils";

// TODO: Refactor to typescript
// TODO: Remove devExtreme and mui components

const PrinterSelectModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
  label,
}) => {
  const theme = useTheme();
  const form = useRef(null);
  const DYMO = window.dymo.label.framework;

  const { data: printers, isFetched } = useQuery({
    queryKey: ["dymo:printers:list"],
    queryFn: () => DYMO.getPrinters(),
    enabled: open,
  });

  const handleSubmit = () => {
    const formData = { ...form.current.instance.option().formData };
    handleClose();
    customizeLabel(label.label_data, defaultInfo).then((customizedLabelXml) => {
      DYMO.openLabelXml(customizedLabelXml).print(formData.printer);
      onSubmit();
    });
  };

  const handleCancel = () => handleClose();

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 400,
          backgroundColor: theme.palette.background.default,
          position: "absolute",
          left: "50%",
          top: "20%",
          transform: "translate(-35%, -20%)",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>Print Label</div>
        {!isFetched && <Loader />}
        {isFetched && printers.length === 0 && (
          <>
            <div style={{ margin: "10px 0px" }}>
              No DYMO printers found. Please connect a DYMO printer and try
              again.
            </div>
            <ToolBarItems
              onCancel={handleCancel}
              style={{ marginTop: 20 }}
              showSubmit={false}
            />
          </>
        )}
        {isFetched && printers.length > 0 && (
          <>
            {label && (
              <div style={{ margin: "15px 0px" }}>
                <div style={{ color: theme.palette.text.secondary }}>
                  Selected Label:
                </div>
                <div>{label.label_name}</div>
              </div>
            )}
            <Form ref={form}>
              <SimpleItem
                dataField="printer"
                label={{ text: "Select Printer" }}
                editorType="dxSelectBox"
                editorOptions={{
                  dataSource: printers,
                  value: printers[0].name,
                  displayExpr: "name",
                  valueExpr: "name",
                }}
              />
            </Form>
            <ToolBarItems
              submitText="Print Label"
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              style={{ marginTop: 20 }}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default PrinterSelectModal;
