import styled from "styled-components";

const ContactSelectBoxItem = styled(({ className, data }) => {
  return (
    <div className={className}>
      {data.client_id === "new" ? (
        <div className="new-contact">+ Create New Contact</div>
      ) : (
        <>
          <div className="contact-name">{data.name}</div>
          <div className="contact-info">
            <div>
              {data.email ? data.email : ""}
              {data.email && data.contact_type ? " | " : ""}
              {data.contact_type ? data.contact_type : ""}
            </div>
          </div>
        </>
      )}
    </div>
  );
})`
  .new-contact {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: bold;
  }
  .contact-name {
    margin-bottom: 5px;
  }
  .contact-info {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

export default ContactSelectBoxItem;
