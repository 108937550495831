import { useEffect } from "react";
import shortUUID from "short-uuid";

import FullscreenTitle from "./Components/FullscreenTitle.js";
import MainNoteView from "./Components/MainNoteView.js";
import TopNav from "./Components/TopNav.js";
import TabsContainer from "./Components/TabsContainer.js";
import TabFill from "./Components/TabFill.js";
import NoteTab from "./Components/NoteTab.js";
import { db_timestamp, monolithMoment } from "../../utils/date-format";
import NoteListMenu from "./Components/NoteListMenu.js";
import {
  NoteSystemProvider,
  useNoteSystem,
} from "./Contexts/NoteSystemContext.js";
import NotesSection from "./Components/NotesSection.js";
import NotesList from "./Components/NotesList.js";
import SearchList from "./Components/SearchList.js";
import DeleteNoteModal from "./Components/DeleteNoteModal.js";
import NoteSegment from "./Components/NoteSegment.js";
import TreeList from "../TreeList/TreeList.js";

import { Folder, File } from "lucide-react";
import styled from "styled-components";
import NoteEditorContainer from "./Components/NoteEditorContainer.js";
import NoteListResizer from "./Components/NoteListResizer.js";
import EmptyState from "./Components/EmptyState.js";
import EmptySegment from "./Components/EmptySegment.js";
import NoteEditor from "./Components/NoteEditor.js";
import { NoteView } from "./Enums/NoteView.js";
import { NoteItemList } from "./Components/NoteItemList.js";
import NoteTooltipContent from "./Components/NoteTooltipContent.js";

const StyledFile = styled(File)`
  color: ${(props) => props.theme.palette.primary.main};
`;

const NoteSystemWrapper = ({
  children,
  fullscreenTitle,
  headerTitle,
  notes = [],
  readOnly = false,
  onNoteDeleted,
  onNoteUpdated,
  onFolderCreated,
  onItemMove,
  onItemRename,
  search = {
    onSearch: () => {},
    onToggleSearch: () => {},
    searchInputPlaceholder: "Search Notes...",
  },
  noteMetadata = {},
  noteRenderer: NoteEditorInstance = () => null,
  templates = [],
  rootObject = {},
}) => {
  const noteSystemApi = useNoteSystem();
  const {
    tabs,
    setTabs,
    tabsRef,
    currentTab,
    setCurrentTab,
    currentTabRef,
    currentNote,
    currentNoteRef,
    setCurrentNote,
    allNotesRef,
    segments,
    noteView,
    fullscreen,
    setFullscreen,
    showSearch,
    setShowSearch,
    showToolbar,
    setShowToolbar,
    showDeleteModal,
    setShowDeleteModal,
    deleteNote,
    setDeleteNote,
    noteListRef,
    leftMenuRef,
    noteListWidthRef,
    mouseX,
    draggingItemRef,
    targetItemRef,
    targetSegementRef,
    handleCreateNote,
  } = noteSystemApi;

  allNotesRef.current = notes;

  const handleFullscreen = () => {
    setFullscreen((f) => !f);
  };

  const handleNoteClick = (note) => {
    if (note?.is_folder === 1) {
      setCurrentNote(note);
      return;
    }
    const newTab = {
      label: note.note_tag,
      note: note,
      value: note.uuid,
      state: "temp",
    };

    const isNoteInTabs = tabsRef.current.find(
      (tab) => tab.note.uuid === note.uuid
    );

    if (isNoteInTabs) {
      setCurrentTab(isNoteInTabs);
      setCurrentNote(isNoteInTabs.note);
      return;
    }

    const tempTab = tabsRef.current.findIndex((tab) => tab.state === "temp");

    if (tempTab !== -1) {
      const newTabs = tabsRef.current.filter((tab) => tab.state !== "temp");

      newTabs.push(newTab);
      setTabs(newTabs);
      setCurrentTab(newTab);
      setCurrentNote(note);
      return;
    }

    const newTabs = [...tabsRef.current];

    newTabs.push(newTab);
    setTabs(newTabs);
    setCurrentTab(newTab);
    setCurrentNote(note);
  };

  const handleNoteDblClick = (note) => {
    if (note.is_folder === 1) return;
    const newTab = {
      label: note.note_tag,
      note: note,
      value: note.uuid,
      state: "perm",
    };

    const isNoteInTabs = tabsRef.current.find(
      (tab) => tab.note.uuid === note.uuid
    );

    if (isNoteInTabs) {
      setTabs((t) => {
        const newTabs = [...t];
        const index = newTabs.findIndex((tab) => tab.value === note.uuid);
        newTabs[index] = newTab;
        return newTabs;
      });
      setCurrentTab(newTab);
      setCurrentNote(newTab.note);
      return;
    }

    const newTabs = [...tabsRef.current];

    newTabs.push(newTab);
    setTabs(newTabs);
    setCurrentTab(newTab);
  };

  const handleTabClick = (tab) => {
    setCurrentTab(tab);
    setCurrentNote(tab.note);
  };

  const handleTabDblClick = (tab) => {
    setTabs((tabs) => {
      return (
        tabs?.map((t) => {
          if (t.value === tab.value) {
            return {
              ...t,
              state: "perm",
            };
          }
          return t;
        }) || []
      );
    });
    setCurrentTab(tab);
    setCurrentNote(tab.note);
    return;
  };

  const handleCloseTab = (tab, event) => {
    event.stopPropagation();
    const newTabs = [...tabs];
    const index = newTabs.findIndex((t) => t.value === tab.value);
    newTabs.splice(index, 1);
    setTabs(newTabs);
    if (currentTab?.value === tab?.value) {
      setCurrentTab(newTabs[index - 1]);
      setCurrentNote(newTabs[index - 1]?.note || null);
      return;
    }
  };

  const handleCreateFolder = (options = {}) => {
    if (readOnly) return; // don't allow creating folders if read-only

    const uuid = shortUUID.generate();
    const createdOn = db_timestamp();

    const parentID =
      currentNoteRef?.current?.is_folder === 1
        ? currentNoteRef?.current?.uuid
        : currentNoteRef?.current?.parent_id || null;

    const segment = segments.find(
      (segment) => segment.object_id === options.object_id
    );

    const parentPath = segment?.noteMap?.[parentID]?.pathArray || [];

    const newNoteTitle = `${monolithMoment({
      timestamp: createdOn,
      includeTime: true,
    })}`;

    const newNote = {
      uuid: uuid,
      case_note_id: null,
      parent_id: parentID,
      note_tag: newNoteTitle,
      path: [...parentPath, newNoteTitle].join("/"),
      expanded: true,
      created_on: createdOn,
      updated_at: null,
      updated_by: null,
      note_data: "",
      is_folder: 1,
      object_type: options.object_type || segments[0]?.object_type || null,
      object_id: options.object_id || segments[0]?.object_id || null,
      object_name: options.object_name || segments[0]?.object_name || null,
      ...noteMetadata,
      created_by: {
        ...noteMetadata.created_by,
      },
    };

    onFolderCreated?.(newNote);
    setCurrentNote(newNote);
  };

  const handleItemDelete = ({ item }) => {
    if (readOnly) return; // don't allow deleting notes if read-only

    setShowDeleteModal(true);
    setDeleteNote(item);
  };

  const handleItemRename = ({ item, newName }) => {
    if (!newName) return;

    let newPath = "";

    if (noteView === NoteView.TREE) {
      const segment = segments.find(
        (segment) => segment.object_id === item.object_id
      );

      const parentPath = segment?.noteMap?.[item.parent_id]?.pathArray || [];

      newPath = [...parentPath, newName].join("\\");
    } else {
      newPath = item?.path?.replace(/[^\\]*$/, newName) || newName;
    }

    setTabs((tabs) => {
      return tabs?.map((t) => {
        if (t.value === item.uuid) {
          return {
            ...t,
            status: null, // set status to null to remove the "new" indicator
            label: newName,
            note: {
              ...t.note,
              note_tag: newName,
              path: newPath,
            },
          };
        }
        return t;
      });
    });

    if (newName === item.note_tag) return;

    onItemRename?.({
      item,
      newName,
      newPath,
    });
  };

  const onDelete = (note) => {
    // remove items from tabs
    setTabs((tabs) => {
      return tabs?.filter(
        (tab) =>
          tab?.note?.uuid !== note.uuid && tab?.note?.parent_id !== note.uuid
      );
    });
    onNoteDeleted?.(note);
  };

  const handleItemDrop = ({ source, target }) => {
    if (source?.uuid === target?.uuid) return;

    let newPath =
      (target?.pathArray?.join("\\") || "") + "\\" + source.note_tag;

    // remove slash from beginning of path, if it exists
    newPath = newPath.replace(/^\\/, "");

    setTabs((tabs) => {
      return tabs?.map((t) => {
        if (t.value === source.uuid) {
          return {
            ...t,
            note: {
              ...t.note,
              parent_id: target?.uuid || null,
              path: newPath,
            },
          };
        }
        return t;
      });
    });

    onItemMove?.({
      source,
      target: target || targetSegementRef.current,
      newPath,
    });
  };

  const handleKeyPress = (e) => {
    // Detect Shift + Ctrl + T
    if (e.ctrlKey && e.shiftKey && e.key === "T") {
      e.stopPropagation();
      e.preventDefault();
      setShowToolbar((t) => !t);
    }

    // Create new note
    if (e.ctrlKey && e.shiftKey && e.key === "N") {
      e.stopPropagation();
      e.preventDefault();
      handleCreateNote();
    }

    // Create new folder
    if (e.ctrlKey && e.shiftKey && e.key === "M") {
      e.stopPropagation();
      e.preventDefault();
      handleCreateFolder();
    }

    // Detect ctrl + right arrow
    if (e.ctrlKey && e.shiftKey && e.key === "ArrowRight") {
      let newTab = null;
      e.stopPropagation();
      e.preventDefault();

      const index = tabsRef.current.findIndex(
        (tab) => tab.value === currentTabRef.current.value
      );

      if (index === tabsRef.current.length - 1) {
        newTab = tabsRef.current[0];
      } else newTab = tabsRef.current[index + 1];

      handleTabClick(newTab);
    }

    // Detect ctrl + left arrow
    if (e.ctrlKey && e.shiftKey && e.key === "ArrowLeft") {
      let newTab = null;
      e.stopPropagation();
      e.preventDefault();

      const index = tabsRef.current.findIndex(
        (tab) => tab.value === currentTabRef.current.value
      );

      if (index === 0) {
        newTab = tabsRef.current[tabsRef.current.length - 1];
      } else newTab = tabsRef.current[index - 1];

      handleTabClick(newTab);
    }

    // Toggle Fullscreen
    if (e.shiftKey && e.ctrlKey && e.key === "F") {
      e.stopPropagation();
      e.preventDefault();
      handleFullscreen();
    }

    // Toggle Search
    if (e.shiftKey && e.ctrlKey && e.key === "S") {
      e.stopPropagation();
      e.preventDefault();
      setShowSearch((s) => !s);
    }
  };

  const handleResize = (e) => {
    const dx = e.clientX - mouseX.current;

    const newNoteListWidth = noteListWidthRef.current + dx;
    const newLeftMenuWidth = noteListWidthRef.current + dx;

    noteListRef.current.style.width = newNoteListWidth + "px";
    leftMenuRef.current.style.width = newLeftMenuWidth + "px";
    leftMenuRef.current.style.minWidth =
      (newLeftMenuWidth <= 320
        ? 320
        : newLeftMenuWidth >= 590
        ? 590
        : newLeftMenuWidth) + "px";
  };

  const handleOnSave = (note) => {
    const newNote = {
      ...note,
      updated_at: new Date().toISOString(),
      updated_by: noteMetadata?.created_by || null,
    };

    setTabs((tabs) => {
      return tabs?.map((t) => {
        if (t.value === note.uuid) {
          return {
            ...t,
            saved: true,
            note: newNote,
          };
        }
        return t;
      });
    });

    setTimeout(() => {
      setTabs((tabs) => {
        return tabs?.map((t) => {
          if (t.value === note.uuid) {
            return {
              ...t,
              saved: false,
            };
          }
          return t;
        });
      });
    }, 2000);

    onNoteUpdated?.(newNote);
  };

  const handleSaving = (note) => {};

  // Key press listener
  // Used for hotkey shortcuts
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [readOnly]);

  // Detect search toggle
  useEffect(() => {
    search?.onToggleSearch?.(showSearch);
  }, [showSearch]);

  return (
    <MainNoteView className="mf-note-system" data-fullscreen={fullscreen}>
      {fullscreen && (
        <FullscreenTitle>
          {fullscreenTitle ? fullscreenTitle : "Notes"}
        </FullscreenTitle>
      )}
      <TopNav className="top-nav">
        {children}
        <TabsContainer data-empty={tabs.length === 0}>
          {tabs?.map((tab) => {
            return (
              <NoteTab
                key={tab?.note?.uuid}
                tabData={tab}
                readOnly={tab?.note?.read_only}
                handleTabClick={handleTabClick}
                handleTabDblClick={handleTabDblClick}
                handleCloseTab={handleCloseTab}
              />
            );
          })}
          {tabs?.length > 0 && <TabFill />}
        </TabsContainer>
      </TopNav>
      <NotesSection className="notes-section">
        <NotesList
          ref={noteListRef}
          onClick={(e) => {
            setCurrentNote(null);
          }}
        >
          {showSearch ? (
            <SearchList
              searchResults={notes}
              onSearch={search?.onSearch}
              handleNoteClick={handleNoteClick}
              handleNoteDblClick={handleNoteDblClick}
              placeholder={search?.placeholder || "Search Notes..."}
            />
          ) : (
            <>
              {noteView === NoteView.TREE ? (
                segments.map((segment) => {
                  return (
                    <NoteSegment
                      label={
                        segment?.object_name ||
                        rootObject?.object_name ||
                        "Notes"
                      }
                      readOnly={readOnly}
                      object_name={segment?.object_name}
                      object_type={segment?.object_type}
                      object_id={segment?.object_id}
                      key={segment.object_id}
                      handleCreateNote={handleCreateNote}
                      handleCreateFolder={handleCreateFolder}
                      onDragOver={(data) => (targetSegementRef.current = data)}
                      defaultOpen={segment?.object_id === rootObject?.object_id}
                    >
                      {segment.notes.length === 0 && (
                        <EmptySegment>No Items</EmptySegment>
                      )}
                      {notes && (
                        <TreeList
                          items={segment.notes}
                          readOnly={readOnly}
                          onItemClicked={(item) => handleNoteClick(item)}
                          onItemDbClicked={(item) => handleNoteDblClick(item)}
                          onItemDrop={() =>
                            handleItemDrop({
                              source: draggingItemRef.current,
                              target: targetItemRef.current,
                            })
                          }
                          onItemRename={handleItemRename}
                          onItemDelete={handleItemDelete}
                          onItemDragging={(item) => {
                            draggingItemRef.current = item;
                          }}
                          onDragOver={(item) => (targetItemRef.current = item)}
                          nodeIcon={<Folder size={13} color="orange" />}
                          leafIcon={<StyledFile size={13} />}
                          activeItems={tabs?.map((tab) => tab.note.uuid)}
                          currentSelection={[currentNote?.uuid]}
                          tooltipComponent={NoteTooltipContent}
                        />
                      )}
                    </NoteSegment>
                  );
                })
              ) : (
                <NoteItemList
                  notes={notes}
                  headerTitle={headerTitle}
                  readOnly={readOnly}
                  handleCreateNote={handleCreateNote}
                  onItemClicked={(item) => handleNoteClick(item)}
                  onItemDbClicked={(item) => handleNoteDblClick(item)}
                  onItemRename={handleItemRename}
                  onItemDelete={handleItemDelete}
                  activeItems={tabs?.map((tab) => tab.note.uuid)}
                  currentSelection={[currentNote?.uuid]}
                  rootObject={rootObject}
                />
              )}
            </>
          )}
          <DeleteNoteModal
            details={deleteNote}
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            onDelete={onDelete}
          />
        </NotesList>
        <NoteEditorContainer>
          <NoteListResizer
            onMouseDown={(e) => {
              mouseX.current = e.clientX;
              noteListWidthRef.current = noteListRef.current?.offsetWidth;
              document.addEventListener("mousemove", handleResize, false);
              document.addEventListener("mouseup", () => {
                document.removeEventListener("mousemove", handleResize, false);
              });
            }}
          />
          {tabs?.length === 0 && (
            <EmptyState
              readOnly={readOnly}
              handleCreateNote={handleCreateNote}
              handleCreateFolder={handleCreateFolder}
              toggleToolbar={() => setShowToolbar((t) => !t)}
              toggleFullscreen={handleFullscreen}
              fullscreen={fullscreen}
              handleShowSearch={() => setShowSearch((s) => !s)}
            />
          )}
          {tabs?.map((tab) => {
            return (
              <NoteEditorInstance
                key={tab.value}
                note={tab.note}
                onNoteTitleEdit={handleItemRename}
                showToolbar={showToolbar}
                setShowToolbar={setShowToolbar}
                onSave={handleOnSave}
                onSaving={handleSaving}
                active={currentTab?.value === tab?.value}
                templates={templates}
              />
            );
          })}
        </NoteEditorContainer>
      </NotesSection>
    </MainNoteView>
  );
};

const NoteSystem = ({ ...props }) => {
  return (
    <NoteSystemProvider
      defaultTabs={props.defaultTabs}
      onTabsChange={props.onTabsChange}
      {...props}
    >
      <NoteSystemWrapper {...props} />
    </NoteSystemProvider>
  );
};

NoteSystem.NoteListMenu = NoteListMenu;
NoteSystem.NoteEditor = NoteEditor;

export default NoteSystem;
