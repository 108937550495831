import React, { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { CheckIcon } from "lucide-react";

import AuditsAPI from "@/api/Audits/Audits";
import { useBarcodeScanner } from "@/components/BarcodeScanner.js";
import AuditOverviewContent from "./AuditOverviewContent";
import { Audit } from "../../../types";
import AuditItemsTable from "./AuditItemsTable";

const StyledToggleButton = styled(ToggleButton)`
  min-width: 150px;
  min-height: 30px;
  padding: 2px 5px;
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledBadge = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  padding: 0.5px 1px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 500;

  margin-left: 5px;

  &[data-status="all"] {
  }

  &[data-status="pending"] {
    color: #f0ad4e;
  }

  &[data-status="passed"] {
    color: ${({ theme }) =>
      theme.name === "DARK" ? "#32CD32" : theme.palette.success.main};
  }

  &[data-status="failed"] {
    color: ${({ theme }) => theme.palette.error.main};
  }
`;

type Tab = "overview" | "all" | "pending" | "passed" | "failed";

interface AuditItemsProps {
  auditData: Audit;
}

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;
  gap: 10px;

  margin-top: 10px;
  overflow-y: hidden;

  .status-section {
    display: flex;
    justify-content: start;
    flex-direction: row;

    gap: 20px;
  }

  .stat-item {
    border-radius: 4px;
    padding: 18px;
    width: 175px;
    height: fit-content;
    position: relative;

    .status-value {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  .toggle-menu {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    overflow-x: auto;

    min-height: 40px;

    .scanner-status {
      display: flex;
      align-items: center;
      gap: 5px;

      font-size: 0.75rem;
      font-weight: 500;
      color: ${({ theme }) => (theme.name === "DARK" ? "limegreen" : "green")};
      cursor: default;

      &:hover {
        color: ${({ theme }) => theme.palette.text.primary};
      }
    }

    .search-input {
      visibility: hidden;

      height: 28px;

      display: flex;
      align-items: center;
      gap: 5px;

      &[data-visible="true"] {
        visibility: visible;
      }
    }
  }
`;

const AuditItems: React.FC<AuditItemsProps> = ({ auditData }) => {
  const { uuid } = useParams();
  const queryClient = useQueryClient();
  const [currentTab, setCurrentTab] = useState<Tab>("overview");
  const { scannedValue, setScannerMode } = useBarcodeScanner();

  const { data: scannedItem } = useQuery({
    queryKey: [
      "audit:items:list",
      { uuid, search: scannedValue, data_only: true },
    ],
    queryFn: () =>
      AuditsAPI.getAuditItems({ uuid, search: scannedValue, data_only: true }),
    enabled: !!scannedValue,
  });

  const handleChange = (event: any, newGroup: Tab) => {
    if (newGroup !== null) {
      setCurrentTab(newGroup);
    }
  };

  //TODO: can update this to take in the updated data and update the cache
  const handleUpdate = () => {
    queryClient.refetchQueries({ queryKey: ["audit:list", { uuid }] });
  };

  // Handle Barcode Scan
  useEffect(() => {
    if (!!scannedItem && scannedItem?.length === 1) {
      const auditItem = scannedItem[0];
      if (auditItem.status !== "passed") {
        AuditsAPI.createAuditLog({
          audit_uuid: auditData.uuid,
          object_uuid: auditItem.object_uuid,
          passed_audit: true,
          object_type: auditItem.object_type,
          notes: "Item scanned",
        });

        AuditsAPI.updateAuditItem({
          uuid: auditData.uuid,
          object_uuid: auditItem.object_uuid,
          status: "passed",
          method: "scanned",
        }).then(() => {
          handleUpdate?.();
        });
      }
    }
  }, [scannedItem, auditData?.uuid]);

  // Set Scanner Mode Back to Search and Navigate
  useEffect(() => {
    setScannerMode("scan_only");
    return () => {
      setScannerMode("search_and_navigate");
    };
  }, [setScannerMode]);

  return (
    <StyledContent>
      <div className="toggle-menu">
        <div
          className="scanner-status"
          title={`Scan audit items to automatically set their status to "Passed"\nBarcode\\QR Code must match the uuid or short-uuid of the item.`}
        >
          <CheckIcon size={16} />
          Scanner Enabled
        </div>
        <ToggleButtonGroup
          size="small"
          value={currentTab}
          onChange={handleChange}
          exclusive={true}
          style={{ margin: "auto" }}
        >
          <StyledToggleButton
            value="overview"
            size="small"
            disableRipple={true}
          >
            <span>Audit Overview</span>
          </StyledToggleButton>
          <StyledToggleButton value="all" size="small" disableRipple={true}>
            <span>All</span>
            <StyledBadge data-status="All">
              {auditData?.totals?.reduce((a, b) => a + b.total, 0)}
            </StyledBadge>
          </StyledToggleButton>
          <StyledToggleButton value="pending" size="small" disableRipple={true}>
            <span>Pending</span>
            <StyledBadge data-status="pending">
              {auditData?.totals?.find((t) => t.status === "pending")?.total ||
                0}
            </StyledBadge>
          </StyledToggleButton>
          <StyledToggleButton value="passed" size="small" disableRipple={true}>
            <span>Passed</span>
            <StyledBadge data-status="passed">
              {auditData?.totals?.find((t) => t.status === "passed")?.total ||
                0}
            </StyledBadge>
          </StyledToggleButton>
          <StyledToggleButton value="failed" size="small" disableRipple={true}>
            <span>Failed</span>
            <StyledBadge data-status="failed">
              {auditData?.totals?.find((t) => t.status === "failed")?.total ||
                0}
            </StyledBadge>
          </StyledToggleButton>
        </ToggleButtonGroup>
      </div>
      {currentTab === "overview" && auditData && (
        <AuditOverviewContent auditData={auditData} />
      )}
      {currentTab === "all" && (
        <AuditItemsTable
          auditData={auditData}
          status={currentTab}
          onUpdate={handleUpdate}
        />
      )}
      {currentTab === "pending" && (
        <AuditItemsTable
          auditData={auditData}
          status={currentTab}
          onUpdate={handleUpdate}
        />
      )}
      {currentTab === "passed" && (
        <AuditItemsTable
          auditData={auditData}
          status={currentTab}
          onUpdate={handleUpdate}
        />
      )}
      {currentTab === "failed" && (
        <AuditItemsTable
          auditData={auditData}
          status={currentTab}
          onUpdate={handleUpdate}
        />
      )}
    </StyledContent>
  );
};

export default AuditItems;
