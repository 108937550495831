import { Card, useTheme } from "@mui/material";
import { DataGrid, Column } from "devextreme-react/ui/data-grid";
import { Link } from "react-router-dom";

export const Cases = ({ cases }) => {
  return (
    <Card sx={{ height: 450 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      ></div>
      <Table cases={cases} />
    </Card>
  );
};

const Table = ({ cases }) => {
  const theme = useTheme();

  return (
    <DataGrid
      dataSource={cases}
      showRowLines={true}
      columnWidth={150}
      columnMinWidth={150}
      height={375}
      width="100%"
      columnResizingMode="widget"
      allowColumnReordering={false}
      allowColumnResizing={true}
      grouping={{ contextMenuEnabled: true }}
      paging={{ pageSize: 20 }}
      scrolling={{
        mode: "virtual",
        rowRenderingMode: "virtual",
        useNative: false,
      }}
      headerFilter={{
        visible: true,
        allowSearch: true,
      }}
      hoverStateEnabled={true}
      loadPanel={{
        enabled: false,
      }}
    >
      <Column caption="Report Count" dataField="index" alignment={"center"} />
      <Column caption="Subject Last Name" dataField="last_name" />
      <Column caption="Subject First Name" dataField="first_name" />
      <Column caption="City of Offense" dataField="city" />
      <Column caption="State of Offense" dataField="state" />
      <Column
        caption="Case Number"
        dataField="case_number"
        cellRender={(e) => {
          return (
            <>
              <Link
                style={{ color: theme.palette.primary.main }}
                onMouseEnter={(event) => {
                  event.target.style.textDecoration = "underline";
                }}
                onMouseLeave={(event) => {
                  event.target.style.textDecoration = "none";
                }}
                to={`/cases/${e.data.case_id}/overview`}
              >
                {e.data.case_number}
              </Link>
            </>
          );
        }}
      />
      <Column caption="Case Type" dataField="fpr_case_type" />
      <Column caption="Case Type Num" dataField="fpr_case_type_num" />
      <Column caption="Case Summary" dataField="case_synopsis" width="100px" />
      <Column
        caption="Exam Start Date"
        dataField="case_open_date"
        dataType={"date"}
        format={{
          type: "MM/dd/yyyy",
        }}
      />
      <Column
        caption="Exam End Date"
        dataField="case_closed_date"
        dataType={"date"}
        format={{
          type: "MM/dd/yyyy",
        }}
      />
      <Column
        caption="CPU Total Devices Examined"
        dataField="cpuTotal"
        alignment={"center"}
      />
      <Column
        caption="CPU Volume Examined"
        dataField="cpuVolume"
        alignment={"center"}
      />
      <Column caption="CPU Scale" dataField="cpuScale" alignment={"center"} />
      <Column
        caption="Etech Total Devices Examined"
        dataField="etechTotal"
        alignment={"center"}
      />
      <Column
        caption="Etech Volume Examined"
        dataField="etechVolume"
        alignment={"center"}
      />
      <Column
        caption="Etech Scale"
        dataField="etechScale"
        alignment={"center"}
      />
      <Column
        caption="Loose Drives Total Devices Examined"
        dataField="looseDrivesTotal"
        alignment={"center"}
      />
      <Column
        caption="Loose Drives Volume Examined"
        dataField="looseDrivesVolume"
        alignment={"center"}
      />
      <Column
        caption="Loose Drives Scale"
        dataField="looseDrivesScale"
        alignment={"center"}
      />
      <Column
        caption="Storage Media Total Devices Examined"
        dataField="storageMediaTotal"
        alignment={"center"}
      />
      <Column
        caption="Storage Media Volume Examined"
        dataField="storageMediaVolume"
        alignment={"center"}
      />
      <Column
        caption="Storage Media Scale"
        dataField="storageMediaScale"
        alignment={"center"}
      />
      <Column
        caption="Cloud Total Devices Examined"
        dataField="cloudTotal"
        alignment={"center"}
      />
      <Column
        caption="Cloud Volume Examined"
        dataField="cloudVolume"
        alignment={"center"}
      />
      <Column
        caption="Cloud Scale"
        dataField="cloudScale"
        alignment={"center"}
      />
      <Column caption="Other Data Analyzed" alignment={"center"} />
      <Column
        caption="Number of Other Data Analyzed"
        dataField="otherTotal"
        alignment={"center"}
      />
      <Column
        caption="Volume of Other Data Analyzed"
        dataField="otherVolume"
        alignment={"center"}
      />
      <Column
        caption="Other Data Analyzed Volume Scale"
        dataField="otherScale"
        alignment={"center"}
      />
    </DataGrid>
  );
};
