import React, { useEffect, useState } from "react";
import { LoadIndicator } from "devextreme-react/ui/load-indicator";
import { getStorageReportMetrics } from "../../../../api";
import { storageDimensions } from "./StorageReport";
import { Card } from "@mui/material";
import DefaultPieChart from "../DefaultPieChart";
import DefaultTextChart from "../DefaultTextChart";
import DropDownSelector from "../../../../components/DropDownSelector";

const views = [
  {
    text: "Charts",
    value: 0,
  },
  {
    text: "Text",
    value: 1,
  },
];

const StorageReportComposition = ({ reportInfo }) => {
  const hasTimeDimension =
    reportInfo.report_parameters &&
    reportInfo.report_parameters.timestamp_dimension;

  const [metrics, setMetrics] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentView, setCurrentView] = useState(0);
  const [timeDimension, setTimeDimension] = useState(
    storageDimensions.find((d) => {
      return (
        d.value ===
        (reportInfo?.report_parameters?.timestamp_dimension || "Date Created")
      );
    })
  );

  useEffect(() => {
    getStorageReportMetrics({
      ...reportInfo.report_parameters,
      timestamp_dimension: timeDimension.value,
      dimension: "composition",
    }).then((result) => {
      if (timeDimension.value === "Date Created") setMetrics(result.new_items);
      if (timeDimension.value === "Date Assigned")
        setMetrics(result.assigned_items);
      if (timeDimension.value === "Date Released")
        setMetrics(result.released_items);
      setIsLoading(false);
    });
  }, [timeDimension]);

  return (
    <Card
      style={{
        padding: "20px",
        marginBottom: "20px",
        height: "350px",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
            borderBottom: "1px solid #515159",
            paddingBottom: "10px",
          }}
        >
          <div
            style={{
              fontSize: "larger",
            }}
          >
            Storage Composition
          </div>
          <div style={{ display: "flex" }}>
            <DropDownSelector
              items={views}
              useSelectMode={true}
              selectedItem={currentView}
              displayExpr="text"
              variant="contained"
              onSelect={(item) => {
                setCurrentView(item.value);
              }}
            />
            {!hasTimeDimension && (
              <DropDownSelector
                style={{ marginLeft: "10px" }}
                items={storageDimensions}
                useSelectMode={true}
                selectedItem={timeDimension}
                displayExpr="text"
                variant="contained"
                onSelect={(item) => {
                  setTimeDimension(item);
                  setIsLoading(true);
                }}
              />
            )}
          </div>
        </div>
        {isLoading && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <LoadIndicator />
          </div>
        )}
        {!isLoading && currentView === 0 && (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <DefaultPieChart
              data={metrics.type_comp}
              title="Storage Types"
              valueExpr="type_name"
            />
            <DefaultPieChart
              data={metrics.brand_comp}
              title="Storage Brands"
              valueExpr="brand_name"
            />
            <DefaultPieChart
              data={metrics.size_comp}
              title="Storage Sizes"
              valueExpr="size_name"
            />
          </div>
        )}
        {!isLoading && currentView === 1 && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              userSelect: "text",
            }}
          >
            <DefaultTextChart
              data={metrics.type_comp}
              title="Storage Types"
              valueExpr="type_name"
            />
            <DefaultTextChart
              data={metrics.brand_comp}
              title="Storage Brands"
              valueExpr="brand_name"
            />
            <DefaultTextChart
              data={metrics.size_comp}
              title="Storage Sizes"
              valueExpr="size_name"
            />
          </div>
        )}
      </div>
    </Card>
  );
};

export default StorageReportComposition;
