import styled from "styled-components/macro";
import { usePermissions } from "../../../hooks/usePermissions";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { getCases } from "../../../api";
import { nanoid } from "nanoid";
import { monolithMoment } from "../../../utils/date-format";
import Loader from "../../../components/Loader";

const CaseFlyoutContent = styled(
  ({ className, defaultInfo, reloadFn = () => {}, columnDefs = [] }) => {
    const { hasPermission } = usePermissions();
    const queryClient = useQueryClient();

    const { data: itemData } = useQuery({
      queryKey: ["cases", "list", { case_id: defaultInfo.case_id }],
      queryFn: () => getCases({ case_id: defaultInfo.case_id }),
      initialData: [defaultInfo],
      select: (res) => {
        return res[0];
      },
    });

    const title = document.querySelector(".flyout-title");
    if (title) {
      title.innerText = itemData?.case_number || defaultInfo?.case_id || "";
    }

    const handleChange = (data) => {
      reloadFn();
      queryClient.setQueryData(
        ["cases", "list", { case_id: defaultInfo.case_id }],
        (oldData) => {
          const dataObj = oldData[0] || {};
          return [{ ...dataObj, ...data }];
        }
      );
    };

    const dataFieldIgnoreList = ["case_synopsis"];

    const data = {
      ...itemData,
      ...(itemData?.custom_attributes?.reduce((acc, attr) => {
        acc[`custom_field_${attr.field_id}`] = attr.value;
        return acc;
      }, {}) || {}),
    };

    const detail = (
      <div className="detail-container">
        {columnDefs
          .filter((c) => !dataFieldIgnoreList.includes(c.dataField))
          .filter((c) =>
            c.dataField.includes("custom_field_") ? !!data[c.dataField] : true
          )
          .map((c) => {
            return (
              <div className="detail-item" key={nanoid()}>
                <div className="detail-label">{c.caption}</div>
                {c.render ? (
                  c.render(data, { onChange: handleChange })
                ) : c.dataType === "date" ? (
                  <>
                    {monolithMoment({
                      timestamp: data[c.dataField],
                      includeTime: true,
                    })}
                  </>
                ) : (
                  <div className="detail-value">{data[c.dataField]}</div>
                )}
              </div>
            );
          })}
      </div>
    );

    return (
      <div className={className}>
        {!!itemData.case_id && (
          <>
            {detail}
            <div className="detail-label" style={{ marginTop: 30 }}>
              Description
            </div>
            <div style={{ position: "relative" }}>
              <div className="item-description">{itemData.case_synopsis}</div>
            </div>
          </>
        )}
        {!itemData && <Loader message={"Loading..."} />}
      </div>
    );
  }
)`
  padding: 20px;
  & {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    margin-right: 5px;
  }
  & .action-menu {
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 15px;
    margin-right: 10px;
  }
  & .action-menu-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin-right: 12px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
    & svg {
      font-size: 15px;
      margin-right: 5px;
    }
  }
  & .detail-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 10px 0px;
    font-size: 12px;
  }
  & .detail-label {
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-right: 10px;
    min-width: 150px;
  }
  & .detail-value {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  & .item-description {
    position: relative;
    resize: none;
    margin-top: 10px;
    font-size: 12px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: transparent;
    white-space: pre-wrap;
    width: 100%;
    height: fit-content;
    max-height: 30%;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 10px;
    transition: border 0.2s ease-in-out;
    outline: none;
    &:hover {
      border: 1px solid ${({ theme }) => theme.palette.divider};
    }
    &:focus {
      border: 1px solid ${({ theme }) => theme.palette.primary.main};
    }
  }
  & .item-description-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.palette.divider};
    display: flex;
    alignitems: center;
    fontsize: 12px;
    padding: 10px;
  }
`;

export default CaseFlyoutContent;
