import { useQuery } from "@tanstack/react-query";
import Chart from "react-apexcharts";
import styled, { useTheme } from "styled-components";
import MetricsAPI from "../../../../api/Metrics/Metrics.js";
import moment from "moment";

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const TopClientsWidget = styled(({ className }) => {
  const theme = useTheme();
  const { data: topClientsData } = useQuery({
    queryKey: ["metrics:topClients"],
    queryFn: () =>
      MetricsAPI.getMetrics({
        metric: "topClients",
        args: [
          timezone,
          moment().subtract(6, "months").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
          5,
        ],
      }),
  });

  if (!topClientsData) return null;

  const labels =
    topClientsData?.data?.topClients?.map(
      (client) => `${client.name} (${client.organization})`
    ) || [];

  const seriesData =
    topClientsData?.data?.topClients?.map((client) => client.total_cases) || [];

  return (
    <div className={className}>
      <Chart
        options={{
          chart: {
            animations: {
              enabled: false,
            },
            stacked: false,
            background: "unset",
            sparkline: {
              enabled: true,
            },
            toolbar: {
              show: false,
            },
            events: {
              dataPointSelection: function (event, chartContext, config) {
                console.log(event, chartContext, config);
              },
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              dataLabels: {
                position: "bottom", // top, center, bottom
              },
            },
          },
          theme: {
            mode: theme.name === "DARK" ? "dark" : "light",
          },
          fill: {
            colors: [theme.palette.success.main],
            type: "solid",
            opacity: 0.33,
          },
          stroke: {
            colors: [theme.palette.success.main + "90"],
            width: 1,
          },
          dataLabels: {
            enabled: true,
            textAnchor: "start",
            style: {
              colors: [theme.palette.text.primary],
            },
            formatter: function (val, data) {
              return `${labels[data.dataPointIndex]} (${val})`;
            },
          },
          grid: {
            show: false,
          },
          yaxis: {
            labels: {
              show: true,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          xaxis: {
            categories: labels,
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
        }}
        type="bar"
        height={"auto"}
        width={325}
        series={[
          {
            name: "Total Cases",
            data: seriesData,
          },
        ]}
      />
    </div>
  );
})``;

export default TopClientsWidget;
