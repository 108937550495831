import { Button, Divider } from "@mui/material";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import { useEffect, useRef, useState } from "react";
import { getOrgInfo, updateOrgInfo } from "../../api";
import Loader from "../../components/Loader";
import { usePermissions } from "../../hooks/usePermissions";
import { useSnackbar } from "notistack";
import styled from "styled-components";
import { Organization } from "./System";

const OrgInfoSettings = styled(({ className }) => {
  const { hasPermission } = usePermissions();
  const [orgInfo, setOrgInfo] = useState<Organization | null>(null);
  const form = useRef<any>(null);
  const logoImg = useRef<HTMLImageElement | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getOrgInfo().then((result) => {
      let arrayBufferView = new Uint8Array(result.image.data);
      let blob = new Blob([arrayBufferView]);
      let urlCreator = window.URL || window.webkitURL;
      let imageUrl = urlCreator.createObjectURL(blob);

      setOrgInfo({ ...result, image: imageUrl });
    });
  }, []);

  const handleSubmit = () => {
    if (form.current.instance.validate().isValid) {
      const formData = Object.assign(
        {},
        form.current.instance.option().formData
      );

      updateOrgInfo({
        name: formData.name,
        address: formData.address,
        city: formData.city,
        state: formData.state,
        zipcode: formData.zipcode,
        phone: formData.phone,
        email: formData.email,
        website: formData.website,
      }).then((result) => {
        //show snackbar
        enqueueSnackbar(`Org Info Updated`, {
          variant: "success",
        });
      });
    }
  };

  const handleUpdateLogo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (!file.type.includes("image")) {
        enqueueSnackbar(`File is not an image`, {
          variant: "error",
        });
        return;
      }

      // Check that file size is less than 100KB
      if (file.size > 100000) {
        enqueueSnackbar(`File is too large, must be less than 100KB`, {
          variant: "error",
        });
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (logoImg.current) {
          // Check if logoImg.current is not null
          logoImg.current.src = reader.result as string; // Cast to string
          updateOrgInfo({
            image: (reader.result as string).split(";base64,")[1],
          }).then(() => {
            enqueueSnackbar(`Org Logo Updated`, {
              variant: "success",
            });
          });
        }
      };
    }
  };

  if (!orgInfo) return <Loader />;

  return (
    <div className={className}>
      <div style={{ display: "flex", justifyContent: "center", width: 650 }}>
        <div style={{ width: "90%" }}>
          <div style={{ marginBottom: 10 }}>
            <div style={{ fontSize: "large", marginBottom: "20px" }}>
              Organization Logo
            </div>
            <div style={{ textAlign: "center" }}>
              <img ref={logoImg} src={orgInfo.image} height={100}></img>
            </div>
            <div
              style={{ display: "flex", justifyContent: "end", marginTop: 20 }}
            >
              <input
                accept="*"
                style={{ display: "none" }}
                id="update-org-logo"
                type="file"
                onChange={handleUpdateLogo}
                disabled={!hasPermission()}
                multiple
              />
              <label htmlFor="update-org-logo">
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  component="span"
                  disabled={!hasPermission()}
                >
                  Update Logo
                </Button>
              </label>
            </div>
          </div>
          <Divider style={{ margin: "20px 0px" }} />
          <div style={{ marginBottom: 10 }}>
            <div style={{ fontSize: "large", marginBottom: "20px" }}>
              Organization Details
            </div>
            <Form ref={form} defaultFormData={orgInfo} colCount={2}>
              <SimpleItem dataField="name" isRequired={true} />
              <SimpleItem dataField="address" />
              <SimpleItem dataField="city" />
              <SimpleItem dataField="state" />
              <SimpleItem dataField="zipcode" />
              <SimpleItem dataField="phone" />
              <SimpleItem dataField="email" />
              <SimpleItem dataField="website" />
            </Form>
            <div
              style={{ display: "flex", justifyContent: "end", marginTop: 20 }}
            >
              <Button
                variant="contained"
                size="small"
                onClick={handleSubmit}
                disabled={!hasPermission()}
              >
                Update Details
              </Button>
            </div>
          </div>
          <Divider style={{ margin: "20px 0px" }} />
        </div>
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
`;

export default OrgInfoSettings;
