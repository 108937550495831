import { useTheme } from "@mui/material";
import styled from "styled-components";

const ItemChip = styled(
  ({
    className,
    text,
    style = {},
    Icon,
    iconStyle = {},
    onClick = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    title = "",
  }) => {
    const handleClick = (event) => {
      onClick(event);
    };

    return (
      <div
        className={className}
        onClick={handleClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        title={title}
      >
        {Icon && <Icon />}
        <div>{text}</div>
      </div>
    );
  }
)(({ theme, style, iconStyle }) => {
  return {
    // styles go here
    padding: "0px 4px",
    fontSize: 11,
    color: theme.palette.text.secondary,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    width: "fit-content",
    minWidth: "fit-content",
    ...style,
    svg: {
      fontSize: 11,
      marginRight: 5,
      ...iconStyle,
    },
  };
});

export default ItemChip;
