import styled from "styled-components";
import {
  TraceAttributeTypes,
  TraceIndicatorDefs,
} from "../../../../../components/ColumnDefinitions";
import { Pill } from "@monolith-forensics/monolith-ui";

const DetailItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DetailLabel = styled.div`
  font-weight: 500;
  margin-right: 8px;
  min-width: 125px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const DetailValue = styled.div`
  white-space: pre-wrap;
  max-height: 150px;
  overflow-y: auto;
`;

const MainDetails = styled(({ className, defaultData }) => {
  defaultData?.trace_attributes?.sort?.((a, b) => {
    if (b.type.toUpperCase() > a.type.toUpperCase()) return -1;
    if (b.type.toUpperCase() < a.type.toUpperCase()) return 1;
    return 0;
  });
  return (
    <div className={className}>
      <h3>Indicator Details</h3>
      <div className="event-details">
        {TraceIndicatorDefs.filter(
          (col) => col.dataField !== "description"
        ).map((col) => {
          const key = col.dataField;
          const label = col.caption;
          const value = col?.render
            ? col.render(defaultData)
            : defaultData[key];

          return (
            <DetailItem key={key}>
              <DetailLabel>{label}</DetailLabel>
              <DetailValue>{value}</DetailValue>
            </DetailItem>
          );
        })}
        <DetailItem>
          <DetailLabel>Description</DetailLabel>
        </DetailItem>
        <DetailValue>{defaultData.description || "None"}</DetailValue>
        <div className="trace-attributes-list">
          {defaultData?.trace_attributes?.map?.((ta) => (
            <Pill size="xs" key={ta.uuid} showRemoveIcon={false}>
              <span className="attribute-type">
                {
                  TraceAttributeTypes.find((type) => type.value === ta.type)
                    ?.label
                }
              </span>
              <span> : </span>
              <span className="attribute-value">{ta.value}</span>
            </Pill>
          ))}
        </div>
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;

  h3 {
    margin: 0px;
    margin-bottom: 10px;
  }

  .event-details {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .trace-attributes-list {
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 10px;
  }

  .attribute-type {
    font-weight: 550;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

const FlyoutDetailsTab = styled(({ className, defaultData }) => {
  return (
    <div className={className}>
      <MainDetails defaultData={defaultData} />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;

  padding: 10px 0px;
`;

export default FlyoutDetailsTab;
