import RelayAPI from "../../../api/RelayAPI/index.js";
import { useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import { useDebouncedCallback } from "use-debounce";
import {
  Controls,
  Extensions,
  RichTextEditor,
  SlashCommands,
} from "@monolith-forensics/monolith-ui";
import { useState } from "react";
import { usePermissions } from "../../../hooks/usePermissions";

const DefaultInstructions = `
    <h3>Please use these guidelines when submitting your request:</h3>
    <p></p>
    <ol class="">
      <li class="">
        <p>
          Enter a name for your Relay request - this will help identify your
          request later.
        </p>
      </li>
      <li class="">
        <p>
          Enter as much detail as you can including your case number and
          description of your request.
        </p>
      </li>
      <li class=""><p>Attach any documents relevant to this request.</p></li>
      <li class="">
        <p>
          Add any evidence items that you want the forensics team to work on.
        </p>
      </li>
      <li class="">
        <p>
          Add any people associated with this request which may include
          subjects, witnesses, custodians, or victims.
        </p>
      </li>
      <li class="">
        <p>Submit your request once you have completed the form.</p>
      </li>
    </ol>
    <p></p>
    <p>
      Once your request is submitted your can start tracking its progress and
      using the comment system to communicate with the forensics team.
    </p>
    <p></p>
    <p>Thanks!</p>
`;

const Instructions = styled(({ className, relayInfo }) => {
  const queryClient = useQueryClient();
  const [saving, setSaving] = useState(false);

  const { currentUser, hasPermission } = usePermissions();
  const tenant = currentUser.licenseInfo.tenant;

  const handleUpdateContent = (content: string) => {
    setSaving(true);
    // Do not upload base64 data
    // Image updloads and edit will be handled by the image uploader
    if (content.includes('src="data:image/')) {
      const parseHTML = new DOMParser().parseFromString(content, "text/html");

      const images = parseHTML.querySelectorAll(
        ".monolith-image"
      ) as NodeListOf<HTMLImageElement>;

      // clear base64 data
      images.forEach((img) => {
        img.src = "";
        img.classList.add("monolith-image");
      });

      // convert to string
      content = parseHTML.body.innerHTML;
    }

    RelayAPI.updateRelayInstructions({
      html: content,
      tenant_uuid: relayInfo.tenant_uuid,
    }).then(() => {
      setSaving(false);
    });

    queryClient.setQueryData(
      [
        "relayInfo",
        {
          cloud_tenant_id: tenant,
          include_logo: true,
          include_instructions: true,
        },
      ],
      ([old]) => {
        return [
          {
            ...old,
            tenant_instructions: content,
          },
        ];
      }
    );
  };

  const debouncedUpdate = useDebouncedCallback(handleUpdateContent, 400);

  return (
    <div className={className}>
      <div style={{ marginBottom: 15 }}>
        These are a set of instructions that will be shown to the requestor in
        Relay before the make any requests.
      </div>
      <div className="inner-content">
        <RichTextEditor
          autoFocus={true}
          saving={saving}
          showToolbar={true}
          defaultValue={relayInfo.tenant_instructions || DefaultInstructions}
          onChange={debouncedUpdate}
          readOnly={!hasPermission()}
          extensions={[
            Extensions.Placeholder,
            Extensions.SlashCommand,
            Extensions.BubbleMenu,
          ]}
          slashCommands={Object.values(SlashCommands).filter(
            (c) => c !== SlashCommands.Image
          )}
          toolbarOptions={{
            controls: Object.values(Controls).filter(
              (c) => c !== Controls.FONT
            ),
          }}
        />
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;

  .inner-content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 0px;

    border: 1px solid ${({ theme }) => theme.palette.input.border};
    border-radius: 4px;
    padding-top: 10px;
    padding-bottom: 10px;

    .editor-content {
      max-width: 1000px;
    }
  }
`;

export default Instructions;
