import { Divider, styled, useTheme } from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { useState } from "react";
import Loader from "../components/Loader.js";
import { useEffect } from "react";
import CaseFileAPI from "../api/CaseFiles/index.js";
import { useCallback } from "react";
import ProgressBar from "../components/ProgressBar/ProgressBar.js";
import { createContext } from "react";
import { useContext } from "react";
import { useSnackbar } from "notistack";
import usePlatform from "../hooks/usePlatform.js";
import Uploader from "../components/Uploader/Uploader.js";

const FileUploaderContext = createContext();

export const FileUploaderProvider = ({ children }) => {
  const [files, setFiles] = useState([]);
  const [uploadQueue, setUploadQueue] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const { isOnPremises } = usePlatform();

  const addFiles = useCallback(
    (data = {}) => {
      data.files.forEach((f) => (f.status = "pending"));
      setFiles([...files, ...data.files]);
      setUploadQueue([...uploadQueue, ...data.files]);
      document.dispatchEvent(
        new CustomEvent("file-uploader:files-added", { detail: data })
      );
    },
    [files, uploadQueue]
  );

  const clearFiles = useCallback(() => {
    setFiles([]);
    setUploadQueue([]);
  }, []);

  const handleUploadProgress = (file, progress) => {
    document.dispatchEvent(
      new CustomEvent("file-uploader:file-upload-progress", {
        detail: {
          file,
          ...progress,
        },
      })
    );
  };

  const uploadFile = useCallback(async (file) => {
    // Upload files

    return new Promise((resolve, reject) => {
      // If on premises, use the old upload method
      // The file is uploaded and hashed on the server, file entry is also created on the server
      if (isOnPremises) {
        const formData = new FormData();
        formData.append("parent_folder_id", file.parent_folder_id);
        formData.append("case_id", file.case_id);
        formData.append("uuid", file.uuid);
        formData.append(
          "client_modified",
          new Date(file.lastModified).toISOString()
        );
        formData.append("file_name", file.name);
        formData.append("file_size", parseInt(file.size));
        formData.append("file_ext", file.name.split(".").pop());
        formData.append("path", file.path || "");
        formData.append("original_path", file.original_path || "");
        formData.append("file", file);

        CaseFileAPI.upload(formData, {
          onUploadProgress: (progress) =>
            handleUploadProgress(file, { progress }),
        }).then((uploadResult) => {
          resolve({
            ...file,
            name: file.name,
            ...uploadResult,
          });
        });
      }

      // For cloud based users, use the multi-part upload method
      // Hashes are calculated on the client, file entry is created on the client as well once the upload is complete
      // The file is uploaded to the server in chunks
      // This prevents the server from having to handle large files, and uploads the file data directly to file storage server using signed URLs
      else {
        const uploader = new Uploader({
          file: file,
          fileName: file.name,
          filePath: file.monolith_path,
          onUploadProgress: (progress) => handleUploadProgress(file, progress),
          onComplete: async (res) => {
            await CaseFileAPI.create({
              case_id: file.case_id,
              parent_folder_id: file.parent_folder_id,
              uuid: file.uuid,
              client_modified: new Date(file.lastModified).toISOString(),
              file_name: file.name,
              file_size: parseInt(file.size),
              file_ext: file.name.split(".").pop(),
              path: file.path || "",
              md5: res.md5,
              sha1: res.sha1,
              sha256: res.sha256,
              is_folder: 0,
            });
            resolve({
              ...file,
              ...res,
              file_name: file.name,
            });
          },
        });
        uploader.start();
      }
    });
  }, []);

  useEffect(() => {
    if (uploadingFile) return;
    if (uploadQueue.length === 0) {
      setUploadingFile(false);
      return;
    } else {
      setUploadingFile(true);
    }

    setCurrentFile(uploadQueue[0]);
    // Upload file from queue
    uploadFile(uploadQueue[0]).then((res) => {
      setUploadQueue((prev) => prev.slice(1));
      setUploadingFile(false);
      document.dispatchEvent(
        new CustomEvent("file-uploader:file-uploaded", { detail: res })
      );
    });
  }, [uploadQueue, uploadFile, uploadingFile]);

  useEffect(() => {
    if (uploadQueue.length === 0) {
      setUploading(false);
      return;
    } else {
      setUploading(true);
    }
  }, [uploadQueue]);

  return (
    <FileUploaderContext.Provider
      value={{
        addFiles,
        clearFiles,
        files,
        uploadQueue,
        uploading,
        currentFile,
      }}
    >
      {children}
    </FileUploaderContext.Provider>
  );
};

export const useFileUploader = () => {
  const context = useContext(FileUploaderContext);
  if (context === undefined) {
    throw new Error(
      "useFileUploader must be used within a FileUploaderProvider"
    );
  }
  return context;
};

const FileUploader = styled(({ className }) => {
  const { files, uploading, clearFiles } = useFileUploader();
  const [open, setOpen] = useState(true);
  const [visible, setVisible] = useState(uploading);

  const handleMinimize = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (uploading) {
      setVisible(true);
    }
  }, [uploading]);

  if (!visible) return null;

  return (
    <div className={`${className} file-uploader ${open ? "" : "closed"}`}>
      <Header
        onMinClick={handleMinimize}
        open={open}
        toggleVisibilty={() => {
          setVisible(!visible);
          clearFiles();
        }}
      />
      <Divider />
      <div className="content">
        {files.map((file) => {
          return <UploadItem key={file.uuid} file={file} />;
        })}
      </div>
    </div>
  );
})`
  &.file-uploader.closed {
    height: 45px;
    overflow: hidden;
  }
  &.file-uploader {
    position: absolute;
    bottom: 10px;
    right: 0;
    margin: 20px 20px 0 20px;
    border-radius: 5px;
    width: 350px;
    max-height: 350px;
    background-color: ${({ theme }) => theme.palette.background.alt};
    border: 1px solid ${({ theme }) => theme.palette.divider};
    z-index: 2000;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    font-size: 10px;
  }
  &.file-uploader .content {
    max-height: calc(350px - 50px);
    overflow-y: auto;
    padding: 5px;
  }
`;

const Header = styled(
  ({ className, onMinClick = () => {}, open, toggleVisibilty }) => {
    const { uploading, files, uploadQueue } = useFileUploader();

    return (
      <div className={className + " header"}>
        <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
          <div>File Uploads</div>
          {!open && (
            <ExpandMoreOutlinedIcon
              onClick={onMinClick}
              style={{ marginLeft: 5, cursor: "pointer" }}
            />
          )}
          {open && (
            <KeyboardArrowUpOutlinedIcon
              onClick={onMinClick}
              style={{ marginLeft: 5, cursor: "pointer" }}
            />
          )}
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            {files.length > 0 && (
              <div style={{ marginRight: 10 }}>
                {files.length - uploadQueue.length} of {files.length}
              </div>
            )}
            {uploading && <Loader style={{ width: 18, height: 18 }} />}
            {!uploading && (
              <CloseOutlinedIcon
                onClick={toggleVisibilty}
                style={{ fontSize: 18, cursor: "pointer" }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
)`
  &.header {
    width: 100%;
    height: 45px;
    padding: 10px;
    background-color: ${({ theme }) => theme.palette.background.default};
    color: ${({ theme }) => theme.palette.text.primary};
    display: flex;
    align-items: center;
    font-size: 12px;
  }
`;

const UploadItem = styled(({ className, file }) => {
  const theme = useTheme();
  const [status, setStatus] = useState(file.status);
  const [uploadPercent, setUploadPercent] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const onUploadProgress = ({ detail }) => {
    if (file.uuid === detail.file.uuid) {
      // Calculate progress event percent
      const percent = Math.round(
        (detail.progress.loaded * 100) / detail.progress.total
      );
      setUploadPercent(percent);
      if (status !== "uploading") setStatus("uploading");
    }
  };

  const onFileUploaded = ({ detail }) => {
    if (file.uuid === detail.uuid) {
      if (detail.success === false) {
        enqueueSnackbar(detail.message, {
          variant: "error",
        });
        setStatus("failed");
      } else setStatus("complete");
    }
  };

  useEffect(() => {
    document.addEventListener(
      "file-uploader:file-upload-progress",
      onUploadProgress
    );

    document.addEventListener("file-uploader:file-uploaded", onFileUploaded);

    return () => {
      document.removeEventListener(
        "file-uploader:file-upload-progress",
        onUploadProgress
      );

      document.removeEventListener(
        "file-uploader:file-uploaded",
        onFileUploaded
      );
    };
  }, [file]);

  return (
    <div className={className + " upload-item"}>
      <div className="content">
        <div className="file-name" title={file.name}>
          {file.name}
        </div>
        <div
          style={{ marginLeft: "auto", display: "flex", overflow: "hidden" }}
        >
          {status === "uploading" && (
            <Loader style={{ width: 16, height: 16 }} />
          )}
          {status === "complete" && (
            <CheckOutlinedIcon
              style={{ color: theme.palette.success.main, fontSize: 16 }}
            />
          )}
          {status === "failed" && (
            <CloseOutlinedIcon
              style={{ color: theme.palette.error.main, fontSize: 16 }}
            />
          )}
        </div>
      </div>
      {status === "uploading" && (
        <div style={{ width: "80%" }}>
          <ProgressBar percent={uploadPercent} height={3} />
        </div>
      )}
    </div>
  );
})`
  & .content {
    overflow: hidden;
  }
  &.upload-item {
    width: 100%;
    height: 40px;
    padding: 5px;
    border-radius: 5px;
    overflow-y: hidden;
    color: ${({ theme }) => theme.palette.text.primary};
    display: flex;
    flex-direction: column;
    .content {
      display: flex;
      align-items: center;
      align-content: center;
      padding: 5px;
      border-radius: 3px;
      overflow: hidden;
    }
    .file-name {
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
    }
  }
  &.upload-item:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.action.hover};
  }
`;

export default FileUploader;
