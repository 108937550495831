/* eslint-disable linebreak-style */

import axios from "axios";
import { AuthService } from "./auth";

const MAX_RETRIES = 0;
const RETRY_DELAY = 2000;

// set axios credetials to true
axios.defaults.withCredentials = true;

axios.interceptors.response.use(undefined, (err) => {
  const { config, response } = err;
  const retryCount = config.retryCount || 0;

  // If the response status is 5xx, retry the request
  if (response && response.status >= 500 && retryCount < MAX_RETRIES) {
    config.retryCount = retryCount + 1;

    return new Promise((resolve) =>
      setTimeout(() => resolve(axios(config)), RETRY_DELAY)
    );
  }

  throw err;
});

let URL = null;

let WORKSPACE = null;

export function getRelayDomain() {
  const isElectron = process.env.REACT_APP_PLATFORM === "electron";
  const isOnPremises =
    window.localStorage.getItem("API_MODE") === "on-premises" ||
    process.env.REACT_APP_API_MODE === "on-premises";

  if (isElectron && isOnPremises) {
    return (
      window.localStorage.getItem("API_HOST").replace("/api", "") +
      "/forensic-requests"
    );
  }
  if (isElectron) {
    const savedDomain = window.localStorage.getItem("relay-domain");
    if (savedDomain) return savedDomain;
    return "https://relay-app.monolithforensics.com";
  }

  if (isOnPremises) return `${window.origin}/forensic-requests`;

  return window.origin.replace("monolith-app", "relay-app");
}

export function getMonolithDomain() {
  const isElectron = process.env.REACT_APP_PLATFORM === "electron";
  const isOnPremises =
    window.localStorage.getItem("API_MODE") === "on-premises" ||
    process.env.REACT_APP_API_MODE === "on-premises";

  if (isElectron && isOnPremises) {
    return window.localStorage.getItem("API_HOST").replace("/api", "");
  }
  if (isElectron) {
    //check if dev mode
    if (process.env.NODE_ENV === "development")
      return "https://dev.monolithforensics.com";
    const savedDomain = window.localStorage.getItem("monolith-domain");
    if (savedDomain) return savedDomain;
    return "https://monolith-app.monolithforensics.com";
  }

  return `${window.origin}`;
}

export async function setWorkspace(workspace) {
  WORKSPACE = workspace;
  axios.defaults.headers.common["x-tenant-slug"] = workspace;
}

export function getCurrentWorkspace() {
  return WORKSPACE;
}

export async function getBaseURL() {
  if (!URL) await initBaseURL();
  return URL.replace("http://", "https://");
}

export async function initBaseURL() {
  if (process.env.NODE_ENV === "development") {
    axios.defaults.headers.common["x-monolith-api-host"] = window.origin;
    return ``;
  }

  // If electron
  // // if on-premises - return API host in local storage
  // // if cloud - return standard API endpoint
  if (process.env.REACT_APP_PLATFORM === "electron") {
    const result =
      (await window.electronAPI.getSettings("API_MODE")) === "on-premises"
        ? (await window.electronAPI.getSettings("API_HOST")).replace(
            "http://",
            "https://"
          )
        : getMonolithDomain() + "/api";

    axios.defaults.headers.common["x-monolith-api-host"] = result;
    return result;
  }

  // If browser
  // return public URL/api
  else {
    axios.defaults.headers.common["x-monolith-origin"] = window.origin;
    axios.defaults.headers.common["x-monolith-api-host"] =
      window.origin + "/api";
    return `/api`;
  }
}

initBaseURL().then((result) => {
  axios.defaults.headers.common["x-monolith-origin"] = window.origin;
  URL = result.replace("http://", "https://");
});

export async function httpGet(api, params = {}, options = {}) {
  return axios
    .get(`${options.baseUrl || URL || (await getBaseURL())}${api}`, {
      params,
    })
    .then(handleRepsonse)
    .catch(handleError);
}

export function httpGetURL(api, params = {}, options = {}) {
  // Create Get URL with query params
  let url = `${options.baseUrl || URL}${api}`;

  if (params) {
    url += "?";
    Object.keys(params).forEach((key) => {
      url += `${key}=${params[key]}&`;
    });
  }

  return url;
}

export async function httpPost(api, body, options = {}) {
  const reqOptions = {
    method: "POST",
    url: `${URL || (await getBaseURL())}${api}`,
    headers: {
      "content-type": "application/json",
    },
    data: JSON.stringify(body),
    onUploadProgress: options.onUploadProgress,
  };

  return axios(reqOptions).then(handleRepsonse).catch(handleError);
}

export async function httpPostFormData(api, formData, options = {}) {
  const reqOptions = {
    method: "POST",
    url: `${URL || (await getBaseURL())}${api}`,
    headers: {
      "content-type": "multipart/form-data",
    },
    data: formData,
    onUploadProgress: options.onUploadProgress,
  };

  return axios(reqOptions)
    .then(handleRepsonse)
    .catch((res) => {
      if (res.response.status === 401) {
        window.location.reload(true);
      }
      return {
        status: res.response.status,
        success: false,
        message: res.response.data.message,
        formData: formData,
      };
    });
}

export async function httpPatch(api, body) {
  const options = {
    method: "PATCH",
    url: `${URL || (await getBaseURL())}${api}`,
    headers: {
      "content-type": "application/json",
    },
    data: JSON.stringify(body),
  };

  return axios(options).then(handleRepsonse).catch(handleError);
}

export async function httpDelete(api, params) {
  return axios
    .delete(`${URL || (await getBaseURL())}${api}`, {
      params,
    })
    .then(handleRepsonse)
    .catch(handleError);
}

export async function httpFileDownload(url, options = {}) {
  return axios
    .get(url, { responseType: "blob" })
    .then(handleRepsonse)
    .catch(handleError);
}

const preventLogoutWhiteList = ["/check-session"];

const handleRepsonse = (response) => {
  if (response.status === 401) {
    console.log("401 unauthorized");
    // auto logout if 401 response returned from api
    AuthService.userLogout();
    window.location.reload(true);
  }

  return response.data;
};

const handleError = (error) => {
  console.error(error.config);

  if (
    error?.response?.status === 401 &&
    !preventLogoutWhiteList.includes(
      error.config.url.replace(error.config.headers["x-monolith-origin"], "")
    )
  ) {
    console.log("401 unauthorized");
    window.location.reload(true);
  }

  return { success: false, error };
};
