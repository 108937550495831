import { styled } from "@mui/material";

const ProgressBar = styled(({ className, percent }) => {
  return (
    <div className={className}>
      <div className="progress-bar">
        <div
          className="progress-bar-fill"
          style={{ width: `${percent}%` }}
        ></div>
      </div>
    </div>
  );
})`
  border-radius: 5px;
  width: 100%;
  & .progress-bar {
    height: ${({ height }) => height || 5}px;
    width: 100%;
    background-color: ${({ theme }) => theme.palette.divider};
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
  }
  & .progress-bar-fill {
    height: 100%;
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 600;
  }
`;

export default ProgressBar;
