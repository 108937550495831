import styled from "styled-components";
import { getDateFormat } from "../../utils/date-format";
import { Link } from "react-router-dom";
import { ArrowRightIcon, ArrowRightSquareIcon } from "lucide-react";
import { formatCurrency } from "../../utils/currency-formatter";

const CaseNumberCell = styled(({ className, rowData }) => {
  if (!rowData.case_id) {
    return null;
  }

  return (
    <div className={className}>
      <Link to={`/cases/${rowData.case_id}/overview`}>
        <div className="casenum-container">
          {rowData.case_number}
          <ArrowRightIcon size={14} />
        </div>
      </Link>
    </div>
  );
})`
  width: fit-content;
  .casenum-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    font-weight: 500;
    padding: 2px 5px;
    background-color: ${(props) => props.theme.palette.primary.main}25;
    border-radius: 5px;
    color: ${(props) => props.theme.palette.primary.main};

    svg {
      display: none;
    }

    &:hover {
      text-decoration: underline;
      cursor: pointer;

      svg {
        display: block;
      }
    }
  }
`;

const TimeEntriesColumnDefs = [
  {
    dataField: "task_name",
    caption: "Task",
  },
  {
    dataField: "task_notes",
    caption: "Task Description",
    visible: false,
  },
  {
    dataField: "category_name",
    caption: "Category",
  },
  {
    dataField: "description",
    caption: "Entry Description",
  },
  {
    dataField: "full_name",
    caption: "User",
  },
  {
    dataField: "duration",
    caption: "Duration (hrs)",
    render: (data) => {
      return (data.duration / (60 * 60)).toFixed(2);
    },
  },
  {
    dataField: "entry_date",
    caption: "Date Entered",
    dataType: "date",
    format: {
      type: getDateFormat({ isMoment: true, includeTime: false }),
    },
    visible: false,
  },
  {
    dataField: "start_time",
    caption: "Start",
    dataType: "date",
    format: {
      type: getDateFormat({ isMoment: true, includeTime: true }),
    },
    visible: false,
  },
  {
    dataField: "end_time",
    caption: "End",
    dataType: "date",
    format: {
      type: getDateFormat({ isMoment: true, includeTime: true }),
    },
    visible: false,
  },
  {
    dataField: "price",
    caption: "Price",
    visible: false,
    render: (data) => formatCurrency(data.price),
  },
  {
    dataField: "billed",
    caption: "Invoiced",
    render: (data) => {
      return data.billed === 1 ? "Yes" : "No";
    },
    visible: false,
  },
  {
    dataField: "invoice_date",
    caption: "Invoice Date",
    dataType: "date",
    visible: false,
    format: {
      type: getDateFormat({ isMoment: true, includeTime: false }),
    },
  },
  {
    dataField: "case_number",
    caption: "Case Number",
    visible: false,
    render: (data) => {
      return <CaseNumberCell rowData={data} />;
    },
  },
  {
    dataField: "case_ref",
    caption: "Case Name",
    visible: false,
  },
];

export default TimeEntriesColumnDefs;
