import { useEffect, useRef, useState } from "react";

// DevExtreme Form components
import { Form, EmptyItem, SimpleItem } from "devextreme-react/ui/form";

// Custom Modal component
import Modal from "../../../../Monolith-UI/Modal/Modal";
import { CUSTOM_FIELD_CATEGORIES } from "../utils/customFieldConstants";
import CasesApi from "../../../../api/cases";
import { useQuery } from "@tanstack/react-query";
import shortUUID from "short-uuid";
import { CustomField, GetTypes } from "../types";
import ErrorComponent from "../../../../Monolith-UI/Error";

interface EditCustomFieldProps {
  handleOpen: boolean;
  customFields: CustomField[];
  setCustomFields: React.Dispatch<React.SetStateAction<null | CustomField[]>>;
  handleUpdateField: (
    id: number,
    values: Partial<CustomField>
  ) => Promise<void>;
  getTypes: null | (() => Promise<GetTypes>);
  category: string;
  title: string;
  editSelection: CustomField;
  handleClose: () => void;
}

const EditCustomField = ({
  handleOpen = false,
  customFields,
  setCustomFields,
  handleUpdateField,
  getTypes = null,
  category = "",
  title = "Edit Custom Field",
  editSelection,
  handleClose = () => {},
}: EditCustomFieldProps) => {
  const form = useRef<any>();
  const [showSelections, setShowSelections] = useState<boolean>(false);
  const [associatedTypes, setAssociatedTypes] = useState<GetTypes | []>([]);
  const [errors, setErrors] = useState<{ uuid: string; error: string }[] | []>(
    []
  );

  const { data: caseCustomFields } = useQuery({
    queryKey: ["custom-fields", "case"],
    queryFn: () => CasesApi.getCaseCustomAttributes({ fieldsOnly: true }),
    enabled: category === CUSTOM_FIELD_CATEGORIES.INQUIRIES,
  });

  useEffect(() => {
    if (getTypes) {
      getTypes().then((result) => {
        setAssociatedTypes(result);
      });
    }
    if (
      editSelection?.editor_type === "tagBox" ||
      editSelection?.editor_type === "dropDown"
    ) {
      setShowSelections(true);
    } else {
      setShowSelections(false);
    }
  }, [handleOpen]);

  // When modal  is open, check editor type to show selections options if needed

  const handleSubmit = async () => {
    if (form.current.instance.validate().isValid) {
      const values = Object.assign(
        {},
        form.current.instance.option("formData")
      );

      if (values?.type_ids?.length === 0) {
        values.type_ids = null;
      }

      if (values?.options?.length === 0) {
        values.options = null;
      }

      try {
        await handleUpdateField(values.field_id, {
          field_name: values.field_name,
          is_required: values.is_required,
          editor_type: values.editor_type,
          options: values.options,
          description: values.description,
          type_ids: values.type_ids,
          linked_id: values.linked_id,
        });
        setCustomFields((prev) => {
          if (!prev) {
            return null;
          }
          return prev.map((field: CustomField) => {
            if (field.field_id === editSelection.field_id) {
              field.field_name = values.field_name;
              field.is_required = values.is_required;
              field.editor_type = values.editor_type;
              field.options = values.options || null;
              field.description = values.description;
              field.type_ids = values.type_ids;
              field.linked_id = values.linked_id;
            }
            return field;
          });
        });
      } catch (e) {
        setErrors((prevErrors) => [
          ...prevErrors,
          {
            uuid: shortUUID.generate(),
            error: `${e}`,
          },
        ]);
      }
      setShowSelections(false);
      handleClose();
    }
  };

  // Clear dropdown and tagbox selection box onCancel
  const onCancel = () => {
    setShowSelections(false);
    handleClose();
  };

  const handleErrorClose = (uuid: string) => {
    const updatedErrors = errors.filter((error) => error.uuid !== uuid);
    setErrors(updatedErrors);
  };

  return (
    <Modal
      open={handleOpen}
      onCancel={onCancel}
      title={title}
      submitText="Edit"
      width={"550px"}
      style={{ marginTop: "150px" }}
      onSubmit={handleSubmit}
    >
      {errors &&
        errors.map((error) => {
          return (
            <ErrorComponent
              key={error.uuid}
              error={error.error}
              onClose={() => handleErrorClose(error.uuid)}
            />
          );
        })}
      <Form
        ref={form}
        style={{ marginBottom: "20px" }}
        formData={editSelection}
        colCount={2}
      >
        <SimpleItem
          dataField="field_name"
          label={{ text: "Field Name" }}
          isRequired={true}
          editorOptions={{
            placeholder: "Name this field...",
          }}
        ></SimpleItem>
        <SimpleItem
          dataField="is_required"
          label={{ text: "Is Required" }}
          isRequired={true}
          editorType="dxSelectBox"
          editorOptions={{
            items: [
              { value: 1, text: "Yes" },
              { value: 0, text: "No" },
            ],
            displayExpr: "text",
            valueExpr: "value",
          }}
        ></SimpleItem>
        <SimpleItem
          dataField="editor_type"
          label={{ text: "Editor Type" }}
          isRequired={true}
          editorType="dxSelectBox"
          editorOptions={{
            items: [
              { value: "textBox", text: "Text Box" },
              { value: "dateBox", text: "Date" },
              { value: "dropDown", text: "Drop Down Menu" },
              { value: "tagBox", text: "Tag Box" },
            ],
            displayExpr: "text",
            valueExpr: "value",
            onValueChanged: (e: any) => {
              if (["dropDown", "tagBox"].includes(e.value)) {
                setShowSelections(true);
              } else if (showSelections) {
                setShowSelections(false);
              }
            },
          }}
        ></SimpleItem>
        {category === CUSTOM_FIELD_CATEGORIES.INQUIRIES ? (
          <SimpleItem
            dataField="linked_id"
            label={{ text: "Case Field Mapping" }}
            isRequired={false}
            editorType="dxSelectBox"
            editorOptions={{
              items: [
                { value: null, text: "None" },
                ...(caseCustomFields
                  ?.filter((field: CustomField) => {
                    if (
                      editSelection?.editor_type === "dropDown" ||
                      editSelection?.editor_type === "textBox"
                    ) {
                      return (
                        field.editor_type === "dropDown" ||
                        field.editor_type === "textBox"
                      );
                    } else {
                      return field.editor_type === editSelection?.editor_type;
                    }
                  })
                  .map((field: any) => ({
                    value: field.field_id,
                    text: field.field_name,
                  })) || []),
              ],
              displayExpr: "text",
              valueExpr: "value",
            }}
          ></SimpleItem>
        ) : (
          <EmptyItem />
        )}
        {getTypes ? (
          <SimpleItem
            dataField="type_ids"
            label={{ text: "Associated Types" }}
            editorType="dxTagBox"
            editorOptions={{
              items: associatedTypes,
              displayExpr: "evi_type",
              valueExpr: "evi_type_id",
              multiline: false,
              maxDisplayedTags: 2,
              showDropDownButton: true,
              showSelectionControls: true,
            }}
          />
        ) : (
          <EmptyItem />
        )}
        <EmptyItem />
        <SimpleItem
          dataField="options"
          label={{ text: "Selection Options" }}
          colSpan={2}
          isRequired={true}
          visible={showSelections}
          editorType="dxTagBox"
          editorOptions={{
            placeholder: "Enter Menu Items...",
            acceptCustomValue: true,
            dropDownOptions: {
              visible: false,
            },
            onCustomItemCreating: (e: any) => {
              const newValue = e.text;
              const currentItems = e.component.option("items");
              currentItems.unshift(newValue);
              e.component.option("items", currentItems);
              e.customItem = newValue;
            },
            multiline: false,
            showDropDownButton: false,
            openOnFieldClick: false,
          }}
        ></SimpleItem>
        <SimpleItem
          dataField="description"
          label={{ text: "Description" }}
          editorType="dxTextArea"
          colSpan={2}
        ></SimpleItem>
      </Form>
    </Modal>
  );
};

export default EditCustomField;
