import { getRecentCases } from "../../../../api/cases/recentCases";
import Loader from "../../../../components/Loader";
import { useAuth } from "../../../../contexts/AuthContext";
import moment from "moment";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { nanoid } from "nanoid";

const MyRecentCases = styled(({ className }) => {
  const { currentUser } = useAuth();

  const { data } = useQuery({
    queryKey: ["recentCases", { user_id: currentUser.user_id }],
    queryFn: () => getRecentCases({ user_id: currentUser.user_id }),
  });

  if (!data) return <Loader />;

  if (data.length === 0)
    return <div style={{ textAlign: "center" }}>No Recent Cases</div>;

  return (
    <div className={className}>
      <div className="recent-cases-container">
        <div className="recent-cases-grid">
          {data.map((recent) => (
            <RecentItem key={nanoid()} recent={recent} />
          ))}
        </div>
      </div>
    </div>
  );
})`
  height: calc(100% - 50px);
  overflow-y: auto;
  display: flex;

  .recent-cases-container {
    width: 100%;
    padding: 0px 10px;
    margin: 0px auto;
  }
  .recent-cases-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
    grid-gap: 15px;
  }
`;

const RecentItem = styled(({ className, recent }) => {
  return (
    <Link to={`/cases/${recent.case_id}/overview`}>
      <div className={className} title="View Case">
        <div className="case-name">{recent.case_ref}</div>
        <div className="case-number">{recent.case_number}</div>
        <div className="case-status">{recent.case_status}</div>
        <div className="date-accessed">
          {moment(recent.accessed_on).fromNow()}
        </div>
      </div>
    </Link>
  );
})`
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.palette.divider};
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  font-size: 10px;
  width: 100%;
  cursor: pointer;
  &:hover {
    border: 1px solid ${(props) => props.theme.palette.primary.main};
  }
  .case-name {
    font-size: 14px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .case-number {
    font-size: 10px;
    color: ${(props) => props.theme.palette.text.secondary};
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .case-status {
    font-size: 12px;
    color: ${(props) => props.theme.palette.primary.main};
    margin-top: 15px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .date-accessed {
    font-size: 10px;
    color: ${(props) => props.theme.palette.text.secondary};
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
`;

export default MyRecentCases;
