import { getDateFormat, monolithMoment } from "../../utils/date-format";

const CustomFieldCell = ({ rowData, field }) => {
  if (rowData.custom_attributes && rowData.custom_attributes.length > 0) {
    let data = rowData.custom_attributes.find((item) => {
      return item.field_id === field.field_id;
    });

    if (data) {
      if (field.editor_type === "dateBox")
        return (
          <>{monolithMoment({ timestamp: data.value, includeTime: false })}</>
        );
      if (data.value && field.editor_type === "tagBox") {
        const value = JSON.parse(data.value).join(", ");
        return <div title={value}>{value}</div>;
      }
      return <>{data.value}</>;
    }
  }

  return <></>;
};

export default CustomFieldCell;

export const addCustomFieldsToColumnDefs = (customFields, columnState) => {
  const newColumns = columnState.filter(
    (cd) => !cd.dataField.includes("custom_field_")
  );

  const customColMap = new Map();
  for (const col of columnState.filter((cd) =>
    cd.dataField.includes("custom_field_")
  )) {
    customColMap.set(col.dataField, col);
  }

  const customColumns = customFields.map((field) => ({
    dataField: `custom_field_${field.field_id}`,
    caption: field.field_name,
    dataType: field.editor_type === "dateBox" ? "date" : "string",
    format: {
      type: getDateFormat({ isMoment: true, includeTime: false }),
    },
    render: (rowData) => {
      return <CustomFieldCell rowData={rowData} field={field} />;
    },
    visible:
      customColMap.get(`custom_field_${field.field_id}`)?.visible || false,
    sorting:
      customColMap.get(`custom_field_${field.field_id}`)?.sorting || null,
    width: customColMap.get(`custom_field_${field.field_id}`)?.width || null,
  }));

  customColMap.clear(); // clear the map

  return newColumns.concat(customColumns);
};
