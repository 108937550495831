import moment from "moment";
import { useEffect, useRef, useState } from "react";
import QualityAssuranceAPI from "../../../../api/quality_assurance/index.js";
import { usePermissions } from "../../../../hooks/usePermissions";
import QAStatusSelections from "./QAStatusSelections.js";
import DropdownMenu from "../../../../Monolith-UI/DropdownMenu/DropdownMenu.js";

const QaStatusSelector = ({
  rowData,
  onChange = () => {},
  showDropdownIcon = false,
  variant = "contained",
}) => {
  const [current, setCurrent] = useState(
    QAStatusSelections?.find((p) => p.label === rowData?.status)
  );
  const { hasPermission } = usePermissions();
  const containerRef = useRef(null);

  const handleItemSelect = (item) => {
    setCurrent(item);
    QualityAssuranceAPI.updateQAReview({
      qa_id: rowData.qa_id,
      status: item.label,
      is_complete: item.label === "Complete" ? 1 : 0,
      completed_on: item.label === "Complete" ? moment().toISOString() : null,
    });

    onChange({
      qa_id: rowData.qa_id,
      status: item.label,
      is_complete: item.label === "Complete" ? 1 : 0,
      completed_on: item.label === "Complete" ? moment().toISOString() : null,
    });
  };

  const menuItems = QAStatusSelections.map((item) => ({
    ...item,
    onClick: handleItemSelect,
  }));

  useEffect(() => {
    setCurrent(QAStatusSelections?.find((p) => p.label === rowData?.status));
  }, [rowData?.status]);

  return (
    <div ref={containerRef} className={`${current.label}`}>
      <DropdownMenu
        menuItems={menuItems}
        title={"Actions"}
        variant={variant}
        arrow={showDropdownIcon}
        disabled={!hasPermission()}
      >
        <current.icon
          color={current?.iconColor}
          size={14}
          style={{ marginRight: 7 }}
        />
        <span style={current.menuItemProps.style}>{current.label}</span>
      </DropdownMenu>
    </div>
  );
};

export default QaStatusSelector;
