const renderPDF = async (viewerRef, url, setIsLoading) => {
  const pdfjsLib = await import("pdfjs-dist/webpack");
  const pdfjsViewer = await import("pdfjs-dist/web/pdf_viewer.js");
  import("pdfjs-dist/web/pdf_viewer.css");

  const container = viewerRef.current;
  const CMAP_URL = "../../../node_modules/pdfjs-dist/cmaps/";
  const CMAP_PACKED = true;

  const DEFAULT_URL = url;

  const ENABLE_XFA = true;
  const SEARCH_FOR = ""; // try "Mozilla";

  const eventBus = new pdfjsViewer.EventBus();

  // (Optionally) enable hyperlinks within PDF files.
  const pdfLinkService = new pdfjsViewer.PDFLinkService({
    eventBus,
  });

  // (Optionally) enable find controller.
  const pdfFindController = new pdfjsViewer.PDFFindController({
    eventBus,
    linkService: pdfLinkService,
  });

  // (Optionally) enable scripting support.

  const pdfViewer = new pdfjsViewer.PDFViewer({
    container,
    eventBus,
    linkService: pdfLinkService,
    findController: pdfFindController,
    textLayerMode: 2,
  });
  pdfLinkService.setViewer(pdfViewer);

  eventBus.on("pagesinit", function () {
    // We can use pdfViewer now, e.g. let's change default scale.
    pdfViewer.currentScaleValue = "page-width";

    // We can try searching for things.
    if (SEARCH_FOR) {
      eventBus.dispatch("find", { type: "", query: SEARCH_FOR });
    }
  });

  // Loading document.
  const loadingTask = pdfjsLib.getDocument({
    url: DEFAULT_URL,
    cMapUrl: CMAP_URL,
    cMapPacked: CMAP_PACKED,
    enableXfa: ENABLE_XFA,
  });

  const pdfDocument = await loadingTask.promise;
  // Document loaded, specifying document for the viewer and
  // the (optional) linkService.
  pdfViewer.setDocument(pdfDocument);

  pdfLinkService.setDocument(pdfDocument, null);

  setIsLoading(false);
};

export default renderPDF;
