import { httpGet } from "../connection.js";

// Get Notes
const getAllPermissions = async () => {
  return await httpGet(`/permissions`);
};

const MonolithPermissionsAPI = {
  getAllPermissions,
};

export default MonolithPermissionsAPI;
