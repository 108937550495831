import { useState } from "react";
import { Button, Modal } from "@monolith-forensics/monolith-ui";
import ButtonMenu from "../Forms/components/ButtonMenu.js";
import { usePermissions } from "../../hooks/usePermissions";
import LoggingAPI from "../../api/logging/index.js";
import { deleteContact } from "@/api/index.js";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Contact } from "@/types";

interface DeleteContactProps {
  show: boolean;
  onClose: () => void;
  onSubmit: (defaultInfo?: Contact) => void;
  onCancel: () => void;
  buttonProps?: {
    cancelText: string;
    submitText: string;
  };
  defaultInfo: Contact;
}

const DeleteContactModal: React.FC<DeleteContactProps> = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  buttonProps,
  defaultInfo,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const { currentUser } = usePermissions();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      await deleteContact(defaultInfo.client_id);
      enqueueSnackbar(`Contact Deleted.`, {
        variant: "success",
      });

      LoggingAPI.createLog({
        user_id: currentUser.user_id,
        message: `Deleted Contact: ${defaultInfo?.name}`,
        event: "contact:delete",
        object_type: "contact",
        object_uuid: defaultInfo?.uuid,
      });

      onSubmit?.(defaultInfo);
      navigate("/contacts");
    } catch (error) {
      console.error("Error deleting contact:", error);
      enqueueSnackbar(`Error deleting contact.`, {
        variant: "error",
      });
    } finally {
      setSubmitting(false);
      onClose?.();
    }
  };

  const handleCancel = () => {
    onCancel?.();
  };

  return (
    <Modal open={show} onClose={onClose}>
      <Modal.Title>Delete Contact</Modal.Title>
      <div style={{ fontWeight: 500, margin: "20px 0px" }}>
        Are you sure you want to delete the contact:{" "}
        <span style={{ fontWeight: 800 }}>"{defaultInfo?.name}"</span>?
        <p>
          This contact will be deleted and unassigned from all cases, evidence,
          and acquisition items
        </p>
      </div>
      <ButtonMenu>
        <Button
          size="xs"
          variant="subtle"
          onClick={handleCancel}
          disabled={submitting}
        >
          {buttonProps?.cancelText || "Cancel"}
        </Button>
        <Button
          size="xs"
          color="error"
          variant="contained"
          onClick={handleSubmit}
          disabled={submitting}
          loading={submitting}
        >
          {buttonProps?.submitText || "Delete Contact"}
        </Button>
      </ButtonMenu>
    </Modal>
  );
};

export default DeleteContactModal;
