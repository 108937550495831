import { httpDelete, httpGet, httpPatch, httpPost } from "../../connection.js";

const get = async (values = {}) => {
  const { uuid, ...rest } = values;
  if (uuid) return httpGet(`/analysis/accounts/${uuid}`, rest);
  return httpGet("/analysis/accounts", rest);
};

const create = async (values = {}) => {
  return httpPost("/analysis/accounts", values);
};

const update = async (values = {}) => {
  const { uuid, ...rest } = values;
  return httpPatch(`/analysis/accounts/${uuid}`, rest);
};

const remove = async (values = {}) => {
  const { uuid, ...rest } = values;
  return httpDelete(`/analysis/accounts/${uuid}`, rest);
};

const exportList = async (values = {}) => {
  return httpPost("/analysis/accounts/export", values);
};

const AccountsAPI = {
  get,
  create,
  update,
  remove,
  exportList,
};

export default AccountsAPI;
