// React and third-party library imports
import { useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useQueries } from "@tanstack/react-query";

// DevExtreme imports
import {
  EmptyItem,
  Form,
  SimpleItem,
  RequiredRule,
} from "devextreme-react/ui/form";
import DataSource from "devextreme/data/data_source";
import { Template } from "devextreme-react/core/template";

// API imports
import { caseNumberExists, createCase } from "../../../api";
import CasesApi from "../../../api/cases/index.js";
import ClientsAPI from "../../../api/clients/index.js";
import UserApi from "../../../api/users/users.js";

// Hook imports
import { usePermissions } from "../../../hooks/usePermissions";

// Utility imports
import { getDateFormat } from "../../../utils/date-format";
import { DevexEditors } from "../../../utils/devex-editors";

// Component imports
import CreateClientFlyout from "../../Clients/components/CreateClientFlyout";
import ClientSelectBoxItem from "../../../components/SelectBoxItems/ClientSelectBoxItem";
import UserSelectBoxItem from "../../../components/SelectBoxItems/UserSelectBoxItem.js";
import Loader from "../../../components/Loader";
import CollapseSection from "../../../components/CollaspeSection";

// Monolith-UI imports
import Modal from "../../../Monolith-UI/Modal/Modal";

const CreateCaseModal = ({
  open = false,
  onSubmit = (result) => {},
  handleClose = () => {},
}) => {
  const { currentUser } = usePermissions();
  const createClientFlyout = useRef(null);

  const form = useRef(null);
  const customFieldsForm = useRef(null);

  let caseTypes, caseStatuses, users, clients, customFields;

  const result = useQueries({
    queries: [
      {
        queryKey: ["cases:types"],
        queryFn: () => CasesApi.getCaseTypes(),
        enabled: open,
      },
      {
        queryKey: ["cases:statuses"],
        queryFn: () => CasesApi.getCaseStatuses(),
        enabled: open,
      },
      {
        queryKey: [
          "cases:leads",
          {
            includeObservers: false,
            includeInactive: false,
          },
        ],
        queryFn: () =>
          UserApi.getUsers({
            includeObservers: false,
            includeInactive: false,
          }),
        enabled: open,
      },
      {
        queryKey: ["clients:list"],
        queryFn: () => ClientsAPI.getClients(),
        enabled: open,
      },
      {
        queryKey: ["cases:custom-fields:list"],
        queryFn: () => CasesApi.getCaseCustomAttributes({ fieldsOnly: true }),
        enabled: open,
        select: (data) => data.filter((c) => c.enabled === 1),
      },
    ],
  });

  const isDone = result.every((x) => x.isSuccess);

  const fieldData = useMemo(
    () => ({
      Casetypes: result[0].data,
      caseStatus: result[1].data,
      users: result[2].data,
      clients: result[3].data,
      customFields: result[4].data,
    }),
    [result]
  );

  caseTypes = fieldData.Casetypes;
  caseStatuses = fieldData.caseStatus;
  users = fieldData.users;
  clients = [
    { name: "Create New Client", client_id: "new" },
    ...(fieldData?.clients || []),
  ];
  customFields = fieldData.customFields;

  const caseFormContent = useMemo(() => {
    return (
      <Form ref={form} colCount={2}>
        <SimpleItem
          dataField={"case_number"}
          label={{ text: "Case Number" }}
          editorOptions={{
            hint: "Enter a unique value for your case.",
            placeholder: "Leave blank to auto-generate...",
          }}
          validationRules={[
            {
              type: "pattern",
              message: 'Contains invalid characters: <>:"/\\|?*',
              pattern: /^[^<>:"/\\|?*]+$/,
            },
            {
              type: "async",
              message: "Case Number already exists",
              ignoreEmptyValue: true,
              validationCallback: (e) => {
                return caseNumberExists({ case_number: e.value }).then(
                  (res) => {
                    return !res;
                  }
                );
              },
            },
          ]}
        />
        <SimpleItem
          dataField={"case_ref"}
          label={{ text: "Case Name" }}
          editorOptions={{
            hint: "Enter a short phrase or term to help identify this case (Cause No., Suspect, Court Case Citation, etc.).",
          }}
          validationRules={[
            {
              type: "required",
              message: "Case Name is required",
            },
            {
              type: "pattern",
              message: 'Contains invalid characters: <>:"/\\|?*',
              pattern: /^[^<>:"/\\|?*]+$/,
            },
          ]}
        />
        <SimpleItem
          dataField="client_id"
          label={{ text: "Client" }}
          editorType="dxSelectBox"
          editorOptions={{
            dataSource: new DataSource({
              store: clients,
              paginate: true,
              pageSize: 10,
            }),
            placeholder: "Select Client...",
            dropDownOptions: {
              maxHeight: 300,
            },
            displayExpr: "name",
            valueExpr: "client_id",
            searchEnabled: true,
            showClearButton: true,
            openOnFieldClick: false,
            onFocusIn: (e) => {
              e.component.open();
            },
            onValueChanged: (e) => {
              if (e.value && e.value === "new") {
                e.event.preventDefault();
                e.event.stopPropagation();
                e.component.option("value", null);
                createClientFlyout.current.instance.show();
              }
            },
            itemTemplate: "client_id",
          }}
        />
        <Template
          name="client_id"
          render={(data) => <ClientSelectBoxItem data={data} />}
        />
        <SimpleItem
          dataField={"case_type"}
          label={{ text: "Case Type" }}
          editorType="dxSelectBox"
          editorOptions={{
            dataSource: caseTypes,
            displayExpr: "case_type",
            valueExpr: "case_type",
            searchEnabled: true,
            searchExpr: "case_type",
            searchMode: "startsWith",
            placeholder: "Select Case Type...",
            dropDownOptions: {
              maxHeight: 250,
            },
          }}
        />
        <SimpleItem
          dataField={"case_status"}
          label={{ text: "Case Status" }}
          editorType="dxSelectBox"
          editorOptions={{
            dataSource: caseStatuses,
            displayExpr: "status_name",
            valueExpr: "status_name",
            value: "Active",
            searchEnabled: true,
            searchExpr: "status_name",
            searchMode: "startsWith",
            placeholder: "Select Case Status...",
            dropDownOptions: {
              maxHeight: 250,
            },
          }}
        />
        <SimpleItem
          dataField={"case_lead_id"}
          label={{ text: "Case Lead" }}
          editorType="dxSelectBox"
          editorOptions={{
            dataSource: users,
            displayExpr: "full_name",
            valueExpr: "user_id",
            searchEnabled: true,
            searchExpr: "full_name",
            searchMode: "startsWith",
            placeholder: "Select Case Lead...",
            dropDownOptions: {
              maxHeight: 250,
            },
            itemTemplate: "case_lead_id",
          }}
        />
        <Template
          name="case_lead_id"
          render={(data) => <UserSelectBoxItem data={data} />}
        />
        <SimpleItem
          dataField={"case_open_date"}
          label={{ text: "Case Open Date" }}
          editorType="dxDateBox"
          editorOptions={{
            type: "datetime",
            useMaskBehavior: true,
            displayFormat: getDateFormat({
              isMoment: false,
              includeTime: false,
            }),
          }}
        />
        <EmptyItem />
        <SimpleItem
          dataField={"case_synopsis"}
          label={{ text: "Description" }}
          editorType="dxTextArea"
          colSpan={2}
          editorOptions={{
            height: 100,
          }}
        />
      </Form>
    );
  }, [clients, caseTypes, caseStatuses, users]);

  const CustomFieldsSection = useMemo(() => {
    return (
      <Form ref={customFieldsForm} colCount={2} style={{ padding: 10 }}>
        {customFields?.map((attribute) => (
          <SimpleItem
            key={`custom_attribute_${attribute.field_id}`}
            dataField={`custom_attribute_${attribute.field_id}`}
            editorType={DevexEditors[attribute.editor_type]}
            label={{ text: attribute.field_name }}
            editorOptions={{
              placeholder: attribute.description || "",
              hint: attribute.description || "",
              items: JSON.parse(attribute.options || "[]"),
              dateSerializationFormat: "yyyy-MM-dd",
              useMaskBehavior: true,
              displayFormat: getDateFormat({
                isMoment: false,
                includeTime: false,
              }),
              showClearButton: attribute.editor_type !== "textBox",
              pickerType: "calendar",
              showDropDownButton: true,
              multiline: false,
              showSelectionControls:
                attribute.editor_type === "tagBox" ? true : false,
            }}
          >
            {attribute.is_required === 1 && <RequiredRule message="" />}
          </SimpleItem>
        ))}
      </Form>
    );
  }, [customFields]);

  const handleCompleteSubmit = async ({ isValid }) => {
    if (!isValid) return;
    if (
      form.current.instance.validate().isValid &&
      (customFieldsForm?.current
        ? customFieldsForm?.current?.instance?.validate()?.isValid
        : true)
    ) {
      let formData = {
        ...form.current.instance.option().formData,
        ...(customFieldsForm?.current?.instance?.option()?.formData || {}),
      };

      formData.user_id = currentUser.user_id;

      let customAttributes = [];

      // parse custom attribute field_id from form field
      for (let key of Object.keys(formData)) {
        if (key.indexOf("custom_attribute_") !== -1) {
          customAttributes.push({
            field_id: parseInt(key.replace("custom_attribute_", "")),
            value: formData[key],
          });

          delete formData[key];
        }
      }
      if (customAttributes.length > 0)
        formData.custom_attributes = customAttributes;
      createCase(formData).then((result) => {
        onSubmit?.(result);
      });
      handleClose();
    }
  };

  const handleSubmit = () => {
    const res = form.current.instance.validate();
    if (res.status === "pending") {
      res.complete.then((r) => {
        handleCompleteSubmit({ isValid: r.isValid });
      });
    } else {
      handleCompleteSubmit({ isValid: res.isValid });
    }
  };

  const handleCancel = () => {
    handleClose();
  };

  const onClientCreated = (newClient) => {
    form.current.instance.option("formData.client_id", newClient.client_id);
    form.current.instance
      .getEditor("client_id")
      .option("value", newClient.client_id);
    form.current.instance
      .getEditor("client_id")
      .option("dataSource", [...clients, newClient]);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={(reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        style={{ zIndex: 1400 }}
        title="Create Case"
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        submitText="Create Case"
        showCloseButton={false}
      >
        <div>
          {!isDone ? (
            <Loader />
          ) : (
            <>
              <CollapseSection title={"Case Details"} visible={true}>
                {caseFormContent}
              </CollapseSection>
              {customFields?.length > 0 && (
                <CollapseSection title={"Custom Fields"}>
                  {CustomFieldsSection}
                </CollapseSection>
              )}
            </>
          )}
        </div>
      </Modal>
      <CreateClientFlyout
        createClientFlyout={createClientFlyout}
        onSubmit={onClientCreated}
      />
    </>
  );
};

export default CreateCaseModal;
