import { getEquipmentNames } from "@/api";
import { SelectBox } from "@monolith-forensics/monolith-ui";
import { useQuery } from "@tanstack/react-query";
import { FC } from "react";

interface EquipmentNameSelectorProps {
  defaultValue: string;
  onChange?: (value: string) => void;
  error?: string;
  openOnFocus?: boolean;
}

type EquipmentName = {
  name: string;
};

const EquipmentNameSelector: FC<EquipmentNameSelectorProps> = ({
  defaultValue,
  onChange,
  error,
  openOnFocus = false,
}) => {
  const { data: equipmentItems } = useQuery<EquipmentName[]>({
    queryKey: ["equipment", "name"],
    queryFn: () => getEquipmentNames(),
  });

  const options = equipmentItems?.map((equipment) => ({
    label: equipment?.name,
    value: equipment?.name,
  }));

  return (
    <>
      <SelectBox
        defaultValue={defaultValue}
        onChange={onChange}
        error={error}
        size="sm"
        variant="outlined"
        label="Name"
        placeholder="Select or enter equipment name"
        data={options}
        clearable={true}
        searchable={true}
        allowCustomValue={true}
        openOnFocus={openOnFocus}
      />
    </>
  );
};
export default EquipmentNameSelector;
