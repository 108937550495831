import React, { useRef, useState } from "react";
import { Tabs } from "devextreme-react/ui/tabs";
import moment from "moment";

import EvidenceReportMetrics from "./EvidenceReportMetrics";
import EvidenceSavedReports from "./EvidenceSavedReports";
import EvidenceReportComposition from "./EvidenceReportComposition";
import { Button, Card, Divider } from "@mui/material";
import { EditReportPopup } from "../../EditReportPopup";
import EvidenceItemGrid from "./EvidenceItemGrid";
import { usePermissions } from "../../../../hooks/usePermissions";
import { MONOLITH_PERMISSIONS } from "../../../../constants.js";

export const evidenceDimensions = [
  {
    text: "New Items",
    value: "Intake Date",
    index: 0,
  },
  {
    text: "Released",
    value: "Date Released",
    index: 1,
  },
];

const ReportDetails = ({ reportInfo }) => {
  return (
    <Card
      style={{
        padding: "20px",
        textAlign: "left",
        flexGrow: 1,
        flexBasis: "10px",
        marginRight: 15,
      }}
    >
      <div
        style={{
          fontSize: "larger",
        }}
      >
        Report Details
      </div>
      <Divider sx={{ my: 2 }} />
      <div
        style={{
          minWidth: "300px",
        }}
      >
        {reportInfo.report_id && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "5px",
            }}
          >
            <div>Report ID: </div>
            <div>{reportInfo.report_id}</div>
          </div>
        )}
        {reportInfo.name && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "5px",
            }}
          >
            <div>Report Name: </div>
            <div>{reportInfo.name}</div>
          </div>
        )}
        {reportInfo.created_at && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "5px",
            }}
          >
            <div>Created On:</div>
            <div>{moment(reportInfo.created_at).format("MMMM DD, YYYY")}</div>
          </div>
        )}
        {reportInfo.created_by && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "5px",
            }}
          >
            <div>Created By: </div>
            <div>{reportInfo.created_by}</div>
          </div>
        )}
        {reportInfo.category && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "5px",
            }}
          >
            <div>Category: </div>
            <div>{reportInfo.category}</div>
          </div>
        )}
      </div>
    </Card>
  );
};

const ReportParameters = ({ reportInfo, editReportPopup }) => {
  const { hasPermission } = usePermissions();
  return (
    <Card
      style={{
        padding: "20px",
        textAlign: "left",
        flexGrow: 1,
        flexBasis: "10px",
        marginRight: 15,
      }}
    >
      <div
        style={{
          fontSize: "larger",
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <span style={{ display: "flex", alignItems: "center" }}>
          Report Parameters
        </span>
        <Button
          size="small"
          onClick={() => editReportPopup.current.instance.show()}
          disabled={!hasPermission(MONOLITH_PERMISSIONS.METRICS_REPORTS_UPDATE)}
        >
          Edit
        </Button>
      </div>
      <Divider sx={{ my: 2 }} />
      <div
        style={{
          minWidth: "300px",
          overflowY: "auto",
          maxHeight: "175px",
          paddingRight: "10px",
        }}
      >
        {reportInfo.report_parameters && (
          <>
            <div
              style={{
                minWidth: "300px",
              }}
            >
              {reportInfo.report_parameters.time_interval && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>Time Interval:</div>
                  <div
                    style={{
                      maxWidth: 150,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    title={reportInfo.report_parameters.time_interval || ""}
                  >
                    {reportInfo.report_parameters.time_interval}
                  </div>
                </div>
              )}
              {reportInfo.report_parameters.start_date && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>Start Date:</div>
                  <div
                    style={{
                      maxWidth: 150,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    title={
                      moment(reportInfo.report_parameters.start_date).format(
                        "MMMM DD, YYYY"
                      ) || ""
                    }
                  >
                    {moment(reportInfo.report_parameters.start_date).format(
                      "MMMM DD, YYYY"
                    )}
                  </div>
                </div>
              )}
              {reportInfo.report_parameters.end_date && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>End Date:</div>
                  <div
                    style={{
                      maxWidth: 150,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    title={
                      moment(reportInfo.report_parameters.end_date).format(
                        "MMMM DD, YYYY"
                      ) || ""
                    }
                  >
                    {moment(reportInfo.report_parameters.end_date).format(
                      "MMMM DD, YYYY"
                    )}
                  </div>
                </div>
              )}
              {reportInfo.report_parameters.timestamp_dimension && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>Timestamp Dimension:</div>
                  <div
                    style={{
                      maxWidth: 150,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    title={
                      reportInfo.report_parameters.timestamp_dimension || ""
                    }
                  >
                    {reportInfo.report_parameters.timestamp_dimension}
                  </div>
                </div>
              )}
              {reportInfo.report_parameters.evidence_types && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>Types:</div>
                  <div
                    style={{
                      maxWidth: 150,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    title={
                      reportInfo.report_parameters.evidence_types.join(", ") ||
                      ""
                    }
                  >
                    {reportInfo.report_parameters.evidence_types.join(", ")}
                  </div>
                </div>
              )}
              {reportInfo.report_parameters.evidence_brands && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>Brands:</div>
                  <div
                    style={{
                      maxWidth: 150,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    title={
                      reportInfo.report_parameters.evidence_brands.join(", ") ||
                      ""
                    }
                  >
                    {reportInfo.report_parameters.evidence_brands.join(", ")}
                  </div>
                </div>
              )}
              {reportInfo.report_parameters.case_lead && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>Case Lead:</div>
                  <div
                    style={{
                      maxWidth: 150,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    title={
                      reportInfo.report_parameters.case_lead
                        .map((cl) => {
                          return cl.full_name;
                        })
                        .join(", ") || ""
                    }
                  >
                    {reportInfo.report_parameters.case_lead
                      .map((cl) => {
                        return cl.full_name;
                      })
                      .join(", ")}
                  </div>
                </div>
              )}
              {reportInfo.report_parameters.organization && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>Organization:</div>
                  <div
                    style={{
                      maxWidth: 150,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    title={
                      reportInfo.report_parameters.organization.join(", ") || ""
                    }
                  >
                    {reportInfo.report_parameters.organization.join(", ")}
                  </div>
                </div>
              )}
              {reportInfo.report_parameters.case_types && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>Case Types:</div>
                  <div
                    style={{
                      maxWidth: 150,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    title={
                      reportInfo.report_parameters.case_types.join(", ") || ""
                    }
                  >
                    {reportInfo.report_parameters.case_types.join(", ")}
                  </div>
                </div>
              )}
              {reportInfo.report_parameters.case_statuses && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>Case Status:</div>
                  <div
                    style={{
                      maxWidth: 150,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    title={
                      reportInfo.report_parameters.case_statuses.join(", ") ||
                      ""
                    }
                  >
                    {reportInfo.report_parameters.case_statuses.join(", ")}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {!reportInfo.report_parameters && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "5px",
            }}
          >
            <div>No Parameters Set</div>
          </div>
        )}
      </div>
    </Card>
  );
};

const TabSection = ({ reportInfo }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div style={{ minHeight: "450px" }}>
      <Tabs
        items={["Saved Reports", "Evidence Items"]}
        selectedIndex={selectedTab}
        focusStateEnabled={false}
        hoverStateEnabled={false}
        activeStateEnabled={false}
        width={250}
        onItemClick={(e) => {
          e.event.preventDefault();
          e.event.stopPropagation();
          setSelectedTab(e.itemIndex);
        }}
      />
      {selectedTab === 0 && <EvidenceSavedReports reportInfo={reportInfo} />}
      {selectedTab === 1 && <EvidenceItemGrid reportInfo={reportInfo} />}
    </div>
  );
};

const EvidenceReport = ({ reportInfo, formUpdated, setFormUpdated }) => {
  const editReportPopup = useRef(null);

  return (
    <>
      <div
        style={{
          display: "flex",
          marginBottom: "20px",
        }}
      >
        <ReportDetails reportInfo={reportInfo} />
        <ReportParameters
          reportInfo={reportInfo}
          editReportPopup={editReportPopup}
        />
        <EvidenceReportMetrics reportInfo={reportInfo} />
      </div>
      <EvidenceReportComposition reportInfo={reportInfo} />
      <TabSection reportInfo={reportInfo} />
      <EditReportPopup
        editReportPopup={editReportPopup}
        reportInfo={reportInfo}
        setFormUpdated={setFormUpdated}
      />
    </>
  );
};

export default EvidenceReport;
