import { Link } from "react-router-dom";
import styled from "styled-components";

const UsersColumnDefs = [
  {
    dataField: "full_name",
    caption: "Name",
    render: (data) => <UserNameComponent data={data} />,
  },
  {
    dataField: "email",
    caption: "Email",
    visible: false,
  },
  {
    dataField: "title",
    caption: "Title",
  },
  {
    dataField: "user_role",
    caption: "Role",
    render: (data) => <UserRoleChip userData={data} />,
  },
  {
    dataField: "observer",
    caption: "Read Only",
    render: (data) => (data.observer === 1 ? "Yes" : "No"),
  },
  {
    dataField: "office_name",
    caption: "Office",
  },
  {
    dataField: "active_status",
    caption: "Active Status",
    render: (data) => <UserStatusChip userData={data} />,
  },
];

const UserNameComponent = styled(({ className, data }) => {
  return (
    <div className={className}>
      <div>
        <Link to={`/team/users/${data.email}/user-details`}>
          <div className="user-link">{data.full_name}</div>
        </Link>
        <div className="user-email">{data.email}</div>
      </div>
    </div>
  );
})`
  padding: 5px 0px;
  .user-link {
    font-size: medium;
    cursor: pointer;
    color: ${(props) => props.theme.palette.primary.main};
    &:hover {
      text-decoration: underline;
    }
  }
  .user-email {
    font-size: x-small;
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;

const UserStatusChip = styled(({ className, userData }) => {
  const color = userData.active === 1 ? "success" : "fourth";
  const label = userData.active === 1 ? "Active" : "Deactivated";

  return (
    <div className={`${className} user-role-indicator ${color}`}>
      <div className="label">{label}</div>
    </div>
  );
})`
  border-radius: 5px;
  padding: 2px 5px;
  width: fit-content;
  font-weight: 500;

  &.fourth {
    color: ${(props) => props.theme.palette.fourth.main};
    background-color: ${(props) => props.theme.palette.fourth.main}33;
  }

  &.success {
    color: ${(props) => props.theme.palette.success.main};
    background-color: ${(props) => props.theme.palette.success.main}33;
  }
`;

const UserRoleChip = styled(({ className, userData }) => {
  let color = "primary";
  let label = "User";

  if (userData.role_id === 1) {
    color = "fourth";
    label = "Super Admin";
  } else if (userData.role_id === 2) {
    color = "success";
    label = "Admin";
  } else if (userData.role_id === 3) {
    color = "primary";
    label = "User";
  } else {
    color = "secondary";
    label = userData?.user_role?.name;
  }
  return (
    <div className={`${className} user-role-indicator ${color}`}>
      <div className="label">{label}</div>
    </div>
  );
})`
  border-radius: 5px;
  padding: 2px 5px;
  width: fit-content;
  font-weight: 500;

  &.fourth {
    color: ${(props) => props.theme.palette.warning.main};
    background-color: ${(props) => props.theme.palette.warning.main}33;
  }

  &.primary {
    color: ${(props) => props.theme.palette.primary.main};
    background-color: ${(props) => props.theme.palette.primary.main}33;
  }

  &.secondary {
    color: ${(props) => props.theme.palette.text.secondary};
    background-color: ${(props) => props.theme.palette.secondary.main};
  }

  &.success {
    color: ${(props) => props.theme.palette.success.main};
    background-color: ${(props) => props.theme.palette.success.main}33;
  }
`;

export default UsersColumnDefs;
