import { Button, Modal, useTheme } from "@mui/material";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useMemo, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ContactsAPI from "../../../api/contacts/index.js";
import ContactsColumnDefs from "../../../components/Contacts/ContactsColumnDefs.js";
import ContactsFilterDimensions from "../../../components/Contacts/ContactsFilterDimensions.js";
import { usePermissions } from "../../../hooks/usePermissions";
import ComboButton from "../../../Monolith-UI/ComboButton/ComboButton.js";
import { useQueryFilter } from "../../../Monolith-UI/QueryFilter/QueryFilter.js";
import Table, { Column, useTable } from "../../../Monolith-UI/Table/Table.js";
import { getDateFormat } from "../../../utils/date-format";
import { ItemTotal } from "../CaseEvidence/index";

//Icons
import PeopleIcon from "@mui/icons-material/People";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";
import Loader from "../../../components/Loader.js";
import styled from "styled-components";
import ToolBarItems from "../../../components/ToolBarItems.js";
import ContactSelectBoxItem from "../../../components/SelectBoxItems/ContactSelectBoxItem.js";

import { Form, SimpleItem } from "devextreme-react/ui/form.js";
import { Template } from "devextreme-react/core/template.js";
import DataSource from "devextreme/data/data_source";
import { TextBox } from "devextreme-react/ui/text-box.js";
import synchronizeColumnState from "../../../utils/synchronize-column-state.js";
import { useDebouncedCallback } from "use-debounce";
import { MONOLITH_PERMISSIONS } from "../../../constants";
import ContactFlyout from "@/components/Flyouts/ContactFlyout";
import CreateContactModal from "@/components/Modals/CreateContactModal";

const actions = [
  { value: 0, text: "Assign Contacts", permission: null },
  {
    value: 1,
    text: "Import from CSV",
    permission: MONOLITH_PERMISSIONS.CONTACTS_BULK_IMPORT,
  },
];

const columnDefs = ContactsColumnDefs;

const CaseContactsView = styled(
  ({ className, stateStoreKey = "case:contacts:view" }) => {
    const { case_id } = useParams();
    const theme = useTheme();
    const { hasPermission } = usePermissions();
    const { enqueueSnackbar } = useSnackbar();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [flyoutData, setFlyoutData] = useState(null);
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);

    const navigate = useNavigate();

    const [columnState, setColumnState] = useState(() => {
      return synchronizeColumnState(
        columnDefs,
        JSON.parse(localStorage.getItem(stateStoreKey) || "{}")
      );
    });

    const [pageSize, setPageSize] = useState(100);
    const [query, setQuery] = useState(null);

    const table = useTable();

    const currentSort = useMemo(() => {
      let [sort] = columnState
        ?.filter((c) => c.sorting?.active)
        .map((c) => ({ field: c.dataField, sort: c.sorting?.direction }));

      return sort;
    }, [columnState]);

    const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } =
      useInfiniteQuery({
        queryKey: [
          "contacts:list",
          {
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
              case_id,
            },
          },
        ],
        queryFn: ({ pageParam }) =>
          ContactsAPI.query({
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
              case_id,
              page: pageParam,
            },
          }),
        getNextPageParam: (lastPage) => {
          return lastPage.nextPage;
        },
        getPreviousPageParam: (firstPage) => {
          if (firstPage.page - 1 === 0) return null;
          return firstPage.page - 1;
        },
        initialPageParam: 1,
        enabled: !!query,
        placeholderData: (data) => data,
      });

    const records = data?.pages?.reduce((acc, page) => {
      return [...acc, ...page.data];
    }, []);

    const totalRecords = data?.pages?.[0]?.total || 0;

    const debouncedFetchNextPage = useDebouncedCallback((e) => {
      fetchNextPage();
    }, 50);

    const { queryButton, queryFilter, conditions } = useQueryFilter({
      dimensions: ContactsFilterDimensions,
      onQuerySet: (newFilter) =>
        setQuery((q) => {
          return { query: { ...q?.query, ...newFilter, page: 1, case_id } };
        }),
      stateStoring: {
        enabled: true,
        type: "localStorage",
        storageKey: stateStoreKey,
      },
    });

    const handleSort = (field) => {
      const savedColumn =
        columnState?.find((svc) => field === svc.dataField) || {};
      const order = !!savedColumn ? savedColumn?.sorting?.direction : null;

      let newOrder = null;

      // if sorted on a different column, reset the sort
      if (query?.query?.order?.field !== field) {
        newOrder = {
          field,
          sort: "asc",
        };
      }

      // otherwise rotate the sort options on the current column
      else {
        switch (order) {
          case "asc":
            newOrder = {
              field,
              sort: "desc",
            };
            break;
          case "desc":
            newOrder = null;
            break;
          default:
            newOrder = {
              field,
              sort: "asc",
            };
        }
      }

      let newCols = columnState?.map((c) => {
        if (c.dataField === field) {
          return {
            ...c,
            sorting: {
              active: newOrder ? true : false,
              direction: newOrder?.sort,
            },
          };
        }
        delete c.sorting;
        return c;
      });

      let newQuery = {
        query: {
          ...query.query,
          order: newOrder,
        },
      };

      setColumnState(newCols);

      setQuery(newQuery);
    };
    const handleRefresh = () => {
      refetch();
    };
    const handleExportTable = () => {
      // show snackbar
      enqueueSnackbar("Exporting table...", {
        variant: "info",
      });

      ContactsAPI.exportContactsList({
        query: {
          ...query?.query,
          order: query?.query?.order || currentSort,
          pageSize,
        },
        type: "xlsx",
        columns: columnState
          .filter((c) => c.visible !== false)
          .sort((a, b) => a.order - b.order)
          .map((c) => {
            return { dataField: c.dataField, header: c.caption, ...c };
          }),
        date_format: getDateFormat({ isMoment: true, includeTime: true }),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }).then((res) => {
        const { signedUrl, filename } = res;
        const el = document.createElement("a");
        el.href = signedUrl.replace(
          "http://localhost:3000",
          "http://localhost:3001"
        );
        el.download = filename;
        el.click();
        // remove snackbar
      });
    };
    const handleColumnReorder = (newOrder) => {
      setColumnState((cs) => {
        return newOrder.map((o) => {
          return {
            ...cs.find((c) => c.dataField === o.column),
            order: o.order,
          };
        });
      });
    };
    const handleColumnResize = (e) => {
      setColumnState((cs) => {
        return cs.map((c) => {
          const col = e.columns.find((col) => col.dataField === c.dataField);
          if (col) {
            return {
              ...c,
              width: parseInt(col.width.replace(/px/g, "")),
            };
          }
          return c;
        });
      });
    };
    const handleColumnVisibility = (column, visible) => {
      setColumnState((cs) => {
        return cs.map((c) => {
          if (c.dataField === column.dataField) {
            return {
              ...c,
              visible,
            };
          }
          return c;
        });
      });
    };
    const handleActionButtonClick = (rowData) => {
      setFlyoutData(rowData);
      setIsFlyoutVisible(true);
    };
    const handleActionSelect = (action) => {
      switch (action.value) {
        case 0:
          setShowAssignModal(true);
          break;
        case 1:
          navigate(`/cases/${case_id}/contacts?v=import`);
          break;
        default:
          break;
      }
    };
    const handleClearFilters = () => queryFilter.clear();
    const handleContactsRefetch = () => {
      refetch();
    };
    const handleGetNextItem = (client_id) => {
      const current = records?.findIndex((i) => i.client_id === client_id);
      const nextItem = records[current + 1] || records[0];

      setFlyoutData(nextItem);
    };
    const handleGetPrevItem = (client_id) => {
      const current = records?.findIndex((i) => i.client_id === client_id);
      const prevItem = records[current - 1] || records[records.length - 1];

      setFlyoutData(prevItem);
    };
    // Detect scroll to bottom
    const handleScroll = (e) => {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      if (
        scrollHeight - scrollTop <=
        clientHeight + 100 * data?.pages?.length
      ) {
        if (hasNextPage && !isFetchingNextPage) {
          debouncedFetchNextPage();
        }
      }
    };

    // Persist column state to local storage
    useEffect(() => {
      let oldState = JSON.parse(localStorage.getItem(stateStoreKey)) || {};
      localStorage.setItem(
        stateStoreKey,
        JSON.stringify({
          ...oldState,
          cols: columnState,
        })
      );
    }, [columnState]);

    return (
      <div className={className}>
        <div
          style={{
            display: "flex",
            flex: "initial",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={!hasPermission()}
            onClick={(e) => {
              setShowCreateModal(true);
            }}
            style={{
              minWidth: "fit-content",
              fontSize: 11,
              padding: "3px 6px",
            }}
          >
            + New Contact
          </Button>
          <div style={{ marginLeft: 10 }}>{queryButton}</div>
          <ItemTotal total={totalRecords} Icon={PeopleIcon} />
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              minWidth: "fit-content",
            }}
          >
            {hasPermission() && (
              <ComboButton
                type="dropDown"
                data={actions.filter(
                  (a) => !a.permission || hasPermission(a.permission)
                )}
                title={"More Actions"}
                showDropdownIcon={true}
                displayField="text"
                variant="outlined"
                useSelectMode={false}
                onItemSelect={(item) => handleActionSelect(item)}
                textColor={theme.palette.text.primary}
              >
                Actions
              </ComboButton>
            )}
            <ComboButton
              type="multi-select"
              data={columnState}
              displayField="caption"
              idField={"dataField"}
              selectedItems={columnState.filter((c) => c.visible !== false)}
              variant="outlined"
              closeOnSelect={false}
              textColor={theme.palette.text.secondary}
              title={"Select Columns"}
              showSearch={true}
              dropDownTitle={() => {
                return (
                  <div
                    style={{
                      margin: "5px 0px",
                      padding: 3,
                      color: theme.palette.text.secondary,
                      display: "flex",
                      alignItems: "center",
                      minWidth: 200,
                    }}
                  >
                    Select Columns
                  </div>
                );
              }}
              onItemDeSelect={(item) => {
                handleColumnVisibility(item, false);
              }}
              onItemSelect={(item) => {
                handleColumnVisibility(item, true);
              }}
            >
              <ViewColumnOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Export Table"}
              onClick={handleExportTable}
            >
              <FileDownloadOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Clear Filters"}
              onClick={handleClearFilters}
            >
              <FilterListOffIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={table.isCompact ? "Zoom In" : "Zoom Out"}
              onClick={() => table.toggleCompact()}
            >
              {table.isCompact ? (
                <ZoomOutMapOutlinedIcon style={{ fontSize: 18 }} />
              ) : (
                <ZoomInMapOutlinedIcon style={{ fontSize: 18 }} />
              )}
            </ComboButton>
            <ComboButton
              type="button"
              variant={"outlined"}
              textColor={
                table.isStriped
                  ? theme.palette.primary.main
                  : theme.palette.text.secondary
              }
              title={table.isStriped ? "Hide Stripes" : "Show Stripes"}
              onClick={() => table.toggleStripes()}
            >
              <ReorderOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Refresh Table"}
              onClick={handleRefresh}
            >
              <SyncOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <TextBox
              stylingMode="filled"
              placeholder="Search Contacts"
              labelMode="static"
              height={30}
              style={{ marginLeft: "10px" }}
              onKeyUp={(e) => {
                let searchText = e.event.currentTarget.value;
                if (
                  e.event.code === "Enter" ||
                  e.event.code === "NumpadEnter" ||
                  searchText === ""
                ) {
                  setQuery((q) => ({
                    query: {
                      ...q?.query,
                      search: searchText === "" ? null : searchText,
                      page: 1,
                    },
                  }));
                }
              }}
            />
          </div>
        </div>
        {data && (
          <>
            {<div>{conditions}</div>}
            <Table
              data={records}
              totalRecords={totalRecords}
              tableInstance={table}
              keyValue="client_id"
              columnProps={{ minWidth: 150, width: 150 }}
              onScroll={handleScroll}
              onHeaderClick={(col) =>
                col?.sorting?.enabled === false
                  ? null
                  : handleSort(col.dataField)
              }
              onColumnReorder={handleColumnReorder}
              onColumnResize={handleColumnResize}
              onActionButtonClick={handleActionButtonClick}
              showActionColumn={true}
              focusedRow={flyoutData}
              showSelection={false}
            >
              {columnState.map((col) => {
                return <Column key={col.dataField} {...col} />;
              })}
            </Table>
          </>
        )}
        {!data && <Loader message={"Retrieving Items..."} />}
        {isFlyoutVisible && (
          <ContactFlyout
            show={isFlyoutVisible}
            caseId={case_id}
            onClose={() => {
              setIsFlyoutVisible(false);
              setFlyoutData(null);
            }}
            onDelete={() => {
              setIsFlyoutVisible(false);
              setFlyoutData(null);
              refetch();
            }}
            defaultInfo={flyoutData}
            onNext={handleGetNextItem}
            onPrevious={handleGetPrevItem}
            onEdit={handleContactsRefetch}
          />
        )}
        <CreateContactModal
          show={showCreateModal}
          onClose={() => setShowCreateModal(false)}
          onCancel={() => setShowCreateModal(false)}
          onSubmit={handleContactsRefetch}
          caseId={case_id}
        />
        <AddContactPopup
          caseInfo={{ case_id }}
          open={showAssignModal}
          handleClose={() => setShowAssignModal(false)}
          onSubmit={handleContactsRefetch}
        />
      </div>
    );
  }
)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const AddContactPopup = ({
  caseInfo,
  onSubmit = () => {},
  open,
  handleClose,
  table,
}) => {
  const theme = useTheme();
  const form = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const { data: contacts, isFetched: isDone } = useQuery({
    queryKey: ["contacts:list"],
    queryFn: () => ContactsAPI.getContacts(),
    enabled: open,
  });

  const handleSubmit = async (event) => {
    const formData = { ...form.current.instance.option().formData };

    let queries = [];

    for (const contact of formData.contacts) {
      queries.push(
        ContactsAPI.linkCaseContact({
          case_id: caseInfo.case_id,
          client_id: contact.client_id,
        })
      );
    }

    handleClose();
    await Promise.all(queries);

    enqueueSnackbar(`Contact Added to Case.`, {
      variant: "success",
    });

    onSubmit();
  };

  const handleCancel = (event) => {
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 400,
          backgroundColor: theme.palette.background.default,
          position: "absolute",
          left: "50%",
          top: "20%",
          transform: "translate(-35%, -20%)",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Assign Contacts
        </div>
        {!isDone && <Loader />}
        {isDone && (
          <>
            <Form ref={form}>
              <SimpleItem
                dataField="contacts"
                label={{ text: "Contacts" }}
                editorType="dxTagBox"
                editorOptions={{
                  dataSource: new DataSource({
                    store: contacts,
                    paginate: true,
                    pageSize: 10,
                  }),
                  displayExpr: "name",
                  searchEnabled: true,
                  showSelectionControls: true,
                  maxDisplayedTags: 3,
                  selectAllMode: "allPages",
                  showDropDownButton: true,
                  dropDownOptions: {
                    height: "250px",
                  },
                  itemTemplate: "contacts",
                }}
              />
              <Template
                name="contacts"
                render={(data) => <ContactSelectBoxItem data={data} />}
              />
            </Form>
            <div style={{ marginTop: 10 }}>
              <ToolBarItems
                submitText="Assign Contacts"
                onSubmit={handleSubmit}
                onCancel={handleCancel}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default CaseContactsView;
