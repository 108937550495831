import styled from "styled-components";

const EmptySegment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 30px;
  max-width: 815px;
  width: fit-content;
  padding-left: 20px;

  font-size: 0.75rem;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export default EmptySegment;
