import { useQuery, useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import EvidenceAPI from "../../api/evidence/index.js";
import { useState } from "react";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import OpenInFullOutlinedIcon from "@mui/icons-material/OpenInFullOutlined";
import { Button, Modal, useTheme } from "@mui/material";
import { usePermissions } from "../../hooks/usePermissions";
import { useSnackbar } from "notistack";
import Loader from "../../components/Loader.js";
import ToolBarItems from "../../components/ToolBarItems.js";
import FileViewer from "../../Monolith-UI/FileViewer/FileViewer.js";
import shortUUID from "short-uuid";
import BasicProgressBar from "../../Monolith-UI/BasicProgressBar/BasicProgressBar.js";
import calculateFileHash from "../../utils/calculateFileHash.js";

const uploadFile = async (values = {}) => {
  const { evidence_id, file } = values;

  const hashes = await calculateFileHash(file);

  // Get upload signed URL for photo
  const { signedUploadUrl, signedDownloadUrl, file_name } =
    await EvidenceAPI.EvidencePhotos.uploadEvidencePhoto({
      evidence_id,
      file_name: file.name,
      file_size: file.size,
      file_type: file.type,
      uuid: file.uuid,
    });

  // upload to signed URL
  await EvidenceAPI.EvidencePhotos.uploadFile({
    url: signedUploadUrl,
    file: file,
    onUploadProgress: values?.onUploadProgress,
  });

  const { data } = await EvidenceAPI.EvidencePhotos.createEvidencePhoto({
    evidence_id,
    file_name, // use file name returned by upload API, incase it was changed due to duplicate
    file_size: file.size,
    file_type: file.type,
    hashes,
    uuid: file.uuid,
  });

  return {
    signedDownloadUrl,
    evidence_id: parseInt(evidence_id, 10),
    image_id: data.image_id,
    file_name, // use file name returned by upload API, incase it was changed due to duplicate
    file_size: file.size,
    file_type: file.type,
    uuid: file.uuid,
  };
};

const PhotosContent = styled(({ className, evidenceInfo }) => {
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: ["evidence:photos", { evidence_id: evidenceInfo.evidence_id }],
    queryFn: () =>
      EvidenceAPI.getEvidencePhotos({ evidence_id: evidenceInfo.evidence_id }),
  });

  const handleFileUpload = async (event) => {
    const files = Array.from(event.target.files).map((file) => {
      file.uuid = shortUUID.generate();
      return file;
    });

    // update the cache with the new file placeholders
    queryClient.setQueryData(
      ["evidence:photos", { evidence_id: evidenceInfo.evidence_id }],
      (oldData) => {
        return [
          ...oldData,
          ...files.map((file) => ({
            image_path: file.name,
            uploading: true,
            uploadPercentage: 0,
            uuid: file.uuid,
            evidence_id: evidenceInfo.evidence_id,
          })),
        ];
      }
    );

    for (const file of files) {
      uploadFile({
        evidence_id: evidenceInfo.evidence_id,
        file,
        onUploadProgress: ({ uploadPercentage }) => {
          queryClient.setQueryData(
            ["evidence:photos", { evidence_id: evidenceInfo.evidence_id }],
            (oldData) => {
              return oldData.map((i) => {
                if (i.uuid === file.uuid) {
                  return {
                    ...i,
                    uploadPercentage,
                  };
                }
                return i;
              });
            }
          );
        },
      }).then((res) => {
        // update the uploading status
        queryClient.setQueryData(
          ["evidence:photos", { evidence_id: res.evidence_id }],
          (oldData) => {
            return oldData.map((i) => {
              if (i.uuid === file.uuid) {
                return {
                  ...i,
                  uploading: false,
                  uploadPercentage: 100,
                  image_id: res.image_id,
                  image_path: res.file_name,
                  image_full_path: res.signedDownloadUrl,
                };
              }
              return i;
            });
          }
        );
      });
    }
  };

  if (!data) return null;

  return (
    <div className={className}>
      {true && (
        <>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="addEviPhotos-button"
            multiple
            type="file"
            onChange={handleFileUpload}
            disabled={!hasPermission(MONOLITH_PERMISSIONS.EVIDENCE_UPDATE)}
          />
          <label htmlFor="addEviPhotos-button">
            <Button
              variant="contained"
              color="primary"
              size="small"
              component="span"
              disabled={!hasPermission(MONOLITH_PERMISSIONS.EVIDENCE_UPDATE)}
              style={{
                minWidth: "fit-content",
                fontSize: 11,
                padding: "3px 6px",
              }}
            >
              + Upload Photos
            </Button>
          </label>
        </>
      )}
      {data.length === 0 ? (
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          No evidence photos
        </div>
      ) : (
        <div className="photos-grid">
          {data?.map((item) => {
            return <EvidencePhotoItem key={item.uuid} item={item} />;
          })}
        </div>
      )}
    </div>
  );
})`
  .photos-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 15px;
    margin-top: 20px;
  }
`;

const EvidencePhotoItem = styled(({ className, item }) => {
  const [open, setOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const queryClient = useQueryClient();

  const handleView = () => setOpen(!open);
  const handleDelete = () => setShowDeleteModal(true);
  const onDelete = () => {
    queryClient.setQueryData(
      ["evidence:photos", { evidence_id: item.evidence_id }],
      (oldData) => oldData.filter((i) => i.image_id !== item.image_id)
    );
  };

  return (
    <>
      <div className={className}>
        {item.uploading === true ? (
          <div className="photo-item">
            <div>
              <Loader
                message="Uploading..."
                style={{ height: 15, width: 15 }}
              />
            </div>
            <BasicProgressBar percentage={item.uploadPercentage} />
            <div className="photo-name">{item.image_path}</div>
          </div>
        ) : (
          <>
            <Overlay
              item={item}
              handleView={handleView}
              handleDelete={handleDelete}
            />
            <FileViewer
              file={item}
              open={open}
              handleClose={() => setOpen(false)}
            />
            <div className="photo-item">
              <img
                className="photo"
                src={item.image_full_path}
                alt="evidence photograph"
              />
              <div className="photo-name">{item.image_path}</div>
            </div>
            <DeleteModal
              open={showDeleteModal}
              handleClose={() => setShowDeleteModal(false)}
              onSubmit={() => onDelete()}
              defaultInfo={item}
            />
          </>
        )}
      </div>
    </>
  );
})`
  position: relative;
  width: 100%;
  .photo-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    height: 100%;
    &:hover {
      border-color: ${({ theme }) => theme.palette.divider};
    }
    .photo {
      max-height: 150px;
      max-width: 150px;
      object-fit: cover;
      image-orienation: from-image;
    }
    .photo-name {
      margin-top: 15px;
      font-size: 10px;
      color: ${({ theme }) => theme.palette.text.secondary};
    }
  }
`;

const Overlay = styled(({ className, item, handleView, handleDelete }) => {
  return (
    <div className={className}>
      <PhotoButtons
        item={item}
        handleView={handleView}
        handleDelete={handleDelete}
      />
    </div>
  );
})`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 5px;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
  border: 1px solid transparent;
  &:hover {
    background-color: #00000060;
    border: 1px solid ${({ theme }) => theme.palette.divider};
    & .photo-buttons {
      opacity: 1;
    }
  }
`;

const PhotoButtons = styled(
  ({ className, item, handleView, handleDelete = () => {} }) => {
    const theme = useTheme();
    return (
      <div className={className + " photo-buttons"}>
        <div
          className="photo-button"
          onClick={handleDelete}
          title="Delete Photo"
        >
          <DeleteOutlineOutlinedIcon
            style={{ color: theme.palette.error.main }}
          />
        </div>
        <a href={item.image_full_path} download>
          <div className="photo-button" title="Download">
            <FileDownloadOutlinedIcon />
          </div>
        </a>
        <div className="photo-button" onClick={handleView} title="Full Screen">
          <OpenInFullOutlinedIcon />
        </div>
      </div>
    );
  }
)`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  margin: 10px;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  .photo-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: ${({ theme }) => theme.palette.divider};
    }
  }
  & svg {
    font-size: 15px;
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

const DeleteModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    EvidenceAPI.deleteEvidencePhoto({
      image_id: defaultInfo.image_id,
    }).then(() => {
      enqueueSnackbar(`Evidence Photo Deleted.`, {
        variant: "success",
      });
      onSubmit();
    });
    handleClose();
  };

  const handleCancel = () => handleClose();

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 400,
          backgroundColor: theme.palette.background.default,
          position: "absolute",
          left: "50%",
          top: "20%",
          transform: "translate(-35%, -20%)",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Delete Evidence Photo
        </div>
        <div style={{ margin: "10px 0px" }}>
          Are you sure you want to delete this Evidence Photo?
        </div>
        <div style={{ margin: "10px 0px" }}>"{defaultInfo.image_path}"</div>
        <div
          style={{
            marginTop: 30,
          }}
        >
          <ToolBarItems
            submitText="Delete Photo"
            submitColor="error"
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PhotosContent;
