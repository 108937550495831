import CasesApi from "../../../../api/cases/index.js";

import {
  createAttributeField as createEvidenceField,
  deleteAttributeField as deleteEvidenceField,
  getCustomEvidenceAttributes,
  getEvidenceTypes,
  updateAttributeField as updateEvidenceField,
} from "../../../../api";

import {
  getCustomAttributes as getAcquisitionFields,
  updateAttributeField as updateAcquisitionField,
  createAttributeField as createAcquisitionField,
  deleteAttributeField as deleteAcquisitionField,
} from "../../../../api/acquisitions";

import {
  getCustomInquiryAttributes,
  updateAttributeField as updateInquiryField,
  createAttributeField as createInquiryField,
  deleteAttributeField as deleteInquiryField,
} from "../../../../api/inquiries";

import {
  getStorageCustomAttributes,
  updateStorageCustomField as updateStorageField,
  createStorageCustomField as createStorageField,
  deleteStorageCustomField as deleteStorageField,
} from "../../../../api/storage";
import { CUSTOM_FIELD_CATEGORIES } from "./customFieldConstants";

const customFieldAPIBundler = [
  {
    id: CUSTOM_FIELD_CATEGORIES.CASES,
    description:
      "Create custom fields for cases. These custom fields will appear when creating a new case and will also show as a column in the case grid.",
    createField: CasesApi.createCaseCustomField,
    updateField: CasesApi.updateCaseCustomField,
    deleteField: CasesApi.deleteCaseCustomField,
    getFields: CasesApi.getCaseCustomAttributes,
    getTypes: null, // Since there's no getTypes for CasesAPI
  },
  {
    id: CUSTOM_FIELD_CATEGORIES.EVIDENCE,
    description:
      "Create custom fields for evidence items. These custom fields will appear when creating new evidence items and will also show as a column in the evidence grid. You can associate custom fields with a specific evidence type so that it is only available when that evidence type is selected.",
    createField: createEvidenceField,
    updateField: updateEvidenceField,
    deleteField: deleteEvidenceField,
    getFields: getCustomEvidenceAttributes,
    getTypes: getEvidenceTypes,
  },
  {
    id: CUSTOM_FIELD_CATEGORIES.ACQUISITIONS,
    description:
      "Create custom fields for acquisition items. These custom fields will appear when creating new acquisition items and will also show as a column in the acquisition grid.",
    createField: createAcquisitionField,
    updateField: updateAcquisitionField,
    deleteField: deleteAcquisitionField,
    getFields: getAcquisitionFields,
    getTypes: null, // Since there's no getTypes for AcquisitionsAPI
  },
  {
    id: CUSTOM_FIELD_CATEGORIES.INQUIRIES,
    description:
      "Create custom fields for inquiries. These custom fields will appear when creating new inquiries and will also show as a column in the inquiry grid.",
    createField: createInquiryField,
    updateField: updateInquiryField,
    deleteField: deleteInquiryField,
    getFields: getCustomInquiryAttributes,
    getTypes: null, // Since there's no getTypes for InquiriesAPI
  },
  {
    id: CUSTOM_FIELD_CATEGORIES.STORAGE,
    description:
      "Create custom fields for storage. These custom fields will appear when creating new storage and will also show as a column in the storage grid.",
    createField: createStorageField,
    updateField: updateStorageField,
    deleteField: deleteStorageField,
    getFields: getStorageCustomAttributes,
    getTypes: null, // Since there's no getTypes for CaseStorageAPI
  },
];

export default customFieldAPIBundler;
