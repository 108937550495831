import styled from "styled-components";
import ComboButton from "../../Monolith-UI/ComboButton/ComboButton.js";
import { useTheme } from "@mui/material";
import LabelsAPI from "../../api/labels/label_api.js";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import PrinterSelectModal from "../../utils/labels/PrinterSelectPopup.js";

const OverviewContent = styled(({ className, evidenceInfo }) => {
  const theme = useTheme();
  const [showPrintLabelModal, setShowPrintLabelModal] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(null);

  const { data } = useQuery({
    queryKey: ["item-labels"],
    queryFn: () => LabelsAPI.getLabels(),
  });

  const handlePrintLabel = (label) => {
    setSelectedLabel(label);
    setShowPrintLabelModal(true);
  };

  const onPrintLabel = () => {};

  return (
    <div className={className}>
      <div className="action-menu">
        <ComboButton
          type="dropDown"
          data={data || []}
          title={"Actions"}
          showDropdownIcon={true}
          displayField="label_name"
          variant="outlined"
          useSelectMode={false}
          onItemSelect={handlePrintLabel}
          textColor={theme.palette.text.primary}
        >
          Print Label
        </ComboButton>
      </div>
      <div className="description-container">
        <div className="description-header">Description</div>
        <div>{evidenceInfo.description || "No Description"}</div>
      </div>
      <PrinterSelectModal
        open={showPrintLabelModal}
        handleClose={() => setShowPrintLabelModal(false)}
        onSubmit={() => onPrintLabel()}
        defaultInfo={{ evidence: evidenceInfo }}
        label={selectedLabel}
      />
    </div>
  );
})`
  .action-menu {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
  }
  .description-container {
    padding: 20px;
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 5px;
    white-space: pre-wrap;
    font-size: 12px;
  }
  .description-header {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 12px;
    font-weight: 500;
    cursor: default;
    margin-bottom: 10px;
  }
`;

export default OverviewContent;
