import { FC } from "react";
import { FormTitle, FormWrapper } from "./components/MonolithFormComponents";
import DefaultContactForm, {
  ContactFormData,
  EditContactFormData,
} from "./DefaultContactForm";
import { updateContact } from "@/api";
import { useSnackbar, VariantType } from "notistack";
import { usePermissions } from "@/hooks/usePermissions";
import LoggingAPI from "@/api/logging";
import { Contact } from "@/types";

interface EditContactFormProps {
  width?: number | undefined;
  defaultFormData: Contact;
  onSubmit: () => void;
  onCancel: () => void;
}

const EditContactForm: FC<EditContactFormProps> = ({
  width,
  defaultFormData,
  onSubmit,
  onCancel,
}) => {
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (
    data: ContactFormData,
    diffData?: EditContactFormData
  ) => {
    try {
      updateContact(defaultFormData.client_id, diffData);
      onSubmit?.();
      enqueueSnackbar(`Contact ${data?.name || ""} Edited`, {
        variant: "success" as VariantType,
      });

      LoggingAPI.createLog({
        user_id: currentUser.user_id,
        message: `Edited Contact: ${data?.name}`,
        event: "contact:update",
        object_type: "contact",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        `An error occurred while editing contact ${
          data?.name || ""
        }. Please try again.`,
        { variant: "error" as VariantType }
      );
    }
  };

  return (
    <FormWrapper width={width}>
      <FormTitle>Edit Contact</FormTitle>
      <DefaultContactForm
        onSubmit={handleSubmit}
        onCancel={onCancel}
        defaultFormData={defaultFormData}
        buttonProps={{ submitText: "Edit Contact" }}
      />
    </FormWrapper>
  );
};
export default EditContactForm;
