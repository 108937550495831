import axios from "axios";

export * from "./users";
export * from "./licensing";
export * from "./cases";
export * from "./evidence";
export * from "./quality_assurance";
export * from "./offices";
export * from "./clients";
export * from "./logging";
export * from "./labels";
export * from "./contacts";
export * from "./storage";
export * from "./software";
export * from "./locations";
export * from "./orginfo";
export * from "./equipment";
export * from "./reports";
export { AnalysisAPI } from "./Analysis";
export { default as AuditsAPI } from "./Audits";

export async function getAppTraceData() {
  return axios
    .get(`https://www.cloudflare.com/cdn-cgi/trace`, {
      transformRequest: (data, headers) => {
        delete headers.common["Authorization"];
        // Remove all custom headers
        Object.keys(headers.common).forEach((key) => {
          if (key.startsWith("x-")) delete headers.common[key];
        });
      },
      timeout: 1500,
      withCredentials: false,
    })
    .catch((error) => {
      console.trace(error);
      return "Could not pull Trace data.";
    })
    .then((response) => {
      return response.data;
    });
}

export async function getIpAddress() {
  return null;
}

export async function getCurrentMonolithVersion() {
  return axios
    .get(`${window.origin}/version`, {
      timeout: 500,
    })
    .catch((error) => {
      console.trace(error);
      return undefined;
    })
    .then((response) => {
      if (!response) return undefined;
      return response.data;
    });
}

export async function getGPSposition() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve(position);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export async function getGeoLocation() {
  const position = await getGPSposition();

  if (!position) return null;

  const { latitude, longitude } = position.coords;

  const res = await fetch(
    `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
  );

  const data = await res.json();

  return data;
}

export async function getLocationdata() {
  const [geoLocation, ipAddress] = await Promise.all([
    getGeoLocation(),
    getIpAddress(),
  ]);

  return { geoLocation, ipAddress };
}
