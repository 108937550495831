import { createContext, useState } from "react";
import { SETTINGS, THEMES } from "../constants";

interface NoteSettings {
  setting: string;
  value: boolean;
}

interface UserSettings {
  theme: string;
  notes: NoteSettings[];
}

const initialState = {
  theme: THEMES.DARK,
  noteSettings: [{ setting: SETTINGS.NOTES_TOGGLE_TOOLBAR, value: true }],
  setTheme: (theme: string) => {},
  setNoteSettings: (settings: NoteSettings[]) => {},
};
const UserSettingsContext = createContext(initialState);

function UserSettingsProvider({ children }: { children: React.ReactNode }) {
  const initialState = (): UserSettings => {
    const notes = localStorage.getItem("notesSettings");
    const theme = localStorage.getItem("theme");

    const userSettings = {
      theme: theme ? JSON.parse(theme) : THEMES.DARK,
      notes: notes
        ? JSON.parse(notes)
        : [{ setting: SETTINGS.NOTES_TOGGLE_TOOLBAR, value: true }],
    };

    return userSettings;
  };

  const [theme, _setTheme] = useState(initialState().theme);
  const [noteSettings, _setNoteSettings] = useState(initialState().notes);

  const setTheme = (theme: string) => {
    localStorage.setItem("theme", JSON.stringify(theme));
    _setTheme(theme);
  };

  const setNoteSettings = (notes: NoteSettings[]) => {
    localStorage.setItem("notesSettings", JSON.stringify(notes));
    _setNoteSettings(notes);
  };

  return (
    <UserSettingsContext.Provider
      value={{ theme, noteSettings, setTheme, setNoteSettings }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
}

export { UserSettingsProvider, UserSettingsContext };
