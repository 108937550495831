import ContactsAPI from "../../api/contacts/index.js";

const ContactsFilterDimensions = [
  {
    name: "Type",
    field: "contact_type",
    id: "contact_type",
    type: "string",
    mode: "multi-select",
    key: ["contacts:types"],
    selectionDisplayName: "contact_type",
    selectionIdField: "contact_type",
    queryFn: (params) => ContactsAPI.getContactTypes(),
  },
  {
    name: "Organization",
    field: "organization",
    id: "organization",
    type: "string",
    mode: "multi-select",
    key: ["contacts:organizations"],
    selectionDisplayName: "organization",
    selectionIdField: "organization",
    queryFn: (params) => ContactsAPI.getOrganizations(),
  },
  {
    name: "Unique ID",
    field: "unique_id",
    id: "unique_id",
    type: "string",
    mode: "text",
    key: ["contacts:list"],
    selectionDisplayName: "unique_id",
    selectionIdField: "unique_id",
    queryFn: (params) => ContactsAPI.getContacts(),
  },
];

export default ContactsFilterDimensions.slice();
