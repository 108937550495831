import { useTheme } from "@mui/material";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import ComboButton from "../ComboButton/ComboButton.js";
import TaskButton from "../TaskButton.js";
import { useRef } from "react";
import moment from "moment";

export const Operators = [
  {
    name: "Is Any Of",
    operator: "isAnyOf",
    mode: ["multi-select"],
    type: "multi-select",
  },
  {
    name: "Is None Of",
    operator: "isNoneOf",
    mode: ["multi-select"],
    type: "multi-select",
  },
  { name: "Is After", operator: "isAfter", mode: ["date"], type: "date" },
  { name: "Is Before", operator: "isBefore", mode: ["date"], type: "date" },
  { name: "Is Between", operator: "isBetween", mode: ["date"], type: "date" },
  {
    name: "Contains",
    operator: "contains",
    mode: ["multi-select", "text"],
    type: "text",
  },
  {
    name: "Does Not Contain",
    operator: "doesNotContain",
    mode: ["multi-select", "text"],
    type: "text",
  },
  {
    name: "Is",
    operator: "equals",
    mode: ["multi-select", "text"],
    type: "text",
  },
  {
    name: "Is Not",
    operator: "doesNotEqual",
    mode: ["multi-select", "text"],
    type: "text",
  },
];

const ConditionRow = ({
  condition,
  defaultValue = null,
  onClose = () => {},
  onChange = () => {},
  readOnly = false,
}) => {
  const theme = useTheme();
  const [selectedOperator, setSelectedOperator] = useState(condition.operator);
  const timer = useRef(null);

  const [currentSelections, setCurrentSelections] = useState(
    defaultValue?.map((dv) => {
      if (condition.operator?.operator?.toLowerCase()?.includes("contain"))
        return dv;
      if (!condition.selectionIdField) return dv;
      return condition.selections.find(
        (s) => s[condition.selectionIdField] === dv[condition.selectionIdField]
      );
    }) || null
  );

  const handleOperatorSelect = (operator) => {
    const isTypeChanged = operator?.type !== selectedOperator?.type;

    setSelectedOperator(operator);
    let queryComplete = false; // if all conditions are met, this will be true

    if (condition.mode === "date") {
      if (operator?.operator.includes("Between")) {
        if (condition.value?.length === 2) {
          // check between status
          queryComplete = !isTypeChanged;
        }
      } else {
        // ensure that the date filter only has one value when operator is not "between"
        if (currentSelections?.length > 0) {
          condition.value = [currentSelections[0]];
          // check between status
          queryComplete = !isTypeChanged;
        }
      }
    } else if (condition.value?.length > 0) queryComplete = !isTypeChanged;

    if (isTypeChanged) {
      setCurrentSelections(null);
    }

    onChange({ ...condition, operator: operator, queryComplete });
  };

  const handleValueChange = (data) => {
    let queryComplete = false; // if all conditions are met, this will be true

    if (condition.mode === "date") {
      if (condition.operator?.operator.includes("Between")) {
        const newValues = [
          data.start
            ? data.start
            : currentSelections
            ? currentSelections[0]
            : null,
          data.end ? data.end : currentSelections ? currentSelections[1] : null,
        ];

        if (newValues[0] && newValues[1]) queryComplete = true;

        setCurrentSelections(newValues);

        onChange({
          ...condition,
          value: newValues,
          queryComplete,
        });
      } else {
        queryComplete = true;
        setCurrentSelections([
          condition.selectionIdField
            ? moment(data[condition.selectionIdField]).toISOString()
            : moment(data).toISOString(),
        ]);

        onChange({
          ...condition,
          operator: data ? condition.operator : Operators[0],
          value: data
            ? [
                condition.selectionIdField
                  ? moment(data[condition.selectionIdField]).toISOString()
                  : moment(data).toISOString(),
              ]
            : [],
          queryComplete,
        });
      }
    } else {
      queryComplete = true;
      setCurrentSelections([...(currentSelections || []), data]);

      onChange({
        ...condition,
        value: [...(currentSelections || []), data],
        queryComplete,
      });
    }
  };

  const handleValueRemove = (data) => {
    const newValues = currentSelections.filter(
      (value) =>
        value[condition.selectionIdField] !== data[condition.selectionIdField]
    );

    setCurrentSelections(newValues);

    onChange({
      ...condition,
      value: newValues,
      queryComplete: true,
    });
  };

  const handleTextChange = (data) => {
    // set delay loop to prevent too many queries

    if (timer.current) clearTimeout(timer.current);

    timer.current = setTimeout(() => {
      if (!data) return;
      onChange({
        ...condition,
        value: [data],
        queryComplete: true,
      });
    }, 500);
  };

  const currentOperators = Operators.filter((operator) => {
    if (condition.operators) {
      return condition.operators.includes(operator.operator);
    }

    return operator.mode.includes(condition?.mode);
  });

  // set default operator
  useEffect(() => {
    handleOperatorSelect(condition.operator || currentOperators[0]);
  }, []);

  return (
    <div style={{ marginRight: 5 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <div>
          <ComboButton
            value={condition}
            displayField="name"
            variant="contained"
            type="button"
            enabled={false}
          />
        </div>
        <div>
          <ComboButton
            type="dropDown"
            data={currentOperators}
            value={condition.operator || currentOperators[0] || { name: "" }}
            displayField="name"
            variant="contained"
            useSelectMode={true}
            enabled={!readOnly}
            onItemSelect={handleOperatorSelect}
            showSearch={true}
          />
        </div>
        <div style={{ display: "flex" }}>
          {selectedOperator?.type === "text" ? (
            <ComboButton
              type="textBox"
              onChange={handleTextChange}
              value={
                currentSelections?.length &&
                typeof currentSelections[0] === "string"
                  ? currentSelections[0]
                  : ""
              }
              autoFocus={true}
            />
          ) : condition.mode === "multi-select" ? (
            <ComboButton
              type="multi-select"
              data={condition.selections.sort((a, b) =>
                a[condition.selectionDisplayName].localeCompare(
                  b[condition.selectionDisplayName]
                )
              )}
              value={currentSelections}
              displayField={condition.selectionDisplayName}
              idField={condition.selectionIdField}
              variant="contained"
              useSelectMode={true}
              selectedItems={currentSelections}
              onItemSelect={(data) => {
                handleValueChange(data);
              }}
              onItemDeSelect={(data) => {
                handleValueRemove(data);
              }}
              closeOnSelect={false}
              showSearch={true}
              enabled={!readOnly}
            />
          ) : condition.mode === "date" ? (
            <>
              {condition?.operator?.name.toLowerCase().includes("between") ? (
                <>
                  <ComboButton
                    type="dateBox"
                    variant="contained"
                    onItemSelect={(d) => handleValueChange({ start: d })}
                    value={condition.value?.[0]}
                    useSelectMode={true}
                    enabled={!readOnly}
                  />
                  <ComboButton
                    value="And"
                    variant="contained"
                    type="button"
                    enabled={!readOnly}
                    textColor={theme.palette.text.primary}
                  />
                  <ComboButton
                    type="dateBox"
                    variant="contained"
                    onItemSelect={(d) => handleValueChange({ end: d })}
                    value={condition.value?.[1]}
                    useSelectMode={true}
                    enabled={!readOnly}
                  />
                </>
              ) : (
                <ComboButton
                  type="dateBox"
                  variant="contained"
                  value={condition.value?.[0]}
                  onItemSelect={(d) => handleValueChange(d)}
                  useSelectMode={true}
                  enabled={!readOnly}
                />
              )}
            </>
          ) : null}
        </div>
        {!readOnly && (
          <div>
            <TaskButton
              onClick={() => onClose(condition)}
              style={{
                fontSize: 10,
                padding: "3px 3px",
              }}
              variant="text"
            >
              <ClearIcon style={{ fontSize: 17 }} />
            </TaskButton>
          </div>
        )}
      </div>
    </div>
  );
};

export const useQueryFilter = ({
  dimensions,
  defaultValue = null,
  queryFilter,
  onQuerySet = (newFilter, query) => {},
  stateStoring = {},
  metadata = null,
  buttonProps = {},
  readOnly = false,
}) => {
  const theme = useTheme();
  const queryFilterRef = useRef();
  const queryClient = useQueryClient();
  const [query, setQuery] = useState(
    defaultValue
      ? defaultValue
      : stateStoring?.enabled && stateStoring?.storageKey
      ? JSON.parse(localStorage.getItem(stateStoring.storageKey))?.filter || {
          conditions: [],
        }
      : { conditions: [] }
  );

  let queries = dimensions
    .filter((dimension) => dimension?.queryFn) // Only query dimensions that have a queryFn
    .map((dimension) => {
      const computedKey = [...dimension.key];
      if (metadata) computedKey.push(metadata);
      return {
        queryKey: computedKey,
        queryFn: () => dimension.queryFn(metadata),
        enabled: true,
      };
    });

  // Requried to prevent infinite loop within useQueries hook
  if (queries.length === 0)
    queries = [{ queryKey: "default", queryFn: () => {} }];

  useQueries({ queries: queries });

  const handleAddCondition = (selectedData) => {
    const computedKey = [...(selectedData?.key || [])];
    if (metadata) computedKey.push(metadata);

    setQuery({
      ...query,
      conditions: [
        ...query.conditions,
        {
          ...selectedData,
          id: nanoid(),
          operator: null,
          value: null,
          queryComplete: false,
          selections: selectedData.key
            ? [
                ...queryClient.getQueryData(computedKey),
                ...(selectedData.addtionalSelections || []),
              ]
            : selectedData.options || [],
        },
      ],
    });
  };

  const handleRemoveCondition = (conditionID) => {
    const newConditions = query.conditions.filter(
      (condition) => condition.id !== conditionID
    );
    setQuery({ ...query, conditions: newConditions });
  };

  const handleUpdateCondition = (condition) => {
    const newConditions = query.conditions.map((c) => {
      if (c.id === condition.id) {
        return condition;
      }
      return c;
    });

    setQuery({ ...query, conditions: newConditions });

    // Trigger External Query if value conditions are met
  };

  const modelQuery = (query) => {
    return {
      conditions: query.conditions.map((condition) => {
        const useRawValue =
          condition?.mode === "date" || condition?.operator?.type === "text";

        return {
          field: condition.field,
          label: condition.name,
          mode: condition.mode,
          operator: condition.operator,
          value: useRawValue
            ? condition.value
            : condition?.value?.map?.((v) => v[condition.selectionIdField]),
          displayValue: useRawValue
            ? condition.value
            : condition?.value?.map?.((v) => v[condition.selectionDisplayName]),
          key: condition.key,
          id: condition.id,
        };
      }),
    };
  };

  useEffect(() => {
    const isQueryComplete = query.conditions.every(
      (condition) => condition.queryComplete
    );

    if (isQueryComplete) {
      if (stateStoring.enabled && stateStoring.storageKey) {
        let oldState =
          JSON.parse(localStorage.getItem(stateStoring.storageKey)) || {};
        localStorage.setItem(
          stateStoring.storageKey,
          JSON.stringify({ ...oldState, filter: query })
        );
      }
      onQuerySet(modelQuery(query), query);
    }
  }, [query]);

  useEffect(() => {
    if (queryFilter) {
      queryFilter.current = {
        clear: () => {
          setQuery({ conditions: [] });
        },
      };
    }
  }, [queryFilter]);

  const queryButton = (
    <ComboButton
      type="dropDown"
      data={dimensions}
      value={buttonProps.label || "Add Filter"}
      showDropdownIcon={true}
      displayField="name"
      variant={buttonProps?.variant || "outlined"}
      onItemSelect={handleAddCondition}
      textColor={theme.palette.text.primary}
      showSearch={true}
      dropDownTitle={() => {
        return (
          <div
            style={{
              margin: "5px 0px",
              padding: 3,
              color: theme.palette.text.secondary,
              display: "flex",
              alignItems: "center",
              minWidth: 200,
            }}
          >
            Select Filter Dimension
          </div>
        );
      }}
    />
  );

  const conditions =
    query?.conditions?.length > 0 ? (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignContent: "center",
          alignItems: "center",
          paddingTop: 5,
          paddingBottom: 5,
        }}
      >
        {query.conditions.map((condition) => {
          return (
            <ConditionRow
              key={condition.id}
              condition={condition}
              defaultValue={condition.value}
              onClose={() => handleRemoveCondition(condition.id)}
              onChange={(condition) => handleUpdateCondition(condition)}
              onMounted={(condition) => handleUpdateCondition(condition)}
              readOnly={readOnly}
            />
          );
        })}
      </div>
    ) : null;

  return {
    queryButton,
    conditions,
    resolvedQuery: modelQuery(query),
    queryFilterRef,
    queryFilter: {
      clear: () => {
        setQuery({ conditions: [] });
      },
    },
  };
};
