import { Popup } from "devextreme-react/ui/popup.js";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { deleteEvidence } from "../../api/index.js";
import ToolBarItems from "../ToolBarItems.js";
import LoggingAPI from "../../api/logging/index.js";
import { usePermissions } from "../../hooks/usePermissions";

const DeleteEvidencePopup = ({
  deleteEvidencePopup,
  onDelete = (deletedEvidence) => {},
}) => {
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const [evidenceInfo, setEvidenceInfo] = useState({});

  const handleDelete = () => {
    deleteEvidencePopup.current.instance.hide();
    deleteEvidence({
      evidence_id: evidenceInfo.evidence_id,
    }).then(() => {
      onDelete(evidenceInfo);
      LoggingAPI.createLog({
        case_id: parseInt(evidenceInfo.case_id),
        user_id: currentUser.user_id,
        message: `Deleted evidence item: ${evidenceInfo.evidence_number}`,
        event: "evidence:delete",
        object_type: "evidence",
        object_uuid: evidenceInfo.uuid,
      });
      enqueueSnackbar(`Evidence Item Deleted.`, {
        variant: "success",
      });
    });
  };

  const handleCancel = () => {
    deleteEvidencePopup.current.instance.hide();
  };

  return (
    <Popup
      ref={deleteEvidencePopup}
      showTitle={true}
      showCloseButton={true}
      defaultHeight={150}
      defaultWidth={450}
      title={`Delete Evidence Item (${evidenceInfo.evidence_number})`}
      onInitialized={(e) => {
        deleteEvidencePopup.current = { instance: e.component };
        deleteEvidencePopup.current.instance.setEvidenceInfo = setEvidenceInfo;
      }}
    >
      <div style={{ margin: "10px 0px" }}>
        Are you sure you want to delete this evidence item?
      </div>
      <ToolBarItems
        style={{ marginTop: 20 }}
        submitText="Delete Evidence"
        submitColor="error"
        onSubmit={handleDelete}
        onCancel={handleCancel}
      />
    </Popup>
  );
};

export default DeleteEvidencePopup;
