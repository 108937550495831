import { Card, Divider } from "@mui/material";
import moment from "moment";

export const TimeEntryDetails = ({ reportInfo }) => {
  return (
    <Card
      style={{
        padding: "20px",
        textAlign: "left",
        flexGrow: 1,
        flexBasis: "10px",
        marginRight: 15,
      }}
    >
      <div style={{ fontSize: "larger" }}>Report Details</div>
      <Divider sx={{ my: 2 }} />
      <div
        style={{
          minWidth: "300px",
          overflowY: "auto",
          maxHeight: "175px",
          paddingRight: "10px",
        }}
      >
        {reportInfo.report_id && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "5px",
            }}
          >
            <div>Report ID: </div>
            <div>{reportInfo.report_id}</div>
          </div>
        )}

        {reportInfo.name && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "5px",
            }}
          >
            <div>Report Name: </div>
            <div>{reportInfo.name}</div>
          </div>
        )}

        {reportInfo.created_at && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "5px",
            }}
          >
            <div>Created On:</div>
            <div>{moment(reportInfo.created_at).format("MMMM DD, YYYY")}</div>
          </div>
        )}

        {reportInfo.created_by && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "5px",
            }}
          >
            <div>Created By: </div>
            <div>{reportInfo.created_by}</div>
          </div>
        )}

        {reportInfo.category && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "5px",
            }}
          >
            <div>Category: </div>
            <div>{reportInfo.category}</div>
          </div>
        )}
      </div>
    </Card>
  );
};
