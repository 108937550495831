import { useTheme } from "@mui/material";

import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Loader from "../../../components/Loader";
import ToolBarItems from "../../../components/ToolBarItems";
import { getContacts } from "../../../api";

const ValidateContactsImport = styled(
  ({
    className,
    caseInfo,
    csvData,
    columnNames,
    columnMap,
    duplicateContacts,
    setDuplicateContacts = () => {},
    onValidated = () => {},
  }) => {
    const [isValidating, setIsValidating] = useState(true);
    const [errors, setErrors] = useState(null);
    const [preparedData, setPreparedData] = useState(null);

    const navigate = useNavigate();

    const checkforDuplicates = async (data, caseId) => {
      // Get Contacts currently assigned to the case
      const caseContactsResponse = await getContacts({ case_id: caseId });
      const caseContacts = caseContactsResponse.filter(
        (c) => c.email !== null && c.email !== undefined && c.email !== ""
      );

      const duplicateRecords = [];
      const dedupedContacts = [];
      // Get linked contacts that were provided in CSV
      data.forEach((contact) => {
        const existingContact = caseContacts.find(
          (c) => c.email === contact.email
        );
        if (existingContact) {
          duplicateRecords.push(existingContact);
        } else {
          dedupedContacts.push(contact);
        }
      });

      //  If duplicate records exist, return them
      return {
        newContacts: dedupedContacts,
        duplicateContacts:
          duplicateRecords.length > 0 ? duplicateRecords : null,
      };
    };

    useEffect(() => {
      (async () => {
        if (csvData && columnNames && columnMap) {
          const compiledData = compileImportData(csvData, columnMap);
          const errorResult = validateImportData(compiledData);
          if (errorResult?.length > 0) {
            setErrors(errorResult);
          } else {
            // Convert rows to create contact model and add case id
            const data = prepareData(compiledData, caseInfo.case_id);
            // Check for duplicates
            const { newContacts, duplicateContacts } = await checkforDuplicates(
              data,
              caseInfo.case_id
            );
            setDuplicateContacts(duplicateContacts);
            onValidated(newContacts);
            setPreparedData(newContacts);
          }
          setIsValidating(false);
        }
      })();
    }, [csvData, columnNames, columnMap]);

    useEffect(() => {
      if (!csvData || !columnNames || !columnMap)
        navigate(`/cases/${caseInfo.case_id}/contacts?v=import`);
    }, []);

    if (!csvData || !columnNames || !columnMap) return null;

    if (isValidating) {
      return (
        <div className={className}>
          <div style={{ width: 600 }}>
            <h2>Validating</h2>
            <Loader />
          </div>
        </div>
      );
    }

    if (errors) {
      return (
        <div className={className}>
          <div style={{ width: 600 }}>
            <h2>Errors</h2>
            <div>
              {errors?.map((error, index) => (
                <div key={index}>
                  Row {error.rowNumber} - {error.error} - {error.value}
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={className}>
        <div style={{ width: 600 }}>
          <h2>Ready to Import?</h2>
          {duplicateContacts && (
            <div>
              <div>
                We detected {duplicateContacts.length} contacts in this upload
                with emails matching current contacts. These contacts will be
                automatically assigned to the case.
              </div>
            </div>
          )}
          <div style={{ marginTop: 10 }}>
            {preparedData.length} Contacts will be imported.
          </div>
          <ExampleData data={preparedData[0]} columnMap={columnMap} />
          <ToolBarItems
            submitText="Start Import"
            onSubmit={() => {
              navigate(
                `/cases/${caseInfo.case_id}/contacts?v=import&s=progress`
              );
            }}
            onCancel={() => {
              navigate(`/cases/${caseInfo.case_id}/contacts`);
            }}
            style={{ marginTop: 25 }}
          />
        </div>
      </div>
    );
  }
)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 750px;
`;

const ExampleData = styled(({ className, data, columnMap }) => {
  const theme = useTheme();
  return (
    <div className={className}>
      <h3>Example Data</h3>
      {Object.entries(data)?.map(([key, value]) => {
        return (
          <div
            key={key}
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "5px 0",
            }}
          >
            <div style={{ color: theme.palette.text.secondary }}>
              {columnMap[key]}
            </div>
            <div>{value}</div>
          </div>
        );
      })}
    </div>
  );
})`
  margin-top: 35px;
  margin-bottom: 50px;
  border-radius: 5px;
`;

// Create data object that maps the csv data to users column mapping choices
const compileImportData = (csvData, columnMap) => {
  const compiledData = csvData.map((row) => {
    const compiledRow = {};
    Object.keys(columnMap).forEach((key) => {
      const csvColumn = columnMap[key];
      compiledRow[key] = row[csvColumn];
    });

    return compiledRow;
  });
  return compiledData;
};

// Model data in preparation for import
const prepareData = (importData, caseId) => {
  const preparedData = importData.map((row) => {
    const preparedRow = {};
    Object.keys(row).forEach((key) => {
      // trim data
      if (typeof row[key] === "string") row[key] = row[key].trim();

      preparedRow[key] = row[key];

      preparedRow.case_id = caseId;
    });
    return preparedRow;
  });
  return preparedData;
};

// Validate data
const validateImportData = (importData) => {
  const errors = [];
  for (const row of importData) {
    //check that acq name exists
    if (!row.name || row.name === "") {
      errors.push({
        rowNumber: row.rowNumber,
        error: "Contact Name is required",
        value: row.name,
      });
    }
  }
};

export default ValidateContactsImport;
