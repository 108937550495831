import {
  CircleDashed,
  CircleDotDashed,
  CircleDot,
  Check,
  X,
} from "lucide-react";

const TaskStatuses = [
  {
    status_id: 4,
    status_name: "Backlog",
    color: "#9e9e9e",
    icon: CircleDashed,
  },
  {
    status_id: 1,
    status_name: "Pending",
    color: "#ff9800",
    icon: CircleDotDashed,
  },
  {
    status_id: 3,
    status_name: "In Progress",
    color: "#3e88e0",
    icon: CircleDot,
  },
  {
    status_id: 2,
    status_name: "Complete",
    color: "limegreen",
    icon: Check,
  },
  {
    status_id: 5,
    status_name: "Canceled",
    color: "#f44336",
    icon: X,
  },
];

export default TaskStatuses;
