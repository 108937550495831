import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Table, { Column, useTable } from "../../../Monolith-UI/Table/Table.js";
import { getDateFormat } from "../../../utils/date-format";
import AuditsAPI from "@/api/Audits/Audits";

const PassIndicator = styled(({ className, passed }) => {
  return <div className={className}>{passed ? "Pass" : "Fail"}</div>;
})`
  display: inline-block;
  color: ${({ passed, theme }) =>
    passed ? theme.palette.success.main : theme.palette.error.main};
  border-radius: 5px;
  padding: 2.5px 5px;
  font-size: 0.75rem;
  font-weight: 500;
  background-color: ${({ passed, theme }) =>
    passed ? theme.palette.success.main : theme.palette.error.main}25;
`;

const AuditContent = styled(({ className, storageInfo }) => {
  const { data } = useQuery({
    queryKey: ["audits:logs", { object_uuid: storageInfo.uuid }],
    queryFn: () => AuditsAPI.getAuditLogs({ object_uuid: storageInfo.uuid }),
  });

  const table = useTable();

  return (
    <div className={className}>
      <Table
        data={data ? data.data : []}
        keyValue="uuid"
        tableInstance={table}
        columnProps={{ minWidth: 150, width: 150 }}
      >
        <Column
          dataField="audit_link"
          caption="Audit"
          render={(data) => {
            return (
              <Link to={`/audits/${data.audit.uuid}/audit-items`}>
                <span title="View Audit">{data.audit.name}</span>
              </Link>
            );
          }}
        />
        <Column
          dataField="created_on"
          caption="Timestamp"
          dataType="date"
          format={{
            type: getDateFormat({ isMoment: true, includeTime: false }),
          }}
        />
        <Column
          dataField="created_by"
          caption="User"
          render={(data) => data.created_by.full_name}
        />
        <Column
          dataField="passed_audit"
          caption="Audit Status"
          render={(data) => <PassIndicator passed={data.passed_audit} />}
        />
        <Column dataField="notes" caption="Notes" />
      </Table>
    </div>
  );
})`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;

  a {
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${({ theme }) => theme.palette.primary.light};
    }
  }
`;

export default AuditContent;
