import styled from "styled-components";
import { TimelineEventDefs } from "../../../../../components/ColumnDefinitions";

const DetailItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DetailLabel = styled.div`
  font-weight: 500;
  margin-right: 8px;
  min-width: 125px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const DetailValue = styled.div`
  white-space: pre-wrap;
  overflow-y: auto;
`;

const MainDetails = styled(({ className, defaultEventData }) => {
  return (
    <div className={className}>
      <h3>Event Details</h3>
      <div className="event-details">
        {TimelineEventDefs.filter((col) => col.dataField !== "description").map(
          (col) => {
            const key = col.dataField;
            const label = col.caption;
            const value = col?.render
              ? col.render(defaultEventData)
              : defaultEventData[key];

            return (
              <DetailItem key={key}>
                <DetailLabel>{label}</DetailLabel>
                <DetailValue>{value}</DetailValue>
              </DetailItem>
            );
          }
        )}
        <DetailItem>
          <DetailLabel>Description</DetailLabel>
        </DetailItem>
        <div className="description">
          <DetailValue>{defaultEventData.description || "None"}</DetailValue>
        </div>
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  h3 {
    margin: 0px;
    margin-bottom: 10px;
  }

  .event-details {
    display: flex;
    flex-direction: column;
    gap: 8px;

    flex: 1 1 auto;
  }

  .description {
    max-height: none;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 0px;
  }
`;

const FlyoutDetailsTab = styled(({ className, defaultEventData }) => {
  const eventData = defaultEventData;

  return (
    <div className={className}>
      <MainDetails defaultEventData={eventData} />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;

  padding: 10px 0px;
`;

export default FlyoutDetailsTab;
