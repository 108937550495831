import styled from "styled-components";

const PassIndicator = styled(({ className, passed }) => {
  return (
    <div className={className}>
      {passed === true ? "Pass" : passed === false ? "Fail" : "Pending"}
    </div>
  );
})`
  display: inline-block;
  color: ${({ passed, theme }) =>
    passed === true
      ? theme.palette.success.main
      : passed === false
      ? theme.palette.error.main
      : theme.palette.text.secondary};
  border-radius: 5px;
  padding: 2.5px 5px;
  font-size: 0.75rem;
  font-weight: 500;
  background-color: ${({ passed, theme }) =>
    passed === true
      ? theme.palette.success.main + "30"
      : passed === false
      ? theme.palette.error.main + "30"
      : theme.name === "DARK"
      ? "#212121"
      : "#dddddd"};
`;

export default PassIndicator;
