import { useSnackbar } from "notistack";
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchApi from "../api/search";

export const ScannerModes = {
  SCAN_ONLY: "scan_only",
  SEARCH_AND_NAVIGATE: "search_and_navigate",
};

const BarcodeScannerContext = createContext();

export const BarcodeScannerProvider = ({ children }) => {
  const [scannedValue, setScannedValue] = useState("");
  const [scannerMode, setScannerMode] = useState(
    ScannerModes.SEARCH_AND_NAVIGATE
  );
  const [scannerEnabled, setScannerEnabled] = useState(true);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleSearchAndNavigate = (barcode) => {
    SearchApi.globalSearch({
      searchText: barcode,
      include_evidence: true,
      include_storage: true,
    }).then((results) => {
      if (results) {
        if (results.length > 1) {
          enqueueSnackbar(
            `Barcode scan found ${results.length} items.  Please scan a unique value.`,
            {
              variant: "warning",
            }
          );
        } else if (results[0].type.toLowerCase() === "evidence") {
          navigate(`/evidence/${results[0].item_id}/overview`);
        } else if (results[0].type.toLowerCase() === "storage") {
          navigate(`/storage/${results[0].item_id}`);
        }
      }
    });
  };

  useEffect(() => {
    let barcode = "";
    let interval = null;

    const handleKeyDown = (event) => {
      if (event.target.isContentEditable) return;

      // check if event is on input field
      if (event.target.tagName === "INPUT") return;

      // check if event is on textarea field
      if (event.target.tagName === "TEXTAREA") return;

      if (interval) clearInterval(interval);
      if (event.code === "Enter") {
        if (barcode) {
          setScannedValue(barcode);
          if (scannerMode === ScannerModes.SEARCH_AND_NAVIGATE) {
            handleSearchAndNavigate(barcode);
          }
          barcode = "";
          return;
        }
        barcode = "";
        return;
      }
      if (event.code && !event.code.includes("Shift")) barcode += event.key;
      interval = setInterval(() => (barcode = ""), 50);
    };

    document.addEventListener("keydown", handleKeyDown);
    console.log("Barcode scanner listener enabled");

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [scannerMode]);

  return (
    <BarcodeScannerContext.Provider
      value={{
        scannerMode,
        setScannerMode,
        scannerEnabled,
        setScannerEnabled,
        scannedValue,
      }}
    >
      {children}
    </BarcodeScannerContext.Provider>
  );
};

export const useBarcodeScanner = () => {
  const context = useContext(BarcodeScannerContext);

  if (context === undefined) {
    throw new Error(
      "useBarcodeScanner must be used within a BarcodeScannerProvider"
    );
  }
  return context;
};
