import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import { getCurrentMonolithVersion, getOrgInfo } from "../../api";
import { THEMES } from "../../constants";
import useSettings from "../../hooks/useSettings";
import { useAppSearch } from "../AppSearchBox/AppSearchBox";
import { Button, Switch } from "@monolith-forensics/monolith-ui";
import {
  ArrowLeftToLineIcon,
  MoonIcon,
  SunIcon,
  SearchIcon,
  ArrowRightToLineIcon,
  DownloadIcon,
  RefreshCwIcon,
} from "lucide-react";

import * as Tooltip from "@radix-ui/react-tooltip";

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: ${({ width }) => (Number.isInteger(width) ? width + "px" : width)};
  max-width: ${({ width }) => (Number.isInteger(width) ? width + "px" : width)};
  min-width: ${({ width }) => (Number.isInteger(width) ? width + "px" : width)};
`;

const Brand = styled.div`
  background-color: ${(props) => props.theme.sidebar.background};
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);

  height: 85px;
  min-height: 85px;
  max-height: 85px;
  padding: 5px;
  display: flex;
  justify-content: center;

  img {
    object-fit: contain;
    width: 85%;
    height: 90%;
  }
`;

const TooltipContent = styled(Tooltip.Content)`
  outline: none;
  .inner-content {
    outline: none;
    user-select: none;
    display: flex;
    flex-direction: column;
    padding: 5px 0;

    margin-bottom: 5px;

    font-size: 0.75rem;
    min-width: 150px;

    background: ${(props) => props.theme.palette.background.default};
    border: 1px solid ${(props) => props.theme.palette.input.border};
    border-radius: 6px;
  }
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.palette.text.primary};
  border: 0px solid transparent;
  padding: 4px 15px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  padding-right: 0;

  &:hover {
    background-color: ${(props) => props.theme.palette.action.hover};
  }
`;

const UpdateButton = ({ collapsed }) => {
  const [show, setShow] = useState(false);
  const isElectron = process.env.REACT_APP_PLATFORM === "electron";
  const isDev = process.env.NODE_ENV === "development";

  // Check for updates in Electron App using interval
  useEffect(() => {
    if (isElectron && !isDev) {
      const interval = setInterval(() => {
        window.electronAPI.checkForUpdate();
      }, 30000);

      window.electronAPI.onUpdateAvailable(() => {
        clearInterval(interval);
      });

      window.electronAPI.onUpdateDownloaded(() => {
        setShow(true);
      });

      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  // Check for updates to web app using interval
  useEffect(() => {
    if (!isElectron && !isDev) {
      const interval = setInterval(() => {
        getCurrentMonolithVersion().then((result) => {
          if (
            result &&
            result.version !== process.env.REACT_APP_MONOLITH_VERSION
          ) {
            setShow(true);
            clearInterval(interval);
          }
        });
      }, 30000);

      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  const handleClick = () => {
    if (isElectron) window.electronAPI.updateMonolith();
    else window.location.reload();
  };

  if (!show) return null;

  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <StyledButton
            size="xs"
            variant="text"
            color="limegreen"
            onClick={handleClick}
            leftSection={
              isElectron ? (
                <DownloadIcon size={15} />
              ) : (
                <RefreshCwIcon size={15} />
              )
            }
            style={{ color: "limegreen" }}
          >
            {collapsed ? "" : "Update Available"}
          </StyledButton>
        </Tooltip.Trigger>
        {collapsed && (
          <Tooltip.Portal>
            <TooltipContent side="right" align="center" collisionPadding={15}>
              <div className="inner-content" style={{ marginLeft: 5 }}>
                <ListItem>Update Available</ListItem>
              </div>
            </TooltipContent>
          </Tooltip.Portal>
        )}
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

const CollapseButton = ({ collapsed, onMouseDown }) => {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <StyledButton
            size="xs"
            variant="text"
            color="gray"
            onClick={onMouseDown}
            leftSection={
              !collapsed ? (
                <ArrowLeftToLineIcon size={16} />
              ) : (
                <ArrowRightToLineIcon size={16} />
              )
            }
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            {collapsed ? "" : "Collapse"}
          </StyledButton>
        </Tooltip.Trigger>
        {collapsed && (
          <Tooltip.Portal>
            <TooltipContent side="right" align="center" collisionPadding={15}>
              <div className="inner-content" style={{ marginLeft: 5 }}>
                <ListItem>{collapsed ? "Expand" : "Collapse"} Sidebar</ListItem>
              </div>
            </TooltipContent>
          </Tooltip.Portal>
        )}
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

const SearchButton = ({ collapsed, onMouseDown }) => {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <StyledButton
            size="xs"
            variant="text"
            color="gray"
            onClick={onMouseDown}
            leftSection={<SearchIcon size={15} />}
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            {collapsed ? "" : "Ctrl F"}
          </StyledButton>
        </Tooltip.Trigger>
        {collapsed && (
          <Tooltip.Portal>
            <TooltipContent side="right" align="center" collisionPadding={15}>
              <div className="inner-content" style={{ marginLeft: 5 }}>
                <ListItem>Global Search {"(Ctrl F)"}</ListItem>
              </div>
            </TooltipContent>
          </Tooltip.Portal>
        )}
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

const DarkModeButton = ({ collapsed }) => {
  const { theme, setTheme } = useSettings();

  if (collapsed) return null;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 10,
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        padding: 5,
      }}
    >
      <SunIcon size={16} />
      <Switch
        size="xs"
        defaultValue={theme === "DARK"}
        onChange={(checked) => {
          checked ? setTheme(THEMES.DARK) : setTheme(THEMES.DEFAULT);
        }}
      />
      <MoonIcon size={16} />
    </div>
  );
};

const Sidebar = ({ items, width, showFooter = true, ...rest }) => {
  const [orgInfo, setOrgInfo] = useState(null);
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("sidebarCollapsed") !== "false" ? true : false
  );

  const { showSearch } = useAppSearch();

  const handleCollapse = () => {
    setCollapsed((prev) => {
      window.localStorage.setItem("sidebarCollapsed", !prev);
      return !prev;
    });
  };

  useEffect(() => {
    getOrgInfo().then((result) => {
      let arrayBufferView = new Uint8Array(result.image.data);
      let blob = new Blob([arrayBufferView]);
      let urlCreator = window.URL || window.webkitURL;
      let imageUrl = urlCreator.createObjectURL(blob);

      setOrgInfo({ ...result, image: imageUrl });
    });
  }, []);

  return (
    <SidebarContainer width={collapsed ? 60 : width}>
      {!collapsed && (
        <Brand>{orgInfo && <img src={orgInfo.image} alt="Org Logo" />}</Brand>
      )}
      <SidebarNav items={items} collapsed={collapsed}></SidebarNav>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <UpdateButton collapsed={collapsed} />
        <CollapseButton collapsed={collapsed} onMouseDown={handleCollapse} />
        <SearchButton collapsed={collapsed} onMouseDown={showSearch} />
        <DarkModeButton collapsed={collapsed} />
        {!!showFooter && <Footer collapsed={collapsed} />}
      </div>
    </SidebarContainer>
  );
};

export default Sidebar;
