import { Link } from "react-router-dom";
import styled from "styled-components";

const AccessDeniedMessage = styled(
  ({ className, message = "Only admins have access to this section." }) => {
    return (
      <div className={className}>
        <div style={{ fontSize: "1.5rem" }}>Access Denied</div>
        <div className="message">{message}</div>
        <Link to={-1}>
          <div className="return-link">Return to Previous Section</div>
        </Link>
      </div>
    );
  }
)`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 4px;
  padding: 1.2rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20rem;
  .message {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
  .return-link {
    color: ${({ theme }) => theme.palette.primary.main};
    cursor: pointer;
    margin-top: 0.5rem;
    :hover {
      text-decoration: underline;
    }
  }
`;

export default AccessDeniedMessage;
