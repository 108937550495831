import styled from "styled-components";

const LocationSelectBoxItem = styled(({ className, data }) => {
  return (
    <div className={className}>
      <div className="location-name">{data.location_name}</div>
      <div className="location-group">{data.location_group}</div>
    </div>
  );
})`
  .location-name {
    margin-bottom: 5px;
  }
  .location-group {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

export default LocationSelectBoxItem;
