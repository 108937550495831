import styled from "styled-components/macro";
import { CircularProgress } from "@mui/material";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const Loader = ({ message = "", style = null, iconProps = null }) => {
  return (
    <Root>
      <CircularProgress color="primary" style={style} {...iconProps} />
      {message && <div style={{ margin: "10px 0px" }}>{message}</div>}
    </Root>
  );
};

export default Loader;
