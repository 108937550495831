import { httpPost } from "../connection.js";

const createMultipartUpload = async (params = {}) => {
  return await httpPost(`/uploads/create-multipart-upload`, params);
};

const getSignedUrls = async (params = {}) => {
  return await httpPost(`/uploads/get-signed-urls`, params);
};

const getSignedUrl = async (params = {}) => {
  return await httpPost(`/uploads/get-signed-url`, params);
};

const completeMultipartUpload = async (params = {}) => {
  return await httpPost(`/uploads/complete-multipart-upload`, params);
};

const UploadsAPI = {
  createMultipartUpload,
  getSignedUrls,
  getSignedUrl,
  completeMultipartUpload,
};

export default UploadsAPI;
