import { useState } from "react";
import { Modal } from "@monolith-forensics/monolith-ui";
import { DefaultTraceAttributeForm } from "../Forms/index.js";
import Loader from "../Loader.js";
import { AnalysisAPI } from "../../api/index.js";
import { usePermissions } from "../../hooks/usePermissions";
import { useMutation } from "@tanstack/react-query";
import LoggingAPI from "../../api/logging/index.js";

const CreateTraceAttributeModal = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  defaultFormData = {},
  ...props
}) => {
  const { currentUser } = usePermissions();
  const [submitting, setSubmitting] = useState(false);

  const createTraceAttribute = useMutation({
    mutationFn: AnalysisAPI.TraceAttributes.create,
    onMutate: () => setSubmitting(true),
    onSuccess: (_, variables) => {
      LoggingAPI.createLog({
        case_id: defaultFormData.case_id,
        user_id: currentUser.user_id,
        message: `Created trace attribute.`,
        event: "trace_attribute:create",
        object_type: "trace_attribute",
        object_uuid: variables.uuid,
      });
      onSubmit?.(defaultFormData, variables);
      onClose?.();
      setSubmitting(false);
    },
  });

  return (
    <Modal open={show} onClose={onClose} style={{ width: 550 }}>
      <Modal.Title style={{ marginBottom: 10 }}>
        Create Trace Attribute
      </Modal.Title>
      {!submitting && (
        <DefaultTraceAttributeForm
          onSubmit={createTraceAttribute.mutate}
          onCancel={onCancel}
          defaultFormData={defaultFormData}
          {...props}
        />
      )}
      {submitting && <Loader />}
    </Modal>
  );
};

export default CreateTraceAttributeModal;
