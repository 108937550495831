import styled from "styled-components";
import {
  FileInputField,
  TextAreaInput,
  TextInput,
  Button,
  Switch,
} from "@monolith-forensics/monolith-ui";
import { useState } from "react";
import { usePermissions } from "../../hooks/usePermissions";
import { useForm, zodResolver } from "@mantine/form";
import readFileAsBase64 from "../../utils/readFileAsBase64.js";
import shortUUID from "short-uuid";
import DocumentTemplatesAPI from "../../api/DocumentTemplates/DocumentTemplates.js";
import ButtonMenu from "./components/ButtonMenu.js";
import { z } from "zod";
import Loader from "../Loader.js";
import LoggingAPI from "../../api/logging/index.js";

const schema = z.object({
  name: z
    .string({ message: "Name is required" })
    .min(1, { message: "Name is required" }),
  description: z.string().nullable(),
  file: z
    .array(z.object({ name: z.string(), size: z.number() }), {
      message: "File is required",
    })
    .nonempty({
      message: "File is required",
    })
    .length(1, {
      message: "Only one file can be uploaded",
    }),
});

const CreateDocumentTemplateForm = styled(
  ({ className, onSubmit, onCancel, onClose, buttonProps }) => {
    const [submitting, setSubmitting] = useState(false);
    const { currentUser } = usePermissions();
    const form = useForm({
      mode: "uncontrolled",
      initialValues: {
        name: null,
        description: null,
        file: null,
      },
      validate: zodResolver(schema),
    });

    const handleClose = () => {
      form.reset();
      onClose?.();
    };

    const handleSubmit = async () => {
      const validateResult = form.validate();

      if (validateResult.hasErrors) {
        return;
      }

      setSubmitting(true);

      const formData = form.getValues();

      // Read the file
      const file = await readFileAsBase64(formData.file[0]);
      const uuid = shortUUID.generate();

      const templateData = {
        uuid,
        file,
        file_name: formData.file[0].name,
        ext: formData.file[0].name.split(".").pop(),
        size: formData.file[0].size,
        mime_type: formData.file[0].type,
        name: formData.name,
        description: formData.description,
        is_shared: formData.is_shared,
      };

      await DocumentTemplatesAPI.create(templateData);

      LoggingAPI.createLog({
        user_id: currentUser.user_id,
        message: `Created document template: ${templateData.name}`,
        event: "create_document_template",
        object_type: "document_template",
        object_uuid: templateData.uuid,
      });

      // Do not need base64 data in the templateData when sent in the onSubmit
      delete templateData.file;

      templateData.created_on = new Date().toISOString();
      templateData.created_by = {
        full_name: currentUser.full_name,
        email: currentUser.email,
        user_id: currentUser.user_id,
        first_name: currentUser.first_name,
        last_name: currentUser.last_name,
      };

      onSubmit?.(templateData);
      setSubmitting(false);
      handleClose();
    };

    const handleCancel = () => {
      form.reset();
      onCancel?.();
    };

    return (
      <div className={className}>
        {submitting && <Loader />}
        {!submitting && (
          <>
            <TextInput
              label="Template Name"
              placeholder="Template Name"
              variant="outlined"
              size="sm"
              required
              key={form.key("name")}
              {...form.getInputProps("name")}
            />
            <TextAreaInput
              label="Description"
              placeholder="Description"
              variant="outlined"
              size="sm"
              minRows={6}
              maxRows={6}
              style={{ height: 100 }}
              key={form.key("description")}
              {...form.getInputProps("description")}
            />
            <FileInputField
              label="Template File (.docx)"
              required
              size="sm"
              key={form.key("file")}
              {...form.getInputProps("file")}
              dropZoneOptions={{
                maxFiles: 1,
                maxSize: 10485760, // 10MB
                accept: {
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                    [".docx"],
                },
              }}
            >
              <div>Drop ".docx" here or Click to select file ...</div>
              <div>(10MB Limit)</div>
            </FileInputField>
            <Switch
              size="sm"
              label="Shared"
              description="Share this template with other Monolith users."
              key={form.key("is_shared")}
              {...form.getInputProps("is_shared")}
            />
            <ButtonMenu>
              <Button size="xs" variant="subtle" onClick={handleCancel}>
                {buttonProps?.cancelText || "Cancel"}
              </Button>
              <Button
                size="xs"
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                {buttonProps?.submitText || "Submit"}
              </Button>
            </ButtonMenu>
          </>
        )}
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  gap: 15px;

  width: ${({ width }) =>
    Number.isInteger(width) ? `${width}px` : width || "100%"};
`;

export default CreateDocumentTemplateForm;
