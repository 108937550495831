import UserApi from "../../api/users/users.js";

const LoggingFilterDimensions = [
  {
    name: "Timestamp",
    field: "timestamp",
    id: "timestamp",
    type: "date",
    mode: "date",
  },
  {
    name: "User",
    field: "user_id",
    id: "user_id",
    type: "string",
    mode: "multi-select",
    key: ["users:list"],
    selectionDisplayName: "full_name",
    selectionIdField: "user_id",
    queryFn: () =>
      UserApi.getUsers({ includeInactive: true, includeObservers: true }),
  },
  {
    name: "Admin Log",
    field: "admin_log",
    id: "admin_log",
    type: "string",
    mode: "multi-select",
    selectionDisplayName: "text",
    selectionIdField: "value",
    key: ["admin_log:options"],
    queryFn: () =>
      Promise.resolve([
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ]),
    enabled: false,
  },
];

export default LoggingFilterDimensions;
