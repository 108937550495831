import moment from "moment";
import { monolithMoment } from "../../utils/date-format";

const TimelineEventDefs = [
  {
    dataField: "uuid",
    caption: "UUID",
    visible: false,
  },
  {
    dataField: "timestamp",
    caption: "Timestamp (UTC)",
    render: (rowData) => {
      return moment.utc(rowData.timestamp).format("YYYY-MM-DD HH:mm:ss.SSS z");
    },
  },
  {
    dataField: "created_on",
    caption: "Created On",
    visible: false,
    render: (rowData) => {
      return monolithMoment({
        timestamp: rowData.created_on,
        includeTime: true,
        includeZone: true,
      });
    },
  },
  {
    dataField: "updated_on",
    caption: "Updated On",
    visible: false,
    render: (rowData) => {
      return monolithMoment({
        timestamp: rowData.updated_on,
        includeTime: true,
        includeZone: true,
      });
    },
  },
  {
    dataField: "created_by",
    caption: "Created By",
    visible: false,
    render: (rowData) => rowData.created_by?.full_name,
  },
  {
    dataField: "event_name",
    caption: "Event Name",
  },
  {
    dataField: "account",
    caption: "User Account",
    render: (rowData) => rowData.account?.name,
  },
  {
    dataField: "source_evidence",
    caption: "Source Evidence",
    render: (rowData) => rowData.source_evidence?.evidence_number,
  },
  {
    dataField: "target_evidence",
    caption: "Target Evidence",
    render: (rowData) => rowData.target_evidence?.evidence_number,
  },
  {
    dataField: "event_type",
    caption: "Event Type",
    render: (rowData) => rowData.event_type?.name,
  },
  {
    dataField: "artifact",
    caption: "Artifact",
    render: (rowData) => rowData.artifact?.name,
  },
  {
    dataField: "description",
    caption: "Description",
    visible: false,
  },
];

export default TimelineEventDefs;
