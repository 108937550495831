import { Modal, useTheme } from "@mui/material";
import { useQueries } from "@tanstack/react-query";
import styled from "styled-components";
import { usePermissions } from "../../../hooks/usePermissions";
import { useRef } from "react";
import { useSnackbar } from "notistack";
import {
  Form,
  SimpleItem,
  GroupItem,
  EmptyItem,
} from "devextreme-react/ui/form.js";
import { logActivity } from "../../../api/index.js";
import CaseStorageAPI from "../../../api/storage/index.js";
import ToolBarItems from "../../../components/ToolBarItems.js";
import Loader from "../../../components/Loader.js";
import convertToBytes from "../../../utils/convertToBytes.js";

const EditStoragePopup = styled(
  ({
    className,
    open,
    handleClose = () => {},
    onSubmit = () => {},
    defaultInfo,
  }) => {
    const theme = useTheme();
    const { currentUser } = usePermissions();
    const { enqueueSnackbar } = useSnackbar();
    const form = useRef(null);

    const result = useQueries({
      queries: [
        {
          queryKey: ["storage:types:list"],
          queryFn: () => CaseStorageAPI.getTypes(),
          enabled: open,
        },
        {
          queryKey: ["storage:providers:list"],
          queryFn: () => CaseStorageAPI.getProviders(),
          enabled: open,
        },
        {
          queryKey: ["storage:models:list"],
          queryFn: () => CaseStorageAPI.getStorageModels(),
          enabled: open,
        },
        {
          queryKey: ["storage:model-numbers:list"],
          queryFn: () => CaseStorageAPI.getStorageModelNumbers(),
          enabled: open,
        },
        {
          queryKey: ["storage:sizes:list"],
          queryFn: () => CaseStorageAPI.getStorageSizes(),
          enabled: open,
        },
        {
          queryKey: ["storage:custom-fields:list"],
          queryFn: () =>
            CaseStorageAPI.getStorageCustomAttributes({ fieldsOnly: true }),
          enabled: open,
          select: (data) => {
            return data.filter((c) => c.enabled === 1);
          },
        },
      ],
    });

    // check if all queries are done
    const isDone = result.every((x) => x.isSuccess);

    const fieldData = {
      types: result[0].data,
      providers: result[1].data,
      model_names: result[2].data,
      model_numbers: result[3].data,
      capacity: result[4].data,
      customFields: result[5].data,
    };

    const handleSubmit = () => {
      if (form.current.instance.validate().isValid) {
        const formData = Object.assign(
          {},
          form.current.instance.option("formData")
        );

        handleClose();

        const updateData = {};

        Object.keys(formData).forEach((key) => {
          if (formData[key] !== defaultInfo[key]) {
            updateData[key] = formData[key];
          }
        });

        CaseStorageAPI.updateStorage(defaultInfo.storage_id, updateData).then(
          (res) => {
            enqueueSnackbar("Storage updated successfully", {
              variant: "success",
            });
            logActivity(
              defaultInfo.case_id,
              currentUser.user_id,
              `Edited Storage Details ${defaultInfo.storage_number}`
            );

            if (updateData.capacity_unit) {
              updateData.capacity_bytes = convertToBytes(
                defaultInfo.capacity,
                updateData.capacity_unit
              );
            }
            if (updateData.capacity || updateData.capacity === 0) {
              updateData.capacity_bytes = convertToBytes(
                updateData.capacity,
                defaultInfo.capacity_unit
              );
            }
            onSubmit(updateData);
          }
        );
      }
    };

    const handleCancel = () => handleClose();

    const defaultFormData = {
      storage_number: defaultInfo.storage_number,
      type: defaultInfo.type,
      make: defaultInfo.make,
      model_name: defaultInfo.model_name,
      model_number: defaultInfo.model_number,
      serial_number: defaultInfo.serial_number,
      capacity: defaultInfo.capacity,
      capacity_unit: defaultInfo.capacity_unit,
      encryption_key: defaultInfo.encryption_key,
      is_general_storage: defaultInfo.is_general_storage,
      notes: defaultInfo.notes,
    };

    return (
      <Modal
        className={className}
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        style={{ zIndex: 1400 }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 700,
            maxHeight: "90vh",
            backgroundColor: theme.palette.background.default,
            position: "fixed",
            left: "calc(50% - 350px)",
            overflowY: "auto",
            padding: 20,
            outline: "none",
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Edit Storage Details
          </div>
          {isDone && (
            <>
              <Form ref={form} colCount={2} defaultFormData={defaultFormData}>
                <SimpleItem
                  dataField="storage_number"
                  label={{ text: "Storage Number" }}
                  isRequired={true}
                  editorOptions={{
                    hint: "Enter a unique ID that identifies this storage item.",
                    placeholder:
                      "Enter a unique ID that identifies this storage item.",
                  }}
                />
                <SimpleItem
                  dataField="type"
                  label={{ text: "Type" }}
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: fieldData.types,
                    placeholder: "Select or enter storage type...",
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                    displayExpr: "type",
                    valueExpr: "type",
                    searchEnabled: true,
                    acceptCustomValue: true,
                    onCustomItemCreating: (e) => {
                      if (e.text != "") {
                        e.customItem = { type: e.text };
                      } else {
                        e.customItem = null;
                      }
                    },
                  }}
                />
                <SimpleItem
                  dataField="serial_number"
                  label={{ text: "Serial number" }}
                  editorOptions={{
                    hint: "Enter a serial number for this item.",
                  }}
                />
                <GroupItem colCount={2} cssClass="dxform-group-no-padding">
                  <SimpleItem
                    dataField="capacity"
                    label={{ text: "Size" }}
                    editorType="dxNumberBox"
                    editorOptions={{
                      dataSource: fieldData.capacity,
                      min: 0,
                      max: 999,
                      showSpinButtons: true,
                      placeholder: 0,
                    }}
                  />
                  <SimpleItem
                    dataField="capacity_unit"
                    label={{ text: "Size Unit" }}
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: ["KB", "MB", "GB", "TB", "PB"],
                    }}
                  />
                </GroupItem>
                <SimpleItem
                  dataField="model_name"
                  label={{ text: "Model Name" }}
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: fieldData.model_names,
                    placeholder: "Select or enter model name...",
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                    displayExpr: "model_name",
                    valueExpr: "model_name",
                    searchEnabled: true,
                    acceptCustomValue: true,
                    onCustomItemCreating: (e) => {
                      if (e.text != "") {
                        e.customItem = { model_name: e.text };
                      } else {
                        e.customItem = null;
                      }
                    },
                  }}
                />
                <SimpleItem
                  dataField="model_number"
                  label={{ text: "Model Number" }}
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: fieldData.model_numbers,
                    placeholder: "Select or enter model number...",
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                    displayExpr: "model_number",
                    valueExpr: "model_number",
                    searchEnabled: true,
                    acceptCustomValue: true,
                    onCustomItemCreating: (e) => {
                      if (e.text !== "") {
                        e.customItem = { model_number: e.text };
                      } else {
                        e.customItem = null;
                      }
                    },
                  }}
                />
                <SimpleItem
                  dataField="make"
                  label={{ text: "Brand" }}
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: fieldData.providers,
                    placeholder: "Select or enter storage manufacturer...",
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                    displayExpr: "make",
                    valueExpr: "make",
                    searchEnabled: true,
                    acceptCustomValue: true,
                    onCustomItemCreating: (e) => {
                      if (e.text != "") {
                        e.customItem = { make: e.text };
                      } else {
                        e.customItem = null;
                      }
                    },
                  }}
                />
                <SimpleItem
                  dataField="encryption_key"
                  label={{ text: "Password/Encryption Key" }}
                  editorOptions={{
                    type: "normal",
                    mode: "password",
                    inputAttr: { autoComplete: "new-password" },
                    buttons: [
                      {
                        name: "password",
                        location: "after",
                        options: {
                          icon: `${process.env.PUBLIC_URL}/static/img/eye.png`,
                          type: "default",
                          onClick() {
                            const passwordEditor =
                              form.current.instance.getEditor("encryption_key");
                            passwordEditor.option(
                              "mode",
                              passwordEditor.option("mode") === "text"
                                ? "password"
                                : "text"
                            );
                          },
                        },
                      },
                    ],
                  }}
                />
                <SimpleItem
                  dataField="is_general_storage"
                  label={{ text: "General Storage" }}
                  visible={!defaultInfo?.case_id}
                  editorType="dxSelectBox"
                  isRequired={true}
                  editorOptions={{
                    items: [
                      { value: 1, text: "Yes" },
                      { value: 0, text: "No" },
                    ],
                    hint: "Is this storage item for general use or will it be assigned to a case?",
                    displayExpr: "text",
                    valueExpr: "value",
                  }}
                />
                {!defaultInfo?.case_id && <EmptyItem />}
                <SimpleItem
                  dataField="notes"
                  label={{ text: "Description" }}
                  colSpan={2}
                  editorType="dxTextArea"
                  editorOptions={{
                    height: 135,
                    placeholder:
                      "Enter a brief description of this item and any important details.",
                  }}
                />
              </Form>
              <div
                style={{
                  marginTop: 20,
                }}
              >
                <ToolBarItems
                  submitText="Edit Storage"
                  onSubmit={handleSubmit}
                  onCancel={handleCancel}
                />
              </div>
            </>
          )}
          {!isDone && <Loader />}
        </div>
      </Modal>
    );
  }
)`
  .dx-widget.dx-button {
    border-radius: 0px !important;
    margin: 0px !important;
    border: 1px solid ${({ theme }) => theme.palette.divider} !important;
    background-color: ${({ theme }) =>
      theme.palette.background.default} !important;
    transition: border 0.2s ease-in-out !important;
    :hover {
      border: 1px solid ${({ theme }) => theme.palette.primary.main} !important;
    }
  }
`;

export default EditStoragePopup;
