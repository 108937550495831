import { useRef, useState } from "react";
import { Form, SimpleItem, GroupItem } from "devextreme-react/ui/form";
import { Popup } from "devextreme-react/ui/popup";
import { isLicenseAvailable, userApi } from "../../../api/index.js";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext.js";
import { UserPermission, usePermissions } from "../../../hooks/usePermissions";
import { useTheme, Button, Modal } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useSnackbar } from "notistack";
import { Template } from "devextreme-react/core/template";
import UserRoleSelectBoxItem from "../../../components/SelectBoxItems/UserRoleSelectBoxItem.js";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AuthService } from "../../../api/auth/index.js";
import UserApi from "../../../api/users/users.js";
import OfficesApi from "../../../api/offices/index.js";
import styled from "styled-components";
import TaskButton from "../../../Monolith-UI/TaskButton.js";
import KeyboardReturnOutlinedIcon from "@mui/icons-material/KeyboardReturnOutlined";
import ToolBarItems from "../../../components/ToolBarItems.js";
import AccessDeniedMessage from "../../../components/AccessDeniedMessage.js";
import UserRolesAPI from "../../../api/UserRoles/UserRoles.js";
import { UserRole } from "../types/Users.js";
import { Office } from "@/pages/Locations/LocationsSection.js";
import usePlatform from "../../../hooks/usePlatform.js";

export interface FullUser {
  user_id: number;
  username: string;
  first_name: string;
  last_name: string;
  phone: string;
  mobile: string;
  email: string;
  title: string;
  observer: number;
  active: number;
  location_id: number;
  office_id: number;
  status: string;
  role_id: number;
  full_name: string;
  office_name: string;
  active_status: string;
  user_role: UserRole;
  permissions: UserPermission[];
  price_per_hour?: number;
}

const UserDetailsBlock = styled(
  ({ userData, offices }: { userData: FullUser; offices: Office[] }) => {
    const theme = useTheme();
    const { hasPermission, MONOLITH_PERMISSIONS, currentUser } =
      usePermissions();
    const form1 = useRef<any>(null);
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const handleCompleteSubmit = async ({ isValid }: { isValid: boolean }) => {
      if (!isValid) return;

      let formData = {
        ...form1.current.instance.option().formData,
      };

      //Collect Changed Fields
      let newValues: Partial<FullUser> = {};
      for (const key of Object.keys(formData) as Array<keyof FullUser>) {
        if (userData[key] !== formData[key]) {
          newValues[key] = formData[key];
        }
      }

      await userApi.updateUser(userData.user_id, newValues);
      enqueueSnackbar("User Updated", {
        variant: "success",
      });

      queryClient.refetchQueries({
        queryKey: ["users:list", { email: userData.email }],
      });
    };

    const handleUpdateUserDetails = () => {
      const res = form1.current.instance.validate();
      if (res.status === "pending") {
        res.complete.then((r: any) => {
          handleCompleteSubmit({ isValid: r.isValid });
        });
      } else {
        handleCompleteSubmit({ isValid: res.isValid });
      }
    };

    return (
      <div
        style={{
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 5,
        }}
      >
        <div
          style={{
            padding: "40px",
            textAlign: "left",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: 750,
            }}
          >
            <div>
              <div style={{ fontSize: "large" }}>User Details</div>
              <div style={{ color: theme.palette.text.secondary }}>
                Provide personal details.
              </div>
            </div>
            <div>
              <Form
                defaultFormData={{
                  first_name: userData.first_name,
                  last_name: userData.last_name,
                  title: userData.title,
                  email: userData.email,
                  office_id: userData.office_id,
                }}
                ref={form1}
                width={450}
                colCount={1}
                readOnly={
                  !hasPermission() ||
                  (!hasPermission(MONOLITH_PERMISSIONS.USERS_MANAGE) &&
                    currentUser.user_id !== userData.user_id)
                }
              >
                <GroupItem colCount={2}>
                  <SimpleItem
                    dataField="first_name"
                    label={{ text: "First Name" }}
                    isRequired={true}
                  />
                  <SimpleItem
                    dataField="last_name"
                    label={{ text: "Last Name" }}
                    isRequired={true}
                  />
                </GroupItem>
                <SimpleItem
                  dataField="title"
                  label={{ text: "Title" }}
                  isRequired={false}
                />
                <SimpleItem
                  dataField="office_id"
                  label={{ text: "Office" }}
                  isRequired={true}
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: offices,
                    valueExpr: "office_id",
                    displayExpr: "office_name",
                  }}
                />
                {/* // Hourly Rate */}
                <SimpleItem
                  dataField="price_per_hour"
                  label={{ text: "Hourly Rate" }}
                  editorType="dxNumberBox"
                  isRequired={true}
                  editorOptions={{
                    format: "currency",
                    value: userData.price_per_hour,
                    min: 0,
                  }}
                />
              </Form>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "35px",
              width: 750,
            }}
          >
            {hasPermission() &&
              (hasPermission(MONOLITH_PERMISSIONS.USERS_MANAGE) ||
                currentUser.user_id === userData.user_id) && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleUpdateUserDetails}
                >
                  Update User Details
                </Button>
              )}
          </div>
        </div>
      </div>
    );
  }
)`
  & {
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 4px;
  }
`;

const UserRoleBlock = styled(({ className, userData, offices }) => {
  const theme = useTheme();
  const { currentUser } = useAuth();
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const form2 = useRef<any>(null);
  const form3 = useRef<any>(null);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: ["user-roles", "list"],
    queryFn: () => UserRolesAPI.get(),
  });

  const userRoles: UserRole[] = data?.data || [];

  const handleUpdateUserRole = async () => {
    const { isValid } = form2.current.instance.validate();

    if (!isValid) return;

    let formData = {
      ...form2.current.instance.option().formData,
    };

    // If user is being set to super admin, set observer to 0
    // remove read only status
    if (formData.role_id === 1) {
      formData.observer = 0;
    }

    await userApi.updateUser(userData.user_id, formData);

    // set readonly status to 0 if user is being set to super admin
    if (formData.role_id === 1) {
      form3.current.instance.getEditor("observer").option("value", 0);
    }

    enqueueSnackbar("User Updated", {
      variant: "success",
    });

    queryClient.refetchQueries({
      queryKey: ["users:list", { email: userData.email }],
    });
  };

  const handleUpdateObserverStatus = async () => {
    const { isValid } = form3.current.instance.validate();

    if (!isValid) return;

    const formData = { ...form3.current.instance.option().formData };

    await userApi.updateUser(userData.user_id, { observer: formData.observer });

    enqueueSnackbar("User Updated", {
      variant: "success",
    });

    queryClient.refetchQueries({
      queryKey: ["users:list", { email: userData.email }],
    });
  };

  return (
    <div
      className={className}
      style={{
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 5,
      }}
    >
      <div
        style={{
          padding: "40px",
          textAlign: "left",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: 750,
          }}
        >
          <div>
            <div style={{ fontSize: "large" }}>User Role</div>
            <div style={{ color: theme.palette.text.secondary }}>
              Assign a role to manage access and permissions.
            </div>
          </div>
          <div>
            <Form
              ref={form2}
              defaultFormData={{ role_id: userData.role_id }}
              width={450}
              readOnly={!hasPermission(MONOLITH_PERMISSIONS.USERS_MANAGE)}
            >
              <SimpleItem
                dataField="role_id"
                label={{ text: "Role" }}
                isRequired={true}
                editorType="dxSelectBox"
                editorOptions={{
                  valueExpr: "role_id",
                  displayExpr: "text",
                  items: userRoles.map((role) => ({
                    text: role.name,
                    description: role.description,
                    role_id: role.id,
                  })),
                  wrapItemText: true,
                  itemTemplate: "user_role",
                  dropDownOptions: {
                    height: 200,
                  },
                }}
              />
              <Template
                name="user_role"
                render={(data: UserRole) => (
                  <UserRoleSelectBoxItem data={data} />
                )}
              />
            </Form>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "35px",
            width: 750,
          }}
        >
          {hasPermission() &&
            (hasPermission(MONOLITH_PERMISSIONS.USERS_MANAGE) ||
              currentUser.user_id === userData.user_id) && (
              <Button
                variant="outlined"
                size="small"
                onClick={handleUpdateUserRole}
              >
                Update User Role
              </Button>
            )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: 750,
            paddingTop: "50px",
          }}
        >
          <div>
            <div style={{ fontSize: "large" }}>Observer Status</div>
            <div style={{ color: theme.palette.text.secondary }}>
              Set the read-only status for this user.
            </div>
          </div>
          <div>
            <Form
              ref={form3}
              defaultFormData={{ observer: userData.observer }}
              width={450}
              readOnly={
                !hasPermission(MONOLITH_PERMISSIONS.USERS_MANAGE) ||
                userData.role_id === 1
              }
            >
              <SimpleItem
                dataField="observer"
                label={{ text: "Read Only Status" }}
                isRequired={true}
                editorType="dxSelectBox"
                editorOptions={{
                  valueExpr: "value",
                  displayExpr: "text",
                  items: [
                    {
                      text: "Read Only",
                      value: 1,
                    },
                    {
                      text: "Write Access",
                      value: 0,
                    },
                  ],
                  wrapItemText: true,
                }}
              />
            </Form>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "35px",
            width: 750,
          }}
        >
          {hasPermission() &&
            (hasPermission(MONOLITH_PERMISSIONS.USERS_MANAGE) ||
              currentUser.user_id === userData.user_id) && (
              <Button
                variant="outlined"
                size="small"
                onClick={handleUpdateObserverStatus}
              >
                Update Observer Status
              </Button>
            )}
        </div>
      </div>
    </div>
  );
})`
  margin-top: 25px;
  & {
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 4px;
  }
`;

const ChangePasswordBlock = styled(({ className, changePasswordPopup }) => {
  const theme = useTheme();

  return (
    <div
      className={className}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "40px",
        marginTop: "25px",
        textAlign: "left",
        alignItems: "center",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 5,
      }}
    >
      <div>
        <div style={{ fontSize: "large" }}>Change Password</div>
        <div style={{ color: theme.palette.text.secondary }}>
          The user will not be able to log in using their previous password.
        </div>
      </div>
      <div>
        <Button
          variant="outlined"
          size="small"
          onClick={(e) => {
            changePasswordPopup.current.instance.show();
          }}
        >
          Change Password
        </Button>
      </div>
    </div>
  );
})`
  & {
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 4px;
  }
`;

const ChangePasswordPopup = ({
  changePasswordPopup,
  userData,
}: {
  changePasswordPopup: any;
  userData: FullUser;
}) => {
  const form = useRef<any>(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    let formData = form.current.instance.option().formData;

    if (form.current.instance.validate().isValid) {
      changePasswordPopup.current.instance.hide();
      userApi.updateUser(userData.user_id, {
        password: formData.password,
      });
      enqueueSnackbar("User Password Updated", {
        variant: "success",
      });
    }
  };

  return (
    <Popup
      ref={changePasswordPopup}
      title="Change User Password"
      showCloseButton={true}
      showTitle={true}
      width={450}
      height={200}
      position={{
        offset: "100 0",
      }}
      onHidden={(e) => {
        form.current.instance.resetValues();
      }}
    >
      <Form ref={form}>
        <SimpleItem
          dataField="password"
          label={{ text: "New Password" }}
          editorOptions={{
            mode: "password",
            maxLength: 100,
          }}
          validationRules={[
            {
              type: "required",
            },
            {
              type: "stringLength",
              message: "Password must be at least 8 characters",
              min: 8,
              max: 100,
            },
            {
              type: "pattern",
              pattern:
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!+_\\-\\.@#$%^&*])",
              message: "Must contain upper, lower, special, & numeric.",
            },
          ]}
        />
      </Form>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginRight: 5,
          marginTop: 30,
        }}
      >
        <Button
          variant="text"
          size="small"
          style={{ marginRight: 10 }}
          onClick={(e) => {
            changePasswordPopup.current.instance.hide();
          }}
        >
          Cancel
        </Button>
        <Button variant="contained" size="small" onClick={handleSubmit}>
          Update Password
        </Button>
      </div>
    </Popup>
  );
};

const ResetPasswordBlock = styled(({ className, resetPasswordPopup }) => {
  const theme = useTheme();

  return (
    <div
      className={className}
      style={{
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 5,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "40px",
        marginTop: "25px",
        textAlign: "left",
        alignItems: "center",
      }}
    >
      <div>
        <div style={{ fontSize: "large" }}>Reset User Password</div>
        <div style={{ color: theme.palette.text.secondary }}>
          Send a password reset email to the user.
        </div>
      </div>
      <div>
        <Button
          variant="outlined"
          size="small"
          onClick={(e) => {
            resetPasswordPopup.current.instance.show();
          }}
        >
          Reset Password
        </Button>
      </div>
    </div>
  );
})`
  & {
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 4px;
  }
`;

const ResetPasswordPopup = ({
  resetPasswordPopup,
  userData,
}: {
  resetPasswordPopup: any;
  userData: FullUser;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    resetPasswordPopup.current.instance.hide();
    AuthService.resetPasswordRequest({ email: userData.email });
    enqueueSnackbar("Password reset email has been sent to user.", {
      variant: "success",
    });
  };

  return (
    <Popup
      ref={resetPasswordPopup}
      title="Reset User Password"
      showCloseButton={true}
      showTitle={true}
      width={450}
      height={150}
      position={{
        offset: "100 0",
      }}
    >
      <div>Are you sure you want to reset this user's password?</div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginRight: 5,
          marginTop: 30,
        }}
      >
        <Button
          variant="text"
          size="small"
          style={{ marginRight: 10 }}
          onClick={(e) => {
            resetPasswordPopup.current.instance.hide();
          }}
        >
          Cancel
        </Button>
        <Button variant="contained" size="small" onClick={handleSubmit}>
          Reset Password
        </Button>
      </div>
    </Popup>
  );
};

const ResetMFABlock = styled(({ className, resetMFAPopup }) => {
  const theme = useTheme();

  return (
    <div
      className={className}
      style={{
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 5,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "40px",
        marginTop: "25px",
        textAlign: "left",
        alignItems: "center",
      }}
    >
      <div>
        <div style={{ fontSize: "large" }}>Reset User MFA</div>
        <div style={{ color: theme.palette.text.secondary }}>
          The user's current MFA device will be removed from the account. The
          user will be prompted to connect a new device at their next login.
        </div>
      </div>
      <div>
        <Button
          variant="outlined"
          size="small"
          onClick={(e) => {
            resetMFAPopup.current.instance.show();
          }}
        >
          Reset MFA
        </Button>
      </div>
    </div>
  );
})`
  & {
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 4px;
  }
`;

const ResetMFAPopup = ({
  resetMFAPopup,
  userData,
}: {
  resetMFAPopup: any;
  userData: FullUser;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    resetMFAPopup.current.instance.hide();
    userApi.resetMFA({
      email: userData.email,
    });
    enqueueSnackbar("User MFA has been reset.", {
      variant: "success",
    });
  };

  return (
    <Popup
      ref={resetMFAPopup}
      title="Reset MFA Device"
      showCloseButton={true}
      showTitle={true}
      width={450}
      height={150}
      position={{
        offset: "100 0",
      }}
    >
      <div>Are you sure you want to reset this user's MFA device?</div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginRight: 5,
          marginTop: 30,
        }}
      >
        <Button
          variant="text"
          size="small"
          style={{ marginRight: 10 }}
          onClick={(e) => {
            resetMFAPopup.current.instance.hide();
          }}
        >
          Cancel
        </Button>
        <Button variant="contained" size="small" onClick={handleSubmit}>
          Reset MFA
        </Button>
      </div>
    </Popup>
  );
};

const MfaStatusBlock = styled(({ className, userData }) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [mfaEnabled, setMfaEnabled] = useState(userData.mfa_enabled === 1);
  const [showModal, setShowModal] = useState(false);

  const onSubmit = ({ mfa_enabled }: { mfa_enabled: number }) => {
    setMfaEnabled(mfa_enabled === 1);
    queryClient.refetchQueries({
      queryKey: ["users:list", { email: userData.email }],
    });
  };

  return (
    <div
      className={className}
      style={{
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 5,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "40px",
        marginTop: "25px",
        textAlign: "left",
        alignItems: "center",
      }}
    >
      <div>
        <div style={{ fontSize: "large" }}>MFA Settings</div>
        <div style={{ color: theme.palette.text.secondary }}>
          Enable or Disable MFA for this user.
        </div>
      </div>
      <div>
        <Button
          variant="outlined"
          size="small"
          onClick={(e) => setShowModal(true)}
        >
          {mfaEnabled ? "Disable MFA" : "Enable MFA"}
        </Button>
      </div>
      <MfaStatusModal
        details={userData}
        open={showModal}
        onSubmit={onSubmit}
        handleClose={() => setShowModal(false)}
      />
    </div>
  );
})`
  & {
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 4px;
  }
`;

const MfaStatusModal = ({
  onSubmit = () => {},
  open = false,
  handleClose = () => {},
  details,
}: {
  onSubmit: ({
    email,
    mfa_enabled,
  }: {
    email: string;
    mfa_enabled: number;
  }) => void;
  open: boolean;
  handleClose: () => void;
  details: any;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const handleSubmit = () => {
    UserApi.updateMFA({
      email: details.email,
      mfa_enabled: details.mfa_enabled === 1 ? 0 : 1,
    }).then((res) => {
      enqueueSnackbar(
        `MFA ${details.mfa_enabled === 1 ? "Disabled" : "Enabled"}.`,
        {
          variant: "success",
        }
      );
      onSubmit({
        email: details.email,
        mfa_enabled: details.mfa_enabled === 1 ? 0 : 1,
      });
    });
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 500,
          maxHeight: "90vh",
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 250px)",
          overflowY: "auto",
          padding: 20,
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>MFA Settings</div>
        {details && (
          <>
            <div style={{ marginBottom: 15 }}>
              <div style={{ color: theme.palette.primary.main }}>
                {details.email}
              </div>
            </div>
            <div style={{ margin: "10px 0px" }}>
              {details.mfa_enabled === 1
                ? "Are you sure you want to disable MFA for this user?"
                : "Are you sure you want to enable MFA for this user?"}
            </div>
            <ToolBarItems
              style={{ marginTop: 20 }}
              submitText={
                details.mfa_enabled === 1 ? "Disable MFA" : "Enable MFA"
              }
              onSubmit={handleSubmit}
              onCancel={handleCancel}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

const UserActivationBlock = styled(
  ({
    className,
    userData,
    confirmUserActivationPopup,
    confirmUserDeactivationPopup,
  }) => {
    const theme = useTheme();

    return (
      <>
        {userData.active === 0 && (
          <div
            className={className}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "40px",
              marginTop: "25px",
              textAlign: "left",
              alignItems: "center",
            }}
          >
            <div>
              <div style={{ fontSize: "large" }}>Activate User</div>
              <div style={{ color: theme.palette.text.secondary }}>
                Give the user access to Monolith.
              </div>
            </div>
            <div>
              {" "}
              <Button
                variant="outlined"
                size="small"
                color="success"
                onClick={(e) => {
                  confirmUserActivationPopup.current.instance.show();
                }}
              >
                Activate User
              </Button>
            </div>
          </div>
        )}
        {userData.active === 1 && (
          <div
            className={className}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "40px",
              marginTop: "25px",
              textAlign: "left",
              alignItems: "center",
            }}
          >
            <div>
              <div style={{ fontSize: "large" }}>Deactivate User</div>
              <div style={{ color: theme.palette.text.secondary }}>
                Prevent the user from accessing Monolith.
              </div>
            </div>
            <div>
              {" "}
              <Button
                variant="outlined"
                size="small"
                color="error"
                onClick={(e) => {
                  confirmUserDeactivationPopup.current.instance.show();
                }}
              >
                Deactivate User
              </Button>
            </div>
          </div>
        )}
      </>
    );
  }
)`
  & {
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 4px;
  }
`;

const ConfirmUserActivationPopup = ({
  confirmUserActivationPopup,
  userData,
}: {
  confirmUserActivationPopup: any;
  userData: FullUser;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    //Verify User Limit
    if (!(await isLicenseAvailable())) {
      enqueueSnackbar(
        "User limit reached - deactivate a user or purchase more licenses.",
        {
          variant: "error",
        }
      );
      return;
    }
    confirmUserActivationPopup.current.instance.hide();
    userApi.updateUser(userData.user_id, { active: 1 });
    enqueueSnackbar("User Activated", {
      variant: "success",
    });
    queryClient.setQueryData(
      ["users:list", { email: userData.email }],
      (oldData: FullUser[]) => {
        return oldData.map((user) => {
          if (user.user_id === userData.user_id) {
            return { ...user, active: 1 };
          }
          return user;
        });
      }
    );
  };

  return (
    <Popup
      ref={confirmUserActivationPopup}
      title="Activate User"
      showTitle={true}
      showCloseButton={true}
      width={450}
      height={150}
      position={{
        offset: "100 0",
      }}
    >
      <div>Are you sure you want to activate this user?</div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginRight: 5,
          marginTop: 30,
        }}
      >
        <Button
          variant="text"
          size="small"
          style={{ marginRight: 10 }}
          onClick={(e) => {
            confirmUserActivationPopup.current.instance.hide();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          color="success"
          onClick={handleSubmit}
        >
          Activate
        </Button>
      </div>
    </Popup>
  );
};

const ConfirmUserDeactivationPopup = ({
  confirmUserDeactivationPopup,
  userData,
}: {
  confirmUserDeactivationPopup: any;
  userData: FullUser;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    confirmUserDeactivationPopup.current.instance.hide();
    userApi.updateUser(userData.user_id, { active: 0 });
    enqueueSnackbar("User Deactivated.", {
      variant: "success",
    });

    queryClient.setQueryData(
      ["users:list", { email: userData.email }],
      (oldData: FullUser[]) => {
        return oldData.map((user) => {
          if (user.user_id === userData.user_id) {
            return { ...user, active: 0 };
          }
          return user;
        });
      }
    );
  };

  return (
    <Popup
      ref={confirmUserDeactivationPopup}
      title="Deactivate User"
      showTitle={true}
      showCloseButton={true}
      width={450}
      height={150}
      position={{
        offset: "100 0",
      }}
    >
      <div>Are you sure you want to deactivate this user?</div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginRight: 5,
          marginTop: 30,
        }}
      >
        <Button
          variant="text"
          size="small"
          style={{ marginRight: 10 }}
          onClick={(e) => {
            confirmUserDeactivationPopup.current.instance.hide();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          color="error"
          onClick={handleSubmit}
        >
          Deactivate
        </Button>
      </div>
    </Popup>
  );
};

const UserDetails = styled(({ className }) => {
  const { currentUser, hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const { isOnPremises } = usePlatform();

  const theme = useTheme();
  const { user_id } = useParams();
  const changePasswordPopup = useRef(null);
  const resetPasswordPopup = useRef(null);
  const resetMFAPopup = useRef(null);
  const confirmUserActivationPopup = useRef(null);
  const confirmUserDeactivationPopup = useRef(null);

  const hasAccessToProfile =
    currentUser.email === user_id ||
    hasPermission(MONOLITH_PERMISSIONS.USERS_MANAGE);

  const { data: userData } = useQuery({
    queryKey: ["users:list", { email: user_id }],
    queryFn: () => UserApi.getUsers({ email: user_id }),
    select: (data) => data[0],
    enabled: hasAccessToProfile,
  });

  const { data: offices } = useQuery({
    queryKey: ["offices:list"],
    queryFn: () => OfficesApi.getOffices(),
    enabled: hasAccessToProfile,
  });

  if (!hasAccessToProfile) return <AccessDeniedMessage />;

  if (!userData || !offices) return null;

  return (
    <div className={className}>
      <Helmet title={`User Details`} />
      <Helmet
        title={`User Details | ${userData.full_name} | ${userData.email}`}
      />
      <div
        style={{
          padding: "10px 5px",
          margin: "0px",
          display: "flex",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        {/*@ts-ignore*/}
        <Link to={-1} style={{ marginRight: 10 }}>
          <TaskButton
            style={{ outline: `1px solid ${theme.palette.divider}` }}
            title="Back to Users"
          >
            <KeyboardReturnOutlinedIcon style={{ fontSize: 20 }} />
          </TaskButton>
        </Link>
        <div style={{ fontSize: "x-large", marginLeft: 10 }}>
          {userData.full_name}
          <div
            style={{
              color: theme.palette.text.secondary,
              fontSize: "medium",
            }}
          >
            {userData.email}
          </div>
        </div>
      </div>
      <div style={{ boxShadow: "none" }}>
        <UserDetailsBlock userData={userData} offices={offices} />
        <UserRoleBlock userData={userData} offices={offices} />
        {!isOnPremises &&
          (hasPermission(MONOLITH_PERMISSIONS.USERS_MANAGE) ||
            currentUser.user_id === userData.user_id) && (
            <>
              <ResetPasswordBlock
                resetPasswordPopup={resetPasswordPopup}
                userData={userData}
              />
              <ResetPasswordPopup
                resetPasswordPopup={resetPasswordPopup}
                userData={userData}
              />
              <ResetMFABlock resetMFAPopup={resetMFAPopup} />
              <ResetMFAPopup
                resetMFAPopup={resetMFAPopup}
                userData={userData}
              />
            </>
          )}
        {isOnPremises &&
          (hasPermission(MONOLITH_PERMISSIONS.USERS_MANAGE) ||
            currentUser.user_id === userData.user_id) && (
            <>
              <ChangePasswordBlock changePasswordPopup={changePasswordPopup} />
              <ChangePasswordPopup
                changePasswordPopup={changePasswordPopup}
                userData={userData}
              />
              <MfaStatusBlock userData={userData} />
            </>
          )}
        {hasPermission(MONOLITH_PERMISSIONS.USERS_MANAGE) && (
          <UserActivationBlock
            userData={userData}
            confirmUserActivationPopup={confirmUserActivationPopup}
            confirmUserDeactivationPopup={confirmUserDeactivationPopup}
          />
        )}
        <ConfirmUserActivationPopup
          confirmUserActivationPopup={confirmUserActivationPopup}
          userData={userData}
        />
        <ConfirmUserDeactivationPopup
          confirmUserDeactivationPopup={confirmUserDeactivationPopup}
          userData={userData}
        />
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  padding-right: 10px;
`;

export default UserDetails;
