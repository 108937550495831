import { httpGet } from "../connection";
import moment from "moment";

export async function isLicenseAvailable() {
  let result = await httpGet("/subscription");

  return result.total_active_users < result.user_licenses;
}

export async function getLicenseInfo() {
  return httpGet("/subscription");
}

export async function isLicenseExpired() {
  let result = await httpGet("/subscription");

  return moment(result.expire_date).isBefore(moment());
}
