// Synchronizes columnDefs and stored column state
// Ensures if ColumnDefs are updated, the column state is updated as well
function synchronizeColumnState(columnDefs, savedColumnState) {
  const newColumnState = [...columnDefs];

  // remove columns that are no longer in columnDefs
  if (savedColumnState?.cols) {
    savedColumnState.cols = savedColumnState.cols.filter((savedColumn) =>
      columnDefs.find(
        (c) =>
          savedColumn.dataField.includes("custom_field_") || // ignore custom fields
          c.dataField === savedColumn.dataField
      )
    );
  }

  if (savedColumnState?.cols) {
    for (const savedColumn of savedColumnState.cols) {
      const column = columnDefs.find(
        (c) => c.dataField === savedColumn.dataField
      );
      if (column) {
        column.visible = savedColumn.visible;
        column.sorting = savedColumn.sorting;
        column.width = savedColumn.width;
        column.order = savedColumn.order;
      } else {
        newColumnState.push(savedColumn);
      }
    }
  }

  return newColumnState;
}

export default synchronizeColumnState;
