import styled from "styled-components";

const FullscreenTitle = styled.div`
  height: 25px;
  width: 100%;
  color: ${(props) => props.theme.palette.text.primary};
  font-weight: 500;
  font-size: 0.75rem;
  background-color: ${(props) => props.theme.palette.background.default};
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default FullscreenTitle;
