import { z } from "zod";
import {
  Button,
  DateInput,
  Grid,
  TextAreaInput,
  TextInput,
} from "@monolith-forensics/monolith-ui";
import ButtonMenu from "./components/ButtonMenu.js";
import { useForm, zodResolver } from "@mantine/form";
import diffFormData from "./utils/diffFormData.js";
import { v4 as uuidv4 } from "uuid";
import EvidenceSelector from "./components/Selectors/EvidenceSelector.js";
import TraceAccountSelector from "./components/Selectors/TraceAccountSelector.js";
import TimelineEventTypeSelector from "./components/Selectors/TimelineEventTypeSelector.js";
import { TimelineArtifactSelector } from "./components/Selectors/index.js";

const TimelineEventSchema = z.object({
  uuid: z.string().min(10).max(36),
  event_name: z.string().min(1).max(255),
  timestamp: z.string({ message: "Required" }).datetime(),
  source_evidence_uuid: z.string().min(10).max(36).nullable().optional(),
  target_evidence_uuid: z.string().min(10).max(36).nullable().optional(),
  event_type_id: z.number().int().min(1).max(36).nullable().optional(),
  artifact_id: z.number().int().min(1).max(36).nullable().optional(),
  account_id: z.number().int().min(1).max(36).nullable().optional(),
  description: z.string().nullable().optional(),
  note_uuid: z.string().min(5).max(255).nullable().optional(),
});

const DefaultTimelineEventForm = ({
  defaultFormData = {},
  buttonProps = {},
  onSubmit,
  onCancel,
}) => {
  const form = useForm({
    mode: "uncontrolled",
    initialValues: { uuid: uuidv4(), ...defaultFormData },
    validate: zodResolver(TimelineEventSchema),
  });

  const handleSubmit = async () => {
    const validateResult = form.validate();

    if (validateResult.hasErrors) {
      return;
    }

    const formData = form.getValues();

    const diffData = diffFormData(defaultFormData, formData);

    // call onSubmit with modeled data and diff data
    // diff data is used when the form is in edit context
    // diff may be used when we only want to update modified values
    onSubmit?.(formData, diffData);
  };

  const handleCancel = () => {
    form.reset();
    onCancel?.();
  };

  return (
    <>
      <Grid col={2} style={{ marginBottom: 10 }}>
        <TextInput
          size="sm"
          variant="outlined"
          label="Event Name"
          placeholder="Event Name..."
          required
          description={
            "Provide a name for the event so that it can be easily identified."
          }
          colSpan={2}
          key={form.key("event_name")}
          {...form.getInputProps("event_name")}
        />
        <DateInput
          size="sm"
          required
          clearable
          enableCalendar
          label="UTC Timestamp"
          description="This is the timestamp of the event in UTC time.  This is the time that the event occurred."
          format={"YYYY-MM-DD HH:mm:ss.SSS"}
          utc={true}
          key={form.key("timestamp")}
          {...form.getInputProps("timestamp")}
        />
        <TraceAccountSelector
          size="sm"
          clearable
          searchable
          label="Account"
          query={{
            case_id: defaultFormData.case_id,
            case_uuid: defaultFormData.case_uuid,
          }}
          placeholder="Select Account..."
          description="If available, this is the user or system account associated with the event."
          key={form.key("account_id")}
          {...form.getInputProps("account_id")}
        />
        <EvidenceSelector
          size="sm"
          clearable
          searchable
          label="Source Evidence"
          query={{
            case_id: defaultFormData.case_id,
            case_uuid: defaultFormData.case_uuid,
          }}
          placeholder="Select Evidence..."
          description="This is the evidence that is associated with this event.  This is where the event was discovered."
          key={form.key("source_evidence_uuid")}
          {...form.getInputProps("source_evidence_uuid")}
        />
        <EvidenceSelector
          size="sm"
          clearable
          searchable
          label="Target Evidence"
          query={{
            case_id: defaultFormData.case_id,
            case_uuid: defaultFormData.case_uuid,
          }}
          placeholder="Select Evidence..."
          description="This is the evidence that is associated with this event.  This is where the event was discovered."
          key={form.key("target_evidence_uuid")}
          {...form.getInputProps("target_evidence_uuid")}
        />
        <TimelineEventTypeSelector
          size="sm"
          clearable
          searchable
          label="Event Type"
          placeholder="Select Event Type..."
          description="This is the type of event that occurred at this timestamp.  This could be a user action, system event, or other type of event."
          key={form.key("event_type_id")}
          {...form.getInputProps("event_type_id")}
        />
        <TimelineArtifactSelector
          size="sm"
          clearable
          searchable
          label="Artifact"
          placeholder="Select Artifact..."
          description="This is the artifact that is associated with this event.  This could be a file, a registry key, a network connection, or other type of artifact.  This is where the event was discovered."
          key={form.key("artifact_id")}
          {...form.getInputProps("artifact_id")}
        />
        <TextAreaInput
          size="sm"
          variant="outlined"
          label="Description"
          placeholder="Enter event description"
          description={
            "Provide a brief description of the event so that it can be easily identified."
          }
          colSpan={2}
          minRows={8}
          maxRows={24}
          key={form.key("description")}
          {...form.getInputProps("description")}
        />
      </Grid>
      <ButtonMenu>
        <Button size="xs" variant="subtle" onClick={handleCancel}>
          {buttonProps.cancelText || "Cancel"}
        </Button>
        <Button
          size="xs"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          {buttonProps.submitText || "Submit"}
        </Button>
      </ButtonMenu>
    </>
  );
};

export default DefaultTimelineEventForm;
