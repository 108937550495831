import { DateInputFormat, getDateFormat } from "@/utils/date-format";
import { z } from "zod";
import {
  Button,
  DateInput,
  Grid,
  TextAreaInput,
  TextInput,
} from "@monolith-forensics/monolith-ui";
import ButtonMenu from "./components/ButtonMenu";
import { FC } from "react";
import { useForm } from "@mantine/form";
import diffFormData from "./utils/diffFormData";
import LocationSelector from "./components/Selectors/LocationSelector";
import VendorSelector from "./components/Selectors/VendorSelector";
import SoftwareSelector from "./components/Selectors/SoftwareSelector";
import validateAsync from "./utils/zod/validateAsync";
import { ForensicSoftware } from "@/types";

export interface SoftwareFormData
  extends Omit<ForensicSoftware, "license_id" | "office_id">,
    Partial<Pick<ForensicSoftware, "license_id" | "office_id">> {}

export interface EditSoftwareFormData extends Partial<SoftwareFormData> {}

interface DefaultSoftwareFormProps {
  defaultFormData?: SoftwareFormData;
  buttonProps?: { cancelText?: string; submitText?: string };
  onSubmit: (data: SoftwareFormData, diffData?: EditSoftwareFormData) => void;
  onCancel: () => void;
}

// FORM VALIDATION
const currencyRegex = /^$|^\d+(\.\d{1,2})?$/;
const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;

const softwareValidationSchema = z.object({
  software_name: z.string({
    required_error: "Software name is required",
  }),
  vendor: z.string().nullable().optional(),
  name: z.string().nullable().optional(),
  edition: z.string().nullable().optional(),
  purchase_date: z
    .string()
    .regex(
      isoDateRegex,
      `Invalid date format. Please select a date or enter in "YYYY-MM-DD" format.`
    )
    .nullable()
    .optional(),
  expire_date: z
    .string()
    .regex(
      isoDateRegex,
      `Invalid date format. Please select a date or enter in "YYYY-MM-DD" format.`
    )
    .nullable()
    .optional(),
  cost: z
    .string()
    .regex(currencyRegex, "Invalid - please enter a cost number")
    .nullable()
    .optional(),
  sms: z
    .string()
    .regex(currencyRegex, "Invalid - please enter a cost number")
    .nullable()
    .optional(),
  license_key: z.string().nullable().optional(),
  dongle_sn: z.string().nullable().optional(),
  location: z.string().nullable().optional(),
  description: z.string().nullable().optional(),
});

const DefaultSoftwareForm: FC<DefaultSoftwareFormProps> = ({
  defaultFormData,
  buttonProps,
  onSubmit,
  onCancel,
}) => {
  const form = useForm<SoftwareFormData>({
    mode: "uncontrolled",
    initialValues: defaultFormData,
  });

  const handleSubmit = async () => {
    const formData = form.getValues();

    const validationResult = await validateAsync(softwareValidationSchema)(
      formData
    );
    if (validationResult?.hasErrors) {
      form.setErrors(validationResult?.errors);
      return;
    }

    const diffData = diffFormData(defaultFormData, formData);
    onSubmit?.(formData, diffData);
  };

  const handleCancel = () => {
    form.reset();
    onCancel?.();
  };

  return (
    <>
      <Grid col={2}>
        <SoftwareSelector
          defaultValue={defaultFormData?.software_name}
          key={form.key("software_name")}
          {...form.getInputProps("software_name")}
        />
        <VendorSelector
          defaultValue={defaultFormData?.vendor}
          openOnFocus={true}
          queryKey="software"
          key={form.key("vendor")}
          {...form.getInputProps("vendor")}
        />
        <TextInput
          label="License Name"
          placeholder="Enter Value"
          description="Enter the name of the software license."
          key={form.key("name")}
          {...form.getInputProps("name")}
        />
        <TextInput
          label="Edition"
          placeholder="Enter Value"
          description="Enter the edition of the software license."
          key={form.key("edition")}
          {...form.getInputProps("edition")}
        />
        <DateInput
          label="Purchase Date"
          variant="outlined"
          description="Enter the date the software license was purchased."
          clearable={true}
          arrow={true}
          enableCalendar={true}
          format={
            getDateFormat({
              isMoment: true,
              includeTime: false,
            }) as DateInputFormat
          }
          key={form.key("purchase_date")}
          {...form.getInputProps("purchase_date")}
        />
        <DateInput
          label="Expire Date"
          variant="outlined"
          description="Enter the date the software license will expire."
          clearable={true}
          arrow={true}
          enableCalendar={true}
          format={
            getDateFormat({
              isMoment: true,
              includeTime: false,
            }) as DateInputFormat
          }
          key={form.key("expire_date")}
          {...form.getInputProps("expire_date")}
        />
        <TextInput
          label="Cost"
          placeholder="Enter Value"
          description="Enter the cost of the software license."
          type="number"
          min={0}
          key={form.key("cost")}
          {...form.getInputProps("cost")}
        />
        <TextInput
          label="SMS"
          placeholder="Enter Value"
          description="Enter the SMS of the software license."
          type="number"
          min={0}
          key={form.key("sms")}
          {...form.getInputProps("sms")}
        />
        <TextInput
          label="License Key"
          placeholder="Enter Value"
          description="Enter the license key of the software license."
          key={form.key("license_key")}
          {...form.getInputProps("license_key")}
        />
        <TextInput
          label="Dongle S/N"
          placeholder="Enter Value"
          description="Enter dongle serial number"
          key={form.key("dongle_sn")}
          {...form.getInputProps("dongle_sn")}
        />
        <LocationSelector
          defaultValue={defaultFormData?.location}
          key={form.key("location")}
          queryKey="software"
          {...form.getInputProps("location")}
        />
        <TextAreaInput
          size="sm"
          variant="outlined"
          placeholder="A brief description of the software item"
          description="Enter a brief description of the software item."
          colSpan={2}
          minRows={6}
          maxRows={24}
          label="Description"
          key={form.key("description")}
          {...form.getInputProps("description")}
        />
      </Grid>
      <ButtonMenu>
        <Button size="xs" variant="subtle" onClick={handleCancel}>
          {buttonProps?.cancelText || "Cancel"}
        </Button>
        <Button
          size="xs"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          {buttonProps?.submitText || "Submit"}
        </Button>
      </ButtonMenu>
    </>
  );
};

export default DefaultSoftwareForm;
