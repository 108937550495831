const convertCustomFieldsToArray = (customFields, formData) => {
  return Object.keys(formData)
    .filter((key) => key.startsWith("custom_attribute_")) // don't process non-custom fields
    .map((key) => {
      const id = parseInt(key.replace("custom_attribute_", ""));
      return {
        ...(customFields.find((field) => field.field_id === id) || {}),
        value: formData[key],
      };
    });
};

export default convertCustomFieldsToArray;
