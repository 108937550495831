import { z } from "zod";
import { Button, Grid, TextAreaInput } from "@monolith-forensics/monolith-ui";
import ButtonMenu from "./components/ButtonMenu.js";
import { useForm, zodResolver } from "@mantine/form";
import diffFormData from "./utils/diffFormData.js";

// Validation schema
const schema = z.object({
  item_name: z
    .string({ message: "Name is required" })
    .min(1, { message: "Name is required" }),
  checklist_id: z.number().positive().min(1),
  group_id: z.number().positive().min(1),
});

const DefaultQaChecklistItemForm = ({
  defaultFormData = {},
  buttonProps = {},
  onSubmit,
  onCancel,
}) => {
  const form = useForm({
    mode: "uncontrolled",
    initialValues: defaultFormData,
    validate: zodResolver(schema),
  });

  const handleSubmit = async () => {
    const validateResult = form.validate();

    if (validateResult.hasErrors) {
      return;
    }

    const formData = form.getValues();

    const diffData = diffFormData(defaultFormData, formData);

    // call onSubmit with modeled data and diff data
    // diff data is used when the form is in edit context
    // diff may be used when we only want to update modified values
    onSubmit?.(formData, diffData);
  };

  const handleCancel = () => {
    form.reset();
    onCancel?.();
  };

  return (
    <>
      <Grid col={1} style={{ marginBottom: 10 }}>
        <TextAreaInput
          size="sm"
          variant="outlined"
          label="Checklist Item Name/Description"
          placeholder="Enter item name"
          required
          description={
            "This is the name or description of your checklist item. This will be used to identify the checklist item in the system."
          }
          minRows={8}
          maxRows={24}
          key={form.key("item_name")}
          {...form.getInputProps("item_name")}
        />
      </Grid>
      <ButtonMenu>
        <Button size="xs" variant="subtle" onClick={handleCancel}>
          {buttonProps.cancelText || "Cancel"}
        </Button>
        <Button
          size="xs"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          {buttonProps.submitText || "Submit"}
        </Button>
      </ButtonMenu>
    </>
  );
};

export default DefaultQaChecklistItemForm;
