import { useRef, useState } from "react";
import {
  Button,
  Grid,
  Pill,
  TextAreaInput,
  TextInput,
} from "@monolith-forensics/monolith-ui";
import ButtonMenu from "./components/ButtonMenu.js";
import styled from "styled-components";

export const EmailList = styled(
  ({
    className,
    emails,
    onRemove,
  }: {
    className?: string;
    emails: string[];
    onRemove: (email: string) => void;
  }) => {
    return (
      <div className={className}>
        {emails.map((email) => {
          return <Pill onRemove={() => onRemove(email)}>{email}</Pill>;
        })}
      </div>
    );
  }
)`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
  height: auto;

  user-select: none;
`;

const isEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

const ShareItemForm = ({
  buttonProps = {},
  onSubmit,
  onCancel,
  defaultFormData,
}: {
  buttonProps?: { cancelText?: string; submitText?: string };
  onSubmit: ({
    emails,
    message,
  }: {
    emails: string[];
    message: string | undefined;
  }) => void;
  onCancel: () => void;
  defaultFormData?: {
    emails?: string[];
    message?: string;
    selectedItem?: any;
    selectedItems?: any[];
    caseInfo?: { uuid: string; case_id: number };
  };
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [emails, setEmails] = useState<string[]>(defaultFormData?.emails || []);
  const [message, setMessage] = useState<string | undefined>(
    defaultFormData?.message
  );
  const [error, setError] = useState<string | undefined>();

  const handleSubmit = async () => {
    if (emails.length === 0) {
      setError("Please enter an email address");
      return;
    }

    setError(undefined);

    onSubmit?.({ ...defaultFormData, emails, message });
  };

  const handleCancel = () => {
    onCancel?.();
  };

  const handleAddEmail = (email: string) => {
    if (!isEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }

    if (emails.includes(email)) {
      return;
    }

    setError(undefined);
    if (inputRef.current) inputRef.current.value = "";
    setEmails([...emails, email]);
  };

  return (
    <>
      <Grid col={1} width={400} style={{ marginBottom: 10 }}>
        <TextInput
          ref={inputRef}
          error={error}
          size="sm"
          variant="outlined"
          label="Share With"
          description="Enter the name or email address of the person you want to share this item with."
          placeholder="Enter Email Address"
          onKeyDown={(e: React.KeyboardEvent) => {
            const target = e.target as HTMLInputElement;
            // Enter Key
            if (e.key === "Enter") {
              e.preventDefault();
              handleAddEmail(target.value);
            }

            // Tab Key
            if (e.key === "Tab") {
              if (target.value === "") {
                return;
              }
              e.preventDefault();
              handleAddEmail(target.value);
            }

            // Space Key
            if (e.key === " ") {
              e.preventDefault();
              handleAddEmail(target.value);
            }
          }}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            if (e.target.value === "") {
              return;
            }
            handleAddEmail(e.target.value);
          }}
        />
        {emails.length > 0 && (
          <EmailList
            emails={emails}
            onRemove={(email) => {
              setEmails(emails.filter((e) => e !== email));
            }}
          />
        )}
        <TextAreaInput
          size="sm"
          variant="outlined"
          label="Message"
          placeholder="Enter a share message"
          description={
            "Provide a brief message to the person you are sharing this item with."
          }
          minRows={4}
          maxRows={8}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setMessage(e.target.value)
          }
        />
      </Grid>
      <ButtonMenu>
        <Button size="xs" variant="subtle" onClick={handleCancel}>
          {buttonProps.cancelText || "Cancel"}
        </Button>
        <Button
          size="xs"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          {buttonProps.submitText || "Submit"}
        </Button>
      </ButtonMenu>
    </>
  );
};

export default ShareItemForm;
