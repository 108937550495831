import UserApi from "../../api/users/users.js";

const QueryFilterDimensions = [
  {
    name: "Created On",
    field: "created_on",
    id: "created_on",
    type: "date",
    mode: "date",
  },
  {
    name: "Created By",
    field: "created_by",
    id: "created_by",
    type: "string",
    mode: "multi-select",
    key: ["users:list", { includeObservers: false, includeInactive: false }],
    selectionDisplayName: "full_name",
    selectionIdField: "user_id",
    admin: true,
    queryFn: () =>
      UserApi.getUsers({ includeObservers: false, includeInactive: false }),
  },
  {
    name: "Category",
    field: "category_id",
    id: "category_id",
    type: "string",
    mode: "multi-select",
    key: ["metrics-reports:categories"],
    selectionDisplayName: "text",
    selectionIdField: "category_id",
    queryFn: () =>
      Promise.resolve([
        {
          text: "Cases - Overview",
          category_id: 1,
        },
        {
          text: "Evidence - Overview",
          category_id: 2,
        },
        {
          text: "Storage - Overview",
          category_id: 3,
        },
        {
          text: "QA Entries - Overview",
          category_id: 4,
        },
        {
          text: "Acquisitions - Overview",
          category_id: 5,
        },
        {
          text: "Forensic Partner Reporting - FPR",
          category_id: 6,
        },
        {
          text: "Time Entries - Overview",
          category_id: 7,
        },
      ]),
  },
];

export default QueryFilterDimensions;
