import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import CheckBox from "../CheckBox.js";
import {
  ChevronDown,
  ChevronRight,
  MoreHorizontal,
  Trash2,
} from "lucide-react";

import Input from "../Input.js";
import DropdownMenu from "../DropdownMenu/DropdownMenu.js";

const TreeList = ({
  items,
  readOnly = false,
  activeItems = [],
  currentSelection = [],
  showCheckboxes = false,
  onSelectionChanged = () => {},
  onItemClicked = () => {},
  onItemDbClicked = () => {},
  onItemDrop = () => {},
  onItemRename = () => {},
  onItemDelete = () => {},
  onItemDragging = () => {},
  onDragOver = () => {},
  itemMenuProps = {},
  ...props
}) => {
  const [checkedItems, setCheckedItems] = useState([]);
  const draggingItemRef = useRef();

  const handleChecked = (node, checked = null) => {
    if (checked) {
      setCheckedItems((prev) => [...prev, node.id]);
    } else {
      setCheckedItems((prev) => prev.filter((item) => item !== node.id));
    }

    if (node.children) {
      node.children.forEach((child) => {
        handleChecked(child, checked);
      });
    }
  };

  const isChecked = (node) => {
    return checkedItems.includes(node.id);
  };

  return (
    <TreeListContent
      items={items}
      readOnly={readOnly}
      activeItems={activeItems}
      currentSelection={currentSelection}
      showCheckboxes={showCheckboxes}
      checkedItems={checkedItems}
      onSelectionChanged={onSelectionChanged}
      handleChecked={handleChecked}
      isChecked={isChecked}
      onItemClicked={onItemClicked}
      onItemDbClicked={onItemDbClicked}
      onItemDrop={onItemDrop}
      onItemRename={onItemRename}
      onItemDelete={onItemDelete}
      onItemDragging={onItemDragging}
      onDragOver={onDragOver}
      draggingItemRef={draggingItemRef}
      itemMenuProps={itemMenuProps}
      {...props}
    />
  );
};

const TreeListContent = styled(
  ({
    className,
    parentNode = null,
    items,
    readOnly = false,
    activeItems = [],
    currentSelection = [],
    depth = 0,
    showCheckboxes = false,
    checkedItems,
    handleChecked = () => {},
    onSelectionChanged = () => {},
    isChecked = () => {},
    onItemClicked = () => {},
    onItemDbClicked = () => {},
    onItemDrop = () => {},
    onItemRename = () => {},
    onItemDelete = () => {},
    onItemDragging = () => {},
    onDragOver = () => {},
    draggingItemRef,
    itemMenuProps = {},
    ...props
  }) => {
    const [openItems, setOpenItems] = useState(
      items.filter((item) => item.expanded).map((item) => item.id)
    );
    const [isDraggingOver, setIsDraggingOver] = useState(false);

    const openItemsRef = useRef();
    openItemsRef.current = openItems;

    const handleClick = (node) => {
      const index = openItems.indexOf(node.id);

      if (index === -1) {
        setOpenItems([...openItems, node.id]);
      } else {
        setOpenItems([
          ...openItems.slice(0, index),
          ...openItems.slice(index + 1),
        ]);
      }

      onItemClicked(node);
    };

    const handleDblClick = (node) => {
      onItemDbClicked(node);
    };

    const isNodeOpen = (node) => {
      return openItemsRef.current.includes(node.id);
    };

    const handleSelectionChanged = (node) => {
      onSelectionChanged({
        node,
        selectedItems: checkedItems,
      });
    };

    const handleDragStart = (node) => {
      draggingItemRef.current = node;
      onItemDragging(node);
    };

    const handleDragEnter = (node) => {
      const hasChildren = node.children !== undefined && node.children !== null;
      if (!hasChildren) setIsDraggingOver(true);
      onDragOver(hasChildren ? node : parentNode);
    };

    const handleDragLeave = (node) => {
      const hasChildren = node.children !== undefined && node.children !== null;
      if (!hasChildren) setIsDraggingOver(false);
    };

    const handleItemDrop = (node) => {
      const hasChildren = node.children !== undefined && node.children !== null;

      onItemDrop({
        source: draggingItemRef.current,
        target: hasChildren ? node : parentNode,
      });
      setIsDraggingOver(false);
    };

    return (
      <div
        className={
          className + " mf-treelist" + (isDraggingOver ? " dragover" : "")
        }
      >
        <div className="list-items-container">
          {parentNode && <div className="level-line"></div>}
          {items.map((node, index) => {
            const expanded = isNodeOpen(node);
            return (
              <div key={node.id + index}>
                <TreeListItem
                  item={node}
                  readOnly={readOnly}
                  active={activeItems.includes(node.id)}
                  selected={currentSelection.includes(node.id)}
                  depth={depth}
                  expanded={expanded}
                  checked={isChecked(node)}
                  showCheckboxes={showCheckboxes}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClick(node);
                  }}
                  onDoubleClick={() => handleDblClick(node)}
                  onItemDrop={() => handleItemDrop(node)}
                  onRename={(value) =>
                    onItemRename({ item: node, newName: value })
                  }
                  onDelete={() => onItemDelete({ item: node })}
                  onDragStart={() => handleDragStart(node)}
                  onDragEnter={() => handleDragEnter(node)}
                  onDragLeave={() => handleDragLeave(node)}
                  onChecked={() => {
                    handleChecked(node, !isChecked(node));
                    handleSelectionChanged(node);
                  }}
                  itemMenuProps={itemMenuProps}
                  {...props}
                />

                {node?.children?.length > 0 && expanded && (
                  <TreeListContent
                    parentNode={node}
                    items={node.children}
                    readOnly={readOnly}
                    activeItems={activeItems}
                    currentSelection={currentSelection}
                    depth={depth + 1}
                    checkedItems={checkedItems}
                    showCheckboxes={showCheckboxes}
                    handleChecked={handleChecked}
                    isChecked={isChecked}
                    onItemClicked={onItemClicked}
                    onItemDbClicked={onItemDbClicked}
                    onItemDrop={onItemDrop}
                    onItemRename={onItemRename}
                    onItemDelete={onItemDelete}
                    onItemDragging={onItemDragging}
                    onDragOver={onDragOver}
                    draggingItemRef={draggingItemRef}
                    itemMenuProps={itemMenuProps}
                    {...props}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
)`
  pointer-events: all;
  position: relative;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  user-select: none;
  margin-top: 2px;
  width: 100%;

  &.dragover {
    background-color: ${(props) => props.theme.palette.action.hover};
  }

  .level-line {
    position: absolute;
    left: ${(props) => (props.depth ? props.depth * 15 - 7.5 : 15)}px;
    top: 0;
    width: 1px;
    background-color: ${(props) => props.theme.palette.divider};
    margin-top: 2.5px;
    margin-bottom: 2.5px;
    margin-right: 5px;
    height: calc(100% - 10px);
    z-index: 1;
  }

  .list-items-container {
    position: relative;
    width: 100%;
  }
`;

const Expander = styled(({ className, expanded, hasChildren }) => {
  return (
    <div className={className + " mf-treelist-expander"}>
      {hasChildren ? (
        expanded ? (
          <ChevronDown size={15} />
        ) : (
          <ChevronRight size={15} />
        )
      ) : null}
    </div>
  );
})`
  display: flex;
  align-items: center;

  min-width: 15px;
`;

const ItemMenu = styled(
  ({
    className,
    item,
    itemMenuProps = {},
    readOnly = false,
    handleRename,
    handleDelete,
  }) => {
    const { items = [] } = itemMenuProps;

    const handleSelect = (menuItem) => {
      if (menuItem.onClick) menuItem.onClick(item);
    };

    const menuItems = [
      ...items.map((item) => ({
        label: item.text,
        onClick: () => handleSelect(item),
      })),
    ];

    if (!readOnly)
      menuItems.push(
        {
          label: "Rename",
          onClick: () => handleRename(item),
        },
        {
          separator: true,
        },
        {
          label: "Delete",
          onClick: () => handleDelete(item),
          render: () => (
            <div style={{ display: "flex", color: "orangered" }}>
              <Trash2 size={12} style={{ marginRight: 5 }} />
              Delete
            </div>
          ),
        }
      );

    return (
      <div
        className={className + " mf-treelist-item-menu"}
        onClick={(e) => e.stopPropagation()}
      >
        <DropdownMenu
          menuItems={menuItems}
          title={"Item Actions"}
          variant="outlined"
          style={{
            minWidth: 125,
          }}
        >
          <MoreHorizontal size={12} />
        </DropdownMenu>
      </div>
    );
  }
)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: all 0.1s ease-in-out;
  border-radius: 4px;
  padding: 0 5px;
  margin-left: auto;

  color: ${(props) => props.theme.palette.text.secondary};
`;

const TreeListItem = styled(
  ({
    className,
    item,
    readOnly = false,
    active,
    selected,
    depth,
    expanded,
    checked = false,
    onClick = () => {},
    onDoubleClick = () => {},
    onItemDrop = () => {},
    onDragStart = () => {},
    onDragEnter = () => {},
    onDragLeave = () => {},
    onChecked = () => {},
    showCheckboxes,
    itemMenuProps = {},
    onRename = () => {},
    onDelete = () => {},
    ...props
  }) => {
    const hasChildren = item.children !== undefined && item.children !== null;
    const itemRef = useRef();
    const inputRef = useRef();
    const [newLabel, setNewLabel] = useState(item.label);
    const [isRenaming, setIsRenaming] = useState(false);

    const Test = ({ children }) => <>{children}</>;
    const TooltipContent = props?.tooltipComponent || Test;

    const handleRename = () => {
      setIsRenaming(true);
    };

    const handleBlur = (value) => {
      setIsRenaming(false);
      setNewLabel(value);
      onRename(value);
    };

    const handleTitleKeyPress = (e) => {
      // Handle Enter key
      if (e.keyCode === 13) {
        e.preventDefault();
        e.target.blur();
      }

      // handle tab key
      if (e.keyCode === 9) {
        e.preventDefault();
        e.target.blur();
      }
    };

    const handleDelete = () => {
      onDelete();
    };

    useEffect(() => {
      setNewLabel(item.label);
    }, [item.label]);

    useEffect(() => {
      if (isRenaming) {
        inputRef.current.focus();
        // Select all
        inputRef.current.select();
      }
    }, [isRenaming]);

    return (
      <TooltipContent item={item}>
        <div
          className={
            className +
            " mf-treelist-item" +
            `${hasChildren ? " node-container" : ""}`
          }
          ref={itemRef}
          data-active={active}
          data-selected={selected}
          data-draggable={!readOnly && !isRenaming}
          onClick={(e) => {
            onClick(e);
            if (e.detail === 2) onDoubleClick(e);
          }}
          draggable={!readOnly && !isRenaming}
          onDragOver={(e) => {
            e.preventDefault();
            e.dataTransfer.dropEffect = "move";
          }}
          onDragEnter={(e) => {
            e.target.classList.add("dragover");
            onDragEnter();
          }}
          onDragLeave={(e) => {
            e.target.classList.remove("dragover");
            onDragLeave();
          }}
          onDrop={(e) => {
            e.preventDefault();
            e.target.classList.remove("dragover");
            onItemDrop();
          }}
          onDragStart={(e) => {
            //set dragging class
            e.target.classList.add("dragging");

            const image = document.createElement("div");
            image.classList.add("dragging-clone");
            image.classList.add("mf-treelist-item");
            // Sanitize HTML in label
            const label = document.createElement("div");
            label.innerHTML = item.label;
            const labelText = label.textContent || label.innerText || "";
            label.remove();

            if (hasChildren) {
              image.classList.add("node-container");

              image.innerHTML = `
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-folder"><path d="M4 20h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-7.93a2 2 0 0 1-1.66-.9l-.82-1.2A2 2 0 0 0 7.93 3H4a2 2 0 0 0-2 2v13c0 1.1.9 2 2 2Z"/></svg>
                    <div class="label-text">${labelText}</div>
                `;
            } else {
              image.innerHTML = `
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file sc-eBXDlP fyINje"><path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z"></path><path d="M14 2v4a2 2 0 0 0 2 2h4"></path></svg>
                    <div class="label-text">${labelText}</div>
                `;
            }

            // Add clone to body
            // insert image to at top of body
            document.body.insertBefore(image, document.body.firstChild);

            // Set data transfer
            e.dataTransfer.setDragImage(image, 0, 0);

            // Set dragging item
            onDragStart();
          }}
          onDragEnd={(e) => {
            e.target.classList.remove("dragging");

            // Remove clone
            const clone = document.querySelector(".dragging-clone");
            if (clone) {
              clone.remove();
            }
          }}
          {...props}
        >
          <div
            className="inner-label"
            style={{ pointerEvents: isRenaming ? "auto" : "none" }}
          >
            <Expander expanded={expanded} hasChildren={hasChildren} />
            <div style={{ display: "flex" }}>
              {hasChildren ? props?.nodeIcon : props.leafIcon}
            </div>
            {showCheckboxes && (
              <CheckBox
                checked={checked}
                onChange={() => onChecked()}
                style={{ marginRight: 5 }}
              />
            )}
            {!isRenaming && <div className="item-label">{newLabel}</div>}
            {isRenaming && (
              <Input.StyledInput
                ref={inputRef}
                defaultValue={newLabel}
                onClick={(e) => e.stopPropagation()}
                onBlur={(e) => handleBlur(e.target.value)}
                onKeyDown={handleTitleKeyPress}
                style={{ padding: 4 }}
                placeholder="Enter a name"
              />
            )}
          </div>
          <ItemMenu
            item={item}
            itemMenuProps={itemMenuProps}
            readOnly={readOnly}
            handleDelete={handleDelete}
            handleRename={handleRename}
          />
        </div>
      </TooltipContent>
    );
  }
)`
  position: relative;
  user-select: none;
  width: 100%;
  min-width: 100px;
  min-height: 30px;
  color: ${(props) => props.theme.palette.text.secondary};
  font-weight: 500;
  font-size: 0.75rem;
  padding: 5px;
  padding-left: ${(props) =>
    props.depth ? props.depth * 15 + (props.item.useEmptyIcon ? 15 : 0) : 0}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  border-radius: 3px;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.1s ease-in-out;
  border: 1px solid transparent;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  .inner-label {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    width: 85%;
    max-width: 85%;
  }

  .item-label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }

  &.node-container.dragover {
    background-color: ${(props) => props.theme.palette.action.hover};
    color: ${(props) => props.theme.palette.primary.main};
    font-weight: 600;
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.action.hover};

    .mf-treelist-item-menu {
      opacity: 1;
    }
  }

  &[data-active="true"] {
    color: ${(props) => props.theme.palette.primary.main};
  }

  &:has(button[data-state="open"]) {
    background-color: ${(props) => props.theme.palette.action.hover};
    .mf-treelist-item-menu {
      opacity: 1;
    }
  }

  &[data-selected="true"] {
    background-color: ${(props) => props.theme.palette.action.hover};
    color: ${(props) => props.theme.palette.text.primary};
    border: 1px solid ${(props) => props.theme.palette.divider};
    font-weight: 600;
  }
`;

export default TreeList;
