import { useQuery, useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";
import {
  Button,
  Flyout,
  FlyoutHeader,
  FlyoutTitle,
} from "@monolith-forensics/monolith-ui";

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EditIcon,
  TrashIcon,
} from "lucide-react";

import {
  DetailItem,
  DetailLabel,
  DetailValue,
  FlyoutContainer,
  HeaderContent,
} from ".";

import { getClients } from "@/api";
import DeleteClientModal from "../Modals/DeleteClientModal";
import EditClientModal from "../Modals/EditClientModal";
import ClientsColumnDefs from "../Clients/ClientsColumnDefs";
import { Client } from "@/types";

interface ClientFlyoutProps {
  show: boolean;
  onClose?: () => void;
  defaultInfo: Client;
  onEdit: () => void;
  onDelete: () => void;
  onNext: () => void;
  onPrevious: () => void;
}

interface FlyoutDetailsProps {
  defaultData: Client;
}

const MainDetails: FC<FlyoutDetailsProps> = ({ defaultData }) => {
  return (
    <FlyoutContainer>
      <div className="event-details">
        {ClientsColumnDefs.filter((col) => col.dataField !== "notes").map(
          (col) => {
            const key = col.dataField as keyof Client;
            const label = col.caption;
            const value = col?.render
              ? col.render(defaultData)
              : defaultData[key];

            return (
              <DetailItem key={key}>
                <DetailLabel>{label}</DetailLabel>
                <DetailValue>{value}</DetailValue>
              </DetailItem>
            );
          }
        )}
        <DetailItem>
          <DetailLabel>Notes</DetailLabel>
        </DetailItem>
        <DetailValue>
          {defaultData.notes || "No notes for this client."}
        </DetailValue>
      </div>
    </FlyoutContainer>
  );
};

const ClientFlyout: React.FC<ClientFlyoutProps> = ({
  show,
  onClose,
  defaultInfo,
  onEdit,
  onDelete,
  onNext,
  onPrevious,
}) => {
  const queryClient = useQueryClient();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const { data: itemData, isLoading } = useQuery({
    queryKey: ["client", "item", defaultInfo.client_id],
    queryFn: () => getClients({ client_id: defaultInfo.client_id }),
    initialData: [defaultInfo],
    select: (res) => {
      return res[0];
    },
  });

  return (
    <Flyout open={show} onClose={() => onClose?.()}>
      {isLoading && <div>Loading...</div>}
      {!isLoading && (
        <>
          <FlyoutHeader>
            <FlyoutTitle>{itemData.name}</FlyoutTitle>
            <HeaderContent>
              <div>Client</div>
              <Button
                size="xxs"
                variant="text"
                leftSection={<EditIcon size={14} />}
                color="primary"
                onClick={() => setShowEditModal(true)}
              >
                Edit
              </Button>
              <Button
                size="xxs"
                variant="text"
                leftSection={<TrashIcon size={14} />}
                color="error"
                onClick={() => setShowDeleteModal(true)}
              >
                Delete
              </Button>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  alignItems: "center",
                  marginLeft: "auto",
                }}
              >
                <Button
                  size="xxs"
                  variant="outlined"
                  onClick={() => onPrevious?.()}
                  style={{ padding: "2px 4px" }}
                >
                  <ChevronLeftIcon size={14} />
                </Button>
                <Button
                  size="xxs"
                  variant="outlined"
                  onClick={() => onNext?.()}
                  style={{ padding: "2px 4px" }}
                >
                  <ChevronRightIcon size={14} />
                </Button>
              </div>
            </HeaderContent>
          </FlyoutHeader>
          <MainDetails defaultData={itemData} />
          <EditClientModal
            onSubmit={() => {
              queryClient.invalidateQueries({
                queryKey: ["client", "item", defaultInfo.client_id],
              });
              queryClient.invalidateQueries({
                queryKey: ["clients", "list"],
              });
              onEdit();
            }}
            show={showEditModal}
            onClose={() => setShowEditModal(false)}
            onCancel={() => setShowEditModal(false)}
            defaultInfo={itemData}
          />
          <DeleteClientModal
            show={showDeleteModal}
            onCancel={() => setShowDeleteModal(false)}
            onClose={() => setShowDeleteModal(false)}
            onSubmit={() => {
              onDelete?.(); // Refetches queries in client section
              setShowDeleteModal(false);
            }}
            defaultInfo={itemData}
          />
        </>
      )}
    </Flyout>
  );
};

export default ClientFlyout;
