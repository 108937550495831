import { Box, Button, useTheme } from "@mui/material";
import { Popup } from "devextreme-react/ui/popup";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import { useEffect, useRef, useState } from "react";
import {
  createCaseType,
  deleteCaseType,
  getCaseTypes,
  updateCaseType,
} from "../../api";
import Loader from "../../components/Loader";
import ToolBarItems from "../../components/ToolBarItems";
import { usePermissions } from "../../hooks/usePermissions";
import styled from "styled-components";
import { CaseTypeItem as CaseTypeItemInterface } from "../Cases/CaseDetails/CaseDetails";

const CaseTypesList = styled(
  ({
    className,
    caseTypes,
    setCaseTypes,
    setDeleteSelection,
    deleteCaseTypePopup,
  }: {
    className: string;
    caseTypes: CaseTypeItemInterface[] | null;
    setCaseTypes: React.Dispatch<
      React.SetStateAction<CaseTypeItemInterface[] | null>
    >;
    setDeleteSelection: React.Dispatch<
      React.SetStateAction<CaseTypeItemInterface | null>
    >;
    deleteCaseTypePopup: React.MutableRefObject<any>;
  }) => {
    useEffect(() => {
      getCaseTypes().then((result) => {
        setCaseTypes(result);
      });
    }, []);

    return (
      <div className={className}>
        {!caseTypes && <Loader />}
        {caseTypes &&
          caseTypes.map((type) => {
            return (
              <CaseTypeItem
                key={type.case_type_id}
                data={type}
                caseTypes={caseTypes}
                setCaseTypes={setCaseTypes}
                setDeleteSelection={setDeleteSelection}
                deleteCaseTypePopup={deleteCaseTypePopup}
              />
            );
          })}
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
`;

const CaseTypeItem = ({
  data,
  caseTypes,
  setCaseTypes,
  setDeleteSelection,
  deleteCaseTypePopup,
}: {
  data: CaseTypeItemInterface;
  caseTypes: CaseTypeItemInterface[];
  setCaseTypes: React.Dispatch<
    React.SetStateAction<CaseTypeItemInterface[] | null>
  >;
  setDeleteSelection: React.Dispatch<
    React.SetStateAction<CaseTypeItemInterface | null>
  >;
  deleteCaseTypePopup: React.MutableRefObject<any>;
}) => {
  const theme = useTheme();
  const { hasPermission } = usePermissions();

  const handleTypeUpdate = (event: React.FocusEvent<HTMLDivElement>) => {
    if (event.currentTarget.innerText === "") {
      event.currentTarget.innerText = data.case_type;
      return;
    }
    if (event.currentTarget.innerText !== data.case_type) {
      updateCaseType(data.case_type_id, {
        case_type: event.currentTarget.innerText,
      });
      setCaseTypes(
        caseTypes.map((type) => {
          if (type.case_type_id === data.case_type_id) {
            type.case_type = event.currentTarget.innerText;
          }
          return type;
        })
      );
    }
  };

  const handleDelete = () => {
    setDeleteSelection(data);
    deleteCaseTypePopup.current.instance.show();
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "10px 10px",
        cursor: "pointer",
        "&:hover": { backgroundColor: theme.palette.action.hover },
      }}
    >
      <div>
        <Box
          contentEditable={true}
          suppressContentEditableWarning
          sx={{
            fontSize: "larger",
            padding: "2px",
            cursor: "text",
            "&:hover": { outline: `1px solid slategray` },
            "&:focus": { outline: `1px solid slategray` },
          }}
          onBlur={handleTypeUpdate}
        >
          {data.case_type}
        </Box>
        <div style={{ color: "slategrey", padding: "2px" }}>
          {data.total} Cases
        </div>
      </div>
      <div style={{ marginLeft: "auto" }}>
        <Button
          variant="text"
          color="error"
          size="small"
          disabled={!hasPermission()}
          onClick={handleDelete}
        >
          Delete
        </Button>
      </div>
    </Box>
  );
};

const CreateCaseTypePopup = ({
  createCaseTypePopup,
  caseTypes,
  setCaseTypes,
}: {
  createCaseTypePopup: React.MutableRefObject<any>;
  caseTypes: CaseTypeItemInterface[] | null;
  setCaseTypes: React.Dispatch<
    React.SetStateAction<CaseTypeItemInterface[] | null>
  >;
}) => {
  const form: any = useRef(null);

  return (
    <Popup
      ref={createCaseTypePopup}
      showTitle={true}
      showCloseButton={true}
      title="Create New Case Type"
      defaultHeight={185}
      defaultWidth={400}
      onHidden={(e) => {
        form.current.instance.resetValues();
      }}
    >
      <Form ref={form}>
        <SimpleItem
          dataField="case_type"
          label={{ text: "Case Type" }}
          isRequired={true}
        ></SimpleItem>
      </Form>
      <ToolBarItems
        submitText="Create Case Type"
        onSubmit={() => {
          if (form.current.instance.validate().isValid) {
            let values = Object.assign(
              {},
              form.current.instance.option("formData")
            );

            createCaseType(values).then((result) => {
              setCaseTypes([
                ...caseTypes!,
                {
                  case_type_id: result.case_type_id,
                  case_type: values.case_type,
                  total: 0,
                },
              ]);
            });

            createCaseTypePopup.current.instance.hide();
          }
        }}
        onCancel={() => {
          createCaseTypePopup.current.instance.hide().then(() => {
            form.current.instance.resetValues();
          });
        }}
      />
    </Popup>
  );
};

const DeleteCaseTypePopup = ({
  deleteCaseTypePopup,
  caseTypes,
  setCaseTypes,
  deleteSelection,
}: {
  deleteCaseTypePopup: React.MutableRefObject<any>;
  caseTypes: CaseTypeItemInterface[];
  setCaseTypes: React.Dispatch<
    React.SetStateAction<CaseTypeItemInterface[] | null>
  >;
  deleteSelection: CaseTypeItemInterface | null;
}) => {
  const [items, setItems] = useState<CaseTypeItemInterface[]>(caseTypes);
  const form: any = useRef(null);

  useEffect(() => {
    deleteSelection &&
      setItems(
        caseTypes.filter(
          (item) => item.case_type_id !== deleteSelection.case_type_id
        )
      );
  }, [deleteSelection]);

  return (
    <Popup
      ref={deleteCaseTypePopup}
      showTitle={true}
      showCloseButton={true}
      title={`Delete Case Type ${deleteSelection?.case_type || ""}`}
      defaultHeight={250}
      defaultWidth={450}
      onHidden={(e) => {
        if (form.current) form.current.instance.resetValues();
      }}
    >
      {deleteSelection?.total === 0 && (
        <div style={{ margin: "15px 0px", minHeight: 115 }}>
          Are you sure you want to delete this case type?
        </div>
      )}
      {deleteSelection?.total! > 0 && (
        <div style={{ minHeight: 115 }}>
          <div style={{ margin: "15px 0px" }}>
            Select the case type that should replace this one when deleted:
          </div>
          <Form ref={form}>
            <SimpleItem
              dataField="case_type"
              label={{ text: "Case Type" }}
              isRequired={true}
              editorType="dxSelectBox"
              editorOptions={{
                items: items,
                displayExpr: "case_type",
              }}
            ></SimpleItem>
          </Form>
        </div>
      )}
      <ToolBarItems
        submitText="Delete Case Type"
        submitColor="error"
        onSubmit={() => {
          if (form.current) {
            if (form.current.instance.validate().isValid) {
              let values = Object.assign(
                {},
                form.current.instance.option("formData")
              );
              deleteCaseType(deleteSelection?.case_type_id, {
                replace: values.case_type.case_type,
              });
              setCaseTypes(
                items.map((item) => {
                  if (item.case_type_id === values.case_type.case_type_id) {
                    item.total += deleteSelection?.total!;
                  }
                  return item;
                })
              );
              deleteCaseTypePopup.current.instance.hide();
            }
          } else {
            deleteCaseTypePopup.current.instance.hide();
            deleteCaseType(deleteSelection?.case_type_id);
            setCaseTypes(items);
          }
        }}
        onCancel={() => {
          deleteCaseTypePopup.current.instance.hide().then(() => {
            if (form.current) form.current.instance.resetValues();
          });
        }}
      />
    </Popup>
  );
};

const CaseTypeSettings = styled(({ className }) => {
  const [caseTypes, setCaseTypes] = useState<CaseTypeItemInterface[] | null>(
    null
  );
  const [deleteSelection, setDeleteSelection] =
    useState<CaseTypeItemInterface | null>(null);
  const createCaseTypePopup = useRef<any>(null);
  const deleteCaseTypePopup = useRef<any>(null);

  return (
    <div className={className} style={{ maxWidth: 800, padding: "0px 30px" }}>
      <div>
        Create, delete, and edit case types. Create case types that will help
        categorize the forensic casework conducted by your organization.
      </div>
      <div style={{ margin: "25px 0px" }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={(e) => {
            createCaseTypePopup.current.instance.show();
          }}
        >
          + Create Case Type
        </Button>
      </div>
      <CaseTypesList
        className=""
        caseTypes={caseTypes}
        setCaseTypes={setCaseTypes}
        setDeleteSelection={setDeleteSelection}
        deleteCaseTypePopup={deleteCaseTypePopup}
      />
      <CreateCaseTypePopup
        createCaseTypePopup={createCaseTypePopup}
        caseTypes={caseTypes}
        setCaseTypes={setCaseTypes}
      />
      {caseTypes && (
        <DeleteCaseTypePopup
          deleteCaseTypePopup={deleteCaseTypePopup}
          caseTypes={caseTypes}
          setCaseTypes={setCaseTypes}
          deleteSelection={deleteSelection}
        />
      )}
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;
`;

export default CaseTypeSettings;
