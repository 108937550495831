import styled from "styled-components";
import Loader from "../../../components/Loader.js";
import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { useInfiniteQuery } from "@tanstack/react-query";
import LoggingColumnDefs from "../../../components/Logging/LoggingColumnDefs.js";
import Table, { Column, useTable } from "../../../Monolith-UI/Table/Table.js";
import LoggingAPI from "../../../api/logging/index.js";
import { useQueryFilter } from "../../../Monolith-UI/QueryFilter/QueryFilter.js";
import LoggingFilterDimensions from "../../../components/Logging/LoggingFilterDimensions.js";
import { getDateFormat } from "../../../utils/date-format";
import { ItemTotal } from "../CaseEvidence/index";
import ComboButton from "../../../Monolith-UI/ComboButton/ComboButton.js";

//Icons
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";
import synchronizeColumnState from "../../../utils/synchronize-column-state.js";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { Input } from "@monolith-forensics/monolith-ui";
import { ColumnProps, TableColumn } from "@/Monolith-UI/Table/types/Table.js";
import { Condition } from "../types/Cases.js";

interface CaseActivityQuery {
  query: {
    conditions?: Condition[];
    page?: number;
    pageSize?: number;
    search?: string | null;
    case_id?: string;
    order?: {
      field: string;
      sort: string;
    } | null;
  };
}

interface CaseActivity {
  activity: string;
  admin: boolean;
  admin_log: number;
  app: string;
  case_id: number;
  case_number: string;
  case_ref: string;
  case_uuid: string;
  client_ip: string;
  event: string;
  event_data: string;
  log_id: number;
  object_type: string;
  object_uuid: string;
  timestamp: string;
  user: {
    email: string;
    title: string;
    user_id: number;
    full_name: string;
    last_name: string;
  };
  user_agent: string;
  user_id: number;
}

const defaultQueryKey = "cases:activity:list";
const pageSize = 100;
const columnDefs = LoggingColumnDefs.filter((c) => c.enabled !== false);

const CaseActivity = styled(({ stateStoreKey = "cases:activity:view" }) => {
  const { case_id } = useParams();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [flyoutData, setFlyoutData] = useState<CaseActivity | null>(null);
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
  const [searchText, setSearchText] = useState(
    localStorage.getItem(`${stateStoreKey}:searchText`)
  );
  const [debouncedSearchText] = useDebounce(searchText, 500);

  const [columnState, setColumnState] = useState(() => {
    return synchronizeColumnState(
      columnDefs,
      JSON.parse(localStorage.getItem(stateStoreKey) || "{}")
    );
  });

  const [query, setQuery] = useState<CaseActivityQuery | null>(null);

  const table = useTable();

  const currentSort = useMemo(() => {
    let [sort] = columnState
      ?.filter((c) => c.sorting?.active)
      .map((c) => ({ field: c.dataField, sort: c.sorting?.direction }));

    return sort;
  }, [columnState]);

  const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: [
        defaultQueryKey,
        {
          query: {
            ...query?.query,
            order: query?.query?.order || currentSort,
            pageSize,
            case_id,
          },
        },
      ],
      queryFn: ({ pageParam }) =>
        LoggingAPI.query({
          query: {
            ...query?.query,
            order: query?.query?.order || currentSort,
            case_id,
            pageSize,
            page: pageParam,
          },
        }),
      getNextPageParam: (lastPage, pages) => {
        return lastPage.nextPage;
      },
      getPreviousPageParam: (firstPage, pages) => {
        if (firstPage.page - 1 === 0) return null;
        return firstPage.page - 1;
      },
      initialPageParam: 1,
      enabled: !!query,
      placeholderData: (data) => data,
    });

  const records = data?.pages?.reduce((acc, page) => {
    return [...acc, ...page.data];
  }, []);

  const totalRecords = data?.pages?.[0]?.total || 0;

  const debouncedFetchNextPage = useDebouncedCallback(() => {
    fetchNextPage();
  }, 50);

  const { queryButton, queryFilter, conditions } = useQueryFilter({
    dimensions: LoggingFilterDimensions.filter((l) => l.enabled !== false),
    onQuerySet: (newFilter) =>
      setQuery((q) => {
        return { query: { ...q?.query, ...newFilter, page: 1, case_id } };
      }),
    stateStoring: {
      enabled: true,
      type: "localStorage",
      storageKey: stateStoreKey,
    },
    queryFilter: undefined,
  });

  const handleSort = (field: string) => {
    const savedColumn =
      columnState?.find((svc) => field === svc.dataField) || {};
    const order = !!savedColumn ? savedColumn?.sorting?.direction : null;

    let newOrder = null;

    // if sorted on a different column, reset the sort
    if (query?.query?.order?.field !== field) {
      newOrder = {
        field,
        sort: "asc",
      };
    }

    // otherwise rotate the sort options on the current column
    else {
      switch (order) {
        case "asc":
          newOrder = {
            field,
            sort: "desc",
          };
          break;
        case "desc":
          newOrder = null;
          break;
        default:
          newOrder = {
            field,
            sort: "asc",
          };
      }
    }

    let newCols = columnState?.map((c) => {
      if (c.dataField === field) {
        return {
          ...c,
          sorting: {
            active: newOrder ? true : false,
            direction: newOrder?.sort,
          },
        };
      }
      delete c.sorting;
      return c;
    });

    let newQuery = {
      query: {
        ...query?.query,
        order: newOrder,
      },
    };

    setColumnState(newCols);

    setQuery(newQuery);
  };
  const handleExportTable = () => {
    // show snackbar
    enqueueSnackbar("Exporting table...", {
      variant: "info",
    });

    LoggingAPI.exportActivityList({
      query: {
        ...query?.query,
        order: query?.query?.order || currentSort,
        pageSize,
      },
      type: "xlsx",
      columns: columnState
        .filter((c) => c.visible !== false && c.showInColumnChooser !== false)
        .sort((a, b) => a.order - b.order)
        .map((c) => {
          return { dataField: c.dataField, header: c.caption, ...c };
        }),
      date_format: getDateFormat({ isMoment: true, includeTime: true }),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }).then((res) => {
      const { signedUrl, filename } = res;
      const el = document.createElement("a");
      el.href = signedUrl.replace(
        "http://localhost:3000",
        "http://localhost:3001"
      );
      el.download = filename;
      el.click();
      // remove snackbar
    });
  };
  const handleColumnReorder = (
    newOrder: { column: string; order: number }[]
  ) => {
    setColumnState((cs) => {
      return newOrder.map((o) => {
        return {
          ...cs.find((c) => c.dataField === o.column),
          order: o.order,
        };
      });
    });
  };
  const handleColumnVisibility = (
    column: ColumnProps<CaseActivity>,
    visible: boolean
  ) => {
    setColumnState((cs) => {
      return cs.map((c) => {
        if (c.dataField === column.dataField) {
          return {
            ...c,
            visible,
          };
        }
        return c;
      });
    });
  };
  const handleActionButtonClick = (rowData: CaseActivity) => {
    setFlyoutData(rowData);
    setIsFlyoutVisible(true);
  };
  const handleClearFilters = () => queryFilter.clear();
  // Detect scroll to bottom
  const handleScroll = (e: React.MouseEvent) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    const pageLength = data?.pages?.length ? data?.pages?.length : 0;
    if (scrollHeight - scrollTop <= clientHeight + 100 * pageLength) {
      if (hasNextPage && !isFetchingNextPage) {
        debouncedFetchNextPage();
      }
    }
  };

  // Persist column state to local storage
  useEffect(() => {
    const localStorageItem = localStorage.getItem(stateStoreKey);
    let oldState = localStorageItem ? JSON.parse(localStorageItem) : {};
    localStorage.setItem(
      stateStoreKey,
      JSON.stringify({
        ...oldState,
        cols: columnState,
      })
    );
  }, [columnState]);

  useEffect(() => {
    setQuery((q) => ({
      query: {
        ...q?.query,
        search: debouncedSearchText ? debouncedSearchText : null,
        page: 1,
      },
    }));
  }, [debouncedSearchText]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flex: "initial",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <div>{queryButton}</div>
        <ItemTotal total={totalRecords} Icon={ArticleOutlinedIcon} />
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            minWidth: "fit-content",
          }}
        >
          <ComboButton
            type="multi-select"
            data={columnState}
            displayField="caption"
            idField={"dataField"}
            selectedItems={columnState.filter((c) => c.visible !== false)}
            variant="outlined"
            closeOnSelect={false}
            textColor={theme.palette.text.secondary}
            title={"Select Columns"}
            showSearch={true}
            dropDownTitle={() => {
              return (
                <div
                  style={{
                    margin: "5px 0px",
                    padding: 3,
                    color: theme.palette.text.secondary,
                    display: "flex",
                    alignItems: "center",
                    minWidth: 200,
                  }}
                >
                  Select Columns
                </div>
              );
            }}
            onItemDeSelect={(item) => {
              handleColumnVisibility(item, false);
            }}
            onItemSelect={(item) => {
              handleColumnVisibility(item, true);
            }}
          >
            <ViewColumnOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            textColor={theme.palette.text.secondary}
            title={"Export Table"}
            onClick={handleExportTable}
          >
            <FileDownloadOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            textColor={theme.palette.text.secondary}
            title={"Clear Filters"}
            onClick={handleClearFilters}
          >
            <FilterListOffIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            textColor={theme.palette.text.secondary}
            title={table.isCompact ? "Zoom In" : "Zoom Out"}
            onClick={() => table.toggleCompact()}
          >
            {table.isCompact ? (
              <ZoomOutMapOutlinedIcon style={{ fontSize: 18 }} />
            ) : (
              <ZoomInMapOutlinedIcon style={{ fontSize: 18 }} />
            )}
          </ComboButton>
          <ComboButton
            type="button"
            variant={"outlined"}
            textColor={
              table.isStriped
                ? theme.palette.primary.main
                : theme.palette.text.secondary
            }
            title={table.isStriped ? "Hide Stripes" : "Show Stripes"}
            onClick={() => table.toggleStripes()}
          >
            <ReorderOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <Input
            placeholder="Search Logs"
            variant="outlined"
            height={30}
            defaultValue={searchText}
            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
              const currentValue = e.currentTarget.value.trim();
              setSearchText(currentValue);

              localStorage.setItem(`${stateStoreKey}:searchText`, currentValue);
            }}
            style={{
              marginLeft: "10px",
              border: searchText
                ? `1px solid ${theme.palette.primary.main}`
                : "",
            }}
          />
        </div>
      </div>
      {data && (
        <>
          {<div>{conditions}</div>}
          <Table
            data={records || []}
            totalRecords={totalRecords || 0}
            tableInstance={table}
            keyValue="log_id"
            columnProps={{ minWidth: 150, width: 150 }}
            onHeaderClick={(col: TableColumn<CaseActivity>) =>
              col?.sorting?.enabled === false ? null : handleSort(col.dataField)
            }
            onScroll={handleScroll}
            onColumnReorder={handleColumnReorder}
            onActionButtonClick={handleActionButtonClick}
            showActionColumn={false}
            focusedRow={flyoutData}
            showSelection={false}
          >
            {columnState.map((col) => {
              return <Column key={col.dataField} {...col} />;
            })}
          </Table>
        </>
      )}
      {!data && <Loader message={"Retrieving Items..."} />}
    </>
  );
})`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export default CaseActivity;
