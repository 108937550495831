import { useState } from "react";
import { Modal } from "@monolith-forensics/monolith-ui";
import DefaultTraceIndicatorForm from "../Forms/DefaultTraceIndicatorForm.js";
import Loader from "../Loader.js";
import { AnalysisAPI } from "../../api/index.js";
import { usePermissions } from "../../hooks/usePermissions";
import { useMutation } from "@tanstack/react-query";
import LoggingAPI from "../../api/logging/index.js";

const CreateTraceIndicatorModal = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  defaultFormData = {},
  ...props
}) => {
  const { currentUser } = usePermissions();
  const [submitting, setSubmitting] = useState(false);

  const createTraceIndicator = useMutation({
    mutationFn: AnalysisAPI.TraceIndicators.create,
    onMutate: () => setSubmitting(true),
    onSuccess: (_, variables) => {
      LoggingAPI.createLog({
        case_id: defaultFormData.case_id,
        user_id: currentUser.user_id,
        message: `Created trace indicator.`,
        event: "trace_indicator:create",
        object_type: "trace_indicator",
        object_uuid: variables.uuid,
      });
      onSubmit?.(defaultFormData, variables);
      setSubmitting(false);
    },
  });

  return (
    <Modal open={show} onClose={onClose} style={{ width: 620 }}>
      <Modal.Title>Create Trace Indicator</Modal.Title>
      <Modal.SubTitle style={{ marginBottom: 15 }}>
        Create a new trace indicator
      </Modal.SubTitle>
      {!submitting && (
        <DefaultTraceIndicatorForm
          onSubmit={createTraceIndicator.mutate}
          onCancel={onCancel}
          defaultFormData={defaultFormData}
          {...props}
        />
      )}
      {submitting && <Loader />}
    </Modal>
  );
};

export default CreateTraceIndicatorModal;
