import { Helmet } from "react-helmet-async";
import { useParams, useSearchParams } from "react-router-dom";
import AcquisitionImport from "./components/AcquisitionImport";
import AcquisitionView from "../../Acquisitions/AcquisitionView";
import { useQuery } from "@tanstack/react-query";
import CasesApi from "../../../api/cases/index.js";
import { usePermissions } from "../../../hooks/usePermissions";
import { Case } from "../types/Cases";

const CaseAcquisition = () => {
  const { case_id } = useParams();
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const [searchParams] = useSearchParams();

  const view = searchParams.get("v") || "list";

  const { data: caseInfo } = useQuery({
    queryKey: [
      "cases",
      "list",
      {
        case_id: case_id ? parseInt(case_id) : null,
      },
    ],
    queryFn: () =>
      CasesApi.getCases({
        case_id: case_id ? parseInt(case_id) : null,
      }),
    select: (data) => (data?.[0] as Case) || {},
  });

  return (
    <>
      <Helmet title="Case Acquisitions" />
      {view === "list" && <AcquisitionView infoData={caseInfo} />}
      {hasPermission(MONOLITH_PERMISSIONS.ACQUISITIONS_BULK_IMPORT) &&
        view === "import" && <AcquisitionImport caseInfo={caseInfo} />}
    </>
  );
};

export default CaseAcquisition;
