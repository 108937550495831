import { httpDelete, httpGet, httpPatch, httpPost } from "../connection";

export async function getMonolithReports(params = {}) {
  return (await httpGet(`/reports`, params)).data;
}

async function query(params = {}) {
  return httpPost(`/reports/query`, params);
}

async function exportReportsList(params = {}) {
  return httpPost(`/reports/export`, params);
}

export async function createMonolithReport(values) {
  return httpPost(`/reports`, values);
}

export async function updateMonolithReport(reportId, values) {
  return httpPatch(`/reports/${reportId}`, values);
}

export async function deleteMonolithReport(values) {
  return httpDelete(`/reports`, values);
}

export async function getSavedReports(params = {}) {
  return httpGet(`/reports/saved-reports`, params);
}

export async function deleteSavedReport(params = {}) {
  return httpDelete(`/reports/saved-reports`, params);
}

export async function getCasesReport(params = {}) {
  return httpPost(`/reports/cases`, params);
}

export async function getCasesReportMetrics(params = {}) {
  return httpPost(`/reports/cases/metrics`, params);
}

export async function saveCasesReport(values = {}) {
  return httpPost(`/reports/cases/save-report`, values);
}

export async function getStorageReport(params = {}) {
  return httpPost(`/reports/storage`, params);
}

export async function getStorageReportMetrics(params = {}) {
  return httpPost(`/reports/storage/metrics`, params);
}

export async function saveStorageReport(values = {}) {
  return httpPost(`/reports/storage/save-report`, values);
}

export async function getEvidenceReport(params = {}) {
  return httpPost(`/reports/evidence`, params);
}

export async function getEvidenceReportMetrics(params = {}) {
  return httpPost(`/reports/evidence/metrics`, params);
}

export async function saveEvidenceReport(values = {}) {
  return httpPost(`/reports/evidence/save-report`, values);
}

export async function getAcquisitionReport(params = {}) {
  return httpPost(`/reports/acquisitions`, params);
}

export async function getAcquisitionReportMetrics(params = {}) {
  return httpPost(`/reports/acquisitions/metrics`, params);
}

export async function saveAcquisitionReport(values = {}) {
  return httpPost(`/reports/acquisitions/save-report`, values);
}

export async function saveFprReport(values = {}) {
  return httpPost(`/reports/fpr/save-report`, values);
}

export async function getFprReport(options = {}) {
  return await httpPost("/reports/fpr", options);
}

async function getQaEntries(params = {}) {
  return httpPost(`/reports/qa`, params);
}

async function getQaEntryMetrics(params = {}) {
  return httpPost(`/reports/qa/metrics`, params);
}

async function saveQaEntriesReport(values = {}) {
  return httpPost(`/reports/qa/save-report`, values);
}

export async function saveTimeEntryReport(values = {}) {
  return httpPost(`/reports/time-entry/save-report`, values);
}

export async function getTimeEntryReportMetrics(values = {}) {
  return httpPost(`/reports/time-entry/metrics`, values);
}

export async function getTimeEntryReport(values = {}) {
  return await httpPost("/reports/time-entry", values);
}

export const QaMetricsApi = {
  getQaEntries,
  getQaEntryMetrics,
  saveQaEntriesReport,
};

const MonolithReportsAPI = {
  getMonolithReports,
  query,
  exportReportsList,
  createMonolithReport,
  updateMonolithReport,
  deleteMonolithReport,
};

export default MonolithReportsAPI;
