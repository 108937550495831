import { httpDelete, httpGet, httpPatch, httpPost } from "../connection";

export async function getEquipmentItems(params = {}) {
  return (await httpGet(`/equipment/items`, params))?.data || [];
}

async function query(params) {
  return await httpPost(`/equipment/items/query`, params);
}

export async function createEquipmentItem(values) {
  return await httpPost(`/equipment/items`, values);
}

export async function updateEquipmentItem(item_id, values) {
  return await httpPatch(`/equipment/items/${item_id}`, values);
}

export async function deleteEquipmentItem(item_id) {
  return await httpDelete(`/equipment/items/${item_id}`);
}

async function exportEquipmentList(params) {
  return await httpPost(`/equipment/items/export`, params);
}

export async function getEquipmentVendors(params = {}) {
  return await httpGet(`/equipment/vendors`, params);
}

export async function getEquipmentModels(params = {}) {
  return await httpGet(`/equipment/models`, params);
}

export async function getEquipmentLocations(params = {}) {
  return await httpGet(`/equipment/locations`, params);
}

export async function getEquipmentNames(params = {}) {
  return await httpGet(`/equipment/names`, params);
}

export async function getEquipmentMeta(params = {}) {
  return await httpGet(`/equipment/meta`, params);
}

const ForensicEquipmentAPI = {
  getEquipmentItems,
  query,
  createEquipmentItem,
  updateEquipmentItem,
  deleteEquipmentItem,
  exportEquipmentList,
  getEquipmentVendors,
  getEquipmentModels,
  getEquipmentLocations,
  getEquipmentNames,
  getEquipmentMeta,
};

export default ForensicEquipmentAPI;
