import readFileAsBuffer from "./readFileAsBuffer.js";

const hashData = async (buffer, checksum) => {
  if (checksum === "MD5") {
    const { default: SparkMD5 } = await import("spark-md5");
    const spark = new SparkMD5.ArrayBuffer();
    spark.append(buffer);
    return spark.end();
  }

  const crypto = window.crypto || window.msCrypto;
  if (!crypto || !crypto.subtle) {
    throw new Error("Web Crypto API not available");
  }

  const hash = await crypto.subtle.digest(checksum, buffer);
  const hashArray = Array.from(new Uint8Array(hash));

  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
};

const calculateFileHash = async (file) => {
  const buffer = await readFileAsBuffer(file);
  const md5Hash = await hashData(buffer, "MD5");
  const sha1Hash = await hashData(buffer, "SHA-1");
  const sha256Hash = await hashData(buffer, "SHA-256");
  return { md5Hash, sha1Hash, sha256Hash };
};

export default calculateFileHash;
