import { useTheme } from "styled-components";
import ToolBarItems from "../../../components/ToolBarItems.js";
import { Modal } from "@mui/material";

export const ViewItemModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
}) => {
  const theme = useTheme();

  const handleSubmit = () => {
    onSubmit();
    handleClose();
  };

  const handleCancel = () => handleClose();

  return (
    <div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        style={{ zIndex: 1400 }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 400,
            backgroundColor: theme.palette.background.default,
            position: "absolute",
            left: "50%",
            top: "20%",
            transform: "translate(-35%, -20%)",
            padding: 20,
            outline: "none",
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            View Storage Item
          </div>
          <div style={{ margin: "10px 0px" }}>
            Would you like to view this new storage item?
          </div>
          <div
            style={{
              marginTop: 30,
            }}
          >
            <ToolBarItems
              submitText="View Storage Item"
              cancelText="No Thanks"
              onSubmit={handleSubmit}
              onCancel={handleCancel}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
