import styled from "styled-components";
import { Link, NavLink, matchPath } from "react-router-dom";
import * as Popover from "@radix-ui/react-popover";

import SidebarNavListItem from "./SidebarNavListItem";
import SidebarNavList from "./SidebarNavList";
import { useState } from "react";

const NavMenuTitle = styled.div`
  color: ${({ theme }) => (theme.name === "DARK" ? "#a0a0a0" : "#000000")};
  font-size: 12px;
  font-weight: bold;
  padding: 2px 15px;
`;

const NavMenuItem = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 4px 15px;
  &:hover {
    background: ${(props) => props.theme.palette.action.hover};
  }
`;

const IconListItem = styled(({ className, icon: Icon, items, page }) => {
  const [open, setOpen] = useState(false);

  const activeChild = items?.some((item) => {
    // Check if first part of current route matches the href
    return window.location.pathname.startsWith(item.href);
  });

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        {page.href ? (
          <div
            style={{ width: "100%" }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseDown={() => setOpen(false)}
          >
            <NavLink
              className={className + " IconListItem"}
              activeClassName="active"
              to={page.href}
            >
              <Icon />
            </NavLink>
          </div>
        ) : (
          <div
            className={
              className + " IconListItem" + (activeChild ? " active" : "")
            }
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={(e) => e.preventDefault()}
          >
            <Icon />
          </div>
        )}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          side="right"
          align="start"
          sideOffset={0}
          className={className + " PopoverContent"}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="inner-content">
            <NavMenuTitle>{page.title}</NavMenuTitle>
            {items?.map((item) => {
              return (
                <Link to={item.href}>
                  <NavMenuItem onClick={() => setOpen(false)}>
                    {item.title}
                  </NavMenuItem>
                </Link>
              );
            })}
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
})`
  &.IconListItem {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 8px 0;

    transition: border 0.2s, background 0.2s;

    border-left: 3px solid transparent;
    border-right: 3px solid transparent;

    svg {
      position: relative;
      color: #bbbbbb;
      margin: 0 auto;
      height: 18px;
      width: 18px;
    }
  }

  &.IconListItem:hover {
    background: ${(props) => props.theme.palette.action.hover};
  }

  &.IconListItem.active {
    background-color: ${(props) => props.theme.sidebar.active};
    color: ${(props) => props.theme.sidebar.color};
    border-left: 3px solid ${(props) => props.theme.palette.primary.main};

    :hover {
      background-color: ${(props) => props.theme.sidebar.active};
    }
  }

  // Check if item contains active child
  &.IconListItem:has(.active) {
    background-color: ${(props) => props.theme.sidebar.active};
    color: ${(props) => props.theme.sidebar.color};
    border-left: 3px solid ${(props) => props.theme.palette.primary.main};
  }

  &.PopoverContent {
    outline: none;
    .inner-content {
      outline: none;
      user-select: none;
      display: flex;
      flex-direction: column;
      padding: 5px 0;

      margin-left: 5px;

      font-size: 0.75rem;
      min-width: 150px;

      background: ${(props) => props.theme.palette.background.default};
      border: 1px solid ${(props) => props.theme.palette.input.border};
      border-radius: 6px;
    }
  }
`;

const reduceChildRoutes = (props) => {
  const { items, page, depth, currentRoute, collapsed } = props;

  if (collapsed) {
    items.push(
      <IconListItem
        icon={page.icon}
        key={page.title}
        items={page.children}
        page={page}
      />
    );
  } else if (page.children) {
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: false,
          },
          currentRoute
        )
      : true;

    items.push(
      <SidebarNavListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        open={true}
        title={page.title}
        href={page.href}
      >
        <SidebarNavList depth={depth + 1} pages={page.children} />
      </SidebarNavListItem>
    );
  } else {
    items.push(
      <SidebarNavListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        title={page.title}
      />
    );
  }

  return items;
};

export default reduceChildRoutes;
