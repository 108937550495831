import { EmptyItem, Form, SimpleItem } from "devextreme-react/ui/form";
import { Popup } from "devextreme-react/ui/popup";
import { useSnackbar } from "notistack";
import { useRef } from "react";
import { createClient } from "../../../api";
import ToolBarItems from "../../../components/ToolBarItems";

const CreateClientFlyout = ({ createClientFlyout, onSubmit = () => {} }) => {
  const form = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (event) => {
    if (form.current.instance.validate().isValid) {
      let data = { ...form.current.instance.option().formData };

      createClientFlyout.current.instance.hide();

      createClient(data).then((result) => {
        onSubmit({ ...data, client_id: result.client_id });
        enqueueSnackbar(`Client Created.`, {
          variant: "success",
        });
      });
    }
  };

  const handleCancel = () => {
    createClientFlyout.current.instance.hide();
  };

  return (
    <Popup
      ref={createClientFlyout}
      title="Create Client"
      showCloseButton={true}
      showTitle={true}
      dragEnabled={false}
      hideOnOutsideClick={false}
      shading={true}
      height={"100vh"}
      width={"50vw"}
      position={{ at: "right", my: "right", of: window }}
      animation={{
        show: {
          type: "slide",
          duration: 200,
          from: {
            position: {
              my: "left",
              at: "right",
              of: window,
            },
          },
          to: {
            position: {
              my: "right",
              at: "right",
              of: window,
            },
          },
        },
        hide: {
          type: "slide",
          duration: 200,
          from: {
            position: {
              my: "right",
              at: "right",
              of: window,
            },
          },
          to: {
            position: {
              my: "left",
              at: "right",
              of: window,
            },
          },
        },
      }}
      onShowing={() => {}}
      onShown={() => {
        form.current.instance.getEditor("name").focus();
      }}
      onHidden={() => {
        form.current.instance.resetValues();
      }}
    >
      <Form ref={form} colCount={2}>
        <SimpleItem
          dataField="name"
          label={{ text: "Name" }}
          isRequired={true}
        />
        <SimpleItem dataField="title" label={{ text: "Title" }} />
        <SimpleItem dataField="organization" label={{ text: "Organization" }} />
        <SimpleItem dataField="email" label={{ text: "Email" }} />
        <SimpleItem dataField="address" label={{ text: "Address" }} />
        <SimpleItem dataField="city" label={{ text: "City" }} />
        <SimpleItem dataField="state" label={{ text: "State/Province" }} />
        <SimpleItem dataField="zipcode" label={{ text: "Postal Code" }} />
        <SimpleItem
          dataField="office_number"
          label={{ text: "Office Number" }}
        />
        <SimpleItem
          dataField="mobile_number"
          label={{ text: "Mobile Number" }}
        />
        <EmptyItem />
        <SimpleItem
          dataField="notes"
          label={{ text: "Notes" }}
          colSpan={2}
          editorType="dxTextArea"
          editorOptions={{
            height: 135,
          }}
        />
      </Form>
      <ToolBarItems
        submitText="Create Client"
        style={{ marginTop: 20 }}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </Popup>
  );
};

export default CreateClientFlyout;
