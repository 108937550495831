import { ChevronRight } from "react-feather";
import { Link } from "react-router-dom";
import styled from "styled-components";

const AuditName = styled.h2`
  font-weight: 500;

  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 10px;
`;

const BackLink = styled.h2`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  gap: 5px;

  width: fit-content;

  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: 400;
  opacity: 0.8;

  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.palette.text.primary};
    text-decoration: underline;
  }
`;

const Separator = styled(ChevronRight)`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const AuditDetailsHeader = styled(({ className, auditData }) => {
  return (
    <div className={className}>
      <Link to="/audits">
        <BackLink>
          <span>Audits</span>
        </BackLink>
      </Link>
      <Separator size={15} />
      <AuditName>{auditData?.name}</AuditName>
    </div>
  );
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;

  margin-bottom: 10px;
`;

export default AuditDetailsHeader;
