import { z } from "zod";
import {
  Button,
  Grid,
  TextAreaInput,
  TextInput,
} from "@monolith-forensics/monolith-ui";
import ButtonMenu from "./components/ButtonMenu.js";
import { useForm, zodResolver } from "@mantine/form";
import diffFormData from "./utils/diffFormData.js";
import { v4 as uuidv4 } from "uuid";
import ContactSelector from "./components/Selectors/ContactSelector.js";

const TraceAccountSchema = z.object({
  name: z.string().min(1),
  platform: z.string().optional().nullable(),
  domain: z.string().optional().nullable(),
  identifier: z.string().optional().nullable(),
  role: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  case_id: z.number().min(1).optional().nullable(),
  case_uuid: z.string().optional().nullable(),
  created_by_id: z.number().min(1).optional().nullable(),
  linked_contact_id: z.number().min(1).nullable().optional(),
});

const DefaultTraceAccountForm = ({
  defaultFormData = {},
  buttonProps = {},
  onSubmit,
  onCancel,
  onChange,
}) => {
  const form = useForm({
    mode: "uncontrolled",
    initialValues: { uuid: uuidv4(), ...defaultFormData },
    validate: zodResolver(TraceAccountSchema),
    onValuesChange: (values) => {
      onChange?.(values);
    },
  });

  const handleSubmit = async () => {
    const validateResult = form.validate();

    if (validateResult.hasErrors) {
      return;
    }

    const formData = form.getValues();

    const diffData = diffFormData(defaultFormData, formData);

    // call onSubmit with modeled data and diff data
    // diff data is used when the form is in edit context
    // diff may be used when we only want to update modified values
    onSubmit?.(formData, diffData);
  };

  const handleCancel = () => {
    form.reset();
    onCancel?.();
  };

  return (
    <>
      <Grid col={2} style={{ marginBottom: 10 }}>
        <TextInput
          size="sm"
          variant="outlined"
          label="Account Name"
          description="Provide the name of this account.  It may be a user name, email address, or any other primary name for the account."
          placeholder="John Smith, jdoe@gmail.com, etc."
          required
          key={form.key("name")}
          {...form.getInputProps("name")}
        />
        <TextInput
          size="sm"
          variant="outlined"
          label="Platform"
          description="This is the platform or service that the account is associated with."
          placeholder="Microsoft, Google, Facebook, etc."
          key={form.key("platform")}
          {...form.getInputProps("platform")}
        />
        <TextInput
          size="sm"
          variant="outlined"
          label="Domain"
          description="This is the domain or website that the account is associated with."
          placeholder="AD Domain, Local, etc."
          key={form.key("domain")}
          {...form.getInputProps("domain")}
        />
        <TextInput
          size="sm"
          variant="outlined"
          label="Identifier"
          description="This is the unique identifier for the account."
          placeholder="SID, Email, etc."
          key={form.key("identifier")}
          {...form.getInputProps("identifier")}
        />
        <TextInput
          size="sm"
          variant="outlined"
          label="Role"
          description="This is the role or access level for the account."
          placeholder="Super Admin, Owner, User, etc."
          key={form.key("role")}
          {...form.getInputProps("role")}
        />
        <ContactSelector
          size="sm"
          clearable
          searchable
          query={{
            case_id: defaultFormData.case_id,
            case_uuid: defaultFormData.case_uuid,
          }}
          key={form.key("linked_contact_id")}
          {...form.getInputProps("linked_contact_id")}
        />
        <TextAreaInput
          size="sm"
          variant="outlined"
          label="Description"
          placeholder="Enter account description"
          description={
            "Provide a brief description or notes for this account to provide any relevant context."
          }
          colSpan={2}
          minRows={8}
          maxRows={24}
          key={form.key("description")}
          {...form.getInputProps("description")}
        />
      </Grid>
      <ButtonMenu>
        <Button size="xs" variant="subtle" onClick={handleCancel}>
          {buttonProps.cancelText || "Cancel"}
        </Button>
        <Button
          size="xs"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          {buttonProps.submitText || "Submit"}
        </Button>
      </ButtonMenu>
    </>
  );
};

export default DefaultTraceAccountForm;
