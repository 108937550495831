import { Modal, useTheme } from "@mui/material";
import ToolBarItems from "./ToolBarItems.js";

const DeleteNoteModal = ({
  onDelete = () => {},
  open = false,
  onClose = () => {},
  details = {},
}) => {
  const theme = useTheme();
  const isFolder = details?.is_folder === 1;

  const handleDelete = () => {
    onDelete(details);
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleCancel();
      }}
      style={{ zIndex: 1400, outline: "none" }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 500,
          maxHeight: "90vh",
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          top: "33%",
          left: "50%",
          transform: "translate(-50%, -33%)",
          overflowY: "auto",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large", fontWeight: 600 }}>
          Delete Case {isFolder ? "Note Folder" : "Note"}
        </div>
        {details && (
          <>
            <div style={{ margin: "10px 0px" }}>
              Are you sure you want to delete this case{" "}
              {isFolder ? "note folder" : "note"}?
            </div>
            <div style={{ marginTop: 15 }}>
              <div
                style={{
                  color: theme.palette.text.secondary,
                  fontWeight: 500,
                  fontStyle: "italic",
                }}
              >
                "{details?.note_tag}"
              </div>
            </div>
            <ToolBarItems
              style={{ marginTop: 20 }}
              submitText={`Delete Case ${isFolder ? "Note Folder" : "Note"}`}
              submitColor="error"
              onSubmit={handleDelete}
              onCancel={handleCancel}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default DeleteNoteModal;
