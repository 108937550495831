import styled from "styled-components";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

import getRandomColor from "../../../utils/getRandomColor.js";
import getInitials from "../../../utils/getInitials.js";
import { useNoteSystem } from "../Contexts/NoteSystemContext.js";
import NoteTooltipContent from "./NoteTooltipContent.js";

const Tab = styled.div`
  position: relative;
  user-select: none;
  padding: 3px 5px;
  min-width: 8rem;
  max-width: 12rem;
  cursor: default;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.palette.text.secondary};
  border-top: 1px solid ${(props) => props.theme.palette.divider};
  padding-top: 1px;
  border-right: 1px solid ${(props) => props.theme.palette.divider};
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  background-color: ${(props) => props.theme.palette.background.default};
  cursor: pointer;

  :hover {
    background-color: ${(props) => props.theme.palette.action.hover};
  }

  svg {
    font-size: 0.85rem;
    cursor: pointer;
    margin-left: 0.5rem;
    color: ${(props) => props.theme.palette.text.secondary};
    :hover {
      background-color: ${(props) => props.theme.palette.action.hover};
    }
  }

  &.active {
    background-color: ${(props) => props.theme.palette.background.default};
    color: ${(props) => props.theme.palette.text.primary};
    border-top: 2px solid
      ${(props) =>
        props.readOnly ? "orange" : props.theme.palette.primary.main};
    padding-top: 0px;
    border-bottom: 1px solid transparent;

    svg {
      color: ${(props) => props.theme.palette.text.primary};
    }
  }

  svg.saved {
    color: limegreen;
  }

  &[data-temp="true"] {
    // italics
    font-style: italic;
  }
`;

const TabInner = styled.div`
  padding-left: 0.5rem;
  font-size: 0.75rem;
  text-align: left;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const TabAvatar = styled.div`
  width: ${({ size }) => (size ? size : "16px")};
  height: ${({ size }) => (size ? size : "16px")};
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.palette.divider};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0.5rem;
  font-weight: 600;
`;

const NoteTab = ({
  tabData,
  readOnly,
  handleTabClick,
  handleTabDblClick,
  handleCloseTab,
}) => {
  const noteData = tabData?.note;

  const { currentTab } = useNoteSystem();

  return (
    <NoteTooltipContent
      item={noteData}
      contentProps={{ side: "bottom", align: "start" }}
    >
      <Tab
        readOnly={readOnly}
        key={tabData.value}
        className={currentTab?.value === tabData?.value ? "active" : ""}
        data-temp={tabData?.state === "temp"}
        onClick={(e) => handleTabClick(tabData)}
        onDoubleClick={(e) => handleTabDblClick(tabData)}
      >
        <TabInner>
          <TabAvatar
            style={{
              backgroundColor: getRandomColor(tabData?.note?.created_by?.email),
            }}
          >
            {getInitials(tabData?.note?.created_by?.full_name)}
          </TabAvatar>
          {tabData.label}
        </TabInner>
        {tabData.saved ? (
          <CheckCircleOutlineOutlinedIcon className="saved" />
        ) : (
          <CloseIcon onClick={(e) => handleCloseTab(tabData, e)} />
        )}
      </Tab>
    </NoteTooltipContent>
  );
};

export default NoteTab;
