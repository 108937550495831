import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import Input from "../../Input.js";

import { MoreHorizontal, Trash2 } from "lucide-react";
import DropdownMenu from "../../DropdownMenu/DropdownMenu.js";
import { monolithMoment } from "../../../utils/date-format";
import { usePermissions } from "../../../hooks/usePermissions";
import NoteTooltipContent from "./NoteTooltipContent.js";

const ItemMenu = styled(
  ({
    className,
    item,
    readOnly = false,
    itemMenuProps = {},
    handleRename,
    handleDelete,
  }) => {
    const { items = [] } = itemMenuProps;

    const handleSelect = (menuItem) => {
      if (menuItem.onClick) menuItem.onClick(item);
    };

    const menuItems = [
      ...items.map((item) => ({
        label: item.text,
        onClick: () => handleSelect(item),
      })),
    ];

    if (!readOnly)
      menuItems.push(
        {
          label: "Rename",
          onClick: () => handleRename(item),
        },
        {
          separator: true,
        },
        {
          label: "Delete",
          onClick: () => handleDelete(item),
          render: () => (
            <div style={{ display: "flex", color: "orangered" }}>
              <Trash2 size={12} style={{ marginRight: 5 }} />
              Delete
            </div>
          ),
        }
      );

    return (
      <div
        className={className + " mf-treelist-item-menu"}
        onClick={(e) => e.stopPropagation()}
      >
        <DropdownMenu
          menuItems={menuItems}
          title={"Item Actions"}
          variant="outlined"
          style={{
            minWidth: 125,
          }}
        >
          <MoreHorizontal size={12} />
        </DropdownMenu>
      </div>
    );
  }
)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: all 0.1s ease-in-out;
  border-radius: 4px;
  padding: 0 5px;
  margin-left: auto;

  color: ${(props) => props.theme.palette.text.secondary};
`;

export const NoteItem = styled(
  ({
    className,
    note,
    active,
    selected,
    onClick = () => {},
    onDoubleClick = () => {},
    itemMenuProps = {},
    onRename = () => {},
    onDelete = () => {},
    ...props
  }) => {
    const { currentUser, hasPermission } = usePermissions();
    const itemRef = useRef();
    const inputRef = useRef();
    const [newLabel, setNewLabel] = useState(note?.note_tag || "Note Item");
    const [isRenaming, setIsRenaming] = useState(false);

    const readOnly =
      !hasPermission() || note?.created_by?.user_id !== currentUser.user_id;

    const handleRename = () => {
      setIsRenaming(true);
    };

    const handleBlur = (value) => {
      setIsRenaming(false);
      setNewLabel(value);
      onRename({ note, newName: value });
    };

    const handleTitleKeyPress = (e) => {
      // Handle Enter key
      if (e.keyCode === 13) {
        e.preventDefault();
        e.target.blur();
      }

      // handle tab key
      if (e.keyCode === 9) {
        e.preventDefault();
        e.target.blur();
      }
    };

    const handleDelete = () => {
      onDelete({ note });
    };

    useEffect(() => {
      setNewLabel(note?.note_tag || "Note Item");
    }, [note.note_tag]);

    useEffect(() => {
      if (isRenaming) {
        setTimeout(() => {
          inputRef.current.focus();
          inputRef.current.select();
        }, 100);
      }
    }, [isRenaming]);

    return (
      <NoteTooltipContent item={note}>
        <div
          className={className + " mf-note-item"}
          ref={itemRef}
          data-active={active}
          data-selected={selected}
          onClick={(e) => {
            e.stopPropagation();
            onClick(note);
            if (e.detail === 2) onDoubleClick(note);
          }}
          title={props?.tooltip?.(note)}
        >
          <div
            className="inner-label"
            style={{ pointerEvents: isRenaming ? "auto" : "none" }}
          >
            <div style={{ display: "flex" }}>{props?.leafIcon}</div>
            <div style={{ width: "100%" }}>
              {!isRenaming && <div className="item-label">{newLabel}</div>}
              {isRenaming && (
                <Input.StyledInput
                  ref={inputRef}
                  defaultValue={newLabel}
                  onClick={(e) => e.stopPropagation()}
                  onBlur={(e) => handleBlur(e.target.value)}
                  onKeyDown={handleTitleKeyPress}
                  style={{ padding: 4 }}
                  autoFocus
                  placeholder="Enter a name"
                />
              )}
              <div className="footer">
                <div>{note.created_by.full_name}</div>
                <div>
                  {note?.created_on
                    ? monolithMoment({
                        timestamp: note.created_on,
                      })
                    : ""}
                </div>
              </div>
            </div>
          </div>
          <ItemMenu
            item={note}
            itemMenuProps={itemMenuProps}
            readOnly={readOnly}
            handleDelete={handleDelete}
            handleRename={handleRename}
          />
        </div>
      </NoteTooltipContent>
    );
  }
)`
  position: relative;
  user-select: none;
  width: 100%;
  min-width: 100px;
  min-height: 30px;
  color: ${(props) => props.theme.palette.text.secondary};
  font-weight: 500;
  font-size: 0.75rem;
  padding: 5px;
  padding-left: ${(props) =>
    props.depth ? props.depth * 15 + (props.item.useEmptyIcon ? 15 : 0) : 0}px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  border-radius: 3px;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.1s ease-in-out;
  border: 1px solid transparent;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.6rem;
    color: ${(props) => props.theme.palette.text.secondary};
    opacity: 0.5;
    margin-top: 5px;
    width: 100%;
  }

  .inner-label {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    width: calc(100% - 30px);
    max-width: calc(100% - 30px);
  }

  .item-label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }

  &.node-container.dragover {
    background-color: ${(props) => props.theme.palette.action.hover};
    color: ${(props) => props.theme.palette.primary.main};
    font-weight: 600;
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.action.hover};

    .mf-treelist-item-menu {
      opacity: 1;
    }
  }

  &[data-active="true"] {
    color: ${(props) => props.theme.palette.primary.main};
    font-weight: 600;

    .footer {
      opacity: 1;
    }
  }

  &:has(button[data-state="open"]) {
    background-color: ${(props) => props.theme.palette.action.hover};
    .mf-treelist-item-menu {
      opacity: 1;
    }
  }

  &[data-selected="true"] {
    background-color: ${(props) => props.theme.palette.action.hover};
    color: ${(props) => props.theme.palette.text.primary};
    border: 1px solid ${(props) => props.theme.palette.divider};
    font-weight: 600;

    .footer {
      opacity: 1;
    }
  }
`;
