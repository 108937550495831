import styled from "styled-components";

const ClientListItem = styled(({ className, client }) => {
  return (
    <div className={className}>
      <div className="label">{client.name}</div>
      <div className="detail">{client.organization}</div>
      <div className="detail">{client.email}</div>
    </div>
  );
})`
  width: 100%;
  line-height: 1.25;

  .label {
    font-weight: 450;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .detail {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.palette.text.secondary};
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default ClientListItem;
