import styled from "styled-components";
import MainGridLayout from "./MainGridLayout.js";
import { useState } from "react";

import UpcomingTasks from "../components/widgets/UpcomingTasks.js";
import MyRecentActivity from "../components/widgets/MyRecentActivity.js";
import MyRecentCases from "../components/widgets/MyRecentCases.js";
import MyRecentEvidence from "../components/widgets/MyRecentEvidence.js";
import MyQaReviews from "../components/widgets/MyQaReviews.js";
import NewInquiries from "../components/widgets/NewInquiries.js";
import DropdownMenu from "../../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import { LayoutDashboardIcon } from "lucide-react";

const persistKey = "overview:layout:user";

const items = [
  {
    title: "Tasks Due Soon",
    label: "Tasks Due Soon",
    value: "upcoming_tasks",
    layout: {
      w: 4,
      h: 7,
      x: 4,
      y: 21,
      visible: true,
      i: "upcoming_tasks",
      isResizable: true,
    },
    widget: <UpcomingTasks />,
    description: "Lists your tasks due in the next 30 days.",
  },
  {
    title: "My Recent Activity",
    label: "My Recent Activity",
    value: "my_recent_activity",
    layout: { w: 4, h: 7, x: 0, y: 21, visible: true, i: "my_recent_activity" },
    widget: <MyRecentActivity />,
    description: "Lists your recent activity in Monolith.",
  },
  {
    title: "My Recent Cases",
    label: "My Recent Cases",
    value: "my_recent_cases",
    layout: { w: 4, h: 7, x: 0, y: 14, visible: true, i: "my_recent_cases" },
    widget: <MyRecentCases />,
    description: "Lists the 15 most recent cases you have accessed.",
  },
  {
    title: "My Recent Evidence",
    label: "My Recent Evidence",
    value: "my_recent_evidence",
    layout: { w: 4, h: 7, x: 8, y: 14, visible: true, i: "my_recent_evidence" },
    widget: <MyRecentEvidence />,
    description: "Lists the 15 most recent evidence items you have accessed.",
  },
  {
    title: "My QA Reviews",
    label: "My QA Reviews",
    value: "my_qa_reviews",
    layout: { w: 4, h: 7, x: 4, y: 14, visible: true, i: "my_qa_reviews" },
    widget: <MyQaReviews />,
    description: "Lists incomplete QA reviews you are assigned to.",
  },
  {
    title: "New Inquiries",
    label: "New Inquiries",
    value: "new_inquiries",
    layout: { w: 4, h: 7, x: 8, y: 21, visible: true, i: "new_inquiries" },
    widget: <NewInquiries />,
    description: "Lists new inquiries.",
  },
];

const UserView = styled(({ className }) => {
  const [widgetState, setWidgetState] = useState(() => {
    const savedLayout = JSON.parse(window.localStorage.getItem(persistKey));

    return items.map((layoutItem) => {
      const layout = layoutItem.layout;
      const saved =
        savedLayout?.find((l) => l?.layout?.i === layout.i)?.layout || {};

      return {
        ...layoutItem,
        layout: {
          ...layout,
          ...saved,
        },
      };
    });
  });

  const onLayoutChange = (layout) => {
    setWidgetState((prev) => {
      const newState = prev.map((l) => {
        const newLayout = layout.find((nl) => nl.i === l.layout.i);
        return {
          ...l,
          layout: {
            ...l.layout,
            ...newLayout,
          },
        };
      });

      window.localStorage.setItem(persistKey, JSON.stringify(newState));

      return newState;
    });
  };

  const handleSelect = (data) => {
    const selected = data.map((d) => d.value);

    setWidgetState((prev) => {
      return prev.map((l) => {
        return {
          ...l,
          layout: {
            ...l.layout,
            visible: selected.includes(l.layout.i),
          },
        };
      });
    });
  };

  return (
    <div className={className}>
      <div className="menu-bar">
        <DropdownMenu
          menuItems={[
            {
              checkboxGroup: true,
              value: widgetState
                .filter((l) => l?.layout?.visible !== false)
                .map((l) => l.layout.i),
              onSelectionChanged: handleSelect,
              items: items,
            },
          ]}
          arrow
        >
          <LayoutDashboardIcon size={14} style={{ marginRight: 5 }} />
          Customize
        </DropdownMenu>
      </div>
      <MainGridLayout
        layout={widgetState.map((l) => l.layout)}
        widgets={widgetState.filter((l) => l?.layout?.visible !== false)}
        onLayoutChange={onLayoutChange}
      />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;

  .menu-bar {
    display: flex;
    flex-direction: row;
    margin: 10px 13px;
    margin-bottom: 0;
  }

  .react-grid-item.react-grid-placeholder {
    background: none !important;
    border: 3px solid rgb(71, 130, 218);
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
`;

export default UserView;
