import styled from "styled-components";
import * as Popover from "@radix-ui/react-popover";

import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import getRandomColor from "../../utils/getRandomColor.js";
import { LogOutIcon, User2Icon } from "lucide-react";

const Footer = styled.div`
  user-select: none;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: ${({ collapsed }) =>
    collapsed === true ? "center" : "flex-start"};
  cursor: pointer;

  width: 100%;
  padding: 10px 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);

  transition: background 0.1s;

  &:hover {
    background: ${({ theme }) => theme.palette.action.hover};
  }
`;

const FooterText = styled.div`
  user-select: none;
  color: #f5f5f5;
  font-weight: 500;

  word-break: break-all;
`;

const FooterSubText = styled.div`
  user-select: none;
  color: #aaaaaa;
  font-size: 0.75rem;

  word-break: break-all;
`;

const Avatar = styled.div`
  user-select: none;
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0000b5;
  color: #f5f5f5;
  font-weight: 600;
  font-size: 0.8rem;
  text-transform: uppercase;
`;

const PopoverContent = styled(Popover.Content)`
  outline: none;
  .inner-content {
    outline: none;
    user-select: none;
    display: flex;
    flex-direction: column;
    padding: 5px 0;

    margin-bottom: 5px;

    font-size: 0.75rem;
    min-width: 150px;

    background: ${(props) => props.theme.palette.background.default};
    border: 1px solid ${(props) => props.theme.palette.input.border};
    border-radius: 6px;
  }
`;

const ListItem = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.palette.text.primary};
  border: 0px solid transparent;
  padding: 4px 15px;
  &:hover {
    background: ${(props) => props.theme.palette.action.hover};
  }
`;

const SidebarFooter = ({ collapsed }) => {
  const { currentUser, logout } = useAuth();

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <Footer collapsed={collapsed}>
          {!!currentUser && (
            <>
              <Avatar
                style={{
                  backgroundColor: getRandomColor(currentUser.email),
                }}
              >
                {currentUser.first_name[0]}
                {currentUser.last_name[0]}
              </Avatar>
              {!collapsed && (
                <>
                  <div>
                    <FooterText>{currentUser.full_name}</FooterText>
                    <FooterSubText>
                      <div>{currentUser.email}</div>
                    </FooterSubText>
                  </div>
                </>
              )}
            </>
          )}
        </Footer>
      </Popover.Trigger>
      <Popover.Portal>
        <PopoverContent side="top" align="center" collisionPadding={15}>
          <div className="inner-content">
            <ListItem onMouseDown={logout}>
              <LogOutIcon size={14} /> Logout
            </ListItem>
            <Link to={`/team/users/${currentUser.email}/user-details`}>
              <ListItem>
                <User2Icon size={14} /> Profile
              </ListItem>
            </Link>
          </div>
        </PopoverContent>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default SidebarFooter;
