import { useTheme } from "@mui/material";
import { getCaseAssignments } from "../../../api";
import styled from "styled-components";
import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import UserApi from "../../../api/users/users.js";
import Loader from "../../../components/Loader.js";
import ComboButton from "../../../Monolith-UI/ComboButton/ComboButton.js";
import CasesApi from "../../../api/cases/index.js";
import { usePermissions } from "../../../hooks/usePermissions";
import LoggingAPI from "../../../api/logging/index.js";
import UserGroupsAPI from "../../../api/users/groups.js";

import CloseIcon from "@mui/icons-material/Close";

const CaseMembers = styled(({ className, caseInfo }) => {
  const { hasPermission, currentUser, MONOLITH_PERMISSIONS } = usePermissions();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const refetchRecentActivity = () => {
    queryClient.refetchQueries({
      queryKey: ["activity:list", { case_id: caseInfo.case_id, pageSize: 20 }],
    });
  };

  const handleUpdateCaseLead = (user) => {
    CasesApi.updateCase(caseInfo.case_id, {
      case_lead_id: user?.user_id,
    }).then((res) => {
      queryClient.refetchQueries({
        queryKey: [
          "cases:assignments",
          { case_id: caseInfo.case_id, include_case_lead: true },
        ],
      });
    });

    queryClient.setQueryData(
      ["cases", "list", { case_id: caseInfo.case_id }],
      (oldData) => {
        const oldCaseData = oldData ? oldData[0] : {};
        return [
          {
            ...oldCaseData,
            case_lead_id: user.user_id,
            case_lead: user,
          },
        ];
      }
    );

    LoggingAPI.logActivity(
      caseInfo.case_id,
      currentUser.user_id,
      `Case lead changed to ${user.full_name}`
    ).then((res) => refetchRecentActivity());
  };

  const handleAssignUser = (user) => {
    CasesApi.assignUsersToCase({
      userIDs: [user.user_id],
      case_id: caseInfo.case_id,
    });

    queryClient.setQueryData(
      [
        "cases:assignments",
        { case_id: caseInfo.case_id, include_case_lead: true },
      ],
      (oldData) => {
        return [
          ...oldData,
          {
            ...user,
            case_id: caseInfo.case_id,
            case_lead: 0,
            case_assign_id: null,
            date_assigned: new Date().toISOString(),
          },
        ];
      }
    );

    LoggingAPI.logActivity(
      caseInfo.case_id,
      currentUser.user_id,
      `Assigned ${user.full_name} to case`
    ).then((res) => refetchRecentActivity());
  };

  const handleRemoveUser = (user) => {
    CasesApi.removeUsersFromCase({
      userIDs: [user.user_id],
      case_id: caseInfo.case_id,
    });

    queryClient.setQueryData(
      [
        "cases:assignments",
        { case_id: caseInfo.case_id, include_case_lead: true },
      ],
      (oldData) => {
        return oldData.filter(
          (assignment) => assignment.user_id !== user.user_id
        );
      }
    );

    LoggingAPI.logActivity(
      caseInfo.case_id,
      currentUser.user_id,
      `Removed ${user.full_name} from case`
    ).then((res) => refetchRecentActivity());
  };

  const handleRemoveCaseLead = async () => {
    try {
      // Remove the case lead
      await CasesApi.updateCase(caseInfo.case_id, { case_lead_id: null });

      // Refetch the case assignments
      queryClient.refetchQueries({
        queryKey: [
          "cases:assignments",
          { case_id: caseInfo.case_id, include_case_lead: true },
        ],
      });
      refetchRecentActivity();
    } catch (error) {
      console.error("Error removing case lead:", error);
    }

    await LoggingAPI.logActivity(
      caseInfo.case_id,
      currentUser.user_id,
      `Removed ${caseLead.full_name} as case lead and from case assignments`
    );
  };

  const results = useQueries({
    queries: [
      {
        queryKey: [
          "cases:assignments",
          { case_id: caseInfo.case_id, include_case_lead: true },
        ],
        queryFn: () =>
          getCaseAssignments({
            case_id: caseInfo.case_id,
            include_case_lead: true,
          }),
      },
      {
        queryKey: ["users", { includeObservers: true, includeInactive: false }],
        queryFn: () =>
          UserApi.getUsers({ includeObservers: true, includeInactive: false }),
      },
    ],
  });

  const isDone = results.every((result) => result.isFetched);

  const assignedUsers = results[0]?.data || [];
  const users = results[1]?.data || [];

  const caseLead = assignedUsers.find((user) => user.case_lead === 1) || {};

  return (
    <div className={className}>
      <div className="assign-users-header">
        <div className="widget-title">Case Lead</div>
        {hasPermission() &&
          (hasPermission(MONOLITH_PERMISSIONS.CASES_READ_ALL) ||
            caseLead.user_id === currentUser.user_id) && (
            <div>
              <ComboButton
                type="dropDown"
                data={users.filter((user) => user.observer !== 1)}
                selectedItems={[caseLead]}
                displayField="full_name"
                idField={"user_id"}
                variant="outlined"
                closeOnSelect={true}
                dropDownTitle={() => {
                  return (
                    <div
                      style={{
                        margin: "5px 0px",
                        padding: 3,
                        color: theme.palette.text.secondary,
                        display: "flex",
                        alignItems: "center",
                        minWidth: 200,
                      }}
                    >
                      Assign Case Lead
                    </div>
                  );
                }}
                onItemSelect={handleUpdateCaseLead}
                textColor={theme.palette.text.primary}
                showDropdownIcon={true}
                title={""}
              >
                Select Case Lead
              </ComboButton>
            </div>
          )}
      </div>
      <div className="assigned-users-container">
        <UserItem
          user={caseLead}
          showClose={true}
          onClose={handleRemoveCaseLead}
        />
      </div>
      <div className="assign-users-header" style={{ marginTop: 10 }}>
        <div className="widget-title">
          User Assignments{" "}
          {`(${
            assignedUsers
              .filter((user) => user.user_id !== caseLead?.user_id)
              .filter(
                (user, index, self) =>
                  index === self.findIndex((u) => u.user_id === user.user_id)
              ).length
          })`}
        </div>
        {hasPermission() && (
          <div>
            <ComboButton
              type="multi-select"
              data={users.filter((user) => user.user_id !== caseLead?.user_id)}
              displayField="full_name"
              idField={"user_id"}
              selectedItems={users.filter((user) =>
                assignedUsers.find(
                  (assignedUser) => assignedUser.user_id === user.user_id
                )
              )}
              variant="outlined"
              closeOnSelect={false}
              dropDownTitle={() => {
                return (
                  <div
                    style={{
                      margin: "5px 0px",
                      padding: 3,
                      color: theme.palette.text.secondary,
                      display: "flex",
                      alignItems: "center",
                      minWidth: 200,
                    }}
                  >
                    Assign Users
                  </div>
                );
              }}
              onItemDeSelect={handleRemoveUser}
              onItemSelect={handleAssignUser}
              textColor={theme.palette.text.primary}
              showDropdownIcon={true}
              title={""}
            >
              Assign Users
            </ComboButton>
          </div>
        )}
      </div>

      {!isDone ? (
        <Loader />
      ) : (
        <div className="assigned-users-container">
          {assignedUsers
            .filter((user) => user.user_id !== caseLead?.user_id)
            // remove duplicates
            .filter(
              (user, index, self) =>
                index === self.findIndex((u) => u.user_id === user.user_id)
            )
            .map((user) => {
              return (
                <UserItem
                  key={user.user_id}
                  user={user}
                  showClose={true}
                  onClose={() => handleRemoveUser(user)}
                />
              );
            })}
        </div>
      )}
      <UserGroups
        caseInfo={caseInfo}
        refetchRecentActivity={refetchRecentActivity}
      />
    </div>
  );
})`
  padding: 10px;
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: 5px;
  flex-grow: 1;
  min-width: 375px;

  .assign-users-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: baseline;
    padding: 0 10px;
  }

  .assigned-users-container {
    max-height: 500px;
    overflow-y: auto;
    padding: 0 10px;
  }

  .widget-title {
    font-size: 12px;
    font-weight: 500;
    color: ${(props) => props.theme.palette.text.secondary};
    margin-bottom: 15px;
  }
`;

const UserItem = styled(
  ({ className, user = {}, showClose, onClose = () => {} }) => {
    const { hasPermission } = usePermissions();
    const CloseIconContainer = styled.div`
      display: flex;
      align-items: center;
      margin-left: 0.5rem;
      cursor: pointer;
      color: ${({ theme }) => theme.palette.text.secondary};

      .icon {
        font-size: 1rem;
        &:hover {
          background-color: ${({ theme }) => theme.palette.action.hover};
        }
      }
    `;

    if (Object.keys(user).length === 0) {
      return (
        <div className={`${className} no-lead`}>
          <div className="user-name">No Case Lead assigned</div>
        </div>
      );
    }

    return (
      <div className={className}>
        <div>
          <div className="user-name">{user?.full_name || "No Name"}</div>
          <div className="user-email">{user?.email || "No Email"}</div>
        </div>
        <div style={{ display: "flex" }}>
          <div>
            <div className="user-title">{user?.title || "No Title"}</div>
            {user.case_lead === 1 ? (
              <div className="case-lead">Case Lead</div>
            ) : (
              <div className="case-lead">{user?.user_role?.name || ""}</div>
            )}
          </div>
          {showClose && Object.keys(user).length > 0 && hasPermission() && (
            <CloseIconContainer onClick={onClose}>
              <CloseIcon className={"icon"} />
            </CloseIconContainer>
          )}
        </div>
      </div>
    );
  }
)`
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  border: 1px solid transparent;

  background-color: ${({ theme }) => theme.palette.background.secondary};

  padding: 10px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;

  .user-name {
    font-size: 12px;
    font-weight: 500;
    color: ${(props) => props.theme.palette.text.primary};
    text-transform: capitalize;
  }

  .user-email {
    font-size: 10px;
    font-weight: 400;
    color: ${(props) => props.theme.palette.text.secondary};
  }

  .user-title {
    font-size: 10px;
    font-weight: 400;
    color: ${(props) => props.theme.palette.text.secondary};
    text-align: right;
    // set to capitalize
    text-transform: capitalize;
  }

  .case-lead {
    font-size: 10px;
    font-weight: 500;
    color: ${(props) => props.theme.palette.primary.main};
    text-align: right;
    // set to capitalize
    text-transform: capitalize;
  }

  &.no-lead {
    justify-content: center;
    align-items: center;
    height: 40px;
  }
`;

const UserGroups = styled(({ className, caseInfo, refetchRecentActivity }) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { hasPermission, currentUser } = usePermissions();

  const handleAssignGroup = (group) => {
    queryClient.setQueryData(
      ["userGroups", "list", { case_id: caseInfo.case_id }],
      (prev) => {
        const oldData = prev?.data || [];
        return {
          ...prev,
          total: oldData.length + 1,
          data: [...oldData, group],
        };
      }
    );

    CasesApi.assignUserGroup({
      group_uuid: group.uuid,
      case_id: caseInfo.case_id,
    }).then((res) => {
      queryClient.refetchQueries({
        queryKey: [
          "cases:assignments",
          { case_id: caseInfo.case_id, include_case_lead: true },
        ],
      });

      LoggingAPI.logActivity(
        caseInfo.case_id,
        currentUser.user_id,
        `User Group Assigned to Case: ${group.name} (${group.uuid})`
      ).then((res) => refetchRecentActivity());
    });
  };

  const handleRemoveGroup = (group) => {
    if (!hasPermission()) return;
    queryClient.setQueryData(
      ["userGroups", "list", { case_id: caseInfo.case_id }],
      (prev) => {
        const oldData = prev?.data || [];
        return {
          ...prev,
          total: oldData.length - 1,
          data: oldData.filter((g) => g.uuid !== group.uuid),
        };
      }
    );

    CasesApi.unassignUserGroup({
      group_uuid: group.uuid,
      case_id: caseInfo.case_id,
    }).then((res) => {
      queryClient.refetchQueries({
        queryKey: [
          "cases:assignments",
          { case_id: caseInfo.case_id, include_case_lead: true },
        ],
      });

      LoggingAPI.logActivity(
        caseInfo.case_id,
        currentUser.user_id,
        `User Group Removed from Case: ${group.name} (${group.uuid})`
      ).then((res) => refetchRecentActivity());
    });
  };

  const { data } = useQuery({
    queryKey: ["user-groups:list", {}],
    queryFn: () => UserGroupsAPI.get({}),
    placeholderData: (data) => data,
  });

  const { data: assignedGroups } = useQuery({
    queryKey: ["userGroups", "list", { case_id: caseInfo.case_id }],
    queryFn: () => UserGroupsAPI.get({ case_id: caseInfo.case_id }),
    placeholderData: (data) => data,
  });

  if (!data) {
    return <Loader message={"Retrieving User Groups..."} />;
  }

  const groups = (data?.data || []).sort(
    (a, b) => a?.name?.localeCompare(b.name) || 0
  );

  const currentGroups = (assignedGroups?.data || []).sort(
    (a, b) => a?.name?.localeCompare(b.name) || 0
  );

  const total = assignedGroups?.total || 0;

  return (
    <div className={className}>
      <div className="assign-users-header" style={{ marginTop: 10 }}>
        <div className="widget-title">Group Assignments {`(${total})`}</div>
        {hasPermission() && (
          <div>
            <ComboButton
              type="multi-select"
              data={groups}
              displayField="name"
              idField={"uuid"}
              selectedItems={currentGroups || []}
              variant="outlined"
              closeOnSelect={false}
              dropDownTitle={() => {
                return (
                  <div
                    style={{
                      margin: "5px 0px",
                      padding: 3,
                      color: theme.palette.text.secondary,
                      display: "flex",
                      alignItems: "center",
                      minWidth: 200,
                    }}
                  >
                    Assign Groups
                  </div>
                );
              }}
              onItemDeSelect={handleRemoveGroup}
              onItemSelect={handleAssignGroup}
              textColor={theme.palette.text.primary}
              showDropdownIcon={true}
              title={""}
            >
              Assign Groups
            </ComboButton>
          </div>
        )}
      </div>
      <div className="groups-list">
        {currentGroups.map((m) => (
          <GroupLabel
            key={m.uuid}
            groupInfo={m}
            handleRemoveGroup={handleRemoveGroup}
          />
        ))}
      </div>
    </div>
  );
})`
  .groups-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 0.5rem;
    max-height: 500px;
    overflow-y: auto;
    padding: 0 10px;
  }
`;

const GroupLabel = styled(
  ({ className, children, groupInfo, handleRemoveGroup }) => {
    return (
      <div
        className={className}
        title={`${groupInfo.name}\n${groupInfo.description}`}
      >
        <div className="user-name">{groupInfo.name}</div>
        <div
          className="remove-icon"
          onClick={() => handleRemoveGroup(groupInfo)}
        >
          <CloseIcon />
        </div>
      </div>
    );
  }
)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.palette.background.secondary};
  margin-bottom: 0.25rem;
  width: fit-content;
  min-width: fit-content;
  user-select: none;
  .user-name {
    font-size: 0.7rem;
    font-weight: 500;
    white-space: nowrap;
  }
  .remove-icon {
    font-size: 0.25rem;
    font-weight: 500;
    white-space: nowrap;
    margin-left: 0.5rem;
    cursor: pointer;
    color: ${({ theme }) => theme.palette.text.secondary};
    display: flex;
    &:hover {
      background-color: ${({ theme }) => theme.palette.action.hover};
    }
    svg {
      font-size: 0.75rem;
    }
  }
`;

export default CaseMembers;
