import { useTheme } from "@mui/styles";
import { useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styled from "styled-components";

const CollapseSection = styled(
  ({
    className,
    title = null,
    titleRender,
    visible = false,
    children,
    allowCollapse = true,
    maxHeight = null,
    onInitialized = () => {},
    onOpen = () => {},
    onClosed = () => {},
    style = {},
  }) => {
    const theme = useTheme();
    const [show, setShow] = useState(visible || !allowCollapse ? true : false);
    const sectionRef = useRef({});

    const rotate = show ? "rotate(180deg)" : "rotate(0)";

    const handleClick = () => {
      if (allowCollapse) {
        setShow(!show);
      }
    };

    const _onInitialized = () => {
      onInitialized(sectionRef.current);
    };

    const _onOpen = () => {
      onOpen();
    };

    const _onClosed = () => {
      onClosed();
    };

    useEffect(() => {
      sectionRef.current.component = {
        openSection: () => setShow(true),
        closeSection: () => setShow(false),
      };

      _onInitialized();
    }, []);

    useEffect(() => {
      show ? _onOpen() : _onClosed();
    }, [show]);

    return (
      <div className={className} style={style}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: allowCollapse ? "pointer" : "unset",
          }}
          onClick={handleClick}
        >
          {(title || titleRender) && (
            <div style={{ fontSize: "0.85rem", fontWeight: "600" }}>
              {title || titleRender}
            </div>
          )}
          {allowCollapse && (
            <KeyboardArrowDownIcon
              style={{
                marginLeft: "auto",
                transform: rotate,
                transition: "transform 0.2s linear",
              }}
            />
          )}
        </div>
        {
          <div
            style={{
              marginTop: 15,
              display: show ? "block" : "none",
              maxHeight: maxHeight || "unset",
            }}
          >
            {children}
          </div>
        }
      </div>
    );
  }
)`
  padding: 15px;
  margin: 5px 0px;
  margin-bottom: 20px;
  border-radius: 5px;
  transition: background-color 0.1s linear;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  user-select: none;
`;

export default CollapseSection;
