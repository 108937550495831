import { useEffect, useRef, useState } from "react";
import NoteTitle from "./NoteTitle.js";
import { useNoteSystem } from "../Contexts/NoteSystemContext.js";
import NoteStateIndicator from "./NoteStateIndicator.js";
import LinkedObjectLabel from "./LinkedObjectLabel.js";
import {
  Extensions,
  RichTextEditor,
  SlashCommands,
  Controls,
} from "@monolith-forensics/monolith-ui";
import { CreateTimelineEventModal } from "../../../components/Modals";
import { FeatureFlagResolver, Features } from "../../../FeatureFlagResovler.js";
import { usePermissions } from "../../../hooks/usePermissions";

const NoteEditor = ({
  readOnly,
  noteRef,
  noteContent,
  handleUpdateContent,
  handleImageUpload,
  handleExport,
  onNoteTitleEdit,
  templates,
  onSaveTemplate,
  onEditorLoad,
  isSaving = false,
}) => {
  const { currentUser, workspace } = usePermissions();
  const { showToolbar, currentTab, currentNote } = useNoteSystem();
  const [showCreateTimelineEvent, setShowCreateTimelineEvent] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  const noteTitleRef = useRef();
  const editorRef = useRef();

  const content = noteRef.current ? noteContent || "" : "";

  const caseAnalysisEnabled = FeatureFlagResolver(
    currentUser.email,
    workspace.tenant_slug,
    Features.CASE_ANALYSIS
  );

  const handleUpdate = (data) => {
    if (readOnly) return;
    handleUpdateContent?.(data);
  };

  const handleInsertTemplate = (editor, template) => {
    if (readOnly) return;

    editor.chain().focus().insertContent(template.content).run();
  };

  const handleTitleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.target.blur();
      // focus editor
      editorRef.current.chain().focus().run();
    }

    // Handle Tab key event
    if (e.key === "Tab") {
      e.preventDefault();
      e.target.blur();
      // focus editor
      editorRef.current.chain().focus().run();
    }
  };

  useEffect(() => {
    if (editorRef.current) {
      onEditorLoad?.(editorRef.current);
    }
  }, [editorRef, onEditorLoad]);

  // Select Title text on new note
  // Status is cleared on blur so that it doesn't select all text when you click on the note
  useEffect(() => {
    if (currentTab?.status === "new") {
      noteTitleRef.current?.focus?.();

      // Select All text
      document.execCommand("selectAll", false, null);
    }
  }, []);

  const customToolbarItems = [
    {
      type: "menu",
      options: {
        label: <div>Actions</div>,
        size: "xxs",
        arrow: true,
        buttonProps: { style: { fontWeight: "normal" } },
        data: [
          {
            label: "Export to Word",
            value: "export_word",
            onClick: () => {
              handleExport(noteRef.current.uuid, "docx");
            },
          },
          {
            label: "Export to PDF",
            value: "export_pdf",
            onClick: () => {
              handleExport(noteRef.current.uuid, "pdf");
            },
          },
          {
            label: "Save as Template",
            value: "save_template",
            onClick: () => {
              onSaveTemplate?.({
                note: noteRef.current,
                content: editorRef.current.getHTML(),
              });
            },
          },
          templates?.length > 0 && {
            label: "Insert Template",
            value: "insert_template",
            type: "menu",
            items: templates?.map((template) => ({
              label: template.name,
              value: template.name,
              onClick: () => {
                handleInsertTemplate(editorRef.current, template);
              },
            })),
          },
        ],
      },
    },
  ];

  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          alignItems: "center",
          padding: "5px",
        }}
      >
        <CreateTimelineEventModal
          show={showCreateTimelineEvent}
          onClose={() => setShowCreateTimelineEvent(false)}
          onCancel={() => setShowCreateTimelineEvent(false)}
          onSubmit={() => setShowCreateTimelineEvent(false)}
          defaultFormData={{
            description: selectedText,
            case_id: currentNote?.linked_case?.case_id,
            note_uuid: currentNote?.uuid,
          }}
        />
        <LinkedObjectLabel
          color={
            noteRef.current?.object_type === "case" ? "primary" : "success"
          }
          // position="top left"
          object_type={noteRef.current?.object_type}
          object_name={noteRef.current?.object_name}
        />
        <NoteTitle
          className="note-title"
          ref={noteTitleRef}
          contentEditable={!readOnly}
          suppressContentEditableWarning
          onBlur={(e) =>
            !readOnly &&
            onNoteTitleEdit?.({
              item: noteRef.current,
              newName: e.target.innerText,
            })
          }
          // Handle enter key event
          onKeyDown={handleTitleKeyPress}
          autoFocus={currentTab?.status === "new"}
        >
          {noteRef.current?.note_tag || ""}
        </NoteTitle>
        <NoteStateIndicator
          color={readOnly ? "warning" : "success"}
          style={{ marginLeft: "auto" }}
        >
          {readOnly ? "Read Only" : "Editable"}
        </NoteStateIndicator>
      </div>

      <RichTextEditor
        editorInstanceRef={editorRef}
        saving={isSaving}
        showToolbar={showToolbar}
        defaultValue={content}
        onChange={handleUpdate}
        readOnly={readOnly}
        extensions={[
          Extensions.Placeholder,
          Extensions.SlashCommand,
          Extensions.Image,
          Extensions.BubbleMenu,
        ]}
        handleImageUpload={handleImageUpload}
        slashCommands={Object.values(SlashCommands)}
        toolbarOptions={{
          controls: [...customToolbarItems, ...Object.values(Controls)],
        }}
        bubbleMenuOptions={{
          customMenuItems: caseAnalysisEnabled
            ? [
                {
                  name: "custom_menu",
                  type: "menu",
                  label: "Actions",
                  arrow: true,
                  buttonProps: { style: { fontSize: 11, padding: "4px" } },
                  items: [
                    {
                      label: "Create Timeline Event",
                      value: "create_timeline_event",
                      data: {
                        command: (editor, selectedText) => {
                          setSelectedText(selectedText);
                          setShowCreateTimelineEvent(true);
                        },
                      },
                    },
                  ],
                },
              ]
            : [],
        }}
      />
    </>
  );
};

export default NoteEditor;
