import ClientNameComponent from "./ClientNameComponents.js";

const ClientsColumnDefs = [
  {
    dataField: "name",
    caption: "Name",
    render: (rowData) => <ClientNameComponent rowData={rowData} />,
  },
  {
    dataField: "email",
    caption: "Email",
  },
  {
    dataField: "title",
    caption: "Title",
  },
  {
    dataField: "organization",
    caption: "Organization",
  },
  {
    dataField: "address",
    caption: "Address",
    visible: false,
  },
  {
    dataField: "city",
    caption: "City",
    visible: false,
  },
  {
    dataField: "state",
    caption: "State",
    visible: false,
  },
  {
    dataField: "zipcode",
    caption: "Zipcode",
    visible: false,
  },
  {
    dataField: "country",
    caption: "Country",
    visible: false,
  },
  {
    dataField: "mobile_number",
    caption: "Mobile",
    visible: false,
  },
  {
    dataField: "office_number",
    caption: "Phone",
    visible: false,
  },
  {
    dataField: "notes",
    caption: "Notes",
    visible: false,
  },
  {
    dataField: "unique_id",
    caption: "Unique ID",
    visible: false,
  },
];

export default ClientsColumnDefs;
