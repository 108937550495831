import { ColumnProps } from "@monolith-forensics/monolith-ui";
import { AuditItem } from "@/types";
import AuditItemStatusSelector from "@/components/Forms/components/Selectors/AuditItemStatusSelector";
import { usePermissions } from "../../hooks/usePermissions";
import { monolithMoment } from "../../utils/date-format";
import { NotepadTextIcon } from "lucide-react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StatusSelectWrapper: React.FC<{
  rowData: AuditItem;
  onRowUpdated?: any;
}> = ({ rowData, onRowUpdated }) => {
  const { hasPermission } = usePermissions();

  const handleUpdate = () => {
    onRowUpdated?.();
  };

  return (
    <AuditItemStatusSelector
      defaultValue={rowData.status}
      auditItem={rowData}
      readOnly={!(hasPermission() || rowData.auditData?.is_complete === 1)}
      onUpdate={handleUpdate}
    />
  );
};

const LogsWrapperStyledContent = styled.div`
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main}20;
  }
`;

const LogsWrapper: React.FC<{
  rowData: AuditItem;
  onRowUpdated?: any;
}> = ({ rowData, onRowUpdated }) => {
  return (
    <LogsWrapperStyledContent
      className="detail subtle cap no-select"
      title={
        `Audit Logs\n` +
        (rowData?.logs
          ? rowData?.logs
              ?.map(
                (log: {
                  created_on: string;
                  created_by: { full_name: string };
                  notes: string;
                }) =>
                  `${monolithMoment({ timestamp: log.created_on })} (${
                    log.created_by.full_name
                  }): ${log.notes}`
              )
              .join("\n")
          : "No Logs")
      }
      style={{
        display: "flex",
        alignItems: "center",
        gap: 5,
        marginTop: 2.5,
        width: "fit-content",
      }}
    >
      <NotepadTextIcon size={14} />
      <div>{rowData?.logs?.length || 0}</div>
    </LogsWrapperStyledContent>
  );
};

const CaseWrapperStyledContent = styled.div`
  padding: 3px 8px;
  border-radius: 5px;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.primary.main}20;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

const CaseWrapper: React.FC<{
  rowData: AuditItem;
  onRowUpdated?: any;
}> = ({ rowData, onRowUpdated }) => {
  if (!rowData?.case_number) return "";
  return (
    <CaseWrapperStyledContent title="View Case">
      <Link
        to={`/cases/${rowData.case_id}/overview`}
      >{`${rowData?.case_number} - ${rowData?.case_ref}`}</Link>
    </CaseWrapperStyledContent>
  );
};

const AuditItemDefs: ColumnProps[] = [
  {
    caption: "Item Number",
    dataField: "object_number",
    width: 200,
  },
  {
    caption: "Item UUID",
    dataField: "object_uuid",
    width: 200,
    visible: false,
  },
  {
    caption: "Status",
    dataField: "status",
    render: StatusSelectWrapper,
  },
  {
    caption: "Method",
    dataField: "method",
    visible: false,
    render: ({ rowData }: { rowData: AuditItem }) => {
      if (!rowData.method) return "";
      const capitalized =
        rowData.method?.charAt(0).toUpperCase() + rowData.method?.slice(1);
      return capitalized;
    },
  },
  {
    caption: "Type",
    dataField: "type",
    visible: false,
  },
  {
    caption: "Unique ID",
    dataField: "unique_id",
    visible: false,
  },
  {
    caption: "Size",
    dataField: "formatted_size",
    visible: false,
  },
  {
    caption: "Item Description",
    dataField: "description",
    visible: false,
  },
  {
    caption: "Location",
    dataField: "current_location",
    width: 200,
  },
  {
    caption: "Location Path",
    dataField: "location_path",
    width: 300,
  },
  {
    caption: "Case",
    dataField: "case",
    width: 250,
    render: CaseWrapper,
  },
  {
    caption: "Case Number",
    dataField: "case_number",
    visible: false,
  },
  {
    caption: "Case Name",
    dataField: "case_ref",
    visible: false,
  },
  {
    caption: "Logs",
    dataField: "logs",
    render: LogsWrapper,
  },
];

export default AuditItemDefs;
