import { createContext, useContext, useState } from "react";
import CaseReportsAPI from "../../../../api/cases/reports.js";
import { usePermissions } from "../../../../hooks/usePermissions";
import { getDateFormat } from "../../../../utils/date-format";
import { ReportTemplate } from "../types/CaseReport";
import { useQueryClient } from "@tanstack/react-query";

export interface ReportParams {
  reportOpts: {
    include: boolean;
    includeHeader: boolean;
    includeFooter: boolean;
  };
  coverPageOpts: {
    include: boolean;
    includeLogo: boolean;
  };
  tocOpts: {
    include: boolean;
  };
  caseSummaryOpts: {
    include: boolean;
    includeMyInfo: boolean;
    includeClientInfo: boolean;
    includeCaseSynopsis: boolean;
    includeReportSummary: boolean;
    includeEvidenceList: boolean;
    includeDocumentsList: boolean;
    includeDocumentAttachments: boolean;
    includedDocumentIDs: number[];
  };
  reportAnalysisOpts: {
    include: boolean;
  };
  evidenceOpts: {
    include: boolean;
    includeDescription: boolean;
    includeDetails: boolean;
    includeChainOfCustody: boolean;
    includeAcquisitions: boolean;
    includeChildEvidence: boolean;
    includePhotos: boolean;
    displayOrder: string;
    includedEvidenceIDs: number[];
  };
  tasksOpts: {
    include: boolean;
  };
  notesOpts: {
    include: boolean;
    includedCaseNoteIDs: number[];
  };
  contactsOpts: {
    include: boolean;
    includedContactIDs: number[];
  };
  activityLogOpts: {
    include: boolean;
  };
  templateOpts: {
    include: boolean;
    selectedTemplate: ReportTemplate | null;
  };
}

interface ReportParamsContextType {
  caseInfo: { case_id?: string; case_report_id?: number };
  reportParams: ReportParams;
  updateParams: (
    section: ReportParamSections,
    values: {
      selectedTemplate?: ReportTemplate | null;
      includedEvidenceIDs?: number[] | [];
      include?: boolean;
      displayOrder?: string;
      includedCaseNoteIDs?: number[];
      includedContactIDs?: number[];
    }
  ) => ReportParams;
  hasPermission: (requestedPermission?: string) => boolean;
  defaultParams: ReportParams;
  usingTemplate: boolean;
}

export type ReportParamSections = keyof ReportParams;

const ReportParamsContext = createContext<ReportParamsContextType | null>(null);

export const defaultParams = {
  reportOpts: {
    include: true,
    includeHeader: true,
    includeFooter: true,
  },
  coverPageOpts: {
    include: true,
    includeLogo: true,
  },
  tocOpts: {
    include: true,
  },
  caseSummaryOpts: {
    include: true,
    includeMyInfo: true,
    includeClientInfo: true,
    includeCaseSynopsis: true,
    includeReportSummary: true,
    includeEvidenceList: true,
    includeDocumentsList: false,
    includeDocumentAttachments: false,
    includedDocumentIDs: [],
  },
  reportAnalysisOpts: {
    include: true,
  },
  evidenceOpts: {
    include: true,
    includeDescription: true,
    includeDetails: true,
    includeChainOfCustody: true,
    includeAcquisitions: true,
    includeChildEvidence: true,
    includePhotos: true,
    displayOrder: "intake_date_desc",
    includedEvidenceIDs: [],
  },
  tasksOpts: {
    include: true,
  },
  notesOpts: {
    include: true,
    includedCaseNoteIDs: [],
  },
  contactsOpts: {
    include: true,
    includedContactIDs: [],
  },
  activityLogOpts: {
    include: true,
  },
  templateOpts: {
    include: false,
    selectedTemplate: null,
  },
};

const getInitialParams = (params: string) => {
  let finalParams = defaultParams;

  if (params) {
    finalParams = JSON.parse(params);
  }

  return {
    ...finalParams,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    date_format: getDateFormat({ isMoment: false, includeTime: true }),
  };
};

export const ReportParamsProvider = ({
  params,
  caseInfo,
  children,
}: {
  params: string;
  caseInfo: { case_id?: string; case_report_id?: number };
  children: React.ReactNode;
}) => {
  const queryClient = useQueryClient();
  const [reportParams, setReportParams] = useState<ReportParams>(
    getInitialParams(params)
  );

  const { hasPermission } = usePermissions();

  const updateParams = <S extends ReportParamSections>(
    section: S,
    values: Partial<ReportParams[S]> // Use Partial to allow updating only specific fields
  ) => {
    const currentParams = { ...reportParams };

    // Get the current section based on the section type
    const currentSection = reportParams[section];

    // Merge the current section with the new values
    const newSection: ReportParams[S] = {
      ...(currentSection || {}), // Fallback to an empty object if currentSection is undefined
      ...values,
    };

    currentParams[section] = newSection;

    setReportParams(currentParams);

    CaseReportsAPI.updateCaseReport({
      case_report_id: caseInfo.case_report_id,
      report_parameters: JSON.stringify(currentParams),
    });

    queryClient.invalidateQueries({
      queryKey: [
        "cases",
        "reports",
        "details",
        { case_report_id: caseInfo.case_report_id },
      ],
    });

    return currentParams;
  };

  const contextValue: ReportParamsContextType = {
    caseInfo,
    reportParams,
    updateParams,
    hasPermission,
    defaultParams,
    usingTemplate: !!reportParams?.templateOpts?.selectedTemplate,
  };

  return (
    <ReportParamsContext.Provider value={contextValue}>
      {children}
    </ReportParamsContext.Provider>
  );
};

export const useReportParams = () => {
  const context = useContext(ReportParamsContext);
  if (!context) {
    throw new Error(
      "useReportParams must be used within a ReportParamsProvider"
    );
  }
  return context;
};
