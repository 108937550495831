import { Button } from "@mui/material";
import styled from "styled-components";
import Dialog from "../../Monolith-UI/Dialog/Dialog.js";
import usePlatform from "../../hooks/usePlatform.js";
import { useQuery } from "@tanstack/react-query";
import SystemAPI from "../../api/System/SystemAPI.js";
import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext.js";

const currentMySQLVersion = "8.0.31";

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 150px;
  max-height: 90vh;
  width: 50vw;
  max-width: 50vw;
  padding: 5px;

  // Allow height to increase with content
  height: fit-content;

  overflow-y: auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
`;

function compareVersions(version1, version2) {
  const parts1 = version1.split(".").map(Number);
  const parts2 = version2.split(".").map(Number);

  for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
    const num1 = parts1[i] || 0; // Default to 0 if undefined
    const num2 = parts2[i] || 0; // Default to 0 if undefined

    if (num1 > num2) return 1; // Version1 is greater
    if (num1 < num2) return -1; // Version2 is greater
  }

  return 0; // Versions are equal
}

const AlertDialog = styled(({ className, open, onClose, children }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent className={className}>
        <h2>Monolith Deployment Notifications</h2>
        <div>{children}</div>
      </DialogContent>
    </Dialog>
  );
})`
  a {
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: underline;
  }
`;

const MonolithDeploymentAlerts = ({}) => {
  const { isOnPremises } = usePlatform();
  const { currentUser } = useAuth();
  const [showDialog, setShowDialog] = useState(false);
  const [systemNotifications, setSystemNotifications] = useState({});

  // Check deployment settings
  const { data } = useQuery({
    queryKey: ["system", "info"],
    queryFn: () => SystemAPI.getSystemInfo(),
    enabled: isOnPremises && !!currentUser,
  });

  useEffect(() => {
    const notify = {};

    // Determine if the dialog should be shown
    // TODO: Redis Check

    // Check for valid MySQL version
    if (data?.mysql?.version) {
      if (compareVersions(data.mysql.version, currentMySQLVersion) < 0) {
        notify.mysql = {
          version: data.mysql_version,
          currentVersion: currentMySQLVersion,
        };
      }
    }

    setSystemNotifications(notify);
    setShowDialog(Object.keys(notify).length > 0);
  }, [data]);

  if (!showDialog) return null;

  return (
    <AlertDialog open={showDialog} onClose={() => setShowDialog(false)}>
      {systemNotifications?.mysql && (
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          <div style={{ fontSize: "large", fontWeight: 600 }}>
            <a
              href="https://docs.monolithforensics.com/monolith/monolith-features/on-premises-deployments/update-mysql-container"
              target="_blank"
              rel="noreferrer"
            >
              Update MySQL Version
            </a>
          </div>
          <div>
            Its time to update your MySQL database from version{" "}
            {data.mysql.version} to {currentMySQLVersion}. Please update your
            MySQL container to version {currentMySQLVersion} as soon as possible
            to ensure compatibility with the latest features and bug fixes.
          </div>
          <div>
            Instructions to update your MySQL database can be found here:{" "}
            <a
              href="https://docs.monolithforensics.com/monolith/monolith-features/on-premises-deployments/update-mysql-container"
              target="_blank"
              rel="noreferrer"
            >
              Monolith MySQL Update Instructions
            </a>
          </div>
          <div>
            If you would like some direct assistance with this, please reach out
            to us at{" "}
            <a
              href="mailto:support@monolithforensics.com"
              target="_blank"
              rel="noreferrer"
            >
              support@monolithforensics.com
            </a>{" "}
          </div>
        </div>
      )}
    </AlertDialog>
  );
};

export default MonolithDeploymentAlerts;
