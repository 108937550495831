import { useState } from "react";
import { Button, Modal } from "@monolith-forensics/monolith-ui";
import ButtonMenu from "../Forms/components/ButtonMenu.js";
import { usePermissions } from "../../hooks/usePermissions";
import LoggingAPI from "../../api/logging/index.js";
import { useMutation } from "@tanstack/react-query";
import { AnalysisAPI } from "../../api/index.js";

const DeleteTimelineEventModal = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  buttonProps = {},
  defaultFormData = {},
}) => {
  const [submitting, setSubmitting] = useState(false);
  const { currentUser } = usePermissions();

  const deleteTimelineEvent = useMutation({
    mutationFn: AnalysisAPI.TimelineEvents.remove,
    onMutate: () => setSubmitting(true),
    onSuccess: (_, variables) => {
      LoggingAPI.createLog({
        case_id: defaultFormData.case_id,
        user_id: currentUser.user_id,
        message: `Deleted timeline event: ${defaultFormData.name}.`,
        event: "delete_timeline_event",
        object_type: "timeline_event",
        object_uuid: defaultFormData.uuid,
      });
      onSubmit?.(defaultFormData);
      setSubmitting(false);
      onClose?.();
    },
  });

  const handleSubmit = async () => {
    deleteTimelineEvent.mutate({ uuid: defaultFormData.uuid });
  };

  const handleCancel = () => {
    onCancel?.();
  };

  return (
    <Modal open={show} onClose={onClose}>
      <Modal.Title>Delete Timeline Event</Modal.Title>
      <div style={{ fontWeight: 600, margin: "20px 0px" }}>
        Are you sure you want to delete this timeline event{" "}
        <span style={{ fontWeight: 600 }}>"{defaultFormData?.event_name}"</span>
        ?
      </div>
      <ButtonMenu>
        <Button
          size="xs"
          variant="subtle"
          onClick={handleCancel}
          disabled={submitting}
        >
          {buttonProps?.cancelText || "Cancel"}
        </Button>
        <Button
          size="xs"
          color="error"
          variant="contained"
          onClick={handleSubmit}
          disabled={submitting}
          loading={submitting}
        >
          {buttonProps?.submitText || "Delete Event"}
        </Button>
      </ButtonMenu>
    </Modal>
  );
};

export default DeleteTimelineEventModal;
