import { useRef } from "react";
import styled from "styled-components";
import Input from "./Input";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import { useState } from "react";
import { useEffect } from "react";

const NumberBox = styled(
  ({
    className,
    fieldName = Date.now(),
    defaultValue,
    onChange = () => {},
    min,
    max,
    placeholder,
    inputStyle = {},
    showArrows = true,
    ...props
  }) => {
    const numberBoxRef = useRef(null);
    const [stateValue, setStateValue] = useState(defaultValue || 0);

    const handleIncrease = () => {
      numberBoxRef.current.stepUp();
      onChange({
        fieldName: fieldName,
        newValue: numberBoxRef.current.value,
        previousValue: stateValue,
      });
      setStateValue(numberBoxRef.current.value);
    };

    const handleDecrease = () => {
      numberBoxRef.current.stepDown();
      onChange({
        fieldName: fieldName,
        newValue: numberBoxRef.current.value,
        previousValue: stateValue,
      });
      setStateValue(numberBoxRef.current.value);
    };

    useEffect(() => {
      const inputBox = numberBoxRef.current;

      const validateNumber = (e) => {
        e.preventDefault();
        if (e.target.value < min) {
          e.target.value = min;
        } else if (e.target.value > max) {
          e.target.value = max;
        }
      };

      inputBox.addEventListener("keydown", validateNumber);
      inputBox.addEventListener("change", validateNumber);

      return () => {
        inputBox.removeEventListener("keydown", validateNumber);
        inputBox.removeEventListener("change", validateNumber);
      };
    }, []);

    return (
      <div className={className}>
        <Input
          inputRef={numberBoxRef}
          labelName={props.labelName}
          type={"number"}
          placeholder={placeholder}
          min={min}
          max={max}
          defaultValue={stateValue}
          inputStyle={inputStyle}
        ></Input>
        {showArrows && (
          <div className="select-box-arrow">
            <div style={{ position: "absolute" }}>
              <div style={{ height: 15 }} onClick={handleIncrease}>
                <ArrowDropUpOutlinedIcon style={{ position: "relative" }} />
              </div>
              <div style={{ height: 15 }} onClick={handleDecrease}>
                <ArrowDropDownOutlinedIcon style={{ position: "relative" }} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
)`
  position: relative;
  // hide arrows
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .select-box-arrow {
    position: absolute;
    height: 30px;
    right: 8px;
    top: 23px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    color: ${(props) => props.theme.palette.text.secondary};
    cursor: pointer;
  }
`;

NumberBox.displayName = "NumberBox";

export default NumberBox;
