import styled from "styled-components";
import EmptySegment from "./EmptySegment.js";
import { memo, useCallback } from "react";
import { NoteItem } from "./NoteItem.js";

import { FilePlus } from "lucide-react";
import LinkedObjectLabel from "./LinkedObjectLabel.js";

const MemoizedNoteItem = memo(NoteItem);

export const NoteItemList = styled(
  ({
    className,
    notes,
    readOnly = false,
    handleCreateNote,
    onItemClicked = () => {},
    onItemDbClicked = () => {},
    onItemRename = () => {},
    onItemDelete = () => {},
    activeItems = [],
    currentSelection = null,
    headerTitle = { badge: "All Notes", label: "", color: "info" },
    rootObject = {},
    ...props
  }) => {
    const handleFileClick = (e) => {
      e.stopPropagation();

      let parent_id = null;

      handleCreateNote?.({
        object_name: rootObject.object_name,
        object_type: rootObject.object_type,
        object_id: rootObject.object_id,
        parent_id,
      });
    };

    const handleItemClick = useCallback((note) => {
      onItemClicked?.(note);
    }, []);

    const handleItemDbClick = useCallback((note) => {
      onItemDbClicked?.(note);
    }, []);

    const handleItemRename = useCallback(({ note, newName: value }) => {
      onItemRename?.({ item: note, newName: value });
    }, []);

    const handleItemDelete = useCallback(({ note }) => {
      onItemDelete?.({ item: note });
    }, []);

    const noteItems = notes?.map((note) => {
      const isActive = activeItems.includes(note.uuid);
      const isSelected = currentSelection.includes(note.uuid);

      return (
        <MemoizedNoteItem
          className="note-item"
          key={note.uuid}
          note={note}
          note_tag={note.note_tag} // Added to ensure item rerenders when note_tag changes
          updated_at={note.updated_at} // Added to ensure item rerenders when updated_at changes
          updated_by={note.updated_by} // Added to ensure item rerenders when updated_by changes
          path={note.path} // Added to ensure item rerenders when path changes
          object_id={note.object_id} // Added to ensure item rerenders when object_id changes
          readOnly={readOnly}
          active={isActive}
          selected={isSelected}
          onClick={handleItemClick}
          onDoubleClick={handleItemDbClick}
          onRename={handleItemRename}
          onDelete={handleItemDelete}
        />
      );
    });

    return (
      <div className={className}>
        <div className="header">
          <LinkedObjectLabel
            color={headerTitle.color}
            object_type={headerTitle.badge}
            object_name={headerTitle.label}
          />
          {!readOnly && (
            <div className="toolbar">
              <FilePlus
                size={16}
                strokeWidth={1}
                absoluteStrokeWidth
                style={{ marginLeft: "auto" }}
                onClick={handleFileClick}
              />
            </div>
          )}
        </div>
        {notes?.length === 0 && <EmptySegment>No Items</EmptySegment>}
        {noteItems}
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &:hover {
    .toolbar {
      visibility: visible;
    }
  }

  .header {
    user-select: none;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    padding-right: 5px;
    padding-top: 5px;
    cursor: pointer;
    color: ${({ theme }) => theme.palette.text.secondary};

    svg {
      font-weight: 400;
      &:hover {
        background-color: ${({ theme }) => theme.palette.action.hover};
      }
    }
  }

  .toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin-left: auto;
    visibility: hidden;
  }
`;
