import { httpGet, httpPost } from "../connection";

export const storeFprSettings = async (values) => {
  return await httpPost("/integrations/fpr/settings", values);
};

export const getFprSettings = async () => {
  return await httpGet("/integrations/fpr/settings");
};

export const getCaseTypesWithFpr = async () => {
  return await httpGet("/integrations/fpr/options");
};

export const markItemsAsReported = async (evidenceIDs) => {
  return await httpPost("/integrations/fpr/mark-as-reported", {
    evidence_ids: evidenceIDs,
  });
};
