import styled from "styled-components";

const LinkedObjectLabel = styled(
  ({ className, object_type, object_name, position, style = {} }) => {
    return (
      <div className={className} style={style}>
        <div className="object-type">{object_type}</div>
        <div className="object-name">{object_name}</div>
      </div>
    );
  }
)`
  user-select: none;
  position: ${({ position }) => (position ? "absolute" : "relative")};
  top: ${({ position }) => (position?.includes("top") ? "10px" : "auto")};
  right: ${({ position }) => (position?.includes("right") ? "10px" : "auto")};
  bottom: ${({ position }) => (position?.includes("bottom") ? "10px" : "auto")};
  left: ${({ position }) => (position?.includes("left") ? "10px" : "auto")};

  display: flex;
  align-items: center;

  .object-name {
    margin-left: 5px;
    font-size: 11px;
    font-weight: 600;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .object-type {
    width: fit-content;
    padding: 1px 3px;
    border-radius: 3px;
    font-size: 10px;
    font-weight: 600;
    text-transform: capitalize;
    background-color: ${({ color, theme }) =>
      color === "success"
        ? `${theme.palette.success.main}35`
        : color === "error"
        ? `${theme.palette.error.main}35`
        : color === "warning"
        ? `${theme.palette.warning.main}35`
        : color === "info"
        ? `${theme.palette.info.main}35`
        : color === "primary"
        ? `${theme.palette.primary.main}35`
        : theme.palette.background.secondary};

    color: ${({ color, theme }) =>
      color === "success"
        ? theme.palette.success.main
        : color === "error"
        ? theme.palette.error.main
        : color === "warning"
        ? theme.palette.warning.main
        : color === "info"
        ? theme.palette.info.main
        : color === "primary"
        ? theme.palette.primary.main
        : theme.palette.text.secondary};
  }
`;

export default LinkedObjectLabel;
