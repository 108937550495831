import { Modal, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { deleteAcquisitions } from "../../../../api/acquisitions";
import ToolBarItems from "../../../../components/ToolBarItems";

const DeleteAcquisitionPopup = ({
  onDelete = () => {},
  open = false,
  handleClose = () => {},
  details,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const handleDelete = () => {
    deleteAcquisitions({ acq_id: details.acq_id }).then(() => {
      onDelete(details);
      enqueueSnackbar(`Acquisition Deleted.`, {
        variant: "success",
      });
    });
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 500,
          maxHeight: "90vh",
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 250px)",
          overflowY: "auto",
          padding: 20,
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Delete Acquisition
        </div>
        {details && (
          <>
            <div style={{ marginBottom: 15 }}>
              <div style={{ color: theme.palette.primary.main }}>
                {details.acq_name} | {details.evidence_number}
              </div>
            </div>
            <div style={{ margin: "10px 0px" }}>
              Are you sure you want to delete this acquisition?
            </div>
            <ToolBarItems
              style={{ marginTop: 20 }}
              submitText="Delete Acquisition"
              submitColor="error"
              onSubmit={handleDelete}
              onCancel={handleCancel}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default DeleteAcquisitionPopup;
