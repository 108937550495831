import styled from "styled-components";
import Dialog from "../../../../Monolith-UI/Dialog/Dialog";
import { Button } from "@monolith-forensics/monolith-ui";
import { useState } from "react";
import Loader from "../../../../components/Loader.js";
import { useQuery } from "@tanstack/react-query";
import EvidenceAPI from "../../../../api/evidence/index.js";

const Menu = styled.div`
  display: flex;
  flex-direction: row;
`;

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 90vh;
  max-width: 90vw;
  width: 600px;
  height: 600px;
  padding: 5px;

  // Allow height to increase with content
  height: fit-content;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
`;

const TemplateList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 40px;
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: 15px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
`;

const TemplateListItem = styled.div`
  cursor: pointer;
  user-select: none;

  padding: 10px;
  border-radius: 4px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }
`;

const TemplateListItemLabel = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 600;

  &[data-selected="true"] {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  &[data-selected="true"]:after {
    content: "✓";
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 12px;
    margin-left: 10px;
  }
`;

const TemplateListItemDetail = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SelectEvidenceModal = ({ open, onClose, onSubmit, defaultInfo }) => {
  const [submitting, setSubmitting] = useState(false);
  const [selectedItems, setSelectedItems] = useState(
    defaultInfo?.selectedItems || []
  );

  const { data } = useQuery({
    queryKey: ["evidence", "list", { case_id: defaultInfo?.caseInfo?.case_id }],
    queryFn: () =>
      EvidenceAPI.getEvidence({
        case_id: defaultInfo?.caseInfo?.case_id,
      }),
  });

  const handleClose = () => {
    onClose?.();
  };

  const handleSubmit = async () => {
    onSubmit?.(selectedItems);
    handleClose();
  };

  const handleSelectItem = (item) => {
    setSelectedItems((prev) => {
      return [...prev, item.evidence_id];
    });
  };

  const handleRemoveItem = (item) => {
    setSelectedItems((prev) => {
      return prev.filter((i) => i !== item.evidence_id);
    });
  };

  const items = data || [];

  return (
    <Dialog open={open} onClose={handleClose} showCloseButton={false}>
      <DialogContent>
        <h2>Select Evidence</h2>
        <div>Select items to include with report template.</div>
        {submitting && <Loader />}
        {!submitting && (
          <div>
            {items.length > 0 && (
              <TemplateList>
                {items.map((item) => {
                  const isSelected = !!selectedItems?.find(
                    (i) => i === item.evidence_id
                  );
                  return (
                    <TemplateListItem
                      key={item.evidence_id}
                      onClick={() =>
                        isSelected
                          ? handleRemoveItem(item)
                          : handleSelectItem(item)
                      }
                      data-selected={isSelected}
                      data-id={item.evidence_id}
                    >
                      <TemplateListItemLabel data-selected={isSelected}>
                        {item.evidence_number}
                      </TemplateListItemLabel>
                      <TemplateListItemDetail>
                        {item.item_type}
                      </TemplateListItemDetail>
                      <TemplateListItemDetail title={item.description}>
                        {item.description}
                      </TemplateListItemDetail>
                      <TemplateListItemDetail>
                        {item.manufacturer}
                      </TemplateListItemDetail>
                      <TemplateListItemDetail>
                        {item.model_name}
                      </TemplateListItemDetail>
                      <TemplateListItemDetail>
                        {item.serial_number}
                      </TemplateListItemDetail>
                    </TemplateListItem>
                  );
                })}
              </TemplateList>
            )}
            {items.length === 0 && <div>No Evidence Available</div>}
            <div style={{ marginTop: "auto" }}>
              <Menu topBorder style={{ justifyContent: "flex-end", gap: 10 }}>
                <Button
                  variant="subtle"
                  size="xs"
                  onClick={() => handleClose()}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="xs"
                  onClick={() => handleSubmit()}
                >
                  Select Evidence
                </Button>
              </Menu>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SelectEvidenceModal;
