import { useQuery } from "@tanstack/react-query";
import styled, { useTheme } from "styled-components";
import { usePermissions } from "../../../hooks/usePermissions";
import Table, { Column } from "../../../Monolith-UI/Table/Table.js";
import { useState } from "react";
import DocumentTemplatesAPI from "../../../api/DocumentTemplates/DocumentTemplates.js";
import { monolithMoment } from "../../../utils/date-format";
import { DownloadIcon, EditIcon, Trash2Icon } from "lucide-react";
import { Switch, Button } from "@monolith-forensics/monolith-ui";
import {
  EditDocumentTemplateModal,
  CreateDocumentTemplateModal,
  DeleteDocumentTemplateModal,
} from "../../../components/Modals/index";
import { DocumentTemplate } from "@/components/Modals/DocumentTemplate.interface";

const DocumentTemplatesSection = styled(({ className }) => {
  const theme: any = useTheme();
  const { currentUser } = usePermissions();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] =
    useState<DocumentTemplate | null>(null);

  const { data, refetch } = useQuery({
    queryKey: [
      "document-templates",
      "list",
      { created_by_id: currentUser.user_id, include_shared: false },
    ],
    queryFn: () =>
      DocumentTemplatesAPI.get({
        created_by_id: currentUser.user_id,
        include_shared: false,
      }),
  });

  const handleUpdateTemplate = (values = {}) => {
    DocumentTemplatesAPI.update(values).then(() => refetch());
  };

  const handleAddTemplate = () => {
    setShowAddModal(false);
    refetch();
  };

  const handleEditTemplate = () => {
    setShowEditModal(false);
    refetch();
  };

  if (!data) return <div>Loading...</div>;

  const templates = data?.data || [];

  return (
    <div className={className}>
      <div>
        These are your document templates. These can be used to create custom
        reports. Documentation on how to use these templates can be found in
        here:{" "}
        <a
          href="https://docs.monolithforensics.com/monolith/monolith-features/case-reports/report-templates"
          target="_blank"
          rel="noreferrer"
        >
          Document Templates
        </a>
      </div>
      <div>
        <Button
          size="xxs"
          variant="contained"
          color="primary"
          onClick={() => setShowAddModal(true)}
        >
          + Add Template
        </Button>
        {showDeleteModal && (
          <DeleteDocumentTemplateModal
            show={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            onCancel={() => setShowDeleteModal(false)}
            onSubmit={() => refetch()}
            defaultInfo={selectedTemplate}
          />
        )}
        {showAddModal && (
          <CreateDocumentTemplateModal
            show={showAddModal}
            onClose={() => setShowAddModal(false)}
            onCancel={() => setShowAddModal(false)}
            onSubmit={handleAddTemplate}
          />
        )}
        {showEditModal && (
          <EditDocumentTemplateModal
            show={showEditModal}
            onClose={() => setShowEditModal(false)}
            onCancel={() => setShowEditModal(false)}
            onSubmit={handleEditTemplate}
            defaultInfo={selectedTemplate}
          />
        )}
      </div>
      <Table data={templates} keyValue="uuid">
        <Column dataField="name" caption="Template Name" width={300} />
        <Column dataField="description" caption="Description" width={300} />
        <Column
          dataField="created_on"
          caption="Created On"
          width={150}
          render={(rowData: DocumentTemplate) =>
            monolithMoment({ timestamp: rowData.created_on })
          }
        />
        <Column
          dataField="actions"
          caption=""
          width={40}
          render={(data: DocumentTemplate) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 35,
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: 600,
                  color: theme.palette.text.secondary,
                  width: "100%",
                  userSelect: "none",
                  paddingRight: 10,
                }}
                title="Shared templates are visible to all users in Monolith."
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 10,
                    alignItems: "center",
                    justifyContent: "flex-end",
                    fontWeight: 600,
                    color: theme.palette.text.secondary,
                    width: "100%",
                    userSelect: "none",
                  }}
                >
                  <div>Shared</div>
                  <Switch
                    size="xs"
                    defaultValue={data.is_shared}
                    onChange={(value) => {
                      handleUpdateTemplate({
                        uuid: data.uuid,
                        is_shared: value,
                      });
                    }}
                  />
                </div>
                <Button
                  size="xxs"
                  variant="text"
                  color="primary"
                  leftSection={<DownloadIcon size={12} />}
                  href={data.download_url}
                  download={data.name}
                >
                  Download
                </Button>
                <Button
                  size="xxs"
                  variant="text"
                  color="primary"
                  leftSection={<EditIcon size={12} />}
                  onClick={() => {
                    setSelectedTemplate(data);
                    setShowEditModal(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  size="xxs"
                  variant="text"
                  color="error"
                  leftSection={<Trash2Icon size={12} />}
                  onClick={() => {
                    setSelectedTemplate(data);
                    setShowDeleteModal(true);
                  }}
                >
                  Delete
                </Button>
              </div>
            );
          }}
        />
      </Table>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1 1 auto;
  overflow-y: auto;

  a {
    color: ${({ theme }) => theme.palette.primary.main};

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default DocumentTemplatesSection;
