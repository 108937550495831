import { useState } from "react";
import { Modal } from "@monolith-forensics/monolith-ui";
import { DefaultUserRoleForm } from "../Forms/index.js";
import Loader from "../Loader.js";
import { usePermissions } from "../../hooks/usePermissions";
import { useMutation } from "@tanstack/react-query";
import LoggingAPI from "../../api/logging/index.js";
import UserRolesAPI from "../../api/UserRoles/UserRoles.js";

const CreateUserRoleModal = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  defaultFormData = {},
  ...props
}) => {
  const { currentUser } = usePermissions();
  const [submitting, setSubmitting] = useState(false);

  const create = useMutation({
    mutationFn: UserRolesAPI.create,
    onMutate: () => setSubmitting(true),
    onSuccess: (_, variables) => {
      LoggingAPI.createLog({
        user_id: currentUser.user_id,
        message: `Created user role.`,
        event: "user_role:create",
        object_type: "user_role",
        object_uuid: variables.uuid,
      });
      onSubmit?.(defaultFormData, variables);
      onClose?.();
      setSubmitting(false);
    },
  });

  return (
    <Modal open={show} onClose={onClose} style={{ width: 550 }}>
      <Modal.Title style={{ marginBottom: 10 }}>Create User Role</Modal.Title>
      {!submitting && (
        <DefaultUserRoleForm
          onSubmit={create.mutate}
          onCancel={onCancel}
          defaultFormData={defaultFormData}
          {...props}
        />
      )}
      {submitting && <Loader />}
    </Modal>
  );
};

export default CreateUserRoleModal;
