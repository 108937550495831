// schema is a Zod schema object

function validateAsync(schema) {
  return async (values) => {
    const parsed = await schema.safeParseAsync(values);
    if (parsed.success) {
      return {
        errors: {},
        isValid: true,
        hasErrors: false,
      };
    }
    const results = {};
    if ("error" in parsed) {
      parsed.error.errors.forEach((error) => {
        results[error.path.join(".")] = error.message;
      });
    }

    return {
      errors: results,
      isValid: false,
      hasErrors: true,
    };
  };
}

export default validateAsync;
