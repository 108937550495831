import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Plus, MoreHorizontalIcon, ArchiveIcon } from "lucide-react";

import styled, { useTheme } from "styled-components";
import { memo, useState } from "react";
import { useEffect } from "react";

import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import TaskButton from "../TaskButton.js";
import DropdownMenu from "../DropdownMenu/DropdownMenu.js";

const StyledBoard = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: hidden;

  padding: 0px 10px;

  background-color: ${({ theme }) =>
    theme.name === "DARK" ? "transparent" : "#f4f5f8"};

  height: ${({ height }) => height || "100%"};
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 325px;
  width: 325px;

  margin-right: 25px;

  border-radius: 5px;
  border: 1px solid transparent;
  transition: border 0.1s ease;
  &.isOver {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }
`;

const ColumnTitle = styled.div`
  font-weight: bold;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  padding-right: 5px;

  .label {
    max-width: 120px;
    // line clamp at 1 line
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .count {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-weight: 600;
  }

  .plus {
    color: ${({ theme }) => theme.palette.text.secondary};
    cursor: pointer;

    margin-left: auto;
  }
`;

const StyledTask = styled.div`
  user-select: none;
  width: 100%;
  height: 100px;
  border-radius: 5px;
  border: 1px solid
    ${({ theme }) => (theme.name === "DARK" ? "#3c3c3c" : "#d3d3d3")};
  background-color: ${({ theme }) =>
    theme.name === "DARK" ? "#2a2a2a" : theme.palette.background.default};

  transition: background-color, border 0.1s ease;

  &.dragging {
    z-index: 1000;
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.name === "DARK" ? "#313131" : "#f5f5f5"};
  }
`;

const MemoizedTask = memo(StyledTask, (prevProps, nextProps) => {
  const { task: currentTask } = nextProps;
  const { task: prevTask } = prevProps;

  return (
    prevTask.sort_value === currentTask.sort_value &&
    prevTask.status_id === currentTask.status_id &&
    prevTask.priority_id === currentTask.priority_id &&
    prevTask.time_category_id === currentTask.time_category_id &&
    prevTask.task_name === currentTask.task_name &&
    prevTask.description === currentTask.description &&
    prevTask.object_id === currentTask.object_id &&
    prevTask.object_type === currentTask.object_type &&
    prevTask.due_date === currentTask.due_date &&
    prevTask.duration === currentTask.duration &&
    prevTask?.assignees?.length === currentTask?.assignees?.length &&
    // Check if the subtask statuses are the same
    // this check is performed to update the subtask completion indicator when a subtask is completed or uncompleted
    prevTask?.subtasks?.every((subtask, index) => {
      return subtask?.status_id === currentTask?.subtasks?.[index]?.status_id;
    }) &&
    prevProps?.displayPreferences?.showStatus ===
      nextProps?.displayPreferences?.showStatus &&
    prevProps?.displayPreferences?.showPriority ===
      nextProps?.displayPreferences?.showPriority &&
    prevProps?.displayPreferences?.showDueDate ===
      nextProps?.displayPreferences?.showDueDate &&
    prevProps?.displayPreferences?.showLinkedObject ===
      nextProps?.displayPreferences?.showLinkedObject &&
    prevProps?.displayPreferences?.showAssignees ===
      nextProps?.displayPreferences?.showAssignees &&
    prevProps?.displayPreferences?.showSubTaskCount ===
      nextProps?.displayPreferences?.showSubTaskCount &&
    prevProps?.displayPreferences?.showSubTaskIcon ===
      nextProps?.displayPreferences?.showSubTaskIcon &&
    prevProps?.displayPreferences?.showDuration ===
      nextProps?.displayPreferences?.showDuration
  );
});

const Row = ({ data, index, style }) => {
  const {
    tasks,
    Item,
    handleItemClick,
    displayPreferences,
    column,
    onItemArchived,
    onItemUpdate,
    onItemRemove,
    itemHref,
  } = data;
  const task = tasks[index];

  // We are rendering an extra item for the placeholder
  if (!task) {
    return null;
  }
  return (
    <Draggable key={task.uuid} draggableId={task.uuid} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...style,
            ...provided.draggableProps.style,
            width: `calc(100% - 7px)`,
          }}
        >
          <MemoizedTask
            key={task.uuid}
            className={
              " mf-kanban-task" + (snapshot.isDragging ? " isDragging" : "")
            }
            onClick={(e) =>
              handleItemClick?.({
                item: task,
                group: column,
                event: e,
              })
            }
            task={task}
            displayPreferences={displayPreferences}
          >
            <Item
              task={task}
              displayPreferences={displayPreferences}
              onItemArchived={onItemArchived}
              onItemUpdate={onItemUpdate}
              onItemRemove={onItemRemove}
              itemHref={itemHref}
            />
          </MemoizedTask>
        </div>
      )}
    </Draggable>
  );
};

const VirtualizedColumn = styled(
  ({
    className,
    column = {},
    itemRender: Item,
    onItemArchived,
    handleAddItem,
    handleArchiveTasks,
    handleItemClick,
    onItemUpdate,
    onItemRemove,
    itemHref,
    displayPreferences,
    allowColumnMigrate,
  }) => {
    const theme = useTheme();
    const tasks = column?.tasks || [];

    return (
      <StyledColumn className={className + " mf-kanban-column"}>
        <ColumnTitle>
          <column.icon size={16} color={column.iconColor} />
          <div className="label">{column.label}</div>
          <div className="count">{column?.tasks?.length || 0}</div>
          <div
            className="plus"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <TaskButton onClick={() => handleAddItem(column)}>
              <Plus size={16} />
            </TaskButton>
            <DropdownMenu
              menuItems={[
                {
                  label: "Archive Tasks",
                  icon: ArchiveIcon,
                  menuItemProps: {
                    style: {
                      color: theme.palette.primary.main,
                    },
                  },
                  onClick: () => handleArchiveTasks?.(column),
                },
              ]}
              title={"Column Actions"}
              variant={"text"}
              textColor={"secondary"}
            >
              <MoreHorizontalIcon size={16} />
            </DropdownMenu>
          </div>
        </ColumnTitle>
        <Droppable
          droppableId={column.id.toString()}
          mode="virtual"
          isDropDisabled={!allowColumnMigrate}
          renderClone={(provided, snapshot, rubric) => {
            const task = tasks[rubric.source.index];
            return (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <MemoizedTask
                  key={task.uuid}
                  className={
                    " mf-kanban-task" +
                    (snapshot.isDragging ? " isDragging" : "")
                  }
                  task={task}
                  displayPreferences={displayPreferences}
                >
                  <Item task={task} displayPreferences={displayPreferences} />
                </MemoizedTask>
              </div>
            );
          }}
        >
          {(provided, snapshot) => {
            // Add an extra item to our list to make space for a dragging item
            // Usually the DroppableProvided.placeholder does this, but that won't
            // work in a virtual list
            const itemCount = snapshot.isUsingPlaceholder
              ? tasks.length + 1
              : tasks.length;

            return (
              <AutoSizer>
                {({ height, width }) => (
                  <FixedSizeList
                    className={"column-inner-container"}
                    overscanCount={3}
                    height={height - 35} // subtract the height of the column title
                    itemCount={itemCount}
                    itemSize={110}
                    width={width}
                    outerRef={provided.innerRef}
                    {...provided.droppableProps}
                    itemData={{
                      tasks,
                      Item,
                      onItemArchived,
                      onItemUpdate,
                      onItemRemove,
                      itemHref,
                      handleItemClick,
                      displayPreferences,
                      column,
                    }}
                  >
                    {Row}
                  </FixedSizeList>
                )}
              </AutoSizer>
            );
          }}
        </Droppable>
      </StyledColumn>
    );
  }
)`
  .column-inner-container {
    padding-right: 5px;
  }
`;

const Kanban = ({
  data,
  allowColumnMigrate = true,
  onItemMove,
  onItemRemove,
  onItemArchived,
  itemRender,
  onAddItem,
  onItemClick,
  onItemUpdate,
  itemHref = () => {},
  handleArchiveTasks,
  ...props
}) => {
  const [groups, setGroups] = useState(data);

  const calculateNewSortValue = (
    destinationGroup,
    destination,
    options = {}
  ) => {
    // was the item inserted at the beginning of the list?
    if (destination.index === 0) {
      // Is the group empty?
      if (destinationGroup.tasks.length === 0) {
        return Math.random() * 1000000;
      }

      // Subtract 1
      return destinationGroup.tasks[0].sort_value - 1;
    }

    // was the item inserted at the end of the list?
    const isLastItem = options?.newGroup
      ? destination.index === destinationGroup.tasks.length
      : destination.index === destinationGroup.tasks.length - 1;

    if (isLastItem) {
      // return sort value of the last item + 1
      return (
        destinationGroup.tasks[destinationGroup.tasks.length - 1].sort_value + 1
      );
    }

    // Calculate new sort value by splitting the difference between the two tasks
    let first = destinationGroup.tasks[destination.index - 1].sort_value;
    let second = destinationGroup.tasks[destination.index].sort_value;

    // If the item was moved down, swap the values
    if (options?.direction === "down") {
      first = destinationGroup.tasks[destination.index].sort_value;
      second = destinationGroup.tasks[destination.index + 1].sort_value;
    }

    const delta = (second - first) / 2;

    return first + delta;
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const sourceGroup = groups.find(
      (group) => group.id.toString() === source.droppableId
    );

    const destinationGroup = groups.find(
      (group) => group.id.toString() === destination.droppableId
    );

    const currentTask = sourceGroup.tasks[source.index];

    // If the source and destination are the same, reorder the tasks
    if (source.droppableId === destination.droppableId) {
      const direction = destination.index < source.index ? "up" : "down";

      setGroups((prevGroups) => {
        const prevData = [...prevGroups];

        // Update the sort value of the task
        currentTask.sort_value = calculateNewSortValue(
          destinationGroup,
          destination,
          {
            direction,
            newGroup: false,
          }
        );

        sourceGroup.tasks.splice(source.index, 1);
        sourceGroup.tasks.splice(destination.index, 0, currentTask);

        return prevData;
      });
    }
    // Move task to new group and set order
    else if (source.droppableId !== destination.droppableId) {
      setGroups((prevGroups) => {
        const prevData = [...prevGroups];

        // Update the sort value of the task
        currentTask.sort_value = calculateNewSortValue(
          destinationGroup,
          destination,
          {
            newGroup: true,
          }
        );

        sourceGroup.tasks.splice(source.index, 1);

        destinationGroup.tasks.splice(destination.index, 0, currentTask);

        return prevData;
      });
    }

    onItemMove?.({
      event: result,
      sourceGroup,
      destinationGroup,
      item: currentTask,
    });
  };

  const handleAddItem = (data) => {
    onAddItem?.(data);
  };

  // Set Groups When the data prop changes
  useEffect(() => {
    setGroups(data);
  }, [data]);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <StyledBoard className="mf-kanban" height={props?.height}>
        {groups.map((column) => {
          return (
            <VirtualizedColumn
              key={column.id}
              column={column}
              itemRender={itemRender}
              handleAddItem={handleAddItem}
              handleArchiveTasks={handleArchiveTasks}
              onItemArchived={onItemArchived}
              onItemUpdate={onItemUpdate}
              onItemRemove={onItemRemove}
              itemHref={itemHref}
              handleItemClick={onItemClick}
              displayPreferences={props?.displayPreferences}
              allowColumnMigrate={allowColumnMigrate}
            />
          );
        })}
      </StyledBoard>
    </DragDropContext>
  );
};

export default Kanban;
