import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useRef, useState } from "react";
import EvidenceAPI from "../../api/evidence";
import { usePermissions } from "../../hooks/usePermissions";
import DropdownMenu from "../../Monolith-UI/DropdownMenu/DropdownMenu.js";

const ProgressSelector = ({ rowData, onChange = () => {} }) => {
  const { hasPermission } = usePermissions();
  const [current, setCurrent] = useState(rowData.progress);
  const queryClient = useQueryClient();
  const cachedData = queryClient.getQueryData(["evidence:progress"]);
  const containerRef = useRef(null);

  const { data: progressItems } = useQuery({
    queryKey: ["evidence:progress"],
    queryFn: () => EvidenceAPI.getEvidenceProgress(),
    enabled: !cachedData,
    placeholderData: (data) => data || [],
  });

  const currentProgress =
    progressItems?.find((p) => p.progress_name === current) || {};

  const handleItemSelect = (item) => {
    setCurrent(item.progress_name);
    containerRef.current.classList.remove("custom-action-button");
    EvidenceAPI.updateEvidence(rowData.evidence_id, {
      progress: item.progress_name,
    });
    onChange({ progress: item.progress_name, progress_id: item.progress_id });
  };

  const menuItems = [
    {
      radioGroup: true,
      value: currentProgress?.progress_id,
      onSelectionChanged: (data) => {
        handleItemSelect(
          progressItems.find((p) => p.progress_id === data.value)
        );
      },
      items: progressItems.map((p) => ({
        label: p.progress_name,
        value: p.progress_id,
      })),
    },
  ];

  return (
    <div
      ref={containerRef}
      className={`${currentProgress ? "" : "custom-action-button"}`}
    >
      <DropdownMenu
        menuItems={menuItems}
        disabled={!hasPermission()}
        title={"Select Progress"}
        variant={"contained"}
        textColor={current ? "primary" : "secondary"}
        buttonProps={{
          style: {
            fontSize: 12,
          },
        }}
      >
        {current ? <div>{current}</div> : <div>Select...</div>}
      </DropdownMenu>
    </div>
  );
};

export default ProgressSelector;
