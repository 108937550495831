import { Link } from "react-router-dom";
import styled from "styled-components";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";

const CaseNumberCell = styled(({ className, rowData, color }) => {
  if (!rowData.case_id || rowData.case_id < 0) return <></>;

  return (
    <Link
      className={className}
      to={`/cases/${rowData.case_id}/overview`}
      state={{
        returnText: `Back to cases`,
        returnPath: `/cases`,
      }}
    >
      <div
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          {!!rowData.case_number
            ? rowData.case_number
            : rowData.case_id && rowData.case_id > 0
            ? "View Case"
            : null}
        </div>
        <LaunchOutlinedIcon
          className="action-button"
          style={{ fontSize: 13, marginLeft: 5 }}
        />
      </div>
    </Link>
  );
})(({ theme, rowData, color }) => ({
  color: color || theme.palette.success.main,
  // hover
  "&:hover": {
    textDecoration: "underline",
  },
}));

export default CaseNumberCell;
