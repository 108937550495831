import styled from "styled-components";

const TaskButton = styled(
  ({
    className,
    children,
    style = {},
    onClick = () => {},
    title = "",
    variant = "text",
    enabled = true,
  }) => {
    const _onClick = (e) => {
      if (enabled) onClick(e);
    };

    return (
      <div
        className={className + " task-button"}
        onClick={_onClick}
        style={{ ...style }}
        title={title}
      >
        {children}
      </div>
    );
  }
)`
  user-select: none;
  cursor: pointer;
  display: flex;
  alignitems: center;
  margin-right: 3px;
  border-radius: 4px;
  width: fit-content;
  min-width: max-content;
  padding: 2px;
  color: ${(props) => props.theme.palette.text.secondary};
  transition: all 0.15s ease-in-out;
  border: 1px solid
    ${(props) => {
      if (props.variant === "outlined") return props.theme.palette.divider;
      if (props.variant === "text") return "transparent";
      if (props.variant === "contained") return "transparent";
      return "transparent";
    }};
  background-color: ${(props) => {
    if (props.variant === "contained")
      return props.theme.palette.background.secondary;
    if (props.variant === "outlined") return "transparent";
    if (props.variant === "text") return "transparent";
    return "transparent";
  }};
  ${(props) =>
    props.enabled === undefined || props.enabled
      ? `&:hover {
    border: 1px solid ${props.theme.palette.primary.main};
  }`
      : ""}
`;

export default TaskButton;
