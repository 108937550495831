import { useState } from "react";
import { Button, Modal } from "@monolith-forensics/monolith-ui";
import ButtonMenu from "../Forms/components/ButtonMenu.js";
import { usePermissions } from "../../hooks/usePermissions";
import LoggingAPI from "../../api/logging/index.js";
import MonolithNotesAPI from "../../api/Notes/index.js";
import { DocumentTemplate } from "./DocumentTemplate.interface";

const DeleteEditorTemplateModal = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  buttonProps,
  defaultInfo,
}: {
  show: boolean;
  onClose: () => void;
  onSubmit: (defaultInfo?: any) => void;
  onCancel: () => void;
  buttonProps?: {
    cancelText: string;
    submitText: string;
  };
  defaultInfo?: DocumentTemplate;
}) => {
  const [submitting, setSubmitting] = useState(false);
  const { currentUser } = usePermissions();

  const handleSubmit = async () => {
    setSubmitting(true);

    await MonolithNotesAPI.deleteTemplate({
      uuid: defaultInfo?.uuid,
    }).then(() => onSubmit());

    LoggingAPI.createLog({
      user_id: currentUser.user_id,
      message: `Deleted editor template: ${defaultInfo?.name}`,
      event: "delete_editor_template",
      object_type: "editor_template",
      object_uuid: defaultInfo?.uuid,
    });

    onSubmit?.(defaultInfo);
    setSubmitting(false);
    onClose?.();
  };

  const handleCancel = () => {
    onCancel?.();
  };

  return (
    <Modal open={show} onClose={onClose}>
      <Modal.Title>Delete Editor Template</Modal.Title>
      <div style={{ fontWeight: 600, margin: "20px 0px" }}>
        Are you sure you want to delete the template{" "}
        <span style={{ fontWeight: 600 }}>"{defaultInfo?.name}"</span>?
      </div>
      <ButtonMenu>
        <Button
          size="xs"
          variant="subtle"
          onClick={handleCancel}
          disabled={submitting}
        >
          {buttonProps?.cancelText || "Cancel"}
        </Button>
        <Button
          size="xs"
          color="error"
          variant="contained"
          onClick={handleSubmit}
          disabled={submitting}
          loading={submitting}
        >
          {buttonProps?.submitText || "Delete Template"}
        </Button>
      </ButtonMenu>
    </Modal>
  );
};

export default DeleteEditorTemplateModal;
