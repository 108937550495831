import {
  useInfiniteQuery,
  useQueries,
  useQueryClient,
} from "@tanstack/react-query";
import AuditsAPI from "@/api/Audits/Audits";
import AuditSectionHeader from "./Components/AuditSectionHeader";
import { getDateFormat } from "../../utils/date-format";
import styled, { useTheme } from "styled-components";
import Table, { Column, useTable } from "../../Monolith-UI/Table/Table.js";
import { useEffect, useRef, useState } from "react";
import synchronizeColumnState from "../../utils/synchronize-column-state.js";
import { Helmet } from "react-helmet-async";
import { Button, Modal } from "@mui/material";
import { usePermissions } from "../../hooks/usePermissions";
import ComboButton from "../../Monolith-UI/ComboButton/ComboButton.js";

// Icons
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Form, EmptyItem, SimpleItem } from "devextreme-react/ui/form.js";
import ToolBarItems from "../../components/ToolBarItems.js";
import UserApi from "../../api/users/users.js";
import OfficesApi from "../../api/offices/index.js";
import LocationsAPI from "../../api/locations/index.js";
import CollapseSection from "../../components/CollaspeSection.js";
import { useQueryFilter } from "../../Monolith-UI/QueryFilter/QueryFilter.js";
import EvidenceDimensions from "../../components/Evidence/EvidenceDimensions.js";
import EvidenceAPI from "../../api/evidence/index.js";
import ClientsAPI from "../../api/clients/index.js";
import CaseStorageDimensions from "../../components/CaseStorage/CaseStorageDimensions.js";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { ItemTotal } from "../Cases/CaseEvidence/index";
import { ClipboardListIcon } from "lucide-react";
import AccessDeniedMessage from "../../components/AccessDeniedMessage.js";
import { MONOLITH_PERMISSIONS } from "../../constants.js";
import { Input } from "@monolith-forensics/monolith-ui";
import { ColumnProps } from "@/Monolith-UI/Table/types/Table.js";
import { User } from "../Users/types/Users.js";

interface AuditFilter {
  field: string;
  label: string;
  mode: string;
  operator: {
    name: string;
    operator: string;
    mode: string[];
    type: string;
  };
  value: string[];
  displayValue: string[];
  id: string;
}

interface Audit {
  assigned_to: Partial<User>;
  completed_by: string;
  completed_on: string;
  created_by: Partial<User>;
  created_on: string;
  description: string;
  due_date: string;
  filter: AuditFilter[];
  id: number;
  is_complete: number;
  item_type: string;
  name: string;
  start_date: string;
  status: string;
  totals: { total: number; status: string }[];
  type: string;
  uuid: string;
}

const NameCell = styled(({ className, rowData }) => {
  return (
    <div className={className}>
      <Link to={`/audits/${rowData.uuid}/audit-items`}>
        <div className="label">{rowData?.name}</div>
      </Link>
    </div>
  );
})`
  .label {
    font-weight: 500;
    color: ${(props) => props.theme.palette.primary.main};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      color: ${(props) => props.theme.palette.text.primary};
    }
  }
`;

const columnDefs = [
  {
    dataField: "name",
    caption: "Name",
    render: (rowData: Audit) => <NameCell rowData={rowData} />,
  },
  {
    dataField: "status",
    caption: "Status",
    render: (data: Audit) => (
      <span style={{ textTransform: "capitalize" }}>{data.status}</span>
    ),
  },
  {
    dataField: "item_type",
    caption: "Item Type",
    render: (data: Audit) => (
      <span style={{ textTransform: "capitalize" }}>{data.item_type}</span>
    ),
  },
  {
    dataField: "progress",
    caption: "Progress",
    render: (data: Audit) => {
      const total =
        data.totals?.reduce((acc, curr) => acc + curr.total, 0) || 0;

      const completed =
        total -
        (data.totals?.find((item) => item.status === "pending")?.total ||
          total);

      return (
        <div className="totals">
          {completed} / {total}
        </div>
      );
    },
  },
  {
    dataField: "pending",
    caption: "Pending",
    render: (data: Audit) =>
      data.totals?.find((item) => item.status === "pending")?.total || 0,
    visible: false,
  },
  {
    dataField: "passed",
    caption: "Passed",
    render: (data: Audit) =>
      data.totals?.find((item) => item.status === "passed")?.total || 0,
    visible: false,
  },
  {
    dataField: "failed",
    caption: "Failed",
    render: (data: Audit) =>
      data.totals?.find((item) => item.status === "failed")?.total || 0,
    visible: false,
  },
  {
    dataField: "total_items",
    caption: "Total Items",
    render: (data: Audit) => {
      const total =
        data.totals?.reduce((acc, curr) => acc + curr.total, 0) || 0;

      return <div className="totals">{total}</div>;
    },
  },
  {
    dataField: "created_on",
    caption: "Created On",
    visible: false,
    dataType: "date",
    format: {
      type: getDateFormat({ isMoment: true, includeTime: false }),
    },
  },
  {
    dataField: "created_by",
    caption: "Created By",
    visible: false,
    render: (data: Audit) => <span>{data?.created_by?.full_name || null}</span>,
  },
  {
    dataField: "assigned_to",
    caption: "Assignee",
    render: (data: Audit) => (
      <span>{data?.assigned_to?.full_name || null}</span>
    ),
  },
  {
    dataField: "start_date",
    caption: "Start Date",
    dataType: "date",
    format: {
      type: getDateFormat({ isMoment: true, includeTime: false }),
    },
  },
  {
    dataField: "due_date",
    caption: "Due Date",
    dataType: "date",
    format: {
      type: getDateFormat({ isMoment: true, includeTime: false }),
    },
  },
  {
    dataField: "description",
    caption: "Description",
    visible: false,
  },
];

const stateStoreKey = "audits:list";

const EvidenceFilterSection = styled(({ className, onFilter }) => {
  const queryFilter = useRef(null);

  useQueries({
    queries: [
      {
        queryKey: ["evidence:types"],
        queryFn: () => EvidenceAPI.getEvidenceTypes(),
      },
      {
        queryKey: ["evidence:providers"],
        queryFn: () => EvidenceAPI.getEvidenceBrands(),
      },
      {
        queryKey: ["evidence:progress"],
        queryFn: () => EvidenceAPI.getEvidenceProgress(),
      },
      {
        queryKey: ["evidence:locations", { include_groups: false }],
        queryFn: () => LocationsAPI.getLocations({}, { include_groups: false }),
      },
      {
        queryKey: ["offices:list"],
        queryFn: () => OfficesApi.getOffices(),
      },
      {
        queryKey: ["clients:list"],
        queryFn: () => ClientsAPI.getClients(),
      },
      {
        queryKey: ["clients:organizations"],
        queryFn: () => ClientsAPI.getOrganizations(),
      },
    ],
  });

  const { queryButton, conditions } = useQueryFilter({
    dimensions: EvidenceDimensions.sort((a, b) => a.name.localeCompare(b.name)),
    onQuerySet: (modeledQuery, rawQuery) => {
      onFilter(modeledQuery?.conditions);
    },
    queryFilter: queryFilter,
  });

  return (
    <div className={className}>
      <div style={{ width: "100%", marginBottom: 10 }}>{queryButton}</div>
      <div>{conditions}</div>
      {!conditions && (
        <div>No Filters Applied - All items will be included.</div>
      )}
    </div>
  );
})``;

const StorageFilterSection = styled(({ className, onFilter }) => {
  const queryFilter = useRef(null);

  const { queryButton, conditions } = useQueryFilter({
    dimensions: CaseStorageDimensions.sort((a, b) =>
      a.name.localeCompare(b.name)
    ),
    onQuerySet: (modeledQuery) => onFilter(modeledQuery?.conditions),
    queryFilter: queryFilter,
  });

  return (
    <div className={className}>
      <div style={{ width: "100%", marginBottom: 10 }}>{queryButton}</div>
      <div>{conditions}</div>
      {!conditions && (
        <div>No Filters Applied - All items will be included.</div>
      )}
    </div>
  );
})``;

const CreateModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
}: {
  open: boolean;
  handleClose: () => void;
  onSubmit: () => void;
}) => {
  const theme: any = useTheme();
  const form = useRef<any>(null);
  const [filter, setFilter] = useState(null);
  const [itemSelection, setItemSelection] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    if (!form.current.instance.validate().isValid)
      return enqueueSnackbar("Please fill out all required fields", {
        variant: "error",
      });

    const formData = {
      ...form.current.instance.option("formData"),
      filter: filter,
    };

    handleClose();

    AuditsAPI.create(formData).then((res) => {
      enqueueSnackbar("Audit created successfully", {
        variant: "success",
      });
      onSubmit();
    });
  };

  const results = useQueries({
    queries: [
      // users
      {
        queryKey: [
          "users:list",
          { includeObservers: false, includeInactive: false },
        ],
        queryFn: () =>
          UserApi.getUsers({ includeObservers: false, includeInactive: false }),
      },
      //offices,
      {
        queryKey: ["offices:list"],
        queryFn: () => OfficesApi.getOffices(),
      },
      //locations
      {
        queryKey: ["locations:list"],
        queryFn: () => LocationsAPI.getLocations(),
      },
    ],
  });

  const isDone = results.every((r) => r.isFetched);

  if (!isDone) return null;

  const users = results[0].data;

  return (
    <>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        style={{ zIndex: 1400 }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 550,
            maxHeight: "90vh",
            backgroundColor: theme.palette.background.default,
            position: "fixed",
            left: "calc(50% - 275px)",
            top: 20,
            overflowY: "auto",
            padding: 20,
            outline: "none",
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Create Audit
          </div>
          <CollapseSection title="Audit Details" visible={true}>
            <Form ref={form} colCount={2}>
              <SimpleItem
                dataField="name"
                label={{ text: "Audit Name" }}
                isRequired={true}
              />
              <SimpleItem
                dataField="assigned_to_id"
                label={{ text: "Assignee" }}
                editorType={"dxSelectBox"}
                editorOptions={{
                  items: users,
                  displayExpr: "full_name",
                  valueExpr: "user_id",
                }}
              />
              {/* Start Date */}
              <SimpleItem
                dataField="start_date"
                label={{ text: "Start Date" }}
                editorType={"dxDateBox"}
                editorOptions={{
                  displayFormat: getDateFormat({
                    isMoment: false,
                    includeTime: false,
                  }),
                  type: "date",
                  onValueChanged: (e: any) => {
                    form.current.instance
                      .getEditor("due_date")
                      .option("min", e.value);
                  },
                }}
              />
              {/* Due Date */}
              <SimpleItem
                dataField={"due_date"}
                label={{ text: "Due Date" }}
                editorType={"dxDateBox"}
                editorOptions={{
                  displayFormat: getDateFormat({
                    isMoment: false,
                    includeTime: false,
                  }),
                  type: "date",
                  onValueChanged: (e: any) => {
                    form.current.instance
                      .getEditor("start_date")
                      .option("max", e.value);
                  },
                }}
              />
              <SimpleItem
                dataField="item_type"
                label={{ text: "Item Type" }}
                editorType={"dxSelectBox"}
                isRequired={true}
                editorOptions={{
                  items: [
                    { text: "Evidence", value: "evidence" },
                    { text: "Storage", value: "storage" },
                  ],
                  displayExpr: "text",
                  valueExpr: "value",
                  showDropDownButton: true,
                  multiline: false,
                  onValueChanged: (e: any) => {
                    setItemSelection(e.value);
                  },
                }}
              />
              <EmptyItem />
              <SimpleItem
                dataField={"description"}
                label={{ text: "Description" }}
                editorType={"dxTextArea"}
                editorOptions={{
                  height: 100,
                }}
                colSpan={2}
              />
            </Form>
          </CollapseSection>
          {itemSelection && (
            <CollapseSection title="Filter" visible={true}>
              {itemSelection === "evidence" && (
                <EvidenceFilterSection onFilter={setFilter} />
              )}
              {itemSelection === "storage" && (
                <StorageFilterSection onFilter={setFilter} />
              )}
            </CollapseSection>
          )}
          <div style={{ marginTop: 20 }}>
            <ToolBarItems
              submitText="Create Audit"
              onSubmit={handleSubmit}
              onCancel={handleClose}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

const AuditsPageContent = styled(({ className }) => {
  const { hasPermission } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [query, setQuery] = useState({ pageSize, page: 1 });

  const queryClient = useQueryClient();
  const theme: any = useTheme();

  const table = useTable();

  const [columnState, setColumnState] = useState(() => {
    return synchronizeColumnState(
      columnDefs,
      JSON.parse(localStorage.getItem(stateStoreKey) || "{}")
    );
  });

  const [searchText, setSearchText] = useState(
    localStorage.getItem(`${stateStoreKey}:searchText`)
  );
  const [debouncedSearchText] = useDebounce(searchText, 500);

  const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ["audits:list", query],
      queryFn: ({ pageParam }) => AuditsAPI.get({ ...query, page: pageParam }),
      getNextPageParam: (lastPage) => {
        return lastPage.nextPage;
      },
      getPreviousPageParam: (firstPage) => {
        if (firstPage.page - 1 === 0) return null;
        return firstPage.page - 1;
      },
      initialPageParam: 1,
      enabled: !!query,
      placeholderData: (data) => data,
    });

  const records = data?.pages?.reduce((acc, page) => {
    return [...acc, ...(page.data || [])];
  }, []);

  const totalRecords = data?.pages?.[0]?.total || 0;

  const debouncedFetchNextPage = useDebouncedCallback(() => {
    fetchNextPage();
  }, 50);

  // Detect scroll to bottom
  const handleScroll = (e: React.MouseEvent) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    const pageLength = data?.pages?.length ? data?.pages?.length : 0;
    if (scrollHeight - scrollTop <= clientHeight + 100 * pageLength) {
      if (hasNextPage && !isFetchingNextPage) {
        debouncedFetchNextPage();
      }
    }
  };

  const handleReload = () => {
    queryClient.refetchQueries({ queryKey: [stateStoreKey, query] });
  };

  const onAuditCreate = () => handleReload();

  const handleColumnVisibility = (
    column: ColumnProps<Audit>,
    visible: boolean
  ) => {
    setColumnState((cs) => {
      return cs.map((c) => {
        if (c.dataField === column.dataField) {
          return {
            ...c,
            visible,
          };
        }
        return c;
      });
    });
  };

  const handleExportTable = async () => {
    try {
      enqueueSnackbar("Exporting table...", {
        variant: "info",
      });

      const { signedUrl, filename } = await AuditsAPI.exportList({
        query,
        type: "xlsx",
        columns: columnState
          .filter((c) => c.visible !== false)
          .sort((a, b) => a.order - b.order)
          .map((c) => {
            return { dataField: c.dataField, header: c.caption, ...c };
          }),
        date_format: getDateFormat({ isMoment: true, includeTime: true }),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });

      const el = document.createElement("a");
      el.href = signedUrl?.replace(
        "http://localhost:3000",
        "http://localhost:3001"
      );
      el.download = filename;
      el.click();
    } catch {
      enqueueSnackbar("Error exporting table", {
        variant: "error",
      });
    }
  };

  // Persist column state to local storage
  useEffect(() => {
    const oldStateStorageValue = localStorage.getItem(stateStoreKey);
    let oldState = oldStateStorageValue ? JSON.parse(oldStateStorageValue) : {};
    localStorage.setItem(
      stateStoreKey,
      JSON.stringify({
        ...oldState,
        cols: columnState,
      })
    );
  }, [columnState]);

  useEffect(() => {
    setQuery((q) => ({
      ...q,
      search: debouncedSearchText ? debouncedSearchText : null,
      page: 1,
    }));
  }, [debouncedSearchText]);

  return (
    <div className={className}>
      <Helmet title="Audits" />
      <AuditSectionHeader totalRecords={totalRecords} />
      <CreateModal
        open={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
        onSubmit={onAuditCreate}
      />
      <div
        style={{
          display: "flex",
          flex: "initial",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          marginBottom: 10,
          marginTop: 10,
        }}
      >
        <Button
          size="small"
          variant="contained"
          color="primary"
          disabled={!hasPermission(MONOLITH_PERMISSIONS.AUDITS_CREATE)}
          onClick={() => setShowCreateModal(true)}
          style={{
            minWidth: "fit-content",
            fontSize: 11,
            padding: "3px 6px",
          }}
        >
          + New Audit
        </Button>
        <ItemTotal total={totalRecords || 0} Icon={ClipboardListIcon} />
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            minWidth: "fit-content",
          }}
        >
          <ComboButton
            type="multi-select"
            data={columnState.filter((c) => c.showInColumnChooser !== false)}
            displayField="caption"
            idField={"dataField"}
            selectedItems={columnState.filter((c) => c.visible !== false)}
            variant="outlined"
            closeOnSelect={false}
            showSearch={true}
            dropDownTitle={() => {
              return (
                <div
                  style={{
                    margin: "5px 0px",
                    padding: 3,
                    color: theme.palette.text.secondary,
                    display: "flex",
                    alignItems: "center",
                    minWidth: 200,
                  }}
                >
                  Select Columns
                </div>
              );
            }}
            onItemDeSelect={(item) => {
              handleColumnVisibility(item, false);
            }}
            onItemSelect={(item) => {
              handleColumnVisibility(item, true);
            }}
            textColor={theme.palette.text.secondary}
            title={"Select Columns"}
          >
            <ViewColumnOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            textColor={theme.palette.text.secondary}
            title={"Export Table"}
            onClick={handleExportTable}
          >
            <FileDownloadOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            textColor={theme.palette.text.secondary}
            title={table.isCompact ? "Zoom In" : "Zoom Out"}
            onClick={() => table.toggleCompact()}
          >
            {table.isCompact && (
              <ZoomOutMapOutlinedIcon style={{ fontSize: 18 }} />
            )}
            {!table.isCompact && (
              <ZoomInMapOutlinedIcon style={{ fontSize: 18 }} />
            )}
          </ComboButton>
          <ComboButton
            type="button"
            variant={"outlined"}
            textColor={
              table.isStriped
                ? theme.palette.primary.main
                : theme.palette.text.secondary
            }
            title={table.isStriped ? "Hide Stripes" : "Show Stripes"}
            onClick={() => table.toggleStripes()}
          >
            <ReorderOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          {/* SEARCH */}
          <Input
            placeholder="Search Audits"
            variant="outlined"
            height={30}
            defaultValue={searchText}
            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
              const currentValue = e.currentTarget.value.trim();
              setSearchText(currentValue);
              localStorage.setItem(`${stateStoreKey}:searchText`, currentValue);
            }}
            style={{
              marginLeft: "10px",
              border: searchText
                ? `1px solid ${theme.palette.primary.main}`
                : "",
            }}
          />
        </div>
      </div>
      {data && (
        <>
          <Table
            data={records}
            totalRecords={totalRecords}
            keyValue="id"
            tableInstance={table}
            onScroll={handleScroll}
          >
            {columnState.map((col) => {
              return <Column key={col.dataField} {...col} />;
            })}
          </Table>
        </>
      )}
    </div>
  );
})`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const AuditsPage = () => {
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();

  if (!hasPermission(MONOLITH_PERMISSIONS.AUDITS_READ)) {
    return <AccessDeniedMessage />;
  }

  return <AuditsPageContent />;
};

export default AuditsPage;
