import { Clock } from "lucide-react";
import TaskDurationOptions from "./TaskDurationOptions.js";
import DropdownMenu from "../../../Monolith-UI/DropdownMenu/DropdownMenu.js";

const TaskDurationSelector = ({
  value,
  onSelect,
  showLabel = true,
  variant = "contained",
  buttonProps = {},
}) => {
  const menuItems = TaskDurationOptions.map((option) => ({
    label: option.label,
    value: option.duration,
    icon: option.icon,
    onClick: () => {
      onSelect?.(option);
    },
  }));

  const duration = !value
    ? "Time"
    : (parseInt(value, 10) / 60 / 60).toFixed(2) + " hrs";

  return (
    <DropdownMenu
      menuItems={menuItems}
      variant={variant}
      buttonProps={{
        textColor: !!value ? "primary" : "secondary",
        ...buttonProps,
      }}
    >
      <div
        style={{ display: "flex", alignItems: "center" }}
        title={"Add time spent on this task"}
      >
        <Clock size={12} style={{ marginRight: showLabel ? 5 : 0 }} />
        {showLabel && <span style={{ fontSize: "0.75rem" }}>{duration}</span>}
      </div>
    </DropdownMenu>
  );
};

export default TaskDurationSelector;
