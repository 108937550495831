// Replace search term with highlited span element
const highlightText = (html, targetText) => {
  const regex = new RegExp(targetText, "gi");
  const match = html.match(regex);
  if (!match) return html;

  const highlightedText = html.replace(
    regex,
    `<span class="highlight">${match[0]}</span>`
  );
  return highlightedText;
};

export default highlightText;
