import { httpDelete, httpGet, httpPatch, httpPost } from "../connection";

const getNotificationSettings = async (query = {}) => {
  return await httpGet(`/notification-settings`, query);
};

const updateNotificationSettings = async (values = {}) => {
  return await httpPatch(`/notification-settings`, values);
};

const createNotificationSettings = async (values = {}) => {
  return await httpPost(`/notification-settings`, values);
};

const deleteNotificationSettings = async (values = {}) => {
  return await httpDelete(`/notification-settings`, values);
};

const NotificationSettingsService = {
  getNotificationSettings,
  updateNotificationSettings,
  createNotificationSettings,
  deleteNotificationSettings,
};

export default NotificationSettingsService;
