import styled from "styled-components";

const ClientSelectBoxItem = styled(({ className, data }) => {
  return (
    <div className={className}>
      {data.client_id === "new" ? (
        <div className="new-client">+ Create New Client</div>
      ) : (
        <>
          <div className="client-name">{data.name}</div>
          <div className="client-info">
            <div>
              {data.email ? data.email : ""}
              {data.email && data.contact_type ? " | " : ""}
              {data.contact_type ? data.contact_type : ""}
            </div>
          </div>
        </>
      )}
    </div>
  );
})`
  .new-client {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: bold;
  }
  .client-name {
    margin-bottom: 5px;
  }
  .client-info {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

export default ClientSelectBoxItem;
