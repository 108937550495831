import { getDateFormat } from "../../utils/date-format";
import CaseNumberCell from "./CaseNumberCell.js";
import EvidenceNumberCell from "./EvidenceNumberCell.js";
import ProgressSelector from "./ProgressSelector.js";

let TableColumnDefs = [
  {
    dataField: "evidence_number",
    caption: "Evidence Number",
    visible: true,
    render: (rowData) => {
      return <EvidenceNumberCell rowData={rowData} />;
    },
  },
  {
    dataField: "uuid",
    caption: "UUID",
    visible: false,
  },
  {
    dataField: "case_number",
    caption: "Case Number",
    visible: true,
    render: (rowData) => {
      return <CaseNumberCell rowData={rowData} />;
    },
  },
  {
    dataField: "progress",
    caption: "Progress",
    render: (rowData, options = {}) => {
      if (options.enabled === false) return rowData.progress;
      return (
        <ProgressSelector
          rowData={rowData}
          onChange={(data) => {
            options?.onRowUpdated?.(data);
          }}
        />
      );
    },
  },
  {
    dataField: "model_name",
    caption: "Item Name",
  },
  {
    dataField: "case_ref",
    caption: "Case Name",
    visible: false,
  },
  {
    dataField: "created_on",
    caption: "Created On",
    dataType: "date",
    format: {
      type: getDateFormat({ isMoment: true, includeTime: false }),
    },
  },
  {
    dataField: "intake_date",
    caption: "Intake Date",
    dataType: "date",
    format: {
      type: getDateFormat({ isMoment: true, includeTime: false }),
    },
  },
  {
    dataField: "last_audit_date",
    caption: "Last Audit Date",
    dataType: "date",
    format: {
      type: getDateFormat({ isMoment: true, includeTime: false }),
    },
    visible: false,
  },
  {
    dataField: "location_received",
    caption: "Location Received",
    visible: false,
  },
  {
    dataField: "item_type",
    caption: "Type",
  },
  {
    dataField: "manufacturer",
    caption: "Provider",
  },
  {
    dataField: "model_number",
    caption: "Model Number",
    visible: false,
  },
  {
    dataField: "serial_number",
    caption: "Unique ID",
    visible: false,
  },
  {
    dataField: "location_name",
    caption: "Current Location",
    visible: false,
  },
  {
    dataField: "location_path",
    caption: "Location Path",
    visible: false,
  },
  {
    dataField: "capacity_bytes",
    caption: "Size",
    visible: false,
    render: (rowData) =>
      rowData.capacity
        ? `${rowData.capacity} ${rowData.capacity_unit || ""}`
        : "",
  },
  {
    dataField: "capacity_gb",
    caption: "Size (GB)",
    visible: false,
    render: (rowData) => {
      if (rowData.capacity_gb === null || rowData.capacity_gb === undefined)
        return "";
      return rowData.capacity_gb + " GB";
    },
  },
  {
    dataField: "parent_name",
    caption: "Parent Evidence",
    visible: false,
    render: (rowData) => {
      return (
        <>
          {rowData.is_parent !== 1 ? (
            <EvidenceNumberCell
              rowData={{
                uuid: rowData.parent_uuid,
                evidence_number: rowData.parent_name,
                is_parent: 1,
              }}
            />
          ) : (
            "N/A"
          )}
        </>
      );
    },
  },
  {
    dataField: "received_by",
    caption: "Received By",
    visible: false,
  },
  {
    dataField: "received_from",
    caption: "Received From",
    visible: false,
  },
  {
    dataField: "client_name",
    caption: "Client Name",
    visible: false,
  },
  {
    dataField: "client_organization",
    caption: "Organization",
    visible: false,
  },
  {
    dataField: "office_name",
    caption: "Office",
    visible: false,
  },
  {
    dataField: "contact_name",
    caption: "Linked Contact",
    visible: false,
  },
  {
    dataField: "is_parent_child",
    caption: "Parent/Child",
    visible: false,
  },
  {
    dataField: "has_children",
    caption: "Has Children",
    visible: false,
  },
  {
    dataField: "child_count",
    caption: "Child Count",
    visible: false,
  },
  {
    dataField: "is_acquired",
    caption: "Is Acquired",
    visible: false,
  },
  {
    dataField: "acquisition_count",
    caption: "Acquisition Count",
    visible: false,
  },
  {
    dataField: "has_photos",
    caption: "Is Photographed",
    visible: false,
  },
  {
    dataField: "photo_count",
    caption: "Photo Count",
    visible: false,
  },
  {
    dataField: "description",
    caption: "Description",
    titleRender: (rowData) => rowData.description,
    visible: false,
  },
];

// export a copy of the array so that we can modify it without affecting the original
export default TableColumnDefs.slice();
