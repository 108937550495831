import { useState } from "react";
import { Modal } from "@monolith-forensics/monolith-ui";
import Loader from "../Loader.js";

import DefaultTimelineEventForm from "../Forms/DefaultTimelineEventForm.js";
import { AnalysisAPI } from "../../api/index.js";
import { useMutation } from "@tanstack/react-query";
import LoggingAPI from "../../api/logging/index.js";
import { usePermissions } from "../../hooks/usePermissions";

const EditTimelineEventModal = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  defaultFormData,
  ...props
}) => {
  const { currentUser } = usePermissions();
  const [submitting, setSubmitting] = useState(false);

  const updateTimelineEvent = useMutation({
    mutationFn: AnalysisAPI.TimelineEvents.update,
    onMutate: () => setSubmitting(true),
    onSuccess: (_, variables) => {
      LoggingAPI.createLog({
        case_id: defaultFormData.case_id,
        user_id: currentUser.user_id,
        message: `Updated timeline event.`,
        event: "update_timeline_event",
        object_type: "timeline_event",
        object_uuid: defaultFormData.uuid,
      });
      setSubmitting(false);
      onSubmit?.(variables);
    },
  });

  return (
    <Modal open={show} onClose={onClose} style={{ width: 600 }}>
      <Modal.Title>Edit Timeline Event</Modal.Title>
      {!submitting && (
        <DefaultTimelineEventForm
          onSubmit={(data, diff) =>
            updateTimelineEvent.mutate({ uuid: data.uuid, ...diff })
          }
          onCancel={onCancel}
          defaultFormData={defaultFormData}
          {...props}
        />
      )}
      {submitting && <Loader />}
    </Modal>
  );
};

export default EditTimelineEventModal;
