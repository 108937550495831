import { useEffect, useState } from "react";

// Material-UI components, hooks and icons
import { Box, Button, Switch, useTheme } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

// Drag and drop functionality
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Utility functions and custom hooks
import { calculateNewSortValue } from "./utils/calcNewValue";
import { usePermissions } from "../../../hooks/usePermissions";

// Custom components
import Loader from "../../../components/Loader";
import CreateCustomField from "./components/CreateCustomField";
import EditCustomField from "./components/EditCustomField";
import DeleteCustomField from "./components/DeleteCustomField";
import { useQuery, useQueryClient } from "@tanstack/react-query";

// Type constants for custom fields description
const EditorTypes = {
  textBox: "Text Box",
  dropDown: "Drop Down Menu",
  dateBox: "Date",
  tagBox: "Tag Box",
};

const CustomFieldsTemplate = ({
  category,
  handleCreateField,
  handleUpdateField,
  handleDeleteField,
  getFields,
  getTypes,
  description,
}) => {
  const [customFields, setCustomFields] = useState(null);
  const [deleteSelection, setDeleteSelection] = useState(null);
  const [editSelection, setEditSelection] = useState(null);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { data, isLoading } = useQuery({
    queryKey: ["custom-fields", `${category}`],
    queryFn: () => getFields({ fieldsOnly: true }),
  });

  useEffect(() => {
    if (!isLoading) {
      const fields = data
        ?.map((r) => {
          return { ...r, options: JSON.parse(r.options || "[]") };
        })
        .sort((a, b) => a.sort_value - b.sort_value);

      setCustomFields(fields);
    }
  }, [isLoading]);

  const handleDelete = () => {
    setShowDeleteModal(false);
    handleDeleteField(deleteSelection.field_id);
    setCustomFields(
      customFields.filter(
        (field) => field.field_id !== deleteSelection.field_id
      )
    );
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // If there is no destination, do nothing
    if (!destination) {
      return;
    }

    // If user puts back in original place, do nothing
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newFields = Array.from(customFields);

    const [removed] = newFields.splice(source.index, 1);
    newFields.splice(destination.index, 0, removed);

    const newSortValue = calculateNewSortValue(newFields, destination);
    newFields[destination.index].sort_value = newSortValue;
    setCustomFields(newFields);
    handleUpdateField(newFields[destination.index].field_id, {
      sort_value: newSortValue,
    });
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        {!customFields && <Loader />}
        {customFields && (
          <>
            <div>{description}</div>
            <div style={{ margin: "25px 0px" }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={(e) => {
                  setShowCreateModal(true);
                }}
              >
                + Create Field
              </Button>
            </div>
          </>
        )}
        <Droppable droppableId="droppable-1">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {customFields &&
                customFields.map((field, index) => {
                  return (
                    <CustomField
                      key={field.field_id}
                      data={field}
                      index={index}
                      customFields={customFields}
                      setCustomFields={setCustomFields}
                      setDeleteSelection={setDeleteSelection}
                      setShowDeleteModal={setShowDeleteModal}
                      setEditSelection={setEditSelection}
                      setShowEditModal={setShowEditModal}
                      handleUpdateField={handleUpdateField}
                    />
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        {customFields && (
          <>
            <CreateCustomField
              handleOpen={showCreateModal}
              handleClose={() => setShowCreateModal(false)}
              category={category}
              customFields={customFields}
              setCustomFields={setCustomFields}
              getTypes={getTypes}
              handleCreateField={handleCreateField}
            />
            <EditCustomField
              handleOpen={showEditModal}
              category={category}
              title={`Edit ${category} Field "${
                editSelection?.field_name || ""
              }"`}
              customFields={customFields}
              setCustomFields={setCustomFields}
              getTypes={getTypes}
              handleUpdateField={handleUpdateField}
              editSelection={editSelection}
              handleClose={() => setShowEditModal(false)}
            />
            <DeleteCustomField
              handleOpen={showDeleteModal}
              handleClose={() => setShowDeleteModal(false)}
              title={`Delete ${category} Field "${
                deleteSelection?.field_name || ""
              }"`}
              handleSubmit={handleDelete}
            />
          </>
        )}
      </DragDropContext>
    </>
  );
};

const CustomField = ({
  data,
  index,
  customFields,
  setCustomFields,
  setDeleteSelection,
  setEditSelection,
  setShowEditModal,
  setShowDeleteModal,
  handleUpdateField,
}) => {
  const theme = useTheme();
  const { hasPermission } = usePermissions();
  const queryClient = useQueryClient();

  const handleUpdate = (event) => {
    if (event.currentTarget.innerText === "") {
      event.currentTarget.innerText = data.field_name;
      return;
    }
    if (event.currentTarget.innerText !== data.field_name) {
      handleUpdateField(data.field_id, {
        field_name: event.currentTarget.innerText,
      });
      setCustomFields(
        customFields.map((type) => {
          if (type.field_id === data.field_id) {
            type.field_name = event.currentTarget.innerText;
          }
          return type;
        })
      );
    }
  };

  const handleDelete = (event) => {
    setDeleteSelection(data);
    setShowDeleteModal(true);
  };

  const handleEdit = (event) => {
    if (data.category === "inquiry") {
      queryClient.invalidateQueries({ queryKey: ["custom-fields", "case"] });
    }
    setEditSelection(data);
    setShowEditModal(true);
  };

  const handleEnableUpdate = (e) => {
    // Switch enabled value on change
    handleUpdateField(e.field_id, {
      enabled: e.enabled === 1 ? 1 : 0,
    });
  };

  return (
    <Draggable draggableId={String(data.sort_value)} index={index}>
      {(provided) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "10px 10px",
            cursor: "pointer",
            backgroundColor: theme.palette.background.default,
            "&:hover": { backgroundColor: theme.palette.action.hover },
          }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <DragIndicatorIcon
            fontSize="small"
            sx={{ marginRight: "10px", cursor: "move" }}
          />
          <div>
            <Box
              suppressContentEditableWarning
              sx={{
                fontSize: "larger",
                padding: "2px",
              }}
              onBlur={handleUpdate}
            >
              {data.field_name}
            </Box>
            <div style={{ color: "slategrey", padding: "2px" }}>
              Required: {data.is_required === 1 ? "Yes" : "No"} | Editor:{" "}
              {EditorTypes[data.editor_type]}
            </div>
            <div
              title={data.description}
              style={{
                color: "slategrey",
                padding: "2px",
                whiteSpace: "nowrap",
                overflowX: "clip",
                maxWidth: 400,
                textOverflow: "ellipsis",
              }}
            >
              {data.description || ""}
            </div>
            <div
              style={{
                color: theme.palette.text.secondary,
                fontSize: 10,
                padding: 2,
              }}
            >
              Field ID: {data.field_id}
            </div>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <div
              style={{
                fontSize: 10,
                color: theme.palette.text.secondary,
                margin: "3px 5px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              Enabled
              <Switch
                size="small"
                defaultChecked={data.enabled}
                onChange={(e) => {
                  handleEnableUpdate({
                    ...data,
                    enabled: e.target.checked ? 1 : 0,
                  });
                }}
              />
              <Button
                variant="text"
                color="primary"
                size="small"
                disabled={!hasPermission()}
                onClick={handleEdit}
              >
                Edit
              </Button>
              <Button
                variant="text"
                color="error"
                size="small"
                disabled={!hasPermission()}
                onClick={handleDelete}
              >
                Delete
              </Button>
            </div>
          </div>
        </Box>
      )}
    </Draggable>
  );
};

export default CustomFieldsTemplate;
