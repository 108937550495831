import { httpDelete, httpGet, httpPatch, httpPost } from "../connection";

async function getTasks(values = {}) {
  return await httpGet(`/tasks/v2`, values);
}

async function exportList(values = {}) {
  return await httpPost(`/tasks/v2/export`, values);
}

async function createTask(values = {}) {
  return await httpPost(`/tasks/v2`, values);
}

async function updateTask(values = {}) {
  return await httpPatch(`/tasks/v2/${values.uuid}`, values);
}

async function deleteTask(values = {}) {
  return await httpDelete(`/tasks/v2/${values.task_id}`, values);
}

async function getStatusItems() {
  return await httpGet("/tasks/v2/status-items");
}

async function assignUsersToTask(values = {}) {
  return await httpPost(`/tasks/v2/${values.task_id}/assign-users`, values);
}

async function removeUsersFromTask(values = {}) {
  return await httpPost(`/tasks/v2/${values.task_id}/remove-users`, values);
}

async function archiveTasks(values = {}) {
  return await httpPost(`/tasks/v2/archive`, values);
}

async function getTaskTemplates(options = {}) {
  return await httpGet(
    `/tasks/v2/templates${
      options?.template_id ? "/" + options.template_id : ""
    }`,
    options
  );
}

async function createTaskTemplate(values) {
  return await httpPost(`/tasks/v2/templates`, values);
}

async function deleteTaskTemplate(template_id) {
  return await httpDelete(`/tasks/v2/templates/${template_id}`);
}

async function updateTaskTemplate(template_id, values = {}) {
  return await httpPatch(`/tasks/v2/templates/${template_id}`, values);
}

const TasksAPI = {
  getTasks,
  exportList,
  createTask,
  updateTask,
  deleteTask,
  getStatusItems,
  assignUsersToTask,
  removeUsersFromTask,
  archiveTasks,
  getTaskTemplates,
  createTaskTemplate,
  deleteTaskTemplate,
  updateTaskTemplate,
};

export default TasksAPI;
