import { usePermissions } from "../../hooks/usePermissions";
import TasksAPI from "../../api/tasks/tasks.js";
import { getDateFormat } from "../../utils/date-format";
import { Switch } from "@monolith-forensics/monolith-ui";

const ArchiveCell = ({ rowData, onChange = () => {} }) => {
  const { hasPermission } = usePermissions();

  const handleItemSelect = (value) => {
    TasksAPI.updateTask({
      uuid: rowData.uuid,
      archived: value,
    });

    onChange({
      archived: value,
    });
  };

  return (
    <div>
      <Switch
        defaultValue={rowData?.archived}
        onChange={handleItemSelect}
        disabled={!hasPermission()}
        size="xs"
      />
    </div>
  );
};

const TaskDefs = [
  {
    dataField: "uuid",
    caption: "UUID",
    visible: false,
  },
  {
    dataField: "archived",
    caption: "Archive Status",
    render: (rowData, options) => {
      return <ArchiveCell rowData={rowData} onChange={options?.onRowUpdated} />;
    },
    visible: true,
  },
  {
    dataField: "task_name",
    caption: "Task Name",
  },
  {
    dataField: "assignees",
    caption: "Assignees",
    render: (rowData) => rowData?.assignees?.map((a) => a.full_name).join(", "),
    visible: false,
  },
  {
    dataField: "created_on",
    caption: "Created On",
    dataType: "date",
    format: {
      type: getDateFormat({ isMoment: true, includeTime: false }),
    },
    visible: false,
  },
  {
    dataField: "created_by",
    caption: "Created By",
    render: (rowData) => rowData?.created_by?.full_name || null,
    visible: false,
  },
  {
    dataField: "due_date",
    caption: "Due Date",
    dataType: "date",
    format: {
      type: getDateFormat({ isMoment: true, includeTime: false }),
    },
  },
  {
    dataField: "status",
    caption: "Status",
  },
  {
    dataField: "priority_name",
    caption: "Priority",
  },
  {
    dataField: "case_number",
    caption: "Case Number",
    visible: false,
  },
  {
    dataField: "case_ref",
    caption: "Case Name",
    visible: false,
  },
  {
    dataField: "time_category",
    caption: "Category",
    visible: false,
  },
  {
    dataField: "duration",
    caption: "Time Spent",
    render: (rowData) => {
      return ((rowData.duration || 0) / 3600).toFixed(2) + " hours";
    },
    visible: false,
  },
];

export default TaskDefs;
