import Accounts from "./Accounts";
import Artifacts from "./Artifacts";
import EventTypes from "./EventTypes";
import Logs from "./Logs";
import TimelineEvents from "./TimelineEvents";
import TraceAttributes from "./TraceAttributes";
import TraceIndicators from "./TraceIndicators";

const AnalysisAPI = {
  TimelineEvents,
  TraceIndicators,
  TraceAttributes,
  EventTypes,
  Artifacts,
  Accounts,
  Logs,
};

export default AnalysisAPI;
