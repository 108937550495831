import { FC } from "react";
import DefaultSoftwareForm, { SoftwareFormData } from "./DefaultSoftwareForm";
import ForensicSoftwareAPI from "@/api/software";
import { FormWrapper, FormTitle } from "./components/MonolithFormComponents";
import { usePermissions } from "@/hooks/usePermissions";
import { useSnackbar, VariantType } from "notistack";
import LoggingAPI from "@/api/logging";

interface CreateSoftwareFormProps {
  width?: number | undefined;
  onSubmit: () => void;
  onCancel: () => void;
}

const CreateSoftwareForm: FC<CreateSoftwareFormProps> = ({
  width,
  onSubmit,
  onCancel,
}) => {
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (data: SoftwareFormData) => {
    try {
      await ForensicSoftwareAPI.createSoftwareItem(data);
      onSubmit?.();
      enqueueSnackbar(`Software item ${data?.name || ""} created`, {
        variant: "success" as VariantType,
      });

      LoggingAPI.createLog({
        user_id: currentUser.user_id,
        message: `Created Software Item: ${data?.name}`,
        event: "software:create",
        object_type: "forensic_software",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        "An error occurred while creating this item. Please try again.",
        { variant: "error" as VariantType }
      );
    }
  };

  return (
    <FormWrapper width={width}>
      <FormTitle>Create Software Item</FormTitle>
      <DefaultSoftwareForm
        onSubmit={handleSubmit}
        onCancel={onCancel}
        buttonProps={{ submitText: "Add Software" }}
      />
    </FormWrapper>
  );
};
export default CreateSoftwareForm;
