import React from "react";
import styled from "styled-components";
import { resolveCSSDimensions } from "../utils/resolveCSSDimensions";

interface FormWrapperProps {
  width?: number | string;
  children: React.ReactNode;
}

const StyledWrapper = styled.div<FormWrapperProps>`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: ${({ width }) => resolveCSSDimensions(width)};
`;

export const FormWrapper: React.FC<FormWrapperProps> = ({
  width,
  children,
}) => {
  return <StyledWrapper width={width}>{children}</StyledWrapper>;
};

export const FormTitle = styled.h2`
  margin: 0;
`;
