import readFileAsBase64 from "../../utils/readFileAsBase64";
import { httpDelete, httpGet, httpPatch, httpPost } from "../connection";

export async function getInquiries(values = {}) {
  return (await httpGet(`/inquiries`, values)).data;
}

async function query(values) {
  return await httpPost(`/inquiries/query`, values);
}

async function exporInquiriesList(values) {
  return await httpPost(`/inquiries/export`, values);
}

export async function createInquiry(values) {
  return await httpPost(`/inquiries`, values);
}

export async function updateInquiry(inquiry_id, values) {
  return await httpPatch(`/inquiries/${inquiry_id}`, values);
}

export async function deleteInquiry(inquiry_id) {
  return await httpDelete(`/inquiries/${inquiry_id}`);
}

export async function convertInquiry(inquiry_id, values) {
  return await httpPost(`/inquiries/${inquiry_id}/convert`, values);
}

export async function mergeInquiry(inquiry_id, values) {
  return await httpPost(`/inquiries/${inquiry_id}/merge`, values);
}

export async function getInquiryClientTypes() {
  return await httpGet(`/inquiries/client-types`);
}

export async function createInquiryQuote(values) {
  return await httpPost(`/inquiries/${values.inquiry_id}/quotes`, values);
}

export async function getInquiryQuotes(inquiry_id) {
  return await httpGet(`/inquiries/${inquiry_id}/quotes`);
}

export async function getInquiryStatuses() {
  return await httpGet(`/inquiries/status`);
}

export async function getInquiryDocs(values) {
  if (typeof values.file_id == "undefined")
    return await httpGet(`/inquiries/${values.inquiry_id}/docs`);
  else
    return await httpGet(
      `/inquiries/${values.inquiry_id}/docs/${values.file_id}`
    );
}

export async function uploadInquiryDocs(values = {}) {
  const requests = [];

  for (const file of values.files) {
    let buffer = await readFileAsBase64(file);

    requests.push(
      httpPost(`/inquiries/${values.inquiry_id}/docs`, {
        inquiry_id: values.inquiry_id,
        file: {
          filename: file.name,
          ext: file.name.split(".")[1],
          size: file.size,
          data: buffer,
        },
      })
    );
  }

  return await Promise.all(requests);
}

export async function createInquiryDoc(values) {
  return await httpPost(`/inquiries/${values.inquiry_id}/docs`, values);
}

export async function deleteInquiryDoc(values = {}) {
  if (!values.inquiry_id && !values.doc_id) return;
  return await httpDelete(`/inquiries/${values.inquiry_id}/docs`, values);
}

export async function createInquiryEvidence(id, values = {}) {
  return await httpPost(`/inquiries/${id}/evidence`, values);
}

export async function updateInquiryEvidence(id, values = {}) {
  return await httpPatch(`/inquiries/${id}/evidence`, values);
}

export async function deleteInquiryEvidence(id, values = {}) {
  return await httpDelete(`/inquiries/${id}/evidence`, values);
}

export async function createInquiryPeople(id, values = {}) {
  return await httpPost(`/inquiries/${id}/people`, values);
}

export async function updateInquiryPeople(id, values = {}) {
  return await httpPatch(`/inquiries/${id}/people`, values);
}

export async function deleteInquiryPeople(id, values = {}) {
  return await httpDelete(`/inquiries/${id}/people`, values);
}

export async function getCustomInquiryAttributes(
  options = { fieldsOnly: false }
) {
  return httpGet(`/inquiries/attributes?fieldsOnly=${options.fieldsOnly}`);
}

export async function createAttributeField(
  values = { field_name: null, is_required: null, type_id: null }
) {
  return await httpPost(`/inquiries/attributes/field`, values);
}

export async function updateAttributeField(id, values = {}) {
  return await httpPost(`/inquiries/attributes/field/${id}`, values);
}

export async function deleteAttributeField(id) {
  return httpDelete(`/inquiries/attributes/field/${id}`);
}

async function getInquiryOrgs() {
  return await httpGet(`/inquiries/organizations`);
}

const InquiriesAPI = {
  getInquiries,
  query,
  exporInquiriesList,
  createInquiry,
  deleteInquiry,
  updateInquiry,
  getCustomFields: getCustomInquiryAttributes,
  getInquiryStatuses,
  updateInquiryEvidence,
  deleteInquiryEvidence,
  createInquiryEvidence,
  getInquiryDocs,
  uploadInquiryDocs,
  createInquiryDoc,
  deleteInquiryDoc,
  createInquiryPeople,
  deleteInquiryPeople,
  updateInquiryPeople,
  getInquiryClientTypes,
  getInquiryOrgs,
};

export default InquiriesAPI;
