import styled from "styled-components";
import { CaseFileDefs } from "../../../../components/ColumnDefinitions";
import { Pill, TextAreaInput } from "@monolith-forensics/monolith-ui";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import CaseFileAPI from "../../../../api/CaseFiles/index.js";
import LoggingAPI from "../../../../api/logging/index.js";
import { usePermissions } from "../../../../hooks/usePermissions";
import { useDebouncedCallback } from "use-debounce";
import Loader from "../../../../components/Loader.js";

const SharedWithComponent = styled(({ className, item }) => {
  const { currentUser } = usePermissions();
  const queryClient = useQueryClient();
  const { data, refetch } = useQuery({
    queryKey: ["case-files:shared-with", { uuid: item.uuid }],
    queryFn: () => CaseFileAPI.getShareDetails({ uuid: item.uuid }),
  });

  const removeUser = useMutation({
    mutationFn: (email) => CaseFileAPI.unShareItems({ uuid: item.uuid, email }),
    onMutate: (email) => {
      queryClient.setQueryData(
        ["case-files:shared-with", { uuid: item.uuid }],
        (old) => {
          return {
            ...old,
            data:
              old?.data?.filter?.((i) => i.shared_with_email !== email) || [],
          };
        }
      );
      return email;
    },
    onSuccess: (data, email) => {
      refetch();
      LoggingAPI.createLog({
        case_id: item.case_id,
        user_id: currentUser.user_id,
        message: `Unshared ${
          item.is_folder === 1 ? "folder" : "file"
        } with ${email}`,
        event:
          item.is_folder === 1 ? "case_folder:unshare" : "case_file:unshare",
        object_type: item.is_folder === 1 ? "folder" : "file",
        object_uuid: item.uuid,
      });
    },
    onError: (error, variables, context) => {
      console.log("Error", error);
    },
  });

  if (!data) return <Loader />;

  const emails = data?.data?.map((item) => item.shared_with_email) || [];

  return (
    <div className={className}>
      {emails.map((email) => {
        return <Pill onRemove={() => removeUser.mutate(email)}>{email}</Pill>;
      })}
    </div>
  );
})`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

const DetailItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DetailLabel = styled.div`
  font-weight: 500;
  margin-right: 8px;
  min-width: 125px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const DetailValue = styled.div`
  white-space: pre-wrap;
  max-height: 150px;
  overflow-y: auto;
`;

const MainDetails = styled(({ className, defaultData, onChange }) => {
  const { currentUser } = usePermissions();

  const caseFileMutation = useMutation({
    mutationFn: CaseFileAPI.update,
    onMutate: (newData) => {
      return newData;
    },
    onSuccess: (data, variables) => {
      onChange?.();
      LoggingAPI.createLog({
        case_id: defaultData.case_id,
        user_id: currentUser.user_id,
        message: `Updated ${
          defaultData.is_folder === 1 ? "folder" : "file"
        } Description`,
        event:
          defaultData.is_folder === 1
            ? "case_folder:update"
            : "case_file:update",
        object_type: defaultData.is_folder === 1 ? "folder" : "file",
        object_uuid: defaultData.uuid,
      });
    },
  });

  const handleDescriptionChange = useDebouncedCallback((value) => {
    caseFileMutation.mutate({
      uuid: defaultData.uuid,
      description: value,
    });
  }, 300);

  const isFolder = defaultData.is_folder === 1;

  return (
    <div className={className}>
      <h3>{isFolder ? "Case Folder Details" : "Case File Details"}</h3>
      <div className="event-details">
        {CaseFileDefs.filter((col) => col.dataField !== "description").map(
          (col) => {
            const key = col.dataField;
            const label = col.caption;
            const value = col?.render
              ? col.render(defaultData)
              : defaultData[key];

            return (
              <DetailItem key={key}>
                <DetailLabel>{label}</DetailLabel>
                <DetailValue>{value}</DetailValue>
              </DetailItem>
            );
          }
        )}
        <div style={{ margin: "10px 0px" }}>
          <DetailItem style={{ marginBottom: 5 }}>
            <DetailLabel>Shared With</DetailLabel>
          </DetailItem>
          <SharedWithComponent item={defaultData} />
        </div>
        <DetailItem>
          <DetailLabel>Description</DetailLabel>
        </DetailItem>
        <TextAreaInput
          minRows={6}
          maxRows={12}
          defaultValue={defaultData.description || "None"}
          onChange={(e) => handleDescriptionChange(e.target.value)}
          style={{ backgroundColor: "transparent" }}
        />
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;

  h3 {
    margin: 0px;
    margin-bottom: 10px;
  }

  .event-details {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .trace-attributes-list {
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 10px;
  }

  .attribute-type {
    font-weight: 550;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

const FlyoutDetailsTab = styled(({ className, defaultData, onChange }) => {
  return (
    <div className={className}>
      <MainDetails defaultData={defaultData} onChange={onChange} />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;

  padding: 10px 0px;
`;

export default FlyoutDetailsTab;
