import styled from "styled-components";

const NoteListResizer = styled.div`
  position: absolute;
  top: -37px;
  left: -1.5px;
  width: 3px;
  height: calc(100% + 43px);
  background-color: transparent;
  transition: background-color 0.3s ease;

  :hover {
    cursor: col-resize;
    background-color: ${(props) => props.theme.palette.primary.main};
  }
`;

export default NoteListResizer;
