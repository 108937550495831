import styled from "styled-components";
import { monolithMoment } from "../../../utils/date-format";
import DetailItem from "../../DetailItem.js";
import LinkedObjectLabel from "./LinkedObjectLabel.js";
import * as Tooltip from "@radix-ui/react-tooltip";

const labelProps = {
  style: {
    minWidth: 100,
  },
};

const NoteTooltipContent = styled(
  ({
    className,
    children,
    item: noteData,
    tooltipProps = {},
    contentProps = {},
  }) => {
    const isFolder = noteData?.is_folder === 1;
    return (
      <Tooltip.Provider
        delayDuration={600}
        skipDelayDuration={1}
        {...tooltipProps}
      >
        <Tooltip.Root>
          <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content
              className={className + " tooltip-content"}
              side="right"
              sideOffset={5}
              align="start"
              collisionPadding={20}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              onPointerDownOutside={(e) => {
                e.preventDefault();
              }}
              {...contentProps}
            >
              <div className="title">{noteData?.note_tag || "Note Info"}</div>

              <DetailItem
                label="Linked To"
                value={
                  <LinkedObjectLabel
                    color={
                      noteData?.object_type === "case" ? "primary" : "success"
                    }
                    object_type={noteData?.object_type}
                    object_name={noteData?.object_name}
                  />
                }
                labelProps={labelProps}
              />
              <DetailItem
                label="Created By"
                value={noteData?.created_by?.full_name}
                labelProps={labelProps}
              />
              <DetailItem
                label="Created On"
                value={
                  noteData?.created_on
                    ? monolithMoment({
                        timestamp: noteData?.created_on,
                        includeTime: true,
                        includeZone: true,
                      })
                    : "N/A"
                }
              />
              {!isFolder && (
                <DetailItem
                  label="Updated By"
                  value={noteData?.updated_by?.full_name || "N/A"}
                />
              )}
              {!isFolder && (
                <DetailItem
                  label="Updated On"
                  value={
                    noteData?.updated_at
                      ? monolithMoment({
                          timestamp: noteData?.updated_at,
                          includeTime: true,
                          includeZone: true,
                        })
                      : "N/A"
                  }
                />
              )}
              {isFolder && (
                <DetailItem
                  label="Total Notes"
                  value={noteData?.note_count || 0}
                />
              )}
              <DetailItem
                label="Path"
                value={`${noteData?.object_name || ""} \\ ${
                  noteData?.path?.split("\\")?.join(" \\ ") ||
                  noteData?.note_tag
                }`}
              />
              <DetailItem label="Note ID" value={noteData?.uuid || "N/A"} />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    );
  }
)`
  &.tooltip-content {
    display: flex;
    flex-direction: column;
    pointer-events: none;
    justify-content: center;
    align-items: flex-start;
    z-index: 99999;
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: ${({ theme }) => theme.palette.background.default};
    padding: 15px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.palette.divider};
    font-size: 0.75rem;
    max-width: 500px;
    user-select: text;

    .title {
      font-size: 0.85rem;
      font-weight: 600;
      margin-bottom: 15px;
    }
  }
`;

export default NoteTooltipContent;
