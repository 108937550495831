import styled from "styled-components";
import {
  Button,
  DropDownMenu,
  TextInput,
} from "@monolith-forensics/monolith-ui";
import { useEffect, useRef, useState } from "react";
import CreateTraceIndicatorModal from "../../../../../components/Modals/CreateTraceIndicatorModal.js";
import { useAuth } from "../../../../../contexts/AuthContext.js";
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import Table, { Column } from "../../../../../Monolith-UI/Table/Table.js";
import { useDebouncedCallback } from "use-debounce";
import CasesApi from "../../../../../api/cases/index.js";
import { AnalysisAPI } from "../../../../../api/index.js";
import { getDateFormat } from "../../../../../utils/date-format";
import synchronizeColumnState from "../../../../../utils/synchronize-column-state.js";
import { Columns3Icon, DownloadIcon } from "lucide-react";
import { TraceIndicatorDefs } from "../../../../../components/ColumnDefinitions";
import TraceIndicatorFlyout from "./TraceIndicatorFlyout.js";
import { ColumnProps } from "@/Monolith-UI/Table/types/Table.js";

interface TraceIndicatorsTableQuery {
  case_id?: number;
  include_attributes?: boolean;
  pageSize?: number;
}

interface TraceIndicator {
  created_by: {
    email: string;
    user_id: number;
    full_name: string;
    last_name: string;
    first_name: string;
  };
  created_on: string;
  description: string;
  id: number;
  name: string;
  trace_attributes: {
    created_by_id: number;
    created_on: string;
    id: number;
    label: string;
    type: string;
    updated_on: string;
    uuid: string;
    value: string;
  };
  updated_on: string;
  uuid: string;
}

interface TraceIndicatorTableMethods {
  refresh: () => void;
}

const pageSize = 50;
const queryKeyPrefix = ["trace-indicators", "list"];
const stateStoreKey = "caseTimeline";

const TraceIndicatorsMenu = styled(
  ({
    className,
    onAddItem,
    columnState,
    handleColumnVisibility,
    onExportTable,
    onSearch,
  }) => {
    return (
      <div className={className}>
        <Button
          variant="contained"
          color="primary"
          size="xxs"
          onClick={() => onAddItem?.()}
        >
          + New Trace Indicator
        </Button>
        <div
          style={{
            display: "flex",
            flex: "initial",
            flexDirection: "row",
            gap: 5,
            alignContent: "center",
            alignItems: "center",
            marginLeft: "auto",
          }}
        >
          <DropDownMenu
            data={columnState.map((col: ColumnProps<TraceIndicator>) => ({
              label: col.caption,
              value: col.dataField,
            }))}
            defaultValue={columnState
              .filter(
                (col: ColumnProps<TraceIndicator>) => col.visible !== false
              )
              .map((col: ColumnProps<TraceIndicator>) => ({
                label: col.caption,
                value: col.dataField,
              }))}
            variant="outlined"
            multiselect
            searchable
            buttonProps={{
              title: "Show/Hide Columns",
              size: "xxs",
              style: { padding: "0px 4px" },
            }}
            onChange={handleColumnVisibility}
            dropDownProps={{
              style: { width: 175, maxWidth: 400 },
            }}
          >
            <Columns3Icon size={14} />
          </DropDownMenu>
          <DropDownMenu
            variant="outlined"
            data={[
              { label: "Export Visible Columns", value: "export:visible" },
              { label: "Export All Columns", value: "export:all" },
            ]}
            buttonProps={{
              title: "Export List to XLSX",
              size: "xxs",
              style: { padding: "0px 4px" },
            }}
            onItemSelect={(item) => onExportTable(item.value)}
            dropDownProps={{
              style: { width: 175, maxWidth: 400 },
            }}
          >
            <DownloadIcon size={14} />
          </DropDownMenu>
          <TextInput
            stylingMode="outlined"
            placeholder="Search Indicators"
            height="100%"
            width={200}
            onKeyUp={onSearch}
          />
        </div>
      </div>
    );
  }
)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
`;

const TraceIndicatorsTable = styled(
  ({ className, caseID, onAddItem, onRowAction, selectedRow, tableRef }) => {
    const [query, setQuery] = useState<TraceIndicatorsTableQuery | null>({
      case_id: caseID,
      include_attributes: true,
      pageSize: pageSize,
    });

    const [columnState, setColumnState] = useState(() => {
      return synchronizeColumnState(
        TraceIndicatorDefs,
        JSON.parse(localStorage.getItem(stateStoreKey) || "{}")
      );
    });

    const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } =
      useInfiniteQuery({
        queryKey: [...queryKeyPrefix, query],
        queryFn: ({ pageParam, queryKey }) => {
          const [_, __, q] = queryKey;
          return AnalysisAPI.TraceIndicators.get({
            ...(q as TraceIndicatorsTableQuery),
            page: pageParam,
          });
        },
        getNextPageParam: (lastPage) => {
          return lastPage.nextPage;
        },
        getPreviousPageParam: (firstPage) => {
          if (firstPage.page - 1 === 0) return null;
          return firstPage.page - 1;
        },
        initialPageParam: 1,
        placeholderData: (data) => data,
      });

    const records = data?.pages?.reduce((acc, page) => {
      return [...acc, ...page.data];
    }, []);

    const debouncedFetchNextPage = useDebouncedCallback(() => {
      fetchNextPage();
    }, 50);

    // Detect scroll to bottom
    const handleScroll = (e: React.MouseEvent) => {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      const pageLength = data?.pages?.length ? data?.pages?.length : 0;
      if (scrollHeight - scrollTop <= clientHeight + 100 * pageLength) {
        if (hasNextPage && !isFetchingNextPage) {
          debouncedFetchNextPage();
        }
      }
    };

    const handleExportTable = (option: string) => {
      // show snackbar

      let columns = columnState.map((c) => {
        return { dataField: c.dataField, header: c.caption, ...c };
      });
      if (option === "export:visible") {
        columns = columns.filter((c) => c.visible !== false);
      }

      AnalysisAPI.TraceIndicators.exportList({
        query,
        type: "xlsx",
        columns,
        date_format: getDateFormat({ isMoment: true, includeTime: true }),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }).then((res) => {
        const { signedUrl, filename } = res;
        const el = document.createElement("a");
        el.href = signedUrl.replace(
          "http://localhost:3000",
          "http://localhost:3001"
        );
        el.download = filename;
        el.click();
        // remove snackbar
        el.remove();
      });
    };

    const handleSearch = (e: React.KeyboardEvent) => {
      const target = e.target as HTMLInputElement;
      let searchText = target.value;
      if (e.code === "Enter" || e.code === "NumpadEnter" || searchText === "") {
        setQuery((q) => ({
          ...q,
          search: searchText === "" ? null : searchText,
        }));
      }
    };

    const handleColumnVisibility = (
      columns: {
        label: string;
        value: string;
      }[]
    ) => {
      setColumnState((cs) => {
        return cs.map((c) => {
          if (columns.find((col) => col.value === c.dataField)) {
            return {
              ...c,
              visible: true,
            };
          }
          return {
            ...c,
            visible: false,
          };
        });
      });
    };

    const handleActionButtonClick = (rowData: TraceIndicator) => {
      onRowAction?.(rowData);
    };

    useEffect(() => {
      if (tableRef) {
        tableRef.current = {
          refresh: refetch,
        };
      }
    }, []);

    return (
      <div className={className}>
        <TraceIndicatorsMenu
          columnState={columnState}
          handleColumnVisibility={handleColumnVisibility}
          onExportTable={handleExportTable}
          onSearch={handleSearch}
          onAddItem={onAddItem}
        />
        <Table
          data={records || []}
          keyValue="uuid"
          columnProps={{ minWidth: 150, width: 150 }}
          onScroll={handleScroll}
          onActionButtonClick={handleActionButtonClick}
          showActionColumn={true}
          focusedRow={selectedRow}
        >
          {columnState.map((col) => (
            <Column key={col.dataField} {...col} />
          ))}
        </Table>
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const TraceIndicators = styled(({ className }) => {
  const { case_id: caseID, uuid } = useParams();
  const case_id = caseID ? parseInt(caseID) : null;
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const queryClient = useQueryClient();
  const tableRef = useRef<TraceIndicatorTableMethods>(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedItem, setSelectedItem] =
    useState<Partial<TraceIndicator> | null>({
      uuid,
    });
  const [showFlyout, setShowFlyout] = useState(uuid ? true : false);

  const { data: caseInfo, isLoading } = useQuery({
    queryKey: [
      "cases",
      "list",
      {
        case_id: case_id ? case_id : null,
      },
    ],
    queryFn: () =>
      CasesApi.getCases({
        case_id: case_id ? case_id : null,
      }),
    select: (data) => data?.[0] || {},
  });

  const onCreateTraceIndicator = () => {
    setShowCreateModal(false);
    queryClient.refetchQueries({
      queryKey: ["trace-indicators", "list", { case_id: caseID }],
      type: "active",
    });
  };

  const handleShowFlyout = (rowData: TraceIndicator) => {
    setSelectedItem(rowData);
    setShowFlyout(true);
    navigate(`/cases/${case_id}/analysis/trace-indicators/${rowData.uuid}`);
  };

  const handleCloseFlyout = () => {
    // remove event_uuid from URL
    const newUrl = `/cases/${case_id}/analysis/trace-indicators`;
    navigate(newUrl, { replace: true });
    setSelectedItem(null);
    setShowFlyout(false);
  };

  // keyboard shortcuts
  useEffect(() => {
    const handleKeyDown = (ev: KeyboardEvent) => {
      // open modal on ctrl + n
      if (ev.ctrlKey && ev.key === "n") {
        ev.preventDefault();
        setShowCreateModal(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  if (isLoading) return null;

  return (
    <div className={className}>
      <CreateTraceIndicatorModal
        show={showCreateModal}
        defaultFormData={{
          created_by_id: currentUser.user_id,
          case_uuid: caseInfo.uuid,
          case_id,
        }}
        onClose={() => setShowCreateModal(false)}
        onCancel={() => setShowCreateModal(false)}
        onSubmit={onCreateTraceIndicator}
      />
      <TraceIndicatorFlyout
        open={showFlyout}
        onClose={handleCloseFlyout}
        uuid={selectedItem?.uuid ? selectedItem?.uuid : ""}
        defaultData={{ ...selectedItem, case_uuid: caseInfo.uuid }}
        onEdit={() => {
          tableRef?.current?.refresh();
        }}
        onDelete={() => {
          tableRef?.current?.refresh();
          handleCloseFlyout();
        }}
      />
      <TraceIndicatorsTable
        caseID={caseID}
        onAddItem={() => setShowCreateModal(true)}
        tableRef={tableRef}
        case_id={case_id}
        onRowAction={handleShowFlyout}
        selectedRow={selectedItem}
      />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export default TraceIndicators;
