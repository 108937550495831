import styled from "styled-components";
import EditorTemplateForm from "../../../components/Forms/EditorTemplateForm.js";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@monolith-forensics/monolith-ui";
import { ArrowLeftIcon } from "lucide-react";
import MonolithNotesAPI from "../../../api/Notes/index.js";
import LoggingAPI from "../../../api/logging/index.js";
import { usePermissions } from "../../../hooks/usePermissions";
import { DocumentTemplate } from "@/components/Modals/DocumentTemplate.interface.js";

const CreateEditorTemplate = styled(({ className }) => {
  const { currentUser } = usePermissions();
  const navigate = useNavigate();

  const onSubmit = async (formData: DocumentTemplate) => {
    await MonolithNotesAPI.createTemplate(formData);

    LoggingAPI.createLog({
      user_id: currentUser.user_id,
      message: `Created editor template: ${formData.name}`,
      event: "create_editor_template",
      object_type: "editor_template",
      object_uuid: formData.uuid,
    });

    navigate("/settings/editor-templates");
  };

  const onCancel = () => {
    navigate("/settings/editor-templates");
  };

  return (
    <>
      <Link to={"/settings/editor-templates"} style={{ width: "fit-content" }}>
        <Button
          size="xxs"
          variant="text"
          color="gray"
          leftSection={<ArrowLeftIcon size={14} />}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          Templates
        </Button>
      </Link>
      <EditorTemplateForm
        title="Create Editor Template"
        defaultInfo={{}}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </>
  );
})``;

export default CreateEditorTemplate;
