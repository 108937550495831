import { getDateFormat } from "../../utils/date-format";
import CaseNumberCell from "../Evidence/CaseNumberCell.js";
import EncryptionKeyComponent from "./EncryptionKeyComponent.js";
import CaseStorageCell from "./StorageNumberCell.js";

let CaseStorageColumnDefs = [
  {
    dataField: "storage_number",
    caption: "Storage Number",
    render: (rowData) => {
      return <CaseStorageCell rowData={rowData} />;
    },
  },
  {
    dataField: "uuid",
    caption: "UUID",
    visible: false,
  },
  {
    dataField: "case_number",
    caption: "Case Number",
    visible: false,
    render: (rowData) => {
      return <CaseNumberCell rowData={rowData} />;
    },
  },
  {
    dataField: "case_ref",
    caption: "Case Name",
    visible: false,
  },
  {
    dataField: "created_on",
    caption: "Created On",
    dataType: "date",
    format: { type: getDateFormat({ isMoment: true, includeTime: false }) },
  },
  {
    dataField: "last_audit_date",
    caption: "Last Audit Date",
    dataType: "date",
    format: { type: getDateFormat({ isMoment: true, includeTime: false }) },
    visible: false,
  },
  {
    dataField: "date_assigned",
    caption: "Date Assigned",
    dataType: "date",
    visible: false,
    format: { type: getDateFormat({ isMoment: true, includeTime: false }) },
  },
  {
    dataField: "encryption_key",
    caption: "Encryption Key",
    render: (rowData) => <EncryptionKeyComponent rowData={rowData} />,
  },
  {
    dataField: "general_storage",
    caption: "General Storage",
    visible: false,
  },
  {
    dataField: "type",
    caption: "Type",
  },
  {
    dataField: "make",
    caption: "Provider",
  },
  {
    dataField: "model_name",
    caption: "Model",
  },
  {
    dataField: "model_number",
    caption: "Model Number",
    visible: false,
  },
  {
    dataField: "serial_number",
    caption: "Serial Number",
    visible: false,
  },
  {
    dataField: "capacity_bytes",
    caption: "Size",
    visible: false,
    render: (rowData) =>
      rowData.capacity
        ? `${rowData.capacity} ${rowData.capacity_unit || ""}`
        : "",
  },
  {
    dataField: "capacity_gb",
    caption: "Size (GB)",
    visible: false,
    render: (rowData) => {
      if (!rowData.capacity_gb) return "";
      return rowData.capacity_gb + " GB";
    },
  },
  {
    dataField: "stored_bytes",
    caption: "Stored Data (GB)",
    visible: false,
    render: (rowData) => {
      // convert to GB
      return Number(
        (rowData.stored_bytes / (1024 * 1024 * 1024)).toFixed(2)
      ).toLocaleString();
    },
  },
  {
    dataField: "free_bytes",
    caption: "Free Space (GB)",
    visible: false,
    render: (rowData) => {
      // convert to GB
      return Number(
        (rowData.free_bytes / (1024 * 1024 * 1024)).toFixed(2)
      ).toLocaleString();
    },
  },
  {
    dataField: "used_percentage",
    caption: "Percent Used",
    visible: false,
    render: (rowData) => {
      if (!rowData.capacity_bytes) return "0%";
      if (!rowData.stored_bytes) return "0%";
      if (rowData.capacity_bytes === 0) return "0%";
      if (rowData.stored_bytes === 0) return "0%";
      const pct = rowData.stored_bytes / rowData.capacity_bytes;
      if (pct > 1) return "100%";
      if (pct < 0) return "0%";

      const formattedPct = Number(pct * 100).toFixed(2) + "%";

      let color = "limegreen";
      if (pct > 0.8) color = "red";
      else if (pct > 0.6) color = "orange";
      else if (pct > 0.4) color = "yellow";
      else if (pct > 0.2) color = "limegreen";

      return (
        <div style={{ position: "relative" }}>
          <div>{formattedPct}</div>
          <div
            style={{
              width: formattedPct,
              height: 4,
              backgroundColor: color,
            }}
          ></div>
        </div>
      );
    },
  },
  {
    dataField: "available_percentage",
    caption: "Percent Available",
    visible: false,
    render: (rowData) => {
      if (!rowData.capacity_bytes) return "0%";
      if (!rowData.free_bytes) return "0%";
      if (rowData.capacity_bytes === 0) return "0%";
      if (rowData.free_bytes === 0) return "0%";
      const pct = rowData.free_bytes / rowData.capacity_bytes;
      if (pct > 1) return "100%";
      if (pct < 0) return "0%";
      const formattedPct = Number(pct * 100).toFixed(2) + "%";

      let color = "limegreen";
      if (pct < 0.2) color = "red";
      else if (pct < 0.4) color = "orange";
      else if (pct < 0.6) color = "yellow";
      else if (pct < 0.8) color = "limegreen";

      return (
        <div style={{ position: "relative" }}>
          <div>{formattedPct}</div>
          <div
            style={{
              width: formattedPct,
              height: 4,
              backgroundColor: color,
            }}
          ></div>
        </div>
      );
    },
  },
  {
    dataField: "location_name",
    caption: "Current Location",
    visible: false,
  },
  {
    dataField: "location_path",
    caption: "Location Path",
    visible: false,
  },
  {
    dataField: "notes",
    caption: "Description",
    visible: false,
  },
];

export default CaseStorageColumnDefs.slice();
