import styled from "styled-components";

const ButtonMenu = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  margin-top: 10px;

  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

export default ButtonMenu;
