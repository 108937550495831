import styled from "styled-components";
import {
  Form,
  SimpleItem,
  EmptyItem,
  GroupItem,
} from "devextreme-react/ui/form";
import { useRef } from "react";

import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import ToolBarItems from "../../../components/ToolBarItems";

const ContactsImportForm = styled(
  ({ className, caseInfo, columnNames = [], onSubmit = () => {} }) => {
    const form = useRef(null);
    const navigate = useNavigate();

    const handleSubmit = () => {
      if (form.current.instance.validate().isValid) {
        const formData = Object.assign(
          {},
          form.current.instance.option("formData")
        );

        onSubmit(formData);
        navigate(`/cases/${caseInfo.case_id}/contacts?v=import&s=validate`);
      }
    };

    const handleCancel = () => {
      navigate(`/cases/${caseInfo.case_id}/contacts?v=import`);
    };

    useEffect(() => {
      if (!columnNames)
        navigate(`/cases/${caseInfo.case_id}/contacts?v=import`);
    }, []);

    if (!columnNames) return null;

    return (
      <div className={className}>
        <Title style={{ marginBottom: 50 }}>
          Map Columns to Contact Fields
        </Title>
        <Form ref={form} colCount={2}>
          <SimpleItem
            dataField="name"
            label={{ text: "Name" }}
            isRequired={true}
            editorType="dxSelectBox"
            editorOptions={{
              items: columnNames,
              showClearButton: true,
              placeholder: "Select Column...",
              dropDownOptions: {
                maxHeight: 300,
              },
              value: columnNames.find((c) => c.toLowerCase() === "name"),
            }}
          />
          <SimpleItem
            dataField="title"
            label={{ text: "Title" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: columnNames,
              showClearButton: true,
              placeholder: "Select Column...",
              value: columnNames.find((c) => c.toLowerCase() === "title"),
            }}
          />
          <SimpleItem
            dataField="organization"
            label={{ text: "Organization" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: columnNames,
              showClearButton: true,
              placeholder: "Select Column...",
              value: columnNames.find(
                (c) => c.toLowerCase() === "organization"
              ),
            }}
          />
          <SimpleItem
            dataField="email"
            label={{ text: "Email" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: columnNames,
              showClearButton: true,
              placeholder: "Select Column...",
              value: columnNames.find((c) => c.toLowerCase() === "email"),
            }}
          />
          <SimpleItem
            dataField="address"
            label={{ text: "Address" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: columnNames,
              showClearButton: true,
              placeholder: "Select Column...",
              value: columnNames.find((c) => c.toLowerCase() === "address"),
            }}
          />
          <SimpleItem
            dataField="city"
            label={{ text: "City" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: columnNames,
              showClearButton: true,
              placeholder: "Select Column...",
              value: columnNames.find((c) => c.toLowerCase() === "city"),
            }}
          />
          <SimpleItem
            dataField="state"
            label={{ text: "State/Province" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: columnNames,
              showClearButton: true,
              placeholder: "Select Column...",
              value: columnNames.find((c) => c.toLowerCase() === "state"),
            }}
          />
          <SimpleItem
            dataField="zipcode"
            label={{ text: "Postal Code" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: columnNames,
              showClearButton: true,
              placeholder: "Select Column...",
              value: columnNames.find((c) => c.toLowerCase() === "zipcode"),
            }}
          />
          <SimpleItem
            dataField="country"
            label={{ text: "Country" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: columnNames,
              showClearButton: true,
              placeholder: "Select Column...",
              value: columnNames.find((c) => c.toLowerCase() === "country"),
            }}
          />
          <SimpleItem
            dataField="office_number"
            label={{ text: "Office Number" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: columnNames,
              showClearButton: true,
              placeholder: "Select Column...",
              value: columnNames.find(
                (c) => c.toLowerCase() === "office_number"
              ),
            }}
          />
          <SimpleItem
            dataField="mobile_number"
            label={{ text: "Mobile Number" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: columnNames,
              showClearButton: true,
              placeholder: "Select Column...",
              value: columnNames.find(
                (c) => c.toLowerCase() === "mobile_number"
              ),
            }}
          />
          <SimpleItem
            dataField="contact_type"
            label={{ text: "Contact Type" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: columnNames,
              showClearButton: true,
              placeholder: "Select Column...",
              value: columnNames.find(
                (c) => c.toLowerCase() === "contact_type"
              ),
            }}
          />
          <SimpleItem
            dataField="notes"
            label={{ text: "Notes" }}
            colSpan={2}
            editorType="dxTextArea"
            editorOptions={{
              height: 135,
              value: columnNames.find((c) => c.toLowerCase() === "notes"),
            }}
          />
        </Form>

        <div
          style={{
            marginTop: 30,
          }}
        >
          <ToolBarItems
            submitText="Next"
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </div>
      </div>
    );
  }
)`
  margin: 40px auto;
  max-width: 800px;
`;

const Title = styled.div`
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 1rem;
`;

export default ContactsImportForm;
