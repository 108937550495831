import styled from "styled-components/macro";
import { usePermissions } from "../../../hooks/usePermissions";
import { useState } from "react";
import { Typography, useTheme } from "@mui/material";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import Menu, { MenuItem } from "../../../Monolith-UI/Menu";

const CasesHeader = styled(({ className, onChange = () => {} }) => {
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();

  const theme = useTheme();
  const [currentView, setCurrentView] = useState(
    localStorage.getItem("casesView") || "My Cases"
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (item) => {
    handleClose();
    localStorage.setItem("casesView", item.text);
    setCurrentView(item.text);
    onChange(item);
  };

  const hasReadAllPermission = hasPermission(
    MONOLITH_PERMISSIONS.CASES_READ_ALL
  );

  return (
    <div className={className}>
      <div
        className={`view-selector` + (hasReadAllPermission ? " admin" : "")}
        onClick={hasReadAllPermission ? handleClick : null}
      >
        {hasReadAllPermission ? (
          <>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h3">{currentView}</Typography>
              <ArrowDropDownOutlinedIcon
                style={{ fontSize: 26, marginLeft: 5 }}
              />
            </div>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              onItemSelect={handleSelect}
              dropDownTitle={""}
            >
              <MenuItem
                onClick={handleClose}
                data={{ text: "My Cases", value: 0 }}
                style={{ minWidth: 200 }}
              >
                {
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      color: theme.palette.text.primary,
                    }}
                  >
                    My Cases
                  </div>
                }
              </MenuItem>
              <MenuItem
                onClick={handleClose}
                data={{ text: "All Cases", value: 1 }}
                style={{ minWidth: 200 }}
              >
                {
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      color: theme.palette.text.primary,
                    }}
                  >
                    All Cases
                  </div>
                }
              </MenuItem>
            </Menu>
          </>
        ) : (
          <Typography variant="h3">My Cases</Typography>
        )}
      </div>
    </div>
  );
})`
  .view-selector {
    border: 1px solid transparent;
    transition: all 0.2s ease;
    width: fit-content;
    user-select: none;
  }
  .view-selector.admin:hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    border-radius: 5px;
  }
`;

export default CasesHeader;
