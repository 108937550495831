import {
  useParams,
  useNavigate,
  Link,
  Outlet,
  NavLink,
} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import CasesApi from "../../../api/cases";

import { Box, Typography, Popover, useTheme, Button } from "@mui/material";
import { getClients, updateCase, logActivity, deleteCase } from "../../../api";

import { Popup } from "devextreme-react/ui/popup";
import { db_timestamp } from "../../../utils/date-format";
import { useAuth } from "../../../contexts/AuthContext";
import { addRecentCase } from "../../../api/cases/recentCases";
import ToolBarItems from "../../../components/ToolBarItems";
import { usePermissions } from "../../../hooks/usePermissions";
import CaseMoreButton from "./CaseMoreButton";
import { useSnackbar } from "notistack";
import Loader from "../../../components/Loader";
import KeyboardReturnOutlinedIcon from "@mui/icons-material/KeyboardReturnOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import TaskButton from "../../../Monolith-UI/TaskButton.js";
import EditCaseModal from "../components/EditCaseModal.js";
import styled from "styled-components";
import DropdownMenu from "../../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import { FeatureFlagResolver, Features } from "../../../FeatureFlagResovler.js";
import { Case } from "../types/Cases";

interface CaseStatusItem {
  case_status_id: number;
  status_name: string;
  total: number;
}

export interface CaseTypeItem {
  case_type_id: number;
  case_type: string;
  total: number;
}

interface CaseHeader {
  className?: string;
  caseInfo?: Case | null;
  handleCaseInfoUpdate: (
    data: Partial<Case>,
    options: { updateDB: boolean }
  ) => void;
  statusItems: CaseStatusItem[];
  caseTypeItems: CaseTypeItem[];
}

const tabs = [
  { label: "Overview", value: "overview" },
  { label: "Evidence", value: "evidence" },
  { label: "Acquisitions", value: "acquisitions" },
  { label: "Storage Items", value: "case-storage" },
  { label: "Analysis", value: "analysis" },
  { label: "Tasks", value: "tasks" },
  { label: "Notes", value: "notes" },
  { label: "Files", value: "documents" },
  { label: "Contacts", value: "contacts" },
  { label: "Quality Assurance", value: "qa" },
  { label: "Reports", value: "reports" },
  { label: "Activity", value: "activity" },
];

const Tabs = styled(({ className }) => {
  const { currentUser, workspace } = useAuth();

  const showTimelineSection = FeatureFlagResolver(
    currentUser.email,
    workspace.tenant_slug,
    Features.CASE_ANALYSIS
  );

  return (
    <div className={className}>
      <div className="tabs-container">
        {tabs
          .filter((tab) => tab.value !== "analysis" || showTimelineSection)
          .map((tab) => (
            <NavLink to={`${tab.value}`} key={tab.value}>
              <div className="tab">{tab.label}</div>
            </NavLink>
          ))}
      </div>
      <div className="border-overlay"></div>
    </div>
  );
})`
  margin-top: 5px;
  margin-bottom: 10px;

  .border-overlay {
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }

  .tabs-container {
    display: flex;
    flex-direction: row;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .tab {
    padding: 4px 8px;
    padding-bottom: 2px;
    border-bottom: 1px solid transparent;
  }

  .tab:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }

  a.active .tab {
    border-bottom-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const TabSection = styled(({ className }) => {
  return (
    <div className={className}>
      <Tabs />
      <div className="tab-content">
        <Outlet />
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .tab-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
`;

const DeleteCasePopup = ({
  deleteCasePopup,
  caseInfo,
}: {
  deleteCasePopup: React.MutableRefObject<any>;
  caseInfo?: Case | null;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isDeleting, setIsDeleting] = useState(false);
  const { currentUser } = usePermissions();
  const navigate = useNavigate();

  const handleSubmit = () => {
    setIsDeleting(true);
    deleteCase(caseInfo?.case_id).then((result) => {
      logActivity(
        null,
        currentUser.user_id,
        `Deleted Case ${caseInfo?.case_number} - ${caseInfo?.case_ref}`
      );
      enqueueSnackbar(`Case ${caseInfo?.case_number} Deleted`, {
        variant: "success",
      });
      navigate("/cases");
    });
  };

  const handleCancel = () => {
    deleteCasePopup.current.instance.hide();
  };

  return (
    <Popup
      ref={deleteCasePopup}
      showCloseButton={false}
      showTitle={true}
      title="Delete Case"
      defaultHeight={"auto"}
      defaultWidth={500}
    >
      {isDeleting ? (
        <>
          <Loader message="Deleting Case" />
        </>
      ) : (
        <>
          <div>Are you sure you want to delete this case?</div>
          <div style={{ marginTop: 20 }}>
            All records and items associated with this case will be deleted,
            including evidence, documents, activity log, etc...
          </div>
          <ToolBarItems
            submitText="Delete Case"
            submitColor="error"
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            style={{ marginTop: 20 }}
          />
        </>
      )}
    </Popup>
  );
};

const CaseMeta = ({ caseInfo }: { caseInfo?: Case | null }) => {
  const caseData = caseInfo;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const theme = useTheme();

  const { data } = useQuery({
    queryKey: ["clients:list", { client_id: caseInfo?.client_id }],
    queryFn: () => getClients({ client_id: caseInfo?.client_id }),
  });

  const handlePopoverOpen = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget as HTMLElement);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const clientInfo = data?.[0];

  return (
    <>
      <div
        style={{
          padding: "0px 3px",
          marginRight: 5,
          fontSize: 11,
          color: theme.palette.text.secondary,
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 3,
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <FolderOutlinedIcon
          style={{ fontSize: 11, color: "orange", marginRight: 3 }}
        />
        <div>{caseInfo?.case_number}</div>
      </div>
      <Popover
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ p: 2, maxWidth: 500 }}>
          <>
            <Typography variant="overline" color="textSecondary">
              Case Open Date
            </Typography>
            <div>
              {caseData?.case_open_date
                ? moment
                    .utc(caseData?.case_open_date)
                    .local()
                    .format("MMMM DD, yyyy HH:mm A")
                : "N/A"}
            </div>
            <Typography variant="overline" color="textSecondary">
              Last Activtiy Date
            </Typography>
            <div>
              {caseData?.last_activity_date
                ? moment
                    .utc(caseData?.last_activity_date)
                    .local()
                    .format("MMMM DD, yyyy HH:mm A")
                : "N/A"}
            </div>
            <Typography variant="overline" color="textSecondary">
              Case Closed Date
            </Typography>
            <div>
              {caseData?.case_closed_date
                ? moment
                    .utc(caseData?.case_closed_date)
                    .local()
                    .format("MMMM DD, yyyy HH:mm A")
                : "N/A"}
            </div>
            <Typography variant="overline" color="textSecondary">
              Case Lead
            </Typography>
            <div>{caseData?.case_lead?.full_name}</div>
            <Typography variant="overline" color="textSecondary">
              Office
            </Typography>
            <div>{caseData?.office_name}</div>
            <Typography variant="overline" color="textSecondary">
              Client Info
            </Typography>
            {clientInfo && (
              <>
                <div>{clientInfo.name}</div>
                <div>{clientInfo.title}</div>
                <div>{clientInfo.organization}</div>
                <div>{clientInfo.email}</div>
                <div>{clientInfo.address}</div>
                <div>
                  {clientInfo.city}, {clientInfo.state} {clientInfo.zipcode}
                </div>
                <div>{clientInfo.office_number}</div>
              </>
            )}
          </>
        </Box>
      </Popover>
    </>
  );
};

const CaseHeader = styled(
  ({
    className,
    caseInfo,
    handleCaseInfoUpdate,
    statusItems,
    caseTypeItems,
  }: CaseHeader) => {
    const queryClient = useQueryClient();
    const deleteCasePopup = useRef<any>(null);
    const theme = useTheme();
    const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
    const navigate = useNavigate();
    const [showEditPopup, setShowEditPopup] = useState(false);

    const handleDeleteCase = () => {
      deleteCasePopup.current.instance.show();
    };

    const handleChangeStatus = (data: { case_status: string }) => {
      updateCase(caseInfo?.case_id, {
        case_status: data.case_status,
        case_closed_date: data.case_status === "Closed" ? db_timestamp() : null,
      });
      queryClient.setQueryData(
        [
          "cases",
          "list",
          {
            case_id: caseInfo?.case_id,
          },
        ],
        (prevData: Case[] | undefined) => {
          if (!prevData) {
            return [];
          }
          const [oldCaseData] = prevData;
          return [
            {
              ...oldCaseData,
              case_status: data.case_status,
              case_closed_date:
                data.case_status === "Closed" ? db_timestamp() : null,
            },
          ];
        }
      );
    };

    const handleChangeType = (data: { case_type: string }) => {
      updateCase(caseInfo?.case_id, {
        case_type: data.case_type,
      });
      queryClient.setQueryData(
        [
          "cases",
          "list",
          {
            case_id: caseInfo?.case_id,
          },
        ],
        (prevData: Case[] | undefined) => {
          if (!prevData) {
            return [];
          }
          const [oldCaseData] = prevData;
          return [
            {
              ...oldCaseData,
              case_type: data.case_type,
            },
          ];
        }
      );
    };

    return (
      <div className={className}>
        <TaskButton
          onClick={() => navigate("/cases")}
          style={{ outline: `1px solid ${theme.palette.divider}` }}
          title="Back to Cases"
        >
          <KeyboardReturnOutlinedIcon style={{ fontSize: 16 }} />
        </TaskButton>
        <div className="case-name">{caseInfo?.case_ref}</div>
        <CaseMeta caseInfo={caseInfo} />
        <DropdownMenu
          menuItems={(statusItems || []).map((item) => ({
            label: item.status_name,
            value: item.status_name,
            onClick: () =>
              handleChangeStatus({ case_status: item.status_name }),
          }))}
          defaultValue={caseInfo?.case_status || "Select Case Status..."}
          variant="text"
          arrow
          disabled={!hasPermission()}
          buttonProps={{
            style: {
              color: theme.palette.primary.main,
              fontSize: 12,
              fontWeight: 600,
            },
          }}
          children={null}
          dropdownContent={null}
        />
        <DropdownMenu
          menuItems={(caseTypeItems || []).map((item) => ({
            label: item.case_type,
            value: item.case_type,
            onClick: () => handleChangeType({ case_type: item.case_type }),
          }))}
          defaultValue={caseInfo?.case_type || "Select Case Type..."}
          variant="text"
          arrow
          disabled={!hasPermission()}
          buttonProps={{
            style: {
              color: theme.palette.primary.main,
              fontSize: 12,
              fontWeight: 600,
            },
          }}
          children={null}
          dropdownContent={null}
        />
        {hasPermission() && (
          <CaseMoreButton
            handleEditCase={() => {
              setShowEditPopup(true);
            }}
            handleDeleteCase={handleDeleteCase}
          />
        )}
        <EditCaseModal
          caseInfo={caseInfo}
          open={showEditPopup}
          handleClose={() => setShowEditPopup(false)}
          onSubmit={(data) => handleCaseInfoUpdate(data, { updateDB: false })}
        />
        <DeleteCasePopup
          deleteCasePopup={deleteCasePopup}
          caseInfo={caseInfo}
        />
      </div>
    );
  }
)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px;

  .case-name {
    font-size: 1.2rem;
    font-weight: bold;
    max-width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
    margin-left: 5px;
  }

  .case-number {
    font-size: 0.65rem;
    color: ${(props) => props.theme.palette.text.secondary};
    font-weight: 600;
  }
`;

const CaseDetails = styled(({ className }) => {
  const theme = useTheme();
  const { case_id, active_tab } = useParams();
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();

  const result = useQueries({
    queries: [
      {
        queryKey: [
          "cases",
          "list",
          {
            case_id: case_id ? parseInt(case_id) : null,
          },
        ],
        queryFn: () =>
          CasesApi.getCases({
            case_id: case_id ? parseInt(case_id) : null,
          }),
      },
      {
        queryKey: ["cases:statuses"],
        queryFn: () => CasesApi.getCaseStatuses(),
      },
      {
        queryKey: ["cases:types"],
        queryFn: () => CasesApi.getCaseTypes(),
      },
    ],
  });

  const handleCaseInfoUpdate = async (
    data: Partial<Case> = {},
    options = { updateDB: false }
  ) => {
    if (options.updateDB !== false) await updateCase(caseInfo.case_id, data);

    queryClient.refetchQueries({
      queryKey: [
        "cases",
        "list",
        {
          case_id: case_id ? parseInt(case_id) : null,
        },
      ],
    });
  };

  useEffect(() => {
    addRecentCase({
      case_id,
      user_id: currentUser.user_id,
    });
  }, [case_id]);

  const isDone = result.every((item) => item.isFetched);

  const [caseInfo] = result[0]?.data || [];
  const statusItems = result[1].data || null;
  const caseTypeItems = result[2].data || null;

  return (
    <div className={className}>
      {isDone && caseInfo && (
        <>
          <Helmet>
            <title>Case Details | {caseInfo.case_number}</title>
          </Helmet>
          <CaseHeader
            caseInfo={caseInfo}
            handleCaseInfoUpdate={handleCaseInfoUpdate}
            statusItems={statusItems}
            caseTypeItems={caseTypeItems}
          />
          <TabSection caseInfo={caseInfo} />
        </>
      )}
      {isDone && !caseInfo && (
        <>
          <Helmet>
            <title>Case Details | Access Denied</title>
          </Helmet>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 100,
              padding: 15,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 5,
              width: "fit-content",
              margin: "auto",
            }}
          >
            <div style={{ fontSize: 24, fontWeight: "bold" }}>
              You do not have access to this case.
            </div>
            <div style={{ color: theme.palette.text.secondary, fontSize: 18 }}>
              Request access from the case lead or Monolith admin.
            </div>
            <Link to="/cases">
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: 20 }}
                size="small"
              >
                Return To Cases List
              </Button>
            </Link>
          </div>
        </>
      )}
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export default CaseDetails;
