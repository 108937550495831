import { useState } from "react";
import styled from "styled-components";
import { useDebouncedCallback } from "use-debounce";
import Input from "../../Input.js";
import SearchResult from "./SearchResult.js";

const SearchList = styled(
  ({
    className,
    onSearch,
    searchResults,
    handleNoteClick,
    handleNoteDblClick,
    placeholder,
  }) => {
    const [searchQuery, setSearchQuery] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const handleChange = (value) => {
      if (!value || value === "") setSearchQuery(null);

      if (value.length < 3) return;
      setSearchQuery(value);
      onSearch?.(value);
    };

    const handleChangeDebounced = useDebouncedCallback(handleChange, 300);

    return (
      <div className={className}>
        <div className="search-container">
          <div className="search-input-container">
            <Input.StyledInput
              type="text"
              className="search-input"
              placeholder={placeholder || "Search Notes..."}
              defaultValue={""}
              onChange={(e) => handleChangeDebounced?.(e.target.value)}
              autoFocus={true}
            />
          </div>
          <div className="search-results-container">
            {!!searchQuery ? (
              <div className="search-results">
                {searchResults?.map((result) => {
                  return (
                    <SearchResult
                      key={result.uuid}
                      result={result}
                      searchQuery={searchQuery}
                      selected={selectedItem?.uuid === result.uuid}
                      onClick={(data) => {
                        setSelectedItem(data);
                        handleNoteClick(data);
                      }}
                    />
                  );
                })}
                {!searchResults?.length && !!searchQuery && (
                  <div className="no-results">
                    No results for "{searchQuery}"
                  </div>
                )}
              </div>
            ) : (
              <div className="no-results">
                Your search results will appear here.
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
)`
  height: 100%;

  .search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    height: 100%;
  }

  .search-input-container {
    width: 100%;
  }

  .search-results-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    width: 100%;
    height: calc(100% - 50px);
    overflow-y: auto;
    padding-right: 5px;
  }

  .search-results {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .no-results {
    font-size: 0.75rem;
    font-weight: 600;
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;

export default SearchList;
