// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

export const DevExtremeMap = {
  DEFAULT: "material.purple.light",
  DARK: "material.blue.dark",
  LIGHT: "material.purple.light",
  BLUE: "material.purple.light",
  GREEN: "material.purple.light",
  INDIGO: "material.purple.light",
};

export const SETTINGS = {
  NOTES_TOGGLE_TOOLBAR: "Default Toggle Toolbar",
};

export const MONOLITH_PERMISSIONS = {
  ACQUISITIONS_BULK_IMPORT: "acquisitions:bulk-import",
  AUDITS_CREATE: "audits:create",
  AUDITS_DELETE: "audits:delete",
  AUDITS_READ: "audits:read",
  AUDITS_UPDATE: "audits:update",
  CASES_CREATE: "cases:create",
  CASES_DELETE: "cases:delete",
  CASES_READ_ALL: "cases:read-all",
  CASES_UPDATE: "cases:update",
  CLIENTS_CREATE: "clients:create",
  CLIENTS_DELETE: "clients:delete",
  CLIENTS_READ_ALL: "clients:read-all",
  CLIENTS_UPDATE: "clients:update",
  COC_DELETE: "coc:delete",
  COC_UPDATE: "coc:update",
  CONTACTS_BULK_IMPORT: "contacts:bulk-import",
  CONTACTS_CREATE: "contacts:create",
  CONTACTS_DELETE: "contacts:delete",
  CONTACTS_READ_ALL: "contacts:read-all",
  CONTACTS_UPDATE: "contacts:update",
  CUSTOMIZATIONS_MANAGE: "customizations:manage",
  EQUIPMENT_CREATE: "equipment:create",
  EQUIPMENT_DELETE: "equipment:delete",
  EQUIPMENT_READ_ALL: "equipment:read-all",
  EQUIPMENT_UPDATE: "equipment:update",
  EVIDENCE_BULK_INTAKE: "evidence:bulk-intake",
  EVIDENCE_BULK_MOVE: "evidence:bulk-move",
  EVIDENCE_BULK_RELEASE: "evidence:bulk-release",
  EVIDENCE_BULK_UPDATE: "evidence:bulk-update",
  EVIDENCE_CREATE: "evidence:create",
  EVIDENCE_DELETE: "evidence:delete",
  EVIDENCE_READ_ALL: "evidence:read-all",
  EVIDENCE_UPDATE: "evidence:update",
  INQUIRIES_READ_ALL: "inquiries:read-all",
  INQUIRIES_CREATE: "inquiries:create",
  INQUIRIES_DELETE: "inquiries:delete",
  INQUIRIES_UPDATE: "inquiries:update",
  LOCATIONS_CREATE: "locations:create",
  LOCATIONS_DELETE: "locations:delete",
  LOCATIONS_READ_ALL: "locations:read-all",
  LOCATIONS_UPDATE: "locations:update",
  METRICS_REPORTS_CREATE: "metrics-reports:create",
  METRICS_REPORTS_DELETE: "metrics-reports:delete",
  METRICS_REPORTS_READ_ALL: "metrics-reports:read-all",
  METRICS_REPORTS_READ: "metrics-reports:read",
  METRICS_REPORTS_UPDATE: "metrics-reports:update",
  OFFICES_CREATE: "offices:create",
  OFFICES_DELETE: "offices:delete",
  OFFICES_READ_ALL: "offices:read-all",
  OFFICES_UPDATE: "offices:update",
  SOFTWARE_CREATE: "software:create",
  SOFTWARE_DELETE: "software:delete",
  SOFTWARE_READ_ALL: "software:read-all",
  SOFTWARE_UPDATE: "software:update",
  STORAGE_CREATE: "storage:create",
  STORAGE_DELETE: "storage:delete",
  STORAGE_READ_ALL: "storage:read-all",
  STORAGE_UPDATE: "storage:update",
  TIME_ENTRIES_INVOICE: "time-entries:invoice",
  USER_GROUPS_MANAGE: "user-groups:manage",
  USERS_MANAGE: "users:manage",
  USER_ROLES_MANAGE: "user-roles:manage",
};
