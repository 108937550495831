import { Typography, useTheme } from "@mui/material";
import { usePermissions } from "../../hooks/usePermissions";
import { NavLink, Outlet } from "react-router-dom";
import styled from "styled-components";
import { MONOLITH_PERMISSIONS } from "../../constants.js";

const TabSection = styled(({ className, children }) => {
  const theme = useTheme();

  return (
    <div className={className}>
      <div
        style={{
          display: "flex",
          borderRight: `1px solid ${theme.palette.action.hover}`,
          overflowY: "auto",
          minWidth: 175,
        }}
      >
        <Tabs />
      </div>
      <div
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </div>
    </div>
  );
})`
  display: flex;
  flex: 1 1 auto;
  height: 0px;
`;

const MonolithSettings = ({
  children,
}: {
  children?: null | undefined | React.ReactNode;
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Typography variant="h3" gutterBottom display="inline">
          Monolith Settings
        </Typography>
      </div>
      <TabSection>
        {children} <Outlet />
      </TabSection>
    </>
  );
};

const tabs = [
  {
    label: "System",
    value: "system",
  },
  {
    label: "Notifications",
    value: "notifications",
  },
  {
    label: "Organization Info",
    value: "org-info",
    permission: MONOLITH_PERMISSIONS.CUSTOMIZATIONS_MANAGE,
  },
  {
    label: "Editor Templates",
    value: "editor-templates",
  },
  {
    label: "Document Templates",
    value: "document-templates",
  },
  {
    label: "Relay Settings",
    value: "relay",
    permission: MONOLITH_PERMISSIONS.CUSTOMIZATIONS_MANAGE,
  },
  {
    label: "Item Number Formats",
    value: "item-numbers",
    permission: MONOLITH_PERMISSIONS.CUSTOMIZATIONS_MANAGE,
  },
  {
    label: "Case Types",
    value: "case-types",
    permission: MONOLITH_PERMISSIONS.CUSTOMIZATIONS_MANAGE,
  },
  {
    label: "Case Statuses",
    value: "case-status",
    permission: MONOLITH_PERMISSIONS.CUSTOMIZATIONS_MANAGE,
  },
  {
    label: "Case Progress",
    value: "case-progress",
    permission: MONOLITH_PERMISSIONS.CUSTOMIZATIONS_MANAGE,
  },
  {
    label: "Evidence Types",
    value: "evidence-types",
    permission: MONOLITH_PERMISSIONS.CUSTOMIZATIONS_MANAGE,
  },
  {
    label: "Evidence Progress",
    value: "evidence-progress",
    permission: MONOLITH_PERMISSIONS.CUSTOMIZATIONS_MANAGE,
  },
  {
    label: "Item Labels",
    value: "item-labels",
    permission: MONOLITH_PERMISSIONS.CUSTOMIZATIONS_MANAGE,
  },
  {
    label: "Custom Fields",
    value: "custom-fields",
    permission: MONOLITH_PERMISSIONS.CUSTOMIZATIONS_MANAGE,
  },
  {
    label: "Task Templates",
    value: "task-templates",
    permission: MONOLITH_PERMISSIONS.CUSTOMIZATIONS_MANAGE,
  },
  {
    label: "Time Entry Categories",
    value: "time-categories",
    permission: MONOLITH_PERMISSIONS.CUSTOMIZATIONS_MANAGE,
  },
  {
    label: "QA Checklist Items",
    value: "qa-checklists",
    permission: MONOLITH_PERMISSIONS.CUSTOMIZATIONS_MANAGE,
  },
  {
    label: "QA Issue Types",
    value: "qa-issue-types",
    permission: MONOLITH_PERMISSIONS.CUSTOMIZATIONS_MANAGE,
  },
  {
    label: "Admin Log",
    value: "admin-log",
    permission: MONOLITH_PERMISSIONS.CUSTOMIZATIONS_MANAGE,
  },
  {
    label: "Integrations",
    value: "integrations",
    permission: MONOLITH_PERMISSIONS.CUSTOMIZATIONS_MANAGE,
  },
  {
    label: "Release Notes",
    value: "release-notes",
  },
  {
    label: "About",
    value: "about",
  },
];

const Tabs = styled(({ className }) => {
  const { hasPermission } = usePermissions();

  return (
    <div className={className}>
      {tabs
        .filter((tab) => {
          if (tab.permission) {
            return hasPermission(tab.permission);
          }
          return true;
        })
        .map((tab) => (
          <NavLink to={`/settings/${tab.value}`} key={tab.value}>
            <div className="tab">{tab.label}</div>
          </NavLink>
        ))}
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.text.secondary};

  .tab {
    padding-right: 15px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-left: 3px solid transparent;
  }

  .tab:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }

  a.active .tab {
    border-left-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export default MonolithSettings;
