import { Button, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import styled from "styled-components";

import {
  startTablet,
  stopTablet,
  clearTablet,
} from "../../utils/SigWebTablet.js";

const SignatureField = styled(
  ({
    className,
    title,
    onSigned = () => {},
    sigFieldRef,
    defaultSignature = null,
    style = {},
  }) => {
    const [open, setOpen] = useState(false);
    const [sig, setSig] = useState(defaultSignature);

    const handleSave = ({ sig, ...rest }) => {
      setSig(sig);
      onSigned({ sig, ...rest });
    };

    useEffect(() => {
      if (sigFieldRef) {
        sigFieldRef.current = {
          clear: () => {
            setSig(null);
          },
          isEmpty: () => {
            return !sig;
          },
          getSignature: () => {
            return sig;
          },
        };
      }
    }, [sig, sigFieldRef]);

    return (
      <div
        className={className}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "10px 0px",
          ...style,
        }}
      >
        {sig && (
          <div className="sig-img-container">
            <img className="sig-img" src={sig} alt="signature" />
          </div>
        )}
        {!sig && (
          <button className="get-sig-btn" onClick={() => setOpen(!open)}>
            Get Signature
          </button>
        )}
        <SignatureOverlay
          sigFieldRef={sigFieldRef}
          open={open}
          handleClose={() => setOpen(false)}
          onSave={handleSave}
          title={title}
        />
      </div>
    );
  }
)`
  .sig-img-container {
    width: 100%;
    height: 100%;
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
  .sig-img {
    filter: ${(props) => (props.theme.name === "DARK" ? "invert(1)" : "unset")};
    padding: 15px;
    max-width: 100%;
    max-height: 100%;
  }

  & .get-sig-btn {
    padding: 20px;
    outline: none;
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 5px;
    background-color: transparent;
    width: 100%;
    color: ${(props) => props.theme.palette.text.secondary};
    &:hover {
      background-color: ${(props) => props.theme.palette.action.hover};
      cursor: pointer;
    }
  }
`;

const SignatureOverlay = styled(
  ({
    className,
    children,
    open,
    handleClose,
    onSave = () => {},
    title = "Sign Here",
  }) => {
    const theme = useTheme();
    const sigPadRef = useRef(null);
    const [isTopaz, setIsTopaz] = useState(false);

    const handleClear = () => {
      sigPadRef.current.clear();
      clearTablet();
    };

    const handleSave = async () => {
      // Process Topaz signature pad data
      if (isTopaz) {
        stopTablet();
        setIsTopaz(false);

        const sig = sigPadRef.current.getCanvas();

        const white = [255, 255, 255, 255];

        const sigImageData = sig
          .getContext("2d")
          .getImageData(0, 0, sig.width, sig.height);

        // Ensure that top right pixel is white
        // The Topaz sig pag returns a non-255 value for the top right pixel for some reason
        // This allows the trim canvas function to work properly by converting white space to transparent
        sigImageData.data[3] = 255;

        // Convert white pixels to transparent
        for (let i = 0; i < sigImageData.data.length; i += 4) {
          if (
            sigImageData.data[i] === white[0] &&
            sigImageData.data[i + 1] === white[1] &&
            sigImageData.data[i + 2] === white[2] &&
            sigImageData.data[i + 3] === white[3]
          ) {
            sigImageData.data[i + 3] = 0;
          }
        }

        sig.getContext("2d").putImageData(sigImageData, 0, 0);
      }

      const sigData = sigPadRef.current
        .getTrimmedCanvas()
        .toDataURL("image/png");

      onSave({
        sig: sigData,
        timestamp: new Date().toISOString(),
        userAgent: window.navigator.userAgent,
      });
      handleClose();
    };

    const handleCancel = () => {
      handleClose();

      if (isTopaz) {
        stopTablet();
        setIsTopaz(false);
      }
    };

    useEffect(() => {
      return () => {
        if (isTopaz) {
          stopTablet();
          setIsTopaz(false);
        }
      };
    }, []);

    if (!open) return null;

    return (
      <div className={className}>
        <div className="sig-container">
          <div className="header">
            <div>{title}</div>
            <div className="subtle">
              {isTopaz ? " (Signature Device Enabled)" : ""}
            </div>
          </div>
          <div className="sig-pad-container">
            <SignatureCanvas
              ref={sigPadRef}
              clearOnResize={false}
              canvasProps={{
                onTouchMove: (event) => {
                  event.stopPropagation();
                },
                style: {
                  filter: theme.name === "DARK" ? "invert(1)" : "unset",
                  width: "100%",
                  height: "100%",
                  touchAction: "none",
                },
              }}
              penColor={"black"}
            />
          </div>
          <div className="action-menu">
            <Button
              variant="outlined"
              size="small"
              disabled={isTopaz}
              onClick={() => {
                setIsTopaz(
                  startTablet({
                    width: 100,
                    height: 100,
                  })
                );
              }}
            >
              Use Sig Pad
            </Button>
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={handleClear}
              style={{ marginLeft: 10 }}
            >
              Clear
            </Button>
            <div style={{ marginLeft: "auto" }}>
              <Button variant="outlined" size="small" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                style={{ marginLeft: 10 }}
                onClick={handleSave}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 1500;

  .header {
    display: flex;
    flex-direction: row;
    align-items: end;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 10px;
    color: white;

    .subtle {
      font-size: 0.8rem;
      font-weight: 400;
      color: ${({ theme }) => theme.palette.text.secondary};
      margin-left: auto;
    }
  }

  .sig-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: all;
  }

  .sig-pad-container {
    width: 50vw;
    height: 25vh;
    max-height: 400px;
    max-width: 800px;
    min-height: 250px;
    min-width: 400px;
    border: 1px solid
      ${({ theme }) =>
        theme.name === "DARK"
          ? theme.palette.divider
          : "rgba(255,255,255,0.5)"};
    background-color: ${({ theme }) =>
      theme.name === "DARK"
        ? theme.palette.signature.backgroundColor
        : "rgba(255,255,255,0.75)"};
    border-radius: 5px;
  }

  .action-menu {
    display: flex;
    margin-top: 10px;
  }

  .clear-btn {
    cursor: pointer;
    color: red;
    display: flex;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const handleSigResize = (element) => {
  if (element) {
    const data = element.toData();
    element.resize();
    element.fromData(data);
  }
};

export default SignatureField;
