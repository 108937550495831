import Loader from "../../../../components/Loader";
import { useAuth } from "../../../../contexts/AuthContext";
import moment from "moment";
import { Link } from "react-router-dom";
import { getRecentEvidence } from "../../../../api/evidence/recentEvidence";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { nanoid } from "nanoid";

const MyRecentEvidence = styled(({ className }) => {
  const { currentUser } = useAuth();

  const { data } = useQuery({
    queryKey: ["recentEvidence", { user_id: currentUser.user_id }],
    queryFn: () => getRecentEvidence({ user_id: currentUser.user_id }),
    select: (data) => data.filter((item) => item.uuid),
  });

  if (!data) return <Loader />;

  if (data.length === 0)
    return <div style={{ textAlign: "center" }}>No Recent Evidence</div>;

  return (
    <div className={className}>
      <div className="recent-evidence-container">
        <div className="recent-evidence-grid">
          {data.map((recent) => (
            <RecentItem key={nanoid()} recent={recent} />
          ))}
        </div>
      </div>
    </div>
  );
})`
  height: calc(100% - 50px);
  overflow-y: auto;
  display: flex;

  .recent-evidence-container {
    width: 100%;
    padding: 0px 10px;
    margin: 0px auto;
  }
  .recent-evidence-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
    grid-gap: 15px;
  }
`;

const RecentItem = styled(({ className, recent }) => {
  return (
    <Link to={`/evidence/${recent.uuid}/overview`}>
      <div className={className} title="View Evidence">
        <div className="evidence-number">
          {recent.evidence_number || "No Evidence Number"}
        </div>
        <div className="item-name">{recent.model_name || "No Item Name"}</div>
        <div className="item-name">
          {recent.case_number || "No Case Number"}
        </div>
        <div className="evidence-progress">
          {recent.progress || "Progress Not Set"}
        </div>
        <div className="date-accessed">
          {moment(recent.accessed_on).fromNow()}
        </div>
      </div>
    </Link>
  );
})`
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.palette.divider};
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  font-size: 10px;
  width: 100%;
  cursor: pointer;
  user-select: none;
  &:hover {
    border: 1px solid ${(props) => props.theme.palette.primary.main};
  }
  .evidence-number {
    font-size: 14px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .item-name {
    font-size: 10px;
    color: ${(props) => props.theme.palette.text.secondary};
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .evidence-progress {
    font-size: 12px;
    color: ${(props) => props.theme.palette.primary.main};
    margin-top: 15px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .date-accessed {
    font-size: 10px;
    color: ${(props) => props.theme.palette.text.secondary};
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
`;

export default MyRecentEvidence;
