import { Card, Divider } from "@mui/material";
import Loader from "../../../../components/Loader";
import React, { useEffect, useRef, useState } from "react";
import { QaMetricsApi } from "../../../../api";

const CaseReportMetrics = ({ reportInfo }) => {
  const [metrics, setMetrics] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    QaMetricsApi.getQaEntryMetrics({
      ...reportInfo.report_parameters,
      dimension: "stats",
    }).then((result) => {
      setMetrics(result);
      setIsLoading(false);
    });
  }, []);

  return (
    <Card
      style={{
        padding: "20px",
        textAlign: "left",
        flexGrow: 1,
        flexBasis: "10px",
      }}
    >
      <div>
        <div
          style={{
            fontSize: "larger",
          }}
        >
          Metrics
        </div>
        <Divider sx={{ my: 2 }} />
        {isLoading && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Loader />
          </div>
        )}
        {!isLoading && (
          <div
            style={{
              minWidth: "300px",
              overflowY: "auto",
              maxHeight: "175px",
              paddingRight: "10px",
            }}
          >
            {metrics && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>QA Entries:</div>
                  <div>{metrics.entries_count}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>Cases: </div>
                  <div>{metrics.cases_count}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>Entries/Case: </div>
                  <div>{metrics.case_ratio}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>Evidence: </div>
                  <div>{metrics.evidence_count}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <div>Entries/Evidence: </div>
                  <div>{metrics.evidence_ratio}</div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Card>
  );
};

export default CaseReportMetrics;
