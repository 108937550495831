import { Card, IconButton, Tooltip, useTheme } from "@mui/material";
import { useRef } from "react";
import {
  getCustomEvidenceAttributes,
  getEvidenceReport,
} from "../../../../api";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import CheckIcon from "@mui/icons-material/Check";
import { Column, DataGrid } from "devextreme-react/ui/data-grid";
import { getDateFormat } from "../../../../utils/date-format";
import Loader from "../../../../components/Loader";
import { Link } from "react-router-dom";
import { useQueries, useQueryClient } from "@tanstack/react-query";

export const evidenceDimensions = [
  {
    text: "New Items",
    value: "Intake Date",
    index: 0,
  },
  {
    text: "Released",
    value: "Date Released",
    index: 1,
  },
];

const EvidenceItemGrid = ({ reportInfo }) => {
  const evidenceReportGrid = useRef(null);
  const queryClient = useQueryClient();

  return (
    <Card
      style={{
        padding: "20px",
        marginBottom: "20px",
        minHeight: "425px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            fontSize: "larger",
          }}
        >
          Evidence Items
        </div>
        <div style={{ marginLeft: "auto" }}>
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              marginRight: 10,
            }}
          >
            <Tooltip arrow title="Export Grid">
              <IconButton
                color="primary"
                size="small"
                disableRipple={false}
                onClick={(e) => {
                  evidenceReportGrid.current.instance.exportToExcel();
                }}
              >
                <FileDownloadOutlinedIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Refresh">
              <IconButton
                color="primary"
                size="small"
                disableRipple={false}
                onClick={(e) => {
                  evidenceReportGrid.current.instance.refresh();
                  queryClient.refetchQueries({
                    queryKey: ["metrics:evidence-overview", reportInfo],
                    active: true,
                    exact: true,
                  });
                }}
              >
                <SyncOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Column Select">
              <IconButton
                color="primary"
                size="small"
                disableRipple={false}
                onClick={(e) => {
                  evidenceReportGrid.current.instance.showColumnChooser();
                }}
              >
                <ViewColumnOutlinedIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      <Table evidenceReportGrid={evidenceReportGrid} reportInfo={reportInfo} />
    </Card>
  );
};

const Table = ({ evidenceReportGrid, reportInfo }) => {
  const theme = useTheme();

  const [
    { isLoading: itemsLoading, isFetching: itemsFetching, data: evidenceItems },
    {
      isLoading: customFieldsLoading,
      isFetching: customFieldsFetching,
      data: customFields,
    },
  ] = useQueries({
    queries: [
      {
        queryKey: ["metrics:evidence-overview", reportInfo],
        queryFn: () => getEvidenceReport(reportInfo.report_parameters || {}),
        refetchOnWindowFocus: false,
      },
      {
        queryKey: [
          "custom-fields:evidence",
          {
            fieldsOnly: true,
          },
        ],
        queryFn: () =>
          getCustomEvidenceAttributes({
            fieldsOnly: true,
          }),
        refetchOnWindowFocus: false,
      },
    ],
  });

  if (itemsLoading || customFieldsLoading)
    return (
      <div style={{ marginTop: 30 }}>
        <Loader message="Retrieving Data..." />
      </div>
    );

  return (
    <DataGrid
      ref={evidenceReportGrid}
      dataSource={evidenceItems}
      height="350px"
      showRowLines={true}
      allowColumnReordering={true}
      allowColumnResizing={true}
      loadPanel={{ enabled: false }}
      onToolbarPreparing={(e) => {
        e.toolbarOptions.visible = false;
      }}
      paging={{
        pageSize: 20,
      }}
      scrolling={{
        mode: "virtual",
        rowRenderingMode: "virtual",
        useNative: false,
      }}
      headerFilter={{
        visible: true,
        allowSearch: true,
      }}
      hoverStateEnabled={true}
      columnChooser={{
        enabled: true,
        mode: "select",
        title: "Filter Report Columns",
        height: "350",
        width: "325",
      }}
      stateStoring={{
        enabled: true,
        type: "localStorage",
        storageKey: "metrics:evidence-grid",
        savingTimeout: 10,
      }}
    >
      <Column
        dataField="evidence_number"
        caption="Evidence Number"
        allowReordering={false}
        showInColumnChooser={false}
        visible={true}
        cellRender={(e) => {
          return (
            <Link
              style={{
                color:
                  e.data.is_parent === 1
                    ? theme.palette.primary.main
                    : theme.palette.warning.main,
              }}
              onMouseEnter={(event) => {
                event.target.style.textDecoration = "underline";
              }}
              onMouseLeave={(event) => {
                event.target.style.textDecoration = "none";
              }}
              to={`/evidence/${e.data.uuid}/overview`}
            >
              {e.data.evidence_number}
            </Link>
          );
        }}
      />
      <Column
        dataField="case_number"
        caption="Case Number"
        visible={true}
        cellRender={(e) => {
          return (
            <Link
              style={{ color: theme.palette.success.main }}
              onMouseEnter={(event) => {
                event.target.style.textDecoration = "underline";
              }}
              onMouseLeave={(event) => {
                event.target.style.textDecoration = "none";
              }}
              to={`/cases/${e.data.case_id}/overview`}
            >
              {e.data.case_number}
            </Link>
          );
        }}
      />
      <Column dataField="case_ref" caption="Case Name" />
      <Column
        dataField="created_on"
        caption="Created On"
        visible={false}
        dataType="date"
        format={{
          type: getDateFormat({
            isMoment: false,
            includeTime: true,
          }),
        }}
      />
      <Column
        dataField="coc_intake_timestamp"
        caption="Intake Date"
        dataType="date"
        format={{
          type: getDateFormat({
            isMoment: false,
            includeTime: false,
          }),
        }}
      />
      <Column
        dataField="location_received"
        caption="Location Received"
        visible={false}
      />
      <Column dataField="item_type" caption="Type" />
      <Column dataField="manufacturer" caption="Brand" />
      <Column dataField="model_name" caption="Item Name" />
      <Column dataField="progress" caption="Progress" visible={false} />
      <Column visible={false} dataField="model_number" caption="Model Number" />
      <Column
        visible={false}
        dataField="serial_number"
        caption="Serial Number"
      />
      <Column
        visible={false}
        dataField="location_name"
        caption="Current Location"
      />
      <Column
        visible={false}
        dataField="capacity_bytes"
        caption="Size"
        alignment="left"
        headerFilter={{ groupInterval: 100 }}
        calculateSortValue={(rowData) => {
          return rowData.capacity_bytes;
        }}
        cellTemplate={(container, options) => {
          if (
            options.data.capacity != null &&
            options.data.capacity_unit != null
          ) {
            container.innerHTML =
              options.data.capacity + " " + options.data.capacity_unit;
          }
        }}
      />
      <Column
        visible={false}
        dataField="capacity"
        caption="Size (no unit)"
        alignment="left"
        headerFilter={{ groupInterval: 100 }}
        calculateSortValue={(rowData) => {
          return rowData.capacity_bytes;
        }}
        cellTemplate={(container, options) => {
          if (
            options.data.capacity != null &&
            options.data.capacity_unit != null
          ) {
            container.innerHTML = options.data.capacity;
          }
        }}
      />
      <Column
        visible={false}
        allowSearch={false}
        dataField="capacity_unit"
        caption="Size Unit"
      />
      <Column visible={false} dataField="custody_to" caption="Received By" />
      <Column
        visible={false}
        dataField="custody_from"
        caption="Received From"
      />
      <Column visible={false} dataField="client_name" caption="Client Name" />
      <Column visible={false} dataField="organization" caption="Organization" />
      <Column visible={false} dataField="office_name" caption="Office" />
      <Column
        visible={false}
        dataField="contact_name"
        caption="Linked Contact"
      />
      <Column
        visible={false}
        dataField="is_parent_child"
        caption="Parent Or Child"
      />
      <Column
        visible={false}
        dataField="has_children"
        caption="Has Children"
        alignment={"center"}
        cellRender={(e) => {
          if (e.data.has_children === "Yes") return <CheckIcon />;
          return <></>;
        }}
      />
      <Column
        visible={false}
        dataField="child_count"
        caption="Child Count"
        alignment="left"
      />
      <Column
        visible={false}
        dataField="is_acquired"
        caption="Is Acquired"
        alignment="center"
        cellRender={(e) => {
          if (e.data.is_acquired === "Yes") return <CheckIcon />;
          return <></>;
        }}
      />
      <Column
        visible={false}
        dataField="acquisition_count"
        caption="Acquisition Count"
        alignment="left"
      />
      <Column
        visible={false}
        dataField="has_photos"
        caption="Is Photographed"
        alignment="center"
        cellRender={(e) => {
          if (e.data.has_photos === "Yes") return <CheckIcon />;
          return <></>;
        }}
      />
      <Column
        visible={false}
        dataField="photo_count"
        caption="Photo Count"
        alignment="left"
      />
      <Column
        visible={false}
        dataField="description"
        caption="Description"
        width={150}
      />
      {customFields.map((attribute) => {
        return (
          <Column
            key={attribute.field_id}
            dataField={`custom_attribute_${attribute.field_id}`}
            caption={attribute.field_name}
            visible={false}
            allowFiltering={true}
            dataType={attribute.editor_type === "dateBox" ? "datetime" : null}
            format={{
              type:
                attribute.editor_type === "dateBox"
                  ? getDateFormat({
                      isMoment: false,
                      includeTime: false,
                    })
                  : null,
            }}
            calculateCellValue={(rowData) => {
              if (
                rowData.custom_attributes &&
                rowData.custom_attributes.length > 0
              ) {
                let field = rowData.custom_attributes.find((item) => {
                  return item.field_id === attribute.field_id;
                });

                if (field) {
                  if (attribute.editor_type === "dateBox")
                    return new Date(field.value);
                  if (field.value && attribute.editor_type === "tagBox") {
                    return JSON.parse(field.value).join(", ");
                  }
                  return field.value;
                }
              }
            }}
          />
        );
      })}
    </DataGrid>
  );
};

export default EvidenceItemGrid;
