import { z } from "zod";
import {
  Button,
  DateInput,
  Grid,
  SelectBox,
  TextAreaInput,
  TextInput,
} from "@monolith-forensics/monolith-ui";
import ButtonMenu from "./components/ButtonMenu.js";
import { useForm, zodResolver } from "@mantine/form";
import diffFormData from "./utils/diffFormData.js";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import { TraceAttributeTypes } from "../ColumnDefinitions";

const TraceAttributeSchema = z
  .object({
    uuid: z.string().min(10).max(45),
    type: z.string({ message: "Value Required" }).min(1),
    value: z.string({ message: "Value Required" }).min(1),
    trace_uuid: z.string().min(10).max(36).optional(),
    created_by_id: z.number().positive().min(1).optional(),
    metadata: z.object().nullable().optional(),
    description: z.string().nullable().optional(),
  })
  .refine(
    (data) => {
      if (!data.value) return false;
      if (z.string().parse(data.value).length < 1) return false;
      return TraceAttributeTypes.find(
        (type) => type.value === data.type
      )?.schema.safeParse(data.value).success;
    },
    (data) => {
      const label = TraceAttributeTypes.find(
        (type) => type.value === data.type
      )?.label;
      return {
        message: `Invalid value${!!label ? ` for ${label}` : ""}`,
        path: ["value"],
      };
    }
  );

const DefaultTraceAttributeForm = ({
  defaultFormData = {},
  buttonProps = {},
  onSubmit,
  onCancel,
  onChange,
}) => {
  const [formData, setFormData] = useState(defaultFormData);

  const form = useForm({
    mode: "uncontrolled",
    initialValues: { uuid: uuidv4(), ...defaultFormData },
    validate: zodResolver(TraceAttributeSchema),
    onValuesChange: (values) => {
      setFormData(values);
      onChange?.(values);
    },
  });

  const handleSubmit = async () => {
    const validateResult = form.validate();

    if (validateResult.hasErrors) {
      console.error(validateResult);
      return;
    }

    const formData = form.getValues();

    const diffData = diffFormData(defaultFormData, formData);

    // call onSubmit with modeled data and diff data
    // diff data is used when the form is in edit context
    // diff may be used when we only want to update modified values
    onSubmit?.(formData, diffData);
  };

  const handleCancel = () => {
    form.reset();
    onCancel?.();
  };

  const selectedType = TraceAttributeTypes.find(
    (type) => type.value === formData.type
  );

  return (
    <>
      <Grid col={1} style={{ marginBottom: 10 }}>
        <SelectBox
          data={TraceAttributeTypes.map((type) => ({
            ...type,
            group: type.category,
            label: type.label.replace(type.category, "").trim(),
          }))}
          grouped
          size="sm"
          placeholder="Select Type"
          label="Attribute Type"
          description="Select the type of attribute you would like to add. The selected type will determine the input field type and validation rules."
          searchable
          clearable
          openOnFocus={false}
          DropDownProps={{ autoHeight: true }}
          key={form.key("type")}
          {...form.getInputProps("type")}
        />
        {selectedType?.editor_type === "datetime" ? (
          <DateInput
            size="sm"
            required
            clearable
            enableCalendar
            label="Attribute Value"
            description="This is the timestamp of the event in UTC time.  This is the time that the event occurred."
            format={"YYYY-MM-DD HH:mm:ss.SSS"}
            utc={true}
            key={form.key("value")}
            {...form.getInputProps("value")}
          />
        ) : selectedType?.editor_type === "number" ? (
          <TextInput
            type="number"
            min={selectedType?.min}
            max={selectedType?.max}
            size="sm"
            variant="outlined"
            label="Attribute Value"
            description="Provide a numeric value for the attribute."
            placeholder="Attribute Value"
            key={form.key("value")}
            {...form.getInputProps("value")}
          />
        ) : (
          <TextInput
            size="sm"
            placeholder="Attribute Value"
            label="Attribute Value"
            description="Provide a value for the attribute."
            disabled={!selectedType}
            key={form.key("value")}
            {...form.getInputProps("value")}
          />
        )}
        <TextAreaInput
          size="sm"
          variant="outlined"
          label="Description"
          placeholder="Enter attribute description"
          description={
            "Provide a brief description or notes for the attribute to provide any relevant context."
          }
          minRows={8}
          maxRows={24}
          key={form.key("description")}
          {...form.getInputProps("description")}
        />
      </Grid>
      <ButtonMenu>
        <Button size="xs" variant="subtle" onClick={handleCancel}>
          {buttonProps.cancelText || "Cancel"}
        </Button>
        <Button
          size="xs"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          {buttonProps.submitText || "Submit"}
        </Button>
      </ButtonMenu>
    </>
  );
};

export default DefaultTraceAttributeForm;
