import styled from "styled-components";
import EvidenceAPI from "../../api/evidence/index.js";
import DefaultEvidenceForm from "./DefaultEvidenceForm.js";

const FormTitle = styled.h2`
  margin: 0;
`;

const EditEvidenceForm = styled(({ className, onSubmit, defaultFormData }) => {
  const handleSubmit = async (data, diff, rawFormData) => {
    await EvidenceAPI.updateEvidence(defaultFormData.evidence_id, diff);
    onSubmit?.(data, diff, rawFormData);
  };
  return (
    <div className={className}>
      <FormTitle>Edit Forensic Evidence</FormTitle>
      <DefaultEvidenceForm
        onSubmit={handleSubmit}
        defaultFormData={defaultFormData}
      />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  gap: 15px;

  width: ${({ width }) =>
    Number.isInteger(width) ? `${width}px` : width || "100%"};
`;

export default EditEvidenceForm;
