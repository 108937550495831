import styled from "styled-components";
import AuditItemsViewProps from "./AuditItemsViewProps";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { AuditsAPI } from "@/api";
import { useDebouncedCallback } from "use-debounce";
import { Table } from "@monolith-forensics/monolith-ui";
import { AuditItemDefs } from "../../../components/ColumnDefinitions";
import { AuditItem } from "@/types";
import EvidenceFlyout from "../../../Monolith-UI/Flyout/EvidenceFlyout";
import { useSnackbar } from "notistack";
import { getDateFormat } from "@/utils/date-format";
import { CustomField } from "@/pages/Settings/CustomFieldSettings/types";
import EvidenceAPI from "@/api/evidence";

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const AuditItemsTable: React.FC<AuditItemsViewProps> = ({
  auditData,
  status,
  onUpdate,
}) => {
  const [showFlyout, setShowFlyout] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any | null>(null);
  const [search, setSearch] = useState<string>("");
  const [sort, setSort] = useState<{
    field: string;
    dir: "asc" | "desc";
  }>();
  const query = useMemo(() => {
    return {
      uuid: auditData?.uuid,
      pageSize: 100,
      item_type: auditData?.item_type,
      status,
      search,
      sort,
    };
  }, [auditData?.uuid, status, search, sort, auditData?.item_type]);
  const { enqueueSnackbar } = useSnackbar();

  const { data: customFields, isLoading: customFieldsLoading } = useQuery({
    queryKey: ["evidence:customFields:list"],
    queryFn: () => EvidenceAPI.getCustomFields({ fieldsOnly: true }),
    enabled: auditData?.item_type === "evidence",
  });

  const {
    data: infiniteData,
    refetch,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    isPending,
  } = useInfiniteQuery({
    queryKey: ["audit:items:list", query],
    queryFn: ({ pageParam, queryKey }) => {
      const queryParams = typeof queryKey[1] === "object" ? queryKey[1] : {};
      return AuditsAPI.getAuditItems({ page: pageParam, ...queryParams });
    },
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage;
    },
    getPreviousPageParam: (firstPage) => {
      if (firstPage.page - 1 === 0) return null;
      return firstPage.page - 1;
    },
    initialPageParam: 1,
    enabled: !!auditData?.uuid,
    // placeholderData: (data) => data,
  });

  const debouncedFetchNextPage = useDebouncedCallback(() => {
    if (!isFetchingNextPage) {
      fetchNextPage();
    }
  }, 50);

  const handleRowUpdate = () => {
    refetch();
    onUpdate?.();
  };

  const handleSearch = (searchText: string) => {
    setSearch(searchText);
  };

  const debouncedSearch = useDebouncedCallback(handleSearch, 300);

  const handleExportTable = () => {
    // show snackbar
    enqueueSnackbar("Exporting table...", {
      variant: "info",
    });

    AuditsAPI.exportAuditItemsList({
      uuid: auditData?.uuid,
      status: status,
      search,
      type: "xlsx",
      date_format: getDateFormat({ isMoment: true, includeTime: true }),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }).then((res) => {
      const { signedUrl, filename } = res;
      const el = document.createElement("a");
      el.href = signedUrl.replace(
        "http://localhost:3000",
        "http://localhost:3001"
      );
      el.download = filename;
      el.click();
      // remove snackbar
      el.remove();
    });
  };

  const auditItems =
    infiniteData?.pages.flatMap((page) =>
      page.data.map((d: AuditItem) => ({ ...d, auditData }))
    ) || [];

  const customColumnDefs =
    customFields?.map((attr: CustomField & { value: any }) => ({
      dataField: `custom_field_${attr.field_id}`,
      caption: attr.field_name,
      visible: false,
      render: ({ rowData }: any) =>
        rowData?.custom_attributes?.find(
          (c: any) => c.field_id === attr.field_id
        )?.value,
    })) || [];

  if (customFieldsLoading) return null;

  return (
    <StyledContent>
      <Table
        data={auditItems}
        keyField="id"
        columnProps={[...AuditItemDefs, ...(customColumnDefs || [])]}
        virtualized={true}
        loading={isLoading || isPending || isFetchingNextPage}
        enableActionButton={true}
        enableSorting={true}
        enableColumnResize={true}
        enableColumnReorder={true}
        onColumnHeaderClick={({ sort }) =>
          setSort(sort ? { field: sort.dataField, dir: sort.dir } : undefined)
        }
        onScrollToBottom={debouncedFetchNextPage}
        onRowUpdated={handleRowUpdate}
        onActionButtonClick={(item) => {
          setSelectedItem(item);
          setShowFlyout(true);
        }}
        tableMenuOptions={{
          enabled: true,
          searchOptions: {
            enabled: true,
            onSearch: debouncedSearch,
            placeholder: "Search Items...",
          },
          exportOptions: {
            enabled: true,
            onExport: handleExportTable,
          },
          columnSelectorOptions: {
            enabled: true,
          },
        }}
        manualSearch={true}
        manualSorting={true}
        onSort={(e) =>
          setSort(
            e.sort ? { field: e.sort.dataField, dir: e.sort.dir } : undefined
          )
        }
        stateStorage={{
          enabled: true,
          key: `audit-items-${auditData?.uuid}`,
          type: "localStorage",
        }}
      />
      {showFlyout && (
        <EvidenceFlyout
          isOpen={showFlyout}
          setIsOpen={setShowFlyout}
          handleClose={() => {
            setShowFlyout(false);
            setSelectedItem(null);
          }}
          defaultEvidenceInfo={selectedItem}
        />
      )}
    </StyledContent>
  );
};

export default AuditItemsTable;
