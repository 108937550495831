import styled from "styled-components";

const About = styled(({ className }) => {
  return (
    <div className={className}>
      <div style={{ maxWidth: 750, padding: "0px 30px" }}>
        <div style={{ marginBottom: "20px" }}>
          <div style={{ fontSize: "xx-large" }}>Monolith</div>
          <div style={{ fontSize: "large" }}>
            Case, Evidence, & Analysis Management for Digital Forensics
          </div>
          <div>v {process.env.REACT_APP_MONOLITH_VERSION}</div>
        </div>
        <div>Monolith Forensics LLC, Austin, TX</div>
        <div>512-434-0657 | support@monolithforensics.com</div>
        <div>
          <a
            href="https://monolithforensics.com"
            target="_blank"
            rel="noreferrer"
          >
            www.monolithforensics.com
          </a>
        </div>
        <div style={{ marginTop: "50px" }}>
          <div style={{ marginBottom: "15px" }}>
            Monolith Software License Agreement:
          </div>
          <div
            style={{
              width: "95%",
              height: "450px",
              whiteSpace: "pre-line",
              overflowY: "scroll",
              padding: "10px",
            }}
          >
            NOTICE TO ALL USERS: CAREFULLY READ THE FOLLOWING LEGAL AGREEMENT
            ("AGREEMENT"), FOR THE LICENSE OF SPECIFIED SOFTWARE ("SOFTWARE")
            PRODUCED BY Monolith Forensics LLC. ("MONOLITH"). BY CLICKING THE
            ACCEPT BUTTON OR INSTALLING THE SOFTWARE, YOU (THE "USER", "END
            USER", OR "LICENSEE", EITHER AN INDIVIDUAL OR A SINGLE ENTITY)
            CONSENT TO BE BOUND BY AND BECOME A PARTY TO THIS AGREEMENT. IF YOU
            DO NOT AGREE TO ALL OF THE TERMS OF THIS AGREEMENT, CLICK THE BUTTON
            THAT INDICATES THAT YOU DO NOT ACCEPT THE TERMS OF THIS AGREEMENT
            AND DO NOT INSTALL THE SOFTWARE. (IF APPLICABLE, YOU MAY RETURN THE
            PRODUCT TO THE PLACE OF PURCHASE FOR A FULL REFUND.) 1. License
            Grant. Subject to the payment of the applicable license fees, and
            subject to the terms and conditions of this Agreement, MONOLITH
            hereby grants to you a non-exclusive, non-transferable right to use
            the specified version of the Software and the accompanying
            documentation (the "Documentation"). You may install and use the
            Software on one or more computers owned by you (each, a "Client
            Device"). If the Software is licensed as a suite or bundle with more
            than one specified Software products, this license applies to all
            such specified Software products, subject to any restrictions or
            usage terms specified on the applicable price list or product
            packaging that apply to any of such Software products individually.
            a. Use. The Software is "in use" on a computer when it is loaded
            into the temporary memory (i.e., random-access memory or RAM) or
            installed into the permanent memory (e.g., hard disk, CD-ROM, or
            other storage device) of that Client Device. b. Volume Use. If the
            Software is licensed with volume license terms specified in the
            applicable product invoicing or packaging for the Software, you may
            make, use and install as many additional copies of the Software as
            needed. You are only restricted by the total number of Users allowed
            to use the software. You must have a reasonable mechanism in place
            to ensure that the license key provided for the software does not
            leave your possession and in use by unauthorized persons. You must
            have a reasonable mechanism in place to ensure that the Software is
            only installed on Client Devices recognized by the terms of the
            licenses you have obtained. c. License Dongle. Certain licenses are
            required to be installed on a single USB flash drive to create a
            License Dongle (the "dongle"). This dongle may be shared within the
            purchasing organization for non-simultaneous use. The dongle may not
            be copied, cloned, or virtualized. Bypassing the usage restrictions
            of the dongle is a violation of the license terms. d. Monolith Pro
            Trial Edition Terms. The Software is licensed to a single user (the
            purchaser of the license also known as the "licensee" and a human
            individual) for use with the licensee only. The licensee may not
            lend or extend this license to other individuals through the act of
            using the Software for the benefit of any other individual or
            entity. The licensee may not lend or extend this license to other
            individuals through the act of using the Software for profit. The
            licensee is entitled to use the trial edition for the purposes of
            testing and evaluating the product for purchase. The trial edition
            license shall be granted for a period designated by the provided
            license key/method. e. Monolith Pro Terms. The Software is licensed
            to a single user (the purchaser of the license also known as the
            "licensee" and a human individual) for use with the licensee only.
            The licensee may not lend or extend this license to other
            individuals through the act of using the Software for the benefit of
            any other individual or entity. The licensee may not lend or extend
            this license to other individuals through the act of using the
            Software for profit. If multiple users (investigators, technicians,
            agents, etc.) require the use of the Software, each user requires a
            separate license. Licenses of the Software may not be shared or
            pooled. The licensee is entitled to all Monolith Pro maintenance
            Updates for the duration of the Term. The licensee is entitled to
            all Monolith Pro major and minor updates for the period of one (1)
            year from the date of purchase. f. Feeback and Feature Requests. The
            Licensee may request addtional features, bug fixes, or general
            recommendations for the software. Any updates or changes made to the
            software based on feedback, error reports, or suggestions does not
            change or alter the copyright or ownership of the software nor does
            it transfer or add any level of ownership in the software to the
            Licensee that is providing the feedback, error reports, or
            suggestions. 2. Term. Unless otherwise specified in Section 1, this
            Agreement is effective for two thousand (2000) years unless you or
            MONOLITH terminates the Agreement earlier, in accordance with the
            terms set forth herein. This Agreement will terminate automatically
            if you fail to comply with any of the limitations or other
            requirements described herein. When this agreement terminates, you
            must destroy all copies of the Software and the Documentation. You
            may terminate this Agreement at any point by destroying all copies
            of the Software and the Documentation. 3. Updates. During the term
            of this Agreement, you may download revisions, upgrades, or updates
            ("Updates") to the Software when and as MONOLITH publishes them via
            its electronic bulletin board system, auto updater, website or
            through other online services. MONOLITH reserves the right to
            determine if your version of the Software is eligible for an Update
            for the duration of the Term. Updates are released with version
            numbers following this format: [major].[minor].[maintenance] 4.
            Ownership Rights. The Software is protected by United States of
            America copyright laws and international treaty provisions. MONOLITH
            and its suppliers own and retain all right, title and interest in
            and to the Software, including all copyrights, patents, trade secret
            rights, trademarks and other intellectual property rights therein.
            You acknowledge that your possession, installation, or use of the
            Software does not transfer to you any title to the intellectual
            property in the Software, and that you will not acquire any rights
            to the Software except as expressly set forth in this Agreement. You
            agree that any copies of the Software and Documentation will contain
            the same proprietary notices which appear on and in the Software and
            Documentation. 5. Restrictions. You may not rent, lease, loan or
            resell the Software. You may not permit third parties to benefit
            from the use or functionality of the Software via a timesharing,
            service bureau or other arrangement, except to the extent such use
            is specified in the applicable price list, purchase order, or
            product packaging for the Software. You may not transfer any of the
            rights granted to you under this Agreement. You may not reverse
            engineer, decompile, or disassemble the Software, except to the
            extent the foregoing restriction is expressly prohibited by
            applicable law. You may not modify, or create derivative works based
            upon, the Software in whole or in part. You may not copy the
            Software or Documentation except as expressly permitted in Section 1
            above. You may not remove any proprietary notices or labels on the
            Software. All rights not expressly set forth hereunder are reserved
            by MONOLITH. MONOLITH reserves the right to periodically conduct
            audits upon advance written notice to verify compliance with the
            terms of this Agreement. 6. Warranty and Disclaimer a. Limited
            Warranty. MONOLITH warrants that for thirty (30) days from the date
            of original purchase the media (for example diskettes) on which the
            Software is contained will be free from defects in materials and
            workmanship. b. Customer Remedies. MONOLITH's and its suppliers'
            entire liability and your exclusive remedy shall be, at MONOLITH's
            option, either (i) return of the purchase price paid for the
            license, if any, or (ii) replacement of the defective media in which
            the Software is contained with a copy on nondefective media. You
            must return the defective media to MONOLITH at your expense with a
            copy of your receipt. This limited warranty is void if the defect
            has resulted from accident, abuse, or misapplication. Any
            replacement media will be warranted for the remainder of the
            original warranty period. Outside the United States, this remedy is
            not available to the extent MONOLITH is subject to restrictions
            under United States export control laws and regulations. c. Warranty
            Disclaimer. To the maximum extent permitted by applicable law, and
            except for the limited warranty set forth herein, THE SOFTWARE IS
            PROVIDED ON AN "AS IS" BASIS WITHOUT WARRANTY OF ANY KIND, EXPRESS
            OR IMPLIED. WITHOUT LIMITING THE FOREGOING PROVISIONS, YOU ASSUME
            RESPONSIBILITY FOR SELECTING THE SOFTWARE TO ACHIEVE YOUR INTENDED
            RESULTS, AND FOR THE INSTALLATION OF, USE OF, AND RESULTS OBTAINED
            FROM THE SOFTWARE. WITHOUT LIMITING THE FOREGOING PROVISIONS,
            MONOLITH FORENSICS LLC MAKES NO WARRANTY THAT THE SOFTWARE WILL BE
            ERROR-FREE OR FREE FROM INTERRUPTIONS OR OTHER FAILURES OR THAT THE
            SOFTWARE WILL MEET YOUR REQUIREMENTS. TO THE MAXIMUM EXTENT
            PERMITTED BY APPLICABLE LAW, MONOLITH FORENSICS LLC DISCLAIMS ALL
            WARRANTIES, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO
            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, AND NONINFRINGEMENT WITH RESPECT TO THE SOFTWARE AND THE
            ACCOMPANYING DOCUMENTATION. SOME STATES AND JURISDICTIONS DO NOT
            ALLOW LIMITATIONS ON IMPLIED WARRANTIES, SO THE ABOVE LIMITATION MAY
            NOT APPLY TO YOU. The foregoing provisions shall be enforceable to
            the maximum extent permitted by applicable law. 7. Limitation of
            Liability. UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY, WHETHER
            IN TORT, CONTRACT, OR OTHERWISE, SHALL MONOLITH FORENSICS LLC OR ITS
            SUPPLIERS BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR ANY INDIRECT,
            SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY CHARACTER
            INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF GOODWILL, WORK
            STOPPAGE, COMPUTER FAILURE OR MALFUNCTION, OR FOR ANY AND ALL OTHER
            DAMAGES OR LOSSES. IN NO EVENT WILL MONOLITH FORENSICS LLC BE LIABLE
            FOR ANY DAMAGES IN EXCESS OF THE LIST PRICE MONOLITH FORENSICS LLC
            CHARGES FOR A LICENSE TO THE SOFTWARE, EVEN IF MONOLITH FORENSICS
            LLC SHALL HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS
            LIMITATION OF LIABILITY SHALL NOT APPLY TO LIABILITY FOR DEATH OR
            PERSONAL INJURY TO THE EXTENT THAT APPLICABLE LAW PROHIBITS SUCH
            LIMITATION. FURTHERMORE, SOME STATES AND JURISDICTIONS DO NOT ALLOW
            THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES,
            SO THIS LIMITATION AND EXCLUSION MAY NOT APPLY TO YOU. The foregoing
            provisions shall be enforceable to the maximum extent permitted by
            applicable law. 8. United States Government. The Software and
            accompanying Documentation are deemed to be "commercial computer
            software" and "commercial computer software documentation,"
            respectively, pursuant to DFAR Section 227.7202 and FAR Section
            12.212, as applicable. Any use, modification, reproduction, release,
            performance, display or disclosure of the Software and accompanying
            Documentation by the United States Government shall be governed
            solely by the terms of this Agreement and shall be prohibited except
            to the extent expressly permitted by the terms of this Agreement. 9.
            Export Controls. Neither the Software nor the Documentation and
            underlying information or technology may be downloaded or otherwise
            exported or re-exported (i) into (or to a national or resident of )
            Cuba, Iran, Iraq, Libya, North Korean, Sudan, Syria or any other
            country to which the United States has embargoed goods; or (ii) to
            anyone on the United States Treasury Department's list of Specially
            Designated Nations or the United States Commerce Department's Table
            of Denial Orders. By downloading or using the Software you are
            agreeing to the foregoing and you are certifying that you are not
            located in, under the control of, or a national or resident of any
            such country or on any such list. IN ADDITION, YOU SHOULD BE AWARE
            OF THE FOLLOWING. EXPORT OF THE SOFTWARE MAY BE SUBJECT TO
            COMPLIANCE WITH THE RULES AND REGULATIONS PROMULGATED FROM TIME TO
            TIME BY THE BUREAU OF EXPORT ADMINISTRATION, UNITED STATES
            DEPARTMENT OF COMMERCE, WHICH RESTRICT THE EXPORT AND RE-EXPORT OF
            CERTAIN PRODUCTS AND TECHNICAL DATA. IF THE EXPORT OF THE SOFTWARE
            IS CONTROLLED UNDER SUCH RULES AND REGULATIONS, THEN THE SOFTWARE
            SHALL NOT BE EXPORTED OR RE-EXPORTED, DIRECTLY OR INDIRECTLY, (A)
            WITHOUT ALL EXPORT OR RE-EXPORT LICENSES AND UNITED STATES OR OTHER
            GOVERNMENTAL APPROVALS REQUIRED BY ANY APPLICABLE LAWS, OR (B) IN
            VIOLATION OF ANY APPLICABLE PROHIBITION AGAINST THE EXPORT OR
            RE-EXPORT OF ANY PART OF THE SOFTWARE. SOME COUNTRIES HAVE
            RESTRICTIONS ON THE USE OF ENCRYPTION WITHIN THEIR BORDERS, OR THE
            IMPORT OR EXPORT OF ENCRYPTION EVEN IF FOR ONLY TEMPORARY PERSONAL
            OR BUSINESS USE. YOU ACKNOWLEDGE THAT THE IMPLEMENTATION AND
            ENFORCEMENT OF THESE LAWS IS NOT ALWAYS CONSISTENT AS TO SPECIFIC
            COUNTRIES. ALTHOUGH THE FOLLOWING COUNTRIES ARE NOT AN EXHAUSTIVE
            LIST THERE MAY EXIST RESTRICTIONS ON THE EXPORTATION TO, OR
            IMPORTATION OF, ENCRYPTION BY: BELGIUM, CHINA (INCLUDING HONG KONG),
            FRANCE, INDIA, INDONESIA, ISRAEL, RUSSIA, SAUDI ARABIA, SINGAPORE,
            AND SOUTH KOREA. YOU ACKNOWLEDGE IT IS YOUR ULTIMATE RESPONSIBILITY
            TO COMPLY WITH ALL ANY AND ALL GOVERNMENT EXPORT AND OTHER
            APPLICABLE LAWS AND THAT MONOLITH FORENSICS LLC HAS NO FURTHER
            RESPONSIBILITY AFTER THE INITIAL SALE TO YOU WITHIN THE ORIGINAL
            COUNTRY OF SALE. 10. High Risk Activities. The Software is not
            fault-tolerant and is not designed or intended for use in hazardous
            environments requiring fail-safe performance, including without
            limitation, in the operation of nuclear facilities, aircraft
            navigation or communication systems, air traffic control, weapons
            systems, direct life-support machines, or any other application in
            which the failure of the Software could lead directly to death,
            personal injury, or severe physical or property damage
            (collectively, "High Risk Activities"). MONOLITH expressly disclaims
            any express or implied warranty of fitness for High Risk Activities.
            11. Miscellaneous. This Agreement is governed by the laws of the
            United States of America and the State of Texas, without reference
            to conflict of laws principles. The application of the United
            Nations Convention of Contracts for the International Sale of Goods
            is expressly excluded. This Agreement sets forth all rights for the
            user of the Software and is the entire agreement between the
            parties. This agreement supersedes any other communications with
            respect to the Software and Documentation. This Agreement may not be
            modified except by a written addendum issued by a duly authorized
            representative of MONOLITH. No provision hereof shall be deemed
            waived unless such waiver shall be in writing and signed by MONOLITH
            or a duly authorized representative of MONOLITH. If any provision of
            this Agreement is held invalid, the remainder of this Agreement
            shall continue in full force and effect. The parties confirm that it
            is their wish that this Agreement has been written in the English
            language only. MONOLITH may identify you as a customer of MONOLITH
            and describe in a mutually agreeable Customer Case Study the
            services and solutions delivered by MONOLITH to you. MONOLITH may
            also issue one or more mutually agreeable press releases containing
            an announcement of the execution and delivery of this Agreement
            and/or the implementation of the Products by you. No such document
            or release shall include any information considered Confidential by
            you. Your approval to any such document or release shall not be
            unreasonably withheld and shall be deemed given unless you indicate
            otherwise in writing within ten (10) days of delivery of the
            proposed document or release. 12. MONOLITH CUSTOMER CONTACT. If you
            have any questions concerning these terms and conditions, or if you
            would like to contact MONOLITH for any other reason, please write:
            Monolith Forensics LLC, contact@monolithforensics.com |
            https://www.monolithforensics.com
          </div>
        </div>
      </div>
    </div>
  );
})`
  max-width: 800px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
`;

export default About;
