import { useState } from "react";
import { Button, Modal } from "@monolith-forensics/monolith-ui";
import ButtonMenu from "../Forms/components/ButtonMenu.js";
import { usePermissions } from "../../hooks/usePermissions";
import LoggingAPI from "../../api/logging/index.js";

import { useSnackbar } from "notistack";
import { AuditsAPI } from "@/api/index.js";
import { Audit } from "@/types";

interface CompleteAuditModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: (defaultInfo?: Audit) => void;
  onCancel: () => void;
  buttonProps?: {
    cancelText: string;
    submitText: string;
  };
  defaultInfo: Audit;
}

const CompleteAuditModal: React.FC<CompleteAuditModalProps> = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  buttonProps,
  defaultInfo,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      await AuditsAPI.completeAudit({
        uuid: defaultInfo.uuid,
      });

      enqueueSnackbar("Audit has been completed", {
        variant: "success",
      });

      LoggingAPI.createLog({
        user_id: currentUser.user_id,
        message: `Completed Audit: ${defaultInfo?.name}`,
        event: "audit:complete",
        object_type: "audit",
        object_uuid: defaultInfo?.uuid,
      });

      onSubmit?.(defaultInfo);
    } catch (error) {
      console.error("Error completing audit:", error);
      enqueueSnackbar(`Error completing audit.`, {
        variant: "error",
      });
    } finally {
      setSubmitting(false);
      onClose?.();
    }
  };

  const handleCancel = () => {
    onCancel?.();
  };

  return (
    <Modal open={show} onClose={onClose}>
      <Modal.Title>Complete Audit</Modal.Title>
      <div style={{ fontWeight: 500 }}>
        <p>Are you sure you want to complete this audit?</p>
        <p>
          Once completed, audit records can no longer be updated within this
          audit.
        </p>
      </div>
      <ButtonMenu>
        <Button
          size="xs"
          variant="subtle"
          onClick={handleCancel}
          disabled={submitting}
        >
          {buttonProps?.cancelText || "Cancel"}
        </Button>
        <Button
          size="xs"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={submitting}
          loading={submitting}
        >
          {buttonProps?.submitText || "Complete Audit"}
        </Button>
      </ButtonMenu>
    </Modal>
  );
};

export default CompleteAuditModal;
