import { httpDelete, httpGet, httpPatch, httpPost } from "../connection";

export async function getLocations(options = {}, params = {}) {
  if (typeof options.location_id != "undefined")
    return await httpGet(`/locations/${options.location_id}`);
  else if (typeof options.group_id != "undefined")
    return await httpGet(`/locations`, { group_id: options.group_id });
  else if (typeof options.office_id != "undefined")
    return await httpGet(`/locations`, { office_id: options.office_id });
  else return await httpGet(`/locations`, params);
}

export async function createLocation(values) {
  return await httpPost(`/locations`, values);
}

export async function updateLocation(location_id, values) {
  return await httpPatch(`/locations/${location_id}`, values);
}

export async function deleteLocation(location_id) {
  return await httpDelete(`/locations/${location_id}`);
}

export async function deleteGroup(group_id) {
  return await httpDelete(`/locations/groups/${group_id}`);
}

const LocationsAPI = {
  getLocations,
};

export default LocationsAPI;
