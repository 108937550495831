import { useState } from "react";
import styled from "styled-components";

import { FolderPlus, ChevronDown, ChevronRight, FilePlus } from "lucide-react";
import { useNoteSystem } from "../Contexts/NoteSystemContext.js";

const NoteSegment = styled(
  ({
    className,
    children,
    label,
    readOnly = false,
    defaultOpen = false,
    handleCreateNote,
    handleCreateFolder,
    object_type,
    object_name,
    object_id,
    onDragOver = () => {},
  }) => {
    const [open, setOpen] = useState(defaultOpen);
    const [isOver, setIsOver] = useState(false);

    const { currentNoteRef } = useNoteSystem();

    const toggleOpen = () => {
      setOpen((prev) => !prev);
    };

    const handleDragOver = (e) => {
      e.preventDefault();
      setIsOver(true);
      onDragOver?.({
        object_name,
        object_type,
        object_id,
      });
    };

    const handleDragLeave = (e) => {
      setIsOver(false);
    };

    const handleHeaderClick = (e) => {
      toggleOpen();
    };

    const handleFileClick = (e) => {
      e.stopPropagation();

      let parent_id = null;

      // Check if the currentNoteRef is in the same object as the segment
      if (currentNoteRef?.current?.object_id !== object_id) {
        parent_id = null;
      } else {
        parent_id =
          currentNoteRef?.current?.is_folder === 1
            ? currentNoteRef?.current?.uuid
            : currentNoteRef?.current?.parent_id || null;
      }

      handleCreateNote?.({
        object_name,
        object_type,
        object_id,
        parent_id,
      });
    };

    const handleFolderClick = (e) => {
      e.stopPropagation();
      handleCreateFolder?.({
        object_name,
        object_type,
        object_id,
      });
    };

    return (
      <div
        className={className}
        data-is-over={isOver}
        draggable={false}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <div className="header" data-open={open} onClick={handleHeaderClick}>
          {open ? (
            <ChevronDown size={16} style={{ marginRight: 5 }} />
          ) : (
            <ChevronRight size={16} style={{ marginRight: 5 }} />
          )}
          <div className="object-type" data-object-type={object_type}>
            {object_type}
          </div>
          <div className="label">{label}</div>
          {!readOnly && open && (
            <div className="toolbar">
              <FilePlus
                size={16}
                strokeWidth={1}
                absoluteStrokeWidth
                style={{ marginLeft: "auto" }}
                onClick={handleFileClick}
              />
              <FolderPlus
                size={16}
                style={{ marginLeft: 5 }}
                onClick={handleFolderClick}
              />
            </div>
          )}
        </div>
        {open && <div className="content">{children}</div>}
      </div>
    );
  }
)`
  margin-bottom: 5px;
  &:hover {
    .toolbar {
      visibility: visible;
    }
  }

  .object-type {
    font-size: 0.6rem;
    font-weight: 600;
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-right: 5px;

    padding: 1px 3px;
    border-radius: 3px;
    background-color: ${({ theme }) => theme.palette.action.hover};
    text-transform: capitalize;

    &[data-object-type="case"] {
      background-color: ${({ theme }) => theme.palette.primary.main}35;
      color: ${({ theme }) => theme.palette.primary.main};
    }

    &[data-object-type="evidence"] {
      background-color: ${({ theme }) => theme.palette.success.main}35;
      color: ${({ theme }) => theme.palette.success.main};
    }
  }

  .header {
    user-select: none;
    display: flex;
    align-items: center;
    padding-right: 5px;
    padding-top: 5px;
    cursor: pointer;
    color: ${({ theme }) => theme.palette.text.secondary};

    svg {
      font-weight: 400;
      &:hover {
        background-color: ${({ theme }) => theme.palette.action.hover};
      }
    }
  }

  // First header item
  &:first-child .header {
    padding-top: 0px;
  }

  .label {
    font-size: 0.75rem;
    font-weight: bold;
    color: ${({ theme }) => theme.palette.text.secondary};
    text-transform: capitalize;
  }

  .content {
    pointer-events: none;
    padding-left: 10px;
  }

  .toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin-left: auto;
    visibility: hidden;
  }
`;

export default NoteSegment;
