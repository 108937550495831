import { Link } from "react-router-dom";
import styled from "styled-components";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";

const ContactNameCell = styled(({ className, rowData, color }) => {
  if (!rowData.client_id) return <></>;

  return (
    <Link className={className} to={`/contacts/${rowData.client_id}`}>
      <div
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div>{!!rowData.name ? rowData.name : "View Contact"}</div>
        <LaunchOutlinedIcon
          className="action-button"
          style={{ fontSize: 13, marginLeft: 5 }}
        />
      </div>
    </Link>
  );
})(({ theme, rowData, color }) => ({
  color: color || theme.palette.primary.main,
  // hover
  "&:hover": {
    textDecoration: "underline",
  },
}));

export default ContactNameCell;
