import QualityAssuranceAPI from "../../api/quality_assurance/index.js";
import UserApi from "../../api/users/users.js";
import QAStatusSelections from "../../pages/Cases/CaseQA/components/QAStatusSelections.js";

const QaReviewFilterDimensions = [
  {
    name: "Created On",
    field: "created_on",
    id: "created_on",
    type: "date",
    mode: "date",
  },
  {
    name: "Completed On",
    field: "completed_on",
    id: "completed_on",
    type: "date",
    mode: "date",
  },
  {
    name: "Created By",
    field: "created_by",
    id: "created_by",
    type: "string",
    mode: "multi-select",
    key: ["users:list", { includeObservers: false, includeInactive: false }],
    selectionDisplayName: "full_name",
    selectionIdField: "user_id",
    queryFn: () =>
      UserApi.getUsers({ includeObservers: false, includeInactive: false }),
  },
  {
    name: "Assigned To",
    field: "assigned_to",
    id: "assigned_to",
    type: "string",
    mode: "multi-select",
    key: ["users:list", { includeObservers: false, includeInactive: false }],
    selectionDisplayName: "full_name",
    selectionIdField: "user_id",
    queryFn: () =>
      UserApi.getUsers({ includeObservers: false, includeInactive: false }),
  },
  {
    name: "Type",
    field: "type",
    id: "type",
    type: "string",
    mode: "multi-select",
    key: ["quality-assurance:qa-review:types"],
    selectionDisplayName: "text",
    selectionIdField: "text",
    queryFn: () =>
      Promise.resolve([
        {
          text: "General Review",
        },
        {
          text: "Technical Review",
        },
        {
          text: "Admin Review",
        },
      ]),
  },
  {
    name: "Status",
    field: "status",
    id: "status",
    type: "string",
    mode: "multi-select",
    key: ["quality-assurance:qa-review:statuses"],
    selectionDisplayName: "text",
    selectionIdField: "text",
    queryFn: () => Promise.resolve(QAStatusSelections),
  },
  {
    name: "QA Checklist",
    field: "checklist_name",
    id: "checklist_name",
    type: "string",
    mode: "multi-select",
    key: ["quality-assurance:checklists"],
    selectionDisplayName: "checklist_name",
    selectionIdField: "checklist_name",
    queryFn: () => QualityAssuranceAPI.getQAChecklists(),
  },
];

export default QaReviewFilterDimensions.slice();
