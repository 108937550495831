import React, { useState } from "react";
import { Form, SimpleItem, EmptyItem } from "devextreme-react/ui/form";
import CollapseSection from "../../../components/CollaspeSection";
import { useQuery } from "@tanstack/react-query";
import AcquisitionsAPI from "../../../api/acquisitions/index.js";

const AcquisitionParamsForm = ({ paramsForm, reportInfo }) => {
  const [editorVisiblity, setEditorVisbility] = useState({
    startDate: false,
    endDate: false,
  });

  const { data: statuses } = useQuery({
    queryKey: ["acquisitions:statuses"],
    queryFn: () => AcquisitionsAPI.getStatuses(),
    initialData: [
      { status: "Active", status_id: 1, color: "limegreen" },
      { status: "Deleted", status_id: 2, color: "orangered" },
    ],
  });

  let startDateRef = null;
  let endDateRef = null;

  return (
    <>
      <CollapseSection
        title={"Report Parameters | Acquisition - Overview"}
        allowCollapse={false}
      >
        <Form
          ref={paramsForm}
          colCount={2}
          defaultFormData={reportInfo || null}
        >
          <SimpleItem
            dataField="report_parameters.time_interval"
            label={{ text: "Time Interval" }}
            colSpan={1}
            editorType="dxSelectBox"
            editorOptions={{
              items: [
                "Custom",
                "Today",
                "Yesterday",
                "This Week",
                "Last Week",
                "This Month",
                "Last Month",
                "This Year",
                "Last Year",
                "All Time",
              ],
              dropDownOptions: {
                maxHeight: 250,
              },
              onValueChanged: (e) => {
                setEditorVisbility({
                  ...editorVisiblity,
                  startDate: e.value === "Custom",
                  endDate: e.value === "Custom",
                  dimension: e.value !== null,
                });
              },
            }}
          />
          <SimpleItem
            dataField="report_parameters.status"
            label={{ text: "Acquisition Status" }}
            colSpan={1}
            editorType="dxSelectBox"
            editorOptions={{
              displayExpr: "status",
              valueExpr: "status",
              items: statuses,
              dropDownOptions: {
                maxHeight: 250,
              },
            }}
          />
          <SimpleItem
            dataField="report_parameters.start_date"
            label={{ text: "Start Date" }}
            editorType="dxDateBox"
            colSpan={1}
            isRequired={true}
            visible={editorVisiblity.startDate}
            editorOptions={{
              onInitialized: (e) => {
                startDateRef = e.component;
              },
              onValueChanged: (e) => {
                endDateRef.option("min", e.value);
              },
            }}
          />
          <SimpleItem
            dataField="report_parameters.end_date"
            label={{ text: "End Date" }}
            editorType="dxDateBox"
            colSpan={1}
            isRequired={true}
            visible={editorVisiblity.endDate}
            editorOptions={{
              onInitialized: (e) => {
                endDateRef = e.component;
              },
              onValueChanged: (e) => {
                startDateRef.option("max", e.value);
              },
            }}
          />
        </Form>
      </CollapseSection>
    </>
  );
};

export default AcquisitionParamsForm;
