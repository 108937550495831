import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

// For routes that can only be accessed by authenticated users
const AuthGuard = ({ children }) => {
  const { isLoading, currentUser } = useAuth();
  const navigate = useNavigate();
  const isElectron = process.env.REACT_APP_PLATFORM === "electron";
  const ssoCode = localStorage.getItem("ssoCode");
  const [apiMode, setApiMode] = useState(null);

  useEffect(() => {
    if (isElectron) {
      window.electronAPI.getSettings("API_MODE").then((result) => {
        setApiMode(result);
      });
    } else {
      setApiMode(process.env.REACT_APP_API_MODE || "cloud");
    }
  }, []);

  useEffect(() => {
    if (!isLoading && !currentUser) {
      console.log("AuthGuard: redirecting to login", isLoading, currentUser);
      if (ssoCode) {
        localStorage.removeItem("ssoCode");
        navigate("/auth/sso/callback?code=" + ssoCode);
        return;
      }
      navigate("/login");
    }
  }, [isLoading, currentUser]);

  if (isLoading || !currentUser) return null;
  if (!apiMode) return null;

  return <>{children}</>;
};

export default AuthGuard;
