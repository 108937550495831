import { useEffect, useRef } from "react";
import { useAuth } from "../contexts/AuthContext.js";
import { useQuery } from "@tanstack/react-query";
import { AuthService } from "../api/auth/index.js";

export const useSessionExpiration = () => {
  const { isAuthenticated, logout, workspace } = useAuth();

  const inactivityTimer = useRef(null);

  function setupInactivityTimer(inactivityTimeout) {
    if (!inactivityTimeout) return;
    inactivityTimer.current = setTimeout(() => {
      logout();
    }, inactivityTimeout); // 900000 milliseconds = 15 minutes
  }

  const resetInactivityTimer = () => {
    clearTimeout(inactivityTimer.current);
    setupInactivityTimer(workspace?.inactivity_timeout);
  };

  // Periodically check if the user's session has expired
  // If the session has expired, log the user out
  const { data: sessionResponse } = useQuery({
    queryKey: ["check-session"],
    queryFn: () => AuthService.checkSession(),
    refetchInterval: 60000 * 3, // 3 minutes
    refetchOnWindowFocus: true,
    enabled: isAuthenticated,
  });

  // Create an inactivity timer that will log the user out after a period of inactivity
  useEffect(() => {
    if (isAuthenticated && workspace?.inactivity_timeout) {
      setupInactivityTimer(workspace?.inactivity_timeout);

      document.addEventListener("mousemove", resetInactivityTimer);
      document.addEventListener("mousedown", resetInactivityTimer);
      document.addEventListener("keypress", resetInactivityTimer);
      document.addEventListener("touchmove", resetInactivityTimer);
      document.addEventListener("scroll", resetInactivityTimer);
      document.addEventListener("wheel", resetInactivityTimer);

      return () => {
        document.removeEventListener("mousemove", resetInactivityTimer);
        document.removeEventListener("mousedown", resetInactivityTimer);
        document.removeEventListener("keypress", resetInactivityTimer);
        document.removeEventListener("touchmove", resetInactivityTimer);
        document.removeEventListener("scroll", resetInactivityTimer);
        document.removeEventListener("wheel", resetInactivityTimer);
      };
    }
  }, [isAuthenticated, workspace]);

  if (isAuthenticated && sessionResponse && sessionResponse?.status !== 200) {
    // log out the user
    logout();
  }

  return null;
};
