import {
  CheckIcon,
  ClockIcon,
  HourglassIcon,
  AlertTriangleIcon,
  InfoIcon,
} from "lucide-react";

const QAStatusSelections = [
  {
    label: "Pending",
    menuItemProps: {
      style: {
        color: "orange",
      },
    },
    icon: ClockIcon,
    iconColor: "orange",
  },
  {
    label: "In Progress",
    menuItemProps: {
      style: {
        color: "lightblue",
      },
    },
    icon: HourglassIcon,
    iconColor: "lightblue",
  },
  {
    label: "Complete",
    menuItemProps: {
      style: {
        color: "limegreen",
      },
    },
    icon: CheckIcon,
    iconColor: "limegreen",
  },
  {
    label: "Resolutions Needed",
    menuItemProps: {
      style: {
        color: "orangered",
      },
    },
    icon: AlertTriangleIcon,
    iconColor: "orangered",
  },
  {
    label: "Resolutions Addressed",
    menuItemProps: {
      style: {
        color: "gold",
      },
    },
    icon: InfoIcon,
    iconColor: "gold",
  },
];

export default QAStatusSelections;
