import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";
import { nanoid } from "nanoid";
import calculateFileHash from "../../../utils/calculateFileHash.js";

const uploadKey = new PluginKey("upload-image");

const UploadImagesPlugin = () =>
  new Plugin({
    key: uploadKey,
    state: {
      init() {
        return DecorationSet.empty;
      },
      apply(tr, set) {
        set = set.map(tr.mapping, tr.doc);
        // See if the transaction adds or removes any placeholders
        const action = tr.getMeta(this);
        if (action && action.add) {
          const { id, pos, src } = action.add;

          const placeholder = document.createElement("div");
          placeholder.setAttribute("class", "img-placeholder");
          const image = document.createElement("img");
          image.setAttribute("class", "monolith-image uploading");
          image.src = src;
          placeholder.appendChild(image);
          const deco = Decoration.widget(pos + 1, placeholder, {
            id,
          });
          set = set.add(tr.doc, [deco]);
        } else if (action && action.remove) {
          set = set.remove(
            set.find(null, null, (spec) => spec.id === action.remove.id)
          );
        }
        return set;
      },
    },
    props: {
      decorations(state) {
        return this.getState(state);
      },
    },
  });

export default UploadImagesPlugin;

function findPlaceholder(state, id) {
  const decos = uploadKey.getState(state);
  const found = decos.find(null, null, (spec) => spec.id === id);
  return found.length ? found[0].from : null;
}

export function startImageUpload(
  file,
  view,
  pos,
  handleImageUpload = async () => {}
) {
  // check if the file is an image
  if (!file.type.includes("image/")) {
    console.log("File type not supported.");
    return;

    // check if the file size is less than 20MB
  } else if (file.size / 1024 / 1024 > 20) {
    console.log("File size too big (max 20MB).");
    return;
  }

  // A fresh object to act as the ID for this upload
  const id = nanoid(25);

  // Replace the selection with a placeholder
  const tr = view.state.tr;
  if (!tr.selection.empty) tr.deleteSelection();

  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    tr.setMeta(uploadKey, {
      add: {
        id,
        pos,
        src: reader.result,
      },
    });
    view.dispatch(tr);
  };

  calculateFileHash(file).then((hashes) => {
    const md5 = hashes.md5Hash;
    const sha1 = hashes.sha1Hash;
    const sha256 = hashes.sha256Hash;

    handleImageUpload({
      file,
      name: `${id}.png`,
      id,
      md5,
      sha1,
      sha256,
    }).then((src) => {
      if (!src) return;
      const { schema } = view.state;

      let pos = findPlaceholder(view.state, id);
      // If the content around the placeholder has been deleted, drop
      // the image
      if (pos == null) return;

      // Otherwise, insert it at the placeholder's position, and remove
      // the placeholder

      // When BLOB_READ_WRITE_TOKEN is not valid or unavailable, read
      // the image locally
      const imageSrc = typeof src === "object" ? reader.result : src;

      const node = schema.nodes.image.create({
        src: imageSrc,
        alt: `${id}.png`,
        "data-uuid": id,
        title: `Filename: ${id}.png`,
      });

      const transaction = view.state.tr
        .replaceWith(pos, pos, node)
        .setMeta(uploadKey, { remove: { id } });
      view.dispatch(transaction);
    });
  });
}
