import { useRef, useState } from "react";
import { Tabs } from "devextreme-react/ui/tabs";

import { Cases } from "./Cases";
import { ETechs } from "./ETechs";
import { Templates } from "./Templates";
import { FprDetails } from "./FprDetails";
import { FprParameters } from "./FprParameters";
import { FprMetrics } from "./FprMetrics";

import {
  FPR_OBJECT,
  Types,
} from "../../../Settings/Integration/FPR_Integration";

import { getFprReport } from "../../../../api";

import { SavedReports } from "./SavedReports";
import { EditReportPopup } from "../../EditReportPopup";
import Loader from "../../../../components/Loader";
import { Card } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

export const FprReport = ({ reportInfo, setFormUpdated }) => {
  const editReportPopup = useRef(null);

  const [mainSelectedTab, setMainSelectedTab] = useState(0);

  const { isLoading, data } = useQuery({
    queryKey: ["fprReport", reportInfo],
    queryFn: () => getFprReport(reportInfo.report_parameters || {}),
  });

  let { evidenceReport: evidencesByFpr, finalReport: casesWithEvidence } =
    data || {};
  if (!evidencesByFpr) evidencesByFpr = FPR_OBJECT;
  if (!casesWithEvidence) casesWithEvidence = [];

  return (
    <div>
      <div
        style={{
          display: "flex",
          marginBottom: "20px",
          userSelect: "text",
        }}
      >
        <FprDetails reportInfo={reportInfo} />
        <FprParameters
          reportInfo={reportInfo}
          editReportPopup={editReportPopup}
        />
        <FprMetrics
          reportInfo={reportInfo}
          cases={casesWithEvidence}
          evidence={evidencesByFpr}
        />
      </div>

      <Tabs
        items={[
          {
            text: "Cases",
            badge: (casesWithEvidence && casesWithEvidence?.length) || 0,
          },
          {
            text: "CPUs",
            badge: (evidencesByFpr && evidencesByFpr[Types.CPU]?.length) || 0,
          },
          {
            text: "ETech",
            badge: (evidencesByFpr && evidencesByFpr[Types.ETECH]?.length) || 0,
          },
          {
            text: "Loose Drives",
            badge:
              (evidencesByFpr && evidencesByFpr[Types.LOOSE_DRIVES]?.length) ||
              0,
          },
          {
            text: "Storage Media",
            badge:
              (evidencesByFpr && evidencesByFpr[Types.STORAGE_MEDIA]?.length) ||
              0,
          },
          {
            text: "Cloud",
            badge: (evidencesByFpr && evidencesByFpr[Types.CLOUD]?.length) || 0,
          },
          {
            text: "Other",
            badge: (evidencesByFpr && evidencesByFpr[Types.OTHER]?.length) || 0,
          },
        ]}
        selectedIndex={mainSelectedTab}
        focusStateEnabled={false}
        hoverStateEnabled={false}
        activeStateEnabled={false}
        onItemClick={(e) => {
          e.event.preventDefault();
          e.event.stopPropagation();
          setMainSelectedTab(e.itemIndex);
        }}
      />
      <Card style={{ height: 400 }}>
        {isLoading && (
          <div style={{ margin: "15px 0px" }}>
            <Loader />
          </div>
        )}
        {!isLoading && (
          <>
            {mainSelectedTab === 0 && <Cases cases={casesWithEvidence} />}
            {mainSelectedTab === 1 && (
              <Templates evidences={evidencesByFpr[Types.CPU]} />
            )}
            {mainSelectedTab === 2 && (
              <ETechs evidences={evidencesByFpr[Types.ETECH]} />
            )}
            {mainSelectedTab === 3 && (
              <Templates evidences={evidencesByFpr[Types.LOOSE_DRIVES]} />
            )}
            {mainSelectedTab === 4 && (
              <Templates evidences={evidencesByFpr[Types.STORAGE_MEDIA]} />
            )}
            {mainSelectedTab === 5 && (
              <Templates evidences={evidencesByFpr[Types.CLOUD]} />
            )}
            {mainSelectedTab === 6 && (
              <Templates evidences={evidencesByFpr[Types.OTHER]} />
            )}
          </>
        )}
      </Card>

      <div
        style={{
          minHeight: "450px",
          margin: "20px 0px",
        }}
      >
        <Tabs
          items={["Saved Reports"]}
          selectedIndex={0}
          focusStateEnabled={false}
          hoverStateEnabled={false}
          activeStateEnabled={false}
          width={250}
        />

        <SavedReports reportInfo={reportInfo} />
      </div>

      <EditReportPopup
        editReportPopup={editReportPopup}
        reportInfo={reportInfo}
        setFormUpdated={setFormUpdated}
      />
    </div>
  );
};
