import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import { UsersIcon } from "lucide-react";
import { useState } from "react";
import getRandomColor from "../../../utils/getRandomColor.js";
import UserApi from "../../../api/users/users.js";
import DropdownMenu from "../../../Monolith-UI/DropdownMenu/DropdownMenu.js";

export const Avatar = styled(({ className, style = {}, color, user }) => {
  return (
    <div
      className={className}
      style={{
        ...style,
      }}
    >
      {user.first_name.charAt(0)}
      {user.last_name.charAt(0)}
    </div>
  );
})`
  border-radius: 50%;
  height: 15px;
  width: 15px;
  background-color: ${({ user }) => getRandomColor(user.user_id, user.user_id)};
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 7.5px;
  font-weight: 600;
  color: white;
`;

export const UserList = styled(({ className, users }) => {
  if (!users) return null;

  let items = [];

  if (users.length > 3) {
    items = users.slice(0, 3);
  } else {
    items = users;
  }

  return (
    <div className={className}>
      {items.map((user) => (
        <Avatar user={user} />
      ))}
      {users.length > 3 && (
        <div style={{ fontWeight: 600, marginLeft: 2 }}>
          +{users.length - 3}
        </div>
      )}
    </div>
  );
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1px;
`;

const AssigneeSelector = ({
  value,
  defaultInfo = [],
  onSelect,
  showLabel = true,
  variant = "contained",
  userQuery = {},
}) => {
  const [open, setOpen] = useState(false);

  const { data, isFetched } = useQuery({
    queryKey: ["users", "list", userQuery],
    queryFn: () => UserApi.getUsers(userQuery),
    placeholderData: (data) => data || defaultInfo,
    enabled: open,
  });

  // Deduplicate users
  const users = data?.reduce((acc, user) => {
    if (!acc.find((u) => u.user_id === user.user_id)) {
      acc.push(user);
    }
    return acc;
  }, []);

  const menuItems = [
    {
      checkboxGroup: true,
      value,
      onSelectionChanged: (data) => {
        onSelect?.({ selections: data });
      },
      items:
        users?.map((user) => ({
          label: user?.full_name || "User",
          value: user.user_id,
          icon: () => <Avatar user={user} style={{ marginRight: 5 }} />,
        })) || [],
    },
  ];

  let isEmpty = true;

  if (Array.isArray(value)) {
    if (value?.length > 0) isEmpty = false;
  } else if (!!value) isEmpty = false;

  return (
    <DropdownMenu
      menuItems={menuItems}
      title={"Select Assignees"}
      variant={variant}
      textColor={isEmpty ? "secondary" : "primary"}
      onOpen={() => setOpen(true)}
      loading={!isFetched}
    >
      {value?.icon && (
        <value.icon
          color={value.color}
          size={13}
          style={{ marginRight: showLabel ? 5 : 0 }}
        />
      )}
      {showLabel ? (
        !isEmpty ? (
          <UserList
            users={users?.filter((u) => value?.includes(u.user_id)) || []}
          />
        ) : (
          <UsersIcon size={13} />
        )
      ) : null}
    </DropdownMenu>
  );
};

export default AssigneeSelector;
