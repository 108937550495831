import styled from "styled-components";
import Loader from "../../components/Loader.js";
import { useEffect, useRef, useState } from "react";
import renderPDF from "./utils/renderPDF.js";

const PDFViewer = styled(({ className, file, zoom = 1 }) => {
  const [isLoading, setIsLoading] = useState(true);
  const viewerRef = useRef(null);

  useEffect(() => {
    const viewer = viewerRef.current;
    if (file?.url) renderPDF(viewerRef, file.url, setIsLoading);

    return () => {
      setIsLoading(true);
      viewer.querySelector("#viewer").innerHTML = "";
    };
  }, [file]);

  return (
    <>
      {isLoading && <Loader message="Loading Document..." />}
      <div className={className}>
        <div
          id="viewerContainer"
          ref={viewerRef}
          style={{
            transform: `scale(${zoom || 1})`,
          }}
        >
          <div id="viewer" className="pdfViewer"></div>
        </div>
      </div>
    </>
  );
})`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  overflow: auto;

  #viewerContainer {
    position: absolute;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    overflow-y: auto;
    top: 0;
    max-height: calc(100% - 10px);
    max-width: 100%;

    .page {
      border: none;
    }
  }
`;

export default PDFViewer;
