import styled from "styled-components";
import { Button } from "@mui/material";
import Dialog from "../../../Monolith-UI/Dialog/Dialog.js";
import TimeEntriesAPI from "../../../api/TimeEntries/time-entries.js";
import { useState } from "react";
import Loader from "../../Loader.js";

const Menu = styled.div`
  display: flex;
  flex-direction: row;
`;

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 150px;
  min-width: 325px;
  max-width: 350px;
  padding: 5px;

  // Allow height to increase with content
  height: fit-content;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
`;

const StyledButton = styled(Button)`
  padding: 0 10px;
`;

const DetailsContainer = styled.div`
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const InvoiceTimeEntriesModal = ({ open, onClose, timeIds, onSubmit }) => {
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    setSubmitting(true);
    await TimeEntriesAPI.invoiceTimeEntries(timeIds);
    onSubmit?.(timeIds);
    onClose?.();
    setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <h2>Invoice Time Entries</h2>
        {submitting && <Loader message={"Invoicing Time Entries..."} />}
        {!submitting && (
          <>
            <div style={{ fontWeight: 600 }}>
              {`Are you sure you want to invoice the ${timeIds.length} selected time entries?`}
            </div>
            <div style={{ marginTop: "auto" }}>
              <Menu topBorder style={{ justifyContent: "flex-end", gap: 10 }}>
                <StyledButton
                  variant="text"
                  color="primary"
                  onClick={() => onClose?.()}
                >
                  Cancel
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="error"
                  onClick={() => handleSubmit()}
                >
                  Invoice Time Entries
                </StyledButton>
              </Menu>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default InvoiceTimeEntriesModal;
