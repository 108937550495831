import DropdownMenu from "../../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import TaskStatuses from "./TaskStatuses.js";

const TaskStatusSelector = ({
  value,
  onSelect,
  showLabel = true,
  variant = "contained",
}) => {
  const statusItems = [
    {
      radioGroup: true,
      value: value?.status_id,
      items: TaskStatuses.map((status) => ({
        label: status.status_name,
        value: status.status_id,
        icon: status.icon,
        iconColor: status.color,
        onClick: () => {
          onSelect?.(status);
        },
      })),
    },
  ];

  return (
    <DropdownMenu
      menuItems={statusItems}
      title={"Select Task Status"}
      variant={variant}
      textColor={!!value ? "primary" : "secondary"}
    >
      {value?.icon && (
        <value.icon
          color={value.color}
          size={13}
          style={{ marginRight: showLabel ? 5 : 0 }}
        />
      )}
      {showLabel && <>{value?.status_name || "Status"}</>}
    </DropdownMenu>
  );
};

export default TaskStatusSelector;
