import { monolithMoment } from "../../utils/date-format";

const UserRoleDefs = [
  {
    dataField: "uuid",
    caption: "UUID",
    visible: false,
  },
  {
    dataField: "name",
    caption: "Name",
  },
  {
    dataField: "description",
    caption: "Description",
  },
  {
    dataField: "created_on",
    caption: "Created On",
    render: (data) => {
      return monolithMoment({
        timestamp: data.created_on,
        includeTime: true,
        includeZone: true,
      });
    },
  },
];

export default UserRoleDefs;
