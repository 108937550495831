import { useInfiniteQuery } from "@tanstack/react-query";
import { SelectBox, TagBox } from "@monolith-forensics/monolith-ui";
import { useDebouncedCallback } from "use-debounce";
import { AnalysisAPI } from "../../../../api/index.js";
import { useState } from "react";

const PAGE_SIZE = 500;

const TraceAccountSelector = ({ query, multiple = false, ...props }) => {
  const [searchValue, setSearchValue] = useState(null);
  const defaultQuery = {
    ...query,
    search: searchValue,
    pageSize: PAGE_SIZE,
  };

  const {
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ["trace-accounts", "list", defaultQuery],
    queryFn: ({ pageParam, queryKey }) => {
      const [, , query] = queryKey;
      return AnalysisAPI.Accounts.get({
        ...query,
        page: pageParam,
      });
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.nextPage;
    },
    getPreviousPageParam: (firstPage, pages) => {
      if (firstPage.page - 1 === 0) return null;
      return firstPage.page - 1;
    },
    initialPageParam: 1,
    placeholderData: (data) => data,
  });

  const debouncedFetchNextPage = useDebouncedCallback((e) => {
    fetchNextPage();
  }, 50);

  // Detect scroll to bottom
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 100 * data?.pages?.length) {
      if (hasNextPage && !isFetchingNextPage) {
        debouncedFetchNextPage();
      }
    }
  };

  const records = data?.pages
    ?.reduce((acc, page) => {
      return [...acc, ...page.data];
    }, [])
    .map((item) => ({
      label: item.name,
      value: item.id,
      data: item,
    }));

  if (multiple) {
    return (
      <TagBox
        {...props}
        loading={isLoading}
        data={records}
        onScroll={handleScroll}
        searchFn={setSearchValue}
      />
    );
  }

  return (
    <SelectBox
      {...props}
      loading={isLoading}
      data={records}
      onScroll={handleScroll}
      searchFn={setSearchValue}
    />
  );
};

export default TraceAccountSelector;
