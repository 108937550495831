import { getOrgInfo } from "../../api";
import resolveCustomFieldValue from "../customFieldValueResolver";
import { monolithMoment } from "../date-format";

interface LabelVariable {
  value: string;
  description: string;
}

interface LabelVariables {
  Organization: LabelVariable[];
  Evidence: LabelVariable[];
  Storage: LabelVariable[];
  People: LabelVariable[];
}

interface LabelStrings {
  [key: string]: string;
  value: any; //TODO: Replace with expected value types from API responses
}

export const labelVariables: LabelVariables = {
  Organization: [
    {
      value: "{{ organization.name }}",
      description: "Your organization's name.",
    },
    {
      value: "{{ organization.address }}",
      description: "Your organization's address.",
    },
    {
      value: "{{ organization.city }}",
      description: "Your organization's city.",
    },
    {
      value: "{{ organization.state }}",
      description: "Your organization's state.",
    },
    {
      value: "{{ organization.postal }}",
      description: "Your organization's postal code.",
    },
    {
      value: "{{ organization.phone }}",
      description: "Your organization's phone number.",
    },
    {
      value: "{{ organization.website }}",
      description: "Your organization's website URL.",
    },
  ],
  Evidence: [
    {
      value: "{{ evidence.evidence_number }}",
      description: "The name of the evidence.",
    },
    {
      value: "{{ evidence.case_number }}",
      description: "The case number for this evidence item.",
    },
    {
      value: "{{ evidence.case_name }}",
      description: "The name of the case for this evidence item.",
    },
    {
      value: "{{ evidence.location }}",
      description: "The current location of the evidence.",
    },
    {
      value: "{{ evidence.type }}",
      description: "The evidence type.",
    },
    {
      value: "{{ evidence.brand }}",
      description: "The evidence manufacturer or service provider.",
    },
    {
      value: "{{ evidence.model_number }}",
      description: "The evidence model number.",
    },
    {
      value: "{{ evidence.serial_number }}",
      description: "The evidence serial number or account name.",
    },
    {
      value: "{{ evidence.item_name }}",
      description: "The evidence item or device name.",
    },
    {
      value: "{{ evidence.size }}",
      description: "The evidence size in selected units.",
    },
    {
      value: "{{ evidence.description }}",
      description: "The evidence description or notes.",
    },
    {
      value: "{{ evidence.organization }}",
      description:
        "The organization name of the client this evidence was collected for. Org name is pulled from client associated with case.",
    },
    {
      value: "{{ evidence.parent }}",
      description: "The parent evidence name if applicable.",
    },
    {
      value: "{{ evidence.progress }}",
      description: "The current evidence progress.",
    },
    {
      value: "{{ evidence.intake_timestamp }}",
      description: "The evidence intake timestamp.",
    },
    {
      value: "{{ evidence.received_by }}",
      description: "The person who received this item at intake.",
    },
    {
      value: "{{ evidence.received_from }}",
      description: "The person that provided this item at intake.",
    },
    {
      value: "{{ evidence.acquired }}",
      description: "Whether data has been collected from this item.",
    },
    {
      value: "{{ evidence.acq_count }}",
      description: "The number of acquisitions for this evidence item.",
    },
    {
      value: "{{ evidence.photographed }}",
      description: "Whether this evidence item has been photographed.",
    },
    {
      value: "{{ evidence.photo_count }}",
      description: "The number of photos for this evidence item.",
    },
    {
      value: "{{ evidence.has_child }}",
      description: "Whether this evidence item has child evidence.",
    },
    {
      value: "{{ evidence.child_count }}",
      description: "The number of child evidence items for this evidence item.",
    },
    {
      value: "{{ evidence.kind }}",
      description: "Whether this item is a parent or child evidence item.",
    },
    {
      value: "{{ evidence.linked_contact }}",
      description:
        "The linked contact for this item (custodian, suspect, victim, etc...).",
    },
    {
      value: "{{ evidence.uuid }}",
      description:
        "A globally unique UUID for this evidence item. Can be used to generate QR codes for scanning. Scanning this value in Monolith will automatically find and navigate to the item.",
    },
    {
      value: "{{ evidence.short_uuid }}",
      description:
        "A short UUID for this evidence item. Can be used to generate barcodes for scanning. Scanning this barcode value in Monolith will automatically find and navigate to the item.",
    },
  ],
  Storage: [
    {
      value: "{{ storage.storage_number }}",
      description: "The number of this storage item.",
    },
    {
      value: "{{ storage.case_number }}",
      description:
        "The case number for this storage item if it is assigned to a case.",
    },
    {
      value: "{{ storage.case_name }}",
      description:
        "The name of the case for this storage item if it is assigned to a case.",
    },
    {
      value: "{{ storage.type }}",
      description: "The storage item type.",
    },
    {
      value: "{{ storage.make }}",
      description: "The storage item manufacturer.",
    },
    {
      value: "{{ storage.model_name }}",
      description: "The storage item model name.",
    },
    {
      value: "{{ storage.model_number }}",
      description: "The storage item model number.",
    },
    {
      value: "{{ storage.serial_number }}",
      description: "The storage item serial number.",
    },
    {
      value: "{{ storage.size }}",
      description: "The storage item size in selected units.",
    },
    {
      value: "{{ storage.description }}",
      description: "The storage item description or notes.",
    },
    {
      value: "{{ storage.date_created }}",
      description: "The storage item creation date.",
    },
    {
      value: "{{ storage.location }}",
      description: "The storage item's current location if applicable.",
    },
    {
      value: "{{ storage.uuid }}",
      description:
        "The storage item's UUID. Use this to generate QA codes for scanning. When this value is scanned, Monolith will automatically find and navigate to the item.",
    },
    {
      value: "{{ storage.short_uuid }}",
      description:
        "The storage item's short UUID. Use this to generate barcodes for scanning. When this value is scanned, Monolith will automatically find and navigate to the item.",
    },
  ],
  People: [
    {
      value: "{{ people.name }}",
      description: "The client or contact name.",
    },
    {
      value: "{{ people.address }}",
      description: "The client or contact address.",
    },
    {
      value: "{{ people.city }}",
      description: "The client or contact city.",
    },
    {
      value: "{{ people.state }}",
      description: "The client or contact state or province.",
    },
    {
      value: "{{ people.postal }}",
      description: "The client or contact postal code.",
    },
    {
      value: "{{ people.organization }}",
      description: "The client or contact organization if applicable.",
    },
    {
      value: "{{ people.phone }}",
      description: "The client or contact phone number.",
    },
    {
      value: "{{ people.mobile }}",
      description: "The client or contact mobile phone number.",
    },
    {
      value: "{{ people.email }}",
      description: "The client or contact email address.",
    },
    {
      value: "{{ people.type }}",
      description: "The client or contact type.",
    },
    {
      value: "{{ people.notes }}",
      description: "The client or contact notes or description.",
    },
  ],
};

export const legacy_labelVariables: LabelVariables = {
  Organization: [
    {
      value: "#org_name",
      description: "Your organization's name.",
    },
    { value: "#org_address", description: "Your organization's address." },
    { value: "#org_city", description: "Your organization's city." },
    { value: "#org_state", description: "Your organization's state." },
    { value: "#org_postal", description: "Your organization's postal code." },
    { value: "#org_phone", description: "Your organization's phone number." },
    { value: "#org_website", description: "Your organization's website URL." },
  ],
  Evidence: [
    { value: "#evidence_number", description: "The name of the evidence." },
    {
      value: "#evidence_case_number",
      description: "The case number for this evidence item.",
    },
    {
      value: "#evidence_case_name",
      description: "The name of the case for this evidence item.",
    },
    {
      value: "#evidence_case_ref",
      description: "The name of the case for this evidence item.",
    },
    {
      value: "#evidence_location",
      description: "The current location of the evidence.",
    },
    {
      value: "#evidence_type",
      description: "The evidence type.",
    },
    {
      value: "#evidence_brand",
      description: "The evidence manufacturer or service provider.",
    },
    {
      value: "#evidence_model_number",
      description: "The evidence model number.",
    },
    {
      value: "#evidence_serial_number",
      description: "The evidence serial number or account name.",
    },
    {
      value: "#evidence_device_name",
      description: "The evidence device name. (deprecated)",
    },
    {
      value: "#evidence_item_name",
      description:
        'The evidence item name. This is a replacement for "#evidence_device_name"',
    },
    {
      value: "#evidence_size",
      description: "The evidence size in selected units.",
    },
    {
      value: "#evidence_description",
      description: "The evidence description or notes.",
    },
    {
      value: "#evidence_organization",
      description:
        "The organization name of the client this evidence was collected for.  Org name is pulled from client associated with case.",
    },
    {
      value: "#evidence_parent",
      description: "The parent evidence name if applicable.",
    },
    {
      value: "#evidence_progress",
      description: "The current evidence progress.",
    },
    {
      value: "#evidence_intake_timestamp",
      description: "The evidence intake timestamp.",
    },
    {
      value: "#evidence_received_by",
      description: "The person who received this item at intake.",
    },
    {
      value: "#evidence_received_from",
      description: "The person that provided this item at intake.",
    },
    {
      value: "#evidence_acquired",
      description: "Whether data has been collected from this item.",
    },
    {
      value: "#evidence_acq_count",
      description: "The number of acquisitions for this evidence item.",
    },
    {
      value: "#evidence_photographed",
      description: "Whether this evidence item has been photographed.",
    },
    {
      value: "#evidence_photo_count",
      description: "The number of photos for this evidence item.",
    },
    {
      value: "#evidence_has_child",
      description: "Whether this evidence item has child evidence.",
    },
    {
      value: "#evidence_child_count",
      description: "The number of child evidence items for this evidence item.",
    },
    {
      value: "#evidence_kind",
      description: "Whether this item is a parent or child evidence item.",
    },
    {
      value: "#evidence_linked_contact",
      description:
        "The linked contact for this item (custodian, suspect, victim, etc...).",
    },
    {
      value: "#evidence_uuid",
      description:
        "A globally unique UUID for this evidence item.  Can be used to generate QR codes for scanning.  Scanning this value in Monolith will automatically find and navigate to the item.",
    },
    {
      value: "#evidence_short_uuid",
      description:
        "A short UUID for this evidence item.  Can be used to generate barcodes for scanning.  Scanning this barcode value in Monolith will automatically find and navigate to the item.",
    },
  ],
  Storage: [
    {
      value: "#storage_number",
      description: "The number of this storage item.",
    },
    {
      value: "#storage_case_number",
      description:
        "The case number for this storage item if it is assigned to a case.",
    },
    {
      value: "#storage_case_name",
      description:
        "The name of the case for this storage item if it is assigned to a case.",
    },
    { value: "#storage_type", description: "The storage item type." },
    { value: "#storage_make", description: "The storage item manufacturer." },
    {
      value: "#storage_model_name",
      description: "The storage item model name.",
    },
    {
      value: "#storage_model_number",
      description: "The storage item model number.",
    },
    {
      value: "#storage_serial_number",
      description: "The storage item serial number.",
    },
    {
      value: "#storage_size",
      description: "The storage item size in selected units.",
    },
    {
      value: "#storage_description",
      description: "The storage item description or notes.",
    },
    {
      value: "#storage_date_created",
      description: "The storage item creation date.",
    },
    {
      value: "#storage_location",
      description: "The storage item's current location if applicable.",
    },
    {
      value: "#storage_uuid",
      description:
        "The storage item's UUID. Use this to generate QA codes for scanning.  When this value is scanned, Monolith will automatically find and navigate to the item.",
    },
    {
      value: "#storage_short_uuid",
      description:
        "The storage item's short UUID. Use this to generate barcodes for scanning.  When this value is scanned, Monolith will automatically find and navigate to the item.",
    },
  ],
  People: [
    { value: "#association_name", description: "The client or contact name." },
    {
      value: "#association_address",
      description: "The client or contact address.",
    },
    { value: "#association_city", description: "The client or contact city." },
    {
      value: "#association_state",
      description: "The client or contact state or province.",
    },
    {
      value: "#association_postal",
      description: "The client or contact postal code.",
    },
    {
      value: "#association_organization",
      description: "The client or contact organization if applicatble.",
    },
    {
      value: "#association_phone",
      description: "The client or contact phone number.",
    },
    {
      value: "#association_mobile",
      description: "The client or contact mobile phone number.",
    },
    {
      value: "#association_email",
      description: "The client or contact email address.",
    },
    { value: "#association_type", description: "The client or contact type." },
    {
      value: "#association_notes",
      description: "The client or contact notes or description.",
    },
  ],
};

const resolveCustomFields = (customFields: any[], prefix: string) => {
  return (
    customFields?.map((cf: any) => ({
      string: `{{ ${prefix}.custom_field_${cf.field_id} }}`,
      value: resolveCustomFieldValue(cf),
    })) || []
  );
};
const resolveCustomFields_LEGACY = (customFields: any[]) => {
  return (
    customFields?.map((cf: any) => ({
      string: `#custom_field_${cf.field_id}`,
      value: resolveCustomFieldValue(cf),
    })) || []
  );
};

export async function customizeLabel(labelXML: any, itemInfo: any) {
  const orgInfo = await getOrgInfo();
  const { evidence, storage, association } = itemInfo;

  let strings: LabelStrings[] = [
    { string: "#org_name", value: orgInfo.name || "" },
    { string: "#org_address", value: orgInfo.address || "" },
    { string: "#org_city", value: orgInfo.city || "" },
    { string: "#org_postal", value: orgInfo.zipcode || "" },
    { string: "#org_state", value: orgInfo.state || "" },
    { string: "#org_phone", value: orgInfo.phone || "" },
    { string: "#org_website", value: orgInfo.website || "" },
    { string: "{{ organization.name }}", value: orgInfo.name || "" },
    { string: "{{ organization.address }}", value: orgInfo.address || "" },
    { string: "{{ organization.city }}", value: orgInfo.city || "" },
    { string: "{{ organization.postal }}", value: orgInfo.zipcode || "" },
    { string: "{{ organization.state }}", value: orgInfo.state || "" },
    { string: "{{ organization.phone }}", value: orgInfo.phone || "" },
    { string: "{{ organization.website }}", value: orgInfo.website || "" },
  ];

  if (evidence) {
    strings = [
      ...strings,
      ...resolveCustomFields(evidence?.custom_attributes, "evidence"),
      ...resolveCustomFields_LEGACY(evidence?.custom_attributes),
      { string: "#case_number", value: evidence.case_number || "" },
      { string: "#location_name", value: evidence.location_name || "" },
      { string: "#case_reference", value: evidence.case_ref || "" },
      { string: "#evidence_case_number", value: evidence.case_number || "" },
      { string: "#evidence_location", value: evidence.location_name || "" },
      { string: "#evidence_case_ref", value: evidence.case_ref || "" },
      { string: "#evidence_case_name", value: evidence.case_ref || "" },
      { string: "#evidence_number", value: evidence.evidence_number || "" },
      { string: "#evidence_type", value: evidence.item_type || "" },
      { string: "#evidence_brand", value: evidence.manufacturer || "" },
      { string: "#evidence_model_number", value: evidence.model_number || "" },
      { string: "#evidence_device_name", value: evidence.model_name || "" },
      { string: "#evidence_item_name", value: evidence.model_name || "" },
      {
        string: "#evidence_serial_number",
        value: evidence.serial_number || "",
      },
      {
        string: "#evidence_size",
        value: evidence.capacity
          ? evidence.capacity + " " + evidence.capacity_unit
          : "",
      },
      { string: "#evidence_description", value: evidence.description || "" },
      {
        string: "#evidence_organization",
        value: evidence.client_organization || "",
      },
      { string: "#evidence_parent", value: evidence.parent_name || "" },
      { string: "#evidence_progress", value: evidence.progress || "" },
      {
        string: "#evidence_intake_timestamp",
        value: monolithMoment({
          timestamp: evidence.intake_date,
          includeTime: true,
        }),
      },
      { string: "#evidence_received_by", value: evidence.received_by || "" },
      {
        string: "#evidence_received_from",
        value: evidence.received_from || "",
      },
      { string: "#evidence_acquired", value: evidence.is_acquired || "" },
      {
        string: "#evidence_acq_count",
        value: evidence.acquisition_count || "",
      },
      { string: "#evidence_photographed", value: evidence.has_photos || "" },
      { string: "#evidence_photo_count", value: evidence.photo_count || "" },
      { string: "#evidence_has_child", value: evidence.has_children || "" },
      { string: "#evidence_child_count", value: evidence.child_count || "" },
      { string: "#evidence_kind", value: evidence.is_parent_child || "" },
      {
        string: "#evidence_linked_contact",
        value: evidence.contact_name || "",
      },
      { string: "#evidence_uuid", value: evidence.uuid || "" },
      {
        string: "#evidence_short_uuid",
        value: evidence.uuid.substring(0, 8) || "",
      },
      {
        string: "{{ evidence.case_number }}",
        value: evidence.case_number || "",
      },
      {
        string: "{{ evidence.case_name }}",
        value: evidence.case_ref || "",
      },
      {
        string: "{{ evidence.location }}",
        value: evidence.location_name || "",
      },
      {
        string: "{{ evidence.evidence_number }}",
        value: evidence.evidence_number || "",
      },
      {
        string: "{{ evidence.type }}",
        value: evidence.item_type || "",
      },
      {
        string: "{{ evidence.brand }}",
        value: evidence.manufacturer || "",
      },
      {
        string: "{{ evidence.model_number }}",
        value: evidence.model_number || "",
      },
      {
        string: "{{ evidence.item_name }}",
        value: evidence.model_name || "",
      },
      {
        string: "{{ evidence.serial_number }}",
        value: evidence.serial_number || "",
      },
      {
        string: "{{ evidence.size }}",
        value: evidence.capacity
          ? evidence.capacity + " " + evidence.capacity_unit
          : "",
      },
      {
        string: "{{ evidence.description }}",
        value: evidence.description || "",
      },
      {
        string: "{{ evidence.organization }}",
        value: evidence.client_organization || "",
      },
      {
        string: "{{ evidence.parent }}",
        value: evidence.parent_name || "",
      },
      {
        string: "{{ evidence.progress }}",
        value: evidence.progress || "",
      },
      {
        string: "{{ evidence.intake_timestamp }}",
        value: monolithMoment({
          timestamp: evidence.intake_date,
          includeTime: true,
        }),
      },
      {
        string: "{{ evidence.received_by }}",
        value: evidence.received_by || "",
      },
      {
        string: "{{ evidence.received_from }}",
        value: evidence.received_from || "",
      },
      {
        string: "{{ evidence.acquired }}",
        value: evidence.is_acquired || "",
      },
      {
        string: "{{ evidence.acq_count }}",
        value: evidence.acquisition_count || "",
      },
      {
        string: "{{ evidence.photographed }}",
        value: evidence.has_photos || "",
      },
      {
        string: "{{ evidence.photo_count }}",
        value: evidence.photo_count || "",
      },
      {
        string: "{{ evidence.has_child }}",
        value: evidence.has_children || "",
      },
      {
        string: "{{ evidence.child_count }}",
        value: evidence.child_count || "",
      },
      {
        string: "{{ evidence.kind }}",
        value: evidence.is_parent_child || "",
      },
      {
        string: "{{ evidence.linked_contact }}",
        value: evidence.contact_name || "",
      },
      { string: "{{ evidence.uuid }}", value: evidence.uuid || "" },
      {
        string: "{{ evidence.short_uuid }}",
        value: evidence.uuid.substring(0, 8) || "",
      },
    ];
  }

  if (storage) {
    strings = [
      ...strings,
      ...resolveCustomFields(storage?.custom_attributes, "storage"),
      ...resolveCustomFields_LEGACY(storage?.custom_attributes),
      { string: "#storage_case_number", value: storage.case_number || "" },
      { string: "#storage_case_name", value: storage.case_ref || "" },
      { string: "#storage_number", value: storage.storage_number || "" },
      { string: "#storage_type", value: storage.type || "" },
      { string: "#storage_make", value: storage.make || "" },
      { string: "#storage_model_name", value: storage.model_name || "" },
      { string: "#storage_model_number", value: storage.model_number || "" },
      { string: "#storage_serial_number", value: storage.serial_number || "" },
      {
        string: "#storage_size",
        value: `${storage.capacity} ${storage.capacity_unit}` || "",
      },
      { string: "#storage_description", value: storage.notes || "" },
      {
        string: "#storage_date_created",
        value: monolithMoment({ timestamp: storage.date_added }),
      },
      { string: "#storage_location", value: storage.location_name || "" },
      { string: "#location_name", value: storage.location_name || "" },
      { string: "#storage_uuid", value: storage.uuid || "" },
      {
        string: "#storage_short_uuid",
        value: storage.uuid.substring(0, 8) || "",
      },
      {
        string: "{{ storage.case_number }}",
        value: storage.case_number || "",
      },
      {
        string: "{{ storage.case_name }}",
        value: storage.case_ref || "",
      },
      {
        string: "{{ storage.storage_number }}",
        value: storage.storage_number || "",
      },
      { string: "{{ storage.type }}", value: storage.type || "" },
      { string: "{{ storage.make }}", value: storage.make || "" },
      {
        string: "{{ storage.model_name }}",
        value: storage.model_name || "",
      },
      {
        string: "{{ storage.model_number }}",
        value: storage.model_number || "",
      },
      {
        string: "{{ storage.serial_number }}",
        value: storage.serial_number || "",
      },
      {
        string: "{{ storage.size }}",
        value: `${storage.capacity} ${storage.capacity_unit}` || "",
      },
      {
        string: "{{ storage.description }}",
        value: storage.notes || "",
      },
      {
        string: "{{ storage.date_created }}",
        value: monolithMoment({ timestamp: storage.date_added }),
      },
      {
        string: "{{ storage.location }}",
        value: storage.location_name || "",
      },
      { string: "{{ storage.uuid }}", value: storage.uuid || "" },
      {
        string: "{{ storage.short_uuid }}",
        value: storage.uuid.substring(0, 8) || "",
      },
    ];
  }

  if (association) {
    strings = [
      ...strings,
      { string: "#association_name", value: itemInfo.association.name || "" },
      {
        string: "#association_address",
        value: itemInfo.association.address || "",
      },
      { string: "#association_city", value: itemInfo.association.city || "" },
      { string: "#association_state", value: itemInfo.association.state || "" },
      {
        string: "#association_postal",
        value: itemInfo.association.zipcode || "",
      },
      {
        string: "#association_org",
        value: itemInfo.association.organization || "",
      },
      {
        string: "#association_phone",
        value: itemInfo.association.office_number || "",
      },
      {
        string: "#association_mobile",
        value: itemInfo.association.mobile_number || "",
      },
      { string: "#association_email", value: itemInfo.association.email || "" },
      {
        string: "#association_type",
        value: itemInfo.association.contact_type || "",
      },
      { string: "#association_notes", value: itemInfo.association.notes || "" },

      {
        string: "{{ people.name }}",
        value: itemInfo.association.name || "",
      },
      {
        string: "{{ people.address }}",
        value: itemInfo.association.address || "",
      },
      {
        string: "{{ people.city }}",
        value: itemInfo.association.city || "",
      },
      {
        string: "{{ people.state }}",
        value: itemInfo.association.state || "",
      },
      {
        string: "{{ people.postal }}",
        value: itemInfo.association.zipcode || "",
      },
      {
        string: "{{ people.organization }}",
        value: itemInfo.association.organization || "",
      },
      {
        string: "{{ people.phone }}",
        value: itemInfo.association.office_number || "",
      },
      {
        string: "{{ people.mobile }}",
        value: itemInfo.association.mobile_number || "",
      },
      {
        string: "{{ people.email }}",
        value: itemInfo.association.email || "",
      },
      {
        string: "{{ people.type }}",
        value: itemInfo.association.contact_type || "",
      },
      {
        string: "{{ people.notes }}",
        value: itemInfo.association.notes || "",
      },
    ];
  }

  for (const item of strings) {
    let regex = new RegExp(item.string, "g");
    labelXML = labelXML.replace(regex, handleSpecialChars(item.value));

    regex = new RegExp(item.string.toUpperCase(), "g");
    labelXML = labelXML.replace(regex, handleSpecialChars(item.value));
  }

  return labelXML;
}

export const handleSpecialChars = (str: string) => {
  return str
    .toString()
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&apos;");
};
