import styled from "styled-components";
import SidebarNavList from "./SidebarNavList.js";

const Title = styled.h4`
  color: #999;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 0.5rem;
  padding-left: 1.75rem;

  text-transform: uppercase;
  display: block;
  margin: 0;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 10px 0px;

  overflow-y: auto;
`;

const InnerList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  &[data-collapsed="true"] {
    align-items: center;
    margin-top: 10px;
  }
`;

const SidebarNav = ({ items, collapsed }) => {
  return (
    <List>
      {items &&
        items.map((item, index) => {
          return (
            <InnerList
              key={item.title}
              collapsed={collapsed}
              data-collapsed={collapsed}
            >
              {item?.title && <Title>{item?.title}</Title>}
              <SidebarNavList
                pages={item?.pages}
                depth={0}
                collapsed={collapsed}
              />
            </InnerList>
          );
        })}
    </List>
  );
};

export default SidebarNav;
