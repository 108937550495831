import QaChecklistAPI from "../../api/quality_assurance/checklists.js";
import { Modal } from "@monolith-forensics/monolith-ui";
import DefaultQaChecklistForm from "../Forms/DefaultQaChecklistForm.js";

const EditQaChecklistModal = ({
  show,
  defaultInfo,
  onClose,
  onSubmit,
  onCancel,
  ...props
}) => {
  const handleSubmit = async (data, diff) => {
    if (Object.keys(diff).length > 0)
      await QaChecklistAPI.updateQAChecklist({
        checklist_id: defaultInfo.checklist_id,
        ...diff,
      });
    onSubmit?.(data);
  };

  return (
    <Modal open={show} onClose={onClose}>
      <Modal.Title>Update QA Checklist</Modal.Title>
      <Modal.SubTitle style={{ marginBottom: 15 }}>
        Update the name of this Quality Assurance checklist.
      </Modal.SubTitle>
      <DefaultQaChecklistForm
        defaultFormData={defaultInfo}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        {...props}
      />
    </Modal>
  );
};

export default EditQaChecklistModal;
