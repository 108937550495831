import { Link } from "react-router-dom";
import styled from "styled-components";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";

const CaseStorageCell = styled(({ className, rowData, color }) => {
  if (!rowData.storage_id) return <></>;

  return (
    <Link
      className={className}
      to={`/storage/${rowData.storage_id}`}
      state={{ from: "case:storage" }}
    >
      <div
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          {!!rowData.storage_number
            ? rowData.storage_number
            : "View Storage Item"}
        </div>
        <LaunchOutlinedIcon
          className="action-button"
          style={{ fontSize: 13, marginLeft: 5 }}
        />
      </div>
    </Link>
  );
})(({ theme, rowData, color }) => ({
  color: theme.palette.primary.main,
  // hover
  "&:hover": {
    textDecoration: "underline",
  },
}));

export default CaseStorageCell;
