import { httpGet } from "../connection.js";

async function getMetrics(query = {}) {
  return httpGet("/metrics", query);
}

const MetricsAPI = {
  getMetrics,
};

export default MetricsAPI;
