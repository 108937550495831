import { BotIcon, FileSpreadsheetIcon, Slack } from "lucide-react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const IntegrationItems = [
  {
    name: "Forensic Partner Reports",
    description:
      "Generate an FPR excel report to submit to the USSS to report case and evidence metrics.",
    icon: FileSpreadsheetIcon,
    href: "forensic-partner-reports",
  },
  {
    name: "Mattermost",
    description: "Send notifications to a Mattermost channel.",
    icon: BotIcon,
    href: "mattermost",
  },
];

const IntegrationItem = styled.div`
  user-select: none;
  cursor: pointer;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.palette.input.border};
  border-radius: 4px;

  display: flex;
  flex-direction: row;

  width: 300px;
  height: 150px;

  &:hover {
    box-shadow: 2px 2px 5px 0px
      ${({ theme }) =>
        theme.name === "DARK" ? "rgba(0, 0, 0, 0.8)" : "rgba(0, 0, 0, 0.2)"};

    // move up 1 pixel
    transform: translateY(-1px);
  }
`;

const IntegrationIcon = styled.div`
  width: 40px;
  min-width: 40px;
`;

const IntegrationTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;

  // check if parent is hovered
  ${IntegrationItem}:hover & {
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: underline;
  }
`;

const IntegrationDescription = styled.div``;

const IntegrationsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
`;

const IntegrationsList = styled(({ className }) => {
  return (
    <IntegrationsGrid>
      {IntegrationItems.map((integration) => {
        return (
          <Link key={integration.name} to={integration.href}>
            <IntegrationItem>
              <IntegrationIcon>
                <integration.icon size={30} />
              </IntegrationIcon>
              <div>
                <IntegrationTitle>{integration.name}</IntegrationTitle>
                <IntegrationDescription>
                  {integration.description}
                </IntegrationDescription>
              </div>
            </IntegrationItem>
          </Link>
        );
      })}
    </IntegrationsGrid>
  );
})``;

export default IntegrationsList;
