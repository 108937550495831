import { ArrowRightIcon, FolderOpenIcon } from "lucide-react";
import { Link } from "react-router-dom";
import * as Tooltip from "@radix-ui/react-tooltip";
import styled from "styled-components";
import CasesApi from "../../../api/cases/index.js";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../Loader.js";

const CaseInfo = styled(({ className, caseInfo }) => {
  const { data } = useQuery({
    queryKey: ["cases", "list", { uuid: caseInfo.uuid }],
    queryFn: () => CasesApi.getCases({ uuid: caseInfo.uuid }),
  });

  if (!data) return <Loader />;

  const caseData = data[0];

  return (
    <div className={className}>
      <h4>{caseData.case_ref}</h4>
      <div className="detail-item">
        <div className="detail-label">Case Number</div>
        <div className="detail-value">{caseData.case_number}</div>
      </div>
      <div className="detail-item">
        <div className="detail-label">Client</div>
        <div className="detail-value">{caseData.client_name || "None"}</div>
      </div>
      <div className="detail-item">
        <div className="detail-label">Organization</div>
        <div className="detail-value">
          {caseData.client_organization || "None"}
        </div>
      </div>
      <div className="detail-item">
        <div className="detail-label">Status</div>
        <div className="detail-value">{caseData.case_status || "None"}</div>
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: ${({ theme }) => theme.palette.text.primary};

  .detail-item {
    display: flex;
    flex-direction: row;
    gap: 5px;
    font-size: 0.6rem;
  }

  .detail-label {
    font-weight: bold;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .detail-value {
  }
`;

const LinkedObject = styled(
  ({ className, object, href, variant = "text", ...props }) => {
    return (
      <Link to={href || ""}>
        <Tooltip.Provider delayDuration={600}>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <div
                className={`${className} linked-object ${variant}`}
                {...props}
              >
                {object?.id ? (
                  <div className="text-container">
                    <FolderOpenIcon size={10} className={"folderIcon"} />
                    <div className="object-name">{object.name}</div>
                    {href && (
                      <ArrowRightIcon size={12} className={"arrowIcon"} />
                    )}
                  </div>
                ) : (
                  <div>Unlinked</div>
                )}
              </div>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content
                className={className + " tooltip-content"}
                side="bottom"
                sideOffset={10}
                align="start"
                onClick={(e) => e.stopPropagation()}
              >
                {object.type === "case" && (
                  <CaseInfo caseInfo={{ uuid: object.id }} />
                )}
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      </Link>
    );
  }
)`
  max-width: 135px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 0.65rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;

  &.highlighted {
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: ${({ theme }) => theme.palette.primary.main}25;
    padding: 2px 5px;
    border-radius: 5px;
  }

  .object-name {
    max-width: 125px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .folderIcon {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  .arrowIcon {
    display: none;
  }

  &:hover {
    text-decoration: underline;
    .arrowIcon {
      display: block;
    }
  }

  &.tooltip-content {
    color: ${({ theme }) => theme.palette.text.primary};
    display: flex;
    flex-direction: column;
    pointer-events: none;
    justify-content: center;
    align-items: flex-start;
    z-index: 100000;
    background-color: ${({ theme }) => theme.palette.background.default};
    padding: 15px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.palette.divider};
    font-size: 0.75rem;
    max-width: 300px;
    width: 250px;

    h2,
    h3,
    h4 {
      margin: 0;
    }

    p {
      margin: 0;
    }
  }
`;

export default LinkedObject;
