import { useQuery } from "@tanstack/react-query";
import { SelectBox, TagBox } from "@monolith-forensics/monolith-ui";
import { AnalysisAPI } from "../../../../api/index.js";

const TimelineArtifactSelector = ({ multiple = false, ...props }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["timeline", "artifact-types"],
    queryFn: () => AnalysisAPI.Artifacts.get(),
  });

  const records =
    data?.map?.((item) => ({
      label: item.artifact,
      value: item.artifact_id,
      data: item,
    })) || [];

  if (multiple) {
    return <TagBox {...props} loading={isLoading} data={records} />;
  }

  return <SelectBox {...props} loading={isLoading} data={records} />;
};

export default TimelineArtifactSelector;
