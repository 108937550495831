import { httpDelete, httpGet, httpPatch, httpPost } from "../connection";
import download from "downloadjs";

export async function getStorage(options = {}) {
  return (await httpGet(`/storage/items`, options)).data;
}

async function getStorageNumbers(options = {}) {
  return await httpGet(`/storage/numbers`, options);
}

async function query(query) {
  return await httpPost(`/storage/query`, query);
}

async function exportStorageList(query) {
  return await httpPost(`/storage/export`, query);
}

export async function createStorage(values) {
  return await httpPost(`/storage/items`, values);
}

export async function updateStorage(storage_id, values = {}) {
  return await httpPatch(`/storage/items/${storage_id}`, values);
}

export async function updateStorageByCase(storage_id, values) {
  return await httpPatch(
    `/storage/items/${storage_id}?case_assignment_id=${values.case_assignment_id}`,
    values
  );
}

export async function moveStorage(values) {
  return await httpPatch(`/storage/items/move`, values);
}

export async function moveStorageItems(values) {
  return await httpPatch(`/storage/items/move-items`, values);
}

export async function getStorageData(storage_id) {
  return await httpGet(`/storage/items/${storage_id}/data`);
}

export async function deleteStorage({ storage_id }) {
  return await httpDelete(`/storage/items/${storage_id}`);
}

export async function getStorageTypes() {
  return await httpGet(`/storage/types`);
}

export async function getStorageSizes() {
  return await httpGet(`/storage/sizes`);
}

export async function getStorageBrands() {
  return await httpGet(`/storage/brands`);
}

export async function getStorageModels() {
  return await httpGet(`/storage/models`);
}

export async function getStorageModelNumbers() {
  return await httpGet(`/storage/model-numbers`);
}

export async function getStorageMeta() {
  return await httpGet(`/storage/meta`);
}

export async function assignStorageToCase(data = {}) {
  return await httpPost(`/storage/items/assign`, data);
}

export async function removeStorageFromCase(data) {
  return await httpPost(`/storage/items/unassign`, data);
}

export async function recycleStorageItem(data = {}) {
  return await httpPost(`/storage/items/recycle`, data);
}

export async function getStorageCocRecords(data = {}) {
  return await httpGet(`/storage/items/${data.storage_id}/coc`, {
    case_assignment_id: data.case_assignment_id,
  });
}

export async function getStorageCocRecord(options = { coc_id: null }) {
  return await httpGet(`/storage/coc/${options.coc_id}`);
}

export async function getStorageCustomAttributes(
  options = { fieldsOnly: false }
) {
  return httpGet(`/storage/attributes?fieldsOnly=${options.fieldsOnly}`);
}

export async function createStorageCustomField(
  values = { field_name: null, is_required: null, type_id: null }
) {
  return await httpPost(`/storage/attributes/field`, values);
}

export async function updateStorageCustomField(id, values = {}) {
  return await httpPost(`/storage/attributes/field/${id}`, values);
}

export async function deleteStorageCustomField(id) {
  return httpDelete(`/storage/attributes/field/${id}`);
}

export async function createStorageCocRecord(
  values = {
    storage_id: 0,
    custody_to: "",
    custody_from: "",
    reason: "",
    timestamp: "",
    entered_by: "",
  }
) {
  return await httpPost(`/storage/items/${values.storage_id}/coc`, values);
}

export async function createStorageIntakeDetails(values = {}) {
  return await httpPost(`/storage/coc/intake-details`, values);
}

export async function updateStorageCocRecord(id, values) {
  return await httpPatch(`/storage/coc/${id}`, values);
}

export async function deleteStorageCocRecord(id) {
  return await httpDelete(`/storage/coc/${id}`);
}

export async function getStorageCocMoveReasons() {
  return await httpGet(`/storage/coc/move-reasons`);
}

export async function getStorageNumberFormat() {
  return await httpGet(`/storage/format`);
}

export async function updateStorageNumberFormat(values) {
  return await httpPatch(`/storage/format`, values);
}

export async function createStorageCocPDF(values) {
  const response = await httpPost(`/storage/items/coc-report`, values);

  download(
    `data:application/pdf;base64,${response.data}`,
    response.filename,
    "application/pdf"
  );
}

export async function createStorageIntakePDF(values) {
  const response = await httpPost(`/storage/items/intake-form`, values);

  download(
    `data:application/pdf;base64,${response.data}`,
    response.filename,
    "application/pdf"
  );
}

export async function createStorageReleasePDF(values) {
  const response = await httpPost(`/storage/items/release-form`, values);

  download(
    `data:application/pdf;base64,${response.data}`,
    response.filename,
    "application/pdf"
  );
}

const CaseStorageAPI = {
  query,
  getStorage,
  createStorage,
  deleteStorage,
  getTypes: getStorageTypes,
  getProviders: getStorageBrands,
  getStorageModels,
  getStorageModelNumbers,
  getStorageSizes,
  exportStorageList,
  moveStorageItems,
  moveStorage,
  getStorageCocMoveReasons,
  removeStorageFromCase,
  getStorageNumbers,
  assignStorageToCase,
  updateStorage,
  getCocRecords: getStorageCocRecords,
  createStorageCocPDF,
  createStorageIntakePDF,
  createStorageReleasePDF,
  createCocRecord: createStorageCocRecord,
  updateCocRecord: updateStorageCocRecord,
  deleteCocRecord: deleteStorageCocRecord,
  createStorageIntakeDetails,
  getStorageCustomAttributes,
  createStorageCustomField,
  updateStorageCustomField,
  deleteStorageCustomField,
};

export default CaseStorageAPI;
