import { useQuery, useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";

import {
  Button,
  Flyout,
  FlyoutHeader,
  FlyoutTitle,
} from "@monolith-forensics/monolith-ui";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EditIcon,
  TrashIcon,
} from "lucide-react";

import ForensicSoftwareAPI from "../../api/software";
import {
  EditSoftwareModal,
  DeleteForensicSoftwareModal,
} from "@/components/Modals";
import { ForensicSoftwareColumnDefs } from "@/components/ColumnDefinitions";
import {
  DetailItem,
  DetailLabel,
  DetailValue,
  FlyoutContainer,
  HeaderContent,
} from ".";
import { ForensicSoftware } from "@/types";

interface SoftwareFlyoutProps {
  open: boolean;
  onClose?: () => void;
  defaultInfo: ForensicSoftware | null;
  onEdit: () => void;
  onDelete: () => void;
  onNext: () => void;
  onPrevious: () => void;
}

interface MainDetailsProps {
  defaultData: ForensicSoftware;
}

const MainDetails: FC<MainDetailsProps> = ({ defaultData }) => {
  return (
    <FlyoutContainer>
      <div className="event-details">
        {ForensicSoftwareColumnDefs.filter(
          (col) => col.dataField !== "description"
        ).map((col) => {
          const key = col.dataField as keyof ForensicSoftware;
          const label = col.caption;
          const value = col?.render
            ? col.render(defaultData)
            : defaultData[key];

          return (
            <DetailItem key={key}>
              <DetailLabel>{label}</DetailLabel>
              <DetailValue>{value}</DetailValue>
            </DetailItem>
          );
        })}
        <DetailItem>
          <DetailLabel>Description</DetailLabel>
        </DetailItem>
        <DetailValue>{defaultData.description || "None"}</DetailValue>
      </div>
    </FlyoutContainer>
  );
};

const SoftwareFlyout: React.FC<SoftwareFlyoutProps> = ({
  open,
  onClose,
  defaultInfo,
  onEdit,
  onDelete,
  onNext,
  onPrevious,
}) => {
  const queryClient = useQueryClient();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const { data: itemData, isLoading } = useQuery({
    queryKey: ["software", "list", { license_id: defaultInfo?.license_id }],
    queryFn: () =>
      ForensicSoftwareAPI.getSoftwareItems({
        license_id: defaultInfo?.license_id,
      }),
    initialData: [defaultInfo],
    select: (res) => {
      return res[0];
    },
    enabled: !!defaultInfo?.license_id,
  });

  return (
    <Flyout open={open} onClose={() => onClose?.()}>
      {isLoading && <div>Loading...</div>}
      {!isLoading && (
        <>
          <FlyoutHeader>
            <FlyoutTitle>{itemData.software_name}</FlyoutTitle>
            <EditSoftwareModal
              onSubmit={() => {
                queryClient.refetchQueries({
                  queryKey: [
                    "software",
                    "list",
                    { license_id: itemData.license_id },
                  ],
                });
                onEdit();
              }}
              show={showEditModal}
              onClose={() => setShowEditModal(false)}
              onCancel={() => setShowEditModal(false)}
              defaultInfo={itemData}
            />
            <DeleteForensicSoftwareModal
              show={showDeleteModal}
              onCancel={() => setShowDeleteModal(false)}
              onClose={() => setShowDeleteModal(false)}
              onSubmit={() => {
                onDelete?.();
                setShowDeleteModal(false);
              }}
              defaultInfo={itemData}
            />
            <HeaderContent>
              <div>Software</div>
              <Button
                size="xxs"
                variant="text"
                leftSection={<EditIcon size={14} />}
                color="primary"
                onClick={() => setShowEditModal(true)}
              >
                Edit
              </Button>
              <Button
                size="xxs"
                variant="text"
                leftSection={<TrashIcon size={14} />}
                color="error"
                onClick={() => setShowDeleteModal(true)}
              >
                Delete
              </Button>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  alignItems: "center",
                  marginLeft: "auto",
                }}
              >
                <Button
                  size="xxs"
                  variant="outlined"
                  onClick={() => onPrevious?.()}
                  style={{ padding: "2px 4px" }}
                >
                  <ChevronLeftIcon size={14} />
                </Button>
                <Button
                  size="xxs"
                  variant="outlined"
                  onClick={() => onNext?.()}
                  style={{ padding: "2px 4px" }}
                >
                  <ChevronRightIcon size={14} />
                </Button>
              </div>
            </HeaderContent>
          </FlyoutHeader>
          <MainDetails defaultData={itemData} />
        </>
      )}
    </Flyout>
  );
};

export default SoftwareFlyout;
