import { httpGet, httpPost } from "../connection.js";

const getRelayInfo = (query = {}) => {
  return httpGet(`/relay-api/info`, query);
};

const updateRelayInstructions = (query = {}) => {
  return httpPost(`/relay-api/instructions`, query);
};

const updateRelaySlug = (query = {}) => {
  return httpPost(`/relay-api/tenants/update-slug`, query);
};

const verifySlug = (query = {}) => {
  return httpGet(`/relay-api/tenants/verify-slug`, query);
};

const updateTenantEmail = (query = {}) => {
  return httpPost(`/relay-api/tenants/update-email`, query);
};

const updateTenantName = (query = {}) => {
  return httpPost(`/relay-api/tenants/update-name`, query);
};

const updateTenantLogo = (query = {}) => {
  return httpPost(`/relay-api/tenants/update-logo`, query);
};

const getUsers = (query = {}) => {
  return httpGet(`/relay-api/tenants/users`, query);
};

const inviteUser = (query = {}) => {
  return httpPost(`/relay-api/tenants/invite-user`, query);
};

const getUserInvites = (query = {}) => {
  return httpGet(`/relay-api/tenants/user-invites`, query);
};

const updateUser = (query = {}) => {
  return httpPost(`/relay-api/tenants/update-user`, query);
};

const updateUserStatus = (query = {}) => {
  return httpPost(`/relay-api/tenants/update-user-status`, query);
};

const updateUserRole = (query = {}) => {
  return httpPost(`/relay-api/tenants/update-user-role`, query);
};

const createRelayTenant = (query = {}) => {
  return httpPost(`/relay-api/tenants/create`, query);
};

const removeUser = (query = {}) => {
  return httpPost(`/relay-api/tenants/remove-user`, query);
};

const grantUserAccess = (query = {}) => {
  return httpPost(`/relay-api/tenants/grant-user-access`, query);
};

const denyUserAccess = (query = {}) => {
  return httpPost(`/relay-api/tenants/deny-user-access`, query);
};

const RelayAPI = {
  getRelayInfo,
  updateRelayInstructions,
  updateRelaySlug,
  verifySlug,
  updateTenantEmail,
  updateTenantName,
  updateTenantLogo,
  getUsers,
  inviteUser,
  getUserInvites,
  updateUser,
  updateUserStatus,
  updateUserRole,
  createRelayTenant,
  removeUser,
  grantUserAccess,
  denyUserAccess,
};

export default RelayAPI;
