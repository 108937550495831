import styled from "styled-components";
import { Button } from "@mui/material";
import TasksAPI from "../../api/tasks/tasks.js";
import Dialog from "../../Monolith-UI/Dialog/Dialog.js";

const Menu = styled.div`
  display: flex;
  flex-direction: row;
`;

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 150px;
  min-width: 325px;
  max-width: 350px;
  padding: 5px;

  // Allow height to increase with content
  height: fit-content;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
`;

const StyledButton = styled(Button)`
  padding: 0 10px;
`;

const DetailsContainer = styled.div`
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const DeleteTaskTemplateModal = ({ open, onClose, template, onDelete }) => {
  const handleSubmit = async () => {
    await TasksAPI.deleteTaskTemplate(template.template_id);
    onDelete?.(template);
    onClose?.();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <h2>Delete Task Template</h2>
        <div style={{ fontWeight: 600 }}>
          {`Are you sure you want to delete task template "${template.template_name}"?`}
        </div>
        <div style={{ marginTop: "auto" }}>
          <Menu topBorder style={{ justifyContent: "flex-end", gap: 10 }}>
            <StyledButton
              variant="text"
              color="primary"
              onClick={() => onClose?.()}
            >
              Cancel
            </StyledButton>
            <StyledButton
              variant="contained"
              color="error"
              onClick={() => handleSubmit()}
            >
              Delete Template
            </StyledButton>
          </Menu>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteTaskTemplateModal;
