import { httpDelete, httpGet, httpPatch, httpPost } from "../connection";

export async function getQaEntries(params) {
  return await httpGet(`/qa/entries`, params);
}

export async function createQaEntry(values) {
  return await httpPost(`/qa/entries`, values);
}

export async function updateQaEntry(values) {
  return await httpPatch(`/qa/entries`, values);
}

export async function deleteQaEntry(entry_id) {
  return await httpDelete(`/qa/entries/${entry_id}`);
}

export async function query(query) {
  return await httpPost(`/qa/entries/query`, query);
}

export async function exportQaEntriesList(query) {
  return await httpPost(`/qa/entries/export`, query);
}
