import ClientsAPI from "../../api/clients/index.js";

const ClientsFilterDimensions = [
  {
    name: "Organization",
    field: "organization",
    id: "organization",
    type: "string",
    mode: "multi-select",
    key: ["clients:organizations"],
    selectionDisplayName: "organization",
    selectionIdField: "organization",
    queryFn: (params) => ClientsAPI.getOrganizations(),
  },
  {
    name: "Unique ID",
    field: "unique_id",
    id: "unique_id",
    type: "string",
    mode: "text",
    key: ["clients:list"],
    selectionDisplayName: "unique_id",
    selectionIdField: "unique_id",
    queryFn: (params) => ClientsAPI.getClients(),
  },
];

export default ClientsFilterDimensions.slice();
