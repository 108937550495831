import { useState, useEffect } from "react";
import { Button, Card, Divider } from "@mui/material";
import moment from "moment";
import TimeEntriesAPI from "../../../../api/TimeEntries/time-entries.js";
import { MONOLITH_PERMISSIONS } from "../../../../constants.js";
import { usePermissions } from "../../../../hooks/usePermissions";

export const TimeEntryParams = ({ reportInfo, editReportPopup }) => {
  const { hasPermission } = usePermissions();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    TimeEntriesAPI.getTimeCategories().then(setCategories);
  }, []);

  return (
    <Card
      style={{
        padding: "20px",
        textAlign: "left",
        flexGrow: 1,
        flexBasis: "10px",
        marginRight: 15,
      }}
    >
      <>
        <div
          style={{
            fontSize: "larger",
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
          }}
        >
          <span style={{ display: "flex", alignItems: "center" }}>
            Report Parameters
          </span>
          <Button
            size="small"
            onClick={() => editReportPopup.current.instance.show()}
            disabled={
              !hasPermission(MONOLITH_PERMISSIONS.METRICS_REPORTS_UPDATE)
            }
          >
            Edit
          </Button>
        </div>
        <Divider sx={{ my: 2 }} />
        <div
          style={{
            minWidth: "300px",
            overflowY: "auto",
            maxHeight: "175px",
            paddingRight: "10px",
          }}
        >
          {reportInfo.report_parameters && (
            <>
              <div
                style={{
                  minWidth: "300px",
                }}
              >
                {reportInfo.report_parameters.time_interval && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>Time Interval:</div>
                    <div
                      style={{
                        maxWidth: 150,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      title={reportInfo.report_parameters.time_interval || ""}
                    >
                      {reportInfo.report_parameters.time_interval}
                    </div>
                  </div>
                )}
                {reportInfo.report_parameters.start_date && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>Start Date:</div>
                    <div
                      style={{
                        maxWidth: 150,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      title={
                        moment(reportInfo.report_parameters.start_date).format(
                          "MMMM DD, YYYY"
                        ) || ""
                      }
                    >
                      {moment(reportInfo.report_parameters.start_date).format(
                        "MMMM DD, YYYY"
                      )}
                    </div>
                  </div>
                )}
                {reportInfo.report_parameters.end_date && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>End Date:</div>
                    <div
                      style={{
                        maxWidth: 150,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      title={
                        moment(reportInfo.report_parameters.end_date).format(
                          "MMMM DD, YYYY"
                        ) || ""
                      }
                    >
                      {moment(reportInfo.report_parameters.end_date).format(
                        "MMMM DD, YYYY"
                      )}
                    </div>
                  </div>
                )}
                {reportInfo.report_parameters.timestamp_dimension && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>Timestamp Dimension:</div>
                    <div
                      style={{
                        maxWidth: 150,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      title={
                        reportInfo.report_parameters.timestamp_dimension || ""
                      }
                    >
                      {reportInfo.report_parameters.timestamp_dimension}
                    </div>
                  </div>
                )}
                {reportInfo.report_parameters.invoiced && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>Invoiced:</div>
                    <div
                      style={{
                        maxWidth: 150,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      title={reportInfo.report_parameters.invoiced || ""}
                    >
                      {reportInfo.report_parameters.invoiced}
                    </div>
                  </div>
                )}
                {reportInfo.report_parameters.category && categories.length && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>Categories:</div>
                    <div
                      style={{
                        maxWidth: 150,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      title={
                        reportInfo.report_parameters.category
                          .map(
                            (reportCategory) =>
                              categories.find(
                                (category) =>
                                  category.category_id === reportCategory
                              ).category_name
                          )
                          .join(", ") || ""
                      }
                    >
                      {reportInfo.report_parameters.category
                        .map(
                          (reportCategory) =>
                            categories.find(
                              (category) =>
                                category.category_id === reportCategory
                            ).category_name
                        )
                        .join(", ")}
                    </div>
                  </div>
                )}
                {reportInfo.report_parameters.users && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>Users:</div>
                    <div
                      style={{
                        maxWidth: 150,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      title={
                        reportInfo.report_parameters.users
                          .map((user) => user.full_name)
                          .join(", ") || ""
                      }
                    >
                      {reportInfo.report_parameters.users
                        .map((user) => user.full_name)
                        .join(", ")}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {!reportInfo.report_parameters && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "5px",
              }}
            >
              <div>No Parameters Set</div>
            </div>
          )}
        </div>
      </>
    </Card>
  );
};
