import { useState } from "react";
import { Button, Modal } from "@monolith-forensics/monolith-ui";
import ButtonMenu from "../Forms/components/ButtonMenu.js";
import { usePermissions } from "../../hooks/usePermissions";
import LoggingAPI from "../../api/logging/index.js";
import { useMutation } from "@tanstack/react-query";
import { AnalysisAPI } from "../../api/index.js";

const DeleteTraceAccountModal = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  buttonProps = {},
  defaultFormData = {},
}) => {
  const [submitting, setSubmitting] = useState(false);
  const { currentUser } = usePermissions();

  const deleteItem = useMutation({
    mutationFn: AnalysisAPI.Accounts.remove,
    onMutate: () => setSubmitting(true),
    onSuccess: (_, variables) => {
      LoggingAPI.createLog({
        case_id: defaultFormData.case_id,
        user_id: currentUser.user_id,
        message: `Deleted trace account: ${defaultFormData.name}.`,
        event: "trace_account:delete",
        object_type: "trace_account",
        object_uuid: defaultFormData.uuid,
      });
      setSubmitting(false);
      onClose?.();
      onSubmit?.(defaultFormData);
    },
  });

  const handleSubmit = async () => {
    deleteItem.mutate({ uuid: defaultFormData.uuid });
  };

  const handleCancel = () => {
    onCancel?.();
  };

  return (
    <Modal open={show} onClose={onClose}>
      <Modal.Title>Delete Trace Account</Modal.Title>
      <div style={{ fontWeight: 600, margin: "20px 0px" }}>
        Are you sure you want to delete this trace account{" "}
        <span style={{ fontWeight: 600 }}>"{defaultFormData?.name}"</span>?
      </div>
      <ButtonMenu>
        <Button
          size="xs"
          variant="subtle"
          onClick={handleCancel}
          disabled={submitting}
        >
          {buttonProps?.cancelText || "Cancel"}
        </Button>
        <Button
          size="xs"
          color="error"
          variant="contained"
          onClick={handleSubmit}
          disabled={submitting}
          loading={submitting}
        >
          {buttonProps?.submitText || "Delete Account"}
        </Button>
      </ButtonMenu>
    </Modal>
  );
};

export default DeleteTraceAccountModal;
