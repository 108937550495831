import { Button, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  getContacts,
  logActivity,
  uploadContacts,
  linkCaseContact,
} from "../../../api";

import { usePermissions } from "../../../hooks/usePermissions";

const ContactsImporter = styled(
  ({
    className,
    caseInfo,
    csvData,
    columnNames,
    columnMap,
    duplicateContacts,
    preparedData,
  }) => {
    const { currentUser } = usePermissions();
    const navigate = useNavigate();
    const theme = useTheme();

    const [contactProgress, setContactProgress] = useState({
      total: preparedData?.length || 0,
      completed: 0,
    });

    const [isImporting, setIsImporting] = useState(true);

    const handleContactsImport = async () => {
      // Get Current Contacts in the case
      const contacts = await getContacts({
        case_id: caseInfo.case_id,
      });

      // Get linked contacts that were provided in CSV
      // remove any null or empty string values
      // remove any that already exist in the case
      // Group them by name so there are no duplicates
      const newImportedContacts = preparedData.map((d) => ({
        ...d,
        case_id: caseInfo.case_id,
      }));

      setContactProgress((prev) => {
        return {
          ...prev,
          total: newImportedContacts.length,
        };
      });

      // Upload contacts in bulk
      try {
        const batchSize = 100;
        const totalContacts = newImportedContacts.length;
        let completed = 0;

        for (let i = 0; i < totalContacts; i += batchSize) {
          // Create a batch by slicing the array
          const batch = newImportedContacts.slice(i, i + batchSize);

          // Upload the batch
          const result = await uploadContacts({ contacts: batch });

          // Update the number of completed uploads

          completed += batch.length;

          // Update progress
          setContactProgress((progress) => ({
            ...progress,
            completed: completed,
          }));
        }
      } catch (error) {
        console.error("Error uploading contacts: ", error);
        // Handle error state update here if necessary
      }

      //  Link Pre-existing Contacts
      if (duplicateContacts) {
        duplicateContacts.forEach(async (contact) => {
          await linkCaseContact({
            case_id: caseInfo.case_id,
            client_id: contact.client_id,
          });
        });
      }
    };

    const runImport = async () => {
      await handleContactsImport();

      logActivity(
        caseInfo.case_id,
        currentUser.user_id,
        `Imported ${contactProgress.total} contacts from CSV and assigned to case.`
      );

      setIsImporting(false);
      preparedData = [];
    };

    useEffect(() => {
      if (preparedData) runImport();
      else navigate(`/cases/${caseInfo.case_id}/contacts?v=import`);
    }, []);

    if (!preparedData) return null;

    return (
      <div className={className}>
        <div className="progress-overlay"></div>
        <div className="progress-content">
          {isImporting ? (
            <>
              <div>
                Contacts are being imported, please do not navigate or refresh
                the page.
              </div>
              <div style={{ marginTop: 20, textAlign: "center" }}>
                {contactProgress.completed} / {contactProgress.total} Imported
              </div>
              <div
                style={{
                  position: "relative",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <div
                  style={{
                    backgroundColor: theme.palette.action.hover,
                    height: 10,
                    width: "100%",
                    borderRadius: 5,
                  }}
                ></div>
                <div
                  className="progress-bar"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    backgroundColor: theme.palette.primary.main,
                    height: 10,
                    width: `${
                      (parseInt(contactProgress.completed) /
                        parseInt(contactProgress.total)) *
                      100
                    }%`,
                    transition: "width 0.5s",
                    borderRadius: 5,
                  }}
                ></div>
              </div>
            </>
          ) : (
            <>
              <div style={{ display: "flex" }}>
                <h2>Import Complete!</h2>
              </div>
              <div style={{ marginBottom: 20 }}>
                <div>{contactProgress.total} Contacts Imported</div>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() =>
                    navigate(`/cases/${caseInfo.case_id}/contacts`)
                  }
                >
                  View Contacts
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 750px;
  .progress-content {
    width: 400px;
    z-index: 2001;
  }
  .progress-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2000;
  }
`;

export default ContactsImporter;
