import { useTheme } from "styled-components";

const AssignedUsersComponent = ({ rowData }) => {
  const theme = useTheme();

  // Remove inactive users from list
  const userList =
    rowData?.assigned_users?.filter?.((user) => user.active === 1) || [];

  return (
    <div
      style={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
      }}
      title={userList.map((user) => user.full_name).join(", ")}
    >
      {userList.map((user) => {
        // return initial of user name
        const initials = user.full_name
          .split(" ")
          .map((n) => n[0])
          .join("");

        return (
          <div
            key={user.user_id}
            style={{
              borderRadius: 3,
              border: `1px solid ${theme.palette.divider}`,
              fontSize: 8,
              width: "fit-content",
              padding: "0 5px",
              marginRight: 3,
            }}
          >
            {initials}
          </div>
        );
      })}
    </div>
  );
};

export default AssignedUsersComponent;
