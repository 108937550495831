import ContactsAPI from "@/api/contacts";
import { SelectBox } from "@monolith-forensics/monolith-ui";
import { useQuery } from "@tanstack/react-query";
import { FC } from "react";

interface ContactTypeSelectorProps {
  defaultValue: any;
  onChange?: (value: any) => void;
  error?: string;
}

type ContactType = {
  contact_type: string;
};

const ContactTypeSelector: FC<ContactTypeSelectorProps> = ({
  defaultValue,
  onChange,
  error,
}) => {
  const { data: types } = useQuery<ContactType[]>({
    queryKey: ["contacts", "types"],
    queryFn: () => ContactsAPI.getContactTypes(),
  });

  const options = types?.map((type) => ({
    label: type?.contact_type,
    value: type?.contact_type,
  }));

  return (
    <>
      <SelectBox
        defaultValue={defaultValue}
        onChange={onChange}
        error={error}
        size="sm"
        variant="outlined"
        label="Type"
        placeholder="Select or enter contact type"
        data={options}
        clearable={true}
        searchable={true}
        allowCustomValue={true}
        openOnFocus={false}
      />
    </>
  );
};

export default ContactTypeSelector;
