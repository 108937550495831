import React, { useRef, useState } from "react";
import moment from "moment";
import { Popup } from "devextreme-react/ui/popup";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import { useAuth } from "../../contexts/AuthContext";
import { useSnackbar } from "notistack";

import { updateMonolithReport } from "../../api";
import ToolBarItems from "../../components/ToolBarItems";

import CollapseSection from "../../components/CollaspeSection";
import { CATEGORY_MAP } from "./CategoryMap";

import CaseParamsForm from "./params/CaseParams";
import EvidenceParamsForm from "./params/EvidenceParams";
import StorageParamsForm from "./params/StorageParams";
import AcquisitionParamsForm from "./params/AcquisitionParams";
import FprParamsForm from "./params/FprParams";
import TimeEntriesParamsForm from "./params/TimeEntriesParams";
import QaEntryParamsForm from "./params/QaEntryParams";

export const EditReportPopup = ({
  editReportPopup,
  reportInfo,
  setFormUpdated,
}) => {
  const [reportCategory, setReportCategory] = useState(
    reportInfo?.category_id || null
  );
  const paramsForm = useRef(null);
  const editReportForm = useRef(null);
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (e) => {
    if (
      editReportForm.current.instance.validate().isValid &&
      paramsForm.current.instance.validate().isValid
    ) {
      let formData = {
        ...editReportForm.current.instance.option().formData,
        ...paramsForm.current.instance.option().formData,
      };

      formData.created_by_id = currentUser.user_id;

      if (formData.report_parameters) {
        // Set Proper Time Interval
        if (formData.report_parameters.time_interval) {
          if (formData.report_parameters.time_interval === "Custom") {
            formData.report_parameters.start_date = moment(
              formData.report_parameters.start_date
            ).toISOString();
            formData.report_parameters.end_date = moment(
              formData.report_parameters.end_date
            ).toISOString();
          }
          if (formData.report_parameters.time_interval === "Today") {
            formData.report_parameters.start_date = moment()
              .startOf("day")
              .toISOString();
            formData.report_parameters.end_date = moment()
              .endOf("day")
              .toISOString();
          }
          if (formData.report_parameters.time_interval === "Yesterday") {
            formData.report_parameters.start_date = moment()
              .subtract(24, "hours")
              .startOf("day")
              .toISOString();
            formData.report_parameters.end_date = moment()
              .startOf("day")
              .toISOString();
          }
          if (formData.report_parameters.time_interval === "This Week") {
            formData.report_parameters.start_date = moment()
              .startOf("week")
              .toISOString();
            formData.report_parameters.end_date = moment()
              .endOf("day")
              .toISOString();
          }
          if (formData.report_parameters.time_interval === "Last Week") {
            formData.report_parameters.start_date = moment()
              .subtract(7, "days")
              .startOf("week")
              .toISOString();
            formData.report_parameters.end_date = moment()
              .subtract(7, "days")
              .endOf("week")
              .toISOString();
          }
          if (formData.report_parameters.time_interval === "This Month") {
            formData.report_parameters.start_date = moment()
              .startOf("month")
              .toISOString();
            formData.report_parameters.end_date = moment()
              .endOf("month")
              .toISOString();
          }
          if (formData.report_parameters.time_interval === "Last Month") {
            formData.report_parameters.start_date = moment()
              .subtract(1, "months")
              .startOf("month")
              .toISOString();
            formData.report_parameters.end_date = moment()
              .subtract(1, "months")
              .endOf("month")
              .toISOString();
          }
          if (formData.report_parameters.time_interval === "This Year") {
            formData.report_parameters.start_date = moment()
              .startOf("year")
              .toISOString();
            formData.report_parameters.end_date = moment()
              .endOf("year")
              .toISOString();
          }
          if (formData.report_parameters.time_interval === "Last Year") {
            formData.report_parameters.start_date = moment()
              .subtract(1, "years")
              .startOf("year")
              .toISOString();
            formData.report_parameters.end_date = moment()
              .subtract(1, "years")
              .endOf("year")
              .toISOString();
          }
        } else {
          delete formData.report_parameters.time_interval;
          delete formData.report_parameters.end_date;
          delete formData.report_parameters.start_date;
          delete formData.report_parameters.timestamp_dimension;
        }

        // Remove Case Lead Object if empty
        if (
          formData.report_parameters.case_lead &&
          formData.report_parameters.case_lead.length === 0
        )
          delete formData.report_parameters.case_lead;
      }

      updateMonolithReport(reportInfo.report_id, formData).then((result) => {
        setFormUpdated((prev) => !prev);
        enqueueSnackbar(`Monolith Report Updated.`, {
          variant: "success",
        });
      });
      editReportPopup.current.instance.hide();
    }
  };

  const handleCancel = (e) => {
    editReportPopup.current.instance.hide();
  };

  return (
    <Popup
      ref={editReportPopup}
      height="auto"
      width="650px"
      showTitle={true}
      showCloseButton={true}
      resizeEnabled={true}
      title="Edit Report"
    >
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <CollapseSection allowCollapse={false} title="Report Details">
          <Form ref={editReportForm} colCount={2}>
            <SimpleItem
              colSpan={1}
              dataField="report_name"
              label={{ text: "Report Name" }}
              isRequired={true}
              editorOptions={{
                value: reportInfo.name,
              }}
            />
          </Form>
        </CollapseSection>
        <div>
          {reportCategory === CATEGORY_MAP.CASES && (
            <CaseParamsForm paramsForm={paramsForm} reportInfo={reportInfo} />
          )}
          {reportCategory === CATEGORY_MAP.EVIDENCE && (
            <EvidenceParamsForm
              paramsForm={paramsForm}
              reportInfo={reportInfo}
            />
          )}
          {reportCategory === CATEGORY_MAP.STORAGE && (
            <StorageParamsForm
              paramsForm={paramsForm}
              reportInfo={reportInfo}
            />
          )}
          {reportCategory === CATEGORY_MAP.TIME_ENTRIES && (
            <TimeEntriesParamsForm
              paramsForm={paramsForm}
              reportInfo={reportInfo}
            />
          )}
          {reportCategory === CATEGORY_MAP.ACQUISITIONS && (
            <AcquisitionParamsForm
              paramsForm={paramsForm}
              reportInfo={reportInfo}
            />
          )}
          {reportCategory === CATEGORY_MAP.QA_ENTRIES && (
            <QaEntryParamsForm
              paramsForm={paramsForm}
              reportInfo={reportInfo}
            />
          )}
          {reportCategory === CATEGORY_MAP.FPR && (
            <FprParamsForm paramsForm={paramsForm} reportInfo={reportInfo} />
          )}
        </div>
        <ToolBarItems
          submitText="Save Report"
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </div>
    </Popup>
  );
};
