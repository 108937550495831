import styled, { useTheme } from "styled-components";
import { Button, Switch } from "@monolith-forensics/monolith-ui";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Table, { Column } from "../../../Monolith-UI/Table/Table.js";
import DeleteQaChecklistModal from "../../../components/Modals/DeleteQaChecklistModal.js";
import CreateQaChecklistModal from "../../../components/Modals/CreateQaChecklistModal.js";
import EditQaChecklistModal from "../../../components/Modals/EditQaChecklistModal.js";
import { EditIcon, EyeIcon, Trash2Icon } from "lucide-react";
import QaChecklistAPI from "../../../api/quality_assurance/checklists.js";
import { Link } from "react-router-dom";

export interface QaCheckList {
  checklist_id: number;
  checklist_name?: string;
  enabled: number;
}

const QaCheckLists = styled(({ className }) => {
  const theme: any = useTheme();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<QaCheckList | null>(null);

  const { data: checklists, refetch } = useQuery({
    queryKey: ["qa-checklists", "list"],
    queryFn: () => QaChecklistAPI.getQAChecklists(),
  });

  const handleCreateQaChecklist = () => {
    setSelectedItem(null);
    setShowAddModal(false);
    refetch();
  };

  const handleEditQaChecklist = () => {
    setSelectedItem(null);
    setShowEditModal(false);
    refetch();
  };

  const handleDeleteQaChecklist = () => {
    setSelectedItem(null);
    setShowDeleteModal(false);
    refetch();
  };

  const handleUpdateEnabled = async (data: QaCheckList) => {
    await QaChecklistAPI.updateQAChecklist({
      checklist_id: data.checklist_id,
      enabled: data.enabled,
    });

    refetch();
  };

  if (!checklists) return <div>Loading...</div>;

  return (
    <div className={className}>
      <div>
        These are your Quality Assurance checklists. These can be used to create
        custom QA processes.
      </div>
      <div>
        <Button
          size="xxs"
          variant="contained"
          color="primary"
          onClick={() => setShowAddModal(true)}
        >
          + Add QA Checklist
        </Button>
        {showDeleteModal && (
          <DeleteQaChecklistModal
            show={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            onCancel={() => setShowDeleteModal(false)}
            onSubmit={handleDeleteQaChecklist}
            defaultInfo={selectedItem}
          />
        )}
        {showAddModal && (
          <CreateQaChecklistModal
            show={showAddModal}
            onClose={() => setShowAddModal(false)}
            onCancel={() => setShowAddModal(false)}
            onSubmit={handleCreateQaChecklist}
          />
        )}
        {showEditModal && (
          <EditQaChecklistModal
            show={showEditModal}
            onClose={() => setShowEditModal(false)}
            onCancel={() => setShowEditModal(false)}
            onSubmit={handleEditQaChecklist}
            defaultInfo={{
              checklist_id: selectedItem?.checklist_id,
              checklist_name: selectedItem?.checklist_name,
            }}
          />
        )}
      </div>
      <Table data={checklists} keyValue="uuid">
        <Column dataField="checklist_name" caption="Checklist Name" />
        <Column
          dataField="actions"
          caption=""
          render={(data: QaCheckList) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 35,
                  alignItems: "center",
                  justifyContent: "end",
                  fontWeight: 600,
                  color: theme.palette.text.secondary,
                  width: "100%",
                  userSelect: "none",
                  paddingRight: 10,
                }}
                title="Shared templates are visible to all users in Monolith."
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 10,
                    alignItems: "center",
                    justifyContent: "flex-end",
                    fontWeight: 600,
                    color: theme.palette.text.secondary,
                    width: "100%",
                    userSelect: "none",
                  }}
                >
                  <div>Enabled</div>
                  <Switch
                    size="xs"
                    defaultValue={data.enabled === 1}
                    onChange={(value) => {
                      handleUpdateEnabled({
                        checklist_id: data.checklist_id,
                        enabled: value,
                      });
                    }}
                  />
                </div>
                <Link to={`${data.checklist_id}`}>
                  <Button
                    size="xxs"
                    variant="text"
                    color="primary"
                    leftSection={<EyeIcon size={12} />}
                  >
                    View
                  </Button>
                </Link>
                <Button
                  size="xxs"
                  variant="text"
                  color="primary"
                  leftSection={<EditIcon size={12} />}
                  onClick={() => {
                    setSelectedItem(data);
                    setShowEditModal(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  size="xxs"
                  variant="text"
                  color="error"
                  leftSection={<Trash2Icon size={12} />}
                  onClick={() => {
                    setSelectedItem(data);
                    setShowDeleteModal(true);
                  }}
                >
                  Delete
                </Button>
              </div>
            );
          }}
        />
      </Table>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1 1 auto;
  overflow-y: auto;

  max-width: 100%;

  a {
    color: ${({ theme }) => theme.palette.primary.main};

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default QaCheckLists;
