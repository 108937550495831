import styled from "styled-components";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { useTheme } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AcquisitionImportForm from "./AcquisitionImportForm";
import KeyboardReturnOutlinedIcon from "@mui/icons-material/KeyboardReturnOutlined";
import ValidateImportData from "./ValidateImportData";
import ImportProgress from "./ImportProgress";
import TaskButton from "../../../../Monolith-UI/TaskButton.js";

const AcquisitionImport = styled(({ className, caseInfo }) => {
  const theme = useTheme();
  const [columnNames, setColumnNames] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [columnMap, setColumnMap] = useState(null);
  const [preparedData, setPrepareData] = useState(null);
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const section = searchParams.get("s") || null;

  const handleFileDrop = async (files, reject, event) => {
    event?.target?.classList?.remove("dragging");
    const { columnNames, rowData } = await getCSVData(files);
    setColumnNames(columnNames);
    setCsvData(rowData);
    navigate(`/cases/${caseInfo.case_id}/acquisitions?v=import&s=columnSelect`);
  };

  const handleRejection = () => {};

  const { getRootProps, getInputProps } = useDropzone({
    onDragEnter: (e) => e.target.classList.add("dragging"),
    onDragLeave: (e) => e.target.classList.remove("dragging"),
    onDrop: handleFileDrop,
    onDropRejected: handleRejection,
    accept: {
      "text/csv": [".csv"],
    },
    maxFiles: 1,
    maxSize: 10485760,
    multiple: false,
  });

  return (
    <>
      {section === "columnSelect" && (
        <AcquisitionImportForm
          caseInfo={caseInfo}
          columnNames={columnNames}
          onSubmit={(formData) => setColumnMap(formData)}
        />
      )}
      {section === "validate" && (
        <ValidateImportData
          caseInfo={caseInfo}
          csvData={csvData}
          columnNames={columnNames}
          columnMap={columnMap}
          onValidated={(data) => setPrepareData(data)}
        />
      )}
      {section === "progress" && (
        <ImportProgress
          caseInfo={caseInfo}
          csvData={csvData}
          columnNames={columnNames}
          columnMap={columnMap}
          preparedData={preparedData}
        />
      )}
      {section === null && (
        <div className={className}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              marginBottom: 20,
            }}
          >
            <TaskButton
              onClick={() =>
                navigate(`/cases/${caseInfo.case_id}/acquisitions`)
              }
              style={{
                outline: `1px solid ${theme.palette.divider}`,
                marginRight: 10,
              }}
              title="Back to Cases"
            >
              <KeyboardReturnOutlinedIcon style={{ fontSize: 20 }} />
            </TaskButton>
            <Title style={{ marginBottom: 0 }}>Import From CSV</Title>
          </div>

          <DropZone {...getRootProps()}>
            <input {...getInputProps()} />
            <UploadFileOutlinedIcon
              className="dropzone-icon"
              style={{
                fontSize: 55,
                marginBottom: 15,
                color: theme.palette.text.secondary,
                transition: "color linear 0.2s",
              }}
            />
            <DropZoneText className="dropzone-text">Upload CSV</DropZoneText>
            <DropZoneSubText className="dropzone-subtext">
              Drag and drop or click to select a CSV file.
            </DropZoneSubText>
          </DropZone>
        </div>
      )}
    </>
  );
})``;

// Parse CSV file with Papa Parse
const getCSVData = async (files) => {
  // Dynamically import papaparse
  const Papa = await import("papaparse");

  return new Promise((resolve) => {
    Papa.parse(files[0], {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const columnNames = results.meta.fields;
        const rowData = results.data;
        resolve({ columnNames, rowData });
      },
    });
  });
};

const Title = styled.div`
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 1rem;
`;

const DropZone = styled.div`
  border: ${({ theme }) => `2px dashed ${theme.palette.divider}`};
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  cursor: pointer;
  height: 300px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: border-color linear 0.2s;
  &:hover {
    border-color: ${({ theme }) => theme.palette.primary.main};
    .dropzone-text,
    .dropzone-icon,
    .dropzone-subtext {
      color: ${({ theme }) => theme.palette.primary.main} !important;
    }
  }
  &.dragging {
    border-color: ${({ theme }) => theme.palette.primary.main};
    .dropzone-text,
    .dropzone-icon,
    .dropzone-subtext {
      color: ${({ theme }) => theme.palette.primary.main} !important;
    }
  }
`;

const DropZoneText = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.palette.text.secondary};
  transition: color linear 0.2s;
`;

const DropZoneSubText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text.secondary};
  transition: color linear 0.2s;
`;

export default AcquisitionImport;
