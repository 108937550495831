import { httpGet, httpPost } from "../connection";

// writes an activity log entry into the database, then refreshes the case log table
export async function logActivity(case_id, userID, message, time = null) {
  if (case_id === "Admin") {
    return await httpPost("/logs/admin", {
      case_id: case_id,
      userID: userID,
      message: message,
      time: time,
    });
  } else {
    return await httpPost("/cases/activity", {
      case_id: case_id,
      userID: userID,
      message: message,
      time: time,
    });
  }
}

async function createLog(values = {}) {
  return await httpPost(`/logs`, values);
}

export async function getAdminLogs() {
  return (await httpGet(`/logs/admin`)).data;
}

async function query(query = {}) {
  return await httpPost(`/logs/query`, query);
}

async function exportActivityList(query = {}) {
  return await httpPost(`/logs/export`, query);
}

export async function getActivityLogs(params = {}) {
  return (await httpGet(`/logs`, params)).data;
}

export async function getRecentActivity(params = {}) {
  return await httpGet(`/logs`, params);
}

async function logCommonActivity({ user_email, timestamp = null, message }) {
  return await httpPost(`/workspaces/common-log`, {
    user_email,
    timestamp,
    message,
  });
}

const LoggingAPI = {
  logActivity,
  getAdminLogs,
  query,
  getActivityLogs,
  getRecentActivity,
  exportActivityList,
  logCommonActivity,
  createLog,
};

export default LoggingAPI;
