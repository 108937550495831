import styled from "styled-components";
import getTipTapExtensions from "../../../../../Monolith-UI/RichTextEditor/extensions/index.js";
import getTipTapEditorProps from "../../../../../Monolith-UI/RichTextEditor/props.js";
import { useEditor } from "@tiptap/react";
import RTEditor from "../../../../../Monolith-UI/RTEditor/RTEditor.js";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import MonolithNotesAPI from "../../../../../api/Notes/index.js";
import { Button, TextInput } from "@monolith-forensics/monolith-ui";
import { useEffect, useRef, useState } from "react";
import shortUUID from "short-uuid";
import moment from "moment";
import { usePermissions } from "../../../../../hooks/usePermissions";
import { ArrowLeftIcon, ArrowRightIcon } from "lucide-react";
import { useDebouncedCallback } from "use-debounce";
import LoggingAPI from "../../../../../api/logging/index.js";
import { monolithMoment } from "../../../../../utils/date-format";
import Loader from "../../../../../components/Loader.js";

const EditorWrapper = styled(
  ({ className, defaultValue, onChange, readOnly, saving }) => {
    const extensions = getTipTapExtensions({
      exclude: ["Image"],
    });

    const editorProps = getTipTapEditorProps({
      exclude: ["Image"],
    });

    const editor = useEditor({
      content: defaultValue,
      editable: !readOnly,
      extensions,
      editorProps,
      onUpdate: ({ editor }) => {
        onChange?.(editor.getHTML());
      },
    });

    return (
      <div className={className}>
        <div className="inner-content">
          <RTEditor
            editor={editor}
            showToolbar={true}
            style={{ height: "100%" }}
            saving={saving}
          />
        </div>
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;

  .inner-content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 0px;

    border: 1px solid ${({ theme }) => theme.palette.input.border};
    border-radius: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const NoteEditor = styled(({ className, defaultNoteData, onClose }) => {
  const { currentUser } = usePermissions();
  const queryClient = useQueryClient();
  const [saving, setSaving] = useState(false);
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [
      "case-notes",
      "list",
      { uuid: defaultNoteData.uuid, include_content: true },
    ],
    queryFn: ({ queryKey }) => {
      const [, , query] = queryKey;
      return MonolithNotesAPI.getNotes(query);
    },
  });

  const updateNoteData = useMutation({
    mutationFn: MonolithNotesAPI.updateNote,
    onMutate: async (variables) => {
      setSaving(true);
    },
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(
        [
          "case-notes",
          "list",
          { uuid: defaultNoteData.uuid, include_content: true },
        ],
        (oldData) => {
          const cachedNote = oldData?.data?.[0];
          if (!cachedNote) {
            return oldData;
          }
          const newData = {};
          if (variables.note_data !== undefined) {
            newData.note_data = variables.note_data;
          }
          if (variables.note_tag !== undefined) {
            newData.note_tag = variables.note_tag;
          }
          return {
            ...oldData,
            data: [
              {
                ...cachedNote,
                ...newData,
              },
            ],
          };
        }
      );

      // Add delay to ensure that user can see the saving state
      // sometimes the update happens so fast that the saving state is not visible
      setTimeout(() => {
        setSaving(false);
      }, 300);

      LoggingAPI.createLog({
        case_id: defaultNoteData.case_id,
        user_id: currentUser.user_id,
        message: `Updated timeline event note.`,
        event: "update_timeline_event",
        object_type: "timeline_event",
        object_uuid: defaultNoteData.object_id,
      });

      LoggingAPI.createLog({
        case_id: defaultNoteData.case_id,
        user_id: currentUser.user_id,
        message: `Updated case note.`,
        event: "update_case_note",
        object_type: "case_note",
        object_uuid: defaultNoteData.uuid,
      });
    },
  });

  const handleUpdateNoteTitle = async (e) => {
    let newTitle = e.target.value;
    if (newTitle === "") {
      newTitle = defaultNoteData.note_tag;
    }
    updateNoteData.mutate({
      uuid: defaultNoteData.uuid,
      note_tag: newTitle,
    });
  };

  const handleChange = async (e) => {
    updateNoteData.mutate({
      uuid: defaultNoteData.uuid,
      note_data: e,
    });
  };

  const debouncedHandleChange = useDebouncedCallback((e) => {
    handleChange(e);
  }, 400);

  const debouncedUpdateNoteTitle = useDebouncedCallback((e) => {
    handleUpdateNoteTitle(e);
  }, 350);

  if (isLoading) return <Loader />;

  const noteData = data?.data?.[0] || defaultNoteData || {};

  return (
    <div className={className}>
      <div className="note-menu">
        <div style={{ width: "100%" }}>
          <TextInput
            size="md"
            defaultValue={noteData?.note_tag}
            placeholder="Enter Note Title"
            style={{ backgroundColor: "transparent" }}
            onChange={debouncedUpdateNoteTitle}
          />
        </div>
        <Button
          size="xxs"
          variant="outlined"
          leftSection={<ArrowLeftIcon size={16} />}
          onClick={() => onClose?.()}
          title="Back to Notes List"
        >
          Back
        </Button>
      </div>

      <EditorWrapper
        onChange={debouncedHandleChange}
        defaultValue={noteData?.note_data}
        saving={saving}
      />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1 1 auto;

  margin-top: 10px;

  .note-menu {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }
`;

const NotesListItem = styled(({ className, data, onClick }) => {
  return (
    <div
      className={className + " timeline-notes-list-item"}
      onClick={() => onClick?.(data)}
    >
      <div className="note-title">{data.note_tag}</div>
      <div className="note-subtitle">{data.created_by.full_name}</div>
      <div
        className="note-subtitle timestamp"
        title={monolithMoment({
          timestamp: data.created_on,
          includeTime: true,
          includeZone: true,
        })}
      >
        {moment.utc(data.created_on).fromNow()}
      </div>
      <div className="view-note-alert">
        <ArrowRightIcon size={20} />
      </div>
    </div>
  );
})`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.background.default};
  border: 1px solid ${({ theme }) => theme.palette.input.border};

  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};

    .view-note-alert {
      display: flex;
    }
  }

  .timestamp {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .note-title {
    font-weight: 500;
    width: fit-content;
  }

  .note-subtitle {
    font-size: 12px;
    color: ${({ theme }) => theme.palette.text.secondary};
    width: fit-content;
  }

  .view-note-alert {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    display: none;
    align-items: center;
  }
`;

const NotesList = styled(
  ({ className, defaultEventData, onNoteSelected, search }) => {
    const [scrollActive, setScrollActive] = useState(false);
    const containerRef = useRef();
    const { data } = useQuery({
      queryKey: [
        "case-notes",
        "list",
        { object_id: defaultEventData.uuid, include_content: false, search },
      ],
      queryFn: ({ queryKey }) => {
        const [, , query] = queryKey;
        return MonolithNotesAPI.getNotes(query);
      },
      placeholderData: (data) => data,
    });

    const notes = data?.data || [];

    useEffect(() => {
      if (containerRef.current) {
        setScrollActive(
          containerRef.current.scrollHeight > containerRef.current.clientHeight
        );
      }
    }, []);

    return (
      <div
        ref={containerRef}
        className={className + " timeline-notes-list"}
        data-scroll-active={scrollActive}
      >
        {notes.map((note) => (
          <NotesListItem key={note.uuid} data={note} onClick={onNoteSelected} />
        ))}
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1 1 auto;
  height: 0px;

  overflow-y: auto;

  &[data-scroll-active="true"] {
    padding-right: 8px;
  }
`;

const FlyoutNotesTab = styled(({ className, defaultEventData }) => {
  const { currentUser } = usePermissions();
  const [search, setSearch] = useState("");
  const [creatingNote, setCreatingNote] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [currentView, setCurrentView] = useState("note-list");

  const createNoteMutation = useMutation({
    mutationFn: MonolithNotesAPI.createNote,
    onMutate: async () => {
      setCreatingNote(true);
    },
    onSuccess: (data, variables, context) => {
      LoggingAPI.createLog({
        case_id: defaultEventData.case.case_id,
        user_id: currentUser.user_id,
        message: `Created timeline event note.`,
        event: "update_timeline_event",
        object_type: "timeline_event",
        object_uuid: defaultEventData.uuid,
      });

      LoggingAPI.createLog({
        case_id: defaultEventData.case.case_id,
        user_id: currentUser.user_id,
        message: `Created case note.`,
        event: "create_case_note",
        object_type: "case_note",
        object_uuid: variables.uuid,
      });

      setCreatingNote(false);
      setSelectedNote(variables);
      setCurrentView("note-editor");
    },
  });

  const handleCreateNote = async () => {
    setCreatingNote(true);
    const note_uuid = shortUUID.generate();
    const note_tag = `Timeline Note_${moment().toISOString()}`;

    const newNoteData = {
      uuid: note_uuid,
      case_id: defaultEventData.case.case_id,
      case_uuid: defaultEventData.case.uuid,
      note_tag,
      path: note_tag,
      created_on: moment().toISOString(),
      created_by_id: currentUser.user_id,
      parent_id: null,
      is_folder: 0,
      object_type: "timeline_event",
      object_id: defaultEventData.uuid,
    };

    createNoteMutation.mutate(newNoteData);
  };

  const handleSelectNote = (note) => {
    setSelectedNote(note);
    setCurrentView("note-editor");
  };

  const handleCloseEditor = () => {
    setSelectedNote(null);
    setCurrentView("note-list");
  };

  const debouncedHandleSearch = useDebouncedCallback((e) => {
    setSearch(e.target.value);
  }, 400);

  return (
    <div className={className + " timeline-notes-tab"}>
      {currentView === "note-list" && (
        <>
          <div
            style={{
              display: "flex",
              flex: "initial",
              flexDirection: "row",
              gap: 5,
              alignContent: "center",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 10,
              marginTop: 10,
              width: "100%",
            }}
          >
            <Button
              size="xxs"
              variant="contained"
              color="primary"
              onClick={handleCreateNote}
              loading={creatingNote}
            >
              + New Note
            </Button>
            <TextInput
              stylingMode="outlined"
              placeholder="Search Notes"
              height="100%"
              width={200}
              style={{ marginLeft: "auto" }}
              onKeyUp={debouncedHandleSearch}
            />
          </div>
          <NotesList
            defaultEventData={defaultEventData}
            onNoteSelected={handleSelectNote}
            search={search}
          />
        </>
      )}
      {currentView === "note-editor" && (
        <NoteEditor
          defaultNoteData={selectedNote}
          onClose={handleCloseEditor}
        />
      )}
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export default FlyoutNotesTab;
