import { createGlobalStyle } from "styled-components/macro";
import "overlayscrollbars/overlayscrollbars.css";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
    overflow: hidden;
  }

  body {
    margin: 0;
    height: 100%;
  }

  a {
    text-decoration: none;
    color: unset;
  }

// Custom OS scrollbar theme
.os-theme-dark {
  --os-handle-bg: ${(props) => props.theme.scrollbar.thumb};
  --os-handle-bg-active: ${(props) => props.theme.scrollbar.thumb};
}

.os-theme-light {
  --os-handle-bg: #e4e4e4;
  --os-handle-bg-hover: #c6c6c6;
  --os-handle-bg-active: #c6c6c6;

  &:hover {
    --os-scrollbar-color: #c6c6c6;
  }
}

  ::-webkit-scrollbar {
	width: 6px;
	height: 5px;
}

::-webkit-scrollbar-track {
	background: ${(props) => props.theme.scrollbar.track};
}

::-webkit-scrollbar-thumb {

	background: ${(props) => props.theme.scrollbar.thumb}
}

::-webkit-scrollbar-thumb:hover {
	background: ${(props) => props.theme.scrollbar.thumbHover}; 
}

.SnackbarContainer-root{
  z-index: 999999 !important;
}
  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }

  /* Dont show undo button in datagrid editing */
.dx-datagrid-revert-tooltip {  
    display: none;  
}

  /* Dont show summary footer in datagrid */
.dx-datagrid-total-footer {
        display: none;
    }

  .dx-htmleditor{
    background-color: ${(props) =>
      props.theme.palette.background.paper} !important;
      border: 1px solid ${(props) => props.theme.scrollbar.track} !important;
  }

  .mf-form{
    padding: 10px !important;
  }

  .dx-popup-content,
  .dx-toolbar.dx-popup-title{
    background-color: ${(props) => props.theme.palette.background.default}
  }

  .ql-editor.dx-htmleditor-content img{
    min-width: 25px;
    min-height: 25px;
    outline: 1px solid slategrey;
  }

  .ql-font-arial {
    font-family: Arial;
  }

  .ql-font-courier {
      font-family: "Courier New";
  }

  .ql-font-georgia{
    font-family: Georgia;
  }

  .ql-font-lucida-console {
    font-family: "Lucida Console";
  }

  .ql-font-impact {
    font-family: "Impact";
  }

  .ql-font-tahoma{
    font-family: Tahoma;
  }

  .ql-font-times-new-roman {
    font-family: "Times New Roman";
  }

  .ql-font-verdana {
    font-family: Verdana;
  }

  .dx-htmleditor {
    border: none !important;
    background-color: transparent !important;
  }

  .dx-field-item-label-location-top{
    padding-left: 0px !important;
  }

  .react-grid-layout {
  position: relative;
}

  .dragging-clone {
    position: fixed;
    
    &.mf-treelist-item{
      top: -500px;
      left: -500px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      background-color: ${(props) => props.theme.palette.background.default};
      padding: 10px;
      border: 1px solid ${(props) => props.theme.palette.primary.main};
      border-radius: 4px;
      opacity: 1;
      width: 200px;

      .label-text {

        font-size: 12px;
        font-weight: 500;
        color: ${(props) => props.theme.palette.text.primary};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

      }

      svg {
        color: ${(props) => props.theme.palette.primary.main};
        width: 20px;
        height: 20px;
      }

      &.node-container svg {
        color: orange;
      }
    }
  }

`;

export default GlobalStyle;
