import randomColor from "randomcolor";

const getRandomColor = (seed, hueIndex = 1, luminosity = "dark") => {
  let hue = "blue";

  switch (hueIndex % 5) {
    case 0:
      hue = "green";
      break;
    case 1:
      hue = "blue";
      break;
    case 2:
      hue = "red";
      break;
    case 3:
      hue = "orange";
      break;
    case 4:
      hue = "purple";
      break;
    default:
      hue = "blue";
      break;
  }

  return randomColor({
    luminosity: luminosity,
    hue: hue,
    seed: seed,
  });
};

export default getRandomColor;
