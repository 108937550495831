const chartOptions = {
  labels: [],
  chart: {
    background: "unset",
    type: "donut",
    height: 350,
  },
  theme: {
    mode: "dark",
    palette: "palette10",
  },
  stroke: {
    show: false,
  },
  legend: {
    show: false,
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
      },
    },
  ],
};

export default chartOptions;
