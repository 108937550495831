import styled from "styled-components";
import { Button } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import Dialog from "../../../Monolith-UI/Dialog/Dialog.js";
import Loader from "../../Loader.js";
import DateField from "../../../Monolith-UI/DateField/DateField.js";
import moment from "moment";
import { getDateFormat } from "../../../utils/date-format";
import TimeEntriesAPI from "../../../api/TimeEntries/time-entries.js";
import TaskCategorySelector from "./TaskCategorySelector.js";
import TaskSelector from "./TaskSelector.js";
import CaseSelector from "./CaseSelector.js";

const Menu = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.divider};
  margin: 10px 0;
`;

const DescriptionInput = styled.textarea`
  background-color: transparent;
  border: none;
  width: 100%;
  min-height: 100px;
  padding: 3px;

  color: ${({ theme }) => theme.palette.text.primary};

  font-size: 14px;
  font-family: inherit;

  // Set to single line
  display: block;

  //Remove resize handle
  resize: none;

  &:focus {
    outline: none;
  }
`;

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 200px;
  min-width: 600px;

  // Allow height to increase with content
  height: fit-content;

  .task-name {
    font-size: 10px;
    font-weight: 500;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

const StyledButton = styled(Button)`
  padding: 0 10px;
`;

const AddCustomTimeModal = ({
  open = false,
  onClose,
  defaultFormData = {},
  formOptions = {},
  onSubmit,
}) => {
  const [formData, setFormData] = useState(defaultFormData);
  const [errors, setErrors] = useState({});
  const [selectedCase, setSelectedCase] = useState({
    case_id: defaultFormData?.case_id,
    case_number: defaultFormData?.case_number,
  });
  const [selectedTask, setSelectedTask] = useState({
    task_id: defaultFormData?.task_id,
    task_name: defaultFormData?.task_name,
  });
  const [selectedCategory, setSelectedCategory] = useState(
    defaultFormData?.category
  );
  const [submitting, setSubmitting] = useState(false);
  const descriptionRef = useRef(null);

  const duration = moment(formData.end_time).diff(
    moment(formData.start_time),
    "hours",
    true
  );

  const handleDescriptionChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      description: e.target.value,
    }));
  };

  const handleCaseChange = (caseRecord) => {
    setFormData((prev) => ({
      ...prev,
      case_id: caseRecord.case_id,
    }));
    setErrors((prev) => ({
      ...prev,
      case: null,
    }));
  };

  const handleTaskChange = (task) => {
    setSelectedTask(task);
    setFormData((prev) => ({
      ...prev,
      task_id: task.task_id,
    }));
    setErrors((prev) => ({
      ...prev,
      task: null,
    }));
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setFormData((prev) => ({
      ...prev,
      category_id: category.category_id,
    }));
  };

  const validateForm = () => {
    const errors = {};
    if (!formData?.start_time) errors.start_time = "Start time is required";
    if (!formData?.end_time) errors.end_time = "End time is required";
    if (!formData?.case_id) errors.case = "Case is required";
    if (!formData?.task_id) errors.task = "Task is required";

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    setSubmitting(true);
    const entryDate = moment().toISOString();

    const values = Object.assign({}, formData);
    delete values.task;
    values.entry_date = entryDate;
    const duration = moment(formData.end_time).diff(
      moment(formData.start_time),
      "seconds"
    );

    //Create Time entry in API
    await TimeEntriesAPI.createTimeEntry({
      ...values,
      duration,
      is_bulk: 1,
    });

    onSubmit?.({ ...formData, entry_date: entryDate, duration });

    onClose?.();
    setSubmitting(false);
  };

  useEffect(() => {
    setFormData(defaultFormData);
  }, [defaultFormData]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        {submitting && <Loader message="Creating Time Entry..." />}
        {!submitting && (
          <>
            <div>
              <div
                style={{
                  margin: 0,
                  fontSize: 20,
                  fontWeight: 600,
                }}
              >
                Create Time Entry{" "}
                <span style={{ fontSize: 14, fontWeight: "normal" }}>
                  {`(${duration.toFixed(2)} hrs)`}
                </span>
              </div>
            </div>
            <DescriptionInput
              ref={descriptionRef}
              placeholder={"Time entry description..."}
              maxLength={512}
              autoComplete="off"
              onChange={handleDescriptionChange}
            />
            <div
              style={{
                display: "grid",
                gridGap: 20,
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              <DateField
                label={{ text: "Start Time" }}
                error={errors?.start_time}
                showDropDownArrow={true}
                enableCalendar={true}
                max={formData?.end_time}
                defaultValue={defaultFormData?.start_time}
                size="sm"
                format={getDateFormat({ isMoment: true, includeTime: true })}
                onChange={(newValue) => {
                  return setFormData((prev) => ({
                    ...prev,
                    duration: moment(formData.end_time).diff(
                      newValue,
                      "seconds"
                    ),
                    start_time: newValue,
                  }));
                }}
              />
              <DateField
                label={{ text: "End Time" }}
                error={errors?.end_time}
                showDropDownArrow={true}
                enableCalendar={true}
                min={formData?.start_time}
                defaultValue={defaultFormData?.end_time}
                size="sm"
                format={getDateFormat({ isMoment: true, includeTime: true })}
                onChange={(newValue) =>
                  setFormData((prev) => ({
                    ...prev,
                    duration: moment(newValue).diff(
                      formData.start_time,
                      "seconds"
                    ),
                    end_time: newValue,
                  }))
                }
              />
            </div>
            <div style={{ marginTop: "auto" }}>
              <Menu>
                <CaseSelector
                  value={selectedCase}
                  error={errors?.case}
                  showLabel={true}
                  variant="contained"
                  onSelect={handleCaseChange}
                  disabled={formOptions?.case?.disabled || false}
                />
                <TaskSelector
                  value={selectedTask}
                  error={errors?.task}
                  defaultQuery={
                    formData?.case_id ? { case_id: formData?.case_id } : null
                  }
                  showLabel={true}
                  variant="contained"
                  disabled={
                    formOptions?.task?.disabled || !formData?.case_id || false
                  }
                  onSelect={handleTaskChange}
                />
                <TaskCategorySelector
                  value={selectedCategory}
                  showLabel={true}
                  variant="contained"
                  onSelect={handleCategoryChange}
                />
              </Menu>
              <Divider />
              <Menu topBorder style={{ justifyContent: "flex-end", gap: 10 }}>
                <StyledButton
                  variant="text"
                  color="primary"
                  onClick={(e) => onClose()}
                >
                  Cancel
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                >
                  Create Entry
                </StyledButton>
              </Menu>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AddCustomTimeModal;
