import { getDateFormat } from "../../utils/date-format";
import CaseNumberCell from "../Evidence/CaseNumberCell.js";

const LoggingColumnDefs = [
  {
    dataField: "timestamp",
    caption: "Timestamp",
    dataType: "date",
    format: {
      type: getDateFormat({ isMoment: true, includeTime: true }),
    },
    width: 150,
  },
  {
    dataField: "admin",
    caption: "Admin Log",
    width: 100,
    render: (data) => (data.admin ? "Yes" : "No"),
    enabled: false,
  },
  {
    dataField: "user.full_name",
    caption: "User",
    width: 100,
  },
  {
    dataField: "case_number",
    caption: "Case Number",
    visible: false,
    enabled: false,
    render: (rowData) => {
      return <CaseNumberCell rowData={rowData} />;
    },
  },
  {
    dataField: "case_ref",
    caption: "Case Name",
    visible: false,
    enabled: false,
  },
  {
    dataField: "activity",
    caption: "Log",
    width: "auto",
    titleRender: (rowData) => {
      return rowData.activity;
    },
  },
];

export default LoggingColumnDefs.slice();
