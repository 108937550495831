import styled from "styled-components";

const OverviewContent = styled(({ className, inquiryInfo }) => {
  return (
    <div className={className}>
      <div className="action-menu"></div>
      <div className="description-container">
        <div className="description-header">Description</div>
        <div>{inquiryInfo.inquiry_notes || "No Description"}</div>
      </div>
    </div>
  );
})`
  font-size: 12px;
  .action-menu {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
  }
  .description-container {
    padding: 15px;
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 5px;
    white-space: pre-wrap;
    overflow-y: auto;
    max-height: 550px;
  }
  & .description-header {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 12px;
    font-weight: 500;
    cursor: default;
    margin-bottom: 10px;
  }
`;

export default OverviewContent;
