import { Button, Typography, useTheme } from "@mui/material";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useMemo, useState, useEffect } from "react";
import styled from "styled-components";

import PeopleIcon from "@mui/icons-material/People";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";

import ContactsColumnDefs from "../../components/Contacts/ContactsColumnDefs.js";
import { usePermissions } from "../../hooks/usePermissions";
import Table, { Column, useTable } from "../../Monolith-UI/Table/Table.js";
import ContactsAPI from "../../api/contacts/index.js";
import { useQueryFilter } from "../../Monolith-UI/QueryFilter/QueryFilter.js";
import ContactsFilterDimensions from "../../components/Contacts/ContactsFilterDimensions.js";
import { getDateFormat } from "../../utils/date-format";
import { ItemTotal } from "../Cases/CaseEvidence/index";
import ComboButton from "../../Monolith-UI/ComboButton/ComboButton.js";
import Loader from "../../components/Loader.js";
import { Helmet } from "react-helmet-async";
import synchronizeColumnState from "../../utils/synchronize-column-state.js";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { useNavigate } from "react-router-dom";
import { Input } from "@monolith-forensics/monolith-ui";

import AccessDeniedMessage from "../../components/AccessDeniedMessage.js";
import { MONOLITH_PERMISSIONS } from "../../constants.js";
import { Case, Condition } from "../Cases/types/Cases.js";
import { ColumnProps, TableColumn } from "@/Monolith-UI/Table/types/Table.js";

import { CreateContactModal } from "@/components/Modals";
import { ContactFlyout } from "@/components/Flyouts";
import { Contact } from "@/types";

interface ContactsQuery {
  query: {
    conditions?: Condition[];
    page?: number;
    search?: string | null;
    order?: {
      field: string;
      sort: string;
    } | null;
  };
}

const actions = [
  { value: 0, text: "Assign Contacts", permission: null },
  {
    value: 1,
    text: "Import from CSV",
    permission: MONOLITH_PERMISSIONS.CONTACTS_BULK_IMPORT,
  },
];

const columnDefs = ContactsColumnDefs;

const CaseContactsSectionContent = styled(
  ({
    className,
    stateStoreKey = "contacts:view",
    defaultQueryKey = "contacts:list",
    caseInfo,
  }) => {
    const theme = useTheme();
    const { hasPermission } = usePermissions();
    const { enqueueSnackbar } = useSnackbar();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [flyoutData, setFlyoutData] = useState<Contact | null>(null);
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const [columnState, setColumnState] = useState(() => {
      return synchronizeColumnState(
        columnDefs,
        JSON.parse(localStorage.getItem(stateStoreKey) || "{}")
      );
    });
    const [pageSize, setPageSize] = useState(100);
    const [query, setQuery] = useState<ContactsQuery | null>(null);
    const [searchText, setSearchText] = useState(
      localStorage.getItem(`${stateStoreKey}:searchText`)
    );
    const [debouncedSearchText] = useDebounce(searchText, 500);

    const navigate = useNavigate();

    const table = useTable();

    const currentSort = useMemo(() => {
      let [sort] = columnState
        ?.filter((c) => c.sorting?.active)
        .map((c) => ({ field: c.dataField, sort: c.sorting?.direction }));

      return sort;
    }, [columnState]);

    const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } =
      useInfiniteQuery({
        queryKey: [
          defaultQueryKey,
          {
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
              case_id: caseInfo?.case_id,
            },
          },
        ],
        queryFn: ({ pageParam }) =>
          ContactsAPI.query({
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
              case_id: caseInfo?.case_id,
              page: pageParam,
            },
          }),
        getNextPageParam: (lastPage) => {
          return lastPage.nextPage;
        },
        getPreviousPageParam: (firstPage) => {
          if (firstPage.page - 1 === 0) return null;
          return firstPage.page - 1;
        },
        initialPageParam: 1,
        enabled: !!query,
        placeholderData: (data) => data,
      });

    const records = data?.pages?.reduce((acc, page) => {
      return [...acc, ...page.data];
    }, []);

    const totalRecords = data?.pages?.[0]?.total || 0;

    const debouncedFetchNextPage = useDebouncedCallback(() => {
      fetchNextPage();
    }, 50);

    const { queryButton, queryFilter, conditions } = useQueryFilter({
      dimensions: ContactsFilterDimensions,
      onQuerySet: (newFilter) =>
        setQuery((q) => {
          return { query: { ...q?.query, ...newFilter, page: 1 } };
        }),
      stateStoring: {
        enabled: true,
        type: "localStorage",
        storageKey: stateStoreKey,
      },
      queryFilter: undefined,
    });

    const handleSort = (field: string) => {
      const savedColumn =
        columnState?.find((svc) => field === svc.dataField) || {};
      const order = !!savedColumn ? savedColumn?.sorting?.direction : null;

      let newOrder = null;

      // if sorted on a different column, reset the sort
      if (query?.query?.order?.field !== field) {
        newOrder = {
          field,
          sort: "asc",
        };
      }

      // otherwise rotate the sort options on the current column
      else {
        switch (order) {
          case "asc":
            newOrder = {
              field,
              sort: "desc",
            };
            break;
          case "desc":
            newOrder = null;
            break;
          default:
            newOrder = {
              field,
              sort: "asc",
            };
        }
      }

      let newCols = columnState?.map((c) => {
        if (c.dataField === field) {
          return {
            ...c,
            sorting: {
              active: newOrder ? true : false,
              direction: newOrder?.sort,
            },
          };
        }
        delete c.sorting;
        return c;
      });

      let newQuery = {
        query: {
          ...query?.query,
          order: newOrder,
        },
      };

      setColumnState(newCols);

      setQuery(newQuery);
    };

    const handleExportTable = () => {
      enqueueSnackbar("Exporting table...", {
        variant: "info",
      });

      ContactsAPI.exportContactsList({
        query: {
          ...query?.query,
          order: query?.query?.order || currentSort,
          pageSize,
        },
        type: "xlsx",
        columns: columnState
          .filter((c) => c.visible !== false)
          .sort((a, b) => a.order - b.order)
          .map((c) => {
            return { dataField: c.dataField, header: c.caption, ...c };
          }),
        date_format: getDateFormat({ isMoment: true, includeTime: true }),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }).then((res) => {
        const { signedUrl, filename } = res;
        const el = document.createElement("a");
        el.href = signedUrl.replace(
          "http://localhost:3000",
          "http://localhost:3001"
        );

        el.download = filename;
        el.click();
      });
    };
    const handleColumnReorder = (
      newOrder: { column: string; order: number }[]
    ) => {
      setColumnState((cs) => {
        return newOrder.map((o) => {
          return {
            ...cs.find((c) => c.dataField === o.column),
            order: o.order,
          };
        });
      });
    };
    const handleColumnVisibility = (
      column: ColumnProps<Contact>,
      visible: boolean
    ) => {
      setColumnState((cs) => {
        return cs.map((c) => {
          if (c.dataField === column.dataField) {
            return {
              ...c,
              visible,
            };
          }
          return c;
        });
      });
    };
    const handleActionButtonClick = (rowData: Contact) => {
      setFlyoutData(rowData);
      setIsFlyoutVisible(true);
    };
    const handleActionSelect = (action: { text: string; value: number }) => {
      switch (action.value) {
        case 0:
          setShowAssignModal(true);
          break;
        case 1:
          navigate(`/cases/${caseInfo.case_id}/contacts?v=import`);
          break;
        default:
          break;
      }
    };
    const handleClearFilters = () => queryFilter.clear();
    const handleContactsRefetch = () => {
      refetch();
    };
    const handleGetNextItem = () => {
      const current = records?.findIndex(
        (i: Contact) => i.client_id === flyoutData?.client_id
      );
      const nextItem = records[current + 1] || records[0];

      setFlyoutData(nextItem);
    };
    const handleGetPrevItem = () => {
      const current = records?.findIndex(
        (i: Contact) => i.client_id === flyoutData?.client_id
      );
      const prevItem = records[current - 1] || records[records.length - 1];

      setFlyoutData(prevItem);
    };
    // Detect scroll to bottom
    const handleScroll = (e: React.MouseEvent) => {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      const pagesLength = data?.pages?.length || 0;
      if (scrollHeight - scrollTop <= clientHeight + 100 * pagesLength) {
        if (hasNextPage && !isFetchingNextPage) {
          debouncedFetchNextPage();
        }
      }
    };

    // Persist column state to local storage
    useEffect(() => {
      const oldStateLocalStorage = localStorage.getItem(stateStoreKey);
      let oldState = oldStateLocalStorage
        ? JSON.parse(oldStateLocalStorage)
        : {};
      localStorage.setItem(
        stateStoreKey,
        JSON.stringify({
          ...oldState,
          cols: columnState,
        })
      );
    }, [columnState, stateStoreKey]);

    useEffect(() => {
      setQuery((q) => ({
        query: {
          ...q?.query,
          search: debouncedSearchText ? debouncedSearchText : null,
          page: 1,
        },
      }));
    }, [debouncedSearchText]);

    return (
      <div className={className}>
        {!caseInfo && (
          <>
            <Helmet title="Contacts" />
            <Typography variant="h3" gutterBottom display="inline">
              Contacts
            </Typography>
          </>
        )}
        <div
          style={{
            display: "flex",
            flex: "initial",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={!hasPermission()}
            onClick={(e) => {
              setShowCreateModal(true);
            }}
            style={{
              minWidth: "fit-content",
              fontSize: 11,
              padding: "3px 6px",
            }}
          >
            + New Contact
          </Button>
          <div style={{ marginLeft: 10 }}>{queryButton}</div>
          <ItemTotal total={totalRecords || 0} Icon={PeopleIcon} />
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              minWidth: "fit-content",
            }}
          >
            {hasPermission() && caseInfo && (
              <ComboButton
                type="dropDown"
                data={actions.filter(
                  (a) => !a.permission || hasPermission(a.permission)
                )}
                title={"More Actions"}
                showDropdownIcon={true}
                displayField="text"
                variant="outlined"
                useSelectMode={false}
                onItemSelect={(item) => handleActionSelect(item)}
                textColor={theme.palette.text.primary}
              >
                Actions
              </ComboButton>
            )}
            <ComboButton
              type="multi-select"
              data={columnState}
              displayField="caption"
              idField={"dataField"}
              selectedItems={columnState.filter((c) => c.visible !== false)}
              variant="outlined"
              closeOnSelect={false}
              textColor={theme.palette.text.secondary}
              title={"Select Columns"}
              showSearch={true}
              dropDownTitle={() => {
                return (
                  <div
                    style={{
                      margin: "5px 0px",
                      padding: 3,
                      color: theme.palette.text.secondary,
                      display: "flex",
                      alignItems: "center",
                      minWidth: 200,
                    }}
                  >
                    Select Columns
                  </div>
                );
              }}
              onItemDeSelect={(item) => {
                handleColumnVisibility(item, false);
              }}
              onItemSelect={(item) => {
                handleColumnVisibility(item, true);
              }}
            >
              <ViewColumnOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Export Table"}
              onClick={handleExportTable}
            >
              <FileDownloadOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Clear Filters"}
              onClick={handleClearFilters}
            >
              <FilterListOffIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={table.isCompact ? "Zoom In" : "Zoom Out"}
              onClick={() => table.toggleCompact()}
            >
              {table.isCompact ? (
                <ZoomOutMapOutlinedIcon style={{ fontSize: 18 }} />
              ) : (
                <ZoomInMapOutlinedIcon style={{ fontSize: 18 }} />
              )}
            </ComboButton>
            <ComboButton
              type="button"
              variant={"outlined"}
              textColor={
                table.isStriped
                  ? theme.palette.primary.main
                  : theme.palette.text.secondary
              }
              title={table.isStriped ? "Hide Stripes" : "Show Stripes"}
              onClick={() => table.toggleStripes()}
            >
              <ReorderOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <Input
              placeholder="Search Contacts"
              variant="outlined"
              height={30}
              defaultValue={searchText}
              onKeyUp={(e: React.KeyboardEvent) => {
                const target = e.target as HTMLInputElement;
                const currentValue = target.value.trim();
                setSearchText(currentValue);

                localStorage.setItem(
                  `${stateStoreKey}:searchText`,
                  currentValue
                );
              }}
              style={{
                marginLeft: "10px",
                border: searchText
                  ? `1px solid ${theme.palette.primary.main}`
                  : "",
              }}
            />
          </div>
        </div>
        {data && (
          <>
            {<div>{conditions}</div>}
            <Table
              data={records}
              totalRecords={totalRecords || 0}
              tableInstance={table}
              keyValue="client_id"
              columnProps={{ minWidth: 150, width: 150 }}
              onHeaderClick={(col: TableColumn<Contact>) =>
                col?.sorting?.enabled === false
                  ? null
                  : handleSort(col.dataField)
              }
              onScroll={handleScroll}
              onColumnReorder={handleColumnReorder}
              onActionButtonClick={handleActionButtonClick}
              showActionColumn={true}
              focusedRow={flyoutData}
              showSelection={false}
            >
              {columnState.map((col) => {
                return <Column key={col.dataField} {...col} />;
              })}
            </Table>
          </>
        )}
        {!data && <Loader message={"Retrieving Items..."} />}
        {isFlyoutVisible && flyoutData && (
          <ContactFlyout
            show={isFlyoutVisible}
            onClose={() => {
              setIsFlyoutVisible(false);
              setFlyoutData(null);
            }}
            onDelete={() => {
              setIsFlyoutVisible(false);
              setFlyoutData(null);
              refetch();
            }}
            onEdit={handleContactsRefetch}
            defaultInfo={flyoutData}
            onNext={handleGetNextItem}
            onPrevious={handleGetPrevItem}
          />
        )}
        <CreateContactModal
          show={showCreateModal}
          onClose={() => setShowCreateModal(false)}
          onCancel={() => setShowCreateModal(false)}
          onSubmit={handleContactsRefetch}
        />
      </div>
    );
  }
)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const CaseContacts = (props: {
  className?: string;
  stateStoreKey?: string;
  defaultQueryKey?: string;
  caseInfo?: Case;
}) => {
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();

  if (!hasPermission(MONOLITH_PERMISSIONS.CONTACTS_READ_ALL)) {
    return <AccessDeniedMessage />;
  }

  return <CaseContactsSectionContent {...props} />;
};

export default CaseContacts;
