import { Button, Divider, Typography, useTheme } from "@mui/material";
import styled from "styled-components";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import { useRef } from "react";
import RelayAPI from "../../../api/RelayAPI/index.js";
import { useSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import { RelayUrl } from "../System";

const BasicDetails = styled(({ className, relayInfo }) => {
  return (
    <div className={className}>
      <RelayAccess relayInfo={relayInfo} />
      <Divider style={{ margin: "30px 0px" }} />
      <TenantLogo relayInfo={relayInfo} />
      <Divider style={{ margin: "30px 0px" }} />
      <TenantName relayInfo={relayInfo} />
      <Divider style={{ margin: "30px 0px" }} />
      <URLslug relayInfo={relayInfo} />
      <Divider style={{ margin: "30px 0px" }} />
      <TenantEmail relayInfo={relayInfo} />
      <Divider style={{ margin: "30px 0px" }} />
    </div>
  );
})`
  overflow-y: auto;
  padding-right: 20px;
  .relay-link {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.palette.primary.main};
    cursor: pointer;
    margin: 10px 0px;
    &:hover {
      text-decoration: underline;
    }
  }
  .message {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-weight: 400;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
`;

const RelayAccess = ({ relayInfo }) => {
  return (
    <div>
      <Typography variant="h3" display="inline">
        Access Your Relay Tenant
      </Typography>
      <div className="message">
        This is the URL you can use to access Relay. Relay uses a different
        authentication system from Monolith, so you will log in with a different
        password.
      </div>
      <RelayUrl relayInfo={relayInfo} />
    </div>
  );
};

const URLslug = ({ relayInfo }) => {
  const form = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const handleUpdate = () => {
    const res = form.current.instance.validate();
    if (res.status === "pending") {
      res.complete.then((r) => {
        if (r.isValid) {
          const slug = form.current.instance.option("formData").tenant_slug;
          RelayAPI.updateRelaySlug({
            tenant_slug: slug,
            tenant_uuid: relayInfo.tenant_uuid,
          }).then((res) => {
            if (res.success) {
              enqueueSnackbar("URL slug updated successfully", {
                variant: "success",
              });

              queryClient.setQueryData(
                [
                  "relayInfo",
                  {
                    cloud_tenant_id: relayInfo.monolith_tenant,
                    include_logo: true,
                    include_instructions: true,
                  },
                ],
                ([old]) => [
                  {
                    ...old,
                    tenant_slug: slug,
                  },
                ]
              );
            }
          });
        }
      });
    }
  };

  return (
    <div>
      <Typography variant="h3" display="inline">
        Relay URL Slug
      </Typography>
      <div className="message">
        This sets the URL identifier for your Relay tenant.
      </div>
      <div>
        <Form ref={form} colCount={1} width={250}>
          <SimpleItem
            dataField="tenant_slug"
            editorType="dxTextBox"
            label={{ visible: false }}
            editorOptions={{
              placeholder: "Enter a new URL slug",
              value: relayInfo.tenant_slug,
            }}
            validationRules={[
              {
                type: "required",
                message: "This field is required",
              },
              {
                type: "pattern",
                pattern: "^[a-z0-9-]+$",
                message:
                  "Only lowercase letters, numbers, and dashes are allowed",
              },
              {
                type: "stringLength",
                min: 3,
                max: 30,
                message: "Must be between 3 and 30 characters",
              },
              {
                type: "async",
                message: "This URL slug is already in use",
                validationCallback: async (e) => {
                  return RelayAPI.verifySlug({ tenant_slug: e.value }).then(
                    (res) => {
                      return res.success;
                    }
                  );
                },
              },
            ]}
          />
        </Form>
      </div>
      <div>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 10, padding: "3px 6px" }}
          size="small"
          onClick={handleUpdate}
        >
          Update Slug
        </Button>
      </div>
    </div>
  );
};

const TenantEmail = ({ relayInfo }) => {
  const form = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const handleUpdate = () => {
    const res = form.current.instance.validate();
    if (!res.isValid) return;
    const email = form.current.instance.option("formData").tenant_email;

    RelayAPI.updateTenantEmail({
      tenant_email: email,
      tenant_uuid: relayInfo.tenant_uuid,
    }).then((res) => {
      if (res.success) {
        enqueueSnackbar("Relay tenant email updated successfully", {
          variant: "success",
        });

        queryClient.setQueryData(
          [
            "relayInfo",
            {
              cloud_tenant_id: relayInfo.monolith_tenant,
              include_logo: true,
              include_instructions: true,
            },
          ],
          ([old]) => [
            {
              ...old,
              tenant_email: email,
            },
          ]
        );
      }
    });
  };

  return (
    <div>
      <Typography variant="h3" display="inline">
        Tenant Email
      </Typography>
      <div className="message">
        This is the default email for your Relay tenant. Notifications from
        Relay (new users, requests, etc...) will be sent to this email address.
      </div>
      <div>
        <Form ref={form} colCount={1} width={250}>
          <SimpleItem
            dataField="tenant_email"
            editorType="dxTextBox"
            label={{ visible: false }}
            editorOptions={{
              placeholder: "Enter a new Relay tenant email",
              mode: "email",
              value: relayInfo.tenant_email,
            }}
            validationRules={[
              {
                type: "required",
                message: "This field is required",
              },
              {
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
          />
        </Form>
      </div>
      <div>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 10, padding: "3px 6px" }}
          size="small"
          onClick={handleUpdate}
        >
          Update Email
        </Button>
      </div>
    </div>
  );
};

const TenantName = ({ relayInfo }) => {
  const form = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const handleUpdate = () => {
    const res = form.current.instance.validate();
    if (!res.isValid) return;
    const name = form.current.instance.option("formData").tenant_org;

    RelayAPI.updateTenantName({
      tenant_org: name,
      tenant_uuid: relayInfo.tenant_uuid,
    }).then((res) => {
      if (res.success) {
        enqueueSnackbar("Relay tenant name updated successfully", {
          variant: "success",
        });

        queryClient.setQueryData(
          [
            "relayInfo",
            {
              cloud_tenant_id: relayInfo.monolith_tenant,
              include_logo: true,
              include_instructions: true,
            },
          ],
          ([old]) => [
            {
              ...old,
              tenant_org: name,
            },
          ]
        );
      }
    });
  };
  return (
    <div>
      <Typography variant="h3" display="inline">
        Tenant Name
      </Typography>
      <div className="message">
        This is the name of your Relay tenant that will display in Relay.
      </div>
      <div>
        <Form ref={form} colCount={1} width={250}>
          <SimpleItem
            dataField="tenant_org"
            editorType="dxTextBox"
            label={{ visible: false }}
            editorOptions={{
              placeholder: "Enter a new Relay tenant name",
              value: relayInfo.tenant_org,
            }}
            validationRules={[
              {
                type: "required",
                message: "This field is required",
              },
              {
                type: "pattern",
                pattern: "^[a-zA-Z0-9 ]+$",
                message: "Only letters, numbers, and spaces are allowed",
              },
            ]}
          />
        </Form>
      </div>
      <div>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 10, padding: "3px 6px" }}
          size="small"
          onClick={handleUpdate}
        >
          Update Name
        </Button>
      </div>
    </div>
  );
};

const TenantLogo = ({ relayInfo }) => {
  const imgRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const theme = useTheme();

  const handleUpdateLogo = (e) => {
    // Check if file is an image
    if (!e.target.files[0].type.includes("image")) {
      enqueueSnackbar(`File is not an image`, {
        variant: "error",
      });
      return;
    }

    // Check that file size is less than 100KB
    if (e.target.files[0].size > 100000) {
      enqueueSnackbar(`File is too large, must be less than 100KB`, {
        variant: "error",
      });
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);

    reader.onload = () => {
      imgRef.current.src = reader.result;

      // get base64 data form reader result
      const base64 = reader.result.split(";base64,")[1];

      RelayAPI.updateTenantLogo({
        tenant_uuid: relayInfo.tenant_uuid,
        tenant_logo: base64,
      }).then((res) => {
        if (res.success) {
          enqueueSnackbar("Relay tenant logo updated successfully", {
            variant: "success",
          });

          queryClient.setQueryData(
            [
              "relayInfo",
              {
                cloud_tenant_id: relayInfo.monolith_tenant,
                include_logo: true,
                include_instructions: true,
              },
            ],
            ([old]) => [
              {
                ...old,
                tenant_logo: reader.result,
              },
            ]
          );
        }
      });
    };
  };

  return (
    <div>
      <Typography variant="h3" display="inline">
        Tenant Logo
      </Typography>
      <div className="message">
        This is the logo that will display in Relay.
      </div>
      <div style={{ maxHeight: 125 }}>
        <img
          ref={imgRef}
          src={relayInfo.tenant_logo}
          alt="logo"
          style={{
            width: "auto",
            maxHeight: "125px",
            display: relayInfo.tenant_logo ? "block" : "none",
          }}
        />
        {!relayInfo.tenant_logo && (
          <div style={{ color: theme.palette.text.secondary }}>
            No logo uploaded
          </div>
        )}
      </div>
      <div style={{ marginTop: 20 }}>
        <input
          accept="*"
          style={{ display: "none" }}
          id="update-relay-logo"
          type="file"
          onChange={handleUpdateLogo}
          multiple
        />
        <label htmlFor="update-relay-logo">
          <Button
            size="small"
            variant="contained"
            color="primary"
            component="span"
          >
            Update Logo
          </Button>
        </label>
      </div>
    </div>
  );
};

export default BasicDetails;
