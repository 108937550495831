import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import { AuthService } from "../../api/auth/index.js";
import { useAuth } from "../../contexts/AuthContext.js";
import { Button, CssBaseline, useTheme } from "@mui/material";
import logo from "../../monolith_logo_white.png"; // Tell Webpack this JS file uses this image
import GlobalStyle from "../../components/GlobalStyle.js";
import { httpPost } from "../../api/connection.js";
import MFA from "./MFA.js";
import { useQuery } from "@tanstack/react-query";
import LoggingAPI from "../../api/logging/index.js";
import TaskButton from "../../Monolith-UI/TaskButton.js";
import { UserInfo } from "../Users/types/Users";

interface LoginResponse {
  success: boolean;
  userInfo: UserInfo;
}

const Login = () => {
  const theme = useTheme();
  const [currentView, setCurrentView] = useState(0);
  const [ssoLogin, setSsoLogin] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const emailInputeRef = useRef();
  const loginForm = useRef<any>(null);
  const resetForm = useRef<any>(null);

  const {
    login,
    setSessionData,
  }: {
    login: (email: string, password: string) => Promise<LoginResponse>;
    setSessionData: (defaultUserInfo: UserInfo) => Promise<void>;
  } = useAuth();

  const navigate = useNavigate();

  const handleLogin = useCallback(async () => {
    if (loginForm.current.instance.validate().isValid) {
      const { email, password }: { email: string; password: string } = {
        ...loginForm.current.instance.option().formData,
      };

      // Check sso and redirect if not on premises
      const domain = email.split("@")[1];

      const { sso_url } = await httpPost("/auth/sso", { domain });

      if (sso_url) {
        window.location.href = sso_url;
        return;
      }

      // Otherwise, login normally
      login(email, password).then(async (result) => {
        if (result?.success !== true) {
          setLoginError(true);
          loginForm.current &&
            loginForm.current.instance
              .getEditor("email")
              .option("isValid", false);
          loginForm.current &&
            loginForm.current.instance
              .getEditor("password")
              .option("isValid", false);
        } else {
          setLoginError(false);
          if (result?.userInfo.mfa_enabled === 0) {
            await setSessionData(result.userInfo);
            LoggingAPI.logCommonActivity({
              user_email: email,
              message: "User logged in with email and password",
            });
            navigate("/");
          } else setCurrentView(2);
        }
      });
    }
  }, []);

  const handleReset = () => {
    if (resetForm.current.instance.validate().isValid) {
      const { email }: { email: string } = {
        ...resetForm.current.instance.option().formData,
      };

      AuthService.resetPasswordRequest({ email: email });
      setRequestSent(true);
    }
  };

  const checkEmailForSSO = useCallback(async (e: any) => {
    const domain = e?.target?.value?.split("@")[1];
    //check if input is an email address
    if (domain && domain.includes(".")) {
      const { sso_url } = await httpPost("/auth/sso", { domain });

      if (sso_url) {
        setSsoLogin(true);
      } else setSsoLogin(false);
    } else {
      if (ssoLogin) setSsoLogin(false);
    }
  }, []);

  const { isLoading, data: sessionResponse } = useQuery({
    queryKey: ["check-session"],
    queryFn: () => AuthService.checkSession(),
  });

  useEffect(() => {
    //login when enter is pressed
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        const target = event.target as HTMLElement | null;
        if (target) {
          target.blur();
        }
        handleLogin();
      }
    };

    document.addEventListener("keydown", handleKeyDown, false);

    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, []);

  if (isLoading) return null;

  if (sessionResponse?.userInfo?.email) {
    navigate("/");
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "calc(100vh - 200px)",
        alignItems: "center",
      }}
    >
      <CssBaseline />
      <GlobalStyle />
      <div
        style={{
          width: 500,
          padding: "20px 60px",
          height: "auto",
          borderRadius: 5,
          backgroundColor: theme.palette.background.default,
          boxShadow: "none !important",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 25,
            }}
          >
            <img src={logo} height="85" alt="Monolith Forensics Logo"></img>
          </div>
          {currentView === 0 && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 25,
                  color: theme.palette.text.secondary,
                  fontSize: "large",
                }}
              >
                {!ssoLogin ? (
                  <div>Login to Monolith</div>
                ) : (
                  <div>Single Sign-on Enabled</div>
                )}
              </div>
              <div
                style={{
                  display: loginError ? "flex" : "none",
                  justifyContent: "center",
                  color: "red",
                }}
              >
                Incorrect email/password
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0px",
                }}
              >
                <Form colCount={1} ref={loginForm} width="100%">
                  <SimpleItem
                    dataField="email"
                    label={{ text: "Email", visible: false }}
                    editorOptions={{
                      mode: "email",
                      placeholder: "Email",
                      onInitialized: (e: any) =>
                        (emailInputeRef.current = e.component),
                      onInput: (e: any) => checkEmailForSSO(e.event),
                    }}
                    validationRules={[
                      {
                        type: "required",
                        message: "Email is required",
                      },
                    ]}
                  />
                  <SimpleItem
                    dataField="password"
                    label={{ text: "Password", visible: false }}
                    editorOptions={{
                      mode: "password",
                      placeholder: "Password",
                    }}
                    visible={!ssoLogin}
                    validationRules={[
                      {
                        type: "required",
                        message: "Password is required",
                      },
                    ]}
                  />
                </Form>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0px",
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  fullWidth
                  style={{ marginTop: 20 }}
                  onClick={handleLogin}
                >
                  Log In
                </Button>
              </div>
              {!ssoLogin && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px 0px",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <TaskButton
                    style={{ marginTop: 10, marginBottom: 10, padding: 8 }}
                    onClick={() => {
                      setCurrentView(1);
                    }}
                  >
                    Forgot Your Password?
                  </TaskButton>
                  {process.env.REACT_APP_PLATFORM === "electron" && (
                    <TaskButton
                      style={{ padding: 8 }}
                      onClick={async () => {
                        await window.electronAPI.clearSettings();
                        window.localStorage.clear();
                        window.location.reload();
                      }}
                    >
                      Reset Monolith
                    </TaskButton>
                  )}
                </div>
              )}
            </>
          )}
          {currentView === 1 && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: theme.palette.text.secondary,
                  fontSize: "large",
                }}
              >
                Reset Your Password
              </div>
              <div
                style={{
                  display: requestSent ? "flex" : "none",
                  justifyContent: "center",
                  margin: "20px 0px",
                  textAlign: "center",
                  color: theme.palette.primary.main,
                }}
              >
                An email with password reset instructions is on the way.
              </div>
              {!requestSent && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "20px 0px",
                    }}
                  >
                    <Form colCount={1} ref={resetForm} width="100%">
                      <SimpleItem
                        dataField="email"
                        label={{ text: "Email", visible: false }}
                        isRequired={true}
                        editorOptions={{ mode: "email", placeholder: "Email" }}
                      />
                    </Form>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "20px 0px",
                    }}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      fullWidth
                      style={{
                        marginTop: 20,
                      }}
                      onClick={handleReset}
                    >
                      Reset Password
                    </Button>
                  </div>
                </>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0px",
                }}
              >
                <TaskButton
                  style={{ marginTop: 10, marginBottom: 10, padding: 8 }}
                  onClick={() => {
                    setCurrentView(0);
                  }}
                >
                  Back to Login
                </TaskButton>
              </div>
            </>
          )}
          {currentView === 2 && <MFA />}
        </div>
      </div>
    </div>
  );
};

export default Login;
