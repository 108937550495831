import styled from "styled-components";
import NoteSystem from "../../../Monolith-UI/NoteSystem/NoteSystem.js";
import { usePermissions } from "../../../hooks/usePermissions";
import MonolithNotesAPI from "../../../api/Notes/index.js";
import { useEffect, useMemo, useRef, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import DropdownMenu from "../../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import { getDateFormat } from "../../../utils/date-format";
import moment from "moment";
import IndexedDBService from "../../../IndexedDB/IndexedDBService.js";
import LoggingAPI from "../../../api/logging/index.js";
import SaveTemplateModal from "../../../Monolith-UI/NoteSystem/Components/SaveTemplateModal.js";
import { NoteView } from "../../../Monolith-UI/NoteSystem/Enums/NoteView.js";

const NoteRenderer = ({ note, onNoteTitleEdit, onSave, active, templates }) => {
  const { currentUser, hasPermission } = usePermissions();
  const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [templateData, setTemplateData] = useState(null);
  const defaultQuery = [
    "notes:list",
    {
      uuid: note?.uuid || null,
      include_content: true,
      data_only: true,
    },
  ];
  const noteRef = useRef(null);
  const editorRef = useRef(null);
  noteRef.current = note;
  const queryClient = useQueryClient();
  const contentUpdateTimer = useRef(null);

  const readOnly =
    !hasPermission() ||
    noteRef.current?.created_by?.user_id !== currentUser.user_id;

  const { data, refetch, isFetching } = useQuery({
    queryKey: defaultQuery,
    queryFn: () => MonolithNotesAPI.getNotes(defaultQuery[1]),
    enabled: !!noteRef.current,
  });

  if (data?.length === 0 && !isFetching) refetch();

  const handleUpdateContent = (content, delay = 700) => {
    if (contentUpdateTimer.current) clearTimeout(contentUpdateTimer.current);

    // Do not upload base64 data
    // Image updloads and edit will be handled by the image uploader
    if (content.includes('src="data:image/')) {
      const parseHTML = new DOMParser().parseFromString(content, "text/html");

      const images = parseHTML.querySelectorAll(".monolith-image");

      // clear base64 data
      images.forEach((img) => {
        img.src = "";
        img.classList = "monolith-image";
      });

      // convert to string
      content = parseHTML.body.innerHTML;
    }

    contentUpdateTimer.current = setTimeout(() => {
      setIsSaving(true);
      MonolithNotesAPI.updateNote({
        uuid: noteRef.current.uuid,
        note_data: content,
      }).then((res) => {
        // Show saving indicator for min amount of time
        // wihout this, the saving indicator will flash too quickly
        setTimeout(() => {
          setIsSaving(false);
          // call this to update note data in case notes list
          onSave?.(noteRef.current);
        }, 125);
      });

      queryClient.setQueryData(defaultQuery, (oldData = []) => {
        if (!oldData?.[0]) return [];
        const newData = [...oldData];
        newData[0].note_data = content;
        newData[0].updated_at = new Date().toISOString();
        newData[0].updated_by = {
          full_name: currentUser.full_name,
          user_id: currentUser.user_id,
          title: currentUser.title,
          email: currentUser.email,
        };
        return newData;
      });
    }, delay);
  };

  const handleImageUpload = async (data) => {
    return new Promise(async (resolve, reject) => {
      const uploadURL = await MonolithNotesAPI.getUploadUrl({
        filename: data.name,
        case_uuid: noteRef.current.linked_case.uuid,
      });

      await MonolithNotesAPI.uploadImage({
        url: uploadURL.uploadUrl,
        file: data.file,
      });

      let image = new Image();
      image.src = uploadURL.downloadUrl;
      image.onload = () => {
        resolve(uploadURL.downloadUrl);
      };

      // Send API request to add attachment record for note and img
      MonolithNotesAPI.addAttachment({
        note_uuid: noteRef.current.uuid,
        uuid: data.id,
        filename: data.name,
        size: data.file.size,
        created_on: moment().toISOString(),
        md5: data.md5,
        sha1: data.sha1,
        sha256: data.sha256,
      });
    });
  };

  const handleExport = (uuid, format) => {
    const font = document.querySelector(".editor-content").dataset.font;
    MonolithNotesAPI.exportNotes({
      uuid: noteRef.current.uuid,
      font: font,
      format,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      dateFormat: getDateFormat({ isMoment: true, includeTime: true }),
    }).then((res) => {
      const { signedUrl, filename } = res;
      const el = document.createElement("a");
      el.href = signedUrl.replace(
        "http://localhost:3000",
        "http://localhost:3001"
      );

      el.download = filename;
      el.click();
      // remove snackbar
    });
  };

  const handleSaveTemplate = ({ note, content }) => {
    setShowSaveTemplateModal(true);
    setTemplateData({ note, content });
  };

  const submitSaveTemplate = ({ name, description, content, is_shared }) => {
    MonolithNotesAPI.createTemplate({
      name,
      description,
      content,
      is_shared,
    }).then((res) => {
      queryClient.refetchQueries({
        queryKey: [
          "notes:templates",
          { data_only: true, include_content: true },
        ],
      });
    });
  };

  // Add save note shortcut
  useEffect(() => {
    if (noteRef.current) {
      // save on ctrl s
      const handleSave = (e) => {
        if (e.ctrlKey && e.key === "s") {
          e.preventDefault();
          const content = editorRef.current.getHTML();
          handleUpdateContent(content, 0);
        }

        // save on cmd s
        if (e.metaKey && e.key === "s") {
          e.preventDefault();
          const content = editorRef.current.getHTML();
          handleUpdateContent(content, 0);
        }
      };
      window.addEventListener("keydown", handleSave);

      return () => {
        window.removeEventListener("keydown", handleSave);
      };
    }
  }, [note, handleUpdateContent]);

  const caseNote = data || [];
  const noteContent = caseNote?.[0]?.note_data || "";

  if (!noteRef.current) return null;

  if (!active) return null;

  return (
    <>
      {data && (
        <NoteSystem.NoteEditor
          readOnly={readOnly}
          noteRef={noteRef}
          noteContent={noteContent}
          handleUpdateContent={handleUpdateContent}
          handleImageUpload={handleImageUpload}
          handleExport={handleExport}
          onNoteTitleEdit={onNoteTitleEdit}
          templates={templates}
          onSaveTemplate={handleSaveTemplate}
          onEditorLoad={(editor) => {
            editorRef.current = editor;
          }}
          isSaving={isSaving}
        />
      )}
      <SaveTemplateModal
        open={showSaveTemplateModal}
        onSubmit={submitSaveTemplate}
        onClose={() => setShowSaveTemplateModal(false)}
        details={templateData}
      />
    </>
  );
};

const EvidenceNotes = styled(({ className, evidenceInfo }) => {
  const { currentUser, hasPermission } = usePermissions();
  const [noteView, setNoteView] = useState(NoteView.TREE);
  const queryClient = useQueryClient();
  const [defaultQuery, setDefaultQuery] = useState([
    "evidence:notes",
    {
      object_id: evidenceInfo.uuid,
      data_only: true,
      order: { field: "name", sort: "asc" },
      search: null,
      created_by_id: currentUser.user_id,
    },
  ]);

  // Set to read only if the current user is viewing another user's notes
  // Or if license has expired
  // read only is false when viewing all notes
  const readOnly =
    !hasPermission() ||
    (currentUser.user_id !== defaultQuery[1].created_by_id &&
      defaultQuery[1].created_by_id !== null);

  const { data, isFetched } = useQuery({
    queryKey: defaultQuery,
    queryFn: () => MonolithNotesAPI.getNotes(defaultQuery[1]),
    placeholderData: (data) => data,
  });

  const { data: noteUsers } = useQuery({
    queryKey: ["notes:users", { object_id: evidenceInfo.uuid }],
    queryFn: () =>
      MonolithNotesAPI.getNoteUsers({ object_id: evidenceInfo.uuid }),
  });

  const { data: templates } = useQuery({
    queryKey: ["notes:templates", { data_only: true, include_content: true }],
    queryFn: () =>
      MonolithNotesAPI.getTemplates({
        data_only: true,
        include_content: true,
        created_by_id: currentUser.user_id,
        include_shared: true,
      }),
    enabled: isFetched,
  });

  const { data: savedTabs, isFetched: savedTabsFetched } = useQuery({
    queryKey: ["notes:tabs", { uuid: evidenceInfo.uuid }],
    queryFn: () =>
      IndexedDBService.NoteTabs.getEvidenceNoteTabs({
        uuid: evidenceInfo.uuid,
      }),
    enabled: isFetched,
    //disable cache
    gcTime: 0,
  });

  const selectedUser = useMemo(() => {
    return noteUsers?.find(
      (user) => user.user_id === defaultQuery[1].created_by_id
    );
  }, [defaultQuery[1].created_by_id]);

  const onNoteCreated = (newNote) => {
    MonolithNotesAPI.createNote({
      uuid: newNote.uuid,
      case_id: evidenceInfo.case_id,
      case_uuid: evidenceInfo.case_uuid,
      note_tag: newNote.note_tag,
      path: newNote.path || null,
      created_on: newNote.created_on,
      created_by_id: currentUser.user_id,
      parent_id: newNote.parent_id,
      is_folder: newNote.is_folder,
      object_type: newNote.object_type || "evidence",
      object_id: newNote.object_id || evidenceInfo.uuid,
    });

    queryClient.setQueryData(defaultQuery, (oldData) => {
      const newData = [newNote, ...oldData];
      return newData;
    });

    LoggingAPI.logActivity(
      evidenceInfo.case_id,
      currentUser.user_id,
      `Created ${newNote.is_folder === 1 ? "note folder" : "note"} "${
        newNote.note_tag
      }", ID ${newNote.uuid}`
    );
  };

  const onNoteDeleted = (deletedNote) => {
    MonolithNotesAPI.deleteNote({
      uuid: deletedNote.uuid,
    });

    queryClient.setQueryData(defaultQuery, (oldData) => {
      const newData = [...oldData];
      const index = newData.findIndex((n) => n.uuid === deletedNote.uuid);
      newData.splice(index, 1);
      return newData;
    });

    LoggingAPI.logActivity(
      evidenceInfo.case_id,
      currentUser.user_id,
      `Deleted ${deletedNote.is_folder === 1 ? "note folder" : "note"} "${
        deletedNote.note_tag
      }", ID ${deletedNote.uuid}`
    );
  };

  const onNoteUpdated = (updatedNote) => {
    // Update note list state with updated note
    queryClient.setQueryData(defaultQuery, (oldData) => {
      const newData = [...oldData];
      const index = newData.findIndex((n) => n.uuid === updatedNote.uuid);
      newData[index].updated_at = updatedNote.updated_at;
      newData[index].updated_by = {
        full_name: currentUser.full_name,
        user_id: currentUser.user_id,
        title: currentUser.title,
        email: currentUser.email,
      };
      return newData;
    });

    LoggingAPI.logActivity(
      evidenceInfo.case_id,
      currentUser.user_id,
      `Updated note "${updatedNote.note_tag}", ID ${updatedNote.uuid}`
    );
  };

  const handleItemRename = ({ item, newName, newPath }) => {
    MonolithNotesAPI.updateNote({
      uuid: item.uuid,
      note_tag: newName,
      path: newPath,
    });

    queryClient.setQueryData(defaultQuery, (oldData) => {
      const newData = [...oldData];
      const index = newData.findIndex((n) => n.uuid === item.uuid);
      newData[index].note_tag = newName;
      newData[index].path = newPath;
      newData[index].updated_at = new Date().toISOString();
      newData[index].updated_by = {
        full_name: currentUser.full_name,
        user_id: currentUser.user_id,
        title: currentUser.title,
        email: currentUser.email,
      };
      return newData;
    });

    LoggingAPI.logActivity(
      evidenceInfo.case_id,
      currentUser.user_id,
      `Renamed ${item.is_folder === 1 ? "note folder" : "note"} "${
        item.note_tag
      }" → "${newName}", ID ${item.uuid}`
    );
  };

  const handleNoteMove = ({ source, target, newPath }) => {
    MonolithNotesAPI.updateNote({
      uuid: source.uuid,
      parent_id: target?.uuid || null,
      path: newPath,
      object_id: target?.object_id || evidenceInfo.uuid,
      object_type: target?.object_type || "evidence",
    });

    queryClient.setQueryData(defaultQuery, (oldData) => {
      const newData = [...oldData];
      const index = newData.findIndex((n) => n.uuid === source.uuid);
      newData[index].parent_id = target?.uuid;
      newData[index].path = newPath;
      newData[index].object_id = target?.object_id || evidenceInfo.uuid;
      newData[index].object_type = target?.object_type || "evidence";
      newData[index].object_name = target?.object_name || null;
      return newData;
    });

    // Triggers a re-render of the note list
    setDefaultQuery((prev) => {
      return [...prev];
    });

    LoggingAPI.logActivity(
      evidenceInfo.case_id,
      currentUser.user_id,
      `Moved ${source.is_folder === 1 ? "note folder" : "note"} "${
        source.note_tag
      }", ID ${source.uuid} - "${source.object_name}\\${source.path}" → "${
        target.object_name
      }\\${newPath}"`
    );
  };

  const handleNoteViewChange = (newView) => {
    // If switching to tree view, ensure that "All users" option is not selected
    if (newView === NoteView.TREE && defaultQuery[1].created_by_id === null) {
      setDefaultQuery((prev) => {
        const newState = [...prev];
        newState[1].created_by_id = currentUser.user_id;
        return newState;
      });
    }

    setNoteView(newView);
  };

  const onToggleSearch = (enabled) => {
    if (!enabled) {
      setDefaultQuery((prev) => {
        const newState = [...prev];
        newState[1].search = null;

        return newState;
      });
    }
  };

  const handleSearch = (searchTerm) => {
    setDefaultQuery((prev) => {
      const newState = [...prev];
      newState[1].search = searchTerm;

      return newState;
    });
  };

  const handleExport = (uuid, format) => {
    // check for valid uuid
    if (!uuid) return;
    if (Array.isArray(uuid) && uuid.length === 0) return;

    const font = document.querySelector(".editor-content")?.dataset?.font;

    MonolithNotesAPI.exportNotes({
      uuid: uuid,
      font: font,
      format,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      dateFormat: getDateFormat({ isMoment: true, includeTime: true }),
    }).then((res) => {
      const { signedUrl, filename } = res;
      const el = document.createElement("a");
      el.href = signedUrl.replace(
        "http://localhost:3000",
        "http://localhost:3001"
      );

      el.download = filename;
      el.click();
      // remove snackbar
    });
  };

  const onTabsChange = (tabs) => {
    if (!savedTabsFetched) return; // dont sync until tabs are fetched from indexeddb
    // Sync tabs to IndexedDB
    IndexedDBService.NoteTabs.putEvidenceNoteTabs({
      uuid: evidenceInfo.uuid,
      tabs,
    }).catch((err) => {
      console.error("Error saving tabs to IndexedDB", err);
    });
  };

  const handleOrder = (options) => {
    setDefaultQuery((prev) => {
      const newState = [...prev];
      newState[1].order = options;
      return newState;
    });
  };

  const userSelectItems = [
    {
      header: true,
      label: "User Notes",
    },
    {
      radioGroup: true,
      value: defaultQuery[1].created_by_id,
      items: [
        {
          label: "My Notes",
          value: currentUser.user_id,
          onClick: () => {
            setDefaultQuery((prev) => {
              const newState = [...prev];
              newState[1].created_by_id = currentUser.user_id;
              return newState;
            });
          },
        },
        ...(noteUsers
          ?.filter((user) => user.user_id !== currentUser.user_id)
          ?.map((user) => ({
            label: user.full_name,
            value: user.user_id,
            onClick: () => {
              setDefaultQuery((prev) => {
                const newState = [...prev];
                newState[1].created_by_id = user.user_id;
                return newState;
              });
            },
          })) || []),
      ],
    },
  ];

  // Add All users Selector
  if (noteView === NoteView.LIST) {
    // Insert item at top of list
    userSelectItems[1].items.unshift({
      label: "All Users",
      value: null,
      onClick: () => {
        setDefaultQuery((prev) => {
          const newState = [...prev];
          newState[1].created_by_id = null;
          return newState;
        });
      },
    });
  }

  const actionMenuItems = [
    {
      label: "Export to PDF",
      items: [
        {
          label: "Current Tabs",
          title:
            "Export notes that are currently opened in tabs to a PDF document.",
          onSelect: ({ tabs }) => {
            handleExport(
              tabs.map((tab) => tab.note.uuid),
              "pdf"
            );
          },
        },
        {
          label: `${
            defaultQuery[1].created_by_id === currentUser.user_id
              ? "All My Notes"
              : defaultQuery[1].created_by_id === null
              ? "All Notes"
              : `All ${selectedUser?.first_name}'s Notes`
          }`,
          title: "Export all notes for this case to a PDF document.",
          onSelect: () =>
            handleExport(
              data
                .filter((note) => note.is_folder === 0)
                .map((note) => note.uuid),
              "pdf"
            ),
        },
      ],
    },
    {
      label: "Export to MS Word",
      items: [
        {
          label: "Current Tabs",
          title:
            "Export notes that are currently opened in tabs to a Microsoft Word document.",
          onSelect: ({ tabs }) =>
            handleExport(
              tabs.map((tab) => tab.note.uuid),
              "docx"
            ),
        },
        {
          label: `${
            defaultQuery[1].created_by_id === currentUser.user_id
              ? "All My Notes"
              : defaultQuery[1].created_by_id === null
              ? "All Notes"
              : `All ${selectedUser?.first_name}'s Notes`
          }`,
          title: "Export all notes for this case to a Microsoft Word document.",
          onSelect: () =>
            handleExport(
              data
                .filter((note) => note.is_folder === 0)
                .map((note) => note.uuid),
              "docx"
            ),
        },
      ],
    },
  ];

  if (!savedTabsFetched) return null;

  const notes =
    noteView === NoteView.TREE ? data : data?.filter((n) => n.is_folder === 0); // Filter out folders when in list view

  return (
    <div className={className}>
      <NoteSystem
        notes={notes}
        defaultTabs={savedTabs?.tabs || []}
        noteRenderer={NoteRenderer}
        readOnly={readOnly}
        fullscreenTitle={evidenceInfo.evidence_number}
        onNoteOrderChange={handleOrder}
        defaultNoteOrder={defaultQuery[1].order}
        onNoteViewChange={handleNoteViewChange}
        defaultNoteView={noteView}
        onNoteCreated={onNoteCreated}
        onNoteDeleted={onNoteDeleted}
        onNoteUpdated={onNoteUpdated}
        onFolderCreated={onNoteCreated}
        onTabsChange={onTabsChange}
        onItemMove={handleNoteMove}
        onItemRename={handleItemRename}
        search={{
          placeholder: !readOnly
            ? "Search My Notes"
            : selectedUser?.first_name
            ? `Search ${selectedUser.first_name}'s Notes`
            : "Search All Notes",
          onToggleSearch,
          onSearch: handleSearch,
        }}
        noteMetadata={{
          linked_case: {
            case_id: evidenceInfo.case_id,
            uuid: evidenceInfo.case_uuid,
            case_number: evidenceInfo.case_number,
            case_ref: evidenceInfo.case_ref,
          },
          created_by: {
            full_name: currentUser.full_name,
            user_id: currentUser.user_id,
            title: currentUser.title,
            email: currentUser.email,
          },
        }}
        templates={templates}
        rootObject={{
          object_id: evidenceInfo.uuid,
          object_type: "evidence",
          object_name: evidenceInfo.evidence_number,
        }}
        headerTitle={{
          badge: "All Notes",
          label: evidenceInfo.evidence_number,
          color: "info",
        }}
      >
        <NoteSystem.NoteListMenu actionMenuItems={actionMenuItems}>
          <DropdownMenu
            menuItems={userSelectItems}
            title={"User Notes"}
            variant="outlined"
            arrow
          >
            {defaultQuery[1].created_by_id === null
              ? "All Users"
              : defaultQuery[1].created_by_id === currentUser.user_id
              ? "My Notes"
              : `${selectedUser.first_name}'s Notes`}
          </DropdownMenu>
        </NoteSystem.NoteListMenu>
      </NoteSystem>
    </div>
  );
})`
  display: flex;
  flex-direction: column;

  margin-top: 10px;
  flex-grow: 1;
`;

export default EvidenceNotes;
