import { Card } from "@mui/material";
import { DataGrid, Column } from "devextreme-react/ui/data-grid";
import BooleanCell from "./BooleanCell";

export const ETechs = ({ evidences }) => {
  return (
    <Card>
      <DataGrid
        dataSource={evidences}
        showRowLines={true}
        columnWidth={150}
        columnMinWidth={150}
        height={375}
        width="100%"
        columnResizingMode="widget"
        allowColumnReordering={false}
        allowColumnResizing={true}
        grouping={{ contextMenuEnabled: true }}
        paging={{ pageSize: 20 }}
        scrolling={{
          mode: "virtual",
          rowRenderingMode: "virtual",
          useNative: false,
        }}
        headerFilter={{
          visible: true,
          allowSearch: true,
        }}
        hoverStateEnabled={true}
      >
        <Column
          caption="Report Row"
          dataField="report_row"
          alignment={"center"}
        />
        <Column
          caption="Item Number"
          dataField="item_number"
          alignment={"center"}
        />
        <Column
          caption="Encrypted"
          dataField="encrypted"
          alignment={"center"}
          cellRender={(e) => {
            return BooleanCell(e.data.encrypted);
          }}
        />
        <Column
          caption="Decrypted"
          dataField="decrypted"
          alignment={"center"}
          cellRender={(e) => {
            return BooleanCell(e.data.decrypted);
          }}
        />
        <Column
          caption="Hashcat Used to Decrypt?"
          dataField="hashcat_used"
          alignment={"center"}
          cellRender={(e) => {
            return BooleanCell(e.data.hashcat_used);
          }}
        />
        <Column
          caption="Passware Used to Decrypt?"
          dataField="passware_used"
          alignment={"center"}
          cellRender={(e) => {
            return BooleanCell(e.data.passware_used);
          }}
        />
        <Column
          caption="Password Locked"
          dataField="password_locked"
          alignment={"center"}
          cellRender={(e) => {
            return BooleanCell(e.data.password_locked);
          }}
        />
        <Column
          caption="Password Unlocked"
          dataField="password_unlocked"
          alignment={"center"}
          cellRender={(e) => {
            return BooleanCell(e.data.password_unlocked);
          }}
        />
        <Column
          caption="Unlock Services"
          dataField="unlock_services"
          alignment={"center"}
        />
      </DataGrid>
    </Card>
  );
};
