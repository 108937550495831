import styled from "styled-components";

// Dependencies for HandleFileDownload
import { useSnackbar } from "notistack";
import CaseFileAPI from "../../api/CaseFiles/index.js";

// Icons
import TaskButton from "../../Monolith-UI/TaskButton.js";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ViewSelector from "./ViewSelector.js";
import RenameFileModal from "../../pages/Cases/CaseDocuments/RenameFileModal.js";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

const FileViewer = styled(
  ({ className, file, open, handleClose = () => {} }) => {
    const handleBackgroundClick = (e) => {
      if (e.target === e.currentTarget) {
        handleClose();
      }
    };
    if (!open) return null;

    const [zoom, setZoom] = useState(1);
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { case_id, folder_id } = useParams();

    const [showRenameFileModal, setShowRenameFileModal] = useState(false);
    const [displayFileName, setDisplayFileName] = useState(
      file.file_name ? file.file_name : file.image_path
    );

    const onRename = async () => {
      queryClient.refetchQueries({
        queryKey: [
          "case-files",
          { case_id: parseInt(case_id), parent_folder_id: folder_id || "root" },
        ],
      });
      enqueueSnackbar("File renamed successfully", { variant: "success" });
    };

    const handleFileDownload = () => {
      if (!file) return;

      const fileInfo = {
        uuid: file.uuid,
      };

      CaseFileAPI.downloadFile(file.file_name, {
        ...fileInfo,
      }).then((res) => {
        if (!res || res.success === false) {
          enqueueSnackbar(res.message || "Error downloading file", {
            variant: "error",
          });
          return;
        }
        const el = document.createElement("a");
        el.href = res.link.replace(
          "http://localhost:3000",
          "http://localhost:3001"
        );
        el.download = res.name;
        el.click();
      });
    };

    const handleZoomIn = () => {
      if (zoom >= 2.5) return;
      setZoom(zoom * 1.1);
    };

    const handleZoomOut = () => {
      setZoom(zoom / 1.1);
    };

    const handleResetZoom = (e) => {
      setZoom(1);
    };

    return (
      <div className={className} onClick={handleBackgroundClick}>
        <div className="display-area" onClick={handleBackgroundClick}>
          <div className="fileviewer-nav">
            <div
              className="filename"
              onClick={() => {
                setShowRenameFileModal(true);
              }}
            >
              {displayFileName}
            </div>
            <div className="fileviewer-right-menu">
              <TaskButton title="Download File">
                <GetAppOutlinedIcon
                  onClick={() => handleFileDownload()}
                  style={{ fontSize: 20, cursor: "pointer" }}
                />
              </TaskButton>
              <TaskButton title="Close">
                <CloseOutlinedIcon
                  onClick={() => handleClose()}
                  style={{ fontSize: 20, cursor: "pointer" }}
                />
              </TaskButton>
            </div>
          </div>
          <ViewSelector
            selectedItem={file}
            handleDownload={handleFileDownload}
            zoom={zoom}
          />
          <RenameFileModal
            open={showRenameFileModal}
            handleClose={() => setShowRenameFileModal(false)}
            onSubmit={() => onRename()}
            handleRename={(newName) => setDisplayFileName(newName)}
            defaultInfo={file}
          />
        </div>
      </div>
    );
  }
)`
  // Full Screen
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.75);

  & .display-area {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
  }

  & .fileviewer-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  & .fileviewer-right-menu {
    display: flex;
    flex-direction: row;
    gap: 10px;

    svg {
      color: #fff;
    }
  }

  & .filename {
    display: flex;
    font-size: 15px;
    margin: auto;
    font-weight: 500;
  }
`;

export default FileViewer;
