import { Box, Button, useTheme } from "@mui/material";
import { Popup } from "devextreme-react/ui/popup";
import { Form, SimpleItem } from "devextreme-react/ui/form";

import { useEffect, useRef, useState } from "react";
import {
  createEvidenceProgress,
  deleteEvidenceProgress,
  getEvidenceProgress,
  updateEvidenceProgress,
} from "../../api";
import Loader from "../../components/Loader";
import ToolBarItems from "../../components/ToolBarItems";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { usePermissions } from "../../hooks/usePermissions";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import styled from "styled-components";

interface EvidenceProgress {
  progress_id: number;
  progress_name: string;
  progress_order: number;
  total: number;
}

const calculateNewSortValue = (
  droppableArr: EvidenceProgress[],
  destination: { droppableId: string; index: number }
) => {
  if (!Array.isArray(droppableArr)) {
    throw new Error('Invalid data type "droppableArr" must be an array');
  }

  // If list is empty, do nothing
  if (droppableArr.length === 0)
    return droppableArr[destination.index].progress_order;

  // was the item inserted at the end of the list
  if (destination.index === droppableArr.length - 1) {
    return droppableArr[destination.index - 1].progress_order + 1;
  }

  // If item inserted at the beginning of the list
  if (destination.index === 0) {
    return droppableArr[destination.index + 1].progress_order - 1;
  }

  // if the item inserted between two items
  if (destination.index > 0 && destination.index < droppableArr.length - 1) {
    const first = droppableArr[destination.index - 1].progress_order;
    const second = droppableArr[destination.index + 1].progress_order;
    return (first + second) / 2;
  }
};

const EvidenceProgressItemsList = styled(
  ({
    className,
    evidenceProgressItems,
    setEvidenceProgressItems,
    setDeleteSelection,
    deleteEvidenceProgressPopup,
  }) => {
    useEffect(() => {
      getEvidenceProgress().then((result) => {
        setEvidenceProgressItems(result);
      });
    }, []);

    return (
      <div className={className}>
        {!evidenceProgressItems && <Loader />}
        <Droppable droppableId="droppable-1">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {evidenceProgressItems &&
                evidenceProgressItems.map(
                  (progress: EvidenceProgress, index: number) => {
                    return (
                      <EvidenceProgressItem
                        key={progress.progress_id}
                        index={index}
                        data={progress}
                        evidenceProgressItems={evidenceProgressItems}
                        setEvidenceProgressItems={setEvidenceProgressItems}
                        setDeleteSelection={setDeleteSelection}
                        deleteEvidenceProgressPopup={
                          deleteEvidenceProgressPopup
                        }
                      />
                    );
                  }
                )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
`;

const EvidenceProgressItem = ({
  data,
  evidenceProgressItems,
  setEvidenceProgressItems,
  setDeleteSelection,
  deleteEvidenceProgressPopup,
  index,
}: {
  data: EvidenceProgress;
  evidenceProgressItems: EvidenceProgress[];
  setEvidenceProgressItems: React.Dispatch<
    React.SetStateAction<EvidenceProgress[] | null>
  >;
  setDeleteSelection: React.Dispatch<
    React.SetStateAction<EvidenceProgress | null>
  >;
  deleteEvidenceProgressPopup: React.MutableRefObject<any>;
  index: number;
}) => {
  const theme = useTheme();
  const { hasPermission } = usePermissions();

  const handleTypeUpdate = (event: React.FocusEvent<HTMLDivElement>) => {
    if (event.currentTarget.innerText === "") {
      event.currentTarget.innerText = data.progress_name;
      return;
    }
    if (event.currentTarget.innerText !== data.progress_name) {
      updateEvidenceProgress(data.progress_id, {
        progress_name: event.currentTarget.innerText,
      });
      setEvidenceProgressItems(
        evidenceProgressItems.map((progress) => {
          if (progress.progress_id === data.progress_id) {
            progress.progress_name = event.currentTarget.innerText;
          }
          return progress;
        })
      );
    }
  };

  const handleDelete = () => {
    setDeleteSelection(data);
    deleteEvidenceProgressPopup.current.instance.show();
  };

  return (
    <Draggable draggableId={String(data.progress_order)} index={index}>
      {(provided) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "10px 10px",
            cursor: "pointer",
            backgroundColor: theme.palette.background.default,
            "&:hover": { backgroundColor: theme.palette.action.hover },
          }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <DragIndicatorIcon
            fontSize="small"
            sx={{ marginRight: "10px", cursor: "move" }}
          />
          <div>
            <Box
              contentEditable={true}
              suppressContentEditableWarning
              sx={{
                fontSize: "larger",
                padding: "2px",
                cursor: "text",
                "&:hover": { outline: `1px solid slategray` },
                "&:focus": { outline: `1px solid slategray` },
              }}
              onBlur={handleTypeUpdate}
            >
              {data.progress_name}
            </Box>
            <div style={{ color: "slategrey", padding: "2px" }}>
              {data.total} Evidence Items
            </div>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <Button
              variant="text"
              color="error"
              size="small"
              disabled={!hasPermission()}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>
        </Box>
      )}
    </Draggable>
  );
};

const CreateEvidenceProgressPopup = ({
  createEvidenceProgressPopup,
  evidenceProgressItems,
  setEvidenceProgressItems,
}: {
  createEvidenceProgressPopup: React.MutableRefObject<any>;
  evidenceProgressItems: EvidenceProgress[] | null;
  setEvidenceProgressItems: React.Dispatch<
    React.SetStateAction<EvidenceProgress[] | null>
  >;
}) => {
  const form: any = useRef(null);

  return (
    <Popup
      ref={createEvidenceProgressPopup}
      showTitle={true}
      showCloseButton={true}
      title="Create New Evidence Progress"
      defaultHeight={185}
      defaultWidth={400}
      onHidden={(e) => {
        form.current.instance.resetValues();
      }}
    >
      <Form ref={form}>
        <SimpleItem
          dataField="progress_name"
          label={{ text: "Evidence Progress" }}
          isRequired={true}
        ></SimpleItem>
      </Form>
      <ToolBarItems
        submitText="Create Evidence Progress"
        onSubmit={() => {
          if (form.current.instance.validate().isValid) {
            let values = Object.assign(
              {},
              form.current.instance.option("formData")
            );

            createEvidenceProgress(values).then((result) => {
              setEvidenceProgressItems([
                {
                  progress_id: result.progress_id,
                  progress_name: values.progress_name,
                  progress_order:
                    (evidenceProgressItems?.length
                      ? evidenceProgressItems[evidenceProgressItems.length - 1]
                          .progress_order
                      : 0) + 1,
                  total: 0,
                },
                ...(evidenceProgressItems || []),
              ]);
            });

            createEvidenceProgressPopup.current.instance.hide();
          }
        }}
        onCancel={() => {
          createEvidenceProgressPopup.current.instance.hide().then(() => {
            form.current.instance.resetValues();
          });
        }}
      />
    </Popup>
  );
};

const DeleteEvidenceProgressPopup = ({
  deleteEvidenceProgressPopup,
  evidenceProgressItems,
  setEvidenceProgressItems,
  deleteSelection,
}: {
  deleteEvidenceProgressPopup: React.MutableRefObject<any>;
  evidenceProgressItems: EvidenceProgress[];
  setEvidenceProgressItems: React.Dispatch<
    React.SetStateAction<EvidenceProgress[] | null>
  >;
  deleteSelection: EvidenceProgress | null;
}) => {
  const [items, setItems] = useState(evidenceProgressItems);
  const form: any = useRef(null);

  useEffect(() => {
    deleteSelection &&
      setItems(
        evidenceProgressItems.filter(
          (item: EvidenceProgress) =>
            item.progress_id !== deleteSelection.progress_id
        )
      );
  }, [deleteSelection]);

  return (
    <Popup
      ref={deleteEvidenceProgressPopup}
      showTitle={true}
      showCloseButton={true}
      title={`Delete Evidence Progress ${deleteSelection?.progress_name || ""}`}
      defaultHeight={250}
      defaultWidth={450}
      onHidden={(e) => {
        if (form.current) form.current.instance.resetValues();
      }}
    >
      {deleteSelection?.total === 0 && (
        <div style={{ margin: "15px 0px", minHeight: 115 }}>
          Are you sure you want to delete this evidence progress?
        </div>
      )}
      {deleteSelection?.total! > 0 && (
        <div style={{ minHeight: 115 }}>
          <div style={{ margin: "15px 0px" }}>
            Select the evidence progress that should replace this one when
            deleted:
          </div>
          <Form ref={form}>
            <SimpleItem
              dataField="progress_name"
              label={{ text: "Evidence Progress" }}
              isRequired={true}
              editorType="dxSelectBox"
              editorOptions={{
                items: items,
                displayExpr: "progress_name",
              }}
            ></SimpleItem>
          </Form>
        </div>
      )}
      <ToolBarItems
        submitText="Delete Evidence Progress"
        submitColor="error"
        onSubmit={() => {
          if (form.current) {
            if (form.current.instance.validate().isValid) {
              let values = Object.assign(
                {},
                form.current.instance.option("formData")
              );
              deleteEvidenceProgress(deleteSelection?.progress_id, {
                replace: values.progress_name.progress_name,
              });
              setEvidenceProgressItems(
                items.map((item) => {
                  if (item.progress_id === values.progress_name.progress_id) {
                    item.total += deleteSelection?.total!;
                  }
                  return item;
                })
              );
              deleteEvidenceProgressPopup.current.instance.hide();
            }
          } else {
            deleteEvidenceProgressPopup.current.instance.hide();
            deleteEvidenceProgress(deleteSelection?.progress_id);
            setEvidenceProgressItems(items);
          }
        }}
        onCancel={() => {
          deleteEvidenceProgressPopup.current.instance.hide().then(() => {
            if (form.current) form.current.instance.resetValues();
          });
        }}
      />
    </Popup>
  );
};

const EvidenceProgressSettings = styled(({ className }) => {
  const [evidenceProgressItems, setEvidenceProgressItems] = useState<
    EvidenceProgress[] | null
  >(null);
  const [deleteSelection, setDeleteSelection] =
    useState<EvidenceProgress | null>(null);
  const createEvidenceProgressPopup = useRef<any>(null);
  const deleteEvidenceProgressPopup = useRef<any>(null);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    // If there is no destination, do nothing
    if (!destination) {
      return;
    }

    // If user puts back in original place, do nothing
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newProgressItems = evidenceProgressItems
      ? Array.from(evidenceProgressItems)
      : [];

    const [removed] = newProgressItems.splice(source.index, 1);
    newProgressItems.splice(destination.index, 0, removed);

    const newSortValue = calculateNewSortValue(newProgressItems, destination);
    newProgressItems[destination.index].progress_order = newSortValue!;

    setEvidenceProgressItems(newProgressItems);
    updateEvidenceProgress(newProgressItems[destination.index].progress_id, {
      progress_order: newSortValue,
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={className} style={{ maxWidth: 800, padding: "0px 30px" }}>
        <div>
          Create, delete, edit, and reorder the progress events for evidence.
          Evidence progress items can be used to illustrate the current stage of
          a particular evidence item. These items should be edited and ordered
          to fit your organization's workflow for evidence.
        </div>
        <div style={{ margin: "25px 0px" }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={(e) => {
              createEvidenceProgressPopup.current.instance.show();
            }}
          >
            + Create Evidence Progress
          </Button>
        </div>
        <EvidenceProgressItemsList
          evidenceProgressItems={evidenceProgressItems}
          setEvidenceProgressItems={setEvidenceProgressItems}
          setDeleteSelection={setDeleteSelection}
          deleteEvidenceProgressPopup={deleteEvidenceProgressPopup}
        />
        <CreateEvidenceProgressPopup
          createEvidenceProgressPopup={createEvidenceProgressPopup}
          evidenceProgressItems={evidenceProgressItems}
          setEvidenceProgressItems={setEvidenceProgressItems}
        />
        {evidenceProgressItems && (
          <DeleteEvidenceProgressPopup
            deleteEvidenceProgressPopup={deleteEvidenceProgressPopup}
            evidenceProgressItems={evidenceProgressItems}
            setEvidenceProgressItems={setEvidenceProgressItems}
            deleteSelection={deleteSelection}
          />
        )}
      </div>
    </DragDropContext>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;
`;

export default EvidenceProgressSettings;
