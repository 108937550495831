const usePlatform = () => {
  const isOnPremises =
    window.localStorage.getItem("API_MODE") === "on-premises" ||
    process.env.REACT_APP_API_MODE === "on-premises";
  const isElectron = process.env.REACT_APP_PLATFORM === "electron";

  return {
    isOnPremises,
    isElectron,
  };
};

export default usePlatform;
