import { Modal, useTheme } from "@mui/material";
import CaseStorageAPI from "../../../api/storage/index.js";
import { useSnackbar } from "notistack";
import ToolBarItems from "../../../components/ToolBarItems.js";
import LoggingAPI from "../../../api/logging/index.js";
import { usePermissions } from "../../../hooks/usePermissions";

export const DeleteModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const theme = useTheme();
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    CaseStorageAPI.deleteStorage({
      storage_id: defaultInfo.storage_id,
    }).then(() => {
      enqueueSnackbar(`Storage Deleted.`, {
        variant: "success",
      });
      onSubmit(defaultInfo);
      if (defaultInfo?.case_id) {
        LoggingAPI.logActivity(
          defaultInfo.case_id,
          currentUser.user_id,
          `Deleted storage item ${defaultInfo.storage_number}`
        );
      }

      LoggingAPI.logActivity(
        null,
        currentUser.user_id,
        `Deleted storage item ${defaultInfo.storage_number}`
      );
    });
    handleClose();
  };

  const handleCancel = () => handleClose();

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 400,
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 200px)",
          maxHeight: "calc(100vh - 100px)",
          overflowY: "auto",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Delete Storage Item
        </div>
        <div style={{ margin: "10px 0px" }}>
          Are you sure you want to delete this storage item?
        </div>
        <div style={{ margin: "10px 0px" }}>"{defaultInfo.storage_number}"</div>
        <div
          style={{
            marginTop: 30,
          }}
        >
          <ToolBarItems
            submitText="Delete Storage"
            submitColor="error"
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </div>
      </div>
    </Modal>
  );
};
