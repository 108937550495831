import styled from "styled-components";
import { Outlet } from "react-router-dom";

const CaseReports = styled(({ className }) => {
  return (
    <div className={className}>
      <Outlet />
    </div>
  );
})`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export default CaseReports;
