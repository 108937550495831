import { httpDelete, httpGet, httpPatch, httpPost } from "../connection.js";

const get = async (values = {}) => {
  const { uuid, ...rest } = values;
  if (uuid) return httpGet(`/user-roles/${uuid}`, rest);
  return httpGet("/user-roles", rest);
};

const create = async (values = {}) => {
  return httpPost("/user-roles", values);
};

const update = async (values = {}) => {
  const { uuid, ...rest } = values;
  return httpPatch(`/user-roles/${uuid}`, rest);
};

const remove = async (values = {}) => {
  const { uuid, ...rest } = values;
  return httpDelete(`/user-roles/${uuid}`, rest);
};

const exportList = async (values = {}) => {
  return httpPost("/user-roles/export", values);
};

const getPermissions = async (values = {}) => {
  const { uuid, ...rest } = values;
  return httpGet(`/user-roles/${uuid}/permissions`, rest);
};

const createPermission = async (values = {}) => {
  const { uuid, ...rest } = values;
  return httpPost(`/user-roles/${uuid}/permissions`, rest);
};

const deletePermission = async (values = {}) => {
  const { uuid, permission_uuid, ...rest } = values;
  return httpDelete(`/user-roles/${uuid}/permissions/${permission_uuid}`, rest);
};

const UserRolesAPI = {
  get,
  create,
  update,
  remove,
  exportList,
  getPermissions,
  createPermission,
  deletePermission,
};

export default UserRolesAPI;
