import ForensicEquipmentAPI from "../../api/equipment/index.js";

const ForensicEquipmentFilterDimensions = [
  {
    name: "Vendor",
    field: "vendor",
    id: "vendor",
    type: "string",
    mode: "multi-select",
    key: ["equipment:vendors"],
    selectionDisplayName: "vendor",
    selectionIdField: "vendor",
    queryFn: (params) => ForensicEquipmentAPI.getEquipmentVendors(),
  },
  {
    name: "Name",
    field: "name",
    id: "name",
    type: "string",
    mode: "multi-select",
    key: ["equipment:names"],
    selectionDisplayName: "name",
    selectionIdField: "name",
    queryFn: (params) => ForensicEquipmentAPI.getEquipmentNames(),
  },
  {
    name: "Model",
    field: "model",
    id: "model",
    type: "string",
    mode: "multi-select",
    key: ["equipment:models"],
    selectionDisplayName: "model",
    selectionIdField: "model",
    queryFn: (params) => ForensicEquipmentAPI.getEquipmentModels(),
  },
  {
    name: "Purchase Date",
    field: "purchase_date",
    id: "purchase_date",
    type: "date",
    mode: "date",
  },
  {
    name: "Location",
    field: "location",
    id: "location",
    type: "string",
    mode: "multi-select",
    key: ["equipment:locations"],
    selectionDisplayName: "location",
    selectionIdField: "location",
    queryFn: (params) => ForensicEquipmentAPI.getEquipmentLocations(),
  },
];

export default ForensicEquipmentFilterDimensions;
