import { useState } from "react";
import moment from "moment";
import { Calendar as CalendarIcon } from "lucide-react";
import TaskDueDateOptions from "./TaskDueDateOptions.js";
import TaskButton from "../../../Monolith-UI/TaskButton.js";
import Calendar from "../../../Monolith-UI/Calendar/Calendar.js";
import DropdownMenu from "../../../Monolith-UI/DropdownMenu/DropdownMenu.js";

const TaskDuedateSelector = ({
  value,
  onSelect,
  showLabel = true,
  variant = "contained",
  buttonProps = {},
}) => {
  const calendarValue = value ? moment(value).toDate() : null;

  const menuItems = TaskDueDateOptions.filter((item) => item?.getDate).map(
    (dueDate) => ({
      label: dueDate.label,
      value: dueDate.value,
      onClick: () => {
        onSelect?.({
          label: dueDate.getLabel(),
          value: dueDate.getValue(),
        });
      },
    })
  );

  const DropdownContent = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 15,
          marginTop: 5,
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: 5,
          }}
        >
          {menuItems.map((item, index) => {
            return (
              <TaskButton
                key={index}
                variant="outlined"
                onClick={() => item.onClick()}
                style={{
                  fontSize: "0.65rem",
                  paddingLeft: 5,
                  paddingRight: 5,
                }}
              >
                {item.label}
              </TaskButton>
            );
          })}
        </div>
        <div style={{ margin: "auto" }}>
          <Calendar
            key={1}
            defaultValue={
              calendarValue
                ? moment(
                    Array.isArray(calendarValue)
                      ? calendarValue[0]
                      : calendarValue
                  ).toDate()
                : undefined
            }
            onChange={(date) => {
              onSelect?.(
                date
                  ? {
                      label: moment(date).format("MMM DD"),
                      value: moment(date).format("YYYY-MM-DD"),
                    }
                  : null
              );
            }}
            includeTime={false}
          />
        </div>
      </div>
    );
  };

  return (
    <DropdownMenu
      variant={variant}
      buttonProps={{
        textColor: !!value ? "primary" : "secondary",
        ...buttonProps,
      }}
      // arrow
      contentProps={{
        style: {
          maxWidth: 300,
          padding: 10,
          height: "fit-content",
          maxHeight: "min-content",
        },
      }}
      dropdownContent={<DropdownContent />}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <CalendarIcon
          color="#3e88e0"
          size={12}
          style={{ marginRight: showLabel ? 5 : 0 }}
        />
        {showLabel && (
          <span style={{ fontSize: "0.75rem" }}>
            {value ? moment(value).format("MMM DD") : "Due Date"}
          </span>
        )}
      </div>
    </DropdownMenu>
  );
};

export default TaskDuedateSelector;
