import { httpDelete, httpGet, httpPatch, httpPost } from "../connection";
import { getQAChecklists } from "./checklists";
import { getCheckListItems } from "./checklist_items";
import * as QaEntriesAPI from "./qa_entries.js";

export * from "./checklist_groups";
export * from "./checklist_items";
export * from "./checklists";

export async function updateQaAssign(
  values = { assignments: [], case_id: null }
) {
  return await httpPatch(`/cases/${values.case_id}/qa/assignments`, {
    assignments: values.assignments,
  });
}

export async function updateQaStatus(
  values = { qa_status: "", case_id: null }
) {
  return await httpPatch(`/cases/${values.case_id}/qa/status`, {
    qa_status: values.qa_status,
  });
}

export async function getQaAssignmentsByUser(userID) {
  return await httpGet(`/users/${userID}/qa_cases`);
}

export async function getQaAssignments(caseID) {
  return await httpGet(`/cases/${caseID}/qa/assignments`);
}

export async function getQaStatus(caseID) {
  return await httpGet(`/cases/${caseID}/qa/status`);
}

export async function getIssueTypes() {
  return await httpGet(`/qa/issue_types`);
}

export async function createIssueType(values) {
  return await httpPost(`/qa/issue_types`, values);
}

export async function updateIssueType(id, values) {
  return await httpPatch(`/qa/issue_types/${id}`, values);
}

export async function deleteIssueType(id, options = { replace: null }) {
  if (options.replace == null) return await httpDelete(`/qa/issue_types/${id}`);
  else if (options.replace != null)
    return await httpDelete(`/qa/issue_types/${id}?replace=${options.replace}`);
}

export async function getQAReviews(params) {
  return (await httpGet(`/qa/reviews`, params)).data;
}

async function query(params) {
  return await httpPost(`/qa/reviews/query`, params);
}

async function exportQaReviewsList(query) {
  return await httpPost(`/qa/reviews/export`, query);
}

export async function createQAReview(values) {
  return await httpPost(`/qa/reviews`, values);
}

export async function updateQAReview(values) {
  return await httpPatch(`/qa/reviews`, values);
}

export async function deleteQAReview(id) {
  return await httpDelete(`/qa/reviews`, { qa_id: id });
}

const QualityAssuranceAPI = {
  getQAReviews,
  query,
  exportQaReviewsList,
  getQAChecklists: getQAChecklists,
  updateQAReview,
  deleteQAReview,
  createQAReview,
  getCheckListItems,
  QaEntries: {
    query: QaEntriesAPI.query,
    getQaEntries: QaEntriesAPI.getQaEntries,
    getIssueTypes,
    createQaEntry: QaEntriesAPI.createQaEntry,
    deleteQaEntry: QaEntriesAPI.deleteQaEntry,
    updateQaEntry: QaEntriesAPI.updateQaEntry,
    exportQaEntriesList: QaEntriesAPI.exportQaEntriesList,
  },
};

export default QualityAssuranceAPI;
