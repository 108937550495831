import { useRef, useMemo } from "react";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import { Button } from "@mui/material";

import {
  getCaseTypesWithFpr,
  storeFprSettings,
} from "../../../../api/integrations/fpr";
import Loader from "../../../../components/Loader";
import { useSnackbar } from "notistack";
import { useQueryClient, useQuery } from "@tanstack/react-query";

export const CaseTypesMapping = ({ fprSettings }) => {
  const caseTypesForm = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const { isLoading, data: caseTypesMap } = useQuery({
    queryKey: ["fpr:caseTypes"],
    queryFn: getCaseTypesWithFpr,
    refetchOnWindowFocus: false,
    initialData: [],
  });

  if (isLoading) return <Loader />;

  const { fpr_case_types: fprCaseTypes } = fprSettings.options;

  const defaultFormValues = {};

  caseTypesMap.forEach((item) => {
    defaultFormValues[item.case_type_id] = {
      fpr_case_type: item.fpr_case_type,
      fpr_case_type_num: item.fpr_case_type_num,
    };
  });

  const handleSubmit = async () => {
    const formData = { ...caseTypesForm.current.instance.option("formData") };

    const newSettings = Object.keys(formData).map((formItem) => {
      const fprType = fprCaseTypes.find(
        (f) => f.fpr_case_type_num === formData[formItem]
      );

      return {
        case_type_id: parseInt(formItem),
        ...fprType,
      };
    });

    storeFprSettings({ fpr_case_types: newSettings }).then((result) => {
      queryClient.invalidateQueries({ queryKey: ["fpr:settings"] });
      enqueueSnackbar("Successfully updated FPR settings", {
        variant: "success",
      });
    });
  };

  return (
    <>
      <p>
        Map Monolith case types to the offense/case categories listed in the FPR
        report template.
      </p>
      <CaseTypeForm
        caseTypesForm={caseTypesForm}
        caseTypesMap={caseTypesMap}
        fprCaseTypes={fprCaseTypes}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          margin: "20px 0px",
        }}
      >
        <Button variant="contained" size="small" onClick={handleSubmit}>
          Save Settings
        </Button>
      </div>
    </>
  );
};

const CaseTypeForm = ({ caseTypesForm, caseTypesMap, fprCaseTypes }) => {
  return (
    <>
      {useMemo(() => {
        return (
          <Form ref={caseTypesForm} colCount={2}>
            {caseTypesMap &&
              caseTypesMap.map((caseType) => (
                <SimpleItem
                  key={caseType.case_type_id} // set a key to the case type id
                  dataField={caseType.case_type_id} // set the dataField to the case type id
                  label={{ text: caseType.case_type }}
                  colSpan={4}
                  editorType="dxSelectBox"
                  editorOptions={{
                    displayExpr: "fpr_case_type",
                    valueExpr: "fpr_case_type_num",
                    value: caseType.fpr_case_type_num || null,
                    items: fprCaseTypes,
                    showClearButton: true,
                    showDropDownButton: true,
                    dropDownOptions: { maxHeight: 250 },
                  }}
                />
              ))}
          </Form>
        );
      }, [caseTypesMap])}
    </>
  );
};
