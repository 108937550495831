import { useTheme } from "styled-components";
import CaseNumberCell from "../Evidence/CaseNumberCell.js";
import ProgressSelector from "./ProgresSelector.js";
import StatusSelector from "./StatusSelector.js";
import AssignedUsersComponent from "./AssignUsersComponent.js";
import { getDateFormat } from "../../utils/date-format";

const CaseColumnDefs = [
  {
    dataField: "case_number",
    caption: "Case Number",
    render: (rowData) => {
      const CustomCell = ({ rowData }) => {
        const theme = useTheme();

        return (
          <CaseNumberCell
            rowData={rowData}
            color={theme.palette.primary.main}
          />
        );
      };

      return <CustomCell rowData={rowData} />;
    },
  },
  {
    dataField: "case_ref",
    caption: "Case Name",
  },
  {
    dataField: "progress",
    caption: "Progress",
    render: (rowData, options = {}) => {
      if (options?.ignoreRender === true) return rowData.progress;

      return (
        <ProgressSelector
          rowData={rowData}
          queryKey={options.queryKey}
          onChange={(data) => {
            options?.onRowUpdated?.(data);
          }}
        />
      );
    },
  },
  {
    dataField: "case_status",
    caption: "Status",
    render: (rowData, options = {}) => {
      if (options?.ignoreRender === true) return rowData.case_status;
      return (
        <StatusSelector
          rowData={rowData}
          queryKey={options.queryKey}
          onChange={(data) => {
            options?.onRowUpdated?.(data);
          }}
        />
      );
    },
  },
  {
    dataField: "assigned_users",
    caption: "Assigned Users",
    sorting: {
      enabled: false,
    },
    visible: true,
    render: (rowData) => {
      return <AssignedUsersComponent rowData={rowData} />;
    },
  },
  {
    dataField: "case_open_date",
    caption: "Case Open Date",
    dataType: "date",
    format: {
      type: getDateFormat({ isMoment: true, includeTime: false }),
    },
  },
  {
    dataField: "case_closed_date",
    caption: "Case Close Date",
    dataType: "date",
    format: {
      type: getDateFormat({ isMoment: true, includeTime: false }),
    },
  },
  {
    dataField: "last_activity_date",
    caption: "Last Activity Date",
    dataType: "date",
    format: {
      type: getDateFormat({ isMoment: true, includeTime: false }),
    },
  },
  {
    dataField: "case_lead",
    caption: "Case Lead",
    render: (rowData) => rowData?.case_lead?.full_name || null,
  },
  {
    dataField: "case_type",
    caption: "Type",
  },
  {
    dataField: "case_synopsis",
    caption: "Description",
    titleRender: (rowData) => rowData.case_synopsis,
    visible: false,
  },
  {
    dataField: "office_name",
    caption: "Office",
    visible: false,
  },
  {
    dataField: "client_name",
    caption: "Client Name",
    visible: false,
  },
  {
    dataField: "client_organization",
    caption: "Organization",
    visible: false,
  },
  {
    dataField: "evidence_count",
    caption: "Evidence Count",
    visible: false,
  },
  {
    dataField: "evidence_total_gb",
    caption: "Evidence Total (GB)",
    visible: false,
  },
  {
    dataField: "acquisition_count",
    caption: "Acquisition Count",
    visible: false,
  },
  {
    dataField: "acq_total_gb",
    caption: "Acquisition Total (GB)",
    visible: false,
  },
  {
    dataField: "active_task_count",
    caption: "Active Tasks",
    visible: false,
  },
];

export default CaseColumnDefs;
