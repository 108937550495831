import { Clock, Plus } from "lucide-react";

const TaskDurationOptions = [
  {
    label: "6 min",
    duration: 360,
    icon: Plus,
  },
  {
    label: "15 min",
    duration: 900,
    icon: Plus,
  },
  {
    label: "30 min",
    duration: 1800,
    icon: Plus,
  },
  {
    label: "45 min",
    duration: 2700,
    icon: Plus,
  },
  {
    label: "1 hr",
    duration: 3600,
    icon: Plus,
  },
  {
    label: "Custom",
    duration: 0,
    icon: Clock,
  },
];

export default TaskDurationOptions;
