import { httpDelete, httpGet, httpPatch, httpPost } from "../connection";
import CaseNotes from "./notes";

export * from "./notes";

export async function getCases(options = {}) {
  return (await httpGet("/cases", options))?.data || [];
}

async function query(query) {
  return await httpPost("/cases/query", query);
}

async function exportCaseList(query) {
  return await httpPost("/cases/export", query);
}

export async function getCaseNumbers(params = {}) {
  return await httpGet(`/cases/case-numbers`, params);
}

export async function createCase(values) {
  return await httpPost("/cases", values);
}

export async function caseNumberExists(params = {}) {
  const result = await httpGet(`/cases/case-numbers`, params);

  if (!result) return false;
  return result?.length > 0;
}

export async function updateCase(id, values) {
  return await httpPatch(`/cases/${id}`, values);
}

export async function deleteCase(id) {
  return await httpDelete(`/cases/${id}`);
}

export async function getCaseTypes() {
  return await httpGet(`/cases/types`);
}

export async function createCaseType(values) {
  return await httpPost(`/cases/types`, values);
}

export async function updateCaseType(case_type_id, values = {}) {
  return await httpPatch(`/cases/types/${case_type_id}`, values);
}

export async function deleteCaseType(id, options = { replace: null }) {
  if (options.replace == null) return await httpDelete(`/cases/types/${id}`);
  else if (options.replace != null)
    return await httpDelete(`/cases/types/${id}?replace=${options.replace}`);
}

export async function getCaseStatuses() {
  return await httpGet(`/cases/statuses`);
}

export async function createCaseStatus(values) {
  return await httpPost(`/cases/statuses`, values);
}

export async function updateCaseStatus(case_status_id, values = {}) {
  return await httpPatch(`/cases/statuses/${case_status_id}`, values);
}

export async function deleteCaseStatus(id, options = { replace: null }) {
  if (options.replace == null) return await httpDelete(`/cases/statuses/${id}`);
  else if (options.replace != null)
    return await httpDelete(`/cases/statuses/${id}?replace=${options.replace}`);
}

export async function getCaseAssignments(
  values = { case_id: null },
  params = {}
) {
  return await httpGet(`/cases/${values.case_id}/assignments`, values);
}

export async function assignUsersToCase(
  values = { userIDs: null, case_id: null }
) {
  return await httpPost(`/cases/${values.case_id}/assignments/add`, {
    userIDs: values.userIDs,
  });
}

export async function removeUsersFromCase(
  values = { userIDs: null, case_id: null }
) {
  return await httpPost(`/cases/${values.case_id}/assignments/remove`, {
    userIDs: values.userIDs,
  });
}

export async function getCaseActivity(values = { case_id: null, limit: null }) {
  if (typeof values.limit == "undefined")
    return await httpGet(`/cases/${values.case_id}/activity`);
  else
    return await httpGet(
      `/cases/${values.case_id}/activity?limit=${values.limit}`
    );
}

export async function getCaseProgress() {
  return await httpGet(`/cases/progress`);
}

export async function createCaseProgress(values) {
  return await httpPost(`/cases/progress`, values);
}

export async function updateCaseProgress(progress_id, values = {}) {
  return await httpPatch(`/cases/progress/${progress_id}`, values);
}

export async function reorderCaseProgress(items) {
  return await httpPatch(`/cases/progress/reorder`, items);
}

export async function deleteCaseProgress(id, options = { replace: null }) {
  if (options.replace == null) return await httpDelete(`/cases/progress/${id}`);
  else if (options.replace != null)
    return await httpDelete(`/cases/progress/${id}?replace=${options.replace}`);
}

export async function getCaseNumberFormat() {
  return await httpGet(`/cases/format`);
}

export async function updateCaseNumberFormat(values) {
  return await httpPatch(`/cases/format`, values);
}

async function assignUserGroup(values = {}) {
  return await httpPost(`/cases/assign-user-group`, values);
}

async function unassignUserGroup(values = {}) {
  return await httpPost(`/cases/unassign-user-group`, values);
}

// CUSTOM FIELDS
async function getCaseCustomAttributes(options = { fieldsOnly: false }) {
  return httpGet(`/cases/attributes?fieldsOnly=${options.fieldsOnly}`);
}

async function createCaseCustomField(
  values = { field_name: null, is_required: null, type_id: null }
) {
  return await httpPost(`/cases/attributes/field`, values);
}

export async function updateCaseCustomField(id, values = {}) {
  return await httpPost(`/cases/attributes/field/${id}`, values);
}

export async function deleteCaseCustomField(id) {
  return httpDelete(`/cases/attributes/field/${id}`);
}

async function metrics(values = {}) {
  return await httpGet(`/cases/metrics`, values);
}

const CasesApi = {
  query,
  exportCaseList,
  getCases,
  getCaseTypes,
  getCaseStatuses,
  getCaseProgress,
  getCaseNumbers,
  updateCase,
  caseNumberExists,
  assignUsersToCase,
  removeUsersFromCase,
  getCaseActivity,
  getCaseAssignments,
  createCase,
  deleteCase,
  createCaseType,
  updateCaseType,
  deleteCaseType,
  createCaseStatus,
  updateCaseStatus,
  deleteCaseStatus,
  createCaseProgress,
  updateCaseProgress,
  deleteCaseProgress,
  getCaseNumberFormat,
  updateCaseNumberFormat,
  assignUserGroup,
  unassignUserGroup,
  CaseNotes: CaseNotes,
  getCaseCustomAttributes,
  createCaseCustomField,
  updateCaseCustomField,
  deleteCaseCustomField,
  metrics,
};

export default CasesApi;
