import styled from "styled-components";
import { Outlet } from "react-router-dom";

export const IntegrationSettings = styled(({ className, children }) => {
  return (
    <div className={className} style={{ maxWidth: 800, padding: "0px 30px" }}>
      {children}
      <Outlet />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex-basis: 0%;
  flex-grow: 1;

  overflow-y: auto;
`;

export default IntegrationSettings;
