import { Link } from "react-router-dom";
import styled from "styled-components";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";

const EvidenceNumberCell = styled(({ className, rowData, color }) => {
  if (!rowData?.uuid) {
    return null;
  }
  return (
    <Link className={className} to={`/evidence/${rowData.uuid}/overview`}>
      <div
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div>{rowData.evidence_number}</div>
        <LaunchOutlinedIcon
          className="action-button"
          style={{ fontSize: 13, marginLeft: 5 }}
        />
      </div>
    </Link>
  );
})(({ theme, rowData, color }) => ({
  color: color
    ? color
    : rowData.is_parent === 1
    ? theme.palette.primary.main
    : theme.palette.warning.main,
  // hover
  "&:hover": {
    textDecoration: "underline",
  },
}));

export default EvidenceNumberCell;
