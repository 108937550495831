import { Minus, ChevronRight, ChevronsRight, Flame } from "lucide-react";

const TaskPriorities = [
  {
    priority_id: 4,
    priority_name: "Urgent",
    color: "#f44336",
    icon: Flame,
  },
  {
    priority_id: 3,
    priority_name: "High",
    color: "#ff9800",
    icon: ChevronsRight,
  },
  {
    priority_id: 1,
    priority_name: "Low",
    color: "#4caf50",
    icon: ChevronRight,
  },
  {
    priority_id: 2,
    priority_name: "None",
    color: "#9e9e9e",
    icon: Minus,
  },
];

export default TaskPriorities;
