import LocationsAPI from "../../api/locations/index.js";
import CaseStorageAPI from "../../api/storage/index.js";

const CaseStorageDimensions = [
  {
    name: "Storage Number",
    field: "storage_number",
    id: "storage_number",
    type: "text",
    mode: "text",
  },
  {
    name: "Created On",
    field: "created_on",
    id: "created_on",
    type: "date",
    mode: "date",
  },
  {
    name: "Last Audit Date",
    field: "last_audit_date",
    id: "last_audit_date",
    type: "date",
    mode: "date",
  },
  {
    name: "Date Assigned",
    field: "date_assigned",
    id: "date_assigned",
    type: "date",
    mode: "date",
  },
  {
    name: "Location Path",
    field: "location_path",
    id: "location_path",
    type: "text",
    mode: "text",
  },
  {
    name: "UUID",
    field: "uuid",
    id: "uuid",
    type: "text",
    mode: "text",
  },
  {
    name: "General Storage",
    field: "general_storage",
    id: "general_storage",
    type: "string",
    mode: "multi-select",
    key: ["storage:general_storage:options"],
    selectionDisplayName: "text",
    selectionIdField: "value",
    queryFn: (params) =>
      Promise.resolve([
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ]),
  },
  {
    name: "Type",
    field: "type",
    id: "type",
    type: "string",
    mode: "multi-select",
    key: ["storage:types:list"],
    selectionDisplayName: "type",
    selectionIdField: "type",
    queryFn: (params) => CaseStorageAPI.getTypes(),
  },
  {
    name: "Provider",
    field: "make",
    id: "make",
    type: "string",
    mode: "multi-select",
    key: ["storage:providers:list"],
    selectionDisplayName: "make",
    selectionIdField: "make",
    queryFn: (params) => CaseStorageAPI.getProviders(),
  },
  {
    name: "Model Name",
    field: "model_name",
    id: "model_name",
    type: "text",
    mode: "text",
  },
  {
    name: "Model Number",
    field: "model_number",
    id: "model_number",
    type: "text",
    mode: "text",
  },
  {
    name: "Serial Number",
    field: "serial_number",
    id: "serial_number",
    type: "text",
    mode: "text",
  },
  {
    name: "Description",
    field: "notes",
    id: "notes",
    type: "text",
    mode: "text",
  },
  {
    name: "Encryption Key",
    field: "encryption_key",
    id: "encryption_key",
    type: "text",
    mode: "text",
  },
  {
    name: "Current Location",
    field: "location_id",
    id: "location_id",
    type: "string",
    mode: "multi-select",
    key: ["evidence:locations", { include_groups: false }],
    selectionDisplayName: "location_name",
    selectionIdField: "location_id",
    queryFn: (params) =>
      LocationsAPI.getLocations({}, { include_groups: false }),
    addtionalSelections: [{ location_id: 1, location_name: "Released" }],
  },
];

export default CaseStorageDimensions;
