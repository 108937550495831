import { httpGet } from "../../connection.js";

async function get(params = {}) {
  return await httpGet(`/analysis/logs`, params);
}

const Logs = {
  get,
};

export default Logs;
