import ForensicSoftwareAPI from "@/api/software";
import { SelectBox } from "@monolith-forensics/monolith-ui";
import { useQuery } from "@tanstack/react-query";
import { FC } from "react";

interface SoftwareSelectorProps {
  defaultValue: string;
  onChange?: (value: string) => void;
  error?: string;
  openOnFocus?: boolean;
}

type Software = {
  software_name: string;
};

const SoftwareSelector: FC<SoftwareSelectorProps> = ({
  defaultValue,
  onChange,
  error,
  openOnFocus = false,
}) => {
  const { data: softwareList } = useQuery<Software[]>({
    queryKey: ["software", "names"],
    queryFn: () => ForensicSoftwareAPI.getSoftwareNames(),
  });

  const options =
    softwareList?.map((software) => ({
      label: software?.software_name,
      value: software?.software_name,
    })) || [];

  return (
    <>
      <SelectBox
        defaultValue={defaultValue}
        size="sm"
        variant="outlined"
        label="Software"
        placeholder="Select or enter software name"
        description="This is the software that was used to perform the acquisition."
        onChange={onChange}
        data={options}
        error={error}
        clearable={true}
        searchable={true}
        allowCustomValue={true}
        openOnFocus={openOnFocus}
        required={true}
      />
    </>
  );
};
export default SoftwareSelector;
