import { Divider, Switch, Typography, useTheme } from "@mui/material";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import CustomFieldsAPI from "../../../api/CustomFields/index.js";
import { getCustomEvidenceAttributes } from "../../../api/index.js";
import { getCustomInquiryAttributes } from "../../../api/inquiries/index.js";
import ItemBox from "../../../components/ItemBox/ItemBox.js";
import Loader from "../../../components/Loader.js";

const FieldTypes = {
  textBox: "Text Box",
  textArea: "Text Area",
  dropDown: "Menu Select",
  tagBox: "Tag Box",
  dateBox: "Date Box",
};

const InquiryCustomFields = styled(({ className, customFields }) => {
  const theme = useTheme();
  return (
    <div className={className}>
      <Typography variant="h3" display="inline">
        Custom Inquiry Fields
      </Typography>
      <div className="message">
        Set the enabled option for your custom inquiry fields. When enabled, the
        field will be available to the user in Relay when creating a new
        request.
      </div>
      <div
        style={{
          marginTop: 20,
          paddingRight: 20,
          paddingLeft: 5,
        }}
      >
        <CustomFields customFields={customFields} type="inquiry" />
      </div>
    </div>
  );
})`
  margin-bottom: 20px;
  .message {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-weight: 400;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
`;

const EvidenceCustomFields = styled(({ className, customFields }) => {
  return (
    <div className={className}>
      <Typography variant="h3" display="inline">
        Custom Evidence Fields
      </Typography>
      <div className="message">
        Set the enabled option for your custom evidence fields. When enabled,
        the field will be available to the user in Relay when adding evidence to
        a request.
      </div>
      <div
        style={{
          marginTop: 20,
          paddingRight: 20,
          paddingLeft: 5,
        }}
      >
        <CustomFields customFields={customFields} type="evidence" />
      </div>
    </div>
  );
})`
  margin-bottom: 20px;
  .message {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-weight: 400;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
`;

const CustomFields = ({ customFields, type }) => {
  const theme = useTheme();
  const queryClient = useQueryClient();

  const handleUpdate = (values) => {
    CustomFieldsAPI.updateCustomField(values);

    queryClient.setQueryData(
      [
        type === "evidence" ? "customEvidenceFields" : "customInquiryFields",
        { fieldsOnly: true },
      ],
      (oldData) => {
        return oldData.map((field) => {
          if (field.field_id === values.field_id) {
            return {
              ...field,
              external_enabled: values.external_enabled,
            };
          }
          return field;
        });
      }
    );
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
        gridGap: 20,
      }}
    >
      {customFields.map((field) => {
        let description = field.description
          ? field.description
          : "No description provided.";

        if (description.length > 25) {
          description = description.substring(0, 25) + "...";
        }

        return (
          <ItemBox
            key={field.field_id}
            title={field.field_name}
            subTitle={`${description}`}
            subTitleHint={field.description}
            endIcon={
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: 10,
                    color: theme.palette.text.secondary,
                    marginRight: 5,
                  }}
                >
                  Enabled
                </div>
                <Switch
                  size="small"
                  defaultChecked={field.external_enabled}
                  onChange={(e) =>
                    handleUpdate({
                      external_enabled: e.target.checked,
                      field_id: field.field_id,
                    })
                  }
                />
              </div>
            }
          />
        );
      })}
    </div>
  );
};

const CustomFieldOptions = styled(({ className }) => {
  const [
    { isLoading: isInquiryDataLoading, data: customInquiryFields },
    { isLoading: isEvidenceDataLoading, data: customEvidenceFields },
  ] = useQueries({
    queries: [
      {
        queryKey: ["customInquiryFields", { fieldsOnly: true }],
        queryFn: () => getCustomInquiryAttributes({ fieldsOnly: true }),
      },
      {
        queryKey: ["customEvidenceFields", { fieldsOnly: true }],
        queryFn: () => getCustomEvidenceAttributes({ fieldsOnly: true }),
      },
    ],
  });

  if (isInquiryDataLoading || isEvidenceDataLoading) return <Loader />;

  return (
    <div className={className}>
      <InquiryCustomFields customFields={customInquiryFields} />
      <Divider style={{ margin: "20px 0px" }} />
      <EvidenceCustomFields customFields={customEvidenceFields} />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
`;

export default CustomFieldOptions;
