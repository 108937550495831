import { Button, Modal, useTheme } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import InquiriesAPI from "../../../api/inquiries/index.js";
import { usePermissions } from "../../../hooks/usePermissions";
import { nanoid } from "nanoid";
import { useSnackbar } from "notistack";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import ToolBarItems from "../../../components/ToolBarItems.js";
import { useState } from "react";

const ActionMenu = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-top: auto;
`;
const ActionMenuItem = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  border-radius: 5px;
  font-size: 10px;
  color: ${(props) =>
    props.color === "primary"
      ? props.theme.palette.primary.main
      : props.color === "error"
      ? props.theme.palette.error.main
      : props.theme.palette.text.primary};
  &:hover {
    text-decoration: underline;
  }
  & svg {
    font-size: 12px;
    margin-right: 5px;
  }
`;
const ActionMenuLabel = styled.div``;

const Documents = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
  margin: auto;
  margin-top: 10px;
`;

const DocumentContent = styled(({ className, readOnly = true }) => {
  const { inquiry_id } = useParams();
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: ["inquiries:docs", { inquiry_id: parseInt(inquiry_id) }],
    queryFn: () =>
      InquiriesAPI.getInquiryDocs({
        inquiry_id,
      }),
  });

  const handleFileUpload = (event) => {
    queryClient.setQueryData(
      ["inquiries:docs", { inquiry_id: parseInt(inquiry_id) }],
      (oldData) => {
        return [
          ...oldData,
          ...Array.from(event.target.files).map((file) => ({
            inquiry_doc_name: file.name,
            size: file.size,
            ext: file.name.split(".").pop() || "",
            uploading: true,
            doc_id: nanoid(),
          })),
        ];
      }
    );

    InquiriesAPI.uploadInquiryDocs({
      inquiry_id: inquiry_id,
      files: event.target.files,
    }).then(() => {
      queryClient.refetchQueries({
        queryKey: ["inquiries:docs", { inquiry_id: parseInt(inquiry_id) }],
      });
      enqueueSnackbar(`Inquiry Documents Uploaded.`, {
        variant: "success",
      });
    });
  };

  return (
    <div className={className}>
      {!readOnly && hasPermission(MONOLITH_PERMISSIONS.INQUIRIES_UPDATE) && (
        <input
          accept="*"
          style={{ display: "none" }}
          id="addEviPhotos-button"
          multiple
          type="file"
          onChange={handleFileUpload}
        />
      )}
      <>
        <label htmlFor="addEviPhotos-button">
          <Button
            variant="contained"
            color="primary"
            size="small"
            component="span"
            disabled={
              !hasPermission(MONOLITH_PERMISSIONS.INQUIRIES_UPDATE) || readOnly
            }
            style={{
              minWidth: "fit-content",
              fontSize: 11,
              padding: "3px 6px",
            }}
          >
            + Upload Files
          </Button>
        </label>
      </>
      <div className="docs-container">
        {data && data.length === 0 ? (
          <div style={{ marginBottom: 20 }}>No documents.</div>
        ) : (
          <>
            {data &&
              data.map((item) => {
                return (
                  <DocumentItem
                    key={item.doc_id}
                    item={item}
                    readOnly={readOnly}
                  />
                );
              })}
          </>
        )}
      </div>
    </div>
  );
})`
  & .docs-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 10px;
    margin-top: 10px;
  }
`;

const DocumentItem = styled(({ className, item, readOnly = true }) => {
  const { inquiry_id } = useParams();
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onDelete = () => {
    queryClient.refetchQueries({
      queryKey: ["inquiries:docs", { inquiry_id: parseInt(inquiry_id) }],
    });
  };

  return (
    <div className={className}>
      <div className="doc-name" title={item.inquiry_doc_name}>
        {item.inquiry_doc_name}
      </div>
      <div
        style={{
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <div className="doc-type">
          {item.ext.replace(".", "").toUpperCase()}
        </div>
        <div style={{ margin: "0px 5px" }}>-</div>
        <div className="doc-size">{convertBytesToSize(item.size)}</div>
      </div>
      {item.uploading === true ? (
        <>Uploading...</>
      ) : (
        <ActionMenu>
          <a href={item.link} download={item.filename}>
            <ActionMenuItem>
              <FileDownloadOutlinedIcon
                style={{ color: theme.palette.primary.main }}
              />
              <ActionMenuLabel>Download</ActionMenuLabel>
            </ActionMenuItem>
          </a>
          {!readOnly && hasPermission(MONOLITH_PERMISSIONS.INQUIRIES_DELETE) && (
            <ActionMenuItem onClick={() => setShowDeleteModal(true)}>
              <DeleteOutlineOutlinedIcon
                style={{ color: theme.palette.error.main }}
              />
              <ActionMenuLabel>Delete</ActionMenuLabel>
            </ActionMenuItem>
          )}
        </ActionMenu>
      )}
      <DeleteModal
        open={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        onSubmit={() => onDelete()}
        defaultInfo={item}
      />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 5px;
  padding: 15px;
  min-width: 275px;
  height: 135px;

  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }

  & .doc-name {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
    overflow: hidden;
    max-width: 100%;

    // Add 2 line clamp
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    word-break: break-all;

    text-align: center;
  }

  & .doc-type {
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  & .doc-size {
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  & .doc-link {
    font-size: 14px;
    margin: auto;
    margin-top: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: fit-content;
    color: ${({ theme }) => theme.palette.primary.main};
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const DeleteModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const { inquiry_id } = useParams();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    InquiriesAPI.deleteInquiryDoc({
      inquiry_id,
      doc_id: defaultInfo.doc_id,
      file_name: defaultInfo.inquiry_doc_name,
    }).then(() => {
      enqueueSnackbar(`Document Deleted.`, {
        variant: "success",
      });
      onSubmit();
    });
    handleClose();
  };

  const handleCancel = () => handleClose();

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 400,
          backgroundColor: theme.palette.background.default,
          position: "absolute",
          left: "50%",
          top: "20%",
          transform: "translate(-35%, -20%)",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Delete Inquiry Document
        </div>
        <div style={{ margin: "10px 0px" }}>
          Are you sure you want to delete this document?
        </div>
        <div style={{ margin: "10px 0px" }}>
          "{defaultInfo.inquiry_doc_name}"
        </div>
        <div
          style={{
            marginTop: 30,
          }}
        >
          <ToolBarItems
            submitText="Delete Document"
            submitColor="error"
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </div>
      </div>
    </Modal>
  );
};

const convertBytesToSize = (bytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export default DocumentContent;
