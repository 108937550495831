import { httpGet, httpPost } from "../connection.js";

interface Params {
  [key: string]: any;
}

interface AuditParams extends Params {
  uuid?: string;
  page?: number;
  pageSize?: number;
  query?: any;
}

interface AuditQuery {
  object_uuid: string;
  audit_uuid: string;
  search: string;
  order: {
    sort: string;
    column: string;
    field: string;
  };
  pageSize: number;
  page: number;
  data_only: string | boolean;
  uuid?: string;
}

interface ExportParams extends Params {
  query: Partial<AuditQuery>;
  type: string;
  columns: Array<{
    dataField: string;
    header: string;
    [key: string]: any;
  }>;
  date_format: string;
  timezone: string;
}

interface ExportResponse {
  signedUrl: string;
  filename: string;
}

const get = async (params: Params = {}): Promise<any> => {
  return await httpGet("/audits", params);
};

const getAuditReport = async (params: AuditParams): Promise<any> => {
  return await httpGet(`/audits/${params.uuid}/audit-report`, params);
};

const getAuditItems = async (params: AuditParams): Promise<any> => {
  return await httpGet(`/audits/${params.uuid}/audit-items`, params);
};

const updateAuditItem = async (params: AuditParams): Promise<any> => {
  return await httpPost(`/audits/${params.uuid}/audit-items`, params);
};

const getAuditLogs = async (params: Params = {}): Promise<any> => {
  return await httpGet(`/audits/audit-logs`, params);
};

const createAuditLog = async (params: Params = {}): Promise<any> => {
  return await httpPost(`/audits/audit-logs`, params);
};

const completeAudit = async (params: AuditParams): Promise<any> => {
  return await httpPost(`/audits/${params.uuid}/complete-audit`, params);
};

const create = async (params: Params = {}): Promise<any> => {
  return await httpPost("/audits", params);
};

const exportAuditItemsList = async (params: Params = {}): Promise<any> => {
  return await httpPost("/audits/export-audit-items", params);
};

const exportAuditLogsList = async (params: Params = {}): Promise<any> => {
  return await httpPost("/audits/export-audit-logs", params);
};

const exportList = async (params: ExportParams): Promise<ExportResponse> => {
  return await httpPost("/audits/export-audits-list", params);
};

const AuditsAPI = {
  get,
  getAuditItems,
  getAuditReport,
  updateAuditItem,
  getAuditLogs,
  createAuditLog,
  create,
  completeAudit,
  exportAuditItemsList,
  exportAuditLogsList,
  exportList,
};

export default AuditsAPI;
