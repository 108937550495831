import styled from "styled-components";
import DefaultEvidenceForm from "./DefaultEvidenceForm.js";
import EvidenceAPI from "../../api/evidence/index.js";

const FormTitle = styled.h2`
  margin: 0;
`;

const CreateEvidenceForm = styled(
  ({
    className,
    onSubmit,
    onCancel,
    includeCoc,
    includeCaseField,
    includeBasicDetails,
  }) => {
    const handleSubmit = async (data) => {
      await EvidenceAPI.createEvidence(data);
      onSubmit?.(data);
    };
    return (
      <div className={className}>
        <FormTitle>Create Forensic Evidence</FormTitle>
        <DefaultEvidenceForm
          includeCoc={includeCoc}
          includeCaseField={includeCaseField}
          includeBasicDetails={includeBasicDetails}
          onSubmit={handleSubmit}
          onCancel={onCancel}
        />
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  gap: 15px;

  width: ${({ width }) =>
    Number.isInteger(width) ? `${width}px` : width || "100%"};
`;

export default CreateEvidenceForm;
