import { httpDelete, httpGet, httpPost } from "../connection.js";

const query = async (query) => {
  return await httpPost("/cases/archives/query", query);
};

//Case Archive API
async function getCaseArchives(params = {}) {
  return await httpGet(`/cases/archives`, params);
}

async function createCaseArchive(params = {}) {
  return await httpPost(`/cases/archives`, params);
}

async function deleteCaseArchive(params = {}) {
  return await httpDelete(`/cases/archives/${params.archive_id}`);
}

async function exportCaseArchiveList(query) {
  return await httpPost("/cases/archives/export", query);
}

const CaseArchivesAPI = {
  query,
  getCaseArchives,
  createCaseArchive,
  deleteCaseArchive,
  exportCaseArchiveList,
};

export default CaseArchivesAPI;
