import { useQuery } from "@tanstack/react-query";
import TimeEntriesAPI from "../../../api/TimeEntries/time-entries.js";
import DropdownMenu from "../../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import getRandomColor from "../../../utils/getRandomColor.js";
import { ShapesIcon } from "lucide-react";

const TaskCategorySelector = ({
  value,
  onSelect,
  showLabel = true,
  variant = "contained",
  error = false,
}) => {
  const { data: TaskCategories } = useQuery({
    queryKey: ["task:categories"],
    queryFn: () => TimeEntriesAPI.getTimeCategories(),
  });

  const Icon = ({ color, style = {} }) => {
    return (
      <div
        style={{
          borderRadius: "50%",
          height: 10,
          width: 10,
          minWidth: 10,
          backgroundColor: color,
          marginRight: 5,
          ...style,
        }}
      ></div>
    );
  };

  const menuItems = [
    {
      radioGroup: true,
      value: value?.time_category_id,
      items:
        TaskCategories?.map((category) => {
          const color = getRandomColor(
            category.category_id,
            category.category_id
          );
          return {
            label: category.category_name,
            value: category.category_id,
            icon: () => <Icon color={color} />,
            onClick: () => {
              onSelect?.(category);
            },
          };
        }) || [],
    },
  ];

  const selectedCategory = TaskCategories?.find(
    (category) =>
      category.category_id === value?.category_id ||
      category.category_id === value?.time_category_id
  );

  return (
    <DropdownMenu
      menuItems={menuItems}
      title={"Select Category"}
      variant={variant}
      textColor={!!value?.category_name ? "primary" : "secondary"}
      error={error}
    >
      {showLabel && (
        <div title={selectedCategory?.category_name || "Category"}>
          {
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                width: "100%",
              }}
            >
              <ShapesIcon size={14} style={{ minWidth: 14 }} />
              <div
                style={{
                  maxWidth: 125,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {selectedCategory?.category_name || "Category"}
              </div>
            </div>
          }
        </div>
      )}
    </DropdownMenu>
  );
};

export default TaskCategorySelector;
