import * as Tooltip from "@radix-ui/react-tooltip";
import { InfoIcon } from "lucide-react";
import styled from "styled-components";

export const UserToolTip = styled(
  ({
    className,
    info = {
      header: `My Items`,
      content: `Below are only the items that are assigned to you. There may be other items associated with this contact that are not visible here.`,
    },
  }) => {
    return (
      <div className={className}>
        <Tooltip.Provider delayDuration={600}>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <div className="info-icon">
                <InfoIcon size={12} />
              </div>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content
                className={className + " tooltip-content"}
                side="bottom"
                sideOffset={10}
                align="start"
                onClick={(e) => e.stopPropagation()}
              >
                <h2>{info.header}</h2>
                <p>{info.body}</p>
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      </div>
    );
  }
)`
  .info-icon {
    top: 10px;
    left: 10px;
    margin-right: 10px;
    color: ${({ theme }) => theme.palette.text.secondary};

    &:hover {
      color: ${({ theme }) => theme.palette.text.primary};
    }
  }

  &.tooltip-content {
    display: flex;
    flex-direction: column;
    pointer-events: none;
    justify-content: center;
    align-items: flex-start;
    z-index: 100000;
    background-color: ${({ theme }) => theme.palette.background.default};
    padding: 15px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.palette.divider};
    font-size: 0.75rem;
    max-width: 300px;
    width: 250px;

    h2 {
      margin: 0;
    }

    p {
      margin: 0;
    }
  }
`;
