import { styled } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-regular-svg-icons";
import { faFileCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";
import { useDropzone } from "react-dropzone";
import { useState } from "react";
import { getIconForFile } from "vscode-icons-js";
import { useEffect } from "react";
import ContextMenu from "../../Monolith-UI/ContextMenu/ContextMenu.js";
import CheckBox from "../../Monolith-UI/CheckBox.js";
import { XIcon } from "lucide-react";

export const EmailList = styled(({ className, emails, onRemove }) => {
  return (
    <div className={className}>
      {emails.map((email) => {
        return (
          <div className="email" key={email}>
            <div className="email-text">{email}</div>
            <div className="remove-button" onClick={() => onRemove?.(email)}>
              <XIcon size={12} />
            </div>
          </div>
        );
      })}
    </div>
  );
})`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
  height: auto;

  user-select: none;
  max-height: 200px;
  overflow-y: auto;
  padding-right: 5px;

  .email {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
    height: auto;
    padding: 2px 4px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.palette.divider};

    transition: border 0.2s ease;
  }

  .email-text {
    font-size: 10px;
  }

  .remove-button {
    display: flex;
    font-size: 10px;
    font-weight: 600;
    color: ${(props) => props.theme.palette.text.secondary};
    border-radius: 5px;
    padding: 2px;
    cursor: pointer;
  }

  .remove-button:hover {
    background-color: ${(props) => props.theme.palette.action.hover};
  }
`;

const FileBrowser = styled(
  ({
    className,
    items,
    onFileClick = () => {},
    fileContextMenuProps = {},
    onFolderClick = () => {},
    folderContextMenuProps = {},
    onFileDblClick = () => {},
    onFolderDblClick = () => {},
    onSelectionChanged = () => {},
    fileComponent,
    fileSectionName = "Files",
    folderSectionName = "Folders",
    emptyFolderMessage = "Create a Folder or Add a File.",
    displayName = null,
    onBrowserDrop = () => {},
    onFolderDrop = () => {},
    fileBrowserRef = null,
    onClearSelection = () => {},
    onItemMoved = () => {},
    disabled = false,
  }) => {
    const [movingItem, setMovingItem] = useState(null);
    const folders = items.filter((item) => item.type === "Folder");
    const files = items.filter((item) => item.type !== "Folder");
    const [selectedItems, setSelectedItems] = useState([]);

    const handleFileDrop = async (files, reject, event) => {
      event.preventDefault();
      event?.target?.classList?.remove("dragging");
      onBrowserDrop(files);
    };

    const handleRejection = (items) => {};

    const handleFileClick = (file) => {
      setSelectedItems([file]);
      onFileClick(file);
    };

    const handleFileSelect = (file) => {
      setSelectedItems((prev) => {
        const newItems = [...prev];
        newItems.push(file);
        return newItems;
      });
      onFileClick(file);
    };

    const handleFileDeSelect = (file) => {
      setSelectedItems((prev) => prev.filter((i) => i.uuid !== file.uuid));
    };

    const handleFileDblClick = (file) => {
      onFileDblClick(file);
    };

    const handleFileContextMenu = (file) => {
      setSelectedItems([file]);
      onFileClick(file);
    };

    const handleFolderSelect = (folder) => {
      setSelectedItems((prev) => {
        const newItems = [...prev];
        newItems.push(folder);
        return newItems;
      });
      onFolderClick(folder);
    };

    const handleFolderDeSelect = (folder) => {
      setSelectedItems((prev) => prev.filter((i) => i.uuid !== folder.uuid));
    };

    const handleFolderClick = (folder) => {
      setSelectedItems([folder]);
      onFolderClick(folder);
    };

    const handleFolderContextMenu = (folder) => {
      setSelectedItems([folder]);
      onFolderClick(folder);
    };

    const clearSelection = () => {
      setSelectedItems([]);

      document
        .querySelectorAll(`.${className.split(" ").join(".")} .selected`)
        .forEach((el) => el.classList.remove("selected"));

      onClearSelection();
    };

    const handleMoveItem = (item, folder) => {
      onItemMoved(item, folder);
      setMovingItem(null);
    };

    const { getRootProps, getInputProps } = useDropzone({
      onDragEnter: (e) => e.target.classList.add("dragging"),
      onDragLeave: (e) => e.target.classList.remove("dragging"),
      onDrop: handleFileDrop,
      onDropRejected: handleRejection,
      disabled: disabled,
      multiple: true,
    });

    useEffect(() => {
      onSelectionChanged?.(selectedItems);
    }, [selectedItems]);

    useEffect(() => {
      if (fileBrowserRef) {
        fileBrowserRef.current = {
          clearSelection,
        };
      }
    }, []);

    return (
      <div
        className={className + " file-browser-container"}
        onClick={() => clearSelection()}
      >
        <div
          className={"file-browser"}
          {...getRootProps()}
          onClick={(e) => {
            clearSelection();
          }}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          onKeyUp={(e) => {
            e.preventDefault();
          }}
        >
          <input {...getInputProps()} />
          {folders && folders.length !== 0 && (
            <>
              <div className="section-title">{folderSectionName}</div>
              <div className="folder-section">
                {folders.map((item) => {
                  return (
                    <Folder
                      key={item.uuid}
                      isSelected={
                        !!selectedItems.find((i) => i.uuid === item.uuid)
                      }
                      folder={item}
                      onFolderSelected={handleFolderSelect}
                      onFolderDeSelected={handleFolderDeSelect}
                      onFolderClick={handleFolderClick}
                      onFolderDblClick={(data) => {
                        setSelectedItems([]);
                        onFolderDblClick(data);
                      }}
                      displayName={displayName}
                      onFolderDrop={onFolderDrop}
                      onDragStart={() => setMovingItem(item)}
                      onItemDrop={() => {
                        handleMoveItem(movingItem, item);
                      }}
                      onContextMenu={handleFolderContextMenu}
                      folderContextMenuProps={folderContextMenuProps}
                      disabled={disabled}
                    />
                  );
                })}
              </div>
              <div style={{ margin: "20px 0px", pointerEvents: "none" }}></div>
            </>
          )}
          {files && files.length !== 0 && (
            <>
              {fileSectionName && (
                <div className="section-title">{fileSectionName}</div>
              )}
              <div className="file-section">
                {files.map((item) => {
                  return (
                    <File
                      key={item.uuid}
                      isSelected={
                        !!selectedItems.find((i) => i.uuid === item.uuid)
                      }
                      file={item}
                      onFileClick={handleFileClick}
                      onFileSelected={handleFileSelect}
                      onFileDeSelected={handleFileDeSelect}
                      onFileDblClick={handleFileDblClick}
                      displayName={displayName}
                      onDragStart={() => setMovingItem(item)}
                      onContextMenu={handleFileContextMenu}
                      fileContextMenuProps={fileContextMenuProps}
                      component={fileComponent}
                    />
                  );
                })}
              </div>
            </>
          )}
          {folders && folders.length === 0 && files && files.length === 0 && (
            <div
              className="no-items"
              style={{ textAlign: "center", pointerEvents: "none" }}
            >
              <div>
                <FontAwesomeIcon icon={faFileCirclePlus} />
              </div>
              <div>{emptyFolderMessage}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
)`
  user-select: none;
  min-height: ${({ minHeight }) =>
    minHeight
      ? minHeight.toString().includes("px")
        ? minHeight
        : minHeight + "px"
      : "calc(100vh - 300px)"};
  &.file-browser-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-grow: 1;
  }
  & .file-browser.dragging {
    border: 1px solid ${(props) => props.theme.palette.primary.main};
    .file {
      pointer-events: none;
    }
  }
  & .file-browser {
    overflow-y: auto;
    padding: 20px;
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 5px;
    flex-grow: 1;
    outline: none;
  }
  & .section-title {
    margin-bottom: 1rem;
    color: ${(props) => props.theme.palette.text.secondary};
    pointer-events: none;
  }
  & .folder-section {
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      ${(props) => props.fileItemWidth || 250}px
    );
    grid-gap: 1rem;
    pointer-events: none;
  }
  & .file-section {
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      ${(props) => props.fileItemWidth || 250}px
    );
    grid-gap: 1rem;
    pointer-events: none;
  }
  & .no-items {
    color: ${(props) => props.theme.palette.divider};
    font-size: 1.5rem;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 3rem;
      margin-bottom: 1.5rem;
    }
  }
`;

const File = styled(
  ({
    className,
    isSelected,
    file,
    onFileClick = () => {},
    onFileSelected = () => {},
    onFileDeSelected = () => {},
    onFileDblClick = () => {},
    onContextMenu = () => {},
    fileContextMenuProps = {},
    displayName,
    onDragStart = () => {},
    component: FileComponent = null,
  }) => {
    const fileRef = useRef(null);
    const fileIcon = getIconForFile(
      displayName ? file[displayName].toLowerCase() : file.name.toLowerCase()
    );

    const handleClick = (e) => {
      e.stopPropagation();
      const os = navigator.userAgent;

      // check if control key is pressed
      if (
        (os.toLowerCase().includes("mac") && e.metaKey) ||
        (os.toLowerCase().includes("win") && e.ctrlKey)
      ) {
        if (isSelected) {
          onFileDeSelected(file);
        } else {
          onFileSelected(file);
        }
      } else {
        onFileClick(file);
      }
    };

    const handleSelect = (e) => {
      onFileSelected(file);
    };

    const handleDeSelect = (e) => {
      onFileDeSelected(file);
    };

    const handleFileDblClick = (e) => {
      e.stopPropagation();
      onFileDblClick(file);
    };

    if (FileComponent)
      return (
        <FileComponent
          itemRef={fileRef}
          item={file}
          isSelected={isSelected}
          onClick={handleClick}
          draggable
          onDragStart={(e) => {
            onDragStart(e);
          }}
        />
      );

    return (
      <ContextMenu
        {...fileContextMenuProps}
        itemData={file}
        onOpenChange={(open) => {
          if (open) {
            onContextMenu(file);
            fileContextMenuProps.onOpenChange?.(open);
          }
        }}
        disabled={
          fileContextMenuProps?.menuItems
            ? fileContextMenuProps?.menuItems?.length === 0
              ? true
              : false
            : true
        }
      >
        <div
          ref={fileRef}
          className={className + ` file ${isSelected ? "selected" : ""}`}
          data-selected={isSelected}
          data-status={file.status}
          onClick={handleClick}
          onDoubleClick={handleFileDblClick}
          draggable
          onDragStart={(e) => {
            onDragStart(e);
          }}
        >
          <div className="badge"></div>
          <div className="file-left">
            <div className="file-icon">
              <img
                src={`${process.env.PUBLIC_URL}/static/icons/${fileIcon}`}
                alt="icon"
                style={{ height: "1.1rem" }}
              />
            </div>
            <div className="file-checkbox">
              <CheckBox
                checked={isSelected}
                onChange={(e) => {
                  if (e.newValue) handleSelect();
                  else handleDeSelect();
                }}
              />
            </div>
          </div>
          <div
            className="name"
            title={displayName ? file[displayName] : file.name}
          >
            {displayName ? file[displayName] : file.name}
          </div>
        </div>
      </ContextMenu>
    );
  }
)`
  pointer-events: all;
  position: relative;

  height: 50px;

  .badge {
    position: absolute;
    top: 0;
    left: 0;
    height: 48px;
    width: 4px;
    // background-color: ${(props) => props.theme.palette.primary.main};
    border-radius: 5px 0px 0px 5px;
  }

  &.file {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 12px 16px;
    border: 1px solid
      ${({ theme, isSelected }) =>
        isSelected ? theme.palette.primary.main : theme.palette.divider};
    border-radius: 5px;
    cursor: pointer;
  }

  .file-left {
    min-width: 20px;
  }

  .file-icon {
    display: flex;
  }

  .file-checkbox {
    display: none;
  }

  &.file:hover {
    background-color: ${(props) => props.theme.palette.action.hover};

    & .file-icon {
      display: none;
    }

    & .file-checkbox {
      display: flex;
    }
  }

  &[data-selected="true"] {
    & .file-icon {
      display: none;
    }

    & .file-checkbox {
      display: flex;
    }
  }

  &.file.selected {
  }

  .name {
    text-align: center;
    margin-left: 0.5rem;
    max-width: calc(100% - 50px);
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 500;
    color: ${(props) => props.theme.palette.text.primary};
  }
  svg {
    color: ${(props) => props.theme.palette.primary.main};
    font-size: 1rem;
  }

  &[data-status="archived"] {
    .badge {
      background-color: ${(props) => props.theme.palette.error.main};
    }
    opacity: 0.5;
  }
`;

const Folder = styled(
  ({
    className,
    folder,
    isSelected,
    onFolderSelected,
    onFolderDeSelected,
    onFolderClick,
    onFolderDblClick,
    onContextMenu = () => {},
    folderContextMenuProps = {},
    displayName,
    onFolderDrop,
    onDragStart = () => {},
    onItemDrop = () => {},
    disabled = false,
  }) => {
    const folderRef = useRef(null);

    const handleFileDrop = async (files, reject, event) => {
      event.stopPropagation();
      event?.target?.classList?.remove("dragging");
      document.querySelector(".file-browser").classList.remove("dragging");

      onFolderDrop(folder, files);
    };

    const handleRejection = (items) => {};

    const { getRootProps, getInputProps } = useDropzone({
      onDragEnter: (e) => {
        e.target.classList.add("dragging");
      },
      onDragLeave: (e) => {
        e.target.classList.remove("dragging");
      },
      onDrop: handleFileDrop,
      onDropRejected: handleRejection,
      disabled,
      multiple: true,
    });

    const handleSelect = (e) => {
      onFolderSelected?.(folder);
    };

    const handleDeSelect = (e) => {
      onFolderDeSelected?.(folder);
    };

    const handleClick = (e) => {
      e.stopPropagation();
      const os = navigator.userAgent;

      // check if control key is pressed
      if (
        (os.toLowerCase().includes("mac") && e.metaKey) ||
        (os.toLowerCase().includes("win") && e.ctrlKey)
      ) {
        if (isSelected) {
          onFolderDeSelected?.(folder);
        } else {
          onFolderSelected?.(folder);
        }
      } else {
        onFolderClick?.(folder);
      }
    };

    const handleDblClick = (e) => {
      onFolderDblClick(folder);
    };

    return (
      <ContextMenu
        {...folderContextMenuProps}
        onOpenChange={(open) => {
          if (open) {
            onContextMenu(folder);
            folderContextMenuProps.onOpenChange?.(open);
          }
        }}
        disabled={
          folderContextMenuProps?.menuItems
            ? folderContextMenuProps?.menuItems?.length === 0
              ? true
              : false
            : true
        }
      >
        <div
          class="folder-overlay"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onDragEnter={(e) => {
            !disabled && e.target.classList.add("dragging");
          }}
          onDragLeave={(e) => {
            !disabled && e.target.classList.remove("dragging");
          }}
          onDrop={(e) => {
            e.target.classList.remove("dragging");
            !disabled && onItemDrop();
          }}
        >
          <div
            className={className + " folder"}
            data-selected={isSelected}
            {...getRootProps()}
            ref={folderRef}
            onClick={(e) => {
              e.stopPropagation();
              // single click
              if (e.detail === 1) {
                handleClick(e);
              }
              // double click
              if (e.detail === 2) {
                handleDblClick(e);
              }
            }}
            draggable
            onDragStart={() => onDragStart()}
          >
            <input {...getInputProps()} />
            <FontAwesomeIcon
              className="folder-icon"
              icon={faFolder}
              style={{ color: "#c98200", width: 20 }}
            />
            <div className="folder-checkbox">
              <CheckBox
                checked={isSelected}
                onChange={(e) => {
                  if (e.newValue) handleSelect();
                  else handleDeSelect();
                }}
              />
            </div>
            <div
              className="name"
              title={displayName ? folder[displayName] : folder.name}
            >
              {displayName ? folder[displayName] : folder.name}
            </div>
          </div>
        </div>
      </ContextMenu>
    );
  }
)`
  user-select: none;
  pointer-events: all;
  position: relative;
  .folder-overlay.dragging {
    .folder {
      border: 1px solid ${(props) => props.theme.palette.primary.main};
    }
  }
  &.folder.dragging {
    border: 1px solid ${(props) => props.theme.palette.primary.main};
  }
  &.folder {
    display: flex;
    align-items: normal;
    justify-content: start;
    padding: 12px 16px;
    border: 1px solid
      ${({ theme, isSelected }) =>
        isSelected ? theme.palette.primary.main : theme.palette.divider};
    border-radius: 5px;
    cursor: pointer;
  }

  .folder-checkbox {
    display: none;
  }

  &.folder:hover {
    background-color: ${(props) => props.theme.palette.action.hover};

    .folder-icon {
      display: none;
    }

    .folder-checkbox {
      display: flex;
    }
  }

  &[data-selected="true"] {
    .folder-icon {
      display: none;
    }

    .folder-checkbox {
      display: flex;
    }
  }

  .name {
    text-align: center;
    margin-left: 0.5rem;
    max-width: calc(100% - 50px);
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 500;
    color: ${(props) => props.theme.palette.text.primary};
  }
  svg {
    color: ${(props) => props.theme.palette.primary.main};
    font-size: 1rem;
  }
`;

export default FileBrowser;
