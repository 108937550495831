import { useCallback, useEffect, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";
import { usePermissions } from "../../hooks/usePermissions";
import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDebouncedCallback } from "use-debounce";
import {
  FolderClosedIcon,
  Columns3Icon,
  ListTodoIcon,
  DownloadIcon,
} from "lucide-react";
import { Button, Switch } from "@mui/material";

import TasksAPI from "../../api/tasks/tasks.js";
import UserApi from "../../api/users/users.js";
import TimeEntriesAPI from "../../api/TimeEntries/time-entries.js";
import TaskFilter, {
  FilterList,
  isDateInRange,
} from "./components/TaskFilter.js";
import TaskStatuses from "./components/TaskStatuses.js";
import { sortTasks } from "./utils/sortTasks.js";
import TaskPriorities from "./components/TaskPriorities.js";
import getRandomColor from "../../utils/getRandomColor.js";
import TaskDueDateOptions from "./components/TaskDueDateOptions.js";
import { Menu } from "./components/Menu.js";
import DropdownPopover from "../../Monolith-UI/DropdownPopover/DropdownPopover.js";
import DropdownMenu from "../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import TaskButton from "../../Monolith-UI/TaskButton.js";
import ViewSelector from "./components/ViewSelector.js";
import Kanban from "../../Monolith-UI/Kanban/Kanban.js";
import Task from "./components/Task.js";
import TaskList from "./components/TaskList/TaskList.js";
import TaskTable from "./TaskTable.js";
import ComboButton from "../../Monolith-UI/ComboButton/ComboButton.js";
import TimeEntriesTable from "./TimeEntriesTable.js";
import { ItemTotal } from "../../pages/Cases/CaseEvidence/index";
import TaskDefs from "../ColumnDefinitions/TaskDefs.js";
import { useSnackbar } from "notistack";
import { getDateFormat } from "../../utils/date-format";
import { Input } from "@monolith-forensics/monolith-ui";

const StyledButton = styled(Button)`
  font-size: 0.75rem;
  padding: 3px 5px;
  line-height: normal;
  margin-right: 5px;
  height: 24px;
`;

const TaskBoard = styled(
  ({
    className,
    taskBoardInstance,
    caseInfo,
    handleAddItem,
    onItemClick,
    showHeader = false,
    taskQuery = {},
    defaultTaskState = null,
    onTaskStateChange,
    itemHref = null,
  }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [totalItems, setTotalItems] = useState(null);
    const [currentView, setCurrentView] = useState(
      new URL(window.location.href).searchParams.get("view") || "board"
    );
    const { currentUser, hasPermission, MONOLITH_PERMISSIONS } =
      usePermissions();
    const theme = useTheme();
    const queryClient = useQueryClient();
    const [taskState, setTaskState] = useState({
      filter: [],
      groupBy: "status",
      orderBy: "manual",
      search: "",
      allTasks: false,
      includeArchived: false,
      displayPreferences: {
        showLinkedObject: true,
        showDueDate: true,
        showStatus: true,
        showPriority: true,
        showCategory: true,
        showUsers: true,
        showSubTaskCount: true,
        showSubTasks: true,
        showSubTaskIcon: true,
        showDuration: true,
        showAssignees: true,
      },
      columnState: TaskDefs,
      ...defaultTaskState,
    });

    const currentTableSort = taskState.columnState.find((c) => c.sorting);

    const query = useMemo(
      () => ({
        ...taskQuery,
        user_id: taskState.allTasks ? null : currentUser.user_id,
      }),
      [taskQuery, taskState, currentUser]
    );

    const { data: tasks, refetch } = useQuery({
      queryKey: ["tasks", "list", query],
      queryFn: () => TasksAPI.getTasks(query || {}),
      placeholderData: (data) => data,
      enabled: currentView === "board" || currentView === "list",
    });

    const apiResults = useQueries({
      queries: [
        {
          queryKey: [
            "users",
            "list",
            { include_observers: false, include_inactive: false },
          ],
          queryFn: () =>
            UserApi.getUsers({
              include_observers: false,
              include_inactive: false,
              case_id: caseInfo?.case_id || null,
            }),
        },
        {
          queryKey: ["task:categories"],
          queryFn: () => TimeEntriesAPI.getTimeCategories(),
        },
      ],
    });

    const handleRefresh = () => {
      refetch();
    };

    const handleUpdateCache = useCallback(
      (updateData = {}) => {
        queryClient.setQueryData(["tasks", "list", query], (oldData) => {
          const prevTasks = [...oldData.data];
          const taskIndex = prevTasks.findIndex(
            (t) => t.uuid === updateData.uuid
          );

          prevTasks[taskIndex] = {
            ...prevTasks[taskIndex],
            ...updateData,
          };

          return {
            ...oldData,
            data: prevTasks,
          };
        });
      },
      [query]
    );

    const handleItemMove = useCallback(
      ({ destinationGroup, sourceGroup, item, event }) => {
        // Update Task status
        if (taskState.groupBy === "status") {
          const status_id = destinationGroup.id;
          const uuid = item.uuid;
          TasksAPI.updateTask({ status_id, uuid, sort_value: item.sort_value });
          handleUpdateCache({ status_id, uuid, sort_value: item.sort_value });
        }

        // Update Priority
        else if (taskState.groupBy === "priority") {
          const priority_id = destinationGroup.id;
          const uuid = item.uuid;
          TasksAPI.updateTask({
            priority_id,
            uuid,
            sort_value: item.sort_value,
          });
          handleUpdateCache({ priority_id, uuid, sort_value: item.sort_value });
        }
      },
      [taskState, handleUpdateCache]
    );

    const handleItemRemove = useCallback(
      ({ task }) => {
        queryClient.setQueryData(["tasks", "list", query], (oldData) => {
          const prevTasks = [...oldData.data];
          const taskIndex = prevTasks.findIndex((t) => t.uuid === task.uuid);

          prevTasks.splice(taskIndex, 1);

          return {
            ...oldData,
            data: prevTasks,
          };
        });
      },
      [query, queryClient]
    );

    const handleUpdateFilter = (filterUpdates) => {
      setTaskState((prev) => {
        const newState = { ...prev };

        const { field, value, label, operator } = filterUpdates;
        let newFilter = [...newState.filter];

        const currentFieldIndex = newFilter.findIndex((f) => f.field === field);

        // Field Condition Exists
        if (currentFieldIndex > -1) {
          if (!value || value.length === 0) {
            newFilter = newFilter.filter((f) => f.field !== field);
          } else {
            newFilter[currentFieldIndex] = {
              field,
              value,
              label,
              operator,
            };
          }
        } else {
          newFilter.push({
            field,
            value,
            label,
            operator,
          });
        }

        return {
          ...newState,
          filter: newFilter,
        };
      });
    };

    const handleRemoveFilter = (field) => {
      setTaskState((prev) => {
        const newState = { ...prev };
        const newFilter = [...newState.filter].filter((f) => f.field !== field);
        return {
          ...newState,
          filter: newFilter,
        };
      });
    };

    const handleArchiveTasks = (data) => {
      const { tasks } = data;
      const task_uuids = tasks.map((t) => t.uuid);

      TasksAPI.archiveTasks({ uuids: task_uuids });

      queryClient.setQueryData(["tasks", "list", query], (oldData) => {
        const prevTasks = [...oldData.data];
        const updatedTasks = prevTasks.filter(
          (t) => !task_uuids.includes(t.uuid)
        );

        return {
          ...oldData,
          data: updatedTasks,
        };
      });
    };

    const handleItemArchived = useCallback(
      ({ task }) => {
        queryClient.setQueryData(["tasks", "list", query], (oldData) => {
          const prevTasks = [...oldData.data];
          const taskIndex = prevTasks.findIndex((t) => t.uuid === task.uuid);

          prevTasks.splice(taskIndex, 1);

          return {
            ...oldData,
            data: prevTasks,
          };
        });
      },
      [query, queryClient]
    );

    const handleColumnVisibility = (column, visible) => {
      setTaskState((ts) => {
        const cs = ts.columnState;
        return {
          ...ts,
          columnState: cs.map((c) => {
            if (c.dataField === column.dataField) {
              return {
                ...c,
                visible,
              };
            }
            return c;
          }),
        };
      });
    };

    // Export Tasks Table
    const handleExportTable = () => {
      // show snackbar
      enqueueSnackbar("Exporting table...", {
        variant: "info",
      });

      TasksAPI.exportList({
        query: {
          ...query,
          user_id: taskState.allTasks ? null : query.user_id,
          include_archived: taskState.includeArchived,
          sort: currentTableSort
            ? {
                field: currentTableSort.dataField,
                dir: currentTableSort.sorting.direction,
              }
            : null,
          ...(taskState.filter.reduce((acc, f) => {
            if (f.field === "status") {
              acc.status_id = f.value;
            }
            if (f.field === "priority") {
              acc.priority_id = f.value;
            }
            if (f.field === "category") {
              acc.time_category_id = f.value;
            }
            if (f.field === "assignees") {
              acc.assignee_id = f.value;
            }
            if (f.field === "has_subtasks") {
              acc.has_subtasks = f.value;
            }
            if (f.field === "due_date") {
              acc.due_date = f.value;
            }
            return acc;
          }, {}) || {}),
          search: taskState.search,
        },
        type: "xlsx",
        columns: taskState.columnState
          .filter((c) => c.visible !== false)
          .sort((a, b) => a.order - b.order)
          .map((c) => {
            return { dataField: c.dataField, header: c.caption, ...c };
          }),
        date_format: getDateFormat({ isMoment: true, includeTime: true }),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        custom_date_format: getDateFormat({
          isMoment: true,
          includeTime: false,
        }),
      }).then((res) => {
        const { signedUrl, filename } = res;
        const el = document.createElement("a");
        el.href = signedUrl.replace(
          "http://localhost:3000",
          "http://localhost:3001"
        );
        el.download = filename;
        el.click();
        el.remove();
      });
    };

    const handleUpdateDisplayPreferences = useDebouncedCallback((key) => {
      setTaskState((prev) => {
        return {
          ...prev,
          displayPreferences: {
            ...prev.displayPreferences,
            [key]: !prev.displayPreferences[key],
          },
        };
      });
    }, 25);

    useEffect(() => {
      // Add current view to url
      const url = new URL(window.location.href);
      const keys = url.searchParams?.keys();

      // clear all search params
      Array.from(keys).forEach((entry) => {
        url.searchParams.delete(entry);
      });

      url.searchParams.set("view", currentView);
      window.history.replaceState({}, "", url);
    }, [currentView]);

    useEffect(() => {
      const newTaskState = {
        ...taskState,
        columnState: taskState.columnState.map((c) => {
          delete c.render;
          return c;
        }),
      };

      onTaskStateChange?.({
        key: caseInfo?.uuid || "tasks:dashboard",
        state: newTaskState,
      });
    }, [taskState]);

    // set taskboard instance
    if (taskBoardInstance) {
      taskBoardInstance.current = {
        taskQueryKey: ["tasks", "list", query],
        reload: handleRefresh,
        addTask: handleAddItem,
        getTasks: () => {
          return tasks?.data || [];
        },
      };
    }

    const data = tasks?.data || [];
    const users = apiResults[0]?.data || [];
    const TaskCategories = apiResults[1]?.data || [];

    // Add subtasks to tasks
    const filteredData = data
      .map((task) => {
        const subtasks = data.filter(
          (subtask) => subtask.parent_id === task.uuid
        );
        return {
          ...task,
          subtasks,
        };
      })
      // Remove tasks that are subtasks, if showSubTasks is false
      .filter((task) => {
        if (taskState?.displayPreferences?.showSubTasks) return true;
        else return !task.parent_id;
      })
      // Apple Search Filter
      .filter((task) => {
        if (!taskState.search) return true;
        else if (taskState.search.length < 3) return true;
        else {
          const searchTerms = taskState.search.split(" ");
          const taskName = task.task_name.toLowerCase();
          const description = task.description?.toLowerCase() || "";
          const objectName = task.object_name?.toLowerCase() || "";

          return (
            taskName.includes(taskState.search) ||
            description.includes(taskState.search) ||
            objectName.includes(taskState.search)
          );
        }
      })
      // Apply filter
      .filter((task) => {
        let include = true;
        taskState?.filter?.forEach((f) => {
          if (f.field === "status") {
            if (!f.value.includes(task.status_id)) include = false;
          }
          if (f.field === "priority") {
            if (!f.value.includes(task.priority_id)) include = false;
          }
          if (f.field === "category") {
            if (!f.value.includes(task.time_category_id)) include = false;
          }
          if (f.field === "assignees") {
            if (
              !f.value.some((a) =>
                task?.assignees?.map((a) => a.user_id).includes(a)
              )
            )
              include = false;
          }
          if (f.field === "has_subtasks") {
            include =
              f.value === "true"
                ? task?.subtasks?.length > 0
                : task?.subtasks?.length === 0;
          }
          if (f.field === "due_date") {
            include = isDateInRange(task?.due_date, f.value);
          }
        });
        return include;
      });

    const totalTasks = filteredData.length || 0;

    let columns = [];

    if (taskState.groupBy === "status") {
      columns = TaskStatuses.map((status) => ({
        type: "group",
        id: status.status_id,
        label: status.status_name,
        icon: status.icon,
        iconColor: status.color,
        taskValue: "status",
        tasks: sortTasks(
          filteredData.filter((task) => task.status_id === status.status_id),
          taskState.orderBy
        ),
      }));
    } else if (taskState.groupBy === "priority") {
      columns = TaskPriorities.map((priority) => ({
        type: "group",
        id: priority.priority_id,
        label: priority.priority_name,
        icon: priority.icon,
        iconColor: priority.color,
        taskValue: "priority",
        tasks: sortTasks(
          filteredData.filter(
            (task) => task.priority_id === priority.priority_id
          ),
          taskState.orderBy
        ),
      }));
    } else if (taskState.groupBy === "category") {
      // Create a column for tasks that don't have a category
      columns.push({
        type: "group",
        id: "null",
        label: "No Category",
        icon: ({ color, style }) => (
          <div
            style={{
              borderRadius: "50%",
              height: 10,
              width: 10,
              backgroundColor: color,
              ...style,
            }}
          ></div>
        ),
        iconColor: theme.palette.text.secondary,
        taskValue: "category",
        tasks: sortTasks(
          filteredData.filter((task) => !task.time_category_id),
          taskState.orderBy
        ),
      });

      TaskCategories.forEach((category) =>
        columns.push({
          type: "group",
          id: category.category_id,
          label: category.category_name,
          icon: ({ color, style }) => (
            <div
              style={{
                borderRadius: "50%",
                height: 10,
                width: 10,
                backgroundColor: color,
                ...style,
              }}
            ></div>
          ),
          iconColor: getRandomColor(category.category_id, category.category_id),
          taskValue: "category",
          tasks: sortTasks(
            filteredData.filter(
              (task) => task.time_category_id === category.category_id
            ),
            taskState.orderBy
          ),
        })
      );
    } else if (taskState.groupBy === "due_date") {
      columns = TaskDueDateOptions.map((option) => ({
        type: "group",
        id: option.value,
        label: option.label,
        icon: option.icon,
        iconColor: option?.iconColor || theme.palette.text.secondary,
        taskValue: "due_date",
        tasks: sortTasks(
          filteredData.filter(
            (task) =>
              isDateInRange(task.due_date, option.value) &&
              task.is_complete === 0
          ),
          taskState.orderBy
        ),
      }));
    } else if (taskState.groupBy === "case") {
      const cases = filteredData.reduce((acc, task) => {
        const case_id = task.case_id;
        if (!acc[case_id]) acc[case_id] = [];
        acc[case_id].push(task);
        return acc;
      }, {});

      Object.keys(cases).forEach((case_id) => {
        const caseTasks = cases[case_id];
        columns.push({
          type: "group",
          id: case_id,
          label: caseTasks[0].case_number,
          icon: FolderClosedIcon,
          iconColor: theme.palette.primary.main,
          taskValue: "case",
          tasks: sortTasks(caseTasks, taskState.orderBy),
        });
      });
    }

    const groupByMenuItems = [
      {
        radioGroup: true,
        closeOnSelect: true,
        value: taskState.groupBy,
        items: [
          {
            label: "Status",
            value: "status",
            onClick: () => {
              setTimeout(() => {
                setTaskState((prev) => ({ ...prev, groupBy: "status" }));
              }, 0);
            },
          },
          {
            label: "Priority",
            value: "priority",
            onClick: () => {
              setTimeout(() => {
                setTaskState((prev) => ({ ...prev, groupBy: "priority" }));
              }, 0);
            },
          },
          {
            label: "Category",
            value: "category",
            onClick: () => {
              setTimeout(() => {
                setTaskState((prev) => ({ ...prev, groupBy: "category" }));
              }, 0);
            },
          },
          {
            label: "Due Date",
            value: "due_date",
            onClick: () => {
              setTimeout(() => {
                setTaskState((prev) => ({ ...prev, groupBy: "due_date" }));
              }, 0);
            },
          },
          {
            label: "Case",
            value: "case",
            onClick: () => {
              setTimeout(() => {
                setTaskState((prev) => ({ ...prev, groupBy: "case" }));
              }, 0);
            },
          },
        ],
      },
    ];

    const orderMenuItems = [
      {
        radioGroup: true,
        closeOnSelect: true,
        value: taskState.orderBy,
        items: [
          {
            label: "Manual",
            value: "manual",
            onClick: () => {
              setTimeout(() => {
                setTaskState((prev) => ({ ...prev, orderBy: "manual" }));
              }, 0);
            },
          },
          {
            label: "Task Name",
            value: "task_name",
            onClick: () => {
              setTimeout(() => {
                setTaskState((prev) => ({ ...prev, orderBy: "task_name" }));
              }, 0);
            },
          },
          {
            label: "Created On",
            value: "created_on",
            onClick: () => {
              setTimeout(() => {
                setTaskState((prev) => ({ ...prev, orderBy: "created_on" }));
              }, 0);
            },
          },
        ],
      },
    ];

    if (!apiResults.every((result) => result.isFetched)) return null;

    return (
      <div className={className}>
        {currentView === "time" && (
          <TimeEntriesTable
            setCurrentView={setCurrentView}
            caseInfo={caseInfo}
          />
        )}
        {currentView !== "time" && (
          <>
            <Menu>
              <StyledButton variant="contained" onClick={() => handleAddItem()}>
                + New Task
              </StyledButton>
              <TaskFilter
                filter={taskState.filter}
                onUpdateFilter={handleUpdateFilter}
                onRemoveFilter={handleRemoveFilter}
              />
              <DropdownPopover
                label="Display"
                variant="outlined"
                arrow
                contentProps={{
                  style: { minWidth: 300, maxWidth: 300, padding: 20 },
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 15,
                  }}
                >
                  {(!!caseInfo ||
                    hasPermission(MONOLITH_PERMISSIONS.CASES_READ_ALL)) && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ color: theme.palette.text.secondary }}>
                        View Filter
                      </div>
                      <DropdownMenu
                        menuItems={[
                          {
                            label: "My Tasks",
                            onClick: () => {
                              setTaskState((prev) => ({
                                ...prev,
                                allTasks: false,
                              }));
                            },
                          },
                          {
                            label: "All Tasks",
                            onClick: () => {
                              setTaskState((prev) => ({
                                ...prev,
                                allTasks: true,
                              }));
                            },
                          },
                        ]}
                        title={"Tasks"}
                        variant="outlined"
                        arrow
                        buttonProps={{
                          style: { minWidth: 100, paddingLeft: 10 },
                        }}
                      >
                        {taskState.allTasks ? "All Tasks" : "My Tasks"}
                      </DropdownMenu>
                    </div>
                  )}
                  {currentView !== "table" && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ color: theme.palette.text.secondary }}>
                        Grouping
                      </div>
                      <DropdownMenu
                        menuItems={groupByMenuItems}
                        title={"Group Tasks"}
                        variant="outlined"
                        arrow
                        buttonProps={{
                          style: { minWidth: 100, paddingLeft: 10 },
                        }}
                      >
                        {taskState.groupBy === "status"
                          ? "Status"
                          : taskState.groupBy === "priority"
                          ? "Priority"
                          : taskState.groupBy === "category"
                          ? "Category"
                          : taskState.groupBy === "due_date"
                          ? "Due Date"
                          : taskState.groupBy === "case"
                          ? "Case"
                          : "None"}
                      </DropdownMenu>
                    </div>
                  )}
                  {currentView !== "table" && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ color: theme.palette.text.secondary }}>
                        Ordering
                      </div>
                      <DropdownMenu
                        menuItems={orderMenuItems}
                        title={"Order Tasks"}
                        variant="outlined"
                        arrow
                        buttonProps={{
                          style: { minWidth: 100, paddingLeft: 10 },
                        }}
                      >
                        {taskState.orderBy === "manual"
                          ? "Manual"
                          : taskState.orderBy === "task_name"
                          ? "Task Name"
                          : "Created On"}
                      </DropdownMenu>
                    </div>
                  )}
                  {currentView === "table" && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ color: theme.palette.text.secondary }}>
                        Show Archived
                      </div>
                      <Switch
                        defaultChecked={taskState.includeArchived}
                        size="small"
                        onChange={() => {
                          setTaskState((prev) => ({
                            ...prev,
                            includeArchived: !prev.includeArchived,
                          }));
                        }}
                      />
                    </div>
                  )}
                  {currentView !== "table" && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ color: theme.palette.text.secondary }}>
                        Show Sub Tasks
                      </div>
                      <Switch
                        defaultChecked={
                          taskState?.displayPreferences?.showSubTasks
                        }
                        size="small"
                        onChange={() => {
                          setTaskState((prev) => ({
                            ...prev,
                            displayPreferences: {
                              ...prev.displayPreferences,
                              showSubTasks:
                                !prev.displayPreferences?.showSubTasks,
                            },
                          }));
                        }}
                      />
                    </div>
                  )}
                  {currentView !== "table" && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ color: theme.palette.text.secondary }}>
                          Task Display Properties
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 5,
                          marginTop: 5,
                        }}
                      >
                        <TaskButton
                          variant={
                            taskState?.displayPreferences?.showAssignees
                              ? "contained"
                              : "outlined"
                          }
                          style={{
                            fontSize: "0.65rem",
                            color: theme.palette.text.primary,
                            padding: "3px 5px",
                          }}
                          onClick={() =>
                            handleUpdateDisplayPreferences("showAssignees")
                          }
                        >
                          Assignees
                        </TaskButton>
                        <TaskButton
                          variant={
                            taskState?.displayPreferences?.showLinkedObject
                              ? "contained"
                              : "outlined"
                          }
                          style={{
                            fontSize: "0.65rem",
                            color: theme.palette.text.primary,
                            padding: "3px 5px",
                          }}
                          onClick={() =>
                            handleUpdateDisplayPreferences("showLinkedObject")
                          }
                        >
                          Linked Object
                        </TaskButton>
                        <TaskButton
                          variant={
                            taskState?.displayPreferences?.showStatus
                              ? "contained"
                              : "outlined"
                          }
                          style={{
                            fontSize: "0.65rem",
                            color: theme.palette.text.primary,
                            padding: "3px 5px",
                          }}
                          onClick={() =>
                            handleUpdateDisplayPreferences("showStatus")
                          }
                        >
                          Status
                        </TaskButton>
                        <TaskButton
                          variant={
                            taskState?.displayPreferences?.showPriority
                              ? "contained"
                              : "outlined"
                          }
                          style={{
                            fontSize: "0.65rem",
                            color: theme.palette.text.primary,
                            padding: "3px 5px",
                          }}
                          onClick={() =>
                            handleUpdateDisplayPreferences("showPriority")
                          }
                        >
                          Priority
                        </TaskButton>
                        <TaskButton
                          variant={
                            taskState?.displayPreferences?.showDueDate
                              ? "contained"
                              : "outlined"
                          }
                          style={{
                            fontSize: "0.65rem",
                            color: theme.palette.text.primary,
                            padding: "3px 5px",
                          }}
                          onClick={() =>
                            handleUpdateDisplayPreferences("showDueDate")
                          }
                        >
                          Due Date
                        </TaskButton>
                        <TaskButton
                          variant={
                            taskState?.displayPreferences?.showSubTaskCount
                              ? "contained"
                              : "outlined"
                          }
                          style={{
                            fontSize: "0.65rem",
                            color: theme.palette.text.primary,
                            padding: "3px 5px",
                          }}
                          onClick={() =>
                            handleUpdateDisplayPreferences("showSubTaskCount")
                          }
                        >
                          Sub Tasks
                        </TaskButton>
                        <TaskButton
                          variant={
                            taskState?.displayPreferences?.showSubTaskIcon
                              ? "contained"
                              : "outlined"
                          }
                          style={{
                            fontSize: "0.65rem",
                            color: theme.palette.text.primary,
                            padding: "3px 5px",
                          }}
                          onClick={() =>
                            handleUpdateDisplayPreferences("showSubTaskIcon")
                          }
                        >
                          Sub Task Icon
                        </TaskButton>
                        <TaskButton
                          variant={
                            taskState?.displayPreferences?.showDuration
                              ? "contained"
                              : "outlined"
                          }
                          style={{
                            fontSize: "0.65rem",
                            color: theme.palette.text.primary,
                            padding: "3px 5px",
                          }}
                          onClick={() =>
                            handleUpdateDisplayPreferences("showDuration")
                          }
                        >
                          Time Spent
                        </TaskButton>
                      </div>
                    </>
                  )}
                </div>
              </DropdownPopover>
              <ItemTotal
                total={
                  currentView === "table" ? totalItems || 0 : totalTasks || 0
                }
                Icon={ListTodoIcon}
                style={{ marginLeft: 0 }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 5,
                  marginLeft: "auto",
                }}
              >
                {currentView === "table" && (
                  <>
                    <ComboButton
                      type="multi-select"
                      data={taskState.columnState.filter(
                        (c) => c.showInColumnChooser !== false
                      )}
                      displayField="caption"
                      idField={"dataField"}
                      selectedItems={taskState.columnState.filter(
                        (c) => c.visible !== false
                      )}
                      variant="outlined"
                      closeOnSelect={false}
                      showSearch={true}
                      dropDownTitle={() => {
                        return (
                          <div
                            style={{
                              margin: "5px 0px",
                              padding: 3,
                              color: theme.palette.text.secondary,
                              display: "flex",
                              alignItems: "center",
                              minWidth: 200,
                            }}
                          >
                            Select Columns
                          </div>
                        );
                      }}
                      onItemDeSelect={(item) => {
                        handleColumnVisibility(item, false);
                      }}
                      onItemSelect={(item) => {
                        handleColumnVisibility(item, true);
                      }}
                      textColor={theme.palette.text.primary}
                      title={"Select Columns"}
                    >
                      <Columns3Icon size={14} />
                    </ComboButton>
                    <TaskButton
                      variant="outlined"
                      style={{
                        fontSize: 11,
                        color: theme.palette.text.primary,
                        padding: "3px 5px",
                      }}
                      onClick={handleExportTable}
                    >
                      <DownloadIcon size={14} />
                    </TaskButton>
                  </>
                )}
                <ViewSelector
                  currentView={currentView}
                  setCurrentView={setCurrentView}
                />
                <Input
                  defaultValue={taskState.search}
                  placeholder="Search Tasks"
                  size={14}
                  style={{
                    height: 25,
                    width: 150,
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.target.value === "") {
                      setTaskState((prev) => ({
                        ...prev,
                        search: e.target.value,
                      }));
                    }
                  }}
                  onKeyUp={(e) => {
                    if (e.target.value === "") {
                      setTaskState((prev) => ({
                        ...prev,
                        search: e.target.value,
                      }));
                    }
                  }}
                />
              </div>
            </Menu>
            <FilterList
              filter={taskState.filter}
              onUpdateFilter={handleUpdateFilter}
              onRemoveFilter={handleRemoveFilter}
            />
          </>
        )}
        {currentView === "board" && (
          <Kanban
            data={columns || []}
            allowColumnMigrate={
              taskState.groupBy === "status" || taskState.groupBy === "priority"
            }
            onItemMove={handleItemMove}
            onItemRemove={handleItemRemove}
            onItemArchived={handleItemArchived}
            groupBy={taskState.groupBy}
            allowReorder={taskState.orderBy === "manual"}
            itemRender={Task}
            itemHref={itemHref}
            onAddItem={handleAddItem}
            onItemClick={onItemClick}
            onItemUpdate={handleUpdateCache}
            handleArchiveTasks={handleArchiveTasks}
            displayPreferences={taskState?.displayPreferences}
          />
        )}
        {currentView === "list" && (
          <TaskList
            data={filteredData}
            groupedData={columns}
            displayPreferences={taskState?.displayPreferences}
            onItemRemove={handleItemRemove}
            onItemArchived={handleItemArchived}
            onItemUpdate={handleUpdateCache}
            handleArchiveTasks={handleArchiveTasks}
            onAddItem={handleAddItem}
          />
        )}
        {currentView === "table" && (
          <TaskTable
            columnState={TaskDefs.map((c) => {
              const svc = taskState.columnState.find(
                (cs) => cs.dataField === c.dataField
              );
              return {
                ...c,
                ...svc,
              };
            })}
            setColumnState={(cs) => {
              setTaskState((prev) => ({
                ...prev,
                columnState: cs,
              }));
            }}
            taskQuery={{
              ...query,
              user_id: taskState.allTasks ? null : query.user_id,
              include_archived: taskState.includeArchived,
              sort: currentTableSort
                ? {
                    field: currentTableSort.dataField,
                    dir: currentTableSort.sorting.direction,
                  }
                : null,
              ...(taskState.filter.reduce((acc, f) => {
                if (f.field === "status") {
                  acc.status_id = f.value;
                }
                if (f.field === "priority") {
                  acc.priority_id = f.value;
                }
                if (f.field === "category") {
                  acc.time_category_id = f.value;
                }
                if (f.field === "assignees") {
                  acc.assignee_id = f.value;
                }
                if (f.field === "has_subtasks") {
                  acc.has_subtasks = f.value;
                }
                if (f.field === "due_date") {
                  acc.due_date = f.value;
                }
                return acc;
              }, {}) || {}),
              search: taskState.search,
            }}
            onTasksLoaded={(data) => {
              setTotalItems(data.total);
            }}
          />
        )}
      </div>
    );
  }
)`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex-grow: 1;
  height: 100%;
`;

export default TaskBoard;
