import { useEffect, useState } from "react";
import Input from "../Input.js";
import Menu, { MenuItem } from "../Menu.js";
import Calendar from "../Calendar/Calendar.js";
import NumberBox from "../NumberBox.js";
import moment from "moment";
import { monolithMoment } from "../../utils/date-format";
import { useTheme } from "@mui/material";
import TaskButton from "../TaskButton.js";
import styled from "styled-components";
import CheckBox from "../CheckBox.js";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";

const ComboButton = ({
  label = "",
  value = "",
  type = "button",
  data = [],
  inputStyle = {},
  onClick = () => {},
  dataField = "",
  displayField = "",
  variant = "text",
  useSelectMode = false,
  onItemSelect = (item) => {},
  onItemDeSelect = (item) => {},
  onChange = () => {},
  enabled = true,
  selectedItems = [],
  idField = "",
  includeTime = false,
  textColor = "",
  children,
  title,
  closeOnSelect = true,
  dropDownTitle = () => <></>,
  showDropdownIcon = false,
  size = "medium",
  showSearch = false,
  ...rest
}) => {
  const theme = useTheme();
  const [currentValue, setCurrentValue] = useState(value);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onClick(event);
  };

  const handleSelect = (event) => {
    if (closeOnSelect) handleClose();
    if (useSelectMode) {
      if (type === "dateBox") {
        if (!event) {
          setCurrentValue("None");
          onItemSelect(null);
          return;
        }
        setCurrentValue(event);
      } else setCurrentValue(event);
    }
    onItemSelect(event);
  };

  const handleOnChange = (event) => {
    setCurrentValue(event);
    onChange(event);
  };

  const handleDeSelect = (event) => {
    if (closeOnSelect) handleClose();
    onItemDeSelect(event);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <div style={{ display: "flex", alignItems: "center", padding: "2px 0px" }}>
      {label && (
        <div
          style={{
            color: theme.palette.text.secondary,
            minWidth: 135,
            fontSize: 12,
            marginRight: 10,
          }}
        >
          {label}
        </div>
      )}
      <div>
        {type === "button" && (
          <TaskButton
            onClick={handleButtonClick}
            variant={variant}
            enabled={enabled}
            style={{
              fontSize: 11,
              color: textColor || theme.palette.text.primary,
              padding: "3px 5px",
            }}
            title={title}
          >
            {displayField ? currentValue[displayField] : currentValue}
            {children}
          </TaskButton>
        )}
        {type === "textBox" && (
          <Input
            defaultValue={currentValue || ""}
            onChange={(e) => handleOnChange(e.newValue)}
            placeholder="Enter Value..."
            autoFocus={rest.autoFocus || false}
            style={{ marginRight: 3 }}
            inputStyle={{
              fontSize: "11px",
              color: textColor || theme.palette.text.primary,
              backgroundColor: theme.palette.background.secondary,
              padding: "3px 5px",
              border: "1px solid transparent",
              outline: "none",
              height: "24.5px",
              width: "100px",
              ...inputStyle,
            }}
          />
        )}
        {type === "dropDown" && (
          <>
            <TaskButton
              onClick={handleClick}
              variant={variant}
              enabled={enabled}
              style={{
                fontSize: size === "small" ? 10 : 11,
                color: textColor || theme.palette.text.primary,
                padding: "3px 5px",
              }}
              title={title}
            >
              {!currentValue && useSelectMode ? (
                <div style={{ color: theme.palette.input.placeholder }}>
                  Select...
                </div>
              ) : displayField && useSelectMode ? (
                currentValue[displayField] ? (
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      color: currentValue.color || theme.palette.text.primary,
                    }}
                  >
                    {currentValue.icon && (
                      <currentValue.icon
                        style={{ fontSize: 14, marginRight: 5 }}
                      />
                    )}
                    {currentValue[displayField]}
                  </div>
                ) : (
                  <span style={{ color: theme.palette.text.secondary }}>
                    Select...
                  </span>
                )
              ) : (
                currentValue
              )}
              {children}
              {showDropdownIcon && (
                <ArrowDropDownOutlinedIcon
                  style={{ fontSize: 16, marginLeft: 5 }}
                />
              )}
            </TaskButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              onItemSelect={handleSelect}
              dropDownTitle={dropDownTitle}
              showSearch={showSearch}
            >
              {data.map((item, index) => {
                const isSelected = !!selectedItems?.find(
                  (s) => item[idField] === s[idField]
                );
                return (
                  <MenuItem
                    key={item.id || index}
                    onClick={handleClose}
                    data={item}
                    style={{ minWidth: 200 }}
                    displayField={displayField}
                    title={item?.title || null}
                  >
                    {
                      <div
                        style={{
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                          color: item.color
                            ? item.color
                            : isSelected
                            ? theme.palette.primary.main
                            : theme.palette.text.primary,
                          fontWeight: isSelected ? 600 : "normal",
                          fontStyle: isSelected ? "italic" : "normal",
                        }}
                      >
                        {item.icon && (
                          <item.icon style={{ fontSize: 14, marginRight: 5 }} />
                        )}
                        {displayField ? item[displayField] : item}
                      </div>
                    }
                  </MenuItem>
                );
              })}
            </Menu>
          </>
        )}
        {type === "multi-select" && (
          <>
            <TaskButton
              onClick={handleClick}
              variant={variant}
              enabled={enabled}
              style={{
                fontSize: 11,
                color: textColor || theme.palette.text.primary,
                padding: "3px 5px",
              }}
            >
              {!selectedItems && useSelectMode ? (
                <div style={{ color: theme.palette.input.placeholder }}>
                  Select...
                </div>
              ) : displayField && useSelectMode ? (
                selectedItems.length === 0 ? (
                  "0 items"
                ) : selectedItems.length > 1 ? (
                  selectedItems.length + " items"
                ) : (
                  selectedItems[0][displayField]
                )
              ) : (
                currentValue
              )}
              {children}
              {showDropdownIcon && (
                <ArrowDropDownOutlinedIcon
                  style={{ fontSize: 16, marginLeft: 5 }}
                />
              )}
            </TaskButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              dropDownTitle={dropDownTitle}
              showSearch={showSearch}
            >
              {data.map((item, index) => {
                const isSelected = !!selectedItems?.find(
                  (s) => item[idField] === s[idField]
                );
                return (
                  <MultiSelectItem
                    key={item.id || index}
                    onItemSelected={(data) => {
                      if (!isSelected) handleSelect(data);
                      else handleDeSelect(data);
                    }}
                    data={item}
                    displayField={displayField}
                    selected={isSelected}
                  />
                );
              })}
            </Menu>
          </>
        )}
        {type === "dateBox" && (
          <>
            <TaskButton
              onClick={handleClick}
              variant={variant}
              style={{
                fontSize: 11,
                color: textColor || theme.palette.text.primary,
                padding: "3px 5px",
              }}
            >
              {currentValue ? (
                currentValue === "None" ? (
                  "None"
                ) : (
                  monolithMoment({
                    timestamp: currentValue,
                    includeTime,
                  })
                )
              ) : (
                <div style={{ color: theme.palette.input.placeholder }}>
                  Select...
                </div>
              )}
            </TaskButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              <Calendar
                key={1}
                defaultValue={
                  currentValue
                    ? moment(
                        Array.isArray(currentValue)
                          ? currentValue[0]
                          : currentValue
                      ).toDate()
                    : undefined
                }
                onChange={handleSelect}
                includeTime={false}
              />
            </Menu>
          </>
        )}
        {type === "numberBox" && (
          <NumberBox
            defaultValue={currentValue}
            showArrows={false}
            min={0}
            max={999}
            inputStyle={{
              fontSize: "12px",
              color: textColor || theme.palette.text.primary,
              padding: "5px",
              border: "1px solid transparent",
              outline: "none",
              backgroundColor: "transparent",
              ...inputStyle,
            }}
          />
        )}
      </div>
    </div>
  );
};

const MultiSelectItem = styled(
  ({ data, displayField, selected = false, onItemSelected = () => {} }) => {
    const [isChecked, setIsChecked] = useState(selected);

    const handleSelect = () => {
      setIsChecked(!isChecked);
      onItemSelected(data);
    };

    return (
      <MenuItem
        data={data}
        onItemSelect={() => handleSelect()}
        style={{
          margin: "5px 0px",
          display: "flex",
          alignItems: "center",
          minWidth: 200,
        }}
      >
        <div>
          <CheckBox
            checked={isChecked}
            onChange={() => handleSelect()}
            style={{ marginRight: 10 }}
          />
        </div>
        <div style={{ marginLeft: 5 }}>
          {displayField ? data[displayField] : data}
        </div>
      </MenuItem>
    );
  }
)``;

MultiSelectItem.displayName = "MultiSelectItem";

export default ComboButton;
