import styled from "styled-components";

const NotesList = styled.div`
  position: relative;
  padding: 0.75rem;
  overflow-y: auto;
  height: 100%;
  width: 20rem;
  min-width: 20rem;
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-top: none;
  display: flex;
  flex-direction: column;

  cursor: pointer;
`;

export default NotesList;
