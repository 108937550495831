// React hooks
import { useMemo, useState } from "react";

// Material-UI Components and Hooks, Styled Components
import { Typography, useTheme } from "@mui/material";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import KeyboardTabOutlinedIcon from "@mui/icons-material/KeyboardTabOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import styled from "styled-components";

// React Query Hooks
import { useQuery } from "@tanstack/react-query";

// Utility Functions and Hooks
import { nanoid } from "nanoid";
import { usePermissions } from "../../hooks/usePermissions";

// API Functions
import { getAllLabelInfo } from "../../api/index.js";

// Custom Components
import TaskButton from "../../Monolith-UI/TaskButton.js";
import CasesTable from "./CasesTable.js";
import DropdownMenu from "../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import PrinterSelectModal from "../../utils/labels/PrinterSelectPopup.js";
import Menu, { MenuItem } from "../../Monolith-UI/Menu.js";
import EditClientModal from "@/components/Modals/EditClientModal";

const ClientOverview = styled(
  ({ className, clientInfo: details, handleClientUpdate = () => {} }) => {
    const [showEditClientModal, setShowEditClientModal] = useState(false);

    return (
      <div className={className}>
        <SideContent
          details={details}
          setShowEditClientModal={setShowEditClientModal}
        />
        <MainContent
          details={details}
          handleClientUpdate={handleClientUpdate}
          showEditClientModal={showEditClientModal}
          setShowEditClientModal={setShowEditClientModal}
        />
      </div>
    );
  }
)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  flex: 1 1 auto;
  height: 0px;
`;

const SideContent = styled(({ className, details, setShowEditClientModal }) => {
  // State management
  const [open, setOpen] = useState(true);
  const [showPrintLabelModal, setShowPrintLabelModal] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(null);

  // React Query for fetching label data
  const { data: labels } = useQuery({
    initialData: [],
    queryKey: ["labels"],
    queryFn: () => getAllLabelInfo(),
  });

  // Custom hook for permission handling
  const { hasPermission } = usePermissions();

  // Material-UI theme hook for uniform styling
  const theme = useTheme();

  // Memoized preparation of labels for dropdown menu
  const labelItems = useMemo(
    () =>
      labels.map((label) => ({
        ...label,
        label: label.label_name,
        onClick: () => handlePrintLabel(label),
      })),
    [labels]
  );

  // Handler for printing labels
  const handlePrintLabel = (label) => {
    setSelectedLabel(label);
    setShowPrintLabelModal(true);
  };

  // Handler for to open edit client modal
  const handleEdit = () => {
    setShowEditClientModal(true);
  };

  // Preparing client details for display
  const clientInformation = {
    Name: details.name,
    Title: details.title,
    Organization: details.organization,
  };

  // Preparing contact information for display
  const contactInfo = {
    Email: details.email,
    "Office Phone": details.office_number,
    "Mobile Phone": details.mobile_number,
    Address: details.address,
    City: details.city,
    State: details.state,
    Zipcode: details.zipcode,
    Country: details.country,
    "Unique ID": details.unique_id,
  };

  const clientNotes = {
    Notes: details.notes,
  };

  // Memoized JSX for client details
  const ClientDetails = useMemo(() => {
    // Sections for client information, contact information, etc.
    const createSection = (title, data) => (
      <div style={{ margin: "40px 2px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              fontSize: "1rem",
              fontWeight: 700,
              color: theme.palette.text.secondary,
              marginBottom: 10,
            }}
          >
            {title}
          </div>
          {hasPermission() && title === "Client Information" && (
            <div className="action-menu-item" onClick={handleEdit}>
              <EditOutlinedIcon style={{ color: theme.palette.primary.main }} />
              <div className="action-menu-label">Edit</div>
            </div>
          )}
        </div>
        {Object.entries(data).map(([key, value]) => (
          <div className="detail-item" key={nanoid()}>
            <div className="detail-label">{key}</div>
            <div className="detail-value">{value}</div>
          </div>
        ))}
      </div>
    );

    return (
      <div className="client-details">
        {createSection("Client Information", clientInformation)}
        {createSection("Contact Information", contactInfo)}
        <div style={{ margin: "10px 2px" }}>
          <div
            style={{
              fontSize: "1rem",
              fontWeight: 700,
              color: theme.palette.text.secondary,
              margin: "10px 0px",
            }}
          >
            Notes
          </div>
          {details.notes}
        </div>
      </div>
    );
  }, [clientInformation, contactInfo, clientNotes]);

  // Render the side content conditionally based on whether hidden or open
  return (
    <div className={className + (open ? " expanded" : " collapsed")}>
      <div
        className={
          open
            ? "collapse-btn side-content-expanded"
            : "collapse-btn side-content-collapsed"
        }
      >
        {open && <div className="header">Client Details</div>}
        <TaskButton variant="outlined" onClick={() => setOpen(!open)}>
          <KeyboardTabOutlinedIcon style={{ fontSize: 16 }} />
        </TaskButton>
      </div>
      {open && (
        <>
          {hasPermission() && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  margin: "12px 0",
                }}
              >
                <DropdownMenu
                  menuItems={labelItems}
                  title={"Print shipping label"}
                  variant="outlined"
                  arrow
                >
                  Print Label
                </DropdownMenu>
              </div>
            </>
          )}
          {ClientDetails}
        </>
      )}
      <PrinterSelectModal
        open={showPrintLabelModal}
        handleClose={() => setShowPrintLabelModal(false)}
        defaultInfo={{ association: details }}
        label={selectedLabel}
      />
    </div>
  );
})`
  max-width: 325px;
  min-width: 325px;
  min-height: 350px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.palette.divider};

  border-radius: 4px;
  overflow-y: auto;
  overflow-x: auto;
  transition: all 0.1s ease-in-out;

  &.collapsed {
    min-width: 50px;
    max-width: 50px;
  }

  & .collapse-btn {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  & .side-content-expanded {
    svg {
      transform: rotate(180deg);
    }
  }

  & .side-content-collapsed {
    justify-content: center;
  }

  & .header {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 1.1rem;
    font-weight: 500;
    cursor: default;
  }
  & .header-container {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 20px 0px;
  }
  & .action-menu-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
    & svg {
      font-size: 15px;
      margin-right: 5px;
    }
  }
  & .detail-item {
    display: flex;
    align-items: baseline;
    align-content: center;
    margin: 10px 0px;
    font-size: 12px;
  }
  & .detail-label {
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-right: 0px;
    min-width: 120px;
  }
  & .detail-value {
    color: ${({ theme }) => theme.palette.text.primary};
    width: 100%;
    white-space: pre-wrap;
  }
`;

const MenuItems = [
  {
    label: "My Associated Cases",
    value: "my-cases",
  },
  {
    label: "All Associated Cases",
    value: "all-cases",
  },
];

const MainContent = styled(
  ({
    className,
    details,
    handleClientUpdate = () => {},
    showEditClientModal,
    setShowEditClientModal,
  }) => {
    // custom permissions hook to check if user is admin
    const { currentUser } = usePermissions();

    // Determines scope of cases displayed in Client Table
    const [currentView, setCurrentView] = useState(
      JSON.parse(localStorage.getItem("client:view:cases")) || MenuItems[0]
    );

    // State to track if client has associated cases
    const [hasCases, setHasCases] = useState(true);

    // Handler that 'Associated Cases' Header and saves view to local storage
    const handleViewChange = (item) => {
      setCurrentView(item);
      localStorage.setItem("client:view:cases", JSON.stringify(item));
    };

    return (
      <div className={className}>
        <div className="header" style={{ marginBottom: 5 }}>
          <CasesHeader onChange={handleViewChange} currentView={currentView} />
        </div>
        <CasesTable
          client_id={details.client_id}
          setHasCases={setHasCases}
          currentView={currentView}
          currentUser={currentUser}
          stateStoreKey="cases:list:client"
        />
        <EditClientModal
          show={showEditClientModal}
          defaultInfo={details}
          onClose={() => setShowEditClientModal(false)}
          onCancel={() => setShowEditClientModal(false)}
          onSubmit={handleClientUpdate}
        />
      </div>
    );
  }
)`
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 4px;
  flex-grow: 1;
  min-width: 0px;

  display: flex;
  flex-direction: column;

  & .toggle-menu {
    margin: auto;
    margin-bottom: 25px;
    display: flex;
    overflow-x: auto;
  }
  & .header {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 1.1rem;
    font-weight: 500;
  }
  & .description {
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 12px;
    margin-top: 10px;
  }
`;

const CasesHeader = styled(
  ({ className, onChange = () => {}, currentView }) => {
    const theme = useTheme();
    const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);

    const viewAll = hasPermission(MONOLITH_PERMISSIONS.CASES_READ_ALL);

    // Handle opening the dropdown menu
    const handleMenuOpen = (event) => {
      if (viewAll) {
        setAnchorEl(event.currentTarget);
      }
    };

    // Handle closing the dropdown menu
    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    // Handle selecting a view option
    const handleSelect = (item) => {
      handleMenuClose();
      onChange(item);
    };

    return (
      <div className={className}>
        <div
          className={`view-selector ${viewAll ? "admin" : ""}`}
          onClick={handleMenuOpen}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h4">{currentView.label}</Typography>
            {viewAll && (
              <ArrowDropDownOutlinedIcon
                style={{ fontSize: 18, marginLeft: 5 }}
              />
            )}
          </div>
        </div>
        {viewAll && (
          <Menu
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={handleMenuClose}
            onItemSelect={handleSelect}
          >
            {MenuItems.map((item) => (
              <MenuItem
                key={item.value}
                data={item}
                style={{ color: theme.palette.text.primary }}
              >
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        )}
      </div>
    );
  }
)`
  display: flex;
  justify-content: space-between;

  .view-selector {
    border: 1px solid transparent;
    transition: all 0.2s ease;
    width: fit-content;
    user-select: none;
    color: ${({ theme }) => theme.palette.text.primary};
  }

  .view-selector.admin:hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    border-radius: 5px;
  }
`;

export default ClientOverview;
