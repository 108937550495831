import { useState } from "react";
import styled from "styled-components/macro";
import { Outlet } from "react-router-dom";

import { CssBaseline, Paper as MuiPaper } from "@mui/material";

import GlobalStyle from "../components/GlobalStyle";
import dashboardItems from "../components/sidebar/dashboardItems";
import Sidebar from "../components/sidebar/Sidebar";
import Settings from "../components/Settings";
import { usePermissions } from "../hooks/usePermissions";
import { Icon } from "react-feather";

interface DashboardItems {
  pages: SideBarItem[];
}

interface SideBarItemChild {
  href: string;
  title: string;
  permission?: string;
}

interface SideBarItem {
  icon: Icon;
  title: string;
  children?: SideBarItemChild[];
}

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  height: 100%;
`;

const AppContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 0px;
`;

const Paper = styled(MuiPaper)({
  padding: 25,
});

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};
  display: flex;
  flex-direction: column;
  height: 100%;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({
  children,
}: {
  children?: null | undefined | React.ReactNode;
}) => {
  const { licenseInfo, hasPermission } = usePermissions();
  const [settingsState, setSettingsState] = useState({
    isOpen: false,
  });

  if (!licenseInfo) {
    return null;
  }

  const resolveSidebarItems = (items: DashboardItems[]) => {
    const filteredItems = [];
    const filteredPages = [];
    let { pages } = items[0];

    for (const page of pages) {
      if (page.children) {
        const children = [];
        for (const child of page.children) {
          if (!child.permission) {
            children.push(child);
            continue;
          }
          if (hasPermission(child.permission)) {
            children.push(child);
          }
        }
        if (children.length > 0) {
          filteredPages.push({ ...page, children });
        }
      } else {
        filteredPages.push({ ...page });
      }
    }

    filteredItems.push({ pages: filteredPages });

    return filteredItems;
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Sidebar
        PaperProps={{ style: { width: drawerWidth } }}
        width={drawerWidth}
        settingsState={settingsState}
        setSettingsState={setSettingsState}
        items={resolveSidebarItems(dashboardItems as DashboardItems[])}
      />
      <AppContent>
        <MainContent>
          {children}
          <Outlet />
        </MainContent>
      </AppContent>
      <Settings
        settingsState={settingsState}
        setSettingsState={setSettingsState}
      />
    </Root>
  );
};

export default Dashboard;
