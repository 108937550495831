import { createElement } from "react";

interface HtmlParserProps {
  htmlContent: string;
}

// Parse HTML string and convert it to React nodes
const parseHTML = (html: string): React.ReactNode[] => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  // Recursively convert DOM nodes to React elements
  const convertNodeToReact = (
    node: Node,
    key: number | string
  ): React.ReactNode => {
    // Handle text nodes
    if (node.nodeType === Node.TEXT_NODE) {
      return node.textContent;
    }

    // Handle element nodes
    if (node.nodeType === Node.ELEMENT_NODE && node instanceof Element) {
      // Convert child nodes recursively
      const children = Array.from(node.childNodes).map((child, index) =>
        convertNodeToReact(child, index)
      );

      // Create React element
      return createElement(
        node.tagName.toLowerCase(),
        { key, ...getAttributes(node) },
        ...children
      );
    }

    return null;
  };

  // Extract attributes from a DOM element
  const getAttributes = (node: Element): { [key: string]: string } => {
    const attributes: { [key: string]: string } = {};
    Array.from(node.attributes).forEach((attr) => {
      attributes[attr.name] = attr.value;
    });
    return attributes;
  };

  // Convert all child nodes of the body to React elements
  return Array.from(doc.body.childNodes).map((node, index) =>
    convertNodeToReact(node, index)
  );
};

// Renders HTML string as React elements
const HtmlParser: React.FC<HtmlParserProps> = ({ htmlContent }) => {
  const reactElements = parseHTML(htmlContent);
  return <>{reactElements}</>;
};

export default HtmlParser;
