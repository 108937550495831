import validateAsync from "@/utils/ZodHelpers/validateAsync";
import { z } from "zod";
import { useForm } from "@mantine/form";
import {
  Button,
  FormSection,
  Grid,
  TextAreaInput,
  TextInput,
} from "@monolith-forensics/monolith-ui";
import { FC } from "react";
import ButtonMenu from "./components/ButtonMenu";
import OrganizationSelector from "./components/Selectors/OrganizationSelector";
import ContactTypeSelector from "./components/Selectors/ContactTypeSelector";
import diffFormData from "./utils/diffFormData";
import { Contact } from "@/types";

export interface ContactFormData
  extends Omit<Contact, "client_id" | "isContact">,
    Partial<Pick<Contact, "client_id" | "isContact">> {}

export interface EditContactFormData extends Partial<ContactFormData> {}

interface DefaultContactFormProps {
  defaultFormData?: ContactFormData;
  buttonProps?: { cancelText?: string; submitText?: string };
  onSubmit: (data: ContactFormData, diffData?: EditContactFormData) => void;
  onCancel: () => void;
}
const contactValidationSchema = z.object({
  name: z
    .string({
      required_error: "Contact name is required",
    })
    .min(1, { message: "Contact name is required" }),
  title: z.string().nullable().optional(),
  organization: z.string().nullable().optional(),
  email: z
    .union([z.string().email(), z.string().max(0)])
    .nullable()
    .optional(),
  office_number: z.string().nullable().optional(),
  mobile_number: z.string().nullable().optional(),
  unique_id: z.string().nullable().optional(),
  contact_type: z.string().nullable().optional(),
  notes: z.string().nullable().optional(),
  address: z.string().nullable().optional(),
  city: z.string().nullable().optional(),
  state: z.string().nullable().optional(),
  zipcode: z.string().nullable().optional(),
  country: z.string().nullable().optional(),
});

const DefaultContactForm: FC<DefaultContactFormProps> = ({
  defaultFormData,
  buttonProps,
  onSubmit,
  onCancel,
}) => {
  const form = useForm<ContactFormData>({
    mode: "uncontrolled",
    initialValues: defaultFormData,
  });
  const handleSubmit = async () => {
    const formData = form.getValues();

    const validationResult = await validateAsync(contactValidationSchema)(
      formData
    );
    if (validationResult?.hasErrors) {
      console.error("Validation Errors", validationResult?.errors);
      form.setErrors(validationResult?.errors);
      return;
    }

    const diffData = diffFormData(defaultFormData, formData);
    onSubmit?.(formData, diffData);
  };
  const handleCancel = () => {
    form.reset();
    onCancel?.();
  };

  return (
    <>
      <Grid col={2}>
        <TextInput
          size="sm"
          variant="outlined"
          label="Name"
          required={true}
          placeholder="Enter contact's name"
          description="The name of the contact"
          key={form.key("name")}
          {...form.getInputProps("name")}
        />
        <TextInput
          size="sm"
          variant="outlined"
          label="Title"
          placeholder="Enter contact's title"
          description="The title of the contact"
          key={form.key("title")}
          {...form.getInputProps("title")}
        />
        <OrganizationSelector
          defaultValue={defaultFormData?.organization}
          queryKey="contacts"
          key={form.key("organization")}
          {...form.getInputProps("organization")}
        />
        <TextInput
          size="sm"
          variant="outlined"
          label="Email"
          placeholder="Enter contact's email"
          description="The email of the contact"
          key={form.key("email")}
          {...form.getInputProps("email")}
        />
        <TextInput
          size="sm"
          variant="outlined"
          label="Office Number"
          placeholder="Enter contact's office number"
          description="The office number of the contact"
          key={form.key("office_number")}
          {...form.getInputProps("office_number")}
        />
        <TextInput
          size="sm"
          variant="outlined"
          label="Mobile Number"
          placeholder="Enter contact's cellphone number"
          description="The mobile number of the contact"
          key={form.key("mobile_number")}
          {...form.getInputProps("mobile_number")}
        />
        <TextInput
          size="sm"
          variant="outlined"
          label="Unique ID"
          placeholder="Enter contact's unique ID"
          description="The unique ID of the contact"
          key={form.key("unique_id")}
          {...form.getInputProps("unique_id")}
        />
        <ContactTypeSelector
          defaultValue={defaultFormData?.contact_type}
          key={form.key("contact_type")}
          {...form.getInputProps("contact_type")}
        />
      </Grid>
      <div style={{ marginTop: "1rem" }}>
        <TextAreaInput
          size="sm"
          variant="outlined"
          label="Notes"
          placeholder="Enter notes on this contact"
          description="Any additional notes on this contact"
          key={form.key("notes")}
          {...form.getInputProps("notes")}
        />
      </div>
      <FormSection title="Mailing Address" defaultOpen={false}>
        <Grid col={2}>
          <TextInput
            size="sm"
            variant="outlined"
            label="Address"
            placeholder="Enter contact's address"
            description="The address of the contact"
            key={form.key("address")}
            {...form.getInputProps("address")}
          />
          <TextInput
            size="sm"
            variant="outlined"
            label="City"
            placeholder="Enter contact's city"
            description="Mailing Address City"
            key={form.key("city")}
            {...form.getInputProps("city")}
          />
          <TextInput
            size="sm"
            variant="outlined"
            label="State"
            placeholder="Enter contact's state"
            description="Mailing Address state"
            key={form.key("state")}
            {...form.getInputProps("state")}
          />
          <TextInput
            size="sm"
            variant="outlined"
            label="Postal code"
            placeholder="Enter contact's postal code"
            description="Mailing Address postal code"
            key={form.key("zipcode")}
            {...form.getInputProps("zipcode")}
          />
          <TextInput
            size="sm"
            variant="outlined"
            label="Country"
            placeholder="Enter contact's country"
            description="Mailing Address country"
            key={form.key("country")}
            {...form.getInputProps("country")}
          />
        </Grid>
      </FormSection>
      <ButtonMenu>
        <Button size="xs" variant="subtle" onClick={handleCancel}>
          {buttonProps?.cancelText || "Cancel"}
        </Button>
        <Button
          size="xs"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          {buttonProps?.submitText || "Submit"}
        </Button>
      </ButtonMenu>
    </>
  );
};

export default DefaultContactForm;
