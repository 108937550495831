import styled, { useTheme } from "styled-components";
import { usePermissions } from "../../../hooks/usePermissions";
import { useMemo, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import TasksAPI from "../../../api/tasks/tasks.js";
import { db_timestamp } from "../../../utils/date-format";
import TimeEntriesAPI from "../../../api/TimeEntries/time-entries.js";
import moment from "moment";
import TaskDueDateOptions from "./TaskDueDateOptions.js";
import TaskButton from "../../../Monolith-UI/TaskButton.js";
import Calendar from "../../../Monolith-UI/Calendar/Calendar.js";
import {
  ArchiveIcon,
  ArrowLeftRightIcon,
  CalendarIcon,
  Clock,
  ShapesIcon,
  SignalIcon,
  Trash2Icon,
  UsersIcon,
  WorkflowIcon,
} from "lucide-react";
import TaskStatuses from "./TaskStatuses.js";
import TaskPriorities from "./TaskPriorities.js";
import UserApi from "../../../api/users/users.js";
import getRandomColor from "../../../utils/getRandomColor.js";
import TaskDurationOptions from "./TaskDurationOptions.js";
import ContextMenu from "../../../Monolith-UI/ContextMenu/ContextMenu.js";
import { Link } from "react-router-dom";
import LinkedObject from "./LinkedObject.js";
import TaskProperties from "./TaskProperties.js";
import TaskStatusSelector from "./TaskStatusSelector.js";
import TaskPrioritySelector from "./TaskPrioritySelector.js";
import TaskDuedateSelector from "./TaskDueDateSelector.js";
import SubTasksIndicator from "./SubTasksIndicator.js";
import TaskDurationSelector from "./TaskDurationSelector.js";
import AssigneeSelector from "./AssigneeSelector.js";
import DeleteTaskModal from "./DeleteTaskModal.js";
import AddCustomTimeModal from "./AddCustomTimeModal.js";

const TaskName = styled.div`
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 16px;
  // line clamp at 2 lines
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;
  padding-right: 65px;
`;

const Task = styled(
  ({
    className,
    task,
    displayPreferences,
    onItemUpdate,
    onItemRemove,
    onItemArchived,
    itemHref = () => {},
  }) => {
    const theme = useTheme();
    const { currentUser } = usePermissions();
    const [showCustomTimeModal, setShowCustomTimeModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [contextMenuOpen, setContextMenuOpen] = useState(false);
    const dueDate = task?.due_date || null;
    const queryClient = useQueryClient();

    const TaskCategories = queryClient.getQueryData(["task:categories"]);

    const caseUsers = queryClient.getQueryData([
      "users",
      "list",
      { include_observers: false, include_inactive: false },
    ]);

    const handleStatusChange = (status) => {
      onItemUpdate?.({
        status_id: status.status_id,
        uuid: task.uuid,
      });

      TasksAPI.updateTask({
        status_id: status.status_id,
        uuid: task.uuid,
      });
    };

    const handlePriorityChange = (priority) => {
      onItemUpdate?.({
        priority_id: priority.priority_id,
        uuid: task.uuid,
      });

      TasksAPI.updateTask({
        priority_id: priority.priority_id,
        uuid: task.uuid,
      });
    };

    const handleDueDateChange = (value) => {
      const duedate = value?.value || null;

      onItemUpdate?.({
        due_date: duedate,
        uuid: task.uuid,
      });

      TasksAPI.updateTask({
        due_date: duedate,
        uuid: task.uuid,
      });
    };

    const handleDurationChange = (item) => {
      if (item.duration === 0) {
        setShowCustomTimeModal(true);
        return;
      }
      let newDuration = (task?.duration || 0) + item.duration;

      onItemUpdate?.({
        duration: newDuration,
        uuid: task.uuid,
      });

      const currentTime = db_timestamp();

      //Create Time entry in API
      TimeEntriesAPI.createTimeEntry({
        task_id: task.task_id,
        case_id: task.case_id,
        user_id: currentUser.user_id,
        entry_date: currentTime,
        start_time: moment(currentTime)
          .subtract(item.duration, "s")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_time: currentTime,
        duration: item.duration,
        is_bulk: 1,
        category_id: task.time_category_id || null,
      });
    };

    const handleCategoryChange = (category) => {
      onItemUpdate?.({
        time_category_id: category.category_id,
        uuid: task.uuid,
      });

      TasksAPI.updateTask({
        time_category_id: category.category_id,
        uuid: task.uuid,
      });
    };

    const handleAssigneeChange = ({ selections }) => {
      const currentAssignees = task?.assignees || [];

      const selectedUsers = selections
        .map((selection) => selection.value)
        .map((user_id) =>
          caseUsers.find((u) => {
            return u.user_id === user_id;
          })
        );

      const newAssignees = selectedUsers.filter(
        (user) => !currentAssignees.map((a) => a.user_id).includes(user.user_id)
      );

      const removedAssignees = currentAssignees.filter(
        (user) => !selectedUsers.map((u) => u.user_id).includes(user.user_id)
      );

      onItemUpdate?.({
        assignees: selectedUsers,
        uuid: task.uuid,
      });

      if (removedAssignees.length > 0) {
        TasksAPI.removeUsersFromTask({
          users: removedAssignees.map((a) => a.user_id),
          task_id: task.task_id,
          task_uuid: task.uuid,
          removed_by_id: currentUser.user_id,
        });
      }

      if (newAssignees.length > 0) {
        TasksAPI.assignUsersToTask({
          users: newAssignees.map((a) => a.user_id),
          task_id: task.task_id,
          task_uuid: task.uuid,
          assigned_by_id: currentUser.user_id,
        });
      }
    };

    const handleArchiveTask = (taskdata) => {
      TasksAPI.archiveTasks({ uuids: [taskdata.uuid] });

      onItemArchived?.({ task: taskdata });
    };

    const handleDeleteTask = (taskData) => {
      TasksAPI.deleteTask({ task_id: taskData.task_id });
      onItemRemove?.({ task: taskData });
    };

    const DueDateContent = useMemo(() => {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 15,
            marginTop: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: 5,
            }}
          >
            {TaskDueDateOptions.filter((item) => item?.getDate).map(
              (item, index) => {
                return (
                  <TaskButton
                    key={index}
                    variant="outlined"
                    onClick={() => {
                      handleDueDateChange?.({
                        label: item.getLabel(),
                        value: item.getValue(),
                      });
                    }}
                    style={{
                      fontSize: "0.65rem",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    {item.label}
                  </TaskButton>
                );
              }
            )}
          </div>
          <div style={{ margin: "auto" }}>
            <Calendar
              key={1}
              defaultValue={
                dueDate
                  ? moment(
                      Array.isArray(dueDate) ? dueDate[0] : dueDate
                    ).toDate()
                  : undefined
              }
              onChange={(date) => {
                handleDueDateChange?.(
                  date
                    ? {
                        label: moment(date).format("MMM DD"),
                        value: moment(date).format("YYYY-MM-DD"),
                      }
                    : null
                );
              }}
              includeTime={false}
            />
          </div>
        </div>
      );
    }, [dueDate]);

    const contextMenuItems = [
      {
        label: "Status",
        value: "status",
        icon: ArrowLeftRightIcon,
        items: [
          {
            radioGroup: true,
            value: task?.status_id,
            onSelectionChanged: (value) => {
              handleStatusChange(
                TaskStatuses.find((s) => s.status_id === value.value)
              );
            },
            items: TaskStatuses.map((status) => ({
              label: status.status_name,
              value: status.status_id,
              icon: status.icon,
              iconColor: status.color,
            })),
          },
        ],
      },
      {
        label: "Priority",
        value: "priority",
        icon: SignalIcon,
        items: [
          {
            radioGroup: true,
            value: task?.priority_id,
            onSelectionChanged: (value) => {
              handlePriorityChange(
                TaskPriorities.find((p) => p.priority_id === value.value)
              );
            },
            items: TaskPriorities.map((priority) => ({
              label: priority.priority_name,
              value: priority.priority_id,
              icon: priority.icon,
              iconColor: priority.color,
            })),
          },
        ],
      },
      {
        label: "Assignees",
        value: "assignees",
        icon: UsersIcon,
        items: [
          {
            checkboxGroup: true,
            value: task?.assignees?.map((a) => a.user_id) || [],
            onSelectionChanged: (value) => {
              handleAssigneeChange({
                selections: value,
              });
            },
            queryKey: [
              "users",
              "list",
              {
                include_observers: false,
                include_inactive: false,
                case_id: task?.case_id,
              },
            ],
            items: async () => {
              const result = await UserApi.getUsers({
                include_observers: false,
                include_inactive: false,
                case_id: task?.case_id,
              });

              return result?.map((user) => ({
                label: user.full_name,
                value: user.user_id,
                icon: ({ color, style }) => (
                  <div
                    style={{
                      borderRadius: "50%",
                      height: 10,
                      width: 10,
                      backgroundColor: color,
                      ...style,
                    }}
                  ></div>
                ),
                iconColor: getRandomColor(user.user_id, user.user_id),
              }));
            },
          },
        ],
      },
      {
        label: "Due Date",
        value: "due_date",
        icon: CalendarIcon,
        content: DueDateContent,
      },
      {
        label: "Category",
        value: "category",
        icon: ShapesIcon,
        items: [
          {
            radioGroup: true,
            value: task?.time_category_id,
            onSelectionChanged: (value) => {
              handleCategoryChange(
                TaskCategories.find((s) => s.category_id === value.value)
              );
            },
            items:
              TaskCategories?.map((cat) => ({
                label: cat.category_name,
                value: cat.category_id,
                icon: ({ color, style }) => (
                  <div
                    style={{
                      borderRadius: "50%",
                      height: 10,
                      width: 10,
                      backgroundColor: color,
                      ...style,
                    }}
                  ></div>
                ),
                iconColor: getRandomColor(cat.category_id, cat.category_id),
              })) || [],
          },
        ],
      },
      {
        label: "Add Time",
        value: "add_time",
        icon: Clock,
        items: TaskDurationOptions.map((option) => ({
          label: option.label,
          value: option.duration,
          icon: option.icon,
          onClick: () => {
            handleDurationChange(option);
          },
        })),
      },
      {
        label: "Archive Task",
        icon: ArchiveIcon,
        value: "archive",
        menuItemProps: { style: { color: theme.palette.primary.main } },
        onClick: () => handleArchiveTask(task),
      },
      {
        separator: true,
      },
      {
        label: "Delete Task",
        icon: Trash2Icon,
        value: "delete",
        menuItemProps: { style: { color: "orangered" } },
        onClick: () => setShowDeleteModal(true),
      },
    ];

    return (
      <>
        <ContextMenu
          menuItems={contextMenuItems}
          contentProps={{
            style: { width: 150 },
          }}
          onOpenChange={(open) => setContextMenuOpen(open)}
        >
          <Link to={itemHref?.(task) || ""}>
            <div
              className={className}
              data-id={task.uuid}
              data-menu-open={contextMenuOpen}
            >
              {displayPreferences?.showLinkedObject && (
                <LinkedObject
                  object={{
                    type: task.object_type,
                    name: task.object_name,
                    id: task.object_id,
                    object: task,
                  }}
                  href={`/cases/${task.case_id}/tasks`}
                />
              )}
              <TaskName title={`${task.task_name}\n${task.description}`}>
                {task.task_name}
              </TaskName>
              <TaskProperties>
                {displayPreferences?.showStatus && (
                  <TaskStatusSelector
                    value={TaskStatuses.find(
                      (s) => s.status_id === task.status_id
                    )}
                    showLabel={false}
                    variant="outlined"
                    onSelect={handleStatusChange}
                  />
                )}
                {displayPreferences?.showPriority && (
                  <TaskPrioritySelector
                    value={TaskPriorities.find(
                      (s) => s.priority_id === task.priority_id
                    )}
                    showLabel={false}
                    variant="outlined"
                    onSelect={handlePriorityChange}
                  />
                )}
                {displayPreferences?.showDueDate && (
                  <TaskDuedateSelector
                    variant="outlined"
                    value={task?.due_date || null}
                    onSelect={handleDueDateChange}
                    buttonProps={{
                      textColor: "secondary",
                    }}
                  />
                )}
                {displayPreferences?.showSubTaskCount &&
                  task?.subtasks &&
                  task?.subtasks?.length > 0 && (
                    <SubTasksIndicator tasks={task.subtasks} />
                  )}
                {displayPreferences?.showSubTaskIcon && task?.parent_id && (
                  <div
                    title={"This is a subtask"}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: `1px solid ${theme.palette.divider}`,
                      borderRadius: 4,
                      padding: "3px",
                    }}
                  >
                    <WorkflowIcon
                      size={14}
                      color={theme.palette.text.secondary}
                    />
                  </div>
                )}
                {displayPreferences?.showDuration && (
                  <TaskDurationSelector
                    value={task?.duration}
                    showLabel={true}
                    variant="outlined"
                    onSelect={handleDurationChange}
                    buttonProps={{
                      textColor: "secondary",
                    }}
                  />
                )}
                {displayPreferences?.showAssignees && (
                  <div
                    style={{
                      position: "absolute",
                      right: 10,
                      top: 5,
                    }}
                  >
                    <AssigneeSelector
                      value={task?.assignees?.map((a) => a.user_id) || null}
                      task={task}
                      defaultInfo={task?.assignees || []}
                      showLabel={true}
                      variant="outlined"
                      onSelect={handleAssigneeChange}
                      userQuery={{
                        case_id: task?.case_id,
                        include_inactive: false,
                        include_observers: false,
                      }}
                    />
                  </div>
                )}
              </TaskProperties>
            </div>
          </Link>
        </ContextMenu>
        {showDeleteModal && (
          <DeleteTaskModal
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            task={task}
            onDelete={handleDeleteTask}
          />
        )}
        {showCustomTimeModal && (
          <AddCustomTimeModal
            open={showCustomTimeModal}
            onClose={() => setShowCustomTimeModal(false)}
            onSubmit={(formData) => {
              onItemUpdate?.({
                duration: task.duration + formData.duration,
                uuid: task.uuid,
              });
            }}
            defaultFormData={{
              task: task,
              task_id: task.task_id,
              task_name: task.task_name,
              case_id: task.case_id,
              case_number: task.case_number,
              user_id: currentUser.user_id,
              duration: 0,
              billed: 0,
              category_id: task.time_category_id || null,
              category: TaskCategories.find(
                (c) => c.category_id === task.time_category_id
              ),
              start_time: moment().toISOString(),
              end_time: moment().add(1, "h").toISOString(),
            }}
          />
        )}
      </>
    );
  }
)`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px 10px;

  &[data-menu-open="true"] {
    background-color: ${({ theme }) =>
      theme.name === "DARK" ? "#313131" : "#f5f5f5"};
  }
`;

Task.displayName = "Task";

export default Task;
