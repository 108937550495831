import { Card, IconButton, Tooltip } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import { DataGrid } from "devextreme-react/ui/data-grid.js";
import { getAcquisitionReport } from "../../../../api/index.js";
import Loader from "../../../../components/Loader.js";
import { getDateFormat } from "../../../../utils/date-format";

const AcquisitionGrid = ({ reportInfo }) => {
  const reportGrid = useRef(null);
  const queryClient = useQueryClient();

  return (
    <Card
      style={{
        padding: "20px",
        marginBottom: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            fontSize: "larger",
          }}
        >
          Acquisitions
        </div>
        <div style={{ marginLeft: "auto" }}>
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              marginRight: 10,
            }}
          >
            <Tooltip arrow title="Export Grid">
              <IconButton
                color="primary"
                size="small"
                disableRipple={false}
                onClick={(e) => {
                  reportGrid.current.instance.exportToExcel();
                }}
              >
                <FileDownloadOutlinedIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Refresh">
              <IconButton
                color="primary"
                size="small"
                disableRipple={false}
                onClick={(e) => {
                  queryClient.refetchQueries({
                    queryKey: ["reports:acquisition-items", reportInfo],
                    active: true,
                    exact: true,
                  });
                }}
              >
                <SyncOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Column Select">
              <IconButton
                color="primary"
                size="small"
                disableRipple={false}
                onClick={(e) => {
                  reportGrid.current.instance.showColumnChooser();
                }}
              >
                <ViewColumnOutlinedIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      <Table reportGrid={reportGrid} reportInfo={reportInfo} />
    </Card>
  );
};

const Table = ({ reportGrid, reportInfo }) => {
  const {
    isLoading,
    isFetching,
    data: acquisitions,
  } = useQuery({
    queryKey: ["reports:acquisition-items", reportInfo],
    queryFn: () => getAcquisitionReport(reportInfo.report_parameters || {}),
  });

  if (isLoading)
    return (
      <div style={{ marginTop: 30 }}>
        <Loader message="Retrieving Data..." />
      </div>
    );

  return (
    <DataGrid
      ref={reportGrid}
      dataSource={acquisitions}
      height="350px"
      columns={[
        {
          dataField: "acq_name",
          caption: "Image Name",
        },
        {
          dataField: "evidence_number",
          caption: "Evidence",
        },
        {
          dataField: "case_number",
          caption: "Case Number",
          visible: false,
        },
        {
          dataField: "case_ref",
          caption: "Case Name",
          visible: false,
        },
        {
          dataField: "evidence_id",
          visible: false,
          showInColumnChooser: false,
          allowSearch: false,
        },
        {
          dataField: "format",
          caption: "Format",
        },
        {
          dataField: "size",
          caption: "Size",
          alignment: "left",
          headerFilter: { groupInterval: 100 },
          calculateSortValue: (rowData) => {
            return rowData.size_bytes;
          },
          cellTemplate: (container, options) => {
            if (options.data.size && options.data.size_unit) {
              container.innerHTML =
                options.data.size + " " + options.data.size_unit;
            }
          },
        },
        {
          dataField: "size_unit",
          visible: false,
          showInColumnChooser: false,
          allowSearch: false,
        },
        {
          dataField: "acq_type",
          caption: "Method",
        },
        {
          dataField: "acquire_date",
          caption: "Acquire Date",
          dataType: "datetime",
          cssClass: "monolithHeader",
          format: {
            type: getDateFormat({ isMoment: false, includeTime: true }),
          },
        },
        {
          dataField: "acquired_by",
          caption: "Acquired By",
          visible: false,
        },
        {
          dataField: "acq_tool",
          caption: "Tool",
          setCellValue: function (rowData, value) {
            rowData.acq_tool = value;
            rowData.tool_version = null;
          },
        },
        {
          dataField: "tool_version",
          visible: false,
        },
        {
          dataField: "storage_number",
          caption: "Storage",
        },
        {
          dataField: "storage_id",
          captions: "Storage",
          visible: false,
          allowSearch: false,
          showInColumnChooser: false,
        },
        {
          dataField: "hash_1",
          visible: false,
        },
        {
          dataField: "hash_1_type",
          caption: "Hash 1 Type",
          visible: false,
        },
        {
          dataField: "hash_2",
          visible: false,
        },
        {
          dataField: "hash_2_type",
          visible: false,
        },
        {
          dataField: "duration_hours",
          caption: "Duration Hours",
          visible: false,
        },
        {
          dataField: "duration_mins",
          caption: "Duration Mins",
          visible: false,
        },
        {
          dataField: "contact",
          caption: "Linked Contact",
          visible: false,
        },
        {
          dataField: "acq_notes",
          caption: "Notes",
          visible: false,
        },
        {
          dataField: "item_type",
          caption: "Evidence Type",
          visible: false,
        },
        {
          dataField: "manufacturer",
          caption: "Evidence Make/ISP",
          visible: false,
        },
        {
          dataField: "model_name",
          caption: "Evidence Name",
          visible: false,
        },
        {
          dataField: "model_number",
          caption: "Evidence Model Number",
          visible: false,
        },
        {
          dataField: "serial_number",
          caption: "Evidence SN/Account",
          visible: false,
        },
        {
          dataField: "evidence_size",
          caption: "Evidence Size",
          visible: false,
        },
        {
          dataField: "client_name",
          caption: "Client Name",
          visible: false,
        },
        {
          dataField: "client_organization",
          caption: "Client Organization",
          visible: false,
        },
      ]}
      showRowLines={true}
      allowColumnReordering={true}
      allowColumnResizing={true}
      onToolbarPreparing={(e) => {
        e.toolbarOptions.visible = false;
      }}
      paging={{
        pageSize: 20,
      }}
      stateStoring={{
        enabled: true,
        type: "localStorage",
        storageKey: "acq.reportDetails-grid",
        savingTimeout: 10,
      }}
      scrolling={{
        mode: "virtual",
        rowRenderingMode: "virtual",
        useNative: false,
      }}
      headerFilter={{
        visible: true,
        allowSearch: true,
      }}
      hoverStateEnabled={true}
      columnChooser={{
        enabled: true,
        mode: "select",
        title: "Filter Report Columns",
        height: "700",
        width: "325",
      }}
      loadPanel={{
        enabled: false,
      }}
    />
  );
};

export default AcquisitionGrid;
