import styled from "styled-components";

const NoteTitle = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  outline: 1px solid transparent;
  border-radius: 2px;
  border: none;
  max-width: 815px;
  background-color: ${(props) => props.theme.palette.background.default};
  padding: 2px 4px;
  text-align: center;
  font-weight: 600;
  font-size: 12px;

  box-sizing: border-box;

  wrap: break-word;
  wrap-word: break-word;

  &:hover {
    outline: 1px solid ${(props) => props.theme.palette.divider};
  }

  &:focus {
    outline: 1px solid ${(props) => props.theme.palette.primary.main};
  }
`;

export default NoteTitle;
