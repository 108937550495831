import { z } from "zod";
import {
  Button,
  Grid,
  TextAreaInput,
  TextInput,
} from "@monolith-forensics/monolith-ui";
import ButtonMenu from "./components/ButtonMenu.js";
import { useForm, zodResolver } from "@mantine/form";
import diffFormData from "./utils/diffFormData.js";
import shortUUID from "short-uuid";

const UserRoleSchema = z.object({
  name: z.string().min(1),
  description: z.string().optional(),
});

const DefaultUserRoleForm = ({
  defaultFormData = {},
  buttonProps = {},
  onSubmit,
  onCancel,
  onChange,
}) => {
  const form = useForm({
    mode: "uncontrolled",
    initialValues: { uuid: shortUUID.generate(), ...defaultFormData },
    validate: zodResolver(UserRoleSchema),
    onValuesChange: (values) => {
      onChange?.(values);
    },
  });

  const handleSubmit = async () => {
    const validateResult = form.validate();

    if (validateResult.hasErrors) {
      console.error(validateResult.errors);
      return;
    }

    const formData = form.getValues();

    const diffData = diffFormData(defaultFormData, formData);

    // call onSubmit with modeled data and diff data
    // diff data is used when the form is in edit context
    // diff may be used when we only want to update modified values
    onSubmit?.(formData, diffData);
  };

  const handleCancel = () => {
    form.reset();
    onCancel?.();
  };

  return (
    <>
      <Grid col={1} style={{ marginBottom: 10 }}>
        <TextInput
          size="sm"
          variant="outlined"
          label="Role Name"
          description="Provide the name of the role."
          placeholder="Auditors, Admins, etc."
          required
          key={form.key("name")}
          {...form.getInputProps("name")}
        />
        <TextAreaInput
          size="sm"
          variant="outlined"
          label="Description"
          placeholder="Enter user role description"
          description={"Provide a brief description of the role."}
          minRows={8}
          maxRows={24}
          key={form.key("description")}
          {...form.getInputProps("description")}
        />
      </Grid>
      <ButtonMenu>
        <Button size="xs" variant="subtle" onClick={handleCancel}>
          {buttonProps.cancelText || "Cancel"}
        </Button>
        <Button
          size="xs"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          {buttonProps.submitText || "Submit"}
        </Button>
      </ButtonMenu>
    </>
  );
};

export default DefaultUserRoleForm;
