/* eslint-disable no-unused-expressions */
import RGL, { WidthProvider } from "react-grid-layout";
import styled from "styled-components";

const ReactGridLayout = WidthProvider(RGL);

const MainGridLayout = ({ layout = [], widgets = [], onLayoutChange }) => {
  return (
    <ReactGridLayout
      layout={layout}
      className="layout"
      rowHeight={30}
      cols={12}
      margin={[13, 13]}
      isDraggable={true}
      isResizable={true}
      onLayoutChange={onLayoutChange}
      draggableHandle=".widgetHandle"
    >
      {widgets.map((item) => {
        return (
          <div key={item.layout.i}>
            <StyledWidget item={item}>{item.widget}</StyledWidget>
          </div>
        );
      })}
    </ReactGridLayout>
  );
};

const Widget = ({ className, item, children }) => {
  return (
    <div className={className}>
      <div className="widget-container">
        {item.title && (
          <div className="widget-title widgetHandle">{item.title}</div>
        )}
        {children}
      </div>
    </div>
  );
};

const StyledWidget = styled(Widget)`
  display: flex;
  height: 100%;

  border: 1px solid ${(props) => props.theme.palette.divider};

  border-radius: 8px;
  padding: 10px;
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  &:hover {
    cursor: pointer;
  }

  .widget-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  .widget-title {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px;
    user-select: none;
  }
`;

export default MainGridLayout;
