import { safeParseJSON } from "./File-parsers/safeParseJSON.js";
import { monolithMoment } from "./date-format";

const resolveCustomFieldValue = (customField) => {
  const editorType = customField?.editor_type;

  switch (editorType) {
    case "textBox":
      return customField?.value || "";
    case "dateBox":
      return customField?.value
        ? monolithMoment({ timestamp: customField?.value })
        : "";
    case "dropDown":
      return customField?.value || "";
    case "tagBox":
      if (customField?.value) {
        const parsedValue = safeParseJSON(customField?.value) || [];
        return Array.isArray(parsedValue)
          ? parsedValue.map((v) => v).join(", ")
          : parsedValue;
      }
      return "";
    default:
      return customField?.value || "";
  }
};

export default resolveCustomFieldValue;
