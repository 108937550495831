import QaChecklistAPI from "../../api/quality_assurance/checklists.js";
import { Modal } from "@monolith-forensics/monolith-ui";
import DefaultQaChecklistItemForm from "../Forms/DefaultQaChecklistItemForm.js";

const EditQaChecklistItemModal = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  ...props
}) => {
  const handleSubmit = async (data, diff) => {
    await QaChecklistAPI.ChecklistItems.editCheckListItem({
      checklist_id: data.checklist_id,
      item_id: data.item_id,
      ...diff,
    });
    onSubmit?.(data);
  };

  return (
    <Modal open={show} onClose={onClose} style={{ width: 450 }}>
      <Modal.Title>Edit QA Checklist Item</Modal.Title>
      <Modal.SubTitle style={{ marginBottom: 15 }}>
        Edit this Quality Assurance checklist item.
      </Modal.SubTitle>
      <DefaultQaChecklistItemForm
        onSubmit={handleSubmit}
        onCancel={onCancel}
        {...props}
      />
    </Modal>
  );
};

export default EditQaChecklistItemModal;
