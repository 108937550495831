import { httpGet, httpPost } from "../connection.js";

const UserGroupsAPI = {};

// Get user groups list
UserGroupsAPI.get = function (values = {}) {
  return httpGet("/user-groups", values);
};

// Create new user group
UserGroupsAPI.create = function (values = {}) {
  return httpPost("/user-groups", values);
};

// Update selected user group
UserGroupsAPI.update = function (values = {}) {
  return httpPost("/user-groups/update", values);
};

// Delete selected user group
UserGroupsAPI.delete = function (values = {}) {
  return httpPost("/user-groups/delete", values);
};

// assign user or users to selected user group
UserGroupsAPI.assign = function (values = {}) {
  return httpPost("/user-groups/assign", values);
};

// Remove user or users from selected user group
UserGroupsAPI.unassign = function (values = {}) {
  return httpPost("/user-groups/unassign", values);
};

export default UserGroupsAPI;
