import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import DropdownMenu from "../../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import { usePermissions } from "../../../hooks/usePermissions";
import TasksAPI from "../../../api/tasks/tasks.js";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { useEffect, useRef, useState } from "react";
import { ListTodoIcon } from "lucide-react";

const PageSize = 100;

const TaskSelector = ({
  value,
  defaultQuery = {},
  onSelect,
  showLabel = true,
  variant = "contained",
  disabled = false,
  error = false,
}) => {
  const { currentUser } = usePermissions();
  const firstRender = useRef(true);
  const [selectedTask, setSelectedTask] = useState(value);
  const [search, setSearch] = useState("");
  const [searchValue] = useDebounce(search, 300);

  const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: [
        "tasks",
        "inf-list",
        {
          user_id: defaultQuery?.case_id ? null : currentUser.user_id,
          case_id: defaultQuery?.case_id,
          search: searchValue,
          pageSize: PageSize,
        },
      ],
      queryFn: ({ pageParam }) =>
        TasksAPI.getTasks(
          {
            user_id: defaultQuery?.case_id ? null : currentUser.user_id,
            case_id: defaultQuery?.case_id,
            search: searchValue,
            pageSize: PageSize,
            page: pageParam,
          } || {}
        ),
      getNextPageParam: (lastPage, pages) => {
        return lastPage.nextPage;
      },
      getPreviousPageParam: (firstPage, pages) => {
        if (firstPage.page - 1 === 0) return null;
        return firstPage.page - 1;
      },
      initialPageParam: 1,
      enabled: !!defaultQuery,
      placeholderData: (data) => data,
    });

  const records = data?.pages?.reduce((acc, page) => {
    return [...acc, ...page.data];
  }, []);

  const debouncedFetchNextPage = useDebouncedCallback((e) => {
    if (isFetchingNextPage) return;
    if (!hasNextPage) return;
    fetchNextPage();
  }, 50);

  const menuItems = [
    {
      radioGroup: true,
      value: value?.task_id,
      items:
        records?.map((task) => {
          return {
            label: task.task_name,
            value: task.task_id,
            onClick: () => {
              setSelectedTask(task);
              onSelect?.(task);
            },
          };
        }) || [],
    },
  ];

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    setSelectedTask(null);
  }, [defaultQuery?.case_id]);

  return (
    <DropdownMenu
      menuItems={menuItems}
      title={"Select Task"}
      variant={variant}
      onScrollEnd={debouncedFetchNextPage}
      onSearch={setSearch}
      searchEnabled={true}
      textColor={!!selectedTask?.task_id ? "primary" : "secondary"}
      disabled={disabled}
      loading={!data}
      error={error}
    >
      {showLabel && (
        <div title={selectedTask?.task_name || "Task"}>
          {
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                width: "100%",
              }}
            >
              <ListTodoIcon size={14} style={{ minWidth: 14 }} />
              <div
                style={{
                  maxWidth: 125,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {selectedTask?.task_name || "Task"}
              </div>
            </div>
          }
        </div>
      )}
    </DropdownMenu>
  );
};

export default TaskSelector;
