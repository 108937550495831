import { Modal } from "@monolith-forensics/monolith-ui";
import ShareItemForm from "../Forms/ShareItemForm";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import CaseFileAPI from "../../api/CaseFiles";
import Loader from "../Loader";

const ShareItemModal = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  type,
  defaultValues,
  buttonProps,
}: {
  show: boolean;
  onClose: () => void;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  type: "relay" | "email";
  defaultValues?: {
    emails?: string[];
    message?: string;
    selectedItem?: any;
    selectedItems?: any[];
    caseInfo?: { uuid: string; case_id: number };
  };
  buttonProps?: { cancelText?: string; submitText?: string };
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const shareItems = useMutation({
    mutationFn: CaseFileAPI.shareItems,
    onMutate: async (values: any) => {
      setSubmitting(true);
    },
    onSuccess: (data, values) => {
      setSubmitting(false);
      onSubmit?.(values);
    },
  });

  const handleSubmit = async (values: any) => {
    const { emails, selectedItems, message, caseInfo } = values;
    shareItems.mutate({
      items: selectedItems.map((item: any) => ({
        name: item.file_name,
        uuid: item.uuid,
        type: item.type,
        share_data: {
          message,
        },
      })),
      case_uuid: caseInfo?.uuid,
      emails,
      type,
      message,
    });
  };

  return (
    <Modal open={show} onClose={onClose}>
      <Modal.Title>Share Items</Modal.Title>
      <Modal.SubTitle style={{ marginBottom: 15 }}>
        Share items with other users via Relay.
      </Modal.SubTitle>
      {submitting ? (
        <Loader message={"Sharing Items..."} style={null} iconProps={null} />
      ) : (
        <ShareItemForm
          onSubmit={handleSubmit}
          onCancel={onCancel}
          buttonProps={buttonProps}
          defaultFormData={defaultValues}
        />
      )}
    </Modal>
  );
};

export default ShareItemModal;
